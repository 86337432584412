import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
//import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Card from "react-bootstrap/Card";
//import "../css/Dashboard.css";
import Table from "react-table";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import invoiceApi from "../Invoice/InvoiceAPI";
import InvoiceAPI from "../Invoice/InvoiceAPI";
import { DriverModal } from "./DriverModal";
import misc from "../../functions/misc";
import DR from "../Invoice/PDF/DR";
import inventoryApi from "../Inventory/InventoryAPI";
import emptyImg from "../../assets/fork-lift.svg";
import actorApi from "../Actor/ActorAPI";
import { NumericFormat } from "react-number-format";
import Form from "react-bootstrap/Form";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import InputGroup from "react-bootstrap/InputGroup";
import moment from "moment";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";
import SalesReport from "../POS/PDF/SalesReport";
import InvReport from "../Invoice/PDF/InvReport";
import { PDFDownloadLink } from "@react-pdf/renderer";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as BarTooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  AreaChart,
  Area,
} from "recharts";
import settingsApi from "../Settings/SettingsAPI";
import { DashboardStatistics } from "./DashboardStatistics";
import { setInvoiceHeaderData } from "../../functions/pdfHeaderData";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
const SelectTable = selectTableHOC(Table);
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="p-1"
        style={{
          backgroundColor: "var(--REB-primary)",
          borderRadius: "5px",
          color: "var(--REB-white)",
        }}
      >
        <p className="m-0 p-0">{`Month: ${payload[0].payload.month}`}</p>
        <p className="m-0 p-0">
          <NumericFormat
            value={payload[0].payload.profits}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"Profits: ₱ "}
            fixedDecimalScale
            decimalScale={2}
          />
        </p>
        <p className="m-0 p-0">
          <NumericFormat
            value={payload[0].payload.totalSales}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"Sales: ₱ "}
            fixedDecimalScale
            decimalScale={2}
          />
        </p>
        <p className="m-0 p-0">
          <NumericFormat
            value={payload[0].payload.totalSpent}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"Expenses: ₱ "}
            fixedDecimalScale
            decimalScale={2}
          />
        </p>
        <p className="m-0 p-0">
          <NumericFormat
            value={payload[0].payload.orders.length}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"Invoice Count: "}
          />
        </p>
        {/* <p className="m-0 p-0">{`Profit Margin: ${payload[0].payload.cogs}`}</p> */}
      </div>
    );
  }

  return null;
};

const PDFGenerateReport = (props) => {
  const dispatch = useDispatch();
  const [btn, setBtn] = useState(false);
  const [ChkDispImage, setChkDispImage] = useState(false);
  const [SelPDFSort, setSelPDFSort] = useState(null);
  //console.log(props);
  return (
    <>
      {/* <style>
      {`
        @media (max-width: 767px) {
          .inventorySelectContainer {
            margin-top: 10px;
            margin-bottom: 10px;
            justify-content: center;
          }
        }
      `}
    </style> */}
      <style>{``}</style>
      {(() => {
        if (btn) {
          return (
            <>
              <PDFDownloadLink
                document={InvReport.InvReport(
                  props.state,
                  props.state.user,
                  ChkDispImage
                )}
              >
                {({ blob, url, loading, error }) => {
                  if (loading) {
                    return (
                      <>
                        <Button
                          style={{
                            backgroundColor: "var(--REB-primary)",
                          }}
                          disabled
                          block
                        >
                          <Spinner
                            size="xs"
                            animation="grow"
                            role="status"
                          ></Spinner>
                          PDF Generating...
                        </Button>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <Button
                          name="downloadPDF"
                          block
                          style={{
                            backgroundColor: "var(--REB-green)",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            props.state.handleActionChange({
                              target: {
                                value: blob,
                                name: "downloadPDF",
                              },
                            });
                            setBtn(false);
                          }}
                        >
                          {" "}
                          Download Inventory Report
                        </Button>
                      </>
                    );
                  }
                }}
              </PDFDownloadLink>
            </>
          );
        } else {
          return (
            <Button
              name="btnGeneratePDF"
              className=""
              style={{ backgroundColor: "var(--REB-primary)" }}
              block
              onClick={(e) => {
                e.preventDefault();

                props.state.handleActionChange(
                  {
                    target: {
                      name: "btnGenerateInvReport",
                    },
                  },
                  null,

                  () => {
                    dispatch({
                      type: "MODAL_SHOW",
                      payload: {
                        title: "Inventory Report Filters",
                        JsxDesc: () => {
                          const [TempChkDispImage, setTempChkDispImage] =
                            useState(false);
                          return (
                            <>
                              <div
                                style={{
                                  // backgroundColor: "var(--REB-primary)",
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                }}
                                // hidden={props.state.chkDispImage.readOnly}
                              >
                                <Form.Check
                                  checked={TempChkDispImage}
                                  //disabled={props.state.chkDispImage.readOnly}
                                  style={{ paddingTop: "6.5px" }}
                                  name="chkDispImage"
                                  label="Show Imgs"
                                  onChange={(e) => {
                                    setChkDispImage(!TempChkDispImage);
                                    setTempChkDispImage(!TempChkDispImage);
                                    // props.state.handleActionChange({
                                    //   target: {
                                    //     value: e.target,
                                    //     name: "chkDispImage",
                                    //   },
                                    // });
                                  }}
                                />
                              </div>
                              <Form.Label
                                className="font-weight-bold mt-2"
                                style={{ color: "var(--REB-black)" }}
                              >
                                Sort By
                              </Form.Label>
                              <select
                                className="form-control actor-selector w-100"
                                name="pdfSortBy"
                                // value={SelPDFSort}
                                // onChange={(e) => {
                                //   setSelPDFSort(e.currentTarget.value);
                                // }}

                                value={SelPDFSort}
                                onChange={(e) => {
                                  setSelPDFSort(e.currentTarget.value);
                                  props.state.handleActionChange(e);
                                }}
                              >
                                <option value={"name"}>Name</option>
                                <option value={"brand"}>Brand</option>
                                <option value={"qty"}>Qty</option>
                              </select>
                            </>
                          );
                        },
                        isYesNo: true,
                        cb: async (x) => {
                          setBtn(true);
                        },
                      },
                    });
                  }
                );
              }}
            >
              Generate Inventory Report
            </Button>
          );
        }
      })()}
    </>
  );
};

class DashboardProcess extends React.Component {
  constructor(props) {
    super(props);

    this.inputChange = this.inputChange.bind(this);

    this.state = {
      userType: this.props.userType,
      detailsData: [],
      dispatch: this.props.dispatch,
      handleActionChange: this.handleActionChange.bind(this),

      handleDriverModal: {
        handleDriverSubmit: this.handleDriverSubmit.bind(this),
        handleExit: this.handleDriverExit.bind(this),
        toggle: this.handleToggleDriverModal.bind(this),
        isValidated: false,
        isYesNo: true,
        isShow: false,
        isView: false,
        title: "",
        desc: "",
        response: false,
      },
      txtUniqueID: "",

      txtDesc: "",
      txtNotes: "",
      txtInvoiceName: "",
      txtShippingAddress: "",
      txtDeadline: "",
      txtDateAdded: "",
      txtDateLastModified: "",
      txtClient: "",
      txtSalesperson: "",
      txtInvoiceStatusName: "",
      txtApprovedBy: "",

      //TransferInventoryItemsTable
      DRTableSelection: [],
      selectedRowDR: null,
      DRTableData: [],
      DRDataCopy: [],
      DRTableloading: false,
      //TransferInventoryItemsTable

      DRContentTableData: [],
      DRContentDataCopy: [],
      DRContentTableloading: false,

      // dateRangeFilter: { startDate: null, endDate: null },
      dpStartDate: { value: "", readOnly: false },
      dpEndDate: { value: "", readOnly: false },
      chkAllTime: { value: false, readOnly: false },

      //Stats Data
      TotalSales: 0.0,
      TotalSalesDaily: 0.0,
      MostOrderedItemsSupplier: [],
      MostOrderedItemsClients: [],
      TopSellingItem: [],
      Invoices: null,
      InventoryStats: {
        inStockItems: [],
        outOfStockItems: [],
        negativeItems: [],
        hasDisplayItem: [],
        items: [],
      },
      isDataLoading: true,

      //INVENTORY
      selInventory: { value: "", className: "", readOnly: false, data: [] },
      selItems: { value: "", className: "", readOnly: false, data: [] },
      inventories: [],
      inventoryItems: [],
      itemCogsData: [],

      //LOADING HADNLERS
      getTotalSalesLoading: true,
      getTotalSalesAnnualLoading: true,
      getMostOrderedItemsCompLoading: true,
      getTopSellingItemLoading: true,
      getMostOrderedItemsClients: true,
      getInvoicesBreakdownLoading: true,
      getInventoryStatsLoading: true,
      getMostOrderedItemsClientsLoading: true,

      getTotalSalesDailyLoading: true,
      // UI
      DateToday: null,

      // Inventory Report
      chkDispImage: { value: false, readOnly: false },

      selPDFSort: {
        value: "name",
        readOnly: false,
      },
    };
  }

  async componentDidMount() {
    const detData = await invoiceApi.getInvoiceDetailsConst(
      this.props.dispatch
    );
    //console.log(detData);
    const inventories = await inventoryApi.getInventory(this.props.dispatch);
    let inventoryItems = [];

    if (this.state.userType.includes("Driver")) {
      this.setState((x) => ({
        detailsData: detData,
      }));
      await this.refreshDriverDR();
    } else {
      const DateToday = await actorApi.getCurrentDateTime(this.state.dispatch);
     
      this.setState(
        (x) => ({
          //detailsData: detData,
          // SelectedMonth,
          // DateToday,
          dpStartDate: {
            ...x.dpStartDate,
            value: moment(DateToday).format("YYYY-MM-DD"),
          },
          dpEndDate: "",
        }),
        () => {
          //this.refreshStats();
        }
      );
      if (inventories.length > 0) {
        this.handleActionChange({
          target: { name: "handleInventoryTypeChange", value: inventories[0] },
        });
        inventoryItems = await inventoryApi.getItemsByInventory(
          this.props.dispatch,
          0
        );
      }
      this.setState((x) => ({
        detailsData: detData,
        inventories,
        inventoryItems,
        DateToday,
      }));
    }
  }
  refreshStats = async () => {
    this.setState(
      (x) => ({
        isDataLoading: true,
      }),
      async () => {
        const filter = {
          // dateStart: this.state.dpStartDate.value,
          dateEnd: this.state.dpStartDate.value,
          inventoryId: this.state.selInventory.value.id,
        };
        this.setState((x) => ({
          getTotalSalesLoading: true,
          getTotalSalesAnnualLoading: true,
          getMostOrderedItemsCompLoading: true,
          getTopSellingItemLoading: true,
          getMostOrderedItemsClients: true,
          getInvoicesBreakdownLoading: true,
          getInventoryStatsLoading: true,
          getMostOrderedItemsClientsLoading: true,
          getTotalSalesDailyLoading: true,
        }));

        const DateToday = await actorApi.getCurrentDateTime(
          this.state.dispatch
        );
        const YearToday = moment(DateToday.data.datetime).format("YYYY");
        //const YearMonthToday = moment(DateToday.data.datetime).format("YYYY-MM");
        const SelectedMonth = moment(this.state.dpStartDate.value).format(
          "MMM"
        );
        this.setState((x) => ({
          SelectedMonth,
        }));
        //console.log(Number(this.state.selInventory.value.id));
        const MonthlySales = [];
       

        const TotalSalesDaily = await inventoryApi.getTotalSales(
          this.props.dispatch,
          {
            ...filter,
            isDaily: 1,
            dateEnd: moment(this.state.dpStartDate.value).format("YYYY-MM-DD"),
          }
        );
        //console.log(TotalSalesDaily);
        this.setState((x) => ({
          getTotalSalesDailyLoading: false,
          TotalSalesDaily,
        }));

        const TotalSales = await inventoryApi.getTotalSales(
          this.props.dispatch,
          filter
        );
        this.setState((x) => ({
          getTotalSalesLoading: false,
          TotalSales,
        }));
       // console.log(MonthlySales);
        //console.log(TotalSales);
        const MostOrderedItemsSupplier =
          await inventoryApi.getMostOrderedItemsComp(
            this.props.dispatch,
            filter
          );
        this.setState((x) => ({
          getMostOrderedItemsCompLoading: false,
          MostOrderedItemsSupplier,
        }));
        const MostOrderedItemsClients =
          await inventoryApi.getMostOrderedItemsClients(this.props.dispatch, {
            ...filter,
            limit: 5,
          });
        this.setState((x) => ({
          getMostOrderedItemsClientsLoading: false,
          MostOrderedItemsClients,
        }));
        const TopSellingItem = await inventoryApi.getTopSellingItem(
          this.props.dispatch,
          filter
        );
        this.setState((x) => ({
          getTopSellingItemLoading: false,
          TopSellingItem,
        }));
        //console.log(MostOrderedItemsSupplier);
        //console.log(MostOrderedItemsClients);
        //TODO HEAVY LOAD
        // const Invoices = await invoiceApi.getInvoicesBreakdown(
        //   this.props.dispatch,
        //   filter
        // );
        // this.setState((x) => ({
        //   getInvoicesBreakdownLoading: false,
        //   Invoices,
        // }));
        // const InventoryStats = await inventoryApi.getInventoryStats(
        //   this.props.dispatch
        // );
        // this.setState((x) => ({
        //   getInventoryStatsLoading: false,
        //   InventoryStats,
        // }));
        for (let index = 0; index < 12; index++) {
          const MonthlyTotalSales = await inventoryApi.getTotalSales(
            this.props.dispatch,
            {
              dateEnd: `${YearToday}-${String(index + 1).padStart(2, "0")}`,
              inventoryId: this.state.selInventory.value.id,
              // dateStart: `${YearToday}-${index + 1}`,
            }
          );

          MonthlySales.push({
            ...MonthlyTotalSales,
            month: moment(`${YearToday}-${index + 1}`).format("MMM"),
          });
        }
        this.setState((x) => ({
          getTotalSalesAnnualLoading: false,
          MonthlySales,
        }));

        //console.log(InventoryStats);
        // this.setState(
        //   (x) => ({
        //     TotalSales,
        //     MostOrderedItemsSupplier,
        //     MostOrderedItemsClients,
        //     TopSellingItem,
        //     Invoices,
        //     InventoryStats,
        //     MonthlySales,
        //     SelectedMonth,
        //     // isDataLoading: false,
        //   }),
        //   () => {
        //     this.setState((x) => ({
        //       isDataLoading: false,
        //     }));
        //   }
        // );
        return true;
      }
    );
  };
  refreshDriverDR = async () => {
    const OrderData = await InvoiceAPI.getOrderByDriverId(
      this.props.dispatch,
      this.props.user.id
    );
    this.setState((x) => ({
      DRTableData: OrderData,
      DRDataCopy: OrderData,
    }));
    return OrderData;
  };

  handleToggleDriverModal = (e) => {
    if (this.state.handleDriverModal.isShow)
      this.setState((x) => ({
        handleDriverModal: {
          ...x.handleDriverModal,
          isShow: false,
        },
      }));
    else
      this.setState((x) => ({
        handleDriverModal: {
          ...x.handleDriverModal,
          isShow: true,
        },
      }));
  };
  handleDriverSubmit = (e) => {};
  handleDriverExit = (e) => {
    this.setState((x) => ({
      selContentItems: {
        ...x.selContentItems,
        value: null,
      },
      selectedModalOperation: { id: 0, name: "Add" },
      verificationTableSelection: [],
      selectedRowVerification: null,

      verificationContentTableSelection: [],
      selectedRowVerificationSelection: null,
    }));
  };

  inputChange = (e, cb) => {
    this.setState(
      {
        [e.target.name]: {
          value: e.target.value,
          placeholder: e.target.placeholder,
          className: e.target.className,
        },
      },
      () => {
        if (cb) cb();
      }
    );
  };
  handleActionChange = async (e, data, cb) => {
    const _elName = e.target.name;

    if (_elName === "resetFilter") {
     
      this.setState(
        (x) => ({
          dpStartDate: {
            ...x.dpStartDate,
            value: misc.parseAPIDate(this.state.DateToday),
            readOnly: false,
          },
          dpEndDate: {
            ...x.dpEndDate,
            value: "",
            readOnly: false,
          },
          chkAllTime: {
            ...x.chkAllTime,
            value: false,
            readOnly: false,
          },
        }),
        () => {
          if (cb) cb();
        }
      );
    }
    if (_elName === "handleInventoryTypeChange") {
      let inventoryId = null;
      if (e.target?.value?.id) {
        inventoryId = e.target?.value?.id;
      } else {
        inventoryId = this.state.selInventory?.value?.id;
      }

      const inventoryItems = await inventoryApi.getItemsByInventory(
        this.props.dispatch,
        inventoryId
      );

      const settings = await settingsApi.getSettingsByInventory(
        this.props.dispatch,
        inventoryId
      );
        //Update Invoice Headers
        setInvoiceHeaderData(
          settings?.invoiceHeaderAddress1,
          settings?.invoiceHeaderAddress2,
          settings?.invoiceHeaderAddress3,
          settings?.invoiceHeaderLandline,
          settings?.invoiceHeaderMobile,
          settings?.invoiceHeaderContact,
          settings?.invoiceHeaderEmail,
          settings?.invoiceDeliveryReceiveAddress
        )
      //console.log(settings);
      this.setState(
        (x) => ({
          selInventory: {
            ...x.selInventory,
            value: e.target.value,
          },
          inventoryItems,
          settings,
        }),
        () => {
          this.refreshStats();
        }
      );
    }
    if (_elName === "handleInventoryItemChange") {
      const itemCogsData = await inventoryApi.getItemCogsSummary(
        this.props.dispatch,
        e.target.value.id
      );

      let itemCogsGraphData = [];

      if (itemCogsData.length > 0) {
        itemCogsData.forEach((e) => {
          itemCogsGraphData.push({
            ...e,
            IHandling: e.handling / e.qtyDelivered,
            IShipping: e.shipping / e.qtyDelivered,
            month: moment(e.dateFinished).format("LL"),
          });
        });
      }

      this.setState(
        (x) => ({
          selItems: {
            ...x.selItems,
            value: e.target.value,
          },
          itemCogsData: itemCogsGraphData,
        }),
        () => {
          //console.log(itemCogsGraphData);
          //this.refreshStats();
        }
      );
    }
    if (_elName === "applyFilter") {
      //console.log("trigger refresh");
      this.refreshStats();
    }
    if (_elName === "chkAllTime") {
      
      if (!this.state.chkAllTime.value) {
        this.setState((x) => ({
          chkAllTime: {
            ...x.chkAllTime,
            value: this.state.chkAllTime.value ? false : true,
          },

          dpStartDate: {
            ...x.dpStartDate,
            value: "",
            readOnly: true,
          },
          dpEndDate: {
            ...x.dpEndDate,
            value: "",
            readOnly: true,
          },
        }));
      } else {
        this.setState((x) => ({
          chkAllTime: {
            ...x.chkAllTime,
            value: this.state.chkAllTime.value ? false : true,
          },

          dpStartDate: {
            ...x.dpStartDate,
            value: misc.parseAPIDate(this.state.DateToday),
            readOnly: false,
          },
          dpEndDate: {
            ...x.dpEndDate,
            value: "",
            readOnly: false,
          },
        }));
      }
      if (cb) cb();
    }
    if (_elName === "btnEditDR") {
      this.populateVerificationModal(data);
      this.handleToggleDriverModal();
      //console.log("trigger");
      // this.setState(
      //   (x) => ({
      //     txtDesc: { ...x.txtDesc },
      //     txtNotes: { ...x.txtNotes },
      //     txtInvoiceName: { ...x.txtInvoiceName },
      //     txtShippingAddress: { ...x.txtShippingAddress },
      //     txtDeadline: { ...x.txtDeadline },
      //     txtDateAdded: { ...x.txtDateAdded },
      //     txtDateLastModified: { ...x.txtDateLastModified },
      //     txtClient: { ...x.txtClient },
      //     txtSalesperson: { ...x.txtSalesperson },
      //   }),
      //   () => {

      //   }
      // );
    }
    if (_elName === "btnViewPDF") {
      const DAT = this.state;
      this.state.dispatch({
        type: "PDF_MODAL_TOGGLE",
        doc: DR.DRPDF(
          this.state,
          this.state.drParent,
          true,
          DAT.txtUniqueID,
          DAT.txtInvoiceStatusName,
          DAT.txtShippingAddress,
          DAT.txtSalesperson,
          DAT.txtApprovedBy
        ),
      });
    }
    if (_elName === "btnSetDRDelivered") {
      const detData = await invoiceApi.getInvoiceDetail(
        this.props.dispatch,
        Number(
          this.state.detailsData.OrderDetailId.find((x) => x.name === "Status")
            .value
        )
      );
      //console.log(detData);
      const _srch = detData.find((x) => x.name === "Finished");
      
      if (_srch) {
        const _data = {
          data: {
            id: Number(this.state.selectedRowDR.id),
            lastModifiedByActorId: Number(this.props.user.id),
            //dateLastModified: DateToday,
            notes: this.state.txtNotes,
            //dateFinished: DateToday,
            invoiceStatusId: Number(_srch.id),
          },
        };
        //console.log(_data);
        await invoiceApi.updateInvoice(this.props.dispatch, _data);
        await this.refreshDriverDR();
        this.handleToggleDriverModal();
      } else {
        //console.log("Error");
      }
      //this.refreshSelectedPRContent(data.id);
    }
    if (_elName === "handleFileUploadChange") {
      let currentFiles = e.target.files;
      //console.log(currentFiles);
      this.setState((x) => ({
        fileUploadInvoice: {
          ...x.fileUploadInvoice,
          value: currentFiles,
        },
      }));
    }
    if (_elName === "btnFileUploadSubmit") {
      const currentFiles = this.state.fileUploadInvoice.value;

      //For custom file name
      //const fileType = currentFiles[0].type.split("/")[1];
      //console.log(this.state.selectedRowDRSelection);

      const formData = new FormData();
      formData.append("uniqueId", this.state.selectedRowDR.uniqueId);
      for (let index = 0; index < currentFiles.length; index++) {
        formData.append(
          "uploadedFiles",
          currentFiles[index]
          //`signature.${fileType}`
        );
      }
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      invoiceApi.uploadFile(this.props.dispatch, formData, async () => {
        const UPDATED_ITEMS = await this.refreshDriverDR();
        const oldSel = this.state.selectedRowDR;

        this.setState(
          (x) => ({
            DRTableData: UPDATED_ITEMS,
            DRTableDataCopy: UPDATED_ITEMS,
          }),
          () => {
            this.setState((x) => ({
              selectedRowDR: this.state.DRTableData.filter(
                (x) => x.id === oldSel.id
              )[0],
              isLoading: false,
            }));
            if (cb) cb();
          }
        );
      });
    }
    if (_elName === "btnFileUploadDownload") {
      invoiceApi.downloadFile(
        this.props.dispatch,
        e.target.id,
        e.target.filename,
        e.target.complete,
        () => {
          // this.props.dispatch({
          //   type: "FILE_VIEWER_MODAL_TOGGLE",
          //   doc: {},
          //   docType: {},
          // });
        }
      );
    }
    if (_elName === "btnFileUploadView") {
      const url = `${process.env.REACT_APP_API_URL}global/getFile2?uniqueId=${
        e.target.id
      }&filename=${e.target.filename}&token=${localStorage.getItem("LUT")}`;
      this.props.dispatch({
        type: "FILE_VIEWER_MODAL_TOGGLE",
        doc: url,
      });
    }
    if (_elName === "btnFileUploadDelete") {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Confirmation",
          desc: `Are you sure you want to delete ${e.target.originalname} ?`,
          isYesNo: true,
          cb: async (x) => {
            invoiceApi.deleteFile(
              this.props.dispatch,
              this.state.selectedRowDR.uniqueId,
              e.target.filename,
              async () => {
                const UPDATED_ITEMS = await this.refreshDriverDR();
                const oldSel = this.state.selectedRowDR;

                this.setState(
                  (x) => ({
                    DRTableData: UPDATED_ITEMS,
                    DRTableDataCopy: UPDATED_ITEMS,
                  }),
                  () => {
                    this.setState((x) => ({
                      selectedRowDR: this.state.DRTableData.filter(
                        (x) => x.id === oldSel.id
                      )[0],
                      isLoading: false,
                    }));
                  }
                );
              }
            );
          },
        },
      });
    }
    if (_elName === "pdfSortBy") {
      this.setState((x) => ({
        selPDFSort: {
          ...x.selPDFSort,
          value: e.target.value,
        },
      }));
    }
    if (_elName === "downloadPDF") {
      const DateToday = await actorApi.getCurrentDateTime(this.state.dispatch);
      const fileName = `Inventory Report ${misc.parseAPIDate(DateToday)}`;
      const href = await window.URL.createObjectURL(new Blob([e.target.value]));
      const link = document.createElement("a");
      link.href = href;

      link.download = fileName + ".pdf";
      document.body.appendChild(link);
      link.click();
      this.setState((x) => ({
        chkDispImage: {
          ...x.chkDispImage,
          readOnly: false,
        },
      }));
      if (cb) cb();
    }
    if (_elName === "btnViewInvReport") {
      this.props.dispatch({
        type: "PDF_MODAL_TOGGLE",
        doc: InvReport.InvReport(
          this.state,
          this.props.user,
          this.state.chkDispImage.value
        ),
      });
    }
    if (_elName === "btnGenerateInvReport") {
      if (!this.state.selInventory.value.id) {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "No Inventory Selection",
            desc: `Please select an Inventory`,
            isYesNo: false,
          },
        });
        return;
      }
      // if (this.state.inventoryData.length === 0) {
      //   this.props.dispatch({
      //     type: "MODAL_SHOW",
      //     payload: {
      //       title: "Inventory Empty",
      //       desc: `You dont have permission to access any inventory. Please contact your administrator`,
      //       isYesNo: false,
      //     },
      //   });
      // }
      else {
        // const _items = this.state.itemData;
        this.setState((x) => ({
          chkDispImage: {
            ...x.chkDispImage,
            readOnly: true,
          },
        }));

        const perChunk = this.state.chkDispImage.value ? 12 : 23; // items per chunk
        // const sorted = _items.sort((a, b) =>
        //   a.name < b.name ? -1 : a.name > b.name ? 1 : 0
        // );

        const inventoryItems = await inventoryApi.getItemsByInventory(
          this.props.dispatch,
          Number(this.state.selInventory.value.id) === 1
            ? 0
            : Number(this.state.selInventory.value.id),
          this.state.selPDFSort.value
        );

        let result = inventoryItems.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / perChunk);
          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
          }
          resultArray[chunkIndex].push({ ...item, index: index + 1 });
          return resultArray;
        }, []);
        //console.log(this.state.pdfGroupTable);

        this.setState({ pdfGroupTable: { result } }, async (x) => {
          //console.log(cb);
          // this.props.dispatch({
          //   type: "PDF_MODAL_TOGGLE",
          //   doc: InvReport.InvReport(
          //     this.state,
          //     this.props.user,
          //     this.state.chkDispImage.value
          //   ),
          // });

          if (cb) cb();
        });
      }
    }
    if (_elName === "chkDispImage") {
      this.setState((x) => ({
        chkDispImage: {
          ...x.chkDispImage,
          value: this.state.chkDispImage.value ? false : true,
        },
      }));
    }
    if (_elName === "chkHandleChange") {
      this.setState((x) => ({
        [e.target.componentName]: {
          ...x[e.target.componentName],
          value: this.state[e.target.componentName].value ? false : true,
        },
      }));
    }
  };
  populateVerificationModal = async (data, cb) => {
    //let _content = [];

    // for (let i = 0; i < data.content.length; i++) {
    //   const e = data.content[i];
    //   if (e.item !== null) {
    //     if (e.item.getImages.length > 0) {
    //       let _imgChild = [];
    //       const _img = await inventoryApi.downloadItemImg(
    //         this.props.dispatch,
    //         e.item,
    //         1
    //       );
    //       if (e.childId !== null) {
    //         if (e.child.item.getImages.length > 0) {
    //           _imgChild = await inventoryApi.downloadItemImg(
    //             this.props.dispatch,
    //             e.child.item,
    //             1
    //           );
    //         }
    //       }
    //       if (_imgChild.length > 0) {
    //         _content.push({
    //           ...e,
    //           dispImg: _img[0],
    //           child: { ...e.child, dispImg: _imgChild[0] },
    //         });
    //       } else {
    //         _content.push({ ...e, dispImg: _img[0] });
    //       }
    //     } else {
    //       _content.push({ ...e });
    //     }
    //   } else {
    //     _content.push({ ...e });
    //   }
    // }
    //console.log(data);
    this.setState(
      (a) => ({
        //txtQty: { ...a.txtQty, value: data.qty },
        txtClient: data.client !== "" ? data.client.company : "",
        txtSalesperson: data.incharge ? data.incharge.name : "",
        txtUniqueID: data.uniqueId,
        txtInvoiceName: data.name,
        txtDesc: data.description,
        txtNotes: data.notes,
        txtDeadline: misc.parseAPIDateTime(data.deadline),
        txtDateLastModified: misc.parseAPIDateTime(data.dateLastModified),
        txtDateAdded: misc.parseAPIDateTime(data.dateAdded),
        txtShippingAddress: data.shippingAddress,
        txtInvoiceStatusName: data.invoiceStatus.name,
        txtApprovedBy: data.approvedBy ? data.approvedBy.name : "-",
        DRContentTableData: data.content,
        DRContentDataCopy: data.content,
      }),
      async (a) => {
        console.log("SELECTED INVOICE ==>", data, this.state);
        const _services = data.content.filter((x) => x.itemId === null);
        const _items = data.content.filter((x) => x.itemId !== null);

        const perChunk = 8; // items per chunk
        let result = _items.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / perChunk);
          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
          }
          resultArray[chunkIndex].push({ ...item, index: index + 1 });
          return resultArray;
        }, []);

        const Parent = await invoiceApi.getInvoice(
          this.props.dispatch,
          Number(data.parentId)
        );

        this.setState(
          {
            pdfGroupTable: { result, _services },
            drParent: Parent,
          },
          (x) => {
            // this.setState({
            //   pdfContent: DR.DRPDF(this.state, Parent, false),
            // });
            if (cb) cb();
          }
        );
      }
    );
  };

  GetItemSalesByDate = async (date, cb) => {
    const filter = {
      // dateStart: this.state.dpStartDate.value,
      dateEnd: moment(date).format("YYYY-MM-DD"),
      inventoryId: this.state.selInventory.value.id,
      limit: 0,
    };
    const TotalSalesDaily = await inventoryApi.getTotalSales(
      this.props.dispatch,
      {
        ...filter,
        isDaily: 1,
        dateEnd: moment(date).format("YYYY-MM-DD"),
      }
    );
    const MostOrderedItemsClients =
      await inventoryApi.getMostOrderedItemsClients(this.props.dispatch, {
        ...filter,
        isDaily: 1,
        dateEnd: moment(date).format("YYYY-MM-DD"),
      });

    if (TotalSalesDaily && MostOrderedItemsClients) {
      //setSalesStats(TotalSalesDaily);
      //setItemOrdersClient(MostOrderedItemsClients);
      //console.log(TotalSalesDaily);
      if (cb) cb(TotalSalesDaily, MostOrderedItemsClients);
    }
  };

  toggleSelectionDR = (key, shift, row) => {
    let DRTableSelection = this.state.DRTableSelection;
    const keyIndex = DRTableSelection.indexOf(key);
    if (keyIndex >= 0) {
      DRTableSelection = [];
      this.setState((x) => ({
        selectedRowDR: null,
      }));
    } else {
      DRTableSelection.length = 0;
      DRTableSelection.push(key);
      this.setState((x) => ({
        selectedRowDR: row,
      }));
    }

    this.setState(
      (x) => ({
        DRTableSelection,
      })
      // () => console.log("SELECTED REQUEST ITEM ===> ", _dat)
    );
  };

  render() {
    const tableContent = (
      <SelectTable
        data={this.state.DRContentTableData}
        columns={drColumn()}
        selectType="radio"
        defaultPageSize={10}
        style={{ overflow: "auto", flex: 1 }}
        className="tableActor -highlight mt-3"
      />
    );
    // const tableTopItemClient = (
    //   <Table
    //     data={this.state.MostOrderedItemsClients}
    //     columns={statisticsBaseColumn()}
    //     filterable
    //     defaultFilterMethod={(filter, row, column) => {
    //       const id = filter.pivotId || filter.id;
    //       return row[id] !== undefined
    //         ? String(String(row[id]).toUpperCase()).includes(
    //             String(filter.value).toUpperCase()
    //           )
    //         : true;
    //     }}
    //     selectType="radio"
    //     showPageSizeOptions={false}
    //     showPageJump={false}
    //     showPaginationBottom={false}
    //     defaultPageSize={10}
    //     style={{ overflow: "auto", height: "600px" }}
    //     className="tableActor -highlight mt-3"
    //   />
    // );
    // const tableTopItemSupplier = (
    //   <Table
    //     data={this.state.MostOrderedItemsSupplier}
    //     columns={statisticsBaseColumnSupplier()}
    //     filterable
    //     defaultFilterMethod={(filter, row, column) => {
    //       const id = filter.pivotId || filter.id;
    //       return row[id] !== undefined
    //         ? String(String(row[id]).toUpperCase()).includes(
    //             String(filter.value).toUpperCase()
    //           )
    //         : true;
    //     }}
    //     selectType="radio"
    //     showPageSizeOptions={false}
    //     showPageJump={false}
    //     showPaginationBottom={false}
    //     defaultPageSize={10}
    //     style={{ overflow: "auto", height: "600px" }}
    //     className="tableActor -highlight mt-3"
    //   />
    // );
    const tableDRList = (
      <Table
        data={this.state.DRTableData}
        keyField="id"
        showPageSizeOptions={false}
        showPageJump={false}
        filterable
        defaultFilterMethod={(filter, row, column) => {
          const id = filter.pivotId || filter.id;
          return row[id] !== undefined
            ? String(row[id].toUpperCase()).includes(filter.value.toUpperCase())
            : true;
        }}
        columns={invoiceColumn([], [], this.state.DateToday)}
        loading={this.state.DRTableloading}
        toggleSelection={this.toggleSelectionDR}
        //selectType="radio"
        isSelected={(key) =>
          this.state.DRTableSelection.includes(`select-${key}`)
        }
        getTrProps={(state, rowInfo, column, instance) => ({
          onClick: async (e, handleOriginal) => {
            e.preventDefault();
            e.stopPropagation();
            const _dat = rowInfo.original;
            if (
              this.state.DRTableSelection.length > 0 &&
              this.state.DRTableSelection[0] === `select-${rowInfo.original.id}`
            ) {
              this.setState((x) => ({
                DRTableSelection: [],
                txtSelectedItemPR: { ...x.txtSelectedItemPR, value: "" },
              }));
            } else {
              if (rowInfo) {
                this.toggleSelectionDR(rowInfo.index, null, rowInfo.original);
                console.log("SELECTED DR DRIVER ===> ", _dat);
                this.handleActionChange(
                  { target: { name: "btnEditDR" } },
                  rowInfo.original
                );
                this.setState((x) => ({
                  DRTableSelection: ["select-" + rowInfo.original.id],
                }));
              }
            }
            if (handleOriginal) {
              handleOriginal();
            }
          },
          style: {
            background:
              rowInfo &&
              this.state.DRTableSelection.includes(
                `select-${rowInfo.original.id}`
              ) &&
              "var(--REB-green)",
            color:
              rowInfo &&
              this.state.DRTableSelection.includes(
                `select-${rowInfo.original.id}`
              ) &&
              "var(--REB-white)",
          },
        })}
        defaultPageSize={12}
        style={{
          overflow: "auto",
          flex: 1,
          backgroundColor: "var(--REB-bg-color)",
        }}
        className="tableActor -highlight "
      />
    );

    const cardTitleStyle = {
      backgroundColor: "var(--REB-primary)",
      color: "var(--REB-white)",
    };
    // const cardTextStyle = {
    //   color: "var(--REB-blue)",
    //   fontWeight: "bolder",
    //   fontSize: "1.5em",
    // };
    const cardInvoiceTitle = {
      textAlign: "start",
      paddingLeft: "1em",
      fontSize: "1.1em",
      fontWeight: "bold",
    };
    const cardInvoiceValues = {
      color: "var(--REB-blue)",
      textAlign: "start",
      paddingLeft: "1em",
      fontSize: "1.1em",
      fontWeight: "bold",
    };

    return (
      <>
        {/* {" "}
        {this.state.isDataLoading ? (
          <></>
        ) : (
        
        )} */}
        <>
          {this.state.userType.includes("Driver") ? (
            <>
              <DriverModal state={this.state} tableContent={tableContent} />
              <Row
                style={{ backgroundColor: "var(--REB-primary)", padding: 20 }}
                noGutters
              >
                <Col></Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <h4
                    className="text-center"
                    style={{
                      fontWeight: "bolder",
                      width: "100%",
                      color: "var(--REB-white)",
                    }}
                  >
                    Your Deliveries
                  </h4>
                </Col>
              </Row>
              {tableDRList}
            </>
          ) : (
            <>
              {/* <Row
                  className="mb-3 animate__animated animate__fadeInLeft"
                  noGutters={true}
                >
                  <Col md={6} lg={6} sm={12} xs={12}>
                    <h4
                      className="page-header-mobile-center"
                      style={{
                        fontWeight: "bolder",
                        width: "100%",
                        color: "var(--REB-black)",
                      }}
                    >
                      Statistics
                    </h4>
                  </Col>
                  <Col
                    md={6}
                    lg={6}
                    sm={12}
                    xs={12}
                    // style={{ textAlignLast: "end" }}
                  >
                    <h4 className="page-header-mobile-center page-header-mobile-end">
                      Date:{" "}
                      <u className="actor-date-date">
                        {moment().format("MMMM Do YYYY")}
                      </u>
                    </h4>
                  </Col>
                </Row> */}
              <style type="text/css">
                {`
      
    @media (max-width: 768px) {
      .res-mb-3{
        margin-bottom: 1rem !important;
      }
      .res-mb-2{
        margin-bottom: 0.5rem !important;
      }
    }
    @media (min-width: 768px) {
      .res-pr-2{
        padding-right: 0.5rem !important;
      }
      .res-pl-2{
        padding-left: 0.5rem !important;
      }
      .res-pl-3{
        padding-left: 1rem !important;
      }
      .res-px-1{
        padding-left: 0.25rem !important;
        padding-right: 0.25rem !important;
      }
     
    }

    
    `}
              </style>
              <Row
                className="globalContainerStyle p-3 animate__animated animate__fadeInLeft"
                noGutters
              >
                <Col
                  md={"auto"}
                  lg={"auto"}
                  sm={12}
                  xs={12}
                  style={{ display: "flex" }}
                  className="res-mb-2 px-4"
                >
                  <h4
                    className="m-0 p-0"
                    style={{ fontWeight: "bolder", alignSelf: "center" }}
                  >
                    <i className="icofont-filter"></i> Filter
                  </h4>
                </Col>
                <Col md={3} lg={3} sm={12} xs={12} className="res-mb-2">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text
                        style={{
                          color: "var(--REB-white)",
                          fontWeight: "bold",
                          backgroundColor: "var(--REB-primary)",
                        }}
                      >
                        Date
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="date"
                      //placeholder={props.state.txtDeadline.placeholder}
                      placeholder="Date"
                      // onMouseOver={(e) => (e.currentTarget.type = "date")}
                      // onMouseOut={(e) => (e.currentTarget.type = "text")}
                      name="dpStartDate"
                      value={this.state.dpStartDate.value}
                      onChange={(x) => {
                        this.inputChange(x, () => {
                          this.state.handleActionChange({
                            target: {
                              value: x.target,
                              name: "applyFilter",
                            },
                          });
                        });
                      }}
                      className={
                        this.state.dpStartDate.className + " css-projectInput"
                      }
                      readOnly={this.state.dpStartDate.readOnly}
                    />
                  </InputGroup>
                </Col>
                <Col md={3} lg={3} className="res-mb-2">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text
                        style={{
                          backgroundColor: "var(--REB-primary)",
                          color: "var(--REB-white)",
                          fontWeight: "bold",
                          fontSize: "0.75rem",
                        }}
                      >
                        INVENTORY
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Select
                      styles={{
                        menu: (provided, state) => {
                          //console.log(state);
                          const width = "100%";

                          let color = state.selectProps.menuColor;
                          const padding = 0;

                          return { ...provided, width, color, padding };
                        },
                        control: (provided) => ({
                          ...provided,
                          borderRadius: "0px 0px 0px 0px",
                        }),

                        container: (_, { selectProps: { width } }) => ({
                          width: width,
                          flex: 1,
                        }),
                        menuPortal: (provided) => ({
                          ...provided,
                          zIndex: 9999,

                          borderRadius: "5px 0px 0px 5px",
                        }),

                        option: (provided, state) => {
                          let backgroundColor = "";
                          let display = "block";
                          let color = "black";
                          if (state.data.isDefault === 1) {
                            backgroundColor = "rgba(255, 0, 0, 0.2)";
                          }
                          const opacity = state.isDisabled ? 0.5 : 1;
                          const transition = "opacity 300ms";
                          //console.log(state.data);
                          if (state.data.isHidden === 1) {
                            display = "none";
                          }

                          return {
                            ...provided,
                            opacity,
                            transition,
                            backgroundColor,
                            color,
                            display,
                          };
                        },
                      }}
                      isSearchable
                      menuPortalTarget={document.body}
                      width="100%"
                      options={this.state.inventories}
                      value={this.state.selInventory.value}
                      isDisabled={this.state.selInventory.readOnly}
                      getOptionLabel={(x) => x.name}
                      getOptionValue={(x) => x.id}
                      //components={{ Option: _option }}
                      //formatOptionLabel={"test"}
                      //innerRef={this.props.propRef}
                      onChange={(x, name) => {
                        //console.log(x, name);

                        this.state.handleActionChange({
                          target: {
                            value: x,
                            name: "handleInventoryTypeChange",
                          },
                        });
                        // this.props.handler(x, name, {
                        //   target: { name: this.props.name },
                        // });
                      }}
                    />
                  </InputGroup>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  style={{
                    height: "38px", //alignSelf: "center"
                  }}
                >
                  <InputGroup
                    className="res-pl-3"
                    style={{
                      //alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "var(--REB-tertiary)",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <Form.Check
                        checked={this.state.chkAllTime.value}
                        disabled={this.state.chkAllTime.readOnly}
                        style={{ paddingTop: "7.5px", color: "white" }}
                        name="chkAllTime"
                        label="All Time"
                        onChange={(e) => {
                          this.state.handleActionChange(
                            {
                              target: {
                                value: e.target,
                                name: "chkAllTime",
                              },
                            },
                            null,
                            () => {
                              this.state.handleActionChange({
                                target: {
                                  value: e.target,
                                  name: "applyFilter",
                                },
                              });
                            }
                          );
                        }}
                      />
                    </div>

                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Tooltip id={`actor-tooltip-add`}>Reset</Tooltip>
                      }
                    >
                      <Button
                        name="btnUnlockInvoiceContentChangeItem"
                        //disabled={!props.state.handleInvoiceModal.isView}
                        style={{
                          backgroundColor: "var(--REB-tertiary)",
                          fontSize: "13px",
                          marginLeft: "10px",
                          marginRight: "10px",
                          fontWeight: "bolder",
                        }}
                        onClick={(e) => {
                          this.state.handleActionChange(
                            {
                              target: {
                                name: "resetFilter",
                              },
                            },
                            null,
                            () => {
                              this.state.handleActionChange({
                                target: {
                                  value: e.target,
                                  name: "applyFilter",
                                },
                              });
                            }
                          );
                        }}
                      >
                        Reset Filter
                      </Button>
                    </OverlayTrigger>
                  </InputGroup>
                </Col>
              </Row>

              <Row
                style={{ height: "auto" }}
                className="globalContainerStyle p-3 my-3 animate__animated animate__fadeInLeft"
                noGutters
              >
                <Col xs={12} className="mb-3 ">
                  <h4
                    className="m-0 p-0"
                    style={{ fontWeight: "bolder", alignSelf: "center" }}
                  >
                    <i className="icofont-chart-histogram"></i> Annual Sales and
                    Expenses
                  </h4>
                </Col>
                {this.state.getTotalSalesAnnualLoading ? (
                  <Col xs={12} style={{ textAlign: "center" }}>
                    <h5>Generating Annual Data</h5>
                    <Spinner animation="border" />
                  </Col>
                ) : (
                  <>
                    {" "}
                    <Col
                      xs={12}
                      className="animate__animated animate__fadeIn"
                      style={{ overflowX: "scroll" }}
                    >
                      <ResponsiveContainer height={350} minWidth={750}>
                        <BarChart
                          data={this.state.MonthlySales}
                          //layout={"vertical"}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="month" />
                          <YAxis
                            interval="preserveEnd"
                            ticks={[
                              0, 20000, 50000, 200000, 400000, 800000, 1000000,
                              1200000, 1400000, 1600000, 1800000, 2000000,
                            ]}
                          />
                          <BarTooltip content={<CustomTooltip />} />
                          <Legend />
                          <Bar
                            dataKey="totalSales"
                            name="Sales"
                            fill="#8884d8"
                          />
                          <Bar
                            dataKey="profits"
                            name="Profits"
                            fill="#A0B7FF"
                          />
                          <Bar
                            dataKey="totalSpent"
                            name="Spending"
                            fill="#F06C63"
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </Col>
                  </>
                )}
              </Row>
              {/* {!this.state.getTotalSalesLoading && (
               
              )} */}
              <Row
                style={{ height: "auto" }}
                className="globalContainerStyle p-3 my-3 animate__animated animate__fadeInLeft"
                noGutters
              >
                <Col xs={12} className="mb-3 ">
                  <h4
                    className="m-0 p-0"
                    style={{ fontWeight: "bolder", alignSelf: "center" }}
                  >
                    <i className="icofont-chart-histogram"></i> PDF Exports
                  </h4>
                </Col>
                <Col className="p-2" xs={12} sm={12} md={4} lg={4}>
                  <Button
                    block
                    style={{ backgroundColor: "var(--REB-primary)" }}
                    onClick={() => {
                      let dateOfReport = null;
                      this.state.dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "Confirmation",
                          // desc: `Do you want to generate sales report for today? ${moment(
                          //   UIDateGenerateReportToday
                          // ).format("LL")}`,
                          JsxDesc: () => {
                            const [
                              tempUIDateGenerateReportToday,
                              settempUIDateGenerateReportToday,
                            ] = useState("");
                            return (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <p
                                    className="m-0 p-0"
                                    style={{
                                      flex: 1,
                                      alignSelf: "center",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    Please select date of report
                                  </p>
                                  <FormControl
                                    type="date"
                                    value={tempUIDateGenerateReportToday}
                                    style={{ flex: 1 }}
                                    onChange={(e) => {
                                      //console.log(e.currentTarget.value);
                                      settempUIDateGenerateReportToday(
                                        e.currentTarget.value
                                      );
                                      dateOfReport = e.currentTarget.value;
                                    }}
                                  />
                                </div>
                              </>
                            );
                          },
                          isYesNo: true,
                          cb: async (e) => {
                            if (!dateOfReport) {
                              alert("Report date should not be empty");
                            } else {
                              this.GetItemSalesByDate(
                                dateOfReport,
                                (TotalSalesDaily, MostOrderedItemsClients) => {
                                  //console.log(TotalSalesDaily);
                                  let perChunk = 15; // items per chunk

                                  let result = MostOrderedItemsClients.reduce(
                                    (resultArray, item, index) => {
                                      const chunkIndex = Math.floor(
                                        index / perChunk
                                      );
                                      if (!resultArray[chunkIndex]) {
                                        resultArray[chunkIndex] = []; // start a new chunk
                                      }
                                      resultArray[chunkIndex].push({
                                        ...item,
                                        index: index + 1,
                                      });
                                      return resultArray;
                                    },
                                    []
                                  );
                                  this.state.dispatch({
                                    type: "PDF_MODAL_TOGGLE",
                                    doc: SalesReport.SalesReport(
                                      {
                                        userType: this.props.user.types,
                                        ...TotalSalesDaily,
                                        orders: result,
                                        ordersLength:
                                          TotalSalesDaily.orders.length,
                                      },
                                      this.props.user,
                                      false,
                                      dateOfReport
                                    ),
                                  });
                                }
                              );
                            }
                          },
                        },
                      });
                    }}
                  >
                    Generate Daily Sales Report
                  </Button>
                </Col>
                <Col className="p-2" xs={12} sm={12} md={4} lg={4}>
                  {/* <Button>Generate Inventory Report</Button> */}
                  <PDFGenerateReport state={{ ...this.state }} />
                </Col>
              </Row>
              <DashboardStatistics state={{ ...this.state }} />

              <Row className="mt-3" noGutters>
                <Col
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  className="res-pr-2 animate__animated animate__fadeInLeft"
                >
                  <Row
                    style={{ height: "50vh" }}
                    className="globalContainerStyle p-3 res-mb-3"
                    noGutters
                  >
                    <Col xs={12} className="mb-3">
                      <h4
                        className="m-0 p-0"
                        style={{ fontWeight: "bolder", alignSelf: "center" }}
                      >
                        <i className="icofont-chart-pie"></i> Top Selling
                        Products ({this.state.SelectedMonth})
                      </h4>
                    </Col>
                    <Col xs={12}>
                      {this.state.MostOrderedItemsClients.length > 0 ? (
                        <>
                          {this.state.getMostOrderedItemsClientsLoading ? (
                            <>
                              <Col xs={12} style={{ textAlign: "center" }}>
                                <Spinner animation="border" />
                              </Col>
                            </>
                          ) : (
                            <>
                              {" "}
                              <ResponsiveContainer>
                                <PieChart>
                                  <Pie
                                    dataKey="count"
                                    // isAnimationActive={true}
                                    isAnimationActive={false}
                                    data={this.state.MostOrderedItemsClients}
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={80}
                                    fill="#8884d8"
                                    label={(value) => {
                                      const unit = value.details?.find(
                                        (x) => x.type === 2
                                      );
                                      return `(${Number(
                                        value.percent * 100
                                      ).toFixed(2)}%) ${value.count} ${
                                        unit.name
                                      } ${value.name} `;
                                    }}
                                    // nameKey={"itemName"}
                                  >
                                    {this.state.MostOrderedItemsClients.map(
                                      (entry, index) => (
                                        <Cell
                                          key={`cell-${index}`}
                                          fill={COLORS[index % COLORS.length]}
                                        />
                                      )
                                    )}
                                  </Pie>
                                  <BarTooltip
                                    formatter={(value, name, props) => [
                                      value,
                                      name,
                                    ]}
                                  />
                                </PieChart>
                              </ResponsiveContainer>
                            </>
                          )}
                        </>
                      ) : (
                        <h4 style={{ textAlign: "center" }}>
                          No Data Available...
                        </h4>
                      )}
                    </Col>
                  </Row>
                </Col>

                {/* {!this.state.getMostOrderedItemsCompLoading && (
                
                )} */}
                <Col
                  sm={12}
                  xs={12}
                  md={6}
                  lg={6}
                  className="res-pl-2 animate__animated animate__fadeInLeft"
                >
                  <Row
                    style={{ height: "50vh" }}
                    className="globalContainerStyle p-3 res-mb-3"
                    noGutters
                  >
                    <Col xs={12} className="mb-3">
                      <h4
                        className="m-0 p-0"
                        style={{ fontWeight: "bolder", alignSelf: "center" }}
                      >
                        <i className="icofont-chart-pie"></i> Top Supplier
                        Orders ({this.state.SelectedMonth})
                      </h4>
                    </Col>
                    <Col xs={12}>
                      {this.state.MostOrderedItemsSupplier.length > 0 ? (
                        <>
                          {this.state.getMostOrderedItemsCompLoading ? (
                            <>
                              <Col xs={12} style={{ textAlign: "center" }}>
                                <Spinner animation="border" />
                              </Col>
                            </>
                          ) : (
                            <>
                              <ResponsiveContainer>
                                <PieChart>
                                  <Pie
                                    dataKey="count"
                                    // isAnimationActive={true}
                                    isAnimationActive={false}
                                    data={this.state.MostOrderedItemsSupplier}
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={80}
                                    fill="#8884d8"
                                    label={(value) => {
                                      const unit = value.details?.find(
                                        (x) => x.type === 2
                                      );
                                      return `(${Number(
                                        value.percent * 100
                                      ).toFixed(2)}%) ${value.count} ${
                                        unit.name
                                      } ${value.name} `;
                                    }}
                                    // nameKey={"itemName"}
                                  >
                                    {this.state.MostOrderedItemsSupplier.map(
                                      (entry, index) => (
                                        <Cell
                                          key={`cell-${index}`}
                                          fill={COLORS[index % COLORS.length]}
                                        />
                                      )
                                    )}
                                  </Pie>
                                  <BarTooltip
                                    formatter={(value, name, props) => [
                                      value,
                                      name,
                                    ]}
                                  />
                                </PieChart>
                              </ResponsiveContainer>
                            </>
                          )}
                        </>
                      ) : (
                        <h4 style={{ textAlign: "center" }}>
                          No Data Available...
                        </h4>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                style={{ height: "auto" }}
                className="globalContainerStyle p-3 my-3 animate__animated animate__fadeInLeft"
                noGutters
              >
                <Col xs={12} sm={12} md={3} lg={3} className="mb-3 ">
                  <h4
                    className="m-0 p-0"
                    style={{ fontWeight: "bolder", alignSelf: "center" }}
                  >
                    <i className="icofont-chart-histogram"></i> Item Cost
                    Movement
                  </h4>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={"auto"}
                  lg={"auto"}
                  className="res-mb-2"
                >
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text
                        style={{
                          backgroundColor: "var(--REB-primary)",
                          color: "var(--REB-white)",
                          fontWeight: "bold",
                          fontSize: "0.75rem",
                        }}
                      >
                        Item
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Select
                      styles={{
                        menu: (provided, state) => {
                          //console.log(state);
                          const width = "100%";

                          let color = state.selectProps.menuColor;
                          const padding = 0;

                          return { ...provided, width, color, padding };
                        },
                        control: (provided) => ({
                          ...provided,
                          borderRadius: "0px 0px 0px 0px",
                        }),

                        container: (_, { selectProps: { width } }) => ({
                          width: width,
                          flex: 1,
                        }),
                        menuPortal: (provided) => ({
                          ...provided,
                          zIndex: 9999,

                          borderRadius: "5px 0px 0px 5px",
                        }),

                        option: (provided, state) => {
                          let backgroundColor = "";
                          let display = "block";
                          let color = "black";
                          if (state.data.isDefault === 1) {
                            backgroundColor = "rgba(255, 0, 0, 0.2)";
                          }
                          const opacity = state.isDisabled ? 0.5 : 1;
                          const transition = "opacity 300ms";
                          //console.log(state.data);
                          if (state.data.isHidden === 1) {
                            display = "none";
                          }

                          return {
                            ...provided,
                            opacity,
                            transition,
                            backgroundColor,
                            color,
                            display,
                          };
                        },
                      }}
                      isSearchable
                      menuPortalTarget={document.body}
                      width="300px"
                      options={this.state.inventoryItems}
                      value={this.state.selItems.value}
                      isDisabled={this.state.selItems.readOnly}
                      getOptionLabel={(x) => x.name}
                      getOptionValue={(x) => x.id}
                      //components={{ Option: _option }}
                      //formatOptionLabel={"test"}
                      //innerRef={this.props.propRef}
                      onChange={(x, name) => {
                        //console.log(x, name);

                        this.state.handleActionChange({
                          target: {
                            value: x,
                            name: "handleInventoryItemChange",
                          },
                        });
                        // this.props.handler(x, name, {
                        //   target: { name: this.props.name },
                        // });
                      }}
                    />
                  </InputGroup>
                </Col>
                <Col xs={12}>
                  {this.state.itemCogsData.length > 0 ? (
                    <>
                      <ResponsiveContainer height={200}>
                        <AreaChart
                          data={this.state.itemCogsData}
                          margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="month" />
                          <YAxis interval="preserveEnd" />
                          <BarTooltip />
                          <Legend />
                          <Area
                            type="monotone"
                            dataKey="price"
                            stroke="#8884D8"
                            fill="#8884D8"
                          />
                          {/* <Area
                              type="monotone"
                              dataKey="IHandling"
                              stroke="#8884D8"
                              fill="#8884D8"
                            />
                            <Area
                              type="monotone"
                              dataKey="IShipping"
                              stroke="#B9B5FF"
                              fill="#B9B5FF"
                            />
                            <Area
                              type="monotone"
                              dataKey="qtyDelivered"
                              stroke="#4B488C"
                              fill="#4B488C"
                            /> */}
                        </AreaChart>
                      </ResponsiveContainer>
                      <ResponsiveContainer height={200}>
                        <AreaChart
                          data={this.state.itemCogsData}
                          margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="month" />
                          <YAxis interval="preserveEnd" />
                          <BarTooltip />
                          <Legend />
                          {/* <Area
                              type="monotone"
                              dataKey="price"
                              stroke="#8884D8"
                              fill="#8884D8"
                            /> */}
                          <Area
                            type="monotone"
                            dataKey="IHandling"
                            stroke="#8884D8"
                            fill="#8884D8"
                            name="Individual Handling"
                          />
                          <Area
                            type="monotone"
                            dataKey="IShipping"
                            stroke="#B9B5FF"
                            fill="#B9B5FF"
                            name="Individual Shipping"
                          />
                          <Area
                            type="monotone"
                            dataKey="qtyDelivered"
                            stroke="#4B488C"
                            fill="#4B488C"
                            name="Qty Delivered"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </>
                  ) : (
                    <>
                      <h4 style={{ textAlign: "center" }}>
                        No Data Available...
                      </h4>
                    </>
                  )}
                </Col>
              </Row>

              {/* <Row className="mt-3" noGutters>
                {!this.state.getInventoryStatsLoading && (
                  <Col
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    className="res-pr-2 animate__animated animate__fadeInLeft"
                  >
                    <Row
                      style={{ height: "50vh" }}
                      className="globalContainerStyle p-3 res-mb-3"
                      noGutters
                    >
                      <Col xs={12} className="mb-3">
                        <h4
                          className="m-0 p-0"
                          style={{ fontWeight: "bolder", alignSelf: "center" }}
                        >
                          <i className="icofont-fork-lift"></i> Inventory
                          Statistics (All Time)
                        </h4>
                      </Col>
                      <Col xs={12}>
                        <Row>
                          <Col>
                            <Card.Text style={cardInvoiceTitle}>
                              In Stock
                            </Card.Text>
                          </Col>
                          <Col>
                            <NumericFormat
                              style={cardInvoiceValues}
                              value={
                                this.state.Invoices !== null
                                  ? this.state.InventoryStats.inStockItems
                                      .length > 0
                                    ? this.state.InventoryStats.inStockItems[1]
                                    : "-"
                                  : "-"
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Card.Text style={cardInvoiceTitle}>
                              Out of Stock
                            </Card.Text>
                          </Col>
                          <Col>
                            <NumericFormat
                              style={cardInvoiceValues}
                              value={
                                this.state.Invoices !== null
                                  ? this.state.InventoryStats.outOfStockItems
                                      .length > 0
                                    ? this.state.InventoryStats
                                        .outOfStockItems[1]
                                    : "-"
                                  : "-"
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Card.Text style={cardInvoiceTitle}>
                              Negative
                            </Card.Text>
                          </Col>
                          <Col>
                            <NumericFormat
                              style={cardInvoiceValues}
                              value={
                                this.state.Invoices !== null
                                  ? this.state.InventoryStats.negativeItems
                                      .length > 0
                                    ? this.state.InventoryStats.negativeItems[1]
                                    : "-"
                                  : "-"
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Card.Text style={cardInvoiceTitle}>
                              W/ Display
                            </Card.Text>
                          </Col>
                          <Col>
                            <NumericFormat
                              style={cardInvoiceValues}
                              value={
                                this.state.Invoices !== null
                                  ? this.state.InventoryStats.hasDisplayItem
                                      .length > 0
                                    ? this.state.InventoryStats
                                        .hasDisplayItem[1]
                                    : "-"
                                  : "-"
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Card.Text style={cardInvoiceTitle}>
                              Total Items
                            </Card.Text>
                          </Col>
                          <Col>
                            <NumericFormat
                              style={cardInvoiceValues}
                              value={
                                this.state.Invoices !== null
                                  ? this.state.InventoryStats.items.length > 0
                                    ? this.state.InventoryStats.items[1]
                                    : "-"
                                  : "-"
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Card.Text style={cardInvoiceTitle}>
                              Total SRP
                            </Card.Text>
                          </Col>
                          <Col>
                            <h6
                              style={{
                                fontWeight: "bold",
                                color: "var(--REB-blue)",
                              }}
                            >
                              ₱{" "}
                              <NumericFormat
                                style={
                                  {
                                    // color: "var(--REB-blue)",
                                    // textAlign: "start",
                                    // paddingLeft: "1em",
                                    // fontSize: "0.8em",
                                    // fontWeight: "bold",
                                  }
                                }
                                value={
                                  this.state.Invoices !== null
                                    ? Math.abs(
                                        Number(
                                          this.state.InventoryStats.SRP
                                        ).toFixed(2)
                                      )
                                    : "-"
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Card.Text style={cardInvoiceTitle}>
                              Total COGS
                            </Card.Text>
                          </Col>
                          <Col>
                            <h6
                              style={{
                                fontWeight: "bold",
                                color: "var(--REB-blue)",
                              }}
                            >
                              ₱{" "}
                              <NumericFormat
                                style={
                                  {
                                    // color: "var(--REB-blue)",
                                    // textAlign: "start",
                                    // paddingLeft: "1em",
                                    // fontSize: "0.8em",
                                    // fontWeight: "bold",
                                  }
                                }
                                value={
                                  this.state.Invoices !== null
                                    ? Math.abs(
                                        Number(
                                          this.state.InventoryStats.COGS
                                        ).toFixed(2)
                                      )
                                    : "-"
                                }
                                decimalScale={2}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </h6>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
                {!this.state.getInvoicesBreakdownLoading && (
                  <Col
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    className="res-pl-2 animate__animated animate__fadeInLeft"
                  >
                    <Row
                      style={{ height: "50vh" }}
                      className="globalContainerStyle p-3 res-mb-3"
                      noGutters
                    >
                      <Col xs={12} className="mb-3">
                        <h4
                          className="m-0 p-0"
                          style={{ fontWeight: "bolder", alignSelf: "center" }}
                        >
                          <i className="icofont-files-stack"></i> Invoices Count
                          (All Time)
                        </h4>
                      </Col>
                      <Col xs={12}>
                        <Row>
                          <Col>
                            <Card.Text style={cardInvoiceTitle}>
                              Quote
                            </Card.Text>
                          </Col>
                          <Col>
                            <NumericFormat
                              style={cardInvoiceValues}
                              value={
                                this.state.Invoices !== null
                                  ? this.state.Invoices.QUOTE.length
                                  : "-"
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Card.Text style={cardInvoiceTitle}>DR</Card.Text>
                          </Col>
                          <Col>
                            <NumericFormat
                              style={cardInvoiceValues}
                              value={
                                this.state.Invoices !== null
                                  ? this.state.Invoices.DR.length
                                  : "-"
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Card.Text style={cardInvoiceTitle}>PR</Card.Text>
                          </Col>
                          <Col>
                            <NumericFormat
                              style={cardInvoiceValues}
                              value={
                                this.state.Invoices !== null
                                  ? this.state.Invoices.PR.length
                                  : "-"
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Card.Text style={cardInvoiceTitle}>PO</Card.Text>
                          </Col>
                          <Col>
                            <NumericFormat
                              style={cardInvoiceValues}
                              value={
                                this.state.Invoices !== null
                                  ? this.state.Invoices.PO.length
                                  : "-"
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Card.Text style={cardInvoiceTitle}>
                              Transfer
                            </Card.Text>
                          </Col>
                          <Col>
                            <NumericFormat
                              style={cardInvoiceValues}
                              value={
                                this.state.Invoices !== null
                                  ? this.state.Invoices.TRANSFER.length
                                  : "-"
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row> */}
            </>
          )}
        </>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return { user: state.apiData.userData, userType: state.apiData.userType };
};

function drColumn() {
  const name = (d) => {
    let name = "-";
    if (d.itemId === null) {
      if (d.serviceName !== null) name = JSON.parse(d.serviceName).label;
    } else {
      if (d.item.details.length > 0) {
        const sDat = d.item.details.find((x) => x.type === 1);
        sDat !== undefined ? (name = sDat.name) : (name = "-");
      }
    }

    return name;
  };
  const brand = (d) => {
    let name = "-";
    if (d.itemId === null) {
      name = "-";
    } else {
      if (d.item.details.length > 0) {
        const sDat = d.item.details.find((x) => x.type === 3);
        sDat !== undefined ? (name = sDat.name) : (name = "-");
      }
    }

    return name;
  };
  const qty = (d) => {
    let name = "-";
    if (d.itemId === null) {
      name = "Service";
    } else {
      if (d.item.details.length > 0) {
        const sDat = d.item.details.find((x) => x.type === 2);
        sDat !== undefined ? (name = sDat.name) : (name = "-");
      }
    }

    return `${d.qtyDelivered} ${name}`;
  };

  return [
    {
      Header: "Image",
      id: "img",
      accessor: (d) => name(d),
      Cell: (row) => {
        const d = row.original;
       const item = d.item
        return (
          <>
            <Row noGutters>
              <Col className="">
                
                {((x) => {
                  if (true) {
                    return (
                      <Row noGutters>
                        <Col
                          style={{
                            textAlign: "-webkit-center",
                          }}
                        >
                          {/* <img
                            style={{
                              objectFit: "contain",
                              maxWidth: "100px",
                              height: "100px",
                              maxHeight: "100px",
                            }}
                            alt=""
                            src={
                              typeof d.dispImg !== "undefined" &&
                              d.dispImg !== null
                                ? d.dispImg.original
                                : emptyImg
                            }
                          ></img> */}
                          <img
                style={{
                  objectFit: "contain",
                  maxWidth: "70px",
                  height: "70px",
                  maxHeight: "70px",
                }}
                alt=""
                src={
                  typeof item.uploads !== "undefined" &&
                  item.uploads !== null &&
                  item.uploads.length > 0 &&
                  item.uploads !== "null"
                    ? `${
                        process.env.REACT_APP_API_URL
                      }global/getFile2?uniqueId=${
                        item.uniqueId
                      }&filename=${encodeURIComponent(
                        item.uploads[0].filename
                      )}&token=${localStorage.getItem("LUT")}`
                    : emptyImg
                }
              />
                        </Col>
                      </Row>
                    );
                  } else {
                    return <></>;
                  }
                })()}
              </Col>
            </Row>
          </>
        );
      },
    },

    {
      Header: "Name",
      id: "name",
      accessor: (d) => name(d),
      getProps: (row) => {
        return { style: { padding: 0, height: 110 } };
      },
      Cell: (row) => {
        const d = row.original;
        return (
          <FormControl
            as="textarea"
            style={{ resize: "none", height: "100%" }}
            defaultValue={name(d)}
            readOnly
          />
        );
      },
    },
    {
      Header: "Brand",
      id: "brand",
      accessor: (d) => brand(d),
    },
    {
      Header: "QTY",
      id: "itemQty",
      accessor: (d) => qty(d),
      Cell: (row) => {
        const d = row.original;

        return (
          <Row
            style={{
              // backgroundColor: warn,
              height: "100%",
              textAlign: "center",
              alignItems: "center",
            }}
            className=""
            noGutters
          >
            <Col
              style={{
                fontWeight: "bolder",
                color: "var(--REB-blue)",
                fontSize: "2vw",
              }}
            >
              {qty(d)}
            </Col>
          </Row>
        );
      },
    },
  ];
}

function statisticsBaseColumn() {
  return [
    {
      Header: "Item Name",
      id: "name",
      Cell: (row) => {
        const d = row.original;
        let name = "-";
        if (d.details.length > 0) {
          const sDat = d.details.find((x) => x.type === 1);
          sDat !== undefined ? (name = sDat.name) : (name = "-");
        }
        return (
          <div
            style={{
              display: "block",
              wordWrap: "break-word",
              whiteSpace: "normal",
            }}
          >
            {name}
          </div>
          // <FormControl
          //   as="textarea"
          //   style={{ resize: "none", height: "100%" }}
          //   defaultValue={d.name === null ? "-" : d.name}
          //   readOnly
          // />
        );
      },
      accessor: (d) => {
        let name = "-";
        if (d.details.length > 0) {
          const sDat = d.details.find((x) => x.type === 1);
          sDat !== undefined ? (name = sDat.name) : (name = "-");
        }

        return name;
      },
    },
    {
      Header: "Sales",
      id: "sales",
      maxWidth: 100,
      //maxWidth: 200,
      accessor: (d) => (d.totalPrice === null ? "-" : d.totalPrice),
    },
    {
      Header: "Count",
      id: "count",
      maxWidth: 100,
      //maxWidth: 200,
      accessor: (d) => (d.count === null ? "-" : d.count),
    },
  ];
}
function statisticsBaseColumnSupplier() {
  return [
    {
      Header: "Item Name",
      id: "name",
      Cell: (row) => {
        const d = row.original;
        let name = "-";
        if (d.details.length > 0) {
          const sDat = d.details.find((x) => x.type === 1);
          sDat !== undefined ? (name = sDat.name) : (name = "-");
        }
        return (
          <div
            style={{
              display: "block",
              wordWrap: "break-word",
              whiteSpace: "normal",
            }}
          >
            {name}
          </div>
          // <FormControl
          //   as="textarea"
          //   style={{ resize: "none", height: "100%" }}
          //   defaultValue={d.name === null ? "-" : d.name}
          //   readOnly
          // />
        );
      },
      accessor: (d) => {
        let name = "-";
        if (d.details.length > 0) {
          const sDat = d.details.find((x) => x.type === 1);
          sDat !== undefined ? (name = sDat.name) : (name = "-");
        }

        return name;
      },
    },
    {
      Header: "Cost",
      id: "cost",
      maxWidth: 100,
      //maxWidth: 200,
      accessor: (d) => (d.totalPrice === null ? "-" : d.totalPrice),
    },
    {
      Header: "Count",
      id: "count",
      maxWidth: 100,
      //maxWidth: 200,
      accessor: (d) => (d.count === null ? "-" : d.count),
    },
  ];
}

function invoiceColumn(InvoiceTypes, InvoiceStatus, DateToday) {
  return [
    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          ID
        </p>
      ),
      id: "uniqueId",
      maxWidth: 110,
      // show: false,
      filterable: false,
      getProps: (row) => {
        return { style: { fontSize: "10px" } };
      },
      accessor: (d) => (d.uniqueId === null ? "-" : d.uniqueId),
    },

    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Invoice Name
        </p>
      ),
      id: "name",
      filterable: false,
      //maxWidth: 200,
      accessor: (d) => (d.name === null ? "-" : d.name),
      getProps: (row) => {
        return { style: {} };
      },
      Cell: (row) => {
        const d = row.original;
        return (
          <div
            style={{
              display: "block",
              wordWrap: "break-word",
              whiteSpace: "normal",
              textTransform: "uppercase",
            }}
          >
            {d.name}
          </div>
          // <FormControl
          //   as="textarea"
          //   style={{ resize: "none", height: "100%" }}
          //   defaultValue={d.name === null ? "-" : d.name}
          //   readOnly
          // />
        );
      },
    },

    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Transaction / Customer
        </p>
      ),
      id: "transaction",
      filterable: false,
      getProps: (row) => {
        return { style: {} };
      },
      Cell: (row) => {
        const d = row.original;
        let name = "-";
        if (d.client !== null) {
          name = d.client.company === null ? "-" : d.client.company;
        }
        if (d.supplier !== null) {
          name = d.supplier.company === null ? "-" : d.supplier.company;
        }
        return (
          <div
            style={{
              display: "block",
              wordWrap: "break-word",
              whiteSpace: "normal",
            }}
          >
            {name}
          </div>
        );
      },
      accessor: (d) => {
        if (d.client !== null) {
          return d.client.company === null ? "-" : d.client.company;
        }
        if (d.supplier !== null) {
          return d.supplier.company === null ? "-" : d.supplier.company;
        }
      },
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Created
        </p>
      ),
      id: "created",
      filterable: false,
      maxWidth: 100,
      getProps: (row) => {
        return {
          style: {
            fontSize: "13px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        };
      },
      Cell: (d) => {
        const data = d.original;
        const name =
          data.dateAdded === null
            ? "-"
            : misc.parseAPIDateTimeToShortDate(
                misc.parseAPIDateTime(data.dateAdded)
              );
        return (
          <div
            style={{
              display: "block",
              wordWrap: "break-word",
              whiteSpace: "normal",
            }}
          >
            {name}
          </div>
        );
      },
      accessor: (d) => d.dateAdded,
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Deadline
        </p>
      ),
      id: "deadline",
      filterable: false,
      maxWidth: 100,

      Cell: (row) => {
        let name = "-";
        const d = row.original;
        let daysLeft = 0;

        let warn = "initial";

        if (
          d.invoiceStatus.name !== "Finished" ||
          d.invoiceStatus.name !== "Archived"
        ) {
          if (d.deadline && DateToday) {
            daysLeft = moment(d.deadline).diff(moment(DateToday), "days");
            if (daysLeft <= 2) warn = "var(--REB-yellow)";
            if (daysLeft <= 1) warn = "var(--REB-warning)";
          }
        } else {
          if (d.dateFinished && DateToday) {
            daysLeft = moment(d.deadline).diff(moment(d.dateFinished), "days");
            if (daysLeft <= 2) warn = "var(--REB-yellow)";
            if (daysLeft <= 1) warn = "var(--REB-warning)";
          }
        }
        name =
          d.deadline === null
            ? "-"
            : misc.parseAPIDateTimeToShortDate(
                misc.parseAPIDateTime(d.deadline)
              );

        return (
          <Row
            style={{
              color: warn,
              height: "100%",
              textAlign: "center",
              alignItems: "center",
            }}
            className=""
            noGutters
          >
            <Col style={{ fontWeight: "bolder" }}>{`${name}`}</Col>
          </Row>
        );
      },
      getProps: (row) => {
        return {
          style: {
            fontSize: "13px",
            height: "100%",
            padding: 0,

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        };
      },
      accessor: (d) =>
        d.deadline === null
          ? "-"
          : misc.parseAPIDateTimeToShortDate(misc.parseAPIDateTime(d.deadline)),
    },

    // {
    //   Header: "Status",
    //   id: "status",
    //   accessor: (d) => (d.invoiceStatus === null ? "-" : d.invoiceStatus.name),
    //   filterMethod: (filter, row) => {
    //     console.log("trigger");
    //     if (filter.value === "all") {
    //       return true;
    //     } else {
    //       //console.log(row[filter.id]);
    //       return row[filter.id] === filter.value;
    //     }
    //     // if (filter.value === "true") {
    //     //   return row[filter.id] >= 21;
    //     // }
    //     // return row[filter.id] < 21;
    //   },
    //   Filter: ({ filter, onChange }) => (
    //     <select
    //       onChange={(event) => onChange(event.target.value)}
    //       style={{ width: "100%" }}
    //       value={filter ? filter.value : "Ongoing Delivery"}
    //     >
    //       <option value="Ongoing Delivery">Ongoing Delivery</option>
    //       <option value="all">All</option>
    //       <option value="For Delivery">For Delivery</option>

    //       <option value="Pending Admin Approval">Pending Admin Approval</option>
    //       <option value="Finished">Finished</option>

    //       <option value="Admin Denied">Admin Denied</option>
    //     </select>
    //   ),
    // },
  ];
}
export default connect(mapStateToProps)(withRouter(DashboardProcess));
