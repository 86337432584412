import React from "react";
//import { NumericFormat } from 'react-number-format';
import pdfTemplate from "../../../components/PdfHeadFoot";
//import TESTIMG from "../../../assets/executiveSig.png";
import misc from "../../../functions/misc";
import { Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer";

import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";
import moment from "moment";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf",
      fontWeight: 800,
    },
  ],
});
let themeColor = "";
if (process.env.REACT_APP_THEME === "SURFACEPLUS") themeColor = "#5eaaff";
if (process.env.REACT_APP_THEME === "DEMO") themeColor = "#ed5309";
const tableStyles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  tableHeader: {
    textAlign: "left",
    fontSize: 9,
    marginLeft: 2,
    marginRight: 2,
    marginBottom: 10,
    color: "#5eaaff",
    fontFamily: "Open Sans",
    fontWeight: 800,
    border: 0,
  },
  tableCell: {
    border: 0,
    textAlign: "left",
    // marginLeft: 2,
    marginRight: 2,
  },
  tableCellNames: {
    fontSize: 8,
    border: 0,
    marginTop: 5,
    marginBottom: 5,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    border: 0,
  },
});

class SalesReport {
  _itemTable(data, showImage, props) {
    const userType = props.userType;
    let showCogs = false;
    userType.includes("Admin") ||
    userType.includes("Executive") ||
    userType.includes("Purchaser")
      ? (showCogs = true)
      : (showCogs = false);
    //console.log(data);
    return (
      <Table data={data}>
        <TableHeader>
          <TableCell weighting={0.03} style={[tableStyles.tableHeader]}>
            #
          </TableCell>

          <TableCell weighting={0.45} style={[tableStyles.tableHeader]}>
            ITEM DESCRIPTION
          </TableCell>
          <TableCell weighting={0.1} style={[tableStyles.tableHeader]}>
            QTY SOLD
          </TableCell>

          <TableCell weighting={0.08} style={[tableStyles.tableHeader]}>
            SRP
          </TableCell>
          <TableCell weighting={0.08} style={[tableStyles.tableHeader]}>
            TOTAL
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={[tableStyles.tableCell, { fontSize: 8 }]}
            weighting={0.03}
            getContent={(d) => (d.index === null ? "" : d.index)}
          />

          <DataTableCell
            style={[
              tableStyles.tableCell,
              tableStyles.tableCellNames,
              { fontSize: 8 },
            ]}
            weighting={0.5}
            getContent={(d) => {
              //console.log(d);
              let name = "-";
              if (d.details.length > 0) {
                const sDat = d.details.find((x) => x.type === 1);
                sDat !== undefined ? (name = sDat.name) : (name = "-");
              }

              return (
                <View style={{ flexDirection: "row" }}>
                  <Text style={[{ marginLeft: 5 }]}>{name}</Text>
                </View>
              );
            }}
          />
          <DataTableCell
            style={[
              tableStyles.tableCell,
              tableStyles.tableCellNames,
              { fontSize: 8 },
            ]}
            weighting={0.06}
            getContent={(d) => {
              let name = "-";

              let qty = d.count;
              // let order = 0;

              if (qty <= 0) {
                //order = Math.abs(qty);
                qty = 0;
              }

              if (d.details.length > 0) {
                const sDat = d.details.find((x) => x.type === 2);
                sDat !== undefined ? (name = sDat.name) : (name = "-");
              }

              return `${qty} \n${name}`;

              // return `stock: ${qty} ${name} \n display:${d.display} ${name} \n order:${order} ${name}`;
            }}
          />

          <DataTableCell
            style={[
              tableStyles.tableCell,
              tableStyles.tableCellNames,
              { fontSize: 7 },
            ]}
            weighting={0.08}
            getContent={(d) => {
              return (
                <View style={{ flexDirection: "row" }}>
                  <Text style={[{ marginLeft: 1 }]}>
                    {/* <NumberFormat
                      value={d.price}
                      displayType={"text"}
                      thousandSeparator={true}
                    /> */}
                    {misc.parseCurrencyNoCurrency(d.price)}
                  </Text>
                </View>
              );
              // return `${d.price} `;
            }}
          />

          <DataTableCell
            style={[
              tableStyles.tableCell,
              tableStyles.tableCellNames,
              { fontSize: 7 },
            ]}
            weighting={0.08}
            getContent={(d) => {
              return (
                <View style={{ flexDirection: "row" }}>
                  {/* <NumberFormat
                    value={d.totalPrice}
                    displayType={"text"}
                    thousandSeparator={true}
                  /> */}
                  {/* {misc.parseCurrencyNoCurrency(
                     d.totalPrice
                    )} */}
                  <Text style={[{ marginLeft: 1 }]}>
                    {/* {d.totalPrice} */}
                    {/* <NumberFormat
                      value={d.totalPrice}
                      displayType={"text"}
                      thousandSeparator={true}
                    /> */}
                    {misc.parseCurrencyNoCurrency(d.totalPrice)}
                  </Text>
                </View>
              );
              // return `${d.price} `;
            }}
          />
        </TableBody>
      </Table>
    );
  }

  _termsSignature(incharge) {
    return (
      <>
        <View>
          <View
            style={[
              {
                marginTop: 5,

                paddingLeft: 80,
                paddingRight: 80,
              },
            ]}
          >
            <View
              style={[
                {
                  marginTop: 10,
                  flex: 1,
                  flexDirection: "row",
                },
              ]}
            >
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      color: themeColor,
                      fontFamily: "Open Sans",
                      fontWeight: 800,
                    },
                  ]}
                >
                  Generated by:
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View>
          <View
            style={[
              {
                marginTop: 5,
                marginBottom: 25,
                paddingLeft: 80,
                paddingRight: 80,
              },
            ]}
          >
            <View
              style={[
                {
                  marginTop: 10,
                  flex: 1,
                  flexDirection: "row",
                },
              ]}
            >
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      textAlign: "center",
                    },
                  ]}
                >
                  {incharge !== null && incharge !== undefined
                    ? incharge.name
                    : "-"}
                </Text>
                {(() => {
                  //console.log(incharge);
                  if (incharge !== null && incharge !== undefined) {
                    if (incharge.uploads) {
                      if (Number(incharge.uploads.length) > 0) {
                        return (
                          <Image
                            style={[
                              {
                                position: "absolute",
                                width: 150,
                                marginTop: -25,
                              },
                            ]}
                            src={{
                              uri: `${process.env.REACT_APP_API_URL}global/getFile?uniqueId=${incharge.uniqueId}&filename=${incharge.uploads[0].filename}`,
                              method: "GET",

                              headers: {
                                Authorization:
                                  "Bearer " + localStorage.getItem("LUT"),
                              },
                              body: "",
                            }}
                          />
                        );
                      }
                    }
                  }
                })()}
              </View>
            </View>
          </View>
        </View>
      </>
    );
  }

  SalesReport(data, incharge, showImage, date) {
    //console.log(props.pdfGroupTable[0]);

    let _content = [];

    _content.push(
      <>
        <View
          style={[
            {
              marginTop: 5,
              width: "100%",
              textAlign: "center",
              color: themeColor,
            },
          ]}
        >
          <Text
            style={[
              {
                fontSize: 12,
                fontFamily: "Open Sans",
                fontWeight: 800,
                marginTop: 10,
                marginBottom: 5,
                //paddingLeft: 50,
              },
            ]}
          >
            DAILY REPORT FOR DATE: {moment(date).format("ddd, LL")}
          </Text>
        </View>
        <View
          style={[
            {
              // flex: 1,
              flexDirection: "row",
              paddingLeft: 40,
              paddingRight: 40,
              marginTop: 5,
            },
          ]}
        >
          <View style={[{ flex: 1 }]}>
            <Text
              style={[
                {
                  textAlign: "left",
                  color: themeColor,
                  fontSize: 10,
                  fontWeight: "bold",
                },
              ]}
            >
              SALES TODAY
            </Text>
            <Text
              style={[
                {
                  textAlign: "left",
                  fontWeight: 800,
                  fontSize: 14,
                  marginTop: 10,
                },
              ]}
            >
              P {misc.parseCurrencyNoCurrency(data.totalSales)}
              {/* <NumberFormat
                prefix={"P "}
                value={data.totalSales}
                displayType={"text"}
                thousandSeparator={true}
              /> */}
            </Text>
          </View>
          <View style={[{ flex: 1 }]}>
            <Text
              style={[
                {
                  textAlign: "left",
                  color: themeColor,
                  fontSize: 10,
                  fontWeight: "bold",
                },
              ]}
            >
              QTY SOLD
            </Text>
            <Text
              style={[
                {
                  textAlign: "left",
                  fontWeight: 800,
                  fontSize: 14,
                  marginTop: 10,
                },
              ]}
            >
              {misc.parseCurrencyNoCurrency(data.qtySold)}
              {/* <NumberFormat
                value={data.qtySold}
                displayType={"text"}
                thousandSeparator={true}
              /> */}
            </Text>
          </View>
          <View style={[{ flex: 1 }]}>
            <Text
              style={[
                {
                  textAlign: "left",
                  color: themeColor,
                  fontSize: 10,
                  fontWeight: "bold",
                },
              ]}
            >
              TOTAL TRANSACTIONS
            </Text>
            <Text
              style={[
                {
                  textAlign: "left",
                  fontWeight: 800,
                  fontSize: 14,
                  marginTop: 10,
                },
              ]}
            >
              {/* <NumberFormat
                value={data.ordersLength}
                displayType={"text"}
                thousandSeparator={true}
              /> */}
              {misc.parseCurrencyNoCurrency(data.ordersLength)}
            </Text>
          </View>
        </View>
        <View
          style={[
            {
              marginTop: 5,
              width: "100%",
              textAlign: "center",
              color: themeColor,
            },
          ]}
        >
          <Text
            style={[
              {
                fontSize: 12,
                fontFamily: "Open Sans",
                fontWeight: 800,
                marginTop: 10,
                marginBottom: 5,
                //paddingLeft: 50,
              },
            ]}
          >
            ITEMS SOLD TODAY
          </Text>
        </View>
        <View
          style={[
            {
              //height: 325,
              paddingLeft: 20,
              paddingRight: 20,
            },
          ]}
        >
          {((x) => {
            let _e = [];
            if (data.orders.length > 0) {
              _e = data.orders[0];
            }
            //console.log(props.pdfGroupTable);
            if (_e.length < 8) {
              return (
                <>
                  <View
                    style={[
                      {
                        paddingLeft: 20,
                        paddingRight: 20,
                      },
                    ]}
                  >
                    {this._itemTable(_e, showImage, data)}
                  </View>

                  {this._termsSignature(incharge)}
                </>
              );
            } else {
              return <>{this._itemTable(_e, showImage, data)}</>;
            }
          })()}
        </View>
      </>
    );

    for (let i = 1; i < data.orders.length; i++) {
      const _e = data.orders[i];

      if (_e.length > 6) {
        _content.push(
          <View
            style={[
              {
                paddingLeft: 20,
                paddingRight: 20,
              },
            ]}
          >
            {this._itemTable(_e, showImage, data)}
          </View>
        );
      } else {
        _content.push(<>{this._termsSignature(incharge)}</>);
      }
    }

    return pdfTemplate.TemplateHeaderFooter(_content, "Sales Report", "-");
  }
}
export default new SalesReport();
// let QuotePDF = (props) => (

// );
//export { QuotePDF };
