import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
//import FormControl from "react-bootstrap/FormControl";
//import InputGroup from "react-bootstrap/InputGroup";
//import moment from "moment";
import { VerificationModal } from "./VerificationModal";
import Badge from "react-bootstrap/Badge";
import FormControl from "react-bootstrap/FormControl";
import { NumericFormat } from "react-number-format";
import emptyImg from "../../assets/fork-lift.svg";
import moment from "moment";
import Button from "react-bootstrap/Button";
import { SELECTED_TABLE_TABS } from "../../constants";
function verificationColumn(InvoiceTypes, InvoiceStatus) {
  return [
    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Name
        </p>
      ),
      id: "name",
      //maxWidth: 200,
      minWidth: 150,
      Cell: (row) => {
        const d = row.original;
        let _name = "";
        if (d.verificationType === 0) {
          _name = d.name === null ? "-" : d.name;
        } else {
          if (d.item) {
            const _itemName = d.item.details.find((x) => x.type === 1);
            if (d.orderType.name === "Item Order Request") {
              _name = `${d.itemQty} ${d.itemUnit.name} Order request for ${_itemName.name}`;
            } else {
              _name = `${d.itemQty} ${d.itemUnit.name} Requisition for ${_itemName.name}`;
            }
          }
          //For Transaction Invoices
          if (
            d.generalTransType === "journal" ||
            d.generalTransType === "transaction"
          ) {
            _name = d.description;
          }
          if (d.courierId) {
            _name = `Logistics Invoice from ${d.supplierVendor?.company}`;
          }
          if (d.verificationType === "INVOICE_ARCHIVE") {
            _name = `Archiving Verification Request By ${d.lastModifiedByActor.lastName}`;
          }
        }
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              wordWrap: "break-word",
              whiteSpace: "normal",
              textTransform: "uppercase",
              fontSize: "0.8rem",
            }}
          >
            {_name}
            <Badge
              className="mb-2"
              style={{
                backgroundColor: "var(--REB-secondary)",
                color: "var(--REB-white)",
                fontSize: "0.75em",
                width: "115px",
              }}
            >
              {d.uniqueId}
            </Badge>
          </div>
          // <FormControl
          //   as="textarea"
          //   style={{ resize: "none", height: "100%" }}
          //   defaultValue={d.name === null ? "-" : d.name}
          //   readOnly
          // />
        );
      },
      accessor: (d) => {
        if (d.verificationType === 0) {
          return d.name === null ? "-" : d.name;
        } else {
          if (d.item) {
            const _itemName = d.item.details.find((x) => x.type === 1);
            if (d.orderType.name === "Item Order Request") {
              return `${d.itemQty} ${d.itemUnit.name} Order request for ${_itemName.name}`;
            } else {
              return `${d.itemQty} ${d.itemUnit.name} Requisition for ${_itemName.name}`;
            }
          }
        }
      },
    },

    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Transaction
        </p>
      ),
      id: "transaction",
      accessor: (d) => {
        if (d.verificationType === 0) {
          if (d.client !== null) {
            return d.client.company === null ? "-" : d.client.company;
          }

          if (d.supplier !== null) {
            return d.supplier.company === null
              ? "Please provide supplier"
              : d.supplier.company;
          }
        } else {
          return "In-House";
        }
      },
      Cell: (row) => {
        const d = row.original;
        let _name = "";
        if (d.verificationType === 0) {
          if (d.client !== null) {
            _name = d.client.company === null ? "-" : d.client.company;
          }
          if (d.supplier !== null) {
            _name =
              d.supplierId === null
                ? "Please provide supplier"
                : d.supplier.company;
          }
        } else {
          _name = "In-House";
          if (d.generalTransType === "transaction") {
            _name = d.supplierVendor.company;
          }
          if (d.generalTransType === "journal") {
            _name = d.order?.supplier?.company;
          }
          if (d.courierId) {
            _name = d.supplierVendor?.company;
          }
        }
        if (d.invoiceType?.name === "Purchase Request") {
          _name =
            d.supplierId === null
              ? "Please provide supplier"
              : d.supplier.company;
        }
        if (d.invoiceType?.name === "Transfer Inventory") {
          _name = `Item Transfer From ${
            d.inventoryFrom ? d.inventoryFrom?.name : "-"
          } To ${d.inventoryTo ? d.inventoryTo?.name : "-"}`;
        }
        return (
          <div
            style={{
              display: "block",
              wordWrap: "break-word",
              whiteSpace: "normal",
              textTransform: "uppercase",
              fontSize: "0.8rem",
            }}
          >
            {_name}
          </div>
          // <FormControl
          //   as="textarea"
          //   style={{ resize: "none", height: "100%" }}
          //   defaultValue={d.name === null ? "-" : d.name}
          //   readOnly
          // />
        );
      },
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Date Posted
        </p>
      ),
      id: "transDate",
      //maxWidth: 200,
      sortType: (a, b) => {
        //console.log(b.datePosted)
        const AdispDate = a.dateEdited ? a.dateEdited : a.dateLastModified;
        const BdispDate = b.dateEdited ? b.dateEdited : b.dateLastModified;
        return new Date(BdispDate) - new Date(AdispDate);
      },
      Cell: (row) => {
        const d = row.original;

        let holdDays = null;
        if (d.invoiceVerificationHold) {
          holdDays = moment(d.invoiceVerificationHold).diff(moment(), "days");
          // if (holdDays < 0) holdDays = 0;
        }
        //let _name = "";

        return (
          <div
            style={{
              display: "block",
              wordWrap: "break-word",
              whiteSpace: "normal",
              textTransform: "uppercase",
              fontSize: "0.8rem",
            }}
          >
            {d.dateEdited
              ? d.dateEdited
                ? moment(d.dateEdited).format("lll")
                : ""
              : d.dateLastModified
              ? moment(d.dateLastModified).format("lll")
              : ""}
            {holdDays !== null && holdDays <= 3 && holdDays > 1 && (
              <div>
                <div>
                  <h5
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "var(--REB-warning)",
                    }}
                  >
                    Verification Notice
                  </h5>
                </div>
                <p>
                  {holdDays} {holdDays > 1 ? "Days" : "Day"} left
                </p>
              </div>
            )}
            {holdDays !== null && holdDays <= 1 && (
              <div>
                <div
                  style={{ backgroundColor: "var(--REB-warning)" }}
                  className="py-2"
                >
                  <h5
                    className="animate__pulse animate__animated animate__infinite	infinite"
                    style={{
                      fontWeight: "bolder",
                      textAlign: "center",
                      color: "var(--REB-white)",
                    }}
                  >
                    THIS INVOICE NEEDS YOUR ATTENTION ASAP
                  </h5>
                </div>
                <p style={{ fontWeight: "bolder" }}>
                  {holdDays} {holdDays > 1 ? "Days" : "Day"} left
                </p>
              </div>
            )}
            {/* <div>
                {d.invoicePriority}
              </div> */}
          </div>
          // <FormControl
          //   as="textarea"
          //   style={{ resize: "none", height: "100%" }}
          //   defaultValue={d.name === null ? "-" : d.name}
          //   readOnly
          // />
        );
      },
      accessor: (d) => {
        return d.dateLastModified ? d.dateLastModified : d.dateEdited;
      },
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Type
        </p>
      ),
      id: "type",

      filterable: false,
      Cell: (row) => {
        const d = row.original;
        let name = "-";
        // let dateFinished = "";
        let infoColor = "";
        let icon = <></>;

        let voucherStatus = "income";
        let voucherInfoColor = "";
        let voucherIcon = <></>;

        //console.log(d);
        if (d.verificationType === 0 && d.invoiceType) {
          // console.log(d.orderType);
          name = d.invoiceType === null ? "-" : d.invoiceType.name;
        } else {
          //name = d.orderType === null ? "-" : d.orderType.name;
          if ((!d.orderId && !d.isEntry) || d.order?.supplierId || (!d?.order?.supplierId && !d?.order?.clientId)) {
            voucherStatus = "expense";
          }

          if (voucherStatus === "income") {
            voucherInfoColor = "var(--REB-green)";
            voucherIcon = (
              <i className="icofont-money " style={{ fontSize: "1.3em" }}></i>
            );
          }
          if (voucherStatus === "expense") {
            voucherInfoColor = "var(--REB-warning)";
            voucherIcon = (
              <i
                className="icofont-minus-circle "
                style={{ fontSize: "1.3em" }}
              ></i>
            );
          }
          if (d.generalTransType === "transaction") {
            name = "Voucher";
          }
          if (d.generalTransType === "journal") {
            name = "Voucher";
          }
          if (d.courierId) {
            name = "Logistics";
          }
          if (d.verificationType === "INVOICE_ARCHIVE") {
            name = "Archiving";
          }
        }

        switch (name) {
          case "Archiving":
            infoColor = "var(--REB-yellow)";
            icon = (
              <i className="icofont-ui-file" style={{ fontSize: "1.3em" }}></i>
            );
            break;
          case "Purchase Request":
            infoColor = "#157EBF";
            icon = (
              <i className="icofont-package" style={{ fontSize: "1.3em" }}></i>
            );
            break;
          case "Voucher":
            infoColor = "#157EBF";
            icon = (
              <i className="icofont-package" style={{ fontSize: "1.3em" }}></i>
            );
            break;
          case "Quote":
            infoColor = "#038C73";
            icon = (
              <i className="icofont-paper " style={{ fontSize: "1.3em" }}></i>
            );
            break;
          case "Purchase Order":
            infoColor = "#F2ACCD";
            icon = (
              <i className="icofont-cart " style={{ fontSize: "1.3em" }}></i>
            );
            break;
          case "Transfer Inventory":
            infoColor = "#F2ACCD";
            icon = (
              <i
                className="icofont-exchange "
                style={{ fontSize: "1.3em" }}
              ></i>
            );
            break;
          case "Delivery Receipt":
            infoColor = "#BBBBEF";
            icon = (
              <i
                className="icofont-truck-loaded "
                style={{ fontSize: "1.3em" }}
              ></i>
            );
            break;
          case "Logistics":
            infoColor = "#BBBBEF";
            icon = (
              <i
                className="icofont-truck-loaded "
                style={{ fontSize: "1.3em" }}
              ></i>
            );
            break;

          default:
            infoColor = "#038C73";
            name = "Request";
            icon = (
              <i
                className="icofont-info-circle "
                style={{ fontSize: "1.3em" }}
              ></i>
            );
            break;
        }

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              wordWrap: "break-word",
              whiteSpace: "normal",
              lineHeight: "0.8em",
              fontWeight: "bolder",
              fontSize: "0.8em",
            }}
          >
            <Badge
              style={{
                backgroundColor: infoColor,
                color: "white",
                fontSize: "0.8em",
              }}
            >
              {icon} {name}
            </Badge>
            {name === "Voucher" && (
              <Badge
                className="mt-2"
                style={{
                  backgroundColor: voucherInfoColor,
                  color: "white",
                  fontSize: "0.8em",
                }}
              >
                {voucherIcon} {voucherStatus}
              </Badge>
            )}
          </div>
        );
      },
      getProps: (row) => {
        return {
          style: {
            padding: 0,

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        };
      },
      accessor: (d) => {
        if (d.verificationType === 0) {
          return d.invoiceStatus === null ? "-" : d.invoiceStatus.name;
        } else {
          if (d.orderStatus) {
            return d.orderStatus === null ? "-" : d.orderStatus.name;
          }
        }
      },
      // filterMethod: (filter, row) => {
      //   if (filter.value === "all") {
      //     return true;
      //   } else {
      //     // console.log(row[filter.id]);
      //     return row[filter.id] === filter.value;
      //   }
      //   // if (filter.value === "true") {
      //   //   return row[filter.id] >= 21;
      //   // }
      //   // return row[filter.id] < 21;
      // },
      // Filter: ({ filter, onChange }) => (
      //   <select
      //     onChange={(event) => onChange(event.target.value)}
      //     style={{ width: "100%" }}
      //     value={filter ? filter.value : "all"}
      //   >
      //     <option value="all">All</option>
      //     <option value="Quote">Quote</option>
      //     <option value="Purchase Request">Purchase Request</option>
      //     <option value="Purchase Order">Purchase Order</option>
      //     <option value="Delivery Receipt">Delivery Receipt</option>
      //   </select>
      // ),
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Priority
        </p>
      ),
      id: "transPrio",
      //maxWidth: 200,
      show: false,

      accessor: (d) => {
        let holdDays = null;
        if (d.invoiceVerificationHold) {
          holdDays = moment(d.invoiceVerificationHold).diff(moment(), "days");

          if (holdDays > 3) holdDays = undefined;
          if (holdDays <= 1) holdDays = 9999;
        }
        return holdDays;
      },
    },
    // {
    //   Header: "Status",
    //   id: "status",
    //   accessor: (d) => {
    //     if (d.verificationType === 0) {
    //       return d.invoiceStatus === null ? "-" : d.invoiceStatus.name;
    //     } else {
    //       return d.orderStatus === null ? "-" : d.orderStatus.name;
    //     }
    //   },
    // },
  ];
}
function PRItemColumn() {
  // const brand = (d) => {
  //   let name = "-";
  //   if (d.itemId === null) {
  //     name = "-";
  //   } else {
  //     if (d.item.details.length > 0) {
  //       const sDat = d.item.details.find((x) => x.type === 3);
  //       sDat !== undefined ? (name = sDat.name) : (name = "-");
  //     }
  //   }

  //   return name;
  // };

  return [
    {
      Header: "Item Details",
      id: "name",

      getProps: (row) => {
        return { style: { padding: 0 } };
      },
      Cell: (row) => {
        const d = row.original;
        let name = "-";
        let unit = "-";
        let image = null;
        let itemUniqueId = null;
        if (d.item) {
          image = d.item.uploads;
          itemUniqueId = d.item.uniqueId;
        }
        if (d.item.details.length > 0) {
          let nameDat = d.item.details.find((x) => x.type === 1);
          let brandDat = d.item.details.find((x) => x.type === 3);

          nameDat = nameDat !== null ? nameDat.name : "";
          brandDat = brandDat !== null ? brandDat.name : "";
          name = nameDat + " " + brandDat;
        }
        if (d.item.details.length > 0) {
          const sDat = d.item.details.find((x) => x.type === 2);
          sDat !== undefined ? (unit = sDat.name) : (unit = "-");
        }
        return (
          <Row className="m-2" noGutters>
            <Col
              xs={12}
              sm={12}
              md={2}
              lg={2}
              style={{
                textAlign: "-webkit-center",
              }}
            >
              <img
                style={{
                  objectFit: "contain",
                  maxWidth: "115px",
                  height: "115px",
                  maxHeight: "115px",
                }}
                alt="item-img"
                src={
                  typeof image !== "undefined" &&
                  image !== null &&
                  image.length > 0 &&
                  image !== "null"
                    ? `${
                        process.env.REACT_APP_API_URL
                      }global/getFile2?uniqueId=${itemUniqueId}&filename=${encodeURIComponent(
                        image[0].filename
                      )}&token=${localStorage.getItem("LUT")}`
                    : emptyImg
                }
              />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <div
                className="h-100"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "var(--REB-primary)",
                  borderRadius: "5px",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    color: "var(--REB-white)",
                    fontWeight: "bold",

                    // backgroundColor: "var(--REB-primary)",
                  }}
                >
                  Item Name
                </p>
                <FormControl
                  as="textarea"
                  style={{ resize: "none", height: "100%" }}
                  name={`txtPONotes${row.original.id}`}
                  defaultValue={name}
                  readOnly
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={2} lg={2}>
              <div
                className="h-100"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "var(--REB-primary)",
                  borderRadius: "5px",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    color: "var(--REB-white)",
                    fontWeight: "bold",

                    // backgroundColor: "var(--REB-primary)",
                  }}
                >
                  Request Qty
                </p>
                <Row
                  style={{
                    backgroundColor: "#e9ecef",
                    border: "1px solid #ced4da",
                    borderRadius: "0.25rem",
                    height: "100%",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                  className=""
                  noGutters
                >
                  <Col style={{ fontWeight: "bolder" }}>
                    <p
                      style={{ color: "var(--REB-black)" }}
                      className="m-0 p-0"
                    >
                      {`${d.qtyOrdered} `}
                    </p>

                    <p
                      style={{ color: "var(--REB-black)" }}
                      className="m-0 p-0"
                    >
                      {`${unit}`}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={12} sm={12} md={2} lg={2}>
              <div
                className="h-100"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: d.price
                    ? "var(--REB-primary)"
                    : "var(--REB-warning)",
                  borderRadius: "5px",
                }}
              >
                <div style={{ flex: 1 }}>
                  <p
                    className="m-0 pl-2"
                    style={{
                      color: "var(--REB-white)",
                      fontWeight: "bold",

                      // backgroundColor: "var(--REB-primary)",
                    }}
                  >
                    Supplier Price
                  </p>
                  <FormControl
                    as={NumericFormat}
                    inputMode="numeric"
                    thousandSeparator={true}
                    prefix={"₱ "}
                    style={{}}
                    placeholder="Supplier Price"
                    allowNegative={false}
                    value={d.price}
                    fixedDecimalScale
                    decimalScale={2}
                    className=""
                    disabled
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <p
                    className="m-0 pl-2"
                    style={{
                      color: "var(--REB-white)",
                      fontWeight: "bold",

                      // backgroundColor: "var(--REB-primary)",
                    }}
                  >
                    Inventory SRP Price
                  </p>
                  <FormControl
                    as={NumericFormat}
                    inputMode="numeric"
                    thousandSeparator={true}
                    prefix={"₱ "}
                    style={{}}
                    placeholder="Inventory SRP Price"
                    allowNegative={false}
                    value={row.original.item.price.toFixed(2)}
                    className=""
                    disabled
                  />
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={2} lg={2}>
              <div
                className="h-100"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "var(--REB-primary)",
                  borderRadius: "5px",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    color: "var(--REB-white)",
                    fontWeight: "bold",

                    // backgroundColor: "var(--REB-primary)",
                  }}
                >
                  Amount Due
                </p>
                <FormControl
                  as={NumericFormat}
                  inputMode="numeric"
                  thousandSeparator={true}
                  prefix={"₱ "}
                  style={{}}
                  placeholder="Supplier Price"
                  allowNegative={false}
                  value={(row.original.price * row.original.qtyOrdered).toFixed(
                    2
                  )}
                  className="h-100"
                  disabled
                />
              </div>
            </Col>
          </Row>
        );
      },
      accessor: (d) => {
        let name = "-";
        if (d.item.details.length > 0) {
          let nameDat = d.item.details.find((x) => x.type === 1);
          let brandDat = d.item.details.find((x) => x.type === 3);

          nameDat = nameDat !== null ? nameDat.name : "";
          brandDat = brandDat !== null ? brandDat.name : "";
          name = nameDat + " " + brandDat;
        }

        return name;
      },
    },
  ];
}
function Verification(props) {
  return (
    <>
      <style type="text/css">
        {`
              .btnNavTabs{
               margin-left: 4px;
               margin-right: 4px;
               border-radius: 0px;
             background: var(--REB-tertiary);
            }
            .btnNavTabsContainer{
               display: flex;
            }
            .btnNavTabsLabel{
             align-self: center;
             margin: 0px;
             padding: 0px;
             margin-right: 6px;
             font-weight: bold;
            }
            .btnNavTabsBadge{
              margin: 0px;
             padding: 0px;
             padding-right: 6px;
             padding-left: 6px;
             border-radius: 8px;
             background: var(--REB-warning);
             font-weight: bolder;
             font-size: 0.8rem;
            }
            .nav-link{
           
            }
            .stage{
              overflow-x: hidden !important;
            }
            .btnNavTabsParentContainer{
              overflow-x: scroll;
              -ms-overflow-style: none;
              scrollbar-width: none;
            }
            .btnNavTabsParentContainer::-webkit-scrollbar {
              display: none;
            }
           
          `}
      </style>

      <div style={{ display: "flex", flexFlow: "column", height: "100%" }}>
        <Row noGutters={true} className="btnNavTabsParentContainer">
          <Col md={12} lg={12} sm={12} xs={12} style={{ minWidth: "1360px" }}>
            <Button
              className="btnNavTabs"
              style={{
                backgroundColor:
                  props.state.selectedTableTab === SELECTED_TABLE_TABS.ALL &&
                  "var(--REB-secondary)",
              }}
              onClick={() => {
                props.state.handleActionChange({
                  target: {
                    value: SELECTED_TABLE_TABS.ALL,
                    name: "btnSwitchTableTabs",
                  },
                });
              }}
            >
              <div className="btnNavTabsContainer">
                <p className="btnNavTabsLabel">All</p>
                {props.state?.allTableDataLength > 0 && (
                  <p className="btnNavTabsBadge animate__pulse animate__animated animate__infinite	infinite">
                    {props.state?.verificationTableData
                      ? props.state?.allTableDataLength
                      : 0}
                  </p>
                )}
              </div>
            </Button>
            <Button
              className="btnNavTabs"
              style={{
                backgroundColor:
                  props.state.selectedTableTab === SELECTED_TABLE_TABS.QUOTE &&
                  "var(--REB-secondary)",
              }}
              onClick={() => {
                props.state.handleActionChange({
                  target: {
                    value: SELECTED_TABLE_TABS.QUOTE,
                    name: "btnSwitchTableTabs",
                  },
                });
              }}
            >
              <div className="btnNavTabsContainer">
                <p className="btnNavTabsLabel">Quotation</p>
                {props.state.verificationDataCount.Quote > 0 && (
                  <p className="btnNavTabsBadge animate__pulse animate__animated animate__infinite	infinite">
                    {props.state.verificationDataCount.Quote}
                  </p>
                )}
              </div>
            </Button>

            <Button
              className="btnNavTabs"
              style={{
                backgroundColor:
                  props.state.selectedTableTab === SELECTED_TABLE_TABS.DR &&
                  "var(--REB-secondary)",
              }}
              onClick={() => {
                props.state.handleActionChange({
                  target: {
                    value: SELECTED_TABLE_TABS.DR,
                    name: "btnSwitchTableTabs",
                  },
                });
              }}
            >
              <div className="btnNavTabsContainer">
                <p className="btnNavTabsLabel">Delivery Receipt</p>
                {props.state.verificationDataCount.DR > 0 && (
                  <p className="btnNavTabsBadge animate__pulse animate__animated animate__infinite	infinite">
                    {props.state.verificationDataCount.DR}
                  </p>
                )}
              </div>
            </Button>
            <Button
              className="btnNavTabs"
              style={{
                backgroundColor:
                  props.state.selectedTableTab === SELECTED_TABLE_TABS.CI &&
                  "var(--REB-secondary)",
              }}
              onClick={() => {
                props.state.handleActionChange({
                  target: {
                    value: SELECTED_TABLE_TABS.CI,
                    name: "btnSwitchTableTabs",
                  },
                });
              }}
            >
              <div className="btnNavTabsContainer">
                <p className="btnNavTabsLabel">Charge Invoice</p>
                {props.state.verificationDataCount.CI > 0 && (
                  <p className="btnNavTabsBadge animate__pulse animate__animated animate__infinite	infinite">
                    {props.state.verificationDataCount.CI}
                  </p>
                )}
              </div>
            </Button>
            <Button
              className="btnNavTabs"
              style={{
                backgroundColor:
                  props.state.selectedTableTab ===
                    SELECTED_TABLE_TABS.TRANSFER && "var(--REB-secondary)",
              }}
              onClick={() => {
                props.state.handleActionChange({
                  target: {
                    value: SELECTED_TABLE_TABS.TRANSFER,
                    name: "btnSwitchTableTabs",
                  },
                });
              }}
            >
              <div className="btnNavTabsContainer">
                <p className="btnNavTabsLabel">Transfer</p>
                {props.state.verificationDataCount.Transfer > 0 && (
                  <p className="btnNavTabsBadge animate__pulse animate__animated animate__infinite	infinite">
                    {props.state.verificationDataCount.Transfer}
                  </p>
                )}
              </div>
            </Button>
            <Button
              className="btnNavTabs"
              style={{
                backgroundColor:
                  props.state.selectedTableTab ===
                    SELECTED_TABLE_TABS.ARCHIVE && "var(--REB-secondary)",
              }}
              onClick={() => {
                props.state.handleActionChange({
                  target: {
                    value: SELECTED_TABLE_TABS.ARCHIVE,
                    name: "btnSwitchTableTabs",
                  },
                });
              }}
            >
              <div className="btnNavTabsContainer">
                <p className="btnNavTabsLabel">Archiving</p>
                {props.state.verificationDataCount.Archive > 0 && (
                  <p className="btnNavTabsBadge animate__pulse animate__animated animate__infinite	infinite">
                    {props.state.verificationDataCount.Archive}
                  </p>
                )}
              </div>
            </Button>
            <Button
              className="btnNavTabs"
              style={{
                backgroundColor:
                  props.state.selectedTableTab === SELECTED_TABLE_TABS.PR &&
                  "var(--REB-secondary)",
              }}
              onClick={() => {
                props.state.handleActionChange({
                  target: {
                    value: SELECTED_TABLE_TABS.PR,
                    name: "btnSwitchTableTabs",
                  },
                });
              }}
            >
              <div className="btnNavTabsContainer">
                <p className="btnNavTabsLabel">Purchase Request</p>
                {props.state.verificationDataCount.PR > 0 && (
                  <p className="btnNavTabsBadge animate__pulse animate__animated animate__infinite	infinite">
                    {props.state.verificationDataCount.PR}
                  </p>
                )}
              </div>
            </Button>
            <Button
              className="btnNavTabs"
              style={{
                backgroundColor:
                  props.state.selectedTableTab ===
                    SELECTED_TABLE_TABS.VOUCHER && "var(--REB-secondary)",
              }}
              onClick={() => {
                props.state.handleActionChange({
                  target: {
                    value: SELECTED_TABLE_TABS.VOUCHER,
                    name: "btnSwitchTableTabs",
                  },
                });
              }}
            >
              <div className="btnNavTabsContainer">
                <p className="btnNavTabsLabel">Expenses Vouchers</p>
                {props.state.verificationDataCount.Voucher > 0 && (
                  <p className="btnNavTabsBadge animate__pulse animate__animated animate__infinite	infinite">
                    {props.state.verificationDataCount.Voucher}
                  </p>
                )}
              </div>
            </Button>
            <Button
              className="btnNavTabs"
              style={{
                backgroundColor:
                  props.state.selectedTableTab ===
                    SELECTED_TABLE_TABS.LOGISTICS && "var(--REB-secondary)",
              }}
              onClick={() => {
                props.state.handleActionChange({
                  target: {
                    value: SELECTED_TABLE_TABS.LOGISTICS,
                    name: "btnSwitchTableTabs",
                  },
                });
              }}
            >
              <div className="btnNavTabsContainer">
                <p className="btnNavTabsLabel">Logistics</p>
                {props.state.verificationDataCount.Logistics > 0 && (
                  <p className="btnNavTabsBadge animate__pulse animate__animated animate__infinite	infinite">
                    {props.state.verificationDataCount.Logistics}
                  </p>
                )}
              </div>
            </Button>
          </Col>
        </Row>
        {props.tablelist}
      </div>
      <VerificationModal
        state={props.state}
        inputChange={props.inputChange}
        tablelist={props.tablelist}
        tableTerms={props.tableTerms}
        tableContent={props.tableContent}
      />
    </>
  );
}
export { Verification, verificationColumn, PRItemColumn };
