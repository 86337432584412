import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actorApi from "../Actor/ActorAPI";
import InventoryAPI from "../Inventory/InventoryAPI";
import LogisticsAPI from "./LogisticsAPI";
import filters from "../../functions/filters";
import { SELECTED_OPERATION } from "../../constants";

import InvoiceAPI from "./InvoiceAPI";
import CourierSelect from "../../components/CustomDrop";
import Badge from "react-bootstrap/Badge";
import moment from "moment";

import UnitSelect from "../../components/CustomDrop";

import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
//import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
//import ButtonGroup from "react-bootstrap/ButtonGroup";
import InchargeSelect from "../../components/CustomDrop";
import StatusSelect from "../../components/CustomDrop";
//import ClientSelect from "../../components/CustomDrop";
import { NumericFormat } from "react-number-format";
//import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import emptyImg from "../../assets/fork-lift.svg";
import Logistics from "./PDF/Logistics";
const LogisticsContainer = (props) => {
  const {
    userType,
    user,
    selectedRowInvoice,
    selectedInvoicePayments,
    settings,
  } = props.state;

  const selectedInvoiceID = props.state.selectedRowInvoice
    ? Number(props.state.selectedRowInvoice.id)
    : null;
  let paymentData = [];

  if (selectedRowInvoice)
    paymentData =
      selectedInvoicePayments !== null ? selectedInvoicePayments : [];

  const dispatch = useDispatch();

  const DSelLogisticsStatus = [
    { value: "on-route", label: "On Route" },
    { value: "finished", label: "Finished" },
    { value: "pending-admin-approval", label: "Pending Admin Approval" },
    { value: "admin-denied", label: "Admin Denied" },
  ];

  const [invoiceStatus, setInvoiceStatus] = useState(null);
  const CURRENT_USERTYPE = useSelector((state) => state.apiData.userType);

  //const user = useSelector((state) => state.apiData.userData);
  const [POContentData, setPOContentData] = useState([]);
  const [LogisticsOperation, setLogisticsOperation] = useState(
    SELECTED_OPERATION.VIEW
  );
  const [txtAmount, setTxtAmount] = useState(0);
  const [txtDate, setTxtDate] = useState(null);
  const [txtType, setTxtType] = useState({ value: "cash", label: "Cash" });

  const [SelInvoiceBankAccount, setSelInvoiceBankAccount] = useState(null);
  const [SelInvoiceExpenseAccount, setSelInvoiceExpenseAccount] =
    useState(null);

  const [TxtContainerDesc, setTxtContainerDesc] = useState(null);
  const [TxtContainerSize, setTxtContainerSize] = useState(null);
  const [SelContainerUnit, setSelContainerUnit] = useState(null);
  const [SelContainerUnitData, setSelContainerUnitData] = useState([]);

  const [TxtCourierContactPerson, setTxtCourierContactPerson] = useState(null);
  const [TxtCourierContactPersonNo, setTxtCourierContactPersonNo] =
    useState(null);
  const [SelCourier, setSelCourier] = useState(null);
  const [SelCourierData, setSelCourierData] = useState([]);

  const [TxtSupplierContactPerson, setTxtSupplierContactPerson] =
    useState(null);
  const [TxtSupplierContactPersonNo, setTxtSupplierContactPersonNo] =
    useState(null);

  const [TxtPickupAddress, setTxtPickupAddress] = useState(null);
  const [TxtPickupDate, setTxtPickupDate] = useState(null);
  const [TxtETA, setTxtETA] = useState(null);
  const [TxtLogiNotes, setTxtLogiNotes] = useState(null);

  const [LogiTxtUniqueID, setLogiTxtUniqueID] = useState(null);
  const [LogiTxtDateAdded, setLogiTxtDateAdded] = useState(null);
  const [LogiTxtDateModified, setLogiTxtDateModified] = useState(null);
  const [LogiTxtLastModifiedBy, setLogiTxtLastModifiedBy] = useState(null);

  const [LogiTxtAddedBy, setLogiTxtAddedBy] = useState(null);

  const [SelInvoiceActsPayable, setSelInvoiceActsPayable] = useState(null);

  const [IsLogistics, setIsLogistics] = useState(false);
  const [SelectedLogistics, setSelectedLogistics] = useState(null);

  //console.log(props.state)

  useEffect(() => {
    if (props.state.selectedRowInvoice) {
      if (props.state.selectedRowInvoice.invoiceStatus) {
        if (props.state.selectedRowInvoice.invoiceStatus.name) {
          setInvoiceStatus(props.state.selectedRowInvoice.invoiceStatus.name);
        }
      }

      if (settings) {
        setSelInvoiceBankAccount(settings.POBankAccount);

        setSelInvoiceActsPayable(settings.POAcctsPayableAccount);
        setSelInvoiceExpenseAccount(settings.POExpenseAccount);
      }
    }
  }, [props.state.selectedRowInvoice]);

  useEffect(() => {
    // console.log("trigger rerender");
    getActors();
    getItemUnits();
  }, []);
  // useEffect(() => {
  //   if (POContentData.length > 0) {
  //     setLogisticsOperation(SELECTED_OPERATION.ADD);
  //   }
  //   if (POContentData.length === 0) {
  //     setLogisticsOperation(SELECTED_OPERATION.VIEW);
  //   }
  // }, [POContentData]);

  const getActors = async (e) => {
    const ACTORS = await actorApi.getAllActors(dispatch);

    const _clients = ACTORS.find((x) => x.name === "Payees");

    setSelCourierData(_clients.actors);
  };
  const getItemUnits = async () => {
    const detData = await InventoryAPI.getItemDetail(dispatch, 2);
    setSelContainerUnitData(detData);
  };

  const resetUserInputs = () => {
    setLogisticsOperation(SELECTED_OPERATION.VIEW);
    setTxtAmount(0);
    setTxtDate(null);
    setTxtType({ value: "cash", label: "Cash" });
    setPOContentData([]);
    setIsLogistics(false);
    setTxtCourierContactPerson("");
    setTxtCourierContactPersonNo("");
    setSelCourier("");
    setTxtSupplierContactPerson("");
    setTxtSupplierContactPersonNo("");
    setTxtPickupAddress("");
    setTxtETA("");
    setLogiTxtUniqueID("");
    setLogiTxtDateAdded("");
    setLogiTxtDateModified("");
    setLogiTxtLastModifiedBy("");
    setLogiTxtAddedBy("");
    setTxtLogiNotes("");

    setTxtContainerDesc("");
    setTxtContainerSize("");
    setSelContainerUnit("");
    setTxtPickupDate("");
    setSelectedLogistics(null);
  };

  const createLogistics = async (cb) => {
    let InventoryId = Number(selectedRowInvoice.inventoryId);
    const transUid = filters.uuidv4();
    // console.log(SelInventory);
    if (InventoryId === 1 || InventoryId === 0) {
      InventoryId = null;
    }

    let logisticsContent = [];

    selectedRowInvoice.content.forEach((cont) => {
      console.log("CONTENT ===> ", cont);
      logisticsContent.push({
        uid: transUid,
        itemId: cont.itemId,
        itemUnitId: cont.itemUnitId,
        qty: cont.itemQty,
        inventoryId: InventoryId,
        orderContentId: cont.id,
      });
    });

    const data = [
      {
        logistics: {
          inventoryId: InventoryId,
          uid: transUid,
          logiStatus: "pending-admin-approval",
          ...(TxtContainerDesc ? { containerDesc: TxtContainerDesc } : null),
          ...(TxtContainerSize ? { containerSize: TxtContainerSize } : null),
          ...(SelContainerUnit
            ? { containerUnitId: SelContainerUnit.id }
            : null),
          ...(TxtCourierContactPerson
            ? { courierContactPerson: TxtCourierContactPerson }
            : null),
          ...(TxtCourierContactPersonNo
            ? { courierContactNumber: TxtCourierContactPersonNo }
            : null),
          ...(SelCourier ? { courierId: SelCourier.id } : null),
          ...(selectedRowInvoice
            ? { supplierVendorId: selectedRowInvoice.supplierId }
            : null),
          ...(TxtSupplierContactPerson
            ? { supplierContactPerson: TxtSupplierContactPerson }
            : null),
          ...(TxtSupplierContactPersonNo
            ? { supplierContactNumber: TxtSupplierContactPersonNo }
            : null),
          ...(TxtPickupAddress ? { pickupAddress: TxtPickupAddress } : null),
          ...(TxtPickupDate ? { pickupDate: TxtPickupDate } : null),
          ...(TxtETA ? { eta: TxtETA } : null),
          ...(TxtLogiNotes ? { notes: TxtLogiNotes } : null),
          orderId: selectedRowInvoice.id,
        },
        content: logisticsContent,
      },
    ];
    console.log("PRE-POST DATA Logistics ===>", data);

    const createdInvoice = await LogisticsAPI.createMultiLogistics(
      dispatch,
      data,
      () => {
        if (cb) {
          // handleTransactionsReset();
          cb();
        }
        // refreshTransactions();
      },
      () => {
        dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Logistics Invoice Creation Error",
            desc: `Please Contact Administrator`,
            isYesNo: false,
          },
        });
      }
    );

    if (createdInvoice.data) {
      actorApi.sendEmail(
        dispatch,
        `Transfer Logistics - ${props.state.selectedRowInvoice?.name}`,
        props.state.selectedRowInvoice?.incharge?.lastName,
        props.state.selectedRowInvoice?.inventoryId
      );
      const orderContent = [];

      POContentData.forEach((cont) => {
        console.log("CONTENT ===> ", cont);
        orderContent.push({
          id: cont.id,
          qtyPendingDelivered: cont.itemQty + cont.qtyPendingDelivered,
        });
      });

      const data = { content: orderContent };
      await InvoiceAPI.updateInvoice(dispatch, data);

      console.log("CREATED LOGISTICS INVOICE ===> ", createdInvoice);
      resetUserInputs();
      const updatedInvoice = await InvoiceAPI.getInvoice(
        dispatch,
        selectedRowInvoice.id
      );
      props.state.handleActionChange({
        target: {
          value: updatedInvoice,
          name: "refreshAndReplaceSelected",
        },
      });
      dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Successfully added logistics invoice`,
        },
      });
    }
  };
  const modifyLogistics = async (cb) => {
    const data = [
      {
        logistics: {
          //inventoryId: InventoryId,
          //uid: transUid,
          logiStatus: "pending-admin-approval",
          id: Number(SelectedLogistics.id),
          ...(TxtContainerDesc ? { containerDesc: TxtContainerDesc } : null),
          ...(TxtContainerSize ? { containerSize: TxtContainerSize } : null),
          ...(SelContainerUnit
            ? { containerUnitId: SelContainerUnit.id }
            : null),
          ...(TxtCourierContactPerson
            ? { courierContactPerson: TxtCourierContactPerson }
            : null),
          ...(TxtCourierContactPersonNo
            ? { courierContactNumber: TxtCourierContactPersonNo }
            : null),
          ...(SelCourier ? { courierId: SelCourier.id } : null),
          ...(selectedRowInvoice
            ? { supplierVendorId: selectedRowInvoice.supplierId }
            : null),
          ...(TxtSupplierContactPerson
            ? { supplierContactPerson: TxtSupplierContactPerson }
            : null),
          ...(TxtSupplierContactPersonNo
            ? { supplierContactNumber: TxtSupplierContactPersonNo }
            : null),
          ...(TxtPickupAddress ? { pickupAddress: TxtPickupAddress } : null),
          ...(TxtPickupDate ? { pickupDate: TxtPickupDate } : null),
          ...(TxtETA ? { eta: TxtETA } : null),
          ...(TxtLogiNotes ? { notes: TxtLogiNotes } : null),
          //orderId: selectedRowInvoice.id,
        },
        //content: logisticsContent,
      },
    ];
    console.log("PRE-POST DATA Logistics Update ===>", data);

    const updatedInvoice = await LogisticsAPI.updateMultiLogistics(
      dispatch,
      data,
      () => {
        if (cb) {
          // handleTransactionsReset();
          cb();
        }
        // refreshTransactions();
      },
      () => {
        dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Logistics Invoice Modification Error",
            desc: `Please Contact Administrator`,
            isYesNo: false,
          },
        });
      }
    );
    console.log("MODIFIED LOGISTICS INVOICE ===> ", updatedInvoice);
    if (updatedInvoice.data) {
      resetUserInputs();
      const updatedInvoice = await InvoiceAPI.getInvoice(
        dispatch,
        selectedRowInvoice.id
      );
      props.state.handleActionChange({
        target: {
          value: updatedInvoice,
          name: "refreshAndReplaceSelected",
        },
      });
      dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Successfully modified logistics invoice`,
        },
      });
    }
  };

  const ReceiveLogistic = async (id) => {
    const data = [{ logistics: { id: id, logiStatus: "finished" } }];
    // await LogisticsAPI.receiveLogistics(dispatch, data);
    // //console.log("CREATED LOGISTICS INVOICE ===> ", createdInvoice);
    await LogisticsAPI.updateMultiLogistics(
      dispatch,
      data,
      () => {},
      () => {
        dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Logistics Invoice Modification Error",
            desc: `Please Contact Administrator`,
            isYesNo: false,
          },
        });
      }
    );

    const updatedInvoice = await InvoiceAPI.getInvoice(
      dispatch,
      selectedRowInvoice.id
    );
    props.state.handleActionChange({
      target: {
        value: updatedInvoice,
        name: "refreshAndReplaceSelected",
      },
    });
    dispatch({
      type: "TOAST_ADD",
      payload: {
        title: `Successfully received Logistic`,
      },
    });
    resetUserInputs();
    setLogisticsOperation(SELECTED_OPERATION.VIEW);
  };

  const ResetQtyDelivered = async (id) => {
    for (const cont of selectedRowInvoice.content) {
      await InvoiceAPI.resetOrderContentQtyDelivered(dispatch, cont.id);
      await props.state.updateInvoiceContentStatus("Processing", cont.id);
      if (cont.histOrderId) {
        await props.state.updateInvoiceContentStatus(
          "Processing",
          cont.histOrderId
        );
      }
    }
    // selectedRowInvoice.content.forEach(async (cont) => {

    // });

    const updatedInvoice = await InvoiceAPI.getInvoice(
      dispatch,
      selectedRowInvoice.id
    );
    props.state.handleActionChange({
      target: {
        value: updatedInvoice,
        name: "refreshAndReplaceSelected",
      },
    });
    dispatch({
      type: "TOAST_ADD",
      payload: {
        title: `Successfully modified PO`,
      },
    });
  };

  const validateUserInputs = (cb) => {
    //let tempUserInput = { ...validateUser };
    let error = false;

    if (SelCourier === "" || SelCourier === null) error = true;
    if (TxtPickupAddress === null || TxtPickupAddress === "") error = true;

    // if (POContentData.length === 0) {
    //   error = true;
    // }
    // POContentData.forEach((cont) => {
    //   if (cont.qty === 0) {
    //     error = true;
    //   }
    // });
    // if (
    //   SelInvoiceInventoryAccount === null ||
    //   SelInvoiceInventoryAccount === ""
    // )
    //   error = true;

    if (error)
      dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Input Notice",
          desc: `Please check if courier, pickup address and contents are not empty or 0`,
          isYesNo: false,
        },
      });
    else {
      if (cb) cb();
    }

    // console.log(tempUserInput);
  };
  const TempModifyLogisContent = (value) => {
    setPOContentData((trans) =>
      trans?.map((dat) => (dat.id === value.id ? { ...dat, ...value } : dat))
    );
  };

  const PopulateLogisticsInvoice = async (d) => {
    const fetchData = await LogisticsAPI.getLogistics(dispatch, d.id);
    //(fetchData);

    const editLastName = d.lastModifiedByActor?.lastName
      ? d.lastModifiedByActor?.lastName
      : "";
    const editFirstName = d.lastModifiedByActor?.firstName
      ? d.lastModifiedByActor?.firstName
      : "";
    const addFirstName = d.createdBy?.firstName ? d.createdBy?.firstName : "";
    const addLastName = d.createdBy?.lastName ? d.createdBy?.lastName : "";

    const tempContent = [];

    for (const cont of fetchData.content) {
      tempContent.push({ ...cont, itemQty: cont.qty });
    }
    //console.log(tempContent);
    setPOContentData(tempContent);

    setTxtCourierContactPerson(d.courierContactPerson);
    setTxtCourierContactPersonNo(d.courierContactNumber);
    setSelCourier(SelCourierData.find((x) => x.id === d.courierId));
    setTxtSupplierContactPerson(d.supplierContactPerson);
    setTxtSupplierContactPersonNo(d.supplierContactNumber);
    setTxtPickupAddress(d.pickupAddress);
    setTxtETA(d.eta ? moment(d.eta).format("YYYY-MM-DD") : "");
    setLogiTxtUniqueID(d.uniqueId);
    setLogiTxtDateAdded(d.datePosted ? moment(d.datePosted).format("LLL") : "");
    setLogiTxtDateModified(
      d.datePosted ? moment(d.datePosted).format("LLL") : ""
    );
    setLogiTxtLastModifiedBy(`${editLastName} ${editFirstName}`);
    setLogiTxtAddedBy(`${addLastName} ${addFirstName}`);
    setTxtLogiNotes(d.notes);

    setTxtContainerDesc(d.containerDesc);
    setTxtContainerSize(d.containerSize);
    setSelContainerUnit(
      SelContainerUnitData.find((x) => x.id === d.containerUnitId)
    );
    setTxtPickupDate(
      d.pickupDate ? moment(d.pickupDate).format("YYYY-MM-DD") : ""
    );
    setIsLogistics(true);
  };

  return (
    <div className="mb-3 pb-3" style={{ backgroundColor: "#e9ecef" }}>
      <style>
        {`
          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            opacity: 1;
          }
          .voucherContentHeaderP {
            text-align: center;
            color: var(--REB-secondary);
            font-weight: bolder;
          }
        `}
      </style>
      <div
        style={{
          backgroundColor: "var(--REB-primary)",
          borderRadius: "5px 5px 0px 0px",
          display: "flex",
        }}
        className="p-3"
      >
        <h4
          className="m-0"
          style={{
            flex: 1,
            fontWeight: "bolder",
            color: "var(--REB-white)",
          }}
        >
          <i
            className="icofont-money pr-1"
            style={{ flex: 1, fontSize: "1.5rem" }}
          ></i>
          Transfer Inventory Logistics Invoice
        </h4>
        <Button
          style={{
            backgroundColor: "var(--REB-yellow)",
            fontWeight: "bolder",
            color: "var(--REB-black)",
          }}
          onClick={() => {
            setLogisticsOperation(SELECTED_OPERATION.ADD);
          }}
        >
          Add Logistics Invoice
        </Button>
      </div>
      <div>
        <Row style={{ border: "solid 1px #ced4da" }} className="" noGutters>
          <Col xs={12} sm={6} md={3} lg={3}>
            <p
              className="m-0 p-0 voucherContentHeaderP"
              style={{ textAlign: "center" }}
            >
              Actions
            </p>
          </Col>
          <Col xs={12} sm={6} md={5} lg={5}>
            <p
              className="m-0 p-0 voucherContentHeaderP"
              style={{ textAlign: "center" }}
            >
              Logistics Invoice Description
            </p>
          </Col>

          <Col xs={12} sm={6} md={2} lg={2}>
            <p
              className="m-0 p-0 voucherContentHeaderP"
              style={{ textAlign: "center" }}
            >
              Status
            </p>
          </Col>
          <Col xs={12} sm={6} md={2} lg={2}>
            <p
              className="m-0 p-0 voucherContentHeaderP"
              style={{ textAlign: "center" }}
            >
              ETA
            </p>
          </Col>
        </Row>
      </div>
      {selectedRowInvoice?.logistics.map((logi, index) => {
        const d = logi;
        let status = "finished";
        let statusDisplay = "";
        let infoColor = "";
        let icon = <></>;

        if (d.logiStatus) {
          status = d.logiStatus;
        }

        if (status === "finished") {
          statusDisplay = "Finished";
          infoColor = "var(--REB-green)";
          icon = (
            <i className="icofont-ui-check " style={{ fontSize: "1.3em" }}></i>
          );
        }
        if (status === "on-route") {
          statusDisplay = "On Route";
          infoColor = "var(--REB-secondary)";
          icon = (
            <i
              className="icofont-truck-loaded"
              style={{ fontSize: "1.3em" }}
            ></i>
          );
        }
        if (status === "admin-denied") {
          statusDisplay = "Admin Denied";
          infoColor = "var(--REB-warning)";
          icon = (
            <i className="icofont-ui-close " style={{ fontSize: "1.3em" }}></i>
          );
        }
        if (status === "pending-admin-approval") {
          statusDisplay = "Pending Admin Approval";
          infoColor = "var(--REB-secondary)";
          icon = (
            <i className="icofont-warning " style={{ fontSize: "1.3em" }}></i>
          );
        }
        if (status === "processing") {
          statusDisplay = "Processing";
          infoColor = "var(--REB-green)";
          icon = (
            <i className="icofont-money" style={{ fontSize: "1.3em" }}></i>
          );
        }

        return (
          <Row
              style={{
                border: "solid 1px #ced4da",
                backgroundColor:
                  logi.id === SelectedLogistics?.id
                    ? "rgb(175, 217, 173)"
                    : "initial",
              }}
              className="py-2"
              key={logi.uniqueId}
              noGutters
            >
              <Col
                xs={12}
                sm={6}
                md={3}
                lg={3}
                className="px-2"
                style={{
                  alignSelf: "center",
                  textAlign: "center",
                }}
              >
                {/* <Button
                  onClick={async () => {
                    setLogisticsOperation(SELECTED_OPERATION.VIEW);
                    await LogisticsAPI.deleteLogistics(dispatch, logi.id);
                    const updatedInvoice = await InvoiceAPI.getInvoice(
                      dispatch,
                      selectedRowInvoice.id
                    );
                    props.state.handleActionChange({
                      target: {
                        value: updatedInvoice,
                        name: "refreshAndReplaceSelected",
                      },
                    });
                    dispatch({
                      type: "TOAST_ADD",
                      payload: {
                        title: `Successfully removed logistics invoice`,
                      },
                    });
                  }}
                  style={{
                    backgroundColor: "var(--REB-warning)",
                  }}
                  className="mx-1"
                >
                  <i
                    style={{ color: "var(--REB-white)" }}
                    className="icofont-trash"
                  ></i>
                </Button> */}

                <Button
                  onClick={() => {
                    setLogisticsOperation(SELECTED_OPERATION.VIEW);
                    PopulateLogisticsInvoice(logi);
                    setSelectedLogistics(logi);
                  }}
                  style={{
                    backgroundColor: "var(--REB-primary)",
                    fontWeight: "bolder",
                  }}
                  className="mx-1"
                >
                  {/* <i
                    style={{ color: "var(--REB-white)" }}
                    className="icofont-navigation-menu"
                  ></i> */}
                  View
                </Button>
                {/* {logi.logiStatus === "on-route" && (
                  <Button
                    onClick={() => {
                      ReceiveLogistic(logi.id);
                    }}
                    style={{
                      backgroundColor: "var(--REB-primary)",
                      fontWeight: "bolder",
                    }}
                    className="mx-1"
                  >
                    Receive
                  </Button>
                )} */}
              </Col>
              <Col xs={12} sm={6} md={5} lg={5}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                    fontSize: "0.8em",
                    height: "100%",
                    color: "var(--REB-blue)",
                    fontWeight: "bold",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <p className="m-0 p-0">Logistic Invoice # {index + 1}</p>
                </div>
              </Col>

              <Col style={{ textAlign: "center" }} xs={12} sm={6} md={2} lg={2}>
                <Badge
                  style={{
                    backgroundColor: infoColor,
                    color: "white",
                    fontSize: "0.8em",
                  }}
                >
                  {icon} {statusDisplay}
                </Badge>
              </Col>
              <Col style={{ textAlign: "center" }} xs={12} sm={6} md={2} lg={2}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                    fontSize: "0.8em",
                    height: "100%",
                    color: "var(--REB-blue)",
                    fontWeight: "bold",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  {logi.eta ? moment(logi.eta).format("LL") : ""}
                </div>
              </Col>
            </Row>
        );
      })}

      {(LogisticsOperation === SELECTED_OPERATION.ADD || SelectedLogistics) && (
        <>
          <div
            style={{
              backgroundColor: "var(--REB-primary)",
              borderRadius: "5px 5px 0px 0px",
              display: "flex",
            }}
            className="p-3"
          >
            <h4
              className="m-0"
              style={{
                flex: 1,
                fontWeight: "bolder",
                color: "var(--REB-white)",
              }}
            >
              <i
                className="icofont-money pr-1"
                style={{ flex: 1, fontSize: "1.5rem" }}
              ></i>
              Logistics Invoice
            </h4>
          </div>
          {(SelectedLogistics?.logiStatus === "on-route" ||
            SelectedLogistics?.logiStatus === "finished") && (
            <div className="p-3">
              <Button
                onClick={async () => {
                  const fetchData = await LogisticsAPI.getLogistics(
                    dispatch,
                    SelectedLogistics.id
                  );
                  console.log("SELECTED LOGISTICS ===>", SelectedLogistics);
                  console.log("FETCHED ORDER ===>", fetchData);

                  let perChunk = 8; // items per chunk

                  let result = fetchData.content.reduce(
                    (resultArray, item, index) => {
                      const chunkIndex = Math.floor(index / perChunk);
                      if (!resultArray[chunkIndex]) {
                        resultArray[chunkIndex] = []; // start a new chunk
                      }
                      resultArray[chunkIndex].push({
                        ...item,
                        index: index + 1,
                      });
                      return resultArray;
                    },
                    []
                  );
                  // For sort by index
                  // for (let index = 0; index < result.length; index++) {
                  //   const element = result[index];
                  //   result[index] = element.sort(function (a, b) {
                  //     return a.partIndex - b.partIndex;
                  //   });
                  // }
                  dispatch({
                    type: "PDF_MODAL_TOGGLE",
                    doc: Logistics.LogisticsPDF(
                      props.state,
                      fetchData,
                      result,
                      false
                    ),
                  });
                }}
                style={{
                  backgroundColor: "var(--REB-primary)",
                  fontWeight: "bolder",
                }}
                className="mx-4 p-3"
              >
                <i
                  style={{ color: "var(--REB-white)" }}
                  className="icofont-print"
                ></i>
              </Button>
              {SelectedLogistics?.logiStatus !== "finished" && (
                <Button
                  onClick={() => {
                    dispatch({
                      type: "MODAL_SHOW",
                      payload: {
                        title: "Confirmation",
                        desc: `Please make sure the items are delivered successfully`,
                        isYesNo: true,
                        cb: () => {
                          ReceiveLogistic(SelectedLogistics.id);
                        },
                      },
                    });
                  }}
                  style={{
                    backgroundColor: "var(--REB-green)",
                    fontWeight: "bolder",
                  }}
                  className="p-3"
                >
                  Receive Items
                </Button>
              )}
            </div>
          )}

          <>
            <Row className="mt-3" noGutters>
              <Col xs={12} sm={6} md={4} lg={3} className="px-1 mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: "var(--REB-white)",
                      color: "var(--REB-black)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                      WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                      MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                      boxShadow: "inset 0px 0px 0px 1px #ced4da",
                    }}
                  >
                    Unique ID
                  </p>
                  <FormControl
                    placeholder="Unique ID"
                    className="h-100"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    value={LogiTxtUniqueID}
                    disabled
                  />
                </div>
              </Col>
              <Col xs={12} sm={6} md={4} lg={3} className="px-1 mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: "var(--REB-white)",
                      color: "var(--REB-black)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                      WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                      MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                      boxShadow: "inset 0px 0px 0px 1px #ced4da",
                    }}
                  >
                    Date Added
                  </p>
                  <FormControl
                    placeholder="Date Added"
                    className="h-100"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    value={LogiTxtDateAdded}
                    disabled
                  />
                </div>
              </Col>
              <Col xs={12} sm={6} md={4} lg={3} className="px-1 mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: "var(--REB-white)",
                      color: "var(--REB-black)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                      WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                      MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                      boxShadow: "inset 0px 0px 0px 1px #ced4da",
                    }}
                  >
                    Date Modified
                  </p>
                  <FormControl
                    placeholder="Date Modified"
                    className="h-100"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    value={LogiTxtDateModified}
                    disabled
                  />
                </div>
              </Col>
            </Row>
            <Row noGutters>
              <Col xs={12} sm={6} md={6} lg={6} className="px-1 mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",

                    // border: "1px solid #ced4da",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: "var(--REB-white)",
                      color: "var(--REB-black)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                      WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                      MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                      boxShadow: "inset 0px 0px 0px 1px #ced4da",
                    }}
                  >
                    Added By
                  </p>
                  <FormControl
                    placeholder="Added By"
                    className="h-100"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    value={LogiTxtAddedBy}
                    disabled
                  />
                </div>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6} className="px-1 mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: "var(--REB-white)",
                      color: "var(--REB-black)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                      WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                      MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                      boxShadow: "inset 0px 0px 0px 1px #ced4da",
                    }}
                  >
                    Modified By
                  </p>
                  <FormControl
                    placeholder="Modified By"
                    className="h-100"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    value={LogiTxtLastModifiedBy}
                    disabled
                  />
                </div>
              </Col>
            </Row>
            <Row noGutters>
              <Col xs={4} className="px-1 mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: SelCourier
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Courier
                  </p>
                  <CourierSelect
                    state={{ userType: CURRENT_USERTYPE }}
                    data={SelCourierData}
                    name="selActorCourier"
                    //isLoading={props.state.suppliers.isLoading}
                    selected={SelCourier}
                    //tabIndex={7}
                    lblHidden={true}
                    refreshData={(event) => {
                      getActors();
                    }}
                    detType={13}
                    dataStructure="invoiceActor"
                    addUrl="actor/"
                    editUrl="actor/updateActor"
                    deleteUrl="actor/deleteActor"
                    componentName="Courier"
                    componentType=""
                    optionLabel="company"
                    //optionLabel="name"
                    disabled={
                      LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                      LogisticsOperation !== SELECTED_OPERATION.ADD
                    }
                    handler={(data) => {
                      // console.log(data);
                      setSelCourier(data.target.value);
                    }}
                    // disabled={props.state.selDetailUnit.readOnly}
                  />
                </div>
              </Col>
              <Col xs={4} className="px-1 mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: true
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Courier Contact Person
                  </p>
                  <FormControl
                    type="text"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    placeholder="Courier Contact Person"
                    value={TxtCourierContactPerson}
                    onChange={(e) => {
                      setTxtCourierContactPerson(e.currentTarget.value);
                    }}
                    disabled={
                      LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                      LogisticsOperation !== SELECTED_OPERATION.ADD
                    }
                    className=""
                  />
                </div>
              </Col>
              <Col xs={4} className="px-1 mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: true
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Courier Contact Person #
                  </p>
                  <FormControl
                    type="text"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    placeholder=" Courier Contact Person #"
                    value={TxtCourierContactPersonNo}
                    disabled={
                      LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                      LogisticsOperation !== SELECTED_OPERATION.ADD
                    }
                    onChange={(e) => {
                      setTxtCourierContactPersonNo(e.currentTarget.value);
                    }}
                    className=""
                  />
                </div>
              </Col>
              <Col xs={4} className="px-1 mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: true
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Supplier Contact Person
                  </p>
                  <FormControl
                    type="text"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    placeholder="Supplier Contact Person"
                    value={TxtSupplierContactPerson}
                    disabled={
                      LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                      LogisticsOperation !== SELECTED_OPERATION.ADD
                    }
                    onChange={(e) => {
                      setTxtSupplierContactPerson(e.currentTarget.value);
                    }}
                    className=""
                  />
                </div>
              </Col>
              <Col xs={4} className="px-1 mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: true
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Supplier Contact Person #
                  </p>
                  <FormControl
                    type="text"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    placeholder="Supplier Contact Person #"
                    value={TxtSupplierContactPersonNo}
                    disabled={
                      LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                      LogisticsOperation !== SELECTED_OPERATION.ADD
                    }
                    onChange={(e) => {
                      setTxtSupplierContactPersonNo(e.currentTarget.value);
                    }}
                    className=""
                  />
                </div>
              </Col>
              <Col xs={4} className="px-1 mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: TxtPickupAddress
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Pickup Address
                  </p>
                  <FormControl
                    type="text"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    placeholder="Pickup Address"
                    value={TxtPickupAddress}
                    disabled={
                      LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                      LogisticsOperation !== SELECTED_OPERATION.ADD
                    }
                    onChange={(e) => {
                      setTxtPickupAddress(e.currentTarget.value);
                    }}
                    className=""
                  />
                </div>
              </Col>
              <Col xs={4} className="px-1  mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: true
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Estimated Arrival Date
                  </p>
                  <FormControl
                    type="date"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    placeholder="Estimated Delivery Date"
                    value={TxtETA}
                    disabled={
                      LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                      LogisticsOperation !== SELECTED_OPERATION.ADD
                    }
                    onChange={(e) => {
                      setTxtETA(e.currentTarget.value);
                    }}
                    className=""
                  />
                </div>
              </Col>
              <Col xs={4} className="px-1  mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: true
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Pickup Date
                  </p>
                  <FormControl
                    type="date"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    placeholder="  Pickup Date"
                    value={TxtPickupDate}
                    disabled={
                      LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                      LogisticsOperation !== SELECTED_OPERATION.ADD
                    }
                    onChange={(e) => {
                      setTxtPickupDate(e.currentTarget.value);
                    }}
                    className=""
                  />
                </div>
              </Col>
              <Col xs={4} className="px-1  mb-2"></Col>
              <Col xs={4} className="px-1  mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: true
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Container Description
                  </p>
                  <FormControl
                    type="text"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    placeholder=" Container Description"
                    value={TxtContainerDesc}
                    disabled={
                      LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                      LogisticsOperation !== SELECTED_OPERATION.ADD
                    }
                    onChange={(e) => {
                      setTxtContainerDesc(e.currentTarget.value);
                    }}
                    className=""
                  />
                </div>
              </Col>

              <Col xs={4} className="px-1  mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: true
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Container Size
                  </p>
                  <FormControl
                    as={NumericFormat}
                    inputMode="numeric"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    placeholder="Container Size"
                    thousandSeparator={true}
                    suffix={` ${
                      SelContainerUnit ? SelContainerUnit?.name : ""
                    }`}
                    allowNegative={false}
                    value={TxtContainerSize}
                    disabled={
                      LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                      LogisticsOperation !== SELECTED_OPERATION.ADD
                    }
                    onValueChange={(x) => {
                      setTxtContainerSize(Number(x.value));
                    }}
                  />
                </div>
              </Col>

              <Col xs={4} className="px-1  mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: true
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Container Unit
                  </p>
                  <UnitSelect
                    state={{ userType: CURRENT_USERTYPE }}
                    data={SelContainerUnitData}
                    name="selDetailUnit"
                    //isLoading={props.state.suppliers.isLoading}
                    selected={SelContainerUnit}
                    //tabIndex={7}
                    lblHidden={true}
                    refreshData={async (event) => {
                      getItemUnits();
                    }}
                    detType={2}
                    addUrl="inventory/createItem"
                    editUrl="inventory/updateItem"
                    deleteUrl="inventory/deleteItemDetail"
                    componentName="Unit"
                    dataStructure="itemDetailsInventory"
                    componentType=""
                    //optionLabel="name"
                    disabled={
                      LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                      LogisticsOperation !== SELECTED_OPERATION.ADD
                    }
                    handler={(data) => {
                      // console.log(data);
                      setSelContainerUnit(data.target.value);
                    }}
                    // disabled={props.state.selDetailUnit.readOnly}
                  />
                </div>
              </Col>
              <Col xs={6} className="px-1  mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: true
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Logistics Notes
                  </p>
                  <FormControl
                    type="text"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    //style={{ textLogiform: "uppercase" }}
                    placeholder="Logistics Notes"
                    value={TxtLogiNotes}
                    disabled={
                      LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                      LogisticsOperation !== SELECTED_OPERATION.ADD
                    }
                    onChange={(e) => {
                      setTxtLogiNotes(e.currentTarget.value);
                    }}
                    className=""
                  />
                </div>
              </Col>
            </Row>
          </>

          <div>
            <Row style={{ border: "solid 1px #ced4da" }} className="" noGutters>
              <Col xs={12} sm={6} md={2} lg={2}>
                <p
                  className="m-0 p-0 voucherContentHeaderP"
                  style={{ textAlign: "center" }}
                >
                  Actions
                </p>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                <p
                  className="m-0 p-0 voucherContentHeaderP"
                  style={{ textAlign: "center" }}
                >
                  Item Name
                </p>
              </Col>

              <Col xs={12} sm={6} md={4} lg={4}>
                <p
                  className="m-0 p-0 voucherContentHeaderP"
                  style={{ textAlign: "center" }}
                >
                  Qty Expected
                </p>
              </Col>
            </Row>
          </div>
          {POContentData.map((val) => {
            const UNIT = val?.item?.details.find((x) => x.type === 2).name;
            const ITEM_NAME = val?.item?.details.find((x) => x.type === 1).name;
            const BRAND = val?.item?.details.find((x) => x.type === 3).name;
            let image = [];
            if (val.item?.uploads !== null && val.item?.uploads !== "") {
              if (val.item?.uploads?.constructor === String)
                image = JSON.parse(val.item?.uploads);
              else {
                image = val.item?.uploads;
              }
            }
            return (
              <>
                <Row
                  style={{ border: "solid 1px #ced4da" }}
                  className="py-2"
                  key={val.uniqueId}
                  noGutters
                >
                  <Col
                    xs={12}
                    sm={6}
                    md={2}
                    lg={2}
                    className="px-2"
                    style={{
                      alignSelf: "center",
                      textAlign: "center",
                    }}
                  >
                    {/* <Button
                      onClick={() => {
                        let tempData = [];
                        tempData = tempData.concat(POContentData);

                        const filtered = tempData.filter(
                          (x) => x.id !== val.id
                        );
                        setPOContentData([...filtered]);
                        if (filtered.length === 0) {
                          resetUserInputs();
                        }
                      }}
                      disabled={LogisticsOperation !== SELECTED_OPERATION.ADD}
                      style={{
                        backgroundColor: "var(--REB-warning)",
                      }}
                      className="mx-1"
                    >
                      <i
                        style={{ color: "var(--REB-white)" }}
                        className="icofont-trash"
                      ></i>
                    </Button> */}
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <Row noGutters>
                      <Col xs={12} sm={12} md={2} lg={2}>
                        <img
                          style={{
                            objectFit: "contain",
                            maxWidth: "100px",
                            height: "100px",
                            maxHeight: "100px",
                          }}
                          alt=""
                          src={
                            typeof image !== "undefined" &&
                            image !== null &&
                            image.length > 0 &&
                            image !== "null"
                              ? `${
                                  process.env.REACT_APP_API_URL
                                }global/getFile2?uniqueId=${
                                  val.item?.uniqueId
                                }&filename=${encodeURIComponent(
                                  image[0].filename
                                )}&token=${localStorage.getItem("LUT")}`
                              : emptyImg
                          }
                        />
                      </Col>
                      <Col xs={12} sm={12} md={10} lg={10}>
                        <p className="m-0 p-0 " style={{}}>
                          {ITEM_NAME}
                        </p>
                        <p className="m-0 p-0 " style={{}}>
                          {val?.item?.description}
                        </p>
                        <p className="m-0 p-0 " style={{}}>
                          {BRAND}
                        </p>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} sm={6} md={4} lg={4}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                        fontSize: "1.5em",
                        height: "100%",
                        color: "var(--REB-blue)",
                        fontWeight: "bold",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {/* <Button
                          onClick={() => {
                            if (Number(val.qtyDelivered) > 1) {
                              TempModifyLogisContent({
                                id: val.id,
                                qtyDelivered: Number(val.qtyDelivered) - 1,
                              });
                            }
                          }}
                          style={{
                            backgroundColor: "var(--REB-warning)",
                          }}
                          className="mx-1"
                        >
                          <i
                            style={{ color: "var(--REB-white)" }}
                            className="icofont-minus"
                          ></i>
                        </Button> */}
                        {/* <p style={{ marginBottom: "0px", flex: 1 }}>
                    {val?.qtyDelivered}
                  </p> */}
                        <FormControl
                          as={NumericFormat}
                          inputMode="numeric"
                          type="text"
                          placeholder="QTY Expected"
                          thousandSeparator={true}
                          suffix={` ${UNIT}`}
                          allowNegative={false}
                          value={val.itemQty}
                          style={{ textAlign: "center" }}
                          isAllowed={(values) => {
                            const { formattedValue, floatValue } = values;
                            const reqQty =
                              Number(val.qtyOrdered) -
                              (Number(val.qtyPendingDelivered) +
                                Number(val.qtyDelivered));
                            // console.log(
                            //   val.qtyOrdered,
                            //   val.qtyPendingDelivered,
                            //   val.qtyDelivered
                            // );
                            return (
                              formattedValue === "" ||
                              (floatValue <= reqQty && floatValue >= 0)
                            );
                          }}
                          onValueChange={(x) => {
                            TempModifyLogisContent({
                              id: val.id,
                              itemQty: Number(x.value),
                            });
                          }}
                          disabled={
                            LogisticsOperation !== SELECTED_OPERATION.ADD
                          }
                        />
                        {/* <Button
                          onClick={() => {
                            TempModifyLogisContent({
                              id: val.id,
                              qtyDelivered: Number(val.qtyDelivered) + 1,
                            });
                          }}
                          style={{
                            backgroundColor: "var(--REB-green)",
                          }}
                          className="mx-1"
                        >
                          <i
                            style={{ color: "var(--REB-white)" }}
                            className="icofont-ui-add"
                          ></i>
                        </Button> */}
                      </div>

                      <p
                        style={{
                          marginBottom: "0px",
                          fontWeight: "normal",
                          flex: 1,
                        }}
                      >
                        {UNIT}
                      </p>
                    </div>
                  </Col>
                </Row>
              </>
            );
          })}
          <>
            <Row className="my-3" noGutters>
              {(LogisticsOperation === SELECTED_OPERATION.ADD ||
                LogisticsOperation === SELECTED_OPERATION.EDIT) && (
                <Col xs={12} sm={12} md={4} lg={4} className="px-1">
                  <Button
                    style={{ backgroundColor: "var(--REB-green)" }}
                    onClick={() => {
                      //console.log(selectedRowInvoice);
                      LogisticsOperation === SELECTED_OPERATION.ADD &&
                        validateUserInputs(() => {
                          createLogistics();
                        });
                      LogisticsOperation === SELECTED_OPERATION.EDIT &&
                        validateUserInputs(() => {
                          modifyLogistics();
                        });
                    }}
                    block
                  >
                    Save Logistics
                  </Button>
                </Col>
              )}
              {LogisticsOperation === SELECTED_OPERATION.VIEW && (
                <Col xs={12} sm={12} md={4} lg={4} className="px-1">
                  <Button
                    style={{ backgroundColor: "var(--REB-green)" }}
                    onClick={() => {
                      setLogisticsOperation(SELECTED_OPERATION.EDIT);
                    }}
                    block
                  >
                    Modify
                  </Button>
                </Col>
              )}
              {LogisticsOperation === SELECTED_OPERATION.EDIT && (
                <Col xs={12} sm={12} md={4} lg={4} className="px-1">
                  <Button
                    style={{ backgroundColor: "var(--REB-warning)" }}
                    onClick={async () => {
                      dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "Confirmation",
                          desc: `Are you sure you want to remove this Logistics invoice? Doing so will reset the QTY and sets items status to processing`,
                          isYesNo: true,
                          cb: async () => {
                            await LogisticsAPI.deleteLogistics(
                              dispatch,
                              SelectedLogistics.id
                            );

                            const updatedInvoice = await InvoiceAPI.getInvoice(
                              dispatch,
                              selectedRowInvoice.id
                            );
                            // await props.state.updateInvoiceContentStatus(
                            //   "Processing",
                            //   selectedRowInvoice.id
                            // );
                            resetUserInputs();
                            props.state.handleActionChange({
                              target: {
                                value: updatedInvoice,
                                name: "refreshAndReplaceSelected",
                              },
                            });
                            dispatch({
                              type: "TOAST_ADD",
                              payload: {
                                title: `Successfully removed logistics invoice`,
                              },
                            });
                          },
                        },
                      });
                    }}
                    block
                  >
                    Delete
                  </Button>
                </Col>
              )}

              {(LogisticsOperation === SELECTED_OPERATION.ADD ||
                LogisticsOperation === SELECTED_OPERATION.EDIT ||
                LogisticsOperation === SELECTED_OPERATION.VIEW) && (
                <Col xs={12} sm={12} md={4} lg={4} className="px-1">
                  <Button
                    style={{
                      backgroundColor: "var(--REB-yellow)",
                      color: "var(--REB-black)",
                    }}
                    onClick={() => {
                      resetUserInputs();
                      setLogisticsOperation(SELECTED_OPERATION.VIEW);
                    }}
                    block
                  >
                    Cancel
                  </Button>
                </Col>
              )}
            </Row>
          </>
        </>
      )}
    </div>
  );
};
function invTransColumn(inventoryData, itemImgs) {
  return [
    {
      Header: "Name",
      Cell: (row) => {
        const d = row.original;
        // console.log(d);
        let image = [];
        if (d) {
          if (d.itemId !== null) {
            if (d.item.uploads !== null || d.item.files !== null) {
              if (d.item?.files?.constructor === String)
                image = JSON.parse(d.item?.files);
              else {
                image = d.item.uploads;
              }
              //console.log(image);
            }
          }
        }
        const name = () => {
          let name = "-";
          if (d.itemId === null) {
            if (d.serviceName !== null) name = JSON.parse(d.serviceName).label;
          } else {
            if (d.item.details.length > 0) {
              const sDat = d.item.details.find((x) => x.type === 1);
              sDat !== undefined ? (name = sDat.name) : (name = "-");
            }
          }

          return name;
        };

        return (
          <>
            <Row noGutters>
              <Col md={3} lg={3} className="">
                <Row noGutters>
                  <Col
                    style={{
                      textAlign: "-webkit-center",
                    }}
                  >
                    {/* <img
                            style={{
                              objectFit: "contain",
                              maxWidth: "100px",
                              height: "100px",
                              maxHeight: "100px",
                            }}
                            alt=""
                            src={
                              typeof d.dispImg !== "undefined" &&
                              d.dispImg !== null
                                ? d.dispImg.original
                                : emptyImg
                            }
                          ></img> */}
                    <img
                      style={{
                        objectFit: "contain",
                        maxWidth: "100px",
                        height: "100px",
                        maxHeight: "100px",
                      }}
                      alt=""
                      src={
                        typeof image !== "undefined" &&
                        image !== null &&
                        image.length > 0 &&
                        image !== "null"
                          ? `${
                              process.env.REACT_APP_API_URL
                            }global/getFile2?uniqueId=${
                              d.item.uniqueId
                            }&filename=${encodeURIComponent(
                              image[0].filename
                            )}&token=${localStorage.getItem("LUT")}`
                          : emptyImg
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={9} lg={9} className="">
                <FormControl
                  as="textarea"
                  style={{ resize: "none", height: "100%" }}
                  defaultValue={name()}
                  readOnly
                />
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      Header: "Brand",
      id: "brand",
      maxWidth: 150,
      accessor: (d) => {
        let name = "-";
        if (d.itemId === null) {
          name = "-";
        } else {
          if (d.item.details !== null)
            if (d.item.details.length > 0) {
              const sDat = d.item.details.find((x) => x.type === 3);
              sDat !== undefined ? (name = sDat.name) : (name = "-");
            }
        }

        return name;
      },
    },
    {
      Header: "Description",
      id: "desc",
      maxWidth: 400,
      Cell: (row) => {
        const d = row.original;
        return (
          <>
            <Row noGutters>
              <Col>
                <FormControl
                  as="textarea"
                  style={{ resize: "none", height: "100%" }}
                  defaultValue={d.item.description}
                  readOnly
                />
              </Col>
            </Row>
          </>
        );
      },
      accessor: (d) => d.item.description,
    },
    {
      Header: "QTY",
      id: "qty",
      maxWidth: 100,
      accessor: (d) => {
        let name = "-";

        if (d.item.details.length > 0) {
          const sDat = d.item.details.find((x) => x.type === 2);
          sDat !== undefined ? (name = sDat.name) : (name = "-");
        }

        return `${d.itemQty} ${name}`;
      },
    },
  ];
}

let TxtQty = (props) => (
  <>
    {/* {console.log(props.state.selContentItems)} */}
    <div
      className=""
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "var(--REB-primary)",
        borderRadius: "5px",
      }}
    >
      <InputGroup.Prepend>
        <p
          className="m-0 pl-2"
          style={{
            color: "var(--REB-white)",
            fontWeight: "bold",

            // backgroundColor: "var(--REB-primary)",
          }}
        >
          QTY TO TRANSFER
        </p>
      </InputGroup.Prepend>
      <FormControl
        as={NumericFormat}
        inputMode="numeric"
        thousandSeparator={true}
        suffix={` ${
          props.state.selInventoryInvChangeFromContent.value !== "" &&
          props.state.selInventoryInvChangeFromContent.value !== null
            ? props.state.selInventoryInvChangeFromContent.value.details.find(
                (x) => x.type === 2
              ).name
            : ""
        }`}
        isAllowed={(values) => {
          const { formattedValue, floatValue } = values;
          return (
            formattedValue === "" ||
            floatValue <=
              (props.state.selInventoryInvChangeFromContent.value === "" &&
              props.state.selInventoryInvChangeFromContent.value !== null
                ? ""
                : props.state.selInventoryInvChangeFromContent.value.qty)
          );
          // if(floatValue <= 100){setDiscountLimit(1)}
          // return formattedValue === "" || floatValue <= 100;
        }}
        placeholder={`AVBL QTY: ${
          props.state.selInventoryInvChangeFromContent.value !== "" &&
          props.state.selInventoryInvChangeFromContent.value !== null
            ? props.state.selInventoryInvChangeFromContent.value.qty
            : ""
        } ${
          props.state.selInventoryInvChangeFromContent.value !== "" &&
          props.state.selInventoryInvChangeFromContent.value !== null
            ? props.state.selInventoryInvChangeFromContent.value.details.find(
                (x) => x.type === 2
              ).name
            : ""
        }`}
        name="txtQtyInvChange"
        value={props.state.txtQtyInvChange.value}
        onValueChange={(x) => {
          props.state.inputChange({
            target: {
              value: Number(x.value),
              name: "txtQtyInvChange",
              placeholder: props.state.txtQtyInvChange.placeholder,
              className: props.state.txtQtyInvChange.className,
            },
          });
        }}
        className={props.state.txtQtyInvChange.className}
        disabled={props.state.selDetailIncharge.readOnly}
      />
    </div>
  </>
);

// let TxtDateDeadlineInvChange = (props) => (
//   <>
//     <Form.Label>
//       <strong>Invoice Deadline</strong>
//     </Form.Label>
//     <FormControl
//       type="date"
//       name="txtDateDeadlineInvChange"
//       value={props.state.txtDateDeadlineInvChange.value}
//       onChange={props.state.inputChange}
//       className={props.state.txtDateDeadlineInvChange.className}
//       readOnly={props.state.txtDateDeadlineInvChange.readOnly}
//     />
//   </>
// );

function TypeTransferInventory(props) {
  let customStyles = {
    menu: (provided, state) => {
      //console.log(state);
      const width = "100%";

      let color = state.selectProps.menuColor;
      const padding = 0;

      return { ...provided, width, color, padding };
    },
    control: (provided) => ({
      ...provided,
      borderRadius: "0px 0px 0px 0px",
    }),

    container: (_, { selectProps: { width } }) => ({
      width: width,
      flex: 1,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,

      borderRadius: "5px 0px 0px 5px",
    }),

    option: (provided, state) => {
      let backgroundColor = "";
      let display = "block";
      let color = "black";
      if (state.data.isDefault === 1) {
        backgroundColor = "rgba(255, 0, 0, 0.2)";
      }
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      //console.log(state.data);
      if (state.data.isHidden === 1) {
        display = "none";
      }

      return {
        ...provided,
        opacity,
        transition,
        backgroundColor,
        color,
        display,
      };
    },
  };

  return (
    <>
      <Row noGutters></Row>
      <Row noGutters>
        <Col className="pl-2 pr-2" md={12} xs={12} sm={12} lg={12}>
          <StatusSelect
            state={props.state}
            data={props.state.selDetailStatus.data}
            name="selDetailStatus"
            containerClassname="mb-3"
            //isLoading={props.state.suppliers.isLoading}
            selected={props.state.selDetailStatus.value}
            //tabIndex={7}
            lblHidden={false}
            refreshData={(event) => {
              props.state.refreshItemDetail("selDetailStatus", 3);
            }}
            detType={3}
            addUrl="inventory/createItem"
            editUrl="inventory/updateItem"
            deleteUrl="inventory/deleteItemDetail"
            componentName="Invoice Status"
            isDropHidden={true}
            componentType=""
            optionLabel="name"
            getOptionLabel={(x) => x.name}
            getOptionValue={(x) => x.id}
            handler={props.state.inputChange}
            disabled={props.state.selDetailStatus.readOnly}
          />
        </Col>

        <Col className="pl-2 pr-2" md={12} xs={12} sm={12} lg={12}>
          <InchargeSelect
            state={props.state}
            data={props.state.selDetailIncharge.data}
            name="selDetailIncharge"
            containerClassname="mb-3"
            //isLoading={props.state.suppliers.isLoading}
            selected={props.state.selDetailIncharge.value}
            //tabIndex={7}
            lblHidden={false}
            refreshData={(event) => {
              props.state.refreshItemDetail("selDetailIncharge", 3);
            }}
            detType={3}
            addUrl="inventory/createItem"
            editUrl="inventory/updateItem"
            deleteUrl="inventory/deleteItemDetail"
            componentName="Incharge"
            isDropHidden={true}
            componentType=""
            optionLabel="name"
            handler={props.state.inputChange}
            disabled={props.state.selDetailIncharge.readOnly}
          />
        </Col>
      </Row>
      <Row className="mb-3" noGutters>
        <Col className="pl-2 pr-2 mb-2" md={6} lg={6} sm={12} xs={12}>
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "var(--REB-primary)",
              borderRadius: "5px",
            }}
          >
            <InputGroup.Prepend>
              <p
                className="m-0 pl-2"
                style={{
                  color: "var(--REB-white)",
                  fontWeight: "bold",

                  // backgroundColor: "var(--REB-primary)",
                }}
              >
                FROM INVENTORY
              </p>
            </InputGroup.Prepend>
            <Select
              styles={customStyles}
              isSearchable
              menuPortalTarget={document.body}
              width="100%"
              options={props.state.selInventory.data}
              value={props.state.selInventoryInvChangeFrom.value}
              isDisabled={props.state.selDetailIncharge.readOnly}
              getOptionLabel={(x) => x.name}
              getOptionValue={(x) => x.id}
              onChange={(x, name) => {
                //console.log(x, name);

                props.state.handleActionChange({
                  target: {
                    value: x,
                    name: "handleInventoryChangeTransferItems",
                  },
                });
              }}
            />
          </div>
        </Col>
        <Col className="pl-2 pr-2" md={6} lg={6} sm={12} xs={12}>
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "var(--REB-primary)",
              borderRadius: "5px",
            }}
          >
            <InputGroup.Prepend>
              <p
                className="m-0 pl-2"
                style={{
                  color: "var(--REB-white)",
                  fontWeight: "bold",

                  // backgroundColor: "var(--REB-primary)",
                }}
              >
                TO INVENTORY
              </p>
            </InputGroup.Prepend>
            <Select
              styles={customStyles}
              isSearchable
              menuPortalTarget={document.body}
              width="100%"
              options={props.state.selInventoryInvChange.data}
              value={props.state.selInventoryInvChange.value}
              isDisabled={props.state.selDetailIncharge.readOnly}
              getOptionLabel={(x) => x.name}
              getOptionValue={(x) => x.id}
              //formatOptionLabel={"test"}
              //innerRef={this.props.propRef}
              onChange={(x, name) => {
                //console.log(x, name);

                props.state.handleActionChange({
                  target: {
                    value: x,
                    name: "selToInventoryTransferInv",
                  },
                });
                // this.props.handler(x, name, {
                //   target: { name: this.props.name },
                // });
              }}
            />
          </div>
        </Col>
      </Row>
      <Row className="mb-3" noGutters>
        <Col className="pl-2 pr-2 mb-2" lg={8} md={7}>
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "var(--REB-primary)",
              borderRadius: "5px",
            }}
          >
            <InputGroup.Prepend>
              <p
                className="m-0 pl-2"
                style={{
                  color: "var(--REB-white)",
                  fontWeight: "bold",

                  // backgroundColor: "var(--REB-primary)",
                }}
              >
                ITEM
              </p>
            </InputGroup.Prepend>
            <Select
              styles={customStyles}
              isSearchable
              menuPortalTarget={document.body}
              width="100%"
              value={props.state.selInventoryInvChangeFromContent.value}
              options={props.state.selInventoryInvChangeFromContent.data}
              isDisabled={props.state.selDetailIncharge.readOnly}
              getOptionLabel={(x) => x.name}
              getOptionValue={(x) => x.id}
              //formatOptionLabel={"test"}
              //innerRef={this.props.propRef}
              onChange={(x, name) => {
                //console.log(x, name);

                props.state.handleActionChange({
                  target: {
                    value: x,
                    name: "selInvoiceInvTransferItem",
                  },
                });
                // this.props.handler(x, name, {
                //   target: { name: this.props.name },
                // });
              }}
            />
          </div>
        </Col>
        <Col className="pl-2 pr-2 mb-3" lg={4} md={5}>
          <TxtQty state={props.state} />
        </Col>
        {props.state.selInventoryInvChangeFromContent.value !== "" &&
        props.state.txtQtyInvChange.value !== "" ? (
          <Col className="pl-2 pr-2" lg={4} md={4}>
            {/* {console.log(props.state.selInventoryInvChangeFromContent.value)} */}
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip id={`actor-tooltip-add`}>ADD ITEM TO TRANSFER</Tooltip>
              }
            >
              <Button
                variant="secondary"
                name="btnAddItemToTransfer"
                //disabled={props.state.selInventoryInvChangeFromContent.readOnly}
                //className="btnAddInvoice icofont-ui-add icofont-1x"
                style={{
                  backgroundColor: "var(--REB-primary)",
                }}
                onClick={(e) => {
                  //console.log(props.state.selInventoryInvChangeFromContent);
                  if (
                    props.state.selInventoryInvChangeFromContent.value === ""
                  ) {
                    props.state.dispatch({
                      type: "MODAL_SHOW",
                      payload: {
                        title: "No selected item",
                        desc: `Please select an item to add to quote`,
                        isYesNo: false,
                      },
                    });
                  } else {
                    if (
                      props.state.TransferInvTableData.find(
                        (x) =>
                          x.item.id ===
                          Number(
                            props.state.selInventoryInvChangeFromContent.value
                              .id
                          )
                      )
                    ) {
                      props.state.dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "Duplicate Data",
                          desc: `This item exists in your selection. If you want to add Qty or modify, please remove this item from your selection.`,
                          isYesNo: false,
                        },
                      });
                    } else {
                      if (
                        props.state.selInventory.value ===
                        props.state.selInventoryInvChange.value
                      ) {
                        props.state.dispatch({
                          type: "MODAL_SHOW",
                          payload: {
                            title: "Selection Notice",
                            desc: `TO and FROM inventory selection should not be the same.`,
                            isYesNo: false,
                          },
                        });
                      } else {
                        props.state.handleActionChange({
                          target: {
                            name: "btnAddItemToTransfer",
                            value: e,
                          },
                        });
                      }
                    }

                    // setIsItem(true);
                    // setIsNew(false);
                    // setIsAdd(true);
                  }

                  //props.state.handleItemModal.toggle();
                }}
              >
                ADD TO TRANSFER
              </Button>
            </OverlayTrigger>
          </Col>
        ) : null}
        {props.state.TransferInvTableSelection.length > 0 &&
        props.state.txtQtyInvChange.value === "" ? (
          <Col className="pl-2 pr-2" lg={4} md={4}>
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip id={`actor-tooltip-add`}>
                  Remove Item From Selection
                </Tooltip>
              }
            >
              <Button
                name="btnRemoveItemToTransfer"
                style={{
                  backgroundColor: "var(--REB-primary)",
                }}
                onClick={(e) => {
                  props.state.handleActionChange({
                    target: {
                      name: "btnRemoveItemToTransfer",
                      value: e,
                    },
                  });
                }}
              >
                REMOVE ITEM FROM SELECTION
              </Button>
            </OverlayTrigger>
          </Col>
        ) : null}
      </Row>
      {(props.state.selDetailStatus.value.name === "Finished" ||
        props.state.selDetailStatus.value.name === "Ongoing Delivery") && (
          <LogisticsContainer state={props.state} />
        )}
      {props.tableTransferInv}
    </>
  );
}
export { TypeTransferInventory, invTransColumn };
