import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import moment from "moment";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";
import Table from "react-table";
import filters from "../../functions/filters";
import accountsApi from "./AccountsAPI";
import invoiceApi from "../Invoice/InvoiceAPI";
import inventoryApi from "../Inventory/InventoryAPI";
import { SELECTED_OPERATION } from "../../constants";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import actorApi from "../Actor/ActorAPI";
import { invoiceColumn } from "../Transactions/TransactionTable";
import {
  tableJournalColumn,
  tableTransactionColumn,
} from "../Transactions/TransactionsProcess";

const initValidateAccount = {
  SelAccountParent: { error: false, message: "" },
  TxtAccountName: { error: false, message: "" },
  SelAccountType: { error: false, message: "" },
  ChkIsAccountParent: { error: false, message: "" },
  UIValidate: { error: false, message: "", validated: false },
};
const customStyles = {
  menu: (provided, state) => {
    //console.log(state);
    const width = "100%";

    let color = state.selectProps.menuColor;
    const padding = 0;

    return { ...provided, width, color, padding };
  },
  control: (provided) => ({
    ...provided,
    borderRadius: "5px",
  }),

  container: (_, { selectProps: { width } }) => ({
    width: width,
    flex: 1,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,

    borderRadius: "5px 0px 0px 5px",
  }),

  option: (provided, state) => {
    let backgroundColor = "";
    let display = "block";
    let color = "black";
    if (state.data.isDefault === 1) {
      backgroundColor = "rgba(255, 0, 0, 0.2)";
    }
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    //console.log(state.data);
    if (state.data.isHidden === 1) {
      display = "none";
    }

    return {
      ...provided,
      opacity,
      transition,
      backgroundColor,
      color,
      display,
    };
  },
};

export const UI_TYPE = {
  AccountsUI: {
    value: "accounts",
    label: "Accounts",
  },
  EntriesUI: {
    value: "entries",
    label: "Entries",
  },
};

export const AccountsProcess = () => {
  const dispatch = useDispatch();

  const [AccountSelection, setAccountSelection] = useState([]);
  const [SelectedAccountList, setSelectedAccountList] = useState(null);

  const [AccountData, setAccountData] = useState([]);
  const [AccountDataCopy, setAccountDataCopy] = useState([]);
  const [AccountLoading, setAccountLoading] = useState(false);

  //Inventory Select
  const [InventoryData, setInventoryData] = useState([]);
  const [SelInventory, setSelInventory] = useState([]);
  const [SelInventoryLoading, setSelInventoryLoading] = useState(true);
  const [SelInventoryDisabled, setSelInventoryDisabled] = useState(false);

  //Invoice Details Data
  const [InvoiceDetails, setInvoiceDetails] = useState([]);

  const [DETAIL_TYPE, setDETAIL_TYPE] = useState([]);
  const [DETAIL_INVOICE_STATUS, setDETAIL_INVOICE_STATUS] = useState([]);
  const [DETAIL_INVOICE_CONTENT_STATUS, setDETAIL_INVOICE_CONTENT_STATUS] =
    useState([]);
  const [DETAIL_INVOICE_CONTENT_TYPE, setDETAIL_INVOICE_CONTENT_TYPE] =
    useState([]);

  //UI

  const [AccountSelectedOperation, setAccountSelectedOperation] = useState(
    SELECTED_OPERATION.VIEW
  );

  const [TxtNotes, setTxtNotes] = useState("");
  const [TxtAccountName, setTxtAccountName] = useState("");

  const [UIViewType, setUIViewType] = useState(UI_TYPE.AccountsUI);

  //Client Select
  const [ClientData, setClientData] = useState([]);
  const [SelClient, setSelClient] = useState(null);
  const [SelClientLoading, setSelClientLoading] = useState(false);
  const [SelClientDisabled, setSelClientDisabled] = useState(false);

  //Account Parent Select
  const [SelAccountParentData, setSelAccountParentData] = useState([]);
  const [SelAccountParent, setSelAccountParent] = useState(null);
  const [SelAccountParentLoading, setSelAccountParentLoading] = useState(false);
  const [SelAccountParentDisabled, setSelAccountParentDisabled] =
    useState(false);
  const [expanded, setExpanded] = useState({});

  //Account Type Select
  const [SelAccountTypeData, setSelAccountTypeData] = useState([]);
  const [SelAccountType, setSelAccountType] = useState(null);
  const [SelAccountTypeLoading, setSelAccountTypeLoading] = useState(false);
  const [SelAccountTypeDisabled, setSelAccountTypeDisabled] = useState(false);

  //REDUX
  const CURRENT_USER = useSelector((state) => state.apiData.userData);
  const CURRENT_USERTYPE = useSelector((state) => state.apiData.userType);

  const permissions = useSelector((state) => {
    const user = state.apiData.userData;
    let _permissions = [];
    if (user && user.permissions) {
      _permissions = JSON.parse(user.permissions);
    }
    return _permissions.toString();
  });

  //INPUTS VALIDATE
  const [ValidateAccount, setValidateAccount] = useState(initValidateAccount);

  //ACCOUNT MODAL
  const [AccountModalOpen, setAccountModalOpen] = useState(false);
  const [ChkIsAccountParent, setChkIsAccountParent] = useState(false);
  //console.log(CURRENT_USER);

  const [RunJournalReport, setRunJournalReport] = useState(false);

  const [TxtReportDateStart, setTxtReportDateStart] = useState(
    moment().subtract(1, "days").format("YYYY-MM-DD")
  );
  const [TxtReportDateEnd, setTxtReportDateEnd] = useState(
    moment().add(1, "days").format("YYYY-MM-DD")
  );

  //Entries Data
  const [EntriesData, setEntriesData] = useState([]);
  const [BakEntriesData, setBakEntriesData] = useState([]);

  const [EntryTotal, setEntryTotal] = useState(0.0);

  const [EntrySelection, setEntrySelection] = useState([]);
  const [SelectedEntryList, setSelectedEntryList] = useState(null);
  const [EntryLoading, setEntryLoading] = useState(false);

  useEffect(() => {
    setUIViewType(UI_TYPE.AccountsUI);

    refreshInventory(true);
    getInvoiceDetailsConst();
    getActors();
  }, []);
  useEffect(() => {
    refreshAccounts(0);
  }, [SelInventory]);

  const validateAccount = (validateComponents, cb) => {
    let tempUserInput = {
      ...ValidateAccount,
      UIValidate: { error: false, message: "", validated: false },
    };

    if (ChkIsAccountParent) {
      if (
        validateComponents.includes("SelAccountType") ||
        validateComponents.includes("all")
      ) {
        SelAccountType !== null
          ? (tempUserInput.SelAccountType = { error: false, message: "" })
          : (tempUserInput.SelAccountType = {
              error: true,
              message: "Account Type cant be empty",
            });
      }
    } else {
      if (
        validateComponents.includes("SelAccountParent") ||
        validateComponents.includes("all")
      ) {
        SelAccountParent !== null
          ? (tempUserInput.SelAccountParent = { error: false, message: "" })
          : (tempUserInput.SelAccountParent = {
              error: true,
              message: "Parent Account cant be empty",
            });
      }
    }
    if (
      validateComponents.includes("TxtAccountName") ||
      validateComponents.includes("all")
    ) {
      TxtAccountName !== ""
        ? (tempUserInput.TxtAccountName = { error: false, message: "" })
        : (tempUserInput.TxtAccountName = {
            error: true,
            message: "Account name cant be empty",
          });
    }

    Object.keys(tempUserInput).forEach((key) => {
      if (tempUserInput[key].error) {
        tempUserInput.UIValidate = {
          error: true,
          message: "",
          validated: true,
        };
      }
    });
    setValidateAccount(tempUserInput);
    if (tempUserInput.UIValidate.error) {
      dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Validation Error",
          desc: `Please check inputs`,
          isYesNo: false,
        },
      });
    } else {
      if (cb) cb();
    }
  };

  const getInvoiceDetailsConst = async () => {
    //const detData = await invoiceApi.getInvoiceDetailsConst(dispatch);
    const DETAILS = await invoiceApi.getAllInvoiceDetail(dispatch);

    const _DETAIL_TYPE = DETAILS.find((x) => x.name === "Type");
    const _DETAIL_INVOICE_STATUS = DETAILS.find((x) => x.name === "Status");
    const _DETAIL_INVOICE_CONTENT_STATUS = DETAILS.find(
      (x) => x.name === "ContentStatus"
    );
    const _DETAIL_INVOICE_CONTENT_TYPE = DETAILS.find(
      (x) => x.name === "ContentType"
    );

    setDETAIL_TYPE(_DETAIL_TYPE.details);
    setDETAIL_INVOICE_STATUS(_DETAIL_INVOICE_STATUS.details);
    setDETAIL_INVOICE_CONTENT_STATUS(_DETAIL_INVOICE_CONTENT_STATUS.details);
    setDETAIL_INVOICE_CONTENT_TYPE(_DETAIL_INVOICE_CONTENT_TYPE.details);

    setInvoiceDetails(DETAILS);
  };

  const refreshInventory = async (initial) => {
    setSelInventoryLoading(true);
    let inventories = [];
    inventories = await inventoryApi.getInventory(dispatch);

    if (inventories.length === 0) {
      dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Inventory Empty",
          desc: `You dont have permission to access any inventory. Please contact your administrator`,
          isYesNo: false,
        },
      });
    } else {
      setInventoryData(inventories);
      setSelInventoryLoading(false);
      if (initial) {
        setSelInventory(inventories[0]);
        refreshAccounts(0);
      }
    }
  };

  const refreshAccounts = async (runReport) => {
    setAccountLoading(true);

    const filter = {
      inventoryId: Number(SelInventory.id),
      ...(TxtReportDateStart && runReport
        ? { dateStart: moment(TxtReportDateStart).format("YYYY-MM-DD") }
        : null),
      ...(TxtReportDateEnd && runReport
        ? { dateEnd: moment(TxtReportDateEnd).format("YYYY-MM-DD") }
        : null),
      // ...(runReport ? { generateReport: runReport } : { generateReport: 0 }),
    };
    if (InventoryData.length > 0) {
      //console.log(InventoryData[0].id);
      const accounts = await accountsApi.getAllAccounts(
        dispatch,
        filter,
        1,
        Number(runReport)
      );
      // parentAccounts = await accountsApi.getParentAccounts(dispatch, filter);
      // const accountTypes = await accountsApi.getAccountTypes(dispatch, filter);
      //console.log(accounts);
      if (accounts) {
        setAccountData([]);

        setAccountData(accounts.accounts);
        setAccountDataCopy(accounts.accounts);

        setSelAccountTypeData(accounts.accountTypes);
        setSelAccountParentData(accounts.parentAccounts);
      }

      setAccountLoading(false);
    }
  };

  const getActors = async (e) => {
    const ACTORS = await actorApi.getAllActors(dispatch);
    const _clients = ACTORS.find((x) => x.name === "Client");
    if (_clients) {
      const WALK_IN = _clients.actors.find((x) => x.company === "WALK-IN");
      if (WALK_IN) {
        setSelClient(WALK_IN);
      }
    }

    setClientData(_clients.actors);
  };

  const AccountSearchHandler = (event) => {
    if (event.target.value !== "") {
      const data = filters.filterData(AccountDataCopy, event.target.value);

      if (data != null) {
        setAccountData(data);
      } else {
        setAccountData(AccountDataCopy);
      }
    } else {
      setAccountData(AccountDataCopy);
    }
  };

  const handleAccountsReset = () => {
    setAccountSelection([]);
    setSelectedAccountList(null);

    setAccountLoading(false);
    setTxtAccountName("");
    setSelAccountParent(null);
    setSelAccountType(null);
    setValidateAccount(initValidateAccount);
  };

  const populateAccountModal = async (row) => {
    if (row.parentId) {
      setChkIsAccountParent(false);
    } else {
      setChkIsAccountParent(true);
    }
    let accountType = null;
    accountType = SelAccountTypeData.find((x) => x.value === row.accountType);
    if (row.parentId) {
      let parentAccount = null;
      parentAccount = SelAccountParentData.find((x) => x.id === row.parentId);
      setSelAccountParent(parentAccount);
    }

    setSelAccountType(accountType);
    setTxtAccountName(row.name);
  };

  const getAccountEntries = async () => {
    const filter = {
      inventoryId: Number(SelInventory.id),
      ...(TxtReportDateStart
        ? { dateStart: moment(TxtReportDateStart).format("YYYY-MM-DD") }
        : null),
      ...(TxtReportDateEnd
        ? { dateEnd: moment(TxtReportDateEnd).format("YYYY-MM-DD") }
        : null),
      // ...(runReport ? { generateReport: runReport } : { generateReport: 0 }),
    };
    setEntryLoading(true);
    const accountEntries = await accountsApi.getAllTransactionsByAccount(
      dispatch,
      filter,
      SelectedAccountList.id
    );
    setEntryLoading(false);
    //console.log(accountEntries);
    if (accountEntries) {
      setEntriesData(accountEntries);
      setBakEntriesData(accountEntries);

      let total = 0;
      total = accountEntries.reduce((a, c) => {
        return a + c.amountValue;
      }, 0);
      setEntryTotal(total);
    }
  };

  const createAccount = async (cb) => {
    let InventoryId = Number(SelInventory.id);
    // console.log(SelInventory);
    if (InventoryId === 1) {
      InventoryId = null;
    }
    //console.log(SelAccountParent);
    const data = {
      name: TxtAccountName,
      inventoryId: InventoryId,

      ...(!ChkIsAccountParent ? { parentId: SelAccountParent.id } : null),
      ...(!ChkIsAccountParent
        ? { accountType: SelAccountParent.accountType }
        : { accountType: SelAccountType.value }),
    };
    console.log("PRE-POST DATA ===>", data);

    const createdInvoice = await accountsApi.createAccount(
      dispatch,
      data,
      () => {
        if (cb) {
          // handleAccountsReset();
          cb();
        }
        refreshAccounts(0);
        setAccountModalOpen(false);
      },
      () => {
        dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Account Creation Error",
            desc: `Please Contact Administrator`,
            isYesNo: false,
          },
        });
      }
    );
    // setCreatedInvoice(createdInvoice.data);

    handleAccountsReset();
    //console.log("CREATED INVOICE ===> ", createdInvoice);
  };

  const findAccountAndReplace = async (account) => {
    let updatedData = [];
    if (!account.parentId) {
      updatedData = AccountData.map((x) =>
        x.id === account.id ? { ...account } : x
      );
      if (updatedData) {
        setAccountData(updatedData);
        setAccountDataCopy(updatedData);
      }
    } else {
      const parentAccount = SelAccountParentData.find(
        (x) => x.id === account.parentId
      );

      let updatedChildrens = null;
      if (parentAccount) {
        updatedChildrens = parentAccount.childrens.map((x) =>
          x.id === account.id ? { ...account } : x
        );
      }
      const updatedParent = { ...parentAccount, childrens: updatedChildrens };

      if (updatedChildrens) {
        updatedData = AccountData.map((x) =>
          x.id === updatedParent.id ? { ...updatedParent } : x
        );
        if (updatedData) {
          setAccountData(updatedData);
          setAccountDataCopy(updatedData);
        }
      }
    }
  };

  const modifyAccount = async (cb) => {
    const data = {
      name: TxtAccountName,
      id: SelectedAccountList.id,

      ...(!ChkIsAccountParent ? { parentId: SelAccountParent.id } : null),
      ...(ChkIsAccountParent ? { accountType: SelAccountType.value } : null),
    };
    console.log("PRE-POST UPDATE DATA ===>", data);

    const updatedInvoice = await accountsApi.updateAccount(
      dispatch,
      data,
      (success) => {
        if (cb) {
          // handleAccountsReset();
          cb();
        }
        findAccountAndReplace(success.data);
        setAccountModalOpen(false);
        //console.log(success);
      },
      () => {
        dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Account Modification Error",
            desc: `Please Contact Administrator`,
            isYesNo: false,
          },
        });
      }
    );
    // setCreatedInvoice(createdInvoice.data);

    handleAccountsReset();
    //console.log("CREATED INVOICE ===> ", createdInvoice);
  };

  const tableAccountColumn = [
    {
      Header: () => (
        <p
          style={{
            fontSize: "0.70em",
            color: "var(--REB-primary)",
            fontWeight: "bolder",
            margin: 0,
          }}
        >
          Account Name
        </p>
      ),
      id: "name",
      getProps: (row) => {
        return {
          style: {
            padding: 0,
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        };
      },
      Cell: (row) => {
        return (
          <p
            style={{
              // fontSize: "0.8rem",
              overflow: "hidden",

              // textOverflow: "ellipsis",
              whiteSpace: "initial",
              // lineHeight: "1em",
              width: "100%",
            }}
          >
            {row.original.name}
          </p>
        );
      },
      accessor: (d) => (d.name === null ? "-" : d.name),
    },

    {
      Header: () => (
        <p
          style={{
            fontSize: "0.70em",
            color: "var(--REB-primary)",
            fontWeight: "bolder",
            margin: 0,
          }}
        >
          Total
        </p>
      ),
      id: "price",
      maxWidth: 120,
      accessor: (d) => (d.price ? d.price : 0),
      Cell: (row) => {
        const d = row.original;
        return (
          <NumericFormat
            value={d.accountTotal}
            displayType={"text"}
            thousandSeparator={true}
          />
        );
      },
    },
  ];

  const AccountSelectHandler = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      e.preventDefault();
      //e.stopPropagation();

      if (
        !(
          e.target.classList.contains("rt-expander") ||
          e.target.classList.contains("rt-expandable")
        )
      ) {
        if (
          AccountSelection.length > 0 &&
          AccountSelection[0] === `select-${rowInfo.original.id}`
        ) {
          setAccountSelection([]);
          setSelectedAccountList(null);
        } else {
          if (rowInfo) {
            console.log("SELECTED ACCOUNT ===>", rowInfo.original);
            setAccountSelection(["select-" + rowInfo.original.id]);
            setSelectedAccountList(rowInfo.original);
            populateAccountModal(rowInfo.original);
            setAccountModalOpen(true);
          }
        }
      }

      if (handleOriginal) {
        handleOriginal();
      }
    },
    style: {
      background:
        rowInfo &&
        AccountSelection.includes(`select-${rowInfo.original.id}`) &&
        "var(--REB-green)",
      color:
        rowInfo &&
        AccountSelection.includes(`select-${rowInfo.original.id}`) &&
        "var(--REB-white)",
    },
  });
  const EntrytSelectHandler = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      e.preventDefault();
      //e.stopPropagation();

      if (
        !(
          e.target.classList.contains("rt-expander") ||
          e.target.classList.contains("rt-expandable")
        )
      ) {
        if (
          AccountSelection.length > 0 &&
          AccountSelection[0] === `select-${rowInfo.original.id}`
        ) {
          setEntrySelection([]);
          setSelectedEntryList(null);
        } else {
          if (rowInfo) {
            console.log("SELECTED ENTRY ===>", rowInfo.original);
            setEntrySelection(["select-" + rowInfo.original.id]);
            setSelectedEntryList(rowInfo.original);
            //populateAccountModal(rowInfo.original);
            //setAccountModalOpen(true);
          }
        }
      }

      if (handleOriginal) {
        handleOriginal();
      }
    },
    style: {
      background:
        rowInfo &&
        AccountSelection.includes(`select-${rowInfo.original.id}`) &&
        "var(--REB-green)",
      color:
        rowInfo &&
        AccountSelection.includes(`select-${rowInfo.original.id}`) &&
        "var(--REB-white)",
    },
  });

  const TableAccountsChildren = (props) => {
    const { row } = props;
    return (
      <>
        <style>
          {`
            @media (max-width: 991px) {
              .tableAccount {
                border-radius: 0px !important;
                background-color: var(--REB-white) !important;
                margin-top: 0px !important;
                margin-bottom: 0px;
              }
            }
            @media (min-width: 992px) {
              .rt-thead {
                padding-right: 20px;
              }
              .tableAccount {
                border-radius: 10px !important;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                background-color: var(--REB-white) !important;
                margin-top: 30px;
              }
            }
          `}
        </style>
        <Table
          data={row.childrens}
          keyField="id"
          columns={tableAccountColumn}
          loading={AccountLoading}
          toggleSelection={AccountSelectHandler}
          isSelected={(key) =>
            AccountSelection.includes(`select-${key}-ACCOUNTLIST`)
          }
          getTrProps={AccountSelectHandler}
          defaultPageSize={10}
          showPageSizeOptions={true}
          showPageJump={false}
          showPagination={true}
          style={{
            overflow: "auto",
            // maxHeight: "50vh",
            backgroundColor: "var(--REB-bg-color)",
          }}
          className="-highlight mt-2"
        />
      </>
    );
  };
  const tableAccounts = (
    <>
      <style>
        {`
          @media (max-width: 991px) {
            .tableAccount {
              border-radius: 0px !important;
              background-color: var(--REB-white) !important;
              margin-top: 0px !important;
              margin-bottom: 0px;
            }
          }
          @media (min-width: 992px) {
            .rt-thead {
              padding-right: 20px;
            }
            .tableAccount {
              border-radius: 10px !important;
              box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
              background-color: var(--REB-white) !important;
              margin-top: 30px;
            }
          }
        `}
      </style>
      <Table
        data={AccountData}
        keyField="id"
        columns={tableAccountColumn}
        loading={AccountLoading}
        toggleSelection={AccountSelectHandler}
        isSelected={(key) =>
          AccountSelection.includes(`select-${key}-ACCOUNTLIST`)
        }
        getTrProps={AccountSelectHandler}
        defaultPageSize={200}
        showPageSizeOptions={false}
        showPageJump={false}
        showPagination={false}
        SubComponent={(row) => {
          return <TableAccountsChildren row={row.original} />;
        }}
        expanded={expanded}
        onExpandedChange={(expanded, index, event) => {
          // console.log(index, expanded);
          // don't for get to save the 'expanded'
          // so it can be fed back in as a prop

          // console.log(expanded);
          setExpanded(expanded);
        }}
        style={{
          overflow: "auto",
          // maxHeight: "50vh",
          backgroundColor: "var(--REB-bg-color)",
        }}
        className="tableAccount tableActor -highlight mt-2"
      />
    </>
  );

  const tableEntries = (
    <>
      <style>
        {`
          @media (max-width: 991px) {
            .tableEntries {
              border-radius: 0px !important;
              background-color: var(--REB-white) !important;
              margin-top: 0px !important;
              margin-bottom: 0px;
            }
          }
          @media (min-width: 992px) {
            .rt-thead {
              padding-right: 20px;
            }
            .tableEntries {
              border-radius: 10px !important;
              box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
              background-color: var(--REB-white) !important;
              margin-top: 30px;
            }
          }
        `}
      </style>
      <Table
        data={EntriesData}
        keyField="id"
        columns={tableJournalColumn}
        loading={EntryLoading}
        toggleSelection={EntrytSelectHandler}
        isSelected={(key) =>
          AccountSelection.includes(`select-${key}-ENTRY-LIST`)
        }
        getTrProps={EntrytSelectHandler}
        defaultPageSize={30}
        showPageSizeOptions={false}
        showPageJump={true}
        showPagination={true}
        style={{
          overflow: "auto",
          // maxHeight: "50vh",
          backgroundColor: "var(--REB-bg-color)",
        }}
        className="tableEntries tableActor -highlight mt-2"
      />
    </>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        maxHeight: "100% !important",
        overflow: "hidden",
        // marginTop: "-30px",
      }}
    >
      {/* ACCOUNTS MODAL */}
      <Modal
        show={AccountModalOpen}
        onHide={() => {
          setAccountModalOpen(false);
          setAccountSelectedOperation(SELECTED_OPERATION.VIEW);
        }}
        onExit={(e) => {
          setAccountModalOpen(false);
          setAccountSelectedOperation(SELECTED_OPERATION.VIEW);
        }}
        dialogClassName=""
        centered
      >
        <Modal.Header
          className="p-0 global-modal-header"
          style={{ backgroundColor: "var(--REB-primary)" }}
        >
          <Modal.Title className="p-2 global-modal-header-title w-100">
            <Row className="w-100" noGutters>
              <Col xs={"auto"} className="h-100">
                <Button
                  style={{ backgroundColor: "var(--REB-warning)" }}
                  onClick={() => {
                    setAccountModalOpen(false);
                  }}
                  className="h-100"
                >
                  <i className="icofont-ui-close"></i>
                </Button>
              </Col>
              <Col xs={"auto"} className="pl-3" style={{ alignSelf: "center" }}>
                <h6
                  className="text-center m-0 h-100"
                  style={{ color: "var(--REB-white)", fontWeight: "bolder" }}
                >
                  {AccountSelectedOperation.name} Account
                </h6>
              </Col>
              <Col
                xs={"auto"}
                className="h-100 d-flex justify-content-end"
                style={{ flex: 1 }}
              >
                {AccountSelectedOperation === SELECTED_OPERATION.VIEW && (
                  <Button
                    style={{ backgroundColor: "var(--REB-green)" }}
                    onClick={() => {
                      setUIViewType(UI_TYPE.EntriesUI);
                      setAccountModalOpen(false);
                      getAccountEntries();
                    }}
                    className="h-100"
                  >
                    VIEW ENTRIES
                  </Button>
                )}
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!ChkIsAccountParent && (
            <div className="px-2 mb-3">
              <Form.Label
                className="font-weight-bold m-0"
                style={{ color: "var(--REB-black)" }}
              >
                <span className="required-field">* </span>
                Parent Account Name
              </Form.Label>
              <Select
                styles={customStyles}
                isSearchable
                menuPortalTarget={document.body}
                width="100%"
                value={SelAccountParent}
                options={SelAccountParentData}
                isDisabled={
                  AccountSelectedOperation === SELECTED_OPERATION.VIEW
                }
                getOptionLabel={(x) => x.name}
                getOptionValue={(x) => x.id}
                //formatOptionLabel={"test"}
                //innerRef={this.props.propRef}
                onChange={(x, name) => {
                  //console.log(x, name);
                  setSelAccountParent(x);
                }}
              />
              {ValidateAccount.SelAccountParent.error && (
                <p style={{ color: " var(--REB-warning)", fontSize: "0.8rem" }}>
                  {ValidateAccount.SelAccountParent.message}
                </p>
              )}
            </div>
          )}
          <div className="px-2">
            <Form.Label
              className="font-weight-bold m-0"
              style={{ color: "var(--REB-black)" }}
            >
              <span className="required-field">* </span>
              Account Name
            </Form.Label>
            <FormControl
              type="text"
              placeholder="Account Name"
              isInvalid={ValidateAccount.TxtAccountName.error}
              value={TxtAccountName}
              onChange={(e) => setTxtAccountName(e.currentTarget.value)}
              disabled={AccountSelectedOperation === SELECTED_OPERATION.VIEW}
            />

            {ValidateAccount.TxtAccountName.error && (
              <p style={{ color: " var(--REB-warning)", fontSize: "0.8rem" }}>
                {ValidateAccount.TxtAccountName.message}
              </p>
            )}
          </div>
          <div className="px-2">
            <Form.Check
              checked={ChkIsAccountParent}
              disabled={AccountSelectedOperation === SELECTED_OPERATION.VIEW}
              className=""
              label="Is Parent Account"
              onChange={(e) => {
                setChkIsAccountParent(!ChkIsAccountParent);
              }}
            />
          </div>
          {ChkIsAccountParent && (
            <div className="px-2 mb-3">
              <Form.Label
                className="font-weight-bold m-0"
                style={{ color: "var(--REB-black)" }}
              >
                <span className="required-field">* </span>
                Account Type
              </Form.Label>
              <Select
                styles={customStyles}
                isSearchable
                menuPortalTarget={document.body}
                width="100%"
                value={SelAccountType}
                options={SelAccountTypeData}
                isDisabled={
                  AccountSelectedOperation === SELECTED_OPERATION.VIEW
                }
                // getOptionLabel={(x) => x.name}
                // getOptionValue={(x) => x.id}
                //formatOptionLabel={"test"}
                //innerRef={this.props.propRef}
                onChange={(x, name) => {
                  //console.log(x, name);
                  setSelAccountType(x);
                }}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="global-modal-footer">
          <Row className="w-100" noGutters>
            {(AccountSelectedOperation === SELECTED_OPERATION.ADD ||
              AccountSelectedOperation === SELECTED_OPERATION.EDIT) && (
              <Col xs={4} sm={4} md={4} lg={4} className="px-2">
                <Button
                  block
                  name="btnSubmitInvoice"
                  //disabled={props.state.handleInvoiceModal.isView}
                  style={{ backgroundColor: "var(--REB-green)" }}
                  onClick={(e) => {
                    AccountSelectedOperation === SELECTED_OPERATION.ADD &&
                      validateAccount(["all"], () => createAccount());

                    AccountSelectedOperation === SELECTED_OPERATION.EDIT &&
                      validateAccount(["all"], () => modifyAccount());
                  }}
                >
                  SAVE
                </Button>
              </Col>
            )}
            {AccountSelectedOperation === SELECTED_OPERATION.VIEW && (
              <>
                <Col xs={4} sm={4} md={4} lg={4} className=" px-2">
                  <Button
                    block
                    name="btnUnlockInvoice"
                    //disabled={!props.state.handleInvoiceModal.isView}
                    style={{ backgroundColor: "var(--REB-green)" }}
                    onClick={(e) => {
                      setAccountSelectedOperation(SELECTED_OPERATION.EDIT);
                    }}
                  >
                    MODIFY
                  </Button>
                </Col>
                <Col xs={4} sm={4} md={4} lg={4} className="px-2">
                  <Button
                    block
                    name="btnDeleteInvoice"
                    style={{ backgroundColor: "var(--REB-warning)" }}
                    // disabled={
                    //   props.state.handleInvoiceModal.isView ||
                    //   Number(props.state.selectedOperation.id) === 0
                    // }
                    onClick={(e) => {
                      dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "Confirmation",
                          JsxDesc: () => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <p>
                                  Are you sure you want to remove this account?
                                </p>
                                <p>
                                  Doing so will remove all of the transactions
                                  related to this account
                                </p>
                                <p>This action is irreversible</p>
                              </div>
                            );
                          },
                          isYesNo: true,
                          cb: (e) => {
                            accountsApi.deleteAccount(
                              dispatch,
                              SelectedAccountList.id,
                              () => {
                                refreshAccounts(0);
                                setAccountModalOpen(false);
                              }
                            );
                          },
                        },
                      });
                      //props.state.handleActionChange(e);
                    }}
                  >
                    DELETE
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Modal.Footer>
      </Modal>
      <style>
        {`
          .itemListCard {
            box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
              rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
              rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
          }
          @media (max-width: 991px) {
            .globalContainerStyle {
              border-radius: 0px !important;
              margin-top: 0px !important;
            }
            .container {
              padding: 0px !important;
              margin: 0px !important;
            }

            body {
              padding: 0px !important;
              margin: 0px !important;
              overflow: hidden;
            }

            .navbar-fixed-top,
            .navbar-fixed-bottom,
            .navbar-static-top {
              margin-left: 0px !important;
              margin-right: 0px !important;
              margin-bottom: 0px !important;
            }
            .stage {
              padding: 0px !important;
              margin: 0px !important;
            }
          }
          @media (min-width: 992px) {
            .globalContainerStyle {
              border-radius: 10px;
            }
          }
        `}
      </style>

      <div
        style={{ zIndex: "100" }}
        className="globalContainerStyle my-2 p-3 animate__animated animate__fadeInLeft"
      >
        <Row className="" style={{}} noGutters>
          <Col xs={12} sm={12} md={4} lg={4} className="mb-2 px-1">
            <InputGroup className="h-100">
              <InputGroup.Prepend>
                <InputGroup.Text className="actor-ico-search">
                  <i className="icofont-search icofont-1x"></i>
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder="Search"
                aria-label="Username"
                name="searchInput"
                className="actor-searchTable shadow-none h-100"
                aria-describedby="basic-addon1"
                onChange={AccountSearchHandler}
              />
            </InputGroup>
          </Col>

          <Col xs={12} sm={12} md={4} lg={4} className="mb-2 px-1">
            <div
              className=""
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p
                className="m-0 pl-2"
                style={{
                  backgroundColor: "var(--REB-primary)",
                  color: "var(--REB-white)",
                  fontWeight: "bold",
                  fontSize: "0.8rem",
                  width: "230px",
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                SELECTED INVENTORY
              </p>
              <Select
                styles={{
                  menu: (provided, state) => {
                    //console.log(state);
                    const width = "100%";

                    let color = state.selectProps.menuColor;
                    const padding = 0;

                    return { ...provided, width, color, padding };
                  },
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0px 0px 0px 0px",
                  }),

                  container: (_, { selectProps: { width } }) => ({
                    width: width,
                    // flex: 1,
                  }),
                  menuPortal: (provided) => ({
                    ...provided,
                    zIndex: 9999,

                    borderRadius: "5px 0px 0px 5px",
                  }),

                  option: (provided, state) => {
                    let backgroundColor = "";
                    let display = "block";
                    let color = "black";
                    if (state.data.isDefault === 1) {
                      backgroundColor = "rgba(255, 0, 0, 0.2)";
                    }
                    const opacity = state.isDisabled ? 0.5 : 1;
                    const transition = "opacity 300ms";
                    //console.log(state.data);
                    if (state.data.isHidden === 1) {
                      display = "none";
                    }

                    return {
                      ...provided,
                      opacity,
                      transition,
                      backgroundColor,
                      color,
                      display,
                    };
                  },
                }}
                isSearchable
                menuPortalTarget={document.body}
                width="100%"
                options={InventoryData}
                value={SelInventory}
                isLoading={SelInventoryLoading}
                isDisabled={SelInventoryDisabled}
                getOptionLabel={(x) => x.name}
                getOptionValue={(x) => x.id}
                onChange={(x, name) => {
                  setSelInventory(x);
                }}
              />
            </div>
          </Col>

          <Col
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="mb-2 d-flex justify-content-end px-1"
          >
            {UIViewType === UI_TYPE.AccountsUI && (
              <Button
                variant="secondary"
                className=""
                style={{
                  backgroundColor: "var(--REB-tertiary)",
                  fontWeight: "bolder",
                  fontSize: "0.65rem",
                }}
                onClick={(e) => {
                  setAccountModalOpen(true);
                  setAccountSelectedOperation(SELECTED_OPERATION.ADD);
                  handleAccountsReset();
                }}
              >
                <i className="icofont-ui-add"></i> Add Account
              </Button>
            )}
            {UIViewType === UI_TYPE.EntriesUI && (
              <Button
                variant="secondary"
                className=""
                style={{
                  backgroundColor: "var(--REB-tertiary)",
                  fontWeight: "bolder",
                  fontSize: "0.65rem",
                }}
                onClick={(e) => {
                  setUIViewType(UI_TYPE.AccountsUI);
                }}
              >
                <i className="icofont-arrow-left"></i> Back to Accounts
              </Button>
            )}
          </Col>
        </Row>
      </div>
      {UIViewType === UI_TYPE.AccountsUI && (
        <>
          <div
            style={{ zIndex: "100" }}
            className="globalContainerStyle my-2 p-3 animate__animated animate__fadeInLeft"
          >
            <Row className="" style={{}} noGutters>
              <Col md={3} lg={3} sm={12} xs={12} className="px-1">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",

                    borderRadius: "5px",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: TxtReportDateStart
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      width: "220px",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "1rem",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Date Start
                  </p>
                  <FormControl
                    type="date"
                    //placeholder={props.state.txtDeadline.placeholder}
                    placeholder="Date"
                    // onMouseOver={(e) => (e.currentTarget.type = "date")}
                    // onMouseOut={(e) => (e.currentTarget.type = "text")}
                    name="dpStartDate"
                    value={TxtReportDateStart}
                    onChange={(x) => {
                      setTxtReportDateStart(x.currentTarget.value);
                    }}
                  />
                </div>
              </Col>
              <Col md={3} lg={3} sm={12} xs={12} className="px-1">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    //backgroundColor: "var(--REB-primary)",
                    borderRadius: "5px",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: TxtReportDateEnd
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      width: "220px",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "1rem",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Date End
                  </p>
                  <FormControl
                    type="date"
                    //placeholder={props.state.txtDeadline.placeholder}
                    placeholder="Date"
                    // onMouseOver={(e) => (e.currentTarget.type = "date")}
                    // onMouseOut={(e) => (e.currentTarget.type = "text")}
                    name="dpStartDate"
                    value={TxtReportDateEnd}
                    onChange={(x) => {
                      setTxtReportDateEnd(x.currentTarget.value);
                    }}
                  />
                </div>
              </Col>
              {(CURRENT_USERTYPE.includes("Admin") ||
                permissions.includes("perm-ui-invoicingPage-view-write")) && (
                <>
                  <Col md={3} lg={3} sm={12} xs={12} className="px-1">
                    <Button
                      onClick={() => {
                        setRunJournalReport(true);
                        refreshAccounts(1);
                      }}
                      className="h-100"
                      style={{
                        fontWeight: "bolder",
                        backgroundColor: "var(--REB-primary)",
                      }}
                    >
                      Run Accounts Report
                    </Button>
                  </Col>
                  <Col md={3} lg={3} sm={12} xs={12} className="px-1">
                    <Button
                      onClick={() => {
                        setRunJournalReport(false);
                        refreshAccounts(0);
                      }}
                      className="h-100"
                      style={{
                        fontWeight: "bolder",
                        backgroundColor: "var(--REB-primary)",
                      }}
                    >
                      Reset
                    </Button>
                  </Col>
                </>
              )}
            </Row>
          </div>

          {tableAccounts}
        </>
      )}
      {UIViewType === UI_TYPE.EntriesUI && (
        <>
          <div
            style={{ zIndex: "100" }}
            className="globalContainerStyle my-2 p-3 animate__animated animate__fadeInLeft"
          >
            <Row className="" style={{}} noGutters>
              <Col md={3} lg={3} sm={12} xs={12} className="px-1">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",

                    borderRadius: "5px",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: TxtReportDateStart
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      width: "220px",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "1rem",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Date Start
                  </p>
                  <FormControl
                    type="date"
                    //placeholder={props.state.txtDeadline.placeholder}
                    placeholder="Date"
                    // onMouseOver={(e) => (e.currentTarget.type = "date")}
                    // onMouseOut={(e) => (e.currentTarget.type = "text")}
                    name="dpStartDate"
                    value={TxtReportDateStart}
                    // onChange={(x) => {
                    //   setTxtReportDateStart(x.currentTarget.value);
                    // }}
                    readOnly
                  />
                </div>
              </Col>
              <Col md={3} lg={3} sm={12} xs={12} className="px-1">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    //backgroundColor: "var(--REB-primary)",
                    borderRadius: "5px",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: TxtReportDateEnd
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      width: "220px",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "1rem",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Date End
                  </p>
                  <FormControl
                    type="date"
                    //placeholder={props.state.txtDeadline.placeholder}
                    placeholder="Date"
                    // onMouseOver={(e) => (e.currentTarget.type = "date")}
                    // onMouseOut={(e) => (e.currentTarget.type = "text")}
                    name="dpStartDate"
                    value={TxtReportDateEnd}
                    // onChange={(x) => {
                    //   setTxtReportDateEnd(x.currentTarget.value);
                    // }}
                    readOnly
                  />
                </div>
              </Col>
              <Col md={3} lg={3} sm={12} xs={12} className="px-1">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    //backgroundColor: "var(--REB-primary)",
                    borderRadius: "5px",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: TxtReportDateEnd
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      width: "220px",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "1rem",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Period Total:
                  </p>
                  <FormControl
                    as={NumericFormat}
                    inputMode="numeric"
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    name="txtPeriodTotal"
                    value={EntryTotal}
                    readOnly={true}
                  />
                </div>
              </Col>
            </Row>
          </div>

          {tableEntries}
        </>
      )}
    </div>
  );
};
