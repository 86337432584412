import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import { NumericFormat } from "react-number-format";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import InputGroup from "react-bootstrap/InputGroup";
let TxtReqQty = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      <span className="required-field">* </span>
      {props.state.txtRequestQty.label}
    </Form.Label>
    <FormControl
      as={NumericFormat}
      inputMode="numeric"
      thousandSeparator={true}
      // prefix={"₱ "}
      suffix={` ${props.state.selDetailUnit.value.name}`}
      style={{}}
      placeholder={props.state.txtRequestQty.placeHolder}
      name="txtRequestQty"
      value={props.state.txtRequestQty.value}
      onValueChange={(x) => {
        props.state.inputChange({
          target: {
            value: Number(x.value),
            name: "txtRequestQty",
            placeholder: props.state.txtRequestQty.placeholder,
            className: props.state.txtRequestQty.className,
          },
        });
      }}
      className={props.state.txtRequestQty.className + " css-actorInput"}
      readOnly={props.state.txtRequestQty.readOnly}
    />
  </>
);

let TxtSelectedItem = (props) => (
  <>
    <InputGroup>
      <InputGroup.Prepend>
        <InputGroup.Text
          style={{
            backgroundColor: "var(--REB-primary)",
            height: "fit-content",
          }}
        >
          <span
            style={{
              alignSelf: "center",
              color: "var(--REB-white)",
            }}
          >
            SELECTED ITEM
          </span>
        </InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl
        style={{}}
        //placeholder={props.state.txtRequestQty.placeHolder}

        defaultValue={props.state.selDetailName.value.name}
        readOnly={true}
      />
    </InputGroup>
  </>
);

let TxtReqNotes = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      Notes
    </Form.Label>
    <FormControl
      as="textarea"
      // style={{ textTransform: "uppercase" }}
      placeholder="Notes"
      name="txtRequestNote"
      value={props.state.txtRequestNote.value}
      onChange={props.state.inputChange}
      className={props.state.txtRequestNote.className + " css-actorInput mb-2"}
      readOnly={props.state.txtRequestNote.readOnly}
    />
  </>
);

const ItemRequestModal = (props) => (
  <Modal
    show={props.state.handleItemRequestModal.isShow}
    onHide={props.state.handleItemRequestModal.toggle}
    // onExit={(e) => {
    //   props.state.handleItemRequestModal.handleExit(e);
    // }}
    dialogClassName="modal-actor-100w modalChildBackground"
    //size="xl"
    centered
  >
    <Modal.Body className="actor-modal-body">
      <Row className="actor-modal-header-row pt-3 pb-3 mb-3">
        <Col xs={2} sm={1} md={1} lg={1}>
          <OverlayTrigger
            placement={"right"}
            overlay={<Tooltip id={`project-tooltip-add`}>Close</Tooltip>}
          >
            <Button
              variant="secondary"
              name="btnCloseProjectModal"
              style={{ backgroundColor: "var(--REB-primary)" }}
              className="icofont-rounded-left"
              onClick={(e) => {
                props.state.handleItemRequestModal.toggle();
              }}
            />
          </OverlayTrigger>
        </Col>
        <Col xs={10} sm={11} md={11} lg={11} className="actor-modal-header-col">
          <h4 className=" actor-modal-header-text">ITEM REQUEST</h4>
        </Col>
      </Row>

      <Form
        id="formActorInput"
        noValidate
        validated={props.state.handleItemRequestModal.isValidated}
        onSubmit={props.state.handleItemRequestModal.handleSubmit}
      >
        <Row className="mb-3" noGutters>
          <Col md={4} lg={4} className="pr-2">
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  style={{
                    backgroundColor: "var(--REB-primary)",
                    height: "fit-content",
                  }}
                >
                  <span
                    style={{
                      alignSelf: "center",
                      color: "var(--REB-white)",
                    }}
                  >
                    REQUEST TYPE
                  </span>
                </InputGroup.Text>
              </InputGroup.Prepend>
              <select
                className="form-control actor-selector w-auto"
                name="selItemRequestType"
                value={props.state.requisitionType}
                onChange={props.state.handleActionChange}
                disabled={props.state.txtRequestNote.readOnly}
              >
                <option value={"Item Requisition"}>Item Order Request</option>
                {/* <option value={2}>Add Item Qty Request</option> */}
                {/* <option value={"Add Item Qty Request"}>
                  Add Item Qty Request
                </option>
                <option value={"Deduct Item Qty Request"}>
                  Deduct Item Qty Request
                </option>
                <option value={"Return Item Request"}>
                  Return Item Request
                </option> */}
              </select>
            </InputGroup>
          </Col>
          <Col md={8} lg={8}>
            <TxtSelectedItem state={props.state} />
          </Col>
        </Row>
        <TxtReqQty state={props.state} inputChange={props.inputChange} />
        <TxtReqNotes state={props.state} inputChange={props.inputChange} />
        <Row
          className="mb-3 mt-3 actor-modal-footer-row actor-modal-footer"
          noGutters
        >
          <Col md={6} lg={6} className="">
            <p className="font-weight-bold">Note:</p>
            <p className="">
              Fields that has asterisk (
              <span className="required-field font-weight-bold"> * </span>)
              requires an input.
            </p>
          </Col>
          {/* <Col md={2} lg={2} className="user-col-cont-padding">
            <Button
              block
              className=""
              name="btnUnlockItem"
              style={{ backgroundColor: "var(--REB-green)" }}
              disabled={!props.state.handleItemRequestModal.isView}
              onClick={(e) => {
                props.state.handleActionChange(e);
              }}
            >
              MODIFY
            </Button>
          </Col> */}
          <Col md={2} lg={2} className="user-col-cont-padding">
            <Button
              block
              className=""
              style={{ backgroundColor: "var(--REB-green)" }}
              name="btnSubmitItemRequest"
              hidden={
                props.state.tableSelection !== null &&
                props.state.tableSelection.orderStatus !== null &&
                props.state.tableSelection.orderStatus !== undefined
                  ? props.state.tableSelection.orderStatus.name !==
                    "Admin Denied"
                  : // props.state.tableSelection.orderStatus.name ===
                    //   "Admin Approved"
                    false
              }
              //type="submit"
              onClick={(e) => {
                if (
                  props.state.txtRequestQty.value !== "" &&
                  props.state.txtRequestQty.value !== null
                ) {
                  props.state.dispatch({
                    type: "MODAL_SHOW",
                    payload: {
                      title: "Confirmation",
                      desc: `Please make sure that the provided information is correct. Are you sure you want to submit this request?`,
                      isYesNo: true,
                      cb: () => {
                        props.state.handleActionChange(e);
                      },
                    },
                  });
                } else {
                  props.state.dispatch({
                    type: "MODAL_SHOW",
                    payload: {
                      title: "Check Qty",
                      desc: `Please make sure qty is not empty`,
                      isYesNo: false,
                    },
                  });
                }
              }}
            >
              SUBMIT REQUEST
            </Button>
          </Col>
          <Col md={2} lg={2} className="user-col-cont-padding">
            <Button
              block
              className=""
              name="btnCancelItemRequest"
              style={{
                backgroundColor: "var(--REB-yellow)",
                color: "var(--REB-black)",
              }}
              disabled={
                props.state.handleItemRequestModal.isView ||
                Number(props.state.selectedOperation.id) === 0
              }
              onClick={(e) => {
                props.state.handleActionChange(e);
              }}
            >
              CANCEL
            </Button>
          </Col>

          {props.state.tableSelection !== null ? (
            props.state.tableSelection.orderStatus !== undefined ? (
              props.state.tableSelection.orderStatus.name === "Admin Denied" ? (
                <Col md={2} lg={2} className="user-col-cont-padding">
                  <Button
                    block
                    className=""
                    name="btnRemoveItemRequest"
                    style={{
                      backgroundColor: "var(--REB-warning)",
                      color: "var(--REB-white)",
                    }}
                    // disabled={
                    //   props.state.handleItemRequestModal.isView ||
                    //   Number(props.state.selectedOperation.id) === 0
                    // }
                    onClick={(e) => {
                      props.state.dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "Confirmation",
                          desc: `Are you sure you want to remove this request? This action cannot be undone`,
                          isYesNo: true,
                          cb: () => {
                            props.state.handleActionChange(e);
                          },
                        },
                      });
                    }}
                  >
                    DELETE
                  </Button>
                </Col>
              ) : null
            ) : null
          ) : null}
        </Row>
      </Form>
    </Modal.Body>
  </Modal>
);

export { ItemRequestModal };
