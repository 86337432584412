import React from "react";
import Table from "react-table";
//import selectTableHOC from "react-table/lib/hoc/selectTable";
import Filters from "../../functions/filters";
import { Verification, verificationColumn, PRItemColumn } from "./Verification";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import invoiceApi from "../Invoice/InvoiceAPI";
import QuotePDF from "../Invoice/PDF/Quote";
import PR from "../Invoice/PDF/PR";
import DR from "../Invoice/PDF/DR";
import inventoryApi from "../Inventory/InventoryAPI";
import actorApi from "../Actor/ActorAPI";
import verificationApi from "./VerificationAPI";
import { itemColumn, termsColumn, selectedDrColumn } from "../Invoice/Invoice";
//import { PRItemColumn } from "../Invoice/PurchaseRequestContent";
import misc from "../../functions/misc";
import { invTransColumn } from "../Invoice/TransferInventoryContent";
import TransferItem from "../Invoice/PDF/TransferItem";
import VerificationAPI from "./VerificationAPI";
import InvoiceAPI from "../Invoice/InvoiceAPI";
import { pdf } from "@react-pdf/renderer";
import transactionsApi from "../Transactions/TransactionsAPI";
//const SelectTable = selectTableHOC(Table);
import Voucher from "../Transactions/PDF/Voucher";
import filters from "../../functions/filters";
import settingsApi from "../Settings/SettingsAPI";
import LogisticsAPI from "../Invoice/LogisticsAPI";
import Logistics from "../Invoice/PDF/Logistics";
import TransactionsAPI from "../Transactions/TransactionsAPI";
import ChargeBillingReceipt from "../Invoice/PDF/ChargeInvoice";
import { setInvoiceHeaderData } from "../../functions/pdfHeaderData";
import { generateQuotePDF } from "../../functions/pdfGenerate";
import {SELECTED_TABLE_TABS} from "../../constants"
class VerificationProcess extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputChange: this.inputChange.bind(this),
      dispatch: this.props.dispatch,
      handleSearch: this.handleSearch.bind(this),
      handleActionChange: this.handleActionChange.bind(this),
      handleVerificationModal: {
        handleVerificationSubmit: this.handleVerificationSubmit.bind(this),
        handleExit: this.handleVerificationExit.bind(this),
        toggle: this.handleToggleVerificationModal.bind(this),
        isValidated: false,
        isYesNo: true,
        isShow: false,
        isView: false,
        title: "",
        desc: "",
        response: false,
      },
      selectedModalOperation: { id: 0, name: "Add" },
      //VerificationTable
      verificationTableSelection: [],
      selectedRowVerification: null,
      verificationTableData: [],
      verificationTableDataCopy: [],
      verificationTableColumn: verificationColumn(),
      //VerificationContentTable
      verificationTableContentData: [],
      verificationTableContentDataCopy: [],
      verificationTableContentColumn: itemColumn([], []),
      verificationTableContentSelection: [],
      selectedRowVerificationTableContent: null,
      //VerificationTerms
      verificationTableTermsData: [],
      verificationTableTermsDataCopy: [],
      //PDF
      pdfContent: <></>,
      //PDF DATA
      pdfData: [],
      pdfGroupTable: { result: [], _services: [] },
      txtDesc: { value: "", readOnly: true },
      txtNotes: { value: "", readOnly: false },
      txtInvoiceName: { value: "", readOnly: true },
      txtShippingAddress: { value: "", readOnly: true },
      txtDeadline: { value: "", readOnly: true },
      txtDateAdded: { value: "", readOnly: true },
      txtDateLastModified: { value: "", readOnly: true },
      txtClient: { value: "", readOnly: true },
      txtSupplier: { value: "", readOnly: true },
      txtSalesperson: { value: "", readOnly: true },
      txtInvFrom: { value: "", readOnly: true },
      txtInvTo: { value: "", readOnly: true },
      txtDiscount: { value: "", readOnly: true },
      txtDiscounted: { value: "", readOnly: true },
      txtDiscountedWhole: { value: "", readOnly: true },

      txtMarkupPercent: { value: "", readOnly: true },
      txtMarkupAmount: { value: "", readOnly: true },
      txtMarkupWhole: { value: "", readOnly: true },
      //Select
      selDetailClient: { value: "", readOnly: false, data: [] },
      selDetailIncharge: {
        value: "",
        readOnly: false,
        data: [],
      },
      selDetailType: { value: "", readOnly: false, data: [], filteredDat: [] },
      selDetailStatus: { value: "", readOnly: false, data: [] },
      selDetailContentStatus: {
        value: "",
        readOnly: false,
        data: [],
      },
      selDetailContentType: {
        value: "",
        readOnly: false,
        data: [],
      },
      selDetailServices: {
        value: "",
        readOnly: false,
        btnReadOnly: false,
        data: [],
      },
      selInventory: {
        value: "",
        readOnly: false,
        data: [],
      },
      selContentItems: {
        value: null,
        readOnly: false,
        btnReadOnly: false,
        data: [],
        bakData: [],
      },
      selDetailTerms: {
        value: null,
        readOnly: false,
        data: [],
        bakData: [],
      },
      selDetailSupplier: {
        value: "",

        readOnly: false,
        data: [],
      },
      //Inventory
      inventoryData: [],
      InventoryItems: [],
      //Item
      itemData: [],
      //Actors
      supplierData: [],
      clientData: [],
      employees: [],
      //User
      user: this.props.user,
      invoiceContentStatus: [],
      //Select Data
      invoiceDetailsConst: [],
      InvoiceTypes: [],
      InvoiceStatus: [],

      isChangeItem: false,
      isPDFLoading: false,
      prHasNoPrices: false,
      verificationDataCount: {
        Quote: 0,
        DR: 0,
        CI: 0,
        Transfer: 0,
        Archive: 0,
        PR: 0,
        Voucher: 0,
        Logistics: 0,
      },
      verificationTableDataIndi: {
        Quote: [],
        DR: [],
        CI: [],
        Transfer: [],
        Archive: [],
        PR: [],
        Voucher: [],
        Logistics: [],
      },
      selectedTableTab: SELECTED_TABLE_TABS.ALL,
      allTableDataLength: 0,
    };
  }
  onRefreshVerification(data) {}

  async componentDidMount() {
    this.refreshVerificationData();

    const detData = await invoiceApi.getInvoiceDetailsConst(
      this.props.dispatch
    );

    let FilteredInvoiceDetails = [];
    if (detData.OrderDetailId) {
      FilteredInvoiceDetails = detData.OrderDetailId.filter(
        (x) => x.name === "Terms"
      );
    }

    this.setState(
      (x) => ({
        invoiceDetailsConst: detData.OrderDetailId,
        invoiceDetailsConstBak: FilteredInvoiceDetails,
      }),
      async () => {
        await this.getActors();
        await this.initializeInvoiceDetails();
        this.props.socketio.socket.on("refreshVerification", () => {
          this.refreshVerificationData();
          this.props.dispatch({
            type: "TOAST_ADD",
            payload: {
              title: `New Pending Invoice`,
            },
          });
        });
      }
    );
  }

  async refreshVerificationData() {
    const VERIFICATION_DATA = await verificationApi.getOrdersForVerification(
      this.state.dispatch
    );
    //console.log("VERIFICATION DATA ===>", VERIFICATION_DATA);

    let verificationDataCount = {
      Quote: 0,
      DR: 0,
      CI: 0,
      Transfer: 0,
      Archive: 0,
      PR: 0,
      Voucher: 0,
      Logistics: 0,
    };
    let verificationTableDataIndi = {
      Quote: [],
      DR: [],
      CI: [],
      Transfer: [],
      Archive: [],
      PR: [],
      Voucher: [],
      Logistics: [],
    };
    //Count verification data
    if (VERIFICATION_DATA) {
      VERIFICATION_DATA.forEach((el) => {
        const ORDER_CONTENT_TYPE = el?.orderType ? el?.orderType?.name : null;
        if (el.invoiceType?.name === "Quote") {
          if (el?.verificationType !== "INVOICE_ARCHIVE") {
            verificationDataCount = {
              ...verificationDataCount,
              Quote: verificationDataCount.Quote + 1,
            };
            verificationTableDataIndi = {
              ...verificationTableDataIndi,
              Quote: verificationTableDataIndi.Quote.concat(el),
            };
          }
        }
        if (el.invoiceType?.name === "Delivery Receipt") {
          verificationDataCount = {
            ...verificationDataCount,
            DR: verificationDataCount.DR + 1,
          };
          verificationTableDataIndi = {
            ...verificationTableDataIndi,
            DR: verificationTableDataIndi.DR.concat(el),
          };
        }
        if (el.invoiceType?.name === "Transfer Inventory") {
          verificationDataCount = {
            ...verificationDataCount,
            Transfer: verificationDataCount.Transfer + 1,
          };
          verificationTableDataIndi = {
            ...verificationTableDataIndi,
            Transfer: verificationTableDataIndi.Transfer.concat(el),
          };
        }
        if (el.invoiceType?.name === "Purchase Request") {
          verificationDataCount = {
            ...verificationDataCount,
            PR: verificationDataCount.PR + 1,
          };
          verificationTableDataIndi = {
            ...verificationTableDataIndi,
            PR: verificationTableDataIndi.PR.concat(el),
          };
        }
        if (
          ORDER_CONTENT_TYPE === "Item Requisition" ||
          ORDER_CONTENT_TYPE === "Add Item Qty Request" ||
          ORDER_CONTENT_TYPE === "Deduct Item Qty Request" ||
          ORDER_CONTENT_TYPE === "Return Item Request"
        ) {
        }
        if (ORDER_CONTENT_TYPE === "Item Order Request") {
        }
        if (
          el?.generalTransType === "transaction" ||
          el?.generalTransType === "journal"
        ) {

          let voucherStatus = "income";

          if ((!el?.orderId && !el?.isEntry) || el?.order?.supplierId || (!el?.order?.supplierId && !el?.order?.clientId)) {
            voucherStatus = "expense";
          }

          if(voucherStatus === 'income'){
            verificationDataCount = {
              ...verificationDataCount,
              CI: verificationDataCount.CI + 1,
            };
            verificationTableDataIndi = {
              ...verificationTableDataIndi,
              CI: verificationTableDataIndi.CI.concat(el),
            };
          }

          if(voucherStatus === 'expense'){
            verificationDataCount = {
              ...verificationDataCount,
              Voucher: verificationDataCount.Voucher + 1,
            };
            verificationTableDataIndi = {
              ...verificationTableDataIndi,
              Voucher: verificationTableDataIndi.Voucher.concat(el),
            };
          }

         
        }
        if (el?.courierId) {
          verificationDataCount = {
            ...verificationDataCount,
            Logistics: verificationDataCount.Logistics + 1,
          };
          verificationTableDataIndi = {
            ...verificationTableDataIndi,
            Logistics: verificationTableDataIndi.Logistics.concat(el),
          };
        }
        if (el?.verificationType === "INVOICE_ARCHIVE") {
          verificationDataCount = {
            ...verificationDataCount,
            Archive: verificationDataCount.Archive + 1,
          };
          verificationTableDataIndi = {
            ...verificationTableDataIndi,
            Archive: verificationTableDataIndi.Archive.concat(el),
          };
        }
      });
    }

    //console.log(verificationTableDataIndi);
    let finalTableData = [];

    if(this.state.selectedTableTab === SELECTED_TABLE_TABS.ALL){
      finalTableData = VERIFICATION_DATA
    }
    if(this.state.selectedTableTab === SELECTED_TABLE_TABS.QUOTE){
      finalTableData = verificationTableDataIndi.Quote
    }
    if(this.state.selectedTableTab === SELECTED_TABLE_TABS.DR){
      finalTableData = verificationTableDataIndi.DR
    }
    if(this.state.selectedTableTab === SELECTED_TABLE_TABS.CI){
      finalTableData = verificationTableDataIndi.CI
    }
    if(this.state.selectedTableTab === SELECTED_TABLE_TABS.TRANSFER){
      finalTableData = verificationTableDataIndi.Transfer
    }
    if(this.state.selectedTableTab === SELECTED_TABLE_TABS.ARCHIVE){
      finalTableData = verificationTableDataIndi.Archive
    }
    if(this.state.selectedTableTab === SELECTED_TABLE_TABS.PR){
      finalTableData = verificationTableDataIndi.PR
    }
    if(this.state.selectedTableTab === SELECTED_TABLE_TABS.VOUCHER){
      finalTableData = verificationTableDataIndi.Voucher
    }
    if(this.state.selectedTableTab === SELECTED_TABLE_TABS.LOGISTICS){
      finalTableData = verificationTableDataIndi.Logistics
    }

    this.setState((x) => ({
      allTableDataLength: VERIFICATION_DATA.length,
      verificationTableData: finalTableData,
      verificationTableDataCopy: finalTableData,
      verificationDataCount: verificationDataCount,
    }));

    return finalTableData;
  }
  async componentWillUnmount() {
    this.props.socketio.socket.off("refreshVerification");
  }

  async initializeInvoiceDetails() {
    const x = this.state.invoiceDetailsConst;
    //console.log(x);
    const DETAILS = await invoiceApi.getAllInvoiceDetail(this.props.dispatch);
    if (DETAILS !== null && x.length !== 0) {
      for (let index = 0; index < x.length; index++) {
        const arr = x[index];
        const compoName = `selDetail${arr.name}`;
        // const detData = await invoiceApi.getInvoiceDetail(
        //   this.props.dispatch,
        //   Number(x[index].value)
        // );
        const detData = DETAILS.find((x) => x.name === arr.name).details;
        //console.log(compoName);
        switch (compoName) {
          case "selDetailServices":
            let _services = [];
            detData.forEach((e) => {
              _services.push({ value: e.id, label: e.name });
            });
            //console.log(detData);
            this.setState((a) => ({
              [compoName]: {
                ...a[compoName],
                data: _services,
              },
            }));
            break;

          default:
            this.setState((a) => ({
              [compoName]: { ...a[compoName], data: detData },
            }));
            break;
        }
      }
      this.setState(
        (a) => ({
          selDetailType: {
            ...a.selDetailType,
            value: this.state.selDetailType.data[0],
            filteredDat: this.state.selDetailType.data.filter(
              (y) => y.hidden === 0
            ),
          },
        }),
        (x) => {
          //console.log(this.state.selDetailType);
        }
      );
      return true;
    } else {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Notice",
          desc: `Incomplete fetch data. You have been logged out.`,
          isYesNo: false,
        },
      });
      localStorage.clear();
      this.props.socketio.socket.disconnect();
      window.location.reload();
    }
  }
  getActors = async (e) => {
    const _suppliers = await actorApi.getActorsByType(this.props.dispatch, 7);
    const _clients = await actorApi.getActorsByType(this.props.dispatch, 6);
    const _employees = await actorApi.getActorsByType(
      this.props.dispatch,
      "1,2,3,5,8"
    );
    //console.log(_employees);
    this.setState((x) => ({
      selDetailClient: {
        ...x.selDetailClient,
        data: _clients,
      },
      selDetailIncharge: {
        ...x.selDetailIncharge,
        data: _employees,
      },
      selDetailSupplier: {
        ...x.selDetailSupplier,
        data: _suppliers,
      },
    }));
    // this.setState({
    //   supplierData: _suppliers,
    //   clientData: _clients,
    // });
  };
  inputChange = (e) => {
    this.setState({
      [e.target.name]: {
        value: e.target.value,
        placeholder: e.target.placeholder,
        className: e.target.className,
      },
    });
  };
  handleToggleVerificationModal = (e) => {
    if (this.state.handleVerificationModal.isShow)
      this.setState((x) => ({
        handleVerificationModal: {
          ...x.handleVerificationModal,
          isShow: false,
        },
      }));
    else
      this.setState((x) => ({
        handleVerificationModal: {
          ...x.handleVerificationModal,
          isShow: true,
        },
      }));
  };
  handleVerificationSubmit = (e) => {};
  handleVerificationExit = (e) => {
    this.setState(
      (x) => ({
        selContentItems: {
          ...x.selContentItems,
          value: null,
        },
        selectedModalOperation: { id: 0, name: "Add" },
        verificationTableSelection: [],
        selectedRowVerification: null,
        verificationTableContentSelection: [],
        selectedRowVerificationTableContent: null,
        verificationContentTableSelection: [],
        selectedRowVerificationSelection: null,
      }),
      async () => {
        // await this.refreshVerificationData();
      }
    );
  };

  handleActionChange = async (e, data, isEdit, cb) => {
    const _elName = e.target.name;
    // SWITCH FOR DIFF. TYPES OF INVOICE
    if (_elName === "btnSwitchTableTabs") {

     
      this.setState((x) => ({
        selectedTableTab: e.target.value
      }),()=>{
        this.refreshVerificationData();
      });
    }
    if (_elName === "btnApprove") {
      const SelectedInvoice = this.state.selectedRowVerification;
      if (SelectedInvoice.verificationType === 0) {
        if (SelectedInvoice.invoiceType.name === "Delivery Receipt") {
          const ParentData = await invoiceApi.getInvoice(
            this.state.dispatch,
            Number(SelectedInvoice.parentId)
          );
          if (ParentData) {
            if (ParentData.children.length === 1) {
              await this.updateInvoiceStatus(
                "Ongoing Delivery",
                2,
                SelectedInvoice.parentId
              );
            }
          }

          await this.updateInvoiceStatus(
            "Ongoing Delivery",
            1,
            SelectedInvoice.id
          );
        }
        //console.log(SelectedInvoice);
        if (SelectedInvoice.invoiceType.name === "Quote") {
          //this.updateInvoiceStatus("For Delivery", 1);
          const invoiceData = await invoiceApi.getInvoice(
            this.state.dispatch,
            Number(SelectedInvoice.id)
          );
          const Settings = await settingsApi.getSettingsByInventory(
            this.props.dispatch,
            Number(
              invoiceData.inventoryId === null ? 1 : invoiceData.inventoryId
            )
          );

          const transUUID = filters.uuidv4();
          const _grandTotal = invoiceData.content
            .reduce((a, c) => {
              // if (c.childId == null) return a + c.price * c.itemQty;
              // else return a + c.child.price * c.child.itemQty;
              return a + c.price * c.itemQty;
            }, 0)
            .toFixed(2);
          // ===== INVENTORY ASSET DEBIT =====
          const inventoryAssetValue = Number(_grandTotal) * -1;
          const actsReceivableTransaction = {
            description: `Inventory Entry - ${this.state.txtInvoiceName.value}`,
            //paymentDate: DateToday,
            transStatus: "finished",
            paymentType: "cash",
            isEntry: 1,
            isInventoryAssetDeductEntry: 1,
            amountValue: inventoryAssetValue,
            grossAmount: inventoryAssetValue,
            netAmount: inventoryAssetValue,
            taxAmount: 0,
            uid: transUUID,
            transType: `credit`,
            clientId:
              this.state.selDetailClient.value !== "" &&
              this.state.selDetailClient.value !== null
                ? Number(this.state.selDetailClient.value.id)
                : null,
            accountId: Settings.inventoryAccountId,
            orderId: Number(invoiceData.id),
            inventoryId: invoiceData.inventoryId,
          };
          console.log(
            "PRE-POST INVENTORY ASSET ===> ",
            actsReceivableTransaction
          );
          await transactionsApi.createTransaction(
            this.props.dispatch,
            { transaction: actsReceivableTransaction },

            (dat) => {
              console.log("INVENTORY ASSET ===>", dat);
            }
          );
          //=== ACCOUNTS RECEIVABLE ===
          const invoiceSalesTransaction = {
            description: `Payment Receivable - ${this.state.txtInvoiceName.value}`,
            //paymentDate: UIDateToday,
            transStatus: "finished",
            paymentType: "cash",
            createdById: Number(this.state.user.id),
            amountValue: Number(_grandTotal),
            lastModifiedByActorId: Number(this.state.user.id),
            grossAmount: 0,
            netAmount: 0,
            taxAmount: 0,
            uid: transUUID,
            isEntry: 1,

            transType: "debit",
            clientId:
              this.state.selDetailClient.value !== "" &&
              this.state.selDetailClient.value !== null
                ? Number(this.state.selDetailClient.value.id)
                : null,
            accountId: Settings.actsReceivableAccountInvoiceId,
            orderId: Number(invoiceData.id),
            inventoryId: invoiceData.inventoryId,
          };
          console.log(
            "PRE-POST INVOICE ACTS RECEIVABLE ===> ",
            invoiceSalesTransaction
          );
          await transactionsApi.createTransaction(
            this.props.dispatch,
            { transaction: invoiceSalesTransaction },

            (dat) => {
              console.log("INVOICE ACTS RECEIVABLE ===>", dat);
            }
          );
         await this.updateInvoiceStatus("Pending Client Payment", 1);

          const pdfBlob = await generateQuotePDF(
            SelectedInvoice.id,
            this.props.dispatch
          );
          //console.log(pdfBlob)
          //const blobLink = await window.URL.createObjectURL(pdfBlob);
          this.props.dispatch({
            type: "FILE_VIEWER_MODAL_TOGGLE",
            doc: pdfBlob,
            fileName: SelectedInvoice.name + ".pdf",
          });
        }
        if (SelectedInvoice.invoiceType.name === "Transfer Inventory") {
          const invoiceData = await invoiceApi.getInvoice(
            this.state.dispatch,
            Number(SelectedInvoice.id)
          );
          const _dat = {
            data: {
              inventoryIdFrom: Number(SelectedInvoice.inventoryIdFrom),
              inventoryIdTo: Number(SelectedInvoice.inventoryIdTo),
            },
            content: invoiceData.content,
          };

          await invoiceApi.transferItemsToInventory(this.props.dispatch, _dat);
          await this.updateInvoiceStatus("Ongoing Delivery", 1);
        }
        if (SelectedInvoice.invoiceType.name === "Purchase Request") {
          // this.updateInvoiceType("For Delivery", "Purchase Order");

          let hasNoPrices = null;
          hasNoPrices = this.state.verificationTableContentData.find(
            (x) => x.price === 0
          );

          let hasSupplier = true;
          if (
            this.state.txtSupplier.value === null ||
            this.state.txtSupplier.value === ""
          ) {
            hasSupplier = false;
          }

          if (hasNoPrices || !hasSupplier) {
            this.props.dispatch({
              type: "MODAL_SHOW",
              payload: {
                title: "Incomplete PR Invoice",
                desc: `Please provide COGS, Cost and Supplier for all the items.`,
                isYesNo: false,
              },
            });
          } else {
            await this.updateInvoiceType(
              "Pending Payment To Supplier",
              "Purchase Order"
            );
          }
        }
      } else {
        const ORDER_CONTENT_TYPE = SelectedInvoice?.orderType?.name;

        if (
          ORDER_CONTENT_TYPE === "Item Requisition" ||
          ORDER_CONTENT_TYPE === "Add Item Qty Request" ||
          ORDER_CONTENT_TYPE === "Deduct Item Qty Request" ||
          ORDER_CONTENT_TYPE === "Return Item Request"
        ) {
          await this.updateInvoiceContentStatus("Admin Approved");
        }

        if (ORDER_CONTENT_TYPE === "Item Order Request") {
          await this.updateInvoiceContentStatus("For order");
        }
        if (
          SelectedInvoice.generalTransType === "transaction" ||
          SelectedInvoice.generalTransType === "journal"
        ) {
          const data = {
            transaction: {
              id: Number(SelectedInvoice.id),
              notes: this.state.txtNotes.value,
              transStatus: "processing",
              approvedById: Number(this.state.user.id),
            },
          };
          //console.log(data)
          await transactionsApi.updateTransaction(
            this.props.dispatch,
            data,
            () => {
              this.props.dispatch({
                type: "TOAST_ADD",
                payload: {
                  title: `Successfully modified transaction`,
                },
              });
              if (this.state.handleVerificationModal.isShow)
                this.handleToggleVerificationModal();
            },
            () => {
              this.props.dispatch({
                type: "MODAL_SHOW",
                payload: {
                  title: "Transaction Approval Error",
                  desc: `Please Contact Administrator`,
                  isYesNo: false,
                },
              });
            }
          );
        }
        if (SelectedInvoice.courierId) {
          const data = {
            logistics: {
              id: Number(SelectedInvoice.id),
              notes: this.state.txtNotes.value,
              logiStatus: "on-route",
              approvedById: Number(this.state.user.id),
            },
          };
          await LogisticsAPI.updateLogistics(
            this.props.dispatch,
            data,
            () => {
              this.props.dispatch({
                type: "TOAST_ADD",
                payload: {
                  title: `Successfully approved Logistics`,
                },
              });
              if (this.state.handleVerificationModal.isShow)
                this.handleToggleVerificationModal();
            },
            () => {
              this.props.dispatch({
                type: "MODAL_SHOW",
                payload: {
                  title: "Logistics Approval Error",
                  desc: `Please Contact Administrator`,
                  isYesNo: false,
                },
              });
            }
          );
        }
        if (SelectedInvoice?.verificationType === "INVOICE_ARCHIVE") {
          await invoiceApi.deleteInvoice(
            this.props.dispatch,
            SelectedInvoice.id,
            null,
            1,
            1
          );
          //await this.refreshVerificationData();
          this.handleToggleVerificationModal();
          this.props.dispatch({
            type: "TOAST_ADD",
            payload: {
              title: `Successfully Archived`,
            },
          });
        }
      }
      await this.refreshVerificationData();
    }
    if (_elName === "btnDeny") {
      const SelectedInvoice = this.state.selectedRowVerification;
      if (SelectedInvoice.verificationType === 0) {
        await this.updateInvoiceStatus("Admin Denied");
        this.props.dispatch({
          type: "TOAST_ADD",
          payload: {
            title: `Successfully Denied Invoice`,
          },
        });
      } else {
        const ORDER_CONTENT_TYPE = SelectedInvoice?.orderType?.name;
        if (
          ORDER_CONTENT_TYPE === "Item Requisition" ||
          ORDER_CONTENT_TYPE === "Add Item Qty Request" ||
          ORDER_CONTENT_TYPE === "Deduct Item Qty Request" ||
          ORDER_CONTENT_TYPE === "Return Item Request" ||
          ORDER_CONTENT_TYPE === "Item Order Request"
        ) {
          await this.updateInvoiceContentStatus("Admin Denied");
          this.props.dispatch({
            type: "TOAST_ADD",
            payload: {
              title: `Successfully Denied Invoice Content`,
            },
          });
        }
        if (
          SelectedInvoice.generalTransType === "transaction" ||
          SelectedInvoice.generalTransType === "journal"
        ) {
          const data = {
            transaction: {
              id: Number(SelectedInvoice.id),
              notes: this.state.txtNotes.value,
              transStatus: "admin-denied",
              approvedById: Number(this.state.user.id),
            },
          };
          await transactionsApi.updateTransaction(
            this.props.dispatch,
            data,
            () => {
              this.props.dispatch({
                type: "TOAST_ADD",
                payload: {
                  title: `Successfully Denied Transaction`,
                },
              });
              if (this.state.handleVerificationModal.isShow)
                this.handleToggleVerificationModal();
            },
            () => {
              this.props.dispatch({
                type: "MODAL_SHOW",
                payload: {
                  title: "Transaction Approval Error",
                  desc: `Please Contact Administrator`,
                  isYesNo: false,
                },
              });
            }
          );
        }
        if (SelectedInvoice.courierId) {
          const data = {
            logistics: {
              id: Number(SelectedInvoice.id),
              notes: this.state.txtNotes.value,
              logiStatus: "admin-denied",
              approvedById: Number(this.state.user.id),
            },
          };
          await LogisticsAPI.updateLogistics(
            this.props.dispatch,
            data,
            () => {
              this.props.dispatch({
                type: "TOAST_ADD",
                payload: {
                  title: `Successfully Denied Logistic`,
                },
              });
              if (this.state.handleVerificationModal.isShow)
                this.handleToggleVerificationModal();
            },
            () => {
              this.props.dispatch({
                type: "MODAL_SHOW",
                payload: {
                  title: "Logistics Deny Error",
                  desc: `Please Contact Administrator`,
                  isYesNo: false,
                },
              });
            }
          );
        }
        if (SelectedInvoice?.verificationType === "INVOICE_ARCHIVE") {
          this.handleToggleVerificationModal();
          await this.updateInvoiceStatus("Admin Denied");
          this.props.dispatch({
            type: "TOAST_ADD",
            payload: {
              title: `Successfully Denied Archive Request`,
            },
          });
        }
      }

      await this.refreshVerificationData();
    }
    if (_elName === "txtMarkupPercent") {
      //console.log(this.state.selectedRowVerificationTableContent);
      let SRP = this.state.selectedRowVerificationTableContent.srp;
      // const PRICE_MODIFIER =
      //   this.state.selectedRowVerificationTableContent.priceModifier;
      const percentDiscount = e.target.value;

      // if (PRICE_MODIFIER) {
      //   SRP = SRP + SRP * (PRICE_MODIFIER / 100);
      // }

      const discountedWhole = SRP + (percentDiscount / 100) * SRP;
      //console.log(discountedWhole);
      const markupAmount = (percentDiscount / 100) * SRP;

      this.setState((x) => ({
        txtMarkupPercent: { ...x.txtMarkupPercent, value: e.target.value },
        txtMarkupAmount: {
          ...x.txtMarkupAmount,
          value: Number(markupAmount),
        },
        txtMarkupWhole: {
          ...x.txtMarkupWhole,
          value: Number(discountedWhole),
        },
      }));
    }
    if (_elName === "txtMarkupAmount") {
      let SRP = this.state.selectedRowVerificationTableContent.srp;
      // const PRICE_MODIFIER =
      //   this.state.selectedRowVerificationTableContent.priceModifier;

      // if (PRICE_MODIFIER) {
      //   SRP = SRP + SRP * (PRICE_MODIFIER / 100);
      // }

      const discounted = e.target.value;
      const discountedWhole = SRP + discounted;
      const discount = (discounted / SRP) * 100;

      this.setState((x) => ({
        txtMarkupPercent: { ...x.txtMarkupPercent, value: discount },
        txtMarkupAmount: {
          ...x.txtMarkupAmount,
          value: Number(discounted),
        },
        txtMarkupWhole: {
          ...x.txtMarkupWhole,
          value: Number(discountedWhole),
        },
      }));
    }
    if (_elName === "txtMarkupWhole") {
      let SRP = this.state.selectedRowVerificationTableContent.srp;

      // const PRICE_MODIFIER =
      //   this.state.selectedRowVerificationTableContent.priceModifier;
      // if (PRICE_MODIFIER) {
      //   SRP = SRP + SRP * (PRICE_MODIFIER / 100);
      // }
      if (Number(e.target.value) >= SRP) {
        const discountedWhole = Number(e.target.value);
        const percentDiscount = Number(((discountedWhole - SRP) / SRP) * 100);
        const discounted = (percentDiscount / 100) * SRP;
        this.setState((x) => ({
          txtMarkupPercent: { ...x.txtMarkupPercent, value: percentDiscount },
          txtMarkupWhole: {
            ...x.txtMarkupWhole,
            value: Number(discountedWhole),
          },
          txtMarkupAmount: {
            ...x.txtMarkupAmount,
            value: Number(discounted),
          },
        }));
      } else {
        this.setState((x) => ({
          txtMarkupPercent: { ...x.txtMarkupPercent, value: 0 },

          txtMarkupAmount: {
            ...x.txtMarkupAmount,
            value: 0,
          },
        }));
      }
    }
    if (_elName === "btnMarkup") {
      const { id } = this.state.selectedRowVerificationTableContent;
      const markup = Number(this.state.txtMarkupPercent.value);
      const price = Number(this.state.txtMarkupWhole.value);

      const result = await VerificationAPI.modifyOrderContentMarkup(
        this.props.dispatch,
        id,
        markup,
        price
      );
      if (result.success) {
        // const NEW_DATA = await this.refreshVerificationData();

        const orderId = this.state.selectedRowVerification.id;

        const updatedInvoice = await InvoiceAPI.getInvoice(
          this.props.dispatch,
          orderId
        );
        //console.log(updatedInvoice);
        //Recalculate Grand Total

        //let _grandTotal = 0;
        let _grandTotalSRP = 0;
        let _grandTotal = 0;
        _grandTotal = updatedInvoice.content
          .reduce((a, c) => {
            return a + c.price * c.itemQty;
          }, 0)
          .toFixed(2);
        _grandTotalSRP = updatedInvoice.content
          .reduce((a, c) => {
            return a + c.srp * c.itemQty;
          }, 0)
          .toFixed(2);
        this.setState((x) => ({
          verificationTableContentData: updatedInvoice.content,
          verificationTableContentDataCopy: updatedInvoice.content,
          verificationTableContentSelection: [],
          selectedRowVerificationTableContent: null,
          txtDiscount: { value: "", readOnly: true },
          grandTotal: _grandTotal,
          grandTotalSRP: _grandTotalSRP,
        }));
      }
    }
    if (_elName === "txtDiscount") {
      //console.log(this.state.selectedRowVerificationTableContent);
      let SRP = this.state.selectedRowVerificationTableContent.srp;
      const PRICE_MODIFIER =
        this.state.selectedRowVerificationTableContent.priceModifier;
      const percentDiscount = e.target.value;

      if (PRICE_MODIFIER) {
        SRP = SRP + SRP * (PRICE_MODIFIER / 100);
      }

      const discountedWhole = SRP - (percentDiscount / 100) * SRP;
      //console.log(discountedWhole);
      const discounted = SRP - discountedWhole;

      this.setState((x) => ({
        txtDiscount: { ...x.txtDiscount, value: e.target.value },
        txtDiscounted: {
          ...x.txtDiscounted,
          value: Number(discounted),
        },
        txtDiscountedWhole: {
          ...x.txtDiscountedWhole,
          value: Number(discountedWhole),
        },
      }));
    }
    if (_elName === "txtDiscounted") {
      let SRP = this.state.selectedRowVerificationTableContent.srp;
      const PRICE_MODIFIER =
        this.state.selectedRowVerificationTableContent.priceModifier;

      if (PRICE_MODIFIER) {
        SRP = SRP + SRP * (PRICE_MODIFIER / 100);
      }

      const discounted = e.target.value;
      const discountedWhole = SRP - discounted;
      const discount = (discounted / SRP) * 100;

      this.setState((x) => ({
        txtDiscount: { ...x.txtDiscount, value: discount },
        txtDiscounted: {
          ...x.txtDiscounted,
          value: Number(discounted),
        },
        txtDiscountedWhole: {
          ...x.txtDiscountedWhole,
          value: Number(discountedWhole),
        },
      }));
    }
    if (_elName === "txtDiscountedWhole") {
      let SRP = this.state.selectedRowVerificationTableContent.srp;

      const PRICE_MODIFIER =
        this.state.selectedRowVerificationTableContent.priceModifier;
      if (PRICE_MODIFIER) {
        SRP = SRP + SRP * (PRICE_MODIFIER / 100);
      }

      const discountedWhole = Number(e.target.value);
      //console.log(Number(e.target.value));
      const percentDiscount = Number(((SRP - discountedWhole) / SRP) * 100);
      const discounted = (percentDiscount / 100) * SRP;
      // misc.debounce(() => {
      //   this.setState((x) => ({
      //     txtDiscountedWhole: {
      //       ...x.txtDiscountedWhole,
      //       value: discountedWhole,
      //     },
      //   }));
      //   console.log("trigger debounce");
      // }, 500)();
      this.setState((x) => ({
        txtDiscount: { ...x.txtDiscount, value: percentDiscount },
        txtDiscountedWhole: {
          ...x.txtDiscountedWhole,
          value: Number(discountedWhole),
        },
        txtDiscounted: {
          ...x.txtDiscounted,
          value: Number(discounted),
        },
      }));
    }

    if (_elName === "btnDiscount") {
      const { id } = this.state.selectedRowVerificationTableContent;
      const discount = Number(this.state.txtDiscount.value);
      const price = Number(this.state.txtDiscountedWhole.value);

      const result = await VerificationAPI.modifyOrderContentDiscount(
        this.props.dispatch,
        id,
        discount,
        price
      );
      if (result.success) {
        // const NEW_DATA = await this.refreshVerificationData();

        const orderId = this.state.selectedRowVerification.id;

        const updatedInvoice = await InvoiceAPI.getInvoice(
          this.props.dispatch,
          orderId
        );
        //console.log(updatedInvoice);
        //Recalculate Grand Total

        //let _grandTotal = 0;
        let _grandTotalSRP = 0;
        let _grandTotal = 0;
        _grandTotal = updatedInvoice.content
          .reduce((a, c) => {
            return a + c.price * c.itemQty;
          }, 0)
          .toFixed(2);
        _grandTotalSRP = updatedInvoice.content
          .reduce((a, c) => {
            return a + c.srp * c.itemQty;
          }, 0)
          .toFixed(2);
        this.setState((x) => ({
          verificationTableContentData: updatedInvoice.content,
          verificationTableContentDataCopy: updatedInvoice.content,
          verificationTableContentSelection: [],
          selectedRowVerificationTableContent: null,
          txtDiscount: { value: "", readOnly: true },
          grandTotal: _grandTotal,
          grandTotalSRP: _grandTotalSRP,
        }));
      }
    }

    if (_elName === "btnHoldInvoice") {
      const { id } = this.state.selectedRowVerification;

      this.holdInvoice(id, e.target.value);
    }
    if (_elName === "btnEditVerification") {
      this.setState((x) => ({
        prHasNoPrices: false,
      }));
      if (data.verificationType === 0) {
        if (this.state.verificationTableSelection.length === 1) {
          //this.clearInputs(true);

          if (data.invoiceType.name === "Delivery Receipt") {
            this.setState((x) => ({
              verificationTableContentColumn: selectedDrColumn(),
            }));
          }
          if (data.invoiceType.name === "Quote") {
            this.setState((x) => ({
              verificationTableContentColumn: itemColumn([], []),
            }));
          }
          if (data.invoiceType.name === "Purchase Request") {
            //check if all invoiceItems are with prices
            const FETCHED_INVOICE = await invoiceApi.getInvoice(
              this.props.dispatch,
              data.id
            );
            let hasNoPrices = null;
            hasNoPrices = FETCHED_INVOICE.content.find((x) => x.price === 0);

            this.setState((x) => ({
              verificationTableContentColumn: PRItemColumn(),
              ...(hasNoPrices ? { prHasNoPrices: true } : null),
            }));
          }
          if (data.invoiceType.name === "Transfer Inventory") {
            //console.log("trigger");
            this.setState((x) => ({
              verificationTableContentColumn: invTransColumn([], []),
            }));
          }

          this.setState(
            (x) => ({
              handleVerificationModal: {
                ...x.handleVerificationModal,
                isView: true,
              },
            }),
            async (x) => {
              //fetch full details of order
              const FETCHED_INVOICE = await invoiceApi.getInvoice(
                this.props.dispatch,
                data.id
              );
              if (FETCHED_INVOICE) {
                this.populateVerificationModal(FETCHED_INVOICE, () => {
                  this.handleToggleVerificationModal();
                });
              }
            }
          );
          this.setState((x) => ({
            selectedOperation: { id: 1, name: "Edit" },
          }));
        } else {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "No Selection",
              desc: `Please select an Invoice`,
              isYesNo: false,
            },
          });
        }
      } else {
        if (data.verificationType) {
          if (data.orderType?.name === "Item Order Request") {
            //TABLE COLUMN
          }
          if (data.orderType?.name === "Item Requisition") {
            //TABLE COLUMN
          }
          this.setState(
            (x) => ({
              handleVerificationModal: {
                ...x.handleVerificationModal,
                isView: true,
              },
            }),
            (x) => {
              this.populateOrderContent(data);
              this.handleToggleVerificationModal();
            }
          );
        }
        if (data.generalTransType) {
          this.setState(
            (x) => ({
              handleVerificationModal: {
                ...x.handleVerificationModal,
                isView: true,
              },
              txtNotes: { ...x.txtNotes, value: data.notes },
            }),
            (x) => {
              //this.populateOrderContent(data);
              this.handleToggleVerificationModal();
            }
          );
        }
        if (data.courierId) {
          const FETCHED_LOGISTICS = await LogisticsAPI.getLogistics(
            this.props.dispatch,
            data.id
          );
          //console.log(FETCHED_LOGISTICS)
          if (FETCHED_LOGISTICS) {
            this.setState(
              (x) => ({
                selectedRowVerification: FETCHED_LOGISTICS,
                txtNotes: { ...x.txtNotes, value: data.notes },
              }),
              (x) => {
                //console.log(this.state.selectedRowVerification)
                this.handleToggleVerificationModal();
              }
            );
          }
        }
        if (data.verificationType === "INVOICE_ARCHIVE") {
          //console.log(data);
        }
        //console.log(data);
      }

      this.setState(
        (x) => ({
          selectedOperation: { id: 1, name: "Edit" },
        }),
        () => {
          //
        }
      );
    }
    if (_elName === "btnViewPDF") {
      const SelectedRow = this.state.selectedRowVerification;
      if (SelectedRow.invoiceType?.name === "Quote") {
        this.state.dispatch({
          type: "PDF_MODAL_TOGGLE",
          doc: QuotePDF.QuotePDF(
            this.state,
            this.state.selectedRowVerification.approvedBy !== null
              ? this.state.selectedRowVerification.approvedBy.name
              : "",
            this.state.selectedRowVerification.isSignatureAvailable,
            this.state.selectedRowVerification.incharge !== null
              ? this.state.selectedRowVerification.incharge
              : ""
          ),
        });
      }
      if (SelectedRow.invoiceType?.name === "Delivery Receipt") {
        this.state.dispatch({
          type: "PDF_MODAL_TOGGLE",
          doc: DR.DRPDF(
            this.state,
            this.state.drParent,
            true,
            this.state.selectedRowVerification.uniqueId,
            this.state.selectedRowVerification.invoiceStatus.name,
            this.state.selectedRowVerification.shippingAddress,
            this.state.selectedRowVerification.incharge !== null
              ? this.state.selectedRowVerification.incharge
              : null,
            this.state.selectedRowVerification.approvedBy !== null
              ? this.state.selectedRowVerification.approvedBy
              : null,
            false
          ),
        });
      }
      if (SelectedRow.invoiceType?.name === "Transfer Inventory") {
        this.state.dispatch({
          type: "PDF_MODAL_TOGGLE",
          doc: TransferItem.TransferItemPDF(
            this.state,
            true,
            this.state.selectedRowVerification.uniqueId,
            this.state.selectedRowVerification.invoiceStatus.name,
            this.state.selectedRowVerification.incharge.name,
            this.state.selectedRowVerification.approvedBy !== null
              ? this.state.selectedRowVerification.approvedBy.name
              : "",
            this.state.selectedRowVerification.incharge !== null
              ? this.state.selectedRowVerification.incharge
              : null,
            false,

            this.state.selectedRowVerification.inventoryFrom.name,
            this.state.selectedRowVerification.inventoryTo.name
          ),
        });
      }
      if (SelectedRow.invoiceType?.name === "Purchase Request") {
        this.state.dispatch({
          type: "PDF_MODAL_TOGGLE",
          doc: PR.PRPDF(
            this.state,
            true,
            this.state.selectedRowVerification.approvedBy !== null
              ? this.state.selectedRowVerification.approvedBy
              : null,
            false,
            this.state.selectedRowVerification.incharge !== null
              ? this.state.selectedRowVerification.incharge
              : null
          ),
        });
      }
      if (SelectedRow?.generalTransType === "transaction") {
        let voucherType = "Cash";
        if (SelectedRow?.paymentType === "Cash") {
          voucherType = "Cash";
        } else {
          voucherType = "Check";
        }
        let perChunk = 8; // items per chunk

        let result = SelectedRow?.content.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / perChunk);
          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
          }
          resultArray[chunkIndex].push({
            ...item,
            index: index + 1,
          });
          return resultArray;
        }, []);
        for (let index = 0; index < result.length; index++) {
          const element = result[index];
          result[index] = element.sort(function (a, b) {
            return a.partIndex - b.partIndex;
          });
        }
        this.props.dispatch({
          type: "PDF_MODAL_TOGGLE",
          doc: Voucher.Voucher(
            SelectedRow?.createdBy,
            voucherType,
            SelectedRow?.uniqueId,
            SelectedRow?.order?.supplier
              ? SelectedRow?.order?.supplier
              : SelectedRow?.supplierVendor,
            SelectedRow?.order?.dateAdded,
            SelectedRow?.amountValue,
            SelectedRow?.createdBy,
            null,
            result,
            SelectedRow?.paymentBankName,
            SelectedRow?.paymentBankNumber,
            SelectedRow?.checkInfo1
          ),
        });
      }
      if (SelectedRow?.courierId) {
        const fetchData = await LogisticsAPI.getLogistics(
          this.props.dispatch,
          SelectedRow.id
        );
        let perChunk = 8; // items per chunk

        let result = fetchData.content.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / perChunk);
          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
          }
          resultArray[chunkIndex].push({
            ...item,
            index: index + 1,
          });
          return resultArray;
        }, []);
        // For sort by index
        // for (let index = 0; index < result.length; index++) {
        //   const element = result[index];
        //   result[index] = element.sort(function (a, b) {
        //     return a.partIndex - b.partIndex;
        //   });
        // }
        this.props.dispatch({
          type: "PDF_MODAL_TOGGLE",
          doc: Logistics.LogisticsPDF(this.state, fetchData, result, false),
        });
      }
    }
    if (_elName === "btnMobilePDF") {
      // console.log(this.state);
      this.setState({ isPDFLoading: true });
      let fileName = "";
      let blob = null;
      if (this.state.selectedRowVerification.invoiceType.name === "Quote") {
        fileName = `${this.state.selectedRowVerification.client.company} QUOTE`;
      }
      if (
        this.state.selectedRowVerification.invoiceType.name ===
        "Delivery Receipt"
      ) {
        fileName = `${this.state.selectedRowVerification.client.company} DR`;
      }
      if (
        this.state.selectedRowVerification.invoiceType.name ===
        "Transfer Inventory"
      ) {
        fileName = `${this.state.selectedRowVerification.inventoryFrom.name} TO ${this.state.selectedRowVerification.inventoryTo.name} Transfer`;
      }
      if (
        this.state.selectedRowVerification.invoiceType.name ===
          "Purchase Order" ||
        this.state.selectedRowVerification.invoiceType.name ===
          "Purchase Request"
      ) {
        fileName = `${this.state.selectedRowVerification.supplier.company} REQUEST`;
      }
      if (this.state.selectedRowVerification.invoiceType.name === "Quote") {
        blob = await pdf(
          QuotePDF.QuotePDF(
            this.state,
            this.state.selectedRowVerification.approvedBy !== null
              ? this.state.selectedRowVerification.approvedBy.name
              : "",
            this.state.selectedRowVerification.isSignatureAvailable,
            this.state.selectedRowVerification.incharge !== null
              ? this.state.selectedRowVerification.incharge
              : ""
          )
        ).toBlob();
      }
      if (
        this.state.selectedRowVerification.invoiceType.name ===
        "Delivery Receipt"
      ) {
        blob = await pdf(
          DR.DRPDF(
            this.state,
            this.state.drParent,
            true,
            this.state.selectedRowVerification.uniqueId,
            this.state.selectedRowVerification.invoiceStatus.name,
            this.state.selectedRowVerification.shippingAddress,
            this.state.selectedRowVerification.incharge !== null
              ? this.state.selectedRowVerification.incharge
              : null,
            this.state.selectedRowVerification.approvedBy !== null
              ? this.state.selectedRowVerification.approvedBy
              : null,
            false
          )
        ).toBlob();
      }
      if (
        this.state.selectedRowVerification.invoiceType.name ===
        "Transfer Inventory"
      ) {
        blob = await pdf(
          TransferItem.TransferItemPDF(
            this.state,
            true,
            this.state.selectedRowVerification.uniqueId,
            this.state.selectedRowVerification.invoiceStatus.name,
            this.state.selectedRowVerification.incharge.name,
            this.state.selectedRowVerification.approvedBy !== null
              ? this.state.selectedRowVerification.approvedBy.name
              : "",
            this.state.selectedRowVerification.incharge !== null
              ? this.state.selectedRowVerification.incharge
              : null,
            false,

            this.state.selectedRowVerification.inventoryFrom.name,
            this.state.selectedRowVerification.inventoryTo.name
          ).toBlob()
        );
      }
      if (
        this.state.selectedRowVerification.invoiceType.name ===
        "Purchase Request"
      ) {
        blob = await pdf(
          PR.PRPDF(
            this.state,
            true,
            this.state.selectedRowVerification.approvedBy !== null
              ? this.state.selectedRowVerification.approvedBy
              : null,
            false,
            this.state.selectedRowVerification.incharge !== null
              ? this.state.selectedRowVerification.incharge
              : null
          )
        ).toBlob();
      }
      const blobLink = await window.URL.createObjectURL(
        new Blob([blob], { type: "application/pdf" })
      );

      const link = document.createElement("a");
      link.href = blobLink;

      link.download = fileName + ".pdf";
      document.body.appendChild(link);

      link.click();
      this.setState({ isPDFLoading: false });
    }
    if (_elName === "refreshSelectedPRContent") {
      const _UpdatedContent = await invoiceApi.getOrderContent(
        this.props.dispatch,
        this.state.selectedRowVerificationTableContent.id
      );
      //console.log(_UpdatedContent);

      if (_UpdatedContent) {
        let _tempData = this.state.verificationTableContentData;
        _tempData = _tempData.map((x) =>
          x.id === _UpdatedContent.id ? _UpdatedContent : x
        );

        const _grandTotal = _tempData
          .reduce((a, c) => {
            return a + c.price * c.qtyOrdered;
          }, 0)
          .toFixed(2);

        let hasNoPrices = null;
        hasNoPrices = _tempData.find((x) => x.price === 0);

        this.setState({
          verificationTableContentData: _tempData,
          verificationTableContentDataCopy: _tempData,
          selectedRowVerificationTableContent: null,
          verificationTableContentSelection: [],
          grandTotal: _grandTotal,
          prHasNoPrices: hasNoPrices,
        });
      }
    }
    if (_elName === "refreshSelectedPR") {
      const _UpdatedContent = await invoiceApi.getInvoice(
        this.props.dispatch,
        this.state.selectedRowVerification.id
      );
      //console.log(_UpdatedContent);

      if (_UpdatedContent) {
        let _tempData = this.state.verificationTableData;
        _tempData = _tempData.map((x) =>
          x.id === _UpdatedContent.id ? _UpdatedContent : x
        );

        this.setState({
          verificationTableData: _tempData,
          verificationTableDataCopy: _tempData,
        });
      }
    }
  };

  populateOrderContent = async (data) => {
    const _itemName = data?.item?.details?.find((x) => x.type === 1);
    let _name = "";
    if (_itemName) {
      if (data.orderType.name === "Item Order Request") {
        _name = `${data.itemQty} ${data.itemUnit.name} Order request for ${_itemName.name}`;
      } else {
        _name = `${data.itemQty} ${data.itemUnit.name} Requisition for ${_itemName.name}`;
      }
    }

    this.setState((a) => ({
      txtInvoiceName: {
        ...a.txtInvoiceName,
        value: _name,
      },
      txtNotes: { ...a.txtNotes, value: data.notes },
      txtDateAdded: {
        ...a.txtDateAdded,
        value: misc.parseAPIDateTime(data.dateAdded),
      },
      txtSalesperson: {
        ...a.txtSalesperson,
        value: data.requestedByActor
          ? `${
              data.requestedByActor?.lastName !== null
                ? data.requestedByActor?.lastName
                : "-"
            } ${
              data.requestedByActor?.firstName !== null
                ? data?.requestedByActor?.firstName
                : "-"
            }`
          : null,
      },
    }));
  };

  async updateInvoiceContentStatus(statusName, id) {
    const _srch = this.state.selDetailContentStatus.data.find(
      (x) => x.name === statusName
    );
    //console.log(_srch, this.state.selectedRowVerification.id);

    const _id = Number(
      typeof id === "undefined" ? this.state.selectedRowVerification.id : id
    );

    if (_srch) {
      if (this.state.selectedRowVerification.verificationType === 2) {
        await inventoryApi.addItemQty(this.props.dispatch, {
          id: Number(this.state.selectedRowVerification.item.id),
          qty: Number(this.state.selectedRowVerification.itemQty),
        });
      }
      const data = {
        id: _id,
        notes: this.state.txtNotes.value,
        orderStatusId: Number(_srch.id),
        lastModifiedByActorId: Number(this.state.user.id),
        //dateLastModified: DateToday,
      };
      await invoiceApi.updateInvoiceContent(this.props.dispatch, data);
      //await this.refreshVerificationData();
      if (this.state.handleVerificationModal.isShow)
        this.handleToggleVerificationModal();

      this.props.dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Successfully Modified Invoice Content Status`,
        },
      });

      this.setState({
        verificationTableSelection: [],
        selectedRowVerification: null,
      });
    } else {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Notice",
          desc: `Incomplete fetch data. "selDetailContentStatus"`,
          isYesNo: false,
        },
      });
    }
  }

  holdInvoice = async (id, invoiceVerificationHold) => {
    const data = {
      data: {
        id: Number(
          typeof id === "undefined" ? this.state.selectedRowVerification.id : id
        ),
        notes: this.state.txtNotes.value,
        invoiceVerificationHold: invoiceVerificationHold,
      },
    };
    await invoiceApi.updateInvoice(this.props.dispatch, data);

    //await this.refreshVerificationData();

    if (this.state.handleVerificationModal.isShow) {
      this.handleToggleVerificationModal();
    }

    this.setState({
      verificationTableSelection: [],
      selectedRowVerification: null,
    });
    await this.refreshVerificationData();
    this.props.dispatch({
      type: "TOAST_ADD",
      payload: {
        title: `Successfully Delayed Invoice Verification`,
      },
    });
  };

  updateInvoiceStatus = async (statusName, isSignatureAvail, id) => {
    const _srch = this.state.selDetailStatus.data.find(
      (x) => x.name === statusName
    );

    //console.log(this.state.selDetailStatus.data);
    if (_srch) {
      const data = {
        data: {
          id: Number(
            typeof id === "undefined"
              ? this.state.selectedRowVerification.id
              : id
          ),
          notes: this.state.txtNotes.value,
          invoiceStatusId: Number(_srch.id),
          approvedById: Number(this.state.user.id),
          //dateLastModified: DateToday,
          isSignatureAvailable: isSignatureAvail,
        },
      };
      await invoiceApi.updateInvoice(this.props.dispatch, data);

      // const _invoices = await invoiceApi.getOrderByStatusID(
      //   this.state.dispatch,
      //   18
      // );

      //await this.refreshVerificationData();

      if (this.state.handleVerificationModal.isShow)
        this.handleToggleVerificationModal();

      this.props.dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Successfully Modified Invoice Status`,
        },
      });

      // this.setState({
      //   //verificationTableSelection: [],
      //   //selectedRowVerification: null,
      //   // verificationTableData: _invoices,
      //   // verificationTableDataCopy: _invoices,
      // });
    } else {
      console.log("Error");
    }
  };

  updateInvoiceType = async (statusName, invoiceName, id) => {
    const _srchStatus = this.state.selDetailStatus.data.find(
      (x) => x.name === statusName
    );
    const _srchType = this.state.selDetailType.data.find(
      (x) => x.name === invoiceName
    );

    if (_srchStatus && _srchType) {
      const data = {
        data: {
          id: Number(
            typeof id === "undefined"
              ? this.state.selectedRowVerification.id
              : id
          ),
          notes: this.state.txtNotes.value,
          //dateLastModified: DateToday,
          invoiceStatusId: Number(_srchStatus.id),
          invoiceTypeId: Number(_srchType.id),
          approvedById: Number(this.state.user.id),
          isSignatureAvailable: 1,
        },
      };
      await invoiceApi.updateInvoice(this.props.dispatch, data);

      const _srchPendingApproval = this.state.selDetailStatus.data.find(
        (x) => x.name === "Pending Admin Approval"
      );

      if (_srchPendingApproval) {
        //await this.refreshVerificationData();
        // const _invoices = await invoiceApi.getOrderByStatusID(
        //   this.state.dispatch,
        //   _srchPendingApproval.id
        // );

        this.setState({
          verificationTableSelection: [],
          selectedRowVerification: null,
          // verificationTableData: _invoices,
          // verificationTableDataCopy: _invoices,
        });
      }

      this.props.dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Successfully Modified Invoice Type`,
        },
      });

      if (this.state.handleVerificationModal.isShow)
        this.handleToggleVerificationModal();
    } else {
      this.state.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Error",
          JsxDesc: () => {
            return (
              <>
                <p className="m-0 p-0">Please contact admin</p>
                <p className="m-0 p-0">Error:</p>
                <p className="m-0 p-0">Some Order Details might be not added</p>
                <p className="m-0 p-0">
                  {invoiceName} | {statusName}
                </p>
                <p className="m-0 p-0">
                  {_srchStatus?.length} | {_srchType?.length}
                </p>
              </>
            );
          },
          isYesNo: false,
        },
      });
      console.log(
        "Some Order Details might be not added",
        `${invoiceName} |`,
        _srchStatus,
        `${statusName} | `,
        _srchType
      );
    }
  };

  populateVerificationModal = async (data, cb) => {
    const x = this.state.invoiceDetailsConst;
    for (let index = 0; index < x.length; index++) {
      const search = data.details.find((f) => f.type === x[index].value);

      if (search) {
        this.setState((a) => ({
          [`selDetail${x[index].name}`]: {
            ...a[`selDetail${x[index].name}`],
            value: search,
          },
        }));
      }
    }

    let _content = [];

    for (let i = 0; i < data.content.length; i++) {
      const e = data.content[i];
      if (e.item !== null) {
        if (e.item.uploads.length > 0) {
          let _imgChild = [];
          const _img = await inventoryApi.downloadItemImg(
            this.props.dispatch,
            e.item,
            1
          );
          if (e.childId !== null) {
            if (e.child.item.uploads.length > 0) {
              _imgChild = await inventoryApi.downloadItemImg(
                this.props.dispatch,
                e.child.item,
                1
              );
            }
          }
          if (_imgChild.length > 0) {
            _content.push({
              ...e,
              dispImg: _img[0],
              child: { ...e.child, dispImg: _imgChild[0] },
            });
          } else {
            _content.push({ ...e, dispImg: _img[0], showImg: 1 });
          }
        } else {
          _content.push({ ...e });
        }
      } else {
        _content.push({ ...e });
      }
    }

    const CHANGE_ITEM_FILTER = (item) => {
      //console.log(item)
      if (item.orderStatus !== null) {
        if (item.orderStatus.name !== "Change Item") return true;
        else return false;
      } else {
        return true;
      }
    };

    if (data.invoiceType.name !== "Delivery Receipt")
      _content = _content.filter(CHANGE_ITEM_FILTER);

    let _terms = [];
    for (let i = 0; i < data.details.length; i++) {
      const _e = data.details[i];
      if (_e.type === 6) {
        _terms.push(_e);
      }
    }

    let _grandTotal = 0;
    let _grandTotalSRP = 0;

    if (data.invoiceType.name !== "Purchase Request") {
      _grandTotal = _content
        .reduce((a, c) => {
          return a + c.price * c.itemQty;
        }, 0)
        .toFixed(2);
    } else {
      _grandTotal = _content
        .reduce((a, c) => {
          return a + c.price * c.qtyOrdered;
        }, 0)
        .toFixed(2);
      _grandTotalSRP = _content
        .reduce((a, c) => {
          return a + c.srp * c.itemQty;
        }, 0)
        .toFixed(2);
    }
    if (data.invoiceType.name === "Quote") {
      _grandTotalSRP = _content
        .reduce((a, c) => {
          return a + c.srp * c.itemQty;
        }, 0)
        .toFixed(2);
    }

    let _showPendingApproval = false;
    if (data.invoiceStatus.name === "New") _showPendingApproval = true;
    if (data.invoiceStatus.name === "Admin Denied") _showPendingApproval = true;

    const _isChangeItem = data.content.find((x) => x.childId !== null);

    this.setState(
      (a) => ({
        showPendingApproval: _showPendingApproval,
        isChangeItem: _isChangeItem ? true : false,
        txtQty: { ...a.txtQty, value: data.qty },
        txtNetPrice: { ...a.txtNetPrice, value: data.price },
        selDetailClient: { ...a.selDetailClient, value: data.client },
        selDetailSupplier: { ...a.selDetailSupplier, value: data.supplier },
        txtClient: {
          ...a.txtClient,
          value: data.client !== null ? data.client.company : null,
        },
        txtSupplier: {
          ...a.txtSupplier,
          value: data.supplier !== null ? data.supplier : null,
        },
        txtSalesperson: {
          ...a.txtSalesperson,
          value: data.incharge !== null ? data.incharge.name : null,
        },
        txtInvoiceName: { ...a.txtInvoiceName, value: data.name },
        grandTotal: _grandTotal,
        grandTotalSRP: _grandTotalSRP,
        txtDesc: { ...a.txtDesc, value: data.description },
        txtNotes: { ...a.txtNotes, value: data.notes },
        txtDeadline: {
          ...a.txtDeadline,
          value: misc.parseAPIDateTime(data.deadline),
        },
        txtDateLastModified: {
          ...a.txtDateLastModified,
          value: misc.parseAPIDateTime(data.dateLastModified),
        },
        txtDateAdded: {
          ...a.txtDateAdded,
          value: misc.parseAPIDateTime(data.dateAdded),
        },
        txtShippingAddress: {
          ...a.txtInvoiceName,
          value: data.shippingAddress,
        },
        selDetailStatus: {
          ...a.selDetailStatus,
          value: this.state.selDetailStatus.data.find(
            (x) => x.id === data.invoiceStatusId
          ),
        },
        selDetailType: { ...a.selDetailType, value: data.invoiceType },
        selDetailIncharge: {
          ...a.selDetailIncharge,
          value: this.state.selDetailIncharge.data.find(
            (x) => x.id === data.inchargeId
          ),
        },
        txtInvFrom: {
          ...a.txtInvFrom,
          value: data.inventoryFrom !== null ? data.inventoryFrom.name : "",
        },
        txtInvTo: {
          ...a.txtInvTo,
          value: data.inventoryTo !== null ? data.inventoryTo.name : "",
        },

        verificationTableContentData: _content,
        verificationTableTermsData: _terms,
        invoiceContentTableData: _content,
        termsTableData: _terms,
      }),
      async (a) => {
        console.log("SELECTED INVOICE ==>", data, this.state);
        //const _services = _content.filter((x) => x.itemId === null);
        //const _items = _content.filter((x) => x.itemId !== null);

        const CHANGE_ITEM_FILTER = (item) => {
          if (item.placeholder !== null || item.placeholder !== "") {
            return true;
          }
          if (item.itemId !== null) {
            return true;
          } else {
            return false;
          }
        };

        const _services = _content.filter(
          (x) => x.itemId === null && x.serviceName !== null
        );
        const _items =
          this.state.invoiceContentTableData.filter(CHANGE_ITEM_FILTER);
        const perChunk = 8; // items per chunk
        let result = _items.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / perChunk);
          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
          }
          resultArray[chunkIndex].push({ ...item, index: index + 1 });
          return resultArray;
        }, []);

        switch (data.invoiceType.name) {
          case "Quote":
            this.setState(
              {
                pdfGroupTable: { result, _services },
              },
              (x) => {
                //console.log(result, _services);
                this.setState({
                  // pdfContent: QuotePDF.QuotePDF(
                  //   this.state,
                  //   this.state.selectedRowVerification.approvedBy !== null
                  //     ? this.state.selectedRowVerification.approvedBy
                  //     : "",
                  //   this.state.selectedRowVerification.isSignatureAvailable,
                  //   this.state.selectedRowVerification.incharge !== null
                  //     ? this.state.selectedRowVerification.incharge
                  //     : ""
                  // ),
                });
                if (cb) cb();
              }
            );
            break;
          case "Purchase Request":
            this.setState(
              {
                pdfGroupTable: { result, _services },
              },
              (x) => {
                this.setState({
                  pdfContent: PR.PRPDF(
                    this.state,
                    data,
                    true,
                    this.state.selectedRowVerification.approvedBy !== null
                      ? this.state.selectedRowVerification.approvedBy
                      : null,
                    false,
                    this.state.selectedRowVerification.incharge !== null
                      ? this.state.selectedRowVerification.incharge
                      : null
                  ),
                });
                if (cb) cb();
              }
            );
            break;
          case "Transfer Inventory":
            this.setState(
              {
                pdfGroupTable: { result, _services },
              },
              (x) => {
                this.setState({
                  pdfContent: TransferItem.TransferItemPDF(
                    this.state,
                    true,
                    this.state.selectedRowVerification.uniqueId,
                    this.state.selectedRowVerification.invoiceStatus?.name,
                    this.state.selectedRowVerification.incharge?.name,
                    this.state.selectedRowVerification.approvedBy !== null
                      ? this.state.selectedRowVerification.approvedBy?.name
                      : "",
                    this.state.selectedRowVerification.incharge !== null
                      ? this.state.selectedRowVerification.incharge
                      : null,
                    false,

                    this.state.selectedRowVerification.inventoryFrom?.name,
                    this.state.selectedRowVerification.inventoryTo?.name
                  ),
                });
                if (cb) cb();
              }
            );
            break;
          case "Delivery Receipt":
            const Parent = await invoiceApi.getInvoice(
              this.state.dispatch,
              Number(data.parentId)
            );

            this.setState(
              {
                pdfGroupTable: { result, _services },
                drParent: Parent,
              },
              (x) => {
                this.setState({
                  pdfContent: DR.DRPDF(
                    this.state,
                    Parent,
                    true,
                    this.state.selectedRowVerification.uniqueId,
                    this.state.selectedRowVerification.invoiceStatus.name,
                    this.state.selectedRowVerification.shippingAddress,
                    this.state.selectedRowVerification.incharge !== null
                      ? this.state.selectedRowVerification.incharge
                      : null,
                    this.state.selectedRowVerification.approvedBy !== null
                      ? this.state.selectedRowVerification.approvedBy
                      : null,
                    false
                  ),
                });
                if (cb) cb();
              }
            );
            break;
          default:
            break;
        }
      }
    );
  };

  toggleSelectionVerificationTableContent = (key, shift, row) => {
    let verificationTableContentSelection =
      this.state.verificationTableContentSelection;
    const keyIndex = verificationTableContentSelection.indexOf(key);
    if (keyIndex >= 0) {
      verificationTableContentSelection = [];
      this.setState((x) => ({
        selectedRowVerificationTableContent: null,
      }));
    } else {
      verificationTableContentSelection.length = 0;
      verificationTableContentSelection.push(key);
      console.log("SELECTED INVOICE CONTENT ITEM ===> ", row);
      this.setState((x) => ({
        selectedRowVerificationTableContent: row,
      }));
    }
    this.setState(
      (x) => ({
        verificationTableContentSelection,
      }),
      () => {
        this.handleActionChange({
          target: {
            value: row.priceModifier ? row.priceModifier : 0,
            name: "txtMarkupPercent",
          },
        });
        this.handleActionChange({
          target: {
            value: row.discount ? row.discount : 0,
            name: "txtDiscount",
          },
        });
      }
    );
  };

  getInvoiceHeaderSettings = async (id) => {
    const settings = await settingsApi.getSettingsByInventory(
      this.props.dispatch,
      id ? Number(id) : 1
    );

    //Update Invoice Headers
    setInvoiceHeaderData(
      settings?.invoiceHeaderAddress1,
      settings?.invoiceHeaderAddress2,
      settings?.invoiceHeaderAddress3,
      settings?.invoiceHeaderLandline,
      settings?.invoiceHeaderMobile,
      settings?.invoiceHeaderContact,
      settings?.invoiceHeaderEmail,
      settings?.invoiceDeliveryReceiveAddress
    );
  };

  toggleSelection = (key, shift, row) => {
    let verificationTableSelection = this.state.verificationTableSelection;
    const keyIndex = verificationTableSelection.indexOf(key);
    if (keyIndex >= 0) {
      verificationTableSelection = [];
      this.setState((x) => ({
        selectedRowVerification: null,
      }));
    } else {
      this.getInvoiceHeaderSettings(row.inventoryId);
      verificationTableSelection.length = 0;
      verificationTableSelection.push(key);
      console.log("SELECTED VERIFICATION INVOICE ===> ", row);

      this.setState((x) => ({
        selectedRowVerification: row,
      }));
    }
    this.setState((x) => ({
      verificationTableSelection,
    }));
  };

  isSelected = (key) => {
    return this.state.verificationTableSelection.includes(`select-${key}`);
  };

  rowFn = (state, rowInfo, column, instance) => ({
    onClick: async (e, handleOriginal) => {
      e.preventDefault();
      e.stopPropagation();

      if (
        this.state.verificationTableSelection.length > 0 &&
        this.state.verificationTableSelection[0] ===
          `select-${rowInfo.original.id}`
      ) {
        this.setState((x) => ({
          verificationTableSelection: [],
        }));
      } else {
        if (rowInfo) {
          this.toggleSelection(rowInfo.index, null, rowInfo.original);
          //console.log(rowInfo.original);

          if (
            rowInfo?.original?.generalTransType === "transaction" ||
            rowInfo?.original?.generalTransType === "journal"
          ) {
            if (rowInfo?.original?.clientId) {
              const fetchedTrans = await TransactionsAPI.getTransaction(
                this.props.dispatch,
                rowInfo?.original?.id
              );

              let perChunk = 8; // items per chunk

              let result = fetchedTrans.order.content.reduce(
                (resultArray, item, index) => {
                  const chunkIndex = Math.floor(index / perChunk);
                  if (!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = []; // start a new chunk
                  }
                  resultArray[chunkIndex].push({
                    ...item,
                    index: index + 1,
                  });
                  return resultArray;
                },
                []
              );
              for (let index = 0; index < result.length; index++) {
                const element = result[index];
                result[index] = element.sort(function (a, b) {
                  return a.partIndex - b.partIndex;
                });
              }
              const ITEMS_GRANDTOTAL = fetchedTrans.order.content.reduce(
                (a, c) => {
                  return a + c.price * c.itemQty;
                },
                0
              );

              //Filter invoice transactions for payments
              let selectedInvoicePayments = [];
              selectedInvoicePayments =
                fetchedTrans.order.paymentTransaction.filter(
                  (x) => x.isEntry !== 1
                );

              //Push Index to paymentData
              const IndexedPaymentData = selectedInvoicePayments.map(
                (x, index) => {
                  return { ...x, index: index + 1 };
                }
              );

              //let paymentData = [];
              let invoiceTotalAmountPaid = 0;
              let invoiceTotalAmountDue = 0;

              // paymentData =
              //   data.paidAmountHistory !== null ? JSON.parse(data.paidAmountHistory) : [];

              invoiceTotalAmountPaid = selectedInvoicePayments
                .reduce((a, c) => {
                  // if (c.childId == null) return a + c.price * c.itemQty;
                  // else return a + c.child.price * c.child.itemQty;
                  return a + c.amountValue;
                }, 0)
                .toFixed(2);

              invoiceTotalAmountDue = ITEMS_GRANDTOTAL - invoiceTotalAmountPaid;

              if (fetchedTrans) {
                const blob = await pdf(
                  ChargeBillingReceipt.ChargeInvoice(
                    fetchedTrans.createdBy,
                    "Billing",
                    fetchedTrans.uniqueId,
                    fetchedTrans.client,
                    fetchedTrans.order.dateAdded,
                    fetchedTrans.amountValue,
                    fetchedTrans.createdBy,
                    fetchedTrans.approvedBy,
                    result,
                    fetchedTrans.paymentBankName,
                    fetchedTrans.paymentBankNumber,
                    fetchedTrans.checkInfo1,
                    fetchedTrans.transStatus,
                    ITEMS_GRANDTOTAL,
                    IndexedPaymentData,
                    Number(invoiceTotalAmountDue),
                    fetchedTrans
                  )
                ).toBlob();

                const blobLink = await window.URL.createObjectURL(blob);

                this.setState({ BLOB_TO_PDF_DISPLAY: blobLink });

                // this.props.dispatch({
                //   type: "FILE_VIEWER_MODAL_TOGGLE",
                //   doc: blobLink,
                // });
              } else {
                this.props.dispatch({
                  type: "MODAL_SHOW",
                  payload: {
                    title: "Error Fetching Data",
                    desc: `Please Contact Administrators. Error in route getTransaction`,
                    isYesNo: false,
                  },
                });
              }
            } else {
              let voucherType = "Cash";
              const fetchedTrans = await TransactionsAPI.getTransaction(
                this.props.dispatch,
                rowInfo?.original?.id
              );
              if (fetchedTrans.paymentType === "cash") {
                voucherType = "Cash";
              } else {
                voucherType = "Check";
              }
              let perChunk = 20; // items per chunk

              let tempTransList = fetchedTrans.content;

              tempTransList.sort(function (a, b) {
                return a.partIndex - b.partIndex;
              });
              let result = tempTransList.reduce((resultArray, item, index) => {
                const chunkIndex = Math.floor(index / perChunk);
                if (!resultArray[chunkIndex]) {
                  resultArray[chunkIndex] = []; // start a new chunk
                }
                resultArray[chunkIndex].push({
                  ...item,
                  index: index + 1,
                });
                return resultArray;
              }, []);
              if (fetchedTrans) {
                const blob = await pdf(
                  Voucher.Voucher(
                    fetchedTrans.createdBy,
                    voucherType,
                    fetchedTrans.uniqueId,
                    fetchedTrans.order?.supplier
                      ? fetchedTrans.order?.supplier
                      : fetchedTrans.supplierVendor,
                    fetchedTrans.order?.dateAdded,
                    fetchedTrans.amountValue,
                    fetchedTrans.createdBy,
                    fetchedTrans.approvedBy,
                    result,
                    fetchedTrans.paymentBankName,
                    fetchedTrans.paymentBankNumber,
                    fetchedTrans.checkInfo1,
                    fetchedTrans.transStatus
                  )
                ).toBlob();

                const blobLink = await window.URL.createObjectURL(blob);
                this.setState({ BLOB_TO_PDF_DISPLAY: blobLink });
                // this.props.dispatch({
                //   type: "FILE_VIEWER_MODAL_TOGGLE",
                //   doc: blobLink,
                // });
              } else {
                this.props.dispatch({
                  type: "MODAL_SHOW",
                  payload: {
                    title: "Error Fetching Data",
                    desc: `Please Contact Administrators. Error in route getTransaction`,
                    isYesNo: false,
                  },
                });
              }
            }
          }
          if (rowInfo?.original?.verificationType === "INVOICE_ARCHIVE") {
            const fetchedInvoice = await invoiceApi.getInvoice(
              this.state.dispatch,
              rowInfo?.original?.id
            );
            if (fetchedInvoice) {
              let perChunk = 8; // items per chunk
              const _services = fetchedInvoice.content.filter(
                (x) => x.itemId === null && x.serviceName !== null
              );
              let result = fetchedInvoice.content.reduce(
                (resultArray, item, index) => {
                  const chunkIndex = Math.floor(index / perChunk);
                  if (!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = []; // start a new chunk
                  }
                  resultArray[chunkIndex].push({ ...item, index: index + 1 });
                  return resultArray;
                },
                []
              );
              result = result.sort(function (a, b) {
                return a.itemIndex - b.itemIndex;
              });
              let _terms = [];
              for (let i = 0; i < fetchedInvoice.details.length; i++) {
                const _e = fetchedInvoice.details[i];
                if (_e.type === 6) {
                  _terms.push(_e);
                }
              }
              let invoiceTotalAmountPaid = 0;
              let invoiceTotalAmountDue = 0;
              const _grandTotal = fetchedInvoice.content
                .reduce((a, c) => {
                  // if (c.childId == null) return a + c.price * c.itemQty;
                  // else return a + c.child.price * c.child.itemQty;
                  return a + c.price * c.itemQty;
                }, 0)
                .toFixed(2);
              invoiceTotalAmountDue = _grandTotal - invoiceTotalAmountPaid;
              // if (data.invoiceType.name === "POS")
              //   invoiceTotalAmountDue = _grandTotal - invoiceTotalAmountPaid;

              const _grandTotalSRP = fetchedInvoice.content
                .reduce((a, c) => {
                  // if (c.childId == null) return a + c.price * c.itemQty;
                  // else return a + c.child.price * c.child.itemQty;
                  return (
                    a + (c.srp + c.srp * (c.priceModifier / 100)) * c.itemQty
                  );
                }, 0)
                .toFixed(2);

              const _TotalMarkupDiscounted = fetchedInvoice.content
                .reduce((a, c) => {
                  return a + c.price * c.itemQty;
                }, 0)
                .toFixed(2);

              const _TotalMarkup = fetchedInvoice.content
                .reduce((a, c) => {
                  return (
                    a + (c.srp + c.srp * (c.priceModifier / 100)) * c.itemQty
                  );
                }, 0)
                .toFixed(2);

              const blob = await pdf(
                QuotePDF.QuotePDF(
                  result,
                  _services,
                  _terms,
                  fetchedInvoice.uniqueId,
                  fetchedInvoice?.approvedBy?.name,
                  fetchedInvoice?.client?.company,
                  fetchedInvoice?.client?.address,
                  false,
                  fetchedInvoice?.incharge?.name,
                  fetchedInvoice.dateAdded,
                  true,
                  fetchedInvoice?.invoiceStatus?.name,
                  _grandTotal,
                  _grandTotalSRP,
                  _TotalMarkupDiscounted,
                  fetchedInvoice.content,
                  _TotalMarkup
                )
              ).toBlob();

              const blobLink = await window.URL.createObjectURL(blob);
              this.setState({ BLOB_TO_PDF_DISPLAY: blobLink });
            } else {
              this.props.dispatch({
                type: "MODAL_SHOW",
                payload: {
                  title: "Error Fetching Data",
                  desc: `Please Contact Administrators. Error in route getInvoice`,
                  isYesNo: false,
                },
              });
            }
          }
          this.handleActionChange(
            { target: { name: "btnEditVerification" } },
            rowInfo.original
          );
          this.setState({
            verificationTableSelection: ["select-" + rowInfo.original.id],
          });
        }
      }

      if (handleOriginal) {
        handleOriginal();
      }
    },
    style: {
      background:
        rowInfo &&
        this.state.verificationTableSelection.includes(
          `select-${rowInfo.original.id}`
        ) &&
        "var(--REB-green)",
      color:
        rowInfo &&
        this.state.verificationTableSelection.includes(
          `select-${rowInfo.original.id}`
        ) &&
        "var(--REB-white)",
    },
  });
  rowFnVerificationTableContent = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      e.preventDefault();
      e.stopPropagation();
      const data = rowInfo.original;

      if (
        this.state.verificationTableContentSelection.length > 0 &&
        this.state.verificationTableContentSelection[0] ===
          `select-${rowInfo.original.id}`
      ) {
        this.setState((x) => ({
          verificationTableContentSelection: [],
          selectedRowVerificationTableContent: null,
        }));
      } else {
        if (rowInfo) {
          this.toggleSelectionVerificationTableContent(
            rowInfo.index,
            null,
            rowInfo.original
          );
          // console.log(rowInfo.original);
          // const amountDiscounted = data.srp * (data.discount / 100);
          //console.log(data.priceModifier)

          this.setState((x) => ({
            // txtDiscount: { ...x.txtDiscount, value: data.discount },
            // txtDiscounted: { ...x.txtDiscounted, value: amountDiscounted },
            // txtDiscountedWhole: { ...x.txtDiscountedWhole, value: data.price },

            // txtMarkupAmount: { ...x.txtDiscounted, value: amountDiscounted },
            // txtMarkupWhole: { ...x.txtDiscountedWhole, value: data.price },
            verificationTableContentSelection: [
              "select-" + rowInfo.original.id,
            ],
          }));
        }
      }

      if (handleOriginal) {
        handleOriginal();
      }
    },
    style: {
      background:
        rowInfo &&
        this.state.verificationTableContentSelection.includes(
          `select-${rowInfo.original.id}`
        ) &&
        "var(--REB-green)",
      color:
        rowInfo &&
        this.state.verificationTableContentSelection.includes(
          `select-${rowInfo.original.id}`
        ) &&
        "var(--REB-white)",
    },
  });

  handleSearch = (event) => {
    if (event.target.value !== "") {
      this.setState({ isFiltered: true });
      const data = Filters.filterData(
        this.state.verificationTableDataCopy,
        event.target.value
      );

      if (data != null) {
        this.setState({ verificationTableData: data });
      } else {
        this.setState({
          verificationTableData: this.state.verificationTableDataCopy,
        });
      }
    } else {
      this.setState({
        verificationTableData: this.state.verificationTableDataCopy,
      });
      this.setState({ isFiltered: false });
    }
  };

  render() {
    const tablelist = (
      <>
        <style type="text/css">
          {`
  
  .tableList{
    
    background-color: var(--REB-white) !important;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  }




`}
        </style>
        <Table
          data={this.state.verificationTableData}
          columns={this.state.verificationTableColumn}
          toggleSelection={this.toggleSelection}
          isSelected={this.isSelected}
          getTrProps={this.rowFn}
          defaultSorted={[
            { id: "transPrio", desc: true },
            {
              id: "transDate",
              desc: true,
            },
          ]}
          defaultPageSize={10}
          showPageSizeOptions={false}
          style={{ overflow: "auto", flex: 1 }}
          className="tableList tableActor -highlight "
        />
      </>
    );
    const tableContent = (
      <Table
        data={this.state.verificationTableContentData}
        columns={this.state.verificationTableContentColumn}
        isSelected={(key) => {
          return this.state.verificationTableContentSelection.includes(
            `select-${key}`
          );
        }}
        getTrProps={this.rowFnVerificationTableContent}
        toggleSelection={this.toggleSelectionVerificationTableContent}
        showPageSizeOptions={false}
        defaultPageSize={10}
        style={{ overflow: "auto", flex: 1 }}
        className="tableActor -highlight mt-3"
      />
    );
    const tableTerms = (
      <Table
        data={this.state.verificationTableTermsData}
        columns={termsColumn}
        defaultPageSize={10}
        showPageSizeOptions={false}
        style={{ overflow: "auto", flex: 1 }}
        className="tableActor -highlight mt-3"
      />
    );

    return (
      <Verification
        state={this.state}
        tablelist={tablelist}
        tableTerms={tableTerms}
        tableContent={tableContent}
        inputChange={this.state.inputChange}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return { user: state.apiData.userData, socketio: state.SocketHandler };
};
export default connect(mapStateToProps)(withRouter(VerificationProcess));
