import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { ButtonGroup } from "react-bootstrap";
import Select from "react-select";

let customStyles = {
  menu: (provided, state) => {
    const width = state.selectProps.width;

    let color = state.selectProps.menuColor;
    const padding = 0;

    return { ...provided, width, color, padding };
  },
  control: (provided) => ({
    ...provided,
    borderRadius: "0px 0px 0px 0px",
  }),

  container: (_, { selectProps: { width } }) => ({
    width: width,
    flex: 1,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
    borderRadius: "5px 0px 0px 5px",
  }),

  option: (provided, state) => {
    let backgroundColor = "";
    let display = "block";
    let color = "black";
    if (state.data.isDefault === 1) {
      backgroundColor = "rgba(255, 0, 0, 0.2)";
    }
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    //console.log(state.data);
    if (state.data.isHidden === 1) {
      display = "none";
    }

    return {
      ...provided,
      opacity,
      transition,
      backgroundColor,
      color,
      display,
    };
  },
};

let TxtName = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      <span className="required-field">* </span>
      Name
    </Form.Label>
    <FormControl
      type="text"
      style={{ textTransform: "uppercase" }}
      placeholder="Name"
      name="txtItemDetailName"
      value={props.state.txtItemDetailName.value}
      onChange={props.state.inputChange}
      className={
        props.state.txtItemDetailName.className + " css-actorInput mb-2"
      }
      readOnly={props.state.txtItemDetailName.readOnly}
      required
    />
  </>
);
let TxtSelect = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      <span className="required-field">* </span>
      Type
    </Form.Label>
    <select
      className="form-control actor-selector  mb-2"
      name="detModalSelType"
      value={props.state.detModalSelType.value}
      disabled={props.state.detModalSelType.readOnly}
      onChange={(e) => props.state.handleActionChange(e)}
    >
      {props.state.itemDetailsConst.map((x) => {
        return (
          <>
            {x.hidden !== 1 ? (
              <option key={x.value} value={x.value}>
                {x.name}
              </option>
            ) : null}
          </>
        );
      })}
    </select>
  </>
);

const ItemDetailsModal = (props) => (
  <Modal
    show={props.state.handleItemDetailsModal.isShow}
    onHide={props.state.handleItemDetailsModal.toggle}
    onExit={(e) => {
      props.state.handleItemDetailsModal.handleExit(e);
    }}
    dialogClassName="modal-actor-100w"
    //size="xl"
    centered
  >
    <Modal.Body className="actor-modal-body">
      <Row className="actor-modal-header-row pt-3 pb-3 mb-3">
        <Col xs={2} sm={1} md={1} lg={1}>
          <OverlayTrigger
            placement={"right"}
            overlay={<Tooltip id={`project-tooltip-add`}>Close</Tooltip>}
          >
            <Button
              variant="secondary"
              name="btnCloseProjectModal"
              style={{ backgroundColor: "var(--REB-primary)" }}
              className="icofont-rounded-left"
              onClick={(e) => {
                props.state.handleItemDetailsModal.toggle();
              }}
            />
          </OverlayTrigger>
        </Col>
        <Col xs={10} sm={11} md={11} lg={11} className="actor-modal-header-col">
          <h4 className=" actor-modal-header-text">
            {" "}
            {props.state.selectedOperation.name} Item Detail
          </h4>
        </Col>
      </Row>

      <Form
        id="formActorInput"
        noValidate
        validated={props.state.handleItemDetailsModal.isValidated}
        onSubmit={props.state.handleItemDetailsModal.handleSubmit}
      >
        <TxtName state={props.state} inputChange={props.inputChange} />
        <TxtSelect state={props.state} inputChange={props.inputChange} />

        {/* {props.state.detModalSelType.value === "1" ? (
          <Form.Check
            checked={props.state.chkSet.value}
            disabled={props.state.chkSet.readOnly}
            name="chkSet"
            label="Set / Bundle Item Type"
            onChange={(e) => {
              props.state.handleActionChange(e);
            }}
          />
        ) : null} */}

        {props.state.chkSet.value === true ? (
          <div
            className="mt-3"
            style={{ borderRadius: 0, backgroundColor: "var(--REB-body)" }}
          >
            <Row className="mb-3" noGutters>
              <Col>
                <h4>Item set contents</h4>
              </Col>
              <Col>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      style={{
                        color: "var(--REB-white)",
                        fontWeight: "bold",
                        backgroundColor: "var(--REB-primary)",
                      }}
                    >
                      ITEM NAME
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Select
                    styles={customStyles}
                    isSearchable
                    menuPortalTarget={document.body}
                    width="100%"
                    value={props.state.selSetItemName.value}
                    options={props.state.selDetailName.data}
                    isDisabled={props.state.handleItemDetailsModal.isView}
                    getOptionLabel={(x) => x.name}
                    getOptionValue={(x) => x.id}
                    //formatOptionLabel={"test"}
                    //innerRef={this.props.propRef}
                    onChange={(x, name) => {
                      //console.log(x, name);

                      props.state.handleActionChange({
                        target: {
                          value: x,
                          name: "selItemName",
                        },
                      });
                    }}
                  />
                </InputGroup>
              </Col>
              <Col className="actor-btn-crud-col d-flex justify-content-end">
                <ButtonGroup className="">
                  {props.state.selectedRowSetItems !== null ? (
                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Tooltip id={`actor-tooltip-add`}>
                          REMOVE ITEM SET
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="secondary"
                        name="btnRemoveItemSet"
                        className="mr-3"
                        style={{ backgroundColor: "var(--REB-primary)" }}
                        onClick={(e) => {
                          props.state.handleActionChange(e);
                        }}
                      >
                        REMOVE SELECTED ITEM
                      </Button>
                    </OverlayTrigger>
                  ) : null}

                  {props.state.selSetItemName.value !== null ? (
                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Tooltip id={`actor-tooltip-add`}>ADD ITEM SET</Tooltip>
                      }
                    >
                      <Button
                        variant="secondary"
                        name="btnAddItemSet"
                        // className="icofont-ui-add icofont-1x"
                        style={{ backgroundColor: "var(--REB-green)" }}
                        onClick={(e) => {
                          props.state.handleActionChange(e);
                        }}
                      >
                        ADD TO SET
                      </Button>
                    </OverlayTrigger>
                  ) : null}
                </ButtonGroup>
              </Col>
            </Row>
            {props.tableSetItems}
          </div>
        ) : null}

        <Row
          className="mb-3 mt-3 actor-modal-footer-row actor-modal-footer"
          noGutters
        >
          <Col md={6} lg={6} className="">
            <p className="font-weight-bold">Note:</p>
            <p className="">
              Fields that has asterisk (
              <span className="required-field font-weight-bold"> * </span>)
              requires an input.
            </p>
          </Col>
          <Col md={2} lg={2} className="user-col-cont-padding">
            <Button
              block
              className=""
              name="btnUnlockItem"
              style={{ backgroundColor: "var(--REB-green)" }}
              disabled={!props.state.handleItemDetailsModal.isView}
              onClick={(e) => {
                props.state.handleActionChange(e);
              }}
            >
              MODIFY
            </Button>
          </Col>
          <Col md={2} lg={2} className="user-col-cont-padding">
            <Button
              block
              className=""
              style={{ backgroundColor: "var(--REB-green)" }}
              name="btnSubmitItemDetails"
              disabled={props.state.handleItemDetailsModal.isView}
              type="submit"
              onClick={(e) => {
                props.state.handleSubmit(e);
              }}
            >
              SAVE
            </Button>
          </Col>
          <Col md={2} lg={2} className="user-col-cont-padding">
            <Button
              block
              className=""
              name="btnDeleteItem"
              style={{ backgroundColor: "var(--REB-warning)" }}
              disabled={
                props.state.handleItemDetailsModal.isView ||
                Number(props.state.selectedOperation.id) === 0
              }
              onClick={(e) => {
                props.state.handleActionChange(e);
              }}
            >
              DELETE
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal.Body>
  </Modal>
);

export { ItemDetailsModal };
