import React, { useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import InchargeSelect from "../../components/CustomDrop";
import StatusSelect from "../../components/CustomDrop";
import ClientSelect from "../../components/CustomDrop";
import { NumericFormat } from "react-number-format";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import Table from "react-table";
import fileReg from "../../assets/file-regular.svg";
import { SELECTED_OPERATION } from "../../constants";
// let TxtAmountDue = (props) => (
//   <>
//     <InputGroup className="pl-2 pr-2">
//       <InputGroup.Prepend>
//         <InputGroup.Text
//           style={{
//             color: "var(--REB-white)",
//             fontWeight: "bold",
//             backgroundColor: "var(--REB-primary)",
//           }}
//         >
//           Amount Due
//         </InputGroup.Text>
//       </InputGroup.Prepend>
//       <FormControl
//         as={NumberFormat}
//         inputMode="numeric"
//         thousandSeparator={true}
//         name="txtAmountDue"
//         value={props.state.txtAmountDue.value}
//         onValueChange={(x) => {
//           props.state.inputChange({
//             target: {
//               value: x.value,
//               name: "txtAmountDue",
//               placeholder: props.state.txtAmountDue.placeholder,
//               className: props.state.txtAmountDue.className,
//             },
//           });
//         }}
//         className={props.state.txtAmountDue.className}
//         readOnly={props.state.txtAmountDue.readOnly}
//       />
//     </InputGroup>
//   </>
// );

// let TxtAmountPaid = (props) => (
//   <>
//     <InputGroup className="pl-2 pr-2">
//       <InputGroup.Prepend>
//         <InputGroup.Text
//           style={{
//             color: "var(--REB-white)",
//             fontWeight: "bold",
//             backgroundColor: "var(--REB-primary)",
//           }}
//         >
//           Amount Paid
//         </InputGroup.Text>
//       </InputGroup.Prepend>
//       <FormControl
//         as={NumberFormat}
//         inputMode="numeric"
//         thousandSeparator={true}
//         name="txtAmountPaid"
//         value={props.state.txtAmountPaid.value}
//         onValueChange={(x) => {
//           props.state.inputChange({
//             target: {
//               value: x.value,
//               name: "txtAmountPaid",
//               placeholder: props.state.txtAmountPaid.placeholder,
//               className: props.state.txtAmountPaid.className,
//             },
//           });
//         }}
//         className={props.state.txtAmountPaid.className}
//         readOnly={props.state.txtAmountPaid.readOnly}
//       />
//     </InputGroup>
//   </>
// );

// let TxtInvoiceOrigTotalDue = (props) => (
//   <>
//     <InputGroup className="pl-2 pr-2">
//       <InputGroup.Prepend>
//         <InputGroup.Text
//           style={{
//             color: "var(--REB-white)",
//             fontWeight: "bold",
//             backgroundColor: "var(--REB-primary)",
//           }}
//         >
//           Invoice Total
//         </InputGroup.Text>
//       </InputGroup.Prepend>
//       <FormControl
//         as={NumberFormat}
//         inputMode="numeric"
//         thousandSeparator={true}
//         name="txtInvoiceOrigTotalDue"
//         value={props.state.txtInvoiceOrigTotalDue.value}
//         onValueChange={(x) => {
//           props.state.inputChange({
//             target: {
//               value: x.value,
//               name: "txtInvoiceOrigTotalDue",
//               placeholder: props.state.txtInvoiceOrigTotalDue.placeholder,
//               className: props.state.txtInvoiceOrigTotalDue.className,
//             },
//           });
//         }}
//         className={props.state.txtInvoiceOrigTotalDue.className}
//         readOnly={props.state.txtInvoiceOrigTotalDue.readOnly}
//       />
//     </InputGroup>
//   </>
// );
function TxtFileUpload(props) {
  const [btnSave, setbtnSave] = useState(true);
  const inputFile = useRef();
  return (
    <>
      <InputGroup
        className="mb-3 p-3"
        style={{ backgroundColor: "var(--REB-white)" }}
      >
        <FormControl
          type="file"
          custom
          ref={inputFile}
          onClick={(e) => (e.target.value = null)}
          data-browse="Browse"
          className="inputFileUpload"
          name="fileUploadInvoice"
          // disabled={props.state.selContentItems.readOnly}
          onChange={(e) => {
            props.state.handleActionChange({
              target: {
                name: "handleFileUploadChange",
                files: e.target.files,
              },
            });
            //console.log(Array.from(e.target.files));

            if (Array.from(e.target.files).length === 0) {
              setbtnSave(true);
            } else {
              setbtnSave(false);
            }
          }}
        />

        <ButtonGroup className="">
          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip id={`order-tooltip-add`}>Save</Tooltip>}
          >
            <Button
              variant="secondary"
              size="sm"
              style={{ backgroundColor: "var(--REB-green)" }}
              onClick={(e) => {
                // props.state.fileUpload.handleSubmit(e);
                e.preventDefault();
                props.state.handleActionChange(
                  {
                    target: {
                      name: "btnFileUploadSubmit",
                      value: e,
                    },
                  },
                  null,
                  () => {
                    inputFile.current.value = null;
                  }
                );
              }}
              disabled={btnSave}
            >
              Save
            </Button>
          </OverlayTrigger>
        </ButtonGroup>
      </InputGroup>
      <Row className="p-3">
              {/* {console.log(props.state.selectedRowInvoice?.uploads.length)} */}
              {(()=>{

                let _selectedRowInvoice = props.state.selectedRowInvoice &&
                props.state.selectedRowInvoice?.uploads.length > 0 ? props.state.selectedRowInvoice?.uploads : []

                try {
                  _selectedRowInvoice = JSON.parse(_selectedRowInvoice)
                } catch (error) {
                  _selectedRowInvoice = props.state.selectedRowInvoice?.uploads
                  //console.log(_selectedRowInvoice)
                }

                if(_selectedRowInvoice){
                  //console.log(_selectedRowInvoice)
                  return(
                    
                    _selectedRowInvoice.map((i, index) => (
                    <Col sm={3} key={index}>
                      <Card className="text-center" style={{ padding: "1px" }}>
                        <Card.Body>
                          <Row className="mb-3">
                            <Col>
                              <Image src={fileReg} style={{ width: "20%" }} />
                            </Col>
                          </Row>
                          <Row noGutters>
                            <Col className="">
                              <Button
                                size="md"
                                style={{
                                  backgroundColor: "var(--REB-green)",
                                }}
                                onClick={(e) => {
                                  props.state.handleActionChange({
                                    target: {
                                      name: "btnFileUploadDownload",
                                      complete: i.originalname,
                                      id: props.state.selectedRowInvoice.uniqueId,
                                      filename: i.filename,
                                    },
                                  });
                                }}
                                name="btnDLLink"
                                key={index}
                              >
                                Download
                              </Button>
                            </Col>
                            <Col className="">
                              <Button
                                size="md"
                                style={{
                                  backgroundColor: "var(--REB-green)",
                                }}
                                onClick={(e) => {
                                  props.state.handleActionChange({
                                    target: {
                                      name: "btnFileUploadView",
                                      filename: i.filename,
                                      originalname: i.originalname,
                                      id: props.state.selectedRowInvoice.uniqueId,
                                    },
                                  });
                                }}
                              >
                                View
                              </Button>
                            </Col>
                            <Col className="">
                              <Button
                                size="md"
                                name={`delete${i.filename}`}
                                value={i.filename}
                                disabled={props.state.selContentItems.readOnly}
                                style={{
                                  backgroundColor: "var(--REB-warning)",
                                }}
                                onClick={(e) => {
                                  props.state.handleActionChange({
                                    target: {
                                      name: "btnFileUploadDelete",
                                      filename: i.filename,
                                      originalname: i.originalname,
                                    },
                                  });
                                }}
                              >
                                Delete
                              </Button>
                            </Col>
                          </Row>
                        </Card.Body>
                        <Card.Footer>{i.originalname}</Card.Footer>
                      </Card>
                    </Col>
                  )))
                }

              })()}
        {/* {props.state.selectedRowInvoice &&
        props.state.selectedRowInvoice?.uploads.length > 0
          ? JSON.parse(props.state.selectedRowInvoice?.uploads).map((i, index) => (
              <Col sm={3} key={index}>
                <Card className="text-center" style={{ padding: "1px" }}>
                  <Card.Body>
                    <Row className="mb-3">
                      <Col>
                        <Image src={fileReg} style={{ width: "20%" }} />
                      </Col>
                    </Row>
                    <Row noGutters>
                      <Col className="">
                        <Button
                          size="md"
                          style={{
                            backgroundColor: "var(--REB-green)",
                          }}
                          onClick={(e) => {
                            props.state.handleActionChange({
                              target: {
                                name: "btnFileUploadDownload",
                                complete: i.originalname,
                                id: props.state.selectedRowInvoice.uniqueId,
                                filename: i.filename,
                              },
                            });
                          }}
                          name="btnDLLink"
                          key={index}
                        >
                          Download
                        </Button>
                      </Col>
                      <Col className="">
                        <Button
                          size="md"
                          style={{
                            backgroundColor: "var(--REB-green)",
                          }}
                          onClick={(e) => {
                            props.state.handleActionChange({
                              target: {
                                name: "btnFileUploadView",
                                filename: i.filename,
                                originalname: i.originalname,
                                id: props.state.selectedRowInvoice.uniqueId,
                              },
                            });
                          }}
                        >
                          View
                        </Button>
                      </Col>
                      <Col className="">
                        <Button
                          size="md"
                          name={`delete${i.filename}`}
                          value={i.filename}
                          disabled={props.state.selContentItems.readOnly}
                          style={{
                            backgroundColor: "var(--REB-warning)",
                          }}
                          onClick={(e) => {
                            props.state.handleActionChange({
                              target: {
                                name: "btnFileUploadDelete",
                                filename: i.filename,
                                originalname: i.originalname,
                              },
                            });
                          }}
                        >
                          Delete
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>{i.originalname}</Card.Footer>
                </Card>
              </Col>
            ))
          : ""} */}
      </Row>
    </>
  );
}

let TxtQtyView = (props) => (
  <>
    {/* {console.log(props.state.selContentItems.value)} */}
    <div
      className=""
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "var(--REB-primary)",
        borderRadius: "5px",
      }}
    >
      <InputGroup.Prepend>
        <p
          className="m-0 pl-2"
          style={{
            color: "var(--REB-white)",
            fontWeight: "bold",

            // backgroundColor: "var(--REB-primary)",
          }}
        >
          AVAILABLE QTY
        </p>
      </InputGroup.Prepend>
      {props.state.txtQtyView.value > 0 ? (
        <FormControl
          as={NumericFormat}
          inputMode="numeric"
          thousandSeparator={true}
          suffix={
            props.isItem
              ? ` ${
                  props.state.selContentItems.value !== ""
                    ? props.state.selContentItems.value.details.find(
                        (x) => x.type === 2
                      ).name
                    : ""
                }`
              : ""
          }
          name="txtQtyView"
          value={props.state.txtQtyView.value}
          readOnly={true}
        />
      ) : (
        <h3
          className="pl-2"
          style={{
            color: "var(--REB-warning)",
            fontWeight: "bold",
          }}
        >
          NO STOCK
        </h3>
      )}
    </div>
  </>
);
let TxtDisplayQtyView = (props) => (
  <>
    {/* {console.log(props.state.selContentItems.value)} */}
    <div
      className=""
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "var(--REB-primary)",
        borderRadius: "5px",
      }}
    >
      <InputGroup.Prepend>
        <p
          className="m-0 pl-2"
          style={{
            color: "var(--REB-white)",
            fontWeight: "bold",

            // backgroundColor: "var(--REB-primary)",
          }}
        >
          DISPLAY QTY
        </p>
      </InputGroup.Prepend>
      <ButtonGroup>
        <FormControl
          as={NumericFormat}
          inputMode="numeric"
          thousandSeparator={true}
          suffix={
            props.isItem
              ? ` ${
                  props.state.selContentItems.value !== ""
                    ? props.state.selContentItems.value.details.find(
                        (x) => x.type === 2
                      ).name
                    : ""
                }`
              : ""
          }
          name="txtDisplayQtyView"
          value={props.state.txtDisplayQtyView.value}
          readOnly={true}
        />
        <InputGroup.Prepend>
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip id={`actor-tooltip-add`}>
                Transfer 1 qty of this selected item to available qty.
              </Tooltip>
            }
          >
            <Button
              variant="secondary"
              name="btnTransferToAvailQty"
              // disabled={props.state.selContentItems.readOnly}
              // className="icofont-exchange"
              style={{
                backgroundColor: "var(--REB-tertiary)",
                borderRadius: "0px 5px 5px 0px",
              }}
              onClick={(e) => {
                if (props.state.selContentItems.value === "") {
                  props.state.dispatch({
                    type: "MODAL_SHOW",
                    payload: {
                      title: "No selected item",
                      desc: `Please select an item to add to quote`,
                      isYesNo: false,
                    },
                  });
                } else {
                  if (Number(props.state.txtDisplayQtyView.value) > 0) {
                    props.state.dispatch({
                      type: "MODAL_SHOW",
                      payload: {
                        title: "Transfer notice",
                        desc: `Are you sure you want to Transfer 1 display item to qty?`,
                        isYesNo: true,
                        cb: () => {
                          props.state.handleActionChange({
                            target: {
                              name: "btnTransferToDisplay",
                              value: e,
                            },
                          });
                        },
                      },
                    });
                  } else {
                    props.state.dispatch({
                      type: "MODAL_SHOW",
                      payload: {
                        title: "Qty Notice",
                        desc: `This selected item has no display to transfer.`,
                        isYesNo: false,
                      },
                    });
                  }
                }

                //props.state.handleItemModal.toggle();
              }}
            >
              TRANSFER
            </Button>
          </OverlayTrigger>
        </InputGroup.Prepend>
      </ButtonGroup>
    </div>
  </>
);

let TxtSRPView = (props) => (
  <>
    {/* {console.log(props.state.selContentItems.value)} */}
    <div
      className=""
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "var(--REB-primary)",
        borderRadius: "5px",
      }}
    >
      <InputGroup.Prepend>
        <p
          className="m-0 pl-2"
          style={{
            color: "var(--REB-white)",
            fontWeight: "bold",

            // backgroundColor: "var(--REB-primary)",
          }}
        >
          SRP
        </p>
      </InputGroup.Prepend>
      <FormControl
        as={NumericFormat}
        inputMode="numeric"
        thousandSeparator={true}
        prefix={"₱ "}
        name="txtSRPView"
        value={props.state.txtSRPView.value}
        readOnly={true}
      />
    </div>
  </>
);

let TxtQty = (props) => (
  <>
    {/* {console.log(props.state.selContentItems.value)} */}
    <div
      className=""
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "var(--REB-primary)",
        borderRadius: "5px",
      }}
    >
      <InputGroup.Prepend>
        <p
          className="m-0 pl-2"
          style={{
            color: "var(--REB-white)",
            fontWeight: "bold",

            // backgroundColor: "var(--REB-primary)",
          }}
        >
          QTY
        </p>
      </InputGroup.Prepend>
      <FormControl
        as={NumericFormat}
        inputMode="numeric"
        thousandSeparator={true}
        suffix={
          props.isItem
            ? ` ${
                props.state.selContentItems.value !== ""
                  ? props.state.selContentItems.value.details.find(
                      (x) => x.type === 2
                    ).name
                  : ""
              }`
            : ""
        }
        placeholder={
          props.isItem
            ? props.state.txtQtyView.value > 0
              ? `AVBL QTY: ${
                  props.state.selContentItems.value !== ""
                    ? props.state.txtQtyView.value
                    : ""
                } ${
                  props.state.selContentItems.value !== ""
                    ? props.state.selContentItems.value.details.find(
                        (x) => x.type === 2
                      ).name
                    : ""
                }`
              : "NO STOCK"
            : `SERVICE QTY`
        }
        name="txtQty"
        value={props.state.txtQty.value}
        onValueChange={(x) => {
          props.state.handleActionChange({
            target: {
              value: Number(x.value),
              name: "txtQty",
              // placeholder: props.state.txtQty.placeholder,
              // className: props.state.txtQty.className,
            },
          });
        }}
        className={props.state.txtQty.className}
        readOnly={props.state.txtQty.readOnly}
      />
    </div>
  </>
);

function CostPrice(props) {
  // console.log(props);

  let priceModifier = 0;
  let productPrice = 0;

  if (props?.state?.settings?.itemPriceModification) {
    priceModifier = props?.state?.settings?.itemPriceModification / 100;
    // priceModifier = percentageModifier;

    // const INVENTORY_PRICE = Number(props.state.txtNetPrice.value)

    // productPrice = INVENTORY_PRICE + (INVENTORY_PRICE * percentageModifier);
    // productPrice = Number(productPrice.toFixed(2))
    // console.log(priceModifier);
  }

  const [isDicountLimit, setDiscountLimit] = useState(false);
  return (
    <>
      <div
        className=""
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "var(--REB-primary)",
          borderRadius: "5px",
        }}
      >
        <InputGroup.Prepend>
          <p
            className="m-0 pl-2"
            style={{
              color: "var(--REB-white)",
              fontWeight: "bold",

              // backgroundColor: "var(--REB-primary)",
            }}
          >
            PRICE (SRP)
          </p>
        </InputGroup.Prepend>
        <div
          className=""
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <FormControl
            as={NumericFormat}
            inputMode="numeric"
            thousandSeparator={true}
            prefix={"₱ "}
            style={{ flex: 2 }}
            placeholder={
              props.isItem
                ? `SRP: ₱ ${
                    props.state.selContentItems.value !== ""
                      ? props.state.selContentItems.value.price
                      : ""
                  } `
                : `SERVICE COST`
            }
            name="txtNetPrice"
            value={props.state.txtNetPrice.value}
            onValueChange={(x) => {
              props.state.inputChange({
                target: {
                  value: Number(x.value),
                  name: "txtNetPrice",
                  placeholder: props.state.txtNetPrice.placeholder,
                  className: props.state.txtNetPrice.className,
                },
              });
            }}
            className={props.state.txtNetPrice.className}
            readOnly={props.state.txtNetPrice.readOnly}
          />
          <OverlayTrigger
            placement={"top"}
            show={isDicountLimit}
            // onToggle={
            //   isDicountLimit ? setDiscountLimit(false) : setDiscountLimit(true)
            // }
            overlay={
              <Tooltip style={{ fontSize: "15px" }} id={`actor-tooltip-add`}>
                {`Your maximum Discount Assigned ${
                  props.state.user.maxDiscount === null
                    ? 0
                    : props.state.user.maxDiscount
                }`}
              </Tooltip>
            }
          >
            <InputGroup.Prepend>
              <InputGroup.Text
                style={{
                  color: "var(--REB-white)",
                  fontWeight: "bold",
                  backgroundColor: "var(--REB-primary)",
                }}
              >
                <i className="icofont-sale-discount" />
              </InputGroup.Text>
            </InputGroup.Prepend>
          </OverlayTrigger>

          <FormControl
            as={NumericFormat}
            inputMode="numeric"
            thousandSeparator={true}
            prefix={"% "}
            style={{ flex: 1 }}
            placeholder="Discount"
            name="txtDiscountMax"
            allowNegative={false}
            decimalScale={2}
            isAllowed={(values) => {
              const { formattedValue, floatValue } = values;

              //console.log(props.state.user);

              if (floatValue >= props.state.user.maxDiscount)
                setDiscountLimit(true);
              else setDiscountLimit(false);
              // if (props.state.user.types.find((x) => x.name === "Admin"))
              //   return formattedValue === "" || floatValue <= 100;
              // else
              return (
                formattedValue === "" ||
                floatValue <=
                  (props.state.user.maxDiscount === null
                    ? ""
                    : props.state.user.maxDiscount)
              );
              // if(floatValue <= 100){setDiscountLimit(1)}
              // return formattedValue === "" || floatValue <= 100;
            }}
            value={props.state.txtDiscountMax.value}
            onValueChange={(x) => {
              props.state.handleActionChange({
                target: {
                  value: Number(x.value),
                  name: "txtDiscountMax",
                },
              });
            }}
            className={props.state.txtDiscountMax.className}
            readOnly={props.state.txtDiscountMax.readOnly}
          />
        </div>
      </div>
      <p
        className="m-0 p-0"
        style={{ color: "var(--REB-green)", fontWeight: "bolder" }}
      >
        Price Modifier: {priceModifier >= 0 ? "+ " : "- "}
        {priceModifier * 100}%
      </p>
      {/* <p
        className="m-0 p-0"
        style={{ color: "var(--REB-black)", fontWeight: "bold" }}
      >
        Inventory Price: {priceModifier * 100} %
      </p> */}
    </>
  );
}
function dupeColumn() {
  return [
    {
      Header: "ID",
      id: "uniqueId",
      maxWidth: 110,
      getProps: (row) => {
        return { style: { fontSize: "10px" } };
      },
      accessor: (d) => (d.uniqueId === null ? "-" : d.uniqueId),
    },
    {
      Header: "Transaction Name",
      id: "name",
      //maxWidth: 200,
      accessor: (d) => (d.name === null ? "-" : d.name),
    },

    {
      Header: "Reserved For",
      id: "client",
      accessor: (d) => (d.client === null ? "-" : d.client.company),
    },
    {
      Header: "Reserved By",
      id: "incharge",
      accessor: (d) =>
        d.incharge === null
          ? "-"
          : `${d.incharge.lastName}, ${d.incharge.firstName}`,
    },
    {
      Header: "Qty",
      id: "qty",
      accessor: (d) => (d.dupe.itemQty === null ? "-" : d.dupe.itemQty),
    },
  ];
}

function TypeQuote(props) {
  const [isItem, setIsItem] = useState(null);
  const [isNew, setIsNew] = useState(true);
  const [isGroup, setIsGroup] = useState(false);
  const [isAdd, setIsAdd] = useState(null);
  const [isReplaceItem, setReplaceItem] = useState(false);

  const tableTerms = (
    <Table
      data={props.state.tableReservedData}
      columns={dupeColumn()}
      defaultPageSize={3}
      showPageSizeOptions={false}
      style={{ overflow: "auto", flex: 1 }}
      className="tableActor -highlight mt-3"
    />
  );

  let customStyles = {
    menu: (provided, state) => {
      //console.log(state);
      const width = "100%";

      let color = state.selectProps.menuColor;
      const padding = 0;

      return { ...provided, width, color, padding };
    },
    control: (provided) => ({
      ...provided,
      borderRadius: "0px 0px 0px 0px",
    }),

    container: (_, { selectProps: { width } }) => ({
      width: width,
      flex: 1,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,

      borderRadius: "5px 0px 0px 5px",
    }),

    option: (provided, state) => {
      let backgroundColor = "";
      let display = "block";
      let color = "black";
      if (state.data.isDefault === 1) {
        backgroundColor = "rgba(255, 0, 0, 0.2)";
      }
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      //console.log(state.data);
      if (state.data.isHidden === 1) {
        display = "none";
      }

      return {
        ...provided,
        opacity,
        transition,
        backgroundColor,
        color,
        display,
      };
    },
  };

  const filterOptions = (candidate, input) => {
    return JSON.stringify(candidate)
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  return (
    <>
      <Row className="mb-3" noGutters>
        <Col xs={12} sm={12} md={12} lg={4} className="pl-2 pr-2">
          <StatusSelect
            state={props.state}
            data={props.state.selDetailStatus.data}
            name="selDetailStatus"
            containerClassname="mb-3"
            //isLoading={props.state.suppliers.isLoading}
            selected={props.state.selDetailStatus.value}
            //tabIndex={7}
            lblHidden={false}
            refreshData={(event) => {
              props.state.refreshItemDetail("selDetailStatus", 3);
            }}
            detType={3}
            addUrl="inventory/createItem"
            editUrl="inventory/updateItem"
            deleteUrl="inventory/deleteItemDetail"
            componentName="Invoice Status"
            isDropHidden={true}
            componentType=""
            optionLabel="name"
            getOptionLabel={(x) => x.name}
            getOptionValue={(x) => x.id}
            handler={props.state.inputChange}
            disabled={
              props.state.selDetailStatus.readOnly ||
              props.state.userType.includes("Sales Person") ||
              !props.state.permissions.includes(
                "perm-ui-invoicingQuoteAccess-view-write-manage-admin"
              )
            }
          />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={4}
          style={{ border: "dotted var(--REB-blue) " }}
          className="pl-2 pr-2"
        >
          <ClientSelect
            state={props.state}
            data={props.state.selDetailClient.data}
            name="selDetailClient"
            containerClassname="mb-3"
            //isLoading={props.state.suppliers.isLoading}
            selected={props.state.selDetailClient.value}
            //tabIndex={7}
            lblHidden={false}
            refreshData={(event) => {
              props.state.getSuppliersClients();
            }}
            detType={6}
            dataStructure="invoiceActor"
            addUrl="actor/"
            editUrl="actor/updateActor"
            deleteUrl="actor/deleteActor"
            componentName="Client"
            componentType=""
            optionLabel="company"
            handler={(e) => {
              props.state.inputChange(e);
              //console.log(e.target?.value?.company);
              props.state.inputChange({
                target: {
                  name: "txtInvoiceName",
                  value: `Quotation For - ${e.target?.value?.company}`,
                },
              });
            }}
            disabled={props.state.selDetailClient.readOnly}
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={4} className="pl-2 pr-2">
          <InchargeSelect
            state={props.state}
            data={props.state.selDetailIncharge.data}
            name="selDetailIncharge"
            containerClassname="mb-3"
            //isLoading={props.state.suppliers.isLoading}
            selected={props.state.selDetailIncharge.value}
            //tabIndex={7}
            lblHidden={false}
            refreshData={(event) => {
              props.state.refreshItemDetail("selDetailIncharge", 3);
            }}
            detType={3}
            addUrl="inventory/createItem"
            editUrl="inventory/updateItem"
            deleteUrl="inventory/deleteItemDetail"
            componentName="Sales Person"
            isDropHidden={true}
            componentType=""
            optionLabel="name"
            handler={props.state.inputChange}
            disabled={
              props.state.selDetailIncharge.readOnly ||
              props.state.userType.includes("Sales Person") ||
              !props.state.permissions.includes(
                "perm-ui-invoicingQuoteAccess-view-write-manage-admin"
              )
            }
            modifyRequiredPermission="perm-ui-invoicingQuoteAccess-view-write-manage"
            deleteRequiredPermission="perm-ui-invoicingQuoteAccess-view-write-manage-admin"
            userPermissions={props.state.permissions}
          />
        </Col>
      </Row>

      {(() => {
        //console.log(props.state.selectedOperation);
        //console.log(props.state.selectedOperation, SELECTED_OPERATION.EDIT);
        if (props.state.selectedOperation !== SELECTED_OPERATION.ADD) {
          return (
            <div
              className="pt-0"
              style={{ backgroundColor: "var(--REB-body)" }}
            >
              <Row
                className="p-3 mb-3"
                style={{
                  backgroundColor: "var(--REB-primary)",
                  borderRadius: "5px 5px 0px 0px",
                }}
                noGutters
              >
                <Col>
                  <h4
                    style={{ fontWeight: "bolder", color: "var(--REB-white)" }}
                  >
                    Invoice Documents
                  </h4>
                </Col>
              </Row>
              <Row noGutters className="px-3">
                <Col>
                  <TxtFileUpload state={props.state} />
                </Col>
              </Row>
            </div>
          );
        }
      })()}
      {props.state.showDR ? (
        <div
          className="p-0 my-3 pb-3"
          style={{ borderRadius: "5px", backgroundColor: "var(--REB-body)" }}
        >
          <Row
            className="p-3 "
            style={{
              backgroundColor: "var(--REB-yellow)",
              borderRadius: "5px 5px 0px 0px",
            }}
            noGutters
          >
            <Col>
              <h4 style={{ fontWeight: "bolder", color: "var(--REB-black)" }}>
                <i
                  className="icofont-fast-delivery"
                  style={{ fontSize: "2rem" }}
                ></i>{" "}
                Delivery Information
              </h4>
            </Col>
            <Col
              md={3}
              lg={3}
              className="user-col-cont-padding"
              // hidden={
              //   !props.state.handleInvoiceModal.isView ||
              //   !props.state.showPendingApproval
              // }
            >
              <Button
                block
                name="btnAddDeliveryReceipt"
                className="animate__pulse animate__animated animate__infinite	infinite h-100"
                style={{
                  backgroundColor: "var(--REB-tertiary)",
                  fontSize: "13px",
                  fontWeight: "bolder",
                }}
                //disabled={props.state.selDetailStatus.readOnly}
                onClick={(e) => {
                  // props.state.dispatch({
                  //   type: "MODAL_SHOW",
                  //   payload: {
                  //     title: "Invoice Type Change",
                  //     desc: `Adding DR will change invoice status to ONGOING DELIVERY`,
                  //     isYesNo: true,
                  //     cb: () => {},
                  //   },
                  // });

                  if (
                    props.state.permissions.includes(
                      "perm-ui-invoicingDRAccess-view-write"
                    )
                  ) {
                    props.state.handleActionChange(e);
                  } else {
                    props.state.dispatch({
                      type: "MODAL_SHOW",
                      payload: {
                        title: "No Permission",
                        desc: `You dont have access to add DR`,
                        isYesNo: false,
                      },
                    });
                  }
                }}
              >
                ADD DELIVERY RECEIPT (DR)
              </Button>
            </Col>
          </Row>
          <Row noGutters>
            <Col>{props.tableListDR}</Col>
          </Row>
        </div>
      ) : null}
      {/* <style>
            {`
              @media (max-width: 991px) {
              }
              @media (min-width: 992px) {
              }
              .chkPOSChkbx > input {
                transform: scale(2);
              }
              .chkPOSChkbx > label {
                padding-left: 10px;
              }
            `}
          </style>
          <Form.Check
                checked={props.state.chkIsPOSVisible.value}
                disabled={props.state.chkIsPOSVisible.readOnly}
                className="chkPOSChkbx"
                name="chkIsPOSVisible"
                label="Display in POS"
                onChange={(e) => {
                  props.state.handleActionChange({
                    target: {
                      value: e.target,
                      componentName: "chkIsPOSVisible",
                      name: "chkHandleChange",
                    },
                  });
                }}
              /> */}
      <div
        className="p-0 "
        style={{ borderRadius: "5px", backgroundColor: "var(--REB-body)" }}
      >
        <Row
          className="px-3 pt-2 mb-3"
          style={{
            backgroundColor: "var(--REB-primary)",
            borderRadius: "5px 5px 0px 0px",
          }}
          noGutters
        >
          <Col md={12} lg={3}>
            <h4 style={{ fontWeight: "bolder", color: "var(--REB-white)" }}>
              <i
                className="icofont-listing-number "
                style={{ fontSize: "1.5rem" }}
              ></i>{" "}
              Invoice Contents
            </h4>
          </Col>
        </Row>
        {props.state.selContentItems.value !== null &&
        props.state.selContentItems.value !== "" ? (
          <Row noGutters>
            <Col xs={12} sm={12} md={6} lg={4} className=" pr-2 mb-2">
              <TxtQtyView state={props.state} />
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className=" pr-2 mb-2">
              <TxtDisplayQtyView state={props.state} />
            </Col>

            <Col xs={12} sm={12} md={6} lg={4} className="pr-2 mb-2">
              <TxtSRPView state={props.state} />
            </Col>
          </Row>
        ) : null}

        {props.state.tableReservedData.length > 0 ? (
          <Row noGutters>
            <Col className=" px-2 mb-2">
              {" "}
              <h3
                className="pl-2"
                style={{
                  color: "var(--REB-warning)",
                  fontWeight: "bold",
                }}
              >
                Some of this item is reserved
              </h3>
              {tableTerms}
            </Col>
          </Row>
        ) : null}

        <Row noGutters className="mb-3" style={{}}>
          <Col>
            <Row className="mb-3" noGutters>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="pl-2 pr-2 "
                hidden={!isGroup && !isNew}
              >
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <InputGroup.Prepend>
                    <p
                      className="m-0 pl-2"
                      style={{
                        backgroundColor: "var(--REB-primary)",
                        color: "var(--REB-white)",
                        fontWeight: "bold",
                        fontSize: "1rem",
                        width: "230px",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                    >
                      GROUP
                    </p>
                  </InputGroup.Prepend>
                  <ButtonGroup>
                    <FormControl
                      type="text"
                      // style={{ textTransform: "uppercase" }}
                      placeholder="Group name"
                      name="txtPlaceholder"
                      disabled={props.state.selContentItems.readOnly}
                      value={props.state.txtPlaceholder.value}
                      onChange={props.state.inputChange}
                      className={
                        props.state.txtPlaceholder.className + " css-actorInput"
                      }
                      readOnly={props.state.txtPlaceholder.readOnly}
                    />
                    <InputGroup.Prepend hidden={isAdd}>
                      <OverlayTrigger
                        placement={"top"}
                        overlay={
                          <Tooltip id={`actor-tooltip-add`}>
                            The order of the quote items are first in first out.
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="secondary"
                          name="btnAddInvoice"
                          disabled={props.state.selContentItems.readOnly}
                          className="btnAddInvoice icofont-ui-add icofont-1x"
                          style={{
                            backgroundColor: "var(--REB-tertiary)",
                            borderRadius: "0px 5px 5px 0px",
                          }}
                          onClick={(e) => {
                            //console.log(props.state.selContentItems);
                            if (props.state.txtPlaceholder.value === "") {
                              props.state.dispatch({
                                type: "MODAL_SHOW",
                                payload: {
                                  title: "Group name empty",
                                  desc: `Please make sure you entered a group name`,
                                  isYesNo: false,
                                },
                              });
                            } else {
                              props.state.handleActionChange({
                                target: {
                                  name: "btnAddGroupToQuote",
                                  value: e,
                                },
                              });
                              setIsItem(false);
                              setIsNew(false);
                              setIsAdd(true);
                              setIsGroup(true);
                            }
                          }}
                        />
                      </OverlayTrigger>
                    </InputGroup.Prepend>
                  </ButtonGroup>
                </div>
              </Col>
              {/* <Col md={4} lg={4} className="pl-2 pr-2 mb-3">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      style={{
                        color: "var(--REB-white)",
                        fontWeight: "bold",
                        backgroundColor: "var(--REB-primary)",
                      }}
                    >
                      SELECTED INVENTORY
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Select
                    styles={customStyles}
                    isSearchable
                    menuPortalTarget={document.body}
                    width="40%"
                    options={props.state.selInventory.data}
                    value={props.state.selInventory.value}
                    isDisabled={props.state.selInventory.readOnly}
                    getOptionLabel={(x) => x.name}
                    getOptionValue={(x) => x.id}               
                    onChange={(x, name) => {                  
                      props.state.handleActionChange({
                        target: {
                          value: x,
                          name: "handleInventoryChange",
                        },
                      });                 
                    }}
                  />
                </InputGroup>
              </Col> */}
              <Col
                xs={12}
                sm={12}
                md={isAdd ? 10 : 12}
                lg={isAdd ? 10 : 12}
                className="pl-2 pr-2 mt-2"
                hidden={!isItem && !isNew}
              >
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <InputGroup.Prepend>
                    <p
                      className="m-0 pl-2"
                      style={{
                        backgroundColor: "var(--REB-primary)",
                        color: "var(--REB-white)",
                        fontWeight: "bold",
                        fontSize: "1rem",
                        width: "230px",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                    >
                      ITEM
                    </p>
                  </InputGroup.Prepend>

                  <ButtonGroup>
                    <Select
                      styles={customStyles}
                      isSearchable
                      menuPortalTarget={document.body}
                      width="60%"
                      value={props.state.selContentItems.value}
                      options={props.state.selContentItems.data}
                      isDisabled={props.state.selContentItems.readOnly}
                      getOptionLabel={(x) => x.name}
                      getOptionValue={(x) => x.id}
                      filterOption={filterOptions}
                      //formatOptionLabel={"test"}
                      //innerRef={this.props.propRef}
                      onChange={(x, name) => {
                        //console.log(x, name);

                        props.state.handleActionChange(
                          {
                            target: {
                              value: x,
                              name: "selInvoiceContentItems",
                            },
                          },
                          null,
                          () => {
                            props.state.handleActionChange({
                              target: {
                                value: x,
                                name: "handleInvoiceContentDuplicate",
                              },
                            });
                          }
                        );

                        // this.props.handler(x, name, {
                        //   target: { name: this.props.name },
                        // });
                      }}
                    />

                    <InputGroup.Prepend hidden={isAdd}>
                      <OverlayTrigger
                        placement={"top"}
                        overlay={
                          <Tooltip id={`actor-tooltip-add`}>
                            ADD ITEM TO QUOTE
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="secondary"
                          name="btnAddInvoice"
                          disabled={props.state.selContentItems.readOnly}
                          className="btnAddInvoice icofont-ui-add icofont-1x"
                          style={{
                            backgroundColor: "var(--REB-tertiary)",
                            borderRadius: "0px 5px 5px 0px",
                          }}
                          onClick={(e) => {
                            //console.log(props.state.selContentItems);
                            if (props.state.selContentItems.value === "") {
                              props.state.dispatch({
                                type: "MODAL_SHOW",
                                payload: {
                                  title: "No selected item",
                                  desc: `Please select an item to add to quote`,
                                  isYesNo: false,
                                },
                              });
                            } else {
                              //Handle item duplicate

                              const DUPLICATE_ITEM_FILTER = (x) => {
                                if (
                                  x.itemId !== null &&
                                  x.itemId !== undefined
                                ) {
                                  if (
                                    Number(x.item.id) ===
                                    Number(props.state.selContentItems.value.id)
                                  )
                                    return true;
                                  else return false;
                                } else {
                                  return false;
                                }
                              };

                              if (
                                props.state.invoiceContentTableDataCopy.find(
                                  DUPLICATE_ITEM_FILTER
                                )
                              ) {
                                props.state.dispatch({
                                  type: "MODAL_SHOW",
                                  payload: {
                                    title: "Duplicate Item",
                                    desc: `Selected item is already added.  `,
                                    isYesNo: true,
                                    yesLbl: "Separately add this item",
                                    noLbl: "cancel",
                                    cb: () => {
                                      props.state.handleActionChange({
                                        target: {
                                          name: "btnAddInvoiceContentQuote",
                                          value: e,
                                        },
                                      });
                                      // props.state.handleActionChange({
                                      //   target: {
                                      //     name: "handleInvoiceContentDuplicate",
                                      //     value: e,
                                      //   },
                                      // });
                                      setIsItem(true);
                                      setIsNew(false);
                                      setIsAdd(true);
                                    },
                                  },
                                });
                              } else {
                                props.state.handleActionChange({
                                  target: {
                                    name: "btnAddInvoiceContentQuote",
                                    value: e,
                                  },
                                });
                                setIsItem(true);
                                setIsNew(false);
                                setIsAdd(true);
                              }
                            }

                            //props.state.handleItemModal.toggle();
                          }}
                        />
                      </OverlayTrigger>
                    </InputGroup.Prepend>

                    <InputGroup.Prepend hidden={!isReplaceItem}>
                      <OverlayTrigger
                        placement={"top"}
                        overlay={
                          <Tooltip id={`actor-tooltip-add`}>
                            REPLACE SELECTION WITH THIS ITEM
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="secondary"
                          name="btnAddInvoiceContentReplaceItem"
                          disabled={props.state.selContentItems.readOnly}
                          className="icofont-exchange"
                          style={{
                            backgroundColor: "var(--REB-primary)",
                          }}
                          onClick={(e) => {
                            // console.log(props.state.selContentItems);
                            if (props.state.selContentItems.value === "") {
                              props.state.dispatch({
                                type: "MODAL_SHOW",
                                payload: {
                                  title: "No selected item",
                                  desc: `Please select an replacement item`,
                                  isYesNo: false,
                                },
                              });
                            } else {
                              props.state.handleActionChange({
                                target: {
                                  name: "btnAddInvoiceContentReplaceItem",
                                  value: e,
                                },
                              });
                              setIsItem(true);
                              setIsNew(false);
                              setIsAdd(true);
                            }

                            //props.state.handleItemModal.toggle();
                          }}
                        />
                      </OverlayTrigger>
                    </InputGroup.Prepend>
                  </ButtonGroup>
                </div>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={2}
                lg={2}
                className=" pr-2  pl-2 mt-2"
                hidden={!isAdd}
              >
                <div
                  className="d-flex align-content-center flex-wrap text-center justify-content-center"
                  style={{
                    backgroundColor: "var(--REB-tertiary)",
                    // paddingLeft: "10px",
                    // paddingRight: "10px",
                    textAlign: "center",

                    height: "100%",
                    borderRadius: "5px",
                  }}
                >
                  <Form.Check
                    checked={props.state.chkImg.value}
                    disabled={props.state.chkImg.readOnly}
                    style={{
                      color: "white",
                    }}
                    // style={{ transform: "scale(1.25)" }}
                    name="chkImg"
                    label="Show Img"
                    onChange={(e) => {
                      props.state.handleActionChange({
                        target: {
                          value: e.target,
                          name: "chkImg",
                        },
                      });
                    }}
                  />
                </div>
              </Col>

              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="pl-2 pr-2 mt-2"
                hidden={(isItem && !isNew) || isGroup}
              >
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <InputGroup.Prepend>
                    <p
                      className="m-0 pl-2"
                      style={{
                        backgroundColor: "var(--REB-primary)",
                        color: "var(--REB-white)",
                        fontWeight: "bold",
                        fontSize: "1rem",
                        width: "230px",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                    >
                      SERVICE
                    </p>
                  </InputGroup.Prepend>
                  <ButtonGroup>
                    <CreatableSelect
                      isClearable
                      width="60%"
                      styles={customStyles}
                      onChange={(e) => {
                        //console.log(e);
                        props.state.handleActionChange({
                          target: { name: "selDetailServices", value: e },
                        });
                      }}
                      onInputChange={(e) => {}}
                      value={props.state.selDetailServices.value}
                      isDisabled={props.state.selDetailServices.readOnly}
                      options={props.state.selDetailServices.data}
                      // getOptionLabel={(x) => x.name}
                      // getOptionValue={(x) => x.id}
                    />
                    <InputGroup.Prepend hidden={isAdd}>
                      <OverlayTrigger
                        placement={"top"}
                        overlay={
                          <Tooltip id={`actor-tooltip-add`}>
                            ADD SERVICE TO QUOTE
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="secondary"
                          name="btnAddInvoice"
                          className="btnAddInvoice icofont-ui-add icofont-1x"
                          disabled={props.state.selDetailServices.readOnly}
                          style={{
                            backgroundColor: "var(--REB-tertiary)",
                            borderRadius: "0px 5px 5px 0px",
                          }}
                          onClick={(e) => {
                            //console.log(props.state.selectedInvoiceContentItem);
                            // console.log(props.state.selDetailServices.value);
                            if (
                              props.state.selDetailServices.value === null ||
                              props.state.selDetailServices.value === ""
                            ) {
                              props.state.dispatch({
                                type: "MODAL_SHOW",
                                payload: {
                                  title: "Service field empty",
                                  desc: `Please select or fill in service input`,
                                  isYesNo: false,
                                },
                              });
                            } else {
                              props.state.handleActionChange({
                                target: {
                                  name: "btnAddInvoiceContentQuote",
                                  value: e,
                                  service: true,
                                },
                              });
                              setIsItem(false);
                              setIsNew(false);
                              setIsAdd(true);
                            }

                            //props.state.handleItemModal.toggle();
                          }}
                        />
                      </OverlayTrigger>
                    </InputGroup.Prepend>
                  </ButtonGroup>
                </div>
              </Col>
            </Row>
            {/* <Row oGutters hidden={!isAdd || !isGroup}>
              <Col md={3} lg={3}>
                        
              </Col>
            </Row> */}
            <Row noGutters hidden={!isAdd || isGroup}>
              <Col
                xs={12}
                sm={12}
                md={4}
                lg={4}
                hidden={!isAdd}
                className="mt-2 pl-2 pr-2"
              >
                <TxtQty state={props.state} isItem={isItem} />
              </Col>
              <Col
                xs={12}
                sm={12}
                md={8}
                lg={8}
                hidden={!isAdd}
                className=" mt-2"
              >
                <CostPrice state={props.state} isItem={isItem} />
              </Col>
            </Row>
            <Row noGutters hidden={!isAdd}>
              <Col xs={12} sm={12} md={4} lg={4} className="pl-2 pr-2 mt-3">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "var(--REB-primary)",
                    borderRadius: "5px",
                  }}
                >
                  <InputGroup.Prepend>
                    <p
                      className="m-0 pl-2"
                      style={{
                        color: "var(--REB-white)",
                        fontWeight: "bold",

                        // backgroundColor: "var(--REB-primary)",
                      }}
                    >
                      INDEX
                    </p>
                  </InputGroup.Prepend>
                  <FormControl
                    type="number"
                    min={1}
                    // style={{ textTransform: "uppercase" }}
                    placeholder="Item Index"
                    name="txtItemIndex"
                    value={props.state.txtItemIndex.value}
                    onChange={props.state.inputChange}
                    className={
                      props.state.txtItemIndex.className + " css-actorInput"
                    }
                    readOnly={props.state.txtItemIndex.readOnly}
                  />
                </div>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={8}
                lg={8}
                className="pr-2 pl-2 mt-3"
                hidden={!isAdd}
              >
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "var(--REB-primary)",
                    borderRadius: "5px",
                  }}
                >
                  <InputGroup.Prepend>
                    <p
                      className="m-0 pl-2"
                      style={{
                        color: "var(--REB-white)",
                        fontWeight: "bold",

                        // backgroundColor: "var(--REB-primary)",
                      }}
                    >
                      STATUS
                    </p>
                  </InputGroup.Prepend>
                  <ButtonGroup>
                    <Select
                      styles={customStyles}
                      isSearchable
                      menuPortalTarget={document.body}
                      width="100%"
                      value={props.state.selDetailContentStatus.value}
                      options={props.state.selDetailContentStatus.data}
                      isDisabled={
                        props.state.selDetailContentStatus.readOnly ||
                        props.state.userType.includes("Sales Person")
                      }
                      getOptionLabel={(x) => x.name}
                      getOptionValue={(x) => x.id}
                      //formatOptionLabel={"test"}
                      //innerRef={this.props.propRef}
                      onChange={(x, name) => {
                        //console.log(x, name);

                        props.state.handleActionChange({
                          target: {
                            value: x,
                            name: "selDetailContentStatus",
                          },
                        });
                        // this.props.handler(x, name, {
                        //   target: { name: this.props.name },
                        // });
                      }}
                    />
                    <InputGroup.Prepend>
                      <OverlayTrigger
                        placement={"top"}
                        overlay={
                          <Tooltip id={`actor-tooltip-add`}>
                            Set item status to for order. This will
                            automatically create a new item request.
                          </Tooltip>
                        }
                      >
                        <Button
                          name="btnSetItemToForOrder"
                          disabled={props.state.selDetailContentStatus.readOnly}
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "var(--REB-tertiary)",
                            fontSize: "16px",
                            borderRadius: "0px 5px 5px 0px",
                          }}
                          onClick={(e) => {
                            props.state.handleActionChange({
                              target: {
                                name: "btnSetItemToForOrder",
                                value: e,
                                service: true,
                              },
                            });
                          }}
                        >
                          Set For Order
                        </Button>
                      </OverlayTrigger>
                    </InputGroup.Prepend>
                    {/* <InputGroup.Prepend>
                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Tooltip id={`actor-tooltip-add`}>
                          Set item as reserved. this will be deducted from
                          inventory.
                        </Tooltip>
                      }
                    >
                      <Button
                        name="btnSetItemToReserved"
                        disabled={props.state.selDetailContentStatus.readOnly}
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "var(--REB-yellow)",
                          color: "var(--REB-black)",
                          fontSize: "12px",
                        }}
                        onClick={(e) => {
                          props.state.handleActionChange({
                            target: {
                              name: "btnSetItemToReserved",
                              value: e,
                              service: true,
                            },
                          });
                        }}
                      >
                        Set Reserved
                      </Button>
                    </OverlayTrigger>
                  </InputGroup.Prepend> */}
                  </ButtonGroup>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row noGutters className="">
          <Col className=" d-flex justify-content-end">
            <ButtonGroup>
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Tooltip id={`actor-tooltip-add`}>
                    You can only edit invoice contents if the status is NEW.
                  </Tooltip>
                }
              >
                <Button
                  name="btnUnlockInvoiceContentChangeItem"
                  //disabled={!props.state.handleInvoiceModal.isView}
                  style={{
                    backgroundColor: "var(--REB-green)",
                    fontSize: "13px",
                    fontWeight: "bolder",
                  }}
                  className="mr-3"
                  hidden={
                    isAdd ||
                    props.state.invoiceContentTableSelection.length === 0 ||
                    props.state.selectedRowInvoiceSelection.childId === null
                  }
                  onClick={(e) => {
                    if (
                      props.state.selectedRowInvoiceSelection.itemId === null
                    ) {
                      setIsItem(false);
                    } else {
                      setIsItem(true);
                    }
                    setIsNew(false);
                    setIsAdd(true);

                    props.state.handleActionChange({
                      target: { name: "btnUnlockInvoiceContentChangeItem" },
                    });
                  }}
                >
                  EDIT CHANGE ITEM
                </Button>
              </OverlayTrigger>

              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Tooltip id={`actor-tooltip-add`}>
                    You can only edit invoice contents if the status is NEW.
                  </Tooltip>
                }
              >
                <Button
                  name="btnUnlockInvoiceContent"
                  //disabled={!props.state.handleInvoiceModal.isView}
                  style={{
                    backgroundColor: "var(--REB-green)",
                    fontSize: "13px",
                    fontWeight: "bolder",
                    borderRadius: "5px",
                  }}
                  className="mr-3"
                  hidden={
                    isAdd ||
                    props.state.invoiceContentTableSelection.length === 0
                  }
                  onClick={(e) => {
                    if (
                      props.state.selectedRowInvoiceSelection.itemId === null
                    ) {
                      setIsItem(false);
                    } else {
                      setIsItem(true);
                    }
                    if (
                      props.state.selectedRowInvoiceSelection.itemId === null &&
                      props.state.selectedRowInvoiceSelection.serviceName ===
                        null
                    ) {
                      setIsGroup(true);
                    }
                    setIsNew(false);
                    setIsAdd(true);

                    props.state.handleActionChange({
                      target: { name: "btnUnlockInvoiceContent" },
                    });
                  }}
                >
                  EDIT SELECTED CONTENT
                </Button>
              </OverlayTrigger>
              {props.state.selectedRowInvoice !== null ? (
                <OverlayTrigger
                  placement={"top"}
                  overlay={
                    <Tooltip id={`actor-tooltip-add`}>
                      This invoice will be verified by an admin AGAIN if change
                      of invoice content is made.
                    </Tooltip>
                  }
                >
                  <Button
                    name="btnReplaceInvoiceContent"
                    //disabled={!props.state.handleInvoiceModal.isView}
                    style={{
                      backgroundColor: "var(--REB-warning)",
                      fontSize: "13px",
                      fontWeight: "bolder",
                    }}
                    //className="icofont-ui-edit "
                    hidden={
                      isAdd ||
                      props.state.invoiceContentTableSelection.length === 0 ||
                      props.state.selectedRowInvoiceSelection.childId !== null
                    }
                    onClick={(e) => {
                      props.state.dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "Replace Confirmation",
                          desc: `Are you sure you want to REPLACE this item?`,
                          isYesNo: true,
                          cb: () => {
                            setIsNew(false);
                            setIsAdd(true);
                            setIsItem(true);
                            setReplaceItem(true);
                            props.state.handleActionChange(e);
                          },
                        },
                      });

                      // props.state.handleActionChange({
                      //   target: { name: "btnUnlockInvoiceContent" },
                      // });
                    }}
                  >
                    REPLACE SELECTED CONTENT
                  </Button>
                </OverlayTrigger>
              ) : null}

              <OverlayTrigger
                placement={"top"}
                overlay={<Tooltip id={`actor-tooltip-add`}>DELETE</Tooltip>}
              >
                <Button
                  name="btnDeleteInvoiceContent"
                  //className="icofont-ui-remove "
                  className="mr-2 ml-2"
                  style={{
                    backgroundColor: "var(--REB-warning)",
                    fontSize: "13px",
                    fontWeight: "bolder",
                  }}
                  // disabled={
                  //   props.state.handleInvoiceModal.isView ||
                  //   Number(props.state.selectedOperation.id) === 0
                  // }
                  hidden={
                    !isAdd ||
                    props.state.invoiceContentTableSelection.length === 0 ||
                    isReplaceItem
                  }
                  onClick={(e) => {
                    props.state.handleActionChange(
                      {
                        target: { name: "btnDeleteInvoiceContent" },
                      },
                      null,
                      () => {
                        props.state.handleActionChange({
                          target: { name: "btnCancelInvoiceContentQuote" },
                        });
                        setIsAdd(false);
                        setIsNew(true);
                        setReplaceItem(false);
                        setIsGroup(false);
                      }
                    );
                  }}
                >
                  DELETE CONTENT
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement={"top"}
                overlay={<Tooltip id={`actor-tooltip-add`}>SAVE</Tooltip>}
              >
                <Button
                  name="btnSubmitInvoiceContentQuote"
                  // disabled={props.state.handleInvoiceModal.isView}
                  style={{
                    backgroundColor: "var(--REB-green)",
                    fontSize: "13px",
                    fontWeight: "bolder",
                    borderRadius: "5px",
                  }}
                  //className="icofont-save "
                  className="mr-2 ml-2"
                  hidden={!isAdd || isReplaceItem}
                  onClick={(e) => {
                    if (
                      (props.state.txtQty.value === "" ||
                        props.state.txtNetPrice.value === "") &&
                      !isGroup
                    ) {
                      props.state.dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "Input Notice",
                          desc: `Please make sure QTY and COST fields are not empty`,
                          isYesNo: false,
                        },
                      });
                    } else {
                      props.state.handleActionChange(
                        e,
                        { isItem: isItem, isGroup: isGroup },
                        (x) => {
                          props.state.handleActionChange(
                            {
                              target: {
                                name: "btnCancelInvoiceContentQuote",
                              },
                            },
                            null
                          );
                        }
                      );
                      setIsAdd(false);
                      setIsNew(true);
                      setIsGroup(false);
                    }
                  }}
                >
                  SAVE CONTENT
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement={"top"}
                overlay={<Tooltip id={`actor-tooltip-add`}>SAVE</Tooltip>}
              >
                <Button
                  name="btnSaveInvoiceContentReplacementItem"
                  // disabled={props.state.handleInvoiceModal.isView}
                  style={{
                    backgroundColor: "var(--REB-green)",
                    fontSize: "13px",
                    fontWeight: "bolder",
                    borderRadius: "5px",
                  }}
                  //className="icofont-save "
                  className="mr-2 ml-2"
                  hidden={!isAdd || !isReplaceItem}
                  onClick={(e) => {
                    if (
                      props.state.selContentItems.value === "" ||
                      props.state.txtQty.value === "" ||
                      props.state.txtNetPrice.value === ""
                    ) {
                      props.state.dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "Input Notice",
                          desc: `Please make sure you selected an item and filled in the qty and cost fields`,
                          isYesNo: false,
                        },
                      });
                    } else {
                      // selectedRowInvoiceSelection
                      if (props.state.isInvoiceContentItemChange) {
                        const _selectedPrice =
                          props.state.selectedRowInvoiceSelection.price *
                          props.state.selectedRowInvoiceSelection.itemQty;
                        const _newPrice = (
                          Number(props.state.txtNetPrice.value) *
                          Number(props.state.txtQty.value)
                        ).toFixed(2);
                        if (_newPrice > _selectedPrice) {
                          props.state.dispatch({
                            type: "MODAL_SHOW",
                            payload: {
                              title: "Price Exceeded",
                              desc: `Change item price exceeded. Customer should pay additional charge`,
                              isYesNo: false,
                            },
                          });
                          props.state.handleActionChange(
                            e,
                            isItem,

                            (x) => {
                              props.state.handleActionChange(
                                {
                                  target: {
                                    name: "btnCancelInvoiceContentQuote",
                                  },
                                },
                                null
                              );
                            }
                          );
                          setIsAdd(false);
                          setIsNew(true);
                        } else {
                          props.state.handleActionChange(
                            e,
                            isItem,

                            (x) => {
                              props.state.handleActionChange(
                                {
                                  target: {
                                    name: "btnCancelInvoiceContentQuote",
                                  },
                                },
                                null
                              );
                            }
                          );
                          setIsAdd(false);
                          setIsNew(true);
                        }
                      } else {
                        props.state.handleActionChange(e, isItem, (x) => {
                          props.state.handleActionChange(
                            {
                              target: {
                                name: "btnCancelInvoiceContentQuote",
                              },
                            },
                            null
                          );
                        });
                        setIsAdd(false);
                        setIsNew(true);
                      }
                    }
                  }}
                >
                  SAVE REPLACEMENT CONTENT
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement={"top"}
                overlay={<Tooltip id={`actor-tooltip-add`}>CANCEL</Tooltip>}
              >
                <Button
                  name="btnCancelInvoiceContentQuote"
                  style={{
                    backgroundColor: "var(--REB-yellow)",
                    color: "var(--REB-black)",
                    fontSize: "13px",
                    fontWeight: "bolder",
                    borderRadius: "5px",
                  }}
                  // className="icofont-close "
                  className="mr-2 ml-2"
                  // disabled={
                  //   props.state.handleInvoiceModal.isView ||
                  //   Number(props.state.selectedOperation.id) === 0
                  // }
                  hidden={!isAdd}
                  onClick={(e) => {
                    props.state.handleActionChange(e);
                    setIsAdd(false);
                    setIsNew(true);
                    setIsGroup(false);
                    setReplaceItem(false);
                  }}
                >
                  CANCEL
                </Button>
              </OverlayTrigger>
            </ButtonGroup>
          </Col>
        </Row>
        <Row noGutters>
          <Col className="pl-2 pr-2">{props.tableInvoiceContent}</Col>
        </Row>
        <Row className="mb-1" noGutters>
          <Col className="d-flex justify-content-end pr-5 py-4 mb-1">
            <div
              style={{
                borderRadius: "5px",
                backgroundColor: "var(--REB-primary)",
              }}
              className="p-3"
            >
              <h4
                className="m-0"
                style={{ fontWeight: "bolder", color: "var(--REB-white)" }}
              >
                GRAND TOTAL: ₱{" "}
                <NumericFormat
                  value={props.state.grandTotal ? props.state.grandTotal : 0.0}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </h4>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
export { TypeQuote };
