class AccountsAPI {
  getAllAccounts = async (dispatch, filter, withParent, generateReport) => {
    const filterData = [];

    filter.inventoryId && filterData.push(`&inventoryId=${filter.inventoryId}`);
    filter.dateStart && filterData.push(`&dateStart=${filter.dateStart}`);
    filter.dateEnd && filterData.push(`&dateEnd=${filter.dateEnd}`);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `accounting/getAllAccounts?${filterData.join(
          ""
        )}&withParent=${withParent}&generateReport=${generateReport}`,
        loadingMsg: "Fetching All Accounts",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getAccountStatsById = async (dispatch, id, filter) => {
    const filterData = [];

    filter.inventoryId && filterData.push(`&inventoryId=${filter.inventoryId}`);
    filter.dateStart && filterData.push(`&dateStart=${filter.dateStart}`);
    filter.dateEnd && filterData.push(`&dateEnd=${filter.dateEnd}`);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `accounting/getAccountStatsById?${filterData.join(
          ""
        )}&accountId=${id}`,
        loadingMsg: "Fetching  Account Stats",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getAccountTypes = async (dispatch) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `accounting/getAccountTypes`,
        loadingMsg: "Fetching All Account Types",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getAccountByType = async (dispatch, inventoryId) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `accounting/getAccountByType?inventoryId=${inventoryId}`,
        loadingMsg: "Fetching All Account By Types",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getParentAccounts = async (dispatch, filter) => {
    const filterData = [];
    if (filter.inventoryId)
      filterData.push(`&inventoryId=${filter.inventoryId}`);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `accounting/getAllAccounts?isParent=1&${filterData.join("")}`,
        loadingMsg: "Fetching Parent Accounts",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getAllTransactionsByAccount = async (dispatch, filter, accountId) => {
    const filterData = [];
    if (filter.inventoryId)
      filterData.push(`&inventoryId=${filter.inventoryId}`);
    if (filter.dateStart) filterData.push(`&dateStart=${filter.dateStart}`);
    if (filter.dateEnd) filterData.push(`&dateEnd=${filter.dateEnd}`);

    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `accounting/getAllTransactionsByAccount?accountId=${accountId}${filterData.join(
          ""
        )}`,
        loadingMsg: "Fetching Parent Accounts",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  createAccount = async (dispatch, uiData, cb, cbFail) => {
    //console.log(sdata);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `accounting/createAccount`,
        loadingMsg: "Creating Account",
        reqType: "post",
        data: uiData,
        resolve: resolve,
        reject: reject,
      });
    }).then(
      async (onfulfilled) => {
        //console.log(onfulfilled);
        if (cb) await cb();
        return onfulfilled.data;
      },
      async (onrejected) => {
        if (cb) await cb();
        return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };
  updateAccount = async (dispatch, uiData, cb, cbFail) => {
    //console.log(sdata);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `accounting/updateAccount`,
        loadingMsg: "Updating Account",
        reqType: "put",
        data: uiData,
        resolve: resolve,
        reject: reject,
      });
    }).then(
      async (onfulfilled) => {
        //console.log(onfulfilled);
        if (cb) await cb(onfulfilled.data);
        return onfulfilled.data;
      },
      async (onrejected) => {
        if (cb) await cb();
        return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };
  deleteAccount = async (dispatch, id, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "delete",
        data: { id: id },
        payload: "accounting/deleteAccount",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );
    if (cb) await cb();
    return data;
  };
}
export default new AccountsAPI();
