export const SELECTED_OPERATION = {
  ADD: { id: 0, name: "Add" },
  EDIT: { id: 1, name: "Edit" },
  DELETE: { id: 2, name: "Delete" },
  VIEW: { id: 3, name: "View" },
  ADD_CONTENT: { id: 4, name: "Add-Content" },
  EDIT_CONTENT: { id: 5, name: "Edit-Content" },
  VIEW_CONTENT: { id: 6, name: "View-Content" },
};

export const SELECTED_TABLE_TABS = {
  ALL: { id: 0, name: "ALL" },
  QUOTE: { id: 1, name: "QUOTE" },
  DR: { id: 2, name: "DR" },
  CI: { id: 3, name: "CI" },
  TRANSFER: { id: 4, name: "TRANSFER" },
  ARCHIVE: { id: 5, name: "ARCHIVE" },
  PR: { id: 6, name: "PR" },
  VOUCHER: { id: 7, name: "VOUCHER" },
  LOGISTICS: { id: 8, name: "LOGISTICS" },
};
