import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
//import ButtonGroup from "react-bootstrap/ButtonGroup";
import { NumericFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { SELECTED_OPERATION } from "../../constants";

import ImageGallery from "react-image-gallery";
import Select from "react-select";
import InventoryAPI from "./InventoryAPI";
const selectStyle = {
  menu: (provided, state) => {
    //console.log(state);
    const width = "100%";

    let color = state.selectProps.menuColor;
    const padding = 0;

    return { ...provided, width, color, padding, zIndex: 6000 };
  },
  control: (provided) => ({
    ...provided,
    borderRadius: "5px",
  }),

  container: (_, { selectProps: { width } }) => ({
    width: width,
    zIndex: 6000,
    // flex: 1,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 6000,

    borderRadius: "5px",
  }),

  option: (provided, state) => {
    let backgroundColor = "";
    let display = "block";
    let color = "black";
    if (state.data.isDefault === 1) {
      backgroundColor = "rgba(255, 0, 0, 0.2)";
    }
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    //console.log(state.data);
    if (state.data.isHidden === 1) {
      display = "none";
    }

    return {
      ...provided,
      opacity,
      transition,
      backgroundColor,
      color,
      display,
    };
  },
};

const filterOptions = (candidate, input) => {
  return JSON.stringify(candidate).toLowerCase().includes(input.toLowerCase());
};

export const CopyItemComponent = (props) => {
  const { state } = props;
  const dispatch = useDispatch();
  const [IsCopyItemOpen, setIsCopyItemOpen] = useState(false);

  const [SelInvCopyFrom, setSelInvCopyFrom] = useState("");

  const [SelItemCopyData, setSelItemCopyData] = useState([]);
  const [SelItemCopy, setSelItemCopy] = useState("");

  // refresh item to copy select data when SelInvCopyFrom changes
  useEffect(() => {
    //console.log(SelInvCopyFrom);
    getAllItemsByInventory();
  }, [SelInvCopyFrom]);

  const resetComponent = () => {
    setIsCopyItemOpen(false);
    setSelInvCopyFrom("");
    setSelItemCopyData([]);
    setSelItemCopy("");
  };

  const getAllItemsByInventory = async () => {
    let inventoryItems = [];
    if (SelInvCopyFrom.id) {
      inventoryItems = await InventoryAPI.getItemsByInventory(
        dispatch,
        Number(SelInvCopyFrom.id),
        null,
        null,
        null,
        null,
        null
      );
    }
    if (inventoryItems) {
      setSelItemCopyData(inventoryItems);
    }
  };

  const SelItemCopyOnChange = (item) => {
    console.log(item);
    //check selected inventory for duplicate items, show message if item exists.
    const findDupe = state.itemData.find((x) => x.name === item.name);
    if (findDupe) {
      dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Duplicate Item",
          desc: `This item already exists in ${SelInvCopyFrom?.name} inventory`,
          isYesNo: false,
        },
      });
    } else {
      item.qty = 0;
      item.display = 0;

      setSelItemCopy(item);
    }
  };

  return (
    <>
      {!IsCopyItemOpen && (
        <OverlayTrigger
          placement={"top"}
          overlay={
            <Tooltip id={`actor-tooltip-add`}>Open Item Copy Options</Tooltip>
          }
        >
          <Button
            style={{
              backgroundColor: "var(--REB-green)",
              // fontSize: "10px",
            }}
            className=""
            onClick={(e) => {
              setIsCopyItemOpen((old) => {
                return !old;
              });
            }}
          >
            Copy Item Options
          </Button>
        </OverlayTrigger>
      )}

      {IsCopyItemOpen && (
        <>
          <p style={{ fontSize: "0.7rem" }}>
            This feature will copy the item from another inventory except for
            the QTY.
          </p>
          <Row noGutters>
            <Col className="px-2">
              <Form.Label
                className="font-weight-bold mt-2"
                style={{ color: "var(--REB-black)" }}
              >
                Inventory To Copy Item From
              </Form.Label>
              <Select
                styles={selectStyle}
                isSearchable
                isClearable={true}
                // menuPortalTarget={document.body}
                // width="50%"
                options={props.state.inventoryData}
                value={SelInvCopyFrom}
                // isDisabled={props.state.selInventory.readOnly}
                getOptionLabel={(x) => x.name}
                getOptionValue={(x) => x.id}
                onChange={(x, name) => {
                  setSelInvCopyFrom(x);
                }}
              />
            </Col>
            <Col className="px-2">
              <Form.Label
                className="font-weight-bold mt-2"
                style={{ color: "var(--REB-black)" }}
              >
                Item to Copy
              </Form.Label>
              <Select
                styles={selectStyle}
                isSearchable
                isClearable={true}
                filterOption={filterOptions}
                // menuPortalTarget={document.body}
                // width="50%"
                options={SelItemCopyData}
                value={SelItemCopy}
                // isDisabled={props.state.selInventory.readOnly}
                getOptionLabel={(x) => x.name}
                getOptionValue={(x) => x.id}
                onChange={(x, name) => {
                  SelItemCopyOnChange(x);
                }}
              />
            </Col>
            {SelItemCopy && (
              <Col xs={12} className="mt-3" style={{}}>
                <style>
                  {`
                    .itemPreviewContainer {
                      display: flex;
                    }
                    .itemPreviewLabel {
                      padding: 0;
                      margin: 0;
                      padding-right: 10px;
                    }
                    .itemPreviewValue {
                      padding: 0;
                      margin: 0;
                      font-weight: bolder;
                    }
                  `}
                </style>
                <Row noGutters>
                  <Col>
                    <div className="itemPreviewContainer">
                      <p className="itemPreviewLabel">Name: </p>
                      <p className="itemPreviewValue">{SelItemCopy?.name}</p>
                    </div>
                    <div className="itemPreviewContainer">
                      <p className="itemPreviewLabel">Description: </p>
                      <p className="itemPreviewValue">
                        {SelItemCopy?.description}
                      </p>
                    </div>
                    <div className="itemPreviewContainer">
                      <p className="itemPreviewLabel">Brand: </p>
                      <p className="itemPreviewValue">{SelItemCopy?.brand}</p>
                    </div>
                    <div className="itemPreviewContainer">
                      <p className="itemPreviewLabel">Type: </p>
                      <p className="itemPreviewValue">{SelItemCopy?.type}</p>
                    </div>
                    <div className="itemPreviewContainer">
                      <p className="itemPreviewLabel">Status: </p>
                      <p className="itemPreviewValue">{SelItemCopy?.status}</p>
                    </div>
                  </Col>
                  <Col>
                    <div className="itemPreviewContainer">
                      <p className="itemPreviewLabel">SRP: </p>
                      <p className="itemPreviewValue">{SelItemCopy?.price}</p>
                    </div>
                    <div className="itemPreviewContainer">
                      <p className="itemPreviewLabel">Unit: </p>
                      <p className="itemPreviewValue">{SelItemCopy?.unit}</p>
                    </div>
                    <div className="itemPreviewContainer">
                      <p className="itemPreviewLabel">Code: </p>
                      <p className="itemPreviewValue">{SelItemCopy?.code}</p>
                    </div>
                    <div className="itemPreviewContainer">
                      <p className="itemPreviewLabel">SKU: </p>
                      <p className="itemPreviewValue">{SelItemCopy?.sku}</p>
                    </div>
                    <div className="itemPreviewContainer">
                      <p className="itemPreviewLabel">Barcode: </p>
                      <p className="itemPreviewValue">{SelItemCopy?.barcode}</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            )}

            <Col xs={12} className="mt-3" style={{ textAlign: "right" }}>
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Tooltip id={`actor-tooltip-add`}>
                    Open Item Copy Options
                  </Tooltip>
                }
              >
                <Button
                  style={{
                    backgroundColor: "var(--REB-yellow)",
                    color: "var(--REB-black)",
                    // fontSize: "10px",
                  }}
                  className="mx-2"
                  onClick={(e) => {
                    setIsCopyItemOpen((old) => {
                      return !old;
                    });
                  }}
                >
                  Close Item Copy Options
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Tooltip id={`actor-tooltip-add`}>
                    Copies this selected item to the new item.
                  </Tooltip>
                }
              >
                <Button
                  style={{
                    backgroundColor: "var(--REB-green)",

                    // fontSize: "10px",
                  }}
                  disabled={!SelItemCopy}
                  className="mx-2"
                  onClick={(e) => {
                    console.log("ITEM TO COPY ===>", SelItemCopy);

                    state.handleActionChange(
                      { target: { name: "copyItemPopulate" } },
                      SelItemCopy
                    );
                    resetComponent();
                    setIsCopyItemOpen(false);
                  }}
                >
                  Copy this item
                </Button>
              </OverlayTrigger>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
