import React from "react";

import pdfTemplate from "../../../components/PdfHeadFoot";
//import TESTIMG from "../../../assets/executiveSig.png";
import misc from "../../../functions/misc";
import { Text, View, Image, Font } from "@react-pdf/renderer";

import moment from "moment";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf",
      fontWeight: 800,
    },
  ],
});
let themeColor = "";
if (process.env.REACT_APP_THEME === "SURFACEPLUS") themeColor = "#5eaaff";
if (process.env.REACT_APP_THEME === "DEMO") themeColor = "#ed5309";
class AcknowledgementReceipt {
  _termsSignature(incharge) {
    return (
      <>
        <View>
          <View
            style={[
              {
                marginTop: 5,

                paddingLeft: 80,
                paddingRight: 80,
              },
            ]}
          >
            <View
              style={[
                {
                  marginTop: 10,
                  flex: 1,
                  flexDirection: "row",
                },
              ]}
            >
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      color: themeColor,
                      fontFamily: "Open Sans",
                      fontWeight: 800,
                    },
                  ]}
                >
                  Generated by:
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View>
          <View
            style={[
              {
                marginTop: 5,
                marginBottom: 25,
                paddingLeft: 80,
                paddingRight: 80,
              },
            ]}
          >
            <View
              style={[
                {
                  marginTop: 10,
                  flex: 1,
                  flexDirection: "row",
                },
              ]}
            >
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      textAlign: "center",
                    },
                  ]}
                >
                  {incharge !== null && incharge !== undefined
                    ? incharge.name
                    : "-"}
                </Text>
                {(() => {
                  //console.log(incharge);
                  if (incharge !== null && incharge !== undefined) {
                    if (incharge.uploads) {
                      if (Number(incharge.uploads.length) > 0) {
                        return (
                          <Image
                            style={[
                              {
                                position: "absolute",
                                width: 150,
                                marginTop: -25,
                              },
                            ]}
                            src={{
                              uri: `${process.env.REACT_APP_API_URL}global/getFile?uniqueId=${incharge.uniqueId}&filename=${incharge.uploads[0].filename}`,
                              method: "GET",

                              headers: {
                                Authorization:
                                  "Bearer " + localStorage.getItem("LUT"),
                              },
                              body: "",
                            }}
                          />
                        );
                      }
                    }
                  }
                })()}
              </View>
            </View>
          </View>
        </View>
      </>
    );
  }

  AcknowledgementReceipt(
    incharge,
    amount,
    paymentDate,
    clientName,
    invoiceNumber,
    invoiceDescription,
    invoiceCreationDate,
    trxNumber,
    bankName,
    acctNumber,
    checkNumber,
    incBank,
    incCheck,
    isDownPayment,
    isFullpayment
  ) {
    //console.log(props.pdfGroupTable[0]);

    let _content = [];

    _content.push(
      <>
        <View
          style={[
            {
              marginTop: 5,
              width: "100%",
              // textAlign: "center",
              // color: themeColor,
            },
          ]}
        >
          <Text
            style={[
              {
                fontSize: 10,
                fontFamily: "Open Sans",
                fontWeight: "normal",
                // fontWeight: 800,
                marginTop: 10,
                marginBottom: 5,
                paddingLeft: 50,
                paddingRight: 50,
              },
            ]}
          >
            <Text
              style={[
                {
                  fontSize: 10,
                  fontFamily: "Open Sans",
                  fontWeight: 800,
                  color: "white",
                  marginLeft: 50,
                  paddingLeft: 50,
                },
              ]}
            >
              __________
            </Text>{" "}
            This is to acknowledge the {`${isDownPayment ? "downpayment" : ""}`}
            {`${
              !isDownPayment
                ? isFullpayment
                  ? "full payment"
                  : "partial payment"
                : ""
            }`}{" "}
            receipt of{" "}
            <Text
              style={[
                {
                  fontSize: 10,
                  fontFamily: "Open Sans",
                  fontWeight: 800,
                },
              ]}
            >
              {misc.currencyToWords(amount)} (P{" "}
              {misc.parseCurrencyNoCurrency(amount)})
            </Text>{" "}
            from ______________________________________, as payment for the
            invoice{" "}
            <Text
              style={[
                {
                  fontSize: 10,
                  fontFamily: "Open Sans",
                  fontWeight: 800,
                },
              ]}
            >
              {invoiceNumber}
            </Text>{" "}
            with description{" "}
            <Text
              style={[
                {
                  fontSize: 10,
                  fontFamily: "Open Sans",
                  fontWeight: 800,
                },
              ]}
            >
              {invoiceDescription}
            </Text>{" "}
            dated{" "}
            <Text
              style={[
                {
                  fontSize: 10,
                  fontFamily: "Open Sans",
                  fontWeight: 800,
                },
              ]}
            >
              {" "}
              {moment(invoiceCreationDate).format("LL")}
            </Text>
          </Text>
          {incBank && (
            <>
              {" "}
              <Text
                style={[
                  {
                    fontSize: 10,
                    fontFamily: "Open Sans",

                    marginTop: 2,
                    marginBottom: 2,
                    paddingLeft: 50,
                    paddingRight: 50,
                  },
                ]}
              >
                Bank Name:{" "}
                <Text
                  style={[
                    {
                      fontSize: 10,
                      fontFamily: "Open Sans",
                      fontWeight: 800,
                    },
                  ]}
                >
                  {bankName}
                </Text>
              </Text>
              <Text
                style={[
                  {
                    fontSize: 10,
                    fontFamily: "Open Sans",

                    marginTop: 2,
                    marginBottom: 2,
                    paddingLeft: 50,
                    paddingRight: 50,
                  },
                ]}
              >
                Bank Account:{" "}
                <Text
                  style={[
                    {
                      fontSize: 10,
                      fontFamily: "Open Sans",
                      fontWeight: 800,
                    },
                  ]}
                >
                  {acctNumber}
                </Text>
              </Text>
            </>
          )}
          {incCheck && (
            <Text
              style={[
                {
                  fontSize: 10,
                  fontFamily: "Open Sans",
                  marginTop: 2,
                  marginBottom: 2,
                  paddingLeft: 50,
                  paddingRight: 50,
                },
              ]}
            >
              Check Number:{" "}
              <Text
                style={[
                  {
                    fontSize: 10,
                    fontFamily: "Open Sans",
                    fontWeight: 800,
                  },
                ]}
              >
                {checkNumber}
              </Text>
            </Text>
          )}
        </View>

        <View
          style={[
            {
              //height: 325,
              paddingLeft: 20,
              paddingRight: 20,
            },
          ]}
        >
          {this._termsSignature(incharge)}
        </View>
      </>
    );

    return pdfTemplate.TemplateHeaderFooter(
      _content,
      "Acknowledgement Receipt",
      trxNumber
    );
  }
}
export default new AcknowledgementReceipt();
// let QuotePDF = (props) => (

// );
//export { QuotePDF };
