import React from "react";

import pdfTemplate from "../../../components/PdfHeadFoot";
//import TESTIMG from "../../../assets/executiveSig.png";
import { Text, View, StyleSheet, Font, Canvas } from "@react-pdf/renderer";
import misc from "../../../functions/misc";
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf",
      fontWeight: 800,
    },
  ],
});
let themeColor = "";
if (process.env.REACT_APP_THEME === "SURFACEPLUS") themeColor = "#5eaaff";
if (process.env.REACT_APP_THEME === "DEMO") themeColor = "#ed5309";
const tableStyles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  tableHeader: {
    textAlign: "left",
    fontSize: 9,
    marginLeft: 2,
    marginRight: 2,
    marginBottom: 10,
    color: "#5eaaff",
    fontFamily: "Open Sans",
    fontWeight: 800,
    border: 0,
  },
  tableCell: {
    border: 0,
    textAlign: "left",
    // height: 12,
    // marginLeft: 2,
    marginRight: 2,
  },
  tableCellNames: {
    fontSize: 8,
    border: 0,
    marginTop: 1,
    marginBottom: 1,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    border: 0,
  },
});

class StatementOfAccount {
  _itemTable(data) {
    return (
      <Table data={data}>
        <TableHeader
          includeBottomBorder={true}
          includeLeftBorder={false}
          includeRightBorder={false}
          includeTopBorder={false}
          style={[
            {
              border: 0,
            },
          ]}
        >
          <TableCell weighting={0.85} style={[tableStyles.tableHeader]}>
            PARTICULARS
          </TableCell>

          <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
            AMOUNT
          </TableCell>
        </TableHeader>
        <TableBody
          includeBottomBorder={false}
          includeLeftBorder={false}
          includeRightBorder={false}
          includeTopBorder={false}
        >
          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.85}
            getContent={(a) => {
              let name = "-";
              let desc = "";
              let trxNo = "-";
              let ordId = "";
              if (a?.name) name = a.name;
              //if (a?.name) desc = a?.name;
              if (a?.uniqueId) trxNo = a?.uniqueId;
              if (a?.uniqueId) ordId = a?.uniqueId;
              const combinedTransactions = a?.pendingTransactions.concat(
                a?.paidTransactions
              );
              return (
                <>
                  <View style={{ flexDirection: "column" }}>
                    <Text
                      style={[{}]}
                    >{`${name} | ${ordId} | Total: ${misc.parseCurrencyNoCurrency(
                      a?.invoiceTotal
                    )}`}</Text>
                    {/* {desc && ordId && (
                      <Text style={[{}]}>{`${desc} | ${ordId}`}</Text>
                    )} */}

                    {/* <Text style={[{}]}>{trxNo}</Text> */}
                    {combinedTransactions && (
                      <>
                        {combinedTransactions.map((x) => {
                          let transStatName = "";
                          if (x.transStatus === "processing") {
                            transStatName = "Waiting for payment";
                          }
                          if (x.transStatus === "finished") {
                            transStatName = "Paid";
                          }
                          let paymentDate = "-";
                          if (x?.paymentDate) {
                            paymentDate = misc.parseAPIDateTimeToShortDate(
                              x?.paymentDate
                            );
                          } else {
                            paymentDate = misc.parseAPIDateTimeToShortDate(
                              x?.dateEdited
                            );
                          }
                          const parsedAmount = misc.parseCurrencyNoCurrency(
                            x?.amountValue
                          );
                          let amountDisplay = "0.00";
                          let transStatDisplay = "-";
                          //console.log(parsedAmount)
                          if (parsedAmount === "0.00") {
                            amountDisplay = "COD/DR RELEASE";
                          } else {
                            amountDisplay = parsedAmount;
                          }
                          if (parsedAmount === "0.00") {
                            transStatDisplay = "DR RELEASED";
                          } else {
                            transStatDisplay = transStatName;
                          }
                          return (
                            <Text
                              style={[{ paddingLeft: 15 }]}
                              key={x?.uniqueId}
                            >
                              {amountDisplay} | {paymentDate} | {x?.uniqueId} |{" "}
                              {transStatDisplay}
                            </Text>
                          );
                        })}
                      </>
                    )}
                  </View>
                </>
              );
            }}
          />

          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.15}
            getContent={(d) => {
              let name = "-";
              return (
                <>
                  <View style={{ flexDirection: "row" }}>
                    <Text style={[{ marginLeft: 5 }]}>
                      {misc.parseCurrencyNoCurrency(
                        d.invoiceTotal - d.invoiceTotalPaid
                      )}
                    </Text>
                  </View>
                </>
              );
            }}
          />
        </TableBody>
      </Table>
    );
  }
  _termsSignature(incharge, approvedBy) {
    return (
      <>
        <View>
          <View
            style={[
              {
                marginTop: 3,
                flexDirection: "row",
                paddingLeft: 80,
                paddingRight: 80,
              },
            ]}
          >
            <View
              style={[
                {
                  marginTop: 1,
                  flex: 1,
                  flexDirection: "row",
                },
              ]}
            >
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      color: themeColor,
                      fontFamily: "Open Sans",
                      fontWeight: 800,
                    },
                  ]}
                >
                  Generated by:
                </Text>
              </View>
            </View>
            <View
              style={[
                {
                  marginTop: 1,
                  flex: 1,
                  flexDirection: "row",
                },
              ]}
            >
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      color: themeColor,
                      fontFamily: "Open Sans",
                      fontWeight: 800,
                    },
                  ]}
                >
                  Approved By:
                </Text>
              </View>
            </View>
            <View
              style={[
                {
                  marginTop: 1,
                  flex: 1,
                  flexDirection: "row",
                },
              ]}
            >
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      color: themeColor,
                      fontFamily: "Open Sans",
                      fontWeight: 800,
                    },
                  ]}
                >
                  Checked By:
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View>
          <View
            style={[
              {
                marginTop: 1,
                marginBottom: 25,
                paddingLeft: 80,
                paddingRight: 80,
              },
            ]}
          >
            <View
              style={[
                {
                  marginTop: 2,
                  flex: 1,
                  flexDirection: "row",
                },
              ]}
            >
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      textAlign: "center",
                    },
                  ]}
                >
                  {incharge !== null && incharge !== undefined
                    ? `${incharge.lastName ? incharge.lastName + ", " : ""} ${
                        incharge.firstName ? incharge.firstName : ""
                      }`
                    : "-"}
                </Text>
              </View>
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      textAlign: "center",
                    },
                  ]}
                >
                  {approvedBy !== null && approvedBy !== undefined
                    ? `${
                        approvedBy.lastName ? approvedBy.lastName + ", " : ""
                      } ${approvedBy.firstName ? approvedBy.firstName : ""}`
                    : "-"}
                </Text>
              </View>
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      textAlign: "center",
                    },
                  ]}
                >
                  ___________________________
                </Text>
              </View>
            </View>
          </View>
        </View>
      </>
    );
  }

  StatementOfAccount(
    tableData,
    clientPayee,
    statementType,
    generatedBy,
    grandTotal
  ) {
    //console.log(incharge);

    let _content = [];

    _content.push(
      <>
        {/* {(transStatus === "new" ||
          transStatus === "pending-admin-approval" ||
          transStatus === "admin-denied") && (
          <View
            style={[
              {
                marginTop: 100,
                fontSize: 15,
                width: "100%",
                position: "absolute",
                textAlign: "center",
                backgroundColor: "red",
              },
            ]}
          >
            <Text style={[{ textAlign: "center", color: "white" }]}>
              --== THIS VOUCHER IS NOT APPROVED BY COMPANY AND IS NOT VALID ==--
            </Text>
          </View>
        )} */}
        <View
          style={[
            {
              // flex: 1,
              flexDirection: "row",
              paddingLeft: 40,
              paddingRight: 40,
              marginTop: 5,
            },
          ]}
        >
          <View style={[{ flex: 1, paddingRight: 8 }]}>
            <Text
              style={[
                {
                  textAlign: "left",
                  color: themeColor,
                  fontSize: 10,
                  fontWeight: "bold",
                },
              ]}
            >
              CLIENT
            </Text>
            <Text
              style={[
                {
                  textAlign: "left",
                  fontSize: 8,
                  marginTop: 3,
                  fontWeight: "bold",
                },
              ]}
            >
              {clientPayee}
            </Text>
          </View>
          <View style={[{ flex: 1 }]}>
            <Text
              style={[
                {
                  textAlign: "left",
                  color: themeColor,
                  fontSize: 10,
                  fontWeight: "bold",
                },
              ]}
            >
              {statementType.value === "payeesAccounts" && (
                <>TOTAL AMOUNT DUE PAYABLE (EXPENSE)</>
              )}
              {statementType.value === "clientAccounts" && (
                <>TOTAL AMOUNT DUE</>
              )}
            </Text>
            <Text
              style={[
                {
                  textAlign: "left",

                  fontSize: 10,
                  fontWeight: "bold",
                },
              ]}
            >
              {misc.parseCurrencyNoCurrency(grandTotal)}
            </Text>
            <Text
              style={[
                {
                  textAlign: "left",
                  fontSize: 8,
                },
              ]}
            >
              {misc.currencyToWords(grandTotal)}
            </Text>
          </View>
        </View>

        <Canvas
          style={[
            {
              marginTop: 5,
              marginBottom: 5,
              width: 600,
              height: 5,
            },
          ]}
          paint={(painter) => {
            painter
              .strokeColor(themeColor)
              .lineWidth(5)
              .moveTo(40, 0)
              .lineTo(555, 0)
              .stroke();
          }}
        />

        <View
          style={[
            {
              //height: 325,
              paddingTop: 10,
              paddingLeft: 20,
              paddingRight: 20,
            },
          ]}
        >
          {/* <Text
            style={[
              {
                fontSize: 9,
                paddingLeft: 20,
              },
            ]}
          >
            Dear Ma'am / Sir,
          </Text>
          <Text
            style={[
              {
                marginBottom: 8,
                paddingLeft: 20,
                fontSize: 9,
              },
            ]}
          >
            We are pleased to submit our quotation for the following items:
          </Text> */}
          {((x) => {
            let _e = [];
            if (tableData.length > 0) {
              _e = tableData[0];
            }
            //console.log(tableData);
            //console.log(props.pdfGroupTable);
            if (_e.length <= 20) {
              return (
                <>
                  <View
                    style={[
                      {
                        paddingLeft: 20,
                        paddingRight: 20,
                      },
                    ]}
                  >
                    {this._itemTable(_e)}
                  </View>
                  <Canvas
                    fixed
                    style={[
                      {
                        width: 600,
                        height: 5,
                        // top: 300,
                        // position: "absolute",
                      },
                    ]}
                    paint={(painter) => {
                      painter
                        .strokeColor("black")
                        .lineWidth(1)
                        .moveTo(20, 0)
                        .lineTo(535, 0)
                        .stroke();
                    }}
                  />
                  <Text
                    style={[
                      {
                        paddingLeft: 20,
                        paddingRight: 40,
                        fontSize: 12,
                        textAlign: "right",
                        marginTop: 5,
                      },
                    ]}
                  >
                    TOTAL: {misc.parseCurrencyNoCurrency(grandTotal)}
                  </Text>
                  {this._termsSignature(generatedBy)}
                </>
              );
            } else {
              return <>{this._itemTable(_e)}</>;
            }
          })()}
        </View>
        {/* <View style={[{ marginTop: 5 }]}>
          <Text style={[{ textAlign: "center", color: "red" }]}>
            --= THIS DOCUMENT IS FOR REFERENCE ONLY =--
          </Text>
        </View> */}
      </>
    );
    for (let i = 1; i < tableData.length; i++) {
      const _e = tableData[i];

      if (_e.length >= 20) {
        _content.push(
          <View
            style={[
              {
                paddingLeft: 20,
                paddingRight: 20,
              },
            ]}
          >
            {this._itemTable(_e)}
          </View>
        );
      } else {
        _content.push(
          <>
            <View
              style={[
                {
                  paddingLeft: 20,
                  paddingRight: 20,
                },
              ]}
            >
              {this._itemTable(_e)}
            </View>
            <Canvas
              fixed
              style={[
                {
                  width: 600,
                  height: 5,
                  // top: 300,
                  // position: "absolute",
                },
              ]}
              paint={(painter) => {
                painter
                  .strokeColor("black")
                  .lineWidth(1)
                  .moveTo(20, 0)
                  .lineTo(535, 0)
                  .stroke();
              }}
            />
            <Text
              style={[
                {
                  paddingLeft: 20,
                  paddingRight: 40,
                  fontSize: 12,
                  textAlign: "right",
                  marginTop: 5,
                },
              ]}
            >
              TOTAL: {misc.parseCurrencyNoCurrency(grandTotal)}
            </Text>
            {this._termsSignature(generatedBy)}
          </>
        );
      }
    }
    return pdfTemplate.TemplateHeaderFooter(
      _content,
      `Statement of Account`,
      null,
      null,
      null,
      15
    );
  }
}
export default new StatementOfAccount();
// let QuotePDF = (props) => (

// );
//export { QuotePDF };
