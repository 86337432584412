class LogisticsAPI {
  getAllLogistics = async (dispatch, filter) => {
    const filterData = [];
    if (filter.inventoryId)
      filterData.push(`&inventoryId=${filter.inventoryId}`);
    if (filter.generalLogisticType)
      filterData.push(`&generalLogisticType=${filter.generalLogisticType}`);

    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `logistics/getAllLogistics?${filterData.join("")}`,
        loadingMsg: "Fetching All Logistics",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getLogistics = async (dispatch, id) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `logistics/getLogistics?id=${id}`,
        loadingMsg: "Fetching  Logistic",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getLogisticsByUid = async (dispatch, uid) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `logistics/getLogisticsByUid?uid=${uid}`,
        loadingMsg: "Fetching  Logistics",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };

  createLogistics = async (dispatch, uiData, cb, cbFail) => {
    //console.log(sdata);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `logistics/createLogistics`,
        loadingMsg: "Creating Logistic",
        reqType: "post",
        data: uiData,
        resolve: resolve,
        reject: reject,
      });
    }).then(
      async (onfulfilled) => {
        //console.log(onfulfilled);
        if (cb) await cb();
        return onfulfilled.data;
      },
      async (onrejected) => {
        if (cb) await cb();
        return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };
  receiveLogistics = async (dispatch, uiData, cb, cbFail) => {
    //console.log(sdata);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `logistics/receiveLogistics`,
        loadingMsg: "Receiving Logistic",
        reqType: "post",
        data: uiData,
        resolve: resolve,
        reject: reject,
      });
    }).then(
      async (onfulfilled) => {
        //console.log(onfulfilled);
        if (cb) await cb();
        return onfulfilled.data;
      },
      async (onrejected) => {
        if (cb) await cb();
        return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };
  createMultiLogistics = async (dispatch, uiData, cb, cbFail) => {
    //console.log(sdata);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `logistics/createMultiLogistics`,
        loadingMsg: "Creating Multi Logistic",
        reqType: "post",
        data: uiData,
        resolve: resolve,
        reject: reject,
      });
    }).then(
      async (onfulfilled) => {
        //console.log(onfulfilled);
        if (cb) await cb();
        return onfulfilled.data;
      },
      async (onrejected) => {
        if (cb) await cb();
        return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };
  updateLogistics = async (dispatch, uiData, cb, cbFail) => {
    //console.log(sdata);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `logistics/updateLogistics`,
        loadingMsg: "Updating Logistic",
        reqType: "put",
        data: uiData,
        resolve: resolve,
        reject: reject,
      });
    }).then(
      async (onfulfilled) => {
        //console.log(onfulfilled);
        if (cb) await cb(onfulfilled.data);
        return onfulfilled.data;
      },
      async (onrejected) => {
        if (cb) await cb();
        return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };
  updateMultiLogistics = async (dispatch, uiData, cb, cbFail) => {
    //console.log(sdata);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `logistics/updateMultiLogistics`,
        loadingMsg: "Updating Multi Logistic",
        reqType: "put",
        data: uiData,
        resolve: resolve,
        reject: reject,
      });
    }).then(
      async (onfulfilled) => {
        //console.log(onfulfilled);
        if (cb) await cb(onfulfilled.data);
        return onfulfilled.data;
      },
      async (onrejected) => {
        if (cb) await cb();
        return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };
  deleteLogisticsByUid = async (dispatch, uid, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "delete",
        data: { uid: uid },
        payload: "logistics/deleteLogisticsByUid",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      async (onfulfilled) => {
        //console.log(onfulfilled);
        if (cb) await cb();
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };
  deleteLogistics = async (dispatch, id, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "delete",
        data: { id: id },
        payload: "logistics/deleteLogistics",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      async (onfulfilled) => {
        //console.log(onfulfilled);
        if (cb) await cb();
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };
}
export default new LogisticsAPI();
