import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { InvoiceDetailsModal } from "./InvoiceDetailsModal";
import { InvoiceModal } from "./InvoiceModal";
import { DRModal } from "./DRModal";
import moment from "moment";
import { InvoiceContentModal } from "./InvoiceContentModal";
//import { InvoiceContentModal } from "./DeliveryReceiptModal";
//import Select from "react-select";
import misc from "../../functions/misc";
import emptyImg from "../../assets/fork-lift.svg";
import { NumericFormat } from "react-number-format";
import { FormLabel } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import Badge from "react-bootstrap/Badge";
import Select from "react-select";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Spinner from "react-bootstrap/Spinner";
import useWindowDimensions from "../../components/useWindowDimensions";
let customStyles = {
  menu: (provided, state) => {
    const width = state.selectProps.width;

    let color = state.selectProps.menuColor;
    const padding = 0;

    return { ...provided, width, color, padding };
  },
  control: (provided) => ({
    ...provided,
    borderRadius: "0px 0px 0px 0px",
  }),

  container: (_, { selectProps: { width } }) => ({
    width: width,
    flex: 1,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
    borderRadius: "5px 0px 0px 5px",
  }),

  option: (provided, state) => {
    let backgroundColor = "";
    let display = "block";
    let color = "black";
    if (state.data.isDefault === 1) {
      backgroundColor = "rgba(255, 0, 0, 0.2)";
    }
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    //console.log(state.data);
    if (state.data.isHidden === 1) {
      display = "none";
    }

    return {
      ...provided,
      opacity,
      transition,
      backgroundColor,
      color,
      display,
    };
  },
};

function invoiceColumn(InvoiceTypes, InvoiceStatus, DateToday) {
  return [
    // {
    //   Header: "PO Materials",

    //   Cell: (row) => (
    //     <Row noGutters>
    //       <Col>
    //         <ImageGallery
    //           showNav={false}
    //           showPlayButton={false}
    //           showThumbnails={false}
    //           Invoices={images}
    //         />
    //       </Col>
    //     </Row>
    //   ),
    // },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          ID
        </p>
      ),
      id: "uniqueId",
      maxWidth: 110,
      show: false,
      filterable: false,
      getProps: (row) => {
        return { style: { fontSize: "10px" } };
      },
      accessor: (d) => (d.uniqueId === null ? "-" : d.uniqueId),
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Invoice Name / Transaction
        </p>
      ),
      id: "name",
      filterable: false,
      //maxWidth: 200,
      minWidth: 520,
      accessor: (d) => (d.name === null ? "-" : d.name),
      getProps: (row) => {
        return { style: {} };
      },
      Cell: (row) => {
        const d = row.original;
        let name = "-";
        if (d.client !== null) {
          name = d.client.company === null ? "-" : d.client.company;
        }
        if (d.supplier !== null) {
          name = d.supplier.company === null ? "-" : d.supplier.company;
        }
        return (
          <>
            <div
              style={{
                display: "block",
                wordWrap: "break-word",
                whiteSpace: "normal",
                textTransform: "uppercase",
                fontSize: "0.70rem",
                fontWeight: "bolder",
              }}
            >
              {d.id} - {d.uniqueId}
            </div>
            <div
              style={{
                display: "block",
                wordWrap: "break-word",
                whiteSpace: "normal",
                textTransform: "uppercase",
              }}
            >
              {d.name}
            </div>
            <div
              style={{
                display: "block",
                wordWrap: "break-word",
                whiteSpace: "normal",
              }}
            >
              {name}
            </div>
          </>

          // <FormControl
          //   as="textarea"
          //   style={{ resize: "none", height: "100%" }}
          //   defaultValue={d.name === null ? "-" : d.name}
          //   readOnly
          // />
        );
      },
    },

    // {
    //   Header: () => (
    //     <p
    //       style={{
    //         fontSize: "1em",
    //         color: "var(--REB-primary)",
    //         fontWeight: "bold",
    //         margin: 0,
    //       }}
    //     >
    //       Transaction / Customer
    //     </p>
    //   ),
    //   id: "transaction",
    //   filterable: false,
    //   getProps: (row) => {
    //     return { style: {} };
    //   },
    //   Cell: (row) => {
    //     const d = row.original;
    //     let name = "-";
    //     if (d.client !== null) {
    //       name = d.client.company === null ? "-" : d.client.company;
    //     }
    //     if (d.supplier !== null) {
    //       name = d.supplier.company === null ? "-" : d.supplier.company;
    //     }
    //     return (
    //       <div
    //         style={{
    //           display: "block",
    //           wordWrap: "break-word",
    //           whiteSpace: "normal",
    //         }}
    //       >
    //         {name}
    //       </div>
    //     );
    //   },
    //   accessor: (d) => {
    //     if (d.client !== null) {
    //       return d.client.company === null ? "-" : d.client.company;
    //     }
    //     if (d.supplier !== null) {
    //       return d.supplier.company === null ? "-" : d.supplier.company;
    //     }
    //   },
    // },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Total
        </p>
      ),
      id: "invoiceTotal",

      maxWidth: 110,
      getProps: (row) => {
        return {
          style: {
            fontSize: "13px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        };
      },
      Cell: (row) => {
        const { value } = row;

        return (
          <>
            {Number(value) > 0 ? (
              <div
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  fontWeight: "bold",
                }}
              >
                {misc.parseCurrencyNoCurrency(Number(value))}
              </div>
            ) : null}
          </>
        );
      },

      accessor: (d) => {
        const { content } = d;
        let invoiceTotal = 0.0;

        if (content) {
          if (d.invoiceType?.name === "Quote") {
            invoiceTotal = content.reduce((a, c) => {
              return a + c.price * c.itemQty;
            }, 0);
          }
          if (
            d.invoiceType?.name === "Purchase Order" ||
            d.invoiceType?.name === "Purchase Request"
          ) {
            invoiceTotal = content.reduce((a, c) => {
              return a + c.price * c.qtyOrdered;
            }, 0);
          }
          //invoiceTotal = misc.parseCurrencyNoCurrency(invoiceTotal);
        }
        return invoiceTotal;
      },
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Balance
        </p>
      ),
      id: "invoiceBalance",
      maxWidth: 110,
      getProps: (row) => {
        return {
          style: {
            fontSize: "13px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        };
      },
      Cell: (row) => {
        const { value } = row;

        return (
          <div
            style={{
              display: "block",
              wordWrap: "break-word",
              whiteSpace: "normal",
              fontWeight: "bold",
              // backgroundColor: "var(--REB-warning)",
              // color: "var(--REB-white)",
            }}
          >
            <Badge
              style={{
                backgroundColor:
                  value.total > 0 ? "var(--REB-warning)" : "var(--REB-green)",
                color: "white",
                fontSize: "1em",
                width: "115px",
              }}
            >
              {misc.parseCurrencyNoCurrency(value.total)}
            </Badge>
            {value.pendingPaymentTrans ? (
              <>
                <p
                  className="m-0 p-0"
                  style={{ fontSize: "0.5rem", textAlign: "center" }}
                >
                  Pending Billing
                </p>
                <Badge
                  style={{
                    color: "var(--REB-black)",
                    fontSize: "1em",
                    width: "115px",
                  }}
                >
                  {misc.parseCurrencyNoCurrency(value.pendingPaymentTrans)}
                </Badge>
              </>
            ) : null}
          </div>
        );
      },

      accessor: (d) => {
        // console.log(x);
        const { content, paymentTransaction } = d;
        let invoiceTotal = 0.0;
        let amountPaid = 0.0;

        if (content) {
          if (d.invoiceType?.name === "Quote") {
            invoiceTotal = content.reduce((a, c) => {
              return (
                a +
                misc.calculateMarkupDiscount(
                  c.srp,
                  c.discount,
                  c.priceModifier
                ) *
                  c.itemQty
              );
            }, 0);
          }
          if (
            d.invoiceType?.name === "Purchase Order" ||
            d.invoiceType?.name === "Purchase Request"
          ) {
            invoiceTotal = content.reduce((a, c) => {
              return a + c.price * c.qtyOrdered;
            }, 0);
          }
          //invoiceTotal = misc.parseCurrencyNoCurrency(invoiceTotal);
        }
        let pendingPaymentTrans = 0;
        if (paymentTransaction) {
          // console.log(paymentTransaction);
          const pendingTrans = paymentTransaction.filter(
            (x) =>
              x.isEntry === 0 &&
              (x.transStatus === "pending-admin-approval" ||
                x.transStatus === "processing")
          );

          const paidTrans = paymentTransaction.filter(
            (x) => x.isEntry === 0 && x.transStatus === "finished"
          );
          amountPaid = paidTrans.reduce((a, c) => {
            return a + c.amountValue;
          }, 0);
          pendingPaymentTrans = pendingTrans.reduce((a, c) => {
            return a + c.amountValue;
          }, 0);
          //amountPaid = misc.parseCurrencyNoCurrency(amountPaid);
        }
        // console.log(invoiceTotal, amountPaid);
        const total = Number(invoiceTotal - amountPaid);
        return { total, pendingPaymentTrans };
      },
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Date Last Modified
        </p>
      ),
      id: "dateLastModified",
      filterable: false,
      show: false,

      accessor: (d) => d.dateLastModified,
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Created
        </p>
      ),
      id: "dateAdded",
      filterable: false,
      maxWidth: 100,
      getProps: (row) => {
        return {
          style: {
            fontSize: "13px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        };
      },
      Cell: (d) => {
        const data = d.original;
        const name =
          data.dateAdded === null
            ? "-"
            : misc.parseAPIDateTimeToShortDate(
                misc.parseAPIDateTime(data.dateAdded)
              );
        return (
          <div
            style={{
              display: "block",
              wordWrap: "break-word",
              whiteSpace: "normal",
            }}
          >
            {name}
          </div>
        );
      },
      accessor: (d) => d.dateAdded,
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Deadline
        </p>
      ),
      id: "deadline",
      filterable: false,
      maxWidth: 100,

      Cell: (row) => {
        let name = "-";
        const d = row.original;
        let daysLeft = 0;

        let warn = "initial";

        if (
          d.invoiceStatus.name !== "Finished" ||
          d.invoiceStatus.name !== "Archived"
        ) {
          if (d.deadline && DateToday) {
            daysLeft = moment(d.deadline).diff(moment(DateToday), "days");
            if (daysLeft <= 2) warn = "var(--REB-yellow)";
            if (daysLeft <= 1) warn = "var(--REB-warning)";
          }
        } else {
          if (d.dateFinished && DateToday) {
            daysLeft = moment(d.deadline).diff(moment(d.dateFinished), "days");
            if (daysLeft <= 2) warn = "var(--REB-yellow)";
            if (daysLeft <= 1) warn = "var(--REB-warning)";
          }
        }
        name =
          d.deadline === null
            ? "-"
            : misc.parseAPIDateTimeToShortDate(
                misc.parseAPIDateTime(d.deadline)
              );
        let holdDays = moment(moment()).diff(d.dateAdded, "days");
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {/* <Row
            style={{
              color: warn,
              width:'100%',
              textAlign: "center",
              // alignItems: "center",
            }}
            className=""
            noGutters
          >
            <Col xs={12} style={{ fontWeight: "bolder" }}>{`${name}`}</Col>
           
          </Row> */}
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--REB-warning)",
                fontSize: "0.7rem",
              }}
            >
              {name}
            </p>
            {holdDays > 15 && (
              <>
                <div style={{}}>
                  <p
                    className="p-0 m-0"
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "0.7rem",
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                    }}
                  >
                    This invoice is stale by
                  </p>
                  <p
                    className="p-0 m-0"
                    style={{
                      fontWeight: "bolder",
                      textAlign: "center",
                      color: "var(--REB-warning)",
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                      fontSize: "0.95rem",
                    }}
                  >
                    {`${holdDays}`} {holdDays > 1 ? "Days" : "Day"}
                  </p>
                </div>
              </>
            )}
          </div>
        );
      },
      // getProps: (row) => {
      //   return {
      //     style: {
      //       fontSize: "13px",
      //       height: "100%",
      //       padding: 0,

      //       display: "flex",
      //       justifyContent: "center",
      //       alignItems: "center",
      //     },
      //   };
      // },
      accessor: (d) =>
        d.deadline === null
          ? "-"
          : misc.parseAPIDateTimeToShortDate(misc.parseAPIDateTime(d.deadline)),
    },

    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Type
        </p>
      ),
      id: "type",
      maxWidth: 200,
      filterable: true,
      Cell: (row) => {
        const d = row.original;
        let name = "-";
        let dateFinished = "";
        let infoColor = "";
        let icon = <></>;
        name = d.invoiceType === null ? "-" : d.invoiceType.name;

        switch (name) {
          case "Purchase Request":
            infoColor = "#157EBF";
            icon = (
              <i className="icofont-package" style={{ fontSize: "1.3em" }}></i>
            );
            break;
          case "Quote":
            infoColor = "#038C73";
            icon = (
              <i className="icofont-paper " style={{ fontSize: "1.3em" }}></i>
            );
            break;
          case "Purchase Order":
            infoColor = "#F2ACCD";
            icon = (
              <i className="icofont-cart " style={{ fontSize: "1.3em" }}></i>
            );
            break;
          case "Delivery Receipt":
            infoColor = "#BBBBEF";
            icon = (
              <i
                className="icofont-truck-loaded "
                style={{ fontSize: "1.3em" }}
              ></i>
            );
            break;
          case "POS":
            infoColor = "#8BAD47";
            icon = (
              <i className="icofont-tags" style={{ fontSize: "1.3rem" }}></i>
            );
            break;
          default:
            infoColor = "#038C73";
            icon = (
              <i className="icofont-warning " style={{ fontSize: "1.3em" }}></i>
            );
            break;
        }

        return (
          <div
            style={{
              display: "block",
              wordWrap: "break-word",
              whiteSpace: "normal",
              lineHeight: "0.8em",
              fontWeight: "bolder",
            }}
          >
            <Badge
              style={{
                backgroundColor: infoColor,
                color: "white",
                fontSize: "0.8em",
              }}
            >
              {icon} {name}
            </Badge>
          </div>
        );
      },
      getProps: (row) => {
        return {
          style: {
            padding: 0,

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        };
      },
      accessor: (d) => (d.invoiceType === null ? "-" : d.invoiceType.name),
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        } else {
          // console.log(row[filter.id]);
          return row[filter.id] === filter.value;
        }
        // if (filter.value === "true") {
        //   return row[filter.id] >= 21;
        // }
        // return row[filter.id] < 21;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="Quote">Quote</option>
          <option value="Purchase Request">Purchase Request</option>
          <option value="Purchase Order">Purchase Order</option>
          <option value="Delivery Receipt">Delivery Receipt</option>
          <option value="POS">POS</option>
          <option value="Transfer Inventory">Transfer Inventory</option>
        </select>
      ),
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Status
        </p>
      ),
      id: "status",
      // getProps: (state, rowInfo) => {
      //   if (rowInfo && rowInfo.row) {
      //     return {
      //       style: {
      //         backgroundColor: rowInfo.row.status === "New" ? "red" : null,
      //         height: 80,
      //       },
      //     };
      //   } else {
      //     return {};
      //   }
      // },
      accessor: (d) => (d.invoiceStatus === null ? "-" : d.invoiceStatus.name),
      maxWidth: 200,
      getProps: (row) => {
        return {
          style: {
            padding: 0,

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        };
      },
      Cell: (row) => {
        const d = row.original;

        // let holdDays = null;
        // if (d.invoiceVerificationHold) {
        //   holdDays = moment(d.invoiceVerificationHold).diff(moment(), "days");
        //   // if (holdDays < 0) holdDays = 0;
        // }

        let name = "-";
        let dateFinished = "";
        let infoColor = "";
        let icon = <></>;
        name = d.invoiceStatus === null ? "-" : d.invoiceStatus.name;

        const { content, paymentTransaction } = d;
        let invoiceTotal = 0.0;
        let amountPaid = 0.0;

        if (content) {
          invoiceTotal = content.reduce((a, c) => {
            return a + c.price * c.itemQty;
          }, 0);
          //invoiceTotal = misc.parseCurrencyNoCurrency(invoiceTotal);
        }
        if (paymentTransaction) {
          const filteredTrans = paymentTransaction.filter(
            (x) => x.isEntry !== 0
          );
          amountPaid = filteredTrans.reduce((a, c) => {
            return a + c.amountValue;
          }, 0);
          //amountPaid = misc.parseCurrencyNoCurrency(amountPaid);
        }
        // console.log("AP => ", Number(invoiceTotal - amountPaid));
        // console.log("TOTAL => ", invoiceTotal);
        switch (name) {
          case "For Delivery":
            infoColor = "#007BD3";
            icon = (
              <i className="icofont-package" style={{ fontSize: "1.3em" }}></i>
            );
            break;
          case "Ongoing Delivery":
            infoColor = "#248A84";
            icon = (
              <i
                className="icofont-truck-loaded "
                style={{ fontSize: "1.3em" }}
              ></i>
            );
            break;
          case "Admin Denied":
            infoColor = "var(--REB-warning)";
            icon = (
              <i
                className="icofont-ui-close "
                style={{ fontSize: "1.3em" }}
              ></i>
            );
            break;
          case "Pending Admin Approval":
            infoColor = "var(--REB-secondary)";
            icon = (
              <i className="icofont-warning " style={{ fontSize: "1.3em" }}></i>
            );
            break;
          case "Finished":
            infoColor = "var(--REB-green)";
            icon = (
              <i
                className="icofont-ui-check "
                style={{ fontSize: "1.3em" }}
              ></i>
            );
            break;
          case "Archived":
            infoColor = "#DAA21C";
            icon = (
              <i className="icofont-ui-file " style={{ fontSize: "1.3em" }}></i>
            );
            break;
          case "Dispute":
            infoColor = "var(--REB-warning)";
            icon = (
              <i
                className="icofont-ui-close "
                style={{ fontSize: "1.3em" }}
              ></i>
            );
            break;
          case "Pending Client Payment":
            infoColor = "var(--REB-green)";
            icon = (
              <i className="icofont-money " style={{ fontSize: "1.3em" }}></i>
            );
            break;
          case "Pending Payment To Supplier":
            infoColor = "var(--REB-green)";
            icon = (
              <i className="icofont-money " style={{ fontSize: "1.3em" }}></i>
            );
            break;
          case "New":
            infoColor = "#8BAD47";
            icon = (
              <i className="icofont-folder " style={{ fontSize: "1.3em" }}></i>
            );
            break;
          case "Walk In":
            infoColor = "#8BAD47";
            icon = (
              <i className="icofont-tags" style={{ fontSize: "1.3rem" }}></i>
            );
            break;
          case "Pending Archive":
            infoColor = "#DAA21C";
            icon = (
              <i className="icofont-ui-file" style={{ fontSize: "1.3rem" }}></i>
            );
            break;
          default:
            break;
        }

        if (d.invoiceStatus.name === "Finished") {
          dateFinished =
            d.dateFinished === null
              ? "-"
              : misc.parseAPIDateTimeToShortDate(
                  misc.parseAPIDateTime(d.dateFinished)
                );
        }

        return (
          <div
            style={{
              display: "block",
              wordWrap: "break-word",
              whiteSpace: "normal",
              lineHeight: "0.8em",
              fontWeight: "bolder",
            }}
          >
            <Badge
              style={{
                backgroundColor: infoColor,
                color: "white",
                fontSize: "0.8em",
              }}
            >
              {icon} {name}
            </Badge>

            <p
              style={{
                fontSize: "0.7em",
                lineHeight: "0.01em",
                color: "var(--REB-blue)",
                fontWeight: "bold",
                margin: 0,
                paddingTop: "0.7em",
                marginBottom: "5px",
              }}
            >
              {dateFinished}
            </p>
            {/* {holdDays !== null && holdDays <= 3 && holdDays > 1 && (
              <div>
                <div>
                  <h5
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "var(--REB-warning)",
                    }}
                  >
                    Verification Notice
                  </h5>
                </div>
                <p>
                  {holdDays} {holdDays > 1 ? "Days" : "Day"} left
                </p>
              </div>
            )}
            {holdDays !== null && holdDays <= 1 && (
              <div>
                <div
                  style={{ backgroundColor: "var(--REB-warning)" }}
                  className="p-2"
                >
                  <p
                    className="animate__pulse animate__animated animate__infinite	infinite"
                    style={{
                      fontWeight: "bolder",
                      textAlign: "center",
                      color: "var(--REB-white)",
                    }}
                  >
                    THIS INVOICE NEEDS YOUR ATTENTION ASAP
                  </p>
                </div>
                <p style={{ fontWeight: "bolder" }}>
                  {holdDays} {holdDays > 1 ? "Days" : "Day"} left
                </p>
              </div>
            )} */}
          </div>
        );
      },
      filterable: true,
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        } else {
          //console.log(row[filter.id]);
          return row[filter.id] === filter.value;
        }
        // if (filter.value === "true") {
        //   return row[filter.id] >= 21;
        // }
        // return row[filter.id] < 21;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="New">New</option>

          <option value="For Delivery">For Delivery</option>
          <option value="Ongoing Delivery">Ongoing Delivery</option>
          <option value="Pending Admin Approval">Pending Admin Approval</option>
          <option value="Finished">Finished</option>

          <option value="Admin Denied">Admin Denied</option>
        </select>
      ),
    },
    // {
    //   Header: () => (
    //     <p
    //       style={{
    //         fontSize: "1em",
    //         color: "var(--REB-primary)",
    //         fontWeight: "bold",
    //         margin: 0,
    //       }}
    //     >
    //       Priority
    //     </p>
    //   ),
    //   id: "transPrio",
    //   //maxWidth: 200,
    //   show: true,

    //   accessor: (d) => {
    //     let holdDays = null;
    //     // if (d.invoiceVerificationHold) {
    //     //   holdDays = moment(d.invoiceVerificationHold).diff(moment(), "days");

    //     //   if (holdDays > 3) holdDays = undefined;
    //     //   if (holdDays <= 1) holdDays = 9999;
    //     // }
    //     holdDays = moment(moment()).diff(d.dateAdded, "days");

    //     return holdDays;
    //   },
    // },
  ];
}

const invoiceDetailsColumn = [
  {
    Header: "Name",
    id: "name",

    accessor: (d) => (d.name === null ? "-" : d.name),
  },
  {
    Header: "Description",
    id: "description",
    maxWidth: 120,
    accessor: (d) => (d.description === null ? "-" : d.description),
  },
  {
    Header: "Default",
    id: "default",
    maxWidth: 90,
    accessor: (d) =>
      d.isDefault === null ? "No" : d.isDefault === 1 ? "Yes" : "No",
  },
];
const termsColumn = [
  {
    Header: "Terms Detail",
    id: "name",
    accessor: (d) => (d.name === null ? "-" : d.name),
  },
];

const deliveryListColumn = [
  {
    Header: "DR ID#",
    id: "uniqueId",
    accessor: (d) => (d.uniqueId === null ? "-" : d.uniqueId),
  },
  {
    Header: "Delivery Date",
    id: "deadline",
    accessor: (d) =>
      d.deadline === null
        ? "-"
        : misc.parseAPIDateTimeToShortDate(misc.parseAPIDateTime(d.deadline)),
  },
  {
    Header: "Driver",
    id: "driver",
    accessor: (d) => (d.driver === null ? "-" : d.driver?.name),
  },
  {
    Header: "Status",
    id: "description",
    accessor: (d) => (d.invoiceStatus === null ? "-" : d.invoiceStatus?.name),
  },
];

function itemColumn(inventoryData, itemImgs) {
  return [
    {
      Header: "Item Description",

      Cell: (row) => {
        const d = row.original;
        //console.log(d);
        let image = [];
        if (d) {
          if (d.itemId !== null) {
            if (d.item.uploads !== null && d.item.uploads !== "") {
              if (d.item.uploads.constructor === String)
                image = JSON.parse(d.item.uploads);
              else {
                image = d.item.uploads;
              }
              //console.log(image);
            }
          }
        }

        //console.log(d);
        const name = () => {
          let name = "-";
          if (d.itemId === null) {
            if (d.serviceName !== null) {
              name = JSON.parse(d.serviceName).label;
            } else {
              name = "-";
            }
          } else {
            if (d.item.details.length > 0) {
              const sDat = d.item.details.find((x) => x.type === 1);
              sDat !== undefined ? (name = sDat.name) : (name = "-");
            }
          }

          return name;
        };
        const brand = () => {
          let name = "-";
          if (d.itemId === null) {
            name = "-";
          } else {
            if (d.item.details.length > 0) {
              const sDat = d.item.details.find((x) => x.type === 3);
              sDat !== undefined ? (name = sDat.name) : (name = "-");
            }
          }

          return name;
        };
        const qty = () => {
          let name = "-";
          const d = row.original;
          if (d.itemId === null) {
            name = "Service";
          } else {
            if (d.item.details.length > 0) {
              const sDat = d.item.details.find((x) => x.type === 2);
              sDat !== undefined ? (name = sDat.name) : (name = "-");
            }
          }

          return `${d.itemQty} ${name}`;
        };
        //child data
        const CD = d.child;
        let image_CD = [];
        if (CD) {
          if (CD.itemId !== null) {
            if (CD.item.uploads.length > 0) {
              if (CD.item.uploads.constructor === String)
                image_CD = JSON.parse(CD.item.uploads);
              else {
                image = d.item.uploads;
              }
            }
          }
        }

        const nameChild = () => {
          let name = "-";
          if (CD.itemId === null) {
            if (CD.serviceName === null) {
            } else {
              name = JSON.parse(CD.serviceName).label;
            }
          } else {
            if (CD.item.details.length > 0) {
              const sDat = CD.item.details.find((x) => x.type === 1);
              sDat !== undefined ? (name = sDat.name) : (name = "-");
            }
          }

          return name;
        };
        const brandChild = () => {
          let name = "-";
          if (CD.itemId === null) {
            name = "-";
          } else {
            if (CD.item.details.length > 0) {
              const sDat = CD.item.details.find((x) => x.type === 3);
              sDat !== undefined ? (name = sDat.name) : (name = "-");
            }
          }

          return name;
        };
        const qtyChild = () => {
          let name = "-";
          //const CD = row.original;
          if (CD.itemId === null) {
            name = "Service";
          } else {
            if (CD.item.details.length > 0) {
              const sDat = CD.item.details.find((x) => x.type === 2);
              sDat !== undefined ? (name = sDat.name) : (name = "-");
            }
          }

          return `${CD.itemQty} ${name}`;
        };
        const labelWidth = "95px";

        const isLabel = () => {
          if (d.serviceName === null && d.itemId === null) return true;
          else return false;
        };

        return (
          <>
            {isLabel() ? <>{d.placeholder}</> : null}
            <Row noGutters>
              <Col md={12} lg={1} className="" hidden={isLabel()}>
                {" "}
                {((x) => {
                  if (d.showImg === 1) {
                    return (
                      <Row noGutters>
                        <Col
                          style={{
                            textAlign: "-webkit-center",
                          }}
                        >
                          <img
                            style={{
                              objectFit: "contain",
                              maxWidth: "100px",
                              height: "100px",
                              maxHeight: "100px",
                            }}
                            alt=""
                            src={
                              typeof image !== "undefined" &&
                              image !== null &&
                              image.length > 0 &&
                              image !== "null"
                                ? `${
                                    process.env.REACT_APP_API_URL
                                  }global/getFile2?uniqueId=${
                                    d.item.uniqueId
                                  }&filename=${encodeURIComponent(
                                    image[0].filename
                                  )}&token=${localStorage.getItem("LUT")}`
                                : emptyImg
                            }
                          ></img>
                        </Col>
                      </Row>
                    );
                  } else {
                    return <></>;
                  }
                })()}
              </Col>
              <Col md={12} lg={4} className="" hidden={isLabel()}>
                <Row noGutters>
                  <Col md={12} lg={12}>
                    <InputGroup>
                      <InputGroup.Prepend style={{}}>
                        <InputGroup.Text
                          style={{
                            color: "var(--REB-white)",
                            fontWeight: "bold",
                            backgroundColor: "var(--REB-primary)",
                            width: labelWidth,
                          }}
                        >
                          NAME
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        style={{ textTransform: "uppercase" }}
                        defaultValue={name()}
                        readOnly={true}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={12} lg={12}>
                    {" "}
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          style={{
                            color: "var(--REB-white)",
                            fontWeight: "bold",
                            backgroundColor: "var(--REB-primary)",
                            width: labelWidth,
                          }}
                        >
                          BRAND
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        style={{ textTransform: "uppercase" }}
                        defaultValue={brand()}
                        readOnly={true}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={12} lg={12}>
                    {" "}
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          style={{
                            color: "var(--REB-white)",
                            fontWeight: "bold",
                            backgroundColor: "var(--REB-primary)",
                            width: labelWidth,
                          }}
                        >
                          STATUS
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        style={{ textTransform: "uppercase" }}
                        defaultValue={
                          d.orderStatus === null ? "-" : d.orderStatus.name
                        }
                        readOnly={true}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
              <Col md={6} lg={3} className="" hidden={isLabel()}>
                <Row noGutters>
                  <Col md={12} lg={12}>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          style={{
                            color: "var(--REB-white)",
                            fontWeight: "bold",
                            backgroundColor: "var(--REB-primary)",
                            width: labelWidth,
                          }}
                        >
                          QTY
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <InputGroup.Prepend style={{ flex: 1 }}>
                        <InputGroup.Text
                          style={{
                            //color: "var(--REB-white)",
                            fontWeight: "bold",
                            //backgroundColor: "var(--REB-green)",
                            flex: 1,
                          }}
                        >
                          {qty()}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                    </InputGroup>
                  </Col>
                  <Col md={12} lg={12}>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          style={{
                            color: "var(--REB-white)",
                            fontWeight: "bold",
                            backgroundColor: "var(--REB-primary)",
                            width: labelWidth,
                          }}
                        >
                          SRP
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <InputGroup.Prepend style={{ flex: 1 }}>
                        <InputGroup.Text
                          style={{
                            //color: "var(--REB-white)",
                            fontWeight: "bold",
                            //backgroundColor: "var(--REB-green)",
                            flex: 1,
                          }}
                        >
                          {d.srp === null ? (
                            "-"
                          ) : (
                            <>
                              <NumericFormat
                                value={d.srp.toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={true}
                                className="pr-2"
                              />
                              <i className="icofont-swoosh-right"></i>
                              <NumericFormat
                                value={d.price.toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={true}
                                className="pl-2"
                              />
                            </>
                          )}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                    </InputGroup>
                  </Col>
                  <Col md={12} lg={12}>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          style={{
                            color: "var(--REB-white)",
                            fontWeight: "bold",
                            backgroundColor: "var(--REB-primary)",
                            width: labelWidth,
                          }}
                        >
                          LESS
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <InputGroup.Prepend style={{ flex: 1 }}>
                        <InputGroup.Text
                          style={{
                            //color: "var(--REB-white)",
                            fontWeight: "bold",
                            //backgroundColor: "var(--REB-green)",
                            flex: 1,
                          }}
                        >
                          {d.discount === null
                            ? "-"
                            : `${Number(d.discount).toFixed(2)} %  `}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <InputGroup.Prepend style={{ flex: 2 }}>
                        <InputGroup.Text
                          style={{
                            color: "var(--REB-white)",
                            fontWeight: "bold",
                            backgroundColor: "var(--REB-warning)",
                            flex: 1,
                          }}
                        >
                          {d.price === null ? (
                            "-"
                          ) : (
                            <NumericFormat
                              // value={d.price.toFixed(2)}
                              value={(d.price * (d.discount / 100)).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          )}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
              <Col md={6} lg={4} className="" hidden={isLabel()}>
                <Row noGutters className="h-100">
                  <Col md={12} lg={12}>
                    <InputGroup className="h-100">
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          style={{
                            color: "var(--REB-white)",
                            fontWeight: "bold",
                            backgroundColor: "var(--REB-primary)",
                            width: labelWidth,
                          }}
                        >
                          AMOUNT
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <InputGroup.Prepend style={{ flex: 1 }}>
                        <InputGroup.Text
                          style={{
                            //color: "var(--REB-white)",
                            fontWeight: "bold",
                            //backgroundColor: "var(--REB-green)",
                            flex: 1,
                          }}
                        >
                          {d.price === null ? (
                            "-"
                          ) : (
                            <NumericFormat
                              value={(d.price * d.itemQty).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          )}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            {(() => {
              if (d.item !== null && d.item !== undefined) {
                if (d.item.details.length > 0) {
                  const itemDetail = d.item.details.find((x) => x.type === 1);
                  if (itemDetail && itemDetail?.itemSets) {
                    //console.log(itemDetail)
                    if (itemDetail?.itemSets.length > 0) {
                      return (
                        <div
                          className="mt-2"
                          style={{
                            borderRadius: 0,
                            padding: 15,
                            backgroundColor: "var(--REB-body)",
                          }}
                        >
                          <h4
                            style={{
                              fontWeight: "bolder",
                              color: "var(--REB-black)",
                            }}
                          >
                            Set Contents
                          </h4>
                          <Row noGutters>
                            <Col>
                              {((x) => {
                                if (itemDetail) {
                                  return itemDetail.itemSets.map((x, index) => (
                                    <div key={x.id}>
                                      {`${index + 1}. `}
                                      {x.itemNameDetail.name}
                                    </div>
                                  ));
                                } else {
                                  return null;
                                }
                              })()}
                            </Col>
                          </Row>
                        </div>
                      );
                    }
                  }
                }
              }
            })()}

            {/* REPLACE WITH */}

            {CD !== undefined && d.childId !== null && CD !== null ? (
              <>
                <Row className="mb-2 mt-2" noGutters>
                  <Col>
                    <h4>Item Replacement</h4>
                  </Col>
                </Row>
                <Row noGutters>
                  <Col md={1} lg={1} className="">
                    {" "}
                    {((x) => {
                      if (CD.showImg === 1) {
                        return (
                          <Row noGutters>
                            <Col
                              style={{
                                textAlign: "-webkit-center",
                              }}
                            >
                              <img
                                style={{
                                  objectFit: "contain",
                                  maxWidth: "100px",
                                  height: "100px",
                                  maxHeight: "100px",
                                }}
                                alt=""
                                src={
                                  typeof image_CD !== "undefined" &&
                                  image_CD !== null &&
                                  image_CD.length > 0 &&
                                  image_CD !== "null"
                                    ? `${
                                        process.env.REACT_APP_API_URL
                                      }global/getFile2?uniqueId=${
                                        CD.item.uniqueId
                                      }&filename=${encodeURIComponent(
                                        image_CD[0].filename
                                      )}&token=${localStorage.getItem("LUT")}`
                                    : emptyImg
                                }
                              ></img>
                            </Col>
                          </Row>
                        );
                      } else {
                        return <></>;
                      }
                    })()}
                  </Col>
                  <Col md={4} lg={4} className="">
                    <Row noGutters>
                      <Col md={12} lg={12}>
                        <InputGroup>
                          <InputGroup.Prepend style={{}}>
                            <InputGroup.Text
                              style={{
                                color: "var(--REB-white)",
                                fontWeight: "bold",
                                backgroundColor: "var(--REB-primary)",
                                width: labelWidth,
                              }}
                            >
                              NAME
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            style={{ textTransform: "uppercase" }}
                            defaultValue={nameChild()}
                            readOnly={true}
                          />
                        </InputGroup>
                      </Col>
                      <Col md={12} lg={12}>
                        {" "}
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text
                              style={{
                                color: "var(--REB-white)",
                                fontWeight: "bold",
                                backgroundColor: "var(--REB-primary)",
                                width: labelWidth,
                              }}
                            >
                              BRAND
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            style={{ textTransform: "uppercase" }}
                            defaultValue={brandChild()}
                            readOnly={true}
                          />
                        </InputGroup>
                      </Col>
                      <Col md={12} lg={12}>
                        {" "}
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text
                              style={{
                                color: "var(--REB-white)",
                                fontWeight: "bold",
                                backgroundColor: "var(--REB-primary)",
                                width: labelWidth,
                              }}
                            >
                              STATUS
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            style={{ textTransform: "uppercase" }}
                            defaultValue={
                              CD.orderStatus === null
                                ? "-"
                                : CD.orderStatus.name
                            }
                            readOnly={true}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={3} lg={3} className="">
                    <Row noGutters>
                      <Col md={12} lg={12}>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text
                              style={{
                                color: "var(--REB-white)",
                                fontWeight: "bold",
                                backgroundColor: "var(--REB-primary)",
                                width: labelWidth,
                              }}
                            >
                              QTY
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <InputGroup.Prepend style={{ flex: 1 }}>
                            <InputGroup.Text
                              style={{
                                //color: "var(--REB-white)",
                                fontWeight: "bold",
                                //backgroundColor: "var(--REB-green)",
                                flex: 1,
                              }}
                            >
                              {qtyChild()}
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                        </InputGroup>
                      </Col>
                      <Col md={12} lg={12}>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text
                              style={{
                                color: "var(--REB-white)",
                                fontWeight: "bold",
                                backgroundColor: "var(--REB-primary)",
                                width: labelWidth,
                              }}
                            >
                              SRP
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <InputGroup.Prepend style={{ flex: 1 }}>
                            <InputGroup.Text
                              style={{
                                //color: "var(--REB-white)",
                                fontWeight: "bold",
                                //backgroundColor: "var(--REB-green)",
                                flex: 1,
                              }}
                            >
                              {CD.srp === null ? (
                                "-"
                              ) : (
                                <NumericFormat
                                  value={CD.srp.toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              )}
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                        </InputGroup>
                      </Col>
                      <Col md={12} lg={12}>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text
                              style={{
                                color: "var(--REB-white)",
                                fontWeight: "bold",
                                backgroundColor: "var(--REB-primary)",
                                width: labelWidth,
                              }}
                            >
                              LESS
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <InputGroup.Prepend style={{ flex: 1 }}>
                            <InputGroup.Text
                              style={{
                                //color: "var(--REB-white)",
                                fontWeight: "bold",
                                //backgroundColor: "var(--REB-green)",
                                flex: 1,
                              }}
                            >
                              {CD.discount === null
                                ? "-"
                                : `${CD.discount} %  `}
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <InputGroup.Prepend style={{ flex: 2 }}>
                            <InputGroup.Text
                              style={{
                                //color: "var(--REB-white)",
                                fontWeight: "bold",
                                //backgroundColor: "var(--REB-green)",
                                flex: 1,
                              }}
                            >
                              {CD.price === null ? (
                                "-"
                              ) : (
                                <NumericFormat
                                  value={CD.price.toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              )}
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                        </InputGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={4} lg={4} className="">
                    <Row noGutters>
                      <Col md={12} lg={12}>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text
                              style={{
                                color: "var(--REB-white)",
                                fontWeight: "bold",
                                backgroundColor: "var(--REB-primary)",
                                width: labelWidth,
                              }}
                            >
                              AMOUNT
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <InputGroup.Prepend style={{ flex: 1 }}>
                            <InputGroup.Text
                              style={{
                                //color: "var(--REB-white)",
                                fontWeight: "bold",
                                //backgroundColor: "var(--REB-green)",
                                flex: 1,
                              }}
                            >
                              {CD.price === null ? (
                                "-"
                              ) : (
                                <NumericFormat
                                  value={(CD.price * CD.itemQty).toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              )}
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                        </InputGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            ) : null}
          </>
        );
      },
    },
  ];
}

function selectedDrColumn() {
  const name = (d) => {
    let name = "-";
    if (d.itemId === null) {
      if (d.serviceName !== null) name = JSON.parse(d.serviceName).label;
    } else {
      if (d.item.details.length > 0) {
        const sDat = d.item.details.find((x) => x.type === 1);
        sDat !== undefined ? (name = sDat.name) : (name = "-");
      }
    }

    return name;
  };
  const brand = (d) => {
    let name = "-";
    if (d.itemId === null) {
      name = "-";
    } else {
      if (d.item.details.length > 0) {
        const sDat = d.item.details.find((x) => x.type === 3);
        sDat !== undefined ? (name = sDat.name) : (name = "-");
      }
    }

    return name;
  };
  // const qty = (d) => {
  //   let name = "-";
  //   if (d.itemId === null) {
  //     name = "Service";
  //   } else {
  //     if (d.item.details.length > 0) {
  //       const sDat = d.item.details.find((x) => x.type === 2);
  //       sDat !== undefined ? (name = sDat.name) : (name = "-");
  //     }
  //   }

  //   return `${d.itemQty} ${name}`;
  // };
  const delivered = (d) => {
    let name = "-";
    if (d.itemId === null) {
      name = "Service";
    } else {
      if (d.item.details.length > 0) {
        const sDat = d.item.details.find((x) => x.type === 2);
        sDat !== undefined ? (name = sDat.name) : (name = "-");
      }
    }
    const delivered = d.qtyDelivered === null ? 0 : d.qtyDelivered;
    return `${delivered} ${name}`;
  };
  // const undelivered = (d) => {
  //   let name = "-";
  //   const qty = d.itemQty === null ? 0 : d.itemQty;
  //   const delivered = d.qtyDelivered === null ? 0 : d.qtyDelivered;
  //   const undelivered = qty - delivered;

  //   if (d.itemId === null) {
  //     name = "Service";
  //   } else {
  //     if (d.item.details.length > 0) {
  //       const sDat = d.item.details.find((x) => x.type === 2);
  //       sDat !== undefined ? (name = sDat.name) : (name = "-");
  //     }
  //   }

  //   return `${undelivered} ${name}`;
  // };
  return [
    {
      Header: () => (
        <p
          style={{
            fontSize: "1rem",
            color: "var(--REB-primary)",
            fontWeight: "bolder",
            margin: 0,
          }}
        >
          Image
        </p>
      ),
      maxWidth: 110,
      filterable: false,
      //accessor: (d) => name(d),

      Cell: (row) => {
        const d = row.original;
        //console.log(d);
        let image = [];
        if (d) {
          if (d.itemId !== null) {
            if (d.item.uploads !== null && d.item.uploads !== "") {
              if (d.item.uploads.constructor === String)
                image = JSON.parse(d.item.uploads);
              else {
                image = d.item.uploads;
              }
              //console.log(image);
            }
          }
        }
        return (
          <>
            {((x) => {
              if (true) {
                return (
                  <Row noGutters>
                    <Col
                      style={{
                        textAlign: "-webkit-center",
                      }}
                    >
                      <img
                        style={{
                          objectFit: "contain",
                          maxWidth: "100px",
                          height: "100px",
                          maxHeight: "100px",
                        }}
                        alt=""
                        src={
                          typeof image !== "undefined" &&
                          image !== null &&
                          image.length > 0 &&
                          image !== "null"
                            ? `${
                                process.env.REACT_APP_API_URL
                              }global/getFile2?uniqueId=${
                                d.item.uniqueId
                              }&filename=${encodeURIComponent(
                                image[0].filename
                              )}&token=${localStorage.getItem("LUT")}`
                            : emptyImg
                        }
                      ></img>
                    </Col>
                  </Row>
                );
              } else {
                return <></>;
              }
            })()}
          </>
        );
      },
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1rem",
            color: "var(--REB-primary)",
            fontWeight: "bolder",
            margin: 0,
          }}
        >
          Item Name
        </p>
      ),
      id: "name",
      Cell: (row) => {
        const d = row.original;
        let name = "-";
        if (d.item.details.length > 0) {
          let nameDat = d.item.details.find((x) => x.type === 1);
          nameDat = nameDat !== null ? nameDat.name : "";
          name = nameDat;
        }
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              wordWrap: "break-word",
              whiteSpace: "normal",
              fontSize: "0.85em",
              height: "100%",
              color: "var(--REB-blue)",
              fontWeight: "bold",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <p style={{ marginBottom: "0px", flex: 1 }}> {name}</p>

            <p style={{ marginBottom: "0px", fontWeight: "normal", flex: 1 }}>
              {d.item?.description}
            </p>
          </div>

          // <FormControl
          //   as="textarea"
          //   style={{ resize: "none", height: "100%" }}
          //   defaultValue={name}
          //   readOnly
          // />
        );
      },
      accessor: (d) => name(d),
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1rem",
            color: "var(--REB-primary)",
            fontWeight: "bolder",
            margin: 0,
          }}
        >
          Brand
        </p>
      ),
      id: "brand",
      maxWidth: 150,
      accessor: (d) => brand(d),
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1rem",
            color: "var(--REB-primary)",
            fontWeight: "bolder",
            margin: 0,
          }}
        >
          Qty To Deliver
        </p>
      ),
      id: "itemQty",
      maxWidth: 160,
      Cell: (row) => {
        let name = "-";
        const d = row.original.item;

        if (d.details.length > 0) {
          const sDat = d.details.find((x) => x.type === 2);
          sDat !== undefined ? (name = sDat.name) : (name = "-");
        }

        return (
          <Row
            style={{
              height: "100%",
              textAlign: "center",
              alignItems: "center",
            }}
            className=""
            noGutters
          >
            <Col style={{ fontWeight: "bolder" }}>
              {`${
                row.original.qtyDelivered === null
                  ? 0
                  : row.original.qtyDelivered
              }`}
              <br /> {` ${name}`}
            </Col>
          </Row>
        );
      },
      accessor: (d) => delivered(d),
    },
  ];
}

function drColumn() {
  const name = (d) => {
    let name = "-";
    if (d.itemId === null) {
      if (d.serviceName !== null) name = JSON.parse(d.serviceName).label;
    } else {
      if (d.item.details.length > 0) {
        const sDat = d.item.details.find((x) => x.type === 1);
        sDat !== undefined ? (name = sDat.name) : (name = "-");
      }
    }

    return name;
  };
  const brand = (d) => {
    let name = "-";
    if (d.itemId === null) {
      name = "-";
    } else {
      if (d.item.details.length > 0) {
        const sDat = d.item.details.find((x) => x.type === 3);
        sDat !== undefined ? (name = sDat.name) : (name = "-");
      }
    }

    return name;
  };
  const qty = (d) => {
    let name = "-";
    if (d.itemId === null) {
      name = "Service";
    } else {
      if (d.item.details.length > 0) {
        const sDat = d.item.details.find((x) => x.type === 2);
        sDat !== undefined ? (name = sDat.name) : (name = "-");
      }
    }

    return `${d.itemQty} ${name}`;
  };
  const delivered = (d) => {
    let name = "-";
    if (d.itemId === null) {
      name = "Service";
    } else {
      if (d.item.details.length > 0) {
        const sDat = d.item.details.find((x) => x.type === 2);
        sDat !== undefined ? (name = sDat.name) : (name = "-");
      }
    }
    const delivered = d.qtyDelivered === null ? 0 : d.qtyDelivered;
    return `${delivered} ${name}`;
  };
  const undelivered = (d) => {
    let name = "-";
    const qty = d.itemQty === null ? 0 : d.itemQty;
    const delivered = d.qtyDelivered === null ? 0 : d.qtyDelivered;
    const undelivered = qty - delivered;

    if (d.itemId === null) {
      name = "Service";
    } else {
      if (d.item.details.length > 0) {
        const sDat = d.item.details.find((x) => x.type === 2);
        sDat !== undefined ? (name = sDat.name) : (name = "-");
      }
    }

    return `${undelivered} ${name}`;
  };
  return [
    {
      Header: () => (
        <p
          style={{
            fontSize: "0.70em",
            color: "var(--REB-primary)",
            fontWeight: "bolder",
            margin: 0,
          }}
        >
          Image
        </p>
      ),
      maxWidth: 110,
      filterable: false,
      //accessor: (d) => name(d),

      Cell: (row) => {
        const d = row.original;
        //console.log(d);
        let image = [];
        if (d) {
          if (d.itemId !== null) {
            if (d.item.uploads !== null && d.item.uploads !== "") {
              if (d.item.uploads.constructor === String)
                image = JSON.parse(d.item.uploads);
              else {
                image = d.item.uploads;
              }
              //console.log(image);
            }
          }
        }
        return (
          <>
            {((x) => {
              if (true) {
                return (
                  <Row noGutters>
                    <Col
                      style={{
                        textAlign: "-webkit-center",
                      }}
                    >
                      <img
                        style={{
                          objectFit: "contain",
                          maxWidth: "100px",
                          height: "100px",
                          maxHeight: "100px",
                        }}
                        alt=""
                        src={
                          typeof image !== "undefined" &&
                          image !== null &&
                          image.length > 0 &&
                          image !== "null"
                            ? `${
                                process.env.REACT_APP_API_URL
                              }global/getFile2?uniqueId=${
                                d.item.uniqueId
                              }&filename=${encodeURIComponent(
                                image[0].filename
                              )}&token=${localStorage.getItem("LUT")}`
                            : emptyImg
                        }
                      ></img>
                    </Col>
                  </Row>
                );
              } else {
                return <></>;
              }
            })()}
          </>
        );
      },
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1rem",
            color: "var(--REB-primary)",
            fontWeight: "bolder",
            margin: 0,
          }}
        >
          Item Name
        </p>
      ),
      id: "name",
      Cell: (row) => {
        const d = row.original;
        let name = "-";
        if (d.item.details.length > 0) {
          let nameDat = d.item.details.find((x) => x.type === 1);
          nameDat = nameDat !== null ? nameDat.name : "";
          name = nameDat;
        }
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              wordWrap: "break-word",
              whiteSpace: "normal",
              fontSize: "0.85em",
              height: "100%",
              color: "var(--REB-blue)",
              fontWeight: "bold",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <p style={{ marginBottom: "0px", flex: 1 }}> {name}</p>

            <p style={{ marginBottom: "0px", fontWeight: "normal", flex: 1 }}>
              {d.item?.description}
            </p>
          </div>

          // <FormControl
          //   as="textarea"
          //   style={{ resize: "none", height: "100%" }}
          //   defaultValue={name}
          //   readOnly
          // />
        );
      },
      accessor: (d) => name(d),
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1rem",
            color: "var(--REB-primary)",
            fontWeight: "bolder",
            margin: 0,
          }}
        >
          Brand
        </p>
      ),
      id: "brand",
      maxWidth: 150,
      accessor: (d) => brand(d),
    },
    // {
    //   Header: "Name",
    //   id: "name",
    //   accessor: (d) => name(d),
    // },
    // {
    //   Header: "Brand",
    //   id: "brand",
    //   accessor: (d) => brand(d),
    // },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1rem",
            color: "var(--REB-primary)",
            fontWeight: "bolder",
            margin: 0,
          }}
        >
          QTY NEED
        </p>
      ),
      id: "itemQty",
      maxWidth: 160,
      Cell: (row) => {
        let name = "-";
        const d = row.original.item;

        if (d.details.length > 0) {
          const sDat = d.details.find((x) => x.type === 2);
          sDat !== undefined ? (name = sDat.name) : (name = "-");
        }

        return (
          <Row
            style={{
              height: "100%",
              textAlign: "center",
              alignItems: "center",
            }}
            className=""
            noGutters
          >
            <Col style={{ fontWeight: "bolder" }}>
              {`${row.original.itemQty === null ? 0 : row.original.itemQty}`}
              <br /> {` ${name}`}
            </Col>
          </Row>
        );
      },
      accessor: (d) => qty(d),
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1rem",
            color: "var(--REB-primary)",
            fontWeight: "bolder",
            margin: 0,
          }}
        >
          UNDELIVERED QTY
        </p>
      ),
      maxWidth: 160,
      id: "undelivered",
      Cell: (row) => {
        let name = "-";
        const d = row.original.item;

        if (d.details.length > 0) {
          const sDat = d.details.find((x) => x.type === 2);
          sDat !== undefined ? (name = sDat.name) : (name = "-");
        }

        const qty = row.original.itemQty === null ? 0 : row.original.itemQty;
        const delivered =
          row.original.qtyDelivered === null ? 0 : row.original.qtyDelivered;
        const undelivered = qty - delivered;

        return (
          <Row
            style={{
              height: "100%",
              textAlign: "center",
              alignItems: "center",
            }}
            className=""
            noGutters
          >
            <Col style={{ fontWeight: "bolder" }}>
              {`${undelivered}`}
              <br /> {` ${name}`}
            </Col>
          </Row>
        );
      },
      accessor: (d) => undelivered(d),
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1rem",
            color: "var(--REB-primary)",
            fontWeight: "bolder",
            margin: 0,
          }}
        >
          QTY DELIVERED
        </p>
      ),
      maxWidth: 160,
      id: "delivered",
      Cell: (row) => {
        let name = "-";
        const d = row.original.item;

        if (d.details.length > 0) {
          const sDat = d.details.find((x) => x.type === 2);
          sDat !== undefined ? (name = sDat.name) : (name = "-");
        }
        const delivered =
          row.original.qtyDelivered === null ? 0 : row.original.qtyDelivered;
        return (
          <Row
            style={{
              height: "100%",
              textAlign: "center",
              alignItems: "center",
            }}
            className=""
            noGutters
          >
            <Col style={{ fontWeight: "bolder" }}>
              {`${delivered}`}
              <br /> {` ${name}`}
            </Col>
          </Row>
        );
      },
      accessor: (d) => delivered(d),
    },
  ];
}

const InvoiceDetails = (props) => (
  <Row className="row-crud mb-3 mt-3" noGutters={true}>
    {/* <Col md="auto" lg="auto" className="options-column">
      <InputGroup className="">
        <InputGroup.Prepend>
          <InputGroup.Text className="actor-ico-search">
            <i className="icofont-search icofont-1x"></i>
          </InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          placeholder="Search"
          aria-label="Username"
          name="searchInput"
          className="actor-searchTable shadow-none"
          aria-describedby="basic-addon1"
          onChange={props.state.handleSearch}
        />
      </InputGroup>
    </Col> */}
    <Col md="auto" lg="auto" className="user-select">
      <InputGroup>
        <select
          className="form-control actor-selector w-auto"
          name="InvoiceDetailsSelect"
          //value={props.state.selSelectedInvoiceDetail.value}
          onChange={props.state.handleActionChangeInvoiceDetails}
          ref={props.invoiceDetailsSelectRef}
        >
          {props.state.invoiceDetailsConstBak.map((x) => {
            // if (x.hidden !== 1) {
            if (true) {
              return (
                <option
                  //ref={x.value === 1 ? props.testRef : null}
                  key={x.value}
                  value={x.value}
                >
                  {x.name}
                </option>
              );
            } else {
              return null;
            }
          })}
        </select>
        {props.userType.includes("Admin") && (
          <InputGroup.Prepend>
            <div
              style={{
                backgroundColor: "var(--REB-green)",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Form.Check
                checked={props.state.chkShowAppData.value}
                disabled={props.state.chkShowAppData.readOnly}
                style={{ paddingTop: "6.5px", color: "white" }}
                name="chkShowAppData"
                label="Show App Data"
                onChange={(e) => {
                  if (!props.state.chkShowAppData.value) {
                    props.state.dispatch({
                      type: "MODAL_SHOW",
                      payload: {
                        title: "Warning",
                        desc: `You are accessing application sensitive settings. Modifying settings here can lead to major application error. Proceed with caution or contact developers. Are you sure you want to proceed?`,
                        isYesNo: true,
                        cb: async () => {
                          props.state.handleActionChange(
                            {
                              target: {
                                value: e.target,
                                name: "chkShowAppData",
                              },
                            },
                            null,
                            () => {
                              // props.state.handleActionChange({
                              //   target: {
                              //     value: e.target,
                              //     name: "applyFilter",
                              //   },
                              // });
                            }
                          );
                        },
                      },
                    });
                  } else {
                    props.state.handleActionChange(
                      {
                        target: {
                          value: e.target,
                          name: "chkShowAppData",
                        },
                      },
                      null,
                      () => {
                        // props.state.handleActionChange({
                        //   target: {
                        //     value: e.target,
                        //     name: "applyFilter",
                        //   },
                        // });
                      }
                    );
                  }
                }}
              />
            </div>
          </InputGroup.Prepend>
        )}
      </InputGroup>
    </Col>

    <Col className="actor-btn-crud-col d-flex justify-content-end">
      <ButtonGroup className="">
        <OverlayTrigger
          placement={"top"}
          overlay={
            <Tooltip id={`actor-tooltip-add`}>ADD ORDER DETAILS</Tooltip>
          }
        >
          <Button
            name="btnAddInvoice"
            style={{
              backgroundColor: "var(--REB-primary)",
            }}
            className="icofont-ui-add"
            onClick={(e) => {
              props.state.handleActionChange(e);
            }}
          ></Button>
        </OverlayTrigger>
      </ButtonGroup>
    </Col>
  </Row>
);

const Invoices = (props) => {
  const [isDisabled, setDisabled] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [search, setSearch] = useState("");

  const { width } = useWindowDimensions();

  useEffect(() => {
    if (!isDisabled) {
      // timeout elapsed, nothing to do
      return;
    }

    // isDisabled was changed to true, set back to false after `delay`
    const handle = setTimeout(() => {
      setDisabled(false);
    }, 3000);
    return () => clearTimeout(handle);
  }, [isDisabled, 3000]);

  useEffect(() => {
    //Reset transmission sequence
    if (timeLeft === 1) {
      props.handleSearch({ target: { value: search } });
      //console.log(searchRef?.current.value);

      setTimeLeft(0);
    }

    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  const handleSearchDebounce = (e, cb) => {
    if (isDisabled) {
      return;
    }

    setDisabled(true);
    return cb && cb(e);
  };

  const handleSearchDelay = (delay, cb) => {
    setTimeLeft(delay);
    if (timeLeft === 1) {
      cb && cb();
    }
  };

  return (
    <>
      <Row className="" style={{}} noGutters>
        {/* <Col className="" style={{ alignSelf: "center" }} md={1} lg={1}>
        <h4 style={{ height: "100%", margin: 0 }}>Filter</h4>
      </Col> */}
        <Col xs={8} sm={8} md="auto" lg="auto" className="options-column mb-2">
          <InputGroup className="">
            <InputGroup.Prepend>
              <InputGroup.Text className="actor-ico-search">
                <i className="icofont-search icofont-1x"></i>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="Search"
              aria-label="InvoiceSearch"
              name="searchInput"
              className="actor-searchTable shadow-none"
              aria-describedby="aria-invoicing-search-bar"
              style={{ borderRight: "transparent " }}
              value={search}
              onChange={(e) => {
                setSearch(e.currentTarget.value);
                // props.state.handleSearch(e);
                setTimeLeft(3);
                // handleSearchDelay(4, () => {
                //   console.log("trigger debounce");
                // });
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #ced4da",
                borderLeft: "unset",
                borderRadius: "0rem 0.25rem 0.25rem 0rem",
              }}
              className="pr-2 "
            >
              {timeLeft > 0 && <Spinner animation="border" variant="primary" />}
              {timeLeft === 0 && search && (
                <Button
                  className="icofont-close"
                  onClick={() => {
                    setSearch("");
                    props.handleSearch({ target: { value: "" } });
                  }}
                  style={{
                    color: "var(--REB-warning)",
                    backgroundColor: "transparent",
                    outline: "none",
                    boxShadow: "none",
                  }}
                ></Button>
              )}
            </div>
          </InputGroup>
        </Col>
        <Col
          xs={4}
          sm={4}
          md="auto" lg="auto"
          className="px-1 mb-2"
          style={
            {
              //alignSelf: "center"
            }
          }
        >
          <InputGroup
            // className="ml-2 "
            style={{
              //alignItems: "center",
              height: "100%",
            }}
          >
            <Dropdown>
              <Dropdown.Toggle
                style={{ backgroundColor: "var(--REB-tertiary)" }}
                id="dropdown-basic"
              >
                <i className="icofont-filter pr-1"></i>
                Filter
              </Dropdown.Toggle>

              <Dropdown.Menu className="px-4">
                <FormLabel>Date Filter Start</FormLabel>
                <FormControl
                  type="month"
                  //placeholder={props.state.txtDeadline.placeholder}
                  placeholder="Date"
                  // onMouseOver={(e) => (e.currentTarget.type = "date")}
                  // onMouseOut={(e) => (e.currentTarget.type = "text")}
                  name="dpStartDate"
                  value={props.state.dpStartDate.value}
                  onChange={(x) => {
                    props.inputChange(x, () => {
                      props.state.handleActionChange({
                        target: {
                          value: x.target,
                          name: "applyFilter",
                        },
                      });
                    });
                  }}
                  className={
                    props.state.dpStartDate.className + " css-projectInput "
                  }
                  readOnly={props.state.dpStartDate.readOnly}
                />
                {/*<InputGroup>
                
               <InputGroup.Prepend>
                  <InputGroup.Text
                    style={{
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      backgroundColor: "var(--REB-primary)",
                    }}
                  >
                    Date Start
                  </InputGroup.Text>
                </InputGroup.Prepend>
               
              </InputGroup> */}
                {/* <FormLabel className="mt-1">Date Filter End</FormLabel>
              <FormControl
                type="date"
                //placeholder={props.state.txtDeadline.placeholder}
                name="dpEndDate"
                value={props.state.dpEndDate.value}
                onChange={(x) => {
                  props.inputChange(x, () => {
                    props.state.handleActionChange({
                      target: {
                        value: x.target,
                        name: "applyFilter",
                      },
                    });
                  });
                }}
                className={
                  props.state.dpEndDate.className + " css-projectInput"
                }
                readOnly={props.state.dpEndDate.readOnly}
              /> */}
                {/* <InputGroup>
                
                <InputGroup.Prepend>
                  <InputGroup.Text
                    style={{
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      backgroundColor: "var(--REB-primary)",
                    }}
                  >
                    Date End
                  </InputGroup.Text>
                </InputGroup.Prepend>
              </InputGroup> */}
                <div
                  style={{
                    // backgroundColor: "var(--REB-green)",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  <Form.Check
                    checked={props.state.chkAllTime.value}
                    disabled={props.state.chkAllTime.readOnly}
                    style={{ paddingTop: "6.5px", color: "black" }}
                    name="chkAllTime"
                    label="All Time"
                    onChange={(e) => {
                      props.state.handleActionChange(
                        {
                          target: {
                            value: e.target,
                            name: "chkAllTime",
                          },
                        },
                        null,
                        () => {
                          props.state.handleActionChange({
                            target: {
                              value: e.target,
                              name: "applyFilter",
                            },
                          });
                        }
                      );
                    }}
                  />
                </div>
                <div
                  style={{
                    // backgroundColor: "var(--REB-green)",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  <Form.Check
                    checked={props.state.chkOngoing.value}
                    disabled={props.state.chkOngoing.readOnly}
                    style={{ paddingTop: "6.5px", color: "black" }}
                    name="chkOngoing"
                    label="Ongoing"
                    onChange={(e) => {
                      props.state.handleActionChange(
                        {
                          target: {
                            value: e.target,
                            name: "chkOngoing",
                          },
                        },
                        null,
                        () => {
                          props.state.handleActionChange({
                            target: {
                              value: e.target,
                              name: "applyFilter",
                            },
                          });
                        }
                      );
                    }}
                  />
                </div>
                <div
                  style={{
                    // backgroundColor: "var(--REB-green)",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  <Form.Check
                    checked={props.state.chkDisplayPOS.value}
                    disabled={props.state.chkDisplayPOS.readOnly}
                    style={{ paddingTop: "6.5px", color: "black" }}
                    name="chkDisplayPOS"
                    label="Display POS Invoices"
                    onChange={(e) => {
                      props.state.handleActionChange(
                        {
                          target: {
                            value: e.target,
                            name: "chkDisplayPOS",
                          },
                        },
                        null,
                        () => {
                          props.state.handleActionChange({
                            target: {
                              value: e.target,
                              name: "applyFilter",
                            },
                          });
                        }
                      );
                    }}
                  />
                </div>
                <OverlayTrigger
                  placement={"top"}
                  overlay={<Tooltip id={`actor-tooltip-add`}>Reset</Tooltip>}
                >
                  <Button
                    block
                    className="px-1 my-1"
                    name="btnUnlockInvoiceContentChangeItem"
                    //disabled={!props.state.handleInvoiceModal.isView}
                    style={{
                      backgroundColor: "var(--REB-green)",

                      // paddingRight: "10px",
                      fontWeight: "bolder",
                    }}
                    onClick={(e) => {
                      props.state.handleActionChange(
                        {
                          target: {
                            name: "resetFilter",
                          },
                        },
                        null,
                        () => {
                          props.state.handleActionChange({
                            target: {
                              value: e.target,
                              name: "applyFilter",
                            },
                          });
                        }
                      );
                    }}
                  >
                    Reset Filter
                  </Button>
                </OverlayTrigger>
              </Dropdown.Menu>
            </Dropdown>
          </InputGroup>
        </Col>
        <Col xs={8} sm={8} md={4} lg={4}  className="">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text
                style={{
                  backgroundColor: "var(--REB-primary)",
                  color: "var(--REB-white)",
                  fontWeight: "bold",
                  fontSize: "0.75rem",
                }}
              >
                INVENTORY
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Select
              styles={{
                menu: (provided, state) => {
                  //console.log(state);
                  const width = "100%";

                  let color = state.selectProps.menuColor;
                  const padding = 0;

                  return { ...provided, width, color, padding };
                },
                control: (provided) => ({
                  ...provided,
                  borderRadius: "0px 0px 0px 0px",
                }),

                container: (_, { selectProps: { width } }) => ({
                  width: width,
                  // flex: 1,
                }),
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999,

                  borderRadius: "5px 0px 0px 5px",
                }),

                option: (provided, state) => {
                  let backgroundColor = "";
                  let display = "block";
                  let color = "black";
                  if (state.data.isDefault === 1) {
                    backgroundColor = "rgba(255, 0, 0, 0.2)";
                  }
                  const opacity = state.isDisabled ? 0.5 : 1;
                  const transition = "opacity 300ms";
                  //console.log(state.data);
                  if (state.data.isHidden === 1) {
                    display = "none";
                  }

                  return {
                    ...provided,
                    opacity,
                    transition,
                    backgroundColor,
                    color,
                    display,
                  };
                },
              }}
              isSearchable
              menuPortalTarget={document.body}
              width="50%"
              options={props.state.selInventory.data}
              value={props.state.selInventory.value}
              // isDisabled={props.state.selInventory.readOnly}
              getOptionLabel={(x) => x.name}
              getOptionValue={(x) => x.id}
              onChange={(x, name) => {
                props.state.handleActionChange({
                  target: {
                    value: x,
                    name: "handleInventoryChange",
                  },
                });
              }}
            />
          </InputGroup>
        </Col>
        <Col
         
          className=" d-flex justify-content-end"
        >
          <ButtonGroup className="">
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip id={`actor-tooltip-add`}>ADD INVOICE</Tooltip>}
            >
              <Button
                variant="secondary"
                name="btnAddInvoice"
                className="btnAddInvoice"
                style={{
                  backgroundColor: "var(--REB-tertiary)",
                  fontWeight: "bolder",
                }}
                onClick={(e) => {
                  if (
                    props.state.permissions.includes(
                      "perm-ui-invoicingPage-view-write"
                    )
                  ) {
                    props.state.handleActionChange(e);
                  } else {
                    props.state.dispatch({
                      type: "MODAL_SHOW",
                      payload: {
                        title: "No Permission",
                        desc: `You dont have access to add invoices`,
                        isYesNo: false,
                      },
                    });
                  }
                }}
              >
                <i className="icofont-ui-add"></i> ADD
              </Button>
            </OverlayTrigger>
          </ButtonGroup>
        </Col>
      </Row>
    </>
  );
};

const Invoice = (props) => {
  const [TabKey, setTabKey] = React.useState("all");
  const { width } = useWindowDimensions();
  return (
    <>
      <div style={{ display: "flex", flexFlow: "column", height: "100%" }}>
        <div
          style={{ zIndex: "100" }}
          className="globalContainerStyle mt-2 p-3 animate__animated animate__fadeInLeft"
        >
          <Row className="mb-2" noGutters={true}>
            <Col>
              <InputGroup>
                <select
                  className="form-control actor-selector w-auto"
                  name="InvoiceContentSelect"
                  //value={props.value}
                  onChange={props.state.handleInvoiceContentChange}
                >
                  <option value={1}>Invoice Management</option>
                  {props.userType.includes("Admin") ||
                  props.userType.includes("Manager") ||
                  props.permissions.includes(
                    "perm-ui-invoicingPage-view-write-manage"
                  ) ? (
                    <option value={2}>Invoice Details Management</option>
                  ) : null}
                </select>
              </InputGroup>
            </Col>

            {width > 500 && (
              <Col style={{ textAlignLast: "end" }}>
                <h4>
                  Date:{" "}
                  <u className="actor-date-date">
                    {moment().format("MMMM Do YYYY")}
                  </u>
                </h4>
              </Col>
            )}
          </Row>
          {(() => {
            if (props.state.selectedContent.value === 1)
              return (
                <Invoices
                  handleSearch={props.handleSearch}
                  inputChange={props.inputChange}
                  state={props.state}
                  userType={props.userType}
                />
              );
            if (props.state.selectedContent.value === 2)
              return (
                <InvoiceDetails
                  handleSearch={props.handleSearch}
                  inputChange={props.inputChange}
                  state={props.state}
                  userType={props.userType}
                  invoiceDetailsSelectRef={props.invoiceDetailsSelectRef}
                />
              );
          })()}
        </div>
        {/* Main Table */}
        <style type="text/css">
          {`
  
            .tab-content{
                height: 100%;
                overflow: hidden;
            }
            .tab-pane{
              height: 100%;
            }
            .nav-item{
              height: 30px;
            }
            .nav-link{
              padding: 0px;
              padding-left: 1.2rem;
              padding-right: 1.2rem;
              padding-top: 5px;
              padding-bottom: 5px;
            }
           
          `}
        </style>
        <Tabs
          id="mainInvoicesTab"
          activeKey={TabKey}
          onSelect={(k) => setTabKey(k)}
          className="mt-2"
          style={{}}
        >
          {/* <Tab eventKey="notice" title="Important!"></Tab> */}
          <Tab eventKey="all" title="All Invoices" style={{ flex: 1 }}>
            {props.tablelist}
          </Tab>
          {/* <Tab eventKey="ongoingQuotes" title="Ongoing Quotes"></Tab>
          <Tab eventKey="ongoingDR" title="Ongoing DR"></Tab> */}
        </Tabs>

        <InvoiceContentModal
          state={props.state}
          inputChange={props.inputChange}
          userType={props.userType}
        />
        <InvoiceDetailsModal
          state={props.state}
          inputChange={props.inputChange}
          userType={props.userType}
        />
        <DRModal
          state={props.state}
          inputChange={props.inputChange}
          tableDRItems={props.tableDRItems}
          tableDRItemSelect={props.tableDRItemSelect}
          userType={props.userType}
          customStyles={customStyles}
        />
        <InvoiceModal
          state={props.state}
          inputChange={props.inputChange}
          tableInvoiceContent={props.tableInvoiceContent}
          tableTerms={props.tableTerms}
          customStyles={customStyles}
          tableListDR={props.tableListDR}
          tablePR={props.tablePR}
          tablePRItems={props.tablePRItems}
          tableTransferInv={props.tableTransferInv}
          userType={props.userType}
          PRItemHistoryTable={props.PRItemHistoryTable}
        />
      </div>
    </>
  );
};

export {
  Invoice,
  invoiceColumn,
  Invoices,
  InvoiceDetails,
  invoiceDetailsColumn,
  itemColumn,
  termsColumn,
  deliveryListColumn,
  drColumn,
  selectedDrColumn,
};
