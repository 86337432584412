import React from "react";
// import { NumericFormat } from 'react-number-format';
import pdfTemplate from "../../../components/PdfHeadFoot";
import misc from "../../../functions/misc";
import {
  Text,
  View,
  StyleSheet,
  Font,
  Image,
  Canvas,
} from "@react-pdf/renderer";

import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf",
      fontWeight: 800,
    },
  ],
});
let themeColor = "";
if (process.env.REACT_APP_THEME === "SURFACEPLUS") themeColor = "#5eaaff";
if (process.env.REACT_APP_THEME === "DEMO") themeColor = "#ed5309";
const tableStyles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  tableHeader: {
    textAlign: "center",
    fontSize: 9,
    marginLeft: 2,
    marginRight: 2,
    color: themeColor,
    fontFamily: "Open Sans",
    fontWeight: 800,
  },
  tableCell: {
    marginLeft: 2,
    marginRight: 2,
  },
  tableCellNames: {
    fontSize: 8,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

class PR {
  _itemTable(data, props) {
    let grouped = [];

    if (
      props.chkPDFGroupItems !== null &&
      props.chkPDFGroupItems !== undefined
    ) {
      if (props.chkPDFGroupItems.value) {
        for (let i = 0; i < data.length; i++) {
          const e = data[i];

          let exists = grouped.find((x) => x.itemId === e.itemId);
          //console.log(exists);
          if (!exists) {
            grouped.push(e);
          } else {
            grouped = grouped.map((x) =>
              x.itemId === e.itemId
                ? {
                    ...x,
                    qtyOrdered: x.qtyOrdered + Number(e.qtyOrdered),
                  }
                : x
            );
          }
        }
      } else {
        grouped = data;
      }
    } else {
      grouped = data;
    }

    return (
      <Table data={grouped}>
        <TableHeader
          includeBottomBorder={false}
          includeLeftBorder={false}
          includeRightBorder={false}
          includeTopBorder={false}
          style={[
            {
              border: 0,
            },
          ]}
        >
          {/* <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
                    PR #
                  </TableCell> */}
          <TableCell weighting={0.08} style={[tableStyles.tableHeader]}>
            #
          </TableCell>
          <TableCell weighting={0.85} style={[tableStyles.tableHeader]}>
            ITEM DESCRIPTION
          </TableCell>
          <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
            QTY
          </TableCell>
          {/* <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
            COST
          </TableCell> */}
          {/* <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
            DISCOUNTED
          </TableCell> */}
          {/* <TableCell weighting={0.2} style={[tableStyles.tableHeader]}>
            AMOUNT
          </TableCell> */}
        </TableHeader>
        <TableBody
          includeBottomBorder={true}
          includeLeftBorder={false}
          includeRightBorder={false}
          includeTopBorder={false}
        >
          <DataTableCell
            style={[tableStyles.tableCell]}
            weighting={0.08}
            getContent={(d) => (d.index === null ? "" : d.index)}
          />
          {/* <DataTableCell
                    style={[tableStyles.tableCell]}
                    weighting={0.15}
                    getContent={(d) => (d.uniqueId === null ? "" : d.uniqueId)}
                  /> */}

          {/* <DataTableCell
                    style={[tableStyles.tableCell]}
                    getContent={(d) =>
                      d.material.unit.name === null ? "" : d.material.unit.name
                    }
                  /> */}
          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.85}
            getContent={(d) => {
              let name = "-";
              if (d.itemId === null) {
                if (d.serviceName !== null)
                  name = JSON.parse(d.serviceName).label;
              } else {
                if (d.item.details.length > 0) {
                  const sDat = d.item.details.find((x) => x.type === 1);
                  sDat !== undefined ? (name = sDat.name) : (name = "-");
                }
              }

              return (
                <View style={{ flexDirection: "row" }}>
                  {/* {((x) => {
                    if (d.showImg === 1) {
                      if (typeof d.dispImg !== "undefined") {
                        return (
                          <Image
                            style={[
                              {
                                maxWidth: 50,
                                height: 50,
                                marginTop: 2,
                                marginBottom: 2,
                                marginLeft: 2,
                              },
                            ]}
                            src={d.dispImg.original}
                          />
                        );
                      }
                    }
                  })()} */}

                  <Text style={[{ marginLeft: 5 }]}>{name}</Text>
                </View>
              );
            }}
          />
          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.15}
            getContent={(d) => {
              let name = "-";

              if (d.itemId === null) {
                name = "Service";
              } else {
                if (d.item.details.length > 0) {
                  const sDat = d.item.details.find((x) => x.type === 2);
                  sDat !== undefined ? (name = sDat.name) : (name = "-");
                }
              }

              return (
                <View style={{ flexDirection: "row" }}>
                  <Text style={[{ marginLeft: 5 }]}>
                    {/* <NumberFormat
                      value={Number(d.qtyOrdered === null ? 0 : d.qtyOrdered)}
                      displayType={"text"}
                      suffix={`${name}`}
                      thousandSeparator={true}
                    /> */}
                    {misc.parseCurrencyNoCurrency(
                      Number(d.qtyOrdered === null ? 0 : d.qtyOrdered)
                    )}{" "}
                    {name}
                  </Text>
                </View>
              );

              // return `${d.qtyOrdered} ${name}`;
            }}
          />

          {/* <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.85}
            getContent={(d) => {
              return (
                <View style={{ flexDirection: "row" }}>
                  <Text style={[{ marginLeft: 5 }]}>
                  
                    {misc.parseCurrencyNoCurrency(
                      Number(d.price === null ? 0 : d.price).toFixed(2)
                    )}
                  </Text>
                </View>
              );
            }}
          /> */}
          {/* <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.2}
            getContent={(d) => {
              return (
                <View style={{ flexDirection: "row" }}>
                  <Text style={[{ marginLeft: 5 }]}>
                  
                    {misc.parseCurrencyNoCurrency(
                      Number(d.price * d.qtyOrdered).toFixed(2)
                    )}
                  </Text>
                </View>
              );
              // return Number(d.price * d.qtyOrdered).toFixed(2);
            }}
            //   getContent={(d) => {
            //     if (d.budget === null) {
            //       return "";
            //     } else {
            //       return new Intl.NumberFormat("en-US").format(d.qty * d.cost);
            //     }
            //   }}
          /> */}
        </TableBody>
      </Table>
    );
  }

  _termsSignature(props, isVerif, approvedBy, isSig, incharge) {
    return (
      <>
        <View>
          <View
            style={[
              {
                marginTop: 5,

                paddingLeft: 40,
                paddingRight: 40,
              },
            ]}
          >
            <View
              style={[
                {
                  marginTop: 10,
                  flex: 1,
                  flexDirection: "row",
                },
              ]}
            >
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      color: themeColor,
                      fontFamily: "Open Sans",
                      fontWeight: 800,
                      textAlign: "center",
                    },
                  ]}
                >
                  Prepared by:
                </Text>
              </View>
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      color: themeColor,
                      fontFamily: "Open Sans",
                      fontWeight: 800,
                      textAlign: "center",
                    },
                  ]}
                >
                  Approved By:
                </Text>
              </View>
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      color: themeColor,
                      fontFamily: "Open Sans",
                      fontWeight: 800,
                      textAlign: "center",
                    },
                  ]}
                >
                  Checked By:
                </Text>
              </View>
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      color: themeColor,
                      fontFamily: "Open Sans",
                      fontWeight: 800,
                      textAlign: "center",
                    },
                  ]}
                >
                  Released By:
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View>
          <View
            style={[
              {
                marginTop: 5,
                marginBottom: 25,
                paddingLeft: 40,
                paddingRight: 40,
              },
            ]}
          >
            <View
              style={[
                {
                  marginTop: 10,
                  flex: 1,
                  flexDirection: "row",
                },
              ]}
            >
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      textAlign: "center",
                    },
                  ]}
                >
                  {" "}
                  {incharge !== null && incharge !== undefined
                    ? incharge.name
                    : "-"}
                </Text>
                {(() => {
                  if (incharge !== null && incharge !== undefined) {
                    if (incharge.uploads) {
                      if (
                        Number(isSig) === 1 &&
                        Number(incharge.uploads.length) > 0
                      ) {
                        return (
                          <Image
                            style={[
                              {
                                position: "absolute",
                                width: 150,
                                marginTop: -25,
                              },
                            ]}
                            src={{
                              uri: `${process.env.REACT_APP_API_URL}global/getFile?uniqueId=${incharge.uniqueId}&filename=${incharge.uploads[0].filename}`,
                              method: "GET",

                              headers: {
                                Authorization:
                                  "Bearer " + localStorage.getItem("LUT"),
                              },
                              body: "",
                            }}
                          />
                        );
                      }
                    }
                  }
                })()}
              </View>
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      textAlign: "center",
                    },
                  ]}
                >
                  {approvedBy !== null && approvedBy !== undefined
                    ? approvedBy.name
                    : "-"}
                </Text>
                {(() => {
                  if (approvedBy !== null && approvedBy !== undefined) {
                    if (approvedBy.uploads) {
                      if (
                        Number(isSig) === 1 &&
                        Number(approvedBy.uploads.length) > 0
                      ) {
                        return (
                          <Image
                            style={[
                              {
                                position: "absolute",
                                width: 150,
                                marginTop: -25,
                              },
                            ]}
                            src={{
                              uri: `${process.env.REACT_APP_API_URL}global/getFile?uniqueId=${approvedBy.uniqueId}&filename=${approvedBy.uploads[0].filename}`,
                              method: "GET",

                              headers: {
                                Authorization:
                                  "Bearer " + localStorage.getItem("LUT"),
                              },
                              body: "",
                            }}
                          />
                        );
                      }
                    }
                  }
                })()}
              </View>
              <View style={[{ flex: 1 }]}></View>
              <View style={[{ flex: 1 }]}></View>
            </View>
          </View>
        </View>
        <View>
          <View
            style={[
              {
                marginTop: -30,

                paddingLeft: 40,
                paddingRight: 40,
              },
            ]}
          >
            <View
              style={[
                {
                  flex: 1,
                  flexDirection: "row",
                },
              ]}
            >
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 8,
                      textAlign: "center",
                    },
                  ]}
                >
                  {"________________"}
                </Text>
              </View>
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 8,
                      textAlign: "center",
                    },
                  ]}
                >
                  {"________________"}
                </Text>
              </View>
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 8,
                      textAlign: "center",
                    },
                  ]}
                >
                  {"________________"}
                </Text>
              </View>
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 8,
                      textAlign: "center",
                    },
                  ]}
                >
                  {"________________"}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </>
    );
  }

  PRPDF(props, isVerif, approvedBy, isSig, incharge) {
    //console.log(props.pdfGroupTable[0]);

    let _content = [];

    const invoiceStatusName = !isVerif
      ? props.selectedRowInvoice?.invoiceStatus?.name
      : props.selectedRowVerification?.invoiceStatus?.name;

    const invName = !isVerif
      ? props.selectedRowInvoice.invoiceType.name === "Purchase Order"
        ? "Purchase Order"
        : "Purchase Request"
      : props.selectedRowVerification.invoiceType.name === "Purchase Order"
      ? "Purchase Order"
      : "Purchase Request";

    _content.push(
      <>
        <View
          style={[
            {
              // flex: 1,
              flexDirection: "row",
              paddingLeft: 40,
              paddingRight: 40,

              marginBottom: 15,
            },
          ]}
        >
          <View style={[{ flex: 1 }]}>
            <Text
              style={[
                {
                  textAlign: "left",
                  color: themeColor,
                  fontSize: 10,
                  fontWeight: "bold",
                },
              ]}
            >
              SUPPLIER:
            </Text>
            <Text style={[{ textAlign: "left", fontSize: 8, marginTop: 10 }]}>
              {props.selDetailSupplier?.value?.company}
            </Text>
          </View>
          <View style={[{ flex: 1 }]}>
            <Text
              style={[
                {
                  textAlign: "left",
                  color: themeColor,
                  fontSize: 10,
                  fontWeight: "bold",
                },
              ]}
            >
              SUPPLIER ADDRESS:
            </Text>
            <Text style={[{ textAlign: "left", fontSize: 8, marginTop: 10 }]}>
              {props.selDetailSupplier?.value?.address}
            </Text>
          </View>
          <View style={[{ flex: 1 }]}>
            <Text
              style={[
                {
                  textAlign: "left",
                  color: themeColor,
                  fontSize: 10,
                  fontWeight: "bold",
                },
              ]}
            >
              DELIVER TO:
            </Text>
            <View
              style={[
                {
                  flexDirection: "row",
                },
              ]}
            >
              <Text style={[{ fontSize: 7 }]}>
                {props.txtShippingAddress?.value}
              </Text>
            </View>
            {/* <View
              style={[
                {
                  flexDirection: "row",
                  marginTop: 10,
                },
              ]}
            >
              <Text style={[{ fontSize: 8, fontWeight: "bold" }]}>
                Surface+
              </Text>
            </View>
            <View
              style={[
                {
                  flexDirection: "row",
                },
              ]}
            >
              <Text style={[{ fontSize: 8 }]}>22 Commission Civil, Jaro,</Text>
            </View>
            <View
              style={[
                {
                  flexDirection: "row",
                },
              ]}
            >
              <Text style={[{ fontSize: 8 }]}>Iloilo City, 5000</Text>
            </View>
            <View
              style={[
                {
                  flexDirection: "row",
                },
              ]}
            >
              <Text style={[{ fontSize: 8 }]}>(63) 917 659 1188</Text>
            </View> */}
          </View>
        </View>

        <Canvas
          fixed
          style={[
            {
              width: 600,
              height: 5,
              //top: 170,
              top: invName === "Purchase Order" ? 166 : 156,
              position: "absolute",
            },
          ]}
          paint={(painter) => {
            painter
              .strokeColor(themeColor)
              .lineWidth(5)
              .moveTo(40, 0)
              .lineTo(555, 0)
              .stroke();
          }}
        />

        <View
          style={[
            {
              //height: 325,
              paddingLeft: 20,
              paddingRight: 20,
            },
          ]}
        >
          {((x) => {
            //console.log(props.pdfGroupTable);
            const _e = props.pdfGroupTable.result[0];
            if (_e.length < 20) {
              return (
                <>
                  <View
                    style={[
                      {
                        paddingTop: 5,
                        paddingLeft: 20,
                        paddingRight: 20,
                      },
                    ]}
                  >
                    {this._itemTable(_e, props)}
                  </View>
                  {/* <View
                    style={[
                      {
                        paddingLeft: 20,
                        paddingRight: 20,
                        marginTop: 10,
                      },
                    ]}
                  >
                    <Text
                      style={[
                        {
                          textAlign: "right",
                          marginTop: 10,
                          color: "#0f4c75",
                        },
                      ]}
                    >
                      GRAND TOTAL:{" "}
                    
                      {misc.parseCurrencyNoCurrency(props.grandTotal)}
                    </Text>
                  </View> */}
                  {this._termsSignature(
                    props,
                    isVerif,
                    approvedBy,
                    isSig,
                    incharge
                  )}
                </>
              );
            } else {
              return <>{this._itemTable(_e, props)}</>;
            }
          })()}
        </View>
      </>
    );

    for (let i = 1; i < props.pdfGroupTable.result.length; i++) {
      const _e = props.pdfGroupTable.result[i];

      if (_e.length > 6) {
        _content.push(
          <View
            style={[
              {
                paddingLeft: 20,
                paddingRight: 20,
              },
            ]}
          >
            {this._itemTable(_e, props)}
          </View>
        );
      } else {
        _content.push(
          <>
            {/* <View
              style={[
                {
                  paddingLeft: 20,
                  paddingRight: 20,
                },
              ]}
            >
              {this._itemTable(_e, props)}
              <Text
                style={[
                  {
                    textAlign: "right",
                    marginTop: 10,
                    color: "#0f4c75",
                  },
                ]}
              >
                GRAND TOTAL:{" "}
                
                {misc.parseCurrencyNoCurrency(props.grandTotal)}
              </Text>
            </View> */}

            {this._termsSignature(props, isVerif, approvedBy, isSig, incharge)}
          </>
        );
      }
    }
    // const invName = !isVerif
    //   ? props.selectedRowInvoice.invoiceType.name === "Purchase Order"
    //     ? "Purchase Order"
    //     : "Purchase Request"
    //   : props.selectedRowVerification.invoiceType.name === "Purchase Order"
    //   ? "Purchase Order"
    //   : "Purchase Request";

    const copy1 = pdfTemplate.TemplateHeaderFooter(
      _content,
      invName,
      !isVerif
        ? props.selectedRowInvoice.uniqueId
        : props.selectedRowVerification.uniqueId,
      true,
      undefined,
      undefined,
      undefined,
      (invoiceStatusName === "New" ||
        invoiceStatusName === "Admin Denied" ||
        invoiceStatusName === "Pending Admin Approval") &&
        true
    );

    return [copy1];
  }
}
export default new PR();
// let QuotePDF = (props) => (

// );
//export { QuotePDF };
