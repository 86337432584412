import React from "react";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import InputGroup from "react-bootstrap/InputGroup";
// import FormControl from "react-bootstrap/FormControl";
import misc from "../../functions/misc";
//import "../css/Profile.css";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class ProfileProcess extends React.Component {
  constructor(props) {
    super(props);

    this.inputChange = this.inputChange.bind(this);

    this.state = {};
  }

  componentDidMount() {
    //console.log(this.props.user);
  }

  inputChange = (e) => {
    this.setState({
      [e.target.name]: {
        value: e.target.value,
        placeholder: e.target.placeholder,
        className: e.target.className,
      },
    });
  };

  render() {
    let userTypes = [];
    //console.log(this.props);
    if (this.props?.user) {
      this.props.user.types?.forEach((e) => {
        userTypes.push(e.name);
      });
    }

    return (
      <div className="col-lg-12">
        <div className="profile-card-4 z-depth-3" style={{ borderRadius: 0 }}>
          <div className="card" style={{ borderRadius: 0 }}>
            <div
              className="card-body text-center  "
              style={{ backgroundColor: "var(--REB-primary)" }}
            >
              <div className="user-box">
                {/* <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="user avatar"> */}
              </div>
              <h5 className="mb-1 text-white">{`${
                this.props.user.lastName ? this.props.user.lastName : "-"
              }, ${
                this.props.user.firstName ? this.props.user.firstName : "-"
              } ${
                this.props.user.middleName ? this.props.user.middleName : "-"
              }`}</h5>
              <h6 className="text-light">{`${userTypes.toString()}`}</h6>
            </div>
            <div className="card-body">
              <ul className="list-group shadow-none">
                <li className="list-group-item">
                  <div className="list-icon">
                    <i className="fa fa-phone-square"></i>
                  </div>
                  <div className="list-details">
                    <small>Username: </small>
                    <span>{`   ${this.props.user.username}`}</span>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="list-icon">
                    <i className="fa fa-envelope"></i>
                  </div>
                  <div className="list-details">
                    {" "}
                    <small>Email Address</small>
                    <span>{`   ${this.props.user.email}`}</span>
                  </div>
                </li>
              </ul>
              <div className="row text-center mt-4">
                <div className="col p-2">
                  <h4 className="mb-1 line-height-5">{`${misc.parseAPIDateTimeToShortDate(
                    misc.parseAPIDateTime(this.props.user.lastLogin)
                  )}`}</h4>
                  <small className="mb-0 font-weight-bold">Last Login</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      // <Row noGutters={true}>
      //   <Col>
      //     <InputGroup className="mb-3">
      //       <InputGroup.Prepend>
      //         <InputGroup.Text>Full Name</InputGroup.Text>
      //         <FormControl
      //           value={`${
      //             this.props.user.lastName === null
      //               ? "-"
      //               : this.props.user.lastName
      //           }, ${
      //             this.props.user.firstName === null
      //               ? "-"
      //               : this.props.user.firstName
      //           } ${
      //             this.props.user.middleName === null
      //               ? "-"
      //               : this.props.user.middleName
      //           }`}
      //         />
      //       </InputGroup.Prepend>{" "}
      //     </InputGroup>
      //     <InputGroup className="mb-3">
      //       <InputGroup.Prepend>
      //         <InputGroup.Text>Username</InputGroup.Text>
      //         <FormControl value={`${this.props.user.username}`} />
      //       </InputGroup.Prepend>{" "}
      //     </InputGroup>
      //     <InputGroup className="mb-3">
      //       <InputGroup.Prepend>
      //         <InputGroup.Text>Email</InputGroup.Text>
      //         <FormControl value={`${this.props.user.email}`} />
      //       </InputGroup.Prepend>{" "}
      //     </InputGroup>
      //     <InputGroup className="mb-3">
      //       <InputGroup.Prepend>
      //         <InputGroup.Text>Designation</InputGroup.Text>
      //         <FormControl value={`${userTypes.toString()}`} />
      //       </InputGroup.Prepend>{" "}
      //     </InputGroup>
      //   </Col>
      // </Row>
    );
  }
}
const mapStateToProps = (state) => {
  return { user: state.apiData.userData };
};
export default connect(mapStateToProps)(withRouter(ProfileProcess));
