class VerificationAPI {
  getOrdersForVerification = async (dispatch, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `order/getOrdersForVerification`,
        loadingMsg: "Fetching Pending Verifications",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );
    if (cb) await cb();
    return data;
  };
  modifyOrderContentDiscount = async (dispatch, id, discount, price, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "put",
        data: { id: id, discount: discount, price: price },
        payload: "order/modifyOrderContentDiscount",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );
    if (cb) await cb();
    return data;
  };
  modifyOrderContentMarkup = async (dispatch, id, markup, price, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "put",
        data: { id: id, markup: markup, price: price },
        payload: "order/modifyOrderContentMarkup",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );
    if (cb) await cb();
    return data;
  };
}
export default new VerificationAPI();
