import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
//import ButtonGroup from "react-bootstrap/ButtonGroup";
import { NumericFormat } from "react-number-format";
import misc from "../../functions/misc";
import NameSelect from "../../components/CustomDrop";
import UnitSelect from "../../components/CustomDrop";
import BrandSelect from "../../components/CustomDrop";
import TypeSelect from "../../components/CustomDrop";
import StatusSelect from "../../components/CustomDrop";
//import GroupSelect from "../../components/CustomDrop";
import { SELECTED_OPERATION } from "../../constants";

import ImageGallery from "react-image-gallery";
import "./itemModal.css";
import { CopyItemComponent } from "./CopyItemComponent";

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as BarTooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  AreaChart,
  Area,
} from "recharts";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="p-1"
        style={{
          backgroundColor: "var(--REB-primary)",
          borderRadius: "5px",
          color: "var(--REB-white)",
        }}
      >
        <p className="m-0 p-0">{`Date: ${misc.parseAPIDateTimeToShortDateTime(
          payload[0].payload.dateAdded
        )}`}</p>
        <p className="m-0 p-0">
          <NumericFormat
            value={payload[0].payload.originalValue}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"Previous Price: ₱ "}
            fixedDecimalScale
            decimalScale={2}
          />
        </p>
        <p className="m-0 p-0">
          <NumericFormat
            value={payload[0].payload.newValue}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"Marked Up Price: ₱ "}
            fixedDecimalScale
            decimalScale={2}
          />
        </p>

        <p className="m-0 p-0">{`Modified By: ${payload[0].payload.createdBy.lastName}`}</p>
      </div>
    );
  }

  return null;
};
const CustomXTick = ({ x, y, payload }) => {
  //console.log(misc.parseAPIDateTimeToShortDate(payload.value));
  // if (payload.value) {
  //   return (
  //     <p style={{ color: "black" }}>
  //       {/* {misc.parseAPIDateTimeToShortDate(payload.value)} */}
  //       asadad
  //     </p>
  //   );
  // }

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} fill="#666">
        <tspan textAnchor="middle" x="0">
          {misc.parseAPIDateTimeToShortDate(payload.value)}
        </tspan>
        {/* <tspan textAnchor="middle" x="0" dy="20">
          Line 2
        </tspan>
        <tspan textAnchor="middle" x="0" dy="40">
          Line 3
        </tspan> */}
      </text>
    </g>
  );
};

let TxtQty = (props) => (
  <Row className="mb-3 pl-2 pr-2" noGutters>
    <Col sm={12} xs={12} md lg className="pl-2 pr-2">
      <Form.Label
        className="font-weight-bold"
        style={{ color: "var(--REB-black)" }}
      >
        <span className="required-field">* </span>
        Qty
        {/* {`Qty ( QTY with Display: ${
          Number(props.state.txtQty.value) +
          Number(props.state.txtDisplay.value)
        })`} */}
      </Form.Label>
      <FormControl
        as={NumericFormat}
        inputMode="numeric"
        thousandSeparator={true}
        suffix={" " + props.state.selDetailUnit.value.name}
        placeholder="Qty"
        name="txtQty"
        value={props.state.txtQty.value}
        onValueChange={(x) => {
          props.state.inputChange({
            target: {
              value: Number(x.value),
              name: "txtQty",
              placeholder: props.state.txtQty.placeholder,
              className: props.state.txtQty.className,
            },
          });
        }}
        className={props.state.txtQty.className + " css-actorInput mb-2"}
        readOnly={props.state.txtQty.readOnly}
        required
      />
    </Col>
    <Col sm={12} xs={12} md lg className="pl-2 pr-2">
      <Form.Label
        className="font-weight-bold"
        style={{ color: "var(--REB-black)" }}
      >
        <span className="required-field"> </span>
        Display
      </Form.Label>
      <InputGroup>
        <FormControl
          as={NumericFormat}
          inputMode="numeric"
          thousandSeparator={true}
          suffix={" " + props.state.selDetailUnit.value.name}
          placeholder="Display"
          name="txtDisplay"
          value={props.state.txtDisplay.value}
          onValueChange={(x) => {
            props.state.inputChange({
              target: {
                value: Number(x.value),
                name: "txtDisplay",
                placeholder: props.state.txtDisplay.placeholder,
                className: props.state.txtDisplay.className,
              },
            });
          }}
          className={props.state.txtDisplay.className + " css-actorInput "}
          readOnly={true}
          //required
        />
        <InputGroup.Prepend>
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip id={`actor-tooltip-add`}>
                Adds 1 qty to display. This will subtract from total qty of this
                item
              </Tooltip>
            }
          >
            <Button
              variant="secondary"
              name="btnAddDisplayItem"
              disabled={props.state.txtDisplay.readOnly}
              style={{
                backgroundColor: "var(--REB-green)",
                // fontSize: "10px",
              }}
              className="icofont-ui-add"
              onClick={(e) => {
                props.state.handleActionChange({
                  target: {
                    name: "btnAddDisplayItem",
                    value: e,
                    service: true,
                  },
                });
              }}
            ></Button>
          </OverlayTrigger>
        </InputGroup.Prepend>
        <InputGroup.Prepend>
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip id={`actor-tooltip-add`}>
                Subtracts 1 qty to display. This will Add from total qty of this
                item
              </Tooltip>
            }
          >
            <Button
              variant="secondary"
              name="btnSubtractDisplayItem"
              disabled={props.state.txtDisplay.readOnly}
              style={{
                backgroundColor: "var(--REB-warning)",
                borderTopRightRadius: "5px",
                borderBottomRightRadius: "5px",
                // fontSize: "10px",
              }}
              className="icofont-minus"
              onClick={(e) => {
                props.state.handleActionChange({
                  target: {
                    name: "btnSubtractDisplayItem",
                    value: e,
                    service: true,
                  },
                });
              }}
            ></Button>
          </OverlayTrigger>
        </InputGroup.Prepend>
      </InputGroup>
    </Col>
    <Col sm={12} xs={12} md lg className="pl-2 pr-2">
      <UnitSelect
        state={props.state}
        data={props.state.selDetailUnit.data}
        name="selDetailUnit"
        //isLoading={props.state.suppliers.isLoading}
        selected={props.state.selDetailUnit.value}
        //tabIndex={7}
        lblHidden={false}
        refreshData={(event) => {
          props.state.refreshItemDetail("selDetailUnit", 2);
        }}
        detType={2}
        addUrl="inventory/createItem"
        editUrl="inventory/updateItem"
        deleteUrl="inventory/deleteItemDetail"
        componentName="Unit"
        dataStructure="itemDetailsInventory"
        componentType=""
        //optionLabel="name"
        handler={props.state.inputChange}
        disabled={props.state.selDetailUnit.readOnly}
        modifyRequiredPermission="perm-ui-inventoryPage-view-write-manage"
        deleteRequiredPermission="perm-ui-inventoryPage-view-write-manage-admin"
        userPermissions={props.state.permissions}
      />
    </Col>
    {/* <Col className="pl-2 pr-2">
      <TxtPrice state={props.state} />
    </Col> */}
    {/* <Col className="pl-2 pr-2">
      <TxtShippingCost state={props.state} />
    </Col>
    <Col className="pl-2 pr-2">
      <TxtGrossPrice state={props.state} />
    </Col>
    <Col className="pl-2 pr-2">
      <TxtNetPrice state={props.state} />
    </Col>
    <Col className="pl-2 pr-2">
      <TxtDiscountMax state={props.state} />
    </Col> */}
  </Row>
  // <Form.Label
  //   className="font-weight-bold"
  //   style={{ color: "var(--REB-black)" }}
  // >
  //   <span className="required-field">* </span>
  //   Qty
  // </Form.Label>
  // <InputGroup>

  // </InputGroup>
);

function CostPrice(props) {
  //const [liveCost, setliveCost] = useState(0);
  return (
    <>
      <Row className="mb-2 pl-2 pr-2" noGutters>
        {props.state.userType.includes("Admin") ||
        props.state.userType.includes("Purchaser") ? (
          <>
            <Col sm={12} xs={12} md lg className="pl-2 pr-2">
              <Form.Label
                className="font-weight-bold"
                style={{ color: "var(--REB-black)" }}
              >
                Handling Cost
              </Form.Label>

              <FormControl
                as={NumericFormat}
                inputMode="numeric"
                thousandSeparator={true}
                prefix={"₱ "}
                style={{}}
                placeholder="Handling Cost"
                name="txtHandlingCost"
                value={props.state.txtHandlingCost.value}
                onValueChange={(x) => {
                  props.state.inputChange({
                    target: {
                      value: Number(x.value),
                      name: "txtHandlingCost",
                      placeholder: props.state.txtHandlingCost.placeholder,
                      className: props.state.txtHandlingCost.className,
                    },
                  });
                  props.state.handleActionChange({
                    target: {
                      name: "txtCogs",
                    },
                  });
                }}
                className={
                  props.state.txtHandlingCost.className + " css-actorInput"
                }
                readOnly={props.state.txtHandlingCost.readOnly}
              />
            </Col>
            <Col sm={12} xs={12} md lg className="pl-2 pr-2">
              <Form.Label
                className="font-weight-bold"
                style={{ color: "var(--REB-black)" }}
              >
                Shipping Cost
              </Form.Label>
              <FormControl
                as={NumericFormat}
                inputMode="numeric"
                thousandSeparator={true}
                prefix={"₱ "}
                style={{}}
                placeholder="Shipping Cost"
                name="txtShippingCost"
                value={props.state.txtShippingCost.value}
                onValueChange={(x) => {
                  //console.log(x.value);
                  props.state.inputChange({
                    target: {
                      value: Number(x.value),
                      name: "txtShippingCost",
                      placeholder: props.state.txtShippingCost.placeholder,
                      className: props.state.txtShippingCost.className,
                    },
                  });
                  props.state.handleActionChange({
                    target: {
                      name: "txtCogs",
                    },
                  });
                }}
                className={
                  props.state.txtShippingCost.className + " css-actorInput"
                }
                readOnly={props.state.txtShippingCost.readOnly}
              />
            </Col>
            <Col sm={12} xs={12} md lg className="pl-2 pr-1">
              <Form.Label
                className="font-weight-bold"
                style={{ color: "var(--REB-black)" }}
              >
                Cost
              </Form.Label>
              <FormControl
                as={NumericFormat}
                inputMode="numeric"
                thousandSeparator={true}
                prefix={"₱ "}
                style={{}}
                placeholder="Cost"
                name="txtNetPrice"
                value={props.state.txtNetPrice.value}
                onValueChange={(x) => {
                  //console.log(x.value);
                  props.state.inputChange({
                    target: {
                      value: Number(x.value),
                      name: "txtNetPrice",
                      placeholder: props.state.txtNetPrice.placeholder,
                      className: props.state.txtNetPrice.className,
                    },
                  });
                  props.state.handleActionChange({
                    target: {
                      name: "txtCogs",
                    },
                  });
                }}
                className={
                  props.state.txtNetPrice.className + " css-actorInput"
                }
                readOnly={props.state.txtNetPrice.readOnly}
              />
            </Col>
            <Col sm={2} xs={2} style={{ alignSelf: "flex-end" }} md lg>
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Tooltip id={`actor-tooltip-add`}>
                    Totals Handling, Shipping & Cost to COGS
                  </Tooltip>
                }
              >
                <Button
                  name="btnTotalCogs"
                  disabled={props.state.txtNetPrice.readOnly}
                  style={{
                    backgroundColor: "var(--REB-green)",
                    //border: "1px solid var(--REB-green) !important",
                    // fontSize: "10px",
                  }}
                  className="icofont-ui-calculator btnCalc"
                  onClick={(e) => {
                    props.state.handleActionChange({
                      target: {
                        name: "btnTotalCogs",
                        value: e,
                        service: true,
                      },
                    });
                  }}
                ></Button>
              </OverlayTrigger>
            </Col>
            <Col sm={10} xs={10} md lg className="pl-2 pr-2">
              <Form.Label
                className="font-weight-bold"
                style={{ color: "var(--REB-black)" }}
              >
                COGS
              </Form.Label>
              <InputGroup>
                <FormControl
                  as={NumericFormat}
                  inputMode="numeric"
                  thousandSeparator={true}
                  prefix={"₱ "}
                  style={{}}
                  placeholder="COGS"
                  name="txtCOGS"
                  value={props.state.txtCOGS.value}
                  onValueChange={(x) => {
                    props.state.inputChange({
                      target: {
                        value: Number(x.value),
                        name: "txtCOGS",
                        placeholder: props.state.txtCOGS.placeholder,
                        className: props.state.txtCOGS.className,
                      },
                    });
                  }}
                  className={props.state.txtCOGS.className + " css-actorInput"}
                  readOnly={props.state.txtCOGS.readOnly}
                />
              </InputGroup>
            </Col>
          </>
        ) : null}

        <Col sm={12} xs={12} md lg className="pl-2 pr-2">
          <Form.Label
            className="font-weight-bold"
            style={{ color: "var(--REB-black)" }}
          >
            <span className="required-field">* </span>
            SRP
          </Form.Label>
          <FormControl
            as={NumericFormat}
            inputMode="numeric"
            thousandSeparator={true}
            prefix={"₱ "}
            style={{}}
            placeholder="Selling Price"
            name="txtPrice"
            value={props.state.txtPrice.value}
            onValueChange={(x) => {
              props.state.inputChange({
                target: {
                  value: Number(x.value),
                  name: "txtPrice",
                  placeholder: props.state.txtPrice.placeholder,
                  className: props.state.txtPrice.className,
                },
              });
            }}
            className={props.state.txtPrice.className + " css-actorInput"}
            readOnly={props.state.txtPrice.readOnly}
            required
          />
        </Col>
        {/* <Col className="pl-2 pr-2">
          <Form.Label
            className="font-weight-bold"
            style={{ color: "var(--REB-black)" }}
          >
            <span className="required-field">* </span>
            Discount Maximum
          </Form.Label>
          <FormControl
            as={NumberFormat}
            inputMode="numeric"
            thousandSeparator={true}
            prefix={"%"}
            style={{}}
            placeholder="Discount Max"
            name="txtDiscountMax"
            value={props.state.txtDiscountMax.value}
            onValueChange={(x) => {
              props.state.inputChange({
                target: {
                  value: x.value,
                  name: "txtDiscountMax",
                  placeholder: props.state.txtDiscountMax.placeholder,
                  className: props.state.txtDiscountMax.className,
                },
              });
            }}
            className={props.state.txtDiscountMax.className + " css-actorInput"}
            readOnly={props.state.txtDiscountMax.readOnly}
            required
          />
        </Col> */}
      </Row>
    </>
  );
}

let TxtPOSItemName = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      POS Item Name
    </Form.Label>
    <FormControl
      type="text"
      placeholder="POS Item Name"
      name="txtPOSItemName"
      value={props.state.txtPOSItemName.value}
      onChange={props.state.inputChange}
      className={props.state.txtPOSItemName.className + " css-actorInput mb-2"}
      readOnly={props.state.txtPOSItemName.readOnly}
    />
  </>
);

let TxtPOSItemNameShort = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      POS Item Name Short
    </Form.Label>
    <FormControl
      type="text"
      placeholder="POS Item Name Short"
      name="txtPOSItemNameShort"
      value={props.state.txtPOSItemNameShort.value}
      onChange={props.state.inputChange}
      className={
        props.state.txtPOSItemNameShort.className + " css-actorInput mb-2"
      }
      readOnly={props.state.txtPOSItemNameShort.readOnly}
    />
  </>
);

let TxtPOSItemDesc = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      POS Item Description
    </Form.Label>
    <FormControl
      type="text"
      placeholder="POS Item Description"
      name="txtPOSItemDesc"
      value={props.state.txtPOSItemDesc.value}
      onChange={props.state.inputChange}
      className={props.state.txtPOSItemDesc.className + " css-actorInput mb-2"}
      readOnly={props.state.txtPOSItemDesc.readOnly}
    />
  </>
);

let TxtPOSItemCode = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      POS Item Code
    </Form.Label>
    <FormControl
      type="text"
      placeholder="POS Item Code"
      name="txtPOSItemCode"
      value={props.state.txtPOSItemCode.value}
      onChange={props.state.inputChange}
      className={props.state.txtPOSItemCode.className + " css-actorInput mb-2"}
      readOnly={props.state.txtPOSItemCode.readOnly}
    />
  </>
);

let TxtSKU = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      SKU
    </Form.Label>
    <FormControl
      type="text"
      //style={{ textTransform: "uppercase" }}
      placeholder="SKU"
      name="txtSKU"
      value={props.state.txtSKU.value}
      onChange={props.state.inputChange}
      className={props.state.txtSKU.className + " css-actorInput mb-2"}
      readOnly={props.state.txtSKU.readOnly}
    />
  </>
);
let TxtBarcode = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      Barcode
    </Form.Label>
    <FormControl
      type="text"
      //style={{ textTransform: "uppercase" }}
      placeholder="Barcode"
      name="txtBarcode"
      value={props.state.txtBarcode.value}
      onChange={props.state.inputChange}
      className={props.state.txtBarcode.className + " css-actorInput mb-2"}
      readOnly={props.state.txtBarcode.readOnly}
    />
  </>
);

let TxtDescription = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      Description
    </Form.Label>
    <FormControl
      type="text"
      //style={{ textTransform: "uppercase" }}
      placeholder="Description"
      name="txtDesc"
      value={props.state.txtDesc.value}
      onChange={props.state.inputChange}
      className={props.state.txtDesc.className + " css-actorInput mb-2"}
      readOnly={props.state.txtDesc.readOnly}
    />
  </>
);

// let TxtSerial = (props) => (
//   <>
//     <Form.Label
//       className="font-weight-bold"
//       style={{ color: "var(--REB-black)" }}
//     >
//       Serial #
//     </Form.Label>
//     <FormControl
//       type="text"
//       //style={{ textTransform: "uppercase" }}
//       placeholder="Serial #"
//       name="txtSerial"
//       value={props.state.txtSerial.value}
//       onChange={props.state.inputChange}
//       className={props.state.txtSerial.className + " css-actorInput mb-2"}
//       readOnly={props.state.txtSerial.readOnly}
//     />
//   </>
// );
let TxtCode = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      Code
    </Form.Label>
    <FormControl
      type="text"
      //style={{ textTransform: "uppercase" }}
      placeholder="Code"
      name="txtCode"
      value={props.state.txtCode.value}
      onChange={props.state.inputChange}
      className={props.state.txtCode.className + " css-actorInput mb-2"}
      readOnly={props.state.txtCode.readOnly}
    />
  </>
);

let TxtNotes = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      Notes
    </Form.Label>
    <FormControl
      as="textarea"
      //style={{ textTransform: "uppercase" }}
      placeholder="Notes"
      name="txtNotes"
      value={props.state.txtNotes.value}
      onChange={props.state.inputChange}
      className={props.state.txtNotes.className + " css-actorInput mb-2"}
      readOnly={props.state.txtNotes.readOnly}
    />
  </>
);

let TxtRestockWarn = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      Restock Warning
    </Form.Label>
    <FormControl
      as={NumericFormat}
      inputMode="numeric"
      thousandSeparator={true}
      suffix={" " + props.state.selDetailUnit.value.name}
      style={{}}
      placeholder="Restock Warning"
      name="txtRestockWarn"
      value={props.state.txtRestockWarn.value}
      onValueChange={(x) => {
        props.state.inputChange({
          target: {
            value: Number(x.value),
            name: "txtRestockWarn",
            placeholder: props.state.txtRestockWarn.placeholder,
            className: props.state.txtRestockWarn.className,
          },
        });
      }}
      className={props.state.txtRestockWarn.className + " css-actorInput"}
      readOnly={props.state.txtRestockWarn.readOnly}
    />
    {/* <FormControl
      type="text"
      style={{ textTransform: "uppercase" }}
      placeholder="Restock Warning"
      name="txtRestockWarn"
      value={props.state.txtRestockWarn.value}
      onChange={props.state.inputChange}
      className={props.state.txtRestockWarn.className + " css-actorInput mb-2"}
      readOnly={props.state.txtRestockWarn.readOnly}
      required
    /> */}
  </>
);

function TxtFileUpload(props) {
  //const [btnSave, setbtnSave] = useState(true);
  const [isAdd, setIsAdd] = useState(false);
  //const [btnDel, setBtnDel] = useState(false);

  return (
    <InputGroup className="mb-2">
      <FormControl
        type="file"
        custom
        data-browse="Browse"
        className="inputFileUpload mr-2 ml-2"
        hidden={!isAdd}
        onChange={(e) => {
          props.state.fileUpload.handler(e);
          //console.log(Array.from(e.target.files));

          if (Array.from(e.target.files).length === 0) {
            //setbtnSave(true);
          } else {
            //setbtnSave(false);
          }
        }}
      />

      <ButtonGroup className="w-100">
        <OverlayTrigger
          placement={"top"}
          overlay={<Tooltip id={`order-tooltip-add`}>Add Image</Tooltip>}
        >
          <Button
            // variant="secondary"
            //name=""
            //size="md"
            style={{ backgroundColor: "var(--REB-green)", borderRadius: "5px" }}
            className="ml-2 mr-2 "
            onClick={(e) => {
              if (
                props.state.permissions.includes(
                  "perm-ui-inventoryPage-view-write"
                )
              ) {
                setIsAdd(true);
              } else {
                props.state.dispatch({
                  type: "MODAL_SHOW",
                  payload: {
                    title: "No Permission",
                    desc: `You dont have access to modify / add item images`,
                    isYesNo: false,
                  },
                });
              }

              //props.state.fileUpload.handleSubmit(e);
            }}
            // disabled={props.state.handleItemModal.isView}
            hidden={isAdd}
          >
            Add
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement={"top"}
          overlay={<Tooltip id={`order-tooltip-add`}>Delete image</Tooltip>}
        >
          <Button
            variant="secondary"
            name="btnDeleteItemImage"
            //size="md"
            className="ml-2 mr-2 "
            style={{
              backgroundColor: "var(--REB-warning)",
              borderRadius: "5px",
            }}
            onClick={(e) => {
              props.state.handleActionChange(e);
            }}
            disabled={props.state.handleItemModal.isView}
            hidden={isAdd}
          >
            Delete
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement={"top"}
          overlay={<Tooltip id={`order-tooltip-add`}>Cancel</Tooltip>}
        >
          <Button
            variant="secondary"
            name=""
            //size="md"
            className="ml-2 mr-2 "
            style={{
              backgroundColor: "var(--REB-yellow)",
              color: "var(--REB-black)",
              borderRadius: "5px",
            }}
            onClick={(e) => {
              //props.state.fileUpload.handleSubmit(e);
              setIsAdd(false);
            }}
            hidden={!isAdd}
          >
            Cancel
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement={"top"}
          overlay={<Tooltip id={`order-tooltip-add`}>Save</Tooltip>}
        >
          <Button
            variant="secondary"
            name=""
            //size="sm"
            className="ml-2 mr-2 "
            style={{ backgroundColor: "var(--REB-green)", borderRadius: "5px" }}
            onClick={(e) => {
              props.state.fileUpload.handleSubmit(e);
              setIsAdd(false);
            }}
            hidden={!isAdd}
          >
            Save
          </Button>
        </OverlayTrigger>
      </ButtonGroup>
    </InputGroup>
  );
}

// let TxtDateLastRestock = (props) => (
//   <>
//     <Form.Label>
//       <strong>Last Restock</strong>
//     </Form.Label>
//     <FormControl
//       type="date"
//       placeholder={props.state.txtLastRestock.placeholder}
//       name="txtLastRestock"
//       value={props.state.txtLastRestock.value}
//       onChange={props.state.inputChange}
//       className={props.state.txtLastRestock.className + " css-projectInput"}
//       readOnly={props.state.txtLastRestock.readOnly}
//     />
//   </>
// );

// function ItemRequest(props) {
//   const [isRequest, setIsRequest] = useState(false);
//   return <></>;
// }

const ItemModal = (props) => (
  <>
    <style>
      {`
        .inventoryItemModal {
          width: 100vw;
          max-height: 100vh;
          max-width: 100vw;
          height: 100vh;
          margin: 0px;
        }
        .itemModalBody {
          width: 100vw;
        }
         {
          /* .modal-content {
          width: 100vw;
          height: 100vh;
        }
        .modal-dialog {
          max-width: 100vw;
          max-height: 100vh;
          margin: 0px;
        } */
        }
        .modal {
          padding-left: 0px !important;
        }
        .modal-body{
          padding: 0px;
        }

        @media (max-width: 768px) {
          .itemModalContentHeader {
            padding: 0px;
          }
          .itemModalControls {
          }
          .itemModalBody {
            
          }
          .itemModalCRUDButtons-padding {
            margin-top: 10px;
            margin-bottom: 10px;
          }
          .tabletMarginTop {
            margin-top: 20px;
          }
        }
        @media (min-width: 769px) {
          .itemModalContentHeader {
            padding: 10px;
            
          }
          .itemModalFooter{
            padding: 10px;
          }
          .itemModalControls {
            
          }
          .itemModalBody {
           
          }
          .itemModalCRUDButtons {
            padding-left: 30px !important;
          }
        }
        @media (min-width: 992px) {
        }
        .chkPOSChkbx > input {
          transform: scale(2);
        }
        .chkPOSChkbx > label {
          padding-left: 10px;
        }
      `}
    </style>
    <Modal
      show={props.state.handleItemModal.isShow}
      onHide={props.state.handleItemModal.toggle}
      onExit={(e) => {
        props.state.handleItemModal.handleExit(e);
      }}
      dialogClassName="inventoryItemModal"
      //size="xl"
      centered
    >
      <Modal.Body
        className="itemModalBody"
        style={{ display: "flex", flexDirection: "column" }}
        //className="px-5 "
        // className="actor-modal-body"
      >
        <Row
          style={{ borderBottom: "var(--REB-black) solid 1px", backgroundColor:'var(--REB-tertiary)' }}
          className="itemModalContentHeader p-2"
        >
          <Col style={{ alignSelf: "center" }} xs={2} sm={1} md={1} lg={1}>
            <OverlayTrigger
              placement={"right"}
              overlay={<Tooltip id={`project-tooltip-add`}>Close</Tooltip>}
            >
              <Button
                variant="secondary"
                name="btnCloseItemModal"
                style={{ backgroundColor: "var(--REB-primary)" }}
                className="icofont-rounded-left"
                onClick={(e) => {
                  props.state.handleItemModal.toggle();
                }}
              />
            </OverlayTrigger>
          </Col>
          <Col xs={6} sm={7} md={7} lg={7} className="actor-modal-header-col" style={{ alignSelf: "center" }}>
            <h4 className="m-0">
              {" "}
              {props.state.selectedOperation.name} Item
            </h4>
          </Col>
          {props.state.selectedOperation !== SELECTED_OPERATION.ADD ? (
            <Col
              md={4}
              lg={4}
              sm={12}
              xs={4}
              className="itemModalCRUDButtons itemModalCRUDButtons-padding"
            >
              <OverlayTrigger
                placement={"left"}
                overlay={
                  <Tooltip id={`project-tooltip-add`}>
                    Make inventory request for this item. You can request for
                    order, add & deduct items here.
                  </Tooltip>
                }
              >
                <Button
                  
                  name="btnRequestItem"
                  style={{ backgroundColor: "var(--REB-green)" }}
                  onClick={(e) => {
                    if (
                      props.state.permissions.includes(
                        "perm-ui-invoicingInvItemRequestAccess-view-write"
                      )
                    ) {
                      props.state.handleActionChange(e);
                    } else {
                      props.state.dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "No Permission",
                          desc: `You dont have access to request items`,
                          isYesNo: false,
                        },
                      });
                    }

                    // props.state.dispatch({
                    //   type: "MODAL_SHOW",
                    //   payload: {
                    //     title: "Confirmation",
                    //     desc: ``,
                    //     isYesNo: true,
                    //     cb: () => {
                    //      //
                    //     },
                    //   },
                    // });
                  }}
                >
                  REQUEST
                </Button>
              </OverlayTrigger>
            </Col>
          ) : null}
        </Row>

        <Form
          id="formActorInput"
          noValidate
          validated={props.state.handleItemModal.isValidated}
          onSubmit={props.state.handleSubmit}
          className="itemModalControls"
          style={{
            overflowX: "hidden",
            overflowY: "auto",
            flex: 30,
            maxHeight: "90vh",
          }}
        >
          <div
            className="p-2"
            style={{
              overflowX: "hidden",
              overflowY: "scroll",
              maxHeight: "80vh",
            }}
          >
            <Row className="my-1 mx-2" style={{}} noGutters>
              {props.state.selectedOperation.id !== 0 ? (
                <Col
                  className="px-2"
                  style={{
                    textAlign: "-webkit-center",
                    alignSelf: "center",
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    borderRadius: "5px",
                  }}
                  lg={3}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <ImageGallery
                    additionalClass="itemModalGallery "
                    //style={{ height: "100px", width: "100px" }}
                    ref={props.ItemModalRef}
                    showNav={true}
                    showPlayButton={false}
                    showThumbnails={false}
                    showFullscreenButton={false}
                    items={props.state.itemImgs}
                  />
                  <Row className="mt-1" noGutters>
                    <Col>
                      <TxtFileUpload state={props.state} />
                    </Col>
                  </Row>
                </Col>
              ) : (
                <Col
                  style={{
                    textAlign: "-webkit-center",
                    // alignSelf: "center",
                    border: "groove",
                  }}
                  lg={3}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <div
                    className="h-100"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h5> Item images can be added after saving</h5>
                  </div>
                </Col>
              )}

              <Col lg={9} md={12} sm={12} xs={12}>
                <Row noGutters className="tabletMarginTop pr-2">
                  {props.state.permissions.includes(
                    "perm-ui-inventoryPage-view-write-manage"
                  ) && (
                    <Col
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      className="pl-3 pr-2 mb-2"
                    >
                      <CopyItemComponent state={props.state} />
                    </Col>
                  )}

                  <Col
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    className="pl-3 pr-2 mb-2"
                  >
                    <NameSelect
                      state={props.state}
                      data={props.state.selDetailName.data}
                      name="selDetailName"
                      //isLoading={props.state.suppliers.isLoading}
                      selected={props.state.selDetailName.value}
                      //tabIndex={7}
                      lblHidden={false}
                      containerClassname=""
                      refreshData={(event) => {
                        props.state.refreshItemDetail("selDetailName", 1);
                      }}
                      detType={1}
                      addUrl="inventory/createItem"
                      editUrl="inventory/updateItem"
                      deleteUrl="inventory/deleteItemDetail"
                      componentName="Item name"
                      componentType=""
                      dataStructure="itemDetailsInventory"
                      //optionLabel="name"
                      handler={props.state.handleActionChange}
                      disabled={props.state.selDetailName.readOnly}
                      modifyRequiredPermission="perm-ui-inventoryPage-view-write-manage"
                      deleteRequiredPermission="perm-ui-inventoryPage-view-write-manage-admin"
                      userPermissions={props.state.permissions}
                    />
                  </Col>
                  {/* <Col sm={12} xs={12} md={6} lg={6} className="pl-5 pr-2 my-3">
                    <Form.Check
                      checked={props.state.chkIsService.value}
                      disabled={props.state.chkIsService.readOnly}
                      className="chkPOSChkbx"
                      name="chkIsService"
                      label="Set as Service"
                      onChange={(e) => {
                        props.state.handleActionChange({
                          target: {
                            value: e.target,
                            componentName: "chkIsService",
                            name: "chkHandleChange",
                          },
                        });
                      }}
                    />
                    <p className="m-0 p-0 pt-2">
                      Service will have unlimited QTY
                    </p>
                  </Col> */}
                  <Col sm={12} xs={12} md={6} lg={6} className="pl-5 pr-2 my-3">
                    <>
                      <Form.Label
                        className="font-weight-bold"
                        style={{ color: "var(--REB-black)" }}
                      >
                        Generated Unique ID
                      </Form.Label>
                      <FormControl
                        type="text"
                        //style={{ textTransform: "uppercase" }}
                        placeholder="Description"
                        name="txtGenUniqueID"
                        value={props.state?.tableSelection?.uniqueId}
                        //onChange={props.state.inputChange}
                        className={" css-actorInput mb-2"}
                        readOnly
                      />
                    </>
                  </Col>
                  <Col sm={12} xs={12} md={12} lg={12} className="pl-3 pr-2">
                    <TxtDescription state={props.state} />
                  </Col>
                </Row>
                <TxtQty state={props.state} />
                <CostPrice state={props.state} />
              </Col>
            </Row>

            <Row noGutters>
              <Col sm={12} xs={12} md lg className="pl-2 pr-2">
                <BrandSelect
                  state={props.state}
                  data={props.state.selDetailBrand.data}
                  name="selDetailBrand"
                  containerClassname="mb-3"
                  //isLoading={props.state.suppliers.isLoading}
                  selected={props.state.selDetailBrand.value}
                  //tabIndex={7}
                  lblHidden={false}
                  refreshData={(event) => {
                    props.state.refreshItemDetail("selDetailBrand", 3);
                  }}
                  detType={3}
                  addUrl="inventory/createItem"
                  editUrl="inventory/updateItem"
                  deleteUrl="inventory/deleteItemDetail"
                  componentName="Brand"
                  componentType=""
                  dataStructure="itemDetailsInventory"
                  //optionLabel="name"
                  handler={props.state.inputChange}
                  disabled={props.state.selDetailBrand.readOnly}
                  modifyRequiredPermission="perm-ui-inventoryPage-view-write-manage"
                  deleteRequiredPermission="perm-ui-inventoryPage-view-write-manage-admin"
                  userPermissions={props.state.permissions}
                />
              </Col>
              <Col sm={12} xs={12} md lg className="pl-2 pr-2">
                <TypeSelect
                  state={props.state}
                  data={props.state.selDetailType.data}
                  name="selDetailType"
                  containerClassname="mb-3"
                  //isLoading={props.state.suppliers.isLoading}
                  selected={props.state.selDetailType.value}
                  //tabIndex={7}
                  lblHidden={false}
                  refreshData={(event) => {
                    props.state.refreshItemDetail("selDetailType", 4);
                  }}
                  detType={4}
                  addUrl="inventory/createItem"
                  editUrl="inventory/updateItem"
                  deleteUrl="inventory/deleteItemDetail"
                  componentName="Type"
                  componentType=""
                  dataStructure="itemDetailsInventory"
                  //optionLabel="name"
                  handler={props.state.inputChange}
                  disabled={props.state.selDetailType.readOnly}
                  modifyRequiredPermission="perm-ui-inventoryPage-view-write-manage"
                  deleteRequiredPermission="perm-ui-inventoryPage-view-write-manage-admin"
                  userPermissions={props.state.permissions}
                />
              </Col>
            </Row>
            <Row noGutters>
              <Col sm={12} xs={12} md lg className="pl-2 pr-2">
                <StatusSelect
                  state={props.state}
                  data={props.state.selDetailStatus.data}
                  name="selDetailStatus"
                  containerClassname="mb-3"
                  //isLoading={props.state.suppliers.isLoading}
                  selected={props.state.selDetailStatus.value}
                  //tabIndex={7}
                  lblHidden={false}
                  refreshData={(event) => {
                    props.state.refreshItemDetail("selDetailStatus", 5);
                  }}
                  detType={5}
                  addUrl="inventory/createItem"
                  editUrl="inventory/updateItem"
                  deleteUrl="inventory/deleteItemDetail"
                  componentName="Status"
                  componentType=""
                  dataStructure="itemDetailsInventory"
                  //optionLabel="name"
                  isDropHidden={true}
                  handler={props.state.inputChange}
                  disabled={props.state.selDetailStatus.readOnly}
                  modifyRequiredPermission="perm-ui-inventoryPage-view-write-manage"
                  deleteRequiredPermission="perm-ui-inventoryPage-view-write-manage-admin"
                  userPermissions={props.state.permissions}
                />
              </Col>
              <Col sm={12} xs={12} md lg className="pl-2 pr-2">
                {/* <GroupSelect
              state={props.state}
              data={props.state.selDetailGroup.data}
              name="selDetailGroup"
              containerClassname="mb-3"
              //isLoading={props.state.suppliers.isLoading}
              selected={props.state.selDetailGroup.value}
              //tabIndex={7}
              lblHidden={false}
              refreshData={(event) => {
                props.state.refreshItemDetail("selDetailGroup", 6);
              }}
              detType={6}
              addUrl="inventory/createItem"
              editUrl="inventory/updateItem"
              deleteUrl="inventory/deleteItemDetail"
              componentName="Group"
              componentType=""
              //optionLabel="name"
              handler={props.state.inputChange}
              disabled={props.state.selDetailGroup.readOnly}
            /> */}
              </Col>
            </Row>
            <Row noGutters>
              {props.state.userType.includes("Admin") ||
              props.state.userType.includes("Purchaser") ||
              props.state.userType.includes("Manager") ? (
                <Col
                  sm={12}
                  xs={12}
                  md={"auto"}
                  lg={"auto"}
                  className="pl-2 pr-2"
                >
                  <TxtRestockWarn state={props.state} />
                </Col>
              ) : null}

              <Col sm={12} xs={12} md lg className="pl-2 pr-2">
                <TxtBarcode state={props.state} />
              </Col>
              <Col sm={12} xs={12} md lg className="pl-2 pr-2">
                <TxtSKU state={props.state} />
              </Col>
              {/* <Col className="pl-2 pr-2">
            <TxtSerial state={props.state} />
          </Col> */}
              <Col sm={12} xs={12} md lg className="pl-2 pr-2">
                <TxtCode state={props.state} />
              </Col>

              {/* <Col><TxtDateLastRestock state={props.state} /></Col> */}
            </Row>
            <Row noGutters>
              <Col sm={12} xs={12} md lg className="pl-2 pr-2">
                <TxtNotes state={props.state} />
              </Col>
            </Row>

            <Row className="my-3 " noGutters>
              <Col sm={12} xs={12} md={4} lg={4} className="pl-3 my-3">
                <Form.Check
                  checked={props.state.chkIsPOSVisible.value}
                  disabled={props.state.chkIsPOSVisible.readOnly}
                  className="chkPOSChkbx"
                  name="chkIsPOSVisible"
                  label="Display in POS"
                  onChange={(e) => {
                    props.state.handleActionChange({
                      target: {
                        value: e.target,
                        componentName: "chkIsPOSVisible",
                        name: "chkHandleChange",
                      },
                    });
                  }}
                />
              </Col>
              {/* {props.state.chkIsPOSVisible.value && (
                <Col sm={12} xs={12} md={4} lg={4} className="pl-3 my-3">
                  <Form.Check
                    checked={props.state.chkIsDiscountable.value}
                    disabled={props.state.chkIsDiscountable.readOnly}
                    className="chkPOSChkbx"
                    name="chkIsDiscountable"
                    label="POS Discountable"
                    onChange={(e) => {
                      props.state.handleActionChange({
                        target: {
                          value: e.target,
                          componentName: "chkIsDiscountable",
                          name: "chkHandleChange",
                        },
                      });
                    }}
                  />
                </Col>
              )} */}

              {/* <Col sm={12} xs={12} md={4} lg={4} className="pl-3 my-3">
                <Form.Check
                  checked={props.state.chkIsTaxable.value}
                  disabled={props.state.chkIsTaxable.readOnly}
                  className="chkPOSChkbx"
                  name="chkIsTaxable"
                  label="Taxable"
                  onChange={(e) => {
                    props.state.handleActionChange({
                      target: {
                        value: e.target,
                        componentName: "chkIsTaxable",
                        name: "chkHandleChange",
                      },
                    });
                  }}
                />
              </Col> */}
            </Row>

            {/* {props.state.chkIsPOSVisible.value && (
            <>
              <Row noGutters>
                <Col sm={12} xs={12} md={4} lg={4} className="pl-2 pr-2">
                  <TxtPOSItemName state={props.state} />
                </Col>
                <Col sm={12} xs={12} md={4} lg={4} className="pl-2 pr-2">
                  <TxtPOSItemNameShort state={props.state} />
                </Col>
                <Col sm={12} xs={12} md={4} lg={4} className="pl-2 pr-2">
                  <TxtPOSItemCode state={props.state} />
                </Col>
              </Row>
              <Row noGutters>
                <Col sm={12} xs={12} md lg className="pl-2 pr-2">
                  <TxtPOSItemDesc state={props.state} />
                </Col>
              </Row>
            </>
          )} */}
            {props?.state?.PRICE_HISTORY.length > 0 && (
              <Row className=" mb-4" noGutters>
                <Col className="px-1" sm={12} xs={12}>
                  <h4>Price History</h4>

                  <ResponsiveContainer height={200}>
                    <AreaChart
                      data={props.state.PRICE_HISTORY}
                      margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="dateAdded" tick={<CustomXTick />} />
                      <YAxis interval="preserveEnd" />

                      <BarTooltip content={<CustomTooltip />} />
                      <Legend />
                      <Area
                        type="monotone"
                        dataKey="newValue"
                        stroke="#8884D8"
                        fill="#8884D8"
                      />
                      {/* <Area
                         type="monotone"
                         dataKey="IHandling"
                         stroke="#8884D8"
                         fill="#8884D8"
                       />
                       <Area
                         type="monotone"
                         dataKey="IShipping"
                         stroke="#B9B5FF"
                         fill="#B9B5FF"
                       />
                       <Area
                         type="monotone"
                         dataKey="qtyDelivered"
                         stroke="#4B488C"
                         fill="#4B488C"
                       /> */}
                    </AreaChart>
                  </ResponsiveContainer>
                </Col>
              </Row>
            )}

            {(props.state.userType.includes("Admin") ||
              props.state.userType.includes("Purchaser") ||
              props.state.userType.includes("Manager")) &&
            props.state.selectedOperation.id !== 0 ? (
              <>
                <Row className=" mb-4" noGutters>
                  <Col className="px-1" sm={12} xs={12}>
                    <h4>Previous Item Re-Supply</h4>
                    {props.PRItemHistoryTable}
                  </Col>
                </Row>
              </>
            ) : null}

            {(props.state.userType.includes("Admin") ||
              props.state.userType.includes("Purchaser") ||
              props.state.permissions.includes("perm-ui-inventoryPage-view") ||
              props.state.userType.includes("Manager")) &&
            props.state.selectedOperation.id !== 0 ? (
              <>
                <Row className=" mb-4" noGutters>
                  <Col className="px-1" sm={12} xs={12}>
                    <h4>Previous Item Purchases</h4>
                    {props.ItemPurchaseHistoryTable}
                  </Col>
                </Row>
              </>
            ) : null}
          </div>

          <Row
            className="itemModalFooter"
            style={{  backgroundColor:'var(--REB-secondary)' }}
            noGutters
          >
            <Col sm={12} xs={12} md={4} lg={6} className=""></Col>
            <Col
              xs={4}
              sm={6}
              lg={2}
              className="itemModalCRUDButtons-padding itemModalCRUDButtons px-2"
            >
              <Button
                block
                name="btnUnlockItem"
                className=""
                disabled={!props.state.handleItemModal.isView}
                style={{ backgroundColor: "var(--REB-green)" }}
                onClick={(e) => {
                  props.state.handleActionChange(e);
                }}
              >
                MODIFY
              </Button>
            </Col>
            <Col
              xs={4}
              sm={6}
              lg={2}
              className="itemModalCRUDButtons-padding itemModalCRUDButtons px-2"
            >
              <Button
                block
                name="btnSubmitItem"
                disabled={props.state.handleItemModal.isView}
                style={{ backgroundColor: "var(--REB-green)" }}
                type="submit"
                // onClick={(e) => {
                //   props.state.handleSubmit(e);
                // }}
              >
                SAVE
              </Button>
            </Col>
            <Col
              xs={4}
              sm={6}
              lg={2}
              className="itemModalCRUDButtons-padding itemModalCRUDButtons px-2"
            >
              <Button
                block
                name="btnDeleteItem"
                style={{ backgroundColor: "var(--REB-warning)" }}
                disabled={
                  props.state.handleItemModal.isView ||
                  Number(props.state.selectedOperation.id) === 0
                }
                onClick={(e) => {
                  props.state.handleActionChange(e);
                }}
              >
                DELETE
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  </>
);

export { ItemModal };
