import React from "react";
import "./css/App.css";

import "./css/icofont.min.css";

import ModalProcess from "./components/Modals/ModalProcess";
import PDFModal from "./components/PDFModal/PDFModal";
import FileViewerModal from "./components/FileViewerModal/FileViewerModal";
import ToastProcess from "./components/Toast/ToastProcess";
import ScreenLoadingProcess from "./components/Loading/ScreenLoadingProcess";
import Login from "./pages/login";

import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import reducer from "./reducer/reducer";
import rootSaga from "./sagas/sagas";
import history from "./functions/history";

//import socketIOClient from "socket.io-client";

const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducer, applyMiddleware(sagaMiddleware));

class App extends React.Component {
  constructor(props) {
    super(props);
    store.dispatch({ type: "SOCKET_HANDLER_INIT" });
  }

  componentDidMount() {
    // console.log(process.env.REACT_APP_THEME);
  }

  componentWillUnmount() {}

  render() {
    sagaMiddleware.run(rootSaga);

    return (
      <Provider store={store}>
        <Router history={history}>
          <ScreenLoadingProcess />
          <ModalProcess />
          <PDFModal />
          <FileViewerModal />
          <ToastProcess />
          <Login />
        </Router>
      </Provider>
    );
  }
}

export default App;
