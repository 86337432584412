import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

//import "bootstrap/dist/js/bootstrap.js";

const GlobalModal = (props) => (
  <>
    <style>
      {`
        .modalOverride {
          z-index: 3000 !important;
        }
      `}
    </style>
    <Modal
      show={props.redux.modal.show}
      onHide={(e) => {
        props.redux.dispatch({ type: "MODAL_HIDE" });
        if (props.redux.modal.onClose) {
          props.redux.modal.onClose();
        }
      }}
      className="modalOverride"
      dialogClassName="modalOverride"
      size={props.redux.modal.size ? props.redux.modal.size : undefined}
      centered
    >
      <Modal.Header className="global-modal-header">
        <Modal.Title className="global-modal-header-title">
          <h6 className="text-center">{props.redux.modal.title}</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="global-modal-body">
        {props.redux.modal.desc && (
          <h6 className="text-center">{props.redux.modal.desc}</h6>
        )}
        {props.redux.modal.JsxDesc && <props.redux.modal.JsxDesc />}
      </Modal.Body>
      <Modal.Footer className="global-modal-footer">
        {(() => {
          if (props.redux.modal.isYesNo) {
            return (
              <>
                <Button
                  variant="success"
                  name="btnYes"
                  onClick={(e) => {
                    props.redux.dispatch({ type: "MODAL_RESP_YES" });
                  }}
                >
                  {props.redux.modal.yesLbl ? props.redux.modal.yesLbl : "Yes"}
                </Button>
                <Button
                  variant="danger"
                  name="btnNo"
                  onClick={(e) => {
                    props.redux.dispatch({ type: "MODAL_RESP_NO" });
                  }}
                >
                  {props.redux.modal.noLbl ? props.redux.modal.noLbl : "No"}
                </Button>
              </>
            );
          } else {
            return (
              <>
                <Button
                  variant="danger"
                  name="btnClose"
                  onClick={(e) => {
                    props.redux.dispatch({ type: "MODAL_HIDE" });
                  }}
                >
                  Close
                </Button>
              </>
            );
          }
        })()}
      </Modal.Footer>
    </Modal>
  </>
);

export { GlobalModal };
