// import moment from "moment";
class ActorAPI {
  getActorsByType = async (dispatch, id) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `actor/getActorByType?types=[${id}]`,
        loadingMsg: "Fetching Actors",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getAllActors = async (dispatch, id) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `actor/`,
        loadingMsg: "Fetching Actors",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getActorActivity = async (dispatch, actorId, filter) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `actor/getActorActivity`,
        loadingMsg: "Fetching Actor Activities",
        data: { actorId: Number(actorId), ...filter },
        disableLoad: true,
        reqType: "post",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getActor = async (dispatch, id) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `actor/getActor?id=${id}`,
        loadingMsg: "Fetching Actor",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getActorPayeesForSelect = async (dispatch, id) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `actor/getActorPayeesForSelect`,
        loadingMsg: "Fetching Actors",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getOrders = async (dispatch) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `order/`,
        loadingMsg: "Fetching Inventories",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getCurrentDateTime = async (dispatch) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `auth/getCurrentDateTime`,
        loadingMsg: "Fetching Current Date Time",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      //console.log("DATE TIME GET ===>", api.data.datetime);
      return api;
      // return moment(api.data.datetime, "DD/MM/YYYY, hh:mm:ss a").format(
      //   "YYYY-MM-DD HH:mm:ss"
      // );
    });
    return data;
  };
  sendEmail = async (dispatch, title, createdBy, inventoryId, cb) => {
    //console.log(sdata);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `actor/sendEmail`,
        loadingMsg: "Sending Verification Email",
        disableLoad: true,
        reqType: "post",
        data: {
          title,
          createdBy,
          inventoryId: inventoryId === null ? 1 : inventoryId,
        },
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );
    if (cb) await cb();
    return data;
  };
  forceLogoutUser = async (dispatch, actorId, cb) => {
    //console.log(sdata);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `auth/forceLogoutUser`,
        loadingMsg: "Force Logout User",
        reqType: "post",
        data: { actorId: Number(actorId) },
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );
    if (cb) await cb();
    return data;
  };
}
export default new ActorAPI();
