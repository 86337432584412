import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { ItemDetailsModal } from "./ItemDetailsModal";
import { ItemModal } from "./ItemModal";
import { ItemRequestModal } from "./ItemRequestModal";
import moment from "moment";
import InventorySelect from "../../components/CustomDrop";
import Form from "react-bootstrap/Form";
import InvReport from "../Invoice/PDF/InvReport";
import { PDFDownloadLink } from "@react-pdf/renderer";
import misc from "../../functions/misc";
import Spinner from "react-bootstrap/Spinner";
import emptyImg from "../../assets/fork-lift.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { NumericFormat } from "react-number-format";
import inventoryApi from "./InventoryAPI";
import { useSelector } from "react-redux";
import Select from "react-select";

function itemColumn(
  inventoryData,
  itemImgs,
  userType,
  downloadImage,
  refreshSelectedItem,
  dispatch,
  priceModification,
  permissions,
  itemStatus,
  itemBrand,
  itemType
) {
  return [
    {
      id: "dateEdited",
      filterable: false,
      show: false,

      accessor: (d) => d.dateEdited,
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "0.70em",
            color: "var(--REB-primary)",
            fontWeight: "bolder",
            margin: 0,
          }}
        >
          Image
        </p>
      ),
      maxWidth: 100,
      filterable: false,
      Cell: (row) => {
        const _dat = row.original;
        let image = null;
        image = _dat?.files ? JSON.parse(_dat?.files) : null;
        return (
          <Row noGutters>
            <Col
              style={{
                textAlign: "-webkit-center",
              }}
            >
              <img
                style={{
                  objectFit: "contain",
                  maxWidth: "70px",
                  height: "70px",
                  maxHeight: "70px",
                }}
                alt=""
                src={
                  typeof image !== "undefined" &&
                  image !== null &&
                  image.length > 0 &&
                  image !== "null"
                    ? `${
                        process.env.REACT_APP_API_URL
                      }global/getFile2?uniqueId=${
                        _dat.uniqueId
                      }&filename=${encodeURIComponent(
                        image[0].filename
                      )}&token=${localStorage.getItem("LUT")}`
                    : emptyImg
                }
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  fontSize: "0.65em",
                  height: "100%",
                  color: "var(--REB-blue)",
                  fontWeight: "bold",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <p style={{ marginBottom: "0px", flex: 1 }}> {_dat.uniqueId}</p>
              </div>
            </Col>
          </Row>
        );
      },
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "0.70em",
            color: "var(--REB-primary)",
            fontWeight: "bolder",
            margin: 0,
          }}
        >
          Item Name
        </p>
      ),
      id: "name",
      maxWidth: "auto",
      minWidth: 550,
      filterable: true,

      getProps: (row) => {
        return { style: { padding: 0, height: 110 } };
      },
      Cell: (row) => {
        const d = row.original;
        let name = "-";
        // let image = null;
        // image = d?.files ? JSON.parse(d?.files) : null;
        if (d.details.length > 0) {
          let nameDat = d.details.find((x) => x.type === 1);
          nameDat = nameDat !== null ? nameDat.name : "";
          name = nameDat;
        }
        const lastPurchased =
          d.lastPurchased === null
            ? "-"
            : misc.parseAPIDateTimeToShortDate(
                misc.parseAPIDateTime(d.lastPurchased)
              );
        const lastRestock =
          d.lastRestock === null
            ? "-"
            : misc.parseAPIDateTimeToShortDate(
                misc.parseAPIDateTime(d.lastRestock)
              );
        return (
          <Row noGutters>
            {/* <Col
              style={{
                textAlign: "-webkit-center",
              }}
              xs={2}
            >
              <img
                style={{
                  objectFit: "contain",
                  maxWidth: "70px",
                  height: "70px",
                  maxHeight: "70px",
                }}
                alt=""
                src={
                  typeof image !== "undefined" &&
                  image !== null &&
                  image.length > 0 &&
                  image !== "null"
                    ? `${
                        process.env.REACT_APP_API_URL
                      }global/getFile2?uniqueId=${
                        d.uniqueId
                      }&filename=${encodeURIComponent(
                        image[0].filename
                      )}&token=${localStorage.getItem("LUT")}`
                    : emptyImg
                }
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  fontSize: "0.65em",
                  height: "100%",
                  color: "var(--REB-blue)",
                  fontWeight: "bold",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <p style={{ marginBottom: "0px" }}> {d.uniqueId}</p>
                <p style={{ marginBottom: "0px" }}> {d.code}</p>
              </div>
            </Col> */}
            <Col xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  fontSize: "0.85em",
                  height: "100%",
                  color: "var(--REB-blue)",
                  fontWeight: "bold",
                  // alignItems: "center",
                }}
              >
                <p
                  style={{ marginBottom: "0px", flex: 1, textAlign: "center" }}
                >
                  {name}
                </p>

                <p
                  style={{
                    marginBottom: "0px",
                    fontWeight: "normal",
                    flex: 1,
                    textAlign: "center",
                  }}
                >
                  {d.description}
                </p>
                <p
                  style={{
                    marginBottom: "0px",
                    fontWeight: "bolder",
                    flex: 1,
                    fontSize: "0.5rem",
                    textAlign: "center",
                  }}
                >
                  {d.status}
                </p>
                <p
                  style={{
                    marginBottom: "0px",
                    fontWeight: "bold",
                    flex: 1,
                    fontSize: "0.6rem",
                    color: "var(--REB-black)",
                  }}
                >
                  Last Purchased: {lastPurchased}
                </p>
                <p
                  style={{
                    marginBottom: "0px",
                    fontWeight: "bold",
                    flex: 1,
                    fontSize: "0.6rem",
                    color: "var(--REB-black)",
                  }}
                >
                  Last Restock: {lastRestock}
                </p>
              </div>
            </Col>
          </Row>

          // <FormControl
          //   as="textarea"
          //   style={{ resize: "none", height: "100%" }}
          //   defaultValue={name}
          //   readOnly
          // />
        );
      },
      accessor: (d) => {
        let name = "-";

        if (d) {
          if (d.details) {
            if (d.details.length > 0) {
              let nameDat = d.details.find((x) => x.type === 1);
              nameDat = nameDat !== null ? nameDat.name : "";
              name = nameDat;
            }
          }
        }

        return name;
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        } else {
          const details = row?._original?.details;
          if (details) {
            let itemStatus = details.find((x) => x.type === 5);
            if (itemStatus) {
              return itemStatus?.name === filter.value;
            }
          } else {
            return row[filter.id] === filter.value;
          }
        }
        // if (filter.value === "true") {
        //   return row[filter.id] >= 21;
        // }
        // return row[filter.id] < 21;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          {itemStatus &&
            itemStatus.map((x) => {
              return (
                <option key={x.id} value={x.name}>
                  {x.name}
                </option>
              );
            })}
        </select>
      ),
    },
    // {
    //   Header: () => (
    //     <p
    //       style={{
    //         fontSize: "1.1em",
    //         color: "var(--REB-primary)",
    //         fontWeight: "bold",
    //         margin: 0,
    //       }}
    //     >
    //       Description
    //     </p>
    //   ),
    //   id: "desc",
    //   maxWidth: 350,
    //   getProps: (row) => {
    //     return {
    //       style: {
    //         padding: 0,
    //         height: 110,
    //         display: "flex",
    //         justifyContent: "center",
    //       },
    //     };
    //   },
    //   Cell: (row) => {
    //     const d = row.original;
    //     return (
    //       <div
    //         style={{
    //           display: "flex",
    //           wordWrap: "break-word",
    //           whiteSpace: "normal",
    //           fontSize: "0.85em",
    //           height: "100%",
    //           color: "var(--REB-blue)",
    //           fontWeight: "bold",
    //           alignItems: "center",
    //           textAlign: "center",
    //         }}
    //       >
    //         {d.description}
    //       </div>
    //       // <FormControl
    //       //   as="textarea"
    //       //   style={{ resize: "none", height: "100%" }}
    //       //   defaultValue={d.description}
    //       //   readOnly
    //       // />
    //     );
    //   },
    //   accessor: (d) => {
    //     return d.description;
    //   },
    // },
    {
      Header: () => (
        <p
          style={{
            fontSize: "0.70em",
            color: "var(--REB-primary)",
            fontWeight: "bolder",
            margin: 0,
          }}
        >
          Brand
        </p>
      ),
      id: "brand",
      maxWidth: 100,
      getProps: (row) => {
        return {
          style: {
            padding: 0,
            height: 110,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        };
      },
      accessor: (d) => {
        let name = "-";

        if (d) {
          if (d.details) {
            if (d.details.length > 0) {
              const sDat = d.details.find((x) => x.type === 3);
              sDat !== undefined ? (name = sDat.name) : (name = "-");
            }
          }
        }

        return name;
      },
      Cell: (row) => {
        //const d = row.original;

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              wordWrap: "break-word",
              whiteSpace: "normal",
              fontSize: "0.85em",
              height: "100%",
              color: "var(--REB-blue)",
              fontWeight: "bold",
              alignItems: "center",
            }}
          >
            <p style={{ marginBottom: "0px", flex: 1, textAlign: "center" }}>
              {row.value}
            </p>
          </div>
        );
      },
      filterable: true,
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        } else {
          const details = row?._original?.details;
          if (details) {
            let itemBrand = details.find((x) => x.type === 3);
            if (itemBrand) {
              return itemBrand?.name === filter.value;
            }
          } else {
            return row[filter.id] === filter.value;
          }
        }
        // if (filter.value === "true") {
        //   return row[filter.id] >= 21;
        // }
        // return row[filter.id] < 21;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          {itemBrand &&
            itemBrand.map((x) => {
              return (
                <option key={x.id} value={x.name}>
                  {x.name}
                </option>
              );
            })}
        </select>
      ),
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "0.70em",
            color: "var(--REB-primary)",
            fontWeight: "bolder",
            margin: 0,
          }}
        >
          Type
        </p>
      ),
      id: "itemType",
      maxWidth: 100,
      getProps: (row) => {
        return {
          style: {
            padding: 0,
            height: 110,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        };
      },
      Cell: (row) => {
        //const d = row.original;

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              wordWrap: "break-word",
              whiteSpace: "normal",
              fontSize: "0.85em",
              height: "100%",
              color: "var(--REB-blue)",
              fontWeight: "bold",
              alignItems: "center",
            }}
          >
            <p style={{ marginBottom: "0px", flex: 1, textAlign: "center" }}>
              {row.value}
            </p>
          </div>
        );
      },
      accessor: (d) => {
        let name = "-";

        if (d) {
          if (d.details) {
            if (d.details.length > 0) {
              const sDat = d.details.find((x) => x.type === 4);
              sDat !== undefined ? (name = sDat.name) : (name = "-");
            }
          }
        }

        return name;
      },
      filterable: true,
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        } else {
          const details = row?._original?.details;
          if (details) {
            let itemType = details.find((x) => x.type === 4);
            if (itemType) {
              return itemType?.name === filter.value;
            }
          } else {
            return row[filter.id] === filter.value;
          }
        }
        // if (filter.value === "true") {
        //   return row[filter.id] >= 21;
        // }
        // return row[filter.id] < 21;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          {itemType &&
            itemType.map((x) => {
              return (
                <option key={x.id} value={x.name}>
                  {x.name}
                </option>
              );
            })}
        </select>
      ),
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "0.70em",
            color: "var(--REB-primary)",
            fontWeight: "bolder",
            margin: 0,
          }}
        >
          Qty
        </p>
      ),
      id: "qty",
      maxWidth: 70,
      // getHeaderProps: (row) => {
      //   return { style: { background: "green" } };
      // },
      getProps: (row) => {
        return { style: { padding: 0, height: "100%" } };
      },
      Cell: (row) => {
        // const dispatch = useDispatch();

        let name = "-";
        const d = row.original;
        const isService = d.isService;

        let warn = "initial";
        if (d.qty <= d.restockWarn) warn = "var(--REB-yellow)";
        if (d.qty <= 0) warn = "var(--REB-warning)";

        if (d.details.length > 0) {
          const sDat = d.details.find((x) => x.type === 2);
          sDat !== undefined ? (name = sDat.name) : (name = "-");
        }
        let _qty = 0;
        if (d.qty <= 0) {
          _qty = 0;
        } else {
          _qty = d.qty;
        }

        let _userType = [];

        if (userType) {
          _userType = userType;
        }

        // userType !== null && userType !== undefined
        //   ? userType.includes("Admin") || userType.includes("Purchaser")
        //     ? true
        //     : false
        //   : false,

        return (
          <>
            {!isService && (
              <Row
                style={{
                  backgroundColor: warn,
                  height: "100%",
                  textAlign: "center",
                  alignItems: "center",
                }}
                className=""
                noGutters
              >
                <Col xs={12} style={{ fontWeight: "bolder" }}>
                  {`${_qty}`}
                  <br /> {` ${name}`}
                </Col>
                {(_userType.includes("Admin") ||
                  _userType.includes("Manager") ||
                  permissions.includes(
                    "perm-ui-inventoryPage-view-write-admin"
                  )) && (
                  <>
                    <Col xs={6}>
                      <OverlayTrigger
                        placement={"top"}
                        overlay={
                          <Tooltip id={`actor-tooltip-add`}>
                            Subtracts 1 qty
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="secondary"
                          name="btnSubtractItemQty"
                          //disabled={props.state.txtDisplay.readOnly}
                          style={{
                            backgroundColor: "var(--REB-secondary)",
                            borderTopRightRadius: "5px",
                            borderBottomRightRadius: "5px",
                            fontSize: "6px",
                            color: "var(--REB-white)",
                            height: "30px",
                          }}
                          className="icofont-minus"
                          onClick={async (e) => {
                            await inventoryApi.subtractItemQty(dispatch, {
                              id: Number(d.id),
                              qty: 1,
                            });
                            if (refreshSelectedItem) {
                              refreshSelectedItem(d.id);
                            }
                          }}
                        ></Button>
                      </OverlayTrigger>
                    </Col>
                    <Col xs={6}>
                      <OverlayTrigger
                        placement={"top"}
                        overlay={
                          <Tooltip id={`actor-tooltip-add`}>Adds 1 qty</Tooltip>
                        }
                      >
                        <Button
                          variant="secondary"
                          name="btnAddItemQty"
                          // disabled={props.state.txtDisplay.readOnly}
                          style={{
                            backgroundColor: "var(--REB-tertiary)",
                            fontSize: "6px",
                            height: "30px",
                          }}
                          className="icofont-ui-add"
                          onClick={async (e) => {
                            await inventoryApi.addItemQty(dispatch, {
                              id: Number(d.id),
                              qty: 1,
                            });
                            if (refreshSelectedItem) {
                              refreshSelectedItem(d.id);
                            }
                          }}
                        ></Button>
                      </OverlayTrigger>
                    </Col>
                  </>
                )}
              </Row>
            )}
            {isService && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <p className="m-0 p-0" style={{ textAlign: "center" }}>
                  Service
                </p>
              </div>
            )}
          </>
        );
      },

      accessor: (d) => (d.qty === null ? "-" : d.qty),
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "0.70em",
            color: "var(--REB-primary)",
            fontWeight: "bolder",
            margin: 0,
          }}
        >
          Display
        </p>
      ),
      id: "display",
      maxWidth: 70,
      // getHeaderProps: (row) => {
      //   return { style: { background: "green" } };
      // },
      accessor: (d) => (d.display === null ? "-" : d.display),
      getProps: (row) => {
        return { style: { padding: 0, height: "100%" } };
      },
      Cell: (row) => {
        let name = "-";
        const d = row.original;
        const isService = d.isService;
        // let warn = "initial";
        // if (d.qty <= d.restockWarn) warn = "var(--REB-yellow)";
        // if (d.qty <= 0) warn = "var(--REB-warning)";

        if (d.details.length > 0) {
          const sDat = d.details.find((x) => x.type === 2);
          sDat !== undefined ? (name = sDat.name) : (name = "-");
        }
        return (
          <>
            {!isService && (
              <Row
                style={{
                  // backgroundColor: warn,
                  height: "100%",
                  textAlign: "center",
                  alignItems: "center",
                }}
                className=""
                noGutters
              >
                <Col style={{ fontWeight: "bolder" }}>
                  {`${d.display === null ? 0 : d.display} `}
                  <br />
                  {`${name}`}
                </Col>
              </Row>
            )}
            {isService && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <p className="m-0 p-0" style={{ textAlign: "center" }}>
                  Service
                </p>
              </div>
            )}
          </>
        );
      },
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "0.70em",
            color: "var(--REB-primary)",
            fontWeight: "bolder",
            margin: 0,
          }}
        >
          For Order
        </p>
      ),
      id: "forOrder",
      maxWidth: 70,
      // getHeaderProps: (row) => {
      //   return { style: { background: "green" } };
      // },
      accessor: (d) => (d.qty === null ? "-" : d.qty),
      getProps: (row) => {
        return { style: { padding: 0, height: "100%" } };
      },
      Cell: (row) => {
        let name = "-";
        const d = row.original;
        const isService = d.isService;
        let _qty = 0;
        // let warn = "initial";
        // if (d.qty <= d.restockWarn) warn = "var(--REB-yellow)";
        // if (d.qty <= 0) warn = "var(--REB-warning)";

        if (d.qty < 0) {
          _qty = Math.abs(d.qty);
        }

        if (d.details.length > 0) {
          const sDat = d.details.find((x) => x.type === 2);
          sDat !== undefined ? (name = sDat.name) : (name = "-");
        }
        return (
          <>
            {!isService && (
              <Row
                style={{
                  // backgroundColor: warn,
                  height: "100%",
                  textAlign: "center",
                  alignItems: "center",
                }}
                className=""
                noGutters
              >
                <Col style={{ fontWeight: "bolder" }}>
                  {`${_qty} `}
                  <br />
                  {`${name}`}
                </Col>
              </Row>
            )}
            {isService && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <p className="m-0 p-0" style={{ textAlign: "center" }}>
                  Service
                </p>
              </div>
            )}
          </>
        );
      },
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "0.70em",
            color: "var(--REB-primary)",
            fontWeight: "bolder",
            margin: 0,
          }}
        >
          COGS
        </p>
      ),
      id: "cogs",
      maxWidth: 130,
      // getHeaderProps: (row) => {
      //   return { style: { marginRight: "17px" } };
      // },
      // getProps: (row) => {
      //   return { style: { color: "var(--REB-blue)" } };
      // },
      show:
        userType !== null && userType !== undefined
          ? userType.includes("Admin") || userType.includes("Purchaser")
            ? true
            : false
          : false,
      accessor: (d) => (d.cogs === null ? "-" : d.cogs),
      Cell: (row) => {
        const d = row.original;

        return (
          <>
            <Row
              style={{
                // backgroundColor: warn,
                height: "100%",
                textAlign: "center",
                alignItems: "center",
              }}
              className=""
              noGutters
            >
              {/* <Col
                style={{ fontWeight: "bolder", color: "var(--REB-blue)" }}
              >{`${d.cogs === null ? "-" : d.cogs} `}</Col> */}
              <Col style={{ fontWeight: "bold", color: "var(--REB-green)" }}>
                <NumericFormat
                  value={d.cogs === null ? 0 : d.cogs}
                  displayType={"text"}
                  thousandSeparator={true}
                  //prefix={"₱ "}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "0.70em",
            color: "var(--REB-primary)",
            fontWeight: "bolder",
            margin: 0,
          }}
        >
          SRP
        </p>
      ),
      id: "price",
      maxWidth: 130,

      accessor: (d) => (d.price === null ? "-" : d.price),
      // show:
      // userType !== null && userType !== undefined
      //   ? userType.includes("Admin") || userType.includes("Purchaser")
      //     ? true
      //     : false
      //   : false,
      Cell: (row) => {
        const d = row.original;
        let modifiedPrice = 0;
        // console.log(priceModification)
        // if (priceModification) {
        //   const percentageModifier = priceModification / 100;
        //   modifiedPrice = d.price + d.price * percentageModifier;
        //   modifiedPrice = Number(modifiedPrice.toFixed(2));
        // }
        modifiedPrice = misc.calculateMarkup(d.price, priceModification);

        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {userType &&
              priceModification > 0 &&
              (userType.includes("Admin") ||
                userType.includes("Purchaser")) && (
                <>
                  <NumericFormat
                    value={d.price === null ? 0 : d.price}
                    displayType={"text"}
                    thousandSeparator={true}
                    //prefix={"₱ "}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    style={{
                      fontSize: "0.65rem",
                      textDecoration: "line-through",
                    }}
                  />
                </>
              )}

            <NumericFormat
              value={modifiedPrice === null ? 0 : modifiedPrice}
              displayType={"text"}
              thousandSeparator={true}
              //prefix={"₱ "}
              decimalScale={2}
              fixedDecimalScale={true}
              style={{
                color: "var(--REB-primary)",
                fontWeight: "bolder",
                textAlign: "center",
              }}
            />
          </div>
          // <Row
          //   style={{
          //     // backgroundColor: warn,
          //     height: "100%",
          //     textAlign: "center",
          //     alignItems: "center",
          //   }}
          //   className=""
          //   noGutters
          // >
          //   <Col style={{ fontWeight: "bold", color: "var(--REB-green)" }}>

          //   </Col>
          //   <Col style={{ fontWeight: "bold", color: "var(--REB-green)" }}>

          //   </Col>
          // </Row>
        );
      },
    },
    // {
    //   Header: () => (
    //     <p
    //       style={{
    //         fontSize: "0.70em",
    //         color: "var(--REB-primary)",
    //         fontWeight: "bolder",
    //         margin: 0,
    //       }}
    //     >
    //       Movement
    //     </p>
    //   ),
    //   id: "lastPurchased",
    //   filterable: false,
    //   maxWidth: 80,
    //   // getProps: (row) => {
    //   //   return { style: { fontSize: "13px" } };
    //   // },
    //   Cell: (row) => {
    //     const d = row.original;
    //     let lastPurchased =
    //       d.lastPurchased === null
    //         ? "-"
    //         : misc.parseAPIDateTimeToShortDate(
    //             misc.parseAPIDateTime(d.lastPurchased)
    //           );
    //     let lastRestock =
    //       d.lastRestock === null
    //         ? "-"
    //         : misc.parseAPIDateTimeToShortDate(
    //             misc.parseAPIDateTime(d.lastRestock)
    //           );
    //     return (
    //       <>
    //         <div
    //           style={{
    //             display: "block",
    //             wordWrap: "break-word",
    //             whiteSpace: "normal",
    //           }}
    //         >
    //           <p
    //             style={{
    //               fontSize: "0.6rem",
    //               fontWeight: "bolder",
    //               textAlign: "center",
    //               marginBottom: "0px",
    //             }}
    //           >
    //             Last Purchased
    //           </p>
    //           <p
    //             style={{
    //               fontSize: "0.65em",
    //               textAlign: "center",
    //               color: "var(--REB-blue)",
    //               fontWeight: "bold",
    //             }}
    //           >
    //             {lastPurchased}
    //           </p>
    //         </div>
    //         <div
    //           style={{
    //             display: "block",
    //             wordWrap: "break-word",
    //             whiteSpace: "normal",
    //           }}
    //         >
    //           <p
    //             style={{
    //               fontSize: "0.6rem",
    //               fontWeight: "bolder",
    //               textAlign: "center",
    //               marginBottom: "0px",
    //             }}
    //           >
    //             Last Restock
    //           </p>
    //           <p
    //             style={{
    //               fontSize: "0.65em",
    //               textAlign: "center",
    //               color: "var(--REB-blue)",
    //               fontWeight: "bold",
    //               margin: "0px !important",
    //             }}
    //           >
    //             {lastRestock}
    //           </p>
    //         </div>
    //       </>
    //     );
    //   },
    //   getHeaderProps: (row) => {
    //     return {
    //       style: {
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //       },
    //     };
    //   },
    //   accessor: (d) =>
    //     d.lastPurchased === null
    //       ? "-"
    //       : misc.parseAPIDateTimeToShortDate(
    //           misc.parseAPIDateTime(d.lastPurchased)
    //         ),
    // },
    // {
    //   Header: () => (
    //     <div
    //       style={{
    //         display: "block",
    //         wordWrap: "break-word",
    //         whiteSpace: "normal",
    //       }}
    //     >
    //       <p
    //         style={{
    //           fontSize: "0.70em",
    //           color: "var(--REB-primary)",
    //           fontWeight: "bold",
    //           margin: 0,
    //         }}
    //       >
    //         Last Restock
    //       </p>
    //     </div>
    //   ),
    //   id: "lastRestock",
    //   filterable: false,
    //   maxWidth: 80,
    //   getHeaderProps: (row) => {
    //     return {
    //       style: {
    //         marginRight: "17px",
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //       },
    //     };
    //   },
    //   Cell: (row) => {
    //     const d = row.original;
    //     let lastRestock =
    //       d.lastRestock === null
    //         ? "-"
    //         : misc.parseAPIDateTimeToShortDate(
    //             misc.parseAPIDateTime(d.lastRestock)
    //           );
    //     return (
    //       <div
    //         style={{
    //           display: "block",
    //           wordWrap: "break-word",
    //           whiteSpace: "normal",
    //         }}
    //       >
    //         <p
    //           style={{
    //             fontSize: "0.80em",
    //             textAlign: "center",
    //             color: "var(--REB-blue)",
    //             fontWeight: "bold",
    //           }}
    //         >
    //           {lastRestock}
    //         </p>
    //       </div>
    //     );
    //   },
    //   accessor: (d) =>
    //     d.lastRestock === null
    //       ? "-"
    //       : misc.parseAPIDateTimeToShortDate(
    //           misc.parseAPIDateTime(d.lastRestock)
    //         ),
    // },
  ];
}
const PurchaseHistoryColumns = [
  {
    Header: () => (
      <p
        style={{
          fontSize: "0.70em",
          color: "var(--REB-primary)",
          fontWeight: "bolder",
          margin: 0,
        }}
      >
        Client
      </p>
    ),

    id: "client",
    accessor: (
      d //console.log(d),
    ) => {
      const { order } = d;

      return `${order?.client?.company}`;
    },
  },
  {
    Header: () => (
      <p
        style={{
          fontSize: "0.70em",
          color: "var(--REB-primary)",
          fontWeight: "bolder",
          margin: 0,
        }}
      >
        Transaction
      </p>
    ),
    id: "transactionName",
    accessor: (
      d //console.log(d),
    ) => {
      const { order } = d;

      return `${order?.name}`;
    },
  },
  {
    Header: () => (
      <p
        style={{
          fontSize: "0.70em",
          color: "var(--REB-primary)",
          fontWeight: "bolder",
          margin: 0,
        }}
      >
        Sales Person
      </p>
    ),
    id: "salesPerson",
    accessor: (
      d //console.log(d),
    ) => {
      const { order } = d;

      return `${order?.incharge?.lastName} ${order?.incharge?.firstName}`;
    },
  },
  {
    Header: () => (
      <p
        style={{
          fontSize: "0.70em",
          color: "var(--REB-primary)",
          fontWeight: "bolder",
          margin: 0,
        }}
      >
        Qty
      </p>
    ),
    id: "qty",
    maxWidth: 70,
    // getHeaderProps: (row) => {
    //   return { style: { background: "green" } };
    // },
    getProps: (row) => {
      return { style: { padding: 0, height: "100%" } };
    },
    Cell: (row) => {
      let name = "-";
      const d = row.original;

      return (
        <Row
          style={{
            backgroundColor: "initial",
            height: "100%",
            textAlign: "center",
            alignItems: "center",
          }}
          className=""
          noGutters
        >
          <Col style={{ fontWeight: "bolder" }}>
            {`${d.itemQty}`}
            <br /> {` ${d.itemUnit?.name ? d.itemUnit?.name : "-"}`}
          </Col>
        </Row>
      );
    },

    accessor: (d) => (d.qty === null ? "-" : d.qty),
  },
  {
    Header: () => (
      <p
        style={{
          fontSize: "0.70em",
          color: "var(--REB-primary)",
          fontWeight: "bolder",
          margin: 0,
        }}
      >
        Price
      </p>
    ),
    id: "price",
    maxWidth: 130,
    Cell: (row) => {
      const d = row.original;

      return (
        <>
          <Row
            style={{
              // backgroundColor: warn,
              height: "100%",
              textAlign: "center",
              alignItems: "center",
            }}
            className=""
            noGutters
          >
            {/* <Col
              style={{ fontWeight: "bolder", color: "var(--REB-blue)" }}
            >{`${d.cogs === null ? "-" : d.cogs} `}</Col> */}
            <Col style={{ fontWeight: "bold", color: "var(--REB-green)" }}>
              <NumericFormat
                value={d.price === null ? 0 : d.price}
                displayType={"text"}
                thousandSeparator={true}
                //prefix={"₱ "}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </Col>
          </Row>
        </>
      );
    },
    accessor: (
      d //console.log(d),
    ) => (d.price === null ? 0 : d.price),
  },
  {
    Header: () => (
      <p
        style={{
          fontSize: "0.70em",
          color: "var(--REB-primary)",
          fontWeight: "bolder",
          margin: 0,
        }}
      >
        Total
      </p>
    ),
    id: "total",
    maxWidth: 130,
    Cell: (row) => {
      const d = row.original;
      const total = d.price * d.itemQty;
      return (
        <>
          <Row
            style={{
              // backgroundColor: warn,
              height: "100%",
              textAlign: "center",
              alignItems: "center",
            }}
            className=""
            noGutters
          >
            {/* <Col
              style={{ fontWeight: "bolder", color: "var(--REB-blue)" }}
            >{`${d.cogs === null ? "-" : d.cogs} `}</Col> */}
            <Col style={{ fontWeight: "bold", color: "var(--REB-green)" }}>
              <NumericFormat
                value={total}
                displayType={"text"}
                thousandSeparator={true}
                //prefix={"₱ "}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </Col>
          </Row>
        </>
      );
    },
    accessor: (d) => {
      const total = d.price * d.itemQty;
      return total;
    },
  },
  {
    Header: () => (
      <p
        style={{
          fontSize: "0.70em",
          color: "var(--REB-primary)",
          fontWeight: "bolder",
          margin: 0,
        }}
      >
        Date
      </p>
    ),
    id: "date",
    maxWidth: 80,
    Cell: (row) => {
      const d = row.original;
      let lastPurchased = d.order
        ? misc.parseAPIDateTimeToShortDate(
            misc.parseAPIDateTime(d.order?.dateAdded)
          )
        : null;

      return (
        <>
          <div
            style={{
              display: "block",
              wordWrap: "break-word",
              whiteSpace: "normal",
            }}
          >
            <p
              style={{
                fontSize: "0.65em",
                textAlign: "center",
                color: "var(--REB-blue)",
                fontWeight: "bold",
              }}
            >
              {lastPurchased}
            </p>
          </div>
        </>
      );
    },
    getHeaderProps: (row) => {
      return {
        style: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingRight: "14px",
        },
      };
    },
    accessor: (d) =>
      d.order
        ? misc.parseAPIDateTimeToShortDate(
            misc.parseAPIDateTime(d.order?.dateAdded)
          )
        : null,
  },
];
const itemSetColumn = [
  {
    Header: "Name",
    id: "name",
    accessor: (
      d //console.log(d),
    ) => (d.name === null ? "-" : d.name),
  },
];
const itemDetailsColumn = [
  {
    Header: "Name",
    id: "name",
    accessor: (d) => (d.name === null ? "-" : d.name),
  },
  {
    Header: "Description",
    id: "description",
    accessor: (d) => (d.description === null ? "-" : d.description),
  },
];
const itemGroupsColumn = [
  {
    Header: "Name",
    id: "name",
    accessor: (d) => (d.name === null ? "-" : d.name),
  },
  {
    Header: "Description",
    id: "description",
    accessor: (d) => (d.description === null ? "-" : d.description),
  },
  {
    Header: "Brand",
    id: "description",
    accessor: (d) => (d.description === null ? "-" : d.description),
  },
  {
    Header: "Type",
    id: "description",
    accessor: (d) => (d.description === null ? "-" : d.description),
  },
  {
    Header: "Qty",
    id: "qty",
    accessor: (d) => (d.qty === null ? "-" : d.qty),
  },
  {
    Header: "Price",
    id: "qty",
    accessor: (d) => (d.qty === null ? "-" : d.qty),
  },
];
function requestColumn(InvoiceTypes, InvoiceStatus) {
  return [
    // {
    //   Header: "PO Materials",

    //   Cell: (row) => (
    //     <Row noGutters>
    //       <Col>
    //         <ImageGallery
    //           showNav={false}
    //           showPlayButton={false}
    //           showThumbnails={false}
    //           Invoices={images}
    //         />
    //       </Col>
    //     </Row>
    //   ),
    // },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1.1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
          }}
        >
          ID
        </p>
      ),
      id: "uniqueId",
      maxWidth: 110,
      getProps: (row) => {
        return { style: { fontSize: "10px" } };
      },
      accessor: (d) => (d.uniqueId === null ? "-" : d.uniqueId),
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1.1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
          }}
        >
          Item Name
        </p>
      ),
      id: "name",
      maxWidth: "auto",
      getProps: (row) => {
        return { style: { padding: 0, height: 110 } };
      },
      Cell: (row) => {
        const d = row.original;
        let name = "-";
        if (d?.item?.details) {
          let nameDat = d.item.details.find((x) => x.type === 1);
          nameDat = nameDat !== null ? nameDat.name : "";
          name = nameDat;
        }
        return (
          <div
            style={{
              display: "block",
              wordWrap: "break-word",
              whiteSpace: "normal",
              fontSize: "0.85em",

              color: "var(--REB-blue)",
              fontWeight: "bold",
            }}
          >
            {name}
          </div>
          // <FormControl
          //   as="textarea"
          //   style={{ resize: "none", height: "100%" }}
          //   defaultValue={name}
          //   readOnly
          // />
        );
      },
      accessor: (d) => {
        let name = "-";
        if (d?.item?.details && d.item.details.length > 0) {
          let nameDat = "-";
          nameDat = d.item.details.find((x) => x.type === 1);
          nameDat = nameDat !== null ? nameDat.name : "";
          name = nameDat;
        }

        return name;
      },
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1.1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
          }}
        >
          Notes
        </p>
      ),
      id: "notes",
      //maxWidth: 200,
      accessor: (d) => (d.name === null ? "-" : d.name),
      getProps: (row) => {
        return { style: {} };
      },
      Cell: (row) => {
        const d = row.original;
        return (
          <div
            style={{
              display: "block",
              wordWrap: "break-word",
              whiteSpace: "normal",
            }}
          >
            {d.notes}
          </div>
          // <FormControl
          //   as="textarea"
          //   style={{ resize: "none", height: "100%" }}
          //   defaultValue={d.name === null ? "-" : d.name}
          //   readOnly
          // />
        );
      },
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1.1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
          }}
        >
          Qty
        </p>
      ),
      id: "qty",
      maxWidth: 100,
      // getHeaderProps: (row) => {
      //   return { style: { background: "green" } };
      // },
      getProps: (row) => {
        return { style: { padding: 0, height: "100%" } };
      },
      Cell: (row) => {
        let name = "-";
        const d = row.original;

        // let warn = "initial";
        // if (d.qty <= d.restockWarn) warn = "var(--REB-yellow)";
        // if (d.qty <= 0) warn = "var(--REB-warning)";

        if (d?.item?.details && d.item.details.length > 0) {
          const sDat = d.item.details.find((x) => x.type === 2);
          sDat !== undefined ? (name = sDat.name) : (name = "-");
        }
        return (
          <Row
            style={{
              // backgroundColor: warn,
              height: "100%",
              textAlign: "center",
              alignItems: "center",
            }}
            className=""
            noGutters
          >
            <Col style={{ fontWeight: "bolder" }}>{`${d.itemQty} ${name}`}</Col>
          </Row>
        );
      },

      accessor: (d) => (d.itemQty === null ? "-" : d.itemQty),
    },

    // {
    //   Header: "Transaction",
    //   id: "transaction",
    //   getProps: (row) => {
    //     return { style: {} };
    //   },
    //   Cell: (row) => {
    //     const d = row.original;
    //     let name = "-";
    //     if (d.client !== null) {
    //       name = d.client.company === null ? "-" : d.client.company;
    //     }
    //     if (d.supplier !== null) {
    //       name = d.supplier.company === null ? "-" : d.supplier.company;
    //     }
    //     return (
    //       <div
    //         style={{
    //           display: "block",
    //           wordWrap: "break-word",
    //           whiteSpace: "normal",
    //         }}
    //       >
    //         {name}
    //       </div>
    //     );
    //   },
    //   accessor: (d) => {
    //     if (d.client !== null) {
    //       return d.client.company === null ? "-" : d.client.company;
    //     }
    //     if (d.supplier !== null) {
    //       return d.supplier.company === null ? "-" : d.supplier.company;
    //     }
    //   },
    // },
    {
      Header: () => (
        <div
          style={{
            display: "block",
            wordWrap: "break-word",
            whiteSpace: "normal",
          }}
        >
          <p
            style={{
              fontSize: "0.70em",
              color: "var(--REB-primary)",
              fontWeight: "bold",
            }}
          >
            Created
          </p>
        </div>
      ),
      id: "dateAdded",
      filterable: false,
      maxWidth: 80,

      Cell: (row) => {
        const d = row.original;
        let name =
          d.dateAdded === null
            ? "-"
            : misc.parseAPIDateTimeToShortDate(
                misc.parseAPIDateTime(d.dateAdded)
              );
        return (
          <div
            style={{
              display: "block",
              wordWrap: "break-word",
              whiteSpace: "normal",
            }}
          >
            <p
              style={{
                fontSize: "0.80em",

                color: "var(--REB-blue)",
                fontWeight: "bold",
              }}
            >
              {name}
            </p>
          </div>
        );
      },
      accessor: (d) => d.dateAdded,
    },
    {
      Header: () => (
        <div
          style={{
            display: "block",
            wordWrap: "break-word",
            whiteSpace: "normal",
          }}
        >
          <p
            style={{
              fontSize: "0.70em",
              color: "var(--REB-primary)",
              fontWeight: "bold",
            }}
          >
            Approved
          </p>
        </div>
      ),
      id: "dateFinished",
      filterable: false,
      maxWidth: 80,

      Cell: (row) => {
        const d = row.original;
        let name =
          d.dateFinished === null
            ? "-"
            : misc.parseAPIDateTimeToShortDate(
                misc.parseAPIDateTime(d.dateFinished)
              );
        return (
          <div
            style={{
              display: "block",
              wordWrap: "break-word",
              whiteSpace: "normal",
            }}
          >
            <p
              style={{
                fontSize: "0.80em",

                color: "var(--REB-blue)",
                fontWeight: "bold",
              }}
            >
              {name}
            </p>
          </div>
        );
      },
      accessor: (d) =>
        d.dateFinished === null
          ? "-"
          : misc.parseAPIDateTimeToShortDate(
              misc.parseAPIDateTime(d.dateFinished)
            ),
    },
    {
      Header: () => (
        <div
          style={{
            display: "block",
            wordWrap: "break-word",
            whiteSpace: "normal",
          }}
        >
          <p
            style={{
              fontSize: "0.70em",
              color: "var(--REB-primary)",
              fontWeight: "bold",
            }}
          >
            Req. By
          </p>
        </div>
      ),
      id: "reqBy",
      filterable: false,
      maxWidth: 110,

      Cell: (row) => {
        const d = row.original;
        let name =
          d.requestedByActor === null
            ? "-"
            : `${d.requestedByActor.lastName}, ${d.requestedByActor.firstName}`;
        return (
          <div
            style={{
              display: "block",
              wordWrap: "break-word",
              whiteSpace: "normal",
            }}
          >
            <p
              style={{
                fontSize: "0.70em",

                color: "var(--REB-blue)",
                fontWeight: "bold",
              }}
            >
              {name}
            </p>
          </div>
        );
      },
      accessor: (d) =>
        d.requestedByActor === null
          ? "-"
          : `${d.requestedByActor.lastName}, ${d.requestedByActor.firstName}`,
    },
    // {
    //   Header: "Deadline",
    //   id: "deadline",
    //   filterable: false,
    //   maxWidth: 200,
    //   getProps: (row) => {
    //     return { style: { fontSize: "13px" } };
    //   },
    //   accessor: (d) =>
    //     d.deadline === null
    //       ? "-"
    //       : misc.parseAPIDateTimeToShortDate(misc.parseAPIDateTime(d.deadline)),
    // },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1.1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
          }}
        >
          Type
        </p>
      ),
      id: "type",
      accessor: (d) => (d.orderType === null ? "-" : d.orderType.name),
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        } else {
          //console.log(row[filter.id]);
          return row[filter.id] === filter.value;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value={"Add Item Qty Request"}>Add Item Qty Request</option>
          <option value={"Deduct Item Qty Request"}>
            Deduct Item Qty Request
          </option>
          <option value={"Return Item Request"}>Return Item Request</option>
        </select>
      ),
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1.1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
          }}
        >
          Status
        </p>
      ),
      id: "status",
      // getProps: (state, rowInfo) => {
      //   if (rowInfo && rowInfo.row) {
      //     return {
      //       style: {
      //         backgroundColor: rowInfo.row.status === "New" ? "red" : null,
      //         height: 80,
      //       },
      //     };
      //   } else {
      //     return {};
      //   }
      // },

      accessor: (d) => (d.orderStatus === null ? "-" : d.orderStatus.name),
      Cell: (row) => {
        const d = row.original;
        let name = "-";
        let dateFinished = "";
        name = d.orderStatus === null ? "-" : d.orderStatus.name;
        if (d.orderStatus.name === "Finished") {
          dateFinished =
            d.dateFinished === null
              ? "-"
              : misc.parseAPIDateTimeToShortDate(
                  misc.parseAPIDateTime(d.dateFinished)
                );
        }
        return (
          <div
            style={{
              display: "block",
              wordWrap: "break-word",
              whiteSpace: "normal",
              lineHeight: "0.8em",
              fontWeight: "bolder",
            }}
          >
            {name}{" "}
            <p
              style={{
                fontSize: "0.7em",
                lineHeight: "0.01em",
                color: "var(--REB-blue)",
                fontWeight: "bold",
                margin: 0,
                paddingTop: "0.7em",
              }}
            >
              {dateFinished}
            </p>
          </div>
        );
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        } else {
          //console.log(row[filter.id]);
          return row[filter.id] === filter.value;
        }
        // if (filter.value === "true") {
        //   return row[filter.id] >= 21;
        // }
        // return row[filter.id] < 21;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>

          <option value="Pending Admin Approval">Pending Admin Approval</option>
          <option value="Finished">Finished</option>

          <option value="Admin Denied">Admin Denied</option>
        </select>
      ),
    },
  ];
}
const ItemRequest = (props) => (
  <Row className="row-crud mb-3 mt-3" noGutters={true}>
    <Col md="auto" lg="auto" className="options-column">
      <InputGroup className="">
        <InputGroup.Prepend>
          <InputGroup.Text className="actor-ico-search">
            <i className="icofont-search icofont-1x"></i>
          </InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          placeholder="Search"
          aria-label="Username"
          name="searchInput"
          className="actor-searchTable shadow-none"
          aria-describedby="basic-addon1"
          onChange={props.state.handleSearch}
        />
      </InputGroup>
    </Col>
  </Row>
);
const ItemDetails = (props) => (
  <Row className="row-crud mb-3 mt-3" noGutters={true}>
    <Col md="auto" lg="auto" className="options-column">
      <InputGroup className="">
        <InputGroup.Prepend>
          <InputGroup.Text className="actor-ico-search">
            <i className="icofont-search icofont-1x"></i>
          </InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          placeholder="Search"
          aria-label="Username"
          name="searchInput"
          className="actor-searchTable shadow-none"
          aria-describedby="basic-addon1"
          onChange={props.state.handleSearch}
        />
      </InputGroup>
    </Col>
    <Col md="auto" lg="auto" className="user-select">
      <InputGroup>
        <select
          className="form-control actor-selector w-auto"
          name="itemDetailsSelect"
          value={props.value}
          onChange={props.state.handleActionChangeItemDetails}
          ref={props.itemDetailsSelectRef}
        >
          {props.state.itemDetailsConst.map((x) => {
            return (
              <>
                {x.hidden !== 1 ? (
                  <option
                    //ref={x.value === 1 ? props.testRef : null}
                    key={x.value}
                    value={x.value}
                  >
                    {x.name}
                  </option>
                ) : null}
              </>
            );
          })}
        </select>
      </InputGroup>
    </Col>

    <Col className="actor-btn-crud-col d-flex justify-content-end">
      <ButtonGroup className="">
        <OverlayTrigger
          placement={"top"}
          overlay={<Tooltip id={`actor-tooltip-add`}>ADD ITEM DETAILS</Tooltip>}
        >
          <Button
            variant="secondary"
            name="btnAddItemDetails"
            className="icofont-ui-add icofont-1x"
            style={{ backgroundColor: "var(--REB-primary)" }}
            onClick={(e) => {
              props.state.handleOperationChangeItemDetails(e);
            }}
          ></Button>
        </OverlayTrigger>
      </ButtonGroup>
    </Col>
  </Row>
);
const ItemGroups = (props) => (
  <Row className="row-crud mb-3" noGutters={true}>
    <Col md="auto" lg="auto" className="options-column">
      <InputGroup className="">
        <InputGroup.Prepend>
          <InputGroup.Text className="actor-ico-search">
            <i className="icofont-search icofont-1x"></i>
          </InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          placeholder="Search"
          aria-label="Username"
          name="searchInput"
          className="actor-searchTable shadow-none"
          aria-describedby="basic-addon1"
          onChange={props.state.handleSearch}
        />
      </InputGroup>
    </Col>

    <Col className="actor-btn-crud-col d-flex justify-content-end">
      <ButtonGroup className="">
        <OverlayTrigger
          placement={"top"}
          overlay={<Tooltip id={`actor-tooltip-add`}>ADD ITEM GROUP</Tooltip>}
        >
          <Button
            variant="secondary"
            name="btnAddActor"
            className="btnAddActor actor-btn-crud"
            onClick={(e) => {
              props.handleShowModal();
              props.state.handleActionChange(e);
            }}
          >
            <i className="icofont-ui-add icofont-1x"></i>
          </Button>
        </OverlayTrigger>
      </ButtonGroup>
    </Col>
  </Row>
);

function PDFGenerateReport(props) {
  const [btn, setBtn] = useState(false);
  //console.log(props);
  return (
    <>
      {/* <style>
      {`
        @media (max-width: 767px) {
          .inventorySelectContainer {
            margin-top: 10px;
            margin-bottom: 10px;
            justify-content: center;
          }
        }
      `}
    </style> */}
      <Dropdown className="btnPDFGenerateReport" as={ButtonGroup}>
        {(() => {
          if (btn) {
            return (
              <>
                <PDFDownloadLink
                  document={InvReport.InvReport(
                    props.state,
                    props.state.user,
                    props.state.chkDispImage.value
                  )}
                >
                  {({ blob, url, loading, error }) => {
                    if (loading) {
                      return (
                        <>
                          <Button
                            style={{ backgroundColor: "var(--REB-primary)" }}
                            disabled
                          >
                            <Spinner
                              size="xs"
                              animation="grow"
                              role="status"
                            ></Spinner>
                            loading
                          </Button>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <Button
                            name="downloadPDF"
                            style={{ backgroundColor: "var(--REB-primary)" }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              props.state.handleActionChange({
                                target: {
                                  value: blob,
                                  name: "downloadPDF",
                                },
                              });
                              setBtn(false);
                            }}
                          >
                            {" "}
                            Download Inventory Report
                          </Button>
                        </>
                      );
                    }
                  }}
                </PDFDownloadLink>
              </>
            );
          } else {
            return (
              <Button
                name="btnGeneratePDF"
                className=""
                style={{ backgroundColor: "var(--REB-tertiary)" }}
                block
                onClick={(e) => {
                  if (
                    props.state.permissions.includes(
                      "perm-ui-chkInventoryGenerateInvoice"
                    )
                  ) {
                    props.state.handleActionChange(e);
                  } else {
                    props.state.dispatch({
                      type: "MODAL_SHOW",
                      payload: {
                        title: "No Permission",
                        desc: `You dont have access to generate inventory report`,
                        isYesNo: false,
                      },
                    });
                  }

                  props.state.handleActionChange(
                    {
                      target: {
                        name: "btnGenerateInvReport",
                      },
                    },
                    null,

                    () => {
                      setBtn(true);
                    }
                  );
                }}
              >
                {" "}
                Generate Report
              </Button>
            );
          }
        })()}
        <Dropdown.Toggle
          // hidden={props.state.chkDispImage.readOnly}
          split
          style={{ backgroundColor: "var(--REB-primary)" }}
          id="dropdown-split-basic"
        />

        <Dropdown.Menu className="p-2" style={{ width: "300px" }}>
          <div
            style={{
              // backgroundColor: "var(--REB-primary)",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            hidden={props.state.chkDispImage.readOnly}
          >
            <Form.Check
              checked={props.state.chkDispImage.value}
              //disabled={props.state.chkDispImage.readOnly}
              style={{ paddingTop: "6.5px" }}
              name="chkDispImage"
              label="Show Images"
              onChange={(e) => {
                props.state.handleActionChange({
                  target: {
                    value: e.target,
                    name: "chkDispImage",
                  },
                });
              }}
            />
          </div>
          <Form.Label
            className="font-weight-bold mt-2"
            style={{ color: "var(--REB-black)" }}
          >
            Sort By
          </Form.Label>
          <select
            className="form-control actor-selector w-100"
            name="pdfSortBy"
            value={props.state.selPDFSort.value}
            onChange={props.state.handleActionChange}
          >
            <option value={"name"}>Name</option>
            <option value={"brand"}>Brand</option>
            <option value={"qty"}>Qty</option>
          </select>
          <Form.Label
            className="font-weight-bold mt-2"
            style={{ color: "var(--REB-black)" }}
          >
            Filter by Type
          </Form.Label>
          <Select
            styles={{
              menu: (provided, state) => {
                //console.log(state);
                const width = "100%";

                let color = state.selectProps.menuColor;
                const padding = 0;

                return { ...provided, width, color, padding };
              },
              control: (provided) => ({
                ...provided,
                borderRadius: "5px",
              }),

              container: (_, { selectProps: { width } }) => ({
                width: width,
                // flex: 1,
              }),
              menuPortal: (provided) => ({
                ...provided,
                zIndex: 9999,

                borderRadius: "5px",
              }),

              option: (provided, state) => {
                let backgroundColor = "";
                let display = "block";
                let color = "black";
                if (state.data.isDefault === 1) {
                  backgroundColor = "rgba(255, 0, 0, 0.2)";
                }
                const opacity = state.isDisabled ? 0.5 : 1;
                const transition = "opacity 300ms";
                //console.log(state.data);
                if (state.data.isHidden === 1) {
                  display = "none";
                }

                return {
                  ...provided,
                  opacity,
                  transition,
                  backgroundColor,
                  color,
                  display,
                };
              },
            }}
            isSearchable
            isClearable={true}
            // menuPortalTarget={document.body}
            // width="50%"
            options={props.state.selDetailType.data}
            value={props.state.selPDFSortByType.value}
            // isDisabled={props.state.selInventory.readOnly}
            getOptionLabel={(x) => x.name}
            getOptionValue={(x) => x.id}
            onChange={(x, name) => {
              props.state.inputChange({
                target: {
                  value: x,
                  name: "selPDFSortByType",
                },
              });
            }}
          />
          <Form.Label
            className="font-weight-bold mt-2"
            style={{ color: "var(--REB-black)" }}
          >
            Filter by Brand
          </Form.Label>
          <Select
            styles={{
              menu: (provided, state) => {
                //console.log(state);
                const width = "100%";

                let color = state.selectProps.menuColor;
                const padding = 0;

                return { ...provided, width, color, padding };
              },
              control: (provided) => ({
                ...provided,
                borderRadius: "5px",
              }),

              container: (_, { selectProps: { width } }) => ({
                width: width,
                // flex: 1,
              }),
              menuPortal: (provided) => ({
                ...provided,
                zIndex: 9999,

                borderRadius: "5px",
              }),

              option: (provided, state) => {
                let backgroundColor = "";
                let display = "block";
                let color = "black";
                if (state.data.isDefault === 1) {
                  backgroundColor = "rgba(255, 0, 0, 0.2)";
                }
                const opacity = state.isDisabled ? 0.5 : 1;
                const transition = "opacity 300ms";
                //console.log(state.data);
                if (state.data.isHidden === 1) {
                  display = "none";
                }

                return {
                  ...provided,
                  opacity,
                  transition,
                  backgroundColor,
                  color,
                  display,
                };
              },
            }}
            isSearchable
            isClearable={true}
            // menuPortalTarget={document.body}
            // width="50%"
            options={props.state.selDetailBrand.data}
            value={props.state.selPDFSortByBrand.value}
            // isDisabled={props.state.selInventory.readOnly}
            getOptionLabel={(x) => x.name}
            getOptionValue={(x) => x.id}
            onChange={(x, name) => {
              props.state.inputChange({
                target: {
                  value: x,
                  name: "selPDFSortByBrand",
                },
              });
            }}
          />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

const Items = (props) => {
  const permissions = useSelector((state) => {
    const user = state.apiData.userData;
    let _permissions = [];
    if (user && user.permissions) {
      _permissions = JSON.parse(user.permissions);
    }
    return _permissions.toString();
  });

  return (
    <Row className="row-crud  mt-3" noGutters={true}>
      <style>
        {`
          @media (max-width: 767px) {
            .inventorySelectContainer {
              margin-top: 10px;
              margin-bottom: 5px;
              justify-content: center;
            }
            .btnPDFGenerateReport {
              display: none;
            }
            .btnAddItemWeb {
              display: none !important;
            }
            .stage {
              padding-top: 0px !important;
              padding-bottom: 0px !important;
            }
          }
          @media (min-width: 767px) {
            .btnAddItemMobile {
              display: none !important;
            }
          }
        `}
      </style>
      <Col xs={12} sm={12} md={2} lg={2} className="options-column">
        <InputGroup className="h-100">
          <InputGroup.Prepend>
            <InputGroup.Text className="actor-ico-search">
              <i className="icofont-search icofont-1x"></i>
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder="Search"
            //aria-label="Username"
            //name="searchInput"
            className="actor-searchTable shadow-none h-100"
            //aria-describedby="basic-addon1"
            onChange={props.state.handleSearch}
          />

          {props.state.userType.includes("Admin") ||
          props.state.userType.includes("Manager") ||
          props.state.userType.includes("Sales Person") ||
          props.state.userType.includes("Purchaser") ||
          permissions.includes("perm-ui-inventoryPage-view-write") ? (
            <Button
              variant="secondary"
              name="btnAddItem"
              className=" btnAddItemMobile ml-2"
              style={{
                backgroundColor: "var(--REB-tertiary)",
              }}
              onClick={(e) => {
                props.state.handleActionChange(e);
              }}
            >
              ADD ITEM
            </Button>
          ) : null}
        </InputGroup>
      </Col>

      <Col xs={12} sm={12} md={4} lg={4} className="pl-3 my-2">
        <div
          className=""
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p
            className="m-0 pl-2"
            style={{
              backgroundColor: `${
                false ? "var(--REB-warning)" : "var(--REB-primary)"
              }`,

              color: "var(--REB-white)",
              fontWeight: "bold",
              fontSize: "0.8rem",
              width: "230px",
              borderRadius: "5px 5px 0px 0px",
            }}
          >
            SELECTED INVENTORY
          </p>
          <InventorySelect
            state={props.state}
            data={props.state.inventoryData}
            name="selInventoryName"
            selectContainerStyle={{ width: "100%" }}
            //isLoading={props.state.suppliers.isLoading}
            selected={props.state.selInventoryName.value}
            //tabIndex={7}
            lblHidden={true}
            refreshData={props.state.getInventory}
            addUrl="inventory"
            editUrl="inventory/updateInventory"
            deleteUrl="inventory/deleteInventory"
            componentName="Inventory"
            dataStructure="inventoryInventory"
            componentType=""
            propRef={props.InventorySelectRef}
            isDropHidden={!props.state.userType.includes("Admin")}
            hasAccess={props.state.userType}
            //optionLabel="name"
            handler={(x) => {
              //console.log(x);
              props.state.inputChange(x);
              props.state.handleInventoryChange(x);
            }}
            disabled={props.state.selInventoryName.readOnly}
            modifyRequiredPermission="perm-ui-inventoryPage-view-write-manage"
            deleteRequiredPermission="perm-ui-inventoryPage-view-write-manage-admin"
            userPermissions={props.state.permissions}
          />
        </div>
      </Col>

      <Col
        xs={2}
        className=""
        style={{ display: "flex", justifyContent: "center" }}
      >
        <PDFGenerateReport state={props.state} />
        {/* <InputGroup>
          <InputGroup.Prepend>
            
          </InputGroup.Prepend>
  
          <div
            style={{
              backgroundColor: "var(--REB-primary)",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            hidden={props.state.chkDispImage.readOnly}
          >
            <Form.Check
              checked={props.state.chkDispImage.value}
              //disabled={props.state.chkDispImage.readOnly}
              style={{ paddingTop: "6.5px", color: "white" }}
              name="chkDispImage"
              label="Show Imgs"
              onChange={(e) => {
                props.state.handleActionChange({
                  target: {
                    value: e.target,
                    name: "chkDispImage",
                  },
                });
              }}
            />
          </div>
          <InputGroup.Prepend>
            <InputGroup.Text
              style={{
                backgroundColor: "var(--REB-primary)",
                height: "fit-content",
              }}
            >
              <span
                style={{
                  alignSelf: "center",
                  color: "var(--REB-white)",
                  fontWeight: "bolder",
                }}
              >
                Sort By
              </span>
            </InputGroup.Text>
          </InputGroup.Prepend>
          <InputGroup.Prepend>
            <select
              className="form-control actor-selector w-auto"
              name="actorSelect"
              value={props.value}
              onChange={props.state.handleActorChange}
            >
              <option value={0}>No Filter</option>
              <option value={1}>Name</option>
              <option value={2}>Brand</option>
              <option value={3}>Qty</option>
            </select>
          </InputGroup.Prepend>
        </InputGroup> */}
      </Col>
      {props.state.userType.includes("Admin") ||
      props.state.userType.includes("Manager") ||
      props.state.userType.includes("Sales Person") ||
      props.state.userType.includes("Purchaser") ||
      permissions.includes("perm-ui-inventoryPage-view-write") ? (
        <Col className="actor-btn-crud-col d-flex justify-content-end btnAddItemWeb">
          <ButtonGroup className="">
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip id={`actor-tooltip-add`}>ADD ITEM</Tooltip>}
            >
              <Button
                variant="secondary"
                name="btnAddItem"
                className="btnAddItem "
                style={{
                  backgroundColor: "var(--REB-tertiary)",
                }}
                onClick={(e) => {
                  props.state.handleActionChange(e);
                }}
              >
                {" "}
                <i className="icofont-ui-add"></i> ADD
              </Button>
            </OverlayTrigger>
          </ButtonGroup>
        </Col>
      ) : null}
    </Row>
  );
};
const Inventory = (props) => (
  <div style={{ display: "flex", flexFlow: "column", height: "100%" }}>
    <style>
      {`
        @media (max-width: 991px) {
          .inventoryHeaderContainerStyle {
            border-radius: 0px !important;
            /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
            box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
              rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
              rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
            background-color: var(--REB-white) !important;
          }
          .stage {
            padding: 0px;
          }
        }
        @media (min-width: 992px) {
          .inventoryHeaderContainerStyle {
            border-radius: 10px !important;
            /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
            box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
              rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
              rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
            background-color: var(--REB-white) !important;
          }
        }
      `}
    </style>
    <div
      style={{ zIndex: "100" }}
      className="inventoryHeaderContainerStyle mt-2 p-3 animate__animated animate__fadeInLeft"
    >
      <Row noGutters={true}>
        <Col xs={12} sm={12} md={6} lg={6}>
          <InputGroup>
            <select
              className="form-control actor-selector w-auto"
              name="inventoryContentSelect"
              value={props.state.selectedContent.value}
              onChange={props.state.handleInventoryContentChange}
            >
              <option value={1}>Inventory Management</option>
              {props.state.userType.includes("Admin") ||
              props.userType.includes("Purchaser") ||
              props.userType.includes("Sales Person") ||
              props.state.userType.includes("Manager") ||
              props.state.permissions.includes(
                "perm-ui-inventoryPage-view-write-manage"
              ) ? (
                <option value={2}>Inventory Details Management</option>
              ) : null}
              {/* <option value={3}>Item Request Summary</option> */}
            </select>
          </InputGroup>
        </Col>
        <style>
          {`
            @media (max-width: 768px) {
              .colDateContainerInventory {
                display: none;
              }
            }
          `}
        </style>
        <Col
          className="colDateContainerInventory"
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{ textAlignLast: "end" }}
        >
          <h4>
            Date:{" "}
            <u className="actor-date-date">{moment().format("MMMM Do YYYY")}</u>
          </h4>
        </Col>
      </Row>
      {(() => {
        if (props.state.selectedContent.value === 1)
          return (
            <Items
              handleSearch={props.handleSearch}
              inputChange={props.inputChange}
              state={props.state}
              InventorySelectRef={props.InventorySelectRef}
            />
          );
        if (props.state.selectedContent.value === 2)
          return (
            <ItemDetails
              handleSearch={props.handleSearch}
              inputChange={props.inputChange}
              state={props.state}
              itemDetailsSelectRef={props.itemDetailsSelectRef}
            />
          );
        if (props.state.selectedContent.value === 3) {
          return (
            <ItemRequest
              handleSearch={props.handleSearch}
              inputChange={props.inputChange}
              state={props.state}
            />
          );
        }
      })()}
    </div>
    {props.tablelist}

    <ItemRequestModal state={props.state} inputChange={props.inputChange} />
    <ItemDetailsModal
      state={props.state}
      inputChange={props.inputChange}
      tableSetItems={props.tableSetItems}
    />
    <ItemModal
      state={props.state}
      inputChange={props.inputChange}
      PRItemHistoryTable={props.PRItemHistoryTable}
      ItemModalRef={props.ItemModalRef}
      ItemPurchaseHistoryTable={props.ItemPurchaseHistoryTable}
    />
  </div>
);

export {
  Inventory,
  itemColumn,
  Items,
  ItemDetails,
  ItemGroups,
  itemDetailsColumn,
  itemGroupsColumn,
  itemSetColumn,
  requestColumn,
  PurchaseHistoryColumns,
};
