import React, { useEffect, useState, useRef } from "react";
import Table from "react-table";
import "react-table/react-table.css";
import {
  //  Actor,
  supplierColumn,
  customerColumn,
  employeeColumn,
  ActorModal,
} from "./Actor.js";
//import LoadingProcess from "../Loading/LoadingProcess";
import Filters from "../../functions/filters";
import InventoryAPI from "../Inventory/InventoryAPI.js";
import Button from "react-bootstrap/Button";
//import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
//import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
//import ButtonGroup from "react-bootstrap/ButtonGroup";
import "../../css/actor.css";
//import Card from "react-bootstrap/Card";
//import { NumericFormat } from 'react-number-format';
//import misc from "../../functions/misc";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ActorAPI from "./ActorAPI.js";
import { SELECTED_OPERATION } from "../../constants";
//let CryptoJS = require("crypto-js");
const InitActorInputValidation = {
  TxtActorName: { isInvalid: false, msg: [] },
  TxtFirstName: { isInvalid: false, msg: [] },
  TxtLastName: { isInvalid: false, msg: [] },
  TxtUserName: { isInvalid: false, msg: [] },
  TxtPassword: { isInvalid: false, msg: [] },
  TxtConfirmPass: { isInvalid: false, msg: [] },
};

export const ActorProcess2 = (props) => {
  const dispatch = useDispatch();
  const [UserType, setUserType] = useState([]);
  const [SelActorType, setSelActorType] = useState(0);

  const [ChkAdmin, setChkAdmin] = useState(false);

  const [ChkExecutive, setChkExecutive] = useState(false);

  const [ChkManager, setChkManager] = useState(false);

  const [ChkDriver, setChkDriver] = useState(false);

  const [ChkSalesPerson, setChkSalesPerson] = useState(false);
  const [ChkPurchaser, setChkPurchaser] = useState(false);
  const [ChkInvClerk, setChkInvClerk] = useState(false);

  const [ChkUser, setChkUser] = useState(false);
  const [ChkBranchMngr, setChkBranchMngr] = useState(false);
  const [ChkUserInvoice, setChkUserInvoice] = useState(false);
  const [ChkEmployee, setChkEmployee] = useState(false);
  const [ChkPayee, setChkPayee] = useState(false);

  const [ChkAllowChargeInvoice, setChkAllowChargeInvoice] = useState(false);

  //Verification Permissions Controls

  const [ChkVeriQuote, setChkVeriQuote] = useState("");
  const [ChkVeriDR, setChkVeriDR] = useState("");
  const [ChkVeriPRPO, setChkVeriPRPO] = useState("");
  const [ChkVeriVoucher, setChkVeriVoucher] = useState("");
  const [ChkVeriInvRequest, setChkVeriInvRequest] = useState("");
  const [ChkVeriInvTransfer, setChkVeriInvTransfer] = useState("");

  //Page Specific Permissions Controls

  const [ChkPOS, setChkPOS] = useState("");
  const [ChkPOSMarkdown, setChkPOSMarkdown] = useState("");
  const [ChkPOSMarkup, setChkPOSMarkup] = useState("");
  const [ChkTransactionPage, setChkTransactionPage] = useState("");
  const [ChkAccountingPage, setChkAccountingPage] = useState("");

  const [ChkInventoryPage, setChkInventoryPage] = useState("");
  const [ChkInventoryDetailsPage, setChkInventoryDetailsPage] = useState("");

  const [ChkInvoicePage, setChkInvoicePage] = useState("");
  const [ChkInvoiceDetailsPage, setChkInvoiceDetailsPage] = useState("");

  const [ChkActorPage, setChkActorPage] = useState("");

  const [ChkQuotationPage, setChkQuotationPage] = useState("");
  const [ChkPurchaseRequestPage, setChkPurchaseRequestPage] = useState("");
  const [ChkTransferInventoryPage, setChkTransferInventoryPage] = useState("");

  const [ChkVerificationPage, setChkVerificationPage] = useState("");

  //Invoice Generation Permissions
  const [ChkSalesReceipt, setChkSalesReceipt] = useState(false);
  const [ChkPayeeBilling, setChkPayeeBilling] = useState(false);
  const [ChkVoucherBilling, setChkVoucherBilling] = useState(false);
  const [ChkAcknowledgementReceipt, setChkAcknowledgementReceipt] =
    useState(false);
  const [ChkChargeInvoice, setChkChargeInvoice] = useState(false);
  const [ChkDRInvoice, setChkDRInvoice] = useState(false);

  const [ChkQuoteInvoice, setChkQuoteInvoice] = useState(false);
  const [ChkPRPOInvoice, setChkPRPOInvoice] = useState(false);
  const [ChkInventoryGenerateInvoice, setChkInventoryGenerateInvoice] =
    useState(false);

  //Invoice Access Permissions
  const [ChkInvQuote, setChkInvQuote] = useState(false);
  const [ChkInvDR, setChkInvDR] = useState(false);
  const [ChkInvPR, setChkInvPR] = useState(false);
  const [ChkInvTransfer, setChkInvTransfer] = useState(false);
  const [ChkInvRequest, setChkInvRequest] = useState(false);

  //Payment Permissions
  const [ChkPaymentAccess, setChkPaymentAccess] = useState("");

  const [ChkInvoicingAccess, setChkInvoicingAccess] = useState("");

  // const [HandleActorModal, setHandleActorModal] = useState();
  //Input
  const [TxtActorName, setTxtActorName] = useState("");
  const [TxtFirstName, setTxtFirstName] = useState("");

  const [TxtLastName, setTxtLastName] = useState("");
  const [TxtUserName, setTxtUserName] = useState("");
  const [TxtPassword, setTxtPassword] = useState("");
  const [TxtConfirmPass, setTxtConfirmPass] = useState("");
  const [TxtEmail, setTxtEmail] = useState("");
  const [TxtContact, setTxtContact] = useState("");
  const [TxtAddress, setTxtAddress] = useState("");
  const [TxtWebsite, setTxtWebsite] = useState("");
  const [TxtMaxDiscount, setTxtMaxDiscount] = useState("");
  const [SelPermission, setSelPermission] = useState("");
  const [BtnEditPW, setBtnEditPW] = useState("");
  const [FileUpload, setFileUpload] = useState(null);

  /// GetFromDB

  // const [SelectAll, setSelectAll] = useState(false);
  const [Selection, setSelection] = useState([]);
  // const [SearchInput, setSearchInput] = useState("");
  const [ActorSelection, setActorSelection] = useState(null);
  // const [CheckBoxTable, setCheckBoxTable] = useState({});
  const [Columns, setColumns] = useState(employeeColumn);
  const [SelectedActor, setSelectedActor] = useState({ id: 1, name: "User" });
  const [SelectedOperation, setSelectedOperation] = useState(
    SELECTED_OPERATION.VIEW
  );

  const [IsPWDisabled, setIsPWDisabled] = useState(true);
  // const [IsLoading, setIsLoading] = useState(false);
  const [IsFiltered, setIsFiltered] = useState(false);
  const [IsPWEdit, setIsPWEdit] = useState(false);
  const [IsPWVisible, setIsPWVisible] = useState(false);

  const [Inventories, setInventories] = useState([]);
  const [SelInventory, setSelInventory] = useState(0);
  const [Permissions, setPermissions] = useState([]);
  const user = useSelector((state) => state.apiData.userData);
  const [Actors, setActors] = useState([]);
  const [ActorsCopy, setActorsCopy] = useState([]);
  const [IsActorModalOpen, setIsActorModalOpen] = useState(false);
  const [ActorInputValidation, setActorInputValidation] = useState(
    InitActorInputValidation
  );
  const [CheckedUIPermission, setCheckedUIPermission] = useState(null);
  const permissions = useSelector((state) => {
    const user = state.apiData.userData;
    let _permissions = [];
    if (user && user.permissions) {
      _permissions = JSON.parse(user.permissions);
    }
    return _permissions.toString();
  });

  const socketio = useSelector((state) => state.SocketHandler.socket);

  const HandleRefreshOnlineRef = useRef();

  const HandleRefreshOnline = (data) => {
    const { actorId, status } = data;

    const MODIFIED_ACTORS = Actors.map((x) => {
      if (x.id === actorId) {
        return { ...x, isOnline: status === "ONLINE" ? 1 : 0 };
      } else {
        return x;
      }
    });
    setActors([...MODIFIED_ACTORS]);
    setActorsCopy([...MODIFIED_ACTORS]);
  };
  HandleRefreshOnlineRef.current = HandleRefreshOnline;

  useEffect(() => {
    const getInventory = async () => {
      const inventories = await InventoryAPI.getInventory(dispatch);
      if (inventories) {
        setInventories(inventories);
      }
    };
    getInventory();

    const onRefreshOnline = (data) => {
      if (data?.actorId) {
        HandleRefreshOnlineRef.current(data);
      }
    };

    socketio.on("refreshOnline", onRefreshOnline);
    return () => {
      socketio.off("refreshOnline", onRefreshOnline);
    };
  }, []);

  useEffect(() => {
    const { userType } = props;
    setUserType(userType);

    if (user?.types) {
      if (user.types.find((x) => x.name === "Admin")) {
        setSelActorType(1);
      }
      if (user.types.find((x) => x.name === "Manager")) {
        setSelActorType(6);
      }
      if (user.types.find((x) => x.name === "Sales Person")) {
        setSelActorType(6);
      }
      if (user.types.find((x) => x.name === "Purchaser")) {
        setSelActorType(7);
      }
    }
  }, [user]);

  useEffect(() => {
    const _SelActorType = Number(SelActorType);

    if (_SelActorType > 0) {
      getActorByType();
    }
  }, [SelActorType]);

  useEffect(() => {
    let tempPermissions = Permissions;

    //For Checkbox Controls
    if (CheckedUIPermission && !CheckedUIPermission.isSelect) {
      if (CheckedUIPermission.checked) {
        tempPermissions.push(CheckedUIPermission.permission);

        setPermissions(tempPermissions);
      } else {
        tempPermissions = tempPermissions.filter(
          (x) => x !== CheckedUIPermission.permission
        );
        setPermissions(tempPermissions);
      }
    }

    // For Select Controls
    if (CheckedUIPermission && CheckedUIPermission.isSelect) {
      //const regex = new RegExp(`^${CheckedUIPermission.controlName}`);

      tempPermissions = tempPermissions.filter(
        (e) => !e.includes(CheckedUIPermission.controlName)
      );

      // tempPermissions = tempPermissions.filter(
      //   (x) => regex.test(x)
      // );
      if (CheckedUIPermission.permission) {
        tempPermissions.push(CheckedUIPermission.permission);
      }

      setPermissions(tempPermissions);
    }
     console.log(tempPermissions);
  }, [CheckedUIPermission]);

  //functions
  const ValidateActor = (cb) => {
    // setActorInputValidation(InitActorInputValidation);
    let re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
    let tempActorValidation = InitActorInputValidation;
    if (SelActorType < 6) {
      if (TxtFirstName === null || TxtFirstName === "") {
        let _error = [];
        _error.push("First Name can't be empty");
        tempActorValidation = {
          ...tempActorValidation,
          TxtFirstName: { isInvalid: true, msg: _error },
        };
      }
      if (TxtLastName === null || TxtLastName === "") {
        let _error = [];
        _error.push("Last Name can't be empty");
        tempActorValidation = {
          ...tempActorValidation,
          TxtLastName: { isInvalid: true, msg: _error },
        };
      }
      if (TxtUserName === null || TxtUserName === "") {
        let _error = [];
        _error.push("Username can't be empty");
        tempActorValidation = {
          ...tempActorValidation,
          TxtUserName: { isInvalid: true, msg: _error },
        };
      }

      //Only modify password when edit user clicks on modify password
      if (IsPWEdit) {
        if (!re.exec(TxtPassword)) {
          let _error = [];
          _error.push(
            "Password must contain atleast one digit, upper case, lower case and atleast 6 characters"
          );
          tempActorValidation = {
            ...tempActorValidation,
            TxtPassword: { isInvalid: true, msg: _error },
          };
        }
        if (TxtConfirmPass !== TxtPassword) {
          let _error = [];
          _error.push("Password mismatch");
          tempActorValidation = {
            ...tempActorValidation,
            TxtConfirmPass: { isInvalid: true, msg: _error },
          };
        }
        if (TxtPassword === null || TxtPassword === "") {
          let _error = [];
          _error.push("Password can't be empty");
          tempActorValidation = {
            ...tempActorValidation,
            TxtPassword: { isInvalid: true, msg: _error },
          };
        }
        if (TxtConfirmPass === null || TxtConfirmPass === "") {
          let _error = [];
          _error.push("Confirm Password can't be empty");
          tempActorValidation = {
            ...tempActorValidation,
            TxtConfirmPass: { isInvalid: true, msg: _error },
          };
        }
      }
    }
    if (SelActorType >= 6) {
      if (TxtActorName === null || TxtActorName === "") {
        let _error = [];
        _error.push("Client can't be empty");
        tempActorValidation = {
          ...tempActorValidation,
          TxtActorName: { isInvalid: true, msg: _error },
        };
      }
    }
    //console.log(tempActorValidation);
    setActorInputValidation(tempActorValidation);
    let err = false;
    Object.entries(tempActorValidation).forEach((element) => {
      if (element[1].isInvalid === true) err = true;
      //console.log(element[1]);
    });
    if (err) {
      dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Validation warning",
          desc: `please fill in the form properly`,
          isYesNo: false,
        },
      });
      return true;
    } else {
      if (cb) {
        cb();
      }
    }
    // if (!err) {
    //   if (cb) {
    //     cb();
    //   } else {

    //   }
    // }
  };

  const getActorByType = async () => {
    let _ActorType = SelActorType;
    if (SelActorType === 1 && user.types.find((x) => x.name === "Admin")) {
      _ActorType = "1,2,3,4,5,8,9,10,11";
    }
    if (SelActorType === 12) {
      _ActorType = "12";
    }
    if (SelActorType === 6) {
      _ActorType = "6";
      //console.log("SelActor = ", SelActorType);
    }
    if (SelActorType === 7) {
      _ActorType = "7";
      //console.log("SelActor = ", SelActorType);
    }
    if (SelActorType === 13) {
      _ActorType = "13";
      //console.log("SelActor = ", SelActorType);
    }
    const actors = await ActorAPI.getActorsByType(dispatch, _ActorType);

    if (actors) {
      setActors(actors);
      setActorsCopy(actors);
    }

    if (SelActorType === 1) {
      //console.log(user)
      if (user.types.find((x) => x.name === "Admin")) {
        setColumns(employeeColumn);
      } else {
        setColumns(supplierColumn);
      }
    }
    // if (_SelActorType === 5) {
    //   setColumns(customerColumn);
    // }
    if (SelActorType >= 5) {
      //setColumns(customerColumn);
      setColumns(supplierColumn);
    }
    // if (_SelActorType === 7) {
    //   setColumns(supplierColumn);
    // }
  };

  const handleSearch = (event) => {
    if (event.target.value !== "") {
      setIsFiltered(true);

      const data = Filters.filterData(ActorsCopy, event.target.value);

      if (data != null) {
        setActors(data);
      } else {
        setActors(ActorsCopy);
      }
    } else {
      setActors(ActorsCopy);
      setIsFiltered(false);
    }
  };

  const toggleSelection = (key, shift, row) => {
    let selection = Selection;
    const keyIndex = Selection.indexOf(key);

    if (keyIndex >= 0) {
      selection = [];
      ActorSelection(null);
    } else {
      selection.length = 0;
      selection.push(key);
      ActorSelection(row);
      // this.clearInputs();
      // this.populateModal(row, false, true);
    }

    this.setState((x) => ({
      selection,
    }));
  };

  const rowFn = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.target.name === "btnViewRow") {
        //console.log(e.target.getAttribute("id"));
        // this.populateModal(rowInfo.original, true, true);
        // this.setState((x) => ({
        //   handleActorModal: {
        //     ...x.handleActorModal,
        //     isShow: true,
        //   },
        // }));
      }
      if (
        e.currentTarget.className.search("expandable") < 0 &&
        e.target.name !== "btnViewRow"
      ) {
        if (
          Selection.length > 0 &&
          Selection[0] === `select-${rowInfo.original.id}`
        ) {
          setSelection([]);
        } else {
          if (rowInfo) {
            // this.toggleSelection(rowInfo.index, null, rowInfo.original);
            // this.handleActionChange({ target: { name: "btnEditActor" } });
            console.log("SELECTED ACTOR ===> ", rowInfo.original);
            PopulateActorModal(rowInfo.original);
            setIsActorModalOpen(true);
            // this.populateModal(rowInfo.original, true, true);
            // this.setState((x) => ({
            //   handleActorModal: {
            //     ...x.handleActorModal,

            //     isShow: true,
            //   },
            // }));
            setSelection(["select-" + rowInfo.original.id]);
            setSelectedActor(rowInfo.original);
          }
        }
      }

      if (handleOriginal) {
        handleOriginal();
      }
    },
    style: {
      background:
        rowInfo &&
        Selection.includes(`select-${rowInfo.original.id}`) &&
        "var(--REB-secondary)",
      color:
        rowInfo &&
        Selection.includes(`select-${rowInfo.original.id}`) &&
        "white",
    },
  });

  const tablelist = (
    <>
      {/* <style type="text/css">
          {`

      .tableActor{
        border-radius: 10px !important;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        background-color: var(--REB-white) !important;
      }
      .rt-thead{
        padding-right: 20px;
      }
       .rt-th{
        // scrollbar-gutter: stable both-edges;

        border:none;
      }
      .rt-td{
        border:none;
      }

    `}
        </style> */}
      <style>
        {`
          @media (max-width: 991px) {
            .tableActor {
              border-radius: 0px !important;
              background-color: var(--REB-white) !important;
              margin-top: 0px !important;
              margin-bottom: 0px;
            }
            .inventoryHeaderContainerStyle {
              margin-top: 0px !important;
              box-shadow: none;
              padding-bottom: 5px !important;
            }
          }
          @media (min-width: 992px) {
            .tableActor {
              border-radius: 10px !important;
              box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
              background-color: var(--REB-white) !important;
              margin-top: 30px;
            }
          }
        `}
      </style>
      <Table
        data={Actors}
        columns={Columns}
        // keyField="id"
        // {...this.props}
        // ref={(r) => (this.checkboxTable = r)}
        showPageSizeOptions={false}
        toggleSelection={toggleSelection}
        //selectAll={this.state.selectAll}
        //selectType="checkbox"
        // loading={this.state.isLoading}
        isSelected={(key) => {
          return Selection.includes(`select-${key}`);
        }}
        getTrProps={rowFn}
        defaultPageSize={50}
        style={{ overflow: "auto", flex: 1 }}
        className="tableActor -highlight mt-3"
      />
    </>
  );

  const handleFileUploadSubmit = (e) => {
    // let urlUpload = "global/uploadFiles";
    let currentFiles = FileUpload.value;
    //console.log(currentFiles);

    // const fileType = currentFiles[0].type.split("/")[1];

    // const formData = new FormData();
    // formData.append("uniqueId", ActorSelection.uniqueId);
    // for (let index = 0; index < currentFiles.length; index++) {
    //   formData.append(
    //     "uploadfiles",
    //     currentFiles[index],
    //     `signature.${fileType}`
    //   );
    // }
  };

  const PopulateActorModal = (row) => {
    ClearActorModal();
    setTxtActorName(row.company ? row.company : "");
    setTxtFirstName(row.firstName ? row.firstName : "");
    setTxtLastName(row.lastName ? row.lastName : "");
    setTxtUserName(row.username ? row.username : "");
    setTxtEmail(row.email ? row.email : "");
    setTxtContact(row.contact ? row.contact : "");
    setTxtAddress(row.address ? row.address : "");
    row.types.forEach((x) => {
      if (x.id === 1) {
        setChkAdmin(true);
      }
      if (x.id === 2) {
        setChkExecutive(true);
      }
      if (x.id === 3) {
        setChkManager(true);
      }
      if (x.id === 4) {
        setChkDriver(true);
      }
      if (x.id === 5) {
        setChkSalesPerson(true);
      }
      if (x.id === 8) {
        setChkPurchaser(true);
      }
      if (x.id === 9) {
        setChkInvClerk(true);
      }
      if (x.id === 10) {
        setChkUser(true);
      }
      if (x.id === 11) {
        setChkBranchMngr(true);
      }
      if (x.id === 12) {
        setChkEmployee(true);
      }
      if (x.id === 13) {
        setChkPayee(true);
      }
    });

    const ParsedPermission = row.permissions ? JSON.parse(row.permissions) : [];
    setPermissions(ParsedPermission);

    //   const toRemove = ["perm-ui-"
    //   // , "-write", "-view", "-manage"
    // ];

    //   const componentName = ParsedPermission.map((str) => {
    //     toRemove.forEach((remove) => {
    //       str = str.replace(remove, "");
    //     });
    //     return str;
    //   });

    //   console.log(componentName);

    //   if (componentName === "chkPOS") {
    //     setChkPOS(true);
    //   }
    //   if (componentName === "chkUserInvoice") {
    //     setChkUserInvoice(true);
    //   }
    //   if (componentName === "chkTransactionPage") {
    //     setChkTransactionPage(true);
    //   }
    //   if (componentName === "chkAcknowledgementReceipt") {
    //     setChkAcknowledgementReceipt(true);
    //   }
    //   if (componentName === "chkChargeInvoice") {
    //     setChkChargeInvoice(true);
    //   }
    //   if (componentName === "chkAccountingPage") {
    //     setChkAccountingPage(true);
    //   }
    //   if (componentName === "chkAccountingPage") {
    //     setChkTransactionPage(true);
    //   }
    //   if (componentName === "chkAccountingPage") {
    //     setChkAccountingPage(true);
    //   }

    ParsedPermission.forEach((e) => {
      const regex = /perm-ui-/gi;

      if (e.match(regex)) {
        const componentName = e.replace(regex, "");

        if (componentName === "chkPOS") {
          setChkPOS(true);
        }
        if (componentName === "chkPOSMarkdown") {
          setChkPOSMarkdown(true);
        }
        if (componentName === "chkPOSMarkup") {
          setChkPOSMarkup(true);
        }
        if (componentName === "chkUserInvoice") {
          setChkUserInvoice(true);
        }
        if (componentName === "chkTransactionPage") {
          setChkTransactionPage(true);
        }
        if (componentName === "chkAcknowledgementReceipt") {
          setChkAcknowledgementReceipt(true);
        }
        if (componentName === "chkChargeInvoice") {
          setChkChargeInvoice(true);
        }
        if (componentName === "chkAccountingPage") {
          setChkAccountingPage(true);
        }

        if (componentName === "chkDRInvoice") {
          setChkDRInvoice(true);
        }
        if (componentName === "chkVoucherBilling") {
          setChkVoucherBilling(true);
        }
        if (componentName === "chkPayeeBilling") {
          setChkPayeeBilling(true);
        }
        if (componentName === "chkSalesReceipt") {
          setChkSalesReceipt(true);
        }

        if (componentName === "chkQuoteInvoice") {
          setChkQuoteInvoice(true);
        }
        if (componentName === "chkInventoryGenerateInvoice") {
          setChkInventoryGenerateInvoice(true);
        }
        if (componentName === "chkPRPOInvoice") {
          setChkPRPOInvoice(true);
        }
        if (componentName === "chkAllowChargeInvoice") {
          setChkAllowChargeInvoice(true);
        }
        
        // if (componentName === "chkInvQuote") {
        //   setChkInvQuote(true);
        // }
        // if (componentName === "chkInvDR") {
        //   setChkInvDR(true);
        // }
        // if (componentName === "chkInvPR") {
        //   setChkInvPR(true);
        // }
        // if (componentName === "chkInvTransfer") {
        //   setChkInvTransfer(true);
        // }

        if (componentName.includes("invoicingQuoteAccess")) {
          setChkInvQuote({ permission: `perm-ui-${componentName}` });
        }
        if (componentName.includes("invoicingDRAccess")) {
          setChkInvDR({ permission: `perm-ui-${componentName}` });
        }
        if (componentName.includes("invoicingPRAccess")) {
          setChkInvPR({ permission: `perm-ui-${componentName}` });
        }
        if (componentName.includes("invoicingInvTransferAccess")) {
          setChkInvTransfer({ permission: `perm-ui-${componentName}` });
        }
        if (componentName.includes("invoicingInvItemRequestAccess")) {
          setChkInvRequest({ permission: `perm-ui-${componentName}` });
        }
        if (componentName.includes("verificationAccess")) {
          setChkVerificationPage({ permission: `perm-ui-${componentName}` });
        }

        if (componentName.includes("transactionPage")) {
          setChkTransactionPage({ permission: `perm-ui-${componentName}` });
        }
        if (componentName.includes("accountingPage")) {
          setChkAccountingPage({ permission: `perm-ui-${componentName}` });
        }
        if (componentName.includes("inventoryPage")) {
          setChkInventoryPage({ permission: `perm-ui-${componentName}` });
        }
        if (componentName.includes("invoicePaymentAccess")) {
          setChkPaymentAccess({ permission: `perm-ui-${componentName}` });
        }
        if (componentName.includes("invoicingPage")) {
          setChkInvoicingAccess({ permission: `perm-ui-${componentName}` });
        }

        if (componentName.includes("quoteVerification")) {
          setChkVeriQuote({ permission: `perm-ui-${componentName}` });
        }

        if (componentName.includes("DRVerification")) {
          setChkVeriDR({ permission: `perm-ui-${componentName}` });
        }
        if (componentName.includes("PRPOVerification")) {
          setChkVeriPRPO({ permission: `perm-ui-${componentName}` });
        }
        if (componentName.includes("vouchersVerification")) {
          setChkVeriVoucher({ permission: `perm-ui-${componentName}` });
        }
        if (componentName.includes("inventoryRequestsVerification")) {
          setChkVeriInvRequest({ permission: `perm-ui-${componentName}` });
        }
        if (componentName.includes("inventoryTransferVerification")) {
          setChkVeriInvTransfer({ permission: `perm-ui-${componentName}` });
        }
      }
    });
  };

  const ClearActorModal = () => {
    setTxtActorName("");
    setTxtFirstName("");
    setTxtLastName("");
    setTxtUserName("");
    setTxtEmail("");
    setTxtContact("");
    setTxtAddress("");
    setTxtPassword("");
    setTxtConfirmPass("");
    setActorInputValidation(InitActorInputValidation);
    setChkUser(false);
    setChkAdmin(false);
    setChkExecutive(false);
    setChkManager(false);
    setChkPurchaser(false);
    setChkSalesPerson(false);
    setChkDriver(false);
    setChkInvClerk(false);
    setChkBranchMngr(false);
    setChkEmployee(false);
    setChkPayee(false);
    setPermissions([]);
    setChkPOS(false);
    setChkUserInvoice(false);
    setChkChargeInvoice(false);
    setChkAcknowledgementReceipt(false);
    setChkTransactionPage(false);
    setChkAccountingPage(false);

    setChkDRInvoice(false);
    setChkInvQuote(false);
    setChkInvDR(false);
    setChkInvPR(false);
    setChkInvTransfer(false);
    setChkInvRequest(false);

    setChkPOSMarkdown(false);

    setChkPOSMarkup(false);
    setChkQuoteInvoice(false);
    setChkInventoryGenerateInvoice(false);
    setChkPRPOInvoice(false);

    setChkVeriQuote(false);

    setChkVeriDR(false);

    setChkVeriPRPO(false);

    setChkVeriVoucher(false);

    setChkVeriInvRequest(false);

    setChkVeriInvTransfer(false);

    setChkVerificationPage(false);

    setChkPaymentAccess(false);
    setChkInventoryPage(false);
    setChkInvoicePage(false);
    setChkInvoicingAccess(false);

    setChkSalesReceipt(false);
    setChkPayeeBilling(false);
    setChkVoucherBilling(false);
    setChkAllowChargeInvoice(false);
  };

  const AddActorInventory = () => {
    //console.log(SelInventory);
    if (SelInventory) {
      const tempPermissions = Permissions;

      tempPermissions.push(`inv-${SelInventory}`);
      setPermissions(tempPermissions);
      console.log(tempPermissions);
      setSelInventory(0);
    } else {
      dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "No Selection",
          desc: `Please select inventory`,
          isYesNo: false,
        },
      });
    }
  };
  const RemoveActorInventory = (value) => {
    let tempPermissions = Permissions;

    tempPermissions = tempPermissions.filter((x) => x !== value);
    console.log(tempPermissions);
    setPermissions(tempPermissions);
    setSelInventory(0);
  };

  const getActorPermission = () => {
    let permission = [];
    if (ChkAdmin) permission.push(1);
    if (ChkExecutive) permission.push(2);
    if (ChkManager) permission.push(3);
    if (ChkDriver) permission.push(4);
    if (ChkSalesPerson) permission.push(5);
    if (ChkPurchaser) permission.push(8);
    if (ChkInvClerk) permission.push(9);
    if (ChkUser) permission.push(10);
    if (ChkBranchMngr) permission.push(11);
    if (ChkEmployee) permission.push(12);
    if (ChkPayee) permission.push(13);

    if (Number(SelActorType) === 6) permission.push(6);
    if (Number(SelActorType) === 7) permission.push(7);
    if (Number(SelActorType) === 12) permission.push(12);
    if (Number(SelActorType) === 13) permission.push(13);

    return permission;
  };

  const CreateActor = () => {
    let urlActorAdd = "actor";
    let userType = null;
    const selId = Number(SelectedActor);

    userType = getActorPermission();
    // if (selId === 1 || selId === 4) {
    //   userType = getActorPermission();
    // } else {
    //   userType = [selId];
    // }

    let data = {
      company: TxtActorName,
      firstName: TxtFirstName,
      lastName: TxtLastName,
      address: TxtAddress,
      email: TxtEmail,
      contact: TxtContact,
      username: TxtUserName,
      password: TxtPassword,
      maxDiscount: TxtMaxDiscount === "" ? 0 : Number(TxtMaxDiscount),
      website: TxtWebsite.toUpperCase(),
      types: userType,
      permissions: JSON.stringify(Permissions),
    };
    console.log("PRE-POST ADD DATA ===> ", data);

    new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "post",
        data: data,
        payload: urlActorAdd,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `${TxtLastName} Added Successfully`,
        },
      });
      setSelectedActor(null);
      setActorSelection([]);
      getActorByType();
      setTxtPassword("");
      setTxtConfirmPass("");
      // this.readActor(["1,2,3,4,5,8"]);
      //getActorByType();
    });
  };

  const ModifyActor = () => {
    //console.log("SelectedActor", SelectedActor);
    const ActorId = SelectedActor.id;

    let userType = null;
    userType = getActorPermission();
    // const selId = Number(SelectedActor);

    // if (selId === 1 || selId === 4) {
    //   userType = getActorPermission();
    // } else {
    //   userType = [selId];
    // }
    let data = null;
    if (!IsPWDisabled) {
      data = {
        id: ActorId,
        company: TxtActorName,
        firstName: TxtFirstName,
        lastName: TxtLastName,
        address: TxtAddress,
        email: TxtEmail,
        contact: TxtContact,
        username: TxtUserName,
        maxDiscount: TxtMaxDiscount === "" ? 0 : Number(TxtMaxDiscount),
        website: TxtWebsite.toUpperCase(),
        types: userType,
        permissions: JSON.stringify(Permissions),
      };
    } else {
      data = {
        id: ActorId,
        company: TxtActorName,
        firstName: TxtFirstName,
        lastName: TxtLastName,
        address: TxtAddress,
        email: TxtEmail,
        contact: TxtContact,
        username: TxtUserName,
        password: TxtPassword,
        maxDiscount: TxtMaxDiscount === "" ? 0 : Number(TxtMaxDiscount),
        website: TxtWebsite.toUpperCase(),
        types: userType,
        permissions: JSON.stringify(Permissions),
      };
    }
    console.log("PRE-POST EDIT DATA ===> ", data);

    let urlUpdateActor = "actor/updateActor";
    //console.log(data);
    new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "put",
        data: data,
        payload: urlUpdateActor,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      //this.readActor(["1,2,3,4,5,8"]);
      dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `${SelectedActor.lastName} Edited Successfully`,
        },
      });

      setSelectedActor(null);
      setActorSelection([]);

      getActorByType();
    });
  };

  const DeleteActor = (e) => {
    let dat = {
      id: Selection[0].slice(7),
    };

    let urlActorDelete = "actor/deleteActor";

    new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "delete",
        data: dat,
        payload: urlActorDelete,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      //this.readActor(["1,2,3,4,5,8"]);
      dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `${SelectedActor.lastName} Deleted Successfully`,
        },
      });
      setSelectedActor(null);
      setActorSelection([]);

      getActorByType();
    });
  };

  return (
    <div style={{ display: "flex", flexFlow: "column", height: "100%" }}>
      <ActorModal
        IsActorModalOpen={IsActorModalOpen}
        setIsActorModalOpen={setIsActorModalOpen}
        //inputs
        TxtActorName={TxtActorName}
        setTxtActorName={setTxtActorName}
        TxtFirstName={TxtFirstName}
        setTxtFirstName={setTxtFirstName}
        TxtLastName={TxtLastName}
        setTxtLastName={setTxtLastName}
        TxtUserName={TxtUserName}
        setTxtUserName={setTxtUserName}
        TxtPassword={TxtPassword}
        setTxtPassword={setTxtPassword}
        TxtConfirmPass={TxtConfirmPass}
        setTxtConfirmPass={setTxtConfirmPass}
        TxtEmail={TxtEmail}
        setTxtEmail={setTxtEmail}
        TxtContact={TxtContact}
        setTxtContact={setTxtContact}
        TxtAddress={TxtAddress}
        setTxtAddress={setTxtAddress}
        TxtWebsite={TxtWebsite}
        setTxtWebsite={setTxtWebsite}
        TxtMaxDiscount={TxtMaxDiscount}
        setTxtMaxDiscount={setTxtMaxDiscount}
        SelPermission={SelPermission}
        setSelPermission={setSelPermission}
        BtnEditPW={BtnEditPW}
        setBtnEditPW={setBtnEditPW}
        FileUpload={FileUpload}
        setFileUpload={setFileUpload}
        handleFileUploadSubmit={handleFileUploadSubmit}
        //
        IsPWDisabled={IsPWDisabled}
        setIsPWDisabled={setIsPWDisabled}
        IsPWEdit={IsPWEdit}
        setIsPWEdit={setIsPWEdit}
        IsPWVisible={IsPWVisible}
        setIsPWVisible={setIsPWVisible}
        SelectedOperation={SelectedOperation}
        setSelectedOperation={setSelectedOperation}
        ActorSelection={ActorSelection}
        setActorSelection={setActorSelection}
        ClearActorModal={ClearActorModal}
        AddActorInventory={AddActorInventory}
        RemoveActorInventory={RemoveActorInventory}
        CreateActor={CreateActor}
        ModifyActor={ModifyActor}
        DeleteActor={DeleteActor}
        SelectedActor={SelectedActor}
        setSelectedActor={setSelectedActor}
        //
        ChkUser={ChkUser}
        setChkUser={setChkUser}
        ChkAdmin={ChkAdmin}
        setChkAdmin={setChkAdmin}
        ChkExecutive={ChkExecutive}
        setChkExecutive={setChkExecutive}
        ChkManager={ChkManager}
        setChkManager={setChkManager}
        ChkDriver={ChkDriver}
        setChkDriver={setChkDriver}
        ChkSalesPerson={ChkSalesPerson}
        setChkSalesPerson={setChkSalesPerson}
        ChkPurchaser={ChkPurchaser}
        setChkPurchaser={setChkPurchaser}
        ChkInvClerk={ChkInvClerk}
        setChkInvClerk={setChkInvClerk}
        ChkPOS={ChkPOS}
        setChkPOS={setChkPOS}
        ChkUserInvoice={ChkUserInvoice}
        setChkUserInvoice={setChkUserInvoice}
        ChkEmployee={ChkEmployee}
        setChkEmployee={setChkEmployee}
        ChkPayee={ChkPayee}
        setChkPayee={setChkPayee}
        //
        SelInventory={SelInventory}
        setSelInventory={setSelInventory}
        Inventories={Inventories}
        setInventories={setInventories}
        Permissions={Permissions}
        setPermissions={setPermissions}
        UserType={UserType}
        setUserType={setUserType}
        user={user}
        SelActorType={SelActorType}
        setSelActorType={setSelActorType}
        // HandleActorModal={HandleActorModal}
        // setHandleActorModal={setHandleActorModal}
        ValidateActor={ValidateActor}
        ActorInputValidation={ActorInputValidation}
        setActorInputValidation={setActorInputValidation}
        CheckedUIPermission={CheckedUIPermission}
        setCheckedUIPermission={setCheckedUIPermission}
        ChkTransactionPage={ChkTransactionPage}
        setChkTransactionPage={setChkTransactionPage}
        ChkAcknowledgementReceipt={ChkAcknowledgementReceipt}
        setChkAcknowledgementReceipt={setChkAcknowledgementReceipt}
        ChkChargeInvoice={ChkChargeInvoice}
        setChkChargeInvoice={setChkChargeInvoice}
        ChkAccountingPage={ChkAccountingPage}
        setChkAccountingPage={setChkAccountingPage}
        ChkSalesReceipt={ChkSalesReceipt}
        setChkSalesReceipt={setChkSalesReceipt}
        ChkPayeeBilling={ChkPayeeBilling}
        setChkPayeeBilling={setChkPayeeBilling}
        ChkVoucherBilling={ChkVoucherBilling}
        setChkVoucherBilling={setChkVoucherBilling}
        ChkPaymentAccess={ChkPaymentAccess}
        setChkPaymentAccess={setChkPaymentAccess}
        ChkInventoryPage={ChkInventoryPage}
        setChkInventoryPage={setChkInventoryPage}
        ChkInventoryDetailsPage={ChkInventoryDetailsPage}
        setChkInventoryDetailsPage={setChkInventoryDetailsPage}
        ChkInvoicePage={ChkInvoicePage}
        setChkInvoicePage={setChkInvoicePage}
        ChkInvoiceDetailsPage={ChkInvoiceDetailsPage}
        setChkInvoiceDetailsPage={setChkInvoiceDetailsPage}
        ChkActorPage={ChkActorPage}
        setChkActorPage={setChkActorPage}
        ChkQuotationPage={ChkQuotationPage}
        setChkQuotationPage={setChkQuotationPage}
        ChkPurchaseRequestPage={ChkPurchaseRequestPage}
        setChkPurchaseRequestPage={setChkPurchaseRequestPage}
        ChkTransferInventoryPage={ChkTransferInventoryPage}
        setChkTransferInventoryPage={setChkTransferInventoryPage}
        ChkInvoicingAccess={ChkInvoicingAccess}
        setChkInvoicingAccess={setChkInvoicingAccess}
        ChkDRInvoice={ChkDRInvoice}
        setChkDRInvoice={setChkDRInvoice}
        ChkInvQuote={ChkInvQuote}
        setChkInvQuote={setChkInvQuote}
        ChkInvDR={ChkInvDR}
        setChkInvDR={setChkInvDR}
        ChkInvPR={ChkInvPR}
        setChkInvPR={setChkInvPR}
        ChkInvTransfer={ChkInvTransfer}
        setChkInvTransfer={setChkInvTransfer}
        ChkInvRequest={ChkInvRequest}
        setChkInvRequest={setChkInvRequest}
        ChkPOSMarkdown={ChkPOSMarkdown}
        setChkPOSMarkdown={setChkPOSMarkdown}
        ChkPOSMarkup={ChkPOSMarkup}
        setChkPOSMarkup={setChkPOSMarkup}
        ChkQuoteInvoice={ChkQuoteInvoice}
        setChkQuoteInvoice={setChkQuoteInvoice}
        ChkPRPOInvoice={ChkPRPOInvoice}
        setChkPRPOInvoice={setChkPRPOInvoice}
        ChkInventoryGenerateInvoice={ChkInventoryGenerateInvoice}
        setChkInventoryGenerateInvoice={setChkInventoryGenerateInvoice}
        ChkVeriQuote={ChkVeriQuote}
        setChkVeriQuote={setChkVeriQuote}
        ChkVeriDR={ChkVeriDR}
        setChkVeriDR={setChkVeriDR}
        ChkVeriPRPO={ChkVeriPRPO}
        setChkVeriPRPO={setChkVeriPRPO}
        ChkVeriVoucher={ChkVeriVoucher}
        setChkVeriVoucher={setChkVeriVoucher}
        ChkVeriInvRequest={ChkVeriInvRequest}
        setChkVeriInvRequest={setChkVeriInvRequest}
        ChkVeriInvTransfer={ChkVeriInvTransfer}
        setChkVeriInvTransfer={setChkVeriInvTransfer}
        ChkVerificationPage={ChkVerificationPage}
        setChkVerificationPage={setChkVerificationPage}
        setChkAllowChargeInvoice={setChkAllowChargeInvoice}
        ChkAllowChargeInvoice={ChkAllowChargeInvoice}
      />

      <style>
        {`
          @media (max-width: 991px) {
            .globalContainerStyle {
              border-radius: 0px !important;
              margin-top: 0px !important;
              box-shadow: none !important;
            }
            .stage {
              padding: 0px;
            }
          }
          @media (max-width: 575px) {
            .actorHeaderDate {
              display: none;
            }
           
          }
          @media (min-width: 992px) {
            .globalContainerStyle {
              border-radius: 10px;
            }
          }
        `}
      </style>
      <div
        style={{ zIndex: "100" }}
        className="globalContainerStyle mt-2 p-3 animate__animated animate__fadeInLeft"
      >
        <Row noGutters={true}>
          <Col>
            <h4>Partners Management</h4>
          </Col>
          <Col className="actorHeaderDate"  style={{ textAlignLast: "end" }}>
            <h4>
              Date:{" "}
              <u className="actor-date-date">
                {moment().format("MMMM Do YYYY")}
              </u>
            </h4>
          </Col>
        </Row>

        <Row className="" noGutters={true}>
          <Col md="auto" lg="auto" sm={6} xs={12} className="py-1 px-1">
            <InputGroup className="">
              <InputGroup.Prepend>
                <InputGroup.Text className="actor-ico-search">
                  <i className="icofont-search icofont-1x"></i>
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder="Search"
                aria-label="Username"
                name="searchInput"
                className="actor-searchTable shadow-none"
                aria-describedby="basic-addon1"
                onChange={handleSearch}
              />
            </InputGroup>
          </Col>
          <Col md="auto" lg="auto" sm={6} xs={12} className="py-1 px-1">
            <InputGroup>
              <select
                className="form-control actor-selector w-auto"
                name="actorSelect"
                value={SelActorType}
                onChange={(e) => {
                  //console.log(Number(e.currentTarget.value));
                  setSelActorType(Number(e.currentTarget.value));
                }}
              >
                {(() => {
                  if (UserType.includes("Admin")) {
                    return (
                      <>
                        <option value="1">Users</option>
                      </>
                    );
                  }
                })()}
                {(() => {
                  if (
                    UserType.includes("Manager") ||
                    UserType.includes("Admin") ||
                    permissions.includes("perm-ui-invoicingPage-view-write") ||
                    permissions.includes("perm-ui-transactionPage-view-write")
                  ) {
                    return (
                      <>
                        <option value="13">Payees</option>
                        <option value="12">Employees</option>
                      </>
                    );
                  }
                })()}
                {(() => {
                  if (
                    UserType.includes("Manager") ||
                    UserType.includes("Purchaser") ||
                    UserType.includes("Admin") ||
                    UserType.includes("Sales Person") ||
                    UserType.includes("Admin") ||
                    permissions.includes("perm-ui-invoicingPage-view-write") ||
                    permissions.includes("perm-ui-transactionPage-view-write")
                  ) {
                    return (
                      <>
                        <option value="6">Clients</option>
                      </>
                    );
                  }
                })()}
                {(() => {
                  if (
                    UserType.includes("Manager") ||
                    UserType.includes("Purchaser") ||
                    UserType.includes("Admin") ||
                    permissions.includes("perm-ui-invoicingPage-view-write") ||
                    permissions.includes("perm-ui-transactionPage-view-write")
                  ) {
                    return (
                      <>
                        <option value="7">Suppliers</option>
                      </>
                    );
                  }
                })()}
              </select>
            </InputGroup>
          </Col>

          <Col className="actor-btn-crud-col d-flex justify-content-end py-1">
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip id={`actor-tooltip-add`}>ADD</Tooltip>}
            >
              <Button
                variant="secondary"
                name="btnAddActor"
                className="btnAddActor actor-btn-crud"
                onClick={(e) => {
                  setIsActorModalOpen(e);
                  setSelectedOperation(SELECTED_OPERATION.ADD);
                  ClearActorModal();
                  // props.state.handleActionChange(e);
                }}
              >
                ADD <i className="icofont-ui-add "></i>
              </Button>
            </OverlayTrigger>
          </Col>
        </Row>
      </div>
      {tablelist}
    </div>
  );
};
