import React from "react";
//import { NumericFormat } from 'react-number-format';
import pdfTemplate from "../../../components/PdfHeadFoot";
import { Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer";

import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf",
      fontWeight: 800,
    },
  ],
});
let themeColor = "";
if (process.env.REACT_APP_THEME === "SURFACEPLUS") themeColor = "#5eaaff";
if (process.env.REACT_APP_THEME === "DEMO") themeColor = "#ed5309";
const tableStyles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  tableHeader: {
    textAlign: "center",
    fontSize: 9,
    marginLeft: 2,
    marginRight: 2,
    color: themeColor,
    fontFamily: "Open Sans",
    fontWeight: 800,
  },
  tableCell: {
    marginLeft: 2,
    marginRight: 2,
  },
  tableCellNames: {
    fontSize: 8,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

class TransferItem {
  _itemTable(data) {
    return (
      <Table data={data}>
        <TableHeader>
          <TableCell weighting={0.08} style={[tableStyles.tableHeader]}>
            #
          </TableCell>

          <TableCell weighting={0.5} style={[tableStyles.tableHeader]}>
            ITEM DESCRIPTION
          </TableCell>
          <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
            QTY
          </TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={[tableStyles.tableCell]}
            weighting={0.08}
            getContent={(d) => (d.index === null ? "" : d.index)}
          />

          {/* <DataTableCell
                    style={[tableStyles.tableCell]}
                    getContent={(d) =>
                      d.material.unit.name === null ? "" : d.material.unit.name
                    }
                  /> */}
          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.5}
            getContent={(d) => {
              // console.log(d);
              let name = "-";
              if (d.itemId === null) {
                if (d.serviceName !== null)
                  name = JSON.parse(d.serviceName).label;
              } else {
                if (d.item.details.length > 0) {
                  const sDat = d.item.details.find((x) => x.type === 1);
                  sDat !== undefined ? (name = sDat.name) : (name = "-");
                }
              }

              return (
                <View style={{ flexDirection: "row" }}>
                  {((x) => {
                    if (d.showImg === 1) {
                      if (typeof d.dispImg !== "undefined") {
                        return (
                          <Image
                            style={[
                              {
                                maxWidth: 50,
                                height: 50,
                                marginTop: 2,
                                marginBottom: 2,
                                marginLeft: 2,
                              },
                            ]}
                            src={d.dispImg.original}
                          />
                        );
                      }
                    }
                  })()}

                  <Text style={[{ marginLeft: 5 }]}>{name}</Text>
                </View>
              );
            }}
          />
          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.15}
            getContent={(d) => {
              let name = "-";

              if (d.itemId === null) {
                name = "Service";
              } else {
                if (d.item.details.length > 0) {
                  const sDat = d.item.details.find((x) => x.type === 2);
                  sDat !== undefined ? (name = sDat.name) : (name = "-");
                }
              }

              return `${d.itemQty} ${name}`;
            }}
          />
          {/* <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.15}
            getContent={(d) => (d.srp === null ? "-" : `${d.srp}`)}
          /> */}
        </TableBody>
      </Table>
    );
  }

  _termsSignature(approvedBy, isSig, incharge) {
    return (
      <>
        <View>
          <View
            style={[
              {
                marginTop: 5,

                paddingLeft: 80,
                paddingRight: 80,
              },
            ]}
          >
            <View
              style={[
                {
                  marginTop: 10,
                  flex: 1,
                  flexDirection: "row",
                },
              ]}
            >
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      color: themeColor,
                      fontFamily: "Open Sans",
                      fontWeight: 800,
                    },
                  ]}
                >
                  Prepared by:
                </Text>
              </View>
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      color: themeColor,
                      fontFamily: "Open Sans",
                      fontWeight: 800,
                    },
                  ]}
                >
                  Approved By:
                </Text>
              </View>
              {/* <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                    },
                  ]}
                >
                  Conformed By:
                </Text>
              </View> */}
            </View>
          </View>
        </View>
        <View>
          <View
            style={[
              {
                marginTop: 5,
                marginBottom: 25,
                paddingLeft: 80,
                paddingRight: 80,
              },
            ]}
          >
            <View
              style={[
                {
                  marginTop: 10,
                  flex: 1,
                  flexDirection: "row",
                },
              ]}
            >
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      textAlign: "center",
                    },
                  ]}
                >
                  {incharge?.name}
                </Text>
                {(() => {
                  if (incharge !== null && incharge !== undefined) {
                    if (incharge.uploads) {
                      if (
                        Number(isSig) === 1 &&
                        Number(incharge.uploads.length) > 0
                      ) {
                        return (
                          <Image
                            style={[
                              {
                                position: "absolute",
                                width: 150,
                                marginTop: -25,
                              },
                            ]}
                            src={{
                              uri: `${process.env.REACT_APP_API_URL}global/getFile?uniqueId=${incharge.uniqueId}&filename=${incharge.uploads[0].filename}`,
                              method: "GET",

                              headers: {
                                Authorization:
                                  "Bearer " + localStorage.getItem("LUT"),
                              },
                              body: "",
                            }}
                          />
                        );
                      }
                    }
                  }
                })()}
              </View>
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      textAlign: "center",
                    },
                  ]}
                >
                  {approvedBy?.name}
                </Text>
                {(() => {
                  if (approvedBy !== null && approvedBy !== undefined) {
                    if (approvedBy.uploads) {
                      if (
                        Number(isSig) === 1 &&
                        Number(approvedBy.uploads.length) > 0
                      ) {
                        return (
                          <Image
                            style={[
                              {
                                position: "absolute",
                                width: 150,
                                marginTop: -25,
                              },
                            ]}
                            src={{
                              uri: `${process.env.REACT_APP_API_URL}global/getFile?uniqueId=${approvedBy.uniqueId}&filename=${approvedBy.uploads[0].filename}`,
                              method: "GET",

                              headers: {
                                Authorization:
                                  "Bearer " + localStorage.getItem("LUT"),
                              },
                              body: "",
                            }}
                          />
                        );
                      }
                    }
                  }
                })()}
              </View>
            </View>
          </View>
        </View>
      </>
    );
  }

  TransferItemPDF(
    props,
    verif,
    uniqueId,
    invoiceStatusName,
    inchargeName,
    approvedBy,
    incharge,
    isSig,
    from,
    to
  ) {
    //console.log(props.pdfGroupTable[0]);

    let _content = [];

    _content.push(
      <>
        <View
          style={[
            {
              //height: 325,
              paddingLeft: 20,
              paddingRight: 20,
            },
          ]}
        >
          <View
            style={[
              {
                flexDirection: "row",
                paddingLeft: 80,
                paddingRight: 80,
                marginTop: 10,
              },
            ]}
          >
            <View style={[{ flex: 1, width: "50%", marginBottom: 10 }]}>
              <Text style={[{ fontSize: 12, textAlign: "left" }]}>
                FROM:{` ${from}`}
              </Text>
            </View>
            <View style={[{ flex: 1, width: "50%" }]}>
              <Text style={[{ fontSize: 12, textAlign: "right" }]}>
                TO:{` ${to}`}
              </Text>
            </View>
          </View>
          {/* <Text
            style={[
              {
                fontSize: 9,
                paddingLeft: 20,
              },
            ]}
          >
            Dear Ma'am / Sir,
          </Text>
          <Text
            style={[
              {
                marginBottom: 8,
                paddingLeft: 20,
                fontSize: 9,
              },
            ]}
          >
            We are pleased to submit our quotation for the following items:
          </Text> */}
          {((x) => {
            const _e = props.pdfGroupTable.result[0];
            if (_e.length < 8) {
              return (
                <>
                  <View
                    style={[
                      {
                        paddingLeft: 20,
                        paddingRight: 20,
                      },
                    ]}
                  >
                    {this._itemTable(_e)}
                  </View>

                  {this._termsSignature(approvedBy, isSig, incharge)}
                </>
              );
            } else {
              return <>{this._itemTable(_e)}</>;
            }
          })()}
        </View>
        {/* <View style={[{ marginTop: 5 }]}>
          <Text style={[{ textAlign: "center", color: "red" }]}>
            --= THIS DOCUMENT IS FOR REFERENCE ONLY =--
          </Text>
        </View> */}
      </>
    );

    for (let i = 1; i < props.pdfGroupTable.result.length; i++) {
      const _e = props.pdfGroupTable.result[i];

      if (_e.length > 6) {
        _content.push(
          <View
            style={[
              {
                paddingLeft: 20,
                paddingRight: 20,
              },
            ]}
          >
            {this._itemTable(_e)}
          </View>
        );
      } else {
        _content.push(<>{this._termsSignature(approvedBy, isSig, incharge)}</>);
      }
    }

    return pdfTemplate.TemplateHeaderFooter(
      _content,
      "Transfer Inventory",
      uniqueId
    );
  }
}
export default new TransferItem();
// let QuotePDF = (props) => (

// );
//export { QuotePDF };
