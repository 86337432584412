import React from "react";
//import { NumericFormat } from 'react-number-format';
import pdfTemplate from "../../../components/PdfHeadFoot";
import {
  Text,
  View,
  Image,
  StyleSheet,
  Font,
  Canvas,
} from "@react-pdf/renderer";

import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf",
      fontWeight: 800,
    },
  ],
});
let themeColor = "";
if (process.env.REACT_APP_THEME === "SURFACEPLUS") themeColor = "#5eaaff";
if (process.env.REACT_APP_THEME === "DEMO") themeColor = "#ed5309";

const tableStyles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  tableHeader: {
    textAlign: "center",
    fontSize: 9,
    marginLeft: 2,
    marginRight: 2,
    color: themeColor,
    fontFamily: "Open Sans",
    fontWeight: 800,
  },
  tableCell: {
    marginLeft: 2,
    marginRight: 2,
  },
  tableCellNames: {
    fontSize: 8,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

class DR {
  _itemTable(data) {
    return (
      <Table data={data}>
        <TableHeader
          includeBottomBorder={false}
          includeLeftBorder={false}
          includeRightBorder={false}
          includeTopBorder={false}
          style={[
            {
              border: 0,
            },
          ]}
        >
          <TableCell weighting={0.08} style={[tableStyles.tableHeader]}>
            #
          </TableCell>

          <TableCell weighting={0.5} style={[tableStyles.tableHeader]}>
            ITEM DESCRIPTION
          </TableCell>
          <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
            QTY
          </TableCell>
        </TableHeader>
        <TableBody
          includeBottomBorder={true}
          includeLeftBorder={false}
          includeRightBorder={false}
          includeTopBorder={false}
        >
          <DataTableCell
            style={[tableStyles.tableCell]}
            weighting={0.08}
            getContent={(d) => (d.index === null ? "" : d.index)}
          />

          {/* <DataTableCell
                    style={[tableStyles.tableCell]}
                    getContent={(d) =>
                      d.material.unit.name === null ? "" : d.material.unit.name
                    }
                  /> */}
          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.5}
            getContent={(d) => {
              // console.log(d);
              let name = "-";
              if (d.itemId === null) {
                if (d.serviceName !== null)
                  name = JSON.parse(d.serviceName).label;
              } else {
                if (d.item.details.length > 0) {
                  const sDat = d.item.details.find((x) => x.type === 1);
                  sDat !== undefined ? (name = sDat.name) : (name = "-");
                }
              }

              return (
                <View style={{ flexDirection: "column" }}>
                  {/* {((x) => {
                    if (d.showImg === 1) {
                      if (typeof d.dispImg !== "undefined") {
                        return (
                          <Image
                            style={[
                              {
                                maxWidth: 50,
                                height: 50,
                                marginTop: 2,
                                marginBottom: 2,
                                marginLeft: 2,
                              },
                            ]}
                            src={d.dispImg.original}
                          />
                        );
                      }
                    }
                  })()} */}

                  <Text style={[{ marginLeft: 5 }]}>{name}</Text>
                  <Text style={[{ marginLeft: 5, fontSize: 6 }]}>
                    {d.itemId !== null ? d?.item?.description : ""}
                  </Text>
                </View>
              );
            }}
          />
          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.15}
            getContent={(d) => {
              let name = "-";

              if (d.itemId === null) {
                name = "Service";
              } else {
                if (d.item.details.length > 0) {
                  const sDat = d.item.details.find((x) => x.type === 2);
                  sDat !== undefined ? (name = sDat.name) : (name = "-");
                }
              }

              return `${
                d.qtyDelivered === 0 ? d.itemQty : d.qtyDelivered
              } ${name}`;
            }}
          />
          {/* <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.15}
            getContent={(d) => (d.srp === null ? "-" : `${d.srp}`)}
          /> */}
        </TableBody>
      </Table>
    );
  }

  _termsSignature(props, incharge, approvedBy, isSig) {
    return (
      <>
        <View>
          <View
            style={[
              {
                marginTop: 5,

                paddingLeft: 40,
                paddingRight: 40,
              },
            ]}
          >
            <View
              style={[
                {
                  marginTop: 10,
                  flex: 1,
                  flexDirection: "row",
                },
              ]}
            >
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      color: themeColor,
                      fontFamily: "Open Sans",
                      fontWeight: 800,
                      textAlign: "center",
                    },
                  ]}
                >
                  Prepared by:
                </Text>
              </View>
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      color: themeColor,
                      fontFamily: "Open Sans",
                      fontWeight: 800,
                      textAlign: "center",
                    },
                  ]}
                >
                  Approved By:
                </Text>
              </View>
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      color: themeColor,
                      fontFamily: "Open Sans",
                      fontWeight: 800,
                      textAlign: "center",
                    },
                  ]}
                >
                  Checked By:
                </Text>
              </View>
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      color: themeColor,
                      fontFamily: "Open Sans",
                      fontWeight: 800,
                      textAlign: "center",
                    },
                  ]}
                >
                  Released By:
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View>
          <View
            style={[
              {
                marginTop: 5,
                marginBottom: 25,
                paddingLeft: 40,
                paddingRight: 40,
              },
            ]}
          >
            <View
              style={[
                {
                  marginTop: 10,
                  flex: 1,
                  flexDirection: "row",
                },
              ]}
            >
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      textAlign: "center",
                    },
                  ]}
                >
                  {incharge !== null && incharge !== undefined
                    ? incharge.name
                    : "-"}
                </Text>
                {(() => {
                  // console.log(incharge);
                  if (incharge !== null && incharge !== undefined) {
                    if (incharge.uploads) {
                      if (
                        Number(isSig) === 1 &&
                        Number(incharge.uploads.length) > 0
                      ) {
                        return (
                          <Image
                            style={[
                              {
                                position: "absolute",
                                width: 150,
                                marginTop: -25,
                              },
                            ]}
                            src={{
                              uri: `${process.env.REACT_APP_API_URL}global/getFile?uniqueId=${incharge.uniqueId}&filename=${incharge.uploads[0].filename}`,
                              method: "GET",

                              headers: {
                                Authorization:
                                  "Bearer " + localStorage.getItem("LUT"),
                              },
                              body: "",
                            }}
                          />
                        );
                      }
                    }
                  }
                })()}
              </View>
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      textAlign: "center",
                    },
                  ]}
                >
                  {approvedBy !== null && approvedBy !== undefined
                    ? approvedBy.name
                    : "-"}
                </Text>
                {(() => {
                  if (approvedBy !== null && approvedBy !== undefined) {
                    if (approvedBy.uploads) {
                      if (
                        Number(isSig) === 1 &&
                        Number(approvedBy.uploads.length) > 0
                      ) {
                        return (
                          <Image
                            style={[
                              {
                                position: "absolute",
                                width: 150,
                                marginTop: -25,
                              },
                            ]}
                            src={{
                              uri: `${process.env.REACT_APP_API_URL}global/getFile?uniqueId=${approvedBy.uniqueId}&filename=${approvedBy.uploads[0].filename}`,
                              method: "GET",

                              headers: {
                                Authorization:
                                  "Bearer " + localStorage.getItem("LUT"),
                              },
                              body: "",
                            }}
                          />
                        );
                      }
                    }
                  }
                })()}
              </View>
              <View style={[{ flex: 1 }]}></View>
              <View style={[{ flex: 1 }]}></View>
            </View>
          </View>
        </View>
        <View>
          <View
            style={[
              {
                marginTop: -23,

                paddingLeft: 40,
                paddingRight: 40,
              },
            ]}
          >
            <View
              style={[
                {
                  flex: 1,
                  flexDirection: "row",
                },
              ]}
            >
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 8,
                      textAlign: "center",
                    },
                  ]}
                >
                  {"________________"}
                </Text>
              </View>
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 8,
                      textAlign: "center",
                    },
                  ]}
                >
                  {"________________"}
                </Text>
              </View>
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 8,
                      textAlign: "center",
                    },
                  ]}
                >
                  {"________________"}
                </Text>
              </View>
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 8,
                      textAlign: "center",
                    },
                  ]}
                >
                  {"________________"}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={[
            {
              marginTop: 5,

              paddingLeft: 20,
              paddingRight: 20,
            },
          ]}
        >
          <View
            style={[
              {
                display: "flex",
                flexDirection: "column",
              },
            ]}
          >
            <View style={[{ flex: 1 }]}>
              <Text
                style={[
                  {
                    fontSize: 10,

                    fontFamily: "Open Sans",

                    textAlign: "center",
                  },
                ]}
              >
                _____________________________________________
              </Text>
            </View>
            <View style={[{ flex: 1, marginTop: 13 }]}>
              <Text
                style={[
                  {
                    fontSize: 10,

                    fontFamily: "Open Sans",

                    textAlign: "center",
                  },
                ]}
              >
                Printed Name and Signature of Receiver
              </Text>
            </View>
            <View style={[{ flex: 1, marginTop: 11 }]}>
              <Text
                style={[
                  {
                    fontSize: 10,
                    color: themeColor,
                    fontFamily: "Open Sans",
                    fontWeight: 800,
                    textAlign: "center",
                  },
                ]}
              >
                NOTE: Products are in all good condition upon receiving and
                signing of this delivery receipt.
              </Text>
            </View>
          </View>
        </View>
      </>
    );
  }

  DRPDF(
    props,
    parent,
    verif,
    uniqueId,
    invoiceStatusName,
    shippingAddress,
    incharge,
    approvedBy,
    isSig
  ) {
    //console.log(props.pdfGroupTable[0]);

    let _content = [];

    _content.push(
      <>
        {/* <View>
          
          <View
            style={[
              {
                marginTop: 8,
                marginBottom: 8,
                paddingLeft: 80,
                paddingRight: 80,
              },
            ]}
          >
            <Text
              style={[
                {
                  marginBottom: 5,
                  fontSize: 12,
                },
              ]}
            >
              Client:{" "}
              {parent !== null && parent !== undefined
                ? parent.client.company
                : "-"}
            </Text>
            <Text
              style={[
                {
                  marginBottom: 20,
                  fontSize: 10,
                },
              ]}
            >
              Address: {shippingAddress}
            </Text>
          </View>
        </View> */}
        {(invoiceStatusName === "New" ||
          invoiceStatusName === "Admin Denied" ||
          invoiceStatusName === "Pending Admin Approval") && (
          <View
            style={[
              {
                marginTop: 85,
                fontSize: 15,
                width: "100%",
                position: "absolute",
                textAlign: "center",
                backgroundColor: "red",
              },
            ]}
          >
            <Text style={[{ textAlign: "center", color: "white" }]}>
              --== THIS INVOICE IS NOT APPROVED BY COMPANY AND IS NOT VALID ==--
            </Text>
          </View>
        )}

        <View
          style={[
            {
              // flex: 1,
              flexDirection: "row",
              paddingLeft: 40,
              paddingRight: 40,
              marginTop: 5,
            },
          ]}
        >
          <View style={[{ flex: 1 }]}>
            <Text
              style={[
                {
                  textAlign: "left",
                  color: themeColor,
                  fontSize: 10,
                  fontWeight: "bold",
                },
              ]}
            >
              INVOICE NAME:
            </Text>
            {/* <Text
              style={[
                {
                  textAlign: "left",
                  fontSize: 8,
                  marginTop: 5,
                  paddingLeft: 3,
                  paddingRight: 3,
                },
              ]}
            >
              {parent.name}
              {parent.name}
            </Text> */}
            <View
              style={[
                {
                  display: "flex",
                  flexDirection: "column",

                  paddingLeft: 3,
                  paddingRight: 3,
                },
              ]}
            >
              <Text style={[{ fontSize: 8, fontWeight: "bold" }]}>
                {parent.uniqueId}
              </Text>
              <Text style={[{ color: "black", fontSize: 8 }]}>
                {parent.name}
              </Text>
            </View>
          </View>
          <View style={[{ flex: 1 }]}>
            <Text
              style={[
                {
                  textAlign: "left",
                  color: themeColor,
                  fontSize: 10,
                  fontWeight: "bold",
                },
              ]}
            >
              CUSTOMER’S NAME:
            </Text>
            <Text
              style={[
                {
                  textAlign: "left",
                  fontSize: 8,
                  marginTop: 5,
                  paddingLeft: 3,
                  paddingRight: 3,
                },
              ]}
            >
              {parent.client.company}
            </Text>
          </View>
          <View style={[{ flex: 1 }]}>
            <Text
              style={[
                {
                  textAlign: "left",
                  color: themeColor,
                  fontSize: 10,
                  fontWeight: "bold",
                },
              ]}
            >
              DELIVERY ADDRESS
            </Text>
            <Text
              style={[
                {
                  textAlign: "left",
                  fontSize: 8,
                  marginTop: 5,
                  paddingLeft: 3,
                  paddingRight: 3,
                },
              ]}
            >
              {shippingAddress}
            </Text>
          </View>
          <View style={[{ flex: 1 }]}>
            <Text
              style={[
                {
                  textAlign: "left",
                  color: themeColor,
                  fontSize: 10,
                  fontWeight: "bold",
                },
              ]}
            >
              FROM
            </Text>
            <View
              style={[
                {
                  display: "flex",
                  flexDirection: "column",

                  paddingLeft: 3,
                  paddingRight: 3,
                },
              ]}
            >
              <Text style={[{ fontSize: 8, fontWeight: "bold" }]}>
                Surface+
              </Text>
              <Text style={[{ color: "black", fontSize: 8 }]}>
                22 Commission Civil, Lapaz
              </Text>
              <Text style={[{ color: "black", fontSize: 8 }]}>
                Iloilo City, 5000
              </Text>
              <Text style={[{ color: "black", fontSize: 8 }]}>
                (63) 917 659 1188
              </Text>
            </View>
          </View>

          {/* <View style={[{ flex: 1 }]}>
            <Text
              style={[
                {
                  textAlign: "left",
                  color: themeColor,
                  fontSize: 12,
                  fontWeight: "bold",
                },
              ]}
            >
              AMOUNT DUE
            </Text>
            <Text
              style={[
                {
                  textAlign: "left",
                  marginTop: 10,
                  color: "#0f4c75",
                },
              ]}
            >
              <NumberFormat
                prefix={"P "}
                value={props.grandTotal}
                displayType={"text"}
                thousandSeparator={true}
              />
            </Text>
          </View> */}
          {/* <View
            style={[
              {
                flex: 1,
                flexDirection: "row",
                paddingLeft: 80,
                paddingRight: 80,
                marginTop: 10,
              },
            ]}
          >
            <View style={[{ flex: 1, width: "50%", marginBottom: 10 }]}>
              <Text style={[{ textAlign: "left" }]}>
                QTN #:{" "}
                {typeof props.selectedRowInvoice !== "undefined"
                  ? props.selectedRowInvoice.uniqueId
                  : props.selectedRowVerification.uniqueId}
              </Text>
            </View>
            <View style={[{ flex: 1, width: "50%" }]}>
              <Text style={[{ fontSize: 15, textAlign: "right" }]}>
                Status: {props.selDetailStatus.value.name}
              </Text>
            </View>
          </View>
          <View
            style={[
              {
                marginTop: 8,
                marginBottom: 8,
                paddingLeft: 80,
                paddingRight: 80,
              },
            ]}
          >
            <Text
              style={[
                {
                  marginBottom: 5,
                  fontSize: 12,
                },
              ]}
            >
              Client: {props.selDetailClient.value.name}
            </Text>
            <Text
              style={[
                {
                  marginBottom: 20,
                  fontSize: 10,
                },
              ]}
            >
              Address: {props.selDetailClient.value.address}
            </Text>
          </View> */}
        </View>

        <Canvas
          fixed
          style={[
            {
              width: 600,
              height: 5,
              top: 190,
              position: "absolute",
            },
          ]}
          paint={(painter) => {
            painter
              .strokeColor(themeColor)
              .lineWidth(5)
              .moveTo(40, 0)
              .lineTo(555, 0)
              .stroke();
          }}
        />

        <View
          style={[
            {
              //height: 325,
              paddingTop: 20,
              paddingLeft: 20,
              paddingRight: 20,
            },
          ]}
        >
          {/* <Text
            style={[
              {
                fontSize: 9,
                paddingLeft: 20,
              },
            ]}
          >
            Dear Ma'am / Sir,
          </Text>
          <Text
            style={[
              {
                marginBottom: 8,
                paddingLeft: 20,
                fontSize: 9,
              },
            ]}
          >
            We are pleased to submit our quotation for the following items:
          </Text> */}
          {((x) => {
            let _e = [];
            if (props.pdfGroupTable.result.length > 0) {
              _e = props.pdfGroupTable.result[0];
            }
            //console.log(props.pdfGroupTable);
            if (_e.length < 20) {
              return (
                <>
                  <View
                    style={[
                      {
                        paddingLeft: 20,
                        paddingRight: 20,
                      },
                    ]}
                  >
                    {this._itemTable(_e)}
                  </View>

                  {this._termsSignature(props, incharge, approvedBy, isSig)}
                </>
              );
            } else {
              return <>{this._itemTable(_e)}</>;
            }
          })()}
        </View>
        {/* <View style={[{ marginTop: 5 }]}>
          <Text style={[{ textAlign: "center", color: "red" }]}>
            --= THIS DOCUMENT IS FOR REFERENCE ONLY =--
          </Text>
        </View> */}
      </>
    );

    for (let i = 1; i < props.pdfGroupTable.result.length; i++) {
      const _e = props.pdfGroupTable.result[i];

      if (_e.length > 6) {
        _content.push(
          <View
            style={[
              {
                paddingLeft: 20,
                paddingRight: 20,
              },
            ]}
          >
            {this._itemTable(_e)}
          </View>
        );
      } else {
        _content.push(
          <>{this._termsSignature(props, incharge, approvedBy, isSig)}</>
        );
      }
    }

    return pdfTemplate.TemplateHeaderFooter(
      _content,
      "Delivery Receipt",
      uniqueId,
      true,
      undefined,
      undefined,
      undefined,
      (invoiceStatusName === "New" ||
        invoiceStatusName === "Admin Denied" ||
        invoiceStatusName === "Pending Admin Approval") &&
        true
    );
  }
}
export default new DR();
// let QuotePDF = (props) => (

// );
//export { QuotePDF };
