import React from "react";

import Toast from "react-bootstrap/Toast";

//import "bootstrap/dist/js/bootstrap.js";

const GlobalToast = (props) => (
  <>
    <div
      aria-live="polite"
      aria-atomic="true"
      style={{
        position: "fixed",
        // minHeight: "200px",
        // minWidth: "200px",
        zIndex: 10000,
      }}
    >
      <div
        style={{
          position: "fixed",
          top: 20,
          right: 20,
          zIndex: 10000,
        }}
      >
        {props.redux.toast.data.map((tst, index) => (
          <Toast
            onClose={(e) => {
              props.redux.dispatch({
                type: "TOAST_RESP_NO",
                payload: { index },
              });
            }}
            key={index}
            show={tst.show}
            delay={props.redux.toast.delay}
            autohide={true}
          >
            <Toast.Header>
              <strong className="mr-auto">{tst.title}</strong>
              <small>{tst.time}</small>
            </Toast.Header>
            <Toast.Body>{tst.desc}</Toast.Body>
          </Toast>
        ))}
      </div>
    </div>
  </>
);

export { GlobalToast };
