import QuotePDF from "../pages/Invoice/PDF/Quote";
import { pdf } from "@react-pdf/renderer";
import invoiceApi from "../pages/Invoice/InvoiceAPI";
import inventoryApi from "../pages/Inventory/InventoryAPI";

export const generateQuotePDF = async (id, dispatch) => {
  //fetch invoice from server
  const quoteData = await invoiceApi.getInvoice(dispatch, Number(id));

  if (quoteData) {
    const quoteContent = quoteData.content ? quoteData.content : [];
    const perChunk = 8;
    let _items = [];

    //Filter Change Item if there are any.

    //Attach, fetch Images
    for (let i = 0; i < quoteContent.length; i++) {
      const e = quoteContent[i];
      //console.log(e);
      if (e.item && e.item?.uploads.length > 0) {
        let _imgChild = [];
        const _img = await inventoryApi.downloadItemImg(dispatch, e.item, 1);
        if (e.childId !== null) {
          if (e.child.item.uploads.length > 0) {
            _imgChild = await inventoryApi.downloadItemImg(
              dispatch,
              e.child.item,
              1
            );
          }
        }
        if (_imgChild.length > 0) {
          _items.push({
            ...e,
            dispImg: _img[0],
            child: { ...e.child, dispImg: _imgChild[0] },
          });
        } else {
          _items.push({ ...e, dispImg: _img[0] });
        }
      } else {
        _items.push({ ...e });
      }
    }
    const CHANGE_ITEM_FILTER = (item) => {
      if (item.placeholder !== null || item.placeholder !== "") {
        return true;
      }
      if (item.itemId !== null) {
        return true;
      } else {
        return false;
      }
    };
    _items = _items.filter(CHANGE_ITEM_FILTER);
    //console.log(_items);

    //console.log(_items);
    //Extract services in quoteContent
    const Services = quoteContent.filter(
      (x) => x.itemId === null && x.serviceName !== null
    );

    //Break down items into chunks by 8 to fit A4 Size pdf
    let result = _items.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }
      resultArray[chunkIndex].push({ ...item, index: index + 1 });
      return resultArray;
    }, []);

    //Sort result chunks by index
    result = result.sort(function (a, b) {
      return a.itemIndex - b.itemIndex;
    });
    const TotalMarkupDiscounted = _items
      .reduce((a, c) => {
        return a + c.price * c.itemQty;
      }, 0)
      .toFixed(2);

    //Calculate Invoice Markup
    const TotalMarkup = _items
      .reduce((a, c) => {
        return a + (c.srp + c.srp * (c.priceModifier / 100)) * c.itemQty;
      }, 0)
      .toFixed(2);
    const GrandTotal = quoteContent
      .reduce((a, c) => {
        return a + c.price * c.itemQty;
      }, 0)
      .toFixed(2);
    let invoiceTotalAmountPaid = 0;
    let invoiceTotalAmountDue = 0;

    invoiceTotalAmountDue = GrandTotal - invoiceTotalAmountPaid;

    const GrandTotalSRP = quoteContent
      .reduce((a, c) => {
        return a + (c.srp + c.srp * (c.priceModifier / 100)) * c.itemQty;
      }, 0)
      .toFixed(2);
    const Terms = [];
    for (let i = 0; i < quoteData.details.length; i++) {
      const _e = quoteData.details[i];
      if (_e.type === 6) {
        Terms.push(_e);
      }
    }

    // create blob pdf
    const PdfBlob = await pdf(
      QuotePDF.QuotePDF(
        result,
        Services,
        Terms,
        quoteData?.uniqueId,
        quoteData?.approvedBy?.name,
        quoteData?.client?.company,
        quoteData?.client?.address,
        false,
        quoteData?.incharge?.name,
        quoteData?.dateAdded,
        true,
        quoteData?.invoiceStatus?.name,
        GrandTotal,
        GrandTotalSRP,
        TotalMarkupDiscounted,
        _items,
        TotalMarkup
      )
    ).toBlob();
    let blob = new Blob([PdfBlob], { type: "application/pdf" });
    // blob.name = quoteData.name + '.pdf';
    // const file = new File([blob], `${quoteData.name}.pdf`, {
    //   type: "application/pdf",
    // });
    const blobLink = await window.URL.createObjectURL(blob);
    return blobLink;
  } else {
  }
};
