import {
  call,
  put,
  takeLatest,
  takeEvery,
  all,
  delay,
  select,
} from "redux-saga/effects";

import axios from "axios";
//import history from "../functions/history";
import Filters from "../functions/filters";

const getModal = (state) => state.globalModal;

export function* API_REQUEST(action) {
  //yield put({ type: "API_GET_LOADING" });

  let response = [];
  const socketio = yield select((state) => state.SocketHandler.socket);
  let serv = process.env.REACT_APP_API_URL;
  const loadId = Filters.uuidv4();

 // const Fingerprint = yield select((state) => state.apiData.fingerprint);

  // console.log(action);

  //LOADING_HANDLER({ isLoading: true, name: "Fetching Parters", id: loadId });
  if (!action.disableLoad) {
    yield put({
      type: "API_TOGGLE_LOADING",
      payload: {
        payload: { isLoading: true, name: action.loadingMsg, id: loadId },
      },
    });
  }

  const API_CALL = () => {
    switch (action.reqType) {
      case "getFile":
        return axios
          .get(serv + action.payload, {
            responseType: "arraybuffer",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("LUT"),
            },
          })
          .then((resp) => {
            return resp;
          })
          .catch((error) => {
            throw error;
          });
      case "get":
        return axios
          .get(serv + action.payload, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("LUT"),
            },
          })
          .then((resp) => {
            return resp;
          })
          .catch((error) => {
            throw error;
          });
      case "post":
        //console.log(action.payload);
        return axios
          .post(serv + action.payload, action.data, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("LUT"),
            },
          })
          .then((resp) => {
            return resp;
          })
          .catch((error) => {
            throw error;
          });
      case "postFiles":
        //console.log(action.payload);

        return axios
          .post(serv + action.payload, action.data, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("LUT"),
            },
          })
          .then((resp) => {
            return resp;
          })
          .catch((error) => {
            throw error;
          });
      case "put":
        return axios
          .put(serv + action.payload, action.data, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("LUT"),
            },
          })
          .then((resp) => {
            return resp;
          })
          .catch((error) => {
            throw error;
          });
      case "delete":
        return axios
          .delete(serv + action.payload, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("LUT"),
            },
            data: action.data,
          })
          .then((resp) => {
            return resp;
          })
          .catch((error) => {
            throw error;
          });
      default:
        return axios
          .get(serv + action.payload, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("LUT"),
            },
          })
          .then((resp) => {
            return resp;
          })
          .catch((error) => {
            throw error;
          });
    }
  };

  try {
    if (socketio.disconnected) {
      yield delay(2000);

      yield axios
        .post(
          serv + "auth/reAuthenticate",
          { sessionID: socketio.id, fingerprint: localStorage.getItem("LUF") },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("LUT"),
            },
          }
        )
        .then((resp) => {
          //console.log(resp.data)
          localStorage.setItem("LUT", resp.data.accessToken);
          return resp;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });

      response = yield call(API_CALL);
    } else {
      response = yield call(API_CALL);
    }

    //yield delay(2000);
    yield put({
      type: "API_TOGGLE_LOADING",
      payload: {
        payload: { isLoading: false, id: loadId },
      },
    });

    action.resolve(response);
    yield put({ type: "API_GET_SUCCESS", payload: { data: [] } });
    //yield put({ type: "API_GET_SUCCESS", payload: { data: [] } });
  } catch (error) {
    //TEMP
    // console.log(response);
    // action.resolve(response);
    // yield put({ type: "API_GET_SUCCESS", payload: { data: [] } });
    console.log(error);
    yield put({
      type: "API_TOGGLE_LOADING",
      payload: {
        payload: { isLoading: false, id: loadId },
      },
    });
    if (!error.response) {
      yield put({ type: "API_GET_ERROR" });
      yield put({
        type: "API_TOGGLE_LOADING",
        payload: { payload: { isLoading: false, force: true } },
      });
      yield put({
        type: "MODAL_SHOW",
        payload: {
          title: "Server Offline",
          desc: "Please contact Administrator",
        },
      });
      //yield call([history, history.push], "/login");

      yield localStorage.clear();
      window.location.reload();
      action.reject({ error: error, resp: response });
    } else {
      switch (error.response.status) {
        case 400:
          console.log(error.response);
          if (error.response.data.errorCode === "validationError") {
            yield put({
              type: "MODAL_SHOW",
              payload: {
                title: "Notice",
                desc: `Validation Error.
                ${error.response.data.error.map((e) => {
                  return e.constraints + " ";
                })}`,
              },
            });
          } else {
            yield put({
              type: "MODAL_SHOW",
              // payload: {
              //   title: "Server Error",
              //   desc: "Please submit a ticket regarding this error",
              // },
              payload: {
                title: "Server Error",
                JsxDesc: () => {
                  const err = error?.response?.data;
                  return (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <h5 style={{ textAlign: "justify" }}>
                        Please contact administrators regarding this error.
                        Please send a screenshot of this window.
                      </h5>
                      <p className="m-0 p-0">{error?.response?.status}</p>
                      <p className="m-0 p-0">{err?.errorCode}</p>
                      <p className="m-0 p-0">{err?.message}</p>
                      <p className="m-0 p-0">{err?.method}</p>
                      <p className="m-0 p-0">{err?.path}</p>
                      <p className="m-0 p-0">{err?.status}</p>
                    </div>
                  );
                },
              },
            });
            yield put({ type: "API_GET_ERROR" });

            action.reject({ error: error, resp: response });
          }
          yield put({
            type: "API_TOGGLE_LOADING",
            payload: { payload: { isLoading: false, force: true } },
          });
          break;
        case 401:
          action.reject({ error: error, resp: response });

          yield put({ type: "API_GET_UNAUTH" });
          //use proper history handling
          //yield call([history, history.push], "/login");
          // window.location.reload();

          // yield localStorage.clear();
          //console.log(error.response.data);
          if (error.response.data.message === "Unauthorized") {
            //yield call([history, history.push], "/login");
            yield localStorage.clear();

            yield put({
              type: "MODAL_SHOW",
              payload: {
                title: "Notice",
                desc: "Please login again",
              },
            });
            yield delay(1000);
            window.location.reload();
          }

          if (error.response.data.message === "userActive") {
            yield put({
              type: "MODAL_SHOW",
              payload: {
                title: "Notice",
                desc: "User active from another device.",
              },
            });
          }
          if (error.response.data.message === "usernamePasswordIncorrect") {
            yield put({
              type: "MODAL_SHOW",
              payload: {
                title: "Notice",
                desc: "Username or password incorrect",
              },
            });
          }
          yield put({
            type: "API_TOGGLE_LOADING",
            payload: { payload: { isLoading: false, force: true } },
          });
          break;
        case 409:
          yield put({
            type: "MODAL_SHOW",
            payload: {
              title: "Duplicate",
              desc: "Your input must be unique",
            },
          });

          break;
        default:
          yield put({ type: "API_GET_ERROR" });
          yield put({
            type: "API_TOGGLE_LOADING",
            payload: { payload: { isLoading: false, force: true } },
          });
          yield put({
            type: "MODAL_SHOW",
            payload: {
              title: "Server Error",
              JsxDesc: () => {
                const err = error?.response?.data;
                return (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h5 style={{ textAlign: "justify" }}>
                      Please contact administrators regarding this error. Please
                      send a screenshot of this window.
                    </h5>

                    <p className="m-0 p-0">{err?.message}</p>
                    <p className="m-0 p-0">{err?.method}</p>
                    <p className="m-0 p-0">{err?.path}</p>
                    <p className="m-0 p-0">{err?.status}</p>
                  </div>
                );
              },
            },
          });
          action.reject({ error: error, resp: response });
          break;
      }
    }
  }
}

export function* GLOBAL_MODAL_CB(action) {
  try {
    const response = yield select(getModal);

    if (response.response === false) {
      if (response.cbNo) response.cbNo();
    } else {
      if (response.cb) response.cb();
    }
    if (response.onClose) response.onClose();
  } catch (error) {
    console.log(error);
  }
}

export function* LOADING_HANDLER(action) {
  //const response = yield select(getLoad);
  //console.log(action);
  try {
    if (action.payload)
      yield put({
        type: "API_TOGGLE_LOADING",
        payload: action,
      });
    else {
      yield put({
        type: "API_TOGGLE_LOADING",
        payload: action,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

// export function* SOCKET_HANDLER(action) {
//   //const response = yield select(getLoad);
//   //console.log(action);
//   try {
//     if (action.payload)
//       yield put({
//         type: "API_TOGGLE_LOADING",
//         payload: action,
//       });
//     else {
//       yield put({
//         type: "API_TOGGLE_LOADING",
//         payload: action,
//       });
//     }
//   } catch (error) {
//     console.log(error);
//   }
// }

// export function* testTimer() {
//   while (true) {
//     yield delay(5000);
//     yield cookies.remove("LUT");
//     yield console.log("tick");
//   }
// }

function* unauthSaga() {
  yield takeEvery("USER_GET_REQUEST", API_REQUEST);
}

function* globalModalSaga() {
  yield takeLatest("MODAL_RESP_YES", GLOBAL_MODAL_CB);
}

function* globalModalSagaNO() {
  yield takeLatest("MODAL_RESP_NO", GLOBAL_MODAL_CB);
}

function* globalLoadingSagaStart() {
  yield takeEvery("TOGGLE_LOADING", LOADING_HANDLER);
}

// function* TimerSaga() {
//   yield takeLatest("TEST_TIMER", testTimer);
// }

export default function* rootSaga() {
  yield all([
    unauthSaga(),
    globalModalSaga(),
    globalLoadingSagaStart(),
    globalModalSagaNO(),
  ]);
}
