import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import settingsApi from "./SettingsAPI";
import invoiceApi from "../Invoice/InvoiceAPI";
import accountsApi from "../Accounts/AccountsAPI";
import inventoryApi from "../Inventory/InventoryAPI";
import { SELECTED_OPERATION } from "../../constants";
import Select from "react-select";
import actorApi from "../Actor/ActorAPI";
import { NumericFormat } from "react-number-format";
import InputGroup from "react-bootstrap/InputGroup";
import { setInvoiceHeaderData } from "../../functions/pdfHeaderData";
const initValidateSettings = {
  SelExpenseData: { error: false, message: "" },
  SelInvoiceIncome: { error: false, message: "" },
  SelPOSIncome: { error: false, message: "" },
  SelDashExpense: { error: false, message: "" },
  SelDashSales: { error: false, message: "" },
  SelDashProfit: { error: false, message: "" },
  SelDashActsReceivable: { error: false, message: "" },
  SelDashActsPayable: { error: false, message: "" },
  UIValidate: { error: false, message: "", validated: false },
};

const customStyles = {
  menu: (provided, state) => {
    //console.log(state);
    const width = "100%";

    let color = state.selectProps.menuColor;
    const padding = 0;

    return { ...provided, width, color, padding };
  },
  control: (provided) => ({
    ...provided,
    borderRadius: "5px",
  }),

  container: (_, { selectProps: { width } }) => ({
    width: width,
    // flex: 1,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,

    borderRadius: "5px 0px 0px 5px",
  }),

  option: (provided, state) => {
    let backgroundColor = "";
    let display = "block";
    let color = "black";
    if (state.data.isDefault === 1) {
      backgroundColor = "rgba(255, 0, 0, 0.2)";
    }
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    //console.log(state.data);
    if (state.data.isHidden === 1) {
      display = "none";
    }

    return {
      ...provided,
      opacity,
      transition,
      backgroundColor,
      color,
      display,
    };
  },
};

export const SettingsProcess = () => {
  const dispatch = useDispatch();

  const [SettingsSelection, setSettingsSelection] = useState([]);
  const [SelectedSettingsList, setSelectedSettingsList] = useState(null);

  const [SettingsLoading, setSettingsLoading] = useState(false);

  //Inventory Select
  const [InventoryData, setInventoryData] = useState([]);
  const [SelInventory, setSelInventory] = useState([]);
  const [SelInventoryLoading, setSelInventoryLoading] = useState(true);
  const [SelInventoryDisabled, setSelInventoryDisabled] = useState(false);

  //Invoice Details Data
  const [InvoiceDetails, setInvoiceDetails] = useState([]);

  const [DETAIL_TYPE, setDETAIL_TYPE] = useState([]);
  const [DETAIL_INVOICE_STATUS, setDETAIL_INVOICE_STATUS] = useState([]);
  const [DETAIL_INVOICE_CONTENT_STATUS, setDETAIL_INVOICE_CONTENT_STATUS] =
    useState([]);
  const [DETAIL_INVOICE_CONTENT_TYPE, setDETAIL_INVOICE_CONTENT_TYPE] =
    useState([]);

  //UI

  const [UIDateToday, setUIDateToday] = useState(null);
  const [InitialLoad, setInitialLoad] = useState(true);
  const [SettingsSelectedOperation, setSettingsSelectedOperation] = useState(
    SELECTED_OPERATION.VIEW
  );

  const [TxtSettingsName, setTxtSettingsName] = useState("");

  //Settings Parent Select
  const [SelSettingsParent, setSelSettingsParent] = useState(null);

  //Settings Type Select
  const [SelSettingsType, setSelSettingsType] = useState(null);

  //POS Income Select
  const [SelPOSIncome, setSelPOSIncome] = useState(null);
  const [SelPOSIncomeLoading, setSelPOSIncomeLoading] = useState(false);

  //Invoice Income Select
  const [SelInvoiceIncome, setSelInvoiceIncome] = useState(null);

  //Invoice Acts Receivable Select
  const [SelInvoiceActsReceivable, setSelInvoiceActsReceivable] =
    useState(null);

  //Default Bank Account
  const [SelBankAccount, setSelBankAccount] = useState(null);

  //PO Acts Payable Select
  const [SelPOActsPayable, setSelPOActsPayable] = useState(null);
  //PO Expense Select
  const [SelPOExpense, setSelPOExpense] = useState(null);

  //Expense Select
  const [SelExpense, setSelExpense] = useState(null);

  const [TxtEmail1, setTxtEmail1] = useState(null);
  const [TxtEmail2, setTxtEmail2] = useState(null);

  //Inventory Account Select

  const [SelInventoryAsset, setSelInventoryAsset] = useState(null);

  const [SelGeneralBankAcct, setSelGeneralBankAcct] = useState(null);

  //Dashboaard Statistics Select
  const [SelDashExpense, setSelDashExpense] = useState(null);
  const [SelDashSales, setSelDashSales] = useState(null);
  const [SelDashProfit, setSelDashProfit] = useState(null);
  const [SelDashActsReceivable, setSelDashActsReceivable] = useState(null);
  const [SelDashActsPayable, setSelDashActsPayable] = useState(null);

  const [SelAdjustedPrice, setSelAdjustedPrice] = useState(null);

  //REDUX
  const CURRENT_USER = useSelector((state) => state.apiData.userData);

  //INPUTS VALIDATE
  const [ValidateSettings, setValidateSettings] =
    useState(initValidateSettings);

  //ACCOUNTS DATA
  const [AccountsAssets, setAccountsAssets] = useState([]);
  const [AccountsExpenses, setAccountsExpenses] = useState([]);
  const [AccountsLiabilities, setAccountsLiabilities] = useState([]);
  const [AccountsEquity, setAccountsEquity] = useState([]);
  const [AccountsRevenues, setAccountsRevenues] = useState([]);

  //ACCOUNT MODAL
  const [ChkIsSettingsParent, setChkIsSettingsParent] = useState(false);

  //PRICE MODIFICATION
  const [TxtPriceModification, setTxtPriceModification] = useState("");
  const [TxtPriceModificationDate, setTxtPriceModificationDate] = useState("");

  const [TxtValueAddedTax, setTxtValueAddedTax] = useState("");
  //ACCOUNTABILITY DATA

  //INVOICE INPUTS
  const [TxtInvoiceHeaderAddress1, setTxtInvoiceHeaderAddress1] = useState("");
  const [TxtInvoiceHeaderAddress2, setTxtInvoiceHeaderAddress2] = useState("");
  const [TxtInvoiceHeaderAddress3, setTxtInvoiceHeaderAddress3] = useState("");
  const [TxtInvoiceHeaderLandline, setTxtInvoiceHeaderLandline] = useState("");
  const [TxtInvoiceHeaderMobile, setTxtInvoiceHeaderMobile] = useState("");
  const [TxtInvoiceHeaderContact, setTxtInvoiceHeaderContact] = useState("");
  const [TxtInvoiceHeaderEmail, setTxtInvoiceHeaderEmail] = useState("");
  const [
    TxtInvoiceDeliveryReceiveAddress,
    setTxtInvoiceDeliveryReceiveAddress,
  ] = useState("");

  //console.log(CURRENT_USER);

  useEffect(() => {
    async function fetchData() {
      setInitialLoad(false);
      await refreshInventory(true);
      await getInvoiceDetailsConst();
    }
    fetchData();
    //getActors();
  }, []);
  useEffect(() => {
    if (!InitialLoad) {
      handleSettingsReset();
      refreshSettings();
      setSettingsSelectedOperation(SELECTED_OPERATION.VIEW);
    }
  }, [SelInventory]);

  const validateSettings = (validateComponents, cb) => {
    let tempUserInput = {
      ...ValidateSettings,
      UIValidate: { error: false, message: "", validated: false },
    };

    if (
      validateComponents.includes("SelPOSIncome") ||
      validateComponents.includes("all")
    ) {
      SelPOSIncome !== null
        ? (tempUserInput.SelPOSIncome = { error: false, message: "" })
        : (tempUserInput.SelPOSIncome = {
            error: true,
            message: "POS Income Account cant be empty",
          });
    }
    if (
      validateComponents.includes("SelInvoiceIncome") ||
      validateComponents.includes("all")
    ) {
      SelInvoiceIncome !== null
        ? (tempUserInput.SelInvoiceIncome = { error: false, message: "" })
        : (tempUserInput.SelInvoiceIncome = {
            error: true,
            message: "Invoice Income Account cant be empty",
          });
    }
    // if (
    //   validateComponents.includes("SelExpenseData") ||
    //   validateComponents.includes("all")
    // ) {
    //   SelExpenseData !== null
    //     ? (tempUserInput.SelExpenseData = { error: false, message: "" })
    //     : (tempUserInput.SelExpenseData = {
    //         error: true,
    //         message: "Expense Account cant be empty",
    //       });
    // }
    if (
      validateComponents.includes("SelInventoryAsset") ||
      validateComponents.includes("all")
    ) {
      SelInventoryAsset !== null
        ? (tempUserInput.SelInventoryAsset = { error: false, message: "" })
        : (tempUserInput.SelInventoryAsset = {
            error: true,
            message: "Inventory Asset Account cant be empty",
          });
    }
    if (
      validateComponents.includes("SelBankAccount") ||
      validateComponents.includes("all")
    ) {
      SelBankAccount !== null
        ? (tempUserInput.SelBankAccount = {
            error: false,
            message: "",
          })
        : (tempUserInput.SelBankAccount = {
            error: true,
            message: "Bank Account cant be empty",
          });
    }
    if (
      validateComponents.includes("SelPOActsPayable") ||
      validateComponents.includes("all")
    ) {
      SelPOActsPayable !== null
        ? (tempUserInput.SelPOActsPayable = {
            error: false,
            message: "",
          })
        : (tempUserInput.SelPOActsPayable = {
            error: true,
            message: "PO Accounts Payable cant be empty",
          });
    }
    if (
      validateComponents.includes("SelPOExpense") ||
      validateComponents.includes("all")
    ) {
      SelPOExpense !== null
        ? (tempUserInput.SelPOExpense = {
            error: false,
            message: "",
          })
        : (tempUserInput.SelPOExpense = {
            error: true,
            message: "PO Expense Account cant be empty",
          });
    }
    if (
      validateComponents.includes("SelInvoiceActsReceivable") ||
      validateComponents.includes("all")
    ) {
      SelInvoiceActsReceivable !== null
        ? (tempUserInput.SelInvoiceActsReceivable = {
            error: false,
            message: "",
          })
        : (tempUserInput.SelInvoiceActsReceivable = {
            error: true,
            message: "Invoice Receivable Account cant be empty",
          });
    }
    if (
      validateComponents.includes("SelGeneralBankAcct") ||
      validateComponents.includes("all")
    ) {
      SelGeneralBankAcct !== null
        ? (tempUserInput.SelGeneralBankAcct = {
            error: false,
            message: "",
          })
        : (tempUserInput.SelGeneralBankAcct = {
            error: true,
            message: "Invoice Receivable Account cant be empty",
          });
    }

    Object.keys(tempUserInput).forEach((key) => {
      if (tempUserInput[key].error) {
        tempUserInput.UIValidate = {
          error: true,
          message: "",
          validated: true,
        };
      }
    });
    setValidateSettings(tempUserInput);
    if (tempUserInput.UIValidate.error) {
      dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Validation Error",
          desc: `Please check inputs`,
          isYesNo: false,
        },
      });
    } else {
      if (cb) cb();
    }
  };

  const getInvoiceDetailsConst = async () => {
    //const detData = await invoiceApi.getInvoiceDetailsConst(dispatch);
    const DETAILS = await invoiceApi.getAllInvoiceDetail(dispatch);

    //console.log("INVOICE DETAILS DATA ===>", detData, DETAILS);

    const _DETAIL_TYPE = DETAILS.find((x) => x.name === "Type");
    const _DETAIL_INVOICE_STATUS = DETAILS.find((x) => x.name === "Status");
    const _DETAIL_INVOICE_CONTENT_STATUS = DETAILS.find(
      (x) => x.name === "ContentStatus"
    );
    const _DETAIL_INVOICE_CONTENT_TYPE = DETAILS.find(
      (x) => x.name === "ContentType"
    );

    setDETAIL_TYPE(_DETAIL_TYPE.details);
    setDETAIL_INVOICE_STATUS(_DETAIL_INVOICE_STATUS.details);
    setDETAIL_INVOICE_CONTENT_STATUS(_DETAIL_INVOICE_CONTENT_STATUS.details);
    setDETAIL_INVOICE_CONTENT_TYPE(_DETAIL_INVOICE_CONTENT_TYPE.details);

    setInvoiceDetails(DETAILS);
    //setUIDateToday(moment(DateToday));
  };

  const refreshInventory = async (initial) => {
    setSelInventoryLoading(true);
    let inventories = [];
    inventories = await inventoryApi.getInventory(dispatch);
    //console.log(inventories);
    if (inventories.length === 0) {
      dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Inventory Empty",
          desc: `You dont have permission to access any inventory. Please contact your administrator`,
          isYesNo: false,
        },
      });
    } else {
      setInventoryData(inventories);
      setSelInventoryLoading(false);
      if (initial) {
        setSelInventory(inventories[0]);

        //refreshSettings(inventories[0]);
        //setInitialLoad(true);
      }
    }
  };

  const refreshSettings = async (initialInventory) => {
    setSettingsLoading(true);

    const inventoryId = initialInventory
      ? Number(initialInventory.id)
      : Number(SelInventory.id);
    if (InventoryData.length >= 0) {
      //console.log(InventoryData[0].id);
    }
    const settings = await settingsApi.getSettingsByInventory(
      dispatch,
      inventoryId
    );
      //Update Invoice Headers
      setInvoiceHeaderData(
        settings?.invoiceHeaderAddress1,
        settings?.invoiceHeaderAddress2,
        settings?.invoiceHeaderAddress3,
        settings?.invoiceHeaderLandline,
        settings?.invoiceHeaderMobile,
        settings?.invoiceHeaderContact,
        settings?.invoiceHeaderEmail,
        settings?.invoiceDeliveryReceiveAddress
      )
    const accounts = await accountsApi.getAccountByType(dispatch, inventoryId);

    //console.log(accounts, settings);
    if (accounts.expenses) {
      setAccountsExpenses(accounts.expenses);
      //setSelExpenseData(accounts.expenses);
    }
    if (accounts.revenues) {
      setAccountsRevenues(accounts.revenues);
      //setSelInvoiceIncomeData(accounts.revenues);
    }
    if (accounts.assets) {
      setAccountsAssets(accounts.assets);
      //setSelInventoryAssetData(accounts.assets);
    }
    if (accounts.liabilities) {
      setAccountsLiabilities(accounts.liabilities);
      //setSelInventoryAssetData(accounts.assets);
    }
    if (accounts.equity) {
      setAccountsEquity(accounts.equity);
      //setSelInventoryAssetData(accounts.assets);
    }
    if (settings) {
      populateSettings(settings);
    } else {
      dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "No settings",
          desc: `Please create a default setting for this selected inventory`,
          isYesNo: false,
        },
      });
    }
    setSettingsLoading(false);
  };

  const handleSettingsReset = () => {
    setSettingsSelection([]);
    setSelExpense(null);
    setSelPOSIncome(null);
    setSelInvoiceIncome(null);
    setSelInventoryAsset(null);
    setSelInvoiceActsReceivable(null);
    setSelBankAccount(null);
    setSelPOActsPayable(null);
    setSelPOExpense(null);
    setSelGeneralBankAcct(null);
    setSelDashExpense(null);
    setSelDashSales(null);
    setSelDashProfit(null);
    setSelDashActsReceivable(null);
    setSelDashActsPayable(null);
    setTxtEmail1(null);
    setTxtEmail2(null);
    setValidateSettings(initValidateSettings);
    setTxtPriceModification(null);
    setTxtPriceModificationDate("");
    setSelAdjustedPrice(null);
    setTxtValueAddedTax("");
    setTxtInvoiceHeaderAddress1("");
    setTxtInvoiceHeaderAddress2("");
    setTxtInvoiceHeaderAddress3("");
    setTxtInvoiceHeaderLandline("");
    setTxtInvoiceHeaderMobile("");
    setTxtInvoiceHeaderContact("");
    setTxtInvoiceHeaderEmail("");
    setTxtInvoiceDeliveryReceiveAddress("");
  };

  const populateSettings = async (data) => {
    if (data) {
      setSelInvoiceIncome(data.incomeAccountInvoice);
      setSelPOSIncome(data.incomeAccountPOS);
      setSelExpense(data.expenseAccount);
      setSelInventoryAsset(data.inventoryAccount);
      setSelInvoiceActsReceivable(data.actsReceivableAccountInvoice);
      setSelBankAccount(data.POBankAccount);
      setSelPOActsPayable(data.POAcctsPayableAccount);
      setSelPOExpense(data.POExpenseAccount);
      setSelGeneralBankAcct(data.generalBank);

      setSelDashExpense(data.statExpenseAccount);
      setSelDashSales(data.statSalesAccount);
      setSelDashProfit(data.statProfitAccount);
      setSelDashActsReceivable(data.statActsReceivableAccount);
      setSelDashActsPayable(data.statActsPayableAccount);
      setTxtEmail1(data.emailReceiver1);
      setTxtEmail2(data.emailReceiver2);
      setTxtPriceModification(data.itemPriceModification);
      setTxtPriceModificationDate(data.itemPriceModificationDate);
      setSelAdjustedPrice(data.adjustedPrice);
      setTxtValueAddedTax(data.valueAddedTax);
      setTxtInvoiceHeaderAddress1(data.invoiceHeaderAddress1);
      setTxtInvoiceHeaderAddress2(data.invoiceHeaderAddress2);
      setTxtInvoiceHeaderAddress3(data.invoiceHeaderAddress3);
      setTxtInvoiceHeaderLandline(data.invoiceHeaderLandline);
      setTxtInvoiceHeaderMobile(data.invoiceHeaderMobile);
      setTxtInvoiceHeaderContact(data.invoiceHeaderContact);
      setTxtInvoiceHeaderEmail(data.invoiceHeaderEmail);
      setTxtInvoiceDeliveryReceiveAddress(data.invoiceDeliveryReceiveAddress);
    }
  };
  const saveSettings = async (cb) => {
    let InventoryId = Number(SelInventory.id);
    // console.log(SelInventory);
    if (InventoryId === 1) {
      InventoryId = null;
    }

    const data = {
      inventoryId: InventoryId,
      expenseAccountId: SelExpense.id,
      incomeAccountInvoiceId: SelInvoiceIncome.id,
      incomeAccountPOSId: SelPOSIncome.id,
      inventoryAccountId: SelInventoryAsset.id,
      actsReceivableAccountInvoiceId: SelInvoiceActsReceivable.id,
      POAcctsPayableAccountId: SelPOActsPayable.id,
      POExpenseAccountId: SelPOExpense.id,
      POBankAccountId: SelBankAccount.id,
      generalBankId: SelGeneralBankAcct.id,

      statSalesAccountId: SelDashSales.id,
      statProfitAccountId: SelDashProfit.id,
      statExpenseAccountId: SelDashExpense.id,
      statActsPayableAccountId: SelDashActsPayable.id,
      statActsReceivableAccountId: SelDashActsReceivable.id,
      ...(TxtEmail1 ? { emailReceiver1: TxtEmail1 } : null),
      ...(TxtEmail2 ? { emailReceiver2: TxtEmail2 } : null),
      adjustedPriceId: SelAdjustedPrice.id,
      itemPriceModification: TxtPriceModification,
      valueAddedTax: TxtValueAddedTax,
      invoiceHeaderAddress1: TxtInvoiceHeaderAddress1,
      invoiceHeaderAddress2: TxtInvoiceHeaderAddress2,
      invoiceHeaderAddress3: TxtInvoiceHeaderAddress3,
      invoiceHeaderLandline: TxtInvoiceHeaderLandline,
      invoiceHeaderMobile: TxtInvoiceHeaderMobile,
      invoiceHeaderContact: TxtInvoiceHeaderContact,
      invoiceHeaderEmail: TxtInvoiceHeaderEmail,
      invoiceDeliveryReceiveAddress: TxtInvoiceDeliveryReceiveAddress,
    };
    console.log("PRE-POST DATA ===>", data);

    const modifiedSettings = await settingsApi.saveSettings(
      dispatch,
      data,
      () => {
        if (cb) {
          cb();
        }
      },
      (newData) => {
        dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Settings Creation Error",
            desc: `Please Contact Administrator`,
            isYesNo: false,
          },
        });
      }
    );
    // handleSettingsReset();
    if (modifiedSettings) {
      // console.log(modifiedSettings);
      populateSettings(modifiedSettings.data);
      setSettingsSelectedOperation(SELECTED_OPERATION.VIEW);
    }

    //console.log(modifiedSettings);
    // setCreatedInvoice(createdInvoice.data);

    //console.log("CREATED INVOICE ===> ", createdInvoice);
  };

  const modifySettings = async (cb) => {
    const data = {
      name: TxtSettingsName,
      id: SelectedSettingsList.id,

      ...(!ChkIsSettingsParent ? { parentId: SelSettingsParent.id } : null),
      ...(ChkIsSettingsParent ? { settingsType: SelSettingsType.value } : null),
    };
    console.log("PRE-POST UPDATE DATA ===>", data);

    const updatedInvoice = await settingsApi.updateSettings(
      dispatch,
      data,
      (success) => {
        if (cb) {
          // handleSettingssReset();
          cb();
        }
        //findSettingsAndReplace(success.data);
        //console.log(success);
      },
      () => {
        dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Settings Modification Error",
            desc: `Please Contact Administrator`,
            isYesNo: false,
          },
        });
      }
    );
    // setCreatedInvoice(createdInvoice.data);

    handleSettingsReset();
    //console.log("CREATED INVOICE ===> ", createdInvoice);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",

        // marginTop: "-30px",
      }}
    >
      <div
        style={{ zIndex: "100" }}
        className="globalContainerStyle my-2 p-3 animate__animated animate__fadeInLeft"
      >
        <Row className="" style={{}} noGutters>
          <Col xs={5} sm={5} className="user-select">
            <div
              className=""
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p
                className="m-0 pl-2"
                style={{
                  backgroundColor: "var(--REB-primary)",
                  color: "var(--REB-white)",
                  fontWeight: "bold",
                  fontSize: "0.8rem",
                  width: "230px",
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                SELECTED INVENTORY
              </p>
              <Select
                styles={{
                  menu: (provided, state) => {
                    //console.log(state);
                    const width = "100%";

                    let color = state.selectProps.menuColor;
                    const padding = 0;

                    return { ...provided, width, color, padding };
                  },
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "5px",
                  }),

                  container: (_, { selectProps: { width } }) => ({
                    width: width,
                    // flex: 1,
                  }),
                  menuPortal: (provided) => ({
                    ...provided,
                    zIndex: 9999,

                    borderRadius: "5px 0px 0px 5px",
                  }),

                  option: (provided, state) => {
                    let backgroundColor = "";
                    let display = "block";
                    let color = "black";
                    if (state.data.isDefault === 1) {
                      backgroundColor = "rgba(255, 0, 0, 0.2)";
                    }
                    const opacity = state.isDisabled ? 0.5 : 1;
                    const transition = "opacity 300ms";
                    //console.log(state.data);
                    if (state.data.isHidden === 1) {
                      display = "none";
                    }

                    return {
                      ...provided,
                      opacity,
                      transition,
                      backgroundColor,
                      color,
                      display,
                    };
                  },
                }}
                isSearchable
                menuPortalTarget={document.body}
                width="100%"
                options={InventoryData}
                value={SelInventory}
                isLoading={SelInventoryLoading}
                isDisabled={SelInventoryDisabled}
                getOptionLabel={(x) => x.name}
                getOptionValue={(x) => x.id}
                onChange={(x, name) => {
                  setSelInventory(x);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div
        className="py-3 px-3"
        style={{
          borderRadius: "10px",
          backgroundColor: "var(--REB-white)",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <div className="" style={{ overflow: "auto", flex: 1, height: "100%" }}>
          <Row noGutters>
            <Col xs={12} className="px-2 my-2">
              <h5>General Settings</h5>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="px-2 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      ValidateSettings.SelInvoiceIncome.error
                        ? "var(--REB-warning)"
                        : "var(--REB-primary)"
                    }`,
                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  General Bank Account
                </p>
                <Select
                  styles={customStyles}
                  isSearchable
                  menuPortalTarget={document.body}
                  width="100%"
                  options={AccountsAssets}
                  value={SelGeneralBankAcct}
                  //isLoading={SelInventoryLoading}
                  isDisabled={
                    SettingsSelectedOperation === SELECTED_OPERATION.VIEW
                  }
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.id}
                  onChange={(x, name) => {
                    setSelGeneralBankAcct(x);
                  }}
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="px-2 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      ValidateSettings.SelInvoiceIncome.error
                        ? "var(--REB-warning)"
                        : "var(--REB-primary)"
                    }`,
                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Default Expense Account
                </p>
                <Select
                  styles={customStyles}
                  isSearchable
                  menuPortalTarget={document.body}
                  width="100%"
                  options={AccountsExpenses}
                  value={SelExpense}
                  //isLoading={SelInventoryLoading}
                  isDisabled={
                    SettingsSelectedOperation === SELECTED_OPERATION.VIEW
                  }
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.id}
                  onChange={(x, name) => {
                    setSelExpense(x);
                  }}
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="px-2 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      ValidateSettings.SelInvoiceIncome.error
                        ? "var(--REB-warning)"
                        : "var(--REB-primary)"
                    }`,
                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Default Inventory Asset Account
                </p>
                <Select
                  styles={customStyles}
                  isSearchable
                  menuPortalTarget={document.body}
                  width="100%"
                  options={AccountsAssets}
                  value={SelInventoryAsset}
                  //isLoading={SelInventoryLoading}
                  isDisabled={
                    SettingsSelectedOperation === SELECTED_OPERATION.VIEW
                  }
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.id}
                  onChange={(x, name) => {
                    setSelInventoryAsset(x);
                  }}
                />
              </div>
            </Col>
            <Col xs={12} className="px-2 my-2">
              <h5>POS Accounting Settings</h5>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="px-2 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      ValidateSettings.SelInvoiceIncome.error
                        ? "var(--REB-warning)"
                        : "var(--REB-primary)"
                    }`,
                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Default POS Income Account
                </p>
                <Select
                  styles={customStyles}
                  isSearchable
                  menuPortalTarget={document.body}
                  width="100%"
                  options={AccountsRevenues}
                  value={SelPOSIncome}
                  isLoading={SelPOSIncomeLoading}
                  isDisabled={
                    SettingsSelectedOperation === SELECTED_OPERATION.VIEW
                  }
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.id}
                  onChange={(x, name) => {
                    setSelPOSIncome(x);
                  }}
                />
              </div>
            </Col>
            <Col xs={12} className="px-2 my-2">
              <h5>Invoice Quote Settings</h5>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="px-2 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      ValidateSettings.SelInvoiceIncome.error
                        ? "var(--REB-warning)"
                        : "var(--REB-primary)"
                    }`,
                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Default Invoice Income Account
                </p>
                <Select
                  styles={customStyles}
                  isSearchable
                  menuPortalTarget={document.body}
                  width="100%"
                  options={AccountsRevenues}
                  value={SelInvoiceIncome}
                  //isLoading={SelInventoryLoading}
                  isDisabled={
                    SettingsSelectedOperation === SELECTED_OPERATION.VIEW
                  }
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.id}
                  onChange={(x, name) => {
                    setSelInvoiceIncome(x);
                  }}
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="px-2 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      ValidateSettings.SelInvoiceIncome.error
                        ? "var(--REB-warning)"
                        : "var(--REB-primary)"
                    }`,
                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Default Invoice Receivable Account
                </p>
                <Select
                  styles={customStyles}
                  isSearchable
                  menuPortalTarget={document.body}
                  width="100%"
                  options={AccountsAssets}
                  value={SelInvoiceActsReceivable}
                  //isLoading={SelInventoryLoading}
                  isDisabled={
                    SettingsSelectedOperation === SELECTED_OPERATION.VIEW
                  }
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.id}
                  onChange={(x, name) => {
                    setSelInvoiceActsReceivable(x);
                  }}
                />
              </div>
            </Col>
            <Col xs={12} className="px-2 my-2">
              <h5>Purchase Order Accounting Settings</h5>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="px-2 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      ValidateSettings.SelInvoiceIncome.error
                        ? "var(--REB-warning)"
                        : "var(--REB-primary)"
                    }`,

                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Default PO Bank Account
                </p>
                <Select
                  styles={customStyles}
                  isSearchable
                  menuPortalTarget={document.body}
                  width="100%"
                  options={AccountsAssets}
                  value={SelBankAccount}
                  //isLoading={SelInventoryLoading}
                  isDisabled={
                    SettingsSelectedOperation === SELECTED_OPERATION.VIEW
                  }
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.id}
                  onChange={(x, name) => {
                    setSelBankAccount(x);
                  }}
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="px-2 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",

                  borderRadius: "5px",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      ValidateSettings.SelInvoiceIncome.error
                        ? "var(--REB-warning)"
                        : "var(--REB-primary)"
                    }`,
                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Default PO Expense Account
                </p>
                <Select
                  styles={customStyles}
                  isSearchable
                  menuPortalTarget={document.body}
                  width="100%"
                  options={AccountsExpenses}
                  value={SelPOExpense}
                  //isLoading={SelInventoryLoading}
                  isDisabled={
                    SettingsSelectedOperation === SELECTED_OPERATION.VIEW
                  }
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.id}
                  onChange={(x, name) => {
                    setSelPOExpense(x);
                  }}
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="px-2 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      ValidateSettings.SelInvoiceIncome.error
                        ? "var(--REB-warning)"
                        : "var(--REB-primary)"
                    }`,

                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Default PO Accts. Payable Account
                </p>
                <Select
                  styles={customStyles}
                  isSearchable
                  menuPortalTarget={document.body}
                  width="100%"
                  options={AccountsLiabilities}
                  value={SelPOActsPayable}
                  //isLoading={SelInventoryLoading}
                  isDisabled={
                    SettingsSelectedOperation === SELECTED_OPERATION.VIEW
                  }
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.id}
                  onChange={(x, name) => {
                    setSelPOActsPayable(x);
                  }}
                />
              </div>
            </Col>
            <Col xs={12} className="px-2 my-2">
              <h5>Dashboard Statistics Settings</h5>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="px-2 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      ValidateSettings.SelDashExpense.error
                        ? "var(--REB-warning)"
                        : "var(--REB-primary)"
                    }`,

                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Expense Account
                </p>
                <Select
                  styles={customStyles}
                  isSearchable
                  menuPortalTarget={document.body}
                  width="100%"
                  options={AccountsExpenses}
                  value={SelDashExpense}
                  //isLoading={SelInventoryLoading}
                  isDisabled={
                    SettingsSelectedOperation === SELECTED_OPERATION.VIEW
                  }
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.id}
                  onChange={(x, name) => {
                    setSelDashExpense(x);
                  }}
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="px-2 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      ValidateSettings.SelDashSales.error
                        ? "var(--REB-warning)"
                        : "var(--REB-primary)"
                    }`,

                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Sales Account
                </p>
                <Select
                  styles={customStyles}
                  isSearchable
                  menuPortalTarget={document.body}
                  width="100%"
                  options={AccountsRevenues}
                  value={SelDashSales}
                  //isLoading={SelInventoryLoading}
                  isDisabled={
                    SettingsSelectedOperation === SELECTED_OPERATION.VIEW
                  }
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.id}
                  onChange={(x, name) => {
                    setSelDashSales(x);
                  }}
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="px-2 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      ValidateSettings.SelDashProfit.error
                        ? "var(--REB-warning)"
                        : "var(--REB-primary)"
                    }`,

                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Profit Account
                </p>
                <Select
                  styles={customStyles}
                  isSearchable
                  menuPortalTarget={document.body}
                  width="100%"
                  options={AccountsRevenues}
                  value={SelDashProfit}
                  //isLoading={SelInventoryLoading}
                  isDisabled={
                    SettingsSelectedOperation === SELECTED_OPERATION.VIEW
                  }
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.id}
                  onChange={(x, name) => {
                    setSelDashProfit(x);
                  }}
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="px-2 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      ValidateSettings.SelDashActsReceivable.error
                        ? "var(--REB-warning)"
                        : "var(--REB-primary)"
                    }`,

                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Accounts Receivable Account
                </p>
                <Select
                  styles={customStyles}
                  isSearchable
                  menuPortalTarget={document.body}
                  width="100%"
                  options={AccountsAssets}
                  value={SelDashActsReceivable}
                  //isLoading={SelInventoryLoading}
                  isDisabled={
                    SettingsSelectedOperation === SELECTED_OPERATION.VIEW
                  }
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.id}
                  onChange={(x, name) => {
                    setSelDashActsReceivable(x);
                  }}
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="px-2 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      ValidateSettings.SelDashActsPayable.error
                        ? "var(--REB-warning)"
                        : "var(--REB-primary)"
                    }`,

                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Accounts Payable Account
                </p>
                <Select
                  styles={customStyles}
                  isSearchable
                  menuPortalTarget={document.body}
                  width="100%"
                  options={AccountsLiabilities}
                  value={SelDashActsPayable}
                  //isLoading={SelInventoryLoading}
                  isDisabled={
                    SettingsSelectedOperation === SELECTED_OPERATION.VIEW
                  }
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.id}
                  onChange={(x, name) => {
                    setSelDashActsPayable(x);
                  }}
                />
              </div>
            </Col>
            <Col xs={12} className="px-2 my-2">
              <h5>Email Notification Settings</h5>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="px-2 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      ValidateSettings.SelDashActsPayable.error
                        ? "var(--REB-warning)"
                        : "var(--REB-primary)"
                    }`,

                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Notif. Email Recipient 1
                </p>
                <FormControl
                  placeholder="Notif. Email Recipient 1"
                  className="h-100"
                  style={{ borderRadius: "0px 5px 5px 5px" }}
                  value={TxtEmail1}
                  onChange={(e) => {
                    setTxtEmail1(e.currentTarget.value);
                  }}
                  disabled={
                    SettingsSelectedOperation === SELECTED_OPERATION.VIEW
                  }
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="px-2 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      ValidateSettings.SelDashActsPayable.error
                        ? "var(--REB-warning)"
                        : "var(--REB-primary)"
                    }`,

                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Notif. Email Recipient 2
                </p>
                <FormControl
                  placeholder="Notif. Email Recipient 2"
                  className="h-100"
                  style={{ borderRadius: "0px 5px 5px 5px" }}
                  value={TxtEmail2}
                  onChange={(e) => {
                    setTxtEmail2(e.currentTarget.value);
                  }}
                  disabled={
                    SettingsSelectedOperation === SELECTED_OPERATION.VIEW
                  }
                />
              </div>
            </Col>
            <Col xs={12} className="px-2 my-2">
              <h5 style={{}} className="pb-0 mb-0">
                Item Price Adjustment Settings
              </h5>
              <p style={{ color: "var(--REB-warning)" }} className="m-0 p-0">
                Warning! This setting adds or subtracts a percentage on " ALL "
                Items. This will apply to all " NEW " Sales. All previous item
                prices will be unaffected.
              </p>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="px-2 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      ValidateSettings.SelDashActsPayable.error
                        ? "var(--REB-warning)"
                        : "var(--REB-primary)"
                    }`,

                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Items Price Adjustment %
                </p>
                <InputGroup className="">
                  <FormControl
                    as={NumericFormat}
                    inputMode="numeric"
                    thousandSeparator={true}
                    placeholder="Price Modification Percentage"
                    style={{ borderRadius: "0px 0px 0px 5px" }}
                    value={TxtPriceModification}
                    suffix={" %"}
                    allowNegative={false}
                    isAllowed={(values) => {
                      const { formattedValue, floatValue } = values;
                      return formattedValue === "" || floatValue <= 100;
                    }}
                    onValueChange={(x) => {
                      setTxtPriceModification(Number(x.value));
                    }}
                    disabled={
                      SettingsSelectedOperation === SELECTED_OPERATION.VIEW
                    }
                  />
                  <InputGroup.Prepend
                    style={{ borderRadius: "0px 5px 5px 0px" }}
                  >
                    <InputGroup.Text className="">
                      <i className="icofont-sale-discount "></i>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                </InputGroup>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="px-2 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      ValidateSettings.SelDashActsPayable.error
                        ? "var(--REB-warning)"
                        : "var(--REB-primary)"
                    }`,

                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Adjusted Price Income Account
                </p>
                <Select
                  styles={customStyles}
                  isSearchable
                  menuPortalTarget={document.body}
                  width="100%"
                  options={AccountsRevenues}
                  value={SelAdjustedPrice}
                  //isLoading={SelInventoryLoading}
                  isDisabled={
                    SettingsSelectedOperation === SELECTED_OPERATION.VIEW
                  }
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.id}
                  onChange={(x, name) => {
                    setSelAdjustedPrice(x);
                  }}
                />
              </div>
            </Col>
            <Col xs={12} className="px-2 my-2">
              <h5 style={{}} className="pb-0 mb-0">
                Taxes Settings
              </h5>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="px-2 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      ValidateSettings.SelDashActsPayable.error
                        ? "var(--REB-warning)"
                        : "var(--REB-primary)"
                    }`,

                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Value Added Tax %
                </p>
                <InputGroup className="">
                  <FormControl
                    as={NumericFormat}
                    inputMode="numeric"
                    thousandSeparator={true}
                    placeholder="Value Added Tax %"
                    style={{ borderRadius: "0px 0px 0px 5px" }}
                    value={TxtValueAddedTax}
                    suffix={" %"}
                    allowNegative={false}
                    isAllowed={(values) => {
                      const { formattedValue, floatValue } = values;
                      return formattedValue === "" || floatValue <= 100;
                    }}
                    onValueChange={(x) => {
                      setTxtValueAddedTax(Number(x.value));
                    }}
                    disabled={
                      SettingsSelectedOperation === SELECTED_OPERATION.VIEW
                    }
                  />
                  <InputGroup.Prepend
                    style={{ borderRadius: "0px 5px 5px 0px" }}
                  >
                    <InputGroup.Text className="">
                      <i className="icofont-sale-discount "></i>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                </InputGroup>
              </div>
            </Col>
            <Col xs={12} className="px-2 my-2">
              <h5 style={{}} className="pb-0 mb-0">
                Invoice Header Information
              </h5>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="px-2 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      ValidateSettings.SelDashActsPayable.error
                        ? "var(--REB-warning)"
                        : "var(--REB-primary)"
                    }`,

                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Header Address Line 1
                </p>
                <FormControl
                  placeholder="Header Address Line 1"
                  className="h-100"
                  style={{ borderRadius: "0px 5px 5px 5px" }}
                  value={TxtInvoiceHeaderAddress1}
                  onChange={(e) => {
                    setTxtInvoiceHeaderAddress1(e.currentTarget.value);
                  }}
                  disabled={
                    SettingsSelectedOperation === SELECTED_OPERATION.VIEW
                  }
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="px-2 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      ValidateSettings.SelDashActsPayable.error
                        ? "var(--REB-warning)"
                        : "var(--REB-primary)"
                    }`,

                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Header Address Line 2
                </p>
                <FormControl
                  placeholder="Header Address Line 2"
                  className="h-100"
                  style={{ borderRadius: "0px 5px 5px 5px" }}
                  value={TxtInvoiceHeaderAddress2}
                  onChange={(e) => {
                    setTxtInvoiceHeaderAddress2(e.currentTarget.value);
                  }}
                  disabled={
                    SettingsSelectedOperation === SELECTED_OPERATION.VIEW
                  }
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="px-2 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      ValidateSettings.SelDashActsPayable.error
                        ? "var(--REB-warning)"
                        : "var(--REB-primary)"
                    }`,

                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Header Address Line 3
                </p>
                <FormControl
                  placeholder="Header Address Line 3"
                  className="h-100"
                  style={{ borderRadius: "0px 5px 5px 5px" }}
                  value={TxtInvoiceHeaderAddress3}
                  onChange={(e) => {
                    setTxtInvoiceHeaderAddress3(e.currentTarget.value);
                  }}
                  disabled={
                    SettingsSelectedOperation === SELECTED_OPERATION.VIEW
                  }
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="px-2 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      ValidateSettings.SelDashActsPayable.error
                        ? "var(--REB-warning)"
                        : "var(--REB-primary)"
                    }`,

                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Header Landline
                </p>
                <FormControl
                  placeholder="Header Landline"
                  className="h-100"
                  style={{ borderRadius: "0px 5px 5px 5px" }}
                  value={TxtInvoiceHeaderLandline}
                  onChange={(e) => {
                    setTxtInvoiceHeaderLandline(e.currentTarget.value);
                  }}
                  disabled={
                    SettingsSelectedOperation === SELECTED_OPERATION.VIEW
                  }
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="px-2 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      ValidateSettings.SelDashActsPayable.error
                        ? "var(--REB-warning)"
                        : "var(--REB-primary)"
                    }`,

                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Header Mobile No.
                </p>
                <FormControl
                  placeholder="Header Mobile No."
                  className="h-100"
                  style={{ borderRadius: "0px 5px 5px 5px" }}
                  value={TxtInvoiceHeaderMobile}
                  onChange={(e) => {
                    setTxtInvoiceHeaderMobile(e.currentTarget.value);
                  }}
                  disabled={
                    SettingsSelectedOperation === SELECTED_OPERATION.VIEW
                  }
                />
              </div>
            </Col>

            <Col xs={12} sm={12} md={6} lg={4} className="px-2 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      ValidateSettings.SelDashActsPayable.error
                        ? "var(--REB-warning)"
                        : "var(--REB-primary)"
                    }`,

                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Header Email
                </p>
                <FormControl
                  placeholder="Header Email"
                  className="h-100"
                  style={{ borderRadius: "0px 5px 5px 5px" }}
                  value={TxtInvoiceHeaderEmail}
                  onChange={(e) => {
                    setTxtInvoiceHeaderEmail(e.currentTarget.value);
                  }}
                  disabled={
                    SettingsSelectedOperation === SELECTED_OPERATION.VIEW
                  }
                />
              </div>
            </Col>
            <Col xs={12} className="px-2 my-2">
              <h5 style={{}} className="pb-0 mb-0">
                Receive Address AutoFill Information
              </h5>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} className="px-2 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      ValidateSettings.SelDashActsPayable.error
                        ? "var(--REB-warning)"
                        : "var(--REB-primary)"
                    }`,

                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  PO Receiving Address AutoFill
                </p>
                <FormControl
                  placeholder="PO Receiving Address AutoFill"
                  className="h-100"
                  style={{ borderRadius: "0px 5px 5px 5px" }}
                  as="textarea"
                  value={TxtInvoiceDeliveryReceiveAddress}
                  onChange={(e) => {
                    setTxtInvoiceDeliveryReceiveAddress(e.currentTarget.value);
                  }}
                  disabled={
                    SettingsSelectedOperation === SELECTED_OPERATION.VIEW
                  }
                />
              </div>
            </Col>
            {/* <Col xs={12} className="px-2 my-2">
              <h5 style={{}} className="pb-0 mb-0">
                Server Console Live Logs
              </h5>
            </Col> */}
          </Row>
        </div>
        <Row className="w-100 my-3" style={{}} noGutters>
          {(SettingsSelectedOperation === SELECTED_OPERATION.ADD ||
            SettingsSelectedOperation === SELECTED_OPERATION.EDIT) && (
            <>
              <Col xs={4} sm={4} md={4} lg={4} className="px-2">
                <Button
                  block
                  name="btnSubmitInvoice"
                  //disabled={props.state.handleInvoiceModal.isView}
                  style={{ backgroundColor: "var(--REB-green)" }}
                  onClick={(e) => {
                    // SettingsSelectedOperation === SELECTED_OPERATION.ADD &&
                    //   validateAccount(["all"], () => createAccount());
                    // SettingsSelectedOperation === SELECTED_OPERATION.EDIT &&
                    //   validateAccount(["all"], () => modifyAccount());
                    validateSettings(["all"], () => saveSettings());
                  }}
                >
                  SAVE
                </Button>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} className="px-2">
                <Button
                  block
                  name="btnDeleteInvoice"
                  style={{ backgroundColor: "var(--REB-warning)" }}
                  // disabled={
                  //   props.state.handleInvoiceModal.isView ||
                  //   Number(props.state.selectedOperation.id) === 0
                  // }
                  onClick={(e) => {
                    setSettingsSelectedOperation(SELECTED_OPERATION.VIEW);
                    setValidateSettings(initValidateSettings);
                    refreshSettings();
                  }}
                >
                  CANCEL
                </Button>
              </Col>
            </>
          )}
          {SettingsSelectedOperation === SELECTED_OPERATION.VIEW && (
            <>
              <Col xs={4} sm={4} md={4} lg={4} className=" px-2 ">
                <Button
                  block
                  name="btnUnlockInvoice"
                  //disabled={!props.state.handleInvoiceModal.isView}
                  style={{ backgroundColor: "var(--REB-green)" }}
                  onClick={(e) => {
                    setSettingsSelectedOperation(SELECTED_OPERATION.EDIT);
                  }}
                >
                  MODIFY
                </Button>
              </Col>
            </>
          )}
        </Row>
      </div>
    </div>
  );
};
