import React from "react";
//import { NumericFormat } from 'react-number-format';

//import TESTIMG from "../../../assets/executiveSig.png";
import misc from "../../../functions/misc";
import { Text, View, Font, Document, Page, Image } from "@react-pdf/renderer";
import REBLogo from "../../../assets/rebLogo.png";
import moment from "moment";
import demoLogo from "../../../assets/Demo/DemoLogo.png";
Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf",
      fontWeight: 800,
    },
  ],
});

class Receipt {
  ReceiptPDF(
    CartData,
    UIDateToday,
    UIAmountDue,
    UIAmountChange,
    UITotalQtyItems,
    UIVat,
    UIVatSales,
    SelClient,
    CURRENT_USER,
    TxtCashTendered,
    createdInvoice,
    reprint
  ) {
    //console.log(CURRENT_USER, SelClient);
    let receiptData = [];
    receiptData = receiptData.concat(CartData);
    return (
      <Document>
        <Page
          //79mm at 72dpi
          size={224}
          wrap={false}
          style={[
            {
              fontFamily: "Open Sans",
              paddingTop: 10,
              paddingBottom: 10,
              paddingHorizontal: 10,
            },
          ]}
        >
          {process.env.REACT_APP_THEME === "SURFACEPLUS" && (
            <View>
              {/* <Text
                style={[{ color: "black", textAlign: "center", fontSize: 7 }]}
              >
                Surface Plus
              </Text> */}
              {process.env.REACT_APP_THEME === "SURFACEPLUS" && (
                <View
                  style={[
                    {
                      display: "flex",

                      alignContent: "center",
                    },
                  ]}
                >
                  <Image
                    style={[{ width: 130, alignSelf: "center" }]}
                    src={REBLogo}
                  />
                </View>
              )}
              <Text
                style={[{ color: "black", textAlign: "center", fontSize: 7 }]}
              >
                22 Commission Civil, Jaro
              </Text>
              <Text
                style={[{ color: "black", textAlign: "center", fontSize: 7 }]}
              >
                Lapaz, Iloilo City, 5000
              </Text>

              <Text
                style={[{ color: "black", textAlign: "center", fontSize: 7 }]}
              >
                TIN: 000-000-000-000 VAT S/N: 000-000-000-000
              </Text>
              <Text
                style={[{ color: "black", textAlign: "center", fontSize: 7 }]}
              >
                MIN: 000-000-000-000
              </Text>
              <Text
                style={[{ color: "black", textAlign: "center", fontSize: 7 }]}
              >
                PN: 000-000-000-000
              </Text>
            </View>
          )}

          {process.env.REACT_APP_THEME === "DEMO" && (
            <View>
              <View
                style={[
                  {
                    display: "flex",

                    alignContent: "center",
                  },
                ]}
              >
                <Image
                  style={[{ width: 45, alignSelf: "center" }]}
                  src={demoLogo}
                />
              </View>

              <Text
                style={[{ color: "black", textAlign: "center", fontSize: 7 }]}
              >
                Quintin Salas, Jaro
              </Text>
              <Text
                style={[{ color: "black", textAlign: "center", fontSize: 7 }]}
              >
                Iloilo City, 5000
              </Text>
              <Text
                style={[{ color: "black", textAlign: "center", fontSize: 7 }]}
              >
                TIN: 000-000-000-000 VAT S/N: 000-000-000-000
              </Text>
              <Text
                style={[{ color: "black", textAlign: "center", fontSize: 7 }]}
              >
                MIN: 000-000-000-000
              </Text>
              <Text
                style={[{ color: "black", textAlign: "center", fontSize: 7 }]}
              >
                PN: 000-000-000-000
              </Text>
            </View>
          )}

          <View View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={[{ color: "black", fontSize: 7, flex: 1 }]}>
              {moment(
                createdInvoice.dateFinished
                  ? createdInvoice.dateFinished
                  : createdInvoice.dateAdded
              ).format("ddd, ll")}
            </Text>
            <Text
              style={[
                { color: "black", fontSize: 7, flex: 1, textAlign: "right" },
              ]}
            >
              {moment(
                createdInvoice.dateFinished
                  ? createdInvoice.dateFinished
                  : createdInvoice.dateAdded
              ).format("LT")}
            </Text>
          </View>
          <View View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={[{ color: "black", fontSize: 7, flex: 1 }]}>
              Cashier:
            </Text>
            <Text
              style={[
                { color: "black", fontSize: 7, flex: 1, textAlign: "right" },
              ]}
            >
              {`${CURRENT_USER.lastName ? CURRENT_USER.lastName : ""} ${
                CURRENT_USER.firstName
              }`}
            </Text>
          </View>
          <View View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={[{ color: "black", fontSize: 7, flex: 1 }]}>
              Trx No:
            </Text>
            <Text
              style={[
                { color: "black", fontSize: 7, flex: 1, textAlign: "right" },
              ]}
            >
              {createdInvoice.id} - {createdInvoice.uniqueId}
            </Text>
          </View>
          <Text style={[{ color: "black", textAlign: "center", fontSize: 7 }]}>
            ----------------------------------------------------------------------------
          </Text>
          {reprint ? (
            <>
              {receiptData.map((e, index) => {
                const unit = e.item.details.find((val) => val.type === 2);
                const itemName = e.item.details.find((val) => val.type === 1);
                const discountPercent = e.discount;
                const priceModifier = e.priceModifier;
                return (
                  <View
                    key={`${index}-receiptItemsReprint`}
                    style={[{ display: "flex" }]}
                  >
                    <Text style={[{ color: "black", fontSize: 6, flex: 3 }]}>
                      {itemName?.name}
                    </Text>
                    <View
                      View
                      style={[{ display: "flex", flexDirection: "row" }]}
                    >
                      <Text
                        style={[
                          {
                            color: "black",
                            fontSize: 7,
                            flex: 1,
                            paddingHorizontal: 5,
                          },
                        ]}
                      >
                        {` ${e.itemQty} ${unit.name} @ ${Number(
                          e.price
                        ).toFixed(2)}`}
                      </Text>
                      <Text
                        style={[
                          {
                            color: "black",
                            fontSize: 7,
                            flex: 2,
                            textAlign: "right",
                          },
                        ]}
                      >
                        {/* <NumberFormat
                          // value={Math.abs(
                          //   Number(UIAmountDue - Number(TxtCashTendered)).toFixed(2)
                          // )}
                          value={Number(e.itemQty * e.price).toFixed(2)}
                          // prefix="₱ "
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={"text"}
                          thousandSeparator={true}
                        /> */}
                        {misc.parseCurrencyNoCurrency(
                          Number(e.itemQty * e.price).toFixed(2)
                        )}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </>
          ) : (
            <>
              {receiptData.map((e, index) => {
                const unit = e.details.find((val) => val.type === 2);
                const discountPercent = e.discount;
                const priceModifier = e.priceModifier;
                return (
                  <View
                    key={`${index}-receiptItems`}
                    View
                    style={[{ display: "flex" }]}
                  >
                    <Text style={[{ color: "black", fontSize: 6, flex: 3 }]}>
                      {e.name}
                    </Text>
                    <View
                      View
                      style={[{ display: "flex", flexDirection: "row" }]}
                    >
                      <Text
                        style={[
                          {
                            color: "black",
                            fontSize: 7,
                            flex: 1,
                            paddingHorizontal: 5,
                          },
                        ]}
                      >
                        {` ${e.qty} ${unit.name} @ ${Number(e.price).toFixed(
                          2
                        )}`}
                      </Text>
                      <Text
                        style={[
                          {
                            color: "black",
                            fontSize: 7,
                            flex: 2,
                            textAlign: "right",
                          },
                        ]}
                      >
                        {/* <NumberFormat
                          // value={Math.abs(
                          //   Number(UIAmountDue - Number(TxtCashTendered)).toFixed(2)
                          // )}
                          value={Number(e.qty * e.price).toFixed(2)}
                          // prefix="₱ "
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={"text"}
                          thousandSeparator={true}
                        /> */}
                        {misc.parseCurrencyNoCurrency(
                          Number(e.qty * e.price).toFixed(2)
                        )}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </>
          )}

          <View View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={[{ color: "black", fontSize: 7, flex: 1 }]}>
              Amount Due:
            </Text>
            <Text
              style={[
                { color: "black", fontSize: 7, flex: 1, textAlign: "right" },
              ]}
            >
              {/* <NumberFormat
                value={UIAmountDue}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
                thousandSeparator={true}
              /> */}
              {misc.parseCurrencyNoCurrency(UIAmountDue)}
            </Text>
          </View>
          <View View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={[{ color: "black", fontSize: 7, flex: 1 }]}>
              Cash Tendered:
            </Text>
            <Text
              style={[
                { color: "black", fontSize: 7, flex: 1, textAlign: "right" },
              ]}
            >
              {/* <NumberFormat
                // value={Math.abs(
                //   Number(UIAmountDue - Number(TxtCashTendered)).toFixed(2)
                // )}
                value={Number(createdInvoice.cashTendered).toFixed(2)}
                // prefix="₱ "
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
                thousandSeparator={true}
              /> */}
              {misc.parseCurrencyNoCurrency(
                Number(createdInvoice.cashTendered).toFixed(2)
              )}
            </Text>
          </View>
          <View View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={[{ color: "black", fontSize: 7, flex: 1 }]}>
              Change:
            </Text>
            <Text
              style={[
                { color: "black", fontSize: 7, flex: 1, textAlign: "right" },
              ]}
            >
              {/* <NumberFormat
                value={Number(
                  createdInvoice.cashTendered - createdInvoice.sellPriceGross
                ).toFixed(2)}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
                thousandSeparator={true}
              /> */}
              {misc.parseCurrencyNoCurrency(
                Number(
                  createdInvoice.cashTendered - createdInvoice.sellPriceGross
                ).toFixed(2)
              )}
            </Text>
          </View>
          <View View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={[{ color: "black", fontSize: 7, flex: 1 }]}>
              Total Items:
            </Text>
            <Text
              style={[
                { color: "black", fontSize: 7, flex: 1, textAlign: "right" },
              ]}
            >
              {/* <NumberFormat
                value={UITotalQtyItems}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
                thousandSeparator={true}
              /> */}
              {misc.parseCurrencyNoCurrency(Number(UITotalQtyItems))}
            </Text>
          </View>
          <Text style={[{ color: "black", textAlign: "center", fontSize: 7 }]}>
            --------------------------------- Tax Info
            ---------------------------------
          </Text>
          <View View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={[{ color: "black", fontSize: 7, flex: 1 }]}>
              VAT Sales:
            </Text>
            <Text
              style={[
                { color: "black", fontSize: 7, flex: 1, textAlign: "right" },
              ]}
            >
              {/* <NumberFormat
                value={UIVatSales}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
                thousandSeparator={true}
              /> */}
              {misc.parseCurrencyNoCurrency(Number(UIVatSales))}
            </Text>
          </View>
          <View View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={[{ color: "black", fontSize: 7, flex: 1 }]}>
              VAT Exempt Sales:
            </Text>
            <Text
              style={[
                { color: "black", fontSize: 7, flex: 1, textAlign: "right" },
              ]}
            >
              0.00
            </Text>
          </View>
          <View View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={[{ color: "black", fontSize: 7, flex: 1 }]}>
              Zero Rated Sales:
            </Text>
            <Text
              style={[
                { color: "black", fontSize: 7, flex: 1, textAlign: "right" },
              ]}
            >
              0.00
            </Text>
          </View>
          <View View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={[{ color: "black", fontSize: 7, flex: 1 }]}>VAT:</Text>
            <Text
              style={[
                { color: "black", fontSize: 7, flex: 1, textAlign: "right" },
              ]}
            >
              {/* <NumberFormat
                value={UIVat}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
                thousandSeparator={true}
              /> */}
              {misc.parseCurrencyNoCurrency(Number(UIVat))}
            </Text>
          </View>
          <View View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text
              style={[
                { color: "black", fontSize: 9.5, flex: 1, fontWeight: "bold" },
              ]}
            >
              TOTAL:
            </Text>
            <Text
              style={[
                {
                  color: "black",
                  fontSize: 9.5,
                  flex: 1,
                  textAlign: "right",
                  fontWeight: "bold",
                },
              ]}
            >
              {/* <NumberFormat
                value={UIAmountDue}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
                thousandSeparator={true}
              /> */}
              {misc.parseCurrencyNoCurrency(Number(UIAmountDue))}
            </Text>
          </View>
          <Text style={[{ color: "black", textAlign: "center", fontSize: 7 }]}>
            ----------------------------------------------------------------------------
          </Text>
          <View View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={[{ color: "black", fontSize: 7, flex: 1 }]}>
              Customer:
            </Text>
            <Text
              style={[
                { color: "black", fontSize: 7, flex: 1, textAlign: "right" },
              ]}
            >
              {createdInvoice.client?.company}
            </Text>
          </View>
          <View View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={[{ color: "black", fontSize: 7, flex: 1 }]}>
              Address:
            </Text>
            <Text
              style={[
                { color: "black", fontSize: 7, flex: 1, textAlign: "right" },
              ]}
            >
              {createdInvoice.client?.address}
            </Text>
          </View>
          <Text style={[{ color: "black", textAlign: "center", fontSize: 7 }]}>
            ----------------------------------------------------------------------------
          </Text>
          <View View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={[{ color: "black", fontSize: 7, flex: 1 }]}>
              Name:
            </Text>
            <Text
              style={[
                { color: "black", fontSize: 7, flex: 1, textAlign: "right" },
              ]}
            >
              _________________________________
            </Text>
          </View>
          <View View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={[{ color: "black", fontSize: 7, flex: 1 }]}>
              Business Style:
            </Text>
            <Text
              style={[
                { color: "black", fontSize: 7, flex: 1, textAlign: "right" },
              ]}
            >
              _________________________________
            </Text>
          </View>
          <View View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={[{ color: "black", fontSize: 7, flex: 1 }]}>
              Address:
            </Text>
            <Text
              style={[
                { color: "black", fontSize: 7, flex: 1, textAlign: "right" },
              ]}
            >
              _________________________________
            </Text>
          </View>
          <View View style={[{ display: "flex", flexDirection: "row" }]}>
            <Text style={[{ color: "black", fontSize: 7, flex: 1 }]}>TIN:</Text>
            <Text
              style={[
                { color: "black", fontSize: 7, flex: 1, textAlign: "right" },
              ]}
            >
              _________________________________
            </Text>
          </View>
          <Text style={[{ color: "black", textAlign: "center", fontSize: 7 }]}>
            ----------------------------------------------------------------------------
          </Text>
          <View>
            <Text
              style={[{ color: "black", textAlign: "center", fontSize: 6 }]}
            >
              This receipt signifies that you received the merchandise in good
              condition.
            </Text>
            <Text
              style={[{ color: "black", textAlign: "center", fontSize: 6 }]}
            >
              Thanks for doing business with us!
            </Text>
          </View>

          <Text style={[{ color: "black", textAlign: "center", fontSize: 7 }]}>
            ----------------------------------------------------------------------------
          </Text>
          <View>
            <Text
              style={[{ color: "black", textAlign: "center", fontSize: 6 }]}
            >
              Please present this receipt at the exit gate for checking and
              releasing
            </Text>
          </View>
          <View style={[{ marginTop: 2 }]}>
            <Text
              style={[
                {
                  color: "black",
                  textAlign: "center",
                  fontSize: 7,
                  margin: 0,
                  padding: 0,
                },
              ]}
            >
              ___________________________________
            </Text>
            <Text
              style={[
                {
                  color: "black",
                  textAlign: "center",
                  fontSize: 7,
                  margin: 0,
                  padding: 0,
                },
              ]}
            >
              Signature of checker
            </Text>
          </View>
          <Text style={[{ color: "black", textAlign: "center", fontSize: 7 }]}>
            --------------------------------- Supplier
            ---------------------------------
          </Text>

          <Text style={[{ color: "black", fontSize: 7 }]}>
            Iloilo City, 5000
          </Text>
          <Text style={[{ color: "black", fontSize: 7 }]}>
            TIN: 000-000-000-000
          </Text>
          <Text style={[{ color: "black", fontSize: 7 }]}>
            ACC NO.: 000-000-000-000
          </Text>
          <Text style={[{ color: "black", fontSize: 7 }]}>
            ACC Date: 01/01/2022
          </Text>
          <Text style={[{ color: "black", fontSize: 7 }]}>
            Valid until: 01/01/2025
          </Text>
          <Text style={[{ color: "black", textAlign: "center", fontSize: 7 }]}>
            ----------------------------------------------------------------------------
          </Text>
          <View>
            <Text
              style={[{ color: "black", textAlign: "center", fontSize: 6 }]}
            >
              --- THIS RECEIPT IS NOT VALID IN CLAIMING INPUT TAXES ---
            </Text>
            <Text
              style={[{ color: "black", textAlign: "center", fontSize: 6 }]}
            >
              --- THIS IS NOT THE OFFICIAL RECEIPT ---
            </Text>
            <Text
              style={[{ color: "black", textAlign: "center", fontSize: 6 }]}
            >
              THIS INVOICE/RECEIPT SHALL BE VALID FOR FIVE(5) YEARS FROM THE
              DATE OF THE PERMIT TO USE
            </Text>
          </View>
        </Page>
      </Document>
    );
  }
}
export default new Receipt();
// let QuotePDF = (props) => (

// );
//export { QuotePDF };
