import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";

import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
//import { PDFDownloadLink } from "@react-pdf/renderer";

import { NumericFormat } from "react-number-format";

import { Modal } from "react-bootstrap";

import ClientSelect from "../../components/CustomDrop";

export const CheckoutModal = (props) => {
  const {
    CheckoutModalOpen,
    setCheckoutModalOpen,
    ValidateCheckout,
    TxtCashTendered,
    setTxtCashTendered,
    UIAmountDue,
    setUIAmountChange,
    ClientData,
    SelClientLoading,
    SelClient,
    getActors,
    setSelClient,
    SelClientDisabled,
    UIAmountChange,
    validateCheckout,
    createInvoice,
  } = props;
  const dispatch = useDispatch();
  return (
    <>
      {/* CHECKOUT MODAL */}
      <Modal
        show={CheckoutModalOpen}
        onHide={() => {
          setCheckoutModalOpen(false);
        }}
        onExit={(e) => {
          setCheckoutModalOpen(false);
        }}
        dialogClassName=""
        centered
      >
        <Modal.Header
          className="p-0 global-modal-header"
          style={{ backgroundColor: "var(--REB-primary)" }}
        >
          <Modal.Title className="p-2 global-modal-header-title">
            <Row noGutters>
              <Col xs="auto" className="h-100">
                <Button
                  style={{ backgroundColor: "var(--REB-warning)" }}
                  onClick={() => {
                    setCheckoutModalOpen(false);
                  }}
                  className="h-100"
                >
                  <i className="icofont-ui-close"></i>
                </Button>
              </Col>
              <Col xs="auto" className="pl-3" style={{ alignSelf: "center" }}>
                <h6
                  className="text-center m-0 h-100"
                  style={{ color: "var(--REB-white)", fontWeight: "bolder" }}
                >
                  Checkout Transaction
                </h6>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3" noGutters>
            <Col xs className="px-2">
              <Form.Label
                className="font-weight-bold"
                style={{ color: "var(--REB-black)" }}
              >
                <span className="required-field">* </span>
                Cash Tendered
              </Form.Label>
              <FormControl
                as={NumericFormat}
                inputMode="numeric"
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={"₱ "}
                style={{}}
                isInvalid={ValidateCheckout.TxtCashTendered.error}
                placeholder="Cash Tendered"
                value={TxtCashTendered}
                onValueChange={(x) => {
                  setTxtCashTendered(Number(x.value));
                  const _change = Number(Number(x.value) - UIAmountDue);
                  //console.log(_change);
                  if (_change >= 0) {
                    setUIAmountChange(_change);
                  } else {
                    setUIAmountChange(0);
                  }
                }}
                className={""}
              />
              {ValidateCheckout.TxtCashTendered.error && (
                <p style={{ color: " var(--REB-warning)", fontSize: "0.8rem" }}>
                  {ValidateCheckout.TxtCashTendered.message}
                </p>
              )}
            </Col>
            {/* <Col xs="auto" className="px-2">
              <Button
                block
                onClick={() => {
                  validateCheckout(["TxtCashTendered"], () => {
                    CalculateChange();
                  });
                }}
                className="icofont-ui-calculator  h-100 mr-4"
                style={{ backgroundColor: "var(--REB-green)" }}
              ></Button>
            </Col> */}
          </Row>
          <div className="px-2">
            <ClientSelect
              state={{ userType: [] }}
              data={ClientData}
              name="selDetailClient"
              containerClassname=""
              selectContainerStyle={{
                ...(ValidateCheckout.SelClient.error
                  ? {
                      border: "solid 2px var(--REB-warning)",
                      borderRadius: "6px",
                    }
                  : null),
              }}
              isLoading={SelClientLoading}
              selected={SelClient}
              //tabIndex={7}
              lblHidden={false}
              refreshData={(event) => {
                getActors();
              }}
              detType={6}
              dataStructure="invoiceActor"
              addUrl="actor/"
              editUrl="actor/updateActor"
              deleteUrl="actor/deleteActor"
              componentName="Client"
              componentType=""
              optionLabel="company"
              handler={(e) => {
                setSelClient(e.target.value);
                // console.log(e);
              }}
              disabled={SelClientDisabled}
              error={true}
            />
          </div>
          {ValidateCheckout.SelClient.error && (
            <p
              className="px-2"
              style={{ color: " var(--REB-warning)", fontSize: "0.8rem" }}
            >
              {ValidateCheckout.SelClient.message}
            </p>
          )}

          <Row
            style={{ display: "flex", alignContent: "end", flexGrow: 1 }}
            noGutters
            className=""
          >
            <Col xs={6} className={"p-2"} style={{}}>
              <p className="m-0" style={{ fontSize: "1.5rem" }}>
                Amount Due:
              </p>
            </Col>
            <Col xs={6} className={"p-2"} style={{}}>
              <p
                className="m-0"
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bolder",
                }}
              >
                <NumericFormat
                  value={UIAmountDue}
                  prefix="₱ "
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </p>
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              alignContent: "end",
              flexGrow: 1,
              backgroundColor:
                Number(TxtCashTendered) >= Number(UIAmountDue)
                  ? "#60BF81"
                  : "var(--REB-warning)",
            }}
            noGutters
            className=""
          >
            <Col
              xs={6}
              className={"p-2"}
              style={{ backgroundColor: "transparent" }}
            >
              <p className="m-0" style={{ fontSize: "1.5rem" }}>
                Change:
              </p>
            </Col>
            <Col
              xs={6}
              className={"p-2"}
              style={{
                backgroundColor: "transparent",
              }}
            >
              <p
                className="m-0"
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bolder",
                }}
              >
                <NumericFormat
                  // value={Math.abs(
                  //   Number(UIAmountDue - Number(TxtCashTendered)).toFixed(2)
                  // )}
                  value={UIAmountChange}
                  prefix="₱ "
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </p>
            </Col>

            {/* <Col xs className="mt-2">
              <Button
                onClick={() => {
                  dispatch({
                    type: "PDF_MODAL_TOGGLE",
                    doc: Receipt.ReceiptPDF(
                      CartData,
                      UIDateToday,
                      UIAmountDue,
                      UIAmountChange,
                      UITotalQtyItems,
                      UIVat,
                      UIVatSales,
                      SelClient,
                      CURRENT_USER,
                      TxtCashTendered
                    ),
                  });
                }}
                style={{ backgroundColor: "var(--REB-tertiary)" }}
              >
                Print Receipt
              </Button>
            </Col> */}
          </Row>
        </Modal.Body>
        <Modal.Footer className="global-modal-footer">
          <Row noGutters>
            <Col xs>
              <Button
                onClick={() => {
                  validateCheckout(["all"], () => {
                    createInvoice(() => {
                      dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "Transaction Finished",
                          desc: `Ready for next transaction`,
                          isYesNo: false,
                        },
                      });
                    });
                  });
                }}
                style={{ backgroundColor: "var(--REB-tertiary)" }}
              >
                Checkout
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};
