class TransactionsAPI {
  getAllTransactions = async (dispatch, filter) => {
    const filterData = [];
    if (filter.inventoryId)
      filterData.push(`&inventoryId=${filter.inventoryId}`);
    if (filter.generalTransactionType)
      filterData.push(
        `&generalTransactionType=${filter.generalTransactionType}`
      );
    if (filter.showFinished) {
      filterData.push(`&showFinished=${filter.showFinished}`);
    }
    if (filter.dateStart) {
      filterData.push(`&dateStart=${filter.dateStart}`);
    }
    if (filter.dateEnd) {
      filterData.push(`&dateEnd=${filter.dateEnd}`);
    }

    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `accounting/getAllTransactions?${filterData.join("")}`,
        loadingMsg: "Fetching All Transactions",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getAllTransactionsByClientId = async (
    dispatch,
    clientIds,
    acctsId,
    filter
  ) => {
    const filterData = [];
    //if (filter.clientId) filterData.push(`&clientId=${filter.clientId}`);
    if (filter.inventoryId)
      filterData.push(`&inventoryId=${filter.inventoryId}`);
    if (filter.generalTransactionType)
      filterData.push(
        `&generalTransactionType=${filter.generalTransactionType}`
      );
    if (filter.showFinished) {
      filterData.push(`&showFinished=${filter.showFinished}`);
    }
    if (filter.dateStart) {
      filterData.push(`&dateStart=${filter.dateStart}`);
    }
    if (filter.dateEnd) {
      filterData.push(`&dateEnd=${filter.dateEnd}`);
    }
    //console.log(filter);
    const stringIds = String(clientIds);
    const stringAcctsId = String(acctsId);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `accounting/getAllTransactionsByClientId?clientId=${stringIds}&acctsId=${stringAcctsId}${filterData.join(
          ""
        )}`,
        loadingMsg: "Fetching All Transactions",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getTransaction = async (dispatch, id) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `accounting/getTransaction?id=${id}`,
        loadingMsg: "Fetching  Transaction",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getTransactionsByUid = async (dispatch, uid) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `accounting/getTransactionsByUid?uid=${uid}`,
        loadingMsg: "Fetching  Transactions",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getAccountByName = async (dispatch, name, inventoryId) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `accounting/getAccountByName`,
        reqType: "post",
        data: { name, inventoryId },
        loadingMsg: "Fetching Account",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getAccountTypes = async (dispatch) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `accounting/getAccountTypes`,
        loadingMsg: "Fetching All Account Types",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getParentAccounts = async (dispatch, filter) => {
    const filterData = [];
    if (filter.inventoryId)
      filterData.push(`&inventoryId=${filter.inventoryId}`);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `accounting/getAllAccounts?isParent=1&${filterData.join("")}`,
        loadingMsg: "Fetching Parent Accounts",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  createAccount = async (dispatch, uiData, cb, cbFail) => {
    //console.log(sdata);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `accounting/createAccount`,
        loadingMsg: "Creating Account",
        reqType: "post",
        data: uiData,
        resolve: resolve,
        reject: reject,
      });
    }).then(
      async (onfulfilled) => {
        //console.log(onfulfilled);
        if (cb) await cb();
        return onfulfilled.data;
      },
      async (onrejected) => {
        if (cb) await cb();
        return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };
  updateAccount = async (dispatch, uiData, cb, cbFail) => {
    //console.log(sdata);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `accounting/updateAccount`,
        loadingMsg: "Creating Account",
        reqType: "put",
        data: uiData,
        resolve: resolve,
        reject: reject,
      });
    }).then(
      async (onfulfilled) => {
        //console.log(onfulfilled);
        if (cb) await cb(onfulfilled.data);
        return onfulfilled.data;
      },
      async (onrejected) => {
        if (cb) await cb();
        return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };
  createTransaction = async (dispatch, uiData, cb, cbFail) => {
    //console.log(sdata);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `accounting/createTransaction`,
        loadingMsg: "Creating Transaction",
        reqType: "post",
        data: uiData,
        resolve: resolve,
        reject: reject,
      });
    }).then(
      async (onfulfilled) => {
        //console.log(onfulfilled);
        if (cb) await cb();
        return onfulfilled.data;
      },
      async (onrejected) => {
        if (cb) await cb();
        return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };
  createMultiTransaction = async (dispatch, uiData, cb, cbFail) => {
    //console.log(sdata);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `accounting/createMultiTransaction`,
        loadingMsg: "Creating Multi Transaction",
        reqType: "post",
        data: uiData,
        resolve: resolve,
        reject: reject,
      });
    }).then(
      async (onfulfilled) => {
        //console.log(onfulfilled);
        if (cb) await cb();
        return onfulfilled.data;
      },
      async (onrejected) => {
        if (cb) await cb();
        return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };
  updateTransaction = async (dispatch, uiData, cb, cbFail) => {
    //console.log(sdata);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `accounting/updateTransaction`,
        loadingMsg: "Updating Transaction",
        reqType: "put",
        data: uiData,
        resolve: resolve,
        reject: reject,
      });
    }).then(
      async (onfulfilled) => {
        //console.log(onfulfilled);
        if (cb) await cb(onfulfilled.data);
        return onfulfilled.data;
      },
      async (onrejected) => {
        if (cb) await cb();
        return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };
  updateMultiTransaction = async (dispatch, uiData, cb, cbFail) => {
    //console.log(sdata);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `accounting/updateMultiTransaction`,
        loadingMsg: "Updating Multi Transaction",
        reqType: "put",
        data: uiData,
        resolve: resolve,
        reject: reject,
      });
    }).then(
      async (onfulfilled) => {
        //console.log(onfulfilled);
        if (cb) await cb(onfulfilled.data);
        return onfulfilled.data;
      },
      async (onrejected) => {
        if (cb) await cb();
        return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };
  deleteTransactionByUid = async (dispatch, uid, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "delete",
        data: { uid: uid },
        payload: "accounting/deleteTransactionByUid",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      async (onfulfilled) => {
        //console.log(onfulfilled);
        if (cb) await cb();
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };
  deleteTransaction = async (dispatch, id, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "delete",
        data: { id: id },
        payload: "accounting/deleteTransaction",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      async (onfulfilled) => {
        //console.log(onfulfilled);
        if (cb) await cb();
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };
}
export default new TransactionsAPI();
