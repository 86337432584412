import React from "react";

import { GlobalModal } from "./GlobalModal";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

//const cookies = new Cookies();

class ModalProcess extends React.Component {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
    this.state = {
      // handleModal: {
      //   handler: this.handleModal.bind(this),
      //   show: this.handleShowModal.bind(this),
      //   hide: this.handleHideModal.bind(this),
      //   isYesNo: true,
      //   isShow: false,
      //   title: "",
      //   desc: "",
      //   response: false
      // }
    };
  }

  componentDidMount() {
    //this.props.dispatch({ type: "MODAL_INIT", payload: url });
  }

  componentWillUnmount() {}

  render() {
    //console.log(this.props);
    return <GlobalModal redux={this.props} />;
  }

  // static getDerivedStateFromProps(nextProps, state) {
  //   console.log(nextProps);
  //   // if (nextProps.modal.globalModal.data) {
  //   //   if (!state.isFiltered) {
  //   //     //console.log("trigger");
  //   //     return {
  //   //       // empData: nextProps.modal.getData.data,
  //   //       // bakEmpData: nextProps.modal.getData.data,
  //   //       // isLoading: nextProps.modal.getData.loading
  //   //     };
  //   //   } else {
  //   //     return {};
  //   //   }
  //   // }
  // }
}

const mapStateToProps = (state) => {
  return {
    modal: state.globalModal,
  };
};
export default connect(mapStateToProps)(withRouter(ModalProcess));
