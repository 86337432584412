import React, { useState, useRef, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from "moment";
import misc from "../../functions/misc";
import { NumericFormat } from "react-number-format";
import Badge from "react-bootstrap/Badge";

export function invoiceColumn() {
  return [
    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          ID
        </p>
      ),
      id: "uniqueId",
      maxWidth: 110,
      show: false,
      filterable: false,
      getProps: (row) => {
        return { style: { fontSize: "10px" } };
      },
      accessor: (d) => (d.uniqueId === null ? "-" : d.uniqueId),
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Invoice Name / Transaction
        </p>
      ),
      id: "name",
      filterable: false,
      //maxWidth: 200,
      accessor: (d) => (d.name === null ? "-" : d.name),
      getProps: (row) => {
        return { style: {} };
      },
      Cell: (row) => {
        const d = row.original;
        let name = "-";
        if (d?.client !== null) {
          name = d?.client?.company === null ? "-" : d?.client?.company;
        }
        if (d.supplier !== null) {
          name = d?.supplier?.company === null ? "-" : d?.supplier?.company;
        }
        return (
          <>
            <div
              style={{
                display: "block",
                wordWrap: "break-word",
                whiteSpace: "normal",
                textTransform: "uppercase",
                fontSize: "0.70rem",
                fontWeight: "bolder",
              }}
            >
              {d.id} - {d.uniqueId}
            </div>
            <div
              style={{
                display: "block",
                wordWrap: "break-word",
                whiteSpace: "normal",
                textTransform: "uppercase",
              }}
            >
              {d.name}
            </div>
            <div
              style={{
                display: "block",
                wordWrap: "break-word",
                whiteSpace: "normal",
              }}
            >
              {name}
            </div>
          </>

          // <FormControl
          //   as="textarea"
          //   style={{ resize: "none", height: "100%" }}
          //   defaultValue={d.name === null ? "-" : d.name}
          //   readOnly
          // />
        );
      },
    },

    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Total
        </p>
      ),
      id: "invoiceTotal",

      maxWidth: 110,
      getProps: (row) => {
        return {
          style: {
            fontSize: "13px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        };
      },
      Cell: (row) => {
        const { value } = row;

        return (
          <>
            {Number(value) > 0 ? (
              <div
                style={{
                  display: "block",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  fontWeight: "bold",
                }}
              >
                {misc.parseCurrencyNoCurrency(Number(value))}
              </div>
            ) : null}
          </>
        );
      },

      accessor: (d) => {
        const { content } = d;
        let invoiceTotal = 0.0;

        if (content) {
          if (d.invoiceType?.name === "Quote") {
            invoiceTotal = content.reduce((a, c) => {
              return a + c.price * c.itemQty;
            }, 0);
          }
          if (
            d.invoiceType?.name === "Purchase Order" ||
            d.invoiceType?.name === "Purchase Request"
          ) {
            invoiceTotal = content.reduce((a, c) => {
              return a + c.price * c.qtyOrdered;
            }, 0);
          }
          //invoiceTotal = misc.parseCurrencyNoCurrency(invoiceTotal);
        }
        return invoiceTotal;
      },
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Balance
        </p>
      ),
      id: "invoiceBalance",
      maxWidth: 110,
      getProps: (row) => {
        return {
          style: {
            fontSize: "13px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        };
      },
      Cell: (row) => {
        const { value } = row;

        return (
          <div
            style={{
              display: "block",
              wordWrap: "break-word",
              whiteSpace: "normal",
              fontWeight: "bold",
              // backgroundColor: "var(--REB-warning)",
              // color: "var(--REB-white)",
            }}
          >
            <Badge
              style={{
                backgroundColor:
                  value.total > 0 ? "var(--REB-warning)" : "var(--REB-green)",
                color: "white",
                fontSize: "1em",
                width: "115px",
              }}
            >
              {misc.parseCurrencyNoCurrency(value.total)}
            </Badge>
            {value.pendingPaymentTrans ? (
              <>
                <p
                  className="m-0 p-0"
                  style={{ fontSize: "0.5rem", textAlign: "center" }}
                >
                  Pending Billing
                </p>
                <Badge
                  style={{
                    color: "var(--REB-black)",
                    fontSize: "1em",
                    width: "115px",
                  }}
                >
                  {misc.parseCurrencyNoCurrency(value.pendingPaymentTrans)}
                </Badge>
              </>
            ) : null}
          </div>
        );
      },

      accessor: (d) => {
        // console.log(x);
        const { content, paymentTransaction } = d;
        let invoiceTotal = 0.0;
        let amountPaid = 0.0;

        if (content) {
          if (d.invoiceType?.name === "Quote") {
            invoiceTotal = content.reduce((a, c) => {
              return a + c.price * c.itemQty;
            }, 0);
          }
          if (
            d.invoiceType?.name === "Purchase Order" ||
            d.invoiceType?.name === "Purchase Request"
          ) {
            invoiceTotal = content.reduce((a, c) => {
              return a + c.price * c.qtyOrdered;
            }, 0);
          }
          //invoiceTotal = misc.parseCurrencyNoCurrency(invoiceTotal);
        }
        let pendingPaymentTrans = 0;
        if (paymentTransaction) {
          // console.log(paymentTransaction);
          const pendingTrans = paymentTransaction.filter(
            (x) =>
              x.isEntry === 0 &&
              (x.transStatus === "pending-admin-approval" ||
                x.transStatus === "processing")
          );

          const paidTrans = paymentTransaction.filter(
            (x) => x.isEntry === 0 && x.transStatus === "finished"
          );
          amountPaid = paidTrans.reduce((a, c) => {
            return a + c.amountValue;
          }, 0);
          pendingPaymentTrans = pendingTrans.reduce((a, c) => {
            return a + c.amountValue;
          }, 0);
          //amountPaid = misc.parseCurrencyNoCurrency(amountPaid);
        }
        // console.log(invoiceTotal, amountPaid);
        const total = Number(invoiceTotal - amountPaid);
        return { total, pendingPaymentTrans };
      },
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Date Last Modified
        </p>
      ),
      id: "dateLastModified",
      filterable: false,
      show: false,

      accessor: (d) => d.dateLastModified,
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Created
        </p>
      ),
      id: "dateAdded",
      filterable: false,
      maxWidth: 100,
      getProps: (row) => {
        return {
          style: {
            fontSize: "13px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        };
      },
      Cell: (d) => {
        const data = d.original;
        const name =
          data.dateAdded === null
            ? "-"
            : misc.parseAPIDateTimeToShortDate(
                misc.parseAPIDateTime(data.dateAdded)
              );
        return (
          <div
            style={{
              display: "block",
              wordWrap: "break-word",
              whiteSpace: "normal",
            }}
          >
            {name}
          </div>
        );
      },
      accessor: (d) => d.dateAdded,
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Deadline
        </p>
      ),
      id: "deadline",
      filterable: false,
      maxWidth: 100,

      Cell: (row) => {
        let name = "-";
        const d = row.original;
        let daysLeft = 0;

        let warn = "initial";

        if (
          d?.invoiceStatus?.name !== "Finished" ||
          d?.invoiceStatus?.name !== "Archived"
        ) {
          if (d.deadline) {
            daysLeft = moment(d.deadline).diff(moment(), "days");
            if (daysLeft <= 2) warn = "var(--REB-yellow)";
            if (daysLeft <= 1) warn = "var(--REB-warning)";
          }
        } else {
          if (d.dateFinished) {
            daysLeft = moment(d.deadline).diff(moment(d.dateFinished), "days");
            if (daysLeft <= 2) warn = "var(--REB-yellow)";
            if (daysLeft <= 1) warn = "var(--REB-warning)";
          }
        }
        name =
          d.deadline === null
            ? "-"
            : misc.parseAPIDateTimeToShortDate(
                misc.parseAPIDateTime(d.deadline)
              );
        let holdDays = moment(moment()).diff(d.dateAdded, "days");
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {/* <Row
              style={{
                color: warn,
                width:'100%',
                textAlign: "center",
                // alignItems: "center",
              }}
              className=""
              noGutters
            >
              <Col xs={12} style={{ fontWeight: "bolder" }}>{`${name}`}</Col>
             
            </Row> */}
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--REB-warning)",
                fontSize: "0.7rem",
              }}
            >
              {name}
            </p>
            {holdDays > 15 && (
              <>
                <div style={{}}>
                  <p
                    className="p-0 m-0"
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "0.7rem",
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                    }}
                  >
                    This invoice is stale by
                  </p>
                  <p
                    className="p-0 m-0"
                    style={{
                      fontWeight: "bolder",
                      textAlign: "center",
                      color: "var(--REB-warning)",
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                      fontSize: "0.95rem",
                    }}
                  >
                    {`${holdDays}`} {holdDays > 1 ? "Days" : "Day"}
                  </p>
                </div>
              </>
            )}
          </div>
        );
      },
      // getProps: (row) => {
      //   return {
      //     style: {
      //       fontSize: "13px",
      //       height: "100%",
      //       padding: 0,

      //       display: "flex",
      //       justifyContent: "center",
      //       alignItems: "center",
      //     },
      //   };
      // },
      accessor: (d) =>
        d.deadline === null
          ? "-"
          : misc.parseAPIDateTimeToShortDate(misc.parseAPIDateTime(d.deadline)),
    },

    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Type
        </p>
      ),
      id: "type",
      maxWidth: 200,
      filterable: true,
      Cell: (row) => {
        const d = row.original;
        let name = "-";
        let dateFinished = "";
        let infoColor = "";
        let icon = <></>;
        name = d?.invoiceType === null ? "-" : d?.invoiceType?.name;

        switch (name) {
          case "Purchase Request":
            infoColor = "#157EBF";
            icon = (
              <i className="icofont-package" style={{ fontSize: "1.3em" }}></i>
            );
            break;
          case "Quote":
            infoColor = "#038C73";
            icon = (
              <i className="icofont-paper " style={{ fontSize: "1.3em" }}></i>
            );
            break;
          case "Purchase Order":
            infoColor = "#F2ACCD";
            icon = (
              <i className="icofont-cart " style={{ fontSize: "1.3em" }}></i>
            );
            break;
          case "Delivery Receipt":
            infoColor = "#BBBBEF";
            icon = (
              <i
                className="icofont-truck-loaded "
                style={{ fontSize: "1.3em" }}
              ></i>
            );
            break;
          case "POS":
            infoColor = "#8BAD47";
            icon = (
              <i className="icofont-tags" style={{ fontSize: "1.3rem" }}></i>
            );
            break;
          default:
            infoColor = "#038C73";
            icon = (
              <i className="icofont-warning " style={{ fontSize: "1.3em" }}></i>
            );
            break;
        }

        return (
          <div
            style={{
              display: "block",
              wordWrap: "break-word",
              whiteSpace: "normal",
              lineHeight: "0.8em",
              fontWeight: "bolder",
            }}
          >
            <Badge
              style={{
                backgroundColor: infoColor,
                color: "white",
                fontSize: "0.8em",
              }}
            >
              {icon} {name}
            </Badge>
          </div>
        );
      },
      getProps: (row) => {
        return {
          style: {
            padding: 0,

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        };
      },
      accessor: (d) => (d.invoiceType === null ? "-" : d?.invoiceType?.name),
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        } else {
          // console.log(row[filter.id]);
          return row[filter.id] === filter.value;
        }
        // if (filter.value === "true") {
        //   return row[filter.id] >= 21;
        // }
        // return row[filter.id] < 21;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="Quote">Quote</option>
          <option value="Purchase Request">Purchase Request</option>
          <option value="Purchase Order">Purchase Order</option>
          <option value="Delivery Receipt">Delivery Receipt</option>
          <option value="POS">POS</option>
          <option value="Transfer Inventory">Transfer Inventory</option>
        </select>
      ),
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Status
        </p>
      ),
      id: "status",
      // getProps: (state, rowInfo) => {
      //   if (rowInfo && rowInfo.row) {
      //     return {
      //       style: {
      //         backgroundColor: rowInfo.row.status === "New" ? "red" : null,
      //         height: 80,
      //       },
      //     };
      //   } else {
      //     return {};
      //   }
      // },
      accessor: (d) =>
        d.invoiceStatus === null ? "-" : d?.invoiceStatus?.name,
      maxWidth: 200,
      getProps: (row) => {
        return {
          style: {
            padding: 0,

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        };
      },
      Cell: (row) => {
        const d = row.original;

        // let holdDays = null;
        // if (d.invoiceVerificationHold) {
        //   holdDays = moment(d.invoiceVerificationHold).diff(moment(), "days");
        //   // if (holdDays < 0) holdDays = 0;
        // }

        let name = "-";
        let dateFinished = "";
        let infoColor = "";
        let icon = <></>;
        name = d.invoiceStatus === null ? "-" : d.invoiceStatus?.name;

        const { content, paymentTransaction } = d;
        let invoiceTotal = 0.0;
        let amountPaid = 0.0;

        if (content) {
          invoiceTotal = content.reduce((a, c) => {
            return a + c.price * c.itemQty;
          }, 0);
          //invoiceTotal = misc.parseCurrencyNoCurrency(invoiceTotal);
        }
        if (paymentTransaction) {
          const filteredTrans = paymentTransaction.filter(
            (x) => x.isEntry !== 0
          );
          amountPaid = filteredTrans.reduce((a, c) => {
            return a + c.amountValue;
          }, 0);
          //amountPaid = misc.parseCurrencyNoCurrency(amountPaid);
        }
        // console.log("AP => ", Number(invoiceTotal - amountPaid));
        // console.log("TOTAL => ", invoiceTotal);
        switch (name) {
          case "For Delivery":
            infoColor = "#007BD3";
            icon = (
              <i className="icofont-package" style={{ fontSize: "1.3em" }}></i>
            );
            break;
          case "Ongoing Delivery":
            infoColor = "#248A84";
            icon = (
              <i
                className="icofont-truck-loaded "
                style={{ fontSize: "1.3em" }}
              ></i>
            );
            break;
          case "Admin Denied":
            infoColor = "var(--REB-warning)";
            icon = (
              <i
                className="icofont-ui-close "
                style={{ fontSize: "1.3em" }}
              ></i>
            );
            break;
          case "Pending Admin Approval":
            infoColor = "var(--REB-secondary)";
            icon = (
              <i className="icofont-warning " style={{ fontSize: "1.3em" }}></i>
            );
            break;
          case "Finished":
            infoColor = "var(--REB-green)";
            icon = (
              <i
                className="icofont-ui-check "
                style={{ fontSize: "1.3em" }}
              ></i>
            );
            break;
          case "Archived":
            infoColor = "#DAA21C";
            icon = (
              <i className="icofont-ui-file " style={{ fontSize: "1.3em" }}></i>
            );
            break;
          case "Dispute":
            infoColor = "var(--REB-warning)";
            icon = (
              <i
                className="icofont-ui-close "
                style={{ fontSize: "1.3em" }}
              ></i>
            );
            break;
          case "Pending Client Payment":
            infoColor = "var(--REB-green)";
            icon = (
              <i className="icofont-money " style={{ fontSize: "1.3em" }}></i>
            );
            break;
          case "Pending Payment To Supplier":
            infoColor = "var(--REB-green)";
            icon = (
              <i className="icofont-money " style={{ fontSize: "1.3em" }}></i>
            );
            break;
          case "New":
            infoColor = "#8BAD47";
            icon = (
              <i className="icofont-folder " style={{ fontSize: "1.3em" }}></i>
            );
            break;
          case "Walk In":
            infoColor = "#8BAD47";
            icon = (
              <i className="icofont-tags" style={{ fontSize: "1.3rem" }}></i>
            );
            break;

          default:
            break;
        }

        if (d.invoiceStatus?.name === "Finished") {
          dateFinished =
            d.dateFinished === null
              ? "-"
              : misc.parseAPIDateTimeToShortDate(
                  misc.parseAPIDateTime(d.dateFinished)
                );
        }

        return (
          <div
            style={{
              display: "block",
              wordWrap: "break-word",
              whiteSpace: "normal",
              lineHeight: "0.8em",
              fontWeight: "bolder",
            }}
          >
            <Badge
              style={{
                backgroundColor: infoColor,
                color: "white",
                fontSize: "0.8em",
              }}
            >
              {icon} {name}
            </Badge>

            <p
              style={{
                fontSize: "0.7em",
                lineHeight: "0.01em",
                color: "var(--REB-blue)",
                fontWeight: "bold",
                margin: 0,
                paddingTop: "0.7em",
                marginBottom: "5px",
              }}
            >
              {dateFinished}
            </p>
          </div>
        );
      },
      filterable: true,
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        } else {
          //console.log(row[filter.id]);
          return row[filter.id] === filter.value;
        }
        // if (filter.value === "true") {
        //   return row[filter.id] >= 21;
        // }
        // return row[filter.id] < 21;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="New">New</option>

          <option value="For Delivery">For Delivery</option>
          <option value="Ongoing Delivery">Ongoing Delivery</option>
          <option value="Pending Admin Approval">Pending Admin Approval</option>
          <option value="Finished">Finished</option>

          <option value="Admin Denied">Admin Denied</option>
        </select>
      ),
    },
  ];
}
