import React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { PDFViewer } from "@react-pdf/renderer";
//import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tooltip from "react-bootstrap/Tooltip";

class ModalProcess extends React.Component {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    //console.log(this.props);
    return (
      <>
        <Modal
          show={this.props.modal.show}
          onHide={(e) => {
            this.props.dispatch({ type: "PDF_MODAL_TOGGLE" });
          }}
          dialogClassName="pdf-modal-dialog"
          className="pdf-modal"
          centered
        >
          <Modal.Body className="pdf-modal-body">
            <Row className="pdf-modal-header pt-3 pb-3 mb-3" noGutters>
              <Col xs={1} sm={1} md={1} lg={1}>
                <OverlayTrigger
                  placement={"right"}
                  overlay={<Tooltip id={`project-tooltip-add`}>Close</Tooltip>}
                >
                  <Button
                    variant="secondary"
                    name="btnCloseProjectModal"
                    className="btnAddProject project-btn-crud"
                    onClick={(e) => {
                      this.props.dispatch({ type: "PDF_MODAL_TOGGLE" });
                    }}
                  >
                    <i className="icofont-rounded-left"></i>
                  </Button>
                </OverlayTrigger>
              </Col>
              <Col xs={11} sm={11} md={11} lg={11}>
                <h4 className="font-weight-bold pdf-modal-header-text">
                  PDF View
                </h4>
              </Col>
            </Row>
            <PDFViewer className="pdfViewer">{this.props.modal.doc}</PDFViewer>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modal: state.PDFModal,
  };
};
export default connect(mapStateToProps)(withRouter(ModalProcess));
