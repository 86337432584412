import React from "react";
import pdfTemplate from "../../../components/PdfHeadFoot";
import {
  Text,
  View,
  Image,
  StyleSheet,
  Font,
  Canvas,
} from "@react-pdf/renderer";

import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";
import misc from "../../../functions/misc";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf",
      fontWeight: 800,
    },
  ],
});
let themeColor = "";
if (process.env.REACT_APP_THEME === "SURFACEPLUS") themeColor = "#5eaaff";
if (process.env.REACT_APP_THEME === "DEMO") themeColor = "#ed5309";
const tableStyles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  tableHeader: {
    textAlign: "left",
    fontSize: 9,
    marginLeft: 2,
    marginRight: 2,
    marginBottom: 10,
    color: themeColor,
    fontFamily: "Open Sans",
    fontWeight: 800,
    border: 0,
  },
  tableCell: {
    border: 0,
    textAlign: "left",
    // marginLeft: 2,
    marginRight: 2,
    height: 40,
  },
  tableCellNames: {
    fontSize: 8,
    border: 0,
    marginTop: 5,
    marginBottom: 5,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    border: 0,
  },
});

class Quote {
  _itemTable(data, withDescription) {
    return (
      <Table data={data} style={[{ border: 0 }]}>
        <TableHeader
          includeBottomBorder={false}
          includeLeftBorder={false}
          includeRightBorder={false}
          includeTopBorder={false}
          style={[
            {
              border: 0,
            },
          ]}
        >
          <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
            {/* ITEM DESCRIPTION */}
          </TableCell>
          <TableCell weighting={0.35} style={[tableStyles.tableHeader]}>
            ITEM DESCRIPTION
          </TableCell>
          <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
            QTY
          </TableCell>
          <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
            SRP
          </TableCell>
          <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
            DISCOUNTED
          </TableCell>
          <TableCell weighting={0.2} style={[tableStyles.tableHeader]}>
            AMOUNT
          </TableCell>
        </TableHeader>
        <TableBody
          includeBottomBorder={true}
          includeLeftBorder={false}
          includeRightBorder={false}
          includeTopBorder={false}
          style={[
            {
              backgroundColor: themeColor,
              color: themeColor,
              borderBottomColor: themeColor,
              borderBottomStyle: "solid",
              borderBottomWidth: 10,
              borderBottom: "thick solid #0000FF",
            },
          ]}
        >
          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.15}
            getContent={(a) => {
              return (
                <>
                  <View style={{ flexDirection: "row" }}>
                    {((x) => {
                      if (a.showImg === 1) {
                        if (typeof a.dispImg !== "undefined") {
                          return (
                            <Image
                              style={[
                                {
                                  maxWidth: 40,
                                  maxHeight: 40,
                                  height: 40,
                                  width: 40,
                                  marginBottom: "1.5px",
                                  //marginLeft: 2,
                                },
                              ]}
                              src={a.dispImg.original}
                            />
                          );
                        }
                      } else {
                        <View
                          style={{
                            maxWidth: 40,
                            maxHeight: 40,
                            height: 40,
                            width: 40,
                            marginBottom: "1.5px",
                          }}
                        ></View>;
                      }
                    })()}
                  </View>
                </>
              );
            }}
          />
          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.35}
            getContent={(a) => {
              let name = "-";
              if (a.itemId === null) {
                if (a.serviceName !== null)
                  name = JSON.parse(a.serviceName).label;
              } else {
                if (a.item.details.length > 0) {
                  const sDat = a.item.details.find((x) => x.type === 1);
                  sDat !== undefined ? (name = sDat.name) : (name = "-");
                }
              }
              let nameChild = "";
              if (a.childId !== null) {
                if (a.child.itemId === null) {
                  if (a.child.serviceName !== null)
                    nameChild = JSON.parse(a.child.serviceName).label;
                } else {
                  if (a.child.item.details.length > 0) {
                    const sDat = a.child.item.details.find((x) => x.type === 1);
                    sDat !== undefined
                      ? (nameChild = sDat.name)
                      : (nameChild = "");
                  }
                }
              }
              if (a.placeholder !== null) {
                name = a.placeholder;
              }

              return (
                <>
                  <View style={{ flexDirection: "row" }}>
                    <Text style={[{ marginLeft: 5, fontSize: 8 }]}>{name}</Text>
                  </View>
                  {(() => {
                    if (a.item !== null && a.item !== undefined) {
                      if (a.item.details.length > 0) {
                        const itemDetail = a.item.details.find(
                          (x) => x.type === 1
                        );
                        if (itemDetail) {
                          if (itemDetail.itemSets.length > 0) {
                            return (
                              <>
                                <View style={{ flexDirection: "row" }}>
                                  <Text style={[{ marginLeft: 15 }]}>
                                    Set Contents
                                  </Text>
                                </View>
                                <View>
                                  {((x) => {
                                    if (itemDetail) {
                                      return itemDetail.itemSets.map(
                                        (x, index) => (
                                          <View key={x.id}>
                                            <Text
                                              style={[{ marginLeft: 5 }]}
                                            >{`${index + 1}. ${
                                              x.itemNameDetail.name
                                            }`}</Text>
                                          </View>
                                        )
                                      );
                                    } else {
                                      return null;
                                    }
                                  })()}
                                </View>
                              </>
                            );
                          }
                        }
                      }
                    }
                  })()}
                  {withDescription !== undefined && withDescription !== null ? (
                    withDescription ? (
                      <View style={{ flexDirection: "row" }}>
                        <Text style={[{ marginLeft: 5, fontSize: 6 }]}>
                          {a.itemId !== null ? a.item.description : ""}
                        </Text>
                      </View>
                    ) : null
                  ) : null}
                  {/* {console.log(a)} */}
                  {a.childId !== null ? (
                    <>
                      <View style={{ flexDirection: "row" }}>
                        <Text style={[{ marginLeft: 15 }]}>
                          Item Replacement
                        </Text>
                      </View>
                      <View style={{ flexDirection: "row" }}>
                        <Text style={[{ marginLeft: 5 }]}>{nameChild}</Text>
                      </View>
                    </>
                  ) : null}
                </>
              );
            }}
          />
          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.15}
            getContent={(a) => {
              let d = a;
              a.childId !== null ? (d = a.child) : (d = a);
              let name = "-";

              if (d.itemId === null) {
                name = "Service";
              } else {
                if (d.item.details.length > 0) {
                  const sDat = d.item.details.find((x) => x.type === 2);
                  sDat !== undefined ? (name = sDat.name) : (name = "-");
                }
              }
              let nameChild = "";
              if (a.childId !== null) {
                if (a.itemId === null) {
                  if (a.serviceName !== null)
                    nameChild = JSON.parse(a.serviceName).label;
                } else {
                  if (a.item.details.length > 0) {
                    const sDat = a.item.details.find((x) => x.type === 2);
                    sDat !== undefined
                      ? (nameChild = sDat.name)
                      : (nameChild = "");
                  }
                }
              }
              return (
                <>
                  {a.placeholder === null ? (
                    <>
                      <View style={{ flexDirection: "row" }}>
                        <Text
                          style={[{ marginLeft: 5 }]}
                        >{`${d.itemQty} ${name}`}</Text>
                      </View>
                      {a.childId !== null ? (
                        <>
                          <View style={{ flexDirection: "row" }}>
                            <Text
                              style={[
                                {
                                  marginLeft: 15,
                                  marginTop:
                                    a.child.showImg === 1 &&
                                    typeof a.child.dispImg !== "undefined"
                                      ? 50
                                      : 0,
                                },
                              ]}
                            >
                              -
                            </Text>
                          </View>
                          <View style={{ flexDirection: "row" }}>
                            <Text
                              style={[{ marginLeft: 5 }]}
                            >{`${a.child.itemQty} ${nameChild}`}</Text>
                          </View>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </>
              );
            }}
          />
          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.15}
            getContent={(a) => {
              return (
                <>
                  {a.placeholder === null ? (
                    <>
                      <View style={{ flexDirection: "row" }}>
                        <Text style={[{ marginLeft: 5 }]}>
                          {misc.parseCurrencyNoCurrency(
                            a.srp + a.srp * (a.priceModifier / 100)
                          )}
                        </Text>
                      </View>

                      {a.childId !== null ? (
                        <>
                          <View style={{ flexDirection: "row" }}>
                            <Text
                              style={[
                                {
                                  marginLeft: 15,
                                  marginTop:
                                    a.child.showImg === 1 &&
                                    typeof a.child.dispImg !== "undefined"
                                      ? 50
                                      : 0,
                                },
                              ]}
                            >
                              -
                            </Text>
                          </View>
                          <View style={{ flexDirection: "row" }}>
                            <Text style={[{ marginLeft: 5 }]}>
                              {misc.parseCurrencyNoCurrency(
                                a.child.srp +
                                  a.child.srp * (a.child.priceModifier / 100)
                              )}
                            </Text>
                          </View>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </>
              );
            }}
          />
          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.15}
            getContent={(a) => {
              // let markupDiscounted = 0;
              // if (a.priceModifier) {
              //   markupDiscounted = a.price - a.price * (a.discount / 100);
              // }
              return (
                <>
                  {a.placeholder === null ? (
                    <>
                      <View style={{ flexDirection: "row" }}>
                        <Text style={[{ marginLeft: 5 }]}>
                          {/* {a.priceModifier
                            ? misc.parseCurrencyNoCurrency(markupDiscounted)
                            : misc.parseCurrencyNoCurrency(a.price)} */}
                          {misc.parseCurrencyNoCurrency(a.price)}
                        </Text>
                      </View>
                      {a.discount === 0 || a.discount === undefined ? null : (
                        <View style={{ flexDirection: "row" }}>
                          <Text style={[{ marginLeft: 5 }]}>{`${Number(
                            a.discount
                          ).toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })} %`}</Text>
                        </View>
                      )}

                      {a.childId !== null ? (
                        <>
                          <View style={{ flexDirection: "row" }}>
                            <Text
                              style={[
                                {
                                  marginLeft: 15,
                                  marginTop:
                                    a.child.showImg === 1 &&
                                    typeof a.child.dispImg !== "undefined"
                                      ? 50
                                      : 0,
                                },
                              ]}
                            >
                              -
                            </Text>
                          </View>
                          <View style={{ flexDirection: "row" }}>
                            <Text style={[{ marginLeft: 5 }]}>
                              {misc.parseCurrencyNoCurrency(a.child.price)}
                            </Text>
                          </View>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </>
              );
            }}
          />
          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.2}
            getContent={(a) => {
              let amount = 0;
              let amountChild = 0;

              let markupDiscounted = 0;

              const ITEM_QTY = Number(a.itemQty);
              const PRICE = Number(a.price);
              const SRP = Number(a.srp);
              const PRICE_MODIFIER = Number(a.priceModifier);
              const DISCOUNT = Number(a.discount);

              //console.log(ITEM_QTY,PRICE,SRP)

              //Discount and Markup
              if (Number(a.priceModifier) > 0 && Number(a.discount) > 0) {
                amount = misc.parseCurrencyNoCurrency(ITEM_QTY * PRICE);
              }

              //With Discount Only
              if (Number(a.discount) > 0 && Number(a.priceModifier) === 0) {
                amount = misc.parseCurrencyNoCurrency(ITEM_QTY * PRICE);
              }

              //With Markup Only
              if (Number(a.discount) === 0 && Number(a.priceModifier) > 0) {
                amount = misc.parseCurrencyNoCurrency(ITEM_QTY * PRICE);
              }

              //No Discount No Markup
              if (Number(a.discount) === 0 && Number(a.priceModifier) === 0) {
                amount = misc.parseCurrencyNoCurrency(ITEM_QTY * SRP);
              }

              if (a.childId !== null) {
                if (a.child.discount !== null && a.child.discount !== 0)
                  amountChild = Number(a.child.itemQty * a.child.price).toFixed(
                    2
                  );
                else {
                  amountChild = Number(a.child.srp * a.child.itemQty).toFixed(
                    2
                  );
                }
              }

              return (
                <>
                  {a.placeholder === null ? (
                    <>
                      <View style={{ flexDirection: "row" }}>
                        <Text style={[{ marginLeft: 5 }]}>{amount}</Text>
                      </View>
                      {a.childId !== null ? (
                        <>
                          <View style={{ flexDirection: "row" }}>
                            <Text
                              style={[
                                {
                                  marginLeft: 15,
                                  marginTop:
                                    a.child.showImg === 1 &&
                                    typeof a.child.dispImg !== "undefined"
                                      ? 50
                                      : 0,
                                },
                              ]}
                            >
                              -
                            </Text>
                          </View>
                          <View style={{ flexDirection: "row" }}>
                            <Text style={[{ marginLeft: 5 }]}>
                              {misc.parseCurrencyNoCurrency(amountChild)}
                            </Text>
                          </View>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </>
              );
            }}
          />
        </TableBody>
      </Table>
    );
  }

  _ServiceTable(data) {
    return (
      <Table data={data} style={[{ border: 0 }]}>
        <TableHeader
          includeBottomBorder={false}
          includeLeftBorder={false}
          includeRightBorder={false}
          includeTopBorder={false}
          style={[{ border: 0 }]}
        >
          <TableCell weighting={0.65} style={[tableStyles.tableHeader]}>
            SERVICE DESCRIPTION
          </TableCell>
          <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
            QTY
          </TableCell>
          <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
            COST
          </TableCell>
          <TableCell weighting={0.2} style={[tableStyles.tableHeader]}>
            AMOUNT
          </TableCell>
        </TableHeader>
        <TableBody
          includeBottomBorder={true}
          includeLeftBorder={false}
          includeRightBorder={false}
          includeTopBorder={false}
        >
          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.65}
            getContent={(d) => {
              let name = "-";
              if (d.itemId === null) {
                if (d.serviceName !== null)
                  name = JSON.parse(d.serviceName).label;
              } else {
                if (d.item.details.length > 0) {
                  const sDat = d.item.details.find((x) => x.type === 1);
                  sDat !== undefined ? (name = sDat.name) : (name = "-");
                }
              }

              return (
                <View style={{ flexDirection: "row" }}>
                  <Text style={[{ marginLeft: 5 }]}>{name}</Text>
                </View>
              );
            }}
          />
          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.15}
            getContent={(d) => {
              let name = "-";

              if (d.itemId === null) {
                name = "Service";
              } else {
                if (d.item.details.length > 0) {
                  const sDat = d.item.details.find((x) => x.type === 2);
                  sDat !== undefined ? (name = sDat.name) : (name = "-");
                }
              }

              return `${d.itemQty} ${name}`;
            }}
          />

          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.15}
            getContent={(d) => (d.price === null ? "" : d.price)}
          />
          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.2}
            getContent={(d) => {
              if (d.discount !== null && d.discount !== 0)
                return Number(d.itemQty * d.price).toFixed(2);
              else {
                return Number(d.srp * d.itemQty).toFixed(2);
              }
            }}
          />
        </TableBody>
      </Table>
    );
  }

  _termsSignature(approvedBy, isSig, incharge) {
    return (
      <>
        <View>
          <View
            style={[
              {
                marginTop: 5,
                width: "100%",
                textAlign: "center",
                color: themeColor,
              },
            ]}
          >
            <Text
              style={[
                {
                  fontSize: 9,
                  fontFamily: "Open Sans",
                  fontWeight: 800,
                  //paddingLeft: 50,
                },
              ]}
            >
              We look forward to serve your prestigious company with our
              products.
            </Text>
            <Text
              style={[
                {
                  fontSize: 9,
                  fontFamily: "Open Sans",
                  fontWeight: 800,
                  //paddingLeft: 50,
                },
              ]}
            >
              Thank you very much and may we look forward in serving your
              requirements soon.
            </Text>
          </View>
          <View
            style={[
              {
                marginTop: 5,

                paddingLeft: 80,
                paddingRight: 80,
              },
            ]}
          >
            <View
              style={[
                {
                  marginTop: 10,
                  flex: 1,
                  flexDirection: "row",
                },
              ]}
            >
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      color: themeColor,
                      fontFamily: "Open Sans",
                      fontWeight: 800,
                    },
                  ]}
                >
                  Prepared by:
                </Text>
              </View>
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      color: themeColor,
                      fontFamily: "Open Sans",
                      fontWeight: 800,
                    },
                  ]}
                >
                  Approved By:
                </Text>
              </View>
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      color: themeColor,
                      fontFamily: "Open Sans",
                      fontWeight: 800,
                    },
                  ]}
                >
                  Conformed By:
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View>
          <View
            style={[
              {
                marginTop: 5,
                marginBottom: 25,
                paddingLeft: 80,
                paddingRight: 80,
              },
            ]}
          >
            <View
              style={[
                {
                  marginTop: 10,
                  flex: 1,
                  flexDirection: "row",
                },
              ]}
            >
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 7,
                      textAlign: "center",
                    },
                  ]}
                >
                  {/* {incharge ? incharge.toUpperCase() : "-"} */}
                  Surface+ Team
                </Text>
                {/* {(() => {
                  if (incharge !== null && incharge !== undefined) {
                    if (incharge.uploads) {
                      if (
                        Number(isSig) === 1 &&
                        Number(incharge.uploads.length) > 0
                      ) {
                        return (
                          <Image
                            style={[
                              {
                                position: "absolute",
                                width: 150,
                                marginTop: -25,
                              },
                            ]}
                            src={{
                              uri: `${process.env.REACT_APP_API_URL}global/getFile?uniqueId=${incharge.uniqueId}&filename=${incharge.uploads[0].filename}`,
                              method: "GET",

                              headers: {
                                Authorization:
                                  "Bearer " + localStorage.getItem("LUT"),
                              },
                              body: "",
                            }}
                          />
                        );
                      }
                    }
                  }
                })()} */}
              </View>
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 7,
                      textAlign: "center",
                    },
                  ]}
                >
                  {approvedBy ? approvedBy.toUpperCase() : "-"}
                </Text>

                {/* {signatures.SIG_APPROVEDBY !== null ? (
                  <Image
                    style={[
                      { position: "absolute", width: 150, marginTop: -25 },
                    ]}
                    src={signatures.SIG_APPROVEDBY}
                  />
                ) : null} */}

                {/* {(() => {
                  if (approvedBy !== null && approvedBy !== undefined) {
                    if (approvedBy.uploads) {
                      if (
                        Number(isSig) === 1 &&
                        Number(approvedBy.uploads.length) > 0
                      ) {
                        return (
                          <Image
                            style={[
                              {
                                position: "absolute",
                                width: 150,
                                marginTop: -25,
                              },
                            ]}
                            src={{
                              uri: `${process.env.REACT_APP_API_URL}global/getFile?uniqueId=${approvedBy.uniqueId}&filename=${approvedBy.uploads[0].filename}`,
                              method: "GET",

                              headers: {
                                Authorization:
                                  "Bearer " + localStorage.getItem("LUT"),
                              },
                              body: "",
                            }}
                          />
                        );
                      }
                    }
                  }
                })()} */}
              </View>
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      textAlign: "center",
                    },
                  ]}
                ></Text>
              </View>
            </View>
          </View>
        </View>
      </>
    );
  }

  _terms(termsTableData) {
    return (
      <View
        style={[
          {
            // flexDirection: "row",
            paddingLeft: 10,
            backgroundColor: "#ebf0f3",
            marginTop: 5,
            paddingBottom: 8,
          },
        ]}
      >
        <Text
          style={[
            {
              fontSize: 10,
              paddingLeft: 20,
              color: themeColor,
              marginTop: 5,
              marginBottom: 5,
              fontFamily: "Open Sans",
              fontWeight: 800,
            },
          ]}
        >
          Terms of payment:
        </Text>
        {((x) => {
          const _termsDat = termsTableData;
          let _terms = [];
          for (let i = 0; i < _termsDat.length; i++) {
            const _e = _termsDat[i];
            _terms.push(
              <Text
                style={[
                  {
                    marginBottom: 1,
                    fontSize: 8,
                    paddingLeft: 20,
                  },
                ]}
                key={_e.id}
              >
                {/* {`${i + 1}. ${_e.name}`} */}
                {`- ${_e.name}`}
              </Text>
            );
          }
          return _terms;
        })()}
      </View>
    );
  }

  QuotePDF(
    itemContentData,
    serviceContentData,
    termsTableData,
    uniqueId,
    approvedBy,
    client,
    clientAddress,
    isSig,
    incharge,
    dateCreated,
    withDescription,
    invoiceStatus,
    grandTotal,
    grandTotalSRP,
    GrandTotalMarkedUpDiscounted,
    rawItemContentData,
    GrandTotalMarkup
  ) {
    //console.log(props?.pdfGroupTable[0]);

    let FinalInvoiceCost = 0;
    let FinalInvoiceSavings = 0;

    let discountedCount = rawItemContentData?.filter(
      (x) => x.discount > 0
    ).length;
    let markedUpCount = rawItemContentData?.filter(
      (x) => x.priceModifier > 0
    ).length;

    if (discountedCount > 0 && markedUpCount > 0) {
      // console.log(GrandTotalMarkedUpDiscounted, GrandTotalMarkup);
      FinalInvoiceSavings = misc.parseCurrencyNoCurrency(
        Math.abs(GrandTotalMarkedUpDiscounted - GrandTotalMarkup)
      );
      FinalInvoiceCost = misc.parseCurrencyNoCurrency(
        GrandTotalMarkedUpDiscounted
      );
    } else {
      const _total = Number(grandTotalSRP) - Number(grandTotal);

      //console.log(_total);

      FinalInvoiceSavings = misc.parseCurrencyNoCurrency(_total);
      //console.log(FinalInvoiceSavings);
      FinalInvoiceCost = misc.parseCurrencyNoCurrency(Number(grandTotal));
    }

    //console.log(discountedCount, markedUpCount);

    let _content = [];

    _content.push(
      <>
        {(invoiceStatus === "New" ||
          invoiceStatus === "Admin Denied" ||
          invoiceStatus === "Pending Admin Approval") && (
          <View
            style={[
              {
                marginTop: 100,
                fontSize: 15,
                width: "100%",
                position: "absolute",
                textAlign: "center",
                backgroundColor: "red",
              },
            ]}
          >
            <Text style={[{ textAlign: "center", color: "white" }]}>
              --== THIS INVOICE IS NOT APPROVED BY COMPANY AND IS NOT VALID ==--
            </Text>
          </View>
        )}
        <View
          style={[
            {
              // flex: 1,
              flexDirection: "row",
              paddingLeft: 40,
              paddingRight: 40,
              marginTop: 5,
            },
          ]}
        >
          <View style={[{ flex: 1 }]}>
            <Text
              style={[
                {
                  textAlign: "left",
                  color: themeColor,
                  fontSize: 12,
                  fontWeight: "bold",
                },
              ]}
            >
              BILL TO:
            </Text>
            <Text style={[{ textAlign: "left", fontSize: 8, marginTop: 10 }]}>
              {client}
            </Text>
          </View>
          <View style={[{ flex: 1 }]}>
            <Text
              style={[
                {
                  textAlign: "left",
                  color: themeColor,
                  fontSize: 12,
                  fontWeight: "bold",
                },
              ]}
            >
              ADDRESS
            </Text>
            <Text style={[{ textAlign: "left", fontSize: 8, marginTop: 10 }]}>
              {clientAddress}
            </Text>
          </View>
          <View style={[{ flex: 1 }]}>
            <Text
              style={[
                {
                  textAlign: "left",
                  color: themeColor,
                  fontSize: 12,
                  fontWeight: "bold",
                },
              ]}
            >
              TOTAL SAVINGS
            </Text>
            <Text
              style={[
                {
                  textAlign: "left",
                  marginTop: 10,
                  color: "#0f4c75",
                },
              ]}
            >
              {FinalInvoiceSavings}
            </Text>
          </View>
          <View style={[{ flex: 1 }]}>
            <Text
              style={[
                {
                  textAlign: "left",
                  color: themeColor,
                  fontSize: 12,
                  fontWeight: "bold",
                },
              ]}
            >
              AMOUNT DUE
            </Text>
            <Text
              style={[
                {
                  textAlign: "left",
                  marginTop: 10,
                  color: "#0f4c75",
                },
              ]}
            >
              {FinalInvoiceCost}
            </Text>
          </View>
        </View>

        <Canvas
          fixed
          style={[
            {
              width: 600,
              height: 5,
              top: 180,
              position: "absolute",
              zIndex: -1,
            },
          ]}
          paint={(painter) => {
            painter
              .strokeColor(themeColor)
              .lineWidth(5)
              .moveTo(40, 0)
              .lineTo(555, 0)
              .stroke();
          }}
        />

        <View
          style={[
            {
              marginTop: 20,
              paddingLeft: 20,
              paddingRight: 20,
            },
          ]}
        >
          <Text
            style={[
              {
                fontSize: 9,
                paddingLeft: 20,
              },
            ]}
          >
            Dear Ma'am / Sir,
          </Text>
          <Text
            style={[
              {
                marginBottom: 8,
                paddingLeft: 20,
                fontSize: 9,
              },
            ]}
          >
            We are pleased to submit our quotation for the following items:
          </Text>
          {((x) => {
            const _e = rawItemContentData;
            const _result = itemContentData;

            if (_result && _result.length > 0) {
              if (_result[0].length <= 8) {
                //console.log(_e.length, _e)
                return (
                  <>
                    <View
                      style={[
                        {
                          paddingLeft: 20,
                          paddingRight: 20,
                        },
                      ]}
                    >
                      {this._itemTable(_result[0], withDescription)}
                    </View>
                    <View
                      style={[
                        {
                          paddingLeft: 20,
                          paddingRight: 20,
                          marginTop: 10,
                        },
                      ]}
                    >
                      {serviceContentData.length > 0
                        ? this._ServiceTable(serviceContentData)
                        : null}
                    </View>
                    {_result.length === 1 && (
                      <>
                        <View
                          style={[
                            {
                              flexDirection: "row",
                              paddingLeft: 20,
                              paddingRight: 20,
                              ...(_e.length === 6 && { marginTop: -15 }),
                              ...(_e.length === 7 && { marginTop: 220 }),
                              ...(_e.length === 8 && { marginTop: 170 }),
                              ...(_e.length >= 7 && {
                                backgroundColor: "#ffff",
                              }),
                            },
                          ]}
                        >
                          <View style={[{ flex: 3 }]}>
                            {this._terms(termsTableData)}
                          </View>
                          <View style={[{ flex: 1 }]}>
                            <View
                              style={[
                                {
                                  flexDirection: "row",
                                },
                              ]}
                            >
                              <View style={[{ flex: 1, paddingLeft: 5 }]}>
                                <Text
                                  style={[
                                    {
                                      textAlign: "left",
                                      marginTop: 10,
                                      color: themeColor,
                                      fontSize: 10,
                                      fontFamily: "Open Sans",
                                      fontWeight: 800,
                                    },
                                  ]}
                                >
                                  SUBTOTAL
                                </Text>
                              </View>
                              <View style={[{ flex: 1 }]}>
                                <Text
                                  style={[
                                    {
                                      textAlign: "right",
                                      marginTop: 10,
                                      fontSize: 10,
                                    },
                                  ]}
                                >
                                  {FinalInvoiceCost}
                                </Text>
                              </View>
                            </View>
                          </View>
                        </View>
                        {this._termsSignature(approvedBy, isSig, incharge)}
                      </>
                    )}
                  </>
                );
              } else {
                //console.log(_result.length, _result)
                //return <>{this._itemTable(_result[0], withDescription)}</>;
              }
            }
          })()}
        </View>
      </>
    );

    for (let i = 1; i < itemContentData.length; i++) {
      const _e = itemContentData[i];

      if (_e.length === 8) {
        _content.push(
          <View
            style={[
              {
                paddingLeft: 20,
                paddingRight: 20,
              },
            ]}
          >
            {this._itemTable(_e, withDescription)}
          </View>
        );
      } else {
        _content.push(
          <>
            <View
              style={[
                {
                  paddingLeft: 20,
                  paddingRight: 20,
                },
              ]}
            >
              {this._itemTable(_e, withDescription)}
              <View
                style={[
                  {
                    paddingLeft: 20,
                    paddingRight: 20,
                    marginTop: 10,
                  },
                ]}
              >
                {serviceContentData.length > 0
                  ? this._ServiceTable(serviceContentData)
                  : null}
              </View>
              <View
                style={[
                  {
                    flexDirection: "row",
                    paddingLeft: 20,
                    paddingRight: 20,
                    ...(_e.length === 6 && { marginTop: -15 }),
                    ...(_e.length === 7 && { marginTop: 220 }),
                    ...(_e.length === 8 && { marginTop: 170 }),
                    ...(_e.length >= 7 && {
                      backgroundColor: "#ffff",
                    }),
                  },
                ]}
              >
                <View style={[{ flex: 3 }]}>{this._terms(termsTableData)}</View>
                <View style={[{ flex: 1 }]}>
                  <View
                    style={[
                      {
                        flexDirection: "row",
                      },
                    ]}
                  >
                    <View style={[{ flex: 1, paddingLeft: 5 }]}>
                      <Text
                        style={[
                          {
                            textAlign: "left",
                            marginTop: 10,
                            color: themeColor,
                            fontSize: 10,
                            fontFamily: "Open Sans",
                            fontWeight: 800,
                          },
                        ]}
                      >
                        SUBTOTAL
                      </Text>
                    </View>
                    <View style={[{ flex: 1 }]}>
                      <Text
                        style={[
                          {
                            textAlign: "right",
                            marginTop: 10,
                            fontSize: 10,
                          },
                        ]}
                      >
                        {FinalInvoiceCost}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              {this._termsSignature(approvedBy, isSig, incharge)}
            </View>

            {/* <View
              style={[
                {
                  paddingLeft: 20,
                  paddingRight: 20,
                  marginTop: 10,
                },
              ]}
            >
              {serviceContentData.length > 0
                ? this._ServiceTable(serviceContentData)
                : null}

              <Text
                style={[
                  {
                    textAlign: "right",
                    marginTop: 10,
                  },
                ]}
              >
                GRAND TOTAL: {misc.parseCurrencyNoCurrency(grandTotal)}
              </Text>
            </View>
            {this._terms(termsTableData)}
            {this._termsSignature(approvedBy, isSig, incharge)} */}
          </>
        );
      }
    }

    return pdfTemplate.TemplateHeaderFooter(
      _content,
      "Quotation",
      uniqueId,
      false,
      misc.parseAPIDateTimeToLongDate(dateCreated),
      undefined,
      undefined,
      (invoiceStatus === "New" ||
        invoiceStatus === "Admin Denied" ||
        invoiceStatus === "Pending Admin Approval") &&
        true
    );
  }
}
export default new Quote();
