class Auth {
  constructor() {
    this.authenticated = false;
    this.register = false;
    this.userInformation = {
      email: "",
      username: "",
      id: 0,
      permissionLevel: 0,
    };
  }

  setIsRegister(val) {
    this.register = val;
  }

  setUserInformation(id, username, email, permissionLevel) {
    this.id = id;
    this.username = username;
    this.email = email;
    this.permissionLevel = permissionLevel;
  }

  getUserInformation() {
    return this.userInformation;
  }

  login(cb) {
    this.authenticated = true;
    cb();
  }

  logout(cb) {
    this.authenticated = false;
    cb();
  }
  isRegister() {
    return this.register;
  }

  isAuthenticated() {
    if (localStorage.getItem("LUT") != null) this.authenticated = true;
    return this.authenticated;
  }
}

export default new Auth();
