import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import "../../css/actor.css";
import Card from "react-bootstrap/Card";
import { NumericFormat } from "react-number-format";
import misc from "../../functions/misc";
import { SELECTED_OPERATION } from "../../constants";
import filters from "../../functions/filters";
import Table from "react-table";
import { useDispatch, useSelector } from "react-redux";
import ActorAPI from "./ActorAPI";
import moment from "moment/moment";
const employeeColumn = [
  {
    Header: () => <></>,
    id: "isOnline",
    maxWidth: 30,

    filterable: false,
    accessor: (d) => d.isOnline,
    Cell: (row) => {
      const d = row.original;

      return (
        <>
          <style type="text/css">
            {`
  
  
              .indicator.online {
                background: #28B62C;
                display: inline-block;
                width: 1em;
                height: 1em;
                border-radius: 50%;
                -webkit-animation: pulse-animation 2s infinite linear;
            }

            @-webkit-keyframes pulse-animation {
              0% { -webkit-transform: scale(1); }
              25% { -webkit-transform: scale(1); }
                50% { -webkit-transform: scale(1.2) }
                75% { -webkit-transform: scale(1); }
                100% { -webkit-transform: scale(1); }
            }

            .indicator.offline {
                background: #d3d3d3;
                display: inline-block;
                width: 0.5em;
                height: 0.5em;
                border-radius: 50%;
            }
        
           
          `}
          </style>
          <div style={{ textAlign: "center" }}>
            {d.isOnline === 1 && <span className="indicator online"></span>}
            {d.isOnline === 0 && <span className="indicator offline"></span>}
          </div>
        </>
      );
    },
  },
  {
    Header: () => (
      <p
        style={{
          fontSize: "1em",
          color: "var(--REB-primary)",
          fontWeight: "bold",
          margin: 0,
        }}
      >
        First Name
      </p>
    ),
    id: "firstName",
    accessor: (d) => (d.firstName === null ? "-" : d.firstName),
  },
  {
    Header: () => (
      <p
        style={{
          fontSize: "1em",
          color: "var(--REB-primary)",
          fontWeight: "bold",
          margin: 0,
        }}
      >
        Last Name
      </p>
    ),
    id: "lastName",
    accessor: (d) => (d.lastName === null ? "-" : d.lastName),
  },
  {
    Header: () => (
      <p
        style={{
          fontSize: "1em",
          color: "var(--REB-primary)",
          fontWeight: "bold",
          margin: 0,
        }}
      >
        Username
      </p>
    ),
    id: "username",
    accessor: (d) => (d.username === null ? "-" : d.username),
  },
  {
    Header: () => (
      <p
        style={{
          fontSize: "1em",
          color: "var(--REB-primary)",
          fontWeight: "bold",
          margin: 0,
        }}
      >
        Last Login
      </p>
    ),
    id: "lastLogin",
    accessor: (d) =>
      d.lastLogin === null
        ? "-"
        : misc.parseAPIDateTimeToShortDateTime(
            misc.parseAPIDateTime(d.lastLogin)
          ),
  },
  {
    Header: () => (
      <p
        style={{
          fontSize: "1em",
          color: "var(--REB-primary)",
          fontWeight: "bold",
          margin: 0,
        }}
      >
        Designation
      </p>
    ),
    id: "type",

    accessor: (d) => {
      //console.log(d);
      if (d.types) {
        if (d.types.length > 0) return d.types[0].name;
        else return d.types.toString();
      } else {
        return "-";
      }
    },
  },
  {
    Header: () => (
      <p
        style={{
          fontSize: "1em",
          color: "var(--REB-primary)",
          fontWeight: "bold",
          margin: 0,
        }}
      >
        Address
      </p>
    ),
    id: "Address",
    // accessor: d => d.name.title
    accessor: (d) => (d.address === null ? "-" : d.address),
  },
  {
    Header: () => (
      <p
        style={{
          fontSize: "1em",
          color: "var(--REB-primary)",
          fontWeight: "bold",
          margin: 0,
        }}
      >
        Contact
      </p>
    ),
    id: "contact",
    // accessor: d => d.contact.landline
    accessor: (d) => (d.contact === null ? "-" : d.contact),
  },
];

const supplierColumn = [
  {
    Header: "Company Name",
    id: "name",
    accessor: (d) => (d.company === null ? "" : d.company),
  },
  {
    Header: "Address",
    id: "Address",
    // accessor: d => d.name.title
    accessor: (d) => (d.address === null ? "" : d.address),
  },
  {
    Header: "Website",
    id: "website",
    accessor: (d) => (d.website === null ? "" : d.website),
  },
  {
    Header: "Email",
    id: "email",
    accessor: (d) => (d.email === null ? "" : d.email),
  },
  {
    Header: "Contact",
    id: "contact",
    // accessor: d => d.contact.landline
    accessor: (d) => (d.contact === null ? "" : d.contact),
  },
];

const customerColumn = [
  {
    Header: "Full Name",
    id: "name",
    accessor: (d) => (d.company === null ? "" : d.company),
  },
  {
    Header: "Address",
    id: "Address",
    // accessor: d => d.name.title
    accessor: (d) => (d.address === null ? "" : d.address),
  },

  {
    Header: "Contact",
    id: "contact",
    // accessor: d => d.contact.landline
    accessor: (d) => (d.contact === null ? "" : d.contact),
  },
  {
    Header: "Email",
    id: "email",
    accessor: (d) => (d.email === null ? "" : d.email),
  },
  // {
  //   Header: "First name",
  //   id: "firstName",
  //   accessor: (d) => (d.firstName === null ? "" : d.firstName),
  // },
  // {
  //   Header: "Last name",
  //   id: "lastName",
  //   accessor: (d) => (d.lastName === null ? "" : d.lastName),
  // },
  // {
  //   Header: "Address",
  //   id: "Address",
  //   // accessor: d => d.name.title
  //   accessor: (d) => (d.address === null ? "" : d.address),
  // },
  // {
  //   Header: "Website",
  //   id: "website",
  //   accessor: (d) => (d.website === null ? "" : d.website),
  // },
  // {
  //   Header: "Contact",
  //   id: "contact",
  //   // accessor: d => d.contact.landline
  //   accessor: (d) => (d.contact === null ? "" : d.contact),
  // },
];

const ActorActivityTableColumns = [
  {
    Header: "Activity",
    id: "activity",
    Cell: (row) => {
      const data = row.original;

      return (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              wordWrap: "break-word",
              whiteSpace: "normal",
              fontSize: "0.85em",
              height: "100%",
              color: "var(--REB-blue)",
              fontWeight: "bold",
              // alignItems: "center",
            }}
          >
            <p>{data.description}</p>
          </div>
        </>
      );
    },
    accessor: (d) => (d.description === null ? "" : d.description),
  },
  {
    Header: "Date",
    id: "date",
    maxWidth: 180,
    Cell: (row) => {
      const data = row.original;
      const ActivityDate = moment(data.dateSubmitted).format("LLLL");
      return (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              wordWrap: "break-word",
              whiteSpace: "normal",
              fontSize: "0.85em",
              height: "100%",
              color: "var(--REB-blue)",
              fontWeight: "bold",
              alignItems: "center",
            }}
          >
            <p>{ActivityDate}</p>
          </div>
        </>
      );
    },
    accessor: (d) => (d.dateSubmitted === null ? "" : d.dateSubmitted),
  },
];

// function TxtFileUpload(props) {
//   const [btnSave, setbtnSave] = useState(true);

//   return (
//     <>
//       <Card className="">
//         <Card.Header>
//           <strong>Signature Upload</strong>
//         </Card.Header>
//         <Card.Body className="overflow-auto h-25">
//           <h6 className="text-center font-weight-bold">
//             *only upload 300x100 png files
//           </h6>
//           <InputGroup className="mb-3">
//             <FormControl
//               type="file"
//               custom
//               data-browse="Browse"
//               className="inputFileUpload"
//               disabled={props.SelectedOperation === SELECTED_OPERATION.VIEW}
//               onChange={(e) => {
//                 props.setFileUpload(e.target.files);
//                 //console.log(Array.from(e.target.files));

//                 if (Array.from(e.target.files).length === 0) {
//                   setbtnSave(true);
//                 } else {
//                   setbtnSave(false);
//                 }
//               }}
//             />

//             <ButtonGroup className="">
//               <OverlayTrigger
//                 placement={"top"}
//                 overlay={<Tooltip id={`order-tooltip-add`}>Save</Tooltip>}
//               >
//                 <Button
//                   variant="secondary"
//                   name=""
//                   size="sm"
//                   className="btnSaveFileMultimat"
//                   onClick={(e) => {
//                     e.preventDefault();
//                     props.handleFileUploadSubmit();
//                     // props.setFileUpload(e);
//                   }}
//                   disabled={btnSave}
//                 >
//                   Save
//                 </Button>
//               </OverlayTrigger>
//             </ButtonGroup>
//           </InputGroup>
//           {props.actorSelection
//             ? props.actorSelection.uploads.map((i, index) => (
//                 <Row key={index}>
//                   <Col key={index}>
//                     <InputGroup>
//                       <OverlayTrigger
//                         overlay={
//                           <Tooltip id="tooltip-disabled">Remove</Tooltip>
//                         }
//                       >
//                         <span className="d-inline-block">
//                           <Button
//                             name={`delete${i.filename}`}
//                             value={i.filename}
//                             size="sm"
//                             variant="danger"
//                             className=""
//                             onClick={(e) => {
//                               props.state.deleteFile(
//                                 i.filename,
//                                 i.originalname
//                               );
//                             }}
//                             style={{
//                               borderRadius: 0,
//                             }}
//                           >
//                             X
//                           </Button>
//                         </span>
//                       </OverlayTrigger>
//                       <Button
//                         onClick={(e) => {
//                           props.state.downloadFile({
//                             name: i.filename,
//                             complete: i.originalname,
//                             id: props.state.actorSelection.uniqueId,
//                           });
//                           // props.state.handleActionChangeOrder(e, {
//                           //   name: i.filename,
//                           //   complete: i.originalname,
//                           //   id: props.state.orderSelection.uniqueId,
//                           // });
//                         }}
//                         name="btnDLLink"
//                         key={index}
//                         variant="link"
//                       >
//                         {i.originalname}
//                       </Button>
//                     </InputGroup>
//                   </Col>
//                 </Row>
//               ))
//             : ""}
//         </Card.Body>
//       </Card>
//     </>
//   );
// }

const SelectPagePermission = (props) => {
  const { controlName, value, setter, permissionSetter, titleLabel, disabled } =
    props;

  return (
    <div className="mb-2" style={{ display: "flex", flexDirection: "row" }}>
      <p style={{ alignSelf: "center", flex: 1 }} className="p-0 m-0 pr-2">
        {titleLabel}
      </p>
      <select
        className="form-control w-50"
        //name="pdfSortBy"
        value={value.permission ? value.permission : value ? value : ""}
        //defaultValue={""}
        style={{ flex: 1 }}
        disabled={disabled}
        onChange={(e) => {
          setter(e.currentTarget.value);
          permissionSetter({
            permission: e.currentTarget.value,
            controlName: `perm-ui-${controlName}`,
            checked: true,
            isSelect: true,
          });
        }}
      >
        <option value="">No Access</option>
        <option value={`perm-ui-${controlName}-view`}>View</option>
        <option value={`perm-ui-${controlName}-view-write`}>Write</option>
        <option value={`perm-ui-${controlName}-view-write-manage`}>
          Manage
        </option>
        <option value={`perm-ui-${controlName}-view-write-manage-admin`}>
          Admin
        </option>
      </select>
    </div>
  );
};

const ActorActivityComponent = (props) => {
  const { user } = props;

  const dispatch = useDispatch();
  const [ActorActivityData, setActorActivityData] = useState([]);
  const [IsActorActivityLoading, setIsActorActivityLoading] = useState(true);
  const [ActorActivityDateStart, setActorActivityDateStart] = useState(
    moment().subtract(3, "days").format("YYYY-MM-DD")
  );
  const [ActorActivityDateEnd, setActorActivityDateEnd] = useState(
    moment().add(1, "days").format("YYYY-MM-DD")
  );

  const getActorActivityById = async () => {
    setIsActorActivityLoading(true);
    const tempActorActivities = await ActorAPI.getActorActivity(
      dispatch,
      user.id,
      { dateStart: ActorActivityDateStart, dateEnd: ActorActivityDateEnd }
    );
    if (tempActorActivities) {
      //console.log(tempActorActivities);
      setActorActivityData(tempActorActivities);
    }
    setIsActorActivityLoading(false);
  };

  useEffect(() => {
    getActorActivityById();
  }, []);

  useEffect(() => {
    getActorActivityById();
  }, [ActorActivityDateStart, ActorActivityDateEnd]);

  const ResetDataFilters = () => {
    setActorActivityDateStart(
      moment().subtract(3, "days").format("YYYY-MM-DD")
    );
    setActorActivityDateEnd(moment().add(1, "days").format("YYYY-MM-DD"));
  };
  const TableActorActivity = (
    <>
      <style>
        {`
          @media (max-width: 991px) {
            .tableActor {
              border-radius: 0px !important;
              background-color: var(--REB-white) !important;
              margin-top: 0px !important;
              margin-bottom: 0px;
            }
            .inventoryHeaderContainerStyle {
              margin-top: 0px !important;
              box-shadow: none;
              padding-bottom: 5px !important;
            }
          }
          @media (min-width: 992px) {
            .tableActor {
              border-radius: 10px !important;
              /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
              background-color: var(--REB-white) !important;
              margin-top: 30px;
            }
          }
        `}
      </style>
      <Table
        data={ActorActivityData}
        columns={ActorActivityTableColumns}
        showPageSizeOptions={false}
        showPageJump={false}
        loading={IsActorActivityLoading}
        // showPaginationBottom={false}
        defaultSorted={[
          // { id: "transPrio", desc: true },
          {
            id: "date",
            desc: true,
          },
        ]}
        defaultPageSize={10}
        style={{ overflow: "auto", flex: 1 }}
        className="tableActor -highlight mt-3"
      />
    </>
  );

  return (
    <>
      <Card className="mt-2">
        <Card.Header className="font-weight-bold">User Activity</Card.Header>
        <Card.Body disabled>
          <Form.Label
            className="font-weight-bold mt-2"
            style={{ color: "var(--REB-black)" }}
          >
            Start Date
          </Form.Label>
          <FormControl
            type="date"
            value={ActorActivityDateStart}
            onChange={(x) => {
              setActorActivityDateStart(x.currentTarget.value);
            }}
            className=""
          />
          <Form.Label
            className="font-weight-bold mt-2"
            style={{ color: "var(--REB-black)" }}
          >
            End Date
          </Form.Label>
          <FormControl
            type="date"
            value={ActorActivityDateEnd}
            onChange={(x) => {
              setActorActivityDateEnd(x.currentTarget.value);
            }}
            className=""
          />
          <Button
            className="my-3"
            style={{ backgroundColor: "var(--REB-green)" }}
            onClick={() => {
              ResetDataFilters();
            }}
          >
            Reset Filters
          </Button>
          {TableActorActivity}
        </Card.Body>
      </Card>
    </>
  );
};

const ActorModal = (props) => {
  const dispatch = useDispatch();
  const [PWVisible, setPWVisible] = useState(false);
  const [CPWVisible, setCPWVisible] = useState(false);
  const permissions = useSelector((state) => {
    const user = state.apiData.userData;
    let _permissions = [];
    if (user && user.permissions) {
      _permissions = JSON.parse(user.permissions);
    }
    return _permissions.toString();
  });

  let userTypes = [];
  if (props.user.types) {
    props.user.types.map((x) => {
      userTypes.push(x.name);
      return true;
    });
  }
  return (
    <>
      <style>
        {`
        .actorModal {
          width: 100vw;
          max-height: 100vh;
          max-width: 100vw;
          height: 100vh;
          margin: 0px;
        }
        .actorModalBody {
          width: 100vw;
        }
        .modal {
          padding-left: 0px !important;
        }

        @media (max-width: 768px) {
          .actorModalHeader {
            padding: 5px;
          }
          .actorModalControls {
          }
          .actorModalBody {
            padding-left: 5px;
            padding-right: 5px;
          }
          .itemModalCRUDButtons-padding {
            margin-top: 10px;
            margin-bottom: 10px;
          }
          .tabletMarginTop {
            margin-top: 20px;
          }
        }
        @media (min-width: 769px) {
          .actorModalHeader {
            padding: 10px;
            padding-left: "20px";
          }
          .actorModalControls {
            padding-top: 10px;
            padding-bottom: 10px;
          }
          .actorModalBody {
            padding-left: 25px;
            padding-right: 25px;
          }
          .itemModalCRUDButtons {
            padding-left: 30px !important;
          }
        }
      `}
      </style>
      <Modal
        show={props.IsActorModalOpen}
        onHide={() => {
          props.setSelectedOperation(SELECTED_OPERATION.VIEW);
          props.setIsPWEdit(false);
          props.setIsPWVisible(false);
          setPWVisible(false);
          setCPWVisible(false);
          props.setSelectedActor({ id: 1, name: "User" });

          props.ClearActorModal();
          props.setIsActorModalOpen(false);
        }}
        onExit={() => {
          props.setSelectedOperation(SELECTED_OPERATION.VIEW);
          props.setIsPWEdit(false);
          props.setIsPWVisible(false);
          setPWVisible(false);
          setCPWVisible(false);
          props.setSelectedActor({ id: 1, name: "User" });

          props.ClearActorModal();
          props.setIsActorModalOpen(false);
        }}
        dialogClassName="actorModal"
        //size="xl"
        centered
      >
        <Modal.Body className="actorModalBody ">
          <Row
            className="actorModalHeader "
            style={{ borderBottom: "var(--REB-black) solid 1px" }}
          >
            <Col xs={2} sm={1} md={1} lg={1}>
              <OverlayTrigger
                placement={"right"}
                overlay={<Tooltip id={`project-tooltip-add`}>Close</Tooltip>}
              >
                <Button
                  variant="secondary"
                  name="BtnCloseProjectModal"
                  style={{ backgroundColor: "var(--REB-primary)" }}
                  onClick={() => {
                    props.setIsActorModalOpen(false);
                  }}
                >
                  <i className="icofont-rounded-left"></i>
                </Button>
              </OverlayTrigger>
            </Col>

            <Col
              xs={10}
              sm={11}
              md={11}
              lg={11}
              className="actor-modal-header-col"
              style={{ alignSelf: "center" }}
            >
              {props.SelectedOperation === SELECTED_OPERATION.VIEW ||
              props.SelectedOperation === SELECTED_OPERATION.EDIT ? (
                <h4 className="actor-modal-header-text m-0">{"View Actor"}</h4>
              ) : (
                <h4 className="actor-modal-header-text m-0">{"Add Actor"}</h4>
              )}
            </Col>
          </Row>
          <div
            className="py-2"
            style={{
              overflowX: "hidden",
              overflowY: "scroll",
              maxHeight: "80vh",
            }}
          >
            {props.SelActorType <= 5 && (
              <Row>
                <Col xs={12} sm={6} md={6}>
                  <div className="px-2 pb-2">
                    <Form.Label className="font-weight-bold">
                      <span className="required-field">* </span>
                      First Name
                    </Form.Label>
                    <FormControl
                      isInvalid={
                        props.ActorInputValidation.TxtFirstName.isInvalid
                      }
                      type="text"
                      //style={{ textTransform: "uppercase" }}
                      // placeholder={props.state.txtFirstName.placeholder}
                      name="txtFirstName"
                      value={props.TxtFirstName}
                      onChange={(e) => {
                        props.setTxtFirstName(e.target.value);
                      }}
                      className=""
                      readOnly={
                        props.SelectedOperation === SELECTED_OPERATION.VIEW
                      }
                      required
                    />
                    {props.ActorInputValidation.TxtFirstName.msg.map((x) => {
                      return (
                        <>
                          <p key={filters.uuidv4()}>{x}</p>
                        </>
                      );
                    })}
                    <Form.Label className="font-weight-bold">
                      <span className="required-field">* </span>
                      Last Name
                    </Form.Label>
                    <FormControl
                      isInvalid={
                        props.ActorInputValidation.TxtLastName.isInvalid
                      }
                      type="text"
                      //style={{ textTransform: "uppercase" }}
                      // placeholder={props.state.txtLastname.placeholder}
                      name="txtLastname"
                      value={props.TxtLastName}
                      onChange={(e) => {
                        props.setTxtLastName(e.target.value);
                      }}
                      className=""
                      readOnly={
                        props.SelectedOperation === SELECTED_OPERATION.VIEW
                      }
                      required
                    />
                    {props.ActorInputValidation.TxtLastName.msg.map((x) => {
                      return (
                        <>
                          <p key={filters.uuidv4()}>{x}</p>
                        </>
                      );
                    })}
                    <Form.Label className="font-weight-bold">
                      Username
                    </Form.Label>
                    <FormControl
                      isInvalid={
                        props.ActorInputValidation.TxtUserName.isInvalid
                      }
                      type="text"
                      // placeholder={props.state.txtUserName.placeholder}
                      name="txtUserName"
                      value={props.TxtUserName}
                      onChange={(e) => {
                        props.setTxtUserName(e.target.value);
                      }}
                      className=""
                      readOnly={
                        props.SelectedOperation === SELECTED_OPERATION.VIEW
                      }
                      required
                    />
                    {props.ActorInputValidation.TxtUserName.msg.map((x) => {
                      return (
                        <>
                          <p key={filters.uuidv4()}>{x}</p>
                        </>
                      );
                    })}
                    <Form.Label className="font-weight-bold">Email</Form.Label>
                    <FormControl
                      type="email"
                      // placeholder={props.state.txtEmail.placeholder}xx
                      name="txtEmail"
                      value={props.TxtEmail}
                      onChange={(e) => {
                        props.setTxtEmail(e.target.value);
                      }}
                      className=""
                      readOnly={
                        props.SelectedOperation === SELECTED_OPERATION.VIEW
                      }
                    />
                    <Form.Label className="font-weight-bold">
                      Contact
                    </Form.Label>
                    <FormControl
                      type="text"
                      // placeholder={props.state.txtContact.placeholder}
                      name="txtContact"
                      value={props.TxtContact}
                      onChange={(e) => {
                        props.setTxtContact(e.target.value);
                      }}
                      className=""
                      readOnly={
                        props.SelectedOperation === SELECTED_OPERATION.VIEW
                      }
                    />
                    <Form.Label className="font-weight-bold">
                      Address
                    </Form.Label>
                    <FormControl
                      type="text"
                      //style={{ textTransform: "uppercase" }}
                      // placeholder={props.state.txtAddress.placeholder}
                      name="txtAddress"
                      value={props.TxtAddress}
                      onChange={(e) => {
                        props.setTxtAddress(e.target.value);
                      }}
                      className=""
                      readOnly={
                        props.SelectedOperation === SELECTED_OPERATION.VIEW
                      }
                    />
                    <Form.Label className="font-weight-bold">
                      Maximum Allowable Discount
                    </Form.Label>
                    <FormControl
                      as={NumericFormat}
                      inputMode="numeric"
                      thousandSeparator={true}
                      prefix={"% "}
                      //style={{ flex: 1 }}
                      placeholder="Discount"
                      name="txtMaxDiscount"
                      isAllowed={(values) => {
                        const { formattedValue, floatValue } = values;
                        return formattedValue === "" || floatValue <= 100;
                      }}
                      value={props.TxtMaxDiscount}
                      onValueChange={(x) => {
                        props.setTxtMaxDiscount(Number(x.value));
                      }}
                      className=""
                      readOnly={
                        props.SelectedOperation === SELECTED_OPERATION.VIEW
                      }
                    />
                  </div>

                  {props.SelActorType <= 5 &&
                    (props.IsPWVisible === true ||
                      props.SelectedOperation === SELECTED_OPERATION.ADD) && (
                      <div className="container cont-pw my-3 pt-3 pb-3">
                        <Form.Label className="font-weight-bold">
                          Password
                        </Form.Label>
                        <InputGroup className="mb-1">
                          <FormControl
                            isInvalid={
                              props.ActorInputValidation.TxtPassword.isInvalid
                            }
                            type={PWVisible ? "text" : "password"}
                            // placeholder={props.state.txtPassword.placeholder}
                            name="txtPassword"
                            id="txtPassword"
                            value={props.TxtPassword}
                            onChange={(e) => {
                              props.setTxtPassword(e.target.value);
                            }}
                            className=""
                            disabled={
                              !props.IsPWEdit &&
                              props.SelectedOperation ===
                                SELECTED_OPERATION.EDIT
                            }
                            readOnly={
                              props.SelectedOperation ===
                              SELECTED_OPERATION.VIEW
                            }
                            //isValid={props.state.txtPassword.isValid}
                            required
                          />
                          <Button
                            disabled={
                              props.IsPWEdit === false &&
                              props.SelectedOperation ===
                                SELECTED_OPERATION.EDIT
                            }
                            onClick={() => setPWVisible(!PWVisible)}
                          >
                            {PWVisible === true ? (
                              <i className="icofont-eye-alt"></i>
                            ) : (
                              <i className="icofont-eye-blocked"></i>
                            )}
                          </Button>
                        </InputGroup>

                        {props.ActorInputValidation.TxtPassword.msg.map((x) => {
                          return (
                            <>
                              <p key={filters.uuidv4()}>{x}</p>
                            </>
                          );
                        })}
                        <Form.Label className="font-weight-bold">
                          Confirm Password
                        </Form.Label>
                        <InputGroup className="mb-1">
                          <FormControl
                            type={CPWVisible ? "text" : "password"}
                            // placeholder={props.state.txtConfirmPass.placeholder}
                            name="txtConfirmPass"
                            id="txtConfirmPass"
                            value={props.TxtConfirmPass}
                            onChange={(e) => {
                              props.setTxtConfirmPass(e.target.value);
                            }}
                            disabled={
                              !props.IsPWEdit &&
                              props.SelectedOperation ===
                                SELECTED_OPERATION.EDIT
                            }
                            readOnly={
                              props.SelectedOperation ===
                              SELECTED_OPERATION.VIEW
                            }
                            isInvalid={
                              props.ActorInputValidation.TxtConfirmPass
                                .isInvalid
                            }
                            //isValid={props.state.txtConfirmPass.isValid}
                            className=""
                            required
                          />
                          <Button
                            disabled={
                              props.IsPWEdit === false &&
                              props.SelectedOperation ===
                                SELECTED_OPERATION.EDIT
                            }
                            onClick={() => setCPWVisible(!CPWVisible)}
                          >
                            {CPWVisible === true ? (
                              <i className="icofont-eye-alt"></i>
                            ) : (
                              <i className="icofont-eye-blocked"></i>
                            )}
                          </Button>
                        </InputGroup>
                        {props.SelectedOperation === SELECTED_OPERATION.EDIT &&
                          (props.IsPWEdit === false ? (
                            <Button
                              block
                              disabled={!props.IsPWDisabled}
                              onClick={() => {
                                props.setIsPWEdit(true);
                              }}
                            >
                              Change Password
                            </Button>
                          ) : (
                            <Button
                              block
                              disabled={!props.IsPWDisabled}
                              onClick={() => {
                                props.setIsPWEdit(false);
                                props.setTxtPassword("");
                                props.setTxtConfirmPass("");
                              }}
                            >
                              Cancel
                            </Button>
                          ))}
                        <p className="lblPasswordMsg mb-2">
                          {props.TxtPassword.errMsg}
                        </p>
                        {props.ActorInputValidation.TxtConfirmPass.msg.map(
                          (x) => {
                            if (
                              props.SelectedOperation === SELECTED_OPERATION.ADD
                            ) {
                              return (
                                <>
                                  <p key={filters.uuidv4()}>{x}</p>
                                </>
                              );
                            }
                            if (props.IsPWVisible) {
                            } else {
                              return (
                                <>
                                  <Button
                                    name={props.btnEditPW.name}
                                    onClick={(e) => {
                                      props.setIsPWVisible(e);
                                    }}
                                  ></Button>
                                </>
                              );
                            }
                            return true;
                          }
                        )}
                      </div>
                    )}
                  {props.SelectedOperation !== SELECTED_OPERATION.ADD && (
                    <>
                      <ActorActivityComponent user={props.SelectedActor} />
                      <Button
                        block
                        style={{ backgroundColor: "var(--REB-warning)" }}
                        className="my-3"
                        onClick={() => {
                          dispatch({
                            type: "MODAL_SHOW",
                            payload: {
                              title: "Confirmation",
                              desc: `Are you sure you want to force logout user ${props.TxtUserName}?`,
                              isYesNo: true,
                              cb: async (e) => {
                                ActorAPI.forceLogoutUser(
                                  dispatch,
                                  props.SelectedActor.id,
                                  () => {
                                    dispatch({
                                      type: "MODAL_SHOW",
                                      payload: {
                                        title: "Action Succeeded",
                                        desc: `Successfully logged out user ${props.TxtUserName}`,
                                        isYesNo: false,
                                      },
                                    });
                                  }
                                );
                              },
                            },
                          });
                        }}
                      >
                        Force Logout User
                      </Button>
                    </>
                  )}
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Card>
                    <Card.Header className="font-weight-bold">
                      Permissions
                    </Card.Header>
                    <Card.Body disabled>
                      <Form.Check
                        checked={props.ChkUser}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                        name="chkUser"
                        label="User"
                        onChange={(e) => {
                          props.setChkUser(e.target.checked);
                        }}
                      />
                      {userTypes.includes("Admin") && (
                        <Form.Check
                          checked={props.ChkAdmin}
                          disabled={
                            props.SelectedOperation === SELECTED_OPERATION.VIEW
                          }
                          name="chkAdmin"
                          label="Admin"
                          onChange={(e) => {
                            props.setChkAdmin(e.target.checked);
                          }}
                        />
                      )}

                      {/* {userTypes.includes("Admin") && (
                        <Form.Check
                          checked={props.ChkExecutive}
                          disabled={
                            props.SelectedOperation === SELECTED_OPERATION.VIEW
                          }
                          name="chkExecutive"
                          label="Executive"
                          onChange={(e) => {
                            props.setChkExecutive(e.target.checked);
                          }}
                        />
                      )} */}

                      {/* {userTypes.includes("Admin") && (
                        <Form.Check
                          checked={props.ChkManager}
                          disabled={
                            props.SelectedOperation === SELECTED_OPERATION.VIEW
                          }
                          name="chkManager"
                          label="Manager"
                          onChange={(e) => {
                            props.setChkManager(e.target.checked);
                          }}
                        />
                      )} */}

                      <Form.Check
                        checked={props.ChkDriver}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                        name="chkDriver"
                        label="Driver"
                        onChange={(e) => {
                          props.setChkDriver(e.target.checked);
                        }}
                      />
                      {/* <Form.Check
                        checked={props.ChkSalesPerson}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                        name="chkSalesPerson"
                        label="Sales Person"
                        onChange={(e) => {
                          props.setChkSalesPerson(e.target.checked);
                        }}
                      /> */}
                      {/* {userTypes.includes("Admin") && (
                        <Form.Check
                          checked={props.ChkPurchaser}
                          disabled={
                            props.SelectedOperation === SELECTED_OPERATION.VIEW
                          }
                          name="chkPurchaser"
                          label="Purchaser"
                          onChange={(e) => {
                            props.setChkPurchaser(e.target.checked);
                          }}
                        />
                      )} */}

                      {/* <Form.Check
                        checked={props.ChkInvClerk}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                        name="chkInvClerk"
                        label="Inventory Clerk"
                        onChange={(e) => {
                          props.setChkInvClerk(e.target.checked);
                        }}
                      /> */}
                      {/* {(userTypes.includes("Admin") ||
                  userTypes.includes("Manager")) && (
                  <Form.Check
                    checked={props.ChkEmployee}
                    disabled={
                      props.SelectedOperation === SELECTED_OPERATION.VIEW
                    }
                    name="chkEmployee"
                    label="Employee"
                    onChange={(e) => {
                      props.setChkEmployee(e.target.checked);
                      //console.log(e);
                      //console.log(props.ChkEmployee);
                    }}
                  />
                )}
                {(userTypes.includes("Admin") ||
                  userTypes.includes("Manager")) && (
                  <Form.Check
                    checked={props.ChkPayee}
                    disabled={
                      props.SelectedOperation === SELECTED_OPERATION.VIEW
                    }
                    name="chkPayee"
                    label="Payee"
                    onChange={(e) => {
                      props.setChkPayee(e.target.checked);
                      //console.log(e);
                      //console.log(props.ChkPayee);
                    }}
                  />
                )} */}
                      {/* <Form.Check
        checked={props.state.chkBranchMngr.value}
        disabled={props.state.chkBranchMngr.readOnly}
        name="chkBranchMngr"
        label="Branch Manager"
        onChange={(e) => {
          props.state.handlePermsChange(e);
        }}
      /> */}
                      <h6 className="py-2" style={{ fontWeight: "bolder" }}>
                        Page Access
                      </h6>

                      <SelectPagePermission
                        titleLabel="Transaction Page Access"
                        controlName="transactionPage"
                        value={props.ChkTransactionPage}
                        setter={props.setChkTransactionPage}
                        permissionSetter={props.setCheckedUIPermission}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                      />
                      <SelectPagePermission
                        titleLabel="Accounting Page Access"
                        controlName="accountingPage"
                        value={props.ChkAccountingPage}
                        setter={props.setChkAccountingPage}
                        permissionSetter={props.setCheckedUIPermission}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                      />

                      <SelectPagePermission
                        titleLabel="Inventory Page Access"
                        controlName="inventoryPage"
                        value={props.ChkInventoryPage}
                        setter={props.setChkInventoryPage}
                        permissionSetter={props.setCheckedUIPermission}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                      />
                      <SelectPagePermission
                        titleLabel="Invoicing Page Access"
                        controlName="invoicingPage"
                        value={props.ChkInvoicingAccess}
                        setter={props.setChkInvoicingAccess}
                        permissionSetter={props.setCheckedUIPermission}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                      />
                      <SelectPagePermission
                        titleLabel="Invoice Payment Access"
                        controlName="invoicePaymentAccess"
                        value={props.ChkPaymentAccess}
                        setter={props.setChkPaymentAccess}
                        permissionSetter={props.setCheckedUIPermission}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                      />

                      <SelectPagePermission
                        titleLabel="Verification Access"
                        controlName="verificationAccess"
                        value={props.ChkVerificationPage}
                        setter={props.setChkVerificationPage}
                        permissionSetter={props.setCheckedUIPermission}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                      />

                      <Form.Check
                        checked={props.ChkPOS}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                        name="chkToggleSpecialPermission"
                        label="POS Page Access"
                        onChange={(e) => {
                          props.setCheckedUIPermission({
                            permission: "perm-ui-chkPOS",
                            checked: e.target.checked,
                          });
                          props.setChkPOS(e.target.checked);
                        }}
                      />
                      <Form.Check
                        checked={props.ChkPOSMarkup}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                        name="chkToggleSpecialPermission"
                        label="POS Item MARKUP Access"
                        onChange={(e) => {
                          props.setCheckedUIPermission({
                            permission: "perm-ui-chkPOSMarkup",
                            checked: e.target.checked,
                          });
                          props.setChkPOSMarkup(e.target.checked);
                        }}
                      />
                      <Form.Check
                        checked={props.ChkPOSMarkdown}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                        name="chkToggleSpecialPermission"
                        label="POS Item MARKDOWN Access"
                        onChange={(e) => {
                          props.setCheckedUIPermission({
                            permission: "perm-ui-chkPOSMarkdown",
                            checked: e.target.checked,
                          });
                          props.setChkPOSMarkdown(e.target.checked);
                        }}
                      />

                      {/* <>
                        <h6 className="py-2" style={{ fontWeight: "bolder" }}>
                          Verification Access Permissions
                        </h6>
                        <SelectPagePermission
                          titleLabel="Quote Verification"
                          controlName="quoteVerification"
                          value={props.ChkVeriQuote}
                          setter={props.setChkVeriQuote}
                          permissionSetter={props.setCheckedUIPermission}
                          disabled={
                            props.SelectedOperation === SELECTED_OPERATION.VIEW
                          }
                        />
                        <SelectPagePermission
                          titleLabel="DR Verification"
                          controlName="DRVerification"
                          value={props.ChkVeriDR}
                          setter={props.setChkVeriDR}
                          permissionSetter={props.setCheckedUIPermission}
                          disabled={
                            props.SelectedOperation === SELECTED_OPERATION.VIEW
                          }
                        />
                        <SelectPagePermission
                          titleLabel="PR PO Verification"
                          controlName="PRPOVerification"
                          value={props.ChkVeriPRPO}
                          setter={props.setChkVeriPRPO}
                          permissionSetter={props.setCheckedUIPermission}
                          disabled={
                            props.SelectedOperation === SELECTED_OPERATION.VIEW
                          }
                        />
                        <SelectPagePermission
                          titleLabel="Vouchers Verification"
                          controlName="vouchersVerification"
                          value={props.ChkVeriVoucher}
                          setter={props.setChkVeriVoucher}
                          permissionSetter={props.setCheckedUIPermission}
                          disabled={
                            props.SelectedOperation === SELECTED_OPERATION.VIEW
                          }
                        />
                        <SelectPagePermission
                          titleLabel="Inventory Requests Verification"
                          controlName="inventoryRequestsVerification"
                          value={props.ChkVeriInvRequest}
                          setter={props.setChkVeriInvRequest}
                          permissionSetter={props.setCheckedUIPermission}
                          disabled={
                            props.SelectedOperation === SELECTED_OPERATION.VIEW
                          }
                        />
                        <SelectPagePermission
                          titleLabel="Inventory Transfer Verification"
                          controlName="inventoryTransferVerification"
                          value={props.ChkVeriInvTransfer}
                          setter={props.setChkVeriInvTransfer}
                          permissionSetter={props.setCheckedUIPermission}
                          disabled={
                            props.SelectedOperation === SELECTED_OPERATION.VIEW
                          }
                        />
                      </> */}

                      <h6 className="py-2" style={{ fontWeight: "bolder" }}>
                        Invoice Access Permissions
                      </h6>

                      <SelectPagePermission
                        titleLabel="Quote Invoicing Access"
                        controlName="invoicingQuoteAccess"
                        value={props.ChkInvQuote}
                        setter={props.setChkInvQuote}
                        permissionSetter={props.setCheckedUIPermission}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                      />
                      <SelectPagePermission
                        titleLabel="Delivery Receipt Invoicing Access"
                        controlName="invoicingDRAccess"
                        value={props.ChkInvDR}
                        setter={props.setChkInvDR}
                        permissionSetter={props.setCheckedUIPermission}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                      />
                      <SelectPagePermission
                        titleLabel="Purchase Request Invoicing Access"
                        controlName="invoicingPRAccess"
                        value={props.ChkInvPR}
                        setter={props.setChkInvPR}
                        permissionSetter={props.setCheckedUIPermission}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                      />
                      <SelectPagePermission
                        titleLabel="Inventory Transfer Invoicing Access"
                        controlName="invoicingInvTransferAccess"
                        value={props.ChkInvTransfer}
                        setter={props.setChkInvTransfer}
                        permissionSetter={props.setCheckedUIPermission}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                      />
                      <SelectPagePermission
                        titleLabel="Inventory Item Request Invoicing Access"
                        controlName="invoicingInvItemRequestAccess"
                        value={props.ChkInvRequest}
                        setter={props.setChkInvRequest}
                        permissionSetter={props.setCheckedUIPermission}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                      />

                      {/* <Form.Check
                  checked={props.ChkInvQuote}
                  disabled={
                    props.SelectedOperation === SELECTED_OPERATION.VIEW
                  }
                  name="chkToggleSpecialPermission"
                  label="Quote Invoicing Access"
                  onChange={(e) => {
                    props.setCheckedUIPermission({
                      permission: "perm-ui-chkInvQuote",
                      checked: e.target.checked,
                    });
                    props.setChkInvQuote(e.target.checked);
                  }}
                />

                <Form.Check
                  checked={props.ChkInvDR}
                  disabled={
                    props.SelectedOperation === SELECTED_OPERATION.VIEW
                  }
                  name="chkToggleSpecialPermission"
                  label="Delivery Receipt Invoicing Access"
                  onChange={(e) => {
                    props.setCheckedUIPermission({
                      permission: "perm-ui-chkInvDR",
                      checked: e.target.checked,
                    });
                    props.setChkInvDR(e.target.checked);
                  }}
                />
                <Form.Check
                  checked={props.ChkInvPR}
                  disabled={
                    props.SelectedOperation === SELECTED_OPERATION.VIEW
                  }
                  name="chkToggleSpecialPermission"
                  label="Purchase Request Invoicing Access"
                  onChange={(e) => {
                    props.setCheckedUIPermission({
                      permission: "perm-ui-chkInvPR",
                      checked: e.target.checked,
                    });
                    props.setChkInvPR(e.target.checked);
                  }}
                />
                 <Form.Check
                  checked={props.ChkInvTransfer}
                  disabled={
                    props.SelectedOperation === SELECTED_OPERATION.VIEW
                  }
                  name="chkToggleSpecialPermission"
                  label="Inventory Request Invoicing Access"
                  onChange={(e) => {
                    props.setCheckedUIPermission({
                      permission: "perm-ui-chkInvTransfer",
                      checked: e.target.checked,
                    });
                    props.setChkInvTransfer(e.target.checked);
                  }}
                /> */}

                      <h6 className="py-2" style={{ fontWeight: "bolder" }}>
                        PDF Generate Permissions
                      </h6>

                      <Form.Check
                        checked={props.ChkQuoteInvoice}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                        name="chkToggleSpecialPermission"
                        label="Quotation Invoice Generate Access"
                        onChange={(e) => {
                          props.setCheckedUIPermission({
                            permission: "perm-ui-chkQuoteInvoice",
                            checked: e.target.checked,
                          });
                          props.setChkQuoteInvoice(e.target.checked);
                        }}
                      />

                      <Form.Check
                        checked={props.ChkInventoryGenerateInvoice}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                        name="chkToggleSpecialPermission"
                        label="Inventory Item Report Invoice Generate Access"
                        onChange={(e) => {
                          props.setCheckedUIPermission({
                            permission: "perm-ui-chkInventoryGenerateInvoice",
                            checked: e.target.checked,
                          });
                          props.setChkInventoryGenerateInvoice(
                            e.target.checked
                          );
                        }}
                      />

                      <Form.Check
                        checked={props.ChkPRPOInvoice}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                        name="chkToggleSpecialPermission"
                        label="PR / PO Invoice Generate Access"
                        onChange={(e) => {
                          props.setCheckedUIPermission({
                            permission: "perm-ui-chkPRPOInvoice",
                            checked: e.target.checked,
                          });
                          props.setChkPRPOInvoice(e.target.checked);
                        }}
                      />

                      <Form.Check
                        checked={props.ChkDRInvoice}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                        name="chkToggleSpecialPermission"
                        label="Delivery Receipt Generate Access"
                        onChange={(e) => {
                          props.setCheckedUIPermission({
                            permission: "perm-ui-chkDRInvoice",
                            checked: e.target.checked,
                          });
                          props.setChkDRInvoice(e.target.checked);
                        }}
                      />

                      <Form.Check
                        checked={props.ChkAcknowledgementReceipt}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                        name="chkToggleSpecialPermission"
                        label="Acknowledgement Receipt Generate Access"
                        onChange={(e) => {
                          props.setCheckedUIPermission({
                            permission: "perm-ui-chkAcknowledgementReceipt",
                            checked: e.target.checked,
                          });
                          props.setChkAcknowledgementReceipt(e.target.checked);
                        }}
                      />
                      <Form.Check
                        checked={props.ChkChargeInvoice}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                        name="chkToggleSpecialPermission"
                        label="Charge Invoice Generate Access"
                        onChange={(e) => {
                          props.setCheckedUIPermission({
                            permission: "perm-ui-chkChargeInvoice",
                            checked: e.target.checked,
                          });
                          props.setChkChargeInvoice(e.target.checked);
                        }}
                      />
                      <Form.Check
                        checked={props.ChkSalesReceipt}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                        name="chkToggleSpecialPermission"
                        label="Sales Invoice / Receipt Generate Access"
                        onChange={(e) => {
                          props.setCheckedUIPermission({
                            permission: "perm-ui-chkSalesReceipt",
                            checked: e.target.checked,
                          });
                          props.setChkSalesReceipt(e.target.checked);
                        }}
                      />
                      <Form.Check
                        checked={props.ChkPayeeBilling}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                        name="chkToggleSpecialPermission"
                        label="Payee Billing Generate Access"
                        onChange={(e) => {
                          props.setCheckedUIPermission({
                            permission: "perm-ui-chkPayeeBilling",
                            checked: e.target.checked,
                          });
                          props.setChkPayeeBilling(e.target.checked);
                        }}
                      />
                      <Form.Check
                        checked={props.ChkVoucherBilling}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                        name="chkToggleSpecialPermission"
                        label="Voucher Billing Generate Access"
                        onChange={(e) => {
                          props.setCheckedUIPermission({
                            permission: "perm-ui-chkVoucherBilling",
                            checked: e.target.checked,
                          });
                          props.setChkVoucherBilling(e.target.checked);
                        }}
                      />
                      <h6 className="py-2" style={{ fontWeight: "bolder" }}>
                        Special Permissions
                      </h6>

                      <Form.Check
                        checked={props.ChkUserInvoice}
                        disabled={
                          props.SelectedOperation === SELECTED_OPERATION.VIEW
                        }
                        name="chkToggleSpecialPermission"
                        label="All Users Invoice Access"
                        onChange={(e) => {
                          props.setCheckedUIPermission({
                            permission: "perm-ui-chkUserInvoice",
                            checked: e.target.checked,
                          });
                          props.setChkUserInvoice(e.target.checked);
                        }}
                      />
                    </Card.Body>
                  </Card>

                  {/* <TxtFileUpload
            SelectedOperation={props.SelectedOperation}
            FileUpload={props.FileUpload}
            setFileUpload={props.setFileUpload}
            handleFileUploadSubmit={props.handleFileUploadSubmit}
          /> */}
                  <Card className="mt-2">
                    <Card.Header className="font-weight-bold">
                      Inventory Access
                    </Card.Header>
                    <Card.Body disabled>
                      <InputGroup className="mb-1">
                        <FormControl
                          as="select"
                          className="form-control"
                          name="selInventory"
                          value={props.SelInventory.value}
                          onChange={(e) => {
                            props.setSelInventory(e.target.value);
                            // console.log(e.target.value);
                          }}
                          disabled={
                            props.SelectedOperation === SELECTED_OPERATION.VIEW
                          }
                        >
                          <option value={0} hidden>
                            Select Inventory...
                          </option>
                          {props.Inventories.map((val, index) => {
                            if (props.Permissions.includes(`inv-${val.name}`)) {
                              return null;
                            } else {
                              return (
                                <option
                                  value={val.name}
                                  key={`invPerm-${index}`}
                                >
                                  {val.name}
                                </option>
                              );
                            }
                          })}
                        </FormControl>
                        <OverlayTrigger
                          placement={"top"}
                          overlay={
                            <Tooltip id={`order-tooltip-add`}>
                              Grants this user access to selected inventory
                            </Tooltip>
                          }
                        >
                          <Button
                            name="btnAddActorInventory"
                            size="sm"
                            style={{ backgroundColor: "var(--REB-tertiary)" }}
                            className="ml-1"
                            onClick={(e) => {
                              props.AddActorInventory();
                            }}
                            disabled={
                              props.SelectedOperation ===
                              SELECTED_OPERATION.VIEW
                            }
                          >
                            Add Access
                          </Button>
                        </OverlayTrigger>
                      </InputGroup>
                      {props.Permissions.map((val, index) => {
                        const regex = /inv-/gi;

                        if (val.match(regex)) {
                          return (
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                              value={val}
                              key={`invUserPerm-${index}`}
                            >
                              <OverlayTrigger
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`order-tooltip-add`}>
                                    Remove {val.substr(4)} Access
                                  </Tooltip>
                                }
                              >
                                <Button
                                  name="btnRemoveActorInventory"
                                  onClick={(e) => {
                                    //props.setTxtActorName(e, val);
                                    props.RemoveActorInventory(val);
                                  }}
                                  variant="danger"
                                  className="mr-2 my-1"
                                  disabled={
                                    props.SelectedOperation ===
                                    SELECTED_OPERATION.VIEW
                                  }
                                >
                                  <i className="icofont-close "></i>
                                </Button>
                              </OverlayTrigger>

                              <h5
                                className="mb-0"
                                style={{ alignSelf: "center" }}
                              >
                                {val.substr(4)}
                              </h5>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            )}
            {props.SelActorType === 6 && (
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <Form.Label className="font-weight-bold">
                    <span className="required-field">* </span>
                    Client Name
                  </Form.Label>
                  <FormControl
                    isInvalid={
                      props.ActorInputValidation.TxtActorName.isInvalid
                    }
                    type="text"
                    // style={{ textTransform: "uppercase" }}
                    // placeholder={props.state.txtFirstName.placeholder}
                    name="txtActorName"
                    value={props.TxtActorName}
                    onChange={(e) => {
                      props.setTxtActorName(e.target.value);
                    }}
                    className=""
                    readOnly={
                      props.SelectedOperation === SELECTED_OPERATION.VIEW
                    }
                    required
                  />
                  {props.ActorInputValidation.TxtActorName.msg.map((x) => {
                    return (
                      <>
                        <p key={filters.uuidv4()}>{x}</p>
                      </>
                    );
                  })}
                  <Form.Label className="font-weight-bold">Contact</Form.Label>
                  <FormControl
                    type="text"
                    // placeholder={props.state.txtContact.placeholder}
                    name="txtContact"
                    value={props.TxtContact}
                    onChange={(e) => {
                      props.setTxtContact(e.target.value);
                    }}
                    className=""
                    readOnly={
                      props.SelectedOperation === SELECTED_OPERATION.VIEW
                    }
                  />
                  <Form.Label className="font-weight-bold">Address</Form.Label>
                  <FormControl
                    type="text"
                    //style={{ textTransform: "uppercase" }}
                    // placeholder={props.state.txtAddress.placeholder}
                    name="txtAddress"
                    value={props.TxtAddress}
                    onChange={(e) => {
                      props.setTxtAddress(e.target.value);
                    }}
                    className=""
                    readOnly={
                      props.SelectedOperation === SELECTED_OPERATION.VIEW
                    }
                  />
                  <Form.Label className="font-weight-bold">Email</Form.Label>
                  <FormControl
                    type="email"
                    // placeholder={props.state.txtEmail.placeholder}xx
                    name="txtEmail"
                    value={props.TxtEmail}
                    onChange={(e) => {
                      props.setTxtEmail(e.target.value);
                    }}
                    className=""
                    readOnly={
                      props.SelectedOperation === SELECTED_OPERATION.VIEW
                    }
                  />
                  <div className="mt-2">
                    <p
                      style={{
                        color: "var(--REB-warning)",
                        fontSize: "0.8rem",
                      }}
                      className="m-0 p-0"
                    >
                      Allow this client to be charged with charge invoice.
                    </p>
                    <Form.Check
                      checked={props.ChkAllowChargeInvoice}
                      disabled={
                        props.SelectedOperation === SELECTED_OPERATION.VIEW
                      }
                      name="chkAllowChargeInvoice"
                      label="Credit Line"
                      onChange={(e) => {
                        props.setCheckedUIPermission({
                          permission: "perm-ui-chkAllowChargeInvoice",
                          checked: e.target.checked,
                        });
                        props.setChkAllowChargeInvoice(e.target.checked);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            )}
            {props.SelActorType === 7 && (
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <Form.Label className="font-weight-bold">
                    <span className="required-field">* </span>
                    Supplier Name
                  </Form.Label>
                  <FormControl
                    isInvalid={
                      props.ActorInputValidation.TxtActorName.isInvalid
                    }
                    type="text"
                    // style={{ textTransform: "uppercase" }}
                    // placeholder={props.state.txtFirstName.placeholder}
                    name="txtActorName"
                    value={props.TxtActorName}
                    onChange={(e) => {
                      props.setTxtActorName(e.target.value);
                    }}
                    className=""
                    readOnly={
                      props.SelectedOperation === SELECTED_OPERATION.VIEW
                    }
                    required
                  />
                  {props.ActorInputValidation.TxtActorName.msg.map((x) => {
                    return (
                      <>
                        <p key={filters.uuidv4()}>{x}</p>
                      </>
                    );
                  })}
                  <Form.Label className="font-weight-bold">Email</Form.Label>
                  <FormControl
                    type="email"
                    // placeholder={props.state.txtEmail.placeholder}xx
                    name="txtEmail"
                    value={props.TxtEmail}
                    onChange={(e) => {
                      props.setTxtEmail(e.target.value);
                    }}
                    className=""
                    readOnly={
                      props.SelectedOperation === SELECTED_OPERATION.VIEW
                    }
                  />
                  <Form.Label className="font-weight-bold">Contact</Form.Label>
                  <FormControl
                    type="text"
                    // placeholder={props.state.txtContact.placeholder}
                    name="txtContact"
                    value={props.TxtContact}
                    onChange={(e) => {
                      props.setTxtContact(e.target.value);
                    }}
                    className=""
                    readOnly={
                      props.SelectedOperation === SELECTED_OPERATION.VIEW
                    }
                  />
                  <Form.Label className="font-weight-bold">Address</Form.Label>
                  <FormControl
                    type="text"
                    //style={{ textTransform: "uppercase" }}
                    // placeholder={props.state.txtAddress.placeholder}
                    name="txtAddress"
                    value={props.TxtAddress}
                    onChange={(e) => {
                      props.setTxtAddress(e.target.value);
                    }}
                    className=""
                    readOnly={
                      props.SelectedOperation === SELECTED_OPERATION.VIEW
                    }
                  />
                  <Form.Label className="font-weight-bold">Website</Form.Label>
                  <FormControl
                    type="text"
                    //style={{ textTransform: "uppercase" }}
                    // placeholder={props.state.txtAddress.placeholder}
                    name="txtWebsite"
                    value={props.TxtWebsite}
                    onChange={(e) => {
                      props.setTxtWebsite(e.target.value);
                    }}
                    className=""
                    readOnly={
                      props.SelectedOperation === SELECTED_OPERATION.VIEW
                    }
                  />
                </Col>
              </Row>
            )}
            {props.SelActorType === 13 && (
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <Form.Label className="font-weight-bold">
                    <span className="required-field">* </span>
                    Payee Name
                  </Form.Label>
                  <FormControl
                    isInvalid={
                      props.ActorInputValidation.TxtActorName.isInvalid
                    }
                    type="text"
                    //style={{ textTransform: "uppercase" }}
                    // placeholder={props.state.txtFirstName.placeholder}
                    name="txtActorName"
                    value={props.TxtActorName}
                    onChange={(e) => {
                      props.setTxtActorName(e.target.value);
                    }}
                    className=""
                    readOnly={
                      props.SelectedOperation === SELECTED_OPERATION.VIEW
                    }
                    required
                  />
                  {props.ActorInputValidation.TxtActorName.msg.map((x) => {
                    return (
                      <>
                        <p key={filters.uuidv4()}>{x}</p>
                      </>
                    );
                  })}
                  <Form.Label className="font-weight-bold">Email</Form.Label>
                  <FormControl
                    type="email"
                    // placeholder={props.state.txtEmail.placeholder}xx
                    name="txtEmail"
                    value={props.TxtEmail}
                    onChange={(e) => {
                      props.setTxtEmail(e.target.value);
                    }}
                    className=""
                    readOnly={
                      props.SelectedOperation === SELECTED_OPERATION.VIEW
                    }
                  />
                  <Form.Label className="font-weight-bold">Contact</Form.Label>
                  <FormControl
                    type="text"
                    // placeholder={props.state.txtContact.placeholder}
                    name="txtContact"
                    value={props.TxtContact}
                    onChange={(e) => {
                      props.setTxtContact(e.target.value);
                    }}
                    className=""
                    readOnly={
                      props.SelectedOperation === SELECTED_OPERATION.VIEW
                    }
                  />
                  <Form.Label className="font-weight-bold">Address</Form.Label>
                  <FormControl
                    type="text"
                    //style={{ textTransform: "uppercase" }}
                    // placeholder={props.state.txtAddress.placeholder}
                    name="txtAddress"
                    value={props.TxtAddress}
                    onChange={(e) => {
                      props.setTxtAddress(e.target.value);
                    }}
                    className=""
                    readOnly={
                      props.SelectedOperation === SELECTED_OPERATION.VIEW
                    }
                  />
                  <Form.Label className="font-weight-bold">Website</Form.Label>
                  <FormControl
                    type="text"
                    //style={{ textTransform: "uppercase" }}
                    // placeholder={props.state.txtAddress.placeholder}
                    name="txtWebsite"
                    value={props.TxtWebsite}
                    onChange={(e) => {
                      props.setTxtWebsite(e.target.value);
                    }}
                    className=""
                    readOnly={
                      props.SelectedOperation === SELECTED_OPERATION.VIEW
                    }
                  />
                </Col>
              </Row>
            )}
            {props.SelActorType === 12 && (
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <Form.Label className="font-weight-bold">
                    <span className="required-field">* </span>
                    Employee Name
                  </Form.Label>
                  <FormControl
                    isInvalid={
                      props.ActorInputValidation.TxtActorName.isInvalid
                    }
                    type="text"
                    //style={{ textTransform: "uppercase" }}
                    // placeholder={props.state.txtFirstName.placeholder}
                    name="txtActorName"
                    value={props.TxtActorName}
                    onChange={(e) => {
                      props.setTxtActorName(e.target.value);
                    }}
                    className=""
                    readOnly={
                      props.SelectedOperation === SELECTED_OPERATION.VIEW
                    }
                    required
                  />
                  {props.ActorInputValidation.TxtActorName.msg.map((x) => {
                    return (
                      <>
                        <p key={filters.uuidv4()}>{x}</p>
                      </>
                    );
                  })}
                  <Form.Label className="font-weight-bold">Contact</Form.Label>
                  <FormControl
                    type="text"
                    // placeholder={props.state.txtContact.placeholder}
                    name="txtContact"
                    value={props.TxtContact}
                    onChange={(e) => {
                      props.setTxtContact(e.target.value);
                    }}
                    className=""
                    readOnly={
                      props.SelectedOperation === SELECTED_OPERATION.VIEW
                    }
                  />
                  <Form.Label className="font-weight-bold">Address</Form.Label>
                  <FormControl
                    type="text"
                    //style={{ textTransform: "uppercase" }}
                    // placeholder={props.state.txtAddress.placeholder}
                    name="txtAddress"
                    value={props.TxtAddress}
                    onChange={(e) => {
                      props.setTxtAddress(e.target.value);
                    }}
                    className=""
                    readOnly={
                      props.SelectedOperation === SELECTED_OPERATION.VIEW
                    }
                  />
                </Col>
              </Row>
            )}
          </div>
          <Row
            className=" actorModalControls"
            style={{ borderTop: "var(--REB-black) solid 1px" }}
            noGutters
          >
            <Col sm={12} xs={12} md={8} lg={6} className="p-2"></Col>
            {(userTypes.includes("Admin") ||
              userTypes.includes("Manager") ||
              permissions.includes("perm-ui-invoicingPage-view-write-manage") ||
              permissions.includes(
                "perm-ui-transactionPage-view-write-manage"
              ) ||
              props.SelectedOperation === SELECTED_OPERATION.VIEW ||
              props.SelectedOperation === SELECTED_OPERATION.EDIT) && (
              <Col xs={4} sm={12} lg={2} className="p-1">
                <Button
                  block
                  style={{ backgroundColor: "var(--REB-green)" }}
                  onClick={() => {
                    props.setSelectedOperation(SELECTED_OPERATION.EDIT);

                    props.setIsPWVisible(true);
                  }}
                  disabled={
                    props.SelectedOperation === SELECTED_OPERATION.ADD ||
                    props.SelectedOperation === SELECTED_OPERATION.EDIT
                  }
                >
                  MODIFY
                </Button>
              </Col>
            )}
            {/* {props.SelectedOperation === SELECTED_OPERATION.ADD  && (
          <Col sx={12} xs={12} lg={2} className="p-2">
            <Button
              block
              style={{ backgroundColor: "var(--REB-green)" }}
              sx={12}
              xs={12}
              lg={2}
              onClick={(e) => {
                props.ValidateActor(() => {
                  props.SelectedOperation === SELECTED_OPERATION.ADD &&
                    props.CreateActor();

                  props.SelectedOperation === SELECTED_OPERATION.EDIT &&
                    props.ModifyActor();
                  props.setIsActorModalOpen(false);
                  props.ClearActorModal();
                });
              }}
              disabled={props.SelectedOperation === SELECTED_OPERATION.VIEW}
            >
              ADD
            </Button>
          </Col>
        )} */}
            {(userTypes.includes("Admin") ||
              userTypes.includes("Manager") ||
              permissions.includes("perm-ui-invoicingPage-view-write") ||
              permissions.includes("perm-ui-transactionPage-view-write") ||
              props.SelectedOperation === SELECTED_OPERATION.EDIT ||
              props.SelectedOperation === SELECTED_OPERATION.VIEW) && (
              <Col xs={4} sm={12} lg={2} className="p-1">
                <Button
                  block
                  style={{ backgroundColor: "var(--REB-green)" }}
                  sx={12}
                  xs={12}
                  lg={2}
                  onClick={(e) => {
                    props.ValidateActor(() => {
                      props.SelectedOperation === SELECTED_OPERATION.ADD &&
                        props.CreateActor();

                      props.SelectedOperation === SELECTED_OPERATION.EDIT &&
                        props.ModifyActor();
                      props.setIsActorModalOpen(false);
                      props.ClearActorModal();
                    });
                  }}
                  disabled={props.SelectedOperation === SELECTED_OPERATION.VIEW}
                >
                  SAVE
                </Button>
              </Col>
            )}
            {(userTypes.includes("Admin") ||
              userTypes.includes("Manager") ||
              permissions.includes(
                "perm-ui-invoicingPage-view-write-manage-admin"
              ) ||
              permissions.includes(
                "perm-ui-transactionPage-view-write-manage-admin"
              )) && (
              <Col xs={4} sm={12} lg={2} className="p-1">
                {props.SelectedOperation === SELECTED_OPERATION.ADD ? (
                  <Button
                    block
                    style={{ backgroundColor: "var(--REB-warning)" }}
                    onClick={(e) => {
                      props.setIsActorModalOpen(false);
                      props.ClearActorModal();
                    }}
                    disabled={
                      props.SelectedOperation === SELECTED_OPERATION.VIEW
                    }
                  >
                    CANCEL
                  </Button>
                ) : (
                  <Button
                    block
                    style={{ backgroundColor: "var(--REB-warning)" }}
                    onClick={(e) => {
                      dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "Confirmation",
                          desc: `Are you sure you want to remove this actor?`,
                          isYesNo: true,
                          cb: async (e) => {
                            props.DeleteActor(e);
                            props.ClearActorModal();
                            props.setIsActorModalOpen(false);
                          },
                        },
                      });
                    }}
                    disabled={
                      props.SelectedOperation === SELECTED_OPERATION.VIEW
                    }
                  >
                    DELETE
                  </Button>
                )}
              </Col>
            )}
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export { employeeColumn, supplierColumn, customerColumn, ActorModal };
