import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Modal } from "react-bootstrap";
import misc from "../../functions/misc";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import { NumericFormat } from "react-number-format";
export const CartModal = (props) => {
  const {
    setCartModalOpen,
    setCartSelection,
    setSelectedCartList,
    CartModalOpen,
    SelectedCartList,
    CartData,
    setCartData,
    setCartDataCopy,
    setPriceAdjustModalOpen,
    Settings,
    CURRENT_USERTYPE,
    ItemListData,
    setItemListData,
  } = props;
  const [TxtItemQty, setTxtItemQty] = useState(SelectedCartList?.qty);
  //console.log(SelectedCartList?.qty)
  useEffect(() => {
    setTxtItemQty(SelectedCartList?.qty);
  }, [SelectedCartList?.qty]);

  //find selected item in itemlist to check available qty
  // const SelectedItemStock = ItemListData.find(
  //   (x) => x.id === SelectedCartList?.id
  // );

  return (
    <>
      {/* CART ITEM SELECT MODAL */}
      <Modal
        show={CartModalOpen}
        onHide={() => {
          setCartModalOpen(false);
          setCartSelection([]);
          setSelectedCartList(null);
        }}
        onExit={(e) => {
          setCartModalOpen(false);
          setCartSelection([]);
          setSelectedCartList(null);
        }}
        dialogClassName=""
        centered
      >
        <Modal.Header
          className="p-0 global-modal-header"
          style={{ backgroundColor: "var(--REB-primary)" }}
        >
          <Modal.Title className="p-2 global-modal-header-title">
            <Row noGutters>
              <Col xs="auto" className="h-100">
                <Button
                  style={{ backgroundColor: "var(--REB-warning)" }}
                  onClick={() => {
                    setCartModalOpen(false);
                  }}
                  className="h-100"
                >
                  <i className="icofont-ui-close"></i>
                </Button>
              </Col>
              <Col xs="auto" className="pl-3" style={{ alignSelf: "center" }}>
                <h6
                  className="text-center m-0 h-100"
                  style={{ color: "var(--REB-white)", fontWeight: "bolder" }}
                >
                  Modify Cart Item
                </h6>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>{SelectedCartList?.name}</h5>
          <p style={{ fontSize: "0.75rem" }}>{SelectedCartList?.description}</p>
          <Form.Label
            className="font-weight-bold"
            style={{ color: "var(--REB-black)" }}
          >
            QTY
          </Form.Label>
          <FormControl
            as={NumericFormat}
            inputMode="numeric"
            placeholder="Item QTY"
            thousandSeparator={true}
            suffix={` ${SelectedCartList?.unit}`}
            allowNegative={false}
            value={Number(TxtItemQty)}
            // isAllowed={(values) => {
            //   const { formattedValue, floatValue } = values;

            //   const itemQty =
            //     Number(SelectedItemStock?.qty) + SelectedCartList?.qty;
            //   //console.log(SRP);
            //   return (
            //     formattedValue === "" ||
            //     (floatValue <= itemQty && floatValue >= 0)
            //   );
            // }}
            onValueChange={(x) => {
              //On change limit user input to maximum amount
              //const amountDue = Number(props.state.invoiceTotalAmountDue);
              setTxtItemQty(Number(x.value));
            }}
          />
          {/* <Row noGutters>
            <Col xs="auto">
              <p>QTY: </p>
            </Col>
            <Col xs="auto" className="pl-3">
              <p>{SelectedCartList?.qty}</p>
            </Col>
          </Row> */}
        </Modal.Body>
        <Modal.Footer className="global-modal-footer">
          <Row noGutters>
            <Col xs="auto" className="px-1">
              <Button
                onClick={() => {
                  let _CartSelection = [];
                  _CartSelection = CartData.map((x) => {
                    return x.id === SelectedCartList?.id
                      ? {
                          ...x,
                          discount: 0,
                          priceModifier: Settings.itemPriceModification
                            ? Settings.itemPriceModification
                            : 0,
                          price: misc.calculateMarkupDiscount(
                            x.srp,
                            0,
                            Settings.itemPriceModification,
                            Settings.itemPriceModification
                          ),
                        }
                      : x;
                  });
                  //console.log(_CartSelection);
                  // _CartSelection = _CartSelection.filter((x) => x.qty !== 0);
                  setCartData(_CartSelection);
                  setCartDataCopy(_CartSelection);
                  setSelectedCartList(
                    _CartSelection.find((x) => x.id === SelectedCartList?.id)
                  );
                  setPriceAdjustModalOpen(true);
                }}
              >
                Price Adjust
              </Button>
            </Col>
            <Col xs="auto" className="px-1">
              <Button
                style={{ backgroundColor: "var(--REB-green)" }}
                onClick={() => {
                  let _CartSelection = [];
                  _CartSelection = _CartSelection.concat(CartData);
                  _CartSelection = _CartSelection.map((x) =>
                    x.id === SelectedCartList?.id
                      ? {
                          ...x,
                          qty: Number(TxtItemQty),
                        }
                      : x
                  );
                  _CartSelection = _CartSelection.filter((x) => x.qty !== 0);

                  // let tempItemList = ItemListData;
                  // tempItemList = tempItemList.map((x) => {
                  //   return x.id === SelectedCartList?.id
                  //     ? { ...x, qty: x.qty - Number(TxtItemQty) }
                  //     : x;
                  // });
                  // setItemListData(tempItemList);

                  setCartData(_CartSelection);
                  setCartDataCopy(_CartSelection);
                  setCartModalOpen(false);
                }}
              >
                Save QTY
              </Button>
            </Col>
            <Col xs="auto" className="px-1">
              <Button
                style={{ backgroundColor: "var(--REB-warning)" }}
                onClick={() => {
                  let _CartSelection = [];
                  _CartSelection = _CartSelection.concat(CartData);

                  _CartSelection = _CartSelection.filter(
                    (x) => x.id !== SelectedCartList?.id
                  );

                  // let tempItemList = ItemListData;
                  // tempItemList = tempItemList.map((x) => {
                  //   return x.id === SelectedCartList?.id
                  //     ? { ...x, qty: x.qty + Number(SelectedCartList?.qty) }
                  //     : x;
                  // });
                  // setItemListData(tempItemList);

                  setCartData(_CartSelection);
                  setCartDataCopy(_CartSelection);
                  setCartModalOpen(false);
                }}
              >
                Remove Item
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};
