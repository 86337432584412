import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Select from "react-select";
import { TypeQuote } from "./QuoteContent";
import { TypePR } from "./PurchaseRequestContent";
import { TypeTransferInventory } from "./TransferInventoryContent";
import "./invoiceModal.css";
import { NumericFormat } from "react-number-format";
import InvoiceAPI from "./InvoiceAPI";
import { useDispatch, useSelector } from "react-redux";
import filters from "../../functions/filters";
import moment from "moment";
import TransactionsAPI from "../Transactions/TransactionsAPI";
import AcknowledgementReceipt from "../Transactions/PDF/AcknowledgementReceipt";
import ChargeBillingReceipt from "./PDF/ChargeInvoice";
import { usePDF, pdf } from "@react-pdf/renderer";
import Receipt from "../POS/PDF/Receipt";
import { getInvoiceHeaderData } from "../../functions/pdfHeaderData";
import misc from "../../functions/misc";
import invoiceApi from "./InvoiceAPI";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import fileReg from "../../assets/file-regular.svg";
let _date = "";
let _type = "";
let _chkNumber = "";
let _paymentNotes = "";
let _paymentBankName = "";
let _paymentBankAccount = "";

const BILL_TYPE = {
  CHARGE_BILL: { name: "charge_bill", id: 0 },
  BILLING: { name: "billing", id: 1 },
};

let customStyles = {
  menu: (provided, state) => {
    //console.log(state);
    const width = "100%";

    let color = state.selectProps.menuColor;
    const padding = 0;

    return { ...provided, width, color, padding };
  },
  control: (provided) => ({
    ...provided,
    borderRadius: "0px 0px 0px 0px",
  }),

  container: (_, { selectProps: { width } }) => ({
    width: width,
    flex: 1,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,

    borderRadius: "5px 0px 0px 5px",
  }),

  option: (provided, state) => {
    let backgroundColor = "";
    let display = "block";
    let color = "black";
    if (state.data.isDefault === 1) {
      backgroundColor = "rgba(255, 0, 0, 0.2)";
    }
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    //console.log(state.data);
    if (state.data.isHidden === 1) {
      display = "none";
    }

    return {
      ...provided,
      opacity,
      transition,
      backgroundColor,
      color,
      display,
    };
  },
};
let TxtInvoiceName = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      Invoice Name
    </Form.Label>
    <FormControl
      type="text"
      style={{ textTransform: "uppercase" }}
      placeholder="Name"
      name="txtInvoiceName"
      value={props.state.txtInvoiceName.value}
      onChange={props.state.inputChange}
      className={props.state.txtInvoiceName.className + " css-actorInput mb-2"}
      readOnly={props.state.txtInvoiceName.readOnly}
      required
    />
  </>
);
let TxtShippingAddress = (props) => {
  const HeaderData = getInvoiceHeaderData();
  return (
    <>
      <Form.Label
        className="font-weight-bold"
        style={{ color: "var(--REB-black)" }}
      >
        Shipping Address
      </Form.Label>
      <InputGroup className="mb-2">
        <FormControl
          as="textarea"
          ///style={{ textTransform: "uppercase" }}
          placeholder="Shipping Address"
          name="txtShippingAddress"
          value={props.state.txtShippingAddress.value}
          onChange={props.state.inputChange}
          className={props.state.txtShippingAddress.className + ""}
          readOnly={props.state.txtShippingAddress.readOnly}
        />
        <InputGroup.Prepend>
          <OverlayTrigger
            // placement={"right"}
            overlay={
              <Tooltip id={`project-tooltip-add`}>
                Copies the preset delivery address from settings
              </Tooltip>
            }
          >
            <Button
              style={{
                backgroundColor: "var(--REB-green)",
                borderRadius: "0px 5px 5px 0px",
              }}
              onClick={(e) => {
                props.state.inputChange({
                  target: {
                    name: "txtShippingAddress",
                    value: HeaderData.invoiceDeliveryReceiveAddress,
                  },
                });
              }}
            >
              <i className="icofont-ui-copy"></i>
            </Button>
          </OverlayTrigger>
        </InputGroup.Prepend>
      </InputGroup>
    </>
  );
};

let TxtDescription = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      Description
    </Form.Label>
    <FormControl
      type="text"
      //style={{ textTransform: "uppercase" }}
      placeholder="Description"
      name="txtDesc"
      value={props.state.txtDesc.value}
      onChange={props.state.inputChange}
      className={props.state.txtDesc.className + " css-actorInput mb-2"}
      readOnly={props.state.txtDesc.readOnly}
    />
  </>
);

let TxtNotes = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      Notes
    </Form.Label>
    <FormControl
      as="textarea"
      //style={{ textTransform: "uppercase" }}
      placeholder="Notes"
      name="txtNotes"
      value={props.state.txtNotes.value}
      onChange={props.state.inputChange}
      className={props.state.txtNotes.className + " css-actorInput mb-2"}
      readOnly={props.state.txtNotes.readOnly}
    />
  </>
);

let TxtDateDeadline = (props) => (
  <>
    <Form.Label>
      <strong>Deadline</strong>
    </Form.Label>
    <FormControl
      type="date"
      //placeholder={props.state.txtDeadline.placeholder}
      name="txtDeadline"
      value={props.state.txtDeadline.value}
      onChange={props.state.inputChange}
      className={props.state.txtDeadline.className + " css-projectInput mb-2"}
      readOnly={props.state.txtDeadline.readOnly}
    />
  </>
);
let TxtDateAdded = (props) => (
  <>
    <Form.Label>
      <strong>Date Added</strong>
    </Form.Label>
    <FormControl
      type="datetime-local"
      //placeholder={props.state.txtDateAdded.placeholder}
      name="txtDateAdded"
      value={props.state.txtDateAdded.value}
      onChange={props.state.inputChange}
      className={props.state.txtDateAdded.className + " css-projectInput mb-2"}
      readOnly={true}
    />
  </>
);

let TxtDateLastModified = (props) => (
  <>
    <Form.Label>
      <strong>Date Last Modified</strong>
    </Form.Label>
    <FormControl
      type="datetime-local"
      //placeholder={props.state.txtDateLastModified.placeholder}
      name="txtDateLastModified"
      value={props.state.txtDateLastModified.value}
      onChange={props.state.inputChange}
      className={
        props.state.txtDateLastModified.className + " css-projectInput mb-2"
      }
      readOnly={true}
    />
  </>
);
let TxtDateFinished = (props) => (
  <>
    <Form.Label>
      <strong>Date Finished</strong>
    </Form.Label>
    <FormControl
      type="datetime-local"
      //placeholder={props.state.txtDateAdded.placeholder}
      name="txtDateFinished"
      value={props.state.txtDateFinished.value}
      onChange={props.state.inputChange}
      className={
        props.state.txtDateFinished.className + " css-projectInput mb-2"
      }
      readOnly={true}
    />
  </>
);
let TxtLastModifiedBy = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      Last Modified By
    </Form.Label>
    <FormControl
      type="text"
      placeholder="Last modified by"
      name="txtLastModifiedByActor"
      value={props.state.txtLastModifiedByActor.value}
      readOnly={true}
      className="mb-2"
    />
  </>
);
let TxtApprovedByActor = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      Approved By
    </Form.Label>
    <FormControl
      type="text"
      placeholder="Approved by"
      name="txtApprovedByActor"
      value={props.state.txtApprovedByActor.value}
      readOnly={true}
      className="mb-2"
    />
  </>
);

let TxtAmountPaid = (props) => {
  let paymentSelectStyle = {
    menu: (provided, state) => {
      //console.log(state);
      const width = "100%";

      let color = state.selectProps.menuColor;
      const padding = 0;

      return {
        ...provided,
        width,
        color,
        padding,

        //backgroundColor: "red",
      };
    },
    control: (provided) => ({
      ...provided,
      // borderRadius: "0px 0px 0px 0px",
    }),

    container: (_, { selectProps: { width } }) => ({
      width: width,
      flex: 1,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 6000,

      borderRadius: "5px 0px 0px 5px",
    }),
    menuList: (provided) => ({
      ...provided,
      zIndex: 6000,

      borderRadius: "5px 0px 0px 5px",
    }),

    option: (provided, state) => {
      let backgroundColor = "";
      let display = "block";
      let color = "black";
      if (state.data.isDefault === 1) {
        backgroundColor = "rgba(255, 0, 0, 0.2)";
      }
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      //console.log(state.data);
      if (state.data.isHidden === 1) {
        display = "none";
      }

      return {
        ...provided,
        opacity,
        transition,
        backgroundColor,
        color,
        display,
      };
    },
  };

  const {
    userType,
    user,
    selectedRowInvoice,
    selectedInvoicePayments,
    settings,
  } = props.state;

  const selectedInvoiceID = props.state.selectedRowInvoice
    ? Number(props.state.selectedRowInvoice.id)
    : null;
  let paymentData = [];

  if (selectedRowInvoice)
    paymentData =
      selectedInvoicePayments !== null ? selectedInvoicePayments : [];

  // CASH = 'cash',
  // CHECK = 'check',
  // POSTDATED_CHECK = 'postdated-check',
  // BANK_DEPOSIT = 'bank-deposit',
  // ELECTRONIC_BANK_TRANSFER = 'electronic-bank-transfer',
  // DEBIT_CARD = 'debit-card',
  // CREDIT_CARD = 'credit-card',
  // PREPAID_CARD = 'prepaid-card',
  // COD = 'cod',
  // MOBILE_WALLET = 'mobile-wallet',

  const PAYMENT_TYPES = [
    { value: "cash", label: "Cash" },
    { value: "check", label: "Check" },
    { value: "post-dated-check", label: "Post Dated Cheque" },
    { value: "bank-deposit", label: "Bank Deposit" },
    { value: "electronic-bank-transfer", label: "Electronic Bank Transfer" },
    { value: "debit-card", label: "Debit Card" },
    { value: "credit-card", label: "Credit Card" },
    { value: "prepaid-card", label: "Pre Paid Card" },
    { value: "cod", label: "Cash on Delivery (COD)" },
    { value: "mobile-wallet", label: "Mobile Wallet" },
  ];

  const dispatch = useDispatch();

  const permissions = useSelector((state) => {
    const user = state.apiData.userData;
    let _permissions = [];
    if (user && user.permissions) {
      _permissions = JSON.parse(user.permissions);
    }
    return _permissions.toString();
  });

  const [invoiceStatus, setInvoiceStatus] = useState(null);
  const [clientPermissions, setClientPermissions] = useState([]);

  //console.log(props.state)

  useEffect(() => {
    if (props.state.selectedRowInvoice) {
      if (props.state.selectedRowInvoice.invoiceStatus) {
        if (props.state.selectedRowInvoice.invoiceStatus.name) {
          setInvoiceStatus(props.state.selectedRowInvoice.invoiceStatus.name);
        }
      }

      if (settings) {
        setSelInvoiceIncome(settings.incomeAccountInvoice);
        setSelInvoiceInventoryAccount(settings.inventoryAccount);
        setSelInvoiceActsReceivable(settings.actsReceivableAccountInvoice);
      }
      if (props.state.selectedRowInvoice?.client) {
        if (props.state.selectedRowInvoice?.client?.permissions) {
          setClientPermissions(
            JSON.parse(props.state.selectedRowInvoice?.client?.permissions)
          );
        }
      }
    }
  }, [props.state.selectedRowInvoice]);

  //const user = useSelector((state) => state.apiData.userData);

  const [operation, setOperation] = useState("VIEW");
  const [BillType, setBillType] = useState(null);
  const [TxtAmount, setTxtAmount] = useState(0);
  const [TxtChkNumber, setTxtChkNumber] = useState("");
  const [TxtDate, setTxtDate] = useState("");
  const [TxtDeadline, setTxtDeadline] = useState("");

  const [TxtType, setTxtType] = useState({ value: "cash", label: "Cash" });
  const [ChkDownPayment, setChkDownPayment] = useState(false);
  const [TxtPaymentNotes, setTxtPaymentNotes] = useState("");
  const [TxtPaymentBankName, setTxtPaymentBankName] = useState("");
  const [TxtPaymentBankAccount, setTxtPaymentBankAccount] = useState("");
  const [SelInvoiceIncome, setSelInvoiceIncome] = useState(null);
  const [SelInvoiceInventoryAccount, setSelInvoiceInventoryAccount] =
    useState(null);
  const [SelInvoiceActsReceivable, setSelInvoiceActsReceivable] =
    useState(null);

  const [PDFInstance, updatePDFInstance] = usePDF({ document: null });

  const [showSetInvoiceForDelivery, setShowSetInvoiceForDelivery] =
    useState(false);

  //FILE UPLOAD CONTROLS
  const [btnSaveFile, setBtnSaveFile] = useState(true);
  const [fileUpload, setFileUpload] = useState(null);
  //const inputFile = useRef();
  const [FileUploadData, setFileUploadData] = useState([]);

  useEffect(() => {
    if (paymentData.length > 0) {
      const hasFinishedTransaction = paymentData.find(
        (t) => t.transStatus === "finished"
      );
      const hasChargeInvoiceTransaction = paymentData.find(
        (t) => t.transStatus === "processing"
      );
      if (hasFinishedTransaction || hasChargeInvoiceTransaction) {
        setShowSetInvoiceForDelivery(true);
      }
      //console.log(paymentData);
    }
  }, [paymentData]);

  const resetUserInputs = () => {
    setOperation("VIEW");
    setTxtAmount(0);
    setTxtChkNumber("");
    setTxtDate("");
    setTxtDeadline("");
    setTxtType({ value: "cash", label: "Cash" });
    setChkDownPayment(false);
    setTxtPaymentNotes("");
    setTxtPaymentBankName("");
    setTxtPaymentBankAccount("");
    // setSelInvoiceIncome(null);
    // setSelInvoiceInventoryAccount(null);
    // setSelInvoiceActsReceivable(null);
    setBillType(BILL_TYPE.BILLING);
  };

  const validateUserInputs = (cb) => {
    //let tempUserInput = { ...validateUser };
    let error = false;

    if (BillType === BILL_TYPE.BILLING) {
      if (TxtAmount === "" || TxtAmount === null) error = true;
      if (TxtDate === null || TxtDate === "") error = true;
      if (TxtType === null || TxtType === "") error = true;
    } else {
      if (TxtAmount === "" || TxtAmount === null) error = true;
    }

    if (SelInvoiceIncome === null || SelInvoiceIncome === "") error = true;
    if (SelInvoiceActsReceivable === null || SelInvoiceActsReceivable === "")
      error = true;
    if (
      SelInvoiceInventoryAccount === null ||
      SelInvoiceInventoryAccount === ""
    )
      error = true;

    if (error)
      dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Input Notice",
          desc: `Please make sure Amount, Accounts, Date and Payment Type fields are not empty and is valid`,
          isYesNo: false,
        },
      });
    else if (cb) cb();

    // console.log(tempUserInput);
  };

  const TxtJAmountPaid = (
    <Col xs={12} sm={12} md={4} lg={4} className="px-1 mb-2">
      <Form.Label
        className="font-weight-bold"
        style={{ color: "var(--REB-black)" }}
      >
        {BillType === BILL_TYPE.BILLING && <>Amount Paid</>}
        {BillType === BILL_TYPE.CHARGE_BILL && <>Amount to be charged</>}
      </Form.Label>
      <FormControl
        as={NumericFormat}
        inputMode="numeric"
        placeholder="Amount Paid"
        thousandSeparator={true}
        prefix={"₱ "}
        allowNegative={false}
        value={Number(TxtAmount)}
        isAllowed={(values) => {
          const { formattedValue, floatValue } = values;
          const amountDue = Number(props.state.invoiceTotalAmountDue);
          //console.log(SRP);
          return (
            formattedValue === "" ||
            (floatValue <= amountDue && floatValue >= 0)
          );
        }}
        onValueChange={(x) => {
          //On change limit user input to maximum amount
          //const amountDue = Number(props.state.invoiceTotalAmountDue);
          setTxtAmount(Number(x.value));
        }}
      />
    </Col>
  );
  const TxtJPaymentDate = (
    <Col xs={12} sm={12} md={4} lg={4} className="px-1 mb-2">
      <Form.Label
        className="font-weight-bold"
        style={{ color: "var(--REB-black)" }}
      >
        Payment Date
      </Form.Label>
      <FormControl
        type="date"
        value={TxtDate}
        onChange={(x) => {
          setTxtDate(x.currentTarget.value);
        }}
      />
    </Col>
  );
  const TxtJPaymentType = (
    <Col xs={12} sm={12} md={4} lg={4} className="px-1 mb-2">
      <Form.Label
        className="font-weight-bold"
        style={{ color: "var(--REB-black)" }}
      >
        Payment Type
      </Form.Label>
      <Select
        value={TxtType}
        // isMulti
        //styles={styles}
        styles={paymentSelectStyle}
        menuPortalTarget={document.body}
        name="colors"
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={setTxtType}
        options={PAYMENT_TYPES}
      />
    </Col>
  );
  const TxtJPaymentDeadline = (
    <Col xs={12} sm={12} md={4} lg={4} className="px-1 mb-2">
      <Form.Label
        className="font-weight-bold"
        style={{ color: "var(--REB-black)" }}
      >
        Payment Deadline
      </Form.Label>
      <FormControl
        type="date"
        value={TxtDeadline}
        onChange={(x) => {
          setTxtDeadline(x.currentTarget.value);
        }}
      />
    </Col>
  );
  const TxtJCheckNumber = (
    <Col xs={12} sm={12} md={4} lg={4} className="px-1 mb-2">
      <Form.Label
        className="font-weight-bold"
        style={{ color: "var(--REB-black)" }}
      >
        Check Number
      </Form.Label>
      <FormControl
        type="text"
        //style={{ textTransform: "uppercase" }}
        placeholder="Check Number"
        name="TxtChkNumber"
        value={TxtChkNumber}
        onChange={(e) => setTxtChkNumber(e.currentTarget.value)}
        className=""
      />
    </Col>
  );
  const TxtJPaymentNotes = (
    <Col xs={12} sm={12} md={4} lg={4} className="px-1 mb-2">
      <Form.Label
        className="font-weight-bold"
        style={{ color: "var(--REB-black)" }}
      >
        Payment Notes
      </Form.Label>
      <FormControl
        type="text"
        //style={{ textTransform: "uppercase" }}
        placeholder="Payment Notes"
        name="TxtPaymentNotes"
        value={TxtPaymentNotes}
        onChange={(e) => setTxtPaymentNotes(e.currentTarget.value)}
        className=""
      />
    </Col>
  );
  const TxtJPaymentBankName = (
    <Col xs={12} sm={12} md={4} lg={4} className="px-1 mb-2">
      <Form.Label
        className="font-weight-bold"
        style={{ color: "var(--REB-black)" }}
      >
        Payment Bank Name
      </Form.Label>
      <FormControl
        type="text"
        //style={{ textTransform: "uppercase" }}
        placeholder="Payment Bank Account Name"
        name="TxtPaymentBankName"
        value={TxtPaymentBankName}
        onChange={(e) => setTxtPaymentBankName(e.currentTarget.value)}
        className=""
      />
    </Col>
  );
  const TxtJBankAccountNumber = (
    <Col xs={12} sm={12} md={4} lg={4} className="px-1 mb-2">
      <Form.Label
        className="font-weight-bold"
        style={{ color: "var(--REB-black)" }}
      >
        Payment Bank Account Number
      </Form.Label>
      <FormControl
        type="text"
        //style={{ textTransform: "uppercase" }}
        placeholder="Payment Bank Account Number"
        name="TxtPaymentBankAccount"
        value={TxtPaymentBankAccount}
        onChange={(e) => setTxtPaymentBankAccount(e.currentTarget.value)}
        className=""
      />
    </Col>
  );

  return (
    <div
      className="p-0 my-3 pb-1"
      style={{ borderRadius: "5px", backgroundColor: "var(--REB-body)" }}
    >
      <Row
        className="px-3 py-2 "
        style={{
          backgroundColor: "var(--REB-primary)",
          borderTopRightRadius: "5px",
          borderTopLeftRadius: "5px",
        }}
        noGutters
      >
        <Col style={{ alignSelf: "center" }}>
          <h4
            className="m-0"
            style={{
              fontWeight: "bolder",
              color: "var(--REB-white)",
            }}
          >
            <i
              className="icofont-money pr-1"
              style={{ fontSize: "1.5rem" }}
            ></i>
            Invoice Payments
          </h4>
        </Col>
        {showSetInvoiceForDelivery &&
          invoiceStatus === "Pending Client Payment" && (
            <Col>
              <Button
                className="animate__pulse animate__animated animate__infinite	infinite"
                onClick={() => {
                  props.state.handleActionChange({
                    target: {
                      //value: updatedInvoice,
                      name: "btnSetInvoiceForDelivery",
                    },
                  });
                }}
                style={{
                  backgroundColor: "var(--REB-yellow)",
                  color: "var(--REB-black)",
                }}
              >
                <i
                  className="icofont-delivery-time pr-1"
                  style={{ fontSize: "1.2rem" }}
                ></i>
                Set Invoice For Delivery
              </Button>
            </Col>
          )}
        {/* {Number(props.state.invoiceTotalAmountDue) === 0 && (
          <Col>
            <Button
              className="animate__pulse animate__animated animate__infinite	infinite"
              onClick={async () => {
                const SELECTED_INVOICE = props.state.selectedRowInvoice;
                let _AmountDue = SELECTED_INVOICE.content
                  .reduce((a, c) => {
                    return a + c.price * c.itemQty;
                  }, 0)
                  .toFixed(2);
                let _TotalQtyItems = SELECTED_INVOICE.content
                  .reduce((a, c) => {
                    return a + c.itemQty;
                  }, 0)
                  .toFixed(2);

                let settingTaxPercentage = 0;
                let _VATSales = 0;
                let _VAT = 0;
                if (props.state?.settings.valueAddedTax) {
                  settingTaxPercentage =
                    Number(props.state?.settings.valueAddedTax) / 100 + 1;
                  _VATSales = Number(_AmountDue / settingTaxPercentage).toFixed(
                    2
                  );
                  _VAT = Number(_AmountDue - _VATSales).toFixed(2);
                }

                const PDFJS = require("pdfjs-dist/webpack");

                const readFileData = (file) => {
                  return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      resolve(e.target.result);
                    };
                    reader.onerror = (err) => {
                      reject(err);
                    };
                    reader.readAsDataURL(file);
                  });
                };

                //param: file -> the input file (e.g. event.target.files[0])
                //return: images -> an array of images encoded in base64
                const convertPdfToImages = async (file) => {
                  const images = [];
                  const data = await readFileData(file);
                  const pdf = await PDFJS.getDocument(data).promise;
                  const canvas = document.createElement("canvas");
                  for (let i = 0; i < pdf.numPages; i++) {
                    const page = await pdf.getPage(i + 1);
                    const viewport = page.getViewport({ scale: 2.5 });
                    const context = canvas.getContext("2d");

                    canvas.height = viewport.height;
                    canvas.width = viewport.width;
                    await page.render({
                      canvasContext: context,
                      viewport: viewport,
                    }).promise;
                    images.push(canvas.toDataURL());
                  }
                  canvas.remove();
                  return images;
                };

                const blob = await pdf(
                  Receipt.ReceiptPDF(
                    SELECTED_INVOICE.content,
                    SELECTED_INVOICE.dateAdded,
                    _AmountDue,
                    SELECTED_INVOICE.cashTendered - _AmountDue,
                    _TotalQtyItems,
                    _VAT,
                    _VATSales,
                    SELECTED_INVOICE.client,
                    SELECTED_INVOICE.incharge,
                    SELECTED_INVOICE.cashTendered,
                    SELECTED_INVOICE,
                    true
                  )
                ).toBlob();

                const pngImg = await convertPdfToImages(blob);

                new Promise((resolve, reject) => {
                  dispatch({
                    type: "USER_GET_REQUEST",
                    reqType: "post",
                    data: { data: pngImg },
                    payload: "global/emitPrint",
                    resolve: resolve,
                    reject: reject,
                  });
                }).then();
              }}
              style={{
                backgroundColor: "var(--REB-yellow)",
                color: "var(--REB-black)",
              }}
            >
              <i
                className="icofont-delivery-time pr-1"
                style={{ fontSize: "1.2rem" }}
              ></i>
              Print Sales Receipt
            </Button>
          </Col>
        )} */}
        {Number(props.state.invoiceTotalAmountDue) === 0 && (
          <Col>
            <Button
              className="animate__pulse animate__animated animate__infinite	infinite"
              onClick={async () => {
                if (
                  props.state.permissions.includes("perm-ui-chkSalesReceipt")
                ) {
                  // REDO THIS FUNCTION
                  const SELECTED_INVOICE = props.state.selectedRowInvoice;
                  let _AmountDue = SELECTED_INVOICE.content
                    .reduce((a, c) => {
                      return a + c.price * c.itemQty;
                    }, 0)
                    .toFixed(2);
                  let _TotalQtyItems = SELECTED_INVOICE.content
                    .reduce((a, c) => {
                      return a + c.itemQty;
                    }, 0)
                    .toFixed(2);

                  let settingTaxPercentage = 0;
                  let _VATSales = 0;
                  let _VAT = 0;
                  if (props.state?.settings.valueAddedTax) {
                    settingTaxPercentage =
                      Number(props.state?.settings.valueAddedTax) / 100 + 1;
                    _VATSales = Number(
                      _AmountDue / settingTaxPercentage
                    ).toFixed(2);
                    _VAT = Number(_AmountDue - _VATSales).toFixed(2);
                  }

                  dispatch({
                    type: "PDF_MODAL_TOGGLE",
                    doc: Receipt.ReceiptPDF(
                      SELECTED_INVOICE.content,
                      SELECTED_INVOICE.dateAdded,
                      _AmountDue,
                      SELECTED_INVOICE.cashTendered - _AmountDue,
                      _TotalQtyItems,
                      _VAT,
                      _VATSales,
                      SELECTED_INVOICE.client,
                      SELECTED_INVOICE.incharge,
                      SELECTED_INVOICE.cashTendered,
                      SELECTED_INVOICE,
                      true
                    ),
                  });
                } else {
                  props.state.dispatch({
                    type: "MODAL_SHOW",
                    payload: {
                      title: "No Permission",
                      desc: `You dont have access to view sales receipts`,
                      isYesNo: false,
                    },
                  });
                }
              }}
              style={{
                backgroundColor: "var(--REB-yellow)",
                color: "var(--REB-black)",
              }}
            >
              <i
                className="icofont-delivery-time pr-1"
                style={{ fontSize: "1.2rem" }}
              ></i>
              View Sales Receipt
            </Button>
          </Col>
        )}
      </Row>
      {!showSetInvoiceForDelivery && (
        <p
          className="px-4 m-0 pt-2"
          style={{ fontSize: "0.8rem", color: "var(--REB-warning)" }}
        >
          * System will only allow generation of DR if there is one SUCCESSFUL
          payment.
        </p>
      )}
      <Row
        style={
          {
            // backgroundColor: "var(--REB-yellow)",
            // borderRadius: "5px 5px 0px 0px",
          }
        }
        className="px-3 pt-2 pb-1"
        noGutters
      >
        <Col xs={6} sm={6} md={3} lg={3} className="px-1">
          <Form.Label
            className="font-weight-bold"
            style={{ color: "var(--REB-black)" }}
          >
            Total Amount Due
          </Form.Label>
          <FormControl
            as={NumericFormat}
            inputMode="numeric"
            thousandSeparator={true}
            fixedDecimalScale={true}
            decimalScale={2}
            prefix={"₱ "}
            allowNegative={false}
            // style={{ flex: 2 }}
            // placeholder={
            //   props.isItem
            //     ? `SRP: ₱ ${
            //         props.state.selContentItems.value !== ""
            //           ? props.state.selContentItems.value.price
            //           : ""
            //       } `
            //     : `SERVICE COST`
            // }
            name="txtAmountPaid"
            value={Number(props.state.invoiceTotalAmountDue)}
            // onValueChange={(x) => {
            //   props.state.inputChange({
            //     target: {
            //       value: x.value,
            //       name: "txtAmountPaid",
            //       // placeholder: props.state.txtPaidAmount.placeholder,
            //       // className: props.state.txtPaidAmount.className,
            //     },
            //   });
            // }}
            className={props.state.txtAmountPaid.className}
            disabled
            //readOnly={props.state.txtAmountPaid.readOnly}
          />
        </Col>
        <Col xs={6} sm={6} md={3} lg={3} className="px-1">
          <Form.Label
            className="font-weight-bold"
            style={{ color: "var(--REB-black)" }}
          >
            Invoice Total
          </Form.Label>
          <FormControl
            as={NumericFormat}
            inputMode="numeric"
            thousandSeparator={true}
            fixedDecimalScale={true}
            decimalScale={2}
            prefix={"₱ "}
            allowNegative={false}
            defaultValue={Number(props.state.grandTotal)}
            disabled
          />
        </Col>
        <Col xs={12} sm={12} md={3} lg={3} className="mt-2">
          {operation === "VIEW" &&
            Number(props.state.invoiceTotalAmountDue) > 0 && (
              <div className="h-100" style={{ display: "flex" }}>
                <Button
                  className="h-100 w-100 mx-1"
                  style={{
                    backgroundColor: "var(--REB-green)",
                    fontSize: "0.8rem",
                    fontWeight: "bolder",
                  }}
                  onClick={() => {
                    if (
                      props.state.permissions.includes(
                        "perm-ui-invoicePaymentAccess-view-write"
                      )
                    ) {
                      setOperation("ADD");
                      setBillType(BILL_TYPE.BILLING);
                      setTxtDate(moment().format("YYYY-MM-DD"));
                      setTxtAmount(Number(props.state.invoiceTotalAmountDue));
                    } else {
                      props.state.dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "No Permission",
                          desc: `You dont have access to add invoice payments`,
                          isYesNo: false,
                        },
                      });
                    }
                  }}
                >
                  Add Payment
                </Button>
                {clientPermissions.includes(
                  "perm-ui-chkAllowChargeInvoice"
                ) && (
                  <>
                   
                    <Button
                      className="h-100 w-100 mx-1"
                      style={{
                        backgroundColor: "var(--REB-green)",
                        fontSize: "0.8rem",
                        fontWeight: "bolder",
                      }}
                      onClick={() => {
                        if (
                          props.state.permissions.includes(
                            "perm-ui-invoicePaymentAccess-view-write"
                          )
                        ) {
                          setOperation("ADD");
                          setBillType(BILL_TYPE.CHARGE_BILL);
                          setTxtDate(moment().format("YYYY-MM-DD"));
                          setTxtAmount(
                            Number(props.state.invoiceTotalAmountDue)
                          );
                        } else {
                          props.state.dispatch({
                            type: "MODAL_SHOW",
                            payload: {
                              title: "No Permission",
                              desc: `You dont have access to add charge / billing invoice`,
                              isYesNo: false,
                            },
                          });
                        }
                      }}
                    >
                      Add Charge Invoice
                    </Button>
                  </>
                )}
              </div>
            )}
          {(operation === "ADD" || operation === "EDIT") && (
            <>
              <Button
                block
                style={{ backgroundColor: "var(--REB-yellow)", color: "black" }}
                onClick={() => {
                  setTxtAmount(null);
                  setTxtDate(null);
                  setTxtType(null);
                  setOperation("VIEW");
                }}
              >
                Cancel
              </Button>
            </>
          )}
          {operation === "ADD" && (
            <>
              <Button
                block
                style={{
                  backgroundColor: "var(--REB-green)",
                  color: "var(--REB-white)",
                }}
                onClick={async () => {
                  validateUserInputs(async () => {
                    setOperation("VIEW");
                    const uid = filters.uuidv4();
                    const inventoryId =
                      Number(props.state.selInventory.value.id) === 1
                        ? null
                        : Number(props.state.selInventory.value.id);
                    let isPartialPayment = true;
                    //full payment
                    if (
                      Number(TxtAmount) !==
                      Number(props.state.invoiceTotalAmountDue)
                    ) {
                      isPartialPayment = true;
                    } else {
                      isPartialPayment = false;
                    }
                    // ===== ACCOUNTS RECEIVABLE CREDIT =========
                    // const actsReceivable = await TransactionsAPI.getAccountByName(
                    //   dispatch,
                    //   "Accounts Receivable Invoice",
                    //   props.state.selInventory.value.id
                    // );

                    // let actsReceivableTransaction = null;

                    // if (isPartialPayment) {

                    // } else {
                    //   const accountReceivableValue = Number(TxtAmount) * -1;
                    //   actsReceivableTransaction = {
                    //     description: `Full Payment - ${selectedRowInvoice.client.company}`,
                    //     paymentDate: TxtDate,
                    //     transStatus: "finished",
                    //     paymentType: "cash",
                    //     isEntry: 1,
                    //     amountValue: Number(accountReceivableValue),
                    //     grossAmount: Number(accountReceivableValue),
                    //     netAmount: Number(accountReceivableValue),
                    //     taxAmount: 0,
                    //     uid: uid,
                    //     transType: `${isPartialPayment ? "debit" : "credit"}`,
                    //     clientId: selectedRowInvoice.clientId,
                    //     accountId: SelInvoiceActsReceivable.id,
                    //     orderId: selectedRowInvoice.id,
                    //     inventoryId: inventoryId,
                    //     notes: TxtPaymentNotes,
                    //     checkInfo1: TxtChkNumber,
                    //     isDownPayment: Number(ChkDownPayment),
                    //   };
                    //   console.log(paymentData);
                    //   if (paymentData.length > 0) {
                    //     await TransactionsAPI.createTransaction(
                    //       dispatch,
                    //       { transaction: actsReceivableTransaction },

                    //       (dat) => {
                    //         console.log("CREATED TRANSACTION ===>", dat);
                    //       }
                    //     );
                    //   }
                    // }
                    const accountReceivableValue = Number(TxtAmount) * -1;

                    const actsReceivableTransaction = {
                      description: `${
                        isPartialPayment
                          ? "Partial Payment -"
                          : "Full Payment -"
                      } ${selectedRowInvoice.client.company}`,
                      paymentDate: TxtDate,
                      transStatus: "finished",
                      paymentType: "cash",
                      isEntry: 1,
                      uid: uid,
                      amountValue: Number(accountReceivableValue),
                      grossAmount: Number(accountReceivableValue),
                      netAmount: Number(accountReceivableValue),
                      taxAmount: 0,
                      transType: `credit`,
                      clientId: selectedRowInvoice.clientId,
                      accountId: SelInvoiceActsReceivable.id,
                      orderId: selectedRowInvoice.id,
                      inventoryId: inventoryId,
                      notes: TxtPaymentNotes,
                      checkInfo1: TxtChkNumber,
                      isDownPayment: Number(ChkDownPayment),
                    };
                    await TransactionsAPI.createTransaction(
                      dispatch,
                      { transaction: actsReceivableTransaction },
                      (dat) => {
                        console.log("CREATED TRANSACTION ===>", dat);
                      }
                    );
                    console.log(
                      "PRE-POST TRANSACTION ===>",
                      actsReceivableTransaction
                    );

                    // // ===== INVENTORY ASSET DEBIT =====
                    // // const inventoryAssetAccount = await TransactionsAPI.getAccountByName(
                    // //   dispatch,
                    // //   "Accounts Receivable Invoice",
                    // //   props.state.selInventory.value.id
                    // // );
                    // const inventoryAssetValue =
                    //   Number(props.state.grandTotal) * -1;
                    // actsReceivableTransaction = {
                    //   description: `Inventory Entry - ${selectedRowInvoice.client.company}`,
                    //   paymentDate: TxtDate,
                    //   transStatus: "finished",
                    //   paymentType: "cash",
                    //   isEntry: 1,
                    //   isInventoryAssetDeductEntry: 1,
                    //   amountValue: inventoryAssetValue,
                    //   grossAmount: inventoryAssetValue,
                    //   netAmount: inventoryAssetValue,
                    //   taxAmount: 0,
                    //   uid: uid,
                    //   transType: `credit`,
                    //   clientId: selectedRowInvoice.clientId,
                    //   accountId: SelInvoiceInventoryAccount.id,
                    //   orderId: selectedRowInvoice.id,
                    //   inventoryId: inventoryId,
                    //   notes: TxtPaymentNotes,
                    //   checkInfo1: TxtChkNumber,
                    //   isDownPayment: Number(ChkDownPayment),
                    // };

                    // // Execute only if no isInventoryAssetDeductEntry can be found in payment history
                    // let inventoryDeduct = false;
                    // inventoryDeduct = paymentData.find(
                    //   (x) => x.isInventoryAssetDeductEntry === 1
                    // );
                    // if (!inventoryDeduct) {
                    //   await TransactionsAPI.createTransaction(
                    //     dispatch,

                    //     { transaction: actsReceivableTransaction },
                    //     (dat) => {
                    //       console.log("CREATED TRANSACTION ===>", dat);
                    //     }
                    //   );
                    // }

                    // ===== REVENUE CREDIT =========

                    // const invoiceSales = await TransactionsAPI.getAccountByName(
                    //   dispatch,
                    //   "Invoice Sales",
                    //   props.state.selInventory.value.id
                    // );

                    if (SelInvoiceIncome.id) {
                      let invoiceSalesTransaction = null;

                      if (BillType === BILL_TYPE.CHARGE_BILL) {
                        invoiceSalesTransaction = {
                          description: `${
                            isPartialPayment
                              ? "Partial Payment"
                              : "Full Payment"
                          } - ${selectedRowInvoice.client.company}`,
                          paymentDeadline: TxtDeadline,
                          transStatus: "pending-admin-approval",
                          paymentType: TxtType?.value,
                          amountValue: Number(TxtAmount),
                          grossAmount: Number(TxtAmount),
                          netAmount: Number(TxtAmount),
                          taxAmount: 0,
                          uid: uid,
                          isEntry: 0,
                          isInventoryAssetDeductEntry: 1,
                          transType: "credit",
                          clientId: selectedRowInvoice.clientId,
                          accountId: SelInvoiceIncome.id,
                          orderId: selectedRowInvoice.id,
                          inventoryId: inventoryId,
                          notes: TxtPaymentNotes,
                        };
                      } else {
                        invoiceSalesTransaction = {
                          description: `${
                            isPartialPayment
                              ? "Partial Payment"
                              : "Full Payment"
                          } - ${selectedRowInvoice.client.company}`,
                          paymentDate: TxtDate,
                          paymentDeadline: TxtDeadline,
                          // transStatus:
                          //   BillType === BILL_TYPE.BILLING
                          //     ? "finished"
                          //     : "pending-admin-approval",
                          transStatus: "pending-payment",
                          paymentType: TxtType.value,
                          amountValue: Number(TxtAmount),
                          grossAmount: Number(TxtAmount),
                          netAmount: Number(TxtAmount),
                          taxAmount: 0,
                          uid: uid,
                          isEntry: 0,
                          isInventoryAssetDeductEntry: 1,
                          transType: "credit",
                          clientId: selectedRowInvoice.clientId,
                          accountId: SelInvoiceIncome.id,
                          orderId: selectedRowInvoice.id,
                          inventoryId: inventoryId,
                          notes: TxtPaymentNotes,
                          checkInfo1: TxtChkNumber,
                          paymentBankName: TxtPaymentBankName,
                          paymentBankNumber: TxtPaymentBankAccount,
                          isDownPayment: Number(ChkDownPayment),
                        };
                      }

                      console.log(
                        "PRE-POST TRANSACTION ===>",
                        invoiceSalesTransaction
                      );

                      await TransactionsAPI.createTransaction(
                        dispatch,
                        { transaction: invoiceSalesTransaction },
                        (dat) => {
                          console.log("CREATED TRANSACTION ===>", dat);
                        }
                      );
                    }
                    // ===== INVENTORY ASSET CREDIT =========

                    // const updatedInvoice = await InvoiceAPI.addPayment(
                    //   dispatch,
                    //   selectedInvoiceID,
                    //   {
                    //     amount: Number(TxtAmount),
                    //     date: TxtDate,
                    //     type: TxtType.value,
                    //     isDownPayment: Number(ChkDownPayment),
                    //     paymentNotes: TxtPaymentNotes,
                    //     chequeDetails: TxtChkNumber,
                    //   },
                    //   () => {
                    //     dispatch({
                    //       type: "TOAST_ADD",
                    //       payload: {
                    //         title: `Invoice Payment Added`,
                    //       },
                    //     });
                    //   }
                    // );
                    //===== Update Selected Invoice =====

                    const updatedInvoice = await InvoiceAPI.getInvoice(
                      dispatch,
                      selectedRowInvoice.id
                    );
                    console.log("UPDATED INVOICE ===> ", updatedInvoice);
                    props.state.handleActionChange({
                      target: {
                        value: updatedInvoice,
                        name: "refreshAndReplaceSelected",
                      },
                    });
                    resetUserInputs();
                  });
                }}
              >
                Save Payment
              </Button>
            </>
          )}
          {operation === "EDIT" && (
            <>
              <Button
                block
                style={{
                  backgroundColor: "var(--REB-green)",
                  color: "var(--REB-white)",
                }}
                onClick={async () => {
                  validateUserInputs(async () => {
                    setOperation("VIEW");
                    const uid = filters.uuidv4();
                    const inventoryId =
                      Number(props.state.selInventory.value.id) === 1
                        ? null
                        : Number(props.state.selInventory.value.id);
                    let isPartialPayment = true;
                    //full payment
                    if (
                      Number(TxtAmount) !==
                      Number(props.state.invoiceTotalAmountDue)
                    ) {
                      isPartialPayment = true;
                    } else {
                      isPartialPayment = false;
                    }

                    const accountReceivableValue = Number(TxtAmount) * -1;

                    const actsReceivableTransaction = {
                      description: `${
                        isPartialPayment
                          ? "Partial Payment -"
                          : "Full Payment -"
                      } ${selectedRowInvoice.client.company}`,
                      paymentDate: TxtDate,
                      transStatus: "finished",
                      paymentType: "cash",
                      isEntry: 1,
                      uid: uid,
                      amountValue: Number(accountReceivableValue),
                      grossAmount: Number(accountReceivableValue),
                      netAmount: Number(accountReceivableValue),
                      taxAmount: 0,
                      transType: `credit`,
                      clientId: selectedRowInvoice.clientId,
                      accountId: SelInvoiceActsReceivable.id,
                      orderId: selectedRowInvoice.id,
                      inventoryId: inventoryId,
                      notes: TxtPaymentNotes,
                      checkInfo1: TxtChkNumber,
                      isDownPayment: Number(ChkDownPayment),
                    };
                    await TransactionsAPI.createTransaction(
                      dispatch,
                      { transaction: actsReceivableTransaction },
                      (dat) => {
                        console.log("CREATED TRANSACTION ===>", dat);
                      }
                    );
                    console.log(
                      "PRE-POST TRANSACTION ===>",
                      actsReceivableTransaction
                    );

                    if (SelInvoiceIncome.id) {
                      let invoiceSalesTransaction = null;

                      if (BillType === BILL_TYPE.CHARGE_BILL) {
                        invoiceSalesTransaction = {
                          description: `${
                            isPartialPayment
                              ? "Partial Payment"
                              : "Full Payment"
                          } - ${selectedRowInvoice.client.company}`,
                          paymentDeadline: TxtDeadline,
                          transStatus: "pending-admin-approval",
                          paymentType: TxtType.value,
                          amountValue: Number(TxtAmount),
                          grossAmount: Number(TxtAmount),
                          netAmount: Number(TxtAmount),
                          taxAmount: 0,
                          uid: uid,
                          isEntry: 0,
                          isInventoryAssetDeductEntry: 1,
                          transType: "credit",
                          clientId: selectedRowInvoice.clientId,
                          accountId: SelInvoiceIncome.id,
                          orderId: selectedRowInvoice.id,
                          inventoryId: inventoryId,
                          notes: TxtPaymentNotes,
                        };
                      } else {
                        invoiceSalesTransaction = {
                          description: `${
                            isPartialPayment
                              ? "Partial Payment"
                              : "Full Payment"
                          } - ${selectedRowInvoice.client.company}`,
                          paymentDate: TxtDate,
                          paymentDeadline: TxtDeadline,
                          transStatus:
                            BillType === BILL_TYPE.BILLING
                              ? "finished"
                              : "pending-admin-approval",
                          paymentType: TxtType.value,
                          amountValue: Number(TxtAmount),
                          grossAmount: Number(TxtAmount),
                          netAmount: Number(TxtAmount),
                          taxAmount: 0,
                          uid: uid,
                          isEntry: 0,
                          isInventoryAssetDeductEntry: 1,
                          transType: "credit",
                          clientId: selectedRowInvoice.clientId,
                          accountId: SelInvoiceIncome.id,
                          orderId: selectedRowInvoice.id,
                          inventoryId: inventoryId,
                          notes: TxtPaymentNotes,
                          checkInfo1: TxtChkNumber,
                          paymentBankName: TxtPaymentBankName,
                          paymentBankNumber: TxtPaymentBankAccount,
                          isDownPayment: Number(ChkDownPayment),
                        };
                      }

                      console.log(
                        "PRE-POST TRANSACTION ===>",
                        invoiceSalesTransaction
                      );

                      await TransactionsAPI.createTransaction(
                        dispatch,
                        { transaction: invoiceSalesTransaction },
                        (dat) => {
                          console.log("CREATED TRANSACTION ===>", dat);
                        }
                      );
                    }

                    const updatedInvoice = await InvoiceAPI.getInvoice(
                      dispatch,
                      selectedRowInvoice.id
                    );
                    console.log("UPDATED INVOICE ===> ", updatedInvoice);
                    props.state.handleActionChange({
                      target: {
                        value: updatedInvoice,
                        name: "refreshAndReplaceSelected",
                      },
                    });
                    resetUserInputs();
                  });
                }}
              >
                Save Modified Payment
              </Button>
            </>
          )}
        </Col>
      </Row>
      {operation === "ADD" && (
        <Row
          className={
            operation === "ADD"
              ? "mt-1 mb-2 mx-2 animate__animated animate__fadeInRight"
              : "mt-1 mb-2 mx-2 animate__animated animate__fadeOutRight"
          }
          noGutters
        >
          {BillType === BILL_TYPE.BILLING && (
            <div className="px-2">
              <h5>CREATE PENDING PAYMENT</h5>
            </div>
          )}
          {BillType === BILL_TYPE.CHARGE_BILL && (
            <div className="px-2">
              <h5>CREATE BILLING / CHARGE INVOICE</h5>
            </div>
          )}

          <Col xs={12} className="p-3" style={{ alignSelf: "center" }}>
            {paymentData.length === 0 && (
              <>
                <style>
                  {`
                    @media (max-width: 991px) {
                    }
                    @media (min-width: 992px) {
                    }
                    .ChkDownPayment > input {
                      transform: scale(2);
                    }
                    .ChkDownPayment > label {
                      padding-left: 10px;
                    }
                  `}
                </style>
                <Form.Check
                  checked={ChkDownPayment}
                  name="ChkDownPayment"
                  className="ChkDownPayment"
                  label="Down Payment"
                  onChange={(e) => {
                    setChkDownPayment(!ChkDownPayment);
                  }}
                />
              </>
            )}
          </Col>

          {/* {console.log(TxtType)} */}
          <Row className="mt-2 w-100" noGutters>
            {TxtJAmountPaid}
            {TxtJPaymentDeadline}
            {TxtJPaymentType}
            {/* if new chargeinvoice, dont show the following controls below */}

            {(() => {
              let showControls = true;
              if (BillType === BILL_TYPE.CHARGE_BILL && operation === "ADD")
                showControls = false;
              if (showControls) {
                return (
                  <>
                    {TxtJPaymentDate}
                    {/* {TxtJPaymentType} */}
                    {TxtJPaymentNotes}

                    {TxtType?.value !== "cash" && (
                      <>
                        {TxtJCheckNumber}

                        {TxtJPaymentBankName}
                        {TxtJBankAccountNumber}
                      </>
                    )}

                    {/*  */}
                    <Col xs={4} className="px-1"></Col>
                  </>
                );
              }
            })()}
          </Row>
          <Row className="mt-2 w-100" noGutters>
            <Col xs={12} sm={12} md={4} lg={4} className="px-1 mb-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: `${
                    SelInvoiceIncome
                      ? "var(--REB-primary)"
                      : "var(--REB-warning)"
                  }`,
                  borderRadius: "5px",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    // backgroundColor: "var(--REB-primary)",
                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}
                >
                  Invoice Income Account
                </p>
                <Select
                  value={SelInvoiceIncome}
                  // isMulti
                  //styles={styles}
                  styles={paymentSelectStyle}
                  menuPortalTarget={document.body}
                  name="colors"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={props.state.accounts.revenues}
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.id}
                  onChange={(x, name) => {
                    setSelInvoiceIncome(x);
                  }}
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} className="px-1 mb-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: `${
                    SelInvoiceInventoryAccount
                      ? "var(--REB-primary)"
                      : "var(--REB-warning)"
                  }`,
                  borderRadius: "5px",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    // backgroundColor: "var(--REB-primary)",
                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}
                >
                  Inventory Account
                </p>
                <Select
                  value={SelInvoiceInventoryAccount}
                  // isMulti
                  //styles={styles}
                  styles={paymentSelectStyle}
                  menuPortalTarget={document.body}
                  name="colors"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={props.state.accounts.assets}
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.id}
                  onChange={(x, name) => {
                    setSelInvoiceInventoryAccount(x);
                  }}
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} className="px-1 mb-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: `${
                    SelInvoiceActsReceivable
                      ? "var(--REB-primary)"
                      : "var(--REB-warning)"
                  }`,
                  borderRadius: "5px",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    // backgroundColor: "var(--REB-primary)",
                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}
                >
                  Receivable Account
                </p>
                <Select
                  value={SelInvoiceActsReceivable}
                  // isMulti
                  //styles={styles}
                  styles={paymentSelectStyle}
                  menuPortalTarget={document.body}
                  name="colors"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={props.state.accounts.assets}
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.id}
                  onChange={(x, name) => {
                    setSelInvoiceActsReceivable(x);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Row>
      )}
      {paymentData.map((val, index) => {
        // console.log(val);
        return (
          <Row
            style={{
              ...(val.transStatus === "finished"
                ? {
                    backgroundColor: "#AFD9AD",
                  }
                : null),
              ...(val.transStatus === "admin-denied"
                ? {
                    backgroundColor: "#D98880",
                  }
                : null),
              ...(val.transStatus === "pending-admin-approval"
                ? {
                    backgroundColor: "#D7BDE2",
                  }
                : null),
              ...(val.transStatus === "processing"
                ? {
                    backgroundColor: "#81DDE8",
                  }
                : null),
              ...(val.transStatus === "pending-payment"
                ? {
                    backgroundColor: "#81DDE8",
                  }
                : null),
              borderRadius: "5px",
              border: "1px solid rgb(206, 212, 218)",
            }}
            className="mx-3 my-2 py-2"
            noGutters
            key={filters.uuidv4()}
          >
            <Col
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{
                alignSelf: "center",
                display: "flex",
                flexDirection: "column",
              }}
              className="px-2"
            >
              <OverlayTrigger
                placement={"bottom"}
                overlay={
                  <Tooltip id={`actor-tooltip-add`}>Remove Payment</Tooltip>
                }
              >
                <Button
                  className="my-1"
                  onClick={() => {
                    let isAllowed = false;

                    const allowed = ["Admin", "Executive", "Manager"];
                    isAllowed = userType.some((el) => allowed.includes(el));

                    if (
                      permissions.includes(
                        "perm-ui-invoicePaymentAccess-view-write-manage"
                      )
                    ) {
                      isAllowed = true;
                    }

                    isAllowed
                      ? dispatch({
                          type: "MODAL_SHOW",
                          payload: {
                            title: "Confirmation",
                            desc: `Are you sure you want to delete this payment ?`,
                            isYesNo: true,
                            cb: async (e) => {
                              await TransactionsAPI.deleteTransactionByUid(
                                dispatch,
                                val.uid,

                                () => {
                                  dispatch({
                                    type: "TOAST_ADD",
                                    payload: {
                                      title: `Invoice Payment Removed`,
                                    },
                                  });
                                }
                              );
                              const updatedInvoice =
                                await InvoiceAPI.getInvoice(
                                  dispatch,
                                  selectedRowInvoice.id
                                );
                              console.log(
                                "UPDATED INVOICE ===> ",
                                updatedInvoice
                              );
                              props.state.handleActionChange({
                                target: {
                                  value: updatedInvoice,
                                  name: "refreshAndReplaceSelected",
                                },
                              });
                            },
                          },
                        })
                      : dispatch({
                          type: "MODAL_SHOW",
                          payload: {
                            title: "No Permission",
                            desc: `You are not allowed to remove payments`,
                            isYesNo: false,
                          },
                        });
                  }}
                  style={{
                    backgroundColor: "var(--REB-warning)",
                    color: "var(--REB-white)",
                  }}
                >
                  Remove Payment
                  <i
                    style={{ color: "var(--REB-white)" }}
                    className="icofont-ui-close pl-2"
                  ></i>
                </Button>
              </OverlayTrigger>
              {/* <OverlayTrigger
                placement={"bottom"}
                overlay={
                  <Tooltip id={`actor-tooltip-add`}>Modify Payment</Tooltip>
                }
              >
                <Button
                  className="my-1"
                  onClick={() => {
                    let isAllowed = false;

                    const allowed = ["Admin", "Executive", "Manager"];
                    isAllowed = userType.some((el) => allowed.includes(el));

                    isAllowed
                      ? dispatch({
                          type: "MODAL_SHOW",
                          payload: {
                            title: "Confirmation",
                            desc: `Are you sure you want to modify this payment ?`,
                            isYesNo: true,
                            cb: async (e) => {
                              setOperation("EDIT");
                            },
                          },
                        })
                      : dispatch({
                          type: "MODAL_SHOW",
                          payload: {
                            title: "No Permission",
                            desc: `You are not allowed to modify payments`,
                            isYesNo: false,
                          },
                        });
                  }}
                  style={{
                    backgroundColor: "var(--REB-tertiary)",
                    color: "var(--REB-white)",
                  }}
                >
                  Modify Payment
                  <i
                    style={{ color: "var(--REB-white)" }}
                    className="icofont-edit pl-2"
                  ></i>
                </Button>
              </OverlayTrigger> */}
              {val?.transStatus === "finished" && (
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Tooltip id={`actor-tooltip-add`}>
                      Export / Print Payment
                    </Tooltip>
                  }
                >
                  <Button
                    className="my-1"
                    onClick={async () => {
                      let isAllowed = false;

                      const allowed = ["Admin", "Executive", "Manager"];
                      isAllowed = userType.some((el) => allowed.includes(el));

                      if (
                        permissions.includes(
                          "perm-ui-chkAcknowledgementReceipt"
                        )
                      ) {
                        isAllowed = true;
                      }

                      if (!isAllowed) {
                        dispatch({
                          type: "MODAL_SHOW",
                          payload: {
                            title: "No Permission",
                            desc: `You are not allowed to print acknowledgement receipts`,
                            isYesNo: false,
                          },
                        });
                      } else {
                        const fetchedTrans =
                          await TransactionsAPI.getTransaction(
                            dispatch,
                            val.id
                          );
                        let voucherType = "Cash";
                        if (fetchedTrans.paymentType === "Cash") {
                          voucherType = "Cash";
                        } else {
                          voucherType = "Check";
                        }
                        let perChunk = 8; // items per chunk

                        let result = fetchedTrans.content.reduce(
                          (resultArray, item, index) => {
                            const chunkIndex = Math.floor(index / perChunk);
                            if (!resultArray[chunkIndex]) {
                              resultArray[chunkIndex] = []; // start a new chunk
                            }
                            resultArray[chunkIndex].push({
                              ...item,
                              index: index + 1,
                            });
                            return resultArray;
                          },
                          []
                        );
                        for (let index = 0; index < result.length; index++) {
                          const element = result[index];
                          result[index] = element.sort(function (a, b) {
                            return a.partIndex - b.partIndex;
                          });
                        }

                        if (fetchedTrans) {
                          const invoicePayment =
                            await misc.calculateInvoicePayment(
                              dispatch,
                              fetchedTrans.id
                            );

                          dispatch({
                            type: "PDF_MODAL_TOGGLE",
                            doc: AcknowledgementReceipt.AcknowledgementReceipt(
                              user,
                              fetchedTrans.amountValue,
                              fetchedTrans.paymentDate,
                              fetchedTrans.client.company,
                              fetchedTrans.order.uniqueId,
                              fetchedTrans.order.name,
                              fetchedTrans.order.dateAdded,
                              fetchedTrans.uniqueId,
                              fetchedTrans.paymentBankName,
                              fetchedTrans.paymentBankNumber,
                              fetchedTrans.checkInfo1,
                              true,
                              true,
                              fetchedTrans.isDownPayment,
                              invoicePayment.invoiceTotalAmountDue === 0
                                ? true
                                : false
                            ),
                          });
                        } else {
                          dispatch({
                            type: "MODAL_SHOW",
                            payload: {
                              title: "Error Fetching Data",
                              desc: `Please Contact Administrators. Error in route getTransaction`,
                              isYesNo: false,
                            },
                          });
                        }
                      }
                    }}
                    style={{
                      backgroundColor: "var(--REB-green)",
                      color: "var(--REB-white)",
                    }}
                  >
                    Acknowledgement
                    <i
                      style={{ color: "var(--REB-white)" }}
                      className="icofont-print pl-2"
                    ></i>
                  </Button>
                </OverlayTrigger>
              )}
              {val?.transStatus === "processing" && (
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Tooltip id={`actor-tooltip-add`}>
                      Export / Print Payment
                    </Tooltip>
                  }
                >
                  <Button
                    onClick={async () => {
                      let isAllowed = false;

                      const allowed = ["Admin", "Executive", "Manager"];
                      isAllowed = userType.some((el) => allowed.includes(el));

                      if (permissions.includes("perm-ui-chkChargeInvoice")) {
                        isAllowed = true;
                      }

                      if (!isAllowed) {
                        dispatch({
                          type: "MODAL_SHOW",
                          payload: {
                            title: "No Permission",
                            desc: `You are not allowed to print Charge Invoice | Billing`,
                            isYesNo: false,
                          },
                        });
                      } else {
                        const fetchedTrans =
                          await TransactionsAPI.getTransaction(
                            dispatch,
                            val.id
                          );

                        let perChunk = 8; // items per chunk

                        let result = fetchedTrans.order.content.reduce(
                          (resultArray, item, index) => {
                            const chunkIndex = Math.floor(index / perChunk);
                            if (!resultArray[chunkIndex]) {
                              resultArray[chunkIndex] = []; // start a new chunk
                            }
                            resultArray[chunkIndex].push({
                              ...item,
                              index: index + 1,
                            });
                            return resultArray;
                          },
                          []
                        );
                        for (let index = 0; index < result.length; index++) {
                          const element = result[index];
                          result[index] = element.sort(function (a, b) {
                            return a.partIndex - b.partIndex;
                          });
                        }
                        const ITEMS_GRANDTOTAL =
                          fetchedTrans.order.content.reduce((a, c) => {
                            return a + c.price * c.itemQty;
                          }, 0);

                        //Push Index to paymentData
                        const IndexedPaymentData = paymentData.map(
                          (x, index) => {
                            return { ...x, index: index + 1 };
                          }
                        );

                        if (fetchedTrans) {
                          dispatch({
                            type: "PDF_MODAL_TOGGLE",
                            doc: ChargeBillingReceipt.ChargeInvoice(
                              user,
                              "Billing",
                              fetchedTrans.uniqueId,
                              fetchedTrans.client,
                              fetchedTrans.order.dateAdded,
                              fetchedTrans.amountValue,
                              fetchedTrans.createdBy,
                              fetchedTrans.approvedBy,
                              result,
                              fetchedTrans.paymentBankName,
                              fetchedTrans.paymentBankNumber,
                              fetchedTrans.checkInfo1,
                              fetchedTrans.transStatus,
                              ITEMS_GRANDTOTAL,
                              IndexedPaymentData,
                              Number(props.state.invoiceTotalAmountDue),
                              fetchedTrans
                            ),
                          });
                          // const blob = await pdf(
                          //   ChargeBillingReceipt.ChargeInvoice(
                          //     user,
                          //     "Billing",
                          //     fetchedTrans.uniqueId,
                          //     fetchedTrans.client,
                          //     fetchedTrans.order.dateAdded,
                          //     fetchedTrans.amountValue,
                          //     fetchedTrans.createdBy,
                          //     fetchedTrans.approvedBy,
                          //     result,
                          //     fetchedTrans.paymentBankName,
                          //     fetchedTrans.paymentBankNumber,
                          //     fetchedTrans.checkInfo1,
                          //     fetchedTrans.transStatus,
                          //     ITEMS_GRANDTOTAL,
                          //     IndexedPaymentData,
                          //     Number(props.state.invoiceTotalAmountDue),
                          //     fetchedTrans
                          //   )
                          // ).toBlob();

                          // const blobLink = await window.URL.createObjectURL(blob);

                          // dispatch({
                          //   type: "FILE_VIEWER_MODAL_TOGGLE",
                          //   doc: blobLink,
                          // });
                        } else {
                          dispatch({
                            type: "MODAL_SHOW",
                            payload: {
                              title: "Error Fetching Data",
                              desc: `Please Contact Administrators. Error in route getTransaction`,
                              isYesNo: false,
                            },
                          });
                        }
                      }
                    }}
                    style={{
                      backgroundColor: "var(--REB-green)",
                      color: "var(--REB-white)",
                    }}
                    className="my-1"
                  >
                    Billing / Charge Invoice
                    <i
                      style={{ color: "var(--REB-white)" }}
                      className="icofont-print pl-2"
                    ></i>
                  </Button>
                </OverlayTrigger>
              )}
              {(val?.transStatus === "processing" ||
                val?.transStatus === "pending-payment") && (
                <Button
                  style={{
                    backgroundColor: "var(--REB-green)",
                  }}
                  className="mt-4"
                  onClick={() => {
                    _date = "";
                    _type = "";
                    _chkNumber = "";
                    _paymentNotes = "";
                    _paymentBankName = "";
                    _paymentBankAccount = "";
                    dispatch({
                      type: "MODAL_SHOW",
                      payload: {
                        title: "Confirmation",
                        size: "xl",
                        JsxDesc: () => {
                          const [TempTxtDate, setTempTxtDate] = useState("");
                          const [TempTxtType, setTempTxtType] = useState("");
                          const [TempTxtChkNumber, setTempTxtChkNumber] =
                            useState("");
                          const [TempTxtPaymentNotes, setTempTxtPaymentNotes] =
                            useState("");
                          const [
                            TempTxtPaymentBankName,
                            setTempTxtPaymentBankName,
                          ] = useState("");
                          const [
                            TempTxtPaymentBankAccount,
                            setTempTxtPaymentBankAccount,
                          ] = useState("");

                          return (
                            <>
                              <p
                                className="px-4 m-0 pt-2"
                                style={{
                                  fontSize: "0.8rem",
                                  color: "var(--REB-warning)",
                                }}
                              >
                                * Please make sure that you received and
                                recorded the payment. Are you sure you want to
                                receive this payment?
                              </p>
                              <Row noGutters>
                                <Col xs={12} sm={12} md={4} lg={4} className="px-1">
                                  <Form.Label
                                    className="font-weight-bold"
                                    style={{ color: "var(--REB-black)" }}
                                  >
                                    Amount Paid
                                  </Form.Label>
                                  <FormControl
                                    as={NumericFormat}
                                    inputMode="numeric"
                                    placeholder="Amount Paid"
                                    thousandSeparator={true}
                                    prefix={"₱ "}
                                    value={Number(val?.amountValue)}
                                    disabled
                                  />
                                </Col>
                                <Col xs={4} className="px-1">
                                  <Form.Label
                                    className="font-weight-bold"
                                    style={{ color: "var(--REB-black)" }}
                                  >
                                    Payment Date
                                  </Form.Label>
                                  <FormControl
                                    type="date"
                                    value={TempTxtDate}
                                    onChange={(x) => {
                                      _date = x.currentTarget.value;
                                      setTempTxtDate(x.currentTarget.value);
                                    }}
                                  />
                                </Col>
                                <Col xs={4} className="px-1">
                                  <Form.Label
                                    className="font-weight-bold"
                                    style={{ color: "var(--REB-black)" }}
                                  >
                                    Payment Type
                                  </Form.Label>
                                  <Select
                                    value={TempTxtType}
                                    // isMulti
                                    //styles={styles}
                                    styles={paymentSelectStyle}
                                    menuPortalTarget={document.body}
                                    name="colors"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(a) => {
                                      _type = a;
                                      setTempTxtType(a);
                                    }}
                                    options={PAYMENT_TYPES}
                                  />
                                </Col>
                                <Col xs={4} className="px-1">
                                  <Form.Label
                                    className="font-weight-bold"
                                    style={{ color: "var(--REB-black)" }}
                                  >
                                    Check Number
                                  </Form.Label>
                                  <FormControl
                                    type="text"
                                    //style={{ textTransform: "uppercase" }}
                                    placeholder="Check Number"
                                    value={TempTxtChkNumber}
                                    onChange={(e) => {
                                      _chkNumber = e.currentTarget.value;
                                      setTempTxtChkNumber(
                                        e.currentTarget.value
                                      );
                                    }}
                                    className=""
                                  />
                                </Col>
                                <Col xs={4} className="px-1">
                                  <Form.Label
                                    className="font-weight-bold"
                                    style={{ color: "var(--REB-black)" }}
                                  >
                                    Payment Notes
                                  </Form.Label>
                                  <FormControl
                                    type="text"
                                    placeholder="Payment Notes"
                                    value={TempTxtPaymentNotes}
                                    onChange={(e) => {
                                      _paymentNotes = e.currentTarget.value;
                                      setTempTxtPaymentNotes(
                                        e.currentTarget.value
                                      );
                                    }}
                                    className=""
                                  />
                                </Col>
                                <Col xs={4} className="px-1">
                                  <Form.Label
                                    className="font-weight-bold"
                                    style={{ color: "var(--REB-black)" }}
                                  >
                                    Payment Bank Name
                                  </Form.Label>
                                  <FormControl
                                    type="text"
                                    placeholder="Payment Bank Account Name"
                                    value={TempTxtPaymentBankName}
                                    onChange={(e) => {
                                      _paymentBankName = e.currentTarget.value;
                                      setTempTxtPaymentBankName(
                                        e.currentTarget.value
                                      );
                                    }}
                                    className=""
                                  />
                                </Col>
                                <Col xs={4} className="px-1">
                                  <Form.Label
                                    className="font-weight-bold"
                                    style={{ color: "var(--REB-black)" }}
                                  >
                                    Payment Bank Account Number
                                  </Form.Label>
                                  <FormControl
                                    type="text"
                                    placeholder="Payment Bank Account Number"
                                    value={TempTxtPaymentBankAccount}
                                    onChange={(e) => {
                                      _paymentBankAccount =
                                        e.currentTarget.value;
                                      setTempTxtPaymentBankAccount(
                                        e.currentTarget.value
                                      );
                                    }}
                                    className=""
                                  />
                                </Col>
                              </Row>
                              <>
                                <p
                                  className="px-4 m-0 pt-2"
                                  style={{
                                    fontSize: "0.8rem",
                                    color: "var(--REB-warning)",
                                  }}
                                >
                                  * If payment type is not CASH, please upload
                                  all necessary pictures and documents for
                                  recording.
                                </p>
                                <div
                                  className="mb-2 mt-3"
                                  style={{
                                    display: "flex",
                                    backgroundColor: "var(--REB-primary)",
                                    borderRadius: "10px 10px 0px 0px",
                                  }}
                                >
                                  <h5
                                    className="p-3 m-0"
                                    style={{
                                      color: "white",
                                      fontWeight: "bolder",
                                    }}
                                  >
                                    Payment Supporting Documents
                                  </h5>
                                </div>
                                <Row noGutters className="px-3">
                                  <Col>
                                    <>
                                      <InputGroup
                                        className="mb-3 p-3"
                                        style={{
                                          backgroundColor: "var(--REB-white)",
                                        }}
                                      >
                                        <FormControl
                                          type="file"
                                          custom
                                          // disabled={
                                          //   TransactionSelectedOperation ===
                                          //     SELECTED_OPERATION.VIEW ||
                                          //   TransactionSelectedOperation ===
                                          //     SELECTED_OPERATION.ADD
                                          // }
                                          // ref={inputFile}
                                          onClick={(e) =>
                                            (e.target.value = null)
                                          }
                                          name="fileUploadInvoice"
                                          // disabled={props.state.selContentItems.readOnly}
                                          onChange={(e) => {
                                            setFileUpload(e.target.files);

                                            if (
                                              Array.from(e.target.files)
                                                .length === 0
                                            ) {
                                              setBtnSaveFile(true);
                                            } else {
                                              setBtnSaveFile(false);
                                            }
                                          }}
                                        />

                                        <ButtonGroup className="">
                                          {/* <OverlayTrigger
                                            placement={"top"}
                                            overlay={
                                              <Tooltip id={`order-tooltip-add`}>
                                                Save
                                              </Tooltip>
                                            }
                                          >
                                            <Button
                                              variant="secondary"
                                              size="sm"
                                              style={{
                                                backgroundColor:
                                                  "var(--REB-green)",
                                              }}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                //const currentFiles = fileUpload;
                                                const formData = new FormData();
                                                //console.log(fileUpload);
                                                formData.append(
                                                  "uniqueId",
                                                  val?.uniqueId
                                                );
                                                for (
                                                  let index = 0;
                                                  index < fileUpload.length;
                                                  index++
                                                ) {
                                                  formData.append(
                                                    "uploadedFiles",
                                                    fileUpload[index]
                                                    //`signature.${fileType}`
                                                  );
                                                }
                                                // for (var pair of formData.entries()) {
                                                //   console.log(pair[0] + ", " + pair[1]);
                                                // }
                                                invoiceApi.uploadFile(
                                                  dispatch,
                                                  formData,
                                                  async () => {
                                                    //const UPDATED_ITEMS = await this.refreshInvoice();
                                                    //const oldSel = this.state.selectedRowInvoice;
                                                    // const fetchedTrans =
                                                    //   await TransactionsAPI.getTransaction(
                                                    //     dispatch,
                                                    //     val?.id
                                                    //   );
                                                    // setSelectedTransactionList(fetchedTrans);
                                                    // findTransactionAndReplace(fetchedTrans);
                                                  }
                                                );
                                              }}
                                              // disabled={btnSaveFile}
                                            >
                                              Save
                                            </Button>
                                          </OverlayTrigger> */}
                                        </ButtonGroup>
                                      </InputGroup>
                                      <Row className="p-3">
                                        {FileUploadData.map((i, index) => (
                                          <Col sm={3} key={index}>
                                            <Card
                                              className="text-center"
                                              style={{ padding: "1px" }}
                                            >
                                              <Card.Body>
                                                <Row className="mb-3">
                                                  <Col>
                                                    <Image
                                                      src={fileReg}
                                                      style={{ width: "20%" }}
                                                    />
                                                  </Col>
                                                </Row>
                                                <Row noGutters>
                                                  <Col className="">
                                                    <Button
                                                      size="md"
                                                      style={{
                                                        backgroundColor:
                                                          "var(--REB-green)",
                                                      }}
                                                      onClick={(e) => {
                                                        // props.state.handleActionChange({
                                                        //   target: {
                                                        //     name: "btnFileUploadDownload",
                                                        //     complete: i.originalname,
                                                        //     id: props.state.selectedRowInvoice
                                                        //       .uniqueId,
                                                        //     filename: i.filename,
                                                        //   },
                                                        // });
                                                        invoiceApi.downloadFile(
                                                          dispatch,
                                                          val?.uniqueId,
                                                          i.filename,
                                                          i.originalname,
                                                          () => {
                                                            // this.props.dispatch({
                                                            //   type: "FILE_VIEWER_MODAL_TOGGLE",
                                                            //   doc: {},
                                                            //   docType: {},
                                                            // });
                                                          }
                                                        );
                                                      }}
                                                      name="btnDLLink"
                                                      key={index}
                                                    >
                                                      Download
                                                    </Button>
                                                  </Col>
                                                  <Col className="">
                                                    <Button
                                                      size="md"
                                                      style={{
                                                        backgroundColor:
                                                          "var(--REB-green)",
                                                      }}
                                                      onClick={(e) => {
                                                        // props.state.handleActionChange({
                                                        //   target: {
                                                        //     name: "btnFileUploadView",
                                                        //     filename: i.filename,
                                                        //     originalname: i.originalname,
                                                        //     id: props.state.selectedRowInvoice
                                                        //       .uniqueId,
                                                        //   },
                                                        // });
                                                        const url = `${
                                                          process.env
                                                            .REACT_APP_API_URL
                                                        }global/getFile2?uniqueId=${
                                                          val?.uniqueId
                                                        }&filename=${
                                                          i.filename
                                                        }&token=${localStorage.getItem(
                                                          "LUT"
                                                        )}`;
                                                        dispatch({
                                                          type: "FILE_VIEWER_MODAL_TOGGLE",
                                                          doc: url,
                                                        });
                                                      }}
                                                    >
                                                      View
                                                    </Button>
                                                  </Col>
                                                  <Col className="">
                                                    <Button
                                                      size="md"
                                                      name={`delete${i.filename}`}
                                                      value={i.filename}
                                                      // disabled={
                                                      //   TransactionSelectedOperation !==
                                                      //   SELECTED_OPERATION.EDIT
                                                      // }
                                                      style={{
                                                        backgroundColor:
                                                          "var(--REB-warning)",
                                                      }}
                                                      onClick={(e) => {
                                                        // props.state.handleActionChange({
                                                        //   target: {
                                                        //     name: "btnFileUploadDelete",
                                                        //     filename: i.filename,
                                                        //     originalname: i.originalname,
                                                        //   },
                                                        // });
                                                        dispatch({
                                                          type: "MODAL_SHOW",
                                                          payload: {
                                                            title:
                                                              "Confirmation",
                                                            desc: `Are you sure you want to delete ${i.originalname} ?`,
                                                            isYesNo: true,
                                                            cb: async (x) => {
                                                              invoiceApi.deleteFile(
                                                                dispatch,
                                                                val?.uniqueId,
                                                                i.filename,
                                                                async () => {
                                                                  // const fetchedTrans =
                                                                  //   await TransactionsAPI.getTransaction(
                                                                  //     dispatch,
                                                                  //     SelectedTransactionList.id
                                                                  //   );
                                                                  // setSelectedTransactionList(
                                                                  //   fetchedTrans
                                                                  // );
                                                                  // findTransactionAndReplace(
                                                                  //   fetchedTrans
                                                                  // );
                                                                }
                                                              );
                                                            },
                                                          },
                                                        });
                                                      }}
                                                    >
                                                      Delete
                                                    </Button>
                                                  </Col>
                                                </Row>
                                              </Card.Body>
                                              <Card.Footer>
                                                {i.originalname}
                                              </Card.Footer>
                                            </Card>
                                          </Col>
                                        ))}
                                      </Row>
                                    </>
                                  </Col>
                                </Row>
                              </>
                            </>
                          );
                        },
                        // desc: `Please make sure that you received and recorded the payment. Are you sure you want to receive this payment?`,
                        isYesNo: true,
                        cb: async (e) => {
                          let err = false;

                          if (_date === null || _date === "") err = true;
                          if (_type === null || _type === "") err = true;

                          if (!err) {
                            const data = {
                              transaction: {
                                id: Number(val.id),

                                transStatus: "finished",
                                notes: _paymentNotes,
                                checkInfo1: _chkNumber,
                                paymentBankName: _paymentBankName,
                                paymentBankNumber: _paymentBankAccount,
                                paymentDate: _date,
                                paymentType: _type.value,
                                // notes: TransModalNotes,
                              },
                            };

                            const modifiedInvoice =
                              await TransactionsAPI.updateTransaction(
                                dispatch,
                                data,
                                () => {},
                                () => {
                                  dispatch({
                                    type: "MODAL_SHOW",
                                    payload: {
                                      title: "Transaction Update Error",
                                      desc: `Please Contact Administrator`,
                                      isYesNo: false,
                                    },
                                  });
                                }
                              );

                            const updatedInvoice = await InvoiceAPI.getInvoice(
                              dispatch,
                              val?.orderId
                            );
                            console.log(
                              "UPDATED INVOICE ===> ",
                              updatedInvoice
                            );

                            //HANDLE FILES
                            //e.preventDefault();
                            //const currentFiles = fileUpload;
                            const formData = new FormData();
                            //console.log(fileUpload);
                            formData.append("uniqueId", val?.uniqueId);
                            if (fileUpload && fileUpload.length) {
                              for (
                                let index = 0;
                                index < fileUpload.length;
                                index++
                              ) {
                                formData.append(
                                  "uploadedFiles",
                                  fileUpload[index]
                                  //`signature.${fileType}`
                                );
                              }
                              // for (var pair of formData.entries()) {
                              //   console.log(pair[0] + ", " + pair[1]);
                              // }
                              invoiceApi.uploadFile(
                                dispatch,
                                formData,
                                async () => {
                                  //const UPDATED_ITEMS = await this.refreshInvoice();
                                  //const oldSel = this.state.selectedRowInvoice;
                                  // const fetchedTrans =
                                  //   await TransactionsAPI.getTransaction(
                                  //     dispatch,
                                  //     val?.id
                                  //   );
                                  // setSelectedTransactionList(fetchedTrans);
                                  // findTransactionAndReplace(fetchedTrans);
                                }
                              );
                            }
                            props.state.handleActionChange({
                              target: {
                                value: updatedInvoice,
                                name: "refreshAndReplaceSelected",
                              },
                            });
                          } else {
                            dispatch({
                              type: "MODAL_SHOW",
                              payload: {
                                title: "Validation Error",
                                desc: `Please make sure Date and Payment Type is filled.`,
                                isYesNo: false,
                              },
                            });
                          }
                        },
                      },
                    });
                  }}
                >
                  Set Payment to Received / Finished
                </Button>
              )}
              {val.transStatus === "finished" && (
                <p className="p-2 m-0" style={{ color: "var(--REB-primary)" }}>
                  Finished
                </p>
              )}
              {val.transStatus === "pending-admin-approval" && (
                <p className="p-2 m-0" style={{ color: "var(--REB-black)" }}>
                  Pending Admin Approval
                </p>
              )}
              {val.transStatus === "admin-denied" && (
                <p className="p-2 m-0" style={{ color: "var(--REB-black)" }}>
                  Admin Denied
                </p>
              )}
              {val.transStatus === "processing" && (
                <p className="p-2 m-0" style={{ color: "var(--REB-black)" }}>
                  Processing
                </p>
              )}
              {val.transStatus === "pending-payment" && (
                <p
                  className="p-2 m-0"
                  style={{ color: "var(--REB-black)", fontWeight: "bold" }}
                >
                  Pending Client Payment
                </p>
              )}
            </Col>
            <Col xs={12}
              sm={12}
              md={3}
              lg={3} style={{ alignSelf: "center" }} className="pt-1 px-2">
              {val.isDownPayment === 1 && "DP "}
              <NumericFormat
                value={Number(val.amountValue)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₱ "}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </Col>
            <Col xs={12}
              sm={12}
              md={3}
              lg={3} style={{ alignSelf: "center" }} className="pt-1 px-2">
              {val.paymentType}

              {val.checkInfo1 && (
                <p className="m-0 p-0"> check #:{val.checkInfo1}</p>
              )}
            </Col>
            <Col xs={12}
              sm={12}
              md={3}
              lg={3} style={{ alignSelf: "center" }} className="pt-1 px-2">
              {val.paymentDate ? moment(val.paymentDate).format("LL") : "-"}
              {val.paymentBankName && (
                <p className="m-0 p-0">{val.paymentBankName}</p>
              )}
              {val.paymentBankNumber && (
                <p className="m-0 p-0">Act #: {val.paymentBankNumber}</p>
              )}
              {val.notes && <p>{val.notes}</p>}
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

function PDFGeneratePOHistory(props) {
  //const [btn, setBtn] = useState(false);

  return (
    <></>
    // <InputGroup className="pdfOrder-select">
    //   {/* <InputGroup.Prepend>
    //     <InputGroup.Text className="">PDF</InputGroup.Text>
    //   </InputGroup.Prepend> */}

    //   {(() => {
    //     if (btn) {
    //       if (
    //         props.state.selectedRowInvoice.invoiceType.name ===
    //         "Transfer Inventory"
    //       ) {
    //         return (
    //           <PDFDownloadLink
    //             document={TransferItem.TransferItemPDF(
    //               props.state,
    //               false,
    //               props.state.selectedRowInvoice.uniqueId,
    //               props.state.selDetailStatus.value.name,

    //               props.state.selectedRowInvoice.incharge.name,
    //               props.state.selectedRowInvoice.approvedBy !== null
    //                 ? props.state.selectedRowInvoice.approvedBy
    //                 : null,
    //               props.state.selectedRowInvoice.incharge !== null
    //                 ? props.state.selectedRowInvoice.incharge
    //                 : null,
    //               props.state.selectedRowInvoice.isSignatureAvailable,
    //               props.state.selectedRowInvoice.inventoryFrom.name,
    //               props.state.selectedRowInvoice.inventoryTo.name
    //             )}
    //           >
    //             {({ blob, url, loading, error }) => {
    //               if (loading) {
    //                 return "Loading";
    //               } else {
    //                 return (
    //                   <>
    //                     <Button
    //                       name="downloadPDF"
    //                       style={{ backgroundColor: "var(--REB-primary)" }}
    //                       onClick={(e) => {
    //                         e.preventDefault();
    //                         e.stopPropagation();
    //                         props.state.dispatch({
    //                           type: "MODAL_SHOW",
    //                           payload: {
    //                             title: "Confirmation",
    //                             desc: `Are you sure you want to generate signed Transfer Inventory? This action will update the status of this invoice.`,
    //                             isYesNo: true,
    //                             cb: () => {
    //                               props.state.handleActionChange({
    //                                 target: {
    //                                   value: blob,
    //                                   name: "downloadPDF",
    //                                 },
    //                               });
    //                               setBtn(false);
    //                             },
    //                           },
    //                         });
    //                       }}
    //                     >
    //                       {" "}
    //                       Download Signed Transfer
    //                     </Button>
    //                   </>
    //                 );
    //               }
    //             }}
    //           </PDFDownloadLink>
    //         );
    //       }
    //       if (props.state.selectedRowInvoice.invoiceType.name === "Quote") {
    //         return (
    //           <PDFDownloadLink
    //             document={QuotePDF.QuotePDF(
    //               props.state,
    //               props.state.selectedRowInvoice.approvedBy,
    //               props.state.selectedRowInvoice.isSignatureAvailable,
    //               props.state.selectedRowInvoice.incharge
    //             )}
    //           >
    //             {({ blob, url, loading, error }) => {
    //               if (loading) {
    //                 return "Loading";
    //               } else {
    //                 return (
    //                   <>
    //                     <Button
    //                       name="downloadPDF"
    //                       style={{ backgroundColor: "var(--REB-primary)" }}
    //                       onClick={(e) => {
    //                         e.preventDefault();
    //                         e.stopPropagation();
    //                         props.state.dispatch({
    //                           type: "MODAL_SHOW",
    //                           payload: {
    //                             title: "Confirmation",
    //                             desc: `Are you sure you want to generate signed Quote? This action will update the status of this invoice.`,
    //                             isYesNo: true,
    //                             cb: () => {
    //                               props.state.handleActionChange({
    //                                 target: {
    //                                   value: blob,
    //                                   name: "downloadPDF",
    //                                 },
    //                               });
    //                               setBtn(false);
    //                             },
    //                           },
    //                         });
    //                       }}
    //                     >
    //                       {" "}
    //                       Download Signed Quote
    //                     </Button>
    //                   </>
    //                 );
    //               }
    //             }}
    //           </PDFDownloadLink>
    //         );
    //       }
    //       if (
    //         props.state.selectedRowInvoice.invoiceType.name ===
    //           "Purchase Order" ||
    //         props.state.selectedRowInvoice.invoiceType.name ===
    //           "Purchase Request"
    //       ) {
    //         return (
    //           <PDFDownloadLink
    //             document={PR.PRPDF(
    //               props.state,
    //               null,
    //               props.state.selectedRowInvoice.approvedBy !== null
    //                 ? props.state.selectedRowInvoice.approvedBy
    //                 : null,
    //               props.state.selectedRowInvoice.isSignatureAvailable,
    //               props.state.selectedRowInvoice.incharge !== null
    //                 ? props.state.selectedRowInvoice.incharge
    //                 : null
    //             )}
    //           >
    //             {({ blob, url, loading, error }) => {
    //               if (loading) {
    //                 return "Loading";
    //               } else {
    //                 return (
    //                   <>
    //                     <Button
    //                       name="downloadPDF"
    //                       style={{ backgroundColor: "var(--REB-primary)" }}
    //                       onClick={(e) => {
    //                         e.preventDefault();
    //                         e.stopPropagation();
    //                         props.state.dispatch({
    //                           type: "MODAL_SHOW",
    //                           payload: {
    //                             title: "Confirmation",
    //                             desc: `Are you sure you want to generate signed PO? This action will update the status of this invoice.`,
    //                             isYesNo: true,
    //                             cb: () => {
    //                               props.state.handleActionChange({
    //                                 target: {
    //                                   value: blob,
    //                                   name: "downloadPDF",
    //                                 },
    //                               });
    //                               setBtn(false);
    //                             },
    //                           },
    //                         });
    //                       }}
    //                     >
    //                       {" "}
    //                       Download Signed PO
    //                     </Button>
    //                   </>
    //                 );
    //               }
    //             }}
    //           </PDFDownloadLink>
    //         );
    //       }
    //     } else {
    //       return (
    //         <Button
    //           name="btnGeneratePDF"
    //           className=""
    //           style={{
    //             backgroundColor: "var(--REB-primary)",
    //             // fontSize: "0.7rem",
    //           }}
    //           block
    //           onClick={(e) => {
    //             props.state.handleActionChange(
    //               {
    //                 target: {
    //                   name: "btnViewInvoicePDF",
    //                 },
    //               },

    //               null,
    //               () => {
    //                 setBtn(true);
    //               }
    //             );
    //           }}
    //         >
    //           Generate Signed Invoice
    //         </Button>
    //       );
    //     }
    //   })()}
    // </InputGroup>
  );
}

function InvoiceModal(props) {
  return (
    <>
      <style>
        {`
        .invoiceModal {
          width: 100vw;
          max-width: 100vw;
          margin: 0px;
          height: 100vh;
        }
        .invoiceModalBody {
          width: 100vw;
          height: 100%;
          padding: 0px;
        }
        
        .modal {
          padding-left: 0px !important;
        }
        .modal-body {
          padding: 0px;
        }
        .modalDialogInvoice {
          height: 100vh;
        }
        .modal-content{
          height: 100%;
        }
        .invoiceModalFooter {
         
        }
        .invoiceModalHeader{
            
        }
        .invoiceModalForm{
         
        }
        

        @media (max-width: 768px) {
          .itemModalContentHeader {
            padding: 0px;
          }
         
          .invoiceModalBody {
           
          }
          .invoiceModalFooter {
            padding: 0px;
          }
          .tabletMarginTop {
            margin-top: 20px;
          }
          .invoiceModalHeader{
            
          }
          .invoiceModalFooterButtons{
            font-size: 0.75rem;
           }
           .invoiceModalFooter {
            padding-top: 5px;
            padding-bottom: 5px;
          }
        }
        @media (min-width: 769px) {
          .itemModalContentHeader {
            padding: 10px;
            padding-left: 20px;
          }
          .invoiceModalFooter {
            padding-top: 10px;
            padding-bottom: 10px;
          }
          .invoiceModalBody {
            
          }
          .invoiceModalContent{
            padding-left: 20px;
            padding-right: 20px;
          }
          .invoiceModalHeader{
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 10px;
            padding-right: 10px;
          }
          .itemModalCRUDButtons {
            padding-left: 30px !important;
          }
         
        }
      `}
      </style>
      <Modal
        show={props.state.handleInvoiceModal.isShow}
        onHide={props.state.handleInvoiceModal.toggle}
        onExit={(e) => {
          props.state.handleInvoiceModal.handleExit(e);
        }}
        dialogClassName="invoiceModal"
        className=""
        centered
      >
        <Modal.Body
          className="invoiceModalBody"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Row
            className="invoiceModalHeader"
            style={{ backgroundColor: "var(--REB-tertiary)" }}
            noGutters
          >
            <Col md={1} lg={1} sm={2} xs={2}>
              <OverlayTrigger
                placement={"right"}
                overlay={<Tooltip id={`project-tooltip-add`}>Close</Tooltip>}
              >
                <Button
                  variant="secondary"
                  name="btnCloseInvoiceModal"
                  style={{ backgroundColor: "var(--REB-primary)" }}
                  className="icofont-rounded-left"
                  onClick={(e) => {
                    props.state.handleInvoiceModal.toggle();
                  }}
                />
              </OverlayTrigger>
            </Col>
            <Col
              md={5}
              lg={2}
              sm={4}
              xs={4}
              className=""
              style={{ alignSelf: "center" }}
            >
              <h4 className="m-0 " style={{ color: "var(--REB-white)" }}>
                {props.state.selectedOperation.name} Invoice
              </h4>
            </Col>
            {/* {props.state.handleInvoiceModal.isView &&
          props.state.showPendingApproval ? (
            <Col md={3} lg={3} className="user-col-cont-padding">
              <OverlayTrigger
                placement={"right"}
                overlay={
                  <Tooltip id={`project-tooltip-add`}>
                    Mark this inventory transfer invoice to finished.
                  </Tooltip>
                }
              >
                <Button
                  block
                  name="btnFinishInvTransfer"
                  style={{ backgroundColor: "var(--REB-green)" }}
                  onClick={(e) => {
                    props.state.dispatch({
                      type: "MODAL_SHOW",
                      payload: {
                        title: "Confirmation",
                        desc: `Please make sure to review all information of this invoice before submitting for admin verification. Are you sure you want to submit this invoice?`,
                        isYesNo: true,
                        cb: () => {
                          props.state.handleActionChange(e);
                        },
                      },
                    });
                  }}
                >
                  SET INVENTORY TRANSFER COMPLETED
                </Button>
              </OverlayTrigger>
            </Col>
          ) : null} */}
            {props.state.handleInvoiceModal.isView &&
            props.state.showPendingApproval ? (
              <Col
                md={6}
                lg={3}
                sm={6}
                xs={6}
                className="user-col-cont-padding"
                hidden={props.state.userType.includes("Inventory Clerk")}
              >
                <OverlayTrigger
                  placement={"right"}
                  overlay={
                    <Tooltip id={`project-tooltip-add`}>
                      Submit this invoice for review of admin.
                    </Tooltip>
                  }
                >
                  <Button
                    block
                    name="btnSubmitForConfirmation"
                    style={{ backgroundColor: "var(--REB-green)" }}
                    onClick={(e) => {
                      props.state.dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "Confirmation",
                          desc: `Please make sure to review all information of this invoice before submitting for admin verification. Are you sure you want to submit this invoice?`,
                          isYesNo: true,
                          cb: () => {
                            props.state.handleActionChange(e);
                          },
                        },
                      });
                    }}
                  >
                    SUBMIT FOR CONFIRMATION
                  </Button>
                </OverlayTrigger>
              </Col>
            ) : null}
            {/* {props.state.handleInvoiceModal.isView &&
          props.state.selDetailStatus.value.name === "New" ? (
            <Col md={6} lg={2} className="user-col-cont-padding">
              <OverlayTrigger
                placement={"right"}
                overlay={
                  <Tooltip id={`project-tooltip-add`}>
                    Checks out this quote. Admin verification will be performed
                    after client transaction.
                  </Tooltip>
                }
              >
                <Button
                  block
                  name="btnSubmitInvoiceForCheckout"
                  style={{ backgroundColor: "var(--REB-green)" }}
                  onClick={(e) => {
                    props.state.dispatch({
                      type: "MODAL_SHOW",
                      payload: {
                        title: "Confirmation",
                        desc: `Please make sure all details are correct and Client completed full payment of this transaction. Are you sure you want to submit this invoice?`,
                        isYesNo: true,
                        cb: () => {
                          props.state.handleActionChange(e);
                        },
                      },
                    });
                  }}
                >
                  CHECKOUT
                </Button>
              </OverlayTrigger>
            </Col>
          ) : null} */}
            {props.state.showInvoiceFinish ? (
              <Col md={6} lg={3} className="user-col-cont-padding">
                <OverlayTrigger
                  placement={"right"}
                  overlay={
                    <Tooltip id={`project-tooltip-add`}>
                      Set invoice to finished
                    </Tooltip>
                  }
                >
                  <Button
                    block
                    className="animate__pulse animate__animated animate__infinite	infinite"
                    name="btnSetInvoiceFinish"
                    style={{ backgroundColor: "var(--REB-green)" }}
                    onClick={(e) => {
                      if (
                        props.state.permissions.includes(
                          "perm-ui-invoicingPage-view-write"
                        )
                      ) {
                        props.state.dispatch({
                          type: "MODAL_SHOW",
                          payload: {
                            title: "Confirmation",
                            desc: `Once invoice status is marked finished, only administrators can modify the invoice. Please make sure to review all information of this invoice. Are you sure you want to set invoice status to finished?`,
                            isYesNo: true,
                            cb: () => {
                              props.state.handleActionChange(e);
                            },
                          },
                        });
                      } else {
                        props.state.dispatch({
                          type: "MODAL_SHOW",
                          payload: {
                            title: "No Permission",
                            desc: `You dont have access to modify invoices`,
                            isYesNo: false,
                          },
                        });
                      }
                    }}
                  >
                    MARK INVOICE AS DONE
                  </Button>
                </OverlayTrigger>
              </Col>
            ) : null}

            <Col md={"auto"} lg={"auto"} className="user-col-cont-padding">
              <InputGroup>
                <Button
                  name="btnViewInvoicePDF"
                  hidden={!props.state.handleInvoiceModal.isView}
                  style={{
                    backgroundColor: "var(--REB-green)",
                    flex: 1,
                    borderRadius: "5px 0px 0px 5px",
                  }}
                  onClick={(e) => {
                    if (
                      props.state.permissions.includes(
                        "perm-ui-chkQuoteInvoice"
                      ) ||
                      props.state.permissions.includes(
                        "perm-ui-chkSalesReceipt"
                      )
                    ) {
                      props.state.handleActionChange(e, (x) => {
                        // props.state.dispatch({
                        //   type: "PDF_MODAL_TOGGLE",
                        //   doc: QuotePDF.QuotePDF(props.state),
                        // });
                      });
                    } else {
                      props.state.dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "No Permission",
                          desc: `You dont have access to view Quote/POS Invoice`,
                          isYesNo: false,
                        },
                      });
                    }
                  }}
                >
                  {props.state.selectedRowInvoice &&
                  props.state.selectedRowInvoice?.invoiceType?.name === "POS"
                    ? "Reprint Receipt"
                    : "VIEW PDF"}
                </Button>

                {props.state.selectedRowInvoice !== null ? (
                  props.state.selectedRowInvoice.invoiceType !== undefined ? (
                    props.state.selectedRowInvoice.invoiceType.name ===
                      "Quote" && props.state.handleInvoiceModal.isView ? (
                      <InputGroup.Prepend>
                        <div
                          style={{
                            backgroundColor: "var(--REB-secondary)",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            borderRadius: "0px 5px 5px 0px",
                          }}
                        >
                          <Form.Check
                            checked={props.state.chkPDFWithDesc.value}
                            disabled={props.state.chkPDFWithDesc.readOnly}
                            style={{ paddingTop: "6.5px", color: "white" }}
                            name="chkPDFWithDesc"
                            // hidden={!props.state.handleInvoiceModal.isView}
                            label="W/ Desc."
                            onChange={(e) => {
                              props.state.handleActionChange(
                                {
                                  target: {
                                    value: e.target,
                                    name: "chkPDFWithDesc",
                                  },
                                },
                                null,
                                () => {}
                              );
                            }}
                          />
                        </div>
                      </InputGroup.Prepend>
                    ) : null
                  ) : null
                ) : null}
                {props.state.selectedRowInvoice !== null ? (
                  props.state.selectedRowInvoice.invoiceType !== undefined ? (
                    props.state.selectedRowInvoice.invoiceType.name ===
                      "Purchase Order" &&
                    props.state.handleInvoiceModal.isView ? (
                      <InputGroup.Prepend>
                        <div
                          style={{
                            backgroundColor: "var(--REB-primary)",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                        >
                          <Form.Check
                            checked={props.state.chkPDFGroupItems.value}
                            disabled={props.state.chkPDFGroupItems.readOnly}
                            style={{ paddingTop: "6.5px", color: "white" }}
                            name="chkPDFGroupItems"
                            //hidden={!props.state.handleInvoiceModal.isView}
                            label="Group Items"
                            onChange={(e) => {
                              props.state.handleActionChange(
                                {
                                  target: {
                                    value: e.target,
                                    name: "chkPDFGroupItems",
                                  },
                                },
                                null,
                                () => {}
                              );
                            }}
                          />
                        </div>
                      </InputGroup.Prepend>
                    ) : null
                  ) : null
                ) : null}
              </InputGroup>
            </Col>
            {props.state.selectedRowInvoice !== null ? (
              props.state.selectedRowInvoice.isSignatureAvailable === 1 ? (
                <Col
                  md={3}
                  lg={3}
                  className="user-col-cont-padding"
                  hidden={props.state.userType.includes("Inventory Clerk")}
                >
                  <PDFGeneratePOHistory state={props.state} />
                </Col>
              ) : null
            ) : null}

            {/* <Col md={2} lg={2} className="user-col-cont-padding">
            <Button
              block
              name="btnTriggerTransfer"
              style={{ backgroundColor: "var(--REB-green)" }}
              onClick={(e) => {
                props.state.handleActionChange(e, (x) => {
                  // props.state.dispatch({
                  //   type: "PDF_MODAL_TOGGLE",
                  //   doc: QuotePDF.QuotePDF(props.state),
                  // });
                });
              }}
            >
              trigger transfer
            </Button>
          </Col> */}
          </Row>
          <Row className="mb-2">
            <Col xs={12} className="user-select pl-2 pr-2 ">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text
                    style={{
                      backgroundColor: "var(--REB-primary)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                    }}
                  >
                    INVOICE TYPE
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Select
                  styles={props.customStyles}
                  isSearchable
                  menuPortalTarget={document.body}
                  width="100%"
                  options={props.state.selDetailType.data}
                  value={props.state.selDetailType.value}
                  isDisabled={props.state.selDetailType.readOnly}
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.id}
                  //components={{ Option: _option }}
                  //formatOptionLabel={"test"}
                  //innerRef={this.props.propRef}
                  onChange={(x, name) => {
                    //console.log(x, name);

                    props.state.handleActionChange({
                      target: {
                        value: x,
                        name: "handleInvoiceTypeChange",
                      },
                    });
                    // this.props.handler(x, name, {
                    //   target: { name: this.props.name },
                    // });
                  }}
                />
                {/* <select
              className="form-control actor-selector w-auto"
              name="InvoiceDetailsSelect"
              // value={props.state.selDetailType.value}
              onChange={props.state.handleInvoiceTypeChange}
              //ref={props.InvoiceDetailsSelectRef}
            >
              {props.state.selDetailType.data.map((x) => {
                return (
                  <option
                    //ref={x.value === 1 ? props.testRef : null}
                    key={x.id}
                    value={x.id}
                  >
                    {x.name}
                  </option>
                );
              })}
            </select> */}
              </InputGroup>
            </Col>
            {/* <Col md={6} lg={6} className="pl-2 pr-2">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text
                    style={{
                      backgroundColor: "var(--REB-primary)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                    }}
                  >
                    UNIQUE ID
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  type="text"
                  placeholder="Generates after saved"
                  value={
                    props.state.selectedRowInvoice !== null
                      ? props.state.selectedRowInvoice.uniqueId
                      : ""
                  }
                  readOnly={true}
                />
              </InputGroup>
            </Col> */}
          </Row>
          <div
            className="invoiceModalContent"
            style={{
              overflow: "scroll",
              overflowX: "hidden",
              flex: 1,
            }}
          >
            {(() => {
              const _e = props.state?.selDetailType?.value?.name;

              if (_e === "Quote" || _e === "POS") {
                return (
                  <>
                    <style>
                      {`
                        .chkbox > input {
                          transform: scale(2);
                        }
                        .chkbox > label {
                          padding-left: 10px;
                        }
                      `}
                    </style>
                    <Row>
                      <Col xs={12} md={12} className="">
                        <Row noGutters>
                          {props.state.chkIsReservation.readOnly && (
                            <Col xs={12} className="px-2">
                              <>
                                <Form.Label
                                  className="font-weight-bold"
                                  style={{ color: "var(--REB-black)" }}
                                >
                                  UNIQUE ID
                                </Form.Label>
                                <FormControl
                                  type="text"
                                  style={{ textTransform: "uppercase" }}
                                  name="uniqueID"
                                  placeholder="Generates after saved"
                                  value={
                                    props.state.selectedRowInvoice !== null
                                      ? props.state.selectedRowInvoice.uniqueId
                                      : ""
                                  }
                                  className="mb-2"
                                  readOnly={true}
                                  disabled={true}
                                />
                              </>
                            </Col>
                          )}

                          <Col xs={12} className="pl-2 pr-2">
                            <Form.Check
                              checked={props.state.chkIsReservation.value}
                              disabled={props.state.chkIsReservation.readOnly}
                              className="chkbox mt-2 ml-2"
                              name="chkIsReservation"
                              label="Set for RESERVATION"
                              onChange={(e) => {
                                props.state.handleActionChange({
                                  target: {
                                    value: e.target,
                                    componentName: "chkIsReservation",
                                    name: "chkHandleChange",
                                  },
                                });
                              }}
                            />
                            <p
                              className="p-0 m-0 mb-2"
                              style={{
                                fontSize: "0.75rem",
                                color: "var(--REB-warning)",
                                fontWeight: "bolder",
                              }}
                            >
                              By checking for reservation, all items of this
                              quotation will be automatically reserved /
                              subtracted to inventory. It will only be returned
                              to inventory if this quotation will expire or
                              removed.
                            </p>
                            <TxtInvoiceName state={props.state} />
                          </Col>
                          <Col xs={12} className="pl-2 pr-2">
                            <TxtDescription state={props.state} />
                          </Col>
                          <Col xs={12} className="pl-2 pr-2">
                            <TxtShippingAddress state={props.state} />
                          </Col>
                          {props.state.selectedOperation.id === 1 ? (
                            <>
                              <Col xs={12} className="pl-2 pr-2">
                                <TxtDateAdded state={props.state} />
                              </Col>
                              <Col xs={12} className="pl-2 pr-2">
                                <TxtDateLastModified state={props.state} />
                              </Col>
                              <Col xs={12} className="pl-2 pr-2">
                                <TxtDateFinished state={props.state} />
                              </Col>
                            </>
                          ) : null}
                          <Col xs={12} className="pl-2 pr-2">
                            <TxtDateDeadline state={props.state} />
                          </Col>
                          <Col xs={12} className="pl-2 pr-2">
                            <TxtNotes state={props.state} />
                          </Col>
                          {props.state.selectedOperation.id === 1 ? (
                            <>
                              <Col xs={12} className="pl-2 pr-2">
                                <TxtLastModifiedBy state={props.state} />
                              </Col>
                              <Col xs={12} className="pl-2 pr-2">
                                <TxtApprovedByActor state={props.state} />
                              </Col>
                            </>
                          ) : null}
                        </Row>
                      </Col>
                      <Col xs={12} md={12} className="px-4">
                        <div
                          className="p-0 pb-3"
                          style={{
                            borderRadius: "5px",
                            backgroundColor: "var(--REB-body)",
                          }}
                        >
                          <Row
                            className="px-3 pt-2 mb-3"
                            style={{
                              backgroundColor: "var(--REB-primary)",
                              borderTopRightRadius: "5px",
                              borderTopLeftRadius: "5px",
                            }}
                            noGutters
                          >
                            <Col>
                              <h4
                                style={{
                                  fontWeight: "bolder",
                                  color: "var(--REB-white)",
                                }}
                              >
                                <i
                                  className="icofont-file-document pr-1"
                                  style={{ fontSize: "1.5rem" }}
                                ></i>
                                Invoice Terms
                              </h4>
                            </Col>
                          </Row>
                          <Row noGutters>
                            <Col
                              xs={12}
                              sm={12}
                              md={6}
                              lg={6}
                              className="px-2 mb-2"
                            >
                              <div
                                className="mb-3"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <p
                                  className="m-0 pl-2 "
                                  style={{
                                    backgroundColor: "var(--REB-primary)",
                                    color: "var(--REB-white)",
                                    fontWeight: "bold",
                                    fontSize: "1rem",
                                    width: "230px",
                                    borderRadius: "5px 5px 0px 0px",
                                  }}
                                >
                                  Terms
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <Select
                                    styles={customStyles}
                                    isSearchable
                                    menuPortalTarget={document.body}
                                    width="80%"
                                    options={props.state.selDetailTerms.data}
                                    value={props.state.selDetailTerms.value}
                                    isDisabled={
                                      props.state.selDetailTerms.readOnly
                                    }
                                    getOptionLabel={(x) => x.name}
                                    getOptionValue={(x) => x.id}
                                    //formatOptionLabel={"test"}
                                    //innerRef={this.props.propRef}
                                    onChange={(x, name) => {
                                      //console.log(x, name);

                                      props.state.handleActionChange({
                                        target: {
                                          value: x,
                                          name: "handleTermsChange",
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>

                            <Col
                              xs={12}
                              sm={12}
                              md={2}
                              lg={2}
                              className="px-2 mb-2"
                            >
                              <OverlayTrigger
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`actor-tooltip-add`}>
                                    ADD SELECTED TERM TO QUOTE
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="secondary"
                                  name="btnAddTerm"
                                  className=""
                                  block
                                  disabled={props.state.selDetailTerms.readOnly}
                                  style={{
                                    height: "100%",
                                    backgroundColor: "var(--REB-green)",
                                    //borderRadius: "0px 5px 5px 0px",
                                  }}
                                  onClick={(e) => {
                                    if (
                                      props.state.selDetailTerms.value ===
                                        null ||
                                      props.state.selDetailTerms.value === ""
                                    ) {
                                      props.state.dispatch({
                                        type: "MODAL_SHOW",
                                        payload: {
                                          title: "Terms Field Empty",
                                          desc: `Please select or fill in terms input`,
                                          isYesNo: false,
                                        },
                                      });
                                    } else {
                                      props.state.handleActionChange({
                                        target: {
                                          value: e,
                                          name: "btnAddTerm",
                                        },
                                      });
                                    }
                                  }}
                                >
                                  Add Term
                                </Button>
                              </OverlayTrigger>
                            </Col>
                            <Col
                              xs={12}
                              sm={12}
                              md={2}
                              lg={2}
                              className="px-2 mb-2"
                            >
                              {props.state.termsTableSelection.length === 1 && (
                                <OverlayTrigger
                                  placement={"top"}
                                  overlay={
                                    <Tooltip id={`actor-tooltip-add`}>
                                      Remove selected term
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    name="btnRemoveTerm"
                                    block
                                    // className="icofont-ui-remove "
                                    className=""
                                    style={{
                                      //borderRadius: "0px 5px 5px 0px",
                                      height: "100%",
                                      backgroundColor: "var(--REB-warning)",
                                    }}
                                    // hidden={
                                    //   props.state.termsTableSelection
                                    //     .length === 0
                                    // }
                                    onClick={(e) => {
                                      if (
                                        props.state.termsTableSelection
                                          .length === 1
                                      ) {
                                        props.state.handleActionChange({
                                          target: { name: "btnRemoveTerm" },
                                        });
                                      }
                                    }}
                                  >
                                    Remove Term
                                  </Button>
                                </OverlayTrigger>
                              )}
                            </Col>
                            <Col
                              xs={12}
                              sm={12}
                              md={2}
                              lg={2}
                              className="px-2 mb-2"
                            >
                              <OverlayTrigger
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`actor-tooltip-add`}>
                                    ADD Default terms to quote
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="secondary"
                                  name="btnAddInvoice"
                                  className=" w-100"
                                  disabled={
                                    props.state.selDetailServices.readOnly
                                  }
                                  style={{
                                    height: "100%",
                                    backgroundColor: "var(--REB-green)",
                                  }}
                                  onClick={(e) => {
                                    props.state.handleActionChange({
                                      target: {
                                        value: e,
                                        name: "btnAddDefaultTerms",
                                      },
                                    });
                                  }}
                                >
                                  Add Default Terms
                                </Button>
                              </OverlayTrigger>
                            </Col>
                          </Row>
                          <Row noGutters>
                            <Col className="px-2">{props.tableTerms}</Col>
                          </Row>
                        </div>
                        {props.state.selectedOperation.id === 1 && (
                          <TxtAmountPaid state={props.state} />
                        )}
                      </Col>
                    </Row>

                    {/* <Row noGutters></Row> */}
                    <TypeQuote
                      state={props.state}
                      tableInvoiceContent={props.tableInvoiceContent}
                      tableTerms={props.tableTerms}
                      tableListDR={props.tableListDR}
                    />
                  </>
                );
              }
              if (_e === "Purchase Request" || _e === "Purchase Order") {
                return (
                  <>
                    <Row noGutters>
                      <Col
                        className="pl-2 pr-2"
                        md={12}
                        xs={12}
                        sm={12}
                        lg={12}
                      >
                        <TxtInvoiceName state={props.state} />
                      </Col>
                      <Col
                        className="pl-2 pr-2"
                        md={12}
                        xs={12}
                        sm={12}
                        lg={12}
                      >
                        <TxtDescription state={props.state} />
                      </Col>
                      <Col
                        className="pl-2 pr-2"
                        md={12}
                        xs={12}
                        sm={12}
                        lg={12}
                      >
                        <TxtShippingAddress state={props.state} />
                      </Col>
                    </Row>
                    {props.state.selectedOperation.id === 1 ? (
                      <>
                        <Row className="mb-2" noGutters>
                          <Col
                            className="pl-2 pr-2"
                            md={6}
                            xs={12}
                            sm={12}
                            lg={4}
                          >
                            <TxtLastModifiedBy state={props.state} />
                          </Col>
                          <Col
                            className="pl-2 pr-2"
                            md={6}
                            xs={12}
                            sm={12}
                            lg={4}
                          >
                            <TxtApprovedByActor state={props.state} />
                          </Col>
                        </Row>
                      </>
                    ) : null}
                    <TypePR
                      state={props.state}
                      tableInvoiceContent={props.tableInvoiceContent}
                      tablePR={props.tablePR}
                      tablePRItems={props.tablePRItems}
                      PRItemHistoryTable={props.PRItemHistoryTable}
                    />
                  </>
                );
              }
              if (_e === "Delivery Receipt") {
                return (
                  <>
                    <Row noGutters>
                      <Col className="pl-2 pr-2">
                        <TxtInvoiceName state={props.state} />
                      </Col>
                      <Col className="pl-2 pr-2">
                        <TxtDescription state={props.state} />
                      </Col>
                      <Col className="pl-2 pr-2">
                        <TxtShippingAddress state={props.state} />
                      </Col>
                    </Row>
                    {/* <TypeDeliveryReceipt
                      state={props.state}
                      tableInvoiceContent={props.tableInvoiceContent}
                    /> */}
                  </>
                );
              }
              if (_e === "Transfer Inventory") {
                return (
                  <>
                    <Row noGutters>
                      <Col className="pl-2 pr-2" md={6} xs={12} sm={12} lg={4}>
                        <TxtInvoiceName state={props.state} />
                      </Col>
                      <Col className="pl-2 pr-2" md={6} xs={12} sm={12} lg={4}>
                        <TxtDescription state={props.state} />
                      </Col>
                    </Row>
                    <Row className="mb-2" noGutters>
                      {props.state.selectedOperation.id === 1 ? (
                        <>
                          <Col className="pl-2 pr-2">
                            <TxtDateAdded state={props.state} />
                          </Col>
                          <Col className="pl-2 pr-2">
                            <TxtDateLastModified state={props.state} />
                          </Col>
                          <Col className="pl-2 pr-2">
                            <TxtDateFinished state={props.state} />
                          </Col>
                        </>
                      ) : null}

                      <Col className="pl-2 pr-2">
                        <TxtDateDeadline state={props.state} />
                      </Col>
                    </Row>
                    {props.state.selectedOperation.id === 1 ? (
                      <>
                        <Row className="mb-2" noGutters>
                          <Col className="pl-2 pr-2">
                            <TxtLastModifiedBy state={props.state} />
                          </Col>
                          <Col className="pl-2 pr-2">
                            <TxtApprovedByActor state={props.state} />
                          </Col>
                        </Row>
                      </>
                    ) : null}
                    <Row noGutters>
                      <Col className="pl-2 pr-2">
                        <TxtNotes state={props.state} />
                      </Col>
                    </Row>
                    <TypeTransferInventory
                      state={props.state}
                      tableTransferInv={props.tableTransferInv}
                    />
                  </>
                );
              }
              if (_e === undefined) {
                return (
                  <>
                    <h3 className="" style={{ fontWeight: "bolder" }}>
                      Please select Invoice Type to create.
                    </h3>
                  </>
                );
              }
            })()}
          </div>
          <Form
            id="formActorInput"
            noValidate
            validated={props.state.handleInvoiceModal.isValidated}
            onSubmit={props.state.handleSubmit}
            className="invoiceModalForm"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Form.Group
              as={Row}
              hidden={props.state.userType.includes("Inventory Clerk")}
              className="invoiceModalFooter m-0"
              style={{ backgroundColor: "var(--REB-secondary)" }}
              noGutters
            >
              <Col md={6} lg={6} sm={3} xs={3} className="px-2 ">
                <Button
                  disabled={!props.state.handleInvoiceModal.isView}
                  className="invoiceModalFooterButtons"
                  style={{
                    backgroundColor: "var(--REB-yellow)",
                    color: "var(--REB-black)",
                  }}
                  onClick={async (e) => {
                    props.state.dispatch({
                      type: "MODAL_SHOW",
                      payload: {
                        title: "Confirmation",
                        desc: `Are you sure you want to archive this invoice? After admin verification, this invoice together with its related invoices and entries will be archived. All contents of this invoice will then be returned to inventory. Are you sure you want to proceed?`,
                        isYesNo: true,
                        cb: async (e) => {
                          const _srch = props.state.selDetailStatus.data.find(
                            (x) => x.name === "Pending Archive"
                          );
                          if (_srch) {
                            const data = {
                              data: {
                                id: props.state.selectedRowInvoice.id,
                                lastModifiedByActorId: Number(
                                  props.state.user.id
                                ),
                                invoiceStatusId: Number(_srch.id),
                              },
                            };

                            await invoiceApi.updateInvoice(
                              props.state.dispatch,
                              data
                            );
                            await props.state.refreshInvoice(
                              props.state.user.id,
                              true
                            );
                            props.state.handleInvoiceModal.toggle();
                          } else {
                            props.state.dispatch({
                              type: "MODAL_SHOW",
                              payload: {
                                title: "Invoice Details Incomplete",
                                desc: `Please add "Pending Archive" to invoice status`,
                                isYesNo: false,
                              },
                            });
                          }
                        },
                      },
                    });
                  }}
                >
                  ARCHIVE
                </Button>
              </Col>

              <Col md={2} lg={2} sm={3} xs={3} className="px-2 ">
                <Button
                  block
                  name="btnUnlockInvoice"
                  className="invoiceModalFooterButtons"
                  disabled={!props.state.handleInvoiceModal.isView}
                  style={{ backgroundColor: "var(--REB-green)" }}
                  onClick={(e) => {
                    if (
                      props.state.permissions.includes(
                        "perm-ui-invoicingPage-view-write-manage"
                      ) ||
                      props.state?.selectedRowInvoice?.invoiceStatus?.name ===
                        "New" ||
                      props.state?.selectedRowInvoice?.invoiceStatus?.name ===
                        "Admin Denied"
                    ) {
                      props.state.handleActionChange(e);
                    } else {
                      props.state.dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "No Permission",
                          desc: `You dont have access to modify invoices`,
                          isYesNo: false,
                        },
                      });
                    }
                  }}
                >
                  MODIFY
                </Button>
              </Col>
              <Col md={2} lg={2} sm={3} xs={3} className="px-2 ">
                <Button
                  block
                  name="btnSubmitInvoice"
                  className="invoiceModalFooterButtons"
                  disabled={props.state.handleInvoiceModal.isView}
                  style={{ backgroundColor: "var(--REB-green)" }}
                  type="submit"
                  // onClick={(e) => {
                  //   props.state.handleSubmit(e);
                  // }}
                >
                  SAVE
                </Button>
              </Col>
              <Col md={2} lg={2} sm={3} xs={3} className="px-2 ">
                <Button
                  block
                  name="btnDeleteInvoice"
                  className="invoiceModalFooterButtons"
                  style={{ backgroundColor: "var(--REB-warning)" }}
                  disabled={
                    props.state.handleInvoiceModal.isView ||
                    Number(props.state.selectedOperation.id) === 0
                  }
                  onClick={(e) => {
                    props.state.handleActionChange(e);
                  }}
                >
                  DELETE
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export {
  InvoiceModal,
  TxtInvoiceName,
  TxtDateAdded,
  TxtDateDeadline,
  TxtNotes,
  TxtShippingAddress,
};
