import React from "react";

import { GlobalToast } from "./GlobalToast";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

//const cookies = new Cookies();

class ToastProcess extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    //this.props.dispatch({ type: "MODAL_INIT", payload: url });
  }

  componentWillUnmount() {}

  render() {
    //console.log(this.props);
    return <GlobalToast redux={this.props} />;
  }

  // static getDerivedStateFromProps(nextProps, state) {
  //   console.log(nextProps);
  //   // if (nextProps.modal.globalModal.data) {
  //   //   if (!state.isFiltered) {
  //   //     //console.log("trigger");
  //   //     return {
  //   //       // empData: nextProps.modal.getData.data,
  //   //       // bakEmpData: nextProps.modal.getData.data,
  //   //       // isLoading: nextProps.modal.getData.loading
  //   //     };
  //   //   } else {
  //   //     return {};
  //   //   }
  //   // }
  // }
}

const mapStateToProps = state => {
  return {
    toast: state.globalToast
  };
};
export default connect(mapStateToProps)(withRouter(ToastProcess));
