import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import moment from "moment";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { NumericFormat } from "react-number-format";
import filters from "../../functions/filters";
import transactionsApi from "./TransactionsAPI";
import { SELECTED_OPERATION } from "../../constants";
import Select from "react-select";
import { Modal } from "react-bootstrap";

const DIncreaseDecrease = [
  { label: "Increase", value: "increase" },
  { label: "Decrease", value: "decrease" },
];

const customStyles = {
  menu: (provided, state) => {
    //console.log(state);
    const width = "100%";

    let color = state.selectProps.menuColor;
    const padding = 0;

    return { ...provided, width, color, padding };
  },
  control: (provided) => ({
    ...provided,
    borderRadius: "5px",
  }),

  container: (_, { selectProps: { width } }) => ({
    width: width,
    flex: 1,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,

    borderRadius: "5px 0px 0px 5px",
  }),

  option: (provided, state) => {
    let backgroundColor = "";
    let display = "block";
    let color = "black";
    if (state.data.isDefault === 1) {
      backgroundColor = "rgba(255, 0, 0, 0.2)";
    }
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    //console.log(state.data);
    if (state.data.isHidden === 1) {
      display = "none";
    }

    return {
      ...provided,
      opacity,
      transition,
      backgroundColor,
      color,
      display,
    };
  },
};

const initValidateTransaction = {
  TxtJournalTransDate: { error: false, message: "" },
  TableData: { error: false, message: "" },
  ContentTotals: { error: false, message: "" },
  UIValidate: { error: false, message: "", validated: false },
};

export const JournalEntryModal = (props) => {
  const {
    JournalEntryModalOpen,
    setJournalEntryModalOpen,
    setJournalEntryTableData,
    setJournalEntryTableSelection,
    TransTxtUniqueID,
    TransTxtDateAdded,
    TransTxtDateModified,
    TransTxtLastModifiedBy,
    TransTxtAddedBy,
    SelectedTransactionList,
    TransModalNotes,
    setTransModalNotes,
    TransactionSelectedOperation,
    CombinedAccounts,
    setTransactionSelectedOperation,
    SelInventory,
    refreshTransactions,
  } = props.state;

  const dispatch = useDispatch();

  const initUid = filters.uuidv4();

  const initTransModalContent = [
    {
      description: "",
      uid: initUid,
      id: "45f6cb43-54c2-423e-82f8-0f8cff6234sd",
      accountId: null,
      amountValue: 0,
      transType: "-",
      transStatus: "finished",
    },
    {
      description: "",
      id: "45f6cb43-54c2-423e-82f8-0f8cff625e91",
      uid: initUid,
      accountId: null,
      amountValue: 0,
      transType: "-",
      transStatus: "finished",
    },
  ];

  const [TransModalContent, setTransModalContent] = useState(
    initTransModalContent
  );
  const [ValidateTransaction, setValidateTransaction] = useState(
    initValidateTransaction
  );
  const [TxtJournalTransDate, setTxtJournalTransDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [TxtJournalGenDesc, setTxtJournalGenDesc] = useState("");
  const [TxtJournalNotes, setTxtJournalNotes] = useState("");
  const [IsModalLoading, setIsModalLoading] = useState(true);

  const [ContentTotals, setContentTotals] = useState({ debits: 0, credits: 0 });

  const [ChkDepoWith, setChkDepoWith] = useState(false);
  //Listen to TransModalContent and calculate content totals
  useEffect(() => {
    const debitList = TransModalContent.filter((x) => x.transType === "debit");
    const creditList = TransModalContent.filter(
      (x) => x.transType === "credit"
    );
    //console.log(debitList, creditList);
    const totalDebit = debitList.reduce((a, c) => {
      return a + c.amountValue;
    }, 0);
    const totalCredit = creditList.reduce((a, c) => {
      return a + c.amountValue;
    }, 0);
    setContentTotals({
      debits: Math.abs(totalDebit),
      credits: Math.abs(totalCredit),
    });
  }, [TransModalContent]);

  const TempModifyJournalContent = (value) => {
    setTransModalContent((journal) =>
      journal?.map((dat) => (dat.id === value.id ? { ...dat, ...value } : dat))
    );
  };
  //Listen to modal open and close; listen to selected operation of modal then populate modal
  useEffect(() => {
    async function fetchData() {
      setIsModalLoading(false);
      const fetchedData = await transactionsApi.getTransactionsByUid(
        dispatch,
        SelectedTransactionList?.uid
      );
      setTransModalContent(fetchedData);
      setIsModalLoading(false);
    }
    if (TransactionSelectedOperation.name === "View" && JournalEntryModalOpen) {
      fetchData();
      setTxtJournalTransDate(
        moment(SelectedTransactionList?.dateAdded).format("YYYY-MM-DD")
      );
      setTxtJournalGenDesc(SelectedTransactionList?.generalDesc);
      setTxtJournalNotes(SelectedTransactionList?.notes);
    }
  }, [TransactionSelectedOperation, JournalEntryModalOpen]);

  const validateTransaction = (validateComponents, cb) => {
    let tempUserInput = {
      ...ValidateTransaction,
      UIValidate: { error: false, message: "", validated: false },
    };

    if (
      validateComponents.includes("ContentTotals") ||
      validateComponents.includes("all")
    ) {
      ContentTotals.credits - ContentTotals.debits === 0
        ? (tempUserInput.ContentTotals = { error: false, message: "" })
        : (tempUserInput.ContentTotals = {
            error: true,
            message: "Journal Entries is not balance",
          });
    }
    if (
      validateComponents.includes("TxtJournalTransDate") ||
      validateComponents.includes("all")
    ) {
      TxtJournalTransDate !== null
        ? (tempUserInput.TxtJournalTransDate = { error: false, message: "" })
        : (tempUserInput.TxtJournalTransDate = {
            error: true,
            message: "Journal Entry Date cant be empty",
          });
    }

    TransModalContent.forEach((el) => {
      el.account !== null || el.account !== undefined
        ? (tempUserInput.TableData = { error: false, message: "" })
        : (tempUserInput.TableData = {
            error: true,
            message: "Journal Entry Table Incomplete",
          });
      el.amountValue !== null
        ? (tempUserInput.TableData = { error: false, message: "" })
        : (tempUserInput.TableData = {
            error: true,
            message: "Journal Entry Table Incomplete",
          });
      el.transType !== "-"
        ? (tempUserInput.TableData = { error: false, message: "" })
        : (tempUserInput.TableData = {
            error: true,
            message: "Journal Entry Table Incomplete",
          });
    });

    Object.keys(tempUserInput).forEach((key) => {
      if (tempUserInput[key].error) {
        tempUserInput.UIValidate = {
          error: true,
          message: "",
          validated: true,
        };
      }
    });
    //console.log(tempUserInput);
    setValidateTransaction(tempUserInput);
    if (tempUserInput.UIValidate.error) {
      dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Validation Error",
          desc: `Please check inputs`,
          isYesNo: false,
        },
      });
    } else {
      if (cb) cb();
    }
  };

  const resetJournalInputs = () => {
    setTransModalContent(initTransModalContent);
    setValidateTransaction(initValidateTransaction);
    setTxtJournalTransDate(moment().format("YYYY-MM-DD"));
    setTxtJournalGenDesc("");
    setContentTotals({ debits: 0, credits: 0 });
    setIsModalLoading(true);
    setTxtJournalNotes("");
  };

  const createMultiTransaction = async () => {
    let InventoryId = Number(SelInventory.id);
    // console.log(SelInventory);
    if (InventoryId === 1) {
      InventoryId = null;
    }
    const createUid = filters.uuidv4();
    const entries = [];

    for (const el of TransModalContent) {
      entries.push({
        transaction: {
          accountId: el.accountId,
          amountValue: el.amountValue,
          description: `${el.description}`,
          generalDesc: TxtJournalGenDesc,
          uid: createUid,
          transStatus: "finished",
          transType: el.transType,
          isEntry: 1,
          generalTransType: "journal",
          notes: TxtJournalNotes,
        },
      });
    }
    console.log("PRE ADD POST JOURNAL ENTRY ===> ", entries);

    const createdEntries = await transactionsApi.createMultiTransaction(
      dispatch,
      entries
    );
    console.log("CREATED JOURNAL ENTRIES ===> ", createdEntries);
    setJournalEntryModalOpen(false);
    resetJournalInputs();
    refreshTransactions();
    dispatch({
      type: "TOAST_ADD",
      payload: {
        title: `Successfully created Journal Entries`,
      },
    });
  };
  const modifyMultiTransaction = async () => {
    const entries = [];

    for (const el of TransModalContent) {
      entries.push({
        transaction: {
          id: el.id,
          accountId: el.accountId,
          amountValue: el.amountValue,
          description: `${el.description}`,
          generalDesc: TxtJournalGenDesc,
          transStatus: "finished",
          transType: el.transType,
          notes: TxtJournalNotes,
        },
      });
    }
    console.log("PRE EDIT POST JOURNAL ENTRY ===> ", entries);

    const createdEntries = await transactionsApi.updateMultiTransaction(
      dispatch,
      entries
    );
    console.log("MODIFIED JOURNAL ENTRIES ===> ", createdEntries);
    setJournalEntryModalOpen(false);
    resetJournalInputs();
    refreshTransactions();
    dispatch({
      type: "TOAST_ADD",
      payload: {
        title: `Successfully modified Journal Entries`,
      },
    });
  };

  return (
    <>
      <Modal
        show={JournalEntryModalOpen}
        onHide={() => {
          setJournalEntryModalOpen(false);
          setTransactionSelectedOperation(SELECTED_OPERATION.VIEW);
          setJournalEntryTableData([]);
          setJournalEntryTableSelection(null);
          resetJournalInputs();
        }}
        onExit={(e) => {
          setJournalEntryModalOpen(false);
          setTransactionSelectedOperation(SELECTED_OPERATION.VIEW);
          setJournalEntryTableData([]);
          setJournalEntryTableSelection(null);
          resetJournalInputs();
        }}
        dialogClassName="modal-actor-100w"
        //size="xl"
        centered
        // fullscreen={true}
      >
        <Modal.Header
          className="p-0 global-modal-header"
          style={{ backgroundColor: "var(--REB-primary)" }}
        >
          <Modal.Title className="p-2 global-modal-header-title">
            <Row noGutters>
              <Col xs="auto" className="h-100">
                <Button
                  style={{ backgroundColor: "var(--REB-warning)" }}
                  onClick={() => {
                    setJournalEntryModalOpen(false);
                  }}
                  className="h-100"
                >
                  <i className="icofont-ui-close"></i>
                </Button>
              </Col>
              <Col xs="auto" className="pl-3" style={{ alignSelf: "center" }}>
                <h6
                  className="text-center m-0 h-100"
                  style={{ color: "var(--REB-white)", fontWeight: "bolder" }}
                >
                  {TransactionSelectedOperation?.name} Journal Entry
                </h6>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row noGutters>
            <Col xs={12} sm={6} md={4} lg={3} className="px-1 mb-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: "var(--REB-white)",
                    color: "var(--REB-black)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                    WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                    MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                    boxShadow: "inset 0px 0px 0px 1px #ced4da",
                  }}
                >
                  Unique ID
                </p>
                <FormControl
                  placeholder="Unique ID"
                  className="h-100"
                  value={TransTxtUniqueID}
                  disabled
                />
              </div>
            </Col>
            <Col xs={12} sm={6} md={4} lg={3} className="px-1 mb-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: "var(--REB-white)",
                    color: "var(--REB-black)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                    WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                    MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                    boxShadow: "inset 0px 0px 0px 1px #ced4da",
                  }}
                >
                  Date Added
                </p>
                <FormControl
                  placeholder="Date Added"
                  className="h-100"
                  value={TransTxtDateAdded}
                  disabled
                />
              </div>
            </Col>
            <Col xs={12} sm={6} md={4} lg={3} className="px-1 mb-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: "var(--REB-white)",
                    color: "var(--REB-black)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                    WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                    MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                    boxShadow: "inset 0px 0px 0px 1px #ced4da",
                  }}
                >
                  Date Modified
                </p>
                <FormControl
                  placeholder="Date Modified"
                  className="h-100"
                  value={TransTxtDateModified}
                  disabled
                />
              </div>
            </Col>
          </Row>
          <Row noGutters>
            <Col xs={12} sm={6} md={6} lg={6} className="px-1 mb-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",

                  // border: "1px solid #ced4da",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: "var(--REB-white)",
                    color: "var(--REB-black)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                    WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                    MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                    boxShadow: "inset 0px 0px 0px 1px #ced4da",
                  }}
                >
                  Added By
                </p>
                <FormControl
                  placeholder="Added By"
                  className="h-100"
                  value={TransTxtAddedBy}
                  disabled
                />
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} className="px-1 mb-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: "var(--REB-white)",
                    color: "var(--REB-black)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                    WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                    MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                    boxShadow: "inset 0px 0px 0px 1px #ced4da",
                  }}
                >
                  Modified By
                </p>
                <FormControl
                  placeholder="Modified By"
                  className="h-100"
                  value={TransTxtLastModifiedBy}
                  disabled
                />
              </div>
            </Col>
          </Row>
          <Row noGutters>
            <Col xs={12} sm={12} md={4} lg={4} className="px-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: "var(--REB-primary)",
                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Transaction Date
                </p>
                <FormControl
                  type="date"
                  placeholder="Date Filter"
                  value={TxtJournalTransDate}
                  disabled={
                    TransactionSelectedOperation !== SELECTED_OPERATION.EDIT &&
                    TransactionSelectedOperation !== SELECTED_OPERATION.ADD
                  }
                  onChange={(x) => {
                    setTxtJournalTransDate(x.currentTarget.value);
                  }}
                  className=""
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} className="px-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: "var(--REB-primary)",
                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  General Journal Description
                </p>
                <FormControl
                  type="text"
                  placeholder="Optional"
                  value={TxtJournalGenDesc}
                  disabled={
                    TransactionSelectedOperation !== SELECTED_OPERATION.EDIT &&
                    TransactionSelectedOperation !== SELECTED_OPERATION.ADD
                  }
                  onChange={(x) => {
                    setTxtJournalGenDesc(x.currentTarget.value);
                  }}
                  className=""
                />
              </div>
            </Col>
          </Row>
          {/* <div className="px-3 py-3">
            <style>
              {`
                @media (max-width: 991px) {
                }
                @media (min-width: 992px) {
                }
                .ChkDepoWith > input {
                  transform: scale(2);
                }
                .ChkDepoWith > label {
                  padding-left: 10px;
                }
              `}
            </style>

            <Form.Check
              checked={ChkDepoWith}
              name="ChkDepoWith"
              className="ChkDepoWith"
              label="Deposit | Withdraw Entry"
              disabled={
                TransactionSelectedOperation !== SELECTED_OPERATION.EDIT &&
                TransactionSelectedOperation !== SELECTED_OPERATION.ADD
              }
              onChange={(e) => {
                setChkDepoWith(!ChkDepoWith);

                if (ChkDepoWith) {
                  resetJournalInputs();
                } else {
                  setTransModalContent([]);
                }
              }}
            />
          </div> */}

          <div
            style={{
              display: "flex",
              backgroundColor: "var(--REB-primary)",
              borderRadius: "10px 10px 0px 0px",
            }}
            className="mt-2"
          >
            <h5
              className="p-3 m-0"
              style={{ color: "white", fontWeight: "bolder" }}
            >
              Journal Entry Content
            </h5>
          </div>
          <div>
            <style>
              {`
                input[type="number"]::-webkit-inner-spin-button,
                input[type="number"]::-webkit-outer-spin-button {
                  opacity: 1;
                }
                .voucherContentHeaderP {
                  text-align: center;
                  color: var(--REB-secondary);
                  font-weight: bolder;
                }
              `}
            </style>
            <div>
              <Row
                style={{ border: "solid 1px #ced4da" }}
                className=""
                noGutters
              >
                <Col xs={12} sm={6} md={4} lg={4}>
                  <p
                    className="m-0 p-0 voucherContentHeaderP"
                    style={{ textAlign: "center" }}
                  >
                    Account
                  </p>
                </Col>

                <Col xs={12} sm={6} md={4} lg={2}>
                  <p
                    className="m-0 p-0 voucherContentHeaderP"
                    style={{ textAlign: "center" }}
                  >
                    INCREASE / DECREASE
                  </p>
                </Col>
                <Col xs={12} sm={6} md={4} lg={2}>
                  <p
                    className="m-0 p-0 voucherContentHeaderP"
                    style={{ textAlign: "center" }}
                  >
                    AMOUNT
                  </p>
                </Col>
                <Col xs={12} sm={6} md={4} lg={3}>
                  <p
                    className="m-0 p-0 voucherContentHeaderP"
                    style={{ textAlign: "center" }}
                  >
                    LINE DESCRIPTION
                  </p>
                </Col>
                <Col xs={12} sm={6} md={4} lg={1}></Col>
              </Row>
              {TransModalContent.map((cont, index) => {
                //console.log(cont)
                return (
                  <Row
                    style={{ border: "solid 1px #ced4da" }}
                    className="pt-2"
                    noGutters
                    key={cont.id}
                  >
                    <Col xs={12} sm={6} md={4} lg={4} className="px-2">
                      <div
                        className="mb-2"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: cont.account
                            ? "var(--REB-primary)"
                            : "var(--REB-warning)",
                          borderRadius: "5px",
                        }}
                      >
                        <p
                          className="m-0 pl-2"
                          style={{
                            // backgroundColor: "var(--REB-primary)",
                            color: "var(--REB-white)",
                            fontWeight: "bold",
                            fontSize: "1rem",
                          }}
                        >
                          Account
                        </p>
                        <Select
                          styles={customStyles}
                          isSearchable
                          menuPortalTarget={document.body}
                          width="100%"
                          options={CombinedAccounts}
                          value={cont.account}
                          getOptionLabel={(x) => x.name}
                          getOptionValue={(x) => x.id}
                          isDisabled={
                            TransactionSelectedOperation !==
                              SELECTED_OPERATION.EDIT &&
                            TransactionSelectedOperation !==
                              SELECTED_OPERATION.ADD
                          }
                          onChange={(x, name) => {
                            TempModifyJournalContent({
                              id: cont.id,
                              account: x,
                              accountId: x.id,
                              transTypeValue: null,
                            });
                          }}
                        />
                      </div>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={2} className="px-2">
                      <div
                        className="mb-2"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: cont.transTypeValue || cont.transType
                            ? "var(--REB-primary)"
                            : "var(--REB-warning)",
                          borderRadius: "5px",
                        }}
                      >
                        <p
                          className="m-0 pl-2"
                          style={{
                            // backgroundColor: "var(--REB-primary)",
                            color: "var(--REB-white)",
                            fontWeight: "bold",
                            fontSize: "1rem",
                            textTransform: "capitalize",
                          }}
                        >
                          {cont.transType}
                        </p>
                        <Select
                          styles={customStyles}
                          isSearchable
                          menuPortalTarget={document.body}
                          width="100%"
                          options={DIncreaseDecrease}
                          value={cont.transTypeValue}
                          isDisabled={
                            (TransactionSelectedOperation !==
                              SELECTED_OPERATION.EDIT &&
                              TransactionSelectedOperation !==
                                SELECTED_OPERATION.ADD) ||
                            !cont?.account
                          }
                          onChange={(x, name) => {
                            //console.log(cont?.account?.accountType);
                            if (cont?.account) {
                              let transType = "debit";

                              if (cont?.account?.accountType === "expenses") {
                                if (x.value === "increase") transType = "debit";
                                if (x.value === "decrease")
                                  transType = "credit";
                              }
                              if (cont?.account?.accountType === "revenues") {
                                if (x.value === "increase")
                                  transType = "credit";
                                if (x.value === "decrease") transType = "debit";
                              }
                              if (cont?.account?.accountType === "assets") {
                                if (x.value === "increase") transType = "debit";
                                if (x.value === "decrease")
                                  transType = "credit";
                              }
                              if (
                                cont?.account?.accountType === "liabilities"
                              ) {
                                if (x.value === "increase")
                                  transType = "credit";
                                if (x.value === "decrease") transType = "debit";
                              }
                              if (cont?.account?.accountType === "equity") {
                                if (x.value === "increase")
                                  transType = "credit";
                                if (x.value === "decrease") transType = "debit";
                              }

                              TempModifyJournalContent({
                                id: cont.id,
                                transType: transType,
                                transTypeValue: DIncreaseDecrease.find(
                                  (d) => d.value === x.value
                                ),
                              });
                            } else {
                              dispatch({
                                type: "MODAL_SHOW",
                                payload: {
                                  title: "Incomplete Input",
                                  desc: `Please provide account first`,
                                  isYesNo: false,
                                },
                              });
                            }
                          }}
                        />
                      </div>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={2} className="px-2">
                      <div
                        className="mb-2"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: true
                            ? "var(--REB-primary)"
                            : "var(--REB-warning)",
                          borderRadius: "5px",
                        }}
                      >
                        <p
                          className="m-0 pl-2"
                          style={{
                            // backgroundColor: "var(--REB-primary)",
                            color: "var(--REB-white)",
                            fontWeight: "bold",
                            fontSize: "1rem",
                          }}
                        >
                          Amount
                        </p>
                        <FormControl
                          as={NumericFormat}
                          inputMode="numeric"
                          placeholder="Amount"
                          thousandSeparator={true}
                          prefix={"₱ "}
                          decimalScale={2}
                          allowNegative={true}
                          disabled={
                            TransactionSelectedOperation !==
                              SELECTED_OPERATION.EDIT &&
                            TransactionSelectedOperation !==
                              SELECTED_OPERATION.ADD
                          }
                          value={Number(cont.amountValue)}
                          //   onBlur={(x) => {
                          //     TempModifyJournalContent({
                          //       id: cont.id,
                          //       amountValue: x.currentTarget.value,
                          //     });
                          //   }}
                          onValueChange={(x) => {
                            TempModifyJournalContent({
                              id: cont.id,
                              amountValue: Number(x.value),
                            });
                            // setTransModalContentAmount(x.value);
                          }}
                        />
                      </div>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={3} className="px-2">
                      <div
                        className="mb-2"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: "var(--REB-primary)",
                          borderRadius: "5px",
                        }}
                      >
                        <p
                          className="m-0 pl-2"
                          style={{
                            // backgroundColor: "var(--REB-primary)",
                            color: "var(--REB-white)",
                            fontWeight: "bold",
                            fontSize: "1rem",
                          }}
                        >
                          Description
                        </p>
                        <FormControl
                          type="text"
                          //style={{ textTransform: "uppercase" }}
                          placeholder="Optional"
                          value={cont.description}
                          disabled={
                            TransactionSelectedOperation !==
                              SELECTED_OPERATION.EDIT &&
                            TransactionSelectedOperation !==
                              SELECTED_OPERATION.ADD
                          }
                          onChange={(x) =>
                            TempModifyJournalContent({
                              id: cont.id,
                              description: x.currentTarget.value,
                            })
                          }
                          className=""
                        />
                      </div>
                    </Col>
                    <Col
                      xs={12}
                      sm={6}
                      md={4}
                      lg={1}
                      className="px-2"
                      style={{ alignSelf: "center", textAlign: "center" }}
                    >
                      {TransModalContent?.length > 2 && (
                        <Button
                          onClick={() => {
                            let modifiedTransModalContent = [
                              ...TransModalContent,
                            ];
                            modifiedTransModalContent =
                              modifiedTransModalContent.filter(
                                (x) => x.id !== cont.id
                              );

                            setTransModalContent(modifiedTransModalContent);
                          }}
                          style={{ backgroundColor: "var(--REB-warning)" }}
                          disabled={
                            TransactionSelectedOperation !==
                              SELECTED_OPERATION.EDIT &&
                            TransactionSelectedOperation !==
                              SELECTED_OPERATION.ADD
                          }
                          className="mx-1"
                        >
                          <i
                            style={{ color: "var(--REB-white)" }}
                            className="icofont-trash"
                          ></i>
                        </Button>
                      )}
                    </Col>
                  </Row>
                );
              })}
              <Row className="my-2" noGutters>
                <Col xs={6}>
                  <Button
                    onClick={() => {
                      let modifiedTransModalContent = [...TransModalContent];
                      modifiedTransModalContent.push({
                        description: "",
                        id: filters.uuidv4(),
                        accountId: null,
                        amountValue: null,
                        transType: "-",
                        transStatus: "finished",
                      });

                      setTransModalContent(modifiedTransModalContent);
                    }}
                    style={{ backgroundColor: "var(--REB-primary)" }}
                    disabled={
                      TransactionSelectedOperation !==
                        SELECTED_OPERATION.EDIT &&
                      TransactionSelectedOperation !== SELECTED_OPERATION.ADD
                    }
                    className="mx-1"
                  >
                    Add Row
                  </Button>
                </Col>
                <Col xs={6}>
                  <style>
                    {`
                      .statsLabel {
                        font-size: 1.2rem;
                      }
                    `}
                  </style>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", alignSelf: "end" }}>
                      <p className="m-0 px-2 statsLabel">Total Debits:</p>
                      <p className="m-0 px-2 statsLabel">
                        <NumericFormat
                          value={ContentTotals.debits}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale
                        />
                      </p>
                    </div>
                    <div style={{ display: "flex", alignSelf: "end" }}>
                      <p className="m-0 px-2 statsLabel">Total Credits:</p>
                      <p className="m-0 px-2 statsLabel">
                        <NumericFormat
                          value={ContentTotals.credits}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale
                        />
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignSelf: "end",
                        color:
                          ContentTotals.debits - ContentTotals.credits === 0
                            ? "var(--REB-black)"
                            : "var(--REB-warning)",
                        fontWeight:
                          ContentTotals.debits - ContentTotals.credits === 0
                            ? "initial"
                            : "bolder",
                      }}
                    >
                      <p className="m-0 px-2 statsLabel">Difference:</p>
                      <p className="m-0 px-2 statsLabel">
                        <NumericFormat
                          value={ContentTotals.debits - ContentTotals.credits}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale
                        />
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div>
            {(() => {
              let disabled = false;
              if (
                TransactionSelectedOperation !== SELECTED_OPERATION.EDIT &&
                TransactionSelectedOperation !== SELECTED_OPERATION.ADD
              ) {
                disabled = true;
              }
              if (SelectedTransactionList?.transStatus === "admin-denied") {
                disabled = false;
              }
              return (
                <>
                  {" "}
                  <Form.Label
                    className="font-weight-bold"
                    style={{ color: "var(--REB-black)" }}
                  >
                    Notes
                  </Form.Label>
                  <FormControl
                    as="textarea"
                    //style={{ textTransform: "uppercase" }}
                    placeholder="Notes"
                    name="txtNotes"
                    value={TxtJournalNotes}
                    onChange={(e) => {
                      setTxtJournalNotes(e.currentTarget.value);
                    }}
                    disabled={disabled}
                  />
                </>
              );
            })()}
          </div>
        </Modal.Body>
        <Modal.Footer className="global-modal-footer">
          <Row className="w-100" noGutters>
            {(TransactionSelectedOperation === SELECTED_OPERATION.ADD ||
              TransactionSelectedOperation === SELECTED_OPERATION.EDIT) && (
              <Col xs={4} sm={4} md={4} lg={4} className="px-2">
                <Button
                  block
                  name="btnSubmitInvoice"
                  disabled={
                    TransactionSelectedOperation !== SELECTED_OPERATION.EDIT &&
                    TransactionSelectedOperation !== SELECTED_OPERATION.ADD
                  }
                  style={{ backgroundColor: "var(--REB-green)" }}
                  onClick={(e) => {
                    TransactionSelectedOperation === SELECTED_OPERATION.ADD &&
                      validateTransaction(["all"], () => {
                        createMultiTransaction();
                      });
                    // console.log(TransactionSelectedOperation);
                    TransactionSelectedOperation === SELECTED_OPERATION.EDIT &&
                      validateTransaction(["all"], () =>
                        modifyMultiTransaction()
                      );
                  }}
                >
                  SAVE
                </Button>
              </Col>
            )}
            {TransactionSelectedOperation === SELECTED_OPERATION.VIEW &&
              SelectedTransactionList?.generalTransType === "journal" && (
                <>
                  <Col xs={4} sm={4} md={4} lg={4} className=" px-2">
                    <Button
                      block
                      name="btnUnlockInvoice"
                      disabled={
                        TransactionSelectedOperation ===
                          SELECTED_OPERATION.EDIT_CONTENT ||
                        TransactionSelectedOperation ===
                          SELECTED_OPERATION.ADD_CONTENT
                      }
                      style={{ backgroundColor: "var(--REB-green)" }}
                      onClick={(e) => {
                        setTransactionSelectedOperation(
                          SELECTED_OPERATION.EDIT
                        );
                      }}
                    >
                      MODIFY
                    </Button>
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} className="px-2">
                    <Button
                      block
                      name="btnDeleteInvoice"
                      style={{ backgroundColor: "var(--REB-warning)" }}
                      disabled={
                        TransactionSelectedOperation ===
                          SELECTED_OPERATION.EDIT_CONTENT ||
                        TransactionSelectedOperation ===
                          SELECTED_OPERATION.ADD_CONTENT
                      }
                      // disabled={
                      //   props.state.handleInvoiceModal.isView ||
                      //   Number(props.state.selectedOperation.id) === 0
                      // }
                      onClick={async (e) => {
                        dispatch({
                          type: "MODAL_SHOW",
                          payload: {
                            title: "Confirmation",
                            desc: `Are you sure you want to delete this journal entries?`,
                            isYesNo: true,
                            cb: async (e) => {
                              setTransactionSelectedOperation(
                                SELECTED_OPERATION.VIEW_CONTENT
                              );

                              await transactionsApi.deleteTransactionByUid(
                                dispatch,
                                SelectedTransactionList.uid
                              );
                              setJournalEntryModalOpen(false);
                              resetJournalInputs();
                              refreshTransactions();
                              dispatch({
                                type: "TOAST_ADD",
                                payload: {
                                  title: `Successfully removed transactions`,
                                },
                              });
                            },
                          },
                        });

                        //props.state.handleActionChange(e);
                      }}
                    >
                      DELETE
                    </Button>
                  </Col>
                </>
              )}
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};
