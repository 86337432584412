import React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

//import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tooltip from "react-bootstrap/Tooltip";
//import FileViewer from "react-file-viewer";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

class FileViewerModal extends React.Component {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    //console.log(this.props.modal.doc);

    return (
      <>
        <Modal
          show={this.props.modal.show}
          onHide={(e) => {
            this.props.dispatch({ type: "FILE_VIEWER_MODAL_TOGGLE" });
          }}
          dialogClassName="pdf-modal-dialog"
          className="pdf-modal"
          centered
        >
          {/* <FileViewer fileType="pdf" filePath={url} /> */}
          <Modal.Body className="pdf-modal-body">
            <Row className="pdf-modal-header pt-3 pb-3 mb-3" noGutters>
              <Col xs={1} sm={1} md={1} lg={1}>
                <OverlayTrigger
                  placement={"right"}
                  overlay={<Tooltip id={`project-tooltip-add`}>Close</Tooltip>}
                >
                  <Button
                    variant="secondary"
                    name="btnCloseProjectModal"
                    className="btnAddProject project-btn-crud"
                    onClick={(e) => {
                      this.props.dispatch({ type: "FILE_VIEWER_MODAL_TOGGLE" });
                    }}
                  >
                    <i className="icofont-rounded-left"></i>
                  </Button>
                </OverlayTrigger>
              </Col>
              <Col xs={11} sm={11} md={11} lg={11}>
                <h4 className="font-weight-bold pdf-modal-header-text">
                  File Viewer
                </h4>
              </Col>
            </Row>
            {/* <PDFViewer className="pdfViewer">{this.props.modal.doc}</PDFViewer> */}
            <style>
        {`
          #pdf-renderer {
            background-color: #d3d3d3
          }
          
        `}
      </style>
            <DocViewer
              pluginRenderers={DocViewerRenderers}
              config={{
                pdfZoom: {
                  defaultZoom: 1, // 1 as default,
                  zoomJump: 0.2, // 0.1 as default,
                },
              }}
              theme={{
                primary: "#5296d8",
                secondary: "#ffffff",
                tertiary: "#5296d899",
                textPrimary: "#ffffff",
                textSecondary: "#5296d8",
                textTertiary: "#00000099",
                disableThemeScrollbar: false,
              }}
              documents={[
                {
                  uri: this.props.modal.doc,
                  fileName: this.props.modal.filename
                    ? this.props.modal.filename
                    : "Generated PDF",
                },
              ]}
            />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modal: state.FileViewerModal,
  };
};
export default connect(mapStateToProps)(withRouter(FileViewerModal));
