import React from "react";

import pdfTemplate from "../../../components/PdfHeadFoot";
//import TESTIMG from "../../../assets/executiveSig.png";
import { Text, View, StyleSheet, Font, Canvas } from "@react-pdf/renderer";
import misc from "../../../functions/misc";
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf",
      fontWeight: 800,
    },
  ],
});
let themeColor = "";
if (process.env.REACT_APP_THEME === "SURFACEPLUS") themeColor = "#5eaaff";
if (process.env.REACT_APP_THEME === "DEMO") themeColor = "#ed5309";
const tableStyles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  tableHeader: {
    textAlign: "left",
    fontSize: 9,
    marginLeft: 2,
    marginRight: 2,
    marginBottom: 2,
    color: "#5eaaff",
    fontFamily: "Open Sans",
    fontWeight: 800,
    border: 0,
  },
  tableCell: {
    border: 0,
    textAlign: "left",
    // height: 12,
    // marginLeft: 2,
    marginRight: 2,
  },
  tableCellNames: {
    fontSize: 8,
    border: 0,
    marginTop: 1,
    marginBottom: 1,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    border: 0,
  },
});

class ChargeInvoice {
  _itemTable(data) {
    return (
      <Table data={data}>
        <TableHeader
          includeBottomBorder={true}
          includeLeftBorder={false}
          includeRightBorder={false}
          includeTopBorder={false}
          style={[
            {
              border: 0,
            },
          ]}
        >
          {/* <TableCell weighting={0.08} style={[tableStyles.tableHeader]}>
            #
          </TableCell> */}

          <TableCell weighting={0.55} style={[tableStyles.tableHeader]}>
            PARTICULARS
          </TableCell>
          <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
            UNIT
          </TableCell>
          <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
            COST
          </TableCell>
          <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
            AMOUNT
          </TableCell>
        </TableHeader>
        <TableBody
          includeBottomBorder={false}
          includeLeftBorder={false}
          includeRightBorder={false}
          includeTopBorder={false}
        >
          {/* <DataTableCell
            style={[tableStyles.tableCell]}
            weighting={0.08}
            getContent={(d) => (d.index === null ? "" : d.index)}
          /> */}

          {/* <DataTableCell
                    style={[tableStyles.tableCell]}
                    getContent={(d) =>
                      d.material.unit.name === null ? "" : d.material.unit.name
                    }
                  /> */}
          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.55}
            getContent={(a) => {
              let name = "-";

              if (a?.item?.details?.length > 0) {
                const find = a?.item?.details?.find((x) => x.type === 1);
                find ? (name = find?.name) : (name = "-");
              }
              if (!a.itemId) {
                name = JSON.parse(a.serviceName).label;
              }
              return (
                <>
                  <View style={{ flexDirection: "column" }}>
                    <Text style={[{ marginLeft: 5 }]}>{name}</Text>
                    <Text style={[{ marginLeft: 5 }]}>
                      {a?.item?.description}
                    </Text>
                  </View>
                </>
              );
            }}
          />
          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.15}
            getContent={(a) => {
              let unit = "";
              if (a?.item?.details?.length > 0) {
                const find = a.item.details.find((x) => x.type === 2);
                find ? (unit = find.name) : (unit = "-");
              }

              return (
                <>
                  <View style={{ flexDirection: "row" }}>
                    <Text style={[{ marginLeft: 5 }]}>
                      {`${a.itemQty} ${unit}`}
                    </Text>
                  </View>
                </>
              );
              // if (d.itemId === null) {
              //   name = "Service";
              // } else {
              //   if (d.item.details.length > 0) {
              //     const sDat = d.item.details.find((x) => x.type === 2);
              //     sDat !== undefined ? (name = sDat.name) : (name = "-");
              //   }
              // }

              // return `${
              //   d.qtyDelivered === 0 ? d.itemQty : d.qtyDelivered
              // } ${name}`;
            }}
          />
          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.15}
            getContent={(d) => {
              let name = "-";
              return (
                <>
                  <View style={{ flexDirection: "column" }}>
                    <Text style={[{ marginLeft: 5 }]}>
                      {misc.parseCurrencyNoCurrency(d.srp)}
                    </Text>
                    {d.discount > 0 && (
                      <>
                        <Text style={[{ marginLeft: 7 }]}>*Discounted</Text>
                        <Text style={[{ marginLeft: 5 }]}>
                          {misc.parseCurrencyNoCurrency(d.price)}
                        </Text>
                      </>
                    )}
                  </View>
                </>
              );
              // if (d.itemId === null) {
              //   name = "Service";
              // } else {
              //   if (d.item.details.length > 0) {
              //     const sDat = d.item.details.find((x) => x.type === 2);
              //     sDat !== undefined ? (name = sDat.name) : (name = "-");
              //   }
              // }

              // return `${
              //   d.qtyDelivered === 0 ? d.itemQty : d.qtyDelivered
              // } ${name}`;
            }}
          />
          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.15}
            getContent={(d) => {
              let name = "-";
              return (
                <>
                  <View style={{ flexDirection: "row" }}>
                    <Text style={[{ marginLeft: 5 }]}>
                      {misc.parseCurrencyNoCurrency(d.price * d.itemQty)}
                    </Text>
                  </View>
                </>
              );
              // if (d.itemId === null) {
              //   name = "Service";
              // } else {
              //   if (d.item.details.length > 0) {
              //     const sDat = d.item.details.find((x) => x.type === 2);
              //     sDat !== undefined ? (name = sDat.name) : (name = "-");
              //   }
              // }

              // return `${
              //   d.qtyDelivered === 0 ? d.itemQty : d.qtyDelivered
              // } ${name}`;
            }}
          />
          {/* <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.15}
            getContent={(d) => (d.srp === null ? "-" : `${d.srp}`)}
          /> */}
        </TableBody>
      </Table>
    );
  }
  _paymentTable(data) {
    const dataCount = data.length;

    return (
      <Table data={data}>
        <TableHeader
          includeBottomBorder={true}
          includeLeftBorder={false}
          includeRightBorder={false}
          includeTopBorder={false}
          style={[
            {
              border: 0,
            },
          ]}
        >
          {/* <TableCell weighting={0.08} style={[tableStyles.tableHeader]}>
            #
          </TableCell> */}

          <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
            AMOUNT
          </TableCell>
          <TableCell weighting={0.4} style={[tableStyles.tableHeader]}>
            TYPE
          </TableCell>
          <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
            STATUS
          </TableCell>
          <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
            DATE ISSUED
          </TableCell>
          <TableCell weighting={0.15} style={[tableStyles.tableHeader]}>
            DATE PAID
          </TableCell>
        </TableHeader>
        <TableBody
          includeBottomBorder={false}
          includeLeftBorder={false}
          includeRightBorder={false}
          includeTopBorder={false}
        >
          {/* <DataTableCell
            style={[tableStyles.tableCell]}
            weighting={0.08}
            getContent={(d) => (d.index === null ? "" : d.index)}
          /> */}

          {/* <DataTableCell
                    style={[tableStyles.tableCell]}
                    getContent={(d) =>
                      d.material.unit.name === null ? "" : d.material.unit.name
                    }
                  /> */}
          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.15}
            getContent={(a) => {
              const parsedAmount = misc.parseCurrencyNoCurrency(a?.amountValue);
              return (
                <View
                  style={{
                    flexDirection: "column",
                    backgroundColor:
                      dataCount === a.index ? "#5eaaff" : "#ffff",
                  }}
                >
                  {dataCount === a.index && (
                    <Text
                      style={[
                        {
                          marginLeft: 5,
                          color: "#ffff",

                          fontWeight: dataCount === a.index ? "bold" : "light",
                        },
                      ]}
                    >
                      Current Due
                    </Text>
                  )}

                  <Text
                    style={[
                      {
                        marginLeft: 5,
                        color: dataCount === a.index ? "#ffff" : "#000000",
                        fontWeight: dataCount === a.index ? "bold" : "light",
                      },
                    ]}
                  >
                    {parsedAmount}
                  </Text>
                </View>
              );
            }}
          />
          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.4}
            getContent={(a) => {
              return (
                <>
                  <View style={{ flexDirection: "row" }}>
                    {a?.transStatus === "pending-admin-approval" ? (
                      <Text style={[{ marginLeft: 5 }]}>pending payment</Text>
                    ) : (
                      <Text style={[{ marginLeft: 5 }]}>
                        {`${a?.paymentType} `}
                      </Text>
                    )}

                    {a?.paymentBankName && (
                      <Text style={[{ marginLeft: 5 }]}>
                        {`${a?.paymentBankName} `}
                      </Text>
                    )}
                    {a?.paymentBankNumber && (
                      <Text style={[{ marginLeft: 5 }]}>
                        {`${a?.paymentBankNumber} `}
                      </Text>
                    )}
                    {a?.checkInfo1 && (
                      <Text style={[{ marginLeft: 5 }]}>
                        {`${a?.checkInfo1} `}
                      </Text>
                    )}
                  </View>
                </>
              );
              // if (d.itemId === null) {
              //   name = "Service";
              // } else {
              //   if (d.item.details.length > 0) {
              //     const sDat = d.item.details.find((x) => x.type === 2);
              //     sDat !== undefined ? (name = sDat.name) : (name = "-");
              //   }
              // }

              // return `${
              //   d.qtyDelivered === 0 ? d.itemQty : d.qtyDelivered
              // } ${name}`;
            }}
          />
          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.15}
            getContent={(a) => {
              let name = "";

              if (a?.transStatus === "finished") {
                name = "Paid";
              }

              return (
                <>
                  <View style={{ flexDirection: "row" }}>
                    <Text style={[{ marginLeft: 5 }]}>{`${name}`}</Text>
                  </View>
                </>
              );
              // if (d.itemId === null) {
              //   name = "Service";
              // } else {
              //   if (d.item.details.length > 0) {
              //     const sDat = d.item.details.find((x) => x.type === 2);
              //     sDat !== undefined ? (name = sDat.name) : (name = "-");
              //   }
              // }

              // return `${
              //   d.qtyDelivered === 0 ? d.itemQty : d.qtyDelivered
              // } ${name}`;
            }}
          />
          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.15}
            getContent={(a) => {
              const parsedDate = misc.parseAPIDateTimeToShortDate(
                a?.datePosted
              );
              return (
                <>
                  <View style={{ flexDirection: "column" }}>
                    <Text style={[{ marginLeft: 5 }]}>{parsedDate}</Text>
                  </View>
                </>
              );
              // if (d.itemId === null) {
              //   name = "Service";
              // } else {
              //   if (d.item.details.length > 0) {
              //     const sDat = d.item.details.find((x) => x.type === 2);
              //     sDat !== undefined ? (name = sDat.name) : (name = "-");
              //   }
              // }

              // return `${
              //   d.qtyDelivered === 0 ? d.itemQty : d.qtyDelivered
              // } ${name}`;
            }}
          />
          <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.15}
            getContent={(a) => {
              const parsedDate = a?.paymentDate
                ? misc.parseAPIDateTimeToShortDate(a?.paymentDate)
                : "-";
              return (
                <>
                  <View style={{ flexDirection: "row" }}>
                    <Text style={[{ marginLeft: 5 }]}>{parsedDate}</Text>
                  </View>
                </>
              );
              // if (d.itemId === null) {
              //   name = "Service";
              // } else {
              //   if (d.item.details.length > 0) {
              //     const sDat = d.item.details.find((x) => x.type === 2);
              //     sDat !== undefined ? (name = sDat.name) : (name = "-");
              //   }
              // }

              // return `${
              //   d.qtyDelivered === 0 ? d.itemQty : d.qtyDelivered
              // } ${name}`;
            }}
          />
          {/* <DataTableCell
            style={[tableStyles.tableCell, tableStyles.tableCellNames]}
            weighting={0.15}
            getContent={(d) => (d.srp === null ? "-" : `${d.srp}`)}
          /> */}
        </TableBody>
      </Table>
    );
  }
  _termsSignature(incharge, approvedBy) {
    return (
      <>
        <View
          style={[
            {
              marginTop: 3,
              flexDirection: "row",
              paddingLeft: 20,
              paddingRight: 20,
            },
          ]}
        >
          <View style={[{ flex: 1 }]}>
            <Text
              style={[
                {
                  fontSize: 10,
                  color: themeColor,
                  fontFamily: "Open Sans",
                  fontWeight: 800,
                },
              ]}
            >
              Generated By:
            </Text>
            <Text
              style={[
                {
                  fontSize: 10,
                },
              ]}
            >
              {incharge !== null && incharge !== undefined
                ? `${incharge.lastName ? incharge.lastName + ", " : ""} ${
                    incharge.firstName ? incharge.firstName : ""
                  }`
                : "-"}
            </Text>
          </View>

          <View style={[{ flex: 1 }]}>
            <Text
              style={[
                {
                  fontSize: 10,
                  color: themeColor,
                  fontFamily: "Open Sans",
                  fontWeight: 800,
                },
              ]}
            >
              Approved by:
            </Text>
            <Text
              style={[
                {
                  fontSize: 10,
                },
              ]}
            >
              {approvedBy !== null && approvedBy !== undefined
                ? `${approvedBy.lastName ? approvedBy.lastName + ", " : ""} ${
                    approvedBy.firstName ? approvedBy.firstName : ""
                  }`
                : "-"}
            </Text>
          </View>
          {process.env.REACT_APP_THEME === "SURFACEPLUS" && (
            <View style={[{ flex: 2 }]}>
              <Text
                style={[
                  {
                    textAlign: "center",
                    fontSize: 9,
                  },
                ]}
              >
                Make all checks payable to JNJ BUILDERS CORPORATION
              </Text>
              <Text
                style={[
                  {
                    textAlign: "center",
                    fontSize: 9,
                  },
                ]}
              >
                For Deposits: Security Bank 0000041123824
              </Text>
            </View>
          )}
        </View>

        <View
          style={[
            {
              marginTop: 8,

              paddingLeft: 20,
              paddingRight: 20,
            },
          ]}
        >
          <View
            style={[
              {
                flex: 1,
                flexDirection: "row",
              },
            ]}
          >
            <View style={[{ flex: 1 }]}>
              <Text
                style={[
                  {
                    fontSize: 10,
                    color: themeColor,
                    fontFamily: "Open Sans",
                    fontWeight: 800,
                  },
                ]}
              >
                Received by:___________________________________
              </Text>
            </View>
          </View>
        </View>
      </>
    );
  }

  ChargeInvoice(
    incharge,
    voucherType,
    trxId,
    payee,
    dateCreated,
    grandTotal,
    createdBy,
    approvedBy,
    tableData,
    bankInfo,
    accountInfo,
    checkInfo,
    transStatus,
    invoiceGrandTotal,
    paymentData,
    TotalAmountDue,
    invoice
  ) {
    //console.log(incharge);

    let _content = [];

    _content.push(
      <>
        {(transStatus === "new" ||
          transStatus === "pending-admin-approval" ||
          transStatus === "admin-denied") && (
          <View
            style={[
              {
                marginTop: 100,
                fontSize: 15,
                width: "100%",
                position: "absolute",
                textAlign: "center",
                backgroundColor: "red",
              },
            ]}
          >
            <Text style={[{ textAlign: "center", color: "white" }]}>
              --== THIS INVOICE IS NOT APPROVED BY COMPANY AND IS NOT VALID ==--
            </Text>
          </View>
        )}
        {transStatus === "finished" && (
          <View
            style={[
              {
                marginTop: 100,
                fontSize: 15,
                width: "100%",
                position: "absolute",
                textAlign: "center",
                backgroundColor: "red",
              },
            ]}
          >
            <Text style={[{ textAlign: "center", color: "white" }]}>
              --== THIS INVOICE IS FINISHED / ARCHIVED. FOR VIEWING PURPOSES
              ONLY ==--
            </Text>
          </View>
        )}
        <View
          style={[
            {
              // flex: 1,
              flexDirection: "row",
              paddingLeft: 40,
              paddingRight: 40,
              marginTop: 5,
            },
          ]}
        >
          <View style={[{ flex: 1 }]}>
            <Text
              style={[
                {
                  textAlign: "left",
                  color: themeColor,
                  fontSize: 10,
                  fontWeight: "bold",
                },
              ]}
            >
              CUSTOMER:
            </Text>
            <Text
              style={[
                {
                  textAlign: "left",
                  fontSize: 8,
                  marginTop: 3,
                  fontWeight: "bold",
                },
              ]}
            >
              {payee?.company}
            </Text>
          </View>
          <View style={[{ flex: 1 }]}>
            <Text
              style={[
                {
                  textAlign: "left",
                  color: themeColor,
                  fontSize: 10,
                  fontWeight: "bold",
                },
              ]}
            >
              AMOUNT DUE:
            </Text>
            <Text
              style={[
                {
                  textAlign: "left",
                  fontSize: 15,
                  marginTop: 3,
                  fontWeight: "bold",
                },
              ]}
            >
              P {misc.parseCurrencyNoCurrency(grandTotal)}
            </Text>
            <Text
              style={[
                {
                  textAlign: "left",
                  fontSize: 8,
                  marginTop: 3,
                  fontWeight: "bold",
                },
              ]}
            >
              {misc.currencyToWords(grandTotal)}
            </Text>
          </View>
        </View>

        <Canvas
          style={[
            {
              marginTop: 5,

              width: 600,
              height: 5,
            },
          ]}
          paint={(painter) => {
            painter
              .strokeColor(themeColor)
              .lineWidth(5)
              .moveTo(40, 0)
              .lineTo(555, 0)
              .stroke();
          }}
        />
        <View
          style={[
            {
              //height: 325,
              paddingLeft: 40,
              paddingRight: 40,
            },
          ]}
        >
          <Text
            style={[
              {
                textAlign: "left",
                paddingLeft: 6,
                marginTop: -3,
                fontSize: 10,
                fontWeight: "bold",
                backgroundColor: themeColor,
                color: "#ffff",
              },
            ]}
          >
            PAYMENTS
          </Text>
          {this._paymentTable(paymentData)}
        </View>
        <Canvas
          style={[
            {
              marginTop: 2,
              marginBottom: 2,
              width: 600,
              height: 2,
            },
          ]}
          paint={(painter) => {
            painter
              .strokeColor("#00000")
              .lineWidth(1)
              .moveTo(40, 0)
              .lineTo(555, 0)
              .stroke();
          }}
        />
        <View
          style={[
            {
              //height: 325,
              paddingLeft: 40,
              paddingRight: 40,
            },
          ]}
        >
          <Text
            style={[
              {
                textAlign: "left",

                fontSize: 10,
              },
            ]}
          >
            Balance After Payment:
            <Text
              style={[
                {
                  marginLeft: 5,

                  fontSize: 10,
                  textAlign: "left",
                  marginTop: 0,
                  color: "#4f6228",
                  fontWeight: "bold",
                },
              ]}
            >
              {misc.parseCurrencyNoCurrency(TotalAmountDue)}
            </Text>
          </Text>
        </View>

        <Canvas
          style={[
            {
              marginTop: 5,

              width: 600,
              height: 5,
            },
          ]}
          paint={(painter) => {
            painter
              .strokeColor(themeColor)
              .lineWidth(5)
              .moveTo(40, 0)
              .lineTo(555, 0)
              .stroke();
          }}
        />
        <View
          style={[
            {
              //height: 325,
              paddingLeft: 40,
              paddingRight: 40,
            },
          ]}
        >
          <Text
            style={[
              {
                textAlign: "left",
                paddingLeft: 6,
                marginTop: -3,
                fontSize: 10,
                fontWeight: "bold",
                backgroundColor: themeColor,
                color: "#ffff",
              },
            ]}
          >
            INVOICE ITEMS
          </Text>
        </View>
        <View
          style={[
            {
              //height: 325,
              paddingLeft: 40,
              paddingRight: 40,
            },
          ]}
        >
          <Text
            style={[
              {
                textAlign: "left",

                fontSize: 8,
              },
            ]}
          >
            Quotation #: {invoice?.order?.uniqueId} Dated:{" "}
            {misc.parseAPIDateTimeToShortDate(invoice?.order?.dateAdded)}
          </Text>
        </View>

        <View
          style={[
            {
              //height: 325,
              paddingTop: 2,
              paddingLeft: 20,
              paddingRight: 20,
            },
          ]}
        >
          {/* <Text
            style={[
              {
                fontSize: 9,
                paddingLeft: 20,
              },
            ]}
          >
            Dear Ma'am / Sir,
          </Text>
          <Text
            style={[
              {
                marginBottom: 8,
                paddingLeft: 20,
                fontSize: 9,
              },
            ]}
          >
            We are pleased to submit our quotation for the following items:
          </Text> */}
          {((x) => {
            let _e = [];
            if (tableData.length > 0) {
              _e = tableData[0];
            }
            // console.log(tableData);
            //console.log(props.pdfGroupTable);
            if (_e.length <= 20) {
              return (
                <>
                  <View
                    style={[
                      {
                        paddingLeft: 20,
                        paddingRight: 20,
                      },
                    ]}
                  >
                    {this._itemTable(_e)}
                  </View>
                  <Canvas
                    fixed
                    style={[
                      {
                        width: 600,
                        height: 5,
                        // top: 300,
                        // position: "absolute",
                      },
                    ]}
                    paint={(painter) => {
                      painter
                        .strokeColor("black")
                        .lineWidth(1)
                        .moveTo(20, 0)
                        .lineTo(535, 0)
                        .stroke();
                    }}
                  />
                  <Text
                    style={[
                      {
                        paddingLeft: 20,
                        paddingRight: 40,
                        fontSize: 6,
                        textAlign: "right",
                        marginTop: 0,
                      },
                    ]}
                  >
                    INVOICE TOTAL:{" "}
                    {misc.parseCurrencyNoCurrency(invoiceGrandTotal)}
                  </Text>
                  {this._termsSignature(incharge, approvedBy)}
                </>
              );
            } else {
              return <>{this._itemTable(_e)}</>;
            }
          })()}
        </View>
        {/* <View style={[{ marginTop: 5 }]}>
          <Text style={[{ textAlign: "center", color: "red" }]}>
            --= THIS DOCUMENT IS FOR REFERENCE ONLY =--
          </Text>
        </View> */}
      </>
    );
    for (let i = 1; i < tableData.length; i++) {
      const _e = tableData[i];

      if (_e.length >= 20) {
        _content.push(
          <View
            style={[
              {
                paddingLeft: 20,
                paddingRight: 20,
              },
            ]}
          >
            {this._itemTable(_e)}
          </View>
        );
      } else {
        _content.push(
          <>
            <View
              style={[
                {
                  paddingLeft: 20,
                  paddingRight: 20,
                },
              ]}
            >
              {this._itemTable(_e)}
            </View>
            <Canvas
              fixed
              style={[
                {
                  width: 600,
                  height: 5,
                  // top: 300,
                  // position: "absolute",
                },
              ]}
              paint={(painter) => {
                painter
                  .strokeColor("black")
                  .lineWidth(1)
                  .moveTo(20, 0)
                  .lineTo(535, 0)
                  .stroke();
              }}
            />
            <Text
              style={[
                {
                  paddingLeft: 20,
                  paddingRight: 40,
                  fontSize: 12,
                  textAlign: "right",
                  marginTop: 5,
                },
              ]}
            >
              TOTAL: {misc.parseCurrencyNoCurrency(grandTotal)}
            </Text>
            {this._termsSignature(incharge, approvedBy)}
          </>
        );
      }
    }
    return pdfTemplate.TemplateHeaderFooter(
      _content,
      `Charge Invoice`,
      trxId,
      undefined,
      undefined,
      undefined,
      undefined,
      (transStatus === "new" ||
        transStatus === "pending-admin-approval" ||
        transStatus === "admin-denied") &&
        true
    );
  }
}
export default new ChargeInvoice();
// let QuotePDF = (props) => (

// );
//export { QuotePDF };
