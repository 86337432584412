class SettingsAPI {
  getSettingsByInventory = async (dispatch, inventoryId) => {
    // const filterData = [];

    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `settings/getSettingsByInventory?inventoryId=${inventoryId}`,
        loadingMsg: "Fetching Settings",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };

  saveSettings = async (dispatch, uiData, cb, cbFail) => {
    //console.log(sdata);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `settings/saveSettings`,
        loadingMsg: "Modifying Settings",
        reqType: "post",
        data: uiData,
        resolve: resolve,
        reject: reject,
      });
    }).then(
      async (onfulfilled) => {
        //console.log(onfulfilled);
        if (cb) await cb();
        return onfulfilled.data;
      },
      async (onrejected) => {
        if (cb) await cb();
        return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };
}
export default new SettingsAPI();
