import React, { useState, useEffect, memo, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";

import moment from "moment";

import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
//import { PDFDownloadLink } from "@react-pdf/renderer";

import emptyImg from "../../assets/fork-lift.svg";

import { NumericFormat } from "react-number-format";
import Table from "react-table";
import filters from "../../functions/filters";
import inventoryApi from "../Inventory/InventoryAPI";
import invoiceApi from "../Invoice/InvoiceAPI";
import accountsApi from "../Accounts/AccountsAPI";
import settingsApi from "../Settings/SettingsAPI";
import transactionsApi from "../Transactions/TransactionsAPI";
//import { SELECTED_OPERATION } from "../../constants";
import Select from "react-select";
import Card from "react-bootstrap/Card";
import { Modal } from "react-bootstrap";
import actorApi from "../Actor/ActorAPI";
import ClientSelect from "../../components/CustomDrop";
import Receipt from "./PDF/Receipt";
import SalesReport from "./PDF/SalesReport";
//import { FixedSizeList as List } from "react-window";
import { AutoSizer, List, InfiniteLoader } from "react-virtualized";
//import 'react-virtualized/dist/es/'
import "react-virtualized/styles.css";
import { CheckoutModal } from "./CheckoutModal";
import { CartModal } from "./CartModal";
import { PriceAdjustModal } from "./PriceAdjustModal";
import misc from "../../functions/misc";
import { setInvoiceHeaderData } from "../../functions/pdfHeaderData";

const initValidateCheckout = {
  SelClient: { error: false, message: "" },
  TxtCashTendered: { error: false, message: "" },
  UIValidate: { error: false, message: "", validated: false },
};

export const POSProcess = () => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => {
    const user = state.apiData.userData;
    let _permissions = [];
    if (user && user.permissions) {
      _permissions = JSON.parse(user.permissions);
    }
    return _permissions.toString();
  });
  //Items List States
  //const [ItemListSelection, setItemListSelection] = useState([]);
  //const [SelectedItemList, setSelectedItemList] = useState(null);
  const [ItemListData, setItemListData] = useState([]);
  const [ItemListDataCopy, setItemListDataCopy] = useState([]);
  const [ItemListLoading, setItemListLoading] = useState(true);

  //Cart List States
  const [CartSelection, setCartSelection] = useState([]);
  const [SelectedCartList, setSelectedCartList] = useState(null);
  const [CartData, setCartData] = useState([]);
  const [CartDataCopy, setCartDataCopy] = useState([]);
  const [CartLoading, setCartLoading] = useState(false);

  //Inventory Select
  const [InventoryData, setInventoryData] = useState([]);
  const [SelInventory, setSelInventory] = useState([]);
  const [SelInventoryLoading, setSelInventoryLoading] = useState(true);
  const [SelInventoryDisabled, setSelInventoryDisabled] = useState(false);

  //Invoice Details Data
  const [InvoiceDetails, setInvoiceDetails] = useState([]);

  const [DETAIL_TYPE, setDETAIL_TYPE] = useState([]);
  const [DETAIL_INVOICE_STATUS, setDETAIL_INVOICE_STATUS] = useState([]);
  const [DETAIL_INVOICE_CONTENT_STATUS, setDETAIL_INVOICE_CONTENT_STATUS] =
    useState([]);
  const [DETAIL_INVOICE_CONTENT_TYPE, setDETAIL_INVOICE_CONTENT_TYPE] =
    useState([]);

  //UI
  const [UIAmountDue, setUIAmountDue] = useState(0);
  const [UITotalQtyItems, setUITotalQtyItems] = useState(0);
  const [UIAmountChange, setUIAmountChange] = useState(0);

  const [UIVat, setUIVat] = useState(0);
  const [UIVatSales, setUIVatSales] = useState(0);
  const [UIDateToday, setUIDateToday] = useState(null);
  const [UIDateGenerateReportToday, setUIDateGenerateReportToday] =
    useState(null);

  //Cart Modal
  const [CartModalOpen, setCartModalOpen] = useState(false);
  // const [TxtItemQty, setTxtItemQty] = useState(0);

  //Checkout Modal
  const [CheckoutModalOpen, setCheckoutModalOpen] = useState(false);
  const [TxtCashTendered, setTxtCashTendered] = useState(null);
  const [TxtNotes, setTxtNotes] = useState("");

  //Price Adjust Modal
  const [PriceAdjustModalOpen, setPriceAdjustModalOpen] = useState(false);

  //Price Adjustments Inputs
  const [UITxtDiscountPercent, setUITxtDiscountPercent] = useState("");
  const [UITxtDiscountAmount, setUITxtDiscountAmount] = useState("");
  const [UITxtDiscountedWhole, setUITxtDiscountedWhole] = useState("");

  const [UITxtMarkupPercent, setUITxtMarkupPercent] = useState("");
  const [UITxtMarkupAmount, setUITxtMarkupAmount] = useState("");
  const [UITxtMarkupWhole, setUITxtMarkupWhole] = useState("");

  //Client Select
  const [ClientData, setClientData] = useState([]);
  const [SelClient, setSelClient] = useState(null);
  const [SelClientLoading, setSelClientLoading] = useState(false);
  const [SelClientDisabled, setSelClientDisabled] = useState(false);

  //REDUX
  const CURRENT_USER = useSelector((state) => state.apiData.userData);
  const CURRENT_USERTYPE = useSelector((state) => state.apiData.userType);

  //CREATED INVOICE
  const [CreatedInvoice, setCreatedInvoice] = useState(null);
  //INPUTS VALIDATE
  const [ValidateCheckout, setValidateCheckout] =
    useState(initValidateCheckout);

  //ACCOUNTS
  const [Accounts, setAccounts] = useState(null);
  //SETTINGS
  const [Settings, setSettings] = useState(null);
  //STATS
  const [SalesStats, setSalesStats] = useState({
    orders: [],
    profits: 0,
    qtySold: 0,
    totalAcquired: 0,
    totalSales: 0,
    totalSpent: 0,
  });
  const [ItemOrdersClient, setItemOrdersClient] = useState(null);

  //console.log(CURRENT_USER);

  useEffect(() => {
    refreshInventory(true);
    getInvoiceDetailsConst();
    getActors();
  }, []);
  useEffect(() => {
    refreshItems();
  }, [SelInventory]);

  useEffect(() => {
    CalculateValues();
    if (CartData.length > 0) {
      setSelInventoryDisabled(true);
    } else {
      setSelInventoryDisabled(false);
    }
  }, [CartData]);

  const validateCheckout = (validateComponents, cb) => {
    let tempUserInput = {
      ...ValidateCheckout,
      UIValidate: { error: false, message: "", validated: false },
    };
    if (
      validateComponents.includes("SelClient") ||
      validateComponents.includes("all")
    ) {
      SelClient !== null
        ? (tempUserInput.SelClient = { error: false, message: "" })
        : (tempUserInput.SelClient = {
            error: true,
            message: "Client cant be empty",
          });
    }
    if (
      validateComponents.includes("TxtCashTendered") ||
      validateComponents.includes("all")
    ) {
      TxtCashTendered !== null && TxtCashTendered.length > 0
        ? (tempUserInput.TxtCashTendered = { error: false, message: "" })
        : (tempUserInput.TxtCashTendered = {
            error: true,
            message: "Cash tendered cant be empty",
          });

      Number(TxtCashTendered - UIAmountDue) >= 0 && TxtCashTendered !== null
        ? (tempUserInput.TxtCashTendered = { error: false, message: "" })
        : (tempUserInput.TxtCashTendered = {
            error: true,
            message:
              "Cash tendered should be greater than or equal to amount due",
          });
    }

    Object.keys(tempUserInput).forEach((key) => {
      if (tempUserInput[key].error) {
        tempUserInput.UIValidate = {
          error: true,
          message: "",
          validated: true,
        };
      }
    });
    setValidateCheckout(tempUserInput);
    if (tempUserInput.UIValidate.error) {
      dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Validation Error",
          desc: `Please check inputs`,
          isYesNo: false,
        },
      });
    } else {
      // dispatch({
      //   type: "MODAL_SHOW",
      //   payload: {
      //     title: "Transaction Finished",
      //     desc: `Ready for next transaction`,
      //     isYesNo: false,
      //   },
      // });
      if (cb) cb();
    }
  };

  const getInvoiceDetailsConst = async () => {
    //const detData = await invoiceApi.getInvoiceDetailsConst(dispatch);
    const DETAILS = await invoiceApi.getAllInvoiceDetail(dispatch);
    const DateToday = await actorApi.getCurrentDateTime(dispatch);
    const accounts = await accountsApi.getAccountByType(dispatch);
    //console.log("INVOICE DETAILS DATA ===>", detData, DETAILS);

    const _DETAIL_TYPE = DETAILS.find((x) => x.name === "Type");
    const _DETAIL_INVOICE_STATUS = DETAILS.find((x) => x.name === "Status");
    const _DETAIL_INVOICE_CONTENT_STATUS = DETAILS.find(
      (x) => x.name === "ContentStatus"
    );
    const _DETAIL_INVOICE_CONTENT_TYPE = DETAILS.find(
      (x) => x.name === "ContentType"
    );
    setAccounts(accounts);
    setDETAIL_TYPE(_DETAIL_TYPE.details);
    setDETAIL_INVOICE_STATUS(_DETAIL_INVOICE_STATUS.details);
    setDETAIL_INVOICE_CONTENT_STATUS(_DETAIL_INVOICE_CONTENT_STATUS.details);
    setDETAIL_INVOICE_CONTENT_TYPE(_DETAIL_INVOICE_CONTENT_TYPE.details);

    setInvoiceDetails(DETAILS);
    setUIDateToday(moment(DateToday));
    setUIDateGenerateReportToday(moment(DateToday).format("YYYY-MM-DD"));
  };

  const refreshInventory = async (initial) => {
    setSelInventoryLoading(true);
    let inventories = [];
    inventories = await inventoryApi.getInventory(dispatch);

    if (inventories.length === 0) {
      dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Inventory Empty",
          desc: `You dont have permission to access any inventory. Please contact your administrator`,
          isYesNo: false,
        },
      });
    } else {
      setInventoryData(inventories);
      setSelInventoryLoading(false);

      if (initial) {
        setSelInventory(inventories[0]);
        //refreshItems();
      }
    }
  };

  const refreshItems = async (startIndex, endIndex) => {
    setItemListLoading(true);

    let inventoryItems = [];
    if (InventoryData.length > 0) {
      //console.log("INDEXES ===>  ", startIndex, endIndex);
      inventoryItems = await inventoryApi.getItemsByInventory(
        dispatch,
        SelInventory.id,
        undefined,
        0,
        startIndex,
        endIndex,
        null,
        'ACTIVE'
      );
      let settings = null;
      if (InventoryData.length > 0) {
        settings = await settingsApi.getSettingsByInventory(
          dispatch,
          Number(SelInventory.id)
        );
        // console.log(settings);
          //Update Invoice Headers
      setInvoiceHeaderData(
        settings?.invoiceHeaderAddress1,
        settings?.invoiceHeaderAddress2,
        settings?.invoiceHeaderAddress3,
        settings?.invoiceHeaderLandline,
        settings?.invoiceHeaderMobile,
        settings?.invoiceHeaderContact,
        settings?.invoiceHeaderEmail,
        settings?.invoiceDeliveryReceiveAddress
      )
        setSettings(settings);
      }
      //console.log("DATA SIZE ===> ", inventoryItems.length);
      setItemListData(inventoryItems);
      setItemListDataCopy(inventoryItems);
      //console.log(inventoryItems);
      setItemListLoading(false);
    }
  };

  const getActors = async (e) => {
    const ACTORS = await actorApi.getAllActors(dispatch);
    const _clients = ACTORS.find((x) => x.name === "Client");

    setClientData(_clients.actors);
  };

  const GetItemSalesByDate = async (date, cb) => {
    const filter = {
      // dateStart: this.state.dpStartDate.value,
      dateEnd: moment(date).format("YYYY-MM-DD"),
      inventoryId: SelInventory.id,
      limit: 0,
    };
    const TotalSalesDaily = await inventoryApi.getTotalSales(dispatch, {
      ...filter,
      isDaily: 1,
      dateEnd: moment(date).format("YYYY-MM-DD"),
    });
    const MostOrderedItemsClients =
      await inventoryApi.getMostOrderedItemsClients(dispatch, {
        ...filter,
        isDaily: 1,
        dateEnd: moment(date).format("YYYY-MM-DD"),
      });

    if (TotalSalesDaily && MostOrderedItemsClients) {
      setSalesStats(TotalSalesDaily);
      setItemOrdersClient(MostOrderedItemsClients);
      //console.log(TotalSalesDaily);
      if (cb) cb(TotalSalesDaily, MostOrderedItemsClients);
    }
  };

  const ItemListSearchHandler = (event) => {
    if (event.target.value !== "") {
      const data = filters.filterData(ItemListDataCopy, event.target.value);

      if (data != null) {
        setItemListData(data);
      } else {
        setItemListData(ItemListDataCopy);
      }
    } else {
      setItemListData(ItemListDataCopy);
    }
  };

  const handlePOSReset = () => {
    setCartSelection([]);
    setSelectedCartList(null);
    setCartData([]);
    setCartDataCopy([]);
    setCartLoading(false);
    setUIAmountDue(0);
    setUITotalQtyItems(0);
    setUIVat(0);
    setUIVatSales(0);
    setSelClient(null);
    setTxtCashTendered(null);
    setTxtNotes(null);
    setCheckoutModalOpen(false);
    setUIAmountChange(0);
    setValidateCheckout(initValidateCheckout);

    //re trigger / mimic onload useEffect
    refreshInventory(false);
    refreshItems();
  };

  const CalculateValues = () => {
    let _AmountDue = CartData.reduce((a, c) => {
      let finalPrice = 0;

      let productPrice = Number(c.price);

      const discountPercent = c.discount;
      const priceModifier = c.priceModifier;

      // // if (priceModifier) {
      // //   const convertedPercentage = priceModifier / 100;
      // //   productPrice = productPrice + productPrice * convertedPercentage;
      // // }
      // productPrice = misc.calculateMarkup(productPrice, priceModifier);

      // if (discountPercent) {
      //   const convertedPercentage = discountPercent / 100;

      //   productPrice = productPrice - productPrice * convertedPercentage;
      // }
      // finalPrice = Number(productPrice);

      // finalPrice = misc.calculateMarkupDiscount(
      //   productPrice,
      //   discountPercent,
      //   priceModifier
      // );
      finalPrice = productPrice;

      return a + finalPrice * c.qty;
    }, 0).toFixed(2);

    let _TotalQtyItems = CartData.reduce((a, c) => {
      return a + c.qty;
    }, 0).toFixed(2);

    let settingTaxPercentage = 0;
    let _VATSales = 0;
    let _VAT = 0;
    if (Settings?.valueAddedTax) {
      settingTaxPercentage = Number(Settings.valueAddedTax) / 100 + 1;
      _VATSales = Number(_AmountDue / settingTaxPercentage).toFixed(2);
      _VAT = Number(_AmountDue - _VATSales).toFixed(2);
    }

    setUITotalQtyItems(_TotalQtyItems);
    setUIAmountDue(_AmountDue);
    setUIVat(_VAT);
    setUIVatSales(_VATSales);
  };

  const CalculateChange = () => {
    const _change = Math.abs(
      Number(UIAmountDue - Number(TxtCashTendered)).toFixed(2)
    );
    setUIAmountChange(_change);
  };

  const ItemListSelectHandler = (data, cb) => {
    //console.log("SELECTED POS ITEM ===>", data);
    let _CartSelection = [];
    _CartSelection = _CartSelection.concat(CartData);
    // _CartSelection.push({
    //   id: data.id,
    //   itemQty: 0,
    //   price: 0,
    //   srp: data.price,
    //   itemId: data.id,
    //   itemIndex: 0,
    //   itemUnitId: 0,
    //   orderStatusId: 0,
    //   orderTypeId: 0,
    //   isPOS: 1,

    //   autoGenID: filters.uuidv4(),
    // });

    //find dupe and add qty
    const findDupe = _CartSelection.find((x) => x.id === data.id);
    if (findDupe) {
      // _CartSelection.push({
      //   ...data,
      //   qty: findDupe.qty + 1,
      //   autoGenID: filters.uuidv4(),
      // });
      _CartSelection = _CartSelection.map((x) =>
        x.id === findDupe.id ? { ...x, qty: x.qty + 1 } : x
      );
    } else {
      _CartSelection.push({
        ...data,
        qty: 1,
        autoGenID: filters.uuidv4(),
      });
    }

    // LAG
    //Subtract qty from itemlists
    //let tempItemList = ItemListData;
    // tempItemList = tempItemList.map((x) =>
    //   x.id === data.id ? { ...x, qty: x.qty - 1 } : x
    // );
    //console.log(tempItemList);

    //setItemListData(tempItemList);
    //setItemListDataCopy(tempItemList);

    setCartData(_CartSelection);
    setCartDataCopy(_CartSelection);
  };
  const createInvoice = async (cb) => {
    let _tempData = [];

    //console.log(DETAIL_TYPE);

    const _InvoiceType = DETAIL_TYPE.find((x) => x.name === "POS");
    const _InvoiceStatus = DETAIL_INVOICE_STATUS.find(
      (x) => x.name === "Finished"
    );

    const _ContentStatus = DETAIL_INVOICE_CONTENT_STATUS.find(
      (x) => x.name === "In-Stock"
    );
    const _ContentType = DETAIL_INVOICE_CONTENT_TYPE.find(
      (x) => x.name === "Item"
    );

    for (let i = 0; i < CartData.length; i++) {
      const a = CartData[i];

      const _ItemUnit = a.details.find((x) => x.type === 2);
      //console.log(_ItemUnit);
      _tempData.push({
        itemQty: a.qty,
        price: a.price,
        itemId: a.id,
        itemUnitId: _ItemUnit.id,
        orderStatusId: _ContentStatus.id,
        orderTypeId: _ContentType.id,
        //serviceName: a.serviceName,
        discount: a.discount,
        priceModifier: a.priceModifier,
        //showImg: a.showImg,
        srp: a.srp,
        //itemIndex: Number(a.itemIndex),
        //placeholder: a.placeholder,
      });
    }

    let InventoryId = Number(SelInventory.id);
    if (InventoryId === 1) {
      InventoryId = null;
    }

    const data = {
      data: {
        name: "POS TRANSACTION",
        inventoryId: InventoryId,
        clientId: Number(SelClient.id),
        inchargeId: Number(CURRENT_USER.id),
        lastModifiedByActorId: Number(CURRENT_USER.id),
        // paidAmount: Number(this.state.txtAmountPaid.value),
        // shippingAddress: this.state.txtShippingAddress.value,
        // description: this.state.txtDesc.value,
        notes: TxtNotes,
        invoiceTypeId: Number(_InvoiceType.id),
        invoiceStatusId: Number(_InvoiceStatus.id),
        cashTendered: Number(TxtCashTendered),
        sellPriceGross: Number(UIAmountDue),
        sellPriceNet: Number(UIVatSales),
        sellPriceVAT: Number(UIVat),
        isPOSTrans: 1,
      },
      //details: this.state.termsTableData,
      content: _tempData,
      // transaction: {
      //   inventoryId: InventoryId,
      //   clientId: Number(SelClient.id),
      //   createdById: Number(CURRENT_USER.id),
      //   amountValue: Number(UIAmountDue),
      //   lastModifiedByActorId: Number(CURRENT_USER.id),
      //   grossAmount: Number(UIAmountDue),
      //   netAmount: Number(UIVatSales),
      //   taxAmount: Number(UIVat),
      // },
    };

    //console.log("PRE-POST DATA ===>", data);

    const createdInvoice = await invoiceApi.createInvoice(
      dispatch,
      data,
      () => {
        if (cb) {
          // handlePOSReset();
          cb();
        }
      },
      () => {
        dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Invoice Creation Error",
            desc: `Please Contact Administrator`,
            isYesNo: false,
          },
        });
      }
    );

    if (createdInvoice) {
      // ===== INVENTORY ASSET DEBIT =====
      const inventoryAssetValue = Number(UIAmountDue) * -1;
      const actsReceivableTransaction = {
        description: `POS Entry - ${SelClient.company}`,
        paymentDate: UIDateToday,
        transStatus: "finished",
        paymentType: "cash",
        isEntry: 1,
        isInventoryAssetDeductEntry: 1,
        amountValue: inventoryAssetValue,
        grossAmount: inventoryAssetValue,
        netAmount: inventoryAssetValue,
        taxAmount: 0,
        uid: filters.uuidv4(),
        transType: `credit`,
        clientId: Number(SelClient.id),
        lastModifiedByActorId: Number(CURRENT_USER.id),
        accountId: Settings.inventoryAccountId,

        inventoryId: InventoryId,
      };
      await transactionsApi.createTransaction(
        dispatch,
        { transaction: actsReceivableTransaction },
        (dat) => {
          console.log("CREATED ASSET DEBIT TRANSACTION ===>", dat);
        }
      );

      setCreatedInvoice(createdInvoice.data);
      console.log("CREATED INVOICE ===> ", createdInvoice.data);
      //===== REVENUE CREDIT =====
      const invoiceSalesTransaction = {
        description: `POS Payment - ${SelClient.company}`,
        paymentDate: UIDateToday,
        transStatus: "finished",
        paymentType: "cash",
        createdById: Number(CURRENT_USER.id),
        amountValue: Number(UIAmountDue),
        lastModifiedByActorId: Number(CURRENT_USER.id),
        grossAmount: Number(UIAmountDue),
        netAmount: Number(UIVatSales),
        taxAmount: Number(UIVat),
        uid: filters.uuidv4(),
        isEntry: 0,
        isInventoryAssetDeductEntry: 1,
        transType: "credit",
        clientId: Number(SelClient.id),
        accountId: Settings.incomeAccountPOSId,
        orderId: Number(createdInvoice.data.id),
        inventoryId: InventoryId,
      };
      console.log("PRE-POST TRANSACTION ===>", invoiceSalesTransaction);

      await transactionsApi.createTransaction(
        dispatch,
        { transaction: invoiceSalesTransaction },

        (dat) => {
          console.log("CREATED REVENUE CREDIT TRANSACTION ===>", dat);
        }
      );
      // console.log(
      //   CartData,
      //   UIDateToday,
      //   UIAmountDue,
      //   UIAmountChange,
      //   UITotalQtyItems,
      //   UIVat,
      //   UIVatSales,
      //   SelClient,
      //   CURRENT_USER,
      //   TxtCashTendered,
      //   createdInvoice.data
      // );
      dispatch({
        type: "PDF_MODAL_TOGGLE",
        doc: Receipt.ReceiptPDF(
          CartData,
          UIDateToday,
          UIAmountDue,
          UIAmountChange,
          UITotalQtyItems,
          UIVat,
          UIVatSales,
          SelClient,
          CURRENT_USER,
          TxtCashTendered,
          createdInvoice.data,
          false
        ),
      });

      handlePOSReset();
      refreshItems();
    }
  };
  const resetItemPriceAdjustment = () => {
    setUITxtDiscountPercent("");
    setUITxtDiscountAmount("");
    setUITxtDiscountedWhole("");
    setUITxtMarkupPercent("");
    setUITxtMarkupAmount("");
    setUITxtMarkupWhole("");
  };
  //   if () {
  //     // ===== INVENTORY ASSET DEBIT =====
  //     const inventoryAssetValue = Number(UIAmountDue) * -1;
  //     const actsReceivableTransaction = {
  //       description: `POS Entry - ${SelClient.company}`,
  //       paymentDate: UIDateToday,
  //       transStatus: "finished",
  //       paymentType: "cash",
  //       isEntry: 1,
  //       isInventoryAssetDeductEntry: 1,
  //       amountValue: inventoryAssetValue,
  //       grossAmount: inventoryAssetValue,
  //       netAmount: inventoryAssetValue,
  //       taxAmount: 0,
  //       uid: filters.uuidv4(),
  //       transType: `credit`,
  //       clientId: Number(SelClient.id),
  //       lastModifiedByActorId: Number(CURRENT_USER.id),
  //       accountId: Settings.inventoryAccountId,

  //       inventoryId: InventoryId,
  //     };
  //     await transactionsApi.createTransaction(
  //       dispatch,
  //       actsReceivableTransaction,
  //       (dat) => {
  //         console.log("CREATED ASSET DEBIT TRANSACTION ===>", dat);
  //       }
  //     );

  //     setCreatedInvoice(createdInvoice.data);
  //     console.log("CREATED INVOICE ===> ", createdInvoice);
  //     //===== REVENUE CREDIT =====
  //     const invoiceSalesTransaction = {
  //       description: `POS Payment - ${SelClient.company}`,
  //       paymentDate: UIDateToday,
  //       transStatus: "finished",
  //       paymentType: "cash",
  //       createdById: Number(CURRENT_USER.id),
  //       amountValue: Number(UIAmountDue),
  //       lastModifiedByActorId: Number(CURRENT_USER.id),
  //       grossAmount: Number(UIAmountDue),
  //       netAmount: Number(UIVatSales),
  //       taxAmount: Number(UIVat),
  //       uid: filters.uuidv4(),
  //       isEntry: 0,
  //       isInventoryAssetDeductEntry: 1,
  //       transType: "credit",
  //       clientId: Number(SelClient.id),
  //       accountId: Settings.incomeAccountPOSId,

  //       inventoryId: InventoryId,
  //     };
  //     console.log("PRE-POST TRANSACTION ===>", invoiceSalesTransaction);

  //     await transactionsApi.createTransaction(
  //       dispatch,
  //       invoiceSalesTransaction,
  //       (dat) => {
  //         console.log("CREATED REVENUE CREDIT TRANSACTION ===>", dat);
  //       }
  //     );
  //     dispatch({
  //       type: "PDF_MODAL_TOGGLE",
  //       doc: Receipt.ReceiptPDF(
  //         CartData,
  //         UIDateToday,
  //         UIAmountDue,
  //         UIAmountChange,
  //         UITotalQtyItems,
  //         UIVat,
  //         UIVatSales,
  //         SelClient,
  //         CURRENT_USER,
  //         TxtCashTendered,
  //         createdInvoice.data
  //       ),
  //     });
  //     handlePOSReset();
  //   }
  // };
  // const ItemListSelectHandler = (key, shift, row) => {
  //   let _ItemListSelection = ItemListSelection;
  //   const keyIndex = _ItemListSelection.indexOf(key);
  //   if (keyIndex >= 0) {
  //     _ItemListSelection = [];
  //     setSelectedItemList(null);
  //   } else {
  //     _ItemListSelection.length = 0;
  //     _ItemListSelection.push(key + "-ITEMLIST");
  //     setSelectedItemList(row);
  //   }
  //   setItemListSelection(_ItemListSelection);
  // };
  // const tableItemList = (
  //   <>
  //     <style>
  //       {`
  //         @media (max-width: 991px) {
  //         }
  //         @media (min-width: 992px) {
  //         }
  //         .tablePOS {
  //           border-radius: 10px;
  //         }
  //       `}
  //     </style>
  //     <Table
  //       data={ItemListData}
  //       keyField="id"
  //       columns={tableItemListColumn}
  //       loading={ItemListLoading}
  //       toggleSelection={ItemListSelectHandler}
  //       isSelected={(key) =>
  //         ItemListSelection.includes(`select-${key}-ITEMLIST`)
  //       }
  //       //getTrProps={this.rowFnDRItemSelect}
  //       defaultPageSize={10}
  //       style={{
  //         overflow: "auto",
  //         flex: 1,
  //         backgroundColor: "var(--REB-bg-color)",
  //       }}
  //       className="tablePOS tableActor -highlight mt-3"
  //     />
  //   </>
  // );
  //TABLE CART
  const tableCartColumn = (Settings) => {
    return [
      {
        Header: () => (
          <p
            style={{
              fontSize: "0.70em",
              color: "var(--REB-primary)",
              fontWeight: "bolder",
              margin: 0,
            }}
          >
            Item Name
          </p>
        ),
        id: "name",
        getProps: (row) => {
          return {
            style: {
              padding: 0,
              paddingLeft: "10px",
              paddingRight: "10px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          };
        },
        Cell: (row) => {
          return (
            <p
              style={{
                fontSize: "0.8rem",
                overflow: "hidden",

                // textOverflow: "ellipsis",
                whiteSpace: "initial",
                // lineHeight: "1em",
                width: "100%",
              }}
            >
              {row.original.name}
            </p>
          );
        },
        accessor: (d) => (d.name === null ? "-" : d.name),
      },
      {
        Header: () => (
          <p
            style={{
              fontSize: "0.70em",
              color: "var(--REB-primary)",
              fontWeight: "bolder",
              margin: 0,
            }}
          >
            QTY
          </p>
        ),
        id: "contentQty",
        maxWidth: 45,
        accessor: (d) => (d.qty ? d.qty : 0),
        Cell: (row) => {
          return (
            <NumericFormat
              value={row.original.qty}
              displayType={"text"}
              thousandSeparator={true}
            />
          );
        },
      },
      {
        Header: () => (
          <p
            style={{
              fontSize: "0.70em",
              color: "var(--REB-primary)",
              fontWeight: "bolder",
              margin: 0,
            }}
          >
            Price
          </p>
        ),
        id: "price",
        maxWidth: 80,
        accessor: (row) => {
          let finalPrice = 0.0;

          let productPrice = Number(row.price);

          const discountPercent = row.discount;
          const priceModifier = row.priceModifier;

          // if (priceModifier) {
          //   const convertedPercentage = priceModifier / 100;
          //   productPrice = productPrice + productPrice * convertedPercentage;
          // }
          // productPrice = misc.calculateMarkup(productPrice, priceModifier);

          // if (discountPercent) {
          //   const convertedPercentage = discountPercent / 100;

          //   productPrice = productPrice - productPrice * convertedPercentage;
          // }
          // finalPrice = Number(productPrice);

          // finalPrice = misc.calculateMarkupDiscount(
          //   productPrice,
          //   discountPercent,
          //   priceModifier,
          //   Settings?.itemPriceModification
          // );
          finalPrice = productPrice;

          return finalPrice;
        },
        Cell: (row) => {
          //console.log(row)
          const d = row.original;

          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {row.original.discount > 0 && (
                <>
                  <NumericFormat
                    style={{
                      fontSize: "0.85rem",
                      textDecoration: "line-through",
                    }}
                    value={misc.calculateMarkup(
                      d.srp,
                      0,
                      Settings.itemPriceModification
                    )}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </>
              )}

              <NumericFormat
                value={row.value}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale
              />
            </div>
          );
        },
      },
      {
        Header: () => (
          <p
            style={{
              fontSize: "0.70em",
              color: "var(--REB-primary)",
              fontWeight: "bolder",
              margin: 0,
            }}
          >
            Total
          </p>
        ),
        id: "total",
        maxWidth: 80,
        accessor: (d) => (d.price ? d.price : 0),
        Cell: (row) => {
          let price = Number(row.row.price) * Number(row.original.qty);

          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <NumericFormat
                value={price}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale
              />
            </div>
          );
        },
      },
    ];
  };
  // const CartSelectHandler = (key, shift, row) => {
  //   console.log("CART SELECTION ===>", row);
  //   let _CartSelection = CartSelection;
  //   const keyIndex = _CartSelection.indexOf(key);
  //   if (keyIndex >= 0) {
  //     _CartSelection = [];
  //     setSelectedCartList(null);
  //   } else {
  //     _CartSelection.length = 0;
  //     _CartSelection.push(key + "-ITEMLIST");
  //     setSelectedCartList(row);
  //   }
  //   setCartSelection(_CartSelection);
  // };

  const CartSelectHandler = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      e.preventDefault();
      e.stopPropagation();

      resetItemPriceAdjustment();

      if (
        CartSelection.length > 0 &&
        CartSelection[0] === `select-${rowInfo.original.id}`
      ) {
        setCartSelection([]);
        setSelectedCartList(null);
      } else {
        if (rowInfo) {
          console.log("SELECTED CART ITEM ===>", rowInfo.original);
          setCartSelection(["select-" + rowInfo.original.id]);
          setSelectedCartList(rowInfo.original);
          setCartModalOpen(true);
        }
      }

      if (handleOriginal) {
        handleOriginal();
      }
    },
    style: {
      background:
        rowInfo &&
        CartSelection.includes(`select-${rowInfo.original.id}`) &&
        "var(--REB-green)",
      color:
        rowInfo &&
        CartSelection.includes(`select-${rowInfo.original.id}`) &&
        "var(--REB-white)",
    },
  });
  const tableCart = (
    <>
      <style>
        {`
          @media (max-width: 991px) {
          }
          @media (min-width: 992px) {
          }
          .rt-thead {
            padding-right: 17px;
          }
        `}
      </style>
      <Table
        data={CartData}
        keyField="id"
        columns={tableCartColumn(Settings)}
        loading={CartLoading}
        //toggleSelection={CartSelectHandler}
        isSelected={(key) => CartSelection.includes(`select-${key}-CARTLIST`)}
        getTrProps={CartSelectHandler}
        defaultPageSize={200}
        showPageSizeOptions={false}
        showPageJump={false}
        showPagination={false}
        style={{
          overflow: "auto",
          maxHeight: "40vh",
          backgroundColor: "var(--REB-bg-color)",
        }}
        className="tableCart tableActor -highlight mt-2"
      />
    </>
  );

  const isRowLoaded = ({ index }) => {
    return !!ItemListData[index];
  };

  const POSItemContainer = (props) => {
    const { index } = props;
    //console.log(props);
    const dat = ItemListData[index];
    let image = null;
    if (dat.files) {
      image = JSON.parse(dat.files);
    }
    const tempUnit = dat.details.find((x) => x.type === 2);
    let unit = "-";
    if (tempUnit) {
      unit = tempUnit.name;
    }
    const LoadImage = memo((props) => {
      const { dat, image } = props;
      return (
        <div
          className="mb-2"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            style={{
              objectFit: "contain",
              maxWidth: "70px",
              height: "70px",
              maxHeight: "70px",
            }}
            alt=""
            src={
              typeof image !== "undefined" &&
              image !== null &&
              image.length > 0 &&
              image !== "null"
                ? `${process.env.REACT_APP_API_URL}global/getFile2?uniqueId=${
                    dat.uniqueId
                  }&filename=${encodeURIComponent(
                    image[0].filename
                  )}&token=${localStorage.getItem("LUT")}`
                : emptyImg
            }
          />
        </div>
      );
    });
    return (
      // <Col
      //   // style={style}
      //   xs={6}
      //   // key={`${dat.uniqueId}-ItemListContent`}
      //   className={`pr-2 py-2 itemListCardContainer ${
      //     index % 2 ? "ListItemOdd" : "ListItemEven"
      //   }`}
      //   // style={{ overFlow: "hidden" }}
      // >

      // </Col>
      <Card
        className="itemListCard mb-3 mr-3"
        key={dat.uniqueId}
        style={{
          // height: "240px",
          maxHeight: "300px",
          borderRadius: "10px",
        }}
      >
        <Card.Body>
          <LoadImage dat={dat} image={image} />
          {/* {LoadImage(dat,image)} */}
          {/* <div
          className="mb-2"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            style={{
              objectFit: "contain",
              maxWidth: "70px",
              height: "70px",
              maxHeight: "70px",
            }}
            alt=""
            src={
              typeof image !== "undefined" &&
              image !== null &&
              image.length > 0 &&
              image !== "null"
                ? `${process.env.REACT_APP_API_URL}global/getFile2?uniqueId=${
                    dat.uniqueId
                  }&filename=${encodeURIComponent(
                    image[0].filename
                  )}&token=${localStorage.getItem("LUT")}`
                : emptyImg
            }
          />
        </div> */}
          {/* <style>
          {`
            .itemCardsName {
              font-size: 0.8rem;
              font-weight: 500;
              line-height: 1.5em;
              max-height: 3.2em;
              height: 134px;
              overflow: hidden;
              max-width: 100%;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
            }
            @media (max-width: 991px) {
              .btnAddToCart {
                font-size: 0.7rem;
              }
            }
           
          `}
        </style> */}
          <h6
            className="itemCardsName"
            style={{
              fontSize: "0.8rem",
              fontWeight: "500",
              lineHeight: "1.5em",
              maxHeight: "3.2em",
              // height: "134px",
              overflow: "hidden",
              maxWidth: "100%",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              textOverflow: "ellipsis",
            }} 
          >
            {dat.name === null ? "-" : dat.name}
          </h6>
          <p style={{ fontSize: "0.75rem", textOverflow: "ellipsis", }}> {dat.description ? dat.description : ''}</p>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <h5 style={{ flex: 1 }}>
              <NumericFormat
                value={misc.calculateMarkupDiscount(
                  dat.price,
                  dat.discount,
                  dat.priceModifier,
                  Settings.itemPriceModification
                )}
                prefix="₱ "
                displayType={"text"}
                thousandSeparator={true}
              />
            </h5>
            <h5 style={{ flex: 1, textAlign: "right" }}>
              {dat.qty > 0 ? (
                <NumericFormat
                  value={dat.qty}
                  suffix={` ${unit}`}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              ) : (
                <p className="m-0 p-0">No Stock</p>
              )}
            </h5>
          </div>

          {/* {dat.qty > 0 ? (
           
          ) : (
            <p className="m-0 p-0">No Stock</p>
          )} */}
           <Button
              onClick={() => {
                //console.log('trigger')
                // ItemListSelectHandler(dat);
                //console.log("SELECTED POS ITEM ===>", data);
                let _CartSelection = [];
                _CartSelection = _CartSelection.concat(CartData);
                // _CartSelection.push({
                //   id: data.id,
                //   itemQty: 0,
                //   price: 0,
                //   srp: data.price,
                //   itemId: data.id,
                //   itemIndex: 0,
                //   itemUnitId: 0,
                //   orderStatusId: 0,
                //   orderTypeId: 0,
                //   isPOS: 1,

                //   autoGenID: filters.uuidv4(),
                // });

                //find dupe and add qty
                const findDupe = _CartSelection.find((x) => x.id === dat.id);
                if (findDupe) {
                  // _CartSelection.push({
                  //   ...data,
                  //   qty: findDupe.qty + 1,
                  //   autoGenID: filters.uuidv4(),
                  // });
                  _CartSelection = _CartSelection.map((x) =>
                    x.id === findDupe.id ? { ...x, qty: x.qty + 1 } : x
                  );
                } else {
                  console.log(dat);
                  _CartSelection.push({
                    ...dat,
                    qty: 1,
                    autoGenID: filters.uuidv4(),
                    price: misc.calculateMarkupDiscount(
                      dat.price,
                      dat.discount,
                      dat.priceModifier,
                      Settings.itemPriceModification
                    ),
                    srp: dat.price,
                    priceModifier: Settings.itemPriceModification,
                  });
                }

                // LAG
                //Subtract qty from itemlists
                // let tempItemList = ItemListData;
                // tempItemList = tempItemList.map((x) => {
                //   return x.id === dat.id ? { ...x, qty: x.qty - 1 } : x;
                // });
                //console.log(tempItemList);

                //setItemListData(tempItemList);
                //setItemListDataCopy(tempItemList);

                setCartData(_CartSelection);
                //setCartDataCopy(_CartSelection);
              }}
              block
              style={{ backgroundColor: "var(--REB-tertiary)" }}
              className="btnAddToCart"
            >
              Add To Cart
            </Button>
        </Card.Body>
      </Card>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        maxHeight: "100% !important",
        overflow: "hidden",
        // marginTop: "-30px",
      }}
    >
      <CheckoutModal
        CheckoutModalOpen={CheckoutModalOpen}
        setCheckoutModalOpen={setCheckoutModalOpen}
        ValidateCheckout={ValidateCheckout}
        TxtCashTendered={TxtCashTendered}
        setTxtCashTendered={setTxtCashTendered}
        UIAmountDue={UIAmountDue}
        setUIAmountChange={setUIAmountChange}
        ClientData={ClientData}
        SelClientLoading={SelClientLoading}
        SelClient={SelClient}
        getActors={getActors}
        setSelClient={setSelClient}
        SelClientDisabled={SelClientDisabled}
        UIAmountChange={UIAmountChange}
        validateCheckout={validateCheckout}
        createInvoice={createInvoice}
      />
      <CartModal
        setCartModalOpen={setCartModalOpen}
        setCartSelection={setCartSelection}
        setSelectedCartList={setSelectedCartList}
        CartModalOpen={CartModalOpen}
        SelectedCartList={SelectedCartList}
        CartData={CartData}
        setCartData={setCartData}
        setCartDataCopy={setCartDataCopy}
        setPriceAdjustModalOpen={setPriceAdjustModalOpen}
        Settings={Settings}
        CURRENT_USERTYPE={CURRENT_USERTYPE}
        ItemListData={ItemListData}
        setItemListData={setItemListData}
      />
      <PriceAdjustModal
        PriceAdjustModalOpen={PriceAdjustModalOpen}
        setPriceAdjustModalOpen={setPriceAdjustModalOpen}
        SelectedCartList={SelectedCartList}
        setSelectedCartList={setSelectedCartList}
        UITxtDiscountPercent={UITxtDiscountPercent}
        setUITxtDiscountPercent={setUITxtDiscountPercent}
        UITxtDiscountAmount={UITxtDiscountAmount}
        setUITxtDiscountAmount={setUITxtDiscountAmount}
        UITxtDiscountedWhole={UITxtDiscountedWhole}
        setUITxtDiscountedWhole={setUITxtDiscountedWhole}
        UITxtMarkupPercent={UITxtMarkupPercent}
        setUITxtMarkupPercent={setUITxtMarkupPercent}
        UITxtMarkupAmount={UITxtMarkupAmount}
        setUITxtMarkupAmount={setUITxtMarkupAmount}
        UITxtMarkupWhole={UITxtMarkupWhole}
        setUITxtMarkupWhole={setUITxtMarkupWhole}
        Settings={Settings}
        CartData={CartData}
        setCartData={setCartData}
        setCartDataCopy={setCartDataCopy}
        setCartModalOpen={setCartModalOpen}
        CURRENT_USERTYPE={CURRENT_USERTYPE}
      />

      <style>
        {`
          .itemListCard {
            box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
              rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
              rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
          }
          @media (max-width: 991px) {
            .globalContainerStyle {
              border-radius: 0px !important;
              margin-top: 0px !important;
            }
            .container {
              padding: 0px !important;
              margin: 0px !important;
            }

            body {
              padding: 0px !important;
              margin: 0px !important;
              overflow: hidden;
            }

            .navbar-fixed-top,
            .navbar-fixed-bottom,
            .navbar-static-top {
              margin-left: 0px !important;
              margin-right: 0px !important;
              margin-bottom: 0px !important;
            }
            .stage {
              padding: 0px !important;
              margin: 0px !important;
            }
          }
          @media (min-width: 992px) {
            .globalContainerStyle {
              border-radius: 10px;
            }
          }
        `}
      </style>

      <div
        style={{ zIndex: "100" }}
        className="globalContainerStyle my-2 p-3 animate__animated animate__fadeInLeft"
      >
        <Row className="" style={{}} noGutters>
          <Col xs={12} sm={12} md={4} lg={4} className="mb-2 px-1">
            <InputGroup className="h-100">
              <InputGroup.Prepend>
                <InputGroup.Text className="actor-ico-search">
                  <i className="icofont-search icofont-1x"></i>
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder="Search"
                aria-label="Username"
                name="searchInput"
                className="actor-searchTable shadow-none h-100"
                aria-describedby="basic-addon1"
                onChange={ItemListSearchHandler}
              />
            </InputGroup>
          </Col>

          <Col xs={12} sm={6} md={4} lg={4} className="mb-2 px-1">
            <div
              className=""
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p
                className="m-0 pl-2"
                style={{
                  backgroundColor: "var(--REB-primary)",
                  color: "var(--REB-white)",
                  fontWeight: "bold",
                  fontSize: "0.8rem",
                  width: "230px",
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                SELECTED INVENTORY
              </p>
              <Select
                styles={{
                  menu: (provided, state) => {
                    //console.log(state);
                    const width = "100%";

                    let color = state.selectProps.menuColor;
                    const padding = 0;

                    return { ...provided, width, color, padding };
                  },
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "5px",
                  }),

                  container: (_, { selectProps: { width } }) => ({
                    width: width,
                    // flex: 1,
                  }),
                  menuPortal: (provided) => ({
                    ...provided,
                    zIndex: 9999,

                    borderRadius: "5px 0px 0px 5px",
                  }),

                  option: (provided, state) => {
                    let backgroundColor = "";
                    let display = "block";
                    let color = "black";
                    if (state.data.isDefault === 1) {
                      backgroundColor = "rgba(255, 0, 0, 0.2)";
                    }
                    const opacity = state.isDisabled ? 0.5 : 1;
                    const transition = "opacity 300ms";
                    //console.log(state.data);
                    if (state.data.isHidden === 1) {
                      display = "none";
                    }

                    return {
                      ...provided,
                      opacity,
                      transition,
                      backgroundColor,
                      color,
                      display,
                    };
                  },
                }}
                isSearchable
                menuPortalTarget={document.body}
                width="100%"
                options={InventoryData}
                value={SelInventory}
                isLoading={SelInventoryLoading}
                isDisabled={SelInventoryDisabled}
                getOptionLabel={(x) => x.name}
                getOptionValue={(x) => x.id}
                onChange={(x, name) => {
                  setSelInventory(x);
                }}
              />
            </div>
          </Col>
          <Col
            xs={4}
            sm={6}
            md={4}
            lg={4}
            className="px-2"
            style={{ alignSelf: "center" }}
          >
            {(CURRENT_USERTYPE.includes("Manager") ||
              CURRENT_USERTYPE.includes("Admin")) && (
              <Button
                className=""
                onClick={() => {
                  let dateOfReport = null;
                  dispatch({
                    type: "MODAL_SHOW",
                    payload: {
                      title: "Confirmation",
                      // desc: `Do you want to generate sales report for today? ${moment(
                      //   UIDateGenerateReportToday
                      // ).format("LL")}`,
                      JsxDesc: () => {
                        const [
                          tempUIDateGenerateReportToday,
                          settempUIDateGenerateReportToday,
                        ] = useState("");
                        return (
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <p
                                className="m-0 p-0"
                                style={{
                                  flex: 1,
                                  alignSelf: "center",
                                  fontWeight: "bolder",
                                }}
                              >
                                Please select date of report
                              </p>
                              <FormControl
                                type="date"
                                value={tempUIDateGenerateReportToday}
                                style={{ flex: 1 }}
                                onChange={(e) => {
                                  //console.log(e.currentTarget.value);
                                  settempUIDateGenerateReportToday(
                                    e.currentTarget.value
                                  );
                                  dateOfReport = e.currentTarget.value;
                                }}
                              />
                            </div>
                          </>
                        );
                      },
                      isYesNo: true,
                      cb: async (e) => {
                        if (!dateOfReport) {
                          alert("Report date should not be empty");
                        } else {
                          GetItemSalesByDate(
                            dateOfReport,
                            (TotalSalesDaily, MostOrderedItemsClients) => {
                              //console.log(TotalSalesDaily);
                              let perChunk = 15; // items per chunk

                              let result = MostOrderedItemsClients.reduce(
                                (resultArray, item, index) => {
                                  const chunkIndex = Math.floor(
                                    index / perChunk
                                  );
                                  if (!resultArray[chunkIndex]) {
                                    resultArray[chunkIndex] = []; // start a new chunk
                                  }
                                  resultArray[chunkIndex].push({
                                    ...item,
                                    index: index + 1,
                                  });
                                  return resultArray;
                                },
                                []
                              );
                              dispatch({
                                type: "PDF_MODAL_TOGGLE",
                                doc: SalesReport.SalesReport(
                                  {
                                    userType: CURRENT_USER.types,
                                    ...TotalSalesDaily,
                                    orders: result,
                                    ordersLength: TotalSalesDaily.orders.length,
                                  },
                                  CURRENT_USER,
                                  false,
                                  dateOfReport
                                ),
                              });
                            }
                          );
                        }
                      },
                    },
                  });
                }}
              >
                Generate Sales Report
              </Button>
            )}
          </Col>
        </Row>
      </div>
      <style>
        {`
          .ReactVirtualized__Grid__innerScrollContainer {
            overflow-y: scroll !important;
            height: 100% !important;
            max-height: 100% !important;
          }
          .POSContentCol2 {
            box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
              rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
              rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
          }
           {
            /* @media (max-width: 1100px) {
            .pricesContainer {
              padding-bottom: 115px !important;
            }
          } */
          }
          @media (max-width: 991px) {
            .POSContentCol2 {
              border-radius: 10px 0px 0px 0px;
            }
          }
          @media (min-width: 992px) {
            .POSContentCol2 {
              border-radius: 10px;
            }
            .AmountDueContainer {
              border-radius: 0px 0px 10px 10px;
            }
          }
        `}
      </style>
      <Row
        style={{ overflow: "hidden", maxHeight: "100%", height: "100%" }}
        className="pricesContainer"
        noGutters
      >
        <style>
          {`
            .ReactVirtualized__Grid__innerScrollContainer {
              overflow: auto !important;
              height: 100% !important;
              max-height: 100% !important;
              width: 100% !important;
            }
          `}
        </style>
        <Col style={{ maxHeight: "100%", height: "100%" }} xs={6}>
          {/* <InfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={async ({ startIndex, stopIndex }) =>
              await refreshItems(startIndex, stopIndex)
            }
            rowCount={10000}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    height={height}
                    rowCount={ItemListData.length}
                    rowHeight={20}
                    rowRenderer={POSItemContainer}
                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                    width={width}
                  />
                )}
              </AutoSizer>
            )}
          </InfiniteLoader> */}
          <AutoSizer
            disableHeight
            style={{ height: "100%", width: "100%", display: "flex" }}
          >
            {({ width }) => (
              <List
                height={500}
                rowCount={ItemListData.length}
                rowHeight={50}
                rowRenderer={POSItemContainer}
                width={width}
                overscanRowCount={3}
                style={{ display: "flex", height: "unset", width: "100%" }}
              />
            )}
          </AutoSizer>
          {/* <Row
            className=""
            style={{
              // overflow: "auto",
              maxHeight: "100%",
              height: "100%",
              paddingBottom: "100px",
            }}
            noGutters
          > */}
          {/* {console.log(ItemListData.length)} */}

          {/* {ItemListData.map((dat, index) => {
              const image = JSON.parse(dat.files);
              return (
                <Col
                  xs={6}
                  key={`${dat.uniqueId}-ItemListContent`}
                  className="pr-2 py-2 itemListCardContainer"
                  style={{ overFlow: "hidden" }}
                >
                  <Card
                    className="itemListCard"
                    style={{
                      height: "240px",
                      maxHeight: "240px",
                      borderRadius: "10px",
                    }}
                  >
                    <Card.Body>
                      <div
                        className="mb-2"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          style={{
                            objectFit: "contain",
                            maxWidth: "70px",
                            height: "70px",
                            maxHeight: "70px",
                          }}
                          alt=""
                          src={
                            typeof image !== "undefined" &&
                            image !== null &&
                            image.length > 0 &&
                            image !== "null"
                              ? `${
                                  process.env.REACT_APP_API_URL
                                }global/getFile2?uniqueId=${
                                  dat.uniqueId
                                }&filename=${encodeURIComponent(
                                  image[0].filename
                                )}&token=${localStorage.getItem("LUT")}`
                              : emptyImg
                          }
                        />
                      </div>
                      <style>
                        {`
                          .itemCardsName {
                            font-size: 0.8rem;
                            font-weight: 500;
                            line-height: 1.5em;
                            max-height: 3.2em;
                            height: 134px;
                            overflow: hidden;
                            max-width: 100%;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            text-overflow: ellipsis;
                          }
                          @media (max-width: 991px) {
                            .btnAddToCart {
                              font-size: 0.7rem;
                            }
                          }
                          @media (min-width: 992px) {
                          }
                        `}
                      </style>
                      <h6 className="itemCardsName">
                        {dat.name === null ? "-" : dat.name}
                      </h6>
                      <h5>
                        <NumericFormat 
                          value={dat.price}
                          prefix="₱ "
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </h5>

                      <Button
                        onClick={() => {
                          ItemListSelectHandler(dat);
                        }}
                        block
                        style={{ backgroundColor: "var(--REB-tertiary)" }}
                        className="btnAddToCart"
                      >
                        Add To Cart
                      </Button>
                    </Card.Body>
                  </Card>
              
                </Col>
              );
            })} */}
          {/* </Row> */}
        </Col>

        <Col xs={6} className="pt-2 " style={{}}>
          <div
            className="POSContentCol2"
            style={{
              //borderRadius: "10px 0px 0px 0px",

              display: "flex",
              flexDirection: "column",
              height: "100%",
              backgroundColor: "var(--REB-bg-color)",
            }}
          >
            <Row className="mt-3" noGutters>
              {CartData.length === 0 && (
                <Col xs={12} className="  px-1">
                  <h5
                    className="m-0"
                    style={{
                      alignSelf: "center",
                      textAlign: "center",
                      fontWeight: "bolder",
                    }}
                  >
                    Ready for transaction
                  </h5>
                </Col>
              )}
              {/* <Col
                xs={12}
                sm={12}
                lg={4}
                xl={4}
                className=" d-flex justify-content-start  px-2"
              >
                <Button
                  variant="secondary"
                  className=""
                  style={{
                    backgroundColor: "var(--REB-yellow)",
                    color: "var(--REB-black)",
                    fontWeight: "bolder",
                    fontSize: "1rem",
                    height: "3rem",
                    width: "8rem",
                  }}
                  onClick={(e) => {
                    setPriceAdjustModalOpen(true);
                  }}
                >
                  Price Adjust
                </Button>
              </Col> */}
              {CartData.length > 0 && (
                <Col
                  xs={12}
                  sm={12}
                  lg={4}
                  xl={4}
                  className=" d-flex justify-content-start  px-2"
                >
                  <Button
                    variant="secondary"
                    className=""
                    style={{
                      backgroundColor: "var(--REB-yellow)",
                      color: "var(--REB-black)",
                      fontWeight: "bolder",
                      fontSize: "1rem",
                      height: "3rem",
                      width: "8rem",
                    }}
                    onClick={(e) => {
                      dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "Confirmation",
                          desc: `Are you sure you want to reset?`,
                          isYesNo: true,
                          cb: async (e) => {
                            handlePOSReset();
                          },
                        },
                      });
                    }}
                  >
                    <i className="icofont-refresh"></i> Reset
                  </Button>
                </Col>
              )}
              {CartData.length > 0 && (
                <Col
                  xs={12}
                  sm={12}
                  lg={4}
                  xl={4}
                  className="actor-btn-crud-col d-flex justify-content-end px-2"
                >
                  <Button
                    variant="secondary"
                    className=""
                    style={{
                      backgroundColor: "var(--REB-green)",
                      fontWeight: "bolder",
                      fontSize: "1rem",
                      height: "3rem",
                      width: "8rem",
                    }}
                    onClick={(e) => {
                      const WALK_IN = ClientData.find(
                        (x) => x.company === "WALK-IN"
                      );
                      if (WALK_IN) {
                        setSelClient(WALK_IN);
                      }
                      setCheckoutModalOpen(true);
                    }}
                  >
                    <i className="icofont-cart"></i> Checkout
                  </Button>
                </Col>
              )}
            </Row>
            <div style={{}}>{tableCart}</div>
            <div
              className="pt-2"
              style={{ flex: 3, display: "flex", flexDirection: "column" }}
            >
              <Row className="px-2" noGutters>
                <Col xs={6}>Total Items:</Col>
                <Col xs={6}>
                  <p className="m-0" style={{}}>
                    <NumericFormat
                      value={UITotalQtyItems}
                      // prefix="₱ "

                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </p>
                </Col>
              </Row>
              <Row className="px-2" noGutters>
                <Col xs={6}>Total Line Items:</Col>
                <Col xs={6}>
                  <p className="m-0" style={{}}>
                    <NumericFormat
                      value={CartData.length}
                      // prefix="₱ "

                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </p>
                </Col>
              </Row>
              <Row className="px-2" noGutters>
                <Col xs={6}>VAT Sales:</Col>
                <Col xs={6}>
                  <NumericFormat
                    value={UIVatSales}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </Col>
              </Row>
              <Row className="px-2" noGutters>
                <Col xs={6}>VAT:</Col>
                <Col xs={6}>
                  <NumericFormat
                    value={UIVat}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </Col>
              </Row>

              <style>
                {`
                  @media (max-width: 991px) {
                    .lblAmountDue {
                      font-size: 0.95rem;
                    }
                  }
                  @media (min-width: 992px) {
                    .lblAmountDue {
                      font-size: 1.5rem;
                    }
                  }
                `}
              </style>
              <div
                style={{
                  display: "flex",
                  // alignContent: "end",
                  // flexGrow: 1,
                  flexDirection: "column",
                  marginTop: "auto",
                }}
              >
                <style>
                  {`
                    @media (max-width: 991px) {
                      .lblAmountDue {
                        font-size: 0.95rem;
                      }
                    }
                    @media (min-width: 992px) {
                      .lblAmountDue {
                        font-size: 1.5rem;
                      }
                    }
                  `}
                </style>
                <Row
                  noGutters
                  className="AmountDueContainer"
                  style={{ backgroundColor: "#025940" }}
                >
                  <Col
                    xs={6}
                    className={"p-2"}
                    // style={{ backgroundColor: "#60BF81" }}
                  >
                    <p
                      className="m-0 lblAmountDue"
                      style={{ color: "var(--REB-white)" }}
                    >
                      Amount Due:
                    </p>
                  </Col>
                  <Col
                    xs={6}
                    className={"p-2"}
                    // style={{ backgroundColor: "#60BF81" }}
                  >
                    <p
                      className="m-0 lblAmountDue"
                      style={{
                        fontWeight: "bolder",
                        color: "var(--REB-white)",
                      }}
                    >
                      <NumericFormat
                        value={UIAmountDue}
                        prefix="₱ "
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {/* {tableItemList} */}

      {/*  */}
    </div>
  );
};
