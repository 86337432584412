import React, { useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import fileReg from "../../assets/file-regular.svg";
import { ButtonGroup } from "react-bootstrap";
import invoiceApi from "../Invoice/InvoiceAPI";
import SignatureCanvas from "react-signature-canvas";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { SELECTED_OPERATION } from "../../constants";
import Filters from "../../functions/filters";
function TxtFileUpload(props) {
  const [btnSave, setBtnSave] = useState(true);
  const inputFile = useRef();
  return (
    <>
      <InputGroup
        className="mb-3 p-3"
        style={{ backgroundColor: "var(--REB-white)" }}
      >
        <FormControl
          type="file"
          custom
          ref={inputFile}
          data-browse="Browse"
          className="inputFileUpload"
          name="fileUploadInvoice"
          // disabled={props.state.txtInvoiceNameDR.readOnly}
          label={"Test"}
          onClick={(e) => (e.target.value = null)}
          onChange={(e) => {
            props.state.handleActionChange({
              target: {
                name: "handleFileUploadChange",
                files: e.target.files,
              },
            });
            //console.log(Array.from(e.target.files));

            if (Array.from(e.target.files).length === 0) {
              setBtnSave(true);
            } else {
              setBtnSave(false);
            }
          }}
        />

        <ButtonGroup className="">
          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip id={`order-tooltip-add`}>Save</Tooltip>}
          >
            <Button
              variant="secondary"
              size="sm"
              style={{ backgroundColor: "var(--REB-green)" }}
              onClick={(e) => {
                // props.state.fileUpload.handleSubmit(e);
                e.preventDefault();

                props.state.handleActionChange(
                  {
                    target: {
                      name: "btnFileUploadSubmit",
                      value: e,
                    },
                  },
                  null,
                  () => {
                    inputFile.current.value = null;
                  }
                );
              }}
              disabled={btnSave}
            >
              Save
            </Button>
          </OverlayTrigger>
        </ButtonGroup>
      </InputGroup>
      <Row className="p-3">
        {props.state.selectedRowDR
          ? props.state.selectedRowDR.uploads.map((i, index) => (
              <Col sm={3} key={index}>
                <Card className="text-center" style={{ padding: "1px" }}>
                  <Card.Body>
                    <Row className="mb-3">
                      <Col>
                        <Image src={fileReg} style={{ width: "20%" }} />
                      </Col>
                    </Row>
                    <Row noGutters>
                      {/* <Col className="">
                        <Button
                          size="md"
                          style={{
                            backgroundColor: "var(--REB-green)",
                          }}
                          onClick={(e) => {
                            props.state.handleActionChange({
                              target: {
                                name: "btnFileUploadDownload",
                                complete: i.originalname,
                                id: props.state.selectedRowDR.uniqueId,
                                filename: i.filename,
                              },
                            });
                          }}
                          name="btnDLLink"
                          key={index}
                        >
                          Download
                        </Button>
                      </Col> */}
                      <Col className="">
                        <Button
                          size="md"
                          style={{
                            backgroundColor: "var(--REB-green)",
                          }}
                          onClick={(e) => {
                            props.state.handleActionChange({
                              target: {
                                name: "btnFileUploadView",
                                filename: i.filename,
                                originalname: i.originalname,
                                id: props.state.selectedRowDR.uniqueId,
                              },
                            });
                          }}
                        >
                          View
                        </Button>
                      </Col>
                      {/* <Col className="">
                        <Button
                          size="md"
                          name={`delete${i.filename}`}
                          value={i.filename}
                          // disabled={props.state.txtInvoiceNameDR.readOnly}
                          style={{
                            backgroundColor: "var(--REB-warning)",
                          }}
                          onClick={(e) => {
                            props.state.handleActionChange({
                              target: {
                                name: "btnFileUploadDelete",
                                filename: i.filename,
                                originalname: i.originalname,
                              },
                            });
                          }}
                        >
                          Delete
                        </Button>
                      </Col> */}
                    </Row>
                  </Card.Body>
                  <Card.Footer>{i.originalname}</Card.Footer>
                </Card>
              </Col>
            ))
          : ""}
      </Row>
    </>
  );
}
function TxtFileUploadReceiversImageContainer(props) {
  const [btnSave, setBtnSave] = useState(true);

  const {
    InputReceiversImage,
    setInputReceiversImage,
    ImgPreview,
    setImgPreview,
  } = props;

  const inputFileReceiver = useRef();
  return (
    <>
      {InputReceiversImage && (
        <div
          style={{ display: "flex", justifyContent: "center" }}
          className="mb-3"
        >
          {ImgPreview && (
            <Image
              src={ImgPreview}
              name="btnHome"
              style={{
                width: "25%",
                height: "25%",
              }}
              alt="receivers-image"
            />
          )}
        </div>
      )}

      <InputGroup
        className="mb-3 p-3 "
        style={{
          backgroundColor: "var(--REB-white)",
          border: "1px solid #ced4da",
          borderRadius: "10px",
          alignItems: "center",
        }}
      >
        <FormControl
          type="file"
          custom
          ref={inputFileReceiver}
          data-browse="Browse"
          className="inputFileUpload"
          name="fileUploadInvoice"
          // disabled={props.state.txtInvoiceNameDR.readOnly}
          label={"Test"}
          onClick={(e) => (e.target.value = null)}
          onChange={(e) => {
            setInputReceiversImage(e.target.files[0]);

            const reader = new FileReader();
            reader.onload = function (ev) {
              setImgPreview(ev.target.result);
            };
            reader.readAsDataURL(e.target.files[0]);

            props.state.handleActionChange({
              target: {
                name: "handleFileUploadChange",
                files: e.target.files,
              },
            });
            // console.log(e.target.files);
            //console.log(Array.from(e.target.files));

            if (Array.from(e.target.files).length === 0) {
              setBtnSave(true);
            } else {
              setBtnSave(false);
            }
          }}
        />

        {/* <ButtonGroup className="">
          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip id={`order-tooltip-add`}>Attach Image</Tooltip>}
          >
            <Button
              variant="secondary"
              style={{
                backgroundColor: "var(--REB-green)",
                height: "55px",
                fontWeight: "bolder",
              }}
              onClick={(e) => {
                // props.state.fileUpload.handleSubmit(e);
                e.preventDefault();
                console.log(e);
                // props.state.handleActionChange(
                //   {
                //     target: {
                //       name: "btnFileUploadSubmit",
                //       value: e,
                //     },
                //   },
                //   null,
                //   () => {
                //     inputFileReceiver.current.value = null;
                //   }
                // );
              }}
              disabled={btnSave}
            >
              Attach Receivers Image
            </Button>
          </OverlayTrigger>
        </ButtonGroup> */}
      </InputGroup>
      {/* <Row className="p-3">
        {props.state.selectedRowDR
          ? props.state.selectedRowDR.uploads.map((i, index) => (
              <Col sm={3} key={index}>
                <Card className="text-center" style={{ padding: "1px" }}>
                  <Card.Body>
                    <Row className="mb-3">
                      <Col>
                        <Image src={fileReg} style={{ width: "20%" }}  />
                      </Col>
                    </Row>
                    <Row noGutters>
                      <Col className="">
                        <Button
                          size="md"
                          style={{
                            backgroundColor: "var(--REB-green)",
                          }}
                          onClick={(e) => {
                            props.state.handleActionChange({
                              target: {
                                name: "btnFileUploadDownload",
                                complete: i.originalname,
                                id: props.state.selectedRowDR.uniqueId,
                                filename: i.filename,
                              },
                            });
                          }}
                          name="btnDLLink"
                          key={index}
                        >
                          Download
                        </Button>
                      </Col>
                      <Col className="">
                        <Button
                          size="md"
                          style={{
                            backgroundColor: "var(--REB-green)",
                          }}
                          onClick={(e) => {
                            props.state.handleActionChange({
                              target: {
                                name: "btnFileUploadView",
                                filename: i.filename,
                                originalname: i.originalname,
                                id: props.state.selectedRowDR.uniqueId,
                              },
                            });
                          }}
                        >
                          View
                        </Button>
                      </Col>
                      <Col className="">
                        <Button
                          size="md"
                          name={`delete${i.filename}`}
                          value={i.filename}
                          // disabled={props.state.txtInvoiceNameDR.readOnly}
                          style={{
                            backgroundColor: "var(--REB-warning)",
                          }}
                          onClick={(e) => {
                            props.state.handleActionChange({
                              target: {
                                name: "btnFileUploadDelete",
                                filename: i.filename,
                                originalname: i.originalname,
                              },
                            });
                          }}
                        >
                          Delete
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>{i.originalname}</Card.Footer>
                </Card>
              </Col>
            ))
          : ""}
      </Row> */}
    </>
  );
}
let TxtInvoiceName = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      DR Name
    </Form.Label>
    <FormControl
      type="text"
      style={{ textTransform: "uppercase" }}
      placeholder="Name"
      defaultValue={props.state.txtInvoiceName}
      readOnly
    />
  </>
);
let TxtShippingAddress = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      Shipping Address
    </Form.Label>
    <FormControl
      type="text"
      placeholder="Shipping Address"
      name="txtShippingAddress"
      defaultValue={props.state.txtShippingAddress}
      readOnly
    />
  </>
);
let TxtApprovedBy = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      Approved By
    </Form.Label>
    <FormControl
      type="text"
      placeholder="Approved By"
      name="txtApprovedBy"
      defaultValue={props.state.txtApprovedBy}
      readOnly
    />
  </>
);
let TxtDateDeadline = (props) => (
  <>
    <Form.Label>
      <strong>Deadline</strong>
    </Form.Label>
    <FormControl
      type="datetime-local"
      placeholder="Deadline"
      name="txtDeadline"
      defaultValue={props.state.txtDeadline}
      readOnly
    />
  </>
);
let TxtDateAdded = (props) => (
  <>
    <Form.Label>
      <strong>Date Added</strong>
    </Form.Label>
    <FormControl
      type="datetime-local"
      //placeholder={props.state.txtDateAdded.placeholder}
      name="txtDateAdded"
      defaultValue={props.state.txtDateAdded}
      readOnly
    />
  </>
);
let TxtDateLastModified = (props) => (
  <>
    <Form.Label>
      <strong>Date Last Modified</strong>
    </Form.Label>
    <FormControl
      type="datetime-local"
      //placeholder={props.state.txtDateLastModified.placeholder}
      name="txtDateLastModified"
      defaultValue={props.state.txtDateLastModified}
      readOnly
    />
  </>
);
let TxtClient = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      Client
    </Form.Label>
    <FormControl
      type="text"
      style={{ textTransform: "uppercase" }}
      placeholder="Name"
      name="txtClient"
      defaultValue={props.state.txtClient}
      readOnly
    />
  </>
);
let TxtNotes = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      Notes
    </Form.Label>
    <FormControl
      as="textarea"
      //style={{ textTransform: "uppercase" }}
      placeholder="Notes"
      name="txtNotes"
      defaultValue={props.state.txtNotes}
      readOnly
    />
  </>
);
let TxtSalesPerson = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      Sales Person
    </Form.Label>
    <FormControl
      type="text"
      style={{ textTransform: "uppercase" }}
      placeholder="Name"
      name="txtSalesperson"
      defaultValue={props.state.txtSalesperson}
      readOnly
    />
  </>
);
let TxtInvoiceStatus = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      DR Status
    </Form.Label>
    <FormControl
      type="text"
      style={{ textTransform: "uppercase" }}
      placeholder="Name"
      name="txtInvoiceStatusName"
      defaultValue={props.state.txtInvoiceStatusName}
      readOnly
    />
  </>
);

const DriverModal = (props) => {
  const SignatureInputRef = useRef();
  const dispatch = useDispatch();
  const [TxtReceiversName, setTxtReceiversName] = useState("");
  const [TxtReceiversNameDisabled, setTxtReceiversNameDisabled] =
    useState(false);
  const [InputReceiversImage, setInputReceiversImage] = useState([]);
  const [ImgPreview, setImgPreview] = useState(null);
  const [SignatureImage, setSignatureImage] = useState(null);
  const [InvoiceStatus, setInvoiceStatus] = useState(null);

  const [SignatureOperation, setSignatureOperation] = useState(
    SELECTED_OPERATION.VIEW
  );

  //Listen to table selection
  useEffect(() => {
    // console.log(props.state);
    if (
      props.state.selectedRowDR !== null &&
      props.state.selectedRowDR !== undefined
    ) {
      const { receiversName, uploads, invoiceStatus } =
        props.state.selectedRowDR;
      receiversName && setTxtReceiversName(receiversName);
      const _SigImage = uploads.find((x) => x.originalname === "signature.png");
      if (_SigImage) setSignatureImage(_SigImage);
      setInvoiceStatus(invoiceStatus.name);
    }
  }, [props.state.selectedRowDR]);
  useEffect(() => {
    setImgPreview(null);
    setSignatureImage(null);
  }, [props.state.handleDriverModal.isShow]);

  const uploadReceiverInfo = async (cb) => {
    const loadId = Filters.uuidv4();
    dispatch({
      type: "API_TOGGLE_LOADING",
      payload: {
        payload: {
          isLoading: true,
          name: "Uploading Receivers Info",
          id: loadId,
        },
      },
    });
    const formData = new window.FormData();
    //console.log(props.state.selectedRowDR);
    formData.append("uniqueId", props.state.selectedRowDR.uniqueId);
    formData.append("id", props.state.selectedRowDR.id);
    formData.append("receiversName", TxtReceiversName);
    formData.append("receiversImg", InputReceiversImage.name);

    const canvas = SignatureInputRef.current.getCanvas();

    //wait for signature to convert to blob
    const myPromise = new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      });
    });
    //After conversion, append to form then post
    myPromise.then((blob) => {
      formData.append("signature", blob, "signature.png");
      formData.append("files", InputReceiversImage);
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }
      dispatch({
        type: "API_TOGGLE_LOADING",
        payload: {
          payload: { isLoading: false, id: loadId },
        },
      });
      invoiceApi.uploadDRReceiver(dispatch, formData, async () => {
        if (cb) cb();
      });
      //if (cb) cb();
    });
  };

  return (
    <Modal
      show={props.state.handleDriverModal.isShow}
      onHide={props.state.handleDriverModal.toggle}
      onExit={(e) => {
        props.state.handleDriverModal.handleExit(e);
      }}
      dialogClassName="modal-actor-100w"
      //size="xl"
      centered
    >
      <Modal.Body className="actor-modal-body">
        <Row className="actor-modal-header-row pt-3 pb-3 mb-3">
          <Col xs={2} sm={1} md={1} lg={1}>
            <OverlayTrigger
              placement={"right"}
              overlay={<Tooltip id={`project-tooltip-add`}>Close</Tooltip>}
            >
              <Button
                variant="secondary"
                name="btnCloseProjectModal"
                style={{ backgroundColor: "var(--REB-primary)" }}
                className="icofont-rounded-left"
                onClick={(e) => {
                  props.state.handleDriverModal.toggle();
                }}
              />
            </OverlayTrigger>
          </Col>
          <Col xs={10} sm={11} md={7} lg={7} className="actor-modal-header-col">
            <h4 className=" actor-modal-header-text">
              Selected DR Information
            </h4>
          </Col>
          {/* <Col xs={12} sm={11} md={3} lg={3} className="user-col-cont-padding">
            <OverlayTrigger
              placement={"left"}
              overlay={
                <Tooltip id={`project-tooltip-add`}>
                  View PDF for this DR
                </Tooltip>
              }
            >
              <Button
                block
                name="btnViewPDF"
                style={{ backgroundColor: "var(--REB-green)" }}
                onClick={(e) => {
                  props.state.handleActionChange(e);
                }}
              >
                VIEW PDF
              </Button>
            </OverlayTrigger>
          </Col> */}
        </Row>

        <Form
          id="formDashboardDR"
          noValidate
          validated={props.state.handleDriverModal.isValidated}
          onSubmit={props.state.handleDriverModal.handleDriverSubmit}
        >
          <Row noGutters>
            <Col xs={12} sm={12} md={4} lg={4} className="px-2 mb-2">
              <TxtInvoiceName state={props.state} />
            </Col>

            <Col xs={12} sm={12} md={4} lg={4} className="px-2 mb-2">
              <TxtShippingAddress state={props.state} />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} className="px-2 mb-2">
              <TxtDateAdded state={props.state} />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} className="px-2 mb-2">
              <TxtDateLastModified state={props.state} />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} className="px-2 mb-2">
              <TxtDateDeadline state={props.state} />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} className="px-2 mb-2">
              <TxtSalesPerson state={props.state} />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} className="px-2 mb-2">
              <TxtClient state={props.state} />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} className="px-2 mb-2">
              <TxtApprovedBy state={props.state} />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} className="px-2 mb-2">
              <TxtInvoiceStatus state={props.state} />
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} className="px-2">
              <TxtNotes state={props.state} />
            </Col>
          </Row>
          <div className="pt-0 mt-2" style={{ backgroundColor: "#e9ecef" }}>
            <Row
              className="p-3 mb-3"
              style={{
                backgroundColor: "var(--REB-primary)",
                borderRadius: "5px 5px 0px 0px",
              }}
              noGutters
            >
              <Col>
                <h4 style={{ fontWeight: "bolder", color: "var(--REB-white)" }}>
                  DR Documents
                </h4>
              </Col>
            </Row>
            <Row noGutters className="px-3 ">
              <Col>
                <TxtFileUpload state={props.state} />
              </Col>
            </Row>
          </div>
          {props.tableContent}

          {InvoiceStatus === "Ongoing Delivery" && (
            <>
              <div
                className="mt-2"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h5 style={{ flex: 1, textAlign: "center" }}>
                  Receivers Image
                </h5>
                <p style={{ textAlign: "center", flex: 1 }} className="m-0">
                  Please attach receivers image before attaching signature
                </p>
                <TxtFileUploadReceiversImageContainer
                  state={props.state}
                  InputReceiversImage={InputReceiversImage}
                  setInputReceiversImage={setInputReceiversImage}
                  ImgPreview={ImgPreview}
                  setImgPreview={setImgPreview}
                />
              </div>
              {ImgPreview && (
                <>
                  {" "}
                  <div
                    className="mt-2"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <h5 style={{ textAlign: "center", flex: 1 }}>
                      Receivers Signature
                    </h5>
                    {SignatureOperation === SELECTED_OPERATION.ADD && (
                      <p
                        style={{ textAlign: "center", flex: 1 }}
                        className="m-0"
                      >
                        Please sign in the box provided below
                      </p>
                    )}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      // height: "250px",
                      margin: "0 auto",
                      backgroundColor: "var(--REB-white)",
                      border: "1px solid black",
                    }}
                  >
                    {SignatureOperation === SELECTED_OPERATION.ADD && (
                      <SignatureCanvas
                        canvasProps={{
                          style: {
                            width: "100%",
                            height: "250px",
                          },
                          // width: "100",
                          // height: "100",
                        }}
                        // ref={(ref) => {
                        //   SignatureInputRef = ref;
                        // }}
                        ref={SignatureInputRef}
                      />
                    )}

                    {props.state.selectedRowDR.uploads && SignatureImage && (
                      <div
                        style={{
                          display: "flex",
                          height: "250px",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{
                            objectFit: "contain",

                            height: "50%",
                          }}
                          alt="Receivers-Signature"
                          src={`${
                            process.env.REACT_APP_API_URL
                          }global/getFile2?uniqueId=${
                            props.state.selectedRowDR.uniqueId
                          }&filename=${encodeURIComponent(
                            SignatureImage.filename
                          )}&token=${localStorage.getItem("LUT")}`}
                        />
                      </div>
                    )}
                  </div>
                  {SignatureOperation === SELECTED_OPERATION.ADD && (
                    <p style={{ textAlign: "center", flex: 1 }} className="m-0">
                      I/We have received the delivered items stated in this DR
                      complete and without damage.
                    </p>
                  )}
                  <div
                    style={{ display: "flex", flexDirection: "column" }}
                    className="mt-2"
                  >
                    <Form.Label
                      className="font-weight-bold"
                      style={{
                        color: "var(--REB-black)",
                        flex: 1,
                        margin: 0,
                        alignSelf: "start",
                      }}
                    >
                      Receivers Full Name
                    </Form.Label>
                    <div style={{ display: "flex" }}>
                      <FormControl
                        type="text"
                        style={{
                          textTransform: "uppercase",
                          flex: 4,
                          height: "55px",
                        }}
                        placeholder="Enter Receivers Full Name Here"
                        value={TxtReceiversName}
                        disabled={TxtReceiversNameDisabled}
                        onChange={(e) => setTxtReceiversName(e.target.value)}
                      />
                      {SignatureOperation === SELECTED_OPERATION.VIEW &&
                        SignatureImage === null && (
                          <Button
                            style={{
                              fontWeight: "bolder",
                              backgroundColor: "var(--REB-green)",
                            }}
                            className="ml-3"
                            disabled={!TxtReceiversName}
                            onClick={() => {
                              setSignatureOperation(SELECTED_OPERATION.ADD);
                              setTxtReceiversNameDisabled(true);
                            }}
                          >
                            ADD SIGNATURE
                          </Button>
                        )}
                      {SignatureOperation === SELECTED_OPERATION.ADD &&
                        SignatureImage === null && (
                          <Button
                            style={{
                              fontWeight: "bolder",
                              backgroundColor: "var(--REB-warning)",
                            }}
                            className="ml-3"
                            onClick={() => {
                              setSignatureOperation(SELECTED_OPERATION.VIEW);
                              setTxtReceiversNameDisabled(false);
                              SignatureInputRef.current.clear();
                              setTxtReceiversName("");
                            }}
                          >
                            CLEAR
                          </Button>
                        )}
                    </div>
                  </div>
                </>
              )}

              <div
                className="mt-2"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {/* <Button
                  style={{
                    backgroundColor: "var(--REB-green)",
                    height: "55px",
                    fontWeight: "bolder",
                  }}
                  onClick={(e) => {
                    
                  }}
                >
                  Upload Receivers Info
                </Button> */}
                {/* {ImgPreview && (
                  <Button
                    style={{
                      backgroundColor: "var(--REB-warning)",
                      height: "55px",
                      fontWeight: "bolder",
                    }}
                    onClick={(e) => {
                      SignatureInputRef.current.clear();
                    }}
                  >
                    Clear Signature
                  </Button>
                )} */}
              </div>
            </>
          )}

          <Row
            className="mb-3 mt-3 actor-modal-footer-row actor-modal-footer"
            noGutters
          >
            {props.state.txtInvoiceStatusName === "Ongoing Delivery" ? (
              <Col xs={12} sm={12} md={6} lg={6} className="" style={{}}>
                <Button
                  block
                  className=""
                  name="btnSetDRDelivered"
                  style={{
                    backgroundColor: "var(--REB-green)",
                    height: "55px",
                    fontWeight: "bolder",
                  }}
                  onClick={(e) => {
                    let err = [];
                    if (TxtReceiversName === null || TxtReceiversName === "") {
                      err.push({
                        err: "TxtReceiversName",
                        msg: "Receivers name cant be empty",
                      });
                    }
                    if (SignatureInputRef.current)
                      if (SignatureInputRef.current.isEmpty()) {
                        err.push({
                          err: "SignatureInput",
                          msg: "Receivers signature cant be empty",
                        });
                      }
                    if (ImgPreview === null) {
                      err.push({
                        err: "Receiver Image",
                        msg: "Receivers Image cant be empty",
                      });
                    }
                    if (err.length > 0) {
                      props.state.dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "Incomplete Receiver Info",
                          JsxDesc: () => {
                            return (
                              <>
                                {err.map((_dat) => {
                                  return (
                                    <p
                                      key={Filters.uuidv4()}
                                      style={{
                                        fontWeight: "bolder",
                                        textAlign: "center",
                                      }}
                                    >
                                      {_dat.msg}
                                    </p>
                                  );
                                })}
                              </>
                            );
                          },
                          isYesNo: false,
                          cb: () => {},
                        },
                      });
                    } else {
                      props.state.dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "Delivery Confirmation",
                          desc: `Please review all DR details and items. Are you sure you want to set this delivery to delivered?`,
                          isYesNo: true,
                          cb: () => {
                            uploadReceiverInfo(() => {
                              props.state.handleActionChange(e);
                            });
                          },
                        },
                      });
                    }

                    // const _SignatureImage = SignatureInputRef.current
                    //   .getTrimmedCanvas()
                    //   .toDataURL("image/png");
                    // setSignatureImage(_SignatureImage);
                    // dispatch({
                    //   type: "FILE_VIEWER_MODAL_TOGGLE",
                    //   doc: SignatureImage,
                    // });
                  }}
                >
                  SET DR DELIVERED
                </Button>
              </Col>
            ) : null}
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export { DriverModal };
