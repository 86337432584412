import React from "react";
import PropTypes from "prop-types";
import Table from "react-table";
//import selectTableHOC from "react-table/lib/hoc/selectTable";
import Filters from "../../functions/filters";
import {
  Inventory,
  itemColumn,
  itemDetailsColumn,
  itemSetColumn,
  requestColumn,
  PurchaseHistoryColumns,
} from "./Inventory.js";
import { connect } from "react-redux";
import api from "./InventoryAPI";
import invoiceApi from "../Invoice/InvoiceAPI";
import actorApi from "../Actor/ActorAPI";
import emptyImg from "../../assets/fork-lift.svg";
import { SELECTED_OPERATION } from "../../constants";
import InvReport from "../Invoice/PDF/InvReport";
import misc from "../../functions/misc";
import settingsApi from "../Settings/SettingsAPI";
import { PRItemHistoryTableColumn } from "../Invoice/PurchaseRequestContent";
import InventoryAPI from "./InventoryAPI";
import { setInvoiceHeaderData } from "../../functions/pdfHeaderData";

//const SelectTable = selectTableHOC(Table);

class InventoryProcess extends React.Component {
  static defaultProps = {
    keyField: "id",
  };

  static propTypes = {
    keyField: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.itemDetailsSelectRef = React.createRef();
    this.InventorySelectRef = React.createRef();
    this.ItemModalRef = React.createRef();
    this.state = {
      handleInventoryContentChange:
        this.handleInventoryContentChange.bind(this),
      downloadImage: this.downloadImage.bind(this),
      getInventory: this.getInventory.bind(this),
      handleInventoryChange: this.handleInventoryChange.bind(this),
      handleSearch: this.handleSearch.bind(this),
      inputChange: this.inputChange.bind(this),
      dispatch: this.props.dispatch,

      handleActionChange: this.handleActionChange.bind(this),
      handleActionChangeItemDetails:
        this.handleActionChangeItemDetails.bind(this),
      handleOperationChangeItemDetails:
        this.handleOperationChangeItemDetails.bind(this),
      handleSubmit: this.handleSubmit.bind(this),

      refreshItemDetail: this.refreshItemDetail.bind(this),
      refreshSelectedItem: this.refreshSelectedItem.bind(this),

      handleItemModal: {
        handleItemSubmit: this.handleItemSubmit.bind(this),
        handleExit: this.handleItemExit.bind(this),
        toggle: this.handleToggleItemModal.bind(this),
        isValidated: false,
        isYesNo: true,
        isShow: false,
        isView: false,
        title: "",
        desc: "",
        response: false,
      },

      handleItemDetailsModal: {
        handleItemSubmit: this.handleItemDetailsSubmit.bind(this),
        handleExit: this.handleItemDetailsExit.bind(this),
        toggle: this.handleItemDetailsToggleModal.bind(this),
        isValidated: false,
        isYesNo: true,
        isShow: false,
        isView: false,
        title: "",
        desc: "",
        response: false,
      },

      handleItemRequestModal: {
        //handleSubmit: this.handleRequestSubmit.bind(this),
        //handleExit: this.handleRequestExit.bind(this),
        toggle: this.handleRequestToggleModal.bind(this),
        isValidated: false,
        isYesNo: true,
        isShow: false,
        isView: false,
        title: "",
        desc: "",
        response: false,
      },

      fileUpload: {
        handleSubmit: this.handleFileUploadSubmit.bind(this),
        handler: this.handleFileUploadChange.bind(this),
        value: "",
      },

      // GetFromDB

      selectAll: false,
      selection: [],
      searchInput: "",
      itemSelection: null,
      checkboxTable: {},
      selectedInventory: null,
      selectedItem: null,
      selectedOperation: SELECTED_OPERATION.ADD,
      selectedModalOperation: SELECTED_OPERATION.ADD,
      selectedItemDetailType: { value: 0, name: "name" },

      isLoading: false,
      isFiltered: false,

      tableSelection: null,

      selectedContent: <></>,
      selectedColumn: [],
      userType: this.props.userType,
      user: this.props.user,
      itemData: [],
      bakItemData: [],

      itemDetailsConst: [],
      inventoryData: [],

      POCogsData: [],
      PREV_PURCHASES: [],

      //SetItemsentoryItemsTable
      SetItemsTableSelection: [],
      selectedRowSetItems: null,
      SetItemsTableData: [],
      SetItemsDataCopy: [],
      SetItemsTableloading: false,

      itemImgs: [{ original: emptyImg, isDefault: true }],

      selInventoryName: {
        value: "",
        className: "",
        readOnly: false,
      },

      txtItemDetailName: { value: "", className: "", readOnly: false },
      detModalSelType: { value: "", className: "", readOnly: false },

      selDetailName: { value: "", className: "", readOnly: false, data: [] },
      selDetailType: { value: "", className: "", readOnly: false, data: [] },
      selDetailUnit: { value: "", className: "", readOnly: false, data: [] },
      selDetailBrand: { value: "", className: "", readOnly: false, data: [] },
      selDetailStatus: { value: "", className: "", readOnly: false, data: [] },
      selDetailGroup: { value: "", className: "", readOnly: false, data: [] },

      selDetailContentStatus: {
        value: "",
        readOnly: false,
        data: [],
      },
      selDetailContentType: {
        value: "",
        readOnly: false,
        data: [],
      },

      selName: {
        value: "",
        readOnly: false,
      },
      txtQty: { value: 0, className: "", readOnly: false },
      txtPrice: { value: 0, className: "", readOnly: false },
      txtGrossPrice: { value: 0, className: "", readOnly: false },
      txtNetPrice: { value: 0, className: "", readOnly: false },
      txtCOGS: { value: 0, className: "", readOnly: false },
      txtDisplay: { value: 0, className: "", readOnly: false },
      txtCode: { value: 0, className: "", readOnly: false },
      txtShippingCost: { value: 0, className: "", readOnly: false },
      txtHandlingCost: { value: 0, className: "", readOnly: false },
      txtDesc: { value: "", className: "", readOnly: false },
      txtNotes: { value: "", className: "", readOnly: false },
      txtRestockWarn: { value: "", className: "", readOnly: false },
      txtDiscountMax: { value: "", className: "", readOnly: false },
      txtLastRestock: { value: "", className: "", readOnly: false },
      txtSKU: { value: "", className: "", readOnly: false },
      txtBarcode: { value: "", className: "", readOnly: false },
      txtSerial: { value: "", className: "", readOnly: false },

      chkSet: { readOnly: false, value: false },

      selSetItemName: {
        value: null,
        readOnly: false,
      },

      txtRequestQty: {
        value: "",
        className: "",
        readOnly: false,
        placeHolder: "Number of items to be requested",
        label: "Order QTY",
      },
      txtRequestNote: { value: "", className: "", readOnly: false },
      requisitionType: "Item Order Request",

      chkDispImage: { value: false, readOnly: false },

      selPDFSort: {
        value: "name",
        readOnly: false,
      },
      txtSearch: "",
      //POS COMPONENTS
      txtPOSItemName: { value: "", className: "", readOnly: false },
      txtPOSItemNameShort: { value: "", className: "", readOnly: false },
      txtPOSItemDesc: { value: "", className: "", readOnly: false },
      txtPOSItemCode: { value: "", className: "", readOnly: false },
      chkIsPOSVisible: { value: true, className: "", readOnly: false },
      chkIsDiscountable: { value: false, className: "", readOnly: false },
      chkIsTaxable: { value: false, className: "", readOnly: false },
      permissions: this.props.permissions,

      chkIsService: { value: false, className: "", readOnly: false },

      selPDFSortByType: {
        value: "",
        readOnly: false,
      },
      selPDFSortByBrand: {
        value: "",
        readOnly: false,
      },
      copiedItem: null,
      PRICE_HISTORY: [],
    };
  }

  async onRefreshInventory(data) {}

  async componentDidMount() {
    const detData = await api.getItemDetailsConst(this.props.dispatch);
    //const invData = await api.getInventory(this.props.dispatch);
    this.setState(
      (prevState) => ({
        itemDetailsConst: detData,
        isInitialPageMount: false,
        //inventoryData: invData,
      }),
      async () => {
        await this.initializeItemDetails();
        await this.initializeInvoiceDetails();
        this.handleInventoryContentChange({ target: { value: 1 } });
        this.props.socketio.socket.on("refreshInventory", async (data) => {
          if (data.userId !== this.props.user.id) {
            const inventoryItems = await InventoryAPI.getItemsByInventory(
              this.props.dispatch,
              Number(this.state.selectedInventory),
              null,
              null,
              null,
              null,
              null
            );
            //console.log(inventoryItems, this.state.selInventory.value.id);
            this.setState((a) => ({
              itemData: inventoryItems,
              bakItemData: inventoryItems,
            }));
            this.props.dispatch({
              type: "TOAST_ADD",
              payload: {
                title: `Inventory Items Updated`,
              },
            });
          }
        });
      }
    );

    //await new Promise((resolve) => setTimeout(resolve, 1000));
  }

  componentWillUnmount() {
    this.props.socketio.socket.off("refreshInventory");
  }

  inputChange = (e) => {
    //console.log(e.target.name);
    this.setState((a) => ({
      [e.target.name]: {
        ...a[e.target.name],
        value: e.target.value,
        placeholder: e.target.placeholder,
        className: e.target.className,
      },
    }));
  };

  handleItemDetailsToggleModal = (e) => {
    if (this.state.handleItemDetailsModal.isShow)
      this.setState((x) => ({
        handleItemDetailsModal: {
          ...x.handleItemDetailsModal,
          isShow: false,
        },
      }));
    else
      this.setState((x) => ({
        handleItemDetailsModal: {
          ...x.handleItemDetailsModal,
          isShow: true,
        },
      }));
  };

  handleRequestToggleModal = (e) => {
    if (this.state.handleItemRequestModal.isShow)
      this.setState((x) => ({
        handleItemRequestModal: {
          ...x.handleItemRequestModal,
          isShow: false,
        },
      }));
    else
      this.setState((x) => ({
        handleItemRequestModal: {
          ...x.handleItemRequestModal,
          isShow: true,
        },
      }));
  };

  handleFileUploadSubmit = async (e) => {
    e.preventDefault();
    let currentFiles = this.state.fileUpload.value;
    //const fileType = currentFiles[0].type.split("/")[1];
    let formData = new FormData();
    formData.append("id", this.state.tableSelection.id);
    for (let index = 0; index < currentFiles.length; index++) {
      await formData.append(
        "uploadedFiles",
        currentFiles[index]
        //`signature.${fileType}`
      );
    }

    api.addItemImg(this.props.dispatch, formData, async (a) => {
      this.refreshImageData();
    });
  };
  refreshImageData = async (cb) => {
    const newItem = await api.getItem(
      this.props.dispatch,
      this.state.tableSelection.id
    );
    let _img = await api.downloadItemImg(this.props.dispatch, newItem);
    const inventoryItems = await api.getItemsByInventory(
      this.props.dispatch,
      Number(this.state.selectedInventory)
    );

    this.setState(
      { itemImgs: _img, itemData: inventoryItems, bakItemData: inventoryItems },
      async (x) => {
        if (cb) await cb();
      }
    );
  };
  handleFileUploadChange = (e) => {
    let currentFiles = e.target.files;

    this.setState((x) => ({
      fileUpload: {
        ...x.fileUpload,
        value: currentFiles,
      },
    }));
  };

  downloadFile = (e) => {
    const dat = { id: "", name: "" };
    api.downloadItemImg(this.props.dispatch, dat, async (a) => {
      const url = window.URL.createObjectURL(new Blob([a.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${e.complete}`);
      document.body.appendChild(link);
      link.click();
    });
  };

  downloadImage = async (item) => {
    //console.log(item);

    const image = await api.downloadItemImg(
      this.props.dispatch,
      item,
      (x) => {},
      1
    );

    const newData = this.state.itemData.map((x) =>
      x.id === item.id ? { ...x, dispImg: image[0] } : x
    );

    this.setState({ itemData: newData });
  };

  deleteFile = async (filename, origName) => {
    if (this.state.itemImgs[0].isDefault) {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Empty item image",
          desc: `No image to delete`,
          isYesNo: false,
        },
      });
    } else {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Confirmation",
          desc: `Are you sure you want to delete ${origName} ?`,
          isYesNo: true,
          cb: async (e) => {
            let dat = {
              uniqueId: this.state.actorSelection.uniqueId,
              filename: filename,
            };

            api.deleteItemImg(this.props.dispatch, dat, async (x) => {
              this.refreshImageData(async (x) => {
                if (this.state.itemImgs.length === 0) {
                  this.setState({
                    itemImgs: [{ original: emptyImg, isDefault: true }],
                  });
                }
              });
            });
          },
        },
      });
    }
  };

  handleItemDetailsSubmit = (e) => {};

  handleItemDetailsExit = (e) => {};

  handleToggleItemModal = (e) => {
    if (this.state.handleItemModal.isShow)
      this.setState((x) => ({
        handleItemModal: {
          ...x.handleItemModal,
          isShow: false,
        },
      }));
    else
      this.setState((x) => ({
        handleItemModal: {
          ...x.handleItemModal,
          isShow: true,
        },
      }));
  };

  handleItemSubmit = (e) => {};

  handleItemExit = (e) => {};

  handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    let isValid = true;
    //console.log(this.state.selectedContent);
    switch (this.state.selectedContent.value) {
      case 2:
        if (
          this.state.detModalSelType.value === null ||
          this.state.detModalSelType.value === ""
        )
          isValid = false;
        if (
          this.state.txtItemDetailName.value === null ||
          this.state.txtItemDetailName.value === ""
        )
          isValid = false;
        if (form.checkValidity() === false || !isValid) {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Validation warning",
              desc: `please fill in the form properly`,
              isYesNo: false,
            },
          });

          this.setState((x) => ({
            handleItemDetailsModal: {
              ...x.handleItemDetailsModal,
              isValidated: false,
            },
            selection: [],
          }));
        } else {
          if (this.state.selectedOperation === SELECTED_OPERATION.ADD) {
            this.createItemDetails();
          } else {
            this.updateItemDetails();
          }
          this.handleItemDetailsToggleModal();

          this.setState((x) => ({
            handleItemDetailsModal: {
              ...x.handleItemDetailsModal,
              isValidated: false,
            },

            selection: [],
          }));
        }
        break;

      case 1:
        if (
          this.state.selDetailName.value === null ||
          this.state.selDetailName.value === ""
        )
          isValid = false;
        if (
          this.state.selDetailBrand.value === null ||
          this.state.selDetailBrand.value === ""
        )
          isValid = false;
        if (
          this.state.selDetailStatus.value === null ||
          this.state.selDetailStatus.value === ""
        )
          isValid = false;
        if (
          this.state.selDetailUnit.value === null ||
          this.state.selDetailUnit.value === ""
        )
          isValid = false;
        if (
          this.state.txtPrice.value === null ||
          this.state.txtPrice.value === ""
        )
          isValid = false;
        if (this.state.txtQty.value === null || this.state.txtQty.value === "")
          isValid = false;
        if (form.checkValidity() === false || !isValid) {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Validation warning",
              desc: `please fill in the form properly`,
              isYesNo: false,
            },
          });

          this.setState((x) => ({
            handleItemModal: {
              ...x.handleItemModal,
              isValidated: false,
            },
            selection: [],
          }));
        } else {
          if (this.state.selectedOperation === SELECTED_OPERATION.ADD) {
            this.createItem();
          } else {
            this.updateItem();
          }
          this.handleToggleItemModal();

          this.setState((x) => ({
            handleItemModal: {
              ...x.handleItemModal,
              isValidated: false,
            },

            selection: [],
          }));
        }
        break;
      default:
        break;
    }
  };

  clearInputs = (readonly, clear) => {
    this.setState((x) => ({
      txtItemDetailName: {
        value: clear ? "" : x.txtItemDetailName.value,
        className: "",
        readOnly: readonly,
      },
      detModalSelType: {
        value: clear ? "" : x.detModalSelType.value,
        className: "",
        readOnly: readonly,
      },

      selDetailName: {
        ...x.selDetailName,
        value: clear ? "" : x.selDetailName.value,
        className: "",
        readOnly: readonly,
      },
      selDetailType: {
        ...x.selDetailType,
        value: clear ? "" : x.selDetailType.value,
        className: "",
        readOnly: readonly,
      },
      selDetailUnit: {
        ...x.selDetailUnit,
        value: clear ? "" : x.selDetailUnit.value,
        className: "",
        readOnly: readonly,
      },
      selDetailBrand: {
        ...x.selDetailBrand,
        value: clear ? "" : x.selDetailBrand.value,
        className: "",
        readOnly: readonly,
      },
      selDetailStatus: {
        ...x.selDetailStatus,
        value: clear ? "" : x.selDetailStatus.value,
        className: "",
        readOnly: readonly,
      },
      selDetailGroup: {
        ...x.selDetailGroup,
        value: clear ? "" : x.selDetailGroup.value,
        className: "",
        readOnly: readonly,
      },
      selName: {
        value: clear ? "" : x.selName.value,
        readOnly: readonly,
      },
      txtQty: {
        value: clear ? "" : x.txtQty.value,
        className: "",
        readOnly: readonly,
      },
      txtPrice: {
        value: clear ? "" : x.txtPrice.value,
        className: "",
        readOnly: readonly,
      },
      txtGrossPrice: {
        value: clear ? "" : x.txtGrossPrice.value,
        className: "",
        readOnly: readonly,
      },
      txtNetPrice: {
        value: clear ? "" : x.txtNetPrice.value,
        className: "",
        readOnly: readonly,
      },
      txtShippingCost: {
        value: clear ? "" : x.txtShippingCost.value,
        className: "",
        readOnly: readonly,
      },
      txtHandlingCost: {
        value: clear ? "" : x.txtHandlingCost.value,
        className: "",
        readOnly: readonly,
      },
      txtDesc: {
        value: clear ? "" : x.txtDesc.value,
        className: "",
        readOnly: readonly,
      },
      txtNotes: {
        value: clear ? "" : x.txtNotes.value,
        className: "",
        readOnly: readonly,
      },
      txtRestockWarn: {
        value: clear ? "" : x.txtRestockWarn.value,
        className: "",
        readOnly: readonly,
      },
      txtDiscountMax: {
        value: clear ? "" : x.txtDiscountMax.value,
        className: "",
        readOnly: readonly,
      },
      txtLastRestock: {
        value: clear ? "" : x.txtLastRestock.value,
        className: "",
        readOnly: readonly,
      },
      txtSKU: {
        value: clear ? "" : x.txtSKU.value,
        className: "",
        readOnly: readonly,
      },
      txtBarcode: {
        value: clear ? "" : x.txtBarcode.value,
        className: "",
        readOnly: readonly,
      },
      txtSerial: {
        value: clear ? "" : x.txtSerial.value,
        className: "",
        readOnly: readonly,
      },
      txtCOGS: {
        value: clear ? "" : x.txtCOGS.value,
        className: "",
        readOnly: readonly,
      },
      txtDisplay: {
        value: clear ? "" : x.txtDisplay.value,
        className: "",
        readOnly: readonly,
      },
      txtCode: {
        value: clear ? "" : x.txtCode.value,
        className: "",
        readOnly: readonly,
      },
      txtPOSItemName: {
        value: clear ? "" : x.txtPOSItemName.value,
        className: "",
        readOnly: readonly,
      },
      txtPOSItemNameShort: {
        value: clear ? "" : x.txtPOSItemNameShort.value,
        className: "",
        readOnly: readonly,
      },
      txtPOSItemDesc: {
        value: clear ? "" : x.txtPOSItemDesc.value,
        className: "",
        readOnly: readonly,
      },
      txtPOSItemCode: {
        value: clear ? "" : x.txtPOSItemCode.value,
        className: "",
        readOnly: readonly,
      },
      chkIsPOSVisible: {
        value: clear ? true : x.chkIsPOSVisible.value,
        className: "",
        readOnly: readonly,
      },
      chkIsDiscountable: {
        value: clear ? "" : x.chkIsDiscountable.value,
        className: "",
        readOnly: readonly,
      },
      chkIsTaxable: {
        value: clear ? "" : x.chkIsTaxable.value,
        className: "",
        readOnly: readonly,
      },
      chkIsService: {
        value: clear ? "" : x.chkIsService.value,
        className: "",
        readOnly: readonly,
      },
    }));
  };

  populateItemDetailModal = (data) => {
    let itemSet = [];
    for (let i = 0; i < data.itemSets.length; i++) {
      const e = data.itemSets[i];
      itemSet.push(e.itemNameDetail);
    }

    if (data.itemSets.length > 0) {
      this.setState((x) => ({
        chkSet: {
          ...x.chkSet,
          value: true,
        },
      }));
    } else {
      this.setState((x) => ({
        chkSet: {
          ...x.chkSet,
          value: false,
        },
      }));
    }

    this.setState((x) => ({
      txtItemDetailName: { ...x.txtItemDetailName, value: data.name },
      detModalSelType: { ...x.detModalSelType, value: data.type },
      SetItemsTableData: itemSet,
    }));
  };

  createItemDetails = async (e) => {
    //const name = this.state.selectedItemDetailType.name.toLowerCase();

    let _itemSet = [];

    for (let i = 0; i < this.state.SetItemsTableData.length; i++) {
      const _data = this.state.SetItemsTableData[i];
      _itemSet.push({ itemNameDetailId: _data.id });
    }

    const data = [
      {
        details: [
          {
            name: this.state.txtItemDetailName.value.toUpperCase(),
            type: Number(this.state.selectedItemDetailType.value),
            itemSets: _itemSet,
          },
        ],
      },
    ];
    //console.log(data);
    if (this.state.itemData.find((x) => x.name === data[0].details[0].name)) {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Duplicate Notice",
          desc: `Item name you entered already exists`,
          isYesNo: false,
        },
      });
    }

    await api.createItem(this.props.dispatch, data);
    const detData = await api.getItemDetail(
      this.props.dispatch,
      this.state.selectedItemDetailType.value
    );
    this.clearInputs();
    await this.initializeItemDetails();
    this.setState({
      itemData: detData,
      bakItemData: detData,
    });
  };

  updateItemDetails = async () => {
    let _itemSet = [];

    for (let i = 0; i < this.state.SetItemsTableData.length; i++) {
      const _data = this.state.SetItemsTableData[i];
      _itemSet.push({ itemNameDetailId: _data.id });
    }
    const data = {
      details: [
        {
          id: Number(this.state.tableSelection.id),
          name: this.state.txtItemDetailName.value.toUpperCase(),
          type: Number(this.state.detModalSelType.value),
          itemSets: _itemSet,
        },
      ],
    };

    await api.updateItem(this.props.dispatch, data);

    const detData = await api.getItemDetail(
      this.props.dispatch,
      this.state.selectedItemDetailType.value
    );
    this.clearInputs();
    this.setState({
      itemData: detData,
      bakItemData: detData,
    });
  };

  deleteItemDetail = async (id) => {
    await api.deleteitemDetail(this.props.dispatch, id);
    const detData = await api.getItemDetail(
      this.props.dispatch,
      this.state.selectedItemDetailType.value
    );
    this.clearInputs();
    this.setState({
      itemData: detData,
      bakItemData: detData,
    });
  };
  getInventory = async (e) => {
    await api.getInventory(this.props.dispatch).then((invData) => {
      this.setState({
        inventoryData: invData,
      });
    });
  };

  handleOperationChangeItemDetails = (e) => {
    // console.log(
    //   this.state.selectedItemDetailType.name,
    //   this.state.selDetailType
    // );
    switch (e.target.name) {
      case "btnAddItemDetails":
        this.handleItemDetailsToggleModal();
        this.clearInputs(false, true);

        this.setState((x) => ({
          chkSet: {
            ...x.chkSet,
            value: false,
          },
          selectedOperation: SELECTED_OPERATION.ADD,
          detModalSelType: {
            ...x.detModalSelType,
            value: this.state.selectedItemDetailType.value,
          },
          handleItemDetailsModal: {
            ...x.handleItemDetailsModal,
            isView: false,
          },

          selection: [],
        }));
        break;

      default:
        break;
    }
  };

  handleActionChange = async (e, data, cb) => {
    //console.log(data);

    const _e = e.target.name;
    if (_e === "pdfSortBy") {
      //console.log(e.target.value);
      this.setState((x) => ({
        selPDFSort: {
          ...x.selPDFSort,
          value: e.target.value,
        },
      }));
    }
    if (_e === "btnTotalCogs") {
      let handling = 0 + Number(this.state.txtHandlingCost.value);
      let shipping = 0 + Number(this.state.txtShippingCost.value);
      let cost = 0 + Number(this.state.txtNetPrice.value);

      let totalCost = handling + shipping + cost;

      this.setState((x) => ({
        txtCOGS: {
          ...x.txtCOGS,
          value: totalCost,
        },
      }));
    }
    if (_e === "downloadPDF") {
      const DateToday = await actorApi.getCurrentDateTime(this.state.dispatch);
      const fileName = `Inventory Report ${misc.parseAPIDate(DateToday)}`;
      const href = await window.URL.createObjectURL(new Blob([e.target.value]));
      const link = document.createElement("a");
      link.href = href;

      link.download = fileName + ".pdf";
      document.body.appendChild(link);
      link.click();
      this.setState((x) => ({
        chkDispImage: {
          ...x.chkDispImage,
          readOnly: false,
        },
      }));
      if (cb) cb();
    }
    if (_e === "btnViewInvReport") {
      this.props.dispatch({
        type: "PDF_MODAL_TOGGLE",
        doc: InvReport.InvReport(
          this.state,
          this.props.user,
          this.state.chkDispImage.value,
          this.state.settings?.itemPriceModification
        ),
      });
    }
    if (_e === "btnGenerateInvReport") {
      if (!this.state.selInventoryName.value) {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "No Inventory Selection",
            desc: `Please select an Inventory`,
            isYesNo: false,
          },
        });
        return;
      }
      // if (this.state.inventoryData.length === 0) {
      //   this.props.dispatch({
      //     type: "MODAL_SHOW",
      //     payload: {
      //       title: "Inventory Empty",
      //       desc: `You dont have permission to access any inventory. Please contact your administrator`,
      //       isYesNo: false,
      //     },
      //   });
      // }
      else {
        // const _items = this.state.itemData;
        this.setState((x) => ({
          chkDispImage: {
            ...x.chkDispImage,
            readOnly: true,
          },
        }));

        const perChunk = 11; // items per chunk
        // const sorted = _items.sort((a, b) =>
        //   a.name < b.name ? -1 : a.name > b.name ? 1 : 0
        // );

        let inventoryItems = await api.getItemsByInventory(
          this.props.dispatch,
          Number(this.state.selectedInventory) === 1
            ? 0
            : Number(this.state.selectedInventory),
          this.state.selPDFSort.value
        );

        if (this.state.selPDFSortByType.value) {
          inventoryItems = inventoryItems.filter(
            (x) => x.type === this.state.selPDFSortByType.value.name
          );
        }
        if (this.state.selPDFSortByBrand.value) {
          inventoryItems = inventoryItems.filter(
            (x) => x.brand === this.state.selPDFSortByBrand.value.name
          );
        }

        let result = inventoryItems.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / perChunk);
          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
          }
          resultArray[chunkIndex].push({ ...item, index: index + 1 });
          return resultArray;
        }, []);

        this.setState({ pdfGroupTable: { result } }, async (x) => {
          // this.props.dispatch({
          //   type: "PDF_MODAL_TOGGLE",
          //   doc: InvReport.InvReport(
          //     this.state,
          //     this.props.user,
          //     this.state.chkDispImage.value
          //   ),
          // });
          if (cb) cb();
        });
      }
    }
    if (_e === "chkDispImage") {
      this.setState((x) => ({
        chkDispImage: {
          ...x.chkDispImage,
          value: this.state.chkDispImage.value ? false : true,
        },
      }));
    }
    if (_e === "chkHandleChange") {
      this.setState((x) => ({
        [e.target.componentName]: {
          ...x[e.target.componentName],
          value: this.state[e.target.componentName].value ? false : true,
        },
      }));
    }
    if (_e === "detModalSelType") {
      this.setState((x) => ({
        chkSet: {
          ...x.chkSet,
          value: false,
        },
        detModalSelType: {
          ...x.detModalSelType,
          value: e.target.value,
        },
      }));
    }
    if (_e === "chkSet") {
      //console.log("trigger");
      this.setState((x) => ({
        chkSet: {
          ...x.chkSet,
          value: this.state.chkSet.value === true ? false : true,
        },
      }));
      // this.handleRequestToggleModal();
    }
    if (_e === "btnRequestItem") {
      this.setState((x) => ({
        selectedOperation: SELECTED_OPERATION.ADD,
        txtRequestQty: { ...x.txtRequestQty, readOnly: false },
        txtRequestNote: { ...x.txtRequestNote, readOnly: false },
      }));
      this.handleToggleItemModal();
      this.handleRequestToggleModal();
    }
    if (_e === "btnRemoveItemRequest") {
      // console.log(this.state.selectedOperation);
      if (this.state.selectedOperation === SELECTED_OPERATION.EDIT) {
        await invoiceApi.deleteOrderContent(
          this.props.dispatch,
          Number(this.state.tableSelection.id)
        );
        this.refreshInventoryRequests();

        this.handleActionChange({ target: { name: "btnCancelItemRequest" } });
      }
    }
    if (_e === "btnAddItemSet") {
      if (this.state.selSetItemName.value !== null) {
        let itemSets = this.state.SetItemsTableData;

        if (
          itemSets.find((x) => x.name === this.state.selSetItemName.value.name)
        ) {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Duplicate",
              desc: `This item is already in your selection`,
              isYesNo: false,
            },
          });
        } else {
          itemSets.push(this.state.selSetItemName.value);
        }
        //console.log(itemSets);
        this.setState((x) => ({
          SetItemsTableData: itemSets,
          selSetItemName: {
            ...x.selSetItemName,
            value: null,
          },
        }));
      } else {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "No Selection",
            desc: `Please select an item to be added`,
            isYesNo: false,
          },
        });
      }
    }
    if (_e === "btnRemoveItemSet") {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Confirmation",
          desc: `Are you sure you want to remove this item set?`,
          isYesNo: true,
          cb: () => {
            let itemSets = this.state.SetItemsTableData;
            itemSets = itemSets.filter(
              (x) => x.name !== this.state.selectedRowSetItems.name
            );

            this.setState((x) => ({
              SetItemsTableData: itemSets,
              selectedRowSetItems: null,
              SetItemsTableSelection: [],
              selSetItemName: {
                ...x.selSetItemName,
                value: null,
              },
            }));
          },
        },
      });
    }
    if (_e === "selItemName") {
      this.setState((x) => ({
        selSetItemName: {
          ...x.selSetItemName,
          value: e.target.value,
        },
      }));
    }
    if (_e === "selDetailName") {
      let isDupe = false;
      const tableData = this.state.itemData;

      for (let i = 0; i < tableData.length; i++) {
        const det = tableData[i].details;
        const hasDupe = det.find((x) => x.id === e.target.value.id);
        if (hasDupe) isDupe = true;
      }
      if (isDupe) {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Item Duplicate",
            desc: `This item already exists in this inventory.`,
            isYesNo: false,
          },
        });
      } else {
        this.setState((a) => ({
          [e.target.name]: {
            ...a[e.target.name],
            value: e.target.value,
            placeholder: e.target.placeholder,
            className: e.target.className,
          },
        }));
      }
    }
    if (_e === "btnSubmitItemRequest") {
      //console.log(this.state.selectedOperation);
      if (this.state.selectedOperation === SELECTED_OPERATION.EDIT) {
        let ORDER_TYPE_ID = Number(
          this.state.selDetailContentType.data.find(
            (x) => x.name === this.state.requisitionType
          ).id
        );
        const data = {
          id: Number(this.state.tableSelection.id),
          itemQty: Number(this.state.txtRequestQty.value),
          lastModifiedByActorId: Number(this.state.user.id),
          notes: this.state.txtRequestNote.value,
          orderTypeId: ORDER_TYPE_ID,
          orderStatusId: Number(
            this.state.selDetailContentStatus.data.find(
              (x) => x.name === "For order"
            ).id
          ),
        };

        //console.log("Updated REQUEST DATA ==> ", data);

        await invoiceApi.updateInvoiceContent(this.props.dispatch, data);
        actorApi.sendEmail(
          this.props.dispatch,
          `Inventory Item Modification Request`,
          this.state.user.lastName,
          this.state.selectedInventory
        );
        this.refreshInventoryRequests();

        this.handleActionChange({ target: { name: "btnCancelItemRequest" } });
      } else {
        let ORDER_TYPE_ID = Number(
          this.state.selDetailContentType.data.find(
            (x) => x.name === this.state.requisitionType
          ).id
        );
        if (ORDER_TYPE_ID > 0) {
          const data = {
            itemQty: Number(this.state.txtRequestQty.value),
            itemId: Number(this.state.tableSelection.id),
            itemUnitId: Number(
              this.state.tableSelection.details.find((x) => x.type === 2).id
            ),
            orderStatusId: Number(
              this.state.selDetailContentStatus.data.find(
                (x) => x.name === "For order"
              ).id
            ),
            orderTypeId: ORDER_TYPE_ID,

            isInventoryRequest: 1,
            lastModifiedByActorId: Number(this.state.user.id),
            requestedByActorId: Number(this.state.user.id),
            notes: this.state.txtRequestNote.value,
            orderId: null,
          };
          //console.log("CREATED REQUEST DATA ==> ", data);

          await invoiceApi.createInvoiceContent(this.props.dispatch, data);
          actorApi.sendEmail(
            this.props.dispatch,
            `Inventory Item Modification Request`,
            this.state.user.lastName,
            this.state.selectedInventory
          );
          this.setState(
            (x) => ({
              selectedOperation: SELECTED_OPERATION.EDIT,
            }),
            () => {
              this.handleToggleItemModal();
            }
          );

          //this.refreshInventoryRequests();

          this.handleActionChange({ target: { name: "btnCancelItemRequest" } });
        } else {
          //console.log("Error getting OrderTypeID");
        }
      }
    }
    if (_e === "btnCancelItemRequest") {
      this.handleRequestToggleModal();
      this.setState((x) => ({
        txtRequestQty: { ...x.txtRequestQty, value: "" },
        txtRequestNote: { ...x.txtRequestNote, value: "" },
      }));
    }
    if (_e === "btnAddDisplayItem") {
      if (Number(this.state.txtQty.value) <= 0) {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Notice",
            desc: `No Item to be displayed`,
            isYesNo: false,
          },
        });
      } else {
        this.setState((x) => ({
          txtQty: {
            ...x.txtQty,
            value: Number(this.state.txtQty.value) - 1,
          },
          txtDisplay: {
            ...x.txtDisplay,
            value: Number(this.state.txtDisplay.value) + 1,
          },
        }));
      }

      // await api.subtractItemQty(this.props.dispatch, {
      //   id: Number(this.state.tableSelection.id),
      //   qty: 1,
      // });
    }
    if (_e === "btnSubtractDisplayItem") {
      if (Number(this.state.txtDisplay.value) <= 0) {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Notice",
            desc: `Display cant be negative`,
            isYesNo: false,
          },
        });
      } else {
        this.setState((x) => ({
          txtQty: {
            ...x.txtQty,
            value: Number(this.state.txtQty.value) + 1,
          },
          txtDisplay: {
            ...x.txtDisplay,
            value: Number(this.state.txtDisplay.value) - 1,
          },
        }));
      }

      // await api.addItemQty(this.props.dispatch, {
      //   id: Number(this.state.tableSelection.id),
      //   qty: 1,
      // });
    }
    if (_e === "selItemRequestType") {
      //console.log(e.target.value);
      if (e.target.value === "Item Requisition") {
        this.setState((x) => ({
          txtRequestQty: {
            ...x.txtRequestQty,
            placeHolder: "Number of items to be Requisitioned",
            label: "Requisition QTY",
          },
          requisitionType: "Item Requisition",
        }));
      }
      if (e.target.value === "Item Order Request") {
        this.setState((x) => ({
          txtRequestQty: {
            ...x.txtRequestQty,
            placeHolder: "Number of items to be requested",
            label: "Order Request QTY",
          },
          requisitionType: "Item Order Request",
        }));
      }
      if (e.target.value === "Add Item Qty Request") {
        this.setState((x) => ({
          txtRequestQty: {
            ...x.txtRequestQty,
            placeHolder: "Number of item qty to be added",
            label: "Qty to be added",
          },
          requisitionType: "Add Item Qty Request",
        }));
      }
      if (e.target.value === "Deduct Item Qty Request") {
        this.setState((x) => ({
          txtRequestQty: {
            ...x.txtRequestQty,
            placeHolder: "Number of item qty to be deducted",
            label: "Qty to be deducted",
          },
          requisitionType: "Deduct Item Qty Request",
        }));
      }
      if (e.target.value === "Return Item Request") {
        this.setState((x) => ({
          txtRequestQty: {
            ...x.txtRequestQty,
            placeHolder: "Number of item qty to be returned",
            label: "Qty to be returned",
          },
          requisitionType: "Return Item Request",
        }));
      }
    }
    switch (e.target.name) {
      case "btnAddItem":
        if (!this.state.selInventoryName.value) {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "No Inventory Selection",
              desc: `Please select an Inventory`,
              isYesNo: false,
            },
          });
          return;
        } else {
          //this.clearInputs();
          this.clearInputs(false, true);
          this.state.selectedContent.value === 1
            ? this.handleToggleItemModal()
            : this.handleItemDetailsToggleModal();
          this.setState((x) => ({
            selectedOperation: SELECTED_OPERATION.ADD,
            handleItemModal: { ...x.handleItemModal, isView: false },
            itemImgs: [{ original: emptyImg, isDefault: true }],
            handleItemDetailsModal: {
              ...x.handleItemDetailsModal,
              isView: false,
            },
            selection: [],
            SetItemsTableSelection: [],
            SetItemsTableData: [],
          }));
          return;
        }

      case "btnUnlockItem":
        if (
          this.state.userType.includes("Admin") ||
          this.state.userType.includes("Manager") ||
          this.state.permissions.includes(
            "perm-ui-inventoryPage-view-write-manage"
          )
        ) {
          this.clearInputs(false);
          if (this.state.selectedContent.value === 1) {
            this.setState((x) => ({
              handleItemModal: { ...x.handleItemModal, isView: false },
            }));
          } else {
            this.setState((x) => ({
              handleItemDetailsModal: {
                ...x.handleItemDetailsModal,
                isView: false,
              },
            }));
          }
        }
        // else if (this.state.userType.includes("Sales Person")) {
        //   this.clearInputs(true);
        //   if (this.state.selectedContent.value === 1) {
        //     this.setState((x) => ({
        //       handleItemModal: { ...x.handleItemModal, isView: false },
        //       txtPrice: { ...x.txtPrice, readOnly: false },
        //     }));
        //   } else {
        //     this.setState((x) => ({
        //       txtItemDetailName: { ...x.txtItemDetailName, readOnly: false },
        //       handleItemDetailsModal: {
        //         ...x.handleItemDetailsModal,
        //         isView: false,
        //       },
        //     }));
        //   }
        // }
        else if (
          this.state.userType.includes("Purchaser") ||
          this.state.permissions.includes(
            "perm-ui-inventoryPage-view-write-manage"
          )
        ) {
          this.clearInputs(true);
          if (this.state.selectedContent.value === 1) {
            this.setState((x) => ({
              handleItemModal: { ...x.handleItemModal, isView: false },
              txtHandlingCost: { ...x.txtHandlingCost, readOnly: false },
              txtShippingCost: { ...x.txtShippingCost, readOnly: false },
              txtNetPrice: { ...x.txtNetPrice, readOnly: false },
              txtCOGS: { ...x.txtCOGS, readOnly: false },
              txtPrice: { ...x.txtPrice, readOnly: false },
            }));
          } else {
            this.setState((x) => ({
              handleItemDetailsModal: {
                ...x.handleItemDetailsModal,
                isView: false,
              },
            }));
          }
        } else {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "No Permission",
              desc: `You are not allowed to modify this item.`,
              isYesNo: false,
            },
          });
        }

        break;
      case "btnEditItem":
        if (this.state.selection.length > 1) {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Selection Exceeded",
              desc: `Only 1 selection is allowed`,

              isYesNo: false,
            },
          });
        } else {
          if (this.state.selection.length === 1) {
            this.clearInputs(true);
            if (this.state.selectedContent.value === 1) {
              this.setState(
                (x) => ({
                  handleItemModal: {
                    ...x.handleItemModal,
                    isView: true,
                  },
                }),
                (x) => {
                  this.handleToggleItemModal();
                  this.populateItemModal(data);
                }
              );
            }
            if (this.state.selectedContent.value === 2) {
              this.setState(
                (x) => ({
                  handleItemDetailsModal: {
                    ...x.handleItemDetailsModal,
                    isView: true,
                  },
                }),
                (x) => {
                  this.populateItemDetailModal(data);
                  this.handleItemDetailsToggleModal();
                }
              );

              //console.log("trigger");
            }
            if (this.state.selectedContent.value === 3) {
              //console.log(data);
              this.setState(
                (x) => ({
                  txtRequestQty: { ...x.txtRequestQty, readOnly: true },
                  txtRequestNote: { ...x.txtRequestNote, readOnly: true },
                  handleItemDetailsModal: {
                    ...x.handleItemDetailsModal,
                    isView: true,
                  },
                }),
                () => {
                  this.populateRequestItemModal(data);
                  this.handleRequestToggleModal();
                  if (data.orderStatus.name === "Admin Denied") {
                    this.setState(
                      (x) => ({
                        txtRequestQty: { ...x.txtRequestQty, readOnly: false },
                        txtRequestNote: {
                          ...x.txtRequestNote,
                          readOnly: false,
                        },
                      }),
                      () => {}
                    );
                  }
                }
              );

              //console.log("trigger");
            }
            this.setState((x) => ({
              selectedOperation: SELECTED_OPERATION.EDIT,
            }));
          } else {
            this.props.dispatch({
              type: "MODAL_SHOW",
              payload: {
                title: "No Selection",
                desc: `Please select an Item`,
                isYesNo: false,
              },
            });
          }
        }

        break;
      case "btnDeleteItem":
        if (this.state.selection.length > 1) {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Selection Exceeded",
              desc: `Please select 1 Item`,
              isYesNo: false,
            },
          });
        } else {
          if (
            this.state.userType.includes("Admin") ||
            this.state.userType.includes("Manager") ||
            this.state.permissions.includes(
              "perm-ui-inventoryPage-view-write-manage-admin"
            )
          ) {
            if (this.state.selection.length === 1) {
              this.setState((x) => ({
                selectedOperation: SELECTED_OPERATION.DELETE,
              }));
              this.props.dispatch({
                type: "MODAL_SHOW",
                payload: {
                  title: "Confirmation",
                  JsxDesc: () => {
                    return (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <h4
                          style={{
                            fontWeight: "bolder",
                            textAlign: "center",
                            color: "var(--REB-warning)",
                          }}
                        >
                          NOTICE
                        </h4>
                        <h5 style={{ textAlign: "justify" }}>
                          Please make sure this item/description is not in use
                          in invoices. If this is in use, the system will
                          prevent you from removing it.
                        </h5>

                        <p>{this.state.tableSelection.name}</p>
                      </div>
                    );
                  },
                  isYesNo: true,
                  cb: (e) => {
                    if (this.state.selectedContent.value === 1) {
                      this.handleToggleItemModal();
                      this.deleteItem(this.state.tableSelection.id);
                    } else {
                      //console.log(this.state.tableSelection.id);
                      this.deleteItemDetail(this.state.tableSelection.id);

                      this.handleItemDetailsToggleModal();
                    }
                  },
                },
              });
            } else {
              this.props.dispatch({
                type: "MODAL_SHOW",
                payload: {
                  title: "No Selection",
                  desc: `Please select a ${this.state.tableSelection.name}`,
                  isYesNo: false,
                },
              });
            }
          } else {
            this.props.dispatch({
              type: "MODAL_SHOW",
              payload: {
                title: "No Permission",
                desc: `You are not allowed to delete this item.`,
                isYesNo: false,
              },
            });
          }
        }
        break;
      case "btnDeleteItemImage": {
        let _len = 0;
        this.state.tableSelection.selDetailStatus === undefined
          ? (_len = this.state.tableSelection.files.length)
          : (_len = this.state.tableSelection.uploads.length);
        //TODO fix
        if (_len > 0) {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Confirmation",
              desc: `Remove this image?`,
              isYesNo: true,
              cb: async (e) => {
                const i = this.ItemModalRef.current.getCurrentIndex();

                await api.deleteItemImg(this.props.dispatch, {
                  id: this.state.tableSelection.id,
                  filename: this.state.itemImgs[i].filename,
                });

                this.refreshImageData(async (x) => {
                  if (this.state.itemImgs.length === 0) {
                    this.setState({
                      itemImgs: [{ original: emptyImg, isDefault: true }],
                    });
                  }
                });
              },
            },
          });
        } else {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Empty item image",
              desc: `No image to delete`,
              isYesNo: false,
            },
          });
        }

        break;
      }
      case "copyItemPopulate": {
        this.populateItemModal(data);
        this.setState((x) => ({
          copiedItem: data,
        }));

        break;
      }
      default:
        break;
    }
  };

  populateRequestItemModal = async (data) => {
    //console.log(data);

    const x = this.state.itemDetailsConst;
    for (let index = 0; index < x.length; index++) {
      const search = data.item.details.find((f) => f.type === x[index].value);

      if (search) {
        this.setState((a) => ({
          [`selDetail${x[index].name}`]: {
            ...a[`selDetail${x[index].name}`],
            value: search,
          },
        }));
      }
    }

    this.setState((x) => ({
      txtRequestQty: { ...x.txtRequestQty, value: data.itemQty },
      txtRequestNote: { ...x.txtRequestNote, value: data.notes },
      //selDetailUnit:{...x.selDetailUnit, value: this.state.selDetailUnit.data.find(x=>x.id)}
    }));

    if (data.orderType.name === "Item Requisition") {
      this.setState((x) => ({
        txtRequestQty: {
          ...x.txtRequestQty,
          placeHolder: "Number of items to be Requisitioned",
          label: "Requisition QTY",
        },
        requisitionType: data.orderType.name,
      }));
    }
    if (data.orderType.name === "Item Order Request") {
      this.setState((x) => ({
        txtRequestQty: {
          ...x.txtRequestQty,
          placeHolder: "Number of items to be requested",
          label: "Order Request QTY",
        },
        requisitionType: data.orderType.name,
      }));
    }
    if (data.orderType.name === "Add Item Qty Request") {
      this.setState((x) => ({
        txtRequestQty: {
          ...x.txtRequestQty,
          placeHolder: "Number of item qty to be added",
          label: "Qty to be added",
        },
        requisitionType: data.orderType.name,
      }));
    }
    if (data.orderType.name === "Deduct Item Qty Request") {
      this.setState((x) => ({
        txtRequestQty: {
          ...x.txtRequestQty,
          placeHolder: "Number of item qty to be deducted",
          label: "Qty to be deducted",
        },
        requisitionType: data.orderType.name,
      }));
    }

    // const data = {
    //   itemQty: Number(this.state.txtRequestQty.value),
    //   itemId: Number(this.state.tableSelection.id),
    //   itemUnitId: Number(
    //     this.state.tableSelection.details.find((x) => x.type === 2).id
    //   ),
    //   orderStatusId: Number(
    //     this.state.selDetailContentStatus.data.find(
    //       (x) => x.name === "Pending Admin Approval"
    //     ).id
    //   ),
    //   orderTypeId:
    //     this.state.requisitionType === 1
    //       ? Number(
    //           this.state.selDetailContentType.data.find(
    //             (x) => x.name === "Item Order Request"
    //           ).id
    //         )
    //       : Number(
    //           this.state.selDetailContentType.data.find(
    //             (x) => x.name === "Item Requisition"
    //           ).id
    //         ),
    //   isInventoryRequest: 1,
    //   lastModifiedByActorId: Number(this.state.user.id),
    //   requestedByActorId: Number(this.state.user.id),
    //   //dateAdded: DateToday,
    //   //dateLastModified: DateToday,
    //   notes: this.state.txtRequestNote.value,
    //   orderId: null,
    // };
    //console.log("CREATED REQUEST DATA ==> ", data);

    //await invoiceApi.createInvoiceContent(this.props.dispatch, data);

    //this.handleActionChange({ target: { name: "btnCancelItemRequest" } });
  };

  createItem = async (e) => {
    const data = [
      {
        data: {
          inventoryId:
            this.state.selectedInventory === 0
              ? null
              : this.state.selectedInventory,
          description: this.state.txtDesc.value,
          notes: this.state.txtNotes.value,
          qty: Number(this.state.txtQty.value),
          price: Number(this.state.txtPrice.value),
          sku: this.state.txtSKU.value,
          barcode: this.state.txtBarcode.value,
          serial: this.state.txtSerial.value,
          cost: Number(this.state.txtNetPrice.value),
          shipping: Number(this.state.txtShippingCost.value),
          handling: Number(this.state.txtHandlingCost.value),
          //discountMax: Number(this.state.txtDiscountMax.value),
          restockWarn: Number(this.state.txtRestockWarn.value),
          cogs: Number(this.state.txtCOGS.value),
          display: Number(this.state.txtDisplay.value),
          code: this.state.txtCode.value,
          isPOSVisible: Number(this.state.chkIsPOSVisible.value),
          posItemName: this.state.txtPOSItemName.value,
          posItemNameShort: this.state.txtPOSItemNameShort.value,
          posItemDesc: this.state.txtPOSItemDesc.value,
          posItemCode: this.state.txtPOSItemCode.value,
          isDiscountable: Number(this.state.chkIsDiscountable.value),
          isTaxable: Number(this.state.chkIsTaxable.value),
          isService: Number(this.state.chkIsService.value),
          ...(this.state.copiedItem
            ? { files: this.state.copiedItem.files }
            : {}),
        },
        details: [
          { ...this.state.selDetailName.value },
          { ...this.state.selDetailBrand.value },
          { ...this.state.selDetailType.value },
          { ...this.state.selDetailStatus.value },
          { ...this.state.selDetailUnit.value },
        ],
      },
    ];

    //console.log(data);

    await api.createItem(this.props.dispatch, data);

    const inventoryItems = await api.getItemsByInventory(
      this.props.dispatch,
      Number(this.state.selectedInventory)
    );

    // this.clearInputs();

    this.setState({
      itemData: inventoryItems,
      bakItemData: inventoryItems,
      copiedItem: null,
    });
  };

  updateItem = async () => {
    const data = {
      data: {
        id: Number(this.state.tableSelection.id),
        inventoryId:
          this.state.selectedInventory === 0
            ? null
            : this.state.selectedInventory,
        description: this.state.txtDesc.value,
        notes: this.state.txtNotes.value,
        qty: Number(this.state.txtQty.value),
        price: Number(this.state.txtPrice.value),
        sku: this.state.txtSKU.value,
        barcode: this.state.txtBarcode.value,
        serial: this.state.txtSerial.value,
        cost: Number(this.state.txtNetPrice.value),
        shipping: Number(this.state.txtShippingCost.value),
        handling: Number(this.state.txtHandlingCost.value),
        //discountMax: Number(this.state.txtDiscountMax.value),
        restockWarn: Number(this.state.txtRestockWarn.value),
        cogs: Number(this.state.txtCOGS.value),
        display: Number(this.state.txtDisplay.value),
        code: this.state.txtCode.value,
        isPOSVisible: Number(this.state.chkIsPOSVisible.value),
        posItemName: this.state.txtPOSItemName.value,
        posItemNameShort: this.state.txtPOSItemNameShort.value,
        posItemDesc: this.state.txtPOSItemDesc.value,
        posItemCode: this.state.txtPOSItemCode.value,
        isDiscountable: Number(this.state.chkIsDiscountable.value),
        isTaxable: Number(this.state.chkIsTaxable.value),
        isService: Number(this.state.chkIsService.value),
      },
      details: [
        { ...this.state.selDetailName.value },
        { ...this.state.selDetailBrand.value },
        { ...this.state.selDetailType.value },
        { ...this.state.selDetailStatus.value },
        { ...this.state.selDetailUnit.value },
      ],
    };

    //console.log(data);

    // await api.createItem(this.props.dispatch, data);
    await api.updateItem(this.props.dispatch, data);

    const inventoryItems = await api.getItemsByInventory(
      this.props.dispatch,
      Number(this.state.selectedInventory)
    );

    // this.clearInputs();

    this.setState(
      {
        itemData: inventoryItems,
        bakItemData: inventoryItems,
      },
      () => {
        this.handleSearch({ target: { value: this.state.txtSearch } });
      }
    );
  };

  deleteItem = async (id) => {
    await api.deleteItem(this.props.dispatch, id);

    this.clearInputs();
    const inventoryItems = await api.getItemsByInventory(
      this.props.dispatch,
      Number(this.state.selectedInventory)
    );
    this.setState({
      itemData: inventoryItems,
      bakItemData: inventoryItems,
    });
  };

  handleInventoryChange = async (e) => {
    //console.log(e.target);
    const invId = Number(e.target.value.id) === 1 ? 0 : e.target.value.id;

    const inventoryItems = await api.getItemsByInventory(
      this.props.dispatch,
      invId
    );
    this.setState({
      selectedColumn: itemColumn(
        inventoryItems,
        this.state.itemImgs,
        this.props.userType,
        this.state.downloadImage,

        this.state.refreshSelectedItem,
        this.state.dispatch,
        this.state.settings?.itemPriceModification,
        this.state.permissions,
        this.state.selDetailStatus.data,
        this.state.selDetailBrand.data,
        this.state.selDetailType.data
      ),
      selectedInventory: invId,
      itemData: inventoryItems,
      bakItemData: inventoryItems,
    });
  };

  refreshSelectedItem = async (id) => {
    const _UpdatedContent = await InventoryAPI.getItem(
      this.props.dispatch,
      !id ? this.state.selectedRowInvoice.id : id
    );
    //console.log(_UpdatedContent);

    if (_UpdatedContent) {
      let _tempData = this.state.itemData;
      _tempData = _tempData.map((x) =>
        x.id === _UpdatedContent.id ? _UpdatedContent : x
      );

      this.setState({
        itemData: _tempData,
        bakItemData: _tempData,
      });
    }
  };

  handleInventoryContentChange = async (e) => {
    const CONTENT_TYPE = Number(e.target.value);

    if (CONTENT_TYPE === 1) {
      // const inventories = await api.getInventory(this.props.dispatch);

      // const inventoryItems = await api.getItemsByInventory(
      //   this.props.dispatch,
      //   0
      // );

      //console.log(inventories[0]);
      let inventories = [];
      inventories = await api.getInventory(this.props.dispatch);

      if (inventories.length === 0) {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Inventory Empty",
            desc: `You dont have permission to access any inventory. Please contact your administrator`,
            isYesNo: false,
          },
        });
      }
      let settings = null;
      let inventoryItems = [];
      if (inventories.length > 0) {
        //console.log(inventories[0].id);
        inventoryItems = await api.getItemsByInventory(
          this.props.dispatch,
          inventories[0].id,
          null,
          null,
          null,
          null,
          null
        );
        //Fetch Settings by inventory
        settings = await settingsApi.getSettingsByInventory(
          this.props.dispatch,
          Number(inventories[0].id)
        );
        //Update Invoice Headers
        setInvoiceHeaderData(
          settings?.invoiceHeaderAddress1,
          settings?.invoiceHeaderAddress2,
          settings?.invoiceHeaderAddress3,
          settings?.invoiceHeaderLandline,
          settings?.invoiceHeaderMobile,
          settings?.invoiceHeaderContact,
          settings?.invoiceHeaderEmail,
          settings?.invoiceDeliveryReceiveAddress
        );
      }

      this.setState(
        (a) => ({
          inventoryData: inventories,
          selInventoryName: { ...a.selInventoryName, value: inventories[0] },
          selectedColumn: itemColumn(
            inventoryItems,
            this.state.itemImgs,
            this.props.userType,
            this.state.downloadImage,
            this.state.refreshSelectedItem,
            this.state.dispatch,
            settings.itemPriceModification,
            this.state.permissions,
            this.state.selDetailStatus.data,
            this.state.selDetailBrand.data,
            this.state.selDetailType.data
          ),
          itemData: inventoryItems,
          bakItemData: inventoryItems,
          selectedContent: {
            value: CONTENT_TYPE,
          },
          settings,
        }),
        (x) => {
          if (!this.state.selInventoryName.value) {
            this.props.dispatch({
              type: "MODAL_SHOW",
              payload: {
                title: "No Inventory Selection",
                desc: `Please select an Inventory`,
                isYesNo: false,
              },
            });
            return;
          }
        }
      );
    }
    if (CONTENT_TYPE === 2) {
      if (!this.state.selInventoryName.value) {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "No Inventory Selection",
            desc: `Please select an Inventory`,
            isYesNo: false,
          },
        });
        return;
      }
      this.setState(
        {
          selectedColumn: itemDetailsColumn,

          selectedContent: {
            value: CONTENT_TYPE,
          },
        },
        (x) => {
          let event = new MouseEvent("change", {
            bubbles: true,
            cancelable: false,
          });

          this.itemDetailsSelectRef.current.dispatchEvent(event);
        }
      );
    }
    if (CONTENT_TYPE === 3) {
      if (!this.state.selInventoryName.value) {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "No Inventory Selection",
            desc: `Please select an Inventory`,
            isYesNo: false,
          },
        });
        return;
      }
      const InventoryRequests = await api.getInventoryRequests(
        this.props.dispatch
      );
      //console.log(InventoryRequests);
      this.setState(
        {
          selectedColumn: requestColumn(),
          itemData: InventoryRequests,
          bakItemData: InventoryRequests,
          selectedContent: {
            value: CONTENT_TYPE,
          },
        },
        (x) => {}
      );
    }
    this.setState(
      {
        tableSelection: null,
      },
      (x) => {}
    );
  };

  refreshInventoryRequests = async () => {
    const InventoryRequests = await api.getInventoryRequests(
      this.props.dispatch
    );
    this.setState(
      {
        itemData: InventoryRequests,
        bakItemData: InventoryRequests,
      },
      (x) => {}
    );
  };

  handleActionChangeItemDetails = async (e) => {
    this.setState({
      selectedItemDetailType: {
        value: e.target.value,
        name: e.target.options[e.target.selectedIndex].text,
      },
    });

    const detData = await api.getItemDetail(
      this.props.dispatch,
      Number(e.target.value)
    );
    this.setState((prevState) => ({
      itemData: detData,
      bakItemData: detData,
    }));
  };

  populateItemModal = async (data) => {
    //console.log(data);
    const x = this.state.itemDetailsConst;
    for (let index = 0; index < x.length; index++) {
      const search = data.details.find((f) => f.type === x[index].value);

      if (search) {
        this.setState((a) => ({
          [`selDetail${x[index].name}`]: {
            ...a[`selDetail${x[index].name}`],
            value: search,
          },
        }));
      }
    }

    const COGS_DATA = await api.getItemCogsSummary(
      this.props.dispatch,
      data.id
    );
    const PREV_PURCHASES = await api.getItemPreviousPurchases(
      this.props.dispatch,
      data.id
    );
    const PRICE_HISTORY = await api.getItemPriceHistoryByItem(
      this.props.dispatch,
      data.id
    );
    console.log(PRICE_HISTORY);
    console.log("PREV-PURCHASES ===> ", PREV_PURCHASES);

    this.setState((a) => ({
      txtQty: { ...a.txtQty, value: data.qty },
      txtPrice: { ...a.txtPrice, value: data.price },
      txtGrossPrice: { ...a.txtGrossPrice, value: data.gross },
      txtNetPrice: { ...a.txtNetPrice, value: data.cost },
      txtShippingCost: { ...a.txtShippingCost, value: data.shipping },
      txtHandlingCost: { ...a.txtHandlingCost, value: data.handling },
      txtDesc: { ...a.txtDesc, value: data.description },
      txtNotes: { ...a.txtNotes, value: data.notes },
      txtRestockWarn: { ...a.txtRestockWarn, value: data.restockWarn },
      txtDiscountMax: { ...a.txtDiscountMax, value: data.discountMax },
      txtSKU: { ...a.txtSKU, value: data.sku },
      txtBarcode: { ...a.txtBarcode, value: data.barcode },
      txtSerial: { ...a.txtSerial, value: data.serial },
      txtCOGS: { ...a.txtCOGS, value: data.cogs },
      txtDisplay: { ...a.txtDisplay, value: data.display },
      txtCode: { ...a.txtCode, value: data.code },
      POCogsData: COGS_DATA,
      PREV_PURCHASES: PREV_PURCHASES,
      chkIsPOSVisible: {
        ...a.chkIsPOSVisible,
        value: Boolean(data.isPOSVisible),
      },
      txtPOSItemName: { ...a.txtPOSItemName, value: data.posItemName },
      txtPOSItemNameShort: {
        ...a.txtPOSItemNameShort,
        value: data.posItemNameShort,
      },
      txtPOSItemDesc: { ...a.txtPOSItemDesc, value: data.posItemDesc },
      txtPOSItemCode: { ...a.txtPOSItemCode, value: data.posItemCode },
      chkIsDiscountable: {
        ...a.chkIsDiscountable,
        value: Boolean(data.isDiscountable),
      },
      chkIsTaxable: { ...a.chkIsTaxable, value: Boolean(data.isTaxable) },
      chkIsService: { ...a.chkIsService, value: Boolean(data.isService) },
      PRICE_HISTORY: PRICE_HISTORY,
    }));
  };

  async initializeInvoiceDetails() {
    const detData = await invoiceApi.getInvoiceDetailsConst(
      this.props.dispatch
    );
    let x = detData.OrderDetailId;
    x = x.filter((a) => a.name === "ContentStatus" || a.name === "ContentType");
    //console.log(x);

    for (let index = 0; index < x.length; index++) {
      const arr = x[index];
      const compoName = `selDetail${arr.name}`;
      const detData = await invoiceApi.getInvoiceDetail(
        this.props.dispatch,
        Number(x[index].value)
      );
      //console.log(compoName);
      this.setState((a) => ({
        [compoName]: { ...a[compoName], data: detData },
      }));
    }
    //console.log(this.state);
    return true;
  }

  initializeItemDetails = async () => {
    const x = this.state.itemDetailsConst;
    //console.log(this.state.itemDetailsConst);

    for (let index = 0; index < x.length; index++) {
      const arr = x[index];
      const compoName = `selDetail${arr.name}`;
      //console.log(compoName);
      const detData = await api.getItemDetail(
        this.props.dispatch,
        Number(x[index].value)
      );

      this.setState(
        (a) => ({
          [compoName]: { ...a[compoName], data: detData },
        }),
        () => {
          //console.log(this.state.selDetailStatus);
        }
      );
      // console.log(detData);
    }

    return true;
  };

  refreshItemDetail = async (e, type) => {
    const detData = await api.getItemDetail(this.props.dispatch, Number(type));

    this.setState((a) => ({
      [e]: { ...a[e], data: detData },
    }));
  };

  handleSearch = (event) => {
    //console.log("search ===>", event.target.value);
    if (event.target.value !== "") {
      this.setState({ isFiltered: true });
      const data = Filters.filterDataNested(
        this.state.bakItemData,
        event.target.value
      );

      if (data != null) {
        this.setState({ itemData: data, txtSearch: event.target.value });
      } else {
        this.setState({ itemData: this.state.bakItemData });
      }
    } else {
      this.setState({
        itemData: this.state.bakItemData,
        txtSearch: event.target.value,
      });
      this.setState({ isFiltered: false });
    }
  };

  toggleSelection = (key, shift, row) => {
    let selection = this.state.selection;
    const keyIndex = selection.indexOf(key);

    if (keyIndex >= 0) {
      selection = [];
      this.setState((x) => ({
        tableSelection: null,
      }));
    } else {
      selection.length = 0;
      selection.push(key);
      this.setState((x) => ({
        tableSelection: row,
      }));
      //this.clearInputs();
      //this.populateModal(row, false, true);
    }
    //console.log(this.state.tableSelection);

    this.setState((x) => ({
      selection,
    }));
  };

  isSelected = (key) => {
    return this.state.selection.includes(`select-${key}`);
  };

  rowFn = (state, rowInfo, column, instance) => ({
    // onMouseEnter: (e) => {
    //   e.preventDefault();
    //   e.stopPropagation();
    //   console.log(rowInfo.original);
    // },
    onClick: async (e, handleOriginal) => {
      e.preventDefault();
      e.stopPropagation();

      if (
        this.state.selection.length > 0 &&
        this.state.selection[0] === `select-${rowInfo.original.id}`
      ) {
        this.setState((x) => ({
          selection: [],
        }));
      } else {
        if (rowInfo) {
          //const SELECTED_CONTENT = Number(this.state.selectedContent.value);

          const BlockTrigger = ["btnAddItemQty", "btnSubtractItemQty"];

          if (!BlockTrigger.includes(e?.target?.name)) {
            this.toggleSelection(rowInfo.index, null, rowInfo.original);

            let _img = [];

            if (Number(this.state.selectedContent.value) === 1)
              _img = await api.downloadItemImg(
                this.props.dispatch,
                rowInfo.original
              );

            if (_img.length === 0) {
              _img = [{ original: emptyImg, isDefault: true }];
            }
            console.log("SELECTED INVENTORY ITEM ===> ", rowInfo.original);
            this.handleActionChange(
              { target: { name: "btnEditItem" } },
              rowInfo.original
            );

            this.setState(
              {
                selection: ["select-" + rowInfo.original.id],
                itemImgs: _img,
              },
              (x) => {
                //console.log(this.state);
              }
            );
          }
        }
      }

      if (handleOriginal) {
        handleOriginal();
      }
    },
    style: {
      //width: "100em",
      background:
        rowInfo &&
        this.state.selection.includes(`select-${rowInfo.original.id}`) &&
        "var(--REB-secondary)",
      color:
        rowInfo &&
        this.state.selection.includes(`select-${rowInfo.original.id}`) &&
        "var(--REB-white)",
    },
  });

  // handleSearch = (event) => {
  //   if (event.target.value !== "") {
  //     this.setState({ isFiltered: true });
  //     const data = Filters.filterData(
  //       this.state.bakItemData,
  //       event.target.value
  //     );

  //     if (data != null) {
  //       this.setState({ itemData: data });
  //     } else {
  //       this.setState({ itemData: this.state.bakItemData });
  //     }
  //   } else {
  //     this.setState({ itemData: this.state.bakItemData });
  //     this.setState({ isFiltered: false });
  //   }
  // };

  toggleSelectionSetItems = (key, shift, row) => {
    if (!this.state.handleItemDetailsModal.isView) {
      let SetItemsTableSelection = this.state.SetItemsTableSelection;
      const keyIndex = SetItemsTableSelection.indexOf(key);
      if (keyIndex >= 0) {
        SetItemsTableSelection = [];
        this.setState((x) => ({
          selectedRowSetItems: null,
        }));
      } else {
        SetItemsTableSelection.length = 0;
        SetItemsTableSelection.push(key);
        this.setState((x) => ({
          selectedRowSetItems: row,
        }));
      }
    }

    // this.setState(
    //   (x) => ({
    //     SetItemsTableSelection,
    //     txtQtyInvChange: { ...x.txtQtyInvChange, value: "" },
    //     selContentItems: { ...x.selContentItems, value: "" },
    //   })
    //   // () => console.log("SELECTED REQUEST ITEM ===> ", _dat)
    // );
  };

  render() {
    const PRItemHistoryTable = (
      <Table
        data={this.state.POCogsData}
        columns={PRItemHistoryTableColumn(this.props.user)}
        //selectType="radio"
        showPageSizeOptions={false}
        showPageJump={false}
        showPaginationBottom={false}
        defaultPageSize={10}
        style={{ overflow: "auto", height: "150px" }}
        className="tableActor -highlight mt-3"
      />
    );
    const ItemPurchaseHistoryTable = (
      <Table
        data={this.state.PREV_PURCHASES}
        columns={PurchaseHistoryColumns}
        //selectType="radio"
        showPageSizeOptions={false}
        showPageJump={false}
        showPaginationBottom={false}
        defaultPageSize={10}
        style={{ overflow: "auto", height: "150px" }}
        className="tableActor -highlight mt-3"
      />
    );
    const tablelist = (
      <>
        {/* <style type="text/css">
          {`
    
    .tableInventory{
      border-radius: 10px !important;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      background-color: var(--REB-white) !important;
    }
    .rt-thead{
       padding-right: 17px;
    }
    
     
      border:none;
    }
    .rt-td{
      border:none;
    }


  
  `}
        </style> */}
        <style>
          {`
            @media (max-width: 768px) {
              .tableInventory {
                border-radius: 0px !important;

                background-color: var(--REB-white) !important;
                margin-top: 0px;
              }
              .inventoryHeaderContainerStyle {
                margin-top: 0px !important;
                box-shadow: none;
                padding-bottom: 5px !important;
              }
              .-pageInfo{
                font-size: 0.8rem;
              }
            }
            @media (min-width: 769px) {
              .tableInventory {
                border-radius: 10px !important;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                background-color: var(--REB-white) !important;
                margin-top: 30px;
              }
            }
            .rt-thead {
              padding-right: 17px;
            }
            .rt-tbody {
              overflow-y: scroll !important;
            }
          `}
        </style>
        <Table
          data={this.state.itemData}
          // resolveData={(data) => {
          //   console.log(data);
          //   return data.map((row) => row);
          // }}
          columns={this.state.selectedColumn}
          keyField="id"
          defaultFilterMethod={(filter, row, column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined
              ? String(String(row[id]).toUpperCase()).includes(
                  String(filter.value).toUpperCase()
                )
              : true;
          }}
          defaultSorted={[
            // { id: "transPrio", desc: true },
            {
              id: "dateEdited",
              desc: true,
            },
            {
              id: "dateAdded",
              desc: true,
            },
          ]}
          toggleSelection={this.toggleSelection}
          selectAll={this.state.selectAll}
          //selectType="radio"
          loading={this.state.isLoading}
          isSelected={this.isSelected}
          getTrProps={this.rowFn}
          // getPaginationProps={(rowInfo) => {
          //   console.log(rowInfo);
          // }}
          onPageChange={(x) => {
            //console.log(OLD_DATA);
          }}
          showPageSizeOptions={false}
          defaultPageSize={10}
          style={{
            overflow: "auto",
            flex: 1,
            // width: "100em",
            // overflowX: "scroll",
          }}
          className=" tableInventory tableActor -highlight "
        />
        {/* {(state, makeTable, instance) => {
          const objectsEqual = (o1, o2) =>
            Object.keys(o1).length === Object.keys(o2).length &&
            Object.keys(o1).every((p) => o1[p] === o2[p]);

          if (objectsEqual(OLD_DATA, state.pageRows)) {
          } else {
            if (IS_ITEM_TABLE_INITALIZED) {
              OLD_DATA = state.pageRows;

              this.setState({ test: "a" });

              console.log(OLD_DATA);
            }
          }

          // console.log(state.pageRows);
          // this.props.dispatch({
          //   type: "LOAD_TABLE_IMAGE",
          //   payload: { isLoading: false, name: "Fetching Data", id: 5 },
          // });

          // console.log(newData);

          return makeTable();
        }} */}
      </>
    );
    const tableSetItems = (
      <Table
        data={this.state.SetItemsTableData}
        keyField="id"
        showPageSizeOptions={false}
        showPageJump={false}
        columns={itemSetColumn}
        loading={this.state.SetItemsTableloading}
        toggleSelection={this.toggleSelectionSetItems}
        //selectType="radio"
        isSelected={(key) =>
          this.state.SetItemsTableSelection.includes(`select-${key}`)
        }
        getTrProps={(state, rowInfo, column, instance) => ({
          onClick: async (e, handleOriginal) => {
            e.preventDefault();
            e.stopPropagation();
            const _dat = rowInfo.original;
            if (!this.state.handleItemDetailsModal.isView) {
              if (
                this.state.SetItemsTableSelection.length > 0 &&
                this.state.SetItemsTableSelection[0] ===
                  `select-${rowInfo.original.id}`
              ) {
                this.setState((x) => ({
                  SetItemsTableSelection: [],
                  selectedRowSetItems: null,
                  //txtSelectedItemPR: { ...x.txtSelectedItemPR, value: "" },
                }));
              } else {
                if (rowInfo) {
                  this.toggleSelectionSetItems(
                    rowInfo.index,
                    null,
                    rowInfo.original
                  );

                  console.log("SELECTED SET ITEM ===> ", _dat);
                  // this.handleActionChange(
                  //   { target: { name: "btnEditPRItems" } },
                  //   rowInfo.original
                  // );

                  this.setState((x) => ({
                    SetItemsTableSelection: ["select-" + rowInfo.original.id],
                  }));
                }
              }
            }

            if (handleOriginal) {
              handleOriginal();
            }
          },
          style: {
            background:
              rowInfo &&
              this.state.SetItemsTableSelection.includes(
                `select-${rowInfo.original.id}`
              ) &&
              "var(--REB-green)",
            color:
              rowInfo &&
              this.state.SetItemsTableSelection.includes(
                `select-${rowInfo.original.id}`
              ) &&
              "var(--REB-white)",
          },
        })}
        defaultPageSize={8}
        style={{
          overflow: "auto",
          flex: 1,
          backgroundColor: "var(--REB-bg-color)",
        }}
        className="tableActor -highlight "
      />
    );

    return (
      <Inventory
        handleActorChange={this.handleActorChange}
        handleSearch={this.handleSearch}
        inputChange={this.inputChange}
        state={this.state}
        handleShowModal={this.handleShowModal}
        handleHideModal={this.handleHideModal}
        onShow={this.state.isVisible}
        userType={this.props.userType}
        tablelist={tablelist}
        itemDetailsSelectRef={this.itemDetailsSelectRef}
        InventorySelectRef={this.InventorySelectRef}
        ItemModalRef={this.ItemModalRef}
        tableSetItems={tableSetItems}
        PRItemHistoryTable={PRItemHistoryTable}
        ItemPurchaseHistoryTable={ItemPurchaseHistoryTable}
      />
    );
  }
}
const mapStateToProps = (state) => {
  const user = state.apiData.userData;
  let _permissions = [];
  if (user && user.permissions) {
    _permissions = JSON.parse(user.permissions);
  }

  return {
    socketio: state.SocketHandler,
    user: state.apiData.userData,
    permissions: _permissions.toString(),
  };
};
export default connect(mapStateToProps)(InventoryProcess);
