let invoiceHeaderData = {
  invoiceHeaderAddress1: "",
  invoiceHeaderAddress2: "",
  invoiceHeaderAddress3: "",
  invoiceHeaderLandline: "",
  invoiceHeaderMobile: "",
  invoiceHeaderContact: "",
  invoiceHeaderEmail: "",
  invoiceDeliveryReceiveAddress: "",
};

export const getInvoiceHeaderData = () => {
  return invoiceHeaderData;
};
export const setInvoiceHeaderData = (
  invoiceHeaderAddress1,
  invoiceHeaderAddress2,
  invoiceHeaderAddress3,
  invoiceHeaderLandline,
  invoiceHeaderMobile,
  invoiceHeaderContact,
  invoiceHeaderEmail,
  invoiceDeliveryReceiveAddress
) => {
  invoiceHeaderData = {
    invoiceHeaderAddress1: invoiceHeaderAddress1,
    invoiceHeaderAddress2: invoiceHeaderAddress2,
    invoiceHeaderAddress3: invoiceHeaderAddress3,
    invoiceHeaderLandline: invoiceHeaderLandline,
    invoiceHeaderMobile: invoiceHeaderMobile,
    invoiceHeaderContact: invoiceHeaderContact,
    invoiceHeaderEmail: invoiceHeaderEmail,
    invoiceDeliveryReceiveAddress: invoiceDeliveryReceiveAddress,
  };
};
