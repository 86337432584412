import React from "react";
import REBLogo from "../assets/rebLogo.png";
import jnjLogo from "../assets/Jnj/jnj-logo.png";
import misc from "../functions/misc";
import filters from "../functions/filters";
import demoLogo from "../assets/Demo/DemoLogo.png";

import {
  //Canvas,
  //BlobProvider,
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
  Svg,
  Rect,
  LinearGradient,
  Defs,
  Stop,
} from "@react-pdf/renderer";
import { getInvoiceHeaderData } from "../functions/pdfHeaderData";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf",
      fontWeight: 800,
    },
  ],
});

const styles = StyleSheet.create({
  verticalText: {
    color: "white",
    fontSize: 6,
    fontFamily: "Open Sans",
    fontWeight: 800,
    marginTop: 0,
  },
  watermarkTextContainer: {
    fontSize: 15,
    width: "100%",
    position: "absolute",
    textAlign: "center",
  },
  watermarkText: {
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.20)",
    transform: "rotate(20deg)",
  },
});

class PdfHeadFoot {
  __Template() {
    return (
      <Document>
        <Page
          size="A4"
          wrap={true}
          style={[
            {
              fontFamily: "Open Sans",
              paddingTop: 35,
              paddingBottom: 15,
              paddingHorizontal: 20,
            },
          ]}
        ></Page>
      </Document>
    );
  }

  WatermarkTemplate(margin) {
    return (
      <View
        style={[
          {
            marginTop: margin,
          },
          styles.watermarkTextContainer,
        ]}
      >
        <Text style={[{ fontSize: 12 }, styles.watermarkText]}>
          THIS INVOICE IS NOT APPROVED BY COMPANY AND IS INVALID. STRICTLY FOR
          VIEWING PURPOSES ONLY.
        </Text>
        {/* <Text style={[{fontSize: 8}, styles.watermarkText]}>
        GENERATED BY TEST
      </Text> */}
      </View>
    );
  }

  TemplateHeaderFooter = (
    content,
    type,
    uniqueId,
    recopy,
    invoiceDate,
    typeSize,
    headerDetails,
    displayWatermark
  ) => {
    const PageTemplate = (props) => {
      const { documentLabel, documentLabelBg } = props;

      const HeaderData = getInvoiceHeaderData();

      let _pages = [];
      //console.log(content);
      for (let i = 0; i < content.length; i++) {
        const _e = content[i];
        _pages.push(
          <Page
            size="A4"
            wrap={true}
            key={filters.uuidv4()}
            style={[
              {
                fontFamily: "Open Sans",
                paddingTop: 20,
                paddingBottom: 10,
                // paddingHorizontal: 20,
              },
            ]}
          >
            {/* //{" "}
            <Svg style={{ position: "absolute" }} viewBox="0 0 220 100">
              // <Rect width="300" height="40" fill="#5eaaff" />
              //{" "}
            </Svg> */}
            {/* <Defs>
                  <LinearGradient id="surfaceGradient">
                    <Stop offset="10%" stopColor="#5eaaff" />
                    <Stop offset="90%" stopColor="#007bd3" />
                  </LinearGradient>
                </Defs> */}

            {process.env.REACT_APP_THEME === "JNJ" && (
              <Svg style={{ position: "absolute" }} viewBox="0 0 220 100">
                <Defs>
                  <LinearGradient id="myLinearGradient">
                    <Stop offset="10%" stopColor="#5eaaff" />
                    <Stop offset="90%" stopColor="#007bd3" />
                  </LinearGradient>
                </Defs>
                <Rect width="300" height="40" fill="url('#myLinearGradient')" />
              </Svg>
            )}
            {process.env.REACT_APP_THEME === "DEMO" && (
              <Svg style={{ position: "absolute" }} viewBox="0 0 220 100">
                <Defs>
                  <LinearGradient id="myLinearGradient">
                    <Stop offset="10%" stopColor="#f79502" />
                    <Stop offset="90%" stopColor="#ff7d7e" />
                  </LinearGradient>
                </Defs>
                <Rect width="300" height="40" fill="url('#myLinearGradient')" />
              </Svg>
            )}
            <View
              fixed
              style={[
                {
                  flexDirection: "row",

                  color: "black",

                  // marginTop: 15,
                  marginBottom: 10,
                  // marginLeft: 15,
                  // marginRight: 15,
                  paddingTop: 10,
                  paddingBottom: 10,
                  // backgroundColor: "#007bd3",
                },
              ]}
            >
              {process.env.REACT_APP_THEME === "SURFACEPLUS" && (
                <View
                  style={{
                    position: "absolute",
                    width: 600,
                    height: 105,
                    backgroundColor: "#5eaaff",
                    marginTop: -20,
                  }}
                />
              )}

              <View style={[{ flex: 1 }]}>
                {process.env.REACT_APP_THEME === "SURFACEPLUS" && (
                  <Image
                    style={[{ marginLeft: 30, width: 150 }]}
                    src={REBLogo}
                  />
                )}
               
                {process.env.REACT_APP_THEME === "JNJ" && (
                  <Image
                    style={[{ marginLeft: 30, width: 85 }]}
                    src={jnjLogo}
                  />
                )}
                {process.env.REACT_APP_THEME === "DEMO" && (
                  <Image
                    style={[{ marginLeft: 30, width: 65 }]}
                    src={demoLogo}
                  />
                )}
              </View>
              {process.env.REACT_APP_THEME === "SURFACEPLUS" && (
                <View
                  style={[
                    {
                      flex: 1,
                      marginLeft: 30,
                    },
                  ]}
                >
                  <Text style={[{ color: "white", fontSize: 8 }]}>
                    {/* 22 Commission Civil, Jaro */}
                    {HeaderData.invoiceHeaderAddress1}
                  </Text>
                  <Text style={[{ color: "white", fontSize: 8 }]}>
                    {/* Lapaz */}
                    {HeaderData.invoiceHeaderAddress2}
                  </Text>
                  <Text style={[{ color: "white", fontSize: 8 }]}>
                    {HeaderData.invoiceHeaderAddress3}
                    {/* Iloilo City, 5000 */}
                  </Text>
                  <Text style={[{ color: "white", fontSize: 8, marginTop: 5 }]}>
                    {HeaderData.invoiceHeaderLandline}
                    {/* (033) 508 0968 */}
                  </Text>
                  <Text style={[{ color: "white", fontSize: 8, marginTop: 5 }]}>
                    {HeaderData.invoiceHeaderEmail}
                    {/* sales.surfaceplus@gmail.com */}
                  </Text>
                </View>
              )}

              {process.env.REACT_APP_THEME === "JNJ" && (
                <View
                  style={[
                    {
                      flex: 1,
                      marginLeft: 30,
                    },
                  ]}
                >
                  <Text style={[{ color: "white", fontSize: 8 }]}>
                    22 Commission Civil, Jaro
                  </Text>
                  <Text style={[{ color: "white", fontSize: 8 }]}>Lapaz</Text>
                  <Text style={[{ color: "white", fontSize: 8 }]}>
                    Iloilo City, 5000
                  </Text>
                  <Text style={[{ color: "white", fontSize: 8, marginTop: 5 }]}>
                    (033) 320 2392
                  </Text>
                  <Text style={[{ color: "white", fontSize: 8, marginTop: 5 }]}>
                    jnjbuilders88@gmail.com
                  </Text>
                </View>
              )}
              {process.env.REACT_APP_THEME === "DEMO" && (
                <View
                  style={[
                    {
                      flex: 1,
                      marginLeft: 30,
                    },
                  ]}
                >
                  <Text style={[{ color: "white", fontSize: 8 }]}>
                    Quintin Salas
                  </Text>
                  <Text style={[{ color: "white", fontSize: 8 }]}>Jaro</Text>
                  <Text style={[{ color: "white", fontSize: 8 }]}>
                    Iloilo City, 5000
                  </Text>
                </View>
              )}

              <View
                style={[
                  {
                    flex: 1,
                    // flexDirection: "row",
                    // paddingLeft: 80,
                    // paddingRight: 80,
                    marginTop: -5,
                  },
                ]}
              >
                {typeSize ? (
                  <>
                    <Text
                      style={[
                        {
                          color: "white",
                          fontFamily: "Open Sans",
                          fontWeight: 800,
                          fontSize: typeSize,
                        },
                      ]}
                    >
                      {type}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text
                      style={[
                        {
                          color: "white",
                          fontFamily: "Open Sans",
                          fontWeight: 800,
                        },
                      ]}
                    >
                      {type}
                    </Text>
                  </>
                )}
                {invoiceDate && (
                  <>
                    <Text
                      style={[
                        {
                          color: "white",
                          fontSize: 10,
                          fontFamily: "Open Sans",
                          fontWeight: 800,
                          marginTop: 3,
                        },
                      ]}
                    >
                      Date: {invoiceDate}
                    </Text>
                  </>
                )}
                <Text
                  style={[
                    {
                      color: "white",
                      fontSize: 6,
                      fontFamily: "Open Sans",
                      fontWeight: 800,
                      marginTop: 3,
                    },
                  ]}
                >
                  Date Generated: {misc.dateTimeToday()}
                </Text>

                <Text
                  style={[
                    {
                      color: "white",
                      fontSize: 10,
                      fontFamily: "Open Sans",
                      fontWeight: 800,
                      marginTop: 3,
                    },
                  ]}
                >
                  Invoice #: {uniqueId}
                </Text>
              </View>
              <View
                style={{
                  flex: 0.1,
                  backgroundColor: documentLabelBg,
                  textAlign: "center",
                  marginTop: -30,
                  marginBottom: -20,
                  paddingTop: 2,
                }}
              >
                {(() => {
                  const text = documentLabel;
                  let renderText = [];
                  for (let i = 0; i < text.length; i++) {
                    renderText.push(
                      <Text style={styles.verticalText} key={filters.uuidv4()}>
                        {text.charAt(i)}
                      </Text>
                    );
                  }
                  return renderText;
                })()}
              </View>
            </View>

            {_e}
            {displayWatermark && (
              <>
                {this.WatermarkTemplate("0%")}
                {this.WatermarkTemplate("20%")}
                {this.WatermarkTemplate("40%")}
                {this.WatermarkTemplate("60%")}
                {this.WatermarkTemplate("80%")}
                {this.WatermarkTemplate("100%")}
                {this.WatermarkTemplate("120%")}
                {/* {this.WatermarkTemplate('140%')} */}
              </>
            )}
          </Page>
        );
      }
      return _pages;
    };

    return (
      <Document>
        <PageTemplate documentLabel="CUSTOMER COPY" documentLabelBg="#80CFA9" />
        {recopy && (
          <>
            <PageTemplate
              documentLabel="FILING COPY"
              documentLabelBg="#563F1B"
            />
            <PageTemplate
              documentLabel="ACCT. COPY"
              documentLabelBg="#4C6663"
            />
          </>
        )}
      </Document>
    );
  };
}
export default new PdfHeadFoot();
