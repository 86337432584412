import { combineReducers } from "redux";
import socketIOClient from "socket.io-client";

let socket = false;

const getDataInitialState = {
  data: [],
  notifData: [],
  loading: false,
  error: false,
};

const apiDataInitialState = {
  supplierData: [],
  materialData: [],
  projectData: [],
  bakProjectData: [],
  projectDataSel: [],
  POData: [],
  PRData: [],
  unitData: [],
  itemData: [],
  actorData: [],
  userData: [],
  userType: [],
  invoiceHeader: [],
  fingerprint: "",
  browserInfo: '',
};

const toastInitialState = {
  delay: 1500,
  data: [],
  show: false,
  title: "",
  desc: "",
  time: "",
  response: false,
  cb: null,
};

const handleModalInitialState = {
  isYesNo: true,
  show: false,
  title: "",
  desc: "",
  response: false,
  cb: null,
};

const handlePRModalInitialState = {
  show: false,
};
const handlePOModalInitialState = {
  show: false,
};
const handleDashPOModalInitialState = {
  show: false,
};
const handlePDFPOModalInitialState = {
  show: false,
  document: null,
};
const handleFileViewerModalInitialState = {
  show: false,
  document: null,
};

function apiData(state = apiDataInitialState, action) {
  switch (action.type) {
    case "API_STORE_PR":
      return Object.assign({}, state, {
        ...state,
        PRData: action.payload.data,
      });
    case "API_STORE_PO":
      return Object.assign({}, state, {
        ...state,
        POData: action.payload.data,
      });
    case "API_STORE_UNIT":
      return Object.assign({}, state, {
        ...state,
        unitData: action.payload.data,
      });
    case "API_STORE_ITEM":
      return Object.assign({}, state, {
        ...state,
        itemData: action.payload.data,
      });
    case "API_STORE_SUPPLIER":
      return Object.assign({}, state, {
        ...state,
        supplierData: action.payload.data,
      });
    case "API_STORE_MATERIAL":
      return Object.assign({}, state, {
        ...state,
        materialData: action.payload.data,
      });
    case "API_STORE_PROJECT":
      return Object.assign({}, state, {
        ...state,
        projectData: action.payload.data,
      });

    case "API_STORE_PROJECT_BAK":
      return Object.assign({}, state, {
        ...state,
        bakProjectData: action.payload.data,
      });
    case "API_STORE_PROJECT_SELECTED":
      return Object.assign({}, state, {
        ...state,
        projectDataSel: action.payload.data,
      });
    case "API_STORE_ACTOR":
      return Object.assign({}, state, {
        ...state,
        actorData: action.payload.data,
      });
    case "API_STORE_USER":
      return Object.assign({}, state, {
        ...state,
        userData: action.payload.data,
      });
    case "API_STORE_USER_TYPE":
      let userType = [];

      for (let i = 0; i < action.payload.data.types.length; i++) {
        const _e = action.payload.data.types[i];
        userType.push(_e.name);
      }

      return Object.assign({}, state, {
        ...state,
        userType: userType,
      });
    case "API_STORE_INVOICE_HEADER":
      return Object.assign({}, state, {
        ...state,
        invoiceHeader: action.payload.data,
      });
    case "API_STORE_FINGERPRINT":
      return Object.assign({}, state, {
        ...state,
        fingerprint: action.payload.data,
      });
    case "API_STORE_BROWSER_INFO":
      return Object.assign({}, state, {
        ...state,
        browserInfo: action.payload.data,
      });

    default:
      return state;
  }
}

function getData(state = getDataInitialState, action) {
  switch (action.type) {
    case "API_GET_SUCCESS":
      return Object.assign({}, state, {
        ...state,
        data: action.payload.data,
      });
    case "API_GET_UNAUTH":
      if (socket) {
        socket.disconnect();
      }
      return Object.assign({}, state, {
        ...state,

        error: true,
      });
    case "API_TOGGLE_LOADING":
      const loadData = action.payload.payload;

      if (loadData.isLoading) {
        state.notifData.push({
          name: loadData.name,
          id: loadData.id,
        });

        return Object.assign({}, state, {
          ...state,
          loading: true,
        });
      } else {
        let lists = state.notifData.filter((x) => {
          return x.id !== loadData.id;
        });
        if (loadData.force) {
          lists = [];
        }
        //console.log("triggerreducer", lists);
        return Object.assign({}, state, {
          ...state,
          loading: false,
          notifData: lists,
        });
      }

    default:
      return state;
  }
}

function globalToast(state = toastInitialState, action) {
  switch (action.type) {
    case "TOAST_ADD":
      return Object.assign({}, state, {
        ...state,
        data: [
          ...state.data,
          {
            show: true,
            title: action.payload.title,
            desc: action.payload.desc,
            cb: action.payload.cb,
          },
        ],
      });
    case "TOAST_HIDE":
      return Object.assign({}, state, {
        ...state,
        show: false,
      });
    case "TOAST_RESP_NO":
      state.data.splice(action.payload.index, 1);
      return Object.assign({}, state, {
        ...state,
        show: false,
        response: false,
      });
    case "TOAST_RESP_YES":
      if (typeof state.cb !== "undefined") {
        state.cb();
      }
      return Object.assign({}, state, {
        ...state,
        show: false,
        response: true,
      });
    default:
      return state;
  }
}

function globalModal(state = handleModalInitialState, action) {
  switch (action.type) {
    case "MODAL_SHOW":
      return Object.assign({}, state, {
        ...state,
        isYesNo: action.payload.isYesNo,
        show: true,
        title: action.payload.title,
        desc: action.payload.desc,
        JsxDesc: action.payload.JsxDesc,
        cb: action.payload.cb,
        cbNo: action.payload.cbNo,
        onClose: action.payload.onClose,
        yesLbl: action.payload.yesLbl,
        noLbl: action.payload.noLbl,
        size: action.payload.size,
      });
    case "MODAL_HIDE":
      return Object.assign({}, state, {
        ...state,
        show: false,
        //onClose: action.payload.onClose,
      });
    case "MODAL_RESP_NO":
      return Object.assign({}, state, {
        ...state,
        show: false,
        response: false,
        //onClose: action.payload.onClose,
      });
    case "MODAL_RESP_YES":
      // if (typeof state.cb !== "undefined") {
      //   return Object.assign({}, state, {
      //     ...state,
      //     show: false,
      //     response: true,
      //   });
      // }

      return Object.assign({}, state, {
        ...state,
        show: false,
        response: true,
      });
    default:
      return state;
  }
}

function prModal(state = handlePRModalInitialState, action) {
  switch (action.type) {
    case "PR_MODAL_TOGGLE":
      if (state.show) {
        return Object.assign({}, state, {
          ...state,
          show: false,
        });
      } else {
        return Object.assign({}, state, {
          ...state,
          show: true,
        });
      }

    default:
      return state;
  }
}
function poModal(state = handlePOModalInitialState, action) {
  switch (action.type) {
    case "PO_MODAL_TOGGLE":
      if (state.show) {
        return Object.assign({}, state, {
          ...state,
          show: false,
        });
      } else {
        return Object.assign({}, state, {
          ...state,
          show: true,
        });
      }

    default:
      return state;
  }
}
function DashPoModal(state = handleDashPOModalInitialState, action) {
  switch (action.type) {
    case "DASH_PO_MODAL_TOGGLE":
      if (state.show) {
        return Object.assign({}, state, {
          ...state,
          show: false,
        });
      } else {
        return Object.assign({}, state, {
          ...state,
          show: true,
        });
      }

    default:
      return state;
  }
}
function PDFModal(state = handlePDFPOModalInitialState, action) {
  switch (action.type) {
    case "PDF_MODAL_TOGGLE":
      if (state.show) {
        return Object.assign({}, state, {
          ...state,
          show: false,
        });
      } else {
        return Object.assign({}, state, {
          ...state,
          show: true,
          doc: action.doc,
        });
      }

    default:
      return state;
  }
}
function FileViewerModal(state = handleFileViewerModalInitialState, action) {
  switch (action.type) {
    case "FILE_VIEWER_MODAL_TOGGLE":
      if (state.show) {
        return Object.assign({}, state, {
          ...state,
          show: false,
        });
      } else {
        return Object.assign({}, state, {
          ...state,
          show: true,
          doc: action.doc,
          docType: action.docType,
          filename: action.fileName,
        });
      }

    default:
      return state;
  }
}
function SocketHandler(state = socket, action) {
  switch (action.type) {
    case "SOCKET_HANDLER_INIT":
      return Object.assign({}, state, {
        ...state,
        socket: socketIOClient(process.env.REACT_APP_SOCKET_URL),
      });

    case "SOCKET_HANDLER":
      return state;
    default:
      return state;
  }
}

const reducer = combineReducers({
  getData,
  globalToast,
  globalModal,
  apiData,
  prModal,
  poModal,
  DashPoModal,
  SocketHandler,
  PDFModal,
  FileViewerModal,
});

export default reducer;
