import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { NumericFormat } from "react-number-format";
import "../../css/verification.css";
import Spinner from "react-bootstrap/Spinner";
import InputGroup from "react-bootstrap/InputGroup";
import invoiceApi from "../Invoice/InvoiceAPI";
import inventoryApi from "../Inventory/InventoryAPI";
import { useDispatch } from "react-redux";
import { SELECTED_OPERATION } from "../../constants";
import Select from "react-select";
import moment from "moment";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
//import filters from "../../functions/filters";
import emptyImg from "../../assets/fork-lift.svg";
//import testPdf from "../../assets/testPdf.pdf";

import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Legend,
  Area,
  ResponsiveContainer,
} from "recharts";
//import Voucher from "../Transactions/PDF/Voucher";
const customStyles = {
  menu: (provided, state) => {
    //console.log(state);
    const width = "100%";

    let color = state.selectProps.menuColor;
    const padding = 0;

    return { ...provided, width, color, padding };
  },
  control: (provided) => ({
    ...provided,
    borderRadius: "5px 0px 0px 5px",
  }),

  container: (_, { selectProps: { width } }) => ({
    width: width,
    flex: 1,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,

    borderRadius: "5px 0px 0px 5px",
  }),

  option: (provided, state) => {
    let backgroundColor = "";
    let display = "block";
    let color = "black";
    if (state.data.isDefault === 1) {
      backgroundColor = "rgba(255, 0, 0, 0.2)";
    }
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    //console.log(state.data);
    if (state.data.isHidden === 1) {
      display = "none";
    }

    return {
      ...provided,
      opacity,
      transition,
      backgroundColor,
      color,
      display,
    };
  },
};

const FileViewer = React.memo((props) => {
  //console.log(props);
  return (
    <>
      {/* pdf-renderer */}
      <style>
        {`
          #pdf-renderer {
            background-color: #d3d3d3
          }
          
        `}
      </style>
      <DocViewer
        pluginRenderers={DocViewerRenderers}
        //prefetchMethod="GET" requestHeaders={headers}
        theme={{
          primary: "#5296d8",
          secondary: "#ffffff",
          tertiary: "#5296d899",
          textPrimary: "#ffffff",
          textSecondary: "#5296d8",
          textTertiary: "#00000099",
          disableThemeScrollbar: false,
        }}
        documents={[
          {
            uri: props.PDFData,
            fileName: `${props.PDFFileName}.pdf`,
          },
        ]}
        config={{
          pdfZoom: {
            defaultZoom: 1, // 1 as default,
            zoomJump: 0.2, // 0.1 as default,
          },
        }}
      />
    </>
  );
});

const TxtDiscount = (props) => (
  <>
    <h5>Percent Discount</h5>
    <FormControl
      as={NumericFormat}
      inputMode="numeric"
      thousandSeparator={true}
      prefix={"% "}
      style={{ flex: 1 }}
      placeholder="Discount"
      name="txtDiscount"
      allowNegative={false}
      decimalScale={2}
      value={props.state.txtDiscount.value}
      // disabled={true}
      // readOnly={true}
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values;

        return formattedValue === "" || floatValue <= 100;
      }}
      // onChange={(x) => {
      //   console.log(String(x.currentTarget.value).slice(1));
      // }}
      onValueChange={(x, e) => {
        e.source === "event" &&
          props.state.handleActionChange({
            target: {
              value: Number(x.value),
              name: "txtDiscount",
            },
          });
        // props.state.inputChange({
        //   target: {
        //     value: x.value,
        //     name: "txtDiscount",
        //   },
        // });
      }}
      className={props.state.txtDiscount.className}
    />
  </>
);
const TxtDiscounted = (props) => (
  <>
    <h5>Discount Amount</h5>
    <FormControl
      as={NumericFormat}
      inputMode="numeric"
      thousandSeparator={true}
      style={{ flex: 1 }}
      placeholder="Discounted SRP"
      name="txtDiscounted"
      prefix={"₱ "}
      // disabled={true}
      // readOnly={true}
      allowNegative={false}
      decimalScale={2}
      fixedDecimalScale={true}
      value={props.state.txtDiscounted.value}
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values;
        let SRP = props.state.selectedRowVerificationTableContent
          ? props.state.selectedRowVerificationTableContent.srp
          : 0;
        const PRICE_MODIFIER =
          props.state.selectedRowVerificationTableContent.priceModifier;
        if (PRICE_MODIFIER) {
          SRP = SRP + SRP * (PRICE_MODIFIER / 100);
        }
        return formattedValue === "" || (floatValue <= SRP && floatValue >= 0);
      }}
      onValueChange={(x, e) => {
        e.source === "event" &&
          props.state.handleActionChange({
            target: {
              value: Number(x.value),
              name: "txtDiscounted",
            },
          });
      }}
      className={props.state.txtDiscount.className}
    />
  </>
);
const TxtDiscountedWhole = (props) => (
  <>
    <h5>Discounted Price</h5>
    <FormControl
      as={NumericFormat}
      inputMode="numeric"
      thousandSeparator={true}
      style={{ flex: 1 }}
      allowNegative={false}
      decimalScale={2}
      fixedDecimalScale={true}
      prefix={"₱ "}
      placeholder="Amount Discounted"
      name="txtDiscountedWhole"
      value={props.state.txtDiscountedWhole.value}
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values;
        let SRP =
          props.state.selectedRowVerificationTableContent !== null
            ? props.state.selectedRowVerificationTableContent.srp
            : 0;
        const PRICE_MODIFIER =
          props.state.selectedRowVerificationTableContent.priceModifier;
        if (PRICE_MODIFIER) {
          SRP = SRP + SRP * (PRICE_MODIFIER / 100);
        }
        //console.log(SRP);
        return formattedValue === "" || (floatValue <= SRP && floatValue >= 0);
      }}
      onValueChange={(x, e) => {
        e.source === "event" &&
          props.state.handleActionChange({
            target: {
              value: Number(x.value),

              name: "txtDiscountedWhole",
            },
          });
      }}
      className={props.state.txtDiscount.className}
    />
  </>
);

const TxtMarkupPercent = (props) => (
  <>
    <h5>Markup Percent</h5>
    <FormControl
      as={NumericFormat}
      inputMode="numeric"
      thousandSeparator={true}
      prefix={"% "}
      style={{ flex: 1 }}
      placeholder="Markup Percent"
      name="txtMarkupPercent"
      allowNegative={false}
      decimalScale={2}
      value={props.state.txtMarkupPercent.value}
      // disabled={true}
      // readOnly={true}
      // isAllowed={(values) => {
      //   const { formattedValue, floatValue } = values;

      //   return formattedValue === "" || floatValue <= 100;
      // }}
      // onChange={(x) => {
      //   console.log(String(x.currentTarget.value).slice(1));
      // }}
      onValueChange={(x, e) => {
        e.source === "event" &&
          props.state.handleActionChange({
            target: {
              value: Number(x.value),
              name: "txtMarkupPercent",
            },
          });
      }}
      className=""
    />
  </>
);
const TxtMarkupAmount = (props) => (
  <>
    <h5>Markup Amount</h5>
    <FormControl
      as={NumericFormat}
      inputMode="numeric"
      thousandSeparator={true}
      style={{ flex: 1 }}
      placeholder="Markup Amount"
      name="txtMarkupAmount"
      prefix={"₱ "}
      // disabled={true}
      // readOnly={true}
      allowNegative={false}
      decimalScale={2}
      fixedDecimalScale={true}
      value={props.state.txtMarkupAmount.value}
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values;
        let SRP = props.state.selectedRowVerificationTableContent
          ? props.state.selectedRowVerificationTableContent.srp
          : 0;

        return formattedValue === "" || floatValue > 0;
      }}
      onValueChange={(x, e) => {
        e.source === "event" &&
          props.state.handleActionChange({
            target: {
              value: Number(x.value),
              name: "txtMarkupAmount",
            },
          });
      }}
      className=""
    />
  </>
);
const TxtMarkupWhole = (props) => (
  <>
    <h5>Marked Up Price</h5>
    <FormControl
      as={NumericFormat}
      inputMode="numeric"
      thousandSeparator={true}
      style={{ flex: 1 }}
      allowNegative={false}
      decimalScale={2}
      fixedDecimalScale={true}
      prefix={"₱ "}
      placeholder="Amount Discounted"
      name="txtMarkupWhole"
      value={props.state.txtMarkupWhole.value}
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values;
        let SRP =
          props.state.selectedRowVerificationTableContent !== null
            ? props.state.selectedRowVerificationTableContent.srp
            : 0;

        //console.log(SRP);
        return formattedValue === "" || floatValue > 0;
      }}
      onValueChange={(x, e) => {
        e.source === "event" &&
          props.state.handleActionChange({
            target: {
              value: Number(x.value),

              name: "txtMarkupWhole",
            },
          });
      }}
      className=""
    />
  </>
);

let TxtInvoiceName = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      Invoice Name
    </Form.Label>
    <FormControl
      type="text"
      style={{ textTransform: "uppercase" }}
      placeholder="Name"
      name="txtInvoiceName"
      value={props.state.txtInvoiceName.value}
      onChange={props.state.inputChange}
      className={props.state.txtInvoiceName.className + " css-actorInput mb-2"}
      readOnly={props.state.txtInvoiceName.readOnly}
      required
    />
  </>
);
let TxtSupplier = (props) => {
  const dispatch = useDispatch();
  const [SupplierOperation, setSupplierOperation] = useState(
    SELECTED_OPERATION.VIEW
  );

  const updateInvoice = async () => {
    const data = {
      data: {
        id: props.state.selectedRowVerification.id,
        supplierId: props.state.txtSupplier.value.id,
      },
    };

    await invoiceApi.updateInvoice(dispatch, data);
    props.state.handleActionChange({
      target: { name: "refreshSelectedPR" },
    });
    dispatch({
      type: "TOAST_ADD",
      payload: {
        title: `Modified Supplier Successfully`,
      },
    });
    console.log("PRE-POST INVOICE DATA ====> ", data);
  };
  return (
    <>
      <div
        className=""
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: props.state.txtSupplier.value
            ? "var(--REB-primary)"
            : "var(--REB-warning)",
          borderRadius: "5px",
        }}
      >
        <p
          className="m-0 pl-2"
          style={{
            color: "var(--REB-white)",
            fontWeight: "bold",

            // backgroundColor: "var(--REB-primary)",
          }}
        >
          Supplier
        </p>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {/* <FormControl
            type="text"
            style={{ borderRadius: "0px 0px 0px 5px" }}
            placeholder="Supplier Name"
            name="txtSupplier"
            value={props.state.txtSupplier.value}
            onChange={props.state.inputChange}
            className=""
            readOnly={
              props.state.txtSupplier.readOnly &&
              SupplierOperation === SELECTED_OPERATION.VIEW
            }
            required
          /> */}
          <Select
            styles={customStyles}
            isSearchable
            menuPortalTarget={document.body}
            width="70%"
            options={props.state.selDetailSupplier.data}
            value={props.state.txtSupplier.value}
            isDisabled={
              props.state.txtSupplier.readOnly &&
              SupplierOperation === SELECTED_OPERATION.VIEW
            }
            getOptionLabel={(x) => x.company}
            getOptionValue={(x) => x.id}
            onChange={(x, name) => {
              props.state.inputChange({
                target: {
                  value: x,
                  name: "txtSupplier",
                },
              });
            }}
          />
          {SupplierOperation === SELECTED_OPERATION.VIEW && (
            <Button
              style={{
                borderRadius: "0px 5px 5px 0px",
                backgroundColor: "var(--REB-tertiary)",
              }}
              onClick={() => {
                setSupplierOperation(SELECTED_OPERATION.EDIT);
              }}
            >
              Modify
            </Button>
          )}
          {SupplierOperation === SELECTED_OPERATION.EDIT && (
            <>
              <Button
                style={{
                  borderRadius: "0px",
                  backgroundColor: "var(--REB-yellow)",
                  color: "var(--REB-black)",
                }}
                onClick={() => {
                  setSupplierOperation(SELECTED_OPERATION.VIEW);
                }}
              >
                Cancel
              </Button>
              <Button
                style={{
                  borderRadius: "0px 5px 5px 0px",
                  backgroundColor: "var(--REB-green)",
                }}
                onClick={() => {
                  props.state.dispatch({
                    type: "MODAL_SHOW",
                    payload: {
                      title: "Confirmation",
                      desc: `Are you sure you want to modify this supplier?`,
                      isYesNo: true,
                      cb: () => {
                        if (
                          props.state.txtSupplier.value !== null &&
                          props.state.txtSupplier.value !== ""
                        ) {
                          setSupplierOperation(SELECTED_OPERATION.VIEW);
                          updateInvoice();
                        } else {
                          props.state.dispatch({
                            type: "MODAL_SHOW",
                            payload: {
                              title: "Validation notice",
                              desc: `Please provide supplier name`,
                              isYesNo: false,
                            },
                          });
                        }
                      },
                    },
                  });
                }}
              >
                Save
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};
let TxtClient = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      Client
    </Form.Label>
    <FormControl
      type="text"
      style={{ textTransform: "uppercase" }}
      placeholder="Name"
      name="txtClient"
      value={props.state.txtClient.value}
      onChange={props.state.inputChange}
      className={props.state.txtClient.className + " css-actorInput mb-2"}
      readOnly={props.state.txtClient.readOnly}
      required
    />
  </>
);
let TxtSalesPerson = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      Sales Person
    </Form.Label>
    <FormControl
      type="text"
      style={{ textTransform: "uppercase" }}
      placeholder="Name"
      name="txtSalesperson"
      value={props.state.txtSalesperson.value}
      onChange={props.state.inputChange}
      className={props.state.txtSalesperson.className + " css-actorInput mb-2"}
      readOnly={props.state.txtSalesperson.readOnly}
      required
    />
  </>
);
let TxtShippingAddress = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      Shipping Address
    </Form.Label>
    <FormControl
      type="text"
      //style={{ textTransform: "uppercase" }}
      placeholder="Shipping Address"
      name="txtShippingAddress"
      value={props.state.txtShippingAddress.value}
      onChange={props.state.inputChange}
      className={
        props.state.txtShippingAddress.className + " css-actorInput mb-2"
      }
      readOnly={props.state.txtShippingAddress.readOnly}
    />
  </>
);

let TxtDescription = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      Description
    </Form.Label>
    <FormControl
      type="text"
      //style={{ textTransform: "uppercase" }}
      placeholder="Description"
      name="txtDesc"
      value={props.state.txtDesc.value}
      onChange={props.state.inputChange}
      className={props.state.txtDesc.className + " css-actorInput mb-2"}
      readOnly={props.state.txtDesc.readOnly}
    />
  </>
);
let TxtInvFrom = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      Inventory From
    </Form.Label>
    <FormControl
      type="text"
      style={{ textTransform: "uppercase" }}
      placeholder="Inventory From"
      name="txtInvFrom"
      value={props.state.txtInvFrom.value}
      onChange={props.state.inputChange}
      className={props.state.txtInvFrom.className + " css-actorInput mb-2"}
      readOnly={props.state.txtInvFrom.readOnly}
    />
  </>
);
let TxtInvTo = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      Inventory To
    </Form.Label>
    <FormControl
      type="text"
      style={{ textTransform: "uppercase" }}
      placeholder="Inventory To"
      name="txtInvTo"
      value={props.state.txtInvTo.value}
      onChange={props.state.inputChange}
      className={props.state.txtInvTo.className + " css-actorInput mb-2"}
      readOnly={props.state.txtInvTo.readOnly}
    />
  </>
);

let TxtNotes = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      Notes
    </Form.Label>
    <FormControl
      as="textarea"
      //style={{ textTransform: "uppercase" }}
      placeholder="Notes"
      name="txtNotes"
      value={props.state.txtNotes.value}
      onChange={props.state.inputChange}
      className={props.state.txtNotes.className + " css-actorInput mb-2"}
      readOnly={props.state.txtNotes.readOnly}
    />
  </>
);

let TxtDateDeadline = (props) => (
  <>
    <Form.Label>
      <strong>Deadline</strong>
    </Form.Label>
    <FormControl
      type="datetime-local"
      placeholder={props.state.txtDeadline.placeholder}
      name="txtDeadline"
      value={props.state.txtDeadline.value}
      onChange={props.state.inputChange}
      className={props.state.txtDeadline.className + " css-projectInput"}
      readOnly={props.state.txtDeadline.readOnly}
    />
  </>
);
let TxtDateAdded = (props) => (
  <>
    <Form.Label>
      <strong>Date Posted</strong>
    </Form.Label>
    <FormControl
      type="datetime-local"
      //placeholder={props.state.txtDateAdded.placeholder}
      name="txtDateAdded"
      value={props.state.txtDateAdded.value}
      onChange={props.state.inputChange}
      className={props.state.txtDateAdded.className + " css-projectInput"}
      readOnly={true}
    />
  </>
);
let TxtDateLastModified = (props) => (
  <>
    <Form.Label>
      <strong>Date Last Modified</strong>
    </Form.Label>
    <FormControl
      type="datetime-local"
      //placeholder={props.state.txtDateLastModified.placeholder}
      name="txtDateLastModified"
      value={props.state.txtDateLastModified.value}
      onChange={props.state.inputChange}
      className={
        props.state.txtDateLastModified.className + " css-projectInput"
      }
      readOnly={true}
    />
  </>
);

const VerificationModal = (props) => {
  const dispatch = useDispatch();
  const [TxtCogs, setTxtCogs] = useState(null);
  const [TxtHandling, setTxtHandling] = useState(null);
  const [TxtShipping, setTxtShipping] = useState(null);

  const [TxtPRItemCost, setTxtPRItemCost] = useState(null);

  const [TxtInvItemSRP, setTxtInvItemSRP] = useState(null);

  const [TxtInvItemCOST, setTxtInvItemCOST] = useState(null);

  const [ModifyInventorySRP, setModifyInventorySRP] = useState(false);
  const [ModifyInventoryCOST, setModifyInventoryCOST] = useState(false);

  const { selectedRowVerificationTableContent } = props.state;
  const [
    SelectedRowVerificationTableContentUnit,
    setSelectedRowVerificationTableContentUnit,
  ] = useState("-");

  const [PrevCogsData, setPrevCogsData] = useState([]);

  const { BLOB_TO_PDF_DISPLAY } = props.state;
  const [PDFData, setPDFData] = useState(BLOB_TO_PDF_DISPLAY);
  const [PDFFileName, setPDFFileName] = useState(
    props.state.selectedRowVerification?.name
  );

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="p-1"
          style={{
            backgroundColor: "var(--REB-primary)",
            borderRadius: "5px",
            color: "var(--REB-white)",
          }}
        >
          <p className="m-0 p-0">{`Date : ${moment(
            payload[0].payload.dateFinished
          ).format("LL")}`}</p>
          <p className="m-0 p-0">{`Cost Price: ${payload[0].payload.price}`}</p>
          <p className="m-0 p-0">{`COGS: ${payload[0].payload.cogs}`}</p>
          <p className="m-0 p-0">{`Profit Margin %: ${(
            ((payload[0].payload.price - payload[0].payload.cogs) /
              payload[0].payload.price) *
            100
          ).toFixed()}`}</p>
          {/* <p className="m-0 p-0">{`Profit Margin: ${payload[0].payload.cogs}`}</p> */}
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    let name = null;
    const getData = async (id) => {
      const COGS_DATA = await inventoryApi.getItemCogsSummary(
        dispatch,
        Number(id)
      );
      if (COGS_DATA) {
        let itemCogsGraphData = [];
        if (COGS_DATA.length > 0) {
          COGS_DATA.forEach((e) => {
            itemCogsGraphData.push({
              ...e,
              IHandling: e.handling / e.qtyDelivered,
              IShipping: e.shipping / e.qtyDelivered,
              month: moment(e.dateFinished).format("MMM DD YYYY"),
            });
          });
        }
        itemCogsGraphData = itemCogsGraphData.sort((a, b) =>
          moment(a.dateFinished).diff(moment(b.dateFinished))
        );
        // console.log("COGS DATA ===> ", itemCogsGraphData);
        setPrevCogsData(itemCogsGraphData);
      }
    };
    if (
      selectedRowVerificationTableContent &&
      selectedRowVerificationTableContent.item
    ) {
      getData(selectedRowVerificationTableContent.item.id);
      setTxtPRItemCost(
        selectedRowVerificationTableContent.price
          ? selectedRowVerificationTableContent.price
          : ""
      );
      setTxtCogs(
        selectedRowVerificationTableContent.cogs
          ? selectedRowVerificationTableContent.cogs
          : ""
      );
      setTxtHandling(
        selectedRowVerificationTableContent.handling
          ? selectedRowVerificationTableContent.handling
          : ""
      );
      setTxtShipping(
        selectedRowVerificationTableContent.shipping
          ? selectedRowVerificationTableContent.shipping
          : ""
      );
      name = selectedRowVerificationTableContent.item.details.find(
        (x) => x.type === 2
      );

      if (name) {
        name = name.name;
      }
    }

    setSelectedRowVerificationTableContentUnit(name);
  }, [selectedRowVerificationTableContent]);

  // useEffect(() => {
  //   console.log("trigger");
  // }, [BLOB_TO_PDF_DISPLAY, props.state.selectedRowVerification?.name]);

  const resetUI = () => {
    setTxtCogs("");
    setTxtHandling("");
    setTxtShipping("");
    setTxtPRItemCost("");
    setTxtInvItemSRP("");
    setModifyInventorySRP(false);
    setTxtInvItemCOST("");
    setModifyInventoryCOST(false);
  };

  const modifyItemCost = async () => {
    const data = {
      id: selectedRowVerificationTableContent.id,

      lastModifiedByActorId: Number(props.state.user.id),
      price: Number(TxtPRItemCost),
      handling: Number(TxtHandling),
      shipping: Number(TxtShipping),
      cogs: Number(TxtCogs),

      //dateLastModified: DateToday,
    };
    await invoiceApi.updateInvoiceContent(dispatch, data);
    props.state.handleActionChange({
      target: { name: "refreshSelectedPRContent" },
    });
    dispatch({
      type: "TOAST_ADD",
      payload: {
        title: `Modified Cost Successfully`,
      },
    });
    resetUI();
  };
  const modifyInventorySRP = async () => {
    const data = {
      data: {
        id: Number(selectedRowVerificationTableContent.item.id),
        price: Number(TxtInvItemSRP),
      },
    };
    await inventoryApi.updateItem(dispatch, data);

    props.state.handleActionChange({
      target: { name: "refreshSelectedPRContent" },
    });
    dispatch({
      type: "TOAST_ADD",
      payload: {
        title: `Modified Item SRP Successfully`,
      },
    });
    resetUI();
  };
  const modifyInventoryCOST = async () => {
    const data = {
      data: {
        id: Number(selectedRowVerificationTableContent.item.id),
        cost: Number(TxtInvItemCOST),
      },
    };
    await inventoryApi.updateItem(dispatch, data);

    props.state.handleActionChange({
      target: { name: "refreshSelectedPRContent" },
    });
    dispatch({
      type: "TOAST_ADD",
      payload: {
        title: `Modified Item COST Successfully`,
      },
    });
    resetUI();
  };

  return (
    <Modal
      show={props.state.handleVerificationModal.isShow}
      onHide={props.state.handleVerificationModal.toggle}
      onExit={(e) => {
        props.state.handleVerificationModal.handleExit(e);
        resetUI();
      }}
      dialogClassName="pdf-modal-dialog"
      className="pdf-modal"
      centered
    >
      <Modal.Body className="verification-modal-body">
        <Row
          className="actor-modal-header-row pt-3 pb-3 mb-1 pl-2 pr-2"
          noGutters
        >
          <Col xs={2} sm={2} md={1} lg={1}>
            <OverlayTrigger
              placement={"right"}
              overlay={<Tooltip id={`project-tooltip-add`}>Close</Tooltip>}
            >
              <Button
                variant="secondary"
                name="btnCloseInvoiceModal"
                style={{ backgroundColor: "var(--REB-primary)" }}
                className="icofont-rounded-left"
                onClick={(e) => {
                  props.state.handleVerificationModal.toggle();
                }}
              />
            </OverlayTrigger>
          </Col>
          <Col xs={10} sm={10} md={6} lg={6} className="actor-modal-header-col">
            <h4 className=" actor-modal-header-text"> Confirmation</h4>
          </Col>
          {/* <Col md={3} lg={3} className="user-col-cont-padding">
        <Button
          block
          name="btnSubmitForConfirmation"
          hidden={
            !props.state.handleInvoiceModal.isView ||
            !props.state.showPendingApproval
          }
          style={{ backgroundColor: "var(--REB-green)" }}
          onClick={(e) => {
            props.state.handleActionChange(e);
          }}
        >
          SUBMIT FOR CONFIRMATION
        </Button>
      </Col> */}
          <style>
            {`
              @media (max-width: 991px) {
                .viewPdfLG {
                  display: none !important;
                }
                .viewPdfMobile {
                  display: block !important;
                }
              }
              @media (min-width: 992px) {
                .viewPdfLG {
                  display: block !important;
                }
                .viewPdfMobile {
                  display: none !important;
                }
              }
            `}
          </style>
          {props.state.selectedRowVerification && (
            <Col
              xs={12}
              sm={12}
              md={2}
              lg={2}
              className="verification-modal-header-pdf-col viewPdfLG"
            >
              {props.state.selectedRowVerification?.generalTransType !==
                "transaction" ||
                (props.state.selectedRowVerification?.generalTransType !==
                  "journal" && (
                  <>
                    {" "}
                    <Button
                      block
                      name="btnViewPDF"
                      style={{ backgroundColor: "var(--REB-green)" }}
                      onClick={(e) => {
                        props.state.handleActionChange(e, (x) => {
                          // props.state.dispatch({
                          //   type: "PDF_MODAL_TOGGLE",
                          //   doc: QuotePDF.QuotePDF(props.state),
                          // });
                        });
                      }}
                    >
                      VIEW PDF
                    </Button>
                  </>
                ))}
            </Col>
          )}
          {props.state.selectedRowVerification && (
            <Col
              xs={12}
              sm={12}
              md={2}
              lg={2}
              className="verification-modal-header-pdf-col viewPdfMobile"
            >
              {props.state.selectedRowVerification?.generalTransType !==
                "transaction" ||
                (props.state.selectedRowVerification?.generalTransType !==
                  "journal" && (
                  <>
                    <Button
                      block
                      name="btnMobilePDF"
                      style={{ backgroundColor: "var(--REB-green)" }}
                      onClick={(e) => {
                        props.state.handleActionChange(e, (x) => {
                          // props.state.dispatch({
                          //   type: "PDF_MODAL_TOGGLE",
                          //   doc: QuotePDF.QuotePDF(props.state),
                          // });
                        });
                      }}
                    >
                      {props.state.isPDFLoading && (
                        <>
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />{" "}
                          Preparing PDF...
                        </>
                      )}
                      {!props.state.isPDFLoading && <>DOWNLOAD PDF</>}
                    </Button>
                  </>
                ))}
            </Col>
          )}
        </Row>

        {/* <Form
          id="formActorInput"
          noValidate
          validated={props.state.handleVerificationModal.isValidated}
          //onSubmit={props.state.handleSubmit}
        > */}
        <div
          style={{
            overflowX: "hidden",
            overflowY: "scroll",
            maxHeight: "70vh",
          }}
        >
          {((x) => {
            const SelectedRow = props.state.selectedRowVerification;
            if (SelectedRow !== null && SelectedRow !== undefined) {
              if (SelectedRow.verificationType === 0) {
                //console.log(SelectedRow)
                const _e = SelectedRow?.invoiceType?.name;

                if (_e === "Quote") {
                  return (
                    <>
                      <Row
                        className="mb-3"
                        style={{ backgroundColor: "var(--REB-blue)" }}
                        noGutters
                      >
                        <h4
                          className=" m-3"
                          style={{
                            fontWeight: "bolder",
                            color: "var(--REB-white)",
                          }}
                        >
                          {props.state.isChangeItem
                            ? "QUOTATION CHANGE ITEM"
                            : "QUOTATION"}
                        </h4>
                      </Row>
                      <Row noGutters>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtInvoiceName state={props.state} />
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtDescription state={props.state} />
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtShippingAddress state={props.state} />
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtDateAdded state={props.state} />
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtDateLastModified state={props.state} />
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtDateDeadline state={props.state} />
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtSalesPerson state={props.state} />
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtClient state={props.state} />
                        </Col>
                        {/* <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className="pl-2 pr-2"
                          >
                            <TxtNotes state={props.state} />
                          </Col> */}
                      </Row>
                      <div
                        className="p-4"
                        style={{ backgroundColor: "var(--REB-body)" }}
                      >
                        <div className="px-2">
                          <h4 style={{ fontWeight: "bolder" }}>
                            Invoice Content
                          </h4>
                          <p style={{ fontWeight: "bold" }}>
                            Select an item to apply discount / markup
                          </p>
                          {props.state.verificationTableContentSelection
                            .length > 0 ? (
                            <>
                              <Row noGutters>
                                <Col
                                  xs={12}
                                  sm={3}
                                  md={4}
                                  lg={4}
                                  className="px-2"
                                >
                                  <TxtDiscount state={props.state} />
                                </Col>
                                <Col
                                  xs={12}
                                  sm={3}
                                  md={4}
                                  lg={4}
                                  className="px-2"
                                >
                                  <TxtDiscounted state={props.state} />
                                </Col>
                                <Col
                                  xs={12}
                                  sm={3}
                                  md={4}
                                  lg={4}
                                  className="px-2"
                                >
                                  <TxtDiscountedWhole state={props.state} />
                                </Col>
                              </Row>
                              <Row className="mt-3" noGutters>
                                <Col
                                  md={3}
                                  lg={3}
                                  sm={12}
                                  xs={12}
                                  className=" verification-modal-footer-buttons"
                                >
                                  <Button
                                    block
                                    name="btnDiscount"
                                    //disabled={!props.state.handleInvoiceModal.isView}
                                    style={{
                                      backgroundColor: "var(--REB-green)",
                                    }}
                                    onClick={(e) => {
                                      props.state.dispatch({
                                        type: "MODAL_SHOW",
                                        payload: {
                                          title: "Discount Confirmation",
                                          desc: `Are you sure you want to apply ${Number(
                                            props.state.txtDiscount.value
                                          ).toFixed(2)}% Discount?`,
                                          isYesNo: true,
                                          cb: () => {
                                            props.state.handleActionChange(e);
                                          },
                                        },
                                      });
                                    }}
                                  >
                                    APPLY DISCOUNT
                                  </Button>
                                </Col>
                              </Row>
                              <Row className="mt-3" noGutters>
                                <Col
                                  xs={12}
                                  sm={3}
                                  md={4}
                                  lg={4}
                                  className="px-2"
                                >
                                  <TxtMarkupPercent state={props.state} />
                                </Col>
                                <Col
                                  xs={12}
                                  sm={3}
                                  md={4}
                                  lg={4}
                                  className="px-2"
                                >
                                  <TxtMarkupAmount state={props.state} />
                                </Col>
                                <Col
                                  xs={12}
                                  sm={3}
                                  md={4}
                                  lg={4}
                                  className="px-2"
                                >
                                  <TxtMarkupWhole state={props.state} />
                                </Col>
                              </Row>
                              <Row className="mt-3" noGutters>
                                <Col
                                  md={3}
                                  lg={3}
                                  sm={12}
                                  xs={12}
                                  className=" verification-modal-footer-buttons"
                                >
                                  <Button
                                    block
                                    name="btnMarkup"
                                    //disabled={!props.state.handleInvoiceModal.isView}
                                    style={{
                                      backgroundColor: "var(--REB-green)",
                                    }}
                                    onClick={(e) => {
                                      props.state.dispatch({
                                        type: "MODAL_SHOW",
                                        payload: {
                                          title: "Markup Confirmation",
                                          desc: `Are you sure you want to apply ${Number(
                                            props.state.txtMarkupPercent.value
                                          ).toFixed(2)}% Markup?`,
                                          isYesNo: true,
                                          cb: () => {
                                            props.state.handleActionChange(e);
                                          },
                                        },
                                      });
                                    }}
                                  >
                                    APPLY MARKUP
                                  </Button>
                                </Col>
                              </Row>
                            </>
                          ) : null}
                        </div>

                        <Row className="h-10" noGutters>
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className="pl-2 pr-2 "
                          >
                            {props.tableContent}
                          </Col>
                        </Row>
                        <style>
                          {`
                            @media (max-width: 991px) {
                              .verifGrandTotalContainer {
                                justify-content: center;
                              }
                            }
                            @media (min-width: 992px) {
                              .verifGrandTotalContainer {
                                justify-content: end;
                              }
                            }
                          `}
                        </style>
                        <Row noGutters>
                          <Col className="d-flex   pt-4 verifGrandTotalContainer">
                            <div
                              style={{
                                borderRadius: "5px",
                                backgroundColor: "var(--REB-primary)",
                              }}
                              className="p-3"
                            >
                              <h4
                                className="m-0"
                                style={{
                                  fontWeight: "bolder",
                                  fontSize: "1rem",
                                  color: "var(--REB-white)",
                                }}
                              >
                                GRAND TOTAL: ₱{" "}
                                <NumericFormat
                                  value={props.state.grandTotal}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              </h4>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div
                        className="p-4"
                        style={{ backgroundColor: "var(--REB-body)" }}
                      >
                        <h4 className="pl-2" style={{ fontWeight: "bolder" }}>
                          Invoice Terms
                        </h4>
                        <Row noGutters>
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className="pl-2 pr-2 h-10"
                          >
                            {props.tableTerms}
                          </Col>
                        </Row>
                      </div>
                    </>
                  );
                }
                if (_e === "Delivery Receipt") {
                  return (
                    <>
                      <Row
                        className="mb-3"
                        style={{ backgroundColor: "var(--REB-blue)" }}
                        noGutters
                      >
                        <h4
                          className=" m-3"
                          style={{
                            fontWeight: "bolder",
                            color: "var(--REB-white)",
                          }}
                        >
                          DELIVERY RECEIPT (DR)
                        </h4>
                      </Row>
                      <Row noGutters>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtInvoiceName state={props.state} />
                        </Col>

                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtShippingAddress state={props.state} />
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtDateAdded state={props.state} />
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtDateLastModified state={props.state} />
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtDateDeadline state={props.state} />
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtSalesPerson state={props.state} />
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtClient state={props.state} />
                        </Col>
                        {/* <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className="pl-2 pr-2"
                          >
                            <TxtNotes state={props.state} />
                          </Col> */}
                      </Row>
                      <div
                        style={{ backgroundColor: "var(--REB-body)" }}
                        className="p-4"
                      >
                        <h4 className="pl-2" style={{ fontWeight: "bolder" }}>
                          DR Content
                        </h4>
                        <Row className="h-10" noGutters>
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className="pl-2 pr-2 "
                          >
                            {props.tableContent}
                          </Col>
                        </Row>
                      </div>
                    </>
                  );
                }
                if (_e === "Purchase Request") {
                  return (
                    <>
                      <Row
                        className="mb-3"
                        style={{ backgroundColor: "var(--REB-blue)" }}
                        noGutters
                      >
                        <h4
                          className=" m-3"
                          style={{
                            fontWeight: "bolder",
                            color: "var(--REB-white)",
                          }}
                        >
                          PURCHASE REQUEST (PR)
                        </h4>
                      </Row>
                      <Row noGutters>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtInvoiceName state={props.state} />
                        </Col>

                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtShippingAddress state={props.state} />
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtDateAdded state={props.state} />
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtDateLastModified state={props.state} />
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtDateDeadline state={props.state} />
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtSalesPerson state={props.state} />
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtSupplier state={props.state} />
                        </Col>
                        {/* <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className="pl-2 pr-2"
                          >
                            <TxtNotes state={props.state} />
                          </Col> */}
                      </Row>
                      <div
                        className="p-4"
                        style={{ backgroundColor: "var(--REB-body)" }}
                      >
                        <h4 className="pl-2" style={{ fontWeight: "bolder" }}>
                          PR Content
                        </h4>
                        {props.state.prHasNoPrices ? (
                          <h4
                            className="pl-2"
                            style={{
                              fontWeight: "bolder",
                              color: "var(--REB-warning)",
                            }}
                          >
                            Please provide cogs and cost for all the items.
                          </h4>
                        ) : (
                          <p
                            className="pl-2 m-0"
                            style={{
                              color: "var(--REB-black)",
                            }}
                          >
                            You can select items below to modify cogs, item SRP
                            and supplier price.
                          </p>
                        )}
                        {props.state.selectedRowVerificationTableContent && (
                          <>
                            {/* {console.log(PrevCogsData)} */}
                            <div className="w-100 " style={{ height: "300px" }}>
                              <ResponsiveContainer width="100%" height="100%">
                                <AreaChart
                                  width={500}
                                  height={300}
                                  data={PrevCogsData}
                                  margin={{
                                    top: 5,
                                    right: 20,
                                    left: 20,
                                    bottom: 5,
                                  }}
                                >
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="month" />
                                  <YAxis interval="preserveEnd" />
                                  <ChartTooltip content={<CustomTooltip />} />
                                  <Legend />
                                  <Area
                                    type="monotone"
                                    dataKey="price"
                                    name="Price"
                                    stroke="#8884D8"
                                    fill="#8884D8"
                                    activeDot={{ r: 8 }}
                                  />
                                  <Area
                                    type="monotone"
                                    dataKey="cogs"
                                    name="COGS"
                                    stroke="#4B488C"
                                    fill="#4B488C"
                                  />
                                </AreaChart>
                              </ResponsiveContainer>
                            </div>
                            <Row noGutters>
                              <Col
                                lg={4}
                                md={4}
                                sm={12}
                                xs={12}
                                className="pl-2 pr-2 mb-3"
                              >
                                <div
                                  className=""
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    backgroundColor: "var(--REB-white)",
                                    borderRadius: "5px",
                                    // border: "1px solid #ced4da",
                                    WebkitBoxShadow:
                                      "inset 0px 0px 0px 1px #ced4da",
                                    MozBoxShadow:
                                      "inset 0px 0px 0px 1px #ced4da",
                                    boxShadow: "inset 0px 0px 0px 1px #ced4da",
                                  }}
                                >
                                  <p
                                    className="m-0 pl-2"
                                    style={{
                                      color: "var(--REB-black)",
                                      fontWeight: "bold",

                                      // backgroundColor: "var(--REB-primary)",
                                    }}
                                  >
                                    Selected Item Current SRP
                                  </p>
                                  <FormControl
                                    as={NumericFormat}
                                    inputMode="numeric"
                                    thousandSeparator={true}
                                    prefix={"₱ "}
                                    style={{}}
                                    placeholder="Selected Item Current SRP"
                                    allowNegative={false}
                                    value={
                                      props.state
                                        .selectedRowVerificationTableContent
                                        .item.price
                                    }
                                    fixedDecimalScale
                                    decimalScale={2}
                                    className=""
                                    disabled
                                  />
                                </div>
                              </Col>
                              <Col
                                lg={4}
                                md={4}
                                sm={12}
                                xs={12}
                                className="pl-2 pr-2 mb-3"
                              >
                                <div
                                  className=""
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    backgroundColor: "var(--REB-white)",
                                    borderRadius: "5px",
                                    // border: "1px solid #ced4da",
                                    WebkitBoxShadow:
                                      "inset 0px 0px 0px 1px #ced4da",
                                    MozBoxShadow:
                                      "inset 0px 0px 0px 1px #ced4da",
                                    boxShadow: "inset 0px 0px 0px 1px #ced4da",
                                  }}
                                >
                                  <p
                                    className="m-0 pl-2"
                                    style={{
                                      color: "var(--REB-black)",
                                      fontWeight: "bold",

                                      // backgroundColor: "var(--REB-primary)",
                                    }}
                                  >
                                    Selected Item COST
                                  </p>
                                  <FormControl
                                    as={NumericFormat}
                                    inputMode="numeric"
                                    thousandSeparator={true}
                                    prefix={"₱ "}
                                    style={{}}
                                    placeholder=" Selected Item COST"
                                    allowNegative={false}
                                    defaultValue={
                                      props.state
                                        .selectedRowVerificationTableContent
                                        .cost
                                    }
                                    fixedDecimalScale
                                    decimalScale={2}
                                    // onValueChange={(x) => {
                                    //   setTxtPRItemCOGS(x.value);
                                    // }}
                                    className=""
                                    disabled
                                  />
                                </div>
                              </Col>
                              <Col
                                lg={4}
                                md={4}
                                sm={12}
                                xs={12}
                                className="pl-2 pr-2 mb-3"
                              >
                                <div
                                  className=""
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    backgroundColor: "var(--REB-white)",
                                    borderRadius: "5px",
                                    // border: "1px solid #ced4da",
                                    WebkitBoxShadow:
                                      "inset 0px 0px 0px 1px #ced4da",
                                    MozBoxShadow:
                                      "inset 0px 0px 0px 1px #ced4da",
                                    boxShadow: "inset 0px 0px 0px 1px #ced4da",
                                  }}
                                >
                                  <p
                                    className="m-0 pl-2"
                                    style={{
                                      color: "var(--REB-black)",
                                      fontWeight: "bold",

                                      // backgroundColor: "var(--REB-primary)",
                                    }}
                                  >
                                    Selected Item COGS
                                  </p>
                                  <FormControl
                                    as={NumericFormat}
                                    inputMode="numeric"
                                    thousandSeparator={true}
                                    prefix={"₱ "}
                                    style={{}}
                                    placeholder=" Selected Item COGS"
                                    allowNegative={false}
                                    defaultValue={
                                      props.state
                                        .selectedRowVerificationTableContent
                                        .cogs
                                    }
                                    fixedDecimalScale
                                    decimalScale={2}
                                    // onValueChange={(x) => {
                                    //   setTxtPRItemCOGS(x.value);
                                    // }}
                                    className=""
                                    disabled
                                  />
                                </div>
                              </Col>
                              <Col
                                lg={4}
                                md={4}
                                sm={12}
                                xs={12}
                                className="pl-2 pr-2 mb-3"
                              >
                                <div
                                  className=""
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    backgroundColor: "var(--REB-white)",
                                    borderRadius: "5px",
                                    // border: "1px solid #ced4da",
                                    WebkitBoxShadow:
                                      "inset 0px 0px 0px 1px #ced4da",
                                    MozBoxShadow:
                                      "inset 0px 0px 0px 1px #ced4da",
                                    boxShadow: "inset 0px 0px 0px 1px #ced4da",
                                  }}
                                >
                                  <p
                                    className="m-0 pl-2"
                                    style={{
                                      color: "var(--REB-black)",
                                      fontWeight: "bold",

                                      // backgroundColor: "var(--REB-primary)",
                                    }}
                                  >
                                    Selected Item Request Qty
                                  </p>
                                  <FormControl
                                    as={NumericFormat}
                                    inputMode="numeric"
                                    thousandSeparator={true}
                                    suffix={` ${SelectedRowVerificationTableContentUnit}`}
                                    style={{}}
                                    placeholder=" Selected Item Request Qty"
                                    allowNegative={false}
                                    defaultValue={
                                      props.state
                                        .selectedRowVerificationTableContent
                                        .qtyOrdered
                                    }
                                    className=""
                                    disabled
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="mb-2 d-flex flex-row" noGutters>
                              <Col
                                lg={4}
                                md={4}
                                sm={12}
                                xs={12}
                                className="pl-2 pr-2 mb-3"
                              >
                                <div
                                  className=""
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    backgroundColor: "var(--REB-primary)",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <InputGroup.Prepend>
                                    <p
                                      className="m-0 pl-2"
                                      style={{
                                        color: "var(--REB-white)",
                                        fontWeight: "bold",

                                        // backgroundColor: "var(--REB-primary)",
                                      }}
                                    >
                                      Handling Cost (OPTIONAL)
                                    </p>
                                  </InputGroup.Prepend>
                                  <InputGroup.Prepend>
                                    <FormControl
                                      as={NumericFormat}
                                      inputMode="numeric"
                                      thousandSeparator={true}
                                      prefix={"₱ "}
                                      allowNegative={false}
                                      style={{}}
                                      placeholder="Handling Cost"
                                      value={TxtHandling}
                                      fixedDecimalScale
                                      decimalScale={2}
                                      onValueChange={(x) => {
                                        setTxtHandling(Number(x.value));
                                        // props.state.inputChange({
                                        //   target: {
                                        //     value: x.value,
                                        //     name: "txtHandlingPR",
                                        //     placeholder:
                                        //       props.state.txtHandlingPR.placeholder,
                                        //     className:
                                        //       props.state.txtHandlingPR.className,
                                        //   },
                                        // });
                                        // props.state.handleActionChange({
                                        //   target: {
                                        //     name: "txtCogsPR",
                                        //   },
                                        // });
                                      }}
                                      className=""
                                    />
                                  </InputGroup.Prepend>
                                </div>
                              </Col>
                              <Col
                                lg={4}
                                md={4}
                                sm={12}
                                xs={12}
                                className="pl-2 pr-2 mb-3"
                              >
                                <div
                                  className=""
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    backgroundColor: "var(--REB-primary)",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <p
                                    className="m-0 pl-2"
                                    style={{
                                      color: "var(--REB-white)",
                                      fontWeight: "bold",

                                      // backgroundColor: "var(--REB-primary)",
                                    }}
                                  >
                                    Shipping Cost (OPTIONAL)
                                  </p>
                                  <FormControl
                                    as={NumericFormat}
                                    inputMode="numeric"
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    prefix={"₱ "}
                                    style={{}}
                                    placeholder="Shipping Cost"
                                    value={TxtShipping}
                                    fixedDecimalScale
                                    decimalScale={2}
                                    onValueChange={(x) => {
                                      //  console.log(x.value);
                                      setTxtShipping(Number(x.value));
                                    }}
                                    className=""
                                  />
                                </div>
                              </Col>

                              <Col
                                lg={4}
                                md={4}
                                sm={12}
                                xs={12}
                                className="pl-2 pr-2 mb-3"
                              >
                                <div
                                  className=""
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    backgroundColor: "var(--REB-primary)",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <p
                                    className="m-0 pl-2"
                                    style={{
                                      color: "var(--REB-white)",
                                      fontWeight: "bold",

                                      // backgroundColor: "var(--REB-primary)",
                                    }}
                                  >
                                    COGS (OPTIONAL)
                                  </p>
                                  <FormControl
                                    as={NumericFormat}
                                    inputMode="numeric"
                                    thousandSeparator={true}
                                    prefix={"₱ "}
                                    style={{}}
                                    placeholder="COGS"
                                    allowNegative={false}
                                    value={TxtCogs}
                                    fixedDecimalScale
                                    decimalScale={2}
                                    onValueChange={(x) => {
                                      setTxtCogs(Number(x.value));
                                    }}
                                    className=""
                                  />
                                </div>
                              </Col>

                              {/* <Col
                            className=" mb-3 pl-2 pr-2"
                            style={{ alignSelf: "flex-end" }}
                            lg={1}
                            md={1}
                            sm={2}
                            xs={3}
                          >
                            <OverlayTrigger
                              placement={"top"}
                              overlay={
                                <Tooltip id={`actor-tooltip-add`}>
                                  Totals Handling, Shipping & Cost to COGS
                                </Tooltip>
                              }
                            >
                              <Button
                                name="btnTotalCogs"
                                //disabled={props.state.txtDisplay.readOnly}
                                style={{
                                  backgroundColor: "var(--REB-green)",
                                  //border: "1px solid var(--REB-green) !important",
                                  // fontSize: "10px",
                                }}
                                className="icofont-ui-calculator btnCalc"
                                onClick={(e) => {
                                  // props.state.handleActionChange({
                                  //   target: {
                                  //     name: "btnTotalCogsPR",
                                  //     value: e,
                                  //     service: true,
                                  //   },
                                  // });
                                }}
                              ></Button>
                            </OverlayTrigger>
                          </Col> */}
                              {/* <Col
                            lg={2}
                            md={11}
                            sm={11}
                            xs={9}
                            className="pl-2 pr-2"
                          >
                            <div
                              className=""
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                backgroundColor: "var(--REB-primary)",
                                borderRadius: "5px",
                              }}
                            >
                              <p
                                className="m-0 pl-2"
                                style={{
                                  color: "var(--REB-white)",
                                  fontWeight: "bold",

                                  // backgroundColor: "var(--REB-primary)",
                                }}
                              >
                                COGS
                              </p>
                              <FormControl
                                as={NumericFormat }
                                inputMode="numeric"
                                thousandSeparator={true}
                                prefix={"₱"}
                                style={{}}
                                placeholder="COGS"
                                allowNegative={false}
                                value={TxtCogs}
                                onValueChange={(x) => {
                                  setTxtCogs(x.value);
                                }}
                                className=""
                              />
                            </div>
                          </Col> */}
                              {/* <Col
                            className=" mb-3 pl-2 pr-2"
                            style={{ alignSelf: "flex-end" }}
                            lg={1}
                            md={1}
                            sm={2}
                            xs={3}
                          >
                            <OverlayTrigger
                              placement={"top"}
                              overlay={
                                <Tooltip id={`actor-tooltip-add`}>
                                  Copies Handling, Shipping, Cost & Cogs data
                                  from Inventory
                                </Tooltip>
                              }
                            >
                              <Button
                                name="btnCopyCogsFromInventory"
                                //disabled={props.state.txtDisplay.readOnly}
                                style={{
                                  backgroundColor: "var(--REB-green)",
                                  //border: "1px solid var(--REB-green) !important",
                                  // fontSize: "10px",
                                }}
                                className="icofont-ui-copy btnCalc"
                                onClick={(e) => {
                                  // props.state.handleActionChange({
                                  //   target: {
                                  //     name: "btnCopyCogsFromInventory",
                                  //     value: e,
                                  //     service: true,
                                  //   },
                                  // });
                                }}
                              ></Button>
                            </OverlayTrigger>
                          </Col> */}
                            </Row>
                            <Row noGutters>
                              <Col
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}
                                className="pl-2 pr-2 mb-3"
                              >
                                <div
                                  className=""
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    backgroundColor: TxtPRItemCost
                                      ? "var(--REB-primary)"
                                      : "var(--REB-warning)",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <p
                                    className="m-0 pl-2"
                                    style={{
                                      color: "var(--REB-white)",
                                      fontWeight: "bold",

                                      // backgroundColor: "var(--REB-primary)",
                                    }}
                                  >
                                    Item Cost *
                                  </p>
                                  <FormControl
                                    as={NumericFormat}
                                    inputMode="numeric"
                                    thousandSeparator={true}
                                    prefix={"₱ "}
                                    style={{}}
                                    placeholder={`Inventory COST: ₱ ${props.state.selectedRowVerificationTableContent.item.cost}`}
                                    allowNegative={false}
                                    value={TxtPRItemCost}
                                    fixedDecimalScale
                                    decimalScale={2}
                                    onValueChange={(x) => {
                                      setTxtPRItemCost(Number(x.value));
                                    }}
                                    className=""
                                  />
                                </div>
                              </Col>
                              <Col
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}
                                className="pl-2 pr-2 mb-3 "
                              >
                                <Button
                                  block
                                  //disabled={!props.state.handleInvoiceModal.isView}
                                  className="h-100"
                                  style={{
                                    backgroundColor: "var(--REB-green)",
                                  }}
                                  onClick={(e) => {
                                    dispatch({
                                      type: "MODAL_SHOW",
                                      payload: {
                                        title: "Verification Confirmation",
                                        desc: `Are you sure you want to Apply this changes?`,
                                        isYesNo: true,
                                        cb: () => {
                                          if (
                                            TxtPRItemCost !== null ||
                                            TxtPRItemCost !== ""
                                          ) {
                                            modifyItemCost();
                                          } else {
                                            dispatch({
                                              type: "MODAL_SHOW",
                                              payload: {
                                                title: "No Item Cost Provided",
                                                desc: `Item cost cant be empty.`,
                                                isYesNo: false,
                                              },
                                            });
                                          }
                                        },
                                      },
                                    });
                                  }}
                                >
                                  Apply Changes
                                </Button>
                              </Col>
                            </Row>

                            {ModifyInventorySRP ? (
                              <Row className="my-3" noGutters>
                                <Col
                                  lg={4}
                                  md={4}
                                  sm={12}
                                  xs={12}
                                  className="pl-2 pr-2 mb-3"
                                >
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      backgroundColor: TxtInvItemSRP
                                        ? "var(--REB-primary)"
                                        : "var(--REB-warning)",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <p
                                      className="m-0 pl-2"
                                      style={{
                                        color: "var(--REB-white)",
                                        fontWeight: "bold",

                                        // backgroundColor: "var(--REB-primary)",
                                      }}
                                    >
                                      Item Inventory SRP
                                    </p>
                                    <FormControl
                                      as={NumericFormat}
                                      inputMode="numeric"
                                      thousandSeparator={true}
                                      prefix={"₱ "}
                                      style={{}}
                                      placeholder={`Current SRP: ₱ ${props.state.selectedRowVerificationTableContent.item.price}`}
                                      allowNegative={false}
                                      value={TxtInvItemSRP}
                                      fixedDecimalScale
                                      decimalScale={2}
                                      onValueChange={(x) => {
                                        setTxtInvItemSRP(Number(x.value));
                                      }}
                                      className=""
                                    />
                                  </div>
                                </Col>
                                <Col
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  lg={4}
                                  className="px-2 mb-3"
                                >
                                  <Button
                                    block
                                    //disabled={!props.state.handleInvoiceModal.isView}
                                    className="h-100"
                                    style={{
                                      backgroundColor: "var(--REB-yellow)",
                                      color: "var(--REB-black)",
                                    }}
                                    onClick={(e) => {
                                      setModifyInventorySRP(false);
                                    }}
                                  >
                                    Cancel Edit SRP
                                  </Button>
                                </Col>
                                <Col
                                  lg={4}
                                  md={4}
                                  sm={12}
                                  xs={12}
                                  className="pl-2 pr-2 mb-3 "
                                >
                                  <Button
                                    block
                                    //disabled={!props.state.handleInvoiceModal.isView}
                                    className="h-100"
                                    style={{
                                      backgroundColor: "var(--REB-green)",
                                    }}
                                    onClick={(e) => {
                                      props.state.dispatch({
                                        type: "MODAL_SHOW",
                                        payload: {
                                          title: "SRP Change Confirmation",
                                          JsxDesc: () => {
                                            return (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    fontWeight: "bolder",
                                                    textAlign: "justify",
                                                    lineHeight: "1.2rem",
                                                  }}
                                                >
                                                  All NEW Invoices will use this
                                                  new SRP when applied.
                                                </p>
                                                <p
                                                  style={{
                                                    fontWeight: "bolder",
                                                    textAlign: "justify",
                                                    lineHeight: "1.2rem",
                                                  }}
                                                >
                                                  Prices of this specific item
                                                  inside existing invoice wont
                                                  be affected. If you want to
                                                  modify that invoice to reflect
                                                  this SRP change, you will need
                                                  to manually edit the specified
                                                  item inside that invoice.
                                                </p>
                                              </div>
                                            );
                                          },
                                          // desc: `Are you sure you want to Apply this changes?`,
                                          isYesNo: true,
                                          cb: () => {
                                            if (
                                              TxtInvItemSRP !== null &&
                                              TxtInvItemSRP !== ""
                                            ) {
                                              modifyInventorySRP();
                                            } else {
                                              dispatch({
                                                type: "MODAL_SHOW",
                                                payload: {
                                                  title:
                                                    "No  Item Inventory SRP Provided",
                                                  desc: `Item Inventory SRP cant be empty.`,
                                                  isYesNo: false,
                                                },
                                              });
                                            }
                                          },
                                        },
                                      });
                                    }}
                                  >
                                    Apply SRP Change to Inventory
                                  </Button>
                                </Col>
                              </Row>
                            ) : (
                              <Row className="my-3" noGutters>
                                <Col xs={0} sm={6} md={8} lg={10}></Col>
                                <Col
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  className="px-2"
                                >
                                  <Button
                                    block
                                    //disabled={!props.state.handleInvoiceModal.isView}
                                    className="h-100"
                                    style={{
                                      backgroundColor: "var(--REB-green)",
                                    }}
                                    onClick={(e) => {
                                      dispatch({
                                        type: "MODAL_SHOW",
                                        payload: {
                                          title: "Confirmation",
                                          desc: `This option is for marking up and marking down item inventory SRP only. are you sure you want to modify item inventory SRP?`,
                                          isYesNo: true,
                                          cb: () => {
                                            setModifyInventorySRP(true);
                                          },
                                        },
                                      });
                                    }}
                                  >
                                    Modify Item Inventory SRP
                                  </Button>
                                </Col>
                              </Row>
                            )}
                            {ModifyInventoryCOST ? (
                              <Row className="my-3" noGutters>
                                <Col
                                  lg={4}
                                  md={4}
                                  sm={12}
                                  xs={12}
                                  className="pl-2 pr-2 mb-3"
                                >
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      backgroundColor: TxtInvItemCOST
                                        ? "var(--REB-primary)"
                                        : "var(--REB-warning)",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <p
                                      className="m-0 pl-2"
                                      style={{
                                        color: "var(--REB-white)",
                                        fontWeight: "bold",

                                        // backgroundColor: "var(--REB-primary)",
                                      }}
                                    >
                                      Item Inventory COST
                                    </p>
                                    <FormControl
                                      as={NumericFormat}
                                      inputMode="numeric"
                                      thousandSeparator={true}
                                      prefix={"₱ "}
                                      style={{}}
                                      placeholder={`Current COST: ₱ ${props.state.selectedRowVerificationTableContent.item.cost}`}
                                      allowNegative={false}
                                      value={TxtInvItemCOST}
                                      fixedDecimalScale
                                      decimalScale={2}
                                      onValueChange={(x) => {
                                        setTxtInvItemCOST(Number(x.value));
                                      }}
                                      className=""
                                    />
                                  </div>
                                </Col>
                                <Col
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  lg={4}
                                  className="px-2 mb-3"
                                >
                                  <Button
                                    block
                                    //disabled={!props.state.handleInvoiceModal.isView}
                                    className="h-100"
                                    style={{
                                      backgroundColor: "var(--REB-yellow)",
                                      color: "var(--REB-black)",
                                    }}
                                    onClick={(e) => {
                                      setModifyInventoryCOST(false);
                                    }}
                                  >
                                    Cancel Edit COST
                                  </Button>
                                </Col>
                                <Col
                                  lg={4}
                                  md={4}
                                  sm={12}
                                  xs={12}
                                  className="pl-2 pr-2 mb-3 "
                                >
                                  <Button
                                    block
                                    //disabled={!props.state.handleInvoiceModal.isView}
                                    className="h-100"
                                    style={{
                                      backgroundColor: "var(--REB-green)",
                                    }}
                                    onClick={(e) => {
                                      props.state.dispatch({
                                        type: "MODAL_SHOW",
                                        payload: {
                                          title: "COST Change Confirmation",
                                          JsxDesc: () => {
                                            return (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    fontWeight: "bolder",
                                                    textAlign: "justify",
                                                    lineHeight: "1.2rem",
                                                  }}
                                                >
                                                  All NEW Invoices will use this
                                                  new COST when applied.
                                                </p>
                                                <p
                                                  style={{
                                                    fontWeight: "bolder",
                                                    textAlign: "justify",
                                                    lineHeight: "1.2rem",
                                                  }}
                                                >
                                                  COST of this specific item
                                                  inside existing invoice wont
                                                  be affected. If you want to
                                                  modify that invoice to reflect
                                                  this COST change, you will
                                                  need to manually edit the
                                                  specified item inside that
                                                  invoice.
                                                </p>
                                              </div>
                                            );
                                          },
                                          // desc: `Are you sure you want to Apply this changes?`,
                                          isYesNo: true,
                                          cb: () => {
                                            if (
                                              TxtInvItemCOST !== null &&
                                              TxtInvItemCOST !== ""
                                            ) {
                                              modifyInventoryCOST();
                                            } else {
                                              dispatch({
                                                type: "MODAL_SHOW",
                                                payload: {
                                                  title:
                                                    "No  Item Inventory COST Provided",
                                                  desc: `Item Inventory COST cant be empty.`,
                                                  isYesNo: false,
                                                },
                                              });
                                            }
                                          },
                                        },
                                      });
                                    }}
                                  >
                                    Apply COST Change to Inventory
                                  </Button>
                                </Col>
                              </Row>
                            ) : (
                              <Row className="my-3" noGutters>
                                <Col xs={0} sm={6} md={8} lg={10}></Col>
                                <Col
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  className="px-2"
                                >
                                  <Button
                                    block
                                    //disabled={!props.state.handleInvoiceModal.isView}
                                    className="h-100"
                                    style={{
                                      backgroundColor: "var(--REB-green)",
                                    }}
                                    onClick={(e) => {
                                      dispatch({
                                        type: "MODAL_SHOW",
                                        payload: {
                                          title: "Confirmation",
                                          desc: `This option is for marking up and marking down item inventory COST only. are you sure you want to modify item inventory COST?`,
                                          isYesNo: true,
                                          cb: () => {
                                            setModifyInventoryCOST(true);
                                          },
                                        },
                                      });
                                    }}
                                  >
                                    Modify Item Inventory COST
                                  </Button>
                                </Col>
                              </Row>
                            )}
                          </>
                        )}

                        <Row className="h-10" noGutters>
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className="pl-2 pr-2 "
                          >
                            {props.tableContent}
                          </Col>
                        </Row>
                        <Row noGutters>
                          <Col className="d-flex justify-content-end pr-5 pt-4">
                            <h4
                              style={{
                                fontWeight: "bolder",
                                color: "var(--REB-green)",
                              }}
                            >
                              GRAND TOTAL:{" "}
                              <NumericFormat
                                value={props.state.grandTotal}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </h4>
                          </Col>
                        </Row>
                      </div>
                    </>
                  );
                }
                if (_e === "Transfer Inventory") {
                  return (
                    <>
                      <Row
                        className="mb-3"
                        style={{ backgroundColor: "var(--REB-blue)" }}
                        noGutters
                      >
                        <h4
                          className=" m-3"
                          style={{
                            fontWeight: "bolder",
                            color: "var(--REB-white)",
                          }}
                        >
                          TRANSFER INVENTORY
                        </h4>
                      </Row>
                      <Row noGutters>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtInvoiceName state={props.state} />
                        </Col>

                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtShippingAddress state={props.state} />
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtDateAdded state={props.state} />
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtDateLastModified state={props.state} />
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtDateDeadline state={props.state} />
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtSalesPerson state={props.state} />
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtInvFrom state={props.state} />
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="pl-2 pr-2"
                        >
                          <TxtInvTo state={props.state} />
                        </Col>

                        {/* <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className="pl-2 pr-2"
                          >
                            <TxtNotes state={props.state} />
                          </Col> */}
                      </Row>
                      <div
                        className="p-4"
                        style={{ backgroundColor: "var(--REB-body)" }}
                      >
                        <h4 className="pl-2" style={{ fontWeight: "bolder" }}>
                          TRANSFER CONTENT
                        </h4>
                        <Row className="h-10" noGutters>
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className="pl-2 pr-2 "
                          >
                            {props.tableContent}
                          </Col>
                        </Row>
                      </div>
                    </>
                  );
                }
              } else {
                if (SelectedRow.orderType) {
                  //console.log(SelectedRow.orderType);
                  const _e = SelectedRow.orderType.name;
                  if (_e === "Item Order Request") {
                    return (
                      <>
                        <Row
                          className="mb-3"
                          style={{ backgroundColor: "var(--REB-blue)" }}
                          noGutters
                        >
                          <h4
                            className=" m-3"
                            style={{
                              fontWeight: "bolder",
                              color: "var(--REB-white)",
                            }}
                          >
                            Item Order Request
                          </h4>
                        </Row>
                        <Row noGutters>
                          <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            className="pl-2 pr-2"
                          >
                            <TxtInvoiceName state={props.state} />
                          </Col>

                          <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            className="pl-2 pr-2"
                          >
                            <TxtDateAdded state={props.state} />
                          </Col>

                          <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            className="pl-2 pr-2"
                          >
                            <TxtSalesPerson state={props.state} />
                          </Col>

                          {/* <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              className="pl-2 pr-2"
                            >
                              <TxtNotes state={props.state} />
                            </Col> */}
                        </Row>
                      </>
                    );
                  }
                  if (_e === "Add Item Qty Request") {
                    return (
                      <>
                        <Row
                          className="mb-3"
                          style={{ backgroundColor: "var(--REB-blue)" }}
                          noGutters
                        >
                          <h4
                            className=" m-3"
                            style={{
                              fontWeight: "bolder",
                              color: "var(--REB-white)",
                            }}
                          >
                            Add Item Qty Request
                          </h4>
                        </Row>
                        <Row noGutters>
                          <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            className="pl-2 pr-2"
                          >
                            <TxtInvoiceName state={props.state} />
                          </Col>

                          <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            className="pl-2 pr-2"
                          >
                            <TxtDateAdded state={props.state} />
                          </Col>

                          <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            className="pl-2 pr-2"
                          >
                            <TxtSalesPerson state={props.state} />
                          </Col>

                          {/* <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              className="pl-2 pr-2"
                            >
                              <TxtNotes state={props.state} />
                            </Col> */}
                        </Row>
                      </>
                    );
                  }
                  if (_e === "Return Item Request") {
                    return (
                      <>
                        <Row
                          className="mb-3"
                          style={{ backgroundColor: "var(--REB-blue)" }}
                          noGutters
                        >
                          <h4
                            className=" m-3"
                            style={{
                              fontWeight: "bolder",
                              color: "var(--REB-white)",
                            }}
                          >
                            Return Item Request
                          </h4>
                        </Row>
                        <Row noGutters>
                          <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            className="pl-2 pr-2"
                          >
                            <TxtInvoiceName state={props.state} />
                          </Col>

                          <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            className="pl-2 pr-2"
                          >
                            <TxtDateAdded state={props.state} />
                          </Col>

                          <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            className="pl-2 pr-2"
                          >
                            <TxtSalesPerson state={props.state} />
                          </Col>

                          {/* <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              className="pl-2 pr-2"
                            >
                              <TxtNotes state={props.state} />
                            </Col> */}
                        </Row>
                      </>
                    );
                  }
                  if (_e === "Deduct Item Qty Request") {
                    return (
                      <>
                        <Row
                          className="mb-3"
                          style={{ backgroundColor: "var(--REB-blue)" }}
                          noGutters
                        >
                          <h4
                            className=" m-3"
                            style={{
                              fontWeight: "bolder",
                              color: "var(--REB-white)",
                            }}
                          >
                            Deduct Item Qty Request
                          </h4>
                        </Row>
                        <Row noGutters>
                          <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            className="pl-2 pr-2"
                          >
                            <TxtInvoiceName state={props.state} />
                          </Col>

                          <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            className="pl-2 pr-2"
                          >
                            <TxtDateAdded state={props.state} />
                          </Col>

                          <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            className="pl-2 pr-2"
                          >
                            <TxtSalesPerson state={props.state} />
                          </Col>

                          {/* <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              className="pl-2 pr-2"
                            >
                              <TxtNotes state={props.state} />
                            </Col> */}
                        </Row>
                      </>
                    );
                  }
                }
                if (
                  SelectedRow?.generalTransType === "transaction" ||
                  SelectedRow?.generalTransType === "journal" ||
                  SelectedRow?.verificationType === "INVOICE_ARCHIVE"
                ) {
                  return (
                    <>
                      <FileViewer
                        PDFData={BLOB_TO_PDF_DISPLAY}
                        PDFFileName={props.state.selectedRowVerification?.name}
                      />
                    </>
                  );
                }
                if (
                  SelectedRow?.courierId &&
                  SelectedRow?.logiStatus === "pending-admin-approval"
                ) {
                  //console.log(SelectedRow)
                  return (
                    <>
                      <Row className="mt-3" noGutters>
                        <Col xs={12} sm={6} md={4} lg={3} className="px-1 mb-2">
                          <div
                            className=""
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <p
                              className="m-0 pl-2"
                              style={{
                                backgroundColor: "var(--REB-white)",
                                color: "var(--REB-black)",
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                width: "230px",
                                borderRadius: "5px 5px 0px 0px",
                                WebkitBoxShadow:
                                  "inset 0px 0px 0px 1px #ced4da",
                                MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                                boxShadow: "inset 0px 0px 0px 1px #ced4da",
                              }}
                            >
                              Unique ID
                            </p>
                            <FormControl
                              placeholder="Unique ID"
                              className="h-100"
                              style={{ borderRadius: "0px 5px 5px 5px" }}
                              value={
                                SelectedRow?.uniqueId
                                  ? SelectedRow?.uniqueId
                                  : "-"
                              }
                              disabled
                            />
                          </div>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} className="px-1 mb-2">
                          <div
                            className=""
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <p
                              className="m-0 pl-2"
                              style={{
                                backgroundColor: "var(--REB-white)",
                                color: "var(--REB-black)",
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                width: "230px",
                                borderRadius: "5px 5px 0px 0px",
                                WebkitBoxShadow:
                                  "inset 0px 0px 0px 1px #ced4da",
                                MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                                boxShadow: "inset 0px 0px 0px 1px #ced4da",
                              }}
                            >
                              Date Added
                            </p>
                            <FormControl
                              placeholder="Date Added"
                              className="h-100"
                              style={{ borderRadius: "0px 5px 5px 5px" }}
                              value={
                                SelectedRow?.dateAdded
                                  ? moment(SelectedRow?.dateAdded).format("lll")
                                  : "-"
                              }
                              disabled
                            />
                          </div>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} className="px-1 mb-2">
                          <div
                            className=""
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <p
                              className="m-0 pl-2"
                              style={{
                                backgroundColor: "var(--REB-white)",
                                color: "var(--REB-black)",
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                width: "230px",
                                borderRadius: "5px 5px 0px 0px",
                                WebkitBoxShadow:
                                  "inset 0px 0px 0px 1px #ced4da",
                                MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                                boxShadow: "inset 0px 0px 0px 1px #ced4da",
                              }}
                            >
                              Date Modified
                            </p>
                            <FormControl
                              placeholder="Date Modified"
                              className="h-100"
                              style={{ borderRadius: "0px 5px 5px 5px" }}
                              value={
                                SelectedRow?.TxtDateLastModified
                                  ? moment(
                                      SelectedRow?.TxtDateLastModified
                                    ).format("lll")
                                  : "-"
                              }
                              disabled
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col xs={12} sm={6} md={6} lg={6} className="px-1 mb-2">
                          <div
                            className=""
                            style={{
                              display: "flex",
                              flexDirection: "column",

                              // border: "1px solid #ced4da",
                            }}
                          >
                            <p
                              className="m-0 pl-2"
                              style={{
                                backgroundColor: "var(--REB-white)",
                                color: "var(--REB-black)",
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                width: "230px",
                                borderRadius: "5px 5px 0px 0px",
                                WebkitBoxShadow:
                                  "inset 0px 0px 0px 1px #ced4da",
                                MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                                boxShadow: "inset 0px 0px 0px 1px #ced4da",
                              }}
                            >
                              Added By
                            </p>
                            <FormControl
                              placeholder="Added By"
                              className="h-100"
                              style={{ borderRadius: "0px 5px 5px 5px" }}
                              value={`${
                                SelectedRow?.createdBy?.lastName
                                  ? SelectedRow?.createdBy?.lastName
                                  : ""
                              }, ${
                                SelectedRow?.createdBy?.firstName
                                  ? SelectedRow?.createdBy?.firstName
                                  : ""
                              }`}
                              disabled
                            />
                          </div>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} className="px-1 mb-2">
                          <div
                            className=""
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <p
                              className="m-0 pl-2"
                              style={{
                                backgroundColor: "var(--REB-white)",
                                color: "var(--REB-black)",
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                width: "230px",
                                borderRadius: "5px 5px 0px 0px",
                                WebkitBoxShadow:
                                  "inset 0px 0px 0px 1px #ced4da",
                                MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                                boxShadow: "inset 0px 0px 0px 1px #ced4da",
                              }}
                            >
                              Modified By
                            </p>
                            <FormControl
                              placeholder="Modified By"
                              className="h-100"
                              style={{ borderRadius: "0px 5px 5px 5px" }}
                              value={`${
                                SelectedRow?.lastModifiedByActor?.lastName
                                  ? SelectedRow?.lastModifiedByActor?.lastName
                                  : ""
                              }, ${
                                SelectedRow?.lastModifiedByActor?.firstName
                                  ? SelectedRow?.lastModifiedByActor?.firstName
                                  : ""
                              }`}
                              disabled
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col xs={4} className="px-1 mb-2">
                          <div
                            className=""
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <p
                              className="m-0 pl-2"
                              style={{
                                backgroundColor: true
                                  ? "var(--REB-primary)"
                                  : "var(--REB-warning)",
                                color: "var(--REB-white)",
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                width: "230px",
                                borderRadius: "5px 5px 0px 0px",
                              }}
                            >
                              Courier
                            </p>
                            <FormControl
                              placeholder="Modified By"
                              className="h-100"
                              style={{ borderRadius: "0px 5px 5px 5px" }}
                              value={
                                SelectedRow?.courier
                                  ? SelectedRow?.courier?.company
                                  : "-"
                              }
                              disabled
                            />
                          </div>
                        </Col>
                        <Col xs={4} className="px-1 mb-2">
                          <div
                            className=""
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <p
                              className="m-0 pl-2"
                              style={{
                                backgroundColor: true
                                  ? "var(--REB-primary)"
                                  : "var(--REB-warning)",
                                color: "var(--REB-white)",
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                width: "230px",
                                borderRadius: "5px 5px 0px 0px",
                              }}
                            >
                              Courier Contact Person
                            </p>
                            <FormControl
                              type="text"
                              style={{ borderRadius: "0px 5px 5px 5px" }}
                              placeholder="Courier Contact Person"
                              value={
                                SelectedRow?.courierContactPerson
                                  ? SelectedRow?.courierContactPerson
                                  : "-"
                              }
                              disabled
                              className=""
                            />
                          </div>
                        </Col>
                        <Col xs={4} className="px-1 mb-2">
                          <div
                            className=""
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <p
                              className="m-0 pl-2"
                              style={{
                                backgroundColor: true
                                  ? "var(--REB-primary)"
                                  : "var(--REB-warning)",
                                color: "var(--REB-white)",
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                width: "230px",
                                borderRadius: "5px 5px 0px 0px",
                              }}
                            >
                              Courier Contact Person #
                            </p>
                            <FormControl
                              type="text"
                              style={{ borderRadius: "0px 5px 5px 5px" }}
                              placeholder=" Courier Contact Person #"
                              value={
                                SelectedRow?.courierContactNumber
                                  ? SelectedRow?.courierContactNumber
                                  : "-"
                              }
                              disabled
                              className=""
                            />
                          </div>
                        </Col>
                        <Col xs={4} className="px-1 mb-2">
                          <div
                            className=""
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <p
                              className="m-0 pl-2"
                              style={{
                                backgroundColor: true
                                  ? "var(--REB-primary)"
                                  : "var(--REB-warning)",
                                color: "var(--REB-white)",
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                width: "230px",
                                borderRadius: "5px 5px 0px 0px",
                              }}
                            >
                              Supplier Contact Person
                            </p>
                            <FormControl
                              type="text"
                              style={{ borderRadius: "0px 5px 5px 5px" }}
                              placeholder="Supplier Contact Person"
                              value={
                                SelectedRow?.supplierContactPerson
                                  ? SelectedRow?.supplierContactPerson
                                  : "-"
                              }
                              disabled
                              className=""
                            />
                          </div>
                        </Col>
                        <Col xs={4} className="px-1 mb-2">
                          <div
                            className=""
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <p
                              className="m-0 pl-2"
                              style={{
                                backgroundColor: true
                                  ? "var(--REB-primary)"
                                  : "var(--REB-warning)",
                                color: "var(--REB-white)",
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                width: "230px",
                                borderRadius: "5px 5px 0px 0px",
                              }}
                            >
                              Supplier Contact Person #
                            </p>
                            <FormControl
                              type="text"
                              style={{ borderRadius: "0px 5px 5px 5px" }}
                              placeholder="Supplier Contact Person #"
                              value={
                                SelectedRow?.supplierContactNumber
                                  ? SelectedRow?.supplierContactNumber
                                  : "-"
                              }
                              disabled
                              className=""
                            />
                          </div>
                        </Col>
                        <Col xs={4} className="px-1 mb-2">
                          <div
                            className=""
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <p
                              className="m-0 pl-2"
                              style={{
                                backgroundColor: true
                                  ? "var(--REB-primary)"
                                  : "var(--REB-warning)",
                                color: "var(--REB-white)",
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                width: "230px",
                                borderRadius: "5px 5px 0px 0px",
                              }}
                            >
                              Pickup Address
                            </p>
                            <FormControl
                              type="text"
                              style={{ borderRadius: "0px 5px 5px 5px" }}
                              placeholder="Pickup Address"
                              value={
                                SelectedRow?.pickupAddress
                                  ? SelectedRow?.pickupAddress
                                  : "-"
                              }
                              disabled
                              className=""
                            />
                          </div>
                        </Col>
                        <Col xs={4} className="px-1  mb-2">
                          <div
                            className=""
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <p
                              className="m-0 pl-2"
                              style={{
                                backgroundColor: true
                                  ? "var(--REB-primary)"
                                  : "var(--REB-warning)",
                                color: "var(--REB-white)",
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                width: "230px",
                                borderRadius: "5px 5px 0px 0px",
                              }}
                            >
                              Estimated Arrival Date
                            </p>
                            <FormControl
                              type="date"
                              style={{ borderRadius: "0px 5px 5px 5px" }}
                              placeholder="Estimated Delivery Date"
                              value={
                                SelectedRow?.eta
                                  ? moment(SelectedRow?.eta).format("lll")
                                  : "-"
                              }
                              disabled
                              className=""
                            />
                          </div>
                        </Col>
                        <Col xs={4} className="px-1  mb-2">
                          <div
                            className=""
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <p
                              className="m-0 pl-2"
                              style={{
                                backgroundColor: true
                                  ? "var(--REB-primary)"
                                  : "var(--REB-warning)",
                                color: "var(--REB-white)",
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                width: "230px",
                                borderRadius: "5px 5px 0px 0px",
                              }}
                            >
                              Pickup Date
                            </p>
                            <FormControl
                              type="date"
                              style={{ borderRadius: "0px 5px 5px 5px" }}
                              placeholder="  Pickup Date"
                              value={
                                SelectedRow?.pickupDate
                                  ? moment(SelectedRow?.pickupDate).format(
                                      "lll"
                                    )
                                  : "-"
                              }
                              disabled
                              className=""
                            />
                          </div>
                        </Col>
                        <Col xs={4} className="px-1  mb-2"></Col>
                        <Col xs={4} className="px-1  mb-2">
                          <div
                            className=""
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <p
                              className="m-0 pl-2"
                              style={{
                                backgroundColor: true
                                  ? "var(--REB-primary)"
                                  : "var(--REB-warning)",
                                color: "var(--REB-white)",
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                width: "230px",
                                borderRadius: "5px 5px 0px 0px",
                              }}
                            >
                              Container Description
                            </p>
                            <FormControl
                              type="text"
                              style={{ borderRadius: "0px 5px 5px 5px" }}
                              placeholder=" Container Description"
                              value={
                                SelectedRow?.containerDesc
                                  ? SelectedRow?.containerDesc
                                  : "-"
                              }
                              disabled
                              className=""
                            />
                          </div>
                        </Col>

                        <Col xs={4} className="px-1  mb-2">
                          <div
                            className=""
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <p
                              className="m-0 pl-2"
                              style={{
                                backgroundColor: true
                                  ? "var(--REB-primary)"
                                  : "var(--REB-warning)",
                                color: "var(--REB-white)",
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                width: "230px",
                                borderRadius: "5px 5px 0px 0px",
                              }}
                            >
                              Container Size
                            </p>
                            <FormControl
                              as={NumericFormat}
                              inputMode="numeric"
                              style={{ borderRadius: "0px 5px 5px 5px" }}
                              placeholder="Container Size"
                              thousandSeparator={true}
                              suffix={` ${
                                SelectedRow?.containerUnit
                                  ? SelectedRow?.containerUnit?.name
                                  : ""
                              }`}
                              allowNegative={false}
                              value={
                                SelectedRow?.containerSize
                                  ? SelectedRow?.containerSize
                                  : 0
                              }
                              disabled
                            />
                          </div>
                        </Col>

                        {/* <Col xs={6} className="px-1  mb-2">
                            <TxtNotes state={props.state} />
                          </Col> */}
                      </Row>
                      <div>
                        <Row
                          style={{ border: "solid 1px #ced4da" }}
                          className=""
                          noGutters
                        >
                          <Col xs={12} sm={6} md={6} lg={6}>
                            <p
                              className="m-0 p-0 voucherContentHeaderP"
                              style={{ textAlign: "center" }}
                            >
                              Item Name
                            </p>
                          </Col>

                          <Col xs={12} sm={6} md={4} lg={4}>
                            <p
                              className="m-0 p-0 voucherContentHeaderP"
                              style={{ textAlign: "center" }}
                            >
                              Qty Expected
                            </p>
                          </Col>
                        </Row>
                      </div>
                      {SelectedRow?.content &&
                        SelectedRow?.content?.map((val) => {
                          const UNIT = val?.item?.details.find(
                            (x) => x.type === 2
                          ).name;
                          const ITEM_NAME = val?.item?.details.find(
                            (x) => x.type === 1
                          ).name;
                          const BRAND = val?.item?.details.find(
                            (x) => x.type === 3
                          ).name;
                          let image = [];
                          if (val?.itemId !== null) {
                            if (
                              val?.item?.uploads !== null ||
                              val?.item?.files !== null
                            ) {
                              if (val?.item?.files?.constructor === String)
                                image = JSON.parse(val?.item?.files);
                              else {
                                image = val?.item?.uploads;
                              }
                              //console.log(image);
                            }
                          }
                          return (
                            <Row
                              style={{ border: "solid 1px #ced4da" }}
                              className="py-2"
                              key={val.uniqueId}
                              noGutters
                            >
                              <Col xs={12} sm={6} md={6} lg={6}>
                                <Row noGutters>
                                  <Col xs={12} sm={12} md={2} lg={2}>
                                    <img
                                      style={{
                                        objectFit: "contain",
                                        maxWidth: "100px",
                                        height: "100px",
                                        maxHeight: "100px",
                                      }}
                                      alt=""
                                      src={
                                        typeof image !== "undefined" &&
                                        image !== null &&
                                        image.length > 0 &&
                                        image !== "null"
                                          ? `${
                                              process.env.REACT_APP_API_URL
                                            }global/getFile2?uniqueId=${
                                              val.item?.uniqueId
                                            }&filename=${encodeURIComponent(
                                              image[0].filename
                                            )}&token=${localStorage.getItem(
                                              "LUT"
                                            )}`
                                          : emptyImg
                                      }
                                    />
                                  </Col>
                                  <Col xs={12} sm={12} md={10} lg={10}>
                                    <p className="m-0 p-0 " style={{}}>
                                      {ITEM_NAME}
                                    </p>
                                    <p className="m-0 p-0 " style={{}}>
                                      {val?.item?.description}
                                    </p>
                                    <p className="m-0 p-0 " style={{}}>
                                      {BRAND}
                                    </p>
                                  </Col>
                                </Row>
                              </Col>

                              <Col xs={12} sm={6} md={4} lg={4}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    wordWrap: "break-word",
                                    whiteSpace: "normal",
                                    fontSize: "1.5em",
                                    height: "100%",
                                    color: "var(--REB-blue)",
                                    fontWeight: "bold",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <FormControl
                                      as={NumericFormat}
                                      inputMode="numeric"
                                      type="text"
                                      placeholder="QTY Expected"
                                      thousandSeparator={true}
                                      suffix={` ${UNIT}`}
                                      allowNegative={false}
                                      value={val.qty}
                                      style={{ textAlign: "center" }}
                                      disabled
                                    />
                                    {/* <Button
                      onClick={() => {
                        TempModifyLogisContent({
                          id: val.id,
                          qtyDelivered: Number(val.qtyDelivered) + 1,
                        });
                      }}
                      style={{
                        backgroundColor: "var(--REB-green)",
                      }}
                      className="mx-1"
                    >
                      <i
                        style={{ color: "var(--REB-white)" }}
                        className="icofont-ui-add"
                      ></i>
                    </Button> */}
                                  </div>

                                  <p
                                    style={{
                                      marginBottom: "0px",
                                      fontWeight: "normal",
                                      flex: 1,
                                    }}
                                  >
                                    {UNIT}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          );
                        })}
                    </>
                  );
                }
              }
            }
          })()}

          {/* <PDFViewer className="pdfViewer">{props.state.pdfContent}</PDFViewer> */}
        </div>
        <Form.Group
          as={Row}
          className="mb-3 mt-3 actor-modal-footer-row actor-modal-footer"
          noGutters
        >
          {/* <Col md={6} lg={6} className="">
            <p className="font-weight-bold">Note:</p>
            <p className="">
              Fields that has asterisk (
              <span className="required-field font-weight-bold"> * </span>)
              requires an input.
            </p>
          </Col> */}

          <Col xs={12} className="mx-1">
            <TxtNotes state={props.state} />
          </Col>
          <Col
            md={3}
            lg={3}
            sm={12}
            xs={12}
            className="ml-1 mr-1 verification-modal-footer-buttons"
          >
            <Button
              block
              name="btnApprove"
              //disabled={!props.state.handleInvoiceModal.isView}
              style={{ backgroundColor: "var(--REB-green)" }}
              onClick={(e) => {
                props.state.dispatch({
                  type: "MODAL_SHOW",
                  payload: {
                    title: "Verification Confirmation",
                    desc: `Are you sure you want to APPROVE this?`,
                    isYesNo: true,
                    cb: () => {
                      props.state.handleActionChange(e);
                    },
                  },
                });
              }}
            >
              APPROVE
            </Button>
          </Col>
          <Col
            md={3}
            lg={3}
            sm={12}
            xs={12}
            className="ml-1 mr-1 verification-modal-footer-buttons"
          >
            <Button
              block
              name="btnHold"
              //disabled={!props.state.handleInvoiceModal.isView}
              style={{
                backgroundColor: "var(--REB-yellow)",
                color: "var(--REB-black)",
              }}
              onClick={(e) => {
                let TxtHoldDate = moment().add(3, "days").format("YYYY-MM-DD");
                props.state.dispatch({
                  type: "MODAL_SHOW",
                  payload: {
                    title: "Verification Confirmation",
                    JsxDesc: () => {
                      const [TempTxtHoldDate, setTempTxtHoldDate] =
                        useState(TxtHoldDate);

                      return (
                        <>
                          <div
                            style={{
                              // backgroundColor: "var(--REB-primary)",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                            }}
                            // hidden={props.state.chkDispImage.readOnly}
                          >
                            <p>Hold Verification Until</p>

                            <FormControl
                              type="date"
                              //placeholder={props.state.txtDeadline.placeholder}
                              placeholder="Date"
                              // onMouseOver={(e) => (e.currentTarget.type = "date")}
                              // onMouseOut={(e) => (e.currentTarget.type = "text")}

                              value={TempTxtHoldDate}
                              onChange={(x) => {
                                setTempTxtHoldDate(x.target.value);
                                //console.log(moment(x.target.value).format('YYYY-MM-DD'))
                                TxtHoldDate = moment(x.target.value).format(
                                  "YYYY-MM-DD"
                                );
                              }}
                              className="mt-3"
                            />
                          </div>
                        </>
                      );
                    },
                    isYesNo: true,
                    yesLbl: "Submit",
                    noLbl: "Cancel",
                    cb: () => {
                      const holdDays = moment(TxtHoldDate).diff(
                        moment(),
                        "days"
                      );
                      //console.log(holdDays,TxtHoldDate)
                      if (TxtHoldDate && holdDays > 0) {
                        props.state.handleActionChange({
                          target: {
                            name: "btnHoldInvoice",
                            value: TxtHoldDate,
                          },
                        });
                      } else {
                        props.state.dispatch({
                          type: "MODAL_SHOW",
                          payload: {
                            title: "Invalid Date",
                            desc: `Hold date should be greater than invoice date.`,
                            isYesNo: false,
                            cb: () => {
                              props.state.handleActionChange(e);
                            },
                          },
                        });
                      }

                      // props.state.handleActionChange(e);
                    },
                  },
                });
              }}
            >
              HOLD
            </Button>
          </Col>
          <Col
            md={3}
            lg={3}
            sm={12}
            xs={12}
            className="ml-1 mr-1 verification-modal-footer-buttons"
          >
            <Button
              block
              name="btnDeny"
              //disabled={props.state.handleInvoiceModal.isView}
              style={{ backgroundColor: "var(--REB-primary)" }}
              onClick={(e) => {
                props.state.dispatch({
                  type: "MODAL_SHOW",
                  payload: {
                    title: "Verification Confirmation",
                    desc: `Are you sure you want to DENY this?`,
                    isYesNo: true,
                    cb: () => {
                      props.state.handleActionChange(e);
                    },
                  },
                });
              }}
            >
              DENY
            </Button>
          </Col>
        </Form.Group>
        {/* </Form> */}
      </Modal.Body>
    </Modal>
  );
};

export { VerificationModal };
