import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import moment from "moment";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import InchargeSelect from "../../components/CustomDrop";
import StatusSelect from "../../components/CustomDrop";
import SupplierSelect from "../../components/CustomDrop";
import { NumericFormat } from "react-number-format";
import misc from "../../functions/misc";
import Select from "react-select";
import filters from "../../functions/filters";
import TransactionsAPI from "../Transactions/TransactionsAPI";
import InvoiceAPI from "./InvoiceAPI";
import Voucher from "../Transactions/PDF/Voucher";
import emptyImg from "../../assets/fork-lift.svg";
import CourierSelect from "../../components/CustomDrop";
import UnitSelect from "../../components/CustomDrop";
import actorApi from "../Actor/ActorAPI";
import { SELECTED_OPERATION } from "../../constants";
import InventoryAPI from "../Inventory/InventoryAPI";
import LogisticsAPI from "./LogisticsAPI";
import Badge from "react-bootstrap/Badge";
import Logistics from "./PDF/Logistics";
import invoiceApi from "../Invoice/InvoiceAPI";
function PRItemHistoryTableColumn(user) {
  let userType = [];
  if (user) {
    userType = userType.concat(user?.types);
  }

  return [
    // {
    //   Header: "Item Name",
    //   id: "name",
    //   Cell: (row) => {
    //     const d = row.original.item;
    //     let name = "-";
    //     if (d.details.length > 0) {
    //       const sDat = d.details.find((x) => x.type === 1);
    //       sDat !== undefined ? (name = sDat.name) : (name = "-");
    //     }
    //     return (
    //       <div
    //         style={{
    //           display: "block",
    //           wordWrap: "break-word",
    //           whiteSpace: "normal",
    //         }}
    //       >
    //         {name}
    //       </div>
    //     );
    //   },
    //   accessor: (d) => {
    //     let name = "-";
    //     if (d.item.details.length > 0) {
    //       const sDat = d.item.details.find((x) => x.type === 1);
    //       sDat !== undefined ? (name = sDat.name) : (name = "-");
    //     }

    //     return name;
    //   },
    // },
    {
      Header: () => (
        <p
          style={{
            fontSize: "0.70em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
          }}
        >
          Order Date
        </p>
      ),
      id: "orderDate",
      filterable: false,

      //maxWidth: 80,
      // getProps: (row) => {
      //   return { style: { fontSize: "13px" } };
      // },
      Cell: (row) => {
        const d = row.original;
        let name =
          d.dateFinished === null
            ? "-"
            : misc.parseAPIDateTimeToShortDate(
                misc.parseAPIDateTime(d.dateFinished)
              );
        return (
          <div
            style={{
              display: "block",
              wordWrap: "break-word",
              whiteSpace: "normal",
            }}
          >
            <p
              style={{
                fontSize: "0.80em",

                color: "var(--REB-blue)",
                fontWeight: "bold",
              }}
            >
              {name}
            </p>
          </div>
        );
      },
      show: userType.includes("Manager") && false,
      accessor: (d) =>
        d.dateFinished === null
          ? "-"
          : misc.parseAPIDateTimeToShortDate(
              misc.parseAPIDateTime(d.dateFinished)
            ),
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "0.70em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
          }}
        >
          Handling
        </p>
      ),
      id: "handling",
      show: userType.includes("Manager") && false,
      accessor: (d) => (d.handling === null ? "-" : d.handling),
    },

    {
      Header: () => (
        <p
          style={{
            fontSize: "0.70em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
          }}
        >
          Shipping
        </p>
      ),
      id: "shipping",
      show: userType.includes("Manager") && false,
      accessor: (d) => (d.shipping === null ? "-" : d.shipping),
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "0.70em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
          }}
        >
          Cost
        </p>
      ),
      id: "cost",
      show: userType.includes("Manager") && false,
      accessor: (d) => (d.cost === null ? "-" : d.cost),
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "0.70em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
          }}
        >
          Cogs
        </p>
      ),
      id: "cogs",
      show: userType.includes("Manager") && false,
      accessor: (d) => (d.cogs === null ? "-" : d.cogs),
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "0.70em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
          }}
        >
          Qty Ordered
        </p>
      ),
      id: "qty",
      resizable: false,
      accessor: (d) => {
        let name = "-";

        if (d.itemId === null) {
          name = "Service";
        } else {
          if (d.item && d.item.details && d.item?.details?.length > 0) {
            const sDat = d.item?.details?.find((x) => x.type === 2);
            sDat !== undefined ? (name = sDat.name) : (name = "-");
          }
        }

        return `${d.qtyOrdered} ${name}`;
      },
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "0.70em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
          }}
        >
          Price
        </p>
      ),
      id: "price",
      accessor: (d) => (d.price === null ? "-" : d.price),
    },

    {
      Header: () => (
        <p
          style={{
            fontSize: "0.70em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
          }}
        >
          Amount
        </p>
      ),
      id: "amount",
      //maxWidth: 150,
      //maxWidth: 200,
      getHeaderProps: (row) => {
        return { style: { marginRight: "17px" } };
      },
      accessor: (d) => (d.count === null ? "-" : d.qtyOrdered * d.price),
    },
  ];
}

function PRItemColumn() {
  // const brand = (d) => {
  //   let name = "-";
  //   if (d.itemId === null) {
  //     name = "-";
  //   } else {
  //     if (d.item.details.length > 0) {
  //       const sDat = d.item.details.find((x) => x.type === 3);
  //       sDat !== undefined ? (name = sDat.name) : (name = "-");
  //     }
  //   }

  //   return name;
  // };

  return [
    {
      Header: () => (
        <p
          style={{
            fontSize: "0.70em",
            color: "var(--REB-primary)",
            fontWeight: "bolder",
            margin: 0,
          }}
        >
          Image
        </p>
      ),
      maxWidth: 110,
      filterable: false,
      //accessor: (d) => name(d),

      Cell: (row) => {
        const d = row.original;
        //console.log(d);
        let image = [];
        if (d) {
          if (d.itemId !== null) {
            if (d.item.uploads !== null && d.item.uploads !== "") {
              if (d.item.uploads.constructor === String)
                image = JSON.parse(d.item.uploads);
              else {
                image = d.item.uploads;
              }
              //console.log(image);
            }
          }
        }
        return (
          <>
            {((x) => {
              if (true) {
                return (
                  <Row noGutters>
                    <Col
                      style={{
                        textAlign: "-webkit-center",
                      }}
                    >
                      <img
                        style={{
                          objectFit: "contain",
                          maxWidth: "100px",
                          height: "100px",
                          maxHeight: "100px",
                        }}
                        alt=""
                        src={
                          typeof image !== "undefined" &&
                          image !== null &&
                          image.length > 0 &&
                          image !== "null"
                            ? `${
                                process.env.REACT_APP_API_URL
                              }global/getFile2?uniqueId=${
                                d.item.uniqueId
                              }&filename=${encodeURIComponent(
                                image[0].filename
                              )}&token=${localStorage.getItem("LUT")}`
                            : emptyImg
                        }
                      ></img>
                    </Col>
                  </Row>
                );
              } else {
                return <></>;
              }
            })()}
          </>
        );
      },
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "0.70em",
            color: "var(--REB-primary)",
            fontWeight: "bolder",
            margin: 0,
          }}
        >
          Item Name
        </p>
      ),
      id: "name",

      getProps: (row) => {
        return { style: { padding: 0, height: 110 } };
      },
      Cell: (row) => {
        const d = row.original;
        let name = "-";

        if (d.item.details.length > 0) {
          let nameDat = d.item.details.find((x) => x.type === 1);
          let brandDat = d.item.details.find((x) => x.type === 3);

          nameDat = nameDat !== null ? nameDat.name : "";
          brandDat = brandDat !== null ? brandDat.name : "";
          name = nameDat + " " + brandDat;
        }

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              wordWrap: "break-word",
              whiteSpace: "normal",
              fontSize: "0.85em",
              height: "100%",
              color: "var(--REB-blue)",
              fontWeight: "bold",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <p style={{ marginBottom: "0px", flex: 1 }}> {name}</p>

            <p style={{ marginBottom: "0px", fontWeight: "normal", flex: 1 }}>
              {/* {d.description} */}
            </p>
          </div>

          // <FormControl
          //   as="textarea"
          //   style={{ resize: "none", height: "100%" }}
          //   defaultValue={name}
          //   readOnly
          // />
        );
      },
      accessor: (d) => {
        let name = "-";
        if (d.item.details.length > 0) {
          let nameDat = d.item.details.find((x) => x.type === 1);
          let brandDat = d.item.details.find((x) => x.type === 3);

          nameDat = nameDat !== null ? nameDat.name : "";
          brandDat = brandDat !== null ? brandDat.name : "";
          name = nameDat + " " + brandDat;
        }

        return name;
      },
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "0.95em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Qty Req.
        </p>
      ),
      id: "qty",
      resizable: false,
      maxWidth: 110,
      getProps: (row) => {
        return { style: { padding: 0, height: "100%" } };
      },
      Cell: (row) => {
        let name = "-";
        const d = row.original;

        if (d.item.details.length > 0) {
          const sDat = d.item.details.find((x) => x.type === 2);
          sDat !== undefined ? (name = sDat.name) : (name = "-");
        }

        return (
          <Row
            style={{
              height: "100%",
              textAlign: "center",
              alignItems: "center",
            }}
            className=""
            noGutters
          >
            <Col style={{ fontWeight: "bolder" }}>
              {`${d.itemQty}`}
              <br /> {` ${name}`}
            </Col>
          </Row>
        );
      },
      accessor: (d) => {
        let name = "-";

        if (d.itemId === null) {
          name = "Service";
        } else {
          if (d.item.details.length > 0) {
            const sDat = d.item.details.find((x) => x.type === 2);
            sDat !== undefined ? (name = sDat.name) : (name = "-");
          }
        }

        return `${d.itemQty} ${name}`;
      },
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "0.95em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Qty ORDERED
        </p>
      ),
      id: "qtyOrdered",
      maxWidth: 110,
      getProps: (row) => {
        return { style: { padding: 0, height: "100%" } };
      },

      resizable: false,
      Cell: (row) => {
        let name = "-";
        const d = row.original;

        if (d.item.details.length > 0) {
          const sDat = d.item.details.find((x) => x.type === 2);
          sDat !== undefined ? (name = sDat.name) : (name = "-");
        }

        return (
          <Row
            style={{
              height: "100%",
              textAlign: "center",
              alignItems: "center",
            }}
            className=""
            noGutters
          >
            <Col style={{ fontWeight: "bolder" }}>
              {`${d.qtyOrdered}`}
              <br /> {` ${name}`}
            </Col>
          </Row>
        );
      },
      accessor: (d) => {
        let name = "-";

        if (d.itemId === null) {
          name = "Service";
        } else {
          if (d.item.details.length > 0) {
            const sDat = d.item.details.find((x) => x.type === 2);
            sDat !== undefined ? (name = sDat.name) : (name = "-");
          }
        }

        return `${d.qtyOrdered} ${name}`;
      },
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Req. By
        </p>
      ),

      getProps: (row) => {
        return { style: { padding: 0, height: "100%" } };
      },
      id: "reqby",
      resizable: false,
      maxWidth: 190,
      accessor: (d) =>
        d.order !== null
          ? d.order.incharge === null
            ? "-"
            : d.order.incharge
            ? d.order.incharge.name
            : "-"
          : d.requestedByActor
          ? d.requestedByActor.name
          : "-",
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Req. Date
        </p>
      ),

      getProps: (row) => {
        return { style: { padding: 0, height: "100%" } };
      },
      id: "reqDate",
      resizable: false,
      maxWidth: 140,
      sortType: (a, b) => {
        //console.log(b.datePosted)
        const AdispDate = a.order === null ? a.datePosted : a.order.dateAdded;
        const BdispDate = b.order === null ? b.datePosted : b.order.dateAdded;
        return new Date(BdispDate) - new Date(AdispDate);
      },
      accessor: (d) => (d.order === null ? d.datePosted : d.order.dateAdded),
      Cell: (row) => {
        const d = row.original;
        const dispDate = d.order === null ? d.datePosted : d.order.dateAdded;

        return <>{misc.parseAPIDateTimeToShortDate(dispDate)}</>;
      },
    },
    {
      Header: () => (
        <p
          style={{
            fontSize: "1em",
            color: "var(--REB-primary)",
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Transaction
        </p>
      ),

      getProps: (row) => {
        return { style: { padding: 0, height: "100%" } };
      },
      id: "trans",
      resizable: false,
      accessor: (d) =>
        d.order !== null
          ? d.order.name === null
            ? "Inventory"
            : d.order.name
          : "Inventory Request",
    },
  ];
}

function PRColumn(inventoryData, itemImgs, state) {
  return [
    {
      Header: "Item Details",
      id: "name",
      maxWidth: 350,
      getProps: (row) => {
        return { style: { padding: 0, height: 110 } };
      },
      Cell: (row) => {
        const d = row.original;
        let name = "-";
        if (d.item.details.length > 0) {
          let nameDat = d.item.details.find((x) => x.type === 1);
          let brandDat = d.item.details.find((x) => x.type === 3);

          nameDat = nameDat !== null ? nameDat.name : "";
          brandDat = brandDat !== null ? brandDat.name : "";
          name = nameDat + " " + brandDat;
        }
        return (
          <FormControl
            as="textarea"
            style={{ resize: "none", height: "100%" }}
            name={`txtPONotes${row.original.id}`}
            defaultValue={name}
            readOnly
          />
        );
      },
      accessor: (d) => {
        let name = "-";
        if (d.item.details.length > 0) {
          let nameDat = d.item.details.find((x) => x.type === 1);
          let brandDat = d.item.details.find((x) => x.type === 3);

          nameDat = nameDat !== null ? nameDat.name : "";
          brandDat = brandDat !== null ? brandDat.name : "";
          name = nameDat + " " + brandDat;
        }

        return name;
      },
    },
    {
      Header: "QTY",
      id: "qty",
      maxWidth: 100,

      accessor: (d) => {
        let name = "-";
        //console.log(state);
        if (d.item.details.length > 0) {
          const sDat = d.item.details.find((x) => x.type === 2);
          sDat !== undefined ? (name = sDat.name) : (name = "-");
        }

        return `${d.qtyOrdered} ${name}`;
      },
    },

    {
      Header: "SRP",
      id: "srp",
      maxWidth: 250,
      Cell: (row) => {
        const d = row.original;
        return (
          <>
            <Row noGutters>
              <Col>
                <p style={{ fontSize: 12.5, margin: 0 }}>INV. SRP:</p>
              </Col>
              <Col>
                {row.original.item === null ? (
                  "-"
                ) : (
                  <NumericFormat
                    value={row.original.item.price.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                )}
              </Col>
            </Row>
            <Row noGutters>
              <Col>
                <p style={{ fontSize: 12.5, margin: 0 }}>SUPP. SRP:</p>
              </Col>
              <Col>
                {row.original.item === null ? (
                  "-"
                ) : (
                  <NumericFormat
                    value={d.price / (1 - d.discount / 100)}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                )}
              </Col>
            </Row>
            <Row noGutters>
              <Col>
                <p style={{ fontSize: 12.5, margin: 0 }}>LESS:</p>
              </Col>
              <Col>
                {row.original.item === null ? (
                  "-"
                ) : (
                  <NumericFormat
                    value={d.discount === null ? "0" : d.discount}
                    displayType={"text"}
                    suffix=" %"
                    thousandSeparator={true}
                  />
                )}
              </Col>
            </Row>
            <Row noGutters>
              <Col>
                <p style={{ fontSize: 12.5, margin: 0 }}>DISCOUNTED:</p>
              </Col>
              <Col>
                {row.original.item === null ? (
                  "-"
                ) : (
                  <NumericFormat
                    value={d.price === null ? "0" : d.price}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                )}
              </Col>
            </Row>
          </>
        );
      },
      accessor: (d) => (d.item === null ? "-" : d.item.price),
    },

    {
      Header: "Amount",
      id: "amount",
      maxWidth: 140,
      Cell: (row) => {
        return row.original.price === null ? (
          "-"
        ) : (
          <NumericFormat
            value={(row.original.price * row.original.qtyOrdered).toFixed(2)}
            displayType={"text"}
            thousandSeparator={true}
          />
        );
      },
      accessor: (d) => (d.price === null ? "-" : d.price * d.qtyOrdered),
    },
  ];
}

function POColumn(inventoryData, itemImgs, state) {
  return [
    {
      Header: () => (
        <p
          style={{
            fontSize: "0.70em",
            color: "var(--REB-primary)",
            fontWeight: "bolder",
            margin: 0,
          }}
        >
          Image
        </p>
      ),
      maxWidth: 110,
      filterable: false,
      //accessor: (d) => name(d),

      Cell: (row) => {
        const d = row.original;
        //console.log(d);
        let image = [];
        if (d) {
          if (d.itemId !== null) {
            if (d.item.uploads !== null && d.item.uploads !== "") {
              if (d.item.uploads.constructor === String)
                image = JSON.parse(d.item.uploads);
              else {
                image = d.item.uploads;
              }
              //console.log(image);
            }
          }
        }
        return (
          <>
            {((x) => {
              if (true) {
                return (
                  <Row noGutters>
                    <Col
                      style={{
                        textAlign: "-webkit-center",
                      }}
                    >
                      <img
                        style={{
                          objectFit: "contain",
                          maxWidth: "100px",
                          height: "100px",
                          maxHeight: "100px",
                        }}
                        alt=""
                        src={
                          typeof image !== "undefined" &&
                          image !== null &&
                          image.length > 0 &&
                          image !== "null"
                            ? `${
                                process.env.REACT_APP_API_URL
                              }global/getFile2?uniqueId=${
                                d.item.uniqueId
                              }&filename=${encodeURIComponent(
                                image[0].filename
                              )}&token=${localStorage.getItem("LUT")}`
                            : emptyImg
                        }
                      ></img>
                    </Col>
                  </Row>
                );
              } else {
                return <></>;
              }
            })()}
          </>
        );
      },
    },
    {
      Header: "Item Details",
      id: "name",
      // maxWidth: 350,
      getProps: (row) => {
        return { style: { padding: 0, height: 110 } };
      },
      Cell: (row) => {
        const d = row.original;
        let name = "-";
        if (d.item.details.length > 0) {
          let nameDat = d.item.details.find((x) => x.type === 1);
          let brandDat = d.item.details.find((x) => x.type === 3);

          nameDat = nameDat !== null ? nameDat.name : "";
          brandDat = brandDat !== null ? brandDat.name : "";
          name = nameDat + " " + brandDat;
        }
        return (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                wordWrap: "break-word",
                whiteSpace: "normal",
                fontSize: "0.85em",
                height: "100%",
                color: "var(--REB-blue)",
                fontWeight: "bold",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <p style={{ marginBottom: "0px", flex: 1 }}> {name}</p>
            </div>
          </>
          // <FormControl
          //   as="textarea"
          //   style={{ resize: "none", height: "100%" }}
          //   name={`txtPONotes${row.original.id}`}
          //   defaultValue={name}
          //   readOnly
          // />
        );
      },
      accessor: (d) => {
        let name = "-";
        if (d.item.details.length > 0) {
          let nameDat = d.item.details.find((x) => x.type === 1);
          let brandDat = d.item.details.find((x) => x.type === 3);

          nameDat = nameDat !== null ? nameDat.name : "";
          brandDat = brandDat !== null ? brandDat.name : "";
          name = nameDat + " " + brandDat;
        }

        return name;
      },
    },
    {
      Header: "QTY",
      id: "qty",
      maxWidth: 100,
      accessor: (d) => {
        let name = "-";

        if (d.item.details.length > 0) {
          const sDat = d.item.details.find((x) => x.type === 2);
          sDat !== undefined ? (name = sDat.name) : (name = "-");
        }

        return `${d.qtyOrdered} ${name}`;
      },
    },
    // {
    //   Header: "DELIVERED",
    //   id: "delivered",
    //   maxWidth: 140,
    //   accessor: (d) => {
    //     let name = "-";

    //     if (d.item.details.length > 0) {
    //       const sDat = d.item.details.find((x) => x.type === 2);
    //       sDat !== undefined ? (name = sDat.name) : (name = "-");
    //     }

    //     return `${d.qtyDelivered} ${name}`;
    //   },
    // },
    {
      Header: "Status",
      id: "status",
      maxWidth: 140,
      accessor: (d) => (d.orderStatus === null ? "-" : d.orderStatus.name),
    },
    // {
    //   Header: "SRP",
    //   id: "srp",
    //   maxWidth: 250,
    //   show: !state.userType.includes("Inventory Clerk"),
    //   Cell: (row) => {
    //     //console.log();
    //     const d = row.original;
    //     return (
    //       <>
    //         <Row noGutters>
    //           <Col>
    //             <p style={{ fontSize: 12.5, margin: 0 }}>INV. SRP:</p>
    //           </Col>
    //           <Col>
    //             {row.original.item === null ? (
    //               "-"
    //             ) : (
    //               <NumberFormat
    //                 value={row.original.item.price.toFixed(2)}
    //                 displayType={"text"}
    //                 thousandSeparator={true}
    //               />
    //             )}
    //           </Col>
    //         </Row>
    //         <Row noGutters>
    //           <Col>
    //             <p style={{ fontSize: 12.5, margin: 0 }}>SUPP. SRP:</p>
    //           </Col>
    //           <Col>
    //             {row.original.item === null ? (
    //               "-"
    //             ) : (
    //               <NumberFormat
    //                 value={d.price / (1 - d.discount / 100)}
    //                 displayType={"text"}
    //                 thousandSeparator={true}
    //               />
    //             )}
    //           </Col>
    //         </Row>
    //         <Row noGutters>
    //           <Col>
    //             <p style={{ fontSize: 12.5, margin: 0 }}>LESS:</p>
    //           </Col>
    //           <Col>
    //             {row.original.item === null ? (
    //               "-"
    //             ) : (
    //               <NumberFormat
    //                 value={d.discount === null ? "0" : d.discount}
    //                 displayType={"text"}
    //                 suffix=" %"
    //                 thousandSeparator={true}
    //               />
    //             )}
    //           </Col>
    //         </Row>
    //         <Row noGutters>
    //           <Col>
    //             <p style={{ fontSize: 12.5, margin: 0 }}>DISCOUNTED:</p>
    //           </Col>
    //           <Col>
    //             {row.original.item === null ? (
    //               "-"
    //             ) : (
    //               <NumberFormat
    //                 value={d.price === null ? "0" : d.price}
    //                 displayType={"text"}
    //                 thousandSeparator={true}
    //               />
    //             )}
    //           </Col>
    //         </Row>
    //       </>
    //     );
    //   },
    //   accessor: (d) => (d.item === null ? "-" : d.item.price),
    // },
    // {
    //   Header: "SRP",
    //   id: "srp",
    //   maxWidth: 140,
    //   Cell: (row) => {
    //     return row.original.price === null ? (
    //       "-"
    //     ) : (
    //       <NumberFormat
    //         value={row.original.price.toFixed(2)}
    //         displayType={"text"}
    //         thousandSeparator={true}
    //       />
    //     );
    //   },
    //   accessor: (d) => (d.price === null ? "-" : d.price),
    // },
    // {
    //   Header: "Discount",
    //   id: "discount",
    //   maxWidth: 90,
    //   accessor: (d) => (d.discount === null ? "-" : `${d.discount} %  `),
    // },
    // {
    //   Header: "Discounted",
    //   id: "discounted",
    //   maxWidth: 140,
    //   accessor: (d) => (d.price === null ? "-" : d.price),
    // },

    // {
    //   Header: "Amount",
    //   id: "amount",
    //   maxWidth: 140,
    //   show: !state.userType.includes("Inventory Clerk"),
    //   Cell: (row) => {
    //     return row.original.price === null ? (
    //       "-"
    //     ) : (
    //       <NumberFormat
    //         value={(row.original.price * row.original.qtyOrdered).toFixed(2)}
    //         displayType={"text"}
    //         thousandSeparator={true}
    //       />
    //     );
    //   },
    //   accessor: (d) => (d.price === null ? "-" : d.price * d.qtyOrdered),
    // },
    // {
    //   Header: "Notes",
    //   id: "notes",
    //   getProps: (row) => {
    //     return { style: { padding: 0, height: 110 } };
    //   },
    //   Cell: (row) => {
    //     return (
    //       <>
    //         <FormControl
    //           as="textarea"
    //           style={{ resize: "none", height: "100%" }}
    //           //placeholder={props.state.txtPONotes.placeholder}
    //           //name={`txtPONotes${row.original.id}`}
    //           defaultValue={row.original.notes}
    //           // onChange={(x) => {
    //           //   console.log(x.currentTarget.value);
    //           // }}
    //           // onFocus={(x) => {
    //           //   console.log("focus");
    //           // }}
    //           onBlur={(x) => {
    //             if (
    //               (state.selectedRowPR !== null
    //                 ? state.selectedRowPR.id
    //                 : null) === row.original.id
    //             ) {
    //               state.handleActionChange(
    //                 {
    //                   target: { name: "txtPOContentNote" },
    //                   value: x.currentTarget.value,
    //                 },
    //                 row.original
    //               );
    //             }

    //             //handlePONotes(x, row.original);
    //             // console.log(
    //             //   x.currentTarget.value,
    //             //   x.currentTarget.name
    //             // );
    //           }}
    //           //className={props.state.txtPONotes.className + " css-projectInput"}
    //           readOnly={
    //             (state.selectedRowPR !== null
    //               ? state.selectedRowPR.id
    //               : null) !== row.original.id
    //           }
    //         />
    //       </>
    //     );
    //   },
    //   //accessor: (d) => (d.price === null ? "-" : d.price * d.qtyOrdered),
    // },
    // {
    //   Header: "Action",
    //   id: "action",

    //   Cell: (row) => {
    //     const isFinish =
    //       row.original.qtyDelivered === row.original.qtyOrdered &&
    //       row.original.orderStatus.name !== "Purchased";
    //     //console.log(state);
    //     return (
    //       <>
    //         {isFinish ? (
    //           <>
    //             <OverlayTrigger
    //               placement={"top"}
    //               overlay={
    //                 <Tooltip id={`actor-tooltip-add`}>
    //                   Make sure the selected item arrived at delivery location
    //                   and ready to be encoded before proceeding. This action is
    //                   irreversable.
    //                 </Tooltip>
    //               }
    //             >
    //               <Button
    //                 name="btnAddDeliveredToItemPO"
    //                 className=""
    //                 block
    //                 style={{
    //                   backgroundColor: "var(--REB-primary)",
    //                   fontWeight: "bold",
    //                   fontSize: 12,
    //                 }}
    //                 // disabled={
    //                 //   props.state.handleInvoiceModal.isView ||
    //                 //   Number(props.state.selectedOperation.id) === 0
    //                 // }
    //                 // hidden={
    //                 //   props.state.PRTableSelection.length === 0 ||
    //                 //   props.state.PRItemsTableSelection.length > 0
    //                 // }
    //                 onClick={(e) => {
    //                   // if (state.txtQtyDeliveredPO.value === "") {
    //                   //   state.dispatch({
    //                   //     type: "MODAL_SHOW",
    //                   //     payload: {
    //                   //       title: "Input Empty",
    //                   //       desc: `Please make sure that DELIVERED QTY has value`,
    //                   //       isYesNo: false,
    //                   //     },
    //                   //   });
    //                   // } else {

    //                   // }
    //                   state.handleActionChange(
    //                     {
    //                       target: { name: "btnSetItemDeliveredFinished" },
    //                     },
    //                     row.original
    //                   );

    //                   // props.state.handleActionChange(
    //                   //   {
    //                   //     target: { name: "btnCancelInvoiceContentQuote" },
    //                   //   },
    //                   //   null
    //                   // );
    //                 }}
    //               >
    //                 SET THIS ITEM TO DELIVERED
    //               </Button>
    //             </OverlayTrigger>
    //           </>
    //         ) : (
    //           <>
    //             {(state.selectedRowPR !== null
    //               ? state.selectedRowPR.id
    //               : null) === row.original.id &&
    //             state.selDetailStatus.value.name === "For Delivery" ? (
    //               <>
    //                 <Row noGutters>
    //                   <Col>
    //                     <TxtQtyDeliveredPO state={state} />
    //                   </Col>
    //                 </Row>
    //                 <Row noGutters>
    //                   <Col>
    //                     <OverlayTrigger
    //                       placement={"top"}
    //                       overlay={
    //                         <Tooltip id={`actor-tooltip-add`}>
    //                           Make sure the selected item arrived at delivery
    //                           location and ready to be encoded before
    //                           proceeding. This action is irreversable.
    //                         </Tooltip>
    //                       }
    //                     >
    //                       <Button
    //                         name="btnAddDeliveredToItemPO"
    //                         className=""
    //                         block
    //                         style={{
    //                           backgroundColor: "var(--REB-primary)",
    //                           fontWeight: "bold",
    //                           fontSize: 12,
    //                         }}
    //                         // disabled={
    //                         //   props.state.handleInvoiceModal.isView ||
    //                         //   Number(props.state.selectedOperation.id) === 0
    //                         // }
    //                         // hidden={
    //                         //   props.state.PRTableSelection.length === 0 ||
    //                         //   props.state.PRItemsTableSelection.length > 0
    //                         // }
    //                         onClick={(e) => {
    //                           // if (state.txtQtyDeliveredPO.value === "") {
    //                           //   state.dispatch({
    //                           //     type: "MODAL_SHOW",
    //                           //     payload: {
    //                           //       title: "Input Empty",
    //                           //       desc: `Please make sure that DELIVERED QTY has value`,
    //                           //       isYesNo: false,
    //                           //     },
    //                           //   });
    //                           // } else {

    //                           // }
    //                           state.handleActionChange(
    //                             {
    //                               target: { name: "btnAddDeliveredToItemPO" },
    //                             },
    //                             row.original
    //                           );
    //                           // props.state.handleActionChange(
    //                           //   {
    //                           //     target: { name: "btnCancelInvoiceContentQuote" },
    //                           //   },
    //                           //   null
    //                           // );
    //                         }}
    //                       >
    //                         ADD DELIVERED TO ITEM
    //                       </Button>
    //                     </OverlayTrigger>
    //                   </Col>
    //                 </Row>
    //               </>
    //             ) : null}
    //           </>
    //         )}
    //         {(state.selectedRowPR !== null ? state.selectedRowPR.id : null) ===
    //           row.original.id &&
    //         state.selDetailStatus.value.name === "For Delivery" ? (
    //           <Row noGutters>
    //             <Col>
    //               <OverlayTrigger
    //                 placement={"top"}
    //                 overlay={
    //                   <Tooltip id={`actor-tooltip-add`}>
    //                     Admin Override to reset delivered items
    //                   </Tooltip>
    //                 }
    //               >
    //                 <Button
    //                   name="btnResetDeliveredItemPO"
    //                   className=""
    //                   block
    //                   style={{
    //                     backgroundColor: "var(--REB-warning)",
    //                     fontWeight: "bold",
    //                     fontSize: 12,
    //                   }}
    //                   // disabled={
    //                   //   props.state.handleInvoiceModal.isView ||
    //                   //   Number(props.state.selectedOperation.id) === 0
    //                   // }
    //                   // hidden={
    //                   //   props.state.PRTableSelection.length === 0 ||
    //                   //   props.state.PRItemsTableSelection.length > 0
    //                   // }
    //                   onClick={(e) => {
    //                     state.handleActionChange(
    //                       {
    //                         target: { name: "btnResetDeliveredItemPO" },
    //                       },
    //                       row.original
    //                     );
    //                     // props.state.handleActionChange(
    //                     //   {
    //                     //     target: { name: "btnCancelInvoiceContentQuote" },
    //                     //   },
    //                     //   null
    //                     // );
    //                   }}
    //                 >
    //                   RESET DELIVERED
    //                 </Button>
    //               </OverlayTrigger>
    //             </Col>
    //           </Row>
    //         ) : null}
    //       </>
    //     );
    //   },
    //   //accessor: (d) => (d.price === null ? "-" : d.price * d.qtyOrdered),
    // },
  ];
}

let TxtQty = (props) => (
  <>
    <div
      className=""
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "var(--REB-primary)",
        borderRadius: "5px",
      }}
    >
      <InputGroup.Prepend>
        <p
          className="m-0 pl-2"
          style={{
            color: "var(--REB-white)",
            fontWeight: "bold",

            // backgroundColor: "var(--REB-primary)",
          }}
        >
          QTY TO ORDER
        </p>
      </InputGroup.Prepend>
      <FormControl
        as={NumericFormat}
        inputMode="numeric"
        thousandSeparator={true}
        suffix={` ${
          props.state.selectedRowPRItems !== null
            ? props.state.selectedRowPRItems?.itemUnit?.name
            : ""
        }`}
        isAllowed={(values) => {
          const { formattedValue, floatValue } = values;
          return (
            formattedValue === "" ||
            floatValue <=
              props.state.selectedRowPRItems.itemQty -
                props.state.selectedRowPRItems.qtyOrdered
          );
        }}
        placeholder={`UNORDERED: ${
          props.state.selectedRowPRItems !== null
            ? props.state.selectedRowPRItems.itemQty -
              props.state.selectedRowPRItems.qtyOrdered
            : ""
        } ${
          props.state.selectedRowPRItems !== null
            ? props.state.selectedRowPRItems.itemUnit.name
            : ""
        }`}
        name="txtQtyPR"
        value={props.state.txtQtyPR.value}
        onValueChange={(x) => {
          //  console.log();
          props.state.inputChange({
            target: {
              value: Number(x.value),
              name: "txtQtyPR",
              placeholder: props.state.txtQtyPR.placeholder,
              className: props.state.txtQtyPR.className,
            },
          });
        }}
        className={props.state.txtQtyPR.className}
        readOnly={props.state.txtQtyPR.readOnly}
      />
    </div>
  </>
);

const TxtQtyDeliveredPO = (props) => (
  <>
    <div
      className=""
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "var(--REB-primary)",
        borderRadius: "5px",
      }}
    >
      <InputGroup.Prepend>
        <p
          className="m-0 pl-2"
          style={{
            color: "var(--REB-white)",
            fontWeight: "bold",

            // backgroundColor: "var(--REB-primary)",
          }}
        >
          DELIVERED
        </p>
      </InputGroup.Prepend>
      <FormControl
        as={NumericFormat}
        inputMode="numeric"
        thousandSeparator={true}
        suffix={
          props.state.selectedRowPR !== null
            ? props.state.selectedRowPR.itemUnit?.name
            : ""
        }
        isAllowed={(values) => {
          const { formattedValue, floatValue } = values;
          return (
            formattedValue === "" ||
            floatValue <=
              props.state.selectedRowPR.qtyOrdered -
                props.state.selectedRowPR.qtyDelivered
          );
        }}
        placeholder={`QTY LEFT: ${
          props.state.selectedRowPR !== null
            ? props.state.selectedRowPR.qtyOrdered -
              props.state.selectedRowPR.qtyDelivered
            : ""
        } ${
          props.state.selectedRowPR !== null
            ? props.state.selectedRowPR.itemUnit?.name
            : ""
        }`}
        name="txtQtyDeliveredPO"
        value={props.state.txtQtyDeliveredPO.value}
        // onBlur={(x) => {
        //   props.state.handleActionChange(
        //     {
        //       target: { name: "txtQtyDeliveredPO" },
        //     },
        //     x.currentTarget.value
        //   );
        //   //handlePONotes(x, row.original);
        //   // console.log(
        //   //   x.currentTarget.value,
        //   //   x.currentTarget.name
        //   // );
        // }}
        onValueChange={(x) => {
          //console.log("test");
          props.state.handleActionChange(
            {
              target: {
                value: Number(x.value),
                name: "txtQtyDeliveredPO",
                //placeholder: props.state.txtQtyDeliveredPO.placeholder,
                //className: props.state.txtQtyDeliveredPO.className,
              },
            },
            Number(x.value)
          );
        }}
        // className={props.state.txtQtyDeliveredPO.className}
        // readOnly={props.state.txtQtyDeliveredPO.readOnly}
      />
    </div>
  </>
);

function CogsDetails(props) {
  //const [liveCost, setliveCost] = useState(0);
  return (
    <>
      <Row className="mb-2 d-flex flex-row" noGutters hidden={props.hidden}>
        <Col lg={3} md={4} xs={12} sm={12} className="pl-2 pr-2 mb-3">
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "var(--REB-primary)",
              borderRadius: "5px",
            }}
          >
            <InputGroup.Prepend>
              <p
                className="m-0 pl-2"
                style={{
                  color: "var(--REB-white)",
                  fontWeight: "bold",

                  // backgroundColor: "var(--REB-primary)",
                }}
              >
                Handling Cost
              </p>
            </InputGroup.Prepend>
            <InputGroup.Prepend>
              <FormControl
                as={NumericFormat}
                inputMode="numeric"
                thousandSeparator={true}
                prefix={"₱"}
                style={{}}
                placeholder="Handling Cost"
                name="txtHandlingPR"
                value={Number(props.state.txtHandlingPR.value)}
                onValueChange={(x) => {
                  props.state.inputChange({
                    target: {
                      value: Number(x.value),
                      name: "txtHandlingPR",
                      placeholder: props.state.txtHandlingPR.placeholder,
                      className: props.state.txtHandlingPR.className,
                    },
                  });
                  props.state.handleActionChange({
                    target: {
                      name: "txtCogsPR",
                    },
                  });
                }}
                className={
                  props.state.txtHandlingPR.className + " css-actorInput"
                }
                readOnly={props.state.txtHandlingPR.readOnly}
              />
            </InputGroup.Prepend>
          </div>
        </Col>
        <Col lg={3} md={4} xs={12} sm={12} className="pl-2 pr-2 mb-3">
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "var(--REB-primary)",
              borderRadius: "5px",
            }}
          >
            <p
              className="m-0 pl-2"
              style={{
                color: "var(--REB-white)",
                fontWeight: "bold",

                // backgroundColor: "var(--REB-primary)",
              }}
            >
              Shipping Cost
            </p>
            <FormControl
              as={NumericFormat}
              inputMode="numeric"
              thousandSeparator={true}
              prefix={"₱ "}
              style={{}}
              placeholder="Shipping Cost"
              name="txtShippingPR"
              value={Number(props.state.txtShippingPR.value)}
              onValueChange={(x) => {
                //  console.log(x.value);
                props.state.inputChange({
                  target: {
                    value: Number(x.value),
                    name: "txtShippingPR",
                    placeholder: props.state.txtShippingPR.placeholder,
                    className: props.state.txtShippingPR.className,
                  },
                });
                props.state.handleActionChange({
                  target: {
                    name: "txtCogsPR",
                  },
                });
              }}
              className={
                props.state.txtShippingPR.className + " css-actorInput"
              }
              readOnly={props.state.txtShippingPR.readOnly}
            />
          </div>
        </Col>
        <Col lg={2} md={3} xs={9} sm={10} className="pl-2 pr-2 mb-3">
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "var(--REB-primary)",
              borderRadius: "5px",
            }}
          >
            <p
              className="m-0 pl-2"
              style={{
                color: "var(--REB-white)",
                fontWeight: "bold",

                // backgroundColor: "var(--REB-primary)",
              }}
            >
              Cost
            </p>
            <FormControl
              as={NumericFormat}
              inputMode="numeric"
              thousandSeparator={true}
              prefix={"₱ "}
              style={{}}
              placeholder="Cost"
              name="txtCostPR"
              value={Number(props.state.txtCostPR.value)}
              onValueChange={(x) => {
                // console.log(x.value);
                props.state.inputChange({
                  target: {
                    value: Number(x.value),
                    name: "txtCostPR",
                    placeholder: props.state.txtCostPR.placeholder,
                    className: props.state.txtCostPR.className,
                  },
                });
                props.state.handleActionChange({
                  target: {
                    name: "txtCogsPR",
                  },
                });
              }}
              className={props.state.txtCostPR.className + " css-actorInput"}
              readOnly={props.state.txtCostPR.readOnly}
            />
          </div>
        </Col>
        <Col
          className=" mb-3 pl-2 pr-2"
          style={{ alignSelf: "flex-end" }}
          lg={1}
          md={1}
          sm={2}
          xs={3}
        >
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip id={`actor-tooltip-add`}>
                Totals Handling, Shipping & Cost to COGS
              </Tooltip>
            }
          >
            <Button
              name="btnTotalCogs"
              //disabled={props.state.txtDisplay.readOnly}
              style={{
                backgroundColor: "var(--REB-green)",
                //border: "1px solid var(--REB-green) !important",
                // fontSize: "10px",
              }}
              className="icofont-ui-calculator btnCalc"
              onClick={(e) => {
                props.state.handleActionChange({
                  target: {
                    name: "btnTotalCogsPR",
                    value: e,
                    service: true,
                  },
                });
              }}
            ></Button>
          </OverlayTrigger>
        </Col>
        <Col lg={2} md={11} sm={11} xs={9} className="pl-2 pr-2">
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "var(--REB-primary)",
              borderRadius: "5px",
            }}
          >
            <p
              className="m-0 pl-2"
              style={{
                color: "var(--REB-white)",
                fontWeight: "bold",

                // backgroundColor: "var(--REB-primary)",
              }}
            >
              COGS
            </p>
            <FormControl
              as={NumericFormat}
              inputMode="numeric"
              thousandSeparator={true}
              prefix={"₱ "}
              style={{}}
              placeholder="COGS"
              name="txtCogsPR"
              value={Number(props.state.txtCogsPR.value)}
              onValueChange={(x) => {
                props.state.inputChange({
                  target: {
                    value: Number(x.value),
                    name: "txtCogsPR",
                    placeholder: props.state.txtCogsPR.placeholder,
                    className: props.state.txtCogsPR.className,
                  },
                });
              }}
              className={props.state.txtCogsPR.className + " css-actorInput"}
              readOnly={props.state.txtCogsPR.readOnly}
            />
          </div>
        </Col>
        <Col
          className=" mb-3 pl-2 pr-2"
          style={{ alignSelf: "flex-end" }}
          lg={1}
          md={1}
          sm={2}
          xs={3}
        >
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip id={`actor-tooltip-add`}>
                Copies Handling, Shipping, Cost & Cogs data from Inventory
              </Tooltip>
            }
          >
            <Button
              name="btnCopyCogsFromInventory"
              //disabled={props.state.txtDisplay.readOnly}
              style={{
                backgroundColor: "var(--REB-green)",
                //border: "1px solid var(--REB-green) !important",
                // fontSize: "10px",
              }}
              className="icofont-ui-copy btnCalc"
              onClick={(e) => {
                props.state.handleActionChange({
                  target: {
                    name: "btnCopyCogsFromInventory",
                    value: e,
                    service: true,
                  },
                });
              }}
            ></Button>
          </OverlayTrigger>
        </Col>
      </Row>
    </>
  );
}

function CostPrice(props) {
  //const [liveCost, setliveCost] = useState(0);
  //const [isDicountLimit, setDiscountLimit] = useState(false);
  return (
    <>
      <div
        className=""
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "var(--REB-primary)",
          borderRadius: "5px",
        }}
      >
        <InputGroup.Prepend>
          <p
            className="m-0 pl-2"
            style={{
              color: "var(--REB-white)",
              fontWeight: "bold",

              // backgroundColor: "var(--REB-primary)",
            }}
          >
            COST
          </p>
        </InputGroup.Prepend>
        <ButtonGroup>
          <FormControl
            as={NumericFormat}
            inputMode="numeric"
            thousandSeparator={true}
            prefix={"₱ "}
            style={{ flex: 3 }}
            placeholder={`SRP: ₱ ${
              props.state.selectedRowPRItems !== null
                ? props.state.selectedRowPRItems.item.price
                : ""
            } `}
            name="txtNetPricePR"
            value={props.state.txtNetPricePR.value}
            onValueChange={(x) => {
              props.state.inputChange({
                target: {
                  value: Number(x.value),
                  name: "txtNetPricePR",
                  placeholder: props.state.txtNetPricePR.placeholder,
                  className: props.state.txtNetPricePR.className,
                },
              });
            }}
            className={props.state.txtNetPricePR.className}
            readOnly={props.state.txtNetPricePR.readOnly}
          />
          {/* <OverlayTrigger
          placement={"top"}
          show={isDicountLimit}
          // onToggle={
          //   isDicountLimit ? setDiscountLimit(false) : setDiscountLimit(true)
          // }
          overlay={
            <Tooltip style={{ fontSize: "15px" }} id={`actor-tooltip-add`}>
              {`Your maximum Discount Assigned ${
                props.state.user.maxDiscount === null
                  ? 0
                  : props.state.user.maxDiscount
              }`}
            </Tooltip>
          }
        >
          <InputGroup.Prepend>
            <InputGroup.Text
              className="icofont-sale-discount"
              style={{
                backgroundColor: "var(--REB-primary)",
                color: "var(--REB-white)",
              }}
            ></InputGroup.Text>
          </InputGroup.Prepend>
        </OverlayTrigger> */}

          <FormControl
            as={NumericFormat}
            inputMode="numeric"
            thousandSeparator={true}
            prefix={"% "}
            style={{ flex: 2 }}
            placeholder="Discount"
            name="txtDiscountMaxPR"
            // isAllowed={(values) => {
            //   const { formattedValue, floatValue } = values;

            //   //console.log(props.state.user);

            //   if (floatValue >= props.state.user.maxDiscount) setDiscountLimit(1);
            //   else setDiscountLimit(0);
            //   // if (props.state.user.types.find((x) => x.name === "Admin"))
            //   //   return formattedValue === "" || floatValue <= 100;
            //   // else
            //   return (
            //     formattedValue === "" ||
            //     floatValue <=
            //       (props.state.user.maxDiscount === null
            //         ? ""
            //         : props.state.user.maxDiscount)
            //   );
            //   // if(floatValue <= 100){setDiscountLimit(1)}
            //   // return formattedValue === "" || floatValue <= 100;
            // }}
            isAllowed={(values) => {
              const { formattedValue, floatValue } = values;

              // if (floatValue <= 100) {
              //   setDiscountLimit(1);
              // } else {
              //   setDiscountLimit(0);
              // }
              return formattedValue === "" || floatValue <= 100;
            }}
            value={props.state.txtDiscountMaxPR.value}
            // onValueChange={(x) => {
            //   props.state.handleActionChange({
            //     target: {
            //       value: x.value,
            //       name: "txtDiscountMaxPR",
            //     },
            //   });
            // }}
            onValueChange={(x) => {
              props.state.inputChange({
                target: {
                  value: Number(x.value),
                  name: "txtDiscountMaxPR",
                },
              });
            }}
            className={props.state.txtDiscountMaxPR.className}
            readOnly={props.state.txtDiscountMaxPR.readOnly}
          />
          <InputGroup.Prepend>
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip id={`actor-tooltip-add`}>Apply Discount</Tooltip>
              }
            >
              <Button
                name="btnApplyPRDiscount"
                className=""
                style={{ backgroundColor: "var(--REB-green)" }}
                // disabled={
                //   props.state.handleInvoiceModal.isView ||
                //   Number(props.state.selectedOperation.id) === 0
                // }

                onClick={(e) => {
                  props.state.handleActionChange({
                    target: { name: "btnApplyPRDiscount" },
                  });

                  // props.state.handleActionChange(
                  //   {
                  //     target: { name: "btnCancelInvoiceContentQuote" },
                  //   },
                  //   null
                  // );
                }}
              >
                APPLY
              </Button>
            </OverlayTrigger>
          </InputGroup.Prepend>
        </ButtonGroup>
      </div>
    </>
  );
}

let TxtNotesPR = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      Notes
    </Form.Label>
    <FormControl
      as="textarea"
      //style={{ textTransform: "uppercase" }}
      placeholder="Notes"
      name="txtNotesPR"
      value={props.state.txtNotesPR.value}
      onChange={props.state.inputChange}
      className={props.state.txtNotesPR.className + " css-actorInput mb-2"}
      readOnly={props.state.txtNotesPR.readOnly}
    />
  </>
);

let TxtNotesPRContent = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      Notes
    </Form.Label>
    <FormControl
      as="textarea"
      //style={{ textTransform: "uppercase" }}
      placeholder="Notes"
      name="txtNotesPRContent"
      value={props.state.txtNotesPRContent.value}
      onChange={props.state.inputChange}
      className={
        props.state.txtNotesPRContent.className + " css-actorInput mb-2"
      }
      readOnly={props.state.txtNotesPRContent.readOnly}
    />
  </>
);

let TxtDateAddedPR = (props) => (
  <>
    <Form.Label>
      <strong>Date Added</strong>
    </Form.Label>
    <FormControl
      type="datetime-local"
      //placeholder={props.state.txtDeadline.placeholder}
      name="txtDateAddedPR"
      value={props.state.txtDateAddedPR.value}
      onChange={props.state.inputChange}
      className={props.state.txtDateAddedPR.className + " css-projectInput"}
      readOnly={true}
    />
  </>
);
let TxtDateLastModifiedPR = (props) => (
  <>
    <Form.Label>
      <strong>Date Last Modified</strong>
    </Form.Label>
    <FormControl
      type="datetime-local"
      //placeholder={props.state.txtDeadline.placeholder}
      name="txtDateLastModifiedPR"
      value={props.state.txtDateLastModifiedPR.value}
      onChange={props.state.inputChange}
      className={
        props.state.txtDateLastModifiedPR.className + " css-projectInput"
      }
      readOnly={true}
    />
  </>
);
let TxtDateFinishedPR = (props) => (
  <>
    <Form.Label>
      <strong>Date Finished</strong>
    </Form.Label>
    <FormControl
      type="date"
      //placeholder={props.state.txtDeadline.placeholder}
      name="txtDateFinishedPR"
      value={props.state.txtDateFinishedPR.value}
      onChange={props.state.inputChange}
      className={props.state.txtDateFinishedPR.className + " css-projectInput"}
      readOnly={true}
    />
  </>
);

let TxtDateDeadlinePR = (props) => (
  <>
    <Form.Label>
      <strong>Invoice Deadline</strong>
    </Form.Label>
    <FormControl
      type="date"
      //placeholder={props.state.txtDeadline.placeholder}
      name="txtDateDeadlinePR"
      value={props.state.txtDateDeadlinePR.value}
      onChange={props.state.inputChange}
      className={props.state.txtDateDeadlinePR.className + " css-projectInput"}
      readOnly={props.state.txtDateDeadlinePR.readOnly}
    />
  </>
);
const LogisticsContainer = (props) => {
  let paymentSelectStyle = {
    menu: (provided, state) => {
      //console.log(state);
      const width = "100%";

      let color = state.selectProps.menuColor;
      const padding = 0;

      return {
        ...provided,
        width,
        color,
        padding,

        //backgroundColor: "red",
      };
    },
    control: (provided) => ({
      ...provided,
      // borderRadius: "0px 0px 0px 0px",
    }),

    container: (_, { selectProps: { width } }) => ({
      width: width,
      flex: 1,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 6000,

      borderRadius: "5px 0px 0px 5px",
    }),
    menuList: (provided) => ({
      ...provided,
      zIndex: 6000,

      borderRadius: "5px 0px 0px 5px",
    }),

    option: (provided, state) => {
      let backgroundColor = "";
      let display = "block";
      let color = "black";
      if (state.data.isDefault === 1) {
        backgroundColor = "rgba(255, 0, 0, 0.2)";
      }
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      //console.log(state.data);
      if (state.data.isHidden === 1) {
        display = "none";
      }

      return {
        ...provided,
        opacity,
        transition,
        backgroundColor,
        color,
        display,
      };
    },
  };

  const {
    userType,
    user,
    selectedRowInvoice,
    selectedInvoicePayments,
    settings,
  } = props.state;

  const selectedInvoiceID = props.state.selectedRowInvoice
    ? Number(props.state.selectedRowInvoice.id)
    : null;
  let paymentData = [];

  if (selectedRowInvoice)
    paymentData =
      selectedInvoicePayments !== null ? selectedInvoicePayments : [];

  const dispatch = useDispatch();

  const DSelLogisticsStatus = [
    { value: "on-route", label: "On Route" },
    { value: "finished", label: "Finished" },
    { value: "pending-admin-approval", label: "Pending Admin Approval" },
    { value: "admin-denied", label: "Admin Denied" },
  ];

  const [invoiceStatus, setInvoiceStatus] = useState(null);
  const CURRENT_USERTYPE = useSelector((state) => state.apiData.userType);

  //const user = useSelector((state) => state.apiData.userData);
  const [POContentData, setPOContentData] = useState([]);
  const [LogisticsOperation, setLogisticsOperation] = useState(
    SELECTED_OPERATION.VIEW
  );
  const [txtAmount, setTxtAmount] = useState(0);
  const [txtDate, setTxtDate] = useState(null);
  const [txtType, setTxtType] = useState({ value: "cash", label: "Cash" });

  const [SelInvoiceBankAccount, setSelInvoiceBankAccount] = useState(null);
  const [SelInvoiceExpenseAccount, setSelInvoiceExpenseAccount] =
    useState(null);

  const [TxtContainerDesc, setTxtContainerDesc] = useState(null);
  const [TxtContainerSize, setTxtContainerSize] = useState(null);
  const [SelContainerUnit, setSelContainerUnit] = useState(null);
  const [SelContainerUnitData, setSelContainerUnitData] = useState([]);

  const [TxtCourierContactPerson, setTxtCourierContactPerson] = useState(null);
  const [TxtCourierContactPersonNo, setTxtCourierContactPersonNo] =
    useState(null);
  const [SelCourier, setSelCourier] = useState(null);
  const [SelCourierData, setSelCourierData] = useState([]);

  const [TxtSupplierContactPerson, setTxtSupplierContactPerson] =
    useState(null);
  const [TxtSupplierContactPersonNo, setTxtSupplierContactPersonNo] =
    useState(null);

  const [TxtPickupAddress, setTxtPickupAddress] = useState(null);
  const [TxtPickupDate, setTxtPickupDate] = useState(null);
  const [TxtETA, setTxtETA] = useState(null);
  const [TxtLogiNotes, setTxtLogiNotes] = useState(null);

  const [LogiTxtUniqueID, setLogiTxtUniqueID] = useState(null);
  const [LogiTxtDateAdded, setLogiTxtDateAdded] = useState(null);
  const [LogiTxtDateModified, setLogiTxtDateModified] = useState(null);
  const [LogiTxtLastModifiedBy, setLogiTxtLastModifiedBy] = useState(null);

  const [LogiTxtAddedBy, setLogiTxtAddedBy] = useState(null);

  const [SelInvoiceActsPayable, setSelInvoiceActsPayable] = useState(null);

  const [IsLogistics, setIsLogistics] = useState(false);
  const [SelectedLogistics, setSelectedLogistics] = useState(null);

  const [TxtCogs, setTxtCogs] = useState(null);
  const [TxtHandling, setTxtHandling] = useState(null);
  const [TxtShipping, setTxtShipping] = useState(null);

  const [TxtPRItemCost, setTxtPRItemCost] = useState(null);

  const [IsItemModify, setIsItemModify] = useState(false);

  //console.log(props.state)

  useEffect(() => {
    if (props.state.selectedRowInvoice) {
      if (props.state.selectedRowInvoice.invoiceStatus) {
        if (props.state.selectedRowInvoice.invoiceStatus.name) {
          setInvoiceStatus(props.state.selectedRowInvoice.invoiceStatus.name);
        }
      }

      if (settings) {
        setSelInvoiceBankAccount(settings.POBankAccount);

        setSelInvoiceActsPayable(settings.POAcctsPayableAccount);
        setSelInvoiceExpenseAccount(settings.POExpenseAccount);
      }
    }
  }, [props.state.selectedRowInvoice]);

  useEffect(() => {
    // console.log("trigger rerender");
    getActors();
    getItemUnits();
  }, []);
  // useEffect(() => {
  //   if (POContentData.length > 0) {
  //     setLogisticsOperation(SELECTED_OPERATION.ADD);
  //   }
  //   if (POContentData.length === 0) {
  //     setLogisticsOperation(SELECTED_OPERATION.VIEW);
  //   }
  // }, [POContentData]);

  const getActors = async (e) => {
    const ACTORS = await actorApi.getAllActors(dispatch);

    const _clients = ACTORS.find((x) => x.name === "Payees");

    setSelCourierData(_clients.actors);
  };
  const getItemUnits = async () => {
    const detData = await InventoryAPI.getItemDetail(dispatch, 2);
    setSelContainerUnitData(detData);
  };

  const modifyItemCost = async (id) => {
    const data = {
      id: Number(id),

      lastModifiedByActorId: Number(props.state.user.id),
      price: Number(TxtPRItemCost),
      handling: Number(TxtHandling),
      shipping: Number(TxtShipping),
      cogs: Number(TxtCogs),

      //dateLastModified: DateToday,
    };
    await invoiceApi.updateInvoiceContent(dispatch, data);
    // props.state.handleActionChange({
    //   target: { name: "refreshSelectedPRContent" },
    // });
    const updatedInvoice = await InvoiceAPI.getInvoice(
      dispatch,
      selectedRowInvoice.id
    );
    props.state.handleActionChange({
      target: {
        value: updatedInvoice,
        name: "refreshAndReplaceSelected",
      },
    });
    dispatch({
      type: "TOAST_ADD",
      payload: {
        title: `Modified Cost Successfully`,
      },
    });
    // resetUI();
  };

  const resetUserInputs = () => {
    setLogisticsOperation(SELECTED_OPERATION.VIEW);
    setTxtAmount(0);
    setTxtDate(null);
    setTxtType({ value: "cash", label: "Cash" });
    setPOContentData([]);
    setIsLogistics(false);
    setTxtCourierContactPerson("");
    setTxtCourierContactPersonNo("");
    setSelCourier("");
    setTxtSupplierContactPerson("");
    setTxtSupplierContactPersonNo("");
    setTxtPickupAddress("");
    setTxtETA("");
    setLogiTxtUniqueID("");
    setLogiTxtDateAdded("");
    setLogiTxtDateModified("");
    setLogiTxtLastModifiedBy("");
    setLogiTxtAddedBy("");
    setTxtLogiNotes("");
    setIsItemModify(false);
    setTxtContainerDesc("");
    setTxtContainerSize("");
    setSelContainerUnit("");
    setTxtPickupDate("");
    setSelectedLogistics(null);
  };

  const createLogistics = async (cb) => {
    let InventoryId = Number(selectedRowInvoice.inventoryId);
    const transUid = filters.uuidv4();
    // console.log(SelInventory);
    if (InventoryId === 1 || InventoryId === 0) {
      InventoryId = null;
    }

    let logisticsContent = [];

    POContentData.forEach((cont) => {
      console.log("CONTENT ===> ", cont);
      logisticsContent.push({
        uid: transUid,
        itemId: cont.itemId,
        itemUnitId: cont.itemUnitId,
        qty: cont.itemQty,
        inventoryId: InventoryId,
        orderContentId: cont.id,
      });
    });

    const data = [
      {
        logistics: {
          inventoryId: InventoryId,
          uid: transUid,
          logiStatus: "pending-admin-approval",
          ...(TxtContainerDesc ? { containerDesc: TxtContainerDesc } : null),
          ...(TxtContainerSize ? { containerSize: TxtContainerSize } : null),
          ...(SelContainerUnit
            ? { containerUnitId: SelContainerUnit.id }
            : null),
          ...(TxtCourierContactPerson
            ? { courierContactPerson: TxtCourierContactPerson }
            : null),
          ...(TxtCourierContactPersonNo
            ? { courierContactNumber: TxtCourierContactPersonNo }
            : null),
          ...(SelCourier ? { courierId: SelCourier.id } : null),
          ...(selectedRowInvoice
            ? { supplierVendorId: selectedRowInvoice.supplierId }
            : null),
          ...(TxtSupplierContactPerson
            ? { supplierContactPerson: TxtSupplierContactPerson }
            : null),
          ...(TxtSupplierContactPersonNo
            ? { supplierContactNumber: TxtSupplierContactPersonNo }
            : null),
          ...(TxtPickupAddress ? { pickupAddress: TxtPickupAddress } : null),
          ...(TxtPickupDate ? { pickupDate: TxtPickupDate } : null),
          ...(TxtETA ? { eta: TxtETA } : null),
          ...(TxtLogiNotes ? { notes: TxtLogiNotes } : null),
          orderId: selectedRowInvoice.id,
        },
        content: logisticsContent,
      },
    ];
    console.log("PRE-POST DATA Logistics ===>", data);

    const createdInvoice = await LogisticsAPI.createMultiLogistics(
      dispatch,
      data,
      () => {
        if (cb) {
          // handleTransactionsReset();
          cb();
        }
        // refreshTransactions();
      },
      () => {
        dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Logistics Invoice Creation Error",
            desc: `Please Contact Administrator`,
            isYesNo: false,
          },
        });
      }
    );

    if (createdInvoice.data) {
      actorApi.sendEmail(
        dispatch,
        `PO Logistics - ${props.state.selectedRowInvoice?.name}`,
        props.state.selectedRowInvoice?.incharge?.lastName,
        props.state.selectedRowInvoice?.inventoryId
      );
      const orderContent = [];

      POContentData.forEach((cont) => {
        console.log("CONTENT ===> ", cont);
        orderContent.push({
          id: cont.id,
          qtyPendingDelivered: cont.itemQty + cont.qtyPendingDelivered,
        });
      });

      const data = { content: orderContent };
      await InvoiceAPI.updateInvoice(dispatch, data);

      console.log("CREATED LOGISTICS INVOICE ===> ", createdInvoice);
      resetUserInputs();
      const updatedInvoice = await InvoiceAPI.getInvoice(
        dispatch,
        selectedRowInvoice.id
      );
      props.state.handleActionChange({
        target: {
          value: updatedInvoice,
          name: "refreshAndReplaceSelected",
        },
      });
      dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Successfully added logistics invoice`,
        },
      });
    }
  };
  const modifyLogistics = async (cb) => {
    const data = [
      {
        logistics: {
          //inventoryId: InventoryId,
          //uid: transUid,
          logiStatus: "pending-admin-approval",
          id: Number(SelectedLogistics.id),
          ...(TxtContainerDesc ? { containerDesc: TxtContainerDesc } : null),
          ...(TxtContainerSize ? { containerSize: TxtContainerSize } : null),
          ...(SelContainerUnit
            ? { containerUnitId: SelContainerUnit.id }
            : null),
          ...(TxtCourierContactPerson
            ? { courierContactPerson: TxtCourierContactPerson }
            : null),
          ...(TxtCourierContactPersonNo
            ? { courierContactNumber: TxtCourierContactPersonNo }
            : null),
          ...(SelCourier ? { courierId: SelCourier.id } : null),
          ...(selectedRowInvoice
            ? { supplierVendorId: selectedRowInvoice.supplierId }
            : null),
          ...(TxtSupplierContactPerson
            ? { supplierContactPerson: TxtSupplierContactPerson }
            : null),
          ...(TxtSupplierContactPersonNo
            ? { supplierContactNumber: TxtSupplierContactPersonNo }
            : null),
          ...(TxtPickupAddress ? { pickupAddress: TxtPickupAddress } : null),
          ...(TxtPickupDate ? { pickupDate: TxtPickupDate } : null),
          ...(TxtETA ? { eta: TxtETA } : null),
          ...(TxtLogiNotes ? { notes: TxtLogiNotes } : null),
          //orderId: selectedRowInvoice.id,
        },
        //content: logisticsContent,
      },
    ];
    console.log("PRE-POST DATA Logistics Update ===>", data);

    const updatedInvoice = await LogisticsAPI.updateMultiLogistics(
      dispatch,
      data,
      () => {
        if (cb) {
          // handleTransactionsReset();
          cb();
        }
        // refreshTransactions();
      },
      () => {
        dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Logistics Invoice Modification Error",
            desc: `Please Contact Administrator`,
            isYesNo: false,
          },
        });
      }
    );

    console.log("MODIFIED LOGISTICS INVOICE ===> ", updatedInvoice);
    if (updatedInvoice.data) {
      actorApi.sendEmail(
        dispatch,
        `PO Logistics - ${this.state.selectedRowInvoice?.name}`,
        this.state.selectedRowInvoice?.incharge?.lastName,
        this.state.selectedRowInvoice?.inventoryId
      );
      resetUserInputs();
      const updatedInvoice = await InvoiceAPI.getInvoice(
        dispatch,
        selectedRowInvoice.id
      );
      props.state.handleActionChange({
        target: {
          value: updatedInvoice,
          name: "refreshAndReplaceSelected",
        },
      });
      dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Successfully modified logistics invoice`,
        },
      });
    }
  };

  const ModifyOrderContent = async () => {
    const orderContent = [];

    const _srch = props.state.selDetailContentStatus.data.find(
      (x) => x.name === "Purchased"
    );
    for (const cont of POContentData) {
      //console.log("CONTENT ===> ", cont);

      orderContent.push({
        id: cont.id,
        qtyDelivered: cont.itemQty + cont.qtyDelivered,
        ...(cont.qtyOrdered ===
        cont.qtyPendingDelivered + cont.itemQty + cont.qtyDelivered
          ? { orderStatusId: Number(_srch.id) }
          : null),
      });
      await InvoiceAPI.addOrderContentQtyDelivered(
        dispatch,
        cont.id,
        cont.itemQty + cont.qtyDelivered
      );
    }
    // POContentData.forEach(async (cont) => {
    //   console.log("CONTENT ===> ", cont);

    //   orderContent.push({
    //     id: cont.id,
    //     qtyDelivered: cont.itemQty + cont.qtyDelivered,
    //     ...(cont.qtyOrdered ===
    //     cont.qtyPendingDelivered + cont.itemQty + cont.qtyDelivered
    //       ? { orderStatusId: Number(_srch.id) }
    //       : null),
    //   });
    //   await InvoiceAPI.addOrderContentQtyDelivered(
    //     dispatch,
    //     cont.id,
    //     cont.itemQty + cont.qtyDelivered
    //   );
    // });
    //console.log(orderContent);

    const data = { content: orderContent };
    await InvoiceAPI.updateInvoice(dispatch, data);
    //console.log("CREATED LOGISTICS INVOICE ===> ", createdInvoice);
    const updatedInvoice = await InvoiceAPI.getInvoice(
      dispatch,
      selectedRowInvoice.id
    );
    resetUserInputs();
    props.state.handleActionChange({
      target: {
        value: updatedInvoice,
        name: "refreshAndReplaceSelected",
      },
    });
    dispatch({
      type: "TOAST_ADD",
      payload: {
        title: `Successfully modified PO`,
      },
    });
  };

  const ReceiveLogistic = async (id) => {
    const data = { id: id, logiStatus: "finished" };
    await LogisticsAPI.receiveLogistics(dispatch, data);
    //console.log("CREATED LOGISTICS INVOICE ===> ", createdInvoice);
    const updatedInvoice = await InvoiceAPI.getInvoice(
      dispatch,
      selectedRowInvoice.id
    );
    props.state.handleActionChange({
      target: {
        value: updatedInvoice,
        name: "refreshAndReplaceSelected",
      },
    });
    dispatch({
      type: "TOAST_ADD",
      payload: {
        title: `Successfully received Logistic`,
      },
    });
    resetUserInputs();
    setLogisticsOperation(SELECTED_OPERATION.VIEW);
  };

  const ResetQtyDelivered = async (id) => {
    for (const cont of selectedRowInvoice.content) {
      await InvoiceAPI.resetOrderContentQtyDelivered(dispatch, cont.id);
      await props.state.updateInvoiceContentStatus("Processing", cont.id);
      if (cont.histOrderId) {
        await props.state.updateInvoiceContentStatus(
          "Processing",
          cont.histOrderId
        );
      }
    }
    // selectedRowInvoice.content.forEach(async (cont) => {

    // });

    const updatedInvoice = await InvoiceAPI.getInvoice(
      dispatch,
      selectedRowInvoice.id
    );
    props.state.handleActionChange({
      target: {
        value: updatedInvoice,
        name: "refreshAndReplaceSelected",
      },
    });
    dispatch({
      type: "TOAST_ADD",
      payload: {
        title: `Successfully modified PO`,
      },
    });
  };

  const validateUserInputs = (cb) => {
    //let tempUserInput = { ...validateUser };
    let error = false;

    if (SelCourier === "" || SelCourier === null) error = true;
    if (TxtPickupAddress === null || TxtPickupAddress === "") error = true;

    if (POContentData.length === 0) {
      error = true;
    }
    POContentData.forEach((cont) => {
      if (cont.qty === 0) {
        error = true;
      }
    });
    // if (
    //   SelInvoiceInventoryAccount === null ||
    //   SelInvoiceInventoryAccount === ""
    // )
    //   error = true;

    if (error)
      dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Input Notice",
          desc: `Please check if courier, pickup address and contents are not empty or 0`,
          isYesNo: false,
        },
      });
    else {
      if (cb) cb();
    }

    // console.log(tempUserInput);
  };
  const TempModifyLogisContent = (value) => {
    setPOContentData((trans) =>
      trans?.map((dat) => (dat.id === value.id ? { ...dat, ...value } : dat))
    );
  };

  const PopulateLogisticsInvoice = async (d) => {
    const fetchData = await LogisticsAPI.getLogistics(dispatch, d.id);
    // console.log(fetchData);

    const editLastName = d.lastModifiedByActor?.lastName
      ? d.lastModifiedByActor?.lastName
      : "";
    const editFirstName = d.lastModifiedByActor?.firstName
      ? d.lastModifiedByActor?.firstName
      : "";
    const addFirstName = d.createdBy?.firstName ? d.createdBy?.firstName : "";
    const addLastName = d.createdBy?.lastName ? d.createdBy?.lastName : "";

    const tempContent = [];

    for (const cont of fetchData.content) {
      tempContent.push({ ...cont, itemQty: cont.qty });
    }
    //console.log(tempContent);
    setPOContentData(tempContent);

    setTxtCourierContactPerson(d.courierContactPerson);
    setTxtCourierContactPersonNo(d.courierContactNumber);
    setSelCourier(SelCourierData.find((x) => x.id === d.courierId));
    setTxtSupplierContactPerson(d.supplierContactPerson);
    setTxtSupplierContactPersonNo(d.supplierContactNumber);
    setTxtPickupAddress(d.pickupAddress);
    setTxtETA(d.eta ? moment(d.eta).format("YYYY-MM-DD") : "");
    setLogiTxtUniqueID(d.uniqueId);
    setLogiTxtDateAdded(d.datePosted ? moment(d.datePosted).format("LLL") : "");
    setLogiTxtDateModified(
      d.datePosted ? moment(d.datePosted).format("LLL") : ""
    );
    setLogiTxtLastModifiedBy(`${editLastName} ${editFirstName}`);
    setLogiTxtAddedBy(`${addLastName} ${addFirstName}`);
    setTxtLogiNotes(d.notes);

    setTxtContainerDesc(d.containerDesc);
    setTxtContainerSize(d.containerSize);
    setSelContainerUnit(
      SelContainerUnitData.find((x) => x.id === d.containerUnitId)
    );
    setTxtPickupDate(
      d.pickupDate ? moment(d.pickupDate).format("YYYY-MM-DD") : ""
    );
    setIsLogistics(true);
  };

  return (
    <div className="mb-3 pb-3" style={{ backgroundColor: "#e9ecef" }}>
      <style>
        {`
          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            opacity: 1;
          }
          .voucherContentHeaderP {
            text-align: center;
            color: var(--REB-secondary);
            font-weight: bolder;
          }
        `}
      </style>
      <div
        style={{
          backgroundColor: "var(--REB-primary)",
          borderRadius: "5px 5px 0px 0px",
          display: "flex",
        }}
        className="p-3"
      >
        <h4
          className="m-0"
          style={{
            flex: 1,
            fontWeight: "bolder",
            color: "var(--REB-white)",
          }}
        >
          <i
            className="icofont-money pr-1"
            style={{ flex: 1, fontSize: "1.5rem" }}
          ></i>
          PO Logistics Invoice
        </h4>
      </div>
      <div>
        <Row style={{ border: "solid 1px #ced4da" }} className="" noGutters>
          <Col xs={12} sm={6} md={3} lg={3}>
            <p
              className="m-0 p-0 voucherContentHeaderP"
              style={{ textAlign: "center" }}
            >
              Actions
            </p>
          </Col>
          <Col xs={12} sm={6} md={5} lg={5}>
            <p
              className="m-0 p-0 voucherContentHeaderP"
              style={{ textAlign: "center" }}
            >
              Logistics Invoice Description
            </p>
          </Col>

          <Col xs={12} sm={6} md={2} lg={2}>
            <p
              className="m-0 p-0 voucherContentHeaderP"
              style={{ textAlign: "center" }}
            >
              Status
            </p>
          </Col>
          <Col xs={12} sm={6} md={2} lg={2}>
            <p
              className="m-0 p-0 voucherContentHeaderP"
              style={{ textAlign: "center" }}
            >
              ETA
            </p>
          </Col>
        </Row>
      </div>
      {selectedRowInvoice?.logistics.map((logi, index) => {
        const d = logi;
        let status = "finished";
        let statusDisplay = "";
        let infoColor = "";
        let icon = <></>;

        if (d.logiStatus) {
          status = d.logiStatus;
        }

        if (status === "finished") {
          statusDisplay = "Finished";
          infoColor = "var(--REB-green)";
          icon = (
            <i className="icofont-ui-check " style={{ fontSize: "1.3em" }}></i>
          );
        }
        if (status === "on-route") {
          statusDisplay = "On Route";
          infoColor = "var(--REB-secondary)";
          icon = (
            <i
              className="icofont-truck-loaded"
              style={{ fontSize: "1.3em" }}
            ></i>
          );
        }
        if (status === "admin-denied") {
          statusDisplay = "Admin Denied";
          infoColor = "var(--REB-warning)";
          icon = (
            <i className="icofont-ui-close " style={{ fontSize: "1.3em" }}></i>
          );
        }
        if (status === "pending-admin-approval") {
          statusDisplay = "Pending Admin Approval";
          infoColor = "var(--REB-secondary)";
          icon = (
            <i className="icofont-warning " style={{ fontSize: "1.3em" }}></i>
          );
        }
        if (status === "processing") {
          statusDisplay = "Processing";
          infoColor = "var(--REB-green)";
          icon = (
            <i className="icofont-money" style={{ fontSize: "1.3em" }}></i>
          );
        }

        return (
          <Row
            style={{
              border: "solid 1px #ced4da",
              backgroundColor:
                logi.id === SelectedLogistics?.id
                  ? "rgb(175, 217, 173)"
                  : "initial",
            }}
            className="py-2"
            key={logi.uniqueId}
            noGutters
          >
            <Col
              xs={12}
              sm={6}
              md={3}
              lg={3}
              className="px-2"
              style={{
                alignSelf: "center",
                textAlign: "center",
              }}
            >
              {/* <Button
              onClick={async () => {
                setLogisticsOperation(SELECTED_OPERATION.VIEW);
                await LogisticsAPI.deleteLogistics(dispatch, logi.id);
                const updatedInvoice = await InvoiceAPI.getInvoice(
                  dispatch,
                  selectedRowInvoice.id
                );
                props.state.handleActionChange({
                  target: {
                    value: updatedInvoice,
                    name: "refreshAndReplaceSelected",
                  },
                });
                dispatch({
                  type: "TOAST_ADD",
                  payload: {
                    title: `Successfully removed logistics invoice`,
                  },
                });
              }}
              style={{
                backgroundColor: "var(--REB-warning)",
              }}
              className="mx-1"
            >
              <i
                style={{ color: "var(--REB-white)" }}
                className="icofont-trash"
              ></i>
            </Button> */}

              <Button
                onClick={() => {
                  setLogisticsOperation(SELECTED_OPERATION.VIEW);
                  PopulateLogisticsInvoice(logi);
                  setSelectedLogistics(logi);
                }}
                style={{
                  backgroundColor: "var(--REB-primary)",
                  fontWeight: "bolder",
                }}
                className="mx-1"
              >
                {/* <i
                style={{ color: "var(--REB-white)" }}
                className="icofont-navigation-menu"
              ></i> */}
                View
              </Button>
              {/* {logi.logiStatus === "on-route" && (
              <Button
                onClick={() => {
                  ReceiveLogistic(logi.id);
                }}
                style={{
                  backgroundColor: "var(--REB-primary)",
                  fontWeight: "bolder",
                }}
                className="mx-1"
              >
                Receive
              </Button>
            )} */}
            </Col>
            <Col xs={12} sm={6} md={5} lg={5}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  fontSize: "0.8em",
                  height: "100%",
                  color: "var(--REB-blue)",
                  fontWeight: "bold",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <p className="m-0 p-0">Logistic Invoice # {index + 1}</p>
              </div>
            </Col>

            <Col style={{ textAlign: "center" }} xs={12} sm={6} md={2} lg={2}>
              <Badge
                style={{
                  backgroundColor: infoColor,
                  color: "white",
                  fontSize: "0.8em",
                }}
              >
                {icon} {statusDisplay}
              </Badge>
            </Col>
            <Col style={{ textAlign: "center" }} xs={12} sm={6} md={2} lg={2}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  fontSize: "0.8em",
                  height: "100%",
                  color: "var(--REB-blue)",
                  fontWeight: "bold",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {logi.eta ? moment(logi.eta).format("LL") : ""}
              </div>
            </Col>
          </Row>
        );
      })}
      <div
        style={{
          backgroundColor: "var(--REB-primary)",
          borderRadius: "5px 5px 0px 0px",
          display: "flex",
        }}
        className="p-3 mt-3"
      >
        <h4
          className="m-0"
          style={{
            flex: 1,
            fontWeight: "bolder",
            color: "var(--REB-white)",
          }}
        >
          <i
            className="icofont-money pr-1"
            style={{ flex: 1, fontSize: "1.5rem" }}
          ></i>
          PO Required Items
        </h4>
      </div>

      <div>
        <Row style={{ border: "solid 1px #ced4da" }} className="" noGutters>
          <Col xs={12} sm={6} md={2} lg={2}>
            <p
              className="m-0 p-0 voucherContentHeaderP"
              style={{ textAlign: "center" }}
            >
              Actions
            </p>
          </Col>
          <Col xs={12} sm={6} md={6} lg={4}>
            <p
              className="m-0 p-0 voucherContentHeaderP"
              style={{ textAlign: "center" }}
            >
              Item Name
            </p>
          </Col>
          <Col xs={12} sm={6} md={2} lg={2}>
            <p
              className="m-0 p-0 voucherContentHeaderP"
              style={{ textAlign: "center" }}
            >
              Qty Total Required
            </p>
          </Col>
          <Col xs={12} sm={6} md={2} lg={2}>
            <p
              className="m-0 p-0 voucherContentHeaderP"
              style={{ textAlign: "center" }}
            >
              Qty Processing
            </p>
          </Col>
          <Col xs={12} sm={6} md={2} lg={2}>
            <p
              className="m-0 p-0 voucherContentHeaderP"
              style={{ textAlign: "center" }}
            >
              Qty Delivered
            </p>
          </Col>
        </Row>
      </div>
      {selectedRowInvoice?.content.map((val) => {
        const UNIT = val?.item?.details.find((x) => x.type === 2).name;
        const ITEM_NAME = val?.item?.details.find((x) => x.type === 1).name;
        const BRAND = val?.item?.details.find((x) => x.type === 3).name;
        let image = [];
        if (val.item?.uploads !== null && val.item?.uploads !== "") {
          if (val.item?.uploads?.constructor === String)
            image = JSON.parse(val.item?.uploads);
          else {
            image = val.item?.uploads;
          }
        }
        // console.log(val);
        return (
          <div
            style={{ border: "solid 1px #ced4da" }}
            className=""
            key={val.uniqueId}
          >
            <Row noGutters>
              <Col
                xs={12}
                sm={6}
                md={2}
                lg={2}
                className="px-2"
                style={{
                  alignSelf: "center",
                  textAlign: "center",
                }}
              >
                {/* {console.log(CURRENT_USERTYPE)} */}
                {CURRENT_USERTYPE.includes("Admin") && (
                  <Button
                    style={{
                      backgroundColor: "var(--REB-yellow)",
                    }}
                    className="mx-1"
                    onClick={() => {
                      dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "Confirmation",
                          desc: `This option is for overriding only. Are you sure you want to proceed?`,
                          isYesNo: true,
                          cb: () => {
                            ResetQtyDelivered(val.id);
                          },
                        },
                      });
                    }}
                  >
                    <i
                      style={{ color: "var(--REB-black)" }}
                      className="icofont-ui-reply"
                    ></i>
                  </Button>
                )}
                {CURRENT_USERTYPE.includes("Admin") && !IsItemModify && (
                  <Button
                    style={{
                      backgroundColor: "var(--REB-yellow)",
                    }}
                    className="mx-1"
                    onClick={() => {
                      dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "Confirmation",
                          desc: `This option is for overriding only. Are you sure you want to proceed?`,
                          isYesNo: true,
                          cb: () => {
                            setIsItemModify(true);
                            setTxtHandling(val.handling);
                            setTxtShipping(val.shipping);
                            setTxtCogs(val.cogs);
                            setTxtPRItemCost(val.price);
                          },
                        },
                      });
                    }}
                  >
                    <i
                      style={{ color: "var(--REB-black)" }}
                      className="icofont-ui-edit"
                    ></i>
                  </Button>
                )}

                <Button
                  onClick={() => {
                    if (
                      props.state.permissions.includes(
                        "perm-ui-invoicingPRAccess-view-write"
                      )
                    ) {
                      let tempData = [];
                      tempData = tempData.concat(POContentData);
                      //Check for duplicate ID. Ignore if added
                      const dupe = tempData.find((x) => x.id === val.id);
                      if (!dupe) {
                        if (
                          val.qtyPendingDelivered + val.qtyDelivered ===
                          val.qtyOrdered
                        ) {
                          dispatch({
                            type: "MODAL_SHOW",
                            payload: {
                              title: "Fully Delivered",
                              desc: `This item is fully delivered`,
                              isYesNo: false,
                            },
                          });
                        } else {
                          setLogisticsOperation(SELECTED_OPERATION.ADD);
                          tempData.push({ ...val });
                          setPOContentData([...tempData]);
                        }
                      } else {
                        dispatch({
                          type: "MODAL_SHOW",
                          payload: {
                            title: "Duplicate Item",
                            desc: `Item is already added in selection`,
                            isYesNo: false,
                          },
                        });
                      }
                    } else {
                      props.state.dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "No Permission",
                          desc: `You dont have access to modify PR / PO invoices`,
                          isYesNo: false,
                        },
                      });
                    }
                  }}
                  style={{
                    backgroundColor: "var(--REB-green)",
                  }}
                  className="mx-1"
                >
                  <i
                    style={{ color: "var(--REB-white)" }}
                    className="icofont-ui-add"
                  ></i>
                </Button>
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <Row noGutters>
                  <Col xs={12} sm={12} md={4} lg={4}>
                    <img
                      style={{
                        objectFit: "contain",
                        maxWidth: "100px",
                        height: "100px",
                        maxHeight: "100px",
                      }}
                      alt=""
                      src={
                        typeof image !== "undefined" &&
                        image !== null &&
                        image.length > 0 &&
                        image !== "null"
                          ? `${
                              process.env.REACT_APP_API_URL
                            }global/getFile2?uniqueId=${
                              val.item?.uniqueId
                            }&filename=${encodeURIComponent(
                              image[0].filename
                            )}&token=${localStorage.getItem("LUT")}`
                          : emptyImg
                      }
                    />
                  </Col>
                  <Col xs={12} sm={12} md={8} lg={8}>
                    <p className="m-0 p-0 " style={{}}>
                      {ITEM_NAME}
                    </p>
                    <p className="m-0 p-0 " style={{}}>
                      {val?.item?.description}
                    </p>
                    <p className="m-0 p-0 " style={{}}>
                      {BRAND}
                    </p>
                    <p className="mt-3 p-0 " style={{ fontWeight: "bolder" }}>
                      {val?.orderStatus?.name}
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm={6} md={2} lg={2}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                    fontSize: "1.5em",
                    height: "100%",
                    color: "var(--REB-blue)",
                    fontWeight: "bold",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <p style={{ marginBottom: "0px", flex: 1 }}>
                    {val?.qtyOrdered}
                  </p>

                  <p
                    style={{
                      marginBottom: "0px",
                      fontWeight: "normal",
                      flex: 1,
                    }}
                  >
                    {UNIT}
                  </p>
                </div>
              </Col>

              <Col xs={12} sm={6} md={2} lg={2}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                    fontSize: "1.5em",
                    height: "100%",
                    color: "var(--REB-blue)",
                    fontWeight: "bold",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <p style={{ marginBottom: "0px", flex: 1 }}>
                    {val?.qtyPendingDelivered}
                  </p>

                  <p
                    style={{
                      marginBottom: "0px",
                      fontWeight: "normal",
                      flex: 1,
                    }}
                  >
                    {UNIT}
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={6} md={2} lg={2}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                    fontSize: "1.5em",
                    height: "100%",
                    color: "var(--REB-blue)",
                    fontWeight: "bold",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <p style={{ marginBottom: "0px", flex: 1 }}>
                    {val?.qtyDelivered}
                  </p>

                  <p
                    style={{
                      marginBottom: "0px",
                      fontWeight: "normal",
                      flex: 1,
                    }}
                  >
                    {UNIT}
                  </p>
                </div>
              </Col>
            </Row>
            {CURRENT_USERTYPE.includes("Admin") && (
              <Row noGutters>
                <Col lg={4} md={4} sm={12} xs={12} className="pl-2 pr-2 mb-3">
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: "var(--REB-white)",
                      borderRadius: "5px",
                      // border: "1px solid #ced4da",
                      WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                      MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                      boxShadow: "inset 0px 0px 0px 1px #ced4da",
                    }}
                  >
                    <p
                      className="m-0 pl-2"
                      style={{
                        color: "var(--REB-black)",
                        fontWeight: "bold",

                        // backgroundColor: "var(--REB-primary)",
                      }}
                    >
                      COST
                    </p>
                    <FormControl
                      as={NumericFormat}
                      inputMode="numeric"
                      thousandSeparator={true}
                      prefix={"₱ "}
                      style={{}}
                      placeholder="Selected Item Current SRP"
                      allowNegative={false}
                      value={val?.price}
                      className=""
                      disabled
                    />
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12} className="pl-2 pr-2 mb-3">
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: "var(--REB-white)",
                      borderRadius: "5px",
                      // border: "1px solid #ced4da",
                      WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                      MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                      boxShadow: "inset 0px 0px 0px 1px #ced4da",
                    }}
                  >
                    <p
                      className="m-0 pl-2"
                      style={{
                        color: "var(--REB-black)",
                        fontWeight: "bold",

                        // backgroundColor: "var(--REB-primary)",
                      }}
                    >
                      SRP
                    </p>
                    <FormControl
                      as={NumericFormat}
                      inputMode="numeric"
                      thousandSeparator={true}
                      prefix={"₱ "}
                      style={{}}
                      placeholder=" Selected Item Request Qty"
                      allowNegative={false}
                      defaultValue={val?.item?.price}
                      className=""
                      disabled
                    />
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} xs={12} className="pl-2 pr-2 mb-3">
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: "var(--REB-white)",
                      borderRadius: "5px",
                      // border: "1px solid #ced4da",
                      WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                      MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                      boxShadow: "inset 0px 0px 0px 1px #ced4da",
                    }}
                  >
                    <p
                      className="m-0 pl-2"
                      style={{
                        color: "var(--REB-black)",
                        fontWeight: "bold",

                        // backgroundColor: "var(--REB-primary)",
                      }}
                    >
                      COGS
                    </p>
                    <FormControl
                      as={NumericFormat}
                      inputMode="numeric"
                      thousandSeparator={true}
                      prefix={"₱ "}
                      style={{}}
                      placeholder=" Selected Item COGS"
                      allowNegative={false}
                      defaultValue={val?.cogs}
                      // onValueChange={(x) => {
                      //   setTxtPRItemCOGS(x.value);
                      // }}
                      className=""
                      disabled
                    />
                  </div>
                </Col>
              </Row>
            )}

            {IsItemModify && (
              <>
                <Row className="mb-2 d-flex flex-row" noGutters>
                  <Col lg={4} md={4} sm={12} xs={12} className="pl-2 pr-2 mb-3">
                    <div
                      className=""
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "var(--REB-primary)",
                        borderRadius: "5px",
                      }}
                    >
                      <InputGroup.Prepend>
                        <p
                          className="m-0 pl-2"
                          style={{
                            color: "var(--REB-white)",
                            fontWeight: "bold",

                            // backgroundColor: "var(--REB-primary)",
                          }}
                        >
                          Handling Cost
                        </p>
                      </InputGroup.Prepend>
                      <InputGroup.Prepend>
                        <FormControl
                          as={NumericFormat}
                          inputMode="numeric"
                          thousandSeparator={true}
                          prefix={"₱ "}
                          allowNegative={false}
                          style={{}}
                          placeholder="Handling Cost"
                          value={TxtHandling}
                          onValueChange={(x) => {
                            setTxtHandling(Number(x.value));
                            // props.state.inputChange({
                            //   target: {
                            //     value: x.value,
                            //     name: "txtHandlingPR",
                            //     placeholder:
                            //       props.state.txtHandlingPR.placeholder,
                            //     className:
                            //       props.state.txtHandlingPR.className,
                            //   },
                            // });
                            // props.state.handleActionChange({
                            //   target: {
                            //     name: "txtCogsPR",
                            //   },
                            // });
                          }}
                          className=""
                        />
                      </InputGroup.Prepend>
                    </div>
                  </Col>
                  <Col lg={4} md={4} sm={12} xs={12} className="pl-2 pr-2 mb-3">
                    <div
                      className=""
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "var(--REB-primary)",
                        borderRadius: "5px",
                      }}
                    >
                      <p
                        className="m-0 pl-2"
                        style={{
                          color: "var(--REB-white)",
                          fontWeight: "bold",

                          // backgroundColor: "var(--REB-primary)",
                        }}
                      >
                        Shipping Cost
                      </p>
                      <FormControl
                        as={NumericFormat}
                        inputMode="numeric"
                        thousandSeparator={true}
                        allowNegative={false}
                        prefix={"₱ "}
                        style={{}}
                        placeholder="Shipping Cost"
                        value={TxtShipping}
                        onValueChange={(x) => {
                          //  console.log(x.value);
                          setTxtShipping(Number(x.value));
                        }}
                        className=""
                      />
                    </div>
                  </Col>

                  <Col lg={4} md={4} sm={12} xs={12} className="pl-2 pr-2 mb-3">
                    <div
                      className=""
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "var(--REB-primary)",
                        borderRadius: "5px",
                      }}
                    >
                      <p
                        className="m-0 pl-2"
                        style={{
                          color: "var(--REB-white)",
                          fontWeight: "bold",

                          // backgroundColor: "var(--REB-primary)",
                        }}
                      >
                        COGS
                      </p>
                      <FormControl
                        as={NumericFormat}
                        inputMode="numeric"
                        thousandSeparator={true}
                        prefix={"₱ "}
                        style={{}}
                        placeholder="COGS"
                        allowNegative={false}
                        value={TxtCogs}
                        onValueChange={(x) => {
                          setTxtCogs(Number(x.value));
                        }}
                        className=""
                      />
                    </div>
                  </Col>
                </Row>
                <Row noGutters>
                  <Col lg={6} md={6} sm={12} xs={12} className="pl-2 pr-2 mb-3">
                    <div
                      className=""
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: TxtPRItemCost
                          ? "var(--REB-primary)"
                          : "var(--REB-warning)",
                        borderRadius: "5px",
                      }}
                    >
                      <p
                        className="m-0 pl-2"
                        style={{
                          color: "var(--REB-white)",
                          fontWeight: "bold",

                          // backgroundColor: "var(--REB-primary)",
                        }}
                      >
                        Item Cost
                      </p>
                      <FormControl
                        as={NumericFormat}
                        inputMode="numeric"
                        thousandSeparator={true}
                        prefix={"₱ "}
                        style={{}}
                        placeholder="Item Cost"
                        allowNegative={false}
                        value={TxtPRItemCost}
                        onValueChange={(x) => {
                          setTxtPRItemCost(Number(x.value));
                        }}
                        className=""
                      />
                    </div>
                  </Col>
                  <Col
                    lg={3}
                    md={3}
                    sm={12}
                    xs={12}
                    className="pl-2 pr-2 mb-3 "
                  >
                    <Button
                      block
                      //disabled={!props.state.handleInvoiceModal.isView}
                      className="h-100"
                      style={{
                        backgroundColor: "var(--REB-green)",
                      }}
                      onClick={(e) => {
                        props.state.dispatch({
                          type: "MODAL_SHOW",
                          payload: {
                            title: "Verification Confirmation",
                            desc: `Are you sure you want to Apply this changes?`,
                            isYesNo: true,
                            cb: () => {
                              if (
                                TxtPRItemCost !== null ||
                                TxtPRItemCost !== ""
                              ) {
                                modifyItemCost(val.id);
                                setIsItemModify(false);
                                setTxtHandling(null);
                                setTxtShipping(null);
                                setTxtCogs(null);
                                setTxtPRItemCost(null);
                              } else {
                                dispatch({
                                  type: "MODAL_SHOW",
                                  payload: {
                                    title: "No Item Cost Provided",
                                    desc: `Item cost cant be empty.`,
                                    isYesNo: false,
                                  },
                                });
                              }
                            },
                          },
                        });
                      }}
                    >
                      Apply Changes
                    </Button>
                  </Col>
                  <Col
                    lg={3}
                    md={3}
                    sm={12}
                    xs={12}
                    className="pl-2 pr-2 mb-3 "
                  >
                    <Button
                      block
                      //disabled={!props.state.handleInvoiceModal.isView}
                      className="h-100"
                      style={{
                        backgroundColor: "var(--REB-yellow)",
                        color: "var(--REB-black)",
                      }}
                      onClick={(e) => {
                        setIsItemModify(false);
                        setTxtHandling(null);
                        setTxtShipping(null);
                        setTxtCogs(null);
                        setTxtPRItemCost(null);
                      }}
                    >
                      CANCEL
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </div>
        );
      })}
      {(LogisticsOperation === SELECTED_OPERATION.ADD ||
        POContentData.length > 0) && (
        <>
          <div
            style={{
              backgroundColor: "var(--REB-primary)",
              borderRadius: "5px 5px 0px 0px",
              display: "flex",
            }}
            className="p-3"
          >
            <h4
              className="m-0"
              style={{
                flex: 1,
                fontWeight: "bolder",
                color: "var(--REB-white)",
              }}
            >
              <i
                className="icofont-money pr-1"
                style={{ flex: 1, fontSize: "1.5rem" }}
              ></i>
              {IsLogistics ? "Create Logistics Invoice" : "Receive Items"}
            </h4>
          </div>
          {(SelectedLogistics?.logiStatus === "on-route" ||
            SelectedLogistics?.logiStatus === "finished") && (
            <div className="p-3">
              <Button
                onClick={async () => {
                  const fetchData = await LogisticsAPI.getLogistics(
                    dispatch,
                    SelectedLogistics.id
                  );
                  console.log("SELECTED LOGISTICS ===>", SelectedLogistics);
                  console.log("FETCHED ORDER ===>", fetchData);

                  let perChunk = 8; // items per chunk

                  let result = fetchData.content.reduce(
                    (resultArray, item, index) => {
                      const chunkIndex = Math.floor(index / perChunk);
                      if (!resultArray[chunkIndex]) {
                        resultArray[chunkIndex] = []; // start a new chunk
                      }
                      resultArray[chunkIndex].push({
                        ...item,
                        index: index + 1,
                      });
                      return resultArray;
                    },
                    []
                  );
                  // For sort by index
                  // for (let index = 0; index < result.length; index++) {
                  //   const element = result[index];
                  //   result[index] = element.sort(function (a, b) {
                  //     return a.partIndex - b.partIndex;
                  //   });
                  // }
                  dispatch({
                    type: "PDF_MODAL_TOGGLE",
                    doc: Logistics.LogisticsPDF(
                      props.state,
                      fetchData,
                      result,
                      false
                    ),
                  });
                }}
                style={{
                  backgroundColor: "var(--REB-primary)",
                  fontWeight: "bolder",
                }}
                className="mx-4 p-3"
              >
                <i
                  style={{ color: "var(--REB-white)" }}
                  className="icofont-print"
                ></i>
              </Button>
              {SelectedLogistics?.logiStatus !== "finished" && (
                <Button
                  onClick={() => {
                    dispatch({
                      type: "MODAL_SHOW",
                      payload: {
                        title: "Confirmation",
                        desc: `Please make sure all items are complete and un-damaged. Receiving this items will automatically add to inventory. Are you sure you want to proceed?`,
                        isYesNo: true,
                        cb: () => {
                          ReceiveLogistic(SelectedLogistics.id);
                        },
                      },
                    });
                  }}
                  style={{
                    backgroundColor: "var(--REB-green)",
                    fontWeight: "bolder",
                  }}
                  className="p-3"
                >
                  Receive Items
                </Button>
              )}
            </div>
          )}
          <div className="p-3 my-3">
            <style>
              {`
                .chkPOSChkbx > input {
                  transform: scale(2);
                }
                .chkPOSChkbx > label {
                  padding-left: 10px;
                }
              `}
            </style>
            <Form.Check
              checked={IsLogistics}
              //disabled={props.state.chkIsPOSVisible.readOnly}
              className="chkPOSChkbx"
              name="chkIsPOSVisible"
              label="Create Logistics Invoice"
              onChange={(e) => {
                setIsLogistics(!IsLogistics);
              }}
            />
          </div>
          {IsLogistics && (
            <>
              <Row className="mt-3" noGutters>
                <Col xs={12} sm={6} md={4} lg={3} className="px-1 mb-2">
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      className="m-0 pl-2"
                      style={{
                        backgroundColor: "var(--REB-white)",
                        color: "var(--REB-black)",
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        width: "230px",
                        borderRadius: "5px 5px 0px 0px",
                        WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                        MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                        boxShadow: "inset 0px 0px 0px 1px #ced4da",
                      }}
                    >
                      Unique ID
                    </p>
                    <FormControl
                      placeholder="Unique ID"
                      className="h-100"
                      style={{ borderRadius: "0px 5px 5px 5px" }}
                      value={LogiTxtUniqueID}
                      disabled
                    />
                  </div>
                </Col>
                <Col xs={12} sm={6} md={4} lg={3} className="px-1 mb-2">
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      className="m-0 pl-2"
                      style={{
                        backgroundColor: "var(--REB-white)",
                        color: "var(--REB-black)",
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        width: "230px",
                        borderRadius: "5px 5px 0px 0px",
                        WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                        MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                        boxShadow: "inset 0px 0px 0px 1px #ced4da",
                      }}
                    >
                      Date Added
                    </p>
                    <FormControl
                      placeholder="Date Added"
                      className="h-100"
                      style={{ borderRadius: "0px 5px 5px 5px" }}
                      value={LogiTxtDateAdded}
                      disabled
                    />
                  </div>
                </Col>
                <Col xs={12} sm={6} md={4} lg={3} className="px-1 mb-2">
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      className="m-0 pl-2"
                      style={{
                        backgroundColor: "var(--REB-white)",
                        color: "var(--REB-black)",
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        width: "230px",
                        borderRadius: "5px 5px 0px 0px",
                        WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                        MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                        boxShadow: "inset 0px 0px 0px 1px #ced4da",
                      }}
                    >
                      Date Modified
                    </p>
                    <FormControl
                      placeholder="Date Modified"
                      className="h-100"
                      style={{ borderRadius: "0px 5px 5px 5px" }}
                      value={LogiTxtDateModified}
                      disabled
                    />
                  </div>
                </Col>
              </Row>
              <Row noGutters>
                <Col xs={12} sm={6} md={6} lg={6} className="px-1 mb-2">
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      // border: "1px solid #ced4da",
                    }}
                  >
                    <p
                      className="m-0 pl-2"
                      style={{
                        backgroundColor: "var(--REB-white)",
                        color: "var(--REB-black)",
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        width: "230px",
                        borderRadius: "5px 5px 0px 0px",
                        WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                        MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                        boxShadow: "inset 0px 0px 0px 1px #ced4da",
                      }}
                    >
                      Added By
                    </p>
                    <FormControl
                      placeholder="Added By"
                      className="h-100"
                      style={{ borderRadius: "0px 5px 5px 5px" }}
                      value={LogiTxtAddedBy}
                      disabled
                    />
                  </div>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} className="px-1 mb-2">
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      className="m-0 pl-2"
                      style={{
                        backgroundColor: "var(--REB-white)",
                        color: "var(--REB-black)",
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        width: "230px",
                        borderRadius: "5px 5px 0px 0px",
                        WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                        MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                        boxShadow: "inset 0px 0px 0px 1px #ced4da",
                      }}
                    >
                      Modified By
                    </p>
                    <FormControl
                      placeholder="Modified By"
                      className="h-100"
                      style={{ borderRadius: "0px 5px 5px 5px" }}
                      value={LogiTxtLastModifiedBy}
                      disabled
                    />
                  </div>
                </Col>
              </Row>
              <Row noGutters>
                <Col xs={4} className="px-1 mb-2">
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      className="m-0 pl-2"
                      style={{
                        backgroundColor: SelCourier
                          ? "var(--REB-primary)"
                          : "var(--REB-warning)",
                        color: "var(--REB-white)",
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        width: "230px",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                    >
                      Courier
                    </p>
                    <CourierSelect
                      state={{ userType: CURRENT_USERTYPE }}
                      data={SelCourierData}
                      name="selActorCourier"
                      //isLoading={props.state.suppliers.isLoading}
                      selected={SelCourier}
                      //tabIndex={7}
                      lblHidden={true}
                      refreshData={(event) => {
                        getActors();
                      }}
                      detType={13}
                      dataStructure="invoiceActor"
                      addUrl="actor/"
                      editUrl="actor/updateActor"
                      deleteUrl="actor/deleteActor"
                      componentName="Courier"
                      componentType=""
                      optionLabel="company"
                      //optionLabel="name"
                      disabled={
                        LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                        LogisticsOperation !== SELECTED_OPERATION.ADD
                      }
                      handler={(data) => {
                        // console.log(data);
                        setSelCourier(data.target.value);
                      }}
                      // disabled={props.state.selDetailUnit.readOnly}
                    />
                  </div>
                </Col>
                <Col xs={4} className="px-1 mb-2">
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      className="m-0 pl-2"
                      style={{
                        backgroundColor: true
                          ? "var(--REB-primary)"
                          : "var(--REB-warning)",
                        color: "var(--REB-white)",
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        width: "230px",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                    >
                      Courier Contact Person
                    </p>
                    <FormControl
                      type="text"
                      style={{ borderRadius: "0px 5px 5px 5px" }}
                      placeholder="Courier Contact Person"
                      value={TxtCourierContactPerson}
                      onChange={(e) => {
                        setTxtCourierContactPerson(e.currentTarget.value);
                      }}
                      disabled={
                        LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                        LogisticsOperation !== SELECTED_OPERATION.ADD
                      }
                      className=""
                    />
                  </div>
                </Col>
                <Col xs={4} className="px-1 mb-2">
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      className="m-0 pl-2"
                      style={{
                        backgroundColor: true
                          ? "var(--REB-primary)"
                          : "var(--REB-warning)",
                        color: "var(--REB-white)",
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        width: "230px",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                    >
                      Courier Contact Person #
                    </p>
                    <FormControl
                      type="text"
                      style={{ borderRadius: "0px 5px 5px 5px" }}
                      placeholder=" Courier Contact Person #"
                      value={TxtCourierContactPersonNo}
                      disabled={
                        LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                        LogisticsOperation !== SELECTED_OPERATION.ADD
                      }
                      onChange={(e) => {
                        setTxtCourierContactPersonNo(e.currentTarget.value);
                      }}
                      className=""
                    />
                  </div>
                </Col>
                <Col xs={4} className="px-1 mb-2">
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      className="m-0 pl-2"
                      style={{
                        backgroundColor: true
                          ? "var(--REB-primary)"
                          : "var(--REB-warning)",
                        color: "var(--REB-white)",
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        width: "230px",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                    >
                      Supplier Contact Person
                    </p>
                    <FormControl
                      type="text"
                      style={{ borderRadius: "0px 5px 5px 5px" }}
                      placeholder="Supplier Contact Person"
                      value={TxtSupplierContactPerson}
                      disabled={
                        LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                        LogisticsOperation !== SELECTED_OPERATION.ADD
                      }
                      onChange={(e) => {
                        setTxtSupplierContactPerson(e.currentTarget.value);
                      }}
                      className=""
                    />
                  </div>
                </Col>
                <Col xs={4} className="px-1 mb-2">
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      className="m-0 pl-2"
                      style={{
                        backgroundColor: true
                          ? "var(--REB-primary)"
                          : "var(--REB-warning)",
                        color: "var(--REB-white)",
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        width: "230px",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                    >
                      Supplier Contact Person #
                    </p>
                    <FormControl
                      type="text"
                      style={{ borderRadius: "0px 5px 5px 5px" }}
                      placeholder="Supplier Contact Person #"
                      value={TxtSupplierContactPersonNo}
                      disabled={
                        LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                        LogisticsOperation !== SELECTED_OPERATION.ADD
                      }
                      onChange={(e) => {
                        setTxtSupplierContactPersonNo(e.currentTarget.value);
                      }}
                      className=""
                    />
                  </div>
                </Col>
                <Col xs={4} className="px-1 mb-2">
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      className="m-0 pl-2"
                      style={{
                        backgroundColor: TxtPickupAddress
                          ? "var(--REB-primary)"
                          : "var(--REB-warning)",
                        color: "var(--REB-white)",
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        width: "230px",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                    >
                      Pickup Address
                    </p>
                    <FormControl
                      type="text"
                      style={{ borderRadius: "0px 5px 5px 5px" }}
                      placeholder="Pickup Address"
                      value={TxtPickupAddress}
                      disabled={
                        LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                        LogisticsOperation !== SELECTED_OPERATION.ADD
                      }
                      onChange={(e) => {
                        setTxtPickupAddress(e.currentTarget.value);
                      }}
                      className=""
                    />
                  </div>
                </Col>
                <Col xs={4} className="px-1  mb-2">
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      className="m-0 pl-2"
                      style={{
                        backgroundColor: true
                          ? "var(--REB-primary)"
                          : "var(--REB-warning)",
                        color: "var(--REB-white)",
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        width: "230px",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                    >
                      Estimated Arrival Date
                    </p>
                    <FormControl
                      type="date"
                      style={{ borderRadius: "0px 5px 5px 5px" }}
                      placeholder="Estimated Delivery Date"
                      value={TxtETA}
                      disabled={
                        LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                        LogisticsOperation !== SELECTED_OPERATION.ADD
                      }
                      onChange={(e) => {
                        setTxtETA(e.currentTarget.value);
                      }}
                      className=""
                    />
                  </div>
                </Col>
                <Col xs={4} className="px-1  mb-2">
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      className="m-0 pl-2"
                      style={{
                        backgroundColor: true
                          ? "var(--REB-primary)"
                          : "var(--REB-warning)",
                        color: "var(--REB-white)",
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        width: "230px",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                    >
                      Pickup Date
                    </p>
                    <FormControl
                      type="date"
                      style={{ borderRadius: "0px 5px 5px 5px" }}
                      placeholder="  Pickup Date"
                      value={TxtPickupDate}
                      disabled={
                        LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                        LogisticsOperation !== SELECTED_OPERATION.ADD
                      }
                      onChange={(e) => {
                        setTxtPickupDate(e.currentTarget.value);
                      }}
                      className=""
                    />
                  </div>
                </Col>
                <Col xs={4} className="px-1  mb-2"></Col>
                <Col xs={4} className="px-1  mb-2">
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      className="m-0 pl-2"
                      style={{
                        backgroundColor: true
                          ? "var(--REB-primary)"
                          : "var(--REB-warning)",
                        color: "var(--REB-white)",
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        width: "230px",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                    >
                      Container Description
                    </p>
                    <FormControl
                      type="text"
                      style={{ borderRadius: "0px 5px 5px 5px" }}
                      placeholder=" Container Description"
                      value={TxtContainerDesc}
                      disabled={
                        LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                        LogisticsOperation !== SELECTED_OPERATION.ADD
                      }
                      onChange={(e) => {
                        setTxtContainerDesc(e.currentTarget.value);
                      }}
                      className=""
                    />
                  </div>
                </Col>

                <Col xs={4} className="px-1  mb-2">
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      className="m-0 pl-2"
                      style={{
                        backgroundColor: true
                          ? "var(--REB-primary)"
                          : "var(--REB-warning)",
                        color: "var(--REB-white)",
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        width: "230px",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                    >
                      Container Size
                    </p>
                    <FormControl
                      as={NumericFormat}
                      inputMode="numeric"
                      style={{ borderRadius: "0px 5px 5px 5px" }}
                      placeholder="Container Size"
                      thousandSeparator={true}
                      suffix={` ${
                        SelContainerUnit ? SelContainerUnit?.name : ""
                      }`}
                      allowNegative={false}
                      value={TxtContainerSize}
                      disabled={
                        LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                        LogisticsOperation !== SELECTED_OPERATION.ADD
                      }
                      onValueChange={(x) => {
                        setTxtContainerSize(Number(x.value));
                      }}
                    />
                  </div>
                </Col>

                <Col xs={4} className="px-1  mb-2">
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      className="m-0 pl-2"
                      style={{
                        backgroundColor: true
                          ? "var(--REB-primary)"
                          : "var(--REB-warning)",
                        color: "var(--REB-white)",
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        width: "230px",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                    >
                      Container Unit
                    </p>
                    <UnitSelect
                      state={{ userType: CURRENT_USERTYPE }}
                      data={SelContainerUnitData}
                      name="selDetailUnit"
                      //isLoading={props.state.suppliers.isLoading}
                      selected={SelContainerUnit}
                      //tabIndex={7}
                      lblHidden={true}
                      refreshData={async (event) => {
                        getItemUnits();
                      }}
                      detType={2}
                      addUrl="inventory/createItem"
                      editUrl="inventory/updateItem"
                      deleteUrl="inventory/deleteItemDetail"
                      componentName="Unit"
                      dataStructure="itemDetailsInventory"
                      componentType=""
                      //optionLabel="name"
                      disabled={
                        LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                        LogisticsOperation !== SELECTED_OPERATION.ADD
                      }
                      handler={(data) => {
                        // console.log(data);
                        setSelContainerUnit(data.target.value);
                      }}
                      // disabled={props.state.selDetailUnit.readOnly}
                    />
                  </div>
                </Col>
                <Col xs={6} className="px-1  mb-2">
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      className="m-0 pl-2"
                      style={{
                        backgroundColor: true
                          ? "var(--REB-primary)"
                          : "var(--REB-warning)",
                        color: "var(--REB-white)",
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        width: "230px",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                    >
                      Logistics Notes
                    </p>
                    <FormControl
                      type="text"
                      style={{ borderRadius: "0px 5px 5px 5px" }}
                      //style={{ textLogiform: "uppercase" }}
                      placeholder="Logistics Notes"
                      value={TxtLogiNotes}
                      disabled={
                        LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                        LogisticsOperation !== SELECTED_OPERATION.ADD
                      }
                      onChange={(e) => {
                        setTxtLogiNotes(e.currentTarget.value);
                      }}
                      className=""
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}

          <div>
            <Row style={{ border: "solid 1px #ced4da" }} className="" noGutters>
              <Col xs={12} sm={6} md={2} lg={2}>
                <p
                  className="m-0 p-0 voucherContentHeaderP"
                  style={{ textAlign: "center" }}
                >
                  Actions
                </p>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                <p
                  className="m-0 p-0 voucherContentHeaderP"
                  style={{ textAlign: "center" }}
                >
                  Item Name
                </p>
              </Col>

              <Col xs={12} sm={6} md={4} lg={4}>
                <p
                  className="m-0 p-0 voucherContentHeaderP"
                  style={{ textAlign: "center" }}
                >
                  Qty Expected
                </p>
              </Col>
            </Row>
          </div>
          {POContentData.map((val) => {
            const UNIT = val?.item?.details.find((x) => x.type === 2).name;
            const ITEM_NAME = val?.item?.details.find((x) => x.type === 1).name;
            const BRAND = val?.item?.details.find((x) => x.type === 3).name;
            let image = [];
            if (val.item?.uploads !== null && val.item?.uploads !== "") {
              if (val.item?.uploads?.constructor === String)
                image = JSON.parse(val.item?.uploads);
              else {
                image = val.item?.uploads;
              }
            }
            return (
              <>
                <Row
                  style={{ border: "solid 1px #ced4da" }}
                  className="py-2"
                  key={val.uniqueId}
                  noGutters
                >
                  <Col
                    xs={12}
                    sm={6}
                    md={2}
                    lg={2}
                    className="px-2"
                    style={{
                      alignSelf: "center",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      onClick={() => {
                        let tempData = [];
                        tempData = tempData.concat(POContentData);

                        const filtered = tempData.filter(
                          (x) => x.id !== val.id
                        );
                        setPOContentData([...filtered]);
                        if (filtered.length === 0) {
                          resetUserInputs();
                        }
                      }}
                      disabled={LogisticsOperation !== SELECTED_OPERATION.ADD}
                      style={{
                        backgroundColor: "var(--REB-warning)",
                      }}
                      className="mx-1"
                    >
                      <i
                        style={{ color: "var(--REB-white)" }}
                        className="icofont-trash"
                      ></i>
                    </Button>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <Row noGutters>
                      <Col xs={12} sm={12} md={2} lg={2}>
                        <img
                          style={{
                            objectFit: "contain",
                            maxWidth: "100px",
                            height: "100px",
                            maxHeight: "100px",
                          }}
                          alt=""
                          src={
                            typeof image !== "undefined" &&
                            image !== null &&
                            image.length > 0 &&
                            image !== "null"
                              ? `${
                                  process.env.REACT_APP_API_URL
                                }global/getFile2?uniqueId=${
                                  val.item?.uniqueId
                                }&filename=${encodeURIComponent(
                                  image[0].filename
                                )}&token=${localStorage.getItem("LUT")}`
                              : emptyImg
                          }
                        />
                      </Col>
                      <Col xs={12} sm={12} md={10} lg={10}>
                        <p className="m-0 p-0 " style={{}}>
                          {ITEM_NAME}
                        </p>
                        <p className="m-0 p-0 " style={{}}>
                          {val?.item?.description}
                        </p>
                        <p className="m-0 p-0 " style={{}}>
                          {BRAND}
                        </p>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} sm={6} md={4} lg={4}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                        fontSize: "1.5em",
                        height: "100%",
                        color: "var(--REB-blue)",
                        fontWeight: "bold",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {/* <Button
                          onClick={() => {
                            if (Number(val.qtyDelivered) > 1) {
                              TempModifyLogisContent({
                                id: val.id,
                                qtyDelivered: Number(val.qtyDelivered) - 1,
                              });
                            }
                          }}
                          style={{
                            backgroundColor: "var(--REB-warning)",
                          }}
                          className="mx-1"
                        >
                          <i
                            style={{ color: "var(--REB-white)" }}
                            className="icofont-minus"
                          ></i>
                        </Button> */}
                        {/* <p style={{ marginBottom: "0px", flex: 1 }}>
                    {val?.qtyDelivered}
                  </p> */}
                        <FormControl
                          as={NumericFormat}
                          inputMode="numeric"
                          type="text"
                          placeholder="QTY Expected"
                          thousandSeparator={true}
                          suffix={` ${UNIT}`}
                          allowNegative={false}
                          value={val.itemQty}
                          style={{ textAlign: "center" }}
                          isAllowed={(values) => {
                            const { formattedValue, floatValue } = values;
                            const reqQty =
                              Number(val.qtyOrdered) -
                              (Number(val.qtyPendingDelivered) +
                                Number(val.qtyDelivered));
                            // console.log(
                            //   val.qtyOrdered,
                            //   val.qtyPendingDelivered,
                            //   val.qtyDelivered
                            // );
                            return (
                              formattedValue === "" ||
                              (floatValue <= reqQty && floatValue >= 0)
                            );
                          }}
                          onValueChange={(x) => {
                            TempModifyLogisContent({
                              id: val.id,
                              itemQty: Number(x.value),
                            });
                          }}
                          disabled={
                            LogisticsOperation !== SELECTED_OPERATION.ADD
                          }
                        />
                        {/* <Button
                          onClick={() => {
                            TempModifyLogisContent({
                              id: val.id,
                              qtyDelivered: Number(val.qtyDelivered) + 1,
                            });
                          }}
                          style={{
                            backgroundColor: "var(--REB-green)",
                          }}
                          className="mx-1"
                        >
                          <i
                            style={{ color: "var(--REB-white)" }}
                            className="icofont-ui-add"
                          ></i>
                        </Button> */}
                      </div>

                      <p
                        style={{
                          marginBottom: "0px",
                          fontWeight: "normal",
                          flex: 1,
                        }}
                      >
                        {UNIT}
                      </p>
                    </div>
                  </Col>
                </Row>
              </>
            );
          })}
          {IsLogistics && (
            <>
              <Row className="my-3" noGutters>
                {(LogisticsOperation === SELECTED_OPERATION.ADD ||
                  LogisticsOperation === SELECTED_OPERATION.EDIT) && (
                  <Col xs={12} sm={12} md={4} lg={4} className="px-1">
                    <Button
                      style={{ backgroundColor: "var(--REB-green)" }}
                      onClick={() => {
                        LogisticsOperation === SELECTED_OPERATION.ADD &&
                          validateUserInputs(() => {
                            createLogistics();
                          });
                        LogisticsOperation === SELECTED_OPERATION.EDIT &&
                          validateUserInputs(() => {
                            modifyLogistics();
                          });
                      }}
                      block
                    >
                      Save Logistics
                    </Button>
                  </Col>
                )}
                {LogisticsOperation === SELECTED_OPERATION.VIEW && (
                  <Col xs={12} sm={12} md={4} lg={4} className="px-1">
                    <Button
                      style={{ backgroundColor: "var(--REB-green)" }}
                      onClick={() => {
                        setLogisticsOperation(SELECTED_OPERATION.EDIT);
                      }}
                      block
                    >
                      Modify
                    </Button>
                  </Col>
                )}
                {LogisticsOperation === SELECTED_OPERATION.EDIT && (
                  <Col xs={12} sm={12} md={4} lg={4} className="px-1">
                    <Button
                      style={{ backgroundColor: "var(--REB-warning)" }}
                      onClick={async () => {
                        dispatch({
                          type: "MODAL_SHOW",
                          payload: {
                            title: "Confirmation",
                            desc: `Are you sure you want to remove this Logistics invoice? Doing so will reset the QTY and sets items status to processing`,
                            isYesNo: true,
                            cb: async () => {
                              await LogisticsAPI.deleteLogistics(
                                dispatch,
                                SelectedLogistics.id
                              );

                              const updatedInvoice =
                                await InvoiceAPI.getInvoice(
                                  dispatch,
                                  selectedRowInvoice.id
                                );
                              // await props.state.updateInvoiceContentStatus(
                              //   "Processing",
                              //   selectedRowInvoice.id
                              // );
                              resetUserInputs();
                              props.state.handleActionChange({
                                target: {
                                  value: updatedInvoice,
                                  name: "refreshAndReplaceSelected",
                                },
                              });
                              dispatch({
                                type: "TOAST_ADD",
                                payload: {
                                  title: `Successfully removed logistics invoice`,
                                },
                              });
                            },
                          },
                        });
                      }}
                      block
                    >
                      Delete
                    </Button>
                  </Col>
                )}

                {(LogisticsOperation === SELECTED_OPERATION.ADD ||
                  LogisticsOperation === SELECTED_OPERATION.EDIT ||
                  LogisticsOperation === SELECTED_OPERATION.VIEW) && (
                  <Col xs={12} sm={12} md={4} lg={4} className="px-1">
                    <Button
                      style={{
                        backgroundColor: "var(--REB-yellow)",
                        color: "var(--REB-black)",
                      }}
                      onClick={() => {
                        resetUserInputs();
                        setLogisticsOperation(SELECTED_OPERATION.VIEW);
                      }}
                      block
                    >
                      Cancel
                    </Button>
                  </Col>
                )}
              </Row>
            </>
          )}
          {!IsLogistics && (
            <>
              <Row className="my-3 px-3" noGutters>
                <Col xs={12} sm={12} md={4} lg={4} className="px-2">
                  <Button
                    block
                    onClick={() => {
                      ModifyOrderContent();
                    }}
                  >
                    Receive Items
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} className="px-2">
                  <Button
                    block
                    onClick={() => {
                      resetUserInputs();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </div>
  );
};
let PaymentContainer = (props) => {
  let paymentSelectStyle = {
    menu: (provided, state) => {
      //console.log(state);
      const width = "100%";

      let color = state.selectProps.menuColor;
      const padding = 0;

      return {
        ...provided,
        width,
        color,
        padding,

        //backgroundColor: "red",
      };
    },
    control: (provided) => ({
      ...provided,
      // borderRadius: "0px 0px 0px 0px",
    }),

    container: (_, { selectProps: { width } }) => ({
      width: width,
      flex: 1,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 6000,

      borderRadius: "5px 0px 0px 5px",
    }),
    menuList: (provided) => ({
      ...provided,
      zIndex: 6000,

      borderRadius: "5px 0px 0px 5px",
    }),

    option: (provided, state) => {
      let backgroundColor = "";
      let display = "block";
      let color = "black";
      if (state.data.isDefault === 1) {
        backgroundColor = "rgba(255, 0, 0, 0.2)";
      }
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      //console.log(state.data);
      if (state.data.isHidden === 1) {
        display = "none";
      }

      return {
        ...provided,
        opacity,
        transition,
        backgroundColor,
        color,
        display,
      };
    },
  };

  const {
    userType,
    user,
    selectedRowInvoice,
    selectedInvoicePayments,
    settings,
  } = props.state;

  const selectedInvoiceID = props.state.selectedRowInvoice
    ? Number(props.state.selectedRowInvoice.id)
    : null;
  let paymentData = [];

  if (selectedRowInvoice)
    paymentData =
      selectedInvoicePayments !== null ? selectedInvoicePayments : [];

  // CASH = 'cash',
  // CHECK = 'check',
  // POSTDATED_CHECK = 'postdated-check',
  // BANK_DEPOSIT = 'bank-deposit',
  // ELECTRONIC_BANK_TRANSFER = 'electronic-bank-transfer',
  // DEBIT_CARD = 'debit-card',
  // CREDIT_CARD = 'credit-card',
  // PREPAID_CARD = 'prepaid-card',
  // COD = 'cod',
  // MOBILE_WALLET = 'mobile-wallet',

  const PAYMENT_TYPES = [
    { value: "cash", label: "Cash" },
    { value: "check", label: "Check" },
    { value: "post-dated-check", label: "Post Dated Cheque" },
    { value: "bank-deposit", label: "Bank Deposit" },
    { value: "electronic-bank-transfer", label: "Bank Deposit" },
    { value: "debit-card", label: "Debit Card" },
    { value: "credit-card", label: "Credit Card" },
    { value: "prepaid-card", label: "Pre Paid Card" },
    { value: "cod", label: "Cash on Delivery (COD)" },
    { value: "mobile-wallet", label: "Mobile Wallet" },
  ];

  const dispatch = useDispatch();

  const [invoiceStatus, setInvoiceStatus] = useState(null);

  //console.log(props.state)

  useEffect(() => {
    if (props.state.selectedRowInvoice) {
      if (props.state.selectedRowInvoice.invoiceStatus) {
        if (props.state.selectedRowInvoice.invoiceStatus.name) {
          setInvoiceStatus(props.state.selectedRowInvoice.invoiceStatus.name);
        }
      }

      if (settings) {
        setSelInvoiceBankAccount(settings.POBankAccount);

        setSelInvoiceActsPayable(settings.POAcctsPayableAccount);
        setSelInvoiceExpenseAccount(settings.POExpenseAccount);
      }
    }
  }, [props.state.selectedRowInvoice]);

  //const user = useSelector((state) => state.apiData.userData);

  const [operation, setOperation] = useState("VIEW");
  const [txtAmount, setTxtAmount] = useState(0);
  const [TxtChkNumber, setTxtChkNumber] = useState("");
  const [txtDate, setTxtDate] = useState(null);
  const [txtType, setTxtType] = useState({ value: "cash", label: "Cash" });
  const [ChkDownPayment, setChkDownPayment] = useState(false);
  const [TxtPaymentNotes, setTxtPaymentNotes] = useState("");
  const [TxtPaymentBankName, setTxtPaymentBankName] = useState("");
  const [TxtPaymentBankAccount, setTxtPaymentBankAccount] = useState("");

  const [SelInvoiceBankAccount, setSelInvoiceBankAccount] = useState(null);

  const [SelInvoiceExpenseAccount, setSelInvoiceExpenseAccount] =
    useState(null);

  // const [SelInvoiceInventoryAccount, setSelInvoiceInventoryAccount] =
  //   useState(null);

  const [SelInvoiceActsPayable, setSelInvoiceActsPayable] = useState(null);

  const resetUserInputs = () => {
    setOperation("VIEW");
    setTxtAmount(0);
    setTxtChkNumber("");
    setTxtDate(null);
    setTxtType({ value: "cash", label: "Cash" });
    setChkDownPayment(false);
    setTxtPaymentNotes("");
    setTxtPaymentBankName("");
    setTxtPaymentBankAccount("");
    // setSelInvoiceIncome(null);
    // setSelInvoiceInventoryAccount(null);
    // setSelInvoiceActsReceivable(null);
  };

  const validateUserInputs = (cb) => {
    //let tempUserInput = { ...validateUser };
    let error = false;

    if (txtAmount === 0 || txtAmount === null) error = true;
    if (txtDate === null || txtDate === "") error = true;
    if (txtType === null || txtType === "") error = true;
    if (SelInvoiceBankAccount === null || SelInvoiceBankAccount === "")
      error = true;
    if (SelInvoiceActsPayable === null || SelInvoiceActsPayable === "")
      error = true;
    if (SelInvoiceExpenseAccount === null || SelInvoiceExpenseAccount === "")
      error = true;
    // if (
    //   SelInvoiceInventoryAccount === null ||
    //   SelInvoiceInventoryAccount === ""
    // )
    //   error = true;

    if (error)
      dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Input Notice",
          desc: `Please make sure Amount, Accounts, Date and Payment Type fields are not empty and is valid`,
          isYesNo: false,
        },
      });
    else if (cb) cb();

    // console.log(tempUserInput);
  };

  return (
    <div className="mb-3 pb-3" style={{ backgroundColor: "#e9ecef" }}>
      <div
        style={{
          backgroundColor: "var(--REB-primary)",
          borderRadius: "5px 5px 0px 0px",
          display: "flex",
        }}
        className="p-3"
      >
        <h4
          className="m-0"
          style={{
            flex: 1,
            fontWeight: "bolder",
            color: "var(--REB-white)",
          }}
        >
          <i
            className="icofont-money pr-1"
            style={{ flex: 1, fontSize: "1.5rem" }}
          ></i>
          PO Payments
        </h4>
        {(() => {
          let show = true;
          paymentData.forEach((e) => {
            if (e.transStatus === "pending-admin-approval") {
              show = false;
            }
            if (e.transStatus === "admin-denied") {
              show = false;
            }
            if (e.transStatus === "processing") {
              show = false;
            }
          });
          if (paymentData.length === 0) {
            show = false;
          }
          return (
            <>
              {invoiceStatus === "Pending Payment To Supplier" && show && (
                <Button
                  className="animate__pulse animate__animated animate__infinite	infinite"
                  onClick={() => {
                    props.state.handleActionChange({
                      target: {
                        //value: updatedInvoice,
                        name: "btnSetInvoiceForDelivery",
                      },
                    });
                  }}
                  style={{
                    backgroundColor: "var(--REB-yellow)",
                    color: "var(--REB-black)",
                  }}
                >
                  <i
                    className="icofont-delivery-time pr-1"
                    style={{ fontSize: "1.2rem" }}
                  ></i>
                  Set PO For Delivery
                </Button>
              )}
            </>
          );
        })()}
        {/* {paymentData !== null &&
          paymentData.length > 0 &&
          invoiceStatus === "Pending Payment To Supplier" && (
            <Button
              className="animate__pulse animate__animated animate__infinite	infinite"
              onClick={() => {
                props.state.handleActionChange({
                  target: {
                    //value: updatedInvoice,
                    name: "btnSetInvoiceForDelivery",
                  },
                });
              }}
              style={{
                backgroundColor: "var(--REB-yellow)",
                color: "var(--REB-black)",
              }}
            >
              <i
                className="icofont-delivery-time pr-1"
                style={{ fontSize: "1.2rem" }}
              ></i>
              Set PO For Delivery
            </Button>
          )} */}
      </div>

      <Row
        style={
          {
            // backgroundColor: "var(--REB-yellow)",
            // borderRadius: "5px 5px 0px 0px",
          }
        }
        className="px-3 pt-2 pb-1"
        noGutters
      >
        <Col xs={6} sm={6} md={6} lg={6} className="px-1">
          <Form.Label
            className="font-weight-bold"
            style={{ color: "var(--REB-black)" }}
          >
            Total Amount Due
          </Form.Label>
          <FormControl
            as={NumericFormat}
            inputMode="numeric"
            thousandSeparator={true}
            prefix={"₱ "}
            decimalScale={2}
            fixedDecimalScale={true}
            allowNegative={false}
            // style={{ flex: 2 }}
            // placeholder={
            //   props.isItem
            //     ? `SRP: ₱ ${
            //         props.state.selContentItems.value !== ""
            //           ? props.state.selContentItems.value.price
            //           : ""
            //       } `
            //     : `SERVICE COST`
            // }
            name="txtAmountPaid"
            value={Number(props.state.invoiceTotalAmountDue)}
            // onValueChange={(x) => {
            //   props.state.inputChange({
            //     target: {
            //       value: x.value,
            //       name: "txtAmountPaid",
            //       // placeholder: props.state.txtPaidAmount.placeholder,
            //       // className: props.state.txtPaidAmount.className,
            //     },
            //   });
            // }}
            className={props.state.txtAmountPaid.className}
            disabled
            //readOnly={props.state.txtAmountPaid.readOnly}
          />
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} className="px-1">
          <Form.Label
            className="font-weight-bold"
            style={{ color: "var(--REB-black)" }}
          >
            Invoice Total
          </Form.Label>
          <FormControl
            as={NumericFormat}
            inputMode="numeric"
            thousandSeparator={true}
            prefix={"₱ "}
            allowNegative={false}
            defaultValue={props.state.grandTotal}
            disabled
          />
        </Col>
        <Col xs={12} sm={2} md={2} lg={2} className="px-1">
          {operation === "VIEW" &&
            Number(props.state.invoiceTotalAmountDue) > 0 && (
              <Button
                block
                className="h-100 mt-2"
                style={{
                  backgroundColor: "var(--REB-tertiary)",
                  fontSize: "0.8rem",
                }}
                onClick={() => {
                  if (
                    props.state.permissions.includes(
                      "perm-ui-invoicePaymentAccess-view-write"
                    )
                  ) {
                    setOperation("ADD");
                    setTxtDate(moment().format("YYYY-MM-DD"));
                  } else {
                    props.state.dispatch({
                      type: "MODAL_SHOW",
                      payload: {
                        title: "No Permission",
                        desc: `You dont have access to add PR / PO Payment`,
                        isYesNo: false,
                      },
                    });
                  }
                }}
              >
                Add Payment
              </Button>
            )}
          {operation === "ADD" && (
            <>
              <Button
                block
                style={{ backgroundColor: "var(--REB-yellow)", color: "black" }}
                className="mt-2"
                onClick={() => {
                  setTxtAmount(null);
                  setTxtDate(null);
                  setTxtType(null);
                  setOperation("VIEW");
                }}
              >
                Cancel
              </Button>

              <Button
                block
                style={{
                  backgroundColor: "var(--REB-green)",
                  color: "var(--REB-white)",
                }}
                onClick={async () => {
                  validateUserInputs(async () => {
                    setOperation("VIEW");
                    const uid = filters.uuidv4();
                    const inventoryId =
                      Number(props.state.selInventory.value.id) === 1
                        ? null
                        : Number(props.state.selInventory.value.id);
                    let isPartialPayment = true;
                    //full payment
                    if (
                      Number(txtAmount) !==
                      Number(props.state.invoiceTotalAmountDue)
                    ) {
                      isPartialPayment = true;
                    } else {
                      isPartialPayment = false;
                    }
                    // ===== ACTS PAYABLE =========
                    // const actsReceivable = await TransactionsAPI.getAccountByName(
                    //   dispatch,
                    //   "Accounts Receivable Invoice",
                    //   props.state.selInventory.value.id
                    // );

                    let actsPayableTransaction = null;

                    if (isPartialPayment) {
                      const accountPayableValue =
                        Number(props.state.grandTotal) - Number(txtAmount);
                      actsPayableTransaction = {
                        description: `Partial Payment - ${selectedRowInvoice.supplier?.company}`,
                        paymentDate: txtDate,
                        transStatus: "finished",
                        paymentType: "cash",
                        isEntry: 1,
                        uid: uid,
                        amountValue: Number(accountPayableValue),
                        grossAmount: Number(accountPayableValue),
                        netAmount: Number(accountPayableValue),
                        taxAmount: 0,
                        transType: `credit`,
                        supplierId: selectedRowInvoice.supplierId,
                        accountId: SelInvoiceActsPayable.id,
                        orderId: selectedRowInvoice.id,
                        inventoryId: inventoryId,
                        notes: TxtPaymentNotes,
                        checkInfo1: TxtChkNumber,
                        isDownPayment: Number(ChkDownPayment),
                      };
                      await TransactionsAPI.createTransaction(
                        dispatch,
                        { transaction: actsPayableTransaction },
                        (dat) => {
                          console.log("CREATED TRANSACTION ===>", dat);
                        }
                      );
                    } else {
                      const accountPayableValue = Number(txtAmount) * -1;
                      actsPayableTransaction = {
                        description: `Full Payment - ${selectedRowInvoice.supplier?.company}`,
                        paymentDate: txtDate,
                        transStatus: "finished",
                        paymentType: "cash",
                        isEntry: 1,
                        amountValue: Number(accountPayableValue),
                        grossAmount: Number(accountPayableValue),
                        netAmount: Number(accountPayableValue),
                        taxAmount: 0,
                        uid: uid,
                        transType: `debit`,
                        supplierId: selectedRowInvoice.supplierId,
                        accountId: SelInvoiceActsPayable.id,
                        orderId: selectedRowInvoice.id,
                        inventoryId: inventoryId,
                        notes: TxtPaymentNotes,
                        checkInfo1: TxtChkNumber,
                        isDownPayment: Number(ChkDownPayment),
                      };
                      //console.log(paymentData);
                      //Trigger only if there is an existing transaction
                      if (paymentData.length > 0) {
                        await TransactionsAPI.createTransaction(
                          dispatch,
                          { transaction: actsPayableTransaction },
                          (dat) => {
                            console.log(
                              "CREATED ACTS PAYABLE TRANSACTION ===>",
                              dat
                            );
                          }
                        );
                      }
                    }
                    console.log(
                      "PRE-POST TRANSACTION ===>",
                      actsPayableTransaction
                    );

                    // ===== INVENTORY ASSET DEBIT =====
                    // const inventoryAssetAccount = await TransactionsAPI.getAccountByName(
                    //   dispatch,
                    //   "Accounts Receivable Invoice",
                    //   props.state.selInventory.value.id
                    // );
                    // const inventoryAssetValue =
                    //   Number(props.state.grandTotal) * -1;
                    // actsReceivableTransaction = {
                    //   description: `Inventory Entry - ${selectedRowInvoice.client.company}`,
                    //   paymentDate: txtDate,
                    //   transStatus: "finished",
                    //   paymentType: "cash",
                    //   isEntry: 1,
                    //   isInventoryAssetDeductEntry: 1,
                    //   amountValue: inventoryAssetValue,
                    //   grossAmount: inventoryAssetValue,
                    //   netAmount: inventoryAssetValue,
                    //   taxAmount: 0,
                    //   uid: uid,
                    //   transType: `credit`,
                    //   clientId: selectedRowInvoice.clientId,
                    //   accountId: SelInvoiceInventoryAccount.id,
                    //   orderId: selectedRowInvoice.id,
                    //   inventoryId: inventoryId,
                    //   notes: TxtPaymentNotes,
                    //   checkInfo1: TxtChkNumber,
                    //   isDownPayment: Number(ChkDownPayment),
                    // };

                    // // Execute only if no isInventoryAssetDeductEntry can be found in payment history
                    // let inventoryDeduct = false;
                    // inventoryDeduct = paymentData.find(
                    //   (x) => x.isInventoryAssetDeductEntry === 1
                    // );
                    // if (!inventoryDeduct) {
                    //   await TransactionsAPI.createTransaction(
                    //     dispatch,
                    //     actsReceivableTransaction,
                    //     (dat) => {
                    //       console.log("CREATED TRANSACTION ===>", dat);
                    //     }
                    //   );
                    // }

                    // ===== EXPENSE DEBIT =========

                    // const invoiceSales = await TransactionsAPI.getAccountByName(
                    //   dispatch,
                    //   "Invoice Sales",
                    //   props.state.selInventory.value.id
                    // );

                    if (SelInvoiceExpenseAccount.id) {
                      const expenseTransContent = {
                        uid: filters.uuidv4(),
                        discount: 0,
                        amountValue: Number(txtAmount),
                        grossAmount: Number(txtAmount),
                        netAmount: Number(txtAmount),
                        taxAmount: 0,
                        partName: `${
                          isPartialPayment ? "Partial Payment" : "Full Payment"
                        } - ${selectedRowInvoice?.supplier?.company}`,
                        partDesc: `PO #: ${
                          selectedRowInvoice?.uniqueId
                        } Date: ${moment(selectedRowInvoice?.dateAdded).format(
                          "LL"
                        )}`,
                        partUnit: null,
                        partQty: 1,
                        partIndex: 1,
                        contentType: "item",
                        notes: "",
                        inventoryId: selectedRowInvoice.inventoryId,
                      };
                      const invoiceExpenseTransaction = {
                        description: `${
                          isPartialPayment ? "Partial Payment" : "Full Payment"
                        } - ${selectedRowInvoice.supplier?.company}`,
                        paymentDate: txtDate,
                        transStatus: "pending-admin-approval",
                        paymentType: txtType.value,
                        amountValue: Number(txtAmount),
                        grossAmount: Number(txtAmount),
                        netAmount: Number(txtAmount),
                        taxAmount: 0,
                        uid: uid,
                        isEntry: 0,
                        // isInventoryAssetDeductEntry: 1,
                        transType: "debit",
                        supplierId: selectedRowInvoice.supplierId,
                        accountId: SelInvoiceExpenseAccount.id,
                        orderId: selectedRowInvoice.id,
                        inventoryId: inventoryId,
                        notes: TxtPaymentNotes,
                        checkInfo1: TxtChkNumber,
                        paymentBankName: TxtPaymentBankName,
                        paymentBankNumber: TxtPaymentBankAccount,

                        isDownPayment: Number(ChkDownPayment),
                      };
                      console.log(
                        "PRE-POST EXPENSE TRANSACTION ===>",
                        invoiceExpenseTransaction,
                        expenseTransContent
                      );

                      await TransactionsAPI.createTransaction(
                        dispatch,
                        {
                          transaction: invoiceExpenseTransaction,
                          content: [expenseTransContent],
                        },
                        (dat) => {
                          console.log("CREATED EXPENSE TRANSACTION ===>", dat);
                        }
                      );
                      actorApi.sendEmail(
                        dispatch,
                        `${
                          isPartialPayment ? "Partial Payment" : "Full Payment"
                        } - ${selectedRowInvoice.supplier?.company}`,
                        selectedRowInvoice.incharge?.lastName,
                        selectedRowInvoice.inventoryId
                      );
                    }
                    // ===== BANK CREDIT =========
                    if (SelInvoiceBankAccount.id) {
                      const invoiceSalesTransaction = {
                        description: `${
                          isPartialPayment ? "Partial Payment" : "Full Payment"
                        } - ${selectedRowInvoice.supplier?.company}`,
                        paymentDate: txtDate,
                        transStatus: "finished",
                        paymentType: txtType.value,
                        amountValue: Number(txtAmount) * -1,
                        grossAmount: Number(txtAmount) * -1,
                        netAmount: Number(txtAmount) * -1,
                        taxAmount: 0,
                        uid: uid,
                        isEntry: 1,
                        // isInventoryAssetDeductEntry: 1,
                        transType: "credit",
                        supplierId: selectedRowInvoice.supplierId,
                        accountId: SelInvoiceBankAccount.id,
                        orderId: selectedRowInvoice.id,
                        inventoryId: inventoryId,
                        notes: TxtPaymentNotes,
                        checkInfo1: TxtChkNumber,
                        isDownPayment: Number(ChkDownPayment),
                      };
                      console.log(
                        "PRE-POST TRANSACTION ===>",
                        invoiceSalesTransaction
                      );

                      await TransactionsAPI.createTransaction(
                        dispatch,
                        { transaction: invoiceSalesTransaction },
                        (dat) => {
                          console.log("CREATED TRANSACTION ===>", dat);
                        }
                      );
                    }
                    // ===== INVENTORY ASSET CREDIT =========

                    // const updatedInvoice = await InvoiceAPI.addPayment(
                    //   dispatch,
                    //   selectedInvoiceID,
                    //   {
                    //     amount: Number(txtAmount),
                    //     date: txtDate,
                    //     type: txtType.value,
                    //     isDownPayment: Number(ChkDownPayment),
                    //     paymentNotes: TxtPaymentNotes,
                    //     chequeDetails: TxtChkNumber,
                    //   },
                    //   () => {
                    //     dispatch({
                    //       type: "TOAST_ADD",
                    //       payload: {
                    //         title: `Invoice Payment Added`,
                    //       },
                    //     });
                    //   }
                    // );
                    //===== Update Selected Invoice =====

                    const updatedInvoice = await InvoiceAPI.getInvoice(
                      dispatch,
                      selectedRowInvoice.id
                    );
                    console.log("UPDATED INVOICE ===> ", updatedInvoice);
                    props.state.handleActionChange({
                      target: {
                        value: updatedInvoice,
                        name: "refreshAndReplaceSelected",
                      },
                    });
                    resetUserInputs();
                  });
                }}
              >
                Save Payment
              </Button>
            </>
          )}
        </Col>
      </Row>
      {operation === "ADD" && (
        <Row
          // className={`mt-1 mb-2 animate__animated ${
          //   operation === "ADD" && "animate__fadeInRight "
          // }${operation === "VIEW" && "animate__fadeOutRight "}`}
          // className={()=>{
          //   switch (operation) {
          //     case 'ADD':
          //       return('mt-1 mb-2 animate__animated animate__fadeInRight')
          //       break;
          //       case 'VIEW':
          //         return('mt-1 mb-2 animate__animated ')
          //         break;
          //     default:
          //       break;
          //   }
          // }
          className={
            operation === "ADD"
              ? "mt-1 mb-2 mx-2 animate__animated animate__fadeInRight"
              : "mt-1 mb-2 mx-2 animate__animated animate__fadeOutRight"
          }
          noGutters
        >
          <Col xs={12} sm={12} md={4} lg={4} className="px-1 mb-2">
            <Form.Label
              className="font-weight-bold"
              style={{ color: "var(--REB-black)" }}
            >
              Amount Paid
            </Form.Label>
            <FormControl
              as={NumericFormat}
              inputMode="numeric"
              placeholder="Amount Paid"
              thousandSeparator={true}
              prefix={"₱ "}
              allowNegative={false}
              value={Number(txtAmount)}
              // isAllowed={(values) => {
              //   const { formattedValue, floatValue } = values;
              //   const amountDue = Number(props.state.invoiceTotalAmountDue);
              //   //console.log(SRP);
              //   return (
              //     formattedValue === "" ||
              //     (floatValue <= amountDue && floatValue >= 0)
              //   );
              // }}
              onValueChange={(x) => {
                //On change limit user input to maximum amount
                // const amountDue = Number(props.state.invoiceTotalAmountDue);
                setTxtAmount(Number(x.value));
                // if (amountDue - Number(x.value) >= 0) {
                //   setTxtAmount(x.value);
                // } else {
                //   setTxtAmount(amountDue);
                // }
              }}
            />
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} className="px-1 mb-2">
            <Form.Label
              className="font-weight-bold"
              style={{ color: "var(--REB-black)" }}
            >
              Date
            </Form.Label>
            <FormControl
              type="date"
              value={txtDate}
              onChange={(x) => {
                setTxtDate(x.currentTarget.value);
              }}
            />
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} className="px-1 mb-2">
            <Form.Label
              className="font-weight-bold"
              style={{ color: "var(--REB-black)" }}
            >
              Payment Type
            </Form.Label>
            <Select
              value={txtType}
              // isMulti
              //styles={styles}
              styles={paymentSelectStyle}
              menuPortalTarget={document.body}
              name="colors"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={setTxtType}
              options={PAYMENT_TYPES}
            />
          </Col>
          {/* {console.log(txtType)} */}
          <Row className="mt-2 w-100" noGutters>
            {paymentData.length === 0 && (
              <Col
                xs={12}
                sm={12}
                md={4}
                lg={4}
                className="p-3"
                style={{ alignSelf: "center" }}
              >
                <style>
                  {`
                    @media (max-width: 991px) {
                    }
                    @media (min-width: 992px) {
                    }
                    .ChkDownPayment > input {
                      transform: scale(2);
                    }
                    .ChkDownPayment > label {
                      padding-left: 10px;
                    }
                  `}
                </style>

                <Form.Check
                  checked={ChkDownPayment}
                  name="ChkDownPayment"
                  className="ChkDownPayment"
                  label="Down Payment"
                  onChange={(e) => {
                    setChkDownPayment(!ChkDownPayment);
                  }}
                />
              </Col>
            )}

            <Col xs={12} sm={12} md={4} lg={4} className="px-1 mb-2">
              <Form.Label
                className="font-weight-bold"
                style={{ color: "var(--REB-black)" }}
              >
                Check Number
              </Form.Label>
              <FormControl
                type="text"
                //style={{ textTransform: "uppercase" }}
                placeholder="Check Number"
                name="TxtChkNumber"
                value={TxtChkNumber}
                onChange={(e) => setTxtChkNumber(e.currentTarget.value)}
                className=""
              />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} className="px-1 mb-2">
              <Form.Label
                className="font-weight-bold"
                style={{ color: "var(--REB-black)" }}
              >
                Payment Notes
              </Form.Label>
              <FormControl
                type="text"
                //style={{ textTransform: "uppercase" }}
                placeholder="Payment Notes"
                name="TxtPaymentNotes"
                value={TxtPaymentNotes}
                onChange={(e) => setTxtPaymentNotes(e.currentTarget.value)}
                className=""
              />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} className="px-1 mb-2">
              <Form.Label
                className="font-weight-bold"
                style={{ color: "var(--REB-black)" }}
              >
                Payment Bank Name
              </Form.Label>
              <FormControl
                type="text"
                //style={{ textTransform: "uppercase" }}
                placeholder="Payment Bank Account Name"
                name="TxtPaymentBankName"
                value={TxtPaymentBankName}
                onChange={(e) => setTxtPaymentBankName(e.currentTarget.value)}
                className=""
              />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} className="px-1 mb-2">
              <Form.Label
                className="font-weight-bold"
                style={{ color: "var(--REB-black)" }}
              >
                Payment Bank Account Number
              </Form.Label>
              <FormControl
                type="text"
                //style={{ textTransform: "uppercase" }}
                placeholder="Payment Bank Account Number"
                name="TxtPaymentBankAccount"
                value={TxtPaymentBankAccount}
                onChange={(e) =>
                  setTxtPaymentBankAccount(e.currentTarget.value)
                }
                className=""
              />
            </Col>

            <Col xs={12} sm={12} md={4} lg={4} className="px-1 mb-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      SelInvoiceBankAccount
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)"
                    }`,
                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Account Bank Account
                </p>
                <Select
                  value={SelInvoiceBankAccount}
                  // isMulti
                  //styles={styles}
                  styles={paymentSelectStyle}
                  menuPortalTarget={document.body}
                  name="colors"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={props.state.accounts.revenues}
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.id}
                  onChange={(x, name) => {
                    setSelInvoiceBankAccount(x);
                  }}
                />
              </div>
            </Col>
            {/* <Col xs={4} className="px-1 my-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: `${
                    SelInvoiceInventoryAccount
                      ? "var(--REB-primary)"
                      : "var(--REB-warning)"
                  }`,
                  borderRadius: "5px",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    // backgroundColor: "var(--REB-primary)",
                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}
                >
                  Inventory Account
                </p>
                <Select
                  value={SelInvoiceInventoryAccount}
                  // isMulti
                  //styles={styles}
                  styles={paymentSelectStyle}
                  menuPortalTarget={document.body}
                  name="colors"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={props.state.accounts.assets}
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.id}
                  onChange={(x, name) => {
                    //setSelInvoiceIncome(x);
                  }}
                />
              </div>
            </Col> */}
            <Col xs={12} sm={12} md={4} lg={4} className="px-1 mb-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      SelInvoiceActsPayable
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)"
                    }`,
                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Accounts Payable Account
                </p>
                <Select
                  value={SelInvoiceActsPayable}
                  // isMulti
                  //styles={styles}
                  styles={paymentSelectStyle}
                  menuPortalTarget={document.body}
                  name="colors"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={props.state.accounts.liabilities}
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.id}
                  onChange={(x, name) => {
                    setSelInvoiceActsPayable(x);
                  }}
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} className="px-1 mb-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: `${
                      SelInvoiceActsPayable
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)"
                    }`,
                    color: "var(--REB-white)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  Expense Account
                </p>
                <Select
                  value={SelInvoiceExpenseAccount}
                  // isMulti
                  //styles={styles}
                  styles={paymentSelectStyle}
                  menuPortalTarget={document.body}
                  name="colors"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={props.state.accounts.expenses}
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x.id}
                  onChange={(x, name) => {
                    setSelInvoiceExpenseAccount(x);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Row>
      )}
      <div style={{}} className="my-2">
        {paymentData.map((val, index) => {
          // console.log(val);
          return (
            <Row
              style={{
                ...(val.transStatus === "finished"
                  ? {
                      backgroundColor: "#AFD9AD",
                    }
                  : null),
                ...(val.transStatus === "admin-denied"
                  ? {
                      backgroundColor: "#D98880",
                    }
                  : null),
                ...(val.transStatus === "pending-admin-approval"
                  ? {
                      backgroundColor: "#D7BDE2",
                    }
                  : null),
                ...(val.transStatus === "processing"
                  ? {
                      backgroundColor: "#81DDE8",
                    }
                  : null),
                borderRadius: "5px",
                border: "1px solid rgb(206, 212, 218)",
              }}
              className="mx-3 my-1"
              noGutters
              key={filters.uuidv4()}
            >
              <Col
                xs={12}
                sm={12}
                md={3}
                lg={3}
                style={{ alignSelf: "center" }}
              >
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Tooltip id={`actor-tooltip-add`}>Remove Payment</Tooltip>
                  }
                >
                  <Button
                    onClick={() => {
                      let isAllowed = false;

                      const allowed = ["Admin", "Executive", "Manager"];
                      isAllowed = userType.some((el) => allowed.includes(el));
                      if (
                        props.state.permissions.includes(
                          "perm-ui-invoicePaymentAccess-view-write-manage-admin"
                        )
                      ) {
                        isAllowed = true;
                      }
                      isAllowed
                        ? dispatch({
                            type: "MODAL_SHOW",
                            payload: {
                              title: "Confirmation",
                              desc: `Are you sure you want to delete this payment ?`,
                              isYesNo: true,
                              cb: async (e) => {
                                await TransactionsAPI.deleteTransactionByUid(
                                  dispatch,
                                  val.uid,

                                  () => {
                                    dispatch({
                                      type: "TOAST_ADD",
                                      payload: {
                                        title: `Invoice Payment Removed`,
                                      },
                                    });
                                  }
                                );
                                const updatedInvoice =
                                  await InvoiceAPI.getInvoice(
                                    dispatch,
                                    selectedRowInvoice.id
                                  );
                                console.log(
                                  "UPDATED INVOICE ===> ",
                                  updatedInvoice
                                );
                                props.state.handleActionChange({
                                  target: {
                                    value: updatedInvoice,
                                    name: "refreshAndReplaceSelected",
                                  },
                                });
                              },
                            },
                          })
                        : dispatch({
                            type: "MODAL_SHOW",
                            payload: {
                              title: "No Permission",
                              desc: `You are not allowed to remove payments`,
                              isYesNo: false,
                            },
                          });
                    }}
                    style={{ backgroundColor: "transparent" }}
                  >
                    <i
                      style={{ color: "var(--REB-warning)" }}
                      className="icofont-ui-close"
                    ></i>
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Tooltip id={`actor-tooltip-add`}>
                      Export / Print Payment
                    </Tooltip>
                  }
                >
                  <Button
                    onClick={async () => {
                      let isAllowed = false;

                      const allowed = ["Admin", "Executive", "Manager"];
                      isAllowed = userType.some((el) => allowed.includes(el));
                      if (
                        props.state.permissions.includes(
                          "perm-ui-invoicePaymentAccess-view"
                        )
                      ) {
                        isAllowed = true;
                      }
                      if (!isAllowed) {
                        dispatch({
                          type: "MODAL_SHOW",
                          payload: {
                            title: "No Permission",
                            desc: `You are not allowed to print payment vouchers`,
                            isYesNo: false,
                          },
                        });
                      } else {
                        const fetchedTrans =
                          await TransactionsAPI.getTransaction(
                            dispatch,
                            val.id
                          );
                        let voucherType = "Cash";
                        if (fetchedTrans.paymentType === "Cash") {
                          voucherType = "Cash";
                        } else {
                          voucherType = "Check";
                        }
                        let perChunk = 8; // items per chunk

                        let result = fetchedTrans.content.reduce(
                          (resultArray, item, index) => {
                            const chunkIndex = Math.floor(index / perChunk);
                            if (!resultArray[chunkIndex]) {
                              resultArray[chunkIndex] = []; // start a new chunk
                            }
                            resultArray[chunkIndex].push({
                              ...item,
                              index: index + 1,
                            });
                            return resultArray;
                          },
                          []
                        );
                        for (let index = 0; index < result.length; index++) {
                          const element = result[index];
                          result[index] = element.sort(function (a, b) {
                            return a.partIndex - b.partIndex;
                          });
                        }

                        if (fetchedTrans) {
                          //console.log(fetchedTrans);
                          dispatch({
                            type: "PDF_MODAL_TOGGLE",
                            doc: Voucher.Voucher(
                              fetchedTrans.createdBy,
                              voucherType,
                              fetchedTrans.uniqueId,
                              fetchedTrans.order?.supplier
                                ? fetchedTrans.order?.supplier
                                : fetchedTrans.supplierVendor,
                              fetchedTrans.order?.dateAdded,
                              fetchedTrans.amountValue,
                              fetchedTrans.createdBy,
                              fetchedTrans.approvedBy,
                              result,
                              fetchedTrans.paymentBankName,
                              fetchedTrans.paymentBankNumber,
                              fetchedTrans.checkInfo1,
                              fetchedTrans.transStatus
                            ),
                          });
                        } else {
                          dispatch({
                            type: "MODAL_SHOW",
                            payload: {
                              title: "Error Fetching Data",
                              desc: `Please Contact Administrators. Error in route getTransaction`,
                              isYesNo: false,
                            },
                          });
                        }
                      }
                    }}
                    style={{ backgroundColor: "transparent" }}
                  >
                    <i
                      style={{ color: "var(--REB-primary)" }}
                      className="icofont-print"
                    ></i>
                  </Button>
                </OverlayTrigger>
                {val.transStatus === "finished" && (
                  <p
                    className="p-2 m-0"
                    style={{ color: "var(--REB-primary)" }}
                  >
                    Finished
                  </p>
                )}
                {val.transStatus === "pending-admin-approval" && (
                  <p className="p-2 m-0" style={{ color: "var(--REB-black)" }}>
                    Pending Admin Approval
                  </p>
                )}
                {val.transStatus === "admin-denied" && (
                  <p className="p-2 m-0" style={{ color: "var(--REB-black)" }}>
                    Admin Denied
                  </p>
                )}
                {val.transStatus === "processing" && (
                  <p className="p-2 m-0" style={{ color: "var(--REB-black)" }}>
                    Processing
                  </p>
                )}
              </Col>
              <Col
                xs={12}
                sm={12}
                md={3}
                lg={3}
                style={{ alignSelf: "center" }}
                className="pt-1 px-2"
              >
                {val.isDownPayment === 1 && "DP "}
                <NumericFormat
                  value={val.amountValue}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₱ "}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </Col>
              <Col
                xs={12}
                sm={12}
                md={3}
                lg={3}
                style={{ alignSelf: "center" }}
                className="pt-1 px-2"
              >
                {val.paymentType}

                {val.checkInfo1 && (
                  <p className="m-0 p-0"> check #:{val.checkInfo1}</p>
                )}
              </Col>
              <Col
                xs={12}
                sm={12}
                md={3}
                lg={3}
                style={{ alignSelf: "center" }}
                className="pt-1 px-2"
              >
                {moment(val.paymentDate).format("LL")}
                {val.paymentBankName && (
                  <p className="m-0 p-0">{val.paymentBankName}</p>
                )}
                {val.paymentBankNumber && (
                  <p className="m-0 p-0">Act #: {val.paymentBankNumber}</p>
                )}
                {val.notes && <p>{val.notes}</p>}
              </Col>
            </Row>
          );
        })}
      </div>
    </div>
  );
};

function TypePR(props) {
  const [isItem, setIsItem] = useState(null);
  const [isNew, setIsNew] = useState(true);
  const [isAdd, setIsAdd] = useState(null);
  const [isDeny, setIsDeny] = useState(false);

  let customStyles = {
    menu: (provided, state) => {
      //console.log(state);
      const width = "100%";

      let color = state.selectProps.menuColor;
      const padding = 0;

      return { ...provided, width, color, padding };
    },
    control: (provided) => ({
      ...provided,
      borderRadius: "5px",
    }),

    container: (_, { selectProps: { width } }) => ({
      width: width,
      flex: 1,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,

      borderRadius: "5px 0px 0px 5px",
    }),

    option: (provided, state) => {
      let backgroundColor = "";
      let display = "block";
      let color = "black";
      if (state.data.isDefault === 1) {
        backgroundColor = "rgba(255, 0, 0, 0.2)";
      }
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      //console.log(state.data);
      if (state.data.isHidden === 1) {
        display = "none";
      }

      return {
        ...provided,
        opacity,
        transition,
        backgroundColor,
        color,
        display,
      };
    },
  };

  return (
    <>
      <Row noGutters>
        <Col className="pl-2 pr-2" md={12} xs={12} sm={12} lg={12}>
          <StatusSelect
            state={props.state}
            data={props.state.selDetailStatus.data}
            name="selDetailStatus"
            containerClassname="mb-3"
            //isLoading={props.state.suppliers.isLoading}
            selected={props.state.selDetailStatus.value}
            //tabIndex={7}
            lblHidden={false}
            refreshData={(event) => {
              props.state.refreshItemDetail("selDetailStatus", 3);
            }}
            detType={3}
            addUrl="inventory/createItem"
            editUrl="inventory/updateItem"
            deleteUrl="inventory/deleteItemDetail"
            componentName="Invoice Status"
            isDropHidden={true}
            componentType=""
            optionLabel="name"
            getOptionLabel={(x) => x.name}
            getOptionValue={(x) => x.id}
            handler={props.state.inputChange}
            disabled={
              props.state.selDetailStatus.readOnly ||
              !props.state.userType.includes("Admin")
            }
          />
        </Col>
        <Col className="pl-2 pr-2" md={12} xs={12} sm={12} lg={12}>
          <SupplierSelect
            state={props.state}
            data={props.state.selDetailSupplier.data}
            name="selDetailSupplier"
            containerClassname="mb-3"
            //isLoading={props.state.suppliers.isLoading}
            selected={props.state.selDetailSupplier.value}
            //tabIndex={7}
            lblHidden={false}
            refreshData={(event) => {
              props.state.getSuppliersClients();
            }}
            //detType={3}
            addUrl="actor/"
            editUrl="actor/updateActor"
            deleteUrl="actor/deleteActor"
            componentName="Supplier"
            detType={7}
            dataStructure="invoiceActor"
            componentType=""
            optionLabel="company"
            handler={props.state.inputChange}
            disabled={props.state.selDetailSupplier.readOnly}
          />
        </Col>
        <Col className="pl-2 pr-2" md={12} xs={12} sm={12} lg={12}>
          <InchargeSelect
            state={props.state}
            data={props.state.selDetailIncharge.data}
            name="selDetailIncharge"
            containerClassname="mb-3"
            //isLoading={props.state.suppliers.isLoading}
            selected={props.state.selDetailIncharge.value}
            //tabIndex={7}
            lblHidden={false}
            refreshData={(event) => {
              props.state.refreshItemDetail("selDetailIncharge", 3);
            }}
            detType={3}
            addUrl="inventory/createItem"
            editUrl="inventory/updateItem"
            deleteUrl="inventory/deleteItemDetail"
            componentName="Incharge"
            isDropHidden={true}
            componentType=""
            optionLabel="name"
            handler={props.state.inputChange}
            disabled={
              props.state.selDetailIncharge.readOnly ||
              !props.state.userType.includes("Admin")
            }
          />
        </Col>
      </Row>
      <Row className="mb-3" noGutters>
        {props.state.selectedOperation.id === 1 ? (
          <>
            <Col className="pl-2 pr-2">
              <TxtDateAddedPR state={props.state} />
            </Col>
            <Col className="pl-2 pr-2">
              <TxtDateLastModifiedPR state={props.state} />
            </Col>
            <Col className="pl-2 pr-2">
              <TxtDateFinishedPR state={props.state} />
            </Col>
          </>
        ) : null}

        <Col className="pl-2 pr-2">
          <TxtDateDeadlinePR state={props.state} />
        </Col>
      </Row>
      <Row className="mb-3" noGutters>
        <Col className="pl-2 pr-2">
          <TxtNotesPR state={props.state} />
        </Col>
      </Row>
      {props.state?.selectedOperation?.name === "Edit" && (
        <PaymentContainer state={props.state} />
      )}
      {props.state.selDetailType.value.name === "Purchase Order" && (
        <>
          <LogisticsContainer state={props.state} />
          {/* {props.tablePR} */}
        </>
      )}

      {props.state.selDetailType.value.name === "Purchase Request" && (
        <>
          <div style={{ backgroundColor: "#e9ecef" }}>
            <div
              style={{
                backgroundColor: "var(--REB-primary)",
                borderRadius: "5px 5px 0px 0px",
              }}
              className="p-3"
            >
              <h4
                style={{ fontWeight: "bolder", color: "var(--REB-white)" }}
                className="m-0"
                hidden={props.state.userType.includes("Inventory Clerk")}
              >
                {props.state.selDetailType.value.name === "Purchase Request"
                  ? "PR Information"
                  : "PO Information"}
              </h4>
            </div>

            <Row className="" style={{}}>
              <Col>
                {/* <p className="m-0 px-3 mt-3">
              Please select a requested item inside Requested Items Table to
              start.
            </p> */}
                <Row
                  noGutters
                  hidden={props.state.userType.includes("Inventory Clerk")}
                  className="px-3 mt-3"
                >
                  <Col md={12} lg={4} className="pl-2 pr-2 mb-2">
                    <div
                      className=""
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <p
                        className="m-0 pl-2"
                        style={{
                          backgroundColor: "var(--REB-primary)",
                          color: "var(--REB-white)",
                          fontWeight: "bold",
                          fontSize: "0.8rem",
                          width: "230px",
                          borderRadius: "5px 5px 0px 0px",
                        }}
                      >
                        SELECTED INVENTORY
                      </p>
                      <Select
                        styles={customStyles}
                        isSearchable
                        menuPortalTarget={document.body}
                        width="100%"
                        options={props.state.selInventory.data}
                        value={props.state.selInventory.value}
                        isDisabled={props.state.selInventory.readOnly}
                        getOptionLabel={(x) => x.name}
                        getOptionValue={(x) => x.id}
                        //formatOptionLabel={"test"}
                        //innerRef={this.props.propRef}
                        onChange={(x, name) => {
                          //console.log(x, name);

                          props.state.handleActionChange({
                            target: {
                              value: x,
                              name: "handleInventoryChange",
                            },
                          });
                          // this.props.handler(x, name, {
                          //   target: { name: this.props.name },
                          // });
                        }}
                      />
                    </div>
                  </Col>
                  <Col
                    md={12}
                    lg={6}
                    className="pl-2 pr-2"
                    hidden={!isItem && !isNew}
                  >
                    <Form.Label
                      className="m-0"
                      style={{ fontWeight: "bolder" }}
                    >
                      Selected Item
                    </Form.Label>
                    <FormControl
                      placeholder="Please select an item"
                      defaultValue={props.state.txtSelectedItemPR.value}
                      disabled={true}
                    />

                    {/* <InputGroup.Prepend hidden={isAdd}>
                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Tooltip id={`actor-tooltip-add`}>
                          ADD ITEM TO PR
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="secondary"
                        name="btnAddInvoice"
                        disabled={props.state.selContentItems.readOnly}
                        className="btnAddInvoice icofont-ui-add icofont-1x"
                        style={{
                          backgroundColor: "var(--REB-primary)",
                        }}
                        onClick={(e) => {
                          console.log(props.state.selContentItems);
                          if (props.state.selContentItems.value === null) {
                            props.state.dispatch({
                              type: "MODAL_SHOW",
                              payload: {
                                title: "No selected item",
                                desc: `Please select an item to add to PR`,
                                isYesNo: false,
                              },
                            });
                          } else {
                            props.state.handleActionChange({
                              target: {
                                name: "btnAddInvoiceContentQuote",
                                value: e,
                              },
                            });
                            setIsItem(true);
                            setIsNew(false);
                            setIsAdd(true);
                          }

                          //props.state.handleItemModal.toggle();
                        }}
                      />
                    </OverlayTrigger>
                  </InputGroup.Prepend> */}
                  </Col>
                </Row>
                {props.state.userType &&
                  props.state.userType.includes("Admin") && (
                    <>
                      {props.state.PRItemsTableSelection.length > 0 && (
                        <Row className="mb-3">
                          <Col>{props.PRItemHistoryTable}</Col>
                        </Row>
                      )}
                    </>
                  )}

                {props.state.userType &&
                  props.state.userType.includes("Admin") && (
                    <>
                      {props.state.PRItemsTableShowPrices && (
                        <CogsDetails state={props.state} hidden={!isAdd} />
                      )}
                    </>
                  )}

                <Row className="mb-3 mt-3" noGutters>
                  {isDeny ? (
                    <>
                      <Col md={6} lg={6}>
                        <TxtNotesPRContent
                          state={props.state}
                          isItem={isItem}
                        />
                      </Col>
                      <Col
                        md={6}
                        lg={6}
                        className=" d-flex justify-content-end"
                      >
                        <OverlayTrigger
                          placement={"top"}
                          overlay={
                            <Tooltip id={`actor-tooltip-add`}>
                              This will set the item status to denied
                            </Tooltip>
                          }
                        >
                          <Button
                            name="btnDeclineItemRequestPRSave"
                            //disabled={!props.state.handleInvoiceModal.isView}
                            style={{
                              backgroundColor: "var(--REB-warning)",
                              height: "fit-content",
                            }}
                            className="mr-1 ml-1"
                            onClick={(e) => {
                              props.state.dispatch({
                                type: "MODAL_SHOW",
                                payload: {
                                  title: "Deny Confirmation",
                                  desc: `Are you sure you want to deny this item request?`,
                                  isYesNo: true,
                                  cb: () => {
                                    setIsDeny(false);
                                    props.state.handleActionChange({
                                      target: {
                                        name: "btnDeclineItemRequestPRSave",
                                      },
                                    });
                                  },
                                },
                              });
                            }}
                          >
                            DENY SELECTED REQUEST
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement={"top"}
                          overlay={
                            <Tooltip id={`actor-tooltip-add`}>Cancel</Tooltip>
                          }
                        >
                          <Button
                            name="btnCancelItemRequestPR"
                            //disabled={!props.state.handleInvoiceModal.isView}
                            style={{
                              backgroundColor: "var(--REB-yellow)",
                              color: "var(--REB-black)",
                              height: "fit-content",
                            }}
                            className="mr-1 ml-1"
                            onClick={(e) => {
                              setIsDeny(false);
                              props.state.handleActionChange({
                                target: {
                                  name: "btnCancelItemRequestPR",
                                },
                              });
                            }}
                          >
                            CANCEL
                          </Button>
                        </OverlayTrigger>
                      </Col>
                    </>
                  ) : null}
                  <Col
                    md={12}
                    lg={6}
                    hidden={!isAdd}
                    className="mb-3 pl-2 pr-2"
                  >
                    <TxtQty state={props.state} isItem={isItem} />
                  </Col>
                  <Col md={8} lg={6} hidden={!isAdd} className="mb-3 pl-2 pr-2">
                    {props.state.PRItemsTableShowPrices ? (
                      <CostPrice state={props.state} isItem={isItem} />
                    ) : (
                      <h5>
                        This item exist in your selection. Price will be copied.
                      </h5>
                    )}
                  </Col>
                  <Col
                    md={4}
                    lg={12}
                    className=" d-flex justify-content-end pl-2 pr-2"
                  >
                    <OverlayTrigger
                      placement={"top"}
                      overlay={<Tooltip id={`actor-tooltip-add`}>EDIT</Tooltip>}
                    >
                      <Button
                        name="btnUnlockInvoiceContent"
                        //disabled={!props.state.handleInvoiceModal.isView}
                        style={{ backgroundColor: "var(--REB-green)" }}
                        className="mr-1 ml-1"
                        hidden={
                          isAdd ||
                          props.state.invoiceContentTableSelection.length === 0
                        }
                        onClick={(e) => {
                          if (
                            props.state.selectedRowInvoiceSelection.itemId ===
                            null
                          ) {
                            setIsItem(false);
                          } else {
                            setIsItem(true);
                          }
                          setIsNew(false);
                          setIsAdd(true);

                          props.state.handleActionChange({
                            target: { name: "btnUnlockInvoiceContent" },
                          });
                        }}
                      >
                        EDIT SELECTED ITEM
                      </Button>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement={"top"}
                      overlay={<Tooltip id={`actor-tooltip-add`}>SAVE</Tooltip>}
                    >
                      <Button
                        name="btnSubmitItemToPR"
                        // disabled={props.state.handleInvoiceModal.isView}
                        style={{ backgroundColor: "var(--REB-green)" }}
                        className="mr-1 ml-1"
                        hidden={!isAdd}
                        onClick={(e) => {
                          if (
                            // props.state.txtNetPricePR.value === "" ||
                            // props.state.txtNetPricePR.value === null ||
                            props.state.txtQtyPR.value === "" ||
                            props.state.txtQtyPR.value === null
                          ) {
                            props.state.dispatch({
                              type: "MODAL_SHOW",
                              payload: {
                                title: "Input Empty",
                                desc: `Please make sure that QTY and COST has value`,
                                isYesNo: false,
                              },
                            });
                          } else {
                            props.state.handleActionChange(e, isItem);
                            setIsAdd(false);
                            setIsNew(true);

                            props.state.handleActionChange(
                              {
                                target: { name: "btnCancelItemPR" },
                              },
                              null
                            );
                          }
                        }}
                      >
                        ADD TO PR
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Tooltip id={`actor-tooltip-add`}>CANCEL</Tooltip>
                      }
                    >
                      <Button
                        name="btnCancelItemPR"
                        style={{
                          backgroundColor: "var(--REB-yellow)",
                          color: "var(--REB-black)",
                        }}
                        className="mr-1 ml-1"
                        // disabled={
                        //   props.state.handleInvoiceModal.isView ||
                        //   Number(props.state.selectedOperation.id) === 0
                        // }
                        hidden={!isAdd}
                        onClick={(e) => {
                          props.state.handleActionChange(e);
                          setIsAdd(false);
                          setIsNew(true);
                        }}
                      >
                        CANCEL
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <Row className="mb-3" noGutters>
            <Col className="d-flex justify-content-end">
              {props.state.isSelectedContentLocked ? (
                <OverlayTrigger
                  placement={"top"}
                  overlay={
                    <Tooltip id={`actor-tooltip-add`}>
                      THIS SHOULD ONLY BE USED FOR OVERRIDE. THIS MIGHT CAUSE PR
                      DUPLICATION.
                    </Tooltip>
                  }
                >
                  <Button
                    name="btnUnlockPRItem"
                    style={{
                      backgroundColor: "var(--REB-green)",
                      //color: "var(--REB-black)",
                      //height: "45%",
                    }}
                    className="mr-2"
                    disabled={props.state.PRItemsTableSelection.length === 0}
                    onClick={(e) => {
                      props.state.handleActionChange(e);
                      setIsAdd(false);
                      setIsNew(true);
                    }}
                  >
                    UNLOCK SELECTED ITEM
                  </Button>
                </OverlayTrigger>
              ) : null}
              {props.state.PRItemsTableSelection.length > 0 &&
              !isAdd &&
              !props.state.isSelectedContentLocked &&
              !isDeny ? (
                <div className="mt-3">
                  <OverlayTrigger
                    placement={"top"}
                    overlay={
                      <Tooltip id={`actor-tooltip-add`}>
                        ADD SELECTED ITEM TO PR. This will lock the selected
                        item for certain amount of time. Only you can modify
                        this item for certain amount of time.
                      </Tooltip>
                    }
                  >
                    <Button
                      name="btnAddItemToPR"
                      style={{
                        backgroundColor: "var(--REB-green)",
                        //color: "var(--REB-black)",
                        //height: "45%",
                      }}
                      className="mr-2"
                      disabled={props.state.PRItemsTableSelection.length === 0}
                      onClick={(e) => {
                        props.state.handleActionChange(e);
                        setIsItem(true);
                        setIsNew(false);
                        setIsAdd(true);
                      }}
                    >
                      ADD SELECTED ITEM TO PR
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement={"top"}
                    overlay={
                      <Tooltip id={`actor-tooltip-add`}>
                        DECLINE ITEM REQUEST
                      </Tooltip>
                    }
                  >
                    <Button
                      name="btnDeclineItemRequestPR"
                      style={{
                        backgroundColor: "var(--REB-yellow)",
                        color: "var(--REB-black)",
                      }}
                      disabled={props.state.PRItemsTableSelection.length === 0}
                      className="ml-2"
                      onClick={(e) => {
                        props.state.handleActionChange(e);
                        setIsAdd(false);
                        setIsNew(true);
                        setIsDeny(true);
                      }}
                    >
                      DECLINE SELECTED REQUEST
                    </Button>
                  </OverlayTrigger>
                </div>
              ) : // <div
              //   style={{
              //     marginRight: "20px",
              //     marginLeft: "20px",
              //     marginTop: "20px",
              //     flexDirection: "column",
              //     display: "flex",
              //   }}
              // >

              //   {/* <OverlayTrigger
              //     placement={"top"}
              //     overlay={
              //       <Tooltip id={`actor-tooltip-add`}>
              //         REMOVE SELECTED ITEM FROM PR
              //       </Tooltip>
              //     }
              //   >
              //     <Button
              //       name="btnRemoveItemToPR"
              //       style={{
              //         backgroundColor: "var(--REB-warning)",
              //         //color: "var(--REB-black)",
              //         height: "45%",
              //       }}
              //       disabled={props.state.PRItemsTableSelection.length === 0}
              //       className="icofont-ui-remove"
              //       onClick={(e) => {
              //         props.state.handleActionChange(e);
              //         setIsAdd(false);
              //         setIsNew(true);
              //       }}
              //     ></Button>
              //   </OverlayTrigger> */}
              // </div>
              null}
            </Col>
          </Row>

          {props.state.selectedOperation.id !== 1 && (
            <div className="mb-3" style={{ backgroundColor: "#e9ecef" }}>
              <Row
                style={{
                  backgroundColor: "var(--REB-primary)",
                  borderRadius: "5px 5px 0px 0px",
                  display: "flex",
                }}
                className="p-3"
                noGutters
              >
                <Col xs={12} sm={12} md={6} lg={6}>
                  <h4
                    className="m-0"
                    style={{
                      fontWeight: "bolder",
                      //flex: 2,
                      color: "var(--REB-white)",
                    }}
                  >
                    Requested Items
                  </h4>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <InputGroup className="" style={{}}>
                    <InputGroup.Prepend>
                      <InputGroup.Text className="actor-ico-search">
                        <i className="icofont-search icofont-1x"></i>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      placeholder="Search"
                      aria-label="searchInputPR"
                      name="searchInputPR"
                      className="actor-searchTable shadow-none"
                      onChange={props.state.handleSearchPRReqItems}
                    />
                  </InputGroup>
                </Col>
              </Row>
              <div></div>
              {props.tablePRItems}
            </div>
          )}
          <div className="mb-3" style={{ backgroundColor: "#e9ecef" }}>
            <div
              style={{
                backgroundColor: "var(--REB-primary)",
                borderRadius: "5px 5px 0px 0px",
              }}
              className="p-3"
            >
              <h4
                className="m-0"
                style={{ fontWeight: "bolder", color: "var(--REB-white)" }}
              >
                {props.state.selDetailType.value.name === "Purchase Request"
                  ? "PR Contents"
                  : "PO Contents"}
              </h4>
            </div>
            {props.state.selDetailType.value.name === "asd" ? (
              <div
                style={{
                  backgroundColor: "var(--REB-primary)",
                  borderRadius: 0,
                  padding: 30,
                }}
              >
                <Row>
                  <Col>
                    <h5
                      style={{
                        fontWeight: "bolder",
                        color: "var(--REB-white)",
                      }}
                    >
                      Consolidate PO Items
                    </h5>
                  </Col>
                  <Col>
                    <TxtQtyDeliveredPO state={props.state} />
                  </Col>
                  <Col>
                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Tooltip id={`actor-tooltip-add`}>
                          Make sure the selected item arrived at delivery
                          location and ready to be encoded before proceeding.
                          This action is irreversable.
                        </Tooltip>
                      }
                    >
                      <Button
                        name="btnAddDeliveredToItemPO"
                        className=""
                        style={{
                          backgroundColor: "var(--REB-green)",
                          fontWeight: "bolder",
                        }}
                        // disabled={
                        //   props.state.handleInvoiceModal.isView ||
                        //   Number(props.state.selectedOperation.id) === 0
                        // }
                        // hidden={
                        //   props.state.PRTableSelection.length === 0 ||
                        //   props.state.PRItemsTableSelection.length > 0
                        // }
                        onClick={async (e) => {
                          e.stopPropagation();
                          props.state.handleActionChange({
                            target: { name: "btnAddDeliveredToItemPO" },
                          });
                          // const updatedInvoice = await InvoiceAPI.getInvoice(
                          //   props.state.dispatch,
                          //   props.state.selectedRowInvoice.id
                          // );
                          // console.log("UPDATED INVOICE ===> ", updatedInvoice);
                          // props.state.handleActionChange({
                          //   target: {
                          //     value: updatedInvoice,
                          //     name: "refreshAndReplaceSelected",
                          //   },
                          // });
                          // props.state.handleActionChange(
                          //   {
                          //     target: { name: "btnCancelInvoiceContentQuote" },
                          //   },
                          //   null
                          // );
                        }}
                      >
                        ADD DELIVERED TO ITEM
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <Col>
                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Tooltip id={`actor-tooltip-add`}>
                          Admin Override to reset delivered items
                        </Tooltip>
                      }
                    >
                      <Button
                        name="btnResetDeliveredItemPO"
                        className=""
                        style={{
                          backgroundColor: "var(--REB-warning)",
                          fontWeight: "bolder",
                        }}
                        // disabled={
                        //   props.state.handleInvoiceModal.isView ||
                        //   Number(props.state.selectedOperation.id) === 0
                        // }
                        // hidden={
                        //   props.state.PRTableSelection.length === 0 ||
                        //   props.state.PRItemsTableSelection.length > 0
                        // }
                        onClick={(e) => {
                          e.stopPropagation();
                          props.state.handleActionChange({
                            target: { name: "btnResetDeliveredItemPO" },
                          });

                          // props.state.handleActionChange(
                          //   {
                          //     target: { name: "btnCancelInvoiceContentQuote" },
                          //   },
                          //   null
                          // );
                        }}
                      >
                        RESET DELIVERED
                      </Button>
                    </OverlayTrigger>
                  </Col>
                </Row>
              </div>
            ) : null}

            {props.state.selectedOperation.id !== 1 ? (
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Tooltip id={`actor-tooltip-add`}>
                    Remove selected item from PR
                  </Tooltip>
                }
              >
                <Button
                  name="btnRemoveItemFromPR"
                  className="mr-1 ml-1 mb-3"
                  style={{ backgroundColor: "var(--REB-warning)" }}
                  // disabled={
                  //   props.state.handleInvoiceModal.isView ||
                  //   Number(props.state.selectedOperation.id) === 0
                  // }
                  hidden={
                    props.state.PRTableSelection.length === 0 ||
                    props.state.PRItemsTableSelection.length > 0
                  }
                  onClick={(e) => {
                    props.state.handleActionChange({
                      target: { name: "btnRemoveItemFromPR" },
                    });

                    // props.state.handleActionChange(
                    //   {
                    //     target: { name: "btnCancelInvoiceContentQuote" },
                    //   },
                    //   null
                    // );
                  }}
                >
                  REMOVE FROM PR
                </Button>
              </OverlayTrigger>
            ) : (
              <h6 hidden={props.state.userType.includes("Inventory Clerk")}>
                Modification of PR / PO Content is not allowed after submission
              </h6>
            )}

            {props.tablePR}
          </div>
        </>
      )}
    </>
  );
}
export { TypePR, PRColumn, POColumn, PRItemColumn, PRItemHistoryTableColumn };
