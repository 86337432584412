import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import misc from "../../functions/misc";
import { NumericFormat } from "react-number-format";
import Table from "react-table";
import filters from "../../functions/filters";
import transactionsApi from "./TransactionsAPI";
import invoiceApi from "../Invoice/InvoiceAPI";
import inventoryApi from "../Inventory/InventoryAPI";
import { SELECTED_OPERATION } from "../../constants";
import Select from "react-select";
import actorApi from "../Actor/ActorAPI";
import Badge from "react-bootstrap/Badge";
import settingsApi from "../Settings/SettingsAPI";
import accountsApi from "../Accounts/AccountsAPI";
import InventoryAPI from "../Inventory/InventoryAPI";
import { JournalEntryModal } from "./JournalEntryModal";
import { TransactionModal } from "./TransactionModal";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import StatementOfAccount from "./PDF/StatementOfAccount";
import { pdf } from "@react-pdf/renderer";
import { invoiceColumn } from "./TransactionTable";
import StatementOfAccountPayee from "./PDF/StatementOfAccountPayee";
import { setInvoiceHeaderData } from "../../functions/pdfHeaderData";
const PAYMENT_TYPES = [
  { value: "cash", label: "Cash" },
  { value: "check", label: "Check" },
  { value: "post-dated-check", label: "Post Dated Cheque" },
  { value: "bank-deposit", label: "Bank Deposit" },
  { value: "electronic-bank-transfer", label: "Bank Deposit" },
  { value: "debit-card", label: "Debit Card" },
  { value: "credit-card", label: "Credit Card" },
  { value: "prepaid-card", label: "Pre Paid Card" },
  { value: "cod", label: "Cash on Delivery (COD)" },
  { value: "mobile-wallet", label: "Mobile Wallet" },
  { value: "pending-payment", label: "Pending Payment" },
];

export const TRANS_TYPE = {
  AcknowledgementReceipt: {
    value: "acknowledgement-receipt",
    label: "Acknowledgement Receipt",
  },
  CashVoucher: { value: "cash-voucher", label: "Cash Voucher" },
  CheckVoucher: { value: "check-voucher", label: "Check Voucher" },
  InvoiceVoucher: { value: "invoice-voucher", label: "Invoice Voucher" },
  POSPayment: { value: "pos", label: "POS" },
};

const DTransactionType = [
  { label: "Income and Expense", value: "transaction" },
  { label: "Journal Entries", value: "journal" },
  { label: "Client Accounts (Receivables)", value: "clientAccounts" },
  { label: "Payees Accounts (Payables)", value: "payeesAccounts" },
  // { label: "Deposit Withdraw", value: "deposit-withdraw" },
];
const DTransactionTypeSpecific = [
  { label: "Voucher", value: "voucher" },
  // { label: "Billing", value: "billing" },
];
const DContentType = [
  { label: "Item", value: "item" },
  // { label: "Group", value: "group" },
  { label: "Description", value: "description" },
  { label: "Title", value: "title" },
];
// const DDepositWithdrawalType = [
//   {
//     label: "Withdraw",
//     value: "withdraw",
//   },
//   {
//     label: "Deposit",
//     value: "deposit",
//   },
// ];

const customStyles = {
  menu: (provided, state) => {
    //console.log(state);
    const width = "100%";

    let color = state.selectProps.menuColor;
    const padding = 0;

    return { ...provided, width, color, padding };
  },
  control: (provided) => ({
    ...provided,
    borderRadius: "5px",
  }),

  container: (_, { selectProps: { width } }) => ({
    width: width,
    flex: 1,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,

    borderRadius: "5px 0px 0px 5px",
  }),

  option: (provided, state) => {
    let backgroundColor = "";
    let display = "block";
    let color = "black";
    if (state.data.isDefault === 1) {
      backgroundColor = "rgba(255, 0, 0, 0.2)";
    }
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    //console.log(state.data);
    if (state.data.isHidden === 1) {
      display = "none";
    }

    return {
      ...provided,
      opacity,
      transition,
      backgroundColor,
      color,
      display,
    };
  },
};

export const tableTransactionColumn = [
  {
    Header: () => (
      <p
        style={{
          fontSize: "0.70em",
          color: "var(--REB-primary)",
          fontWeight: "bolder",
          margin: 0,
        }}
      >
        Transaction Name
      </p>
    ),
    id: "name",
    getProps: (row) => {
      return {
        style: {
          padding: 0,
          paddingLeft: "10px",
          paddingRight: "10px",
          width: "100%",
          display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
        },
      };
    },
    Cell: (row) => {
      let name = "-";
      let desc = "";
      name = row.original.order ? row.original.order.uniqueId : "-";
      desc = row.original?.generalDesc;
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p
            className="m-0"
            style={{
              // fontSize: "0.8rem",
              overflow: "hidden",

              // textOverflow: "ellipsis",
              whiteSpace: "initial",
              // lineHeight: "1em",
              width: "100%",
            }}
          >
            Invoice #: {name} {desc}
          </p>
          <p
            className="m-0"
            style={{
              // fontSize: "0.8rem",
              overflow: "hidden",

              // textOverflow: "ellipsis",
              whiteSpace: "initial",
              // lineHeight: "1em",
              width: "100%",
            }}
          >
            {row.original.description}
          </p>
          <Badge
            className="mb-2"
            style={{
              backgroundColor: "var(--REB-secondary)",
              color: "var(--REB-white)",
              fontSize: "0.6em",
              width: "115px",
            }}
          >
            {row.original.uniqueId}
          </Badge>
          {/* <p
            style={{
              // fontSize: "0.8rem",
              overflow: "hidden",

              // textOverflow: "ellipsis",
              whiteSpace: "initial",
              // lineHeight: "1em",
              width: "100%",
            }}
          >
            {row.original.uniqueId}
          </p> */}
        </div>
      );
    },
    accessor: (d) => (d.name === null ? "-" : d.name),
  },
  {
    Header: () => (
      <p
        style={{
          fontSize: "0.70em",
          color: "var(--REB-primary)",
          fontWeight: "bolder",
          margin: 0,
        }}
      >
        Trx. Type
      </p>
    ),
    id: "trxType",
    maxWidth: 100,
    accessor: (d) => (d.price ? d.price : 0),
    Cell: (row) => {
      const d = row.original;
      let status = "income";
      let infoColor = "";
      let icon = <></>;

      if ((!d.orderId && !d.isEntry) || d.order?.supplierId || (!d?.order?.supplierId && !d?.order?.clientId)) {
        status = "expense";
      }

      if (status === "income") {
        infoColor = "var(--REB-green)";
        icon = <i className="icofont-money " style={{ fontSize: "1.3em" }}></i>;
      }
      if (status === "expense") {
        infoColor = "var(--REB-warning)";
        icon = (
          <i
            className="icofont-minus-circle "
            style={{ fontSize: "1.3em" }}
          ></i>
        );
      }

      return (
        <div
          style={{
            display: "block",
            wordWrap: "break-word",
            whiteSpace: "normal",
            lineHeight: "0.8em",
            fontWeight: "bolder",
          }}
        >
          <Badge
            style={{
              backgroundColor: infoColor,
              color: "white",
              fontSize: "0.8em",
            }}
          >
            {icon} {status}
          </Badge>
        </div>
      );
    },
  },
  {
    Header: () => (
      <p
        style={{
          fontSize: "0.70em",
          color: "var(--REB-primary)",
          fontWeight: "bolder",
          margin: 0,
        }}
      >
        Status
      </p>
    ),
    id: "trxStatus",
    maxWidth: 200,
    accessor: (d) => (d.price ? d.price : 0),
    Cell: (row) => {
      const d = row.original;
      let status = "finished";
      let statusDisplay = "";
      let infoColor = "";
      let icon = <></>;

      if (d.transStatus) {
        status = d.transStatus;
      }

      if (status === "finished") {
        statusDisplay = "Finished";
        infoColor = "var(--REB-green)";
        icon = (
          <i className="icofont-ui-check " style={{ fontSize: "1.3em" }}></i>
        );
      }
      if (status === "pending-admin-approval") {
        statusDisplay = "Pending Admin Approval";
        infoColor = "var(--REB-secondary)";
        icon = (
          <i className="icofont-warning " style={{ fontSize: "1.3em" }}></i>
        );
      }
      if (status === "admin-denied") {
        statusDisplay = "Admin Denied";
        infoColor = "var(--REB-warning)";
        icon = (
          <i className="icofont-ui-close " style={{ fontSize: "1.3em" }}></i>
        );
      }
      if (status === "processing") {
        statusDisplay = "Processing";
        infoColor = "var(--REB-green)";
        icon = <i className="icofont-money" style={{ fontSize: "1.3em" }}></i>;
      }
      if (status === "pending-payment") {
        statusDisplay = "Payment Processing";
        infoColor = "#c2922b";
        icon = <i className="icofont-money" style={{ fontSize: "1.3em" }}></i>;
      }
      if (status === "archived") {
        statusDisplay = "Archived";
        infoColor = "#DAA21C";
        icon = (
          <i className="icofont-ui-file" style={{ fontSize: "1.3em" }}></i>
        );
      }
      return (
        <div
          style={{
            display: "block",
            wordWrap: "break-word",
            whiteSpace: "normal",
            lineHeight: "0.8em",
            fontWeight: "bolder",
          }}
        >
          <Badge
            style={{
              backgroundColor: infoColor,
              color: "white",
              fontSize: "0.8em",
            }}
          >
            {icon} {statusDisplay}
          </Badge>
        </div>
      );
    },
  },
  {
    Header: () => (
      <p
        style={{
          fontSize: "0.70em",
          color: "var(--REB-primary)",
          fontWeight: "bolder",
          margin: 0,
        }}
      >
        Date Posted
      </p>
    ),
    id: "trxDate",
    maxWidth: 120,
    accessor: (d) => (d.price ? d.price : 0),
    Cell: (row) => {
      let date = null;
      date =
        row.original.datePosted === null
          ? "-"
          : misc.parseAPIDateTimeToShortDate(
              misc.parseAPIDateTime(row.original.datePosted)
            );
      return (
        <p
          style={{
            // fontSize: "0.8rem",
            overflow: "hidden",

            // textOverflow: "ellipsis",
            whiteSpace: "initial",
            // lineHeight: "1em",
            width: "100%",
          }}
        >
          {date}
        </p>
      );
    },
  },

  {
    Header: () => (
      <p
        style={{
          fontSize: "0.70em",
          color: "var(--REB-primary)",
          fontWeight: "bolder",
          margin: 0,
        }}
      >
        Amount
      </p>
    ),
    id: "price",
    maxWidth: 120,
    accessor: (d) => (d.amountValue ? d.amountValue : 0),
    Cell: (row) => {
      let price = 0;

      if (row.original.orderId) {
        price = Number(row.original.amountValue);
      } else {
        price = row.original.content
          .reduce((a, c) => {
            return a + c.amountValue * c.partQty;
          }, 0)
          .toFixed(2);
      }
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <NumericFormat
            value={price}
            displayType={"text"}
            thousandSeparator={true}
          />
          <div
            style={{
              display: "block",
              wordWrap: "break-word",
              whiteSpace: "normal",
              lineHeight: "0.8em",
              fontWeight: "bolder",
            }}
          ></div>
        </div>
      );
    },
  },
];
export const tableJournalColumn = [
  {
    Header: () => (
      <p
        style={{
          fontSize: "0.70em",
          color: "var(--REB-primary)",
          fontWeight: "bolder",
          margin: 0,
        }}
      >
        Transaction Name
      </p>
    ),
    id: "name",
    getProps: (row) => {
      return {
        style: {
          padding: 0,
          paddingLeft: "10px",
          paddingRight: "10px",
          width: "100%",
          display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
        },
      };
    },
    Cell: (row) => {
      let name = "-";
      let desc = "";
      name = row.original.order ? row.original.order.uniqueId : "-";
      desc = row.original?.generalDesc;
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p
            className="m-0"
            style={{
              // fontSize: "0.8rem",
              overflow: "hidden",

              // textOverflow: "ellipsis",
              whiteSpace: "initial",
              // lineHeight: "1em",
              width: "100%",
            }}
          >
            Invoice #: {name} {desc}
          </p>
          <p
            className="m-0"
            style={{
              // fontSize: "0.8rem",
              overflow: "hidden",

              // textOverflow: "ellipsis",
              whiteSpace: "initial",
              // lineHeight: "1em",
              width: "100%",
            }}
          >
            {row.original.description}
          </p>
          <Badge
            className="mb-2"
            style={{
              backgroundColor: "var(--REB-secondary)",
              color: "var(--REB-white)",
              fontSize: "0.6em",
              width: "115px",
            }}
          >
            {row.original.uniqueId}
          </Badge>
          {/* <p
            style={{
              // fontSize: "0.8rem",
              overflow: "hidden",

              // textOverflow: "ellipsis",
              whiteSpace: "initial",
              // lineHeight: "1em",
              width: "100%",
            }}
          >
            {row.original.uniqueId}
          </p> */}
        </div>
      );
    },
    accessor: (d) => (d.name === null ? "-" : d.name),
  },
  {
    Header: () => (
      <p
        style={{
          fontSize: "0.70em",
          color: "var(--REB-primary)",
          fontWeight: "bolder",
          margin: 0,
        }}
      >
        Account
      </p>
    ),
    id: "price",
    maxWidth: 120,
    accessor: (d) => (d.price ? d.price : 0),
    Cell: (row) => {
      let name = "-";
      if (row.original.account && row.original.account.name) {
        name = row.original.account.name;
      }
      return (
        <p
          style={{
            // fontSize: "0.8rem",
            overflow: "hidden",

            // textOverflow: "ellipsis",
            whiteSpace: "initial",
            // lineHeight: "1em",
            width: "100%",
          }}
        >
          {name}
        </p>
      );
    },
  },
  {
    Header: () => (
      <p
        style={{
          fontSize: "0.70em",
          color: "var(--REB-primary)",
          fontWeight: "bolder",
          margin: 0,
        }}
      >
        Date
      </p>
    ),
    id: "trxDate",
    maxWidth: 120,
    accessor: (d) => (d.price ? d.price : 0),
    Cell: (row) => {
      let date = null;
      date =
        row.original.datePosted === null
          ? "-"
          : misc.parseAPIDateTimeToShortDate(
              misc.parseAPIDateTime(row.original.datePosted)
            );
      return (
        <p
          style={{
            // fontSize: "0.8rem",
            overflow: "hidden",

            // textOverflow: "ellipsis",
            whiteSpace: "initial",
            // lineHeight: "1em",
            width: "100%",
          }}
        >
          {date}
        </p>
      );
    },
  },

  {
    Header: () => (
      <p
        style={{
          fontSize: "0.70em",
          color: "var(--REB-primary)",
          fontWeight: "bolder",
          margin: 0,
        }}
      >
        Amount
      </p>
    ),
    id: "price",
    maxWidth: 120,
    accessor: (d) => (d.amountValue ? d.amountValue : 0),
    Cell: (row) => {
      const price = Number(row.original.amountValue);
      const transType = row.original.transType;
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <NumericFormat
            value={price}
            displayType={"text"}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={true}
          />
          <div
            style={{
              display: "block",
              wordWrap: "break-word",
              whiteSpace: "normal",
              lineHeight: "0.8em",
              fontWeight: "bolder",
            }}
          >
            <Badge
              style={{
                backgroundColor: `${
                  transType === "credit"
                    ? "var(--REB-secondary)"
                    : "var(--REB-tertiary)"
                }`,
                color: "var(--REB-white)",
                fontSize: "0.6em",
              }}
            >
              {transType}
            </Badge>
          </div>
        </div>
      );
    },
  },
];

export const TransactionsProcess = () => {
  const dispatch = useDispatch();

  const permissions = useSelector((state) => {
    const user = state.apiData.userData;
    let _permissions = [];
    if (user && user.permissions) {
      _permissions = JSON.parse(user.permissions);
    }
    return _permissions.toString();
  });
  const userType = useSelector((state) => state.apiData.userType);

  const [TransactionSelection, setTransactionSelection] = useState([]);
  const [SelectedTransactionList, setSelectedTransactionList] = useState(null);

  const [TransactionData, setTransactionData] = useState([]);
  const [TransactionDataCopy, setTransactionDataCopy] = useState([]);
  const [TransactionLoading, setTransactionLoading] = useState(false);

  //Inventory Select
  const [InventoryData, setInventoryData] = useState([]);
  const [SelInventory, setSelInventory] = useState([]);
  const [SelInventoryLoading, setSelInventoryLoading] = useState(true);
  const [SelInventoryDisabled, setSelInventoryDisabled] = useState(false);

  //Invoice Details Data
  const [InvoiceDetails, setInvoiceDetails] = useState([]);

  const [DETAIL_TYPE, setDETAIL_TYPE] = useState([]);
  const [DETAIL_INVOICE_STATUS, setDETAIL_INVOICE_STATUS] = useState([]);
  const [DETAIL_INVOICE_CONTENT_STATUS, setDETAIL_INVOICE_CONTENT_STATUS] =
    useState([]);
  const [DETAIL_INVOICE_CONTENT_TYPE, setDETAIL_INVOICE_CONTENT_TYPE] =
    useState([]);

  //UI

  const [ChkShowFinished, setChkShowFinished] = useState(null);

  const [TransactionSelectedOperation, setTransactionSelectedOperation] =
    useState(SELECTED_OPERATION.VIEW);
  const [InitialLoad, setInitialLoad] = useState(true);
  const [SettingsData, setSettingsData] = useState([]);

  const [UITransactionType, setUITransactionType] = useState(
    TRANS_TYPE.InvoiceVoucher
  );

  const [TableSelectedTransactionColumn, setTableSelectedTransactionColumn] =
    useState(tableTransactionColumn);

  //Client Select
  const [ClientData, setClientData] = useState([]);
  const [SelClient, setSelClient] = useState(null);

  //REDUX
  const CURRENT_USER = useSelector((state) => state.apiData.userData);
  const CURRENT_USERTYPE = useSelector((state) => state.apiData.userType);

  //JOURNAL ENTRY MODAL
  const [JournalEntryModalOpen, setJournalEntryModalOpen] = useState(false);

  const [JournalEntryTableData, setJournalEntryTableData] = useState([]);
  const [JournalEntryTableSelection, setJournalEntryTableSelection] =
    useState(null);

  //TRANSACTION MODAL
  const [TransactionModalOpen, setTransactionModalOpen] = useState(false);

  const [TransTxtAddedBy, setTransTxtAddedBy] = useState(null);

  const [TransSelPayeeData, setTransSelPayeeData] = useState([]);

  const [TransModalNotes, setTransModalNotes] = useState(null);

  //console.log(CURRENT_USER);

  //TRANSACTION FILTER
  const [SelTransactionTypeFilter, setSelTransactionTypeFilter] = useState(
    DTransactionType[0]
  );
  const [DPTransactionFilter, setDPTransactionFilter] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [DPDateStart, setDPDateStart] = useState("");
  const [DPDateEnd, setDPDateEnd] = useState("");

  const [SelClientPayee, setSelClientPayee] = useState("");
  const [SelClientPayeeData, setSelClientPayeeData] = useState([]);
  const [SelPayeeAccounts, setSelPayeeAccounts] = useState("");
  //DEPOSIT WITHDRAW MODAL
  // const [DepositWithdrawModalOpen, setDepositWithdrawModalOpen] =
  //   useState(false);

  //ACCOUNTS DATA
  const [AccountsAssets, setAccountsAssets] = useState([]);
  const [AccountsExpenses, setAccountsExpenses] = useState([]);
  const [AccountsLiabilities, setAccountsLiabilities] = useState([]);
  const [AccountsEquity, setAccountsEquity] = useState([]);
  const [AccountsRevenues, setAccountsRevenues] = useState([]);

  const [CombinedAccounts, setCombinedAccounts] = useState([]);
  const [CombinedAccountsPayables, setCombinedAccountsPayables] = useState([]);

  //Acknowledgement UI Controls

  const [TransSelModalContentUnitData, setTransSelModalContentUnitData] =
    useState([]);

  useEffect(() => {
    resetFilters();
    async function fetchData() {
      setInitialLoad(false);
      await refreshInventory(true);
      await getInvoiceDetailsConst();
      await getActors();
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (!InitialLoad) {
      refreshTransactions();
      fetchData();
    }

    async function fetchData() {
      const inventoryId = SelInventory.id ? null : Number(SelInventory.id);
      const settings = await settingsApi.getSettingsByInventory(
        dispatch,
        Number(SelInventory.id)
      );
      //Update Invoice Headers
      setInvoiceHeaderData(
        settings?.invoiceHeaderAddress1,
        settings?.invoiceHeaderAddress2,
        settings?.invoiceHeaderAddress3,
        settings?.invoiceHeaderLandline,
        settings?.invoiceHeaderMobile,
        settings?.invoiceHeaderContact,
        settings?.invoiceHeaderEmail,
        settings?.invoiceDeliveryReceiveAddress
      );
      const accounts = await accountsApi.getAccountByType(
        dispatch,
        inventoryId
      );
      if (accounts.expenses) {
        setAccountsExpenses(accounts.expenses);
      }
      if (accounts.revenues) {
        setAccountsRevenues(accounts.revenues);
      }
      if (accounts.assets) {
        setAccountsAssets(accounts.assets);
      }
      if (accounts.liabilities) {
        setAccountsLiabilities(accounts.liabilities);
      }
      if (accounts.equity) {
        setAccountsEquity(accounts.equity);
      }
      setCombinedAccounts([
        {
          label: "Expenses",
          options: accounts.expenses,
        },
        {
          label: "Revenues",
          options: accounts.revenues,
        },
        {
          label: "Assets",
          options: accounts.assets,
        },
        {
          label: "Liabilities",
          options: accounts.liabilities,
        },
        {
          label: "Equity",
          options: accounts.equity,
        },
      ]);
      setCombinedAccountsPayables([
        {
          label: "Expenses",
          options: accounts.expenses,
        },
        {
          label: "Liabilities",
          options: accounts.liabilities,
        },
      ]);

      console.log("ACCOUNTS & SETTINGS ===> ", accounts, settings);
      setSettingsData(settings);
    }
  }, [SelInventory]);

  useEffect(() => {
    if (!InitialLoad) {
      if (SelTransactionTypeFilter === DTransactionType[0]) {
        setTableSelectedTransactionColumn(tableTransactionColumn);
      }
      if (SelTransactionTypeFilter === DTransactionType[1]) {
        setTableSelectedTransactionColumn(tableJournalColumn);
      }
      if (SelTransactionTypeFilter.value === "payeesAccounts") {
        getActors();
        setTableSelectedTransactionColumn(tableTransactionColumn);
      }
      if (SelTransactionTypeFilter.value === "clientAccounts") {
        getActors();
        setTableSelectedTransactionColumn(invoiceColumn());
      }
      refreshTransactions();
    }
  }, [
    SelTransactionTypeFilter,
    SelClientPayee,
    SelPayeeAccounts,
    DPDateStart,
    DPDateEnd,
  ]);
  useEffect(() => {
    if (!InitialLoad) {
      refreshTransactions();
    }
  }, [ChkShowFinished]);

  useEffect(() => {
    setSelClientPayee("");
    setSelPayeeAccounts("");
  }, [SelTransactionTypeFilter]);

  const getAllOrdersByClientId = async () => {
    const filter = {
      inventoryId: Number(SelInventory.id),
      generalTransactionType: SelTransactionTypeFilter.value,
      showFinished: Number(!ChkShowFinished),
      dateStart: DPDateStart,
      dateEnd: DPDateEnd,
    };

    let clientIds = [];

    for (const client of SelClientPayee) {
      clientIds.push(client.id);
    }

    const fetchedPayables = await invoiceApi.getAllOrdersByClientId(
      dispatch,
      clientIds,
      filter
    );

    let pendingPaymentTrans = 0;
    let amountPaid = 0;
    let transactionArray = [];
    if (fetchedPayables) {
      transactionArray = fetchedPayables.map((x) => {
        const paidTransactions = x.paymentTransaction.filter(
          (x) => x.isEntry === 0 && x.transStatus === "finished"
        );
        const pendingTransactions = x.paymentTransaction.filter(
          (x) => x.isEntry === 0 && x.transStatus === "processing"
        );
        //console.log(pendingTransactions)
        return {
          ...x,
          invoiceTotal: x.content.reduce((a, c) => {
            return a + c.price * c.itemQty;
          }, 0),
          invoiceTotalPaid: paidTransactions.reduce((a, c) => {
            return a + c.amountValue;
          }, 0),
          paidTransactions,
          pendingTransactions,
        };
      });

      //Filter invoices that is completed
      if (!ChkShowFinished) {
        transactionArray = transactionArray.filter(
          (x) => x.invoiceTotal !== x.invoiceTotalPaid
        );
      }

      // const pendingTrans = transactionArray.filter(
      //   (x) =>
      //     x.isEntry === 0 &&
      //     (x.transStatus === "pending-admin-approval" ||
      //       x.transStatus === "processing")
      // );

      // const paidTrans = transactionArray.filter(
      //   (x) => x.isEntry === 0 && x.transStatus === "finished"
      // );
      // amountPaid = paidTrans.reduce((a, c) => {
      //   return a + c.amountValue;
      // }, 0);
      // pendingPaymentTrans = pendingTrans.reduce((a, c) => {
      //   return a + c.amountValue;
      // }, 0);
      return transactionArray;
      //console.log(transactionArray);
      //amountPaid = misc.parseCurrencyNoCurrency(amountPaid);
    }
    // console.log(invoiceTotal, amountPaid);
    // const total = Number(invoiceTotal - amountPaid);
    // return { total, pendingPaymentTrans };

    //console.log(fetchedPayables);
  };

  const resetFilters = () => {
    setDPDateStart(moment().subtract(60, "days").format("YYYY-MM-DD"));
    setDPDateEnd(moment().add(3, "days").format("YYYY-MM-DD"));
    setChkShowFinished(false);
  };

  const refreshTransactions = async () => {
    setTransactionLoading(true);

    const filter = {
      inventoryId: Number(SelInventory.id),
      generalTransactionType: SelTransactionTypeFilter.value,
      showFinished: Number(!ChkShowFinished),
      dateStart: DPDateStart,
      dateEnd: DPDateEnd,
    };
    if (InventoryData.length >= 0) {
      let transactions = [];

      if (
        SelTransactionTypeFilter.value === "transaction" ||
        SelTransactionTypeFilter.value === "journal"
      ) {
        transactions = await transactionsApi.getAllTransactions(
          dispatch,
          filter
        );
        if (transactions) {
          setTransactionData(transactions);
          setTransactionDataCopy(transactions);
        }
      }

      if (SelTransactionTypeFilter.value === "payeesAccounts") {
        let payeeIds = [];
        let accountsIds = [];
        for (const payee of SelClientPayee) {
          payeeIds.push(payee.id);
        }
        for (const accts of SelPayeeAccounts) {
          accountsIds.push(accts.id);
        }
        transactions = await transactionsApi.getAllTransactionsByClientId(
          dispatch,
          payeeIds,
          accountsIds,
          filter
        );
        if (transactions) {
          setTransactionData(transactions);
          setTransactionDataCopy(transactions);
        }
      }

      if (SelTransactionTypeFilter.value === "clientAccounts") {
        const clientTransactions = await getAllOrdersByClientId();
        setTransactionData(clientTransactions);
        setTransactionDataCopy(clientTransactions);
      }
      if (SelTransactionTypeFilter.value === "payeesAccounts") {
        transactions = transactions.filter(
          (x) => (!x.orderId && !x.isEntry) || x.order?.supplierId
        );
      }

      setTransactionLoading(false);
    }
  };

  const getInvoiceDetailsConst = async () => {
    //const detData = await invoiceApi.getInvoiceDetailsConst(dispatch);
    const DETAILS = await invoiceApi.getAllInvoiceDetail(dispatch);

    const detDataUnit = await InventoryAPI.getItemDetail(dispatch, 2);
    //console.log("INVOICE DETAILS DATA ===>", detData, DETAILS);

    const _DETAIL_TYPE = DETAILS.find((x) => x.name === "Type");
    const _DETAIL_INVOICE_STATUS = DETAILS.find((x) => x.name === "Status");
    const _DETAIL_INVOICE_CONTENT_STATUS = DETAILS.find(
      (x) => x.name === "ContentStatus"
    );
    const _DETAIL_INVOICE_CONTENT_TYPE = DETAILS.find(
      (x) => x.name === "ContentType"
    );
    setTransSelModalContentUnitData(detDataUnit);
    setDETAIL_TYPE(_DETAIL_TYPE.details);
    setDETAIL_INVOICE_STATUS(_DETAIL_INVOICE_STATUS.details);
    setDETAIL_INVOICE_CONTENT_STATUS(_DETAIL_INVOICE_CONTENT_STATUS.details);
    setDETAIL_INVOICE_CONTENT_TYPE(_DETAIL_INVOICE_CONTENT_TYPE.details);

    setInvoiceDetails(DETAILS);
  };

  const refreshInventory = async (initial) => {
    setSelInventoryLoading(true);
    let inventories = [];
    inventories = await inventoryApi.getInventory(dispatch);

    if (inventories.length === 0) {
      dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Inventory Empty",
          desc: `You dont have permission to access any inventory. Please contact your administrator`,
          isYesNo: false,
        },
      });
    } else {
      setSelInventoryLoading(false);
      setInventoryData(inventories);
      if (initial) {
        setSelInventory(inventories[0]);
        // refreshTransactions();
      }
    }
  };

  const getActors = async (e) => {
    const ACTORS = await actorApi.getAllActors(dispatch);
    const PAYEES = await actorApi.getActorPayeesForSelect(dispatch);
    const _clients = ACTORS.find((x) => x.name === "Client");
    if (_clients) {
      const WALK_IN = _clients.actors.find((x) => x.company === "WALK-IN");
      if (WALK_IN) {
        setSelClient(WALK_IN);
      }
    }
    if (SelTransactionTypeFilter.value === "clientAccounts") {
      setSelClientPayeeData(_clients.actors);
    }
    if (SelTransactionTypeFilter.value === "payeesAccounts") {
      setSelClientPayeeData(PAYEES);
    }

    setClientData(_clients.actors);
    // console.log(PAYEES);
    setTransSelPayeeData(PAYEES);
  };

  const TransactionSearchHandler = (event) => {
    if (event.target.value !== "") {
      const data = filters.filterDataNested(
        TransactionDataCopy,
        event.target.value
      );

      if (data != null) {
        setTransactionData(data);
      } else {
        setTransactionData(TransactionDataCopy);
      }
    } else {
      setTransactionData(TransactionDataCopy);
    }
  };

  const TransactionSelectHandler = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      e.preventDefault();
      //e.stopPropagation();

      if (
        !(
          e.target.classList.contains("rt-expander") ||
          e.target.classList.contains("rt-expandable")
        )
      ) {
        const row = rowInfo.original;
        if (
          TransactionSelection.length > 0 &&
          TransactionSelection[0] === `select-${rowInfo.original.id}`
        ) {
          setTransactionSelection([]);
          setSelectedTransactionList(null);
        } else {
          if (rowInfo) {
            console.log("SELECTED TRANSACTION ===>", rowInfo.original);
            setTransactionSelection(["select-" + rowInfo.original.id]);
            setSelectedTransactionList(rowInfo.original);
            // populateTransactionModal(rowInfo.original);

            //Populate UI based on transaction type

            if (SelTransactionTypeFilter?.value === "transaction") {
              setTransactionModalOpen(true);
              if (row.order) {
                if (row.order.isPOSTrans === 1) {
                  setUITransactionType(TRANS_TYPE.POSPayment);
                }
                if (row.order.isPOSTrans === 0) {
                  if (row?.order?.supplierId) {
                    setUITransactionType(TRANS_TYPE.InvoiceVoucher);
                    // populateInvoiceVoucher(row);
                  } else {
                    setUITransactionType(TRANS_TYPE.AcknowledgementReceipt);
                  }
                }
              } else {
                setUITransactionType(TRANS_TYPE.InvoiceVoucher);
                // populateInvoiceVoucher(row);
              }
            } else {
              setJournalEntryModalOpen(true);
              // populateInvoiceVoucher(row);
            }

            // PopulateTransactionDetails(row);
            console.log("TRANSACTION-TYPE ===> ", UITransactionType);
          }
        }
      }

      if (handleOriginal) {
        handleOriginal();
      }
    },
    style: {
      background:
        rowInfo &&
        TransactionSelection.includes(`select-${rowInfo.original.id}`) &&
        "var(--REB-green)",
      color:
        rowInfo &&
        TransactionSelection.includes(`select-${rowInfo.original.id}`) &&
        "var(--REB-white)",
    },
  });

  const GenerateSOA = async (data) => {
    let perChunk = 20; // items per chunk

    // const filteredTransactions = TransactionData.filter(
    //   (x) => x.transStatus === "processing"
    // );

    // const displayTransactions = TransactionData.filter(
    //   (x) => x.transStatus === "processing" || x.transStatus === "finished"
    // );

    //console.log(TransactionData);

    let totalInvoices = TransactionData.reduce((a, c) => {
      return a + c.invoiceTotal;
    }, 0);

    let totalPaid = TransactionData.reduce((a, c) => {
      return a + c.invoiceTotalPaid;
    }, 0);
    //console.log(totalInvoices, totalPaid);

    let _grandTotal = totalInvoices - totalPaid;

    let result = TransactionData.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }
      resultArray[chunkIndex].push({
        ...item,
        index: index + 1,
      });
      return resultArray;
    }, []);
    const clients = [];
    for (const client of SelClientPayee) {
      clients.push(client.company);
    }
    if (SelTransactionTypeFilter.value === "clientAccounts") {
      const blob = await pdf(
        StatementOfAccount.StatementOfAccount(
          result,
          String(clients),
          SelTransactionTypeFilter,
          CURRENT_USER,
          _grandTotal
        )
      ).toBlob();

      const blobLink = await window.URL.createObjectURL(blob);
      dispatch({
        type: "FILE_VIEWER_MODAL_TOGGLE",
        doc: blobLink,
      });
    }
    if (SelTransactionTypeFilter.value === "payeesAccounts") {
      let totalPayables = TransactionData.reduce((a, c) => {
        console.log(c);
        if (c.transStatus === "processing") return a + c.amountValue;
        else return a;
      }, 0);
      const blob = await pdf(
        StatementOfAccountPayee.StatementOfAccountPayee(
          result,
          String(clients),
          SelTransactionTypeFilter,
          CURRENT_USER,
          totalPayables
        )
      ).toBlob();

      const blobLink = await window.URL.createObjectURL(blob);
      dispatch({
        type: "FILE_VIEWER_MODAL_TOGGLE",
        doc: blobLink,
      });
    }
  };

  const tableTransactions = (
    <>
      <style>
        {`
          @media (max-width: 991px) {
            .tableTransaction {
              border-radius: 0px !important;
              background-color: var(--REB-white) !important;
              margin-top: 0px !important;
              margin-bottom: 0px;
            }
          }
          @media (min-width: 992px) {
            .rt-thead {
              padding-right: 20px;
            }
            .tableTransaction {
              border-radius: 10px !important;
              box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
              background-color: var(--REB-white) !important;
              margin-top: 30px;
            }
          }
        `}
      </style>
      <Table
        data={TransactionData}
        keyField="id"
        columns={TableSelectedTransactionColumn}
        loading={TransactionLoading}
        toggleSelection={TransactionSelectHandler}
        isSelected={(key) =>
          TransactionSelection.includes(`select-${key}-ACCOUNTLIST`)
        }
        getTrProps={TransactionSelectHandler}
        defaultPageSize={20}
        showPageSizeOptions={false}
        showPageJump={false}
        showPagination={true}
        // SubComponent={(row) => {
        //   return <TableTransactionsChildren row={row.original} />;
        // }}
        // expanded={expanded}
        // onExpandedChange={(expanded, index, event) => {
        //   // console.log(index, expanded);
        //   // don't for get to save the 'expanded'
        //   // so it can be fed back in as a prop

        //   console.log(expanded);
        //   setExpanded(expanded);
        // }}
        style={{
          overflow: "auto",
          // maxHeight: "50vh",
          backgroundColor: "var(--REB-bg-color)",
        }}
        className="tableTransaction tableActor -highlight mt-2"
      />
    </>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        maxHeight: "100% !important",
        overflow: "hidden",
        // marginTop: "-30px",
      }}
    >
      {/* JOURNAL ENTRY MODAL*/}
      {SelTransactionTypeFilter?.value === "journal" && (
        <JournalEntryModal
          state={{
            JournalEntryModalOpen,
            setJournalEntryModalOpen,
            setJournalEntryTableData,
            setJournalEntryTableSelection,
            // TransTxtUniqueID,
            // TransTxtDateAdded,
            // TransTxtDateModified,
            // TransTxtLastModifiedBy,
            TransTxtAddedBy,
            SelectedTransactionList,
            TransModalNotes,
            setTransModalNotes,
            TransactionSelectedOperation,
            CombinedAccounts,
            setTransactionSelectedOperation,
            SelInventory,
            refreshTransactions,
          }}
        />
      )}

      {/* TRANSACTION MODAL */}
      {SelTransactionTypeFilter?.value === "transaction" && (
        <TransactionModal
          state={{
            DTransactionTypeSpecific,
            PAYMENT_TYPES,
            TRANS_TYPE,
            DTransactionType,
            DContentType,
            TransactionModalOpen,
            setTransactionModalOpen,
            TransactionSelectedOperation,
            setTransactionSelectedOperation,
            SelectedTransactionList,
            setSelectedTransactionList,
            TransSelModalContentUnitData,
            setTransSelModalContentUnitData,
            TransactionSelection,
            setTransactionSelection,
            SelTransactionTypeFilter,
            SettingsData,
            UITransactionType,
            setUITransactionType,
            refreshTransactions,
            TransSelPayeeData,
            AccountsAssets,
            AccountsExpenses,
            TransactionData,
            TransactionDataCopy,
            setTransactionData,
            setTransactionDataCopy,
            getActors,
          }}
        />
      )}
      {/* DEPOSIT WITHDRAW MODAL */}
      {/* {SelTransactionTypeFilter?.value === "deposit-withdraw" && (
        <DepositWithdrawModal
          state={{
            DTransactionTypeSpecific,
            PAYMENT_TYPES,
            TRANS_TYPE,
            DTransactionType,
            DContentType,
            DepositWithdrawModalOpen,
            setDepositWithdrawModalOpen,
            TransactionSelectedOperation,
            setTransactionSelectedOperation,
            SelectedTransactionList,
            setSelectedTransactionList,
            TransSelModalContentUnitData,
            setTransSelModalContentUnitData,
            TransactionSelection,
            setTransactionSelection,
            SelTransactionTypeFilter,
            SettingsData,
            UITransactionType,
            setUITransactionType,
            refreshTransactions,
            TransSelPayeeData,
            AccountsAssets,
            AccountsExpenses,
            TransactionData,
            TransactionDataCopy,
            setTransactionData,
            setTransactionDataCopy,
          }}
        />
      )} */}

      <style>
        {`
          .itemListCard {
            box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
              rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
              rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
          }
          @media (max-width: 991px) {
            .globalContainerStyle {
              border-radius: 0px !important;
              margin-top: 0px !important;
            }
            .container {
              padding: 0px !important;
              margin: 0px !important;
            }

            body {
              padding: 0px !important;
              margin: 0px !important;
              overflow: hidden;
            }

            .navbar-fixed-top,
            .navbar-fixed-bottom,
            .navbar-static-top {
              margin-left: 0px !important;
              margin-right: 0px !important;
              margin-bottom: 0px !important;
            }
            .stage {
              padding: 0px !important;
              margin: 0px !important;
            }
          }
          @media (min-width: 992px) {
            .globalContainerStyle {
              border-radius: 10px;
            }
          }
        `}
      </style>

      <div
        style={{ zIndex: "100" }}
        className="globalContainerStyle my-2 p-3 animate__animated animate__fadeInLeft"
      >
        <Row className="" style={{}} noGutters>
          <Col xs={12} sm={12} md={4} lg={4} className="mb-2 px-1">
            <InputGroup className="h-100">
              <InputGroup.Prepend>
                <InputGroup.Text className="actor-ico-search">
                  <i className="icofont-search icofont-1x"></i>
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder="Search"
                aria-label="Username"
                name="searchInput"
                className="actor-searchTable shadow-none h-100"
                aria-describedby="basic-addon1"
                onChange={TransactionSearchHandler}
              />
            </InputGroup>
          </Col>

          <Col xs={12} sm={12} md={4} lg={4} className="mb-2 px-1">
            <div
              className=""
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p
                className="m-0 pl-2"
                style={{
                  backgroundColor: "var(--REB-primary)",
                  color: "var(--REB-white)",
                  fontWeight: "bold",
                  fontSize: "0.8rem",
                  width: "230px",
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                SELECTED INVENTORY
              </p>
              <Select
                styles={customStyles}
                isSearchable
                menuPortalTarget={document.body}
                width="100%"
                options={InventoryData}
                value={SelInventory}
                isLoading={SelInventoryLoading}
                isDisabled={SelInventoryDisabled}
                getOptionLabel={(x) => x.name}
                getOptionValue={(x) => x.id}
                onChange={(x, name) => {
                  setSelInventory(x);
                }}
              />
            </div>
          </Col>

          {(SelTransactionTypeFilter.value === "transaction" ||
            SelTransactionTypeFilter.value === "journal") && (
            <Col
              xs={12}
              sm={12}
              md={4}
              lg={4}
              className="mb-2 d-flex justify-content-end px-1"
            >
              <Button
                variant="secondary"
                className=""
                style={{
                  backgroundColor: "var(--REB-tertiary)",
                  fontWeight: "bolder",
                  fontSize: "0.65rem",
                }}
                onClick={(e) => {
                  if (
                    permissions.includes("transactionPage-view-write") ||
                    userType.includes("Admin")
                  ) {
                    // if (SelTransactionTypeFilter?.value === "deposit-withdraw") {
                    //   setTransactionModalOpen(true);
                    //   setDepositWithdrawModalOpen(true);
                    //   setTransactionSelectedOperation(SELECTED_OPERATION.ADD);
                    // }
                    if (SelTransactionTypeFilter?.value === "transaction") {
                      // handleTransactionsReset();
                      setTransactionModalOpen(true);
                      setTransactionSelectedOperation(SELECTED_OPERATION.ADD);
                      //setTransSelBankAccount(SettingsData.generalBank);
                      //setTransSelExpenseAccount(SettingsData.expenseAccount);
                      //setTransPaymentDate(moment().format("YYYY-MM-DD"));
                      setUITransactionType(TRANS_TYPE.InvoiceVoucher);
                    } else {
                      // handleTransactionsReset();
                      setJournalEntryModalOpen(true);

                      setTransactionSelectedOperation(SELECTED_OPERATION.ADD);
                    }
                  } else {
                    dispatch({
                      type: "MODAL_SHOW",
                      payload: {
                        title: "No Permission",
                        desc: `You dont have permission to Add or Modify Transactions.`,
                        isYesNo: false,
                      },
                    });
                  }
                }}
              >
                <i className="icofont-ui-add"></i> Add{" "}
                {SelTransactionTypeFilter?.label}
              </Button>
            </Col>
          )}
          {(SelTransactionTypeFilter.value === "clientAccounts" ||
            SelTransactionTypeFilter.value === "payeesAccounts") && (
            <Col
              className="px-4 h-100"
              xs={12}
              sm={12}
              md={4}
              lg={4}
              style={{ alignSelf: "center" }}
            >
              <Button
                className="h-100"
                style={{ backgroundColor: "var(--REB-green)" }}
                onClick={() => {
                  GenerateSOA();
                }}
              >
                Generate SOA
              </Button>
            </Col>
          )}
        </Row>
        <Row className="" noGutters>
          <Col className="px-1" xs={12} sm={6} md={4} lg={3}>
            <div
              className=""
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p
                className="m-0 pl-2"
                style={{
                  backgroundColor: "var(--REB-primary)",
                  color: "var(--REB-white)",
                  fontWeight: "bold",
                  fontSize: "0.8rem",
                  width: "230px",
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                TABLE VIEW
              </p>
              <Select
                styles={customStyles}
                isSearchable
                menuPortalTarget={document.body}
                width="100%"
                options={DTransactionType}
                value={SelTransactionTypeFilter}
                onChange={(x, name) => {
                  setSelTransactionTypeFilter(x);
                }}
              />
            </div>
          </Col>
          {(SelTransactionTypeFilter.value === "clientAccounts" ||
            SelTransactionTypeFilter.value === "payeesAccounts") && (
            <>
              <Col className="px-1" xs={12} sm={6} md={4} lg={3}>
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: "var(--REB-primary)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    {SelTransactionTypeFilter.value === "clientAccounts" && (
                      <>CLIENT</>
                    )}
                    {SelTransactionTypeFilter.value === "payeesAccounts" && (
                      <>PAYEE</>
                    )}
                  </p>

                  <Select
                    styles={customStyles}
                    isSearchable
                    isMulti
                    closeMenuOnSelect={false}
                    menuPortalTarget={document.body}
                    placeholder={`Displaying All ${
                      SelTransactionTypeFilter.value === "clientAccounts"
                        ? "Receivables"
                        : "Payables"
                    } ...`}
                    width="100%"
                    isClearable={true}
                    options={SelClientPayeeData}
                    value={SelClientPayee}
                    getOptionLabel={(x) => x.company}
                    getOptionValue={(x) => x.id}
                    onChange={(x, name) => {
                      setSelClientPayee(x);
                    }}
                  />
                </div>
              </Col>
            </>
          )}
          {SelTransactionTypeFilter.value === "payeesAccounts" && (
            <>
              <Col className="px-1" xs={12} sm={6} md={4} lg={3}>
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: "var(--REB-primary)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    ACCOUNTS
                  </p>

                  <Select
                    styles={customStyles}
                    isSearchable
                    isMulti
                    closeMenuOnSelect={false}
                    menuPortalTarget={document.body}
                    width="100%"
                    options={CombinedAccountsPayables}
                    value={SelPayeeAccounts}
                    getOptionLabel={(x) => x.name}
                    getOptionValue={(x) => x.id}
                    onChange={(x, name) => {
                      setSelPayeeAccounts(x);
                    }}
                  />
                </div>
              </Col>
            </>
          )}

          <Col
            className="px-1"
            xs={12}
            sm={6}
            md={4}
            lg={3}
            style={{ alignSelf: "center" }}
          >
            {/* <div
              className=""
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p
                className="m-0 pl-2"
                style={{
                  backgroundColor: "var(--REB-primary)",
                  color: "var(--REB-white)",
                  fontWeight: "bold",
                  fontSize: "0.8rem",
                  width: "230px",
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                FILTER BY DATE
              </p>
              <FormControl
                type="date"
                placeholder="Date Filter"
                value={DPTransactionFilter}
                onChange={(x) => {
                  setDPTransactionFilter(x.currentTarget.value);
                }}
                className="h-100"
              />
            </div> */}
            <Dropdown className="">
              <Dropdown.Toggle
                // hidden={props.state.chkDispImage.readOnly}

                style={{
                  backgroundColor: "var(--REB-primary)",
                  // borderRadius: "0px 5px 5px 5px ",
                }}
              >
                <i className="icofont-filter pr-1"></i>
                Filters
                {/* {SelTransactionTypeFilter.value === "clientAccounts" && (
                  <> CLIENT </>
                )}
                {SelTransactionTypeFilter.value === "payeesAccounts" && (
                  <> PAYEE </>
                )} */}
              </Dropdown.Toggle>

              <Dropdown.Menu
                className="p-2"
                style={{
                  width: "300px",
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                <style>
                  {`
                    @media (max-width: 991px) {
                    }
                    @media (min-width: 992px) {
                    }
                    .ChkShowFinished > input {
                      transform: scale(2);
                    }
                    .ChkShowFinished > label {
                      padding-left: 10px;
                    }
                  `}
                </style>

                <Form.Check
                  checked={ChkShowFinished}
                  name="ChkShowFinished"
                  className="ChkShowFinished m-3"
                  label="Show Finished / Archived"
                  onChange={(e) => {
                    setChkShowFinished(!ChkShowFinished);
                  }}
                />
                <Form.Label
                  className="font-weight-bold mt-2"
                  style={{ color: "var(--REB-black)" }}
                >
                  Start Date
                </Form.Label>
                <FormControl
                  type="date"
                  value={DPDateStart}
                  onChange={(x) => {
                    setDPDateStart(x.currentTarget.value);
                  }}
                  className=""
                />
                <Form.Label
                  className="font-weight-bold mt-2"
                  style={{ color: "var(--REB-black)" }}
                >
                  End Date
                </Form.Label>
                <FormControl
                  type="date"
                  value={DPDateEnd}
                  onChange={(x) => {
                    setDPDateEnd(x.currentTarget.value);
                  }}
                  className=""
                />
                <Button
                  className="my-3"
                  style={{ backgroundColor: "var(--REB-green)" }}
                  onClick={() => {
                    resetFilters();
                  }}
                >
                  Reset Filters
                </Button>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>
      {/* <Row
        style={{ overflow: "auto", maxHeight: "100%", height: "100%" }}
        noGutters
      ></Row> */}
      {tableTransactions}
    </div>
  );
};
