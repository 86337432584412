import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

let TxtName = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      <span className="required-field">* </span>
      Name
    </Form.Label>
    <FormControl
      type="text"
      style={{ textTransform: "uppercase" }}
      placeholder="Name"
      name="txtInvoiceDetailName"
      value={props.state.txtInvoiceDetailName.value}
      onChange={props.state.inputChange}
      className={
        props.state.txtInvoiceDetailName.className + " css-actorInput mb-2"
      }
      readOnly={props.state.txtInvoiceDetailName.readOnly}
      required
    />
  </>
);
let TxtSelect = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      <span className="required-field">* </span>
      Type
    </Form.Label>
    <select
      className="form-control actor-selector  mb-2"
      name="detModalSelType"
      value={props.state.detModalSelType.value}
      disabled={props.state.detModalSelType.readOnly}
      onChange={props.state.inputChange}
    >
      {props.state.InvoiceDetailsConst.map((x) => {
        return (
          <option key={x.value} value={x.value}>
            {x.name}
          </option>
        );
      })}
    </select>
  </>
);

// let SelInventory = (props) => (
//   <>
//     <Form.Label
//       className="font-weight-bold"
//       style={{ color: "var(--REB-black)" }}
//     >
//       <span className="required-field">* </span>
//       Inventory
//     </Form.Label>
//     <select
//       className="form-control actor-selector  mb-2"
//       name="detModalSelType"
//       value={props.state.detModalSelType.value}
//       disabled={props.state.detModalSelType.readOnly}
//       onChange={props.state.inputChange}
//     >
//       {props.state.InvoiceDetailsConst.map((x) => {
//         return (
//           <option key={x.value} value={x.value}>
//             {x.name}
//           </option>
//         );
//       })}
//     </select>
//   </>
// );

const InvoiceContentModal = (props) => (
  <Modal
    show={props.state.handleItemModal.isShow}
    onHide={props.state.handleItemModal.toggle}
    onExit={(e) => {
      props.state.handleItemModal.handleExit(e);
    }}
    dialogClassName="modal-actor-100w"
    //size="xl"
    centered
  >
    <Modal.Body className="actor-modal-body">
      <Row className="actor-modal-header-row pt-3 pb-3 mb-3">
        <Col xs={2} sm={1} md={1} lg={1}>
          <OverlayTrigger
            placement={"right"}
            overlay={<Tooltip id={`project-tooltip-add`}>Close</Tooltip>}
          >
            <Button
              variant="secondary"
              name="btnCloseProjectModal"
              style={{ backgroundColor: "var(--REB-primary)" }}
              className="icofont-rounded-left"
              onClick={(e) => {
                props.state.handleItemModal.toggle();
              }}
            />
          </OverlayTrigger>
        </Col>
        <Col xs={10} sm={11} md={11} lg={11} className="actor-modal-header-col">
          <h4 className=" actor-modal-header-text">
            {" "}
            {props.state.selectedOperation.name} Invoice Content
          </h4>
        </Col>
      </Row>

      <Form
        id="formActorInput"
        noValidate
        validated={props.state.handleItemModal.isValidated}
        onSubmit={props.state.handleItemModal.handleSubmit}
      >
        <TxtName state={props.state} inputChange={props.inputChange} />
        <TxtSelect state={props.state} inputChange={props.inputChange} />
        <Row
          className="mb-3 mt-3 actor-modal-footer-row actor-modal-footer"
          noGutters
        >
          <Col md={6} lg={6} className="">
            <p className="font-weight-bold">Note:</p>
            <p className="">
              Fields that has asterisk (
              <span className="required-field font-weight-bold"> * </span>)
              requires an input.
            </p>
          </Col>
          <Col md={2} lg={2} className="user-col-cont-padding">
            <Button
              block
              className=""
              name="btnUnlockInvoice"
              style={{ backgroundColor: "var(--REB-green)" }}
              disabled={!props.state.handleItemModal.isView}
              onClick={(e) => {
                props.state.handleActionChange(e);
              }}
            >
              MODIFY
            </Button>
          </Col>
          <Col md={2} lg={2} className="user-col-cont-padding">
            <Button
              block
              className=""
              style={{ backgroundColor: "var(--REB-green)" }}
              name="btnSubmitInvoiceDetails"
              disabled={props.state.handleItemModal.isView}
              type="submit"
              onClick={(e) => {
                props.state.handleSubmit(e);
              }}
            >
              SAVE
            </Button>
          </Col>
          <Col md={2} lg={2} className="user-col-cont-padding">
            <Button
              block
              className=""
              name="btnDeleteInvoice"
              style={{ backgroundColor: "var(--REB-warning)" }}
              disabled={
                props.state.handleItemModal.isView ||
                Number(props.state.selectedOperation.id) === 0
              }
              onClick={(e) => {
                props.state.handleActionChange(e);
              }}
            >
              DELETE
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal.Body>
  </Modal>
);

export { InvoiceContentModal };
