import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

let TxtName = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      <span className="required-field">* </span>
      Name
    </Form.Label>
    <FormControl
      type="text"
      // style={{ textTransform: "uppercase" }}
      placeholder="Name"
      name="txtInvoiceDetailName"
      value={props.state.txtInvoiceDetailName.value}
      onChange={props.state.inputChange}
      className={
        props.state.txtInvoiceDetailName.className + " css-actorInput mb-2"
      }
      readOnly={props.state.handleInvoiceDetailsModal.isView}
      required
    />
  </>
);
let TxtSelect = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      <span className="required-field">* </span>
      Type
    </Form.Label>
    <select
      className="form-control actor-selector  mb-2"
      name="detModalSelType"
      value={props.state.detModalSelType.value}
      //disabled={props.state.txtInvoiceDetailName.readOnly}
      disabled
      onChange={props.state.inputChange}
    >
      {props.state.invoiceDetailsConst.map((x) => {
        // if (x.hidden !== 1) {
        //console.log(x);
        if (true) {
          return (
            <option key={x.value} value={x.value}>
              {x.name}
            </option>
          );
        } else {
          return null;
        }
      })}
    </select>
  </>
);

const InvoiceDetailsModal = (props) => (
  <Modal
    show={props.state.handleInvoiceDetailsModal.isShow}
    onHide={props.state.handleInvoiceDetailsModal.toggle}
    onExit={(e) => {
      //props.state.handleInvoiceDetailsModal.handleExit(e);
    }}
    dialogClassName="modal-actor-100w"
    //size="xl"
    centered
  >
    <Modal.Body className="actor-modal-body">
      <Row className="actor-modal-header-row pt-3 pb-3 mb-3">
        <Col xs={2} sm={1} md={1} lg={1}>
          <OverlayTrigger
            placement={"right"}
            overlay={<Tooltip id={`project-tooltip-add`}>Close</Tooltip>}
          >
            <Button
              variant="secondary"
              name="btnCloseProjectModal"
              style={{ backgroundColor: "var(--REB-primary)" }}
              className="icofont-rounded-left"
              onClick={(e) => {
                props.state.handleInvoiceDetailsModal.toggle();
              }}
            />
          </OverlayTrigger>
        </Col>
        <Col xs={10} sm={11} md={11} lg={11} className="actor-modal-header-col">
          <h4 className=" actor-modal-header-text">
            {" "}
            {props.state.selectedOperation.name} Invoice Detail
          </h4>
        </Col>
      </Row>

      <Form
        id="formActorInput"
        noValidate
        validated={props.state.handleInvoiceDetailsModal.isValidated}
        onSubmit={props.state.handleInvoiceDetailsModal.handleSubmit}
      >
        <TxtName state={props.state} inputChange={props.inputChange} />

        <TxtSelect state={props.state} inputChange={props.inputChange} />
        <Form.Check
          checked={props.state.chkIsDefaultTerm.value}
          disabled={props.state.handleInvoiceDetailsModal.isView}
          name="chkIsDefaultTerm"
          label="Default"
          onChange={(e) => {
            props.state.handleActionChange(e);
          }}
        />
        <p
          className="px-4 m-0 "
          style={{ fontSize: "0.8rem", color: "var(--REB-warning)" }}
        >
          When checked, automatically add to invoice terms upon creation.
        </p>
        <Row
          className="mb-3 mt-3 actor-modal-footer-row actor-modal-footer"
          noGutters
        >
          <Col md={6} lg={6} className="px-2 mb-2"></Col>
          <Col md={2} lg={2} className="px-2 mb-2">
            <Button
              block
              className=""
              name="btnUnlockInvoice"
              style={{ backgroundColor: "var(--REB-green)" }}
              disabled={!props.state.handleInvoiceDetailsModal.isView}
              onClick={(e) => {
                props.state.handleActionChange(e);
              }}
            >
              MODIFY
            </Button>
          </Col>
          <Col md={2} lg={2} className="px-2 mb-2">
            <Button
              block
              className=""
              style={{ backgroundColor: "var(--REB-green)" }}
              name="btnSubmitInvoiceDetails"
              disabled={props.state.handleInvoiceDetailsModal.isView}
              type="submit"
              onClick={(e) => {
                props.state.handleSubmit(e);
              }}
            >
              SAVE
            </Button>
          </Col>
          <Col md={2} lg={2} className="px-2 mb-2">
            <Button
              block
              className=""
              name="btnDeleteInvoice"
              style={{ backgroundColor: "var(--REB-warning)" }}
              disabled={props.state.handleInvoiceDetailsModal.isView}
              onClick={(e) => {
                props.state.handleActionChange(e);
              }}
            >
              DELETE
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal.Body>
  </Modal>
);

export { InvoiceDetailsModal };
