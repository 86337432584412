class Filters {
  filterData(arr, value) {
    if (arr && value) {
      return arr.filter((obj) =>
        Object.values(obj)
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      );
    }
    return null;
  }
  filterDataNested(arr, value) {
    if (arr && value) {
      return arr.filter((obj) => {
        // console.log(JSON.stringify(Object.values(obj)));
        return JSON.stringify(Object.values(obj))
          .toLowerCase()
          .includes(value.toLowerCase());
      });
    }
    return null;
  }
  uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
}
export default new Filters();
