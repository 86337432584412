import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Receipt from "../POS/PDF/Receipt";
import moment from "moment";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";
import filters from "../../functions/filters";
import transactionsApi from "./TransactionsAPI";
import { SELECTED_OPERATION } from "../../constants";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import actorApi from "../Actor/ActorAPI";
import AcknowledgementReceipt from "./PDF/AcknowledgementReceipt";
import UnitSelect from "../../components/CustomDrop";
import InventoryAPI from "../Inventory/InventoryAPI";
import Voucher from "./PDF/Voucher";
import ChargeBillingReceipt from "../Invoice/PDF/ChargeInvoice";
import { usePDF, pdf } from "@react-pdf/renderer";
import TransactionsAPI from "../Transactions/TransactionsAPI";
import fileReg from "../../assets/file-regular.svg";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from "react-bootstrap/InputGroup";
import invoiceApi from "../Invoice/InvoiceAPI";
import CustomSelect from "../../components/CustomDrop";
import misc from "../../functions/misc";
const customStyles = {
  menu: (provided, state) => {
    //console.log(state);
    const width = "100%";

    let color = state.selectProps.menuColor;
    const padding = 0;

    return { ...provided, width, color, padding };
  },
  control: (provided) => ({
    ...provided,
    borderRadius: "5px",
  }),

  container: (_, { selectProps: { width } }) => ({
    width: width,
    flex: 1,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,

    borderRadius: "5px 0px 0px 5px",
  }),

  option: (provided, state) => {
    let backgroundColor = "";
    let display = "block";
    let color = "black";
    if (state.data.isDefault === 1) {
      backgroundColor = "rgba(255, 0, 0, 0.2)";
    }
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    //console.log(state.data);
    if (state.data.isHidden === 1) {
      display = "none";
    }

    return {
      ...provided,
      opacity,
      transition,
      backgroundColor,
      color,
      display,
    };
  },
};

export const TransactionModal = (props) => {
  const {
    DTransactionTypeSpecific,
    PAYMENT_TYPES,
    TRANS_TYPE,
    DTransactionType,
    DContentType,
    TransactionModalOpen,
    setTransactionModalOpen,
    TransactionSelectedOperation,
    setTransactionSelectedOperation,
    SelectedTransactionList,
    setSelectedTransactionList,
    TransSelModalContentUnitData,
    setTransSelModalContentUnitData,
    TransactionSelection,
    setTransactionSelection,
    SelTransactionTypeFilter,
    SettingsData,
    UITransactionType,
    setUITransactionType,
    refreshTransactions,
    TransSelPayeeData,
    AccountsAssets,
    AccountsExpenses,
    TransactionData,
    TransactionDataCopy,
    setTransactionData,
    setTransactionDataCopy,
    getActors,
  } = props.state;
  const dispatch = useDispatch();
  const permissions = useSelector((state) => {
    const user = state.apiData.userData;
    let _permissions = [];
    if (user && user.permissions) {
      _permissions = JSON.parse(user.permissions);
    }
    return _permissions.toString();
  });
  const userType = useSelector((state) => state.apiData.userType);

  //Validate
  const initValidateTransaction = {
    TransSelPayee: { error: false, message: "" },
    TransSelPaymentType: { error: false, message: "" },
    TransSelBankAccount: { error: false, message: "" },
    TransSelExpenseAccount: { error: false, message: "" },

    UIValidate: { error: false, message: "", validated: false },
  };
  const [ValidateTransaction, setValidateTransaction] = useState(
    initValidateTransaction
  );

  //Transaction Input
  const initTransModalContent = {
    partName: "",
    partIndex: 1,
    partUnit: "pcs",
    tempUUID: filters.uuidv4(),
    partDesc: "",
    partQty: 0,
    amountValue: 0,
    // operation: SELECTED_OPERATION.VIEW,
    contentType: "item",
    contentTypeValue: DContentType[0],
  };

  const [TransModalContentName, setTransModalContentName] = useState(null);
  const [TransSelModalContentUnit, setTransSelModalContentUnit] =
    useState(null);
  const [TransModalContent, setTransModalContent] = useState([
    initTransModalContent,
  ]);

  const [TransModalContentQty, setTransModalContentQty] = useState(null);
  const [TransModalContentAmount, setTransModalContentAmount] = useState(null);
  const [TransModalContentType, setTransModalContentType] = useState(null);
  const [InitialLoad, setInitialLoad] = useState(true);
  const [TransGrandTotal, setTransGrandTotal] = useState(0);

  //Transaction Process

  // const [TransactionData, setTransactionData] = useState([]);
  // const [TransactionDataCopy, setTransactionDataCopy] = useState([]);
  const [TransactionLoading, setTransactionLoading] = useState(false);

  //Inventory Select
  const [InventoryData, setInventoryData] = useState([]);
  const [SelInventory, setSelInventory] = useState([]);

  //Invoice Details Data

  //UI

  //REDUX
  const CURRENT_USER = useSelector((state) => state.apiData.userData);
  const CURRENT_USERTYPE = useSelector((state) => state.apiData.userType);

  //JOURNAL ENTRY MODAL

  //TRANSACTION MODAL

  const [TransTxtUniqueID, setTransTxtUniqueID] = useState(null);
  const [TransTxtDateAdded, setTransTxtDateAdded] = useState(null);
  const [TransTxtDateModified, setTransTxtDateModified] = useState(null);
  const [TransTxtLastModifiedBy, setTransTxtLastModifiedBy] = useState(null);
  const [TransTxtDeadline, setTransTxtDeadline] = useState(null);
  const [TransTxtAddedBy, setTransTxtAddedBy] = useState(null);

  const [TransTxtDescription, setTransTxtDescription] = useState(null);

  const [TransSelPayee, setTransSelPayee] = useState(null);

  const [TransSelExpenseAccount, setTransSelExpenseAccount] = useState(null);
  const [TransSelBankAccount, setTransSelBankAccount] = useState(null);

  const [TransSelPaymentType, setTransSelPaymentType] = useState(null);
  const [TransChkDownPayment, setTransChkDownPayment] = useState(false);
  const [TransPaymentDate, setTransPaymentDate] = useState(null);
  const [TransPaymentNotes, setTransPaymentNotes] = useState(null);

  const [TransCheckInformation, setTransCheckInformation] = useState(null);
  const [TransBankNameInformation, setTransBankNameInformation] =
    useState(null);
  const [TransBankAccountInformation, setTransBankAccountInformation] =
    useState(null);

  const [TransModalContentCopy, setTransModalContentCopy] = useState([]);
  const [TransModalNotes, setTransModalNotes] = useState(null);

  //console.log(CURRENT_USER);

  //ACCOUNTS DATA

  //Acknowledgement UI Controls
  const [AckIncBank, setAckIncBank] = useState(false);
  const [AckIncCheck, setAckIncCheck] = useState(false);

  const [TransSelTransactionTypeSpecific, setTransSelTransactionTypeSpecific] =
    useState(DTransactionTypeSpecific[0]);

  //FILE UPLOAD CONTROLS
  const [btnSaveFile, setBtnSaveFile] = useState(true);
  const [fileUpload, setFileUpload] = useState(null);
  const inputFile = useRef();
  const [FileUploadData, setFileUploadData] = useState([]);

  // const [ModifyTransModalContent, setModifyTransModalContent] = useState([
  //   {
  //     description: "",
  //     uid: filters.uuidv4(),
  //     accountId: null,
  //     amountValue: null,
  //     transType: "-",
  //     transStatus: "finished",
  //   },
  //   {
  //     description: "",
  //     uid: filters.uuidv4(),
  //     accountId: null,
  //     amountValue: null,
  //     transType: "-",
  //     transStatus: "finished",
  //   },
  // ]);

  //useEffect
  useEffect(() => {
    let itemContent = [];
    itemContent = TransModalContent.filter((x) => x.contentType === "item");
    let _grandTotal = itemContent.reduce((a, c) => {
      return a + c.partQty * c.amountValue;
    }, 0);
    setTransGrandTotal(_grandTotal);
  }, [TransModalContent]);

  // useEffect(() => {
  //   console.log(TransModalContent);
  // }, [TransModalContent]);

  useEffect(() => {
    if (
      TransactionSelectedOperation === SELECTED_OPERATION.VIEW &&
      SelectedTransactionList
    ) {
      // console.log("trigger populate");
      populateInvoiceVoucher(SelectedTransactionList);
    }

    if (
      TransactionSelectedOperation === SELECTED_OPERATION.ADD &&
      !SelectedTransactionList
    ) {
      //console.log("trigger reset");
      handleTransactionsReset();
    }
  }, [TransactionSelectedOperation, SelectedTransactionList]);
  useEffect(() => {
    // console.log("trigger");
    if (SelectedTransactionList?.files) {
      setFileUploadData([...JSON.parse(SelectedTransactionList?.files)]);
    } else {
      setFileUploadData([]);
    }
  }, [SelectedTransactionList?.files]);

  const handleTransactionsReset = () => {
    setTransactionLoading(false);
    setTransPaymentDate("");
    setTransSelPaymentType("");
    setTransChkDownPayment(false);
    setTransCheckInformation("");
    setTransPaymentNotes("");
    setTransSelExpenseAccount("");
    setTransSelBankAccount("");
    setTransTxtDeadline("");
    setTransSelPayee("");
    setValidateTransaction(initValidateTransaction);

    setTransTxtUniqueID("");
    setTransTxtDateAdded("");
    setTransTxtDateModified("");
    setTransTxtAddedBy("");
    setTransTxtLastModifiedBy("");

    setTransBankNameInformation("");
    setTransBankAccountInformation("");
    setAckIncBank(false);
    setAckIncCheck(false);
    setTransModalContent([initTransModalContent]);

    setTransModalContentCopy([]);
    setSelectedTransactionList("");
    setTransactionSelection([]);

    setTransPaymentDate("");

    setTransModalNotes("");

    setTransSelTransactionTypeSpecific(DTransactionTypeSpecific[0]);
    setTransTxtDescription("");
  };
  const createTransaction = async (cb) => {
    let InventoryId = Number(SelInventory.id);
    const transUid = filters.uuidv4();
    // console.log(SelInventory);
    if (InventoryId === 1) {
      InventoryId = null;
    }

    let transactionContent = [];

    TransModalContent.forEach((cont) => {
      console.log("CONTENT ===> ", cont);
      transactionContent.push({
        uid: cont.tempUUID,
        discount: 0,
        amountValue: cont.contentType === "item" ? cont.amountValue : 0,
        grossAmount: cont.contentType === "item" ? cont.amountValue : 0,
        netAmount: cont.contentType === "item" ? cont.amountValue : 0,
        taxAmount: 0,
        partName: cont.partName,
        partDesc: cont.partDesc,
        partUnit: cont.partUnit,
        partQty: cont.partQty,
        partIndex: Number(cont.partIndex),
        contentType: cont.contentType,
        notes: "",
        inventoryId: InventoryId,
      });
    });

    const data = [
      {
        transaction: {
          inventoryId: InventoryId,
          checkInfo1: TransCheckInformation,
          paymentDate: TransPaymentDate,
          paymentCompletedDate: TransPaymentDate,
          paymentInformation: TransPaymentNotes,
          expenseAccountId: Number(TransSelExpenseAccount.id),
          bankAccountId: Number(TransSelBankAccount.id),
          transStatus: "processing",
          paymentType: TransSelPaymentType.value,
          amountValue: TransGrandTotal,
          grossAmount: TransGrandTotal,
          netAmount: TransGrandTotal,
          taxAmount: 0,
          amountInWords: "",
          generalTransType: "transaction",
          supplierVendorId: Number(TransSelPayee.id),
          isEntry: 0,
          isDownPayment: Number(TransChkDownPayment),
          uid: transUid,
          // description: `VOUCHER FOR ${TransSelPayee.company}`,
          description: TransTxtDescription,
          paymentBankName: TransBankNameInformation,
          paymentBankNumber: TransBankAccountInformation,
          paymentDeadline: TransTxtDeadline,
          notes: TransModalNotes,
          generalTransTypeSpecific: TransSelTransactionTypeSpecific.value,
          accountId: Number(TransSelExpenseAccount.id),
        },
        content: transactionContent,
      },
      {
        transaction: {
          accountId: Number(TransSelBankAccount.id),
          amountValue: Number(TransGrandTotal) * -1,
          description: `${TransTxtDescription}`,
          generalDesc: TransSelTransactionTypeSpecific.value,
          uid: transUid,
          transStatus: "finished",
          transType: "credit",
          isEntry: 1,
          generalTransType: "journal",
        },
      },
    ];
    console.log("PRE-POST DATA TRANSACTION ===>", data);
    actorApi.sendEmail(
      dispatch,
      `Transaction - ${TransTxtDescription}`,
      CURRENT_USER?.lastName,
      InventoryId
    );
    const createdInvoice = await transactionsApi.createMultiTransaction(
      dispatch,
      data,
      () => {
        if (cb) {
          // handleTransactionsReset();
          cb();
        }
        refreshTransactions();
      },
      () => {
        dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Transaction Creation Error",
            desc: `Please Contact Administrator`,
            isYesNo: false,
          },
        });
      }
    );
    // setCreatedInvoice(createdInvoice.data);

    // handleTransactionsReset();
    setTransactionModalOpen(false);
    console.log("CREATED INVOICE ===> ", createdInvoice);
    dispatch({
      type: "TOAST_ADD",
      payload: {
        title: `Successfully added transaction`,
      },
    });
  };
  const modifyTransaction = async (cb) => {
    let InventoryId = Number(SelInventory.id);
    //console.log(SelInventory);
    if (InventoryId === 1) {
      InventoryId = null;
    }

    let transactionContent = [];

    TransModalContent.forEach((cont) => {
      console.log("CONTENT ===> ", cont);

      transactionContent.push({
        uid: cont.tempUUID,
        discount: 0,
        amountValue: cont.contentType === "item" ? cont.amountValue : 0,
        grossAmount: cont.contentType === "item" ? cont.amountValue : 0,
        netAmount: cont.contentType === "item" ? cont.amountValue : 0,
        taxAmount: 0,
        partName: cont.partName,
        partDesc: cont.partDesc,
        partUnit: cont.partUnit,
        partQty: cont.partQty,
        partIndex: Number(cont.partIndex),
        contentType: cont.contentType,
        notes: "",
        inventoryId: InventoryId,
      });
    });

    const data = {
      transaction: {
        id: Number(SelectedTransactionList.id),
        inventoryId: InventoryId,
        checkInfo1: TransCheckInformation,
        paymentDate: TransPaymentDate,
        paymentCompletedDate: TransPaymentDate,
        paymentInformation: TransPaymentNotes,
        expenseAccountId: Number(TransSelExpenseAccount.id),
        bankAccountId: Number(TransSelBankAccount.id),
        transStatus: "processing",
        paymentType: TransSelPaymentType.value,
        amountValue: TransGrandTotal,
        grossAmount: TransGrandTotal,
        netAmount: TransGrandTotal,
        taxAmount: 0,
        amountInWords: "",
        generalTransType: "transaction",
        supplierVendorId: Number(TransSelPayee.id),
        isEntry: 0,
        isDownPayment: Number(TransChkDownPayment),
        uid: filters.uuidv4(),
        // description: `VOUCHER FOR ${TransSelPayee.company}`,
        description: TransTxtDescription,
        paymentBankName: TransBankNameInformation,
        paymentBankNumber: TransBankAccountInformation,
        paymentDeadline: TransTxtDeadline,
        notes: TransModalNotes,
      },
      content: transactionContent,
    };
    console.log("PRE-POST DATA TRANSACTION ===>", data);

    const modifiedInvoice = await transactionsApi.updateTransaction(
      dispatch,
      data,
      () => {
        if (cb) {
          // handleTransactionsReset();
          cb();
        }
        //refreshTransactions();
      },
      () => {
        dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Transaction Creation Error",
            desc: `Please Contact Administrator`,
            isYesNo: false,
          },
        });
      }
    );
    // setCreatedInvoice(createdInvoice.data);

    handleTransactionsReset();
    setTransactionModalOpen(false);
    console.log("MODIFIED TRANSACTION ===> ", modifiedInvoice);
    findTransactionAndReplace(modifiedInvoice.data);
    dispatch({
      type: "TOAST_ADD",
      payload: {
        title: `Successfully modified transaction`,
      },
    });
  };
  const ModifyTransactionStatus = async (status, cb) => {
    const data = {
      transaction: {
        id: Number(SelectedTransactionList.id),

        transStatus: status,

        notes: TransModalNotes,
      },
    };

    const modifiedInvoice = await transactionsApi.updateTransaction(
      dispatch,
      data,
      () => {
        if (cb) {
          // handleTransactionsReset();
          cb();
        }
        //refreshTransactions();
      },
      () => {
        dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Transaction Creation Error",
            desc: `Please Contact Administrator`,
            isYesNo: false,
          },
        });
      }
    );
    handleTransactionsReset();
    setTransactionModalOpen(false);
    console.log("MODIFIED TRANSACTION ===> ", modifiedInvoice);
    refreshTransactions();
    findTransactionAndReplace(modifiedInvoice.data);
    dispatch({
      type: "TOAST_ADD",
      payload: {
        title: `Successfully modified transaction`,
      },
    });
  };

  const findTransactionAndReplace = async (transaction) => {
    let updatedData = [];

    // console.log(TransactionData);

    // for (const x in TransactionData) {
    //   x.id === transaction.id
    //     ? updatedData.push(transaction)
    //     : updatedData.push(x);
    //   console.log(x);
    // }
    updatedData = TransactionData.map((x) =>
      x.id === transaction.id ? { ...transaction } : x
    );
    // console.log(updatedData);
    if (updatedData) {
      setTransactionData([...updatedData]);
      setTransactionDataCopy([...updatedData]);
    }
  };

  const populateInvoiceVoucher = (data) => {
    let TransContent = [];
    TransContent = data.content ? data.content : [];
    setTransTxtDescription(data.description ? data.description : "");
    setTransPaymentDate(
      data.paymentDate ? moment(data.paymentDate).format("YYYY-MM-DD") : ""
    );
    setTransTxtDeadline(
      data.paymentDeadline
        ? moment(data.paymentDeadline).format("YYYY-MM-DD")
        : ""
    );
    setTransPaymentNotes(
      data.paymentInformation ? data.paymentInformation : ""
    );
    setTransSelPaymentType(
      data.paymentType
        ? PAYMENT_TYPES.find((x) => x.value === data.paymentType)
        : null
    );

    let payeeId = 0;
    if (data.generalTransType === "transaction") {
      payeeId = data.supplierVendorId;
    }
    if (data.generalTransType === "journal") {
      payeeId = data.order?.supplierId;
    }

    if (payeeId) {
      for (const parent of TransSelPayeeData) {
        const payee = parent.options.find((x) => x.id === Number(payeeId));
        if (payee) {
          setTransSelPayee(payee);
        }

        //console.log(parent.options.find((x) => x.id === Number(payeeId)));
        //payee = ;
      }
    }

    //console.log(payee, payeeId, TransSelPayeeData);
    setTransCheckInformation(data.checkInfo1 ? data.checkInfo1 : "");

    const FinalTransContent = [];
    TransContent.forEach((e) => {
      FinalTransContent.push({
        ...e,
        tempUUID: e.uid,
        // partUnitValue: e.partUnit ?  TransSelModalContentUnitData.find((x) => x.name === e.partUnit)
        // : "",

        contentTypeValue: e.contentType
          ? DContentType.find((x) => x.value === e.contentType)
          : "",

        partUnitValue: e.partUnit
          ? TransSelModalContentUnitData.find((x) => x.name === e.partUnit)
          : "",
      });
    });
    const SortedData = FinalTransContent.sort(function (a, b) {
      return Number(a.partIndex) - Number(b.partIndex);
    });

    setTransModalContent(SortedData);
    setTransSelBankAccount(data.bankAccount ? data.bankAccount : null);
    setTransSelExpenseAccount(data.expenseAccount ? data.expenseAccount : null);
    setTransChkDownPayment(Boolean(data.isDownPayment));
    setTransBankNameInformation(
      data.paymentBankName ? data.paymentBankName : ""
    );
    setTransBankAccountInformation(
      data.paymentBankNumber ? data.paymentBankNumber : ""
    );
    setTransModalNotes(data.notes);
    setTransSelTransactionTypeSpecific(
      DTransactionTypeSpecific.find(
        (x) => x.value === data.generalTransTypeSpecific
      )
    );

    if (SelTransactionTypeFilter?.value === "transaction") {
      setTransSelBankAccount(SettingsData.generalBank);
      setTransSelExpenseAccount(SettingsData.expenseAccount);
      setTransPaymentDate(moment().format("YYYY-MM-DD"));
      //setUITransactionType(TRANS_TYPE.InvoiceVoucher);
    }
    const editLastName = data?.lastModifiedByActor?.lastName
      ? data?.lastModifiedByActor?.lastName
      : "";
    const editFirstName = data?.lastModifiedByActor?.firstName
      ? data?.lastModifiedByActor?.firstName
      : "";
    const addFirstName = data.createdBy.firstName
      ? data.createdBy.firstName
      : "";
    const addLastName = data?.createdBy?.lastName
      ? data.createdBy?.lastName
      : "";

    setTransTxtUniqueID(data.uniqueId);
    setTransTxtDateAdded(
      data.datePosted ? moment(data.datePosted).format("LLL") : ""
    );
    setTransTxtDateModified(
      data.dateEdited ? moment(data.dateEdited).format("LLL") : ""
    );
    setTransTxtAddedBy(`${addLastName} ${addFirstName}`);
    setTransTxtLastModifiedBy(`${editLastName} ${editFirstName}`);
  };

  const validateTransaction = (validateComponents, cb) => {
    let tempUserInput = {
      ...ValidateTransaction,
      UIValidate: { error: false, message: "", validated: false },
    };

    if (
      validateComponents.includes("TransSelPayee") ||
      validateComponents.includes("all")
    ) {
      TransSelPayee !== null && TransSelPayee !== ""
        ? (tempUserInput.TransSelPayee = { error: false, message: "" })
        : (tempUserInput.TransSelPayee = {
            error: true,
            message: "Payee cant be empty",
          });
    }
    if (
      validateComponents.includes("TransSelPaymentType") ||
      validateComponents.includes("all")
    ) {
      TransSelPaymentType !== null && TransSelPaymentType !== ""
        ? (tempUserInput.TransSelPaymentType = { error: false, message: "" })
        : (tempUserInput.TransSelPaymentType = {
            error: true,
            message: "Payment Type name cant be empty",
          });
    }
    if (
      validateComponents.includes("TransSelBankAccount") ||
      validateComponents.includes("all")
    ) {
      TransSelBankAccount !== null && TransSelBankAccount !== ""
        ? (tempUserInput.TransSelBankAccount = { error: false, message: "" })
        : (tempUserInput.TransSelBankAccount = {
            error: true,
            message: "Bank Account cant be empty",
          });
    }
    if (
      validateComponents.includes("TransSelExpenseAccount") ||
      validateComponents.includes("all")
    ) {
      TransSelExpenseAccount !== null && TransSelExpenseAccount !== ""
        ? (tempUserInput.TransSelExpenseAccount = { error: false, message: "" })
        : (tempUserInput.TransSelExpenseAccount = {
            error: true,
            message: "Expense Account cant be empty",
          });
    }
    if (
      validateComponents.includes("TransTxtDescription") ||
      validateComponents.includes("all")
    ) {
      TransTxtDescription !== null && TransTxtDescription !== ""
        ? (tempUserInput.TransTxtDescription = { error: false, message: "" })
        : (tempUserInput.TransTxtDescription = {
            error: true,
            message: "Description cant be empty",
          });
    }

    Object.keys(tempUserInput).forEach((key) => {
      if (tempUserInput[key].error) {
        tempUserInput.UIValidate = {
          error: true,
          message: "",
          validated: true,
        };
      }
    });
    //console.log(tempUserInput);
    setValidateTransaction(tempUserInput);
    if (tempUserInput.UIValidate.error) {
      dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Validation Error",
          desc: `Please check inputs`,
          isYesNo: false,
        },
      });
    } else {
      if (cb) cb();
    }
  };

  const TempModifyTransContent = (value, name) => {
    setTransModalContent((trans) =>
      trans?.map((dat) =>
        dat.tempUUID === value.tempUUID ? { ...dat, ...value } : dat
      )
    );
    if (name) {
      setTransModalContent((trans) =>
        trans.sort(function (a, b) {
          return Number(a.partIndex) - Number(b.partIndex);
        })
      );
    }
  };

  return (
    <>
      <Modal
        show={TransactionModalOpen}
        onHide={() => {
          setTransModalContent([initTransModalContent]);
          setTransactionModalOpen(false);
          setTransactionSelectedOperation(SELECTED_OPERATION.VIEW);
          setSelectedTransactionList(null);
          setTransactionSelection([]);
        }}
        onExit={(e) => {
          setTransModalContent([initTransModalContent]);
          setTransactionModalOpen(false);
          setTransactionSelectedOperation(SELECTED_OPERATION.VIEW);
          setSelectedTransactionList(null);
          setTransactionSelection([]);
        }}
        dialogClassName="modal-actor-100w"
        //size="xl"
        centered
        // fullscreen={true}
      >
        <Modal.Header
          className="p-0 global-modal-header"
          style={{ backgroundColor: "var(--REB-primary)" }}
        >
          <Modal.Title className="p-2 global-modal-header-title">
            <Row noGutters>
              <Col xs="auto" className="h-100">
                <Button
                  style={{ backgroundColor: "var(--REB-warning)" }}
                  onClick={() => {
                    setTransactionModalOpen(false);
                  }}
                  className="h-100"
                >
                  <i className="icofont-ui-close"></i>
                </Button>
              </Col>
              <Col xs="auto" className="pl-3" style={{ alignSelf: "center" }}>
                <h6
                  className="text-center m-0 h-100"
                  style={{ color: "var(--REB-white)", fontWeight: "bolder" }}
                >
                  {TransactionSelectedOperation.name} Transaction
                </h6>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row noGutters>
            {SelectedTransactionList?.transStatus === "processing" && (
              <Col xs={12} sm={6} md={4} lg={3} className="px-1 mb-2">
                <Button
                  style={{
                    backgroundColor: "var(--REB-green)",
                  }}
                  onClick={() => {
                    if (
                      permissions.includes("transactionPage-view-write") ||
                      userType.includes("Admin")
                    ) {
                      dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "Confirmation",
                          desc: `Please make sure that this voucher is finished and final. Are you sure you want to set this voucher to finished?`,
                          isYesNo: true,
                          cb: async (e) => {
                            ModifyTransactionStatus("finished");
                          },
                        },
                      });
                    } else {
                      dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "No Permission",
                          desc: `You dont have permission to Modify Transactions.`,
                          isYesNo: false,
                        },
                      });
                    }
                  }}
                >
                  Set Voucher to Finished
                </Button>
              </Col>
            )}
            {SelectedTransactionList?.transStatus === "processing" && (
              <Col xs={12} sm={6} md={4} lg={3} className="px-1 mb-2">
                <Button
                  style={{
                    backgroundColor: "var(--REB-warning)",
                  }}
                  onClick={() => {
                    if (
                      permissions.includes("transactionPage-view-write") ||
                      userType.includes("Admin")
                    ) {
                      dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "Confirmation",
                          desc: `Are you sure you want to cancel / archive this voucher?`,
                          isYesNo: true,
                          cb: async (e) => {
                            ModifyTransactionStatus("archived");
                          },
                        },
                      });
                    } else {
                      dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "No Permission",
                          desc: `You dont have permission to Modify Transactions.`,
                          isYesNo: false,
                        },
                      });
                    }
                  }}
                >
                  Cancel / Archive Voucher
                </Button>
              </Col>
            )}
            {SelectedTransactionList?.transStatus === "admin-denied" && (
              <Col xs={12} sm={6} md={4} lg={3} className="px-1 mb-2">
                <Button
                  style={{
                    backgroundColor: "var(--REB-yellow)",
                    color: "var(--REB-black)",
                  }}
                  onClick={() => {
                    dispatch({
                      type: "MODAL_SHOW",
                      payload: {
                        title: "Confirmation",
                        desc: `Please make sure that this voucher is correct. Are you sure you want to re-submit this voucher?`,
                        isYesNo: true,
                        cb: async (e) => {
                          ModifyTransactionStatus("pending-admin-approval");
                          actorApi.sendEmail(
                            dispatch,
                            `Transaction - ${SelectedTransactionList.description}`,
                            CURRENT_USER?.lastName,
                            SelectedTransactionList?.inventoryId
                          );
                        },
                      },
                    });
                  }}
                >
                  Re-Submit Voucher for Confirmation
                </Button>
              </Col>
            )}
          </Row>
          <Row noGutters>
            <Col xs={12} sm={6} md={4} lg={3} className="px-1 mb-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: "var(--REB-white)",
                    color: "var(--REB-black)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                    WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                    MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                    boxShadow: "inset 0px 0px 0px 1px #ced4da",
                  }}
                >
                  Unique ID
                </p>
                <FormControl
                  placeholder="Unique ID"
                  className="h-100"
                  value={TransTxtUniqueID}
                  disabled
                />
              </div>
            </Col>
            <Col xs={12} sm={6} md={4} lg={3} className="px-1 mb-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: "var(--REB-white)",
                    color: "var(--REB-black)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                    WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                    MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                    boxShadow: "inset 0px 0px 0px 1px #ced4da",
                  }}
                >
                  Date Added
                </p>
                <FormControl
                  placeholder="Date Added"
                  className="h-100"
                  value={TransTxtDateAdded}
                  disabled
                />
              </div>
            </Col>
            <Col xs={12} sm={6} md={4} lg={3} className="px-1 mb-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: "var(--REB-white)",
                    color: "var(--REB-black)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                    WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                    MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                    boxShadow: "inset 0px 0px 0px 1px #ced4da",
                  }}
                >
                  Date Modified
                </p>
                <FormControl
                  placeholder="Date Modified"
                  className="h-100"
                  value={TransTxtDateModified}
                  disabled
                />
              </div>
            </Col>
          </Row>
          <Row noGutters>
            <Col xs={12} sm={6} md={6} lg={6} className="px-1 mb-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",

                  // border: "1px solid #ced4da",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: "var(--REB-white)",
                    color: "var(--REB-black)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                    WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                    MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                    boxShadow: "inset 0px 0px 0px 1px #ced4da",
                  }}
                >
                  Added By
                </p>
                <FormControl
                  placeholder="Added By"
                  className="h-100"
                  value={TransTxtAddedBy}
                  disabled
                />
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} className="px-1 mb-2">
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  className="m-0 pl-2"
                  style={{
                    backgroundColor: "var(--REB-white)",
                    color: "var(--REB-black)",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "230px",
                    borderRadius: "5px 5px 0px 0px",
                    WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                    MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                    boxShadow: "inset 0px 0px 0px 1px #ced4da",
                  }}
                >
                  Modified By
                </p>
                <FormControl
                  placeholder="Modified By"
                  className="h-100"
                  value={TransTxtLastModifiedBy}
                  disabled
                />
              </div>
            </Col>
          </Row>

          {UITransactionType === TRANS_TYPE.InvoiceVoucher && (
            <>
              <Row className="my-3" noGutters>
                {TransactionSelectedOperation !== SELECTED_OPERATION.ADD && (
                  <Col xs={4}>
                    <Button
                      style={{ backgroundColor: "var(--REB-green)" }}
                      onClick={() => {
                        let voucherType = "Cash";
                        console.log(SelectedTransactionList.paymentType);
                        if (SelectedTransactionList.paymentType === "cash") {
                          voucherType = "Cash";
                        } else {
                          voucherType = "Check";
                        }
                        let perChunk = 20; // items per chunk

                        let tempTransList = SelectedTransactionList.content;

                        tempTransList.sort(function (a, b) {
                          return a.partIndex - b.partIndex;
                        });
                        let result = tempTransList.reduce(
                          (resultArray, item, index) => {
                            const chunkIndex = Math.floor(index / perChunk);
                            if (!resultArray[chunkIndex]) {
                              resultArray[chunkIndex] = []; // start a new chunk
                            }
                            resultArray[chunkIndex].push({
                              ...item,
                              index: index + 1,
                            });
                            return resultArray;
                          },
                          []
                        );

                        dispatch({
                          type: "PDF_MODAL_TOGGLE",
                          doc: Voucher.Voucher(
                            SelectedTransactionList.createdBy,
                            voucherType,
                            SelectedTransactionList.uniqueId,
                            SelectedTransactionList.order?.supplier
                              ? SelectedTransactionList.order?.supplier
                              : SelectedTransactionList.supplierVendor,
                            SelectedTransactionList.paymentDate,
                            SelectedTransactionList.amountValue,
                            SelectedTransactionList.createdBy,
                            SelectedTransactionList.approvedBy,
                            result,
                            SelectedTransactionList.paymentBankName,
                            SelectedTransactionList.paymentBankNumber,
                            SelectedTransactionList.checkInfo1,

                            SelectedTransactionList.transStatus
                          ),
                        });
                      }}
                    >
                      Generate Voucher PDF
                    </Button>
                  </Col>
                )}
              </Row>
              <Row noGutters>
                <Col xs={12} sm={12} md={12} lg={12} className="px-1 mb-2">
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "5px",
                    }}
                  >
                    <p
                      className="m-0 pl-2"
                      style={{
                        backgroundColor: TransSelTransactionTypeSpecific
                          ? "var(--REB-primary)"
                          : "var(--REB-warning)",
                        color: "var(--REB-white)",
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        width: "230px",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                    >
                      Transaction Type
                    </p>
                    <Select
                      styles={customStyles}
                      //  isSearchable
                      menuPortalTarget={document.body}
                      width="100%"
                      options={DTransactionTypeSpecific}
                      value={TransSelTransactionTypeSpecific}
                      // isLoading={SelInventoryLoading}
                      isDisabled={
                        TransactionSelectedOperation !==
                          SELECTED_OPERATION.EDIT &&
                        TransactionSelectedOperation !== SELECTED_OPERATION.ADD
                      }
                      onChange={(x, name) => {
                        setTransSelTransactionTypeSpecific(x);
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row noGutters>
                <Col xs={12} sm={12} md={12} lg={12} className="px-1 mb-2">
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      borderRadius: "5px",
                    }}
                  >
                    <p
                      className="m-0 px-2"
                      style={{
                        backgroundColor: TransTxtDescription
                          ? "var(--REB-primary)"
                          : "var(--REB-warning)",
                        color: "var(--REB-white)",
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        width: "230px",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                    >
                      {TransSelTransactionTypeSpecific?.label} Title / Name /
                      Description
                    </p>
                    <FormControl
                      type="text"
                      //style={{ textTransform: "uppercase" }}
                      placeholder={` ${TransSelTransactionTypeSpecific?.label} Title / Name / Description`}
                      value={TransTxtDescription}
                      disabled={
                        TransactionSelectedOperation !==
                          SELECTED_OPERATION.EDIT &&
                        TransactionSelectedOperation !== SELECTED_OPERATION.ADD
                      }
                      onChange={(e) =>
                        setTransTxtDescription(e.currentTarget.value)
                      }
                      className=""
                    />
                  </div>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} className="px-1 mb-2">
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "5px",
                    }}
                  >
                    <p
                      className="m-0 pl-2"
                      style={{
                        backgroundColor: TransSelPayee
                          ? "var(--REB-primary)"
                          : "var(--REB-warning)",
                        color: "var(--REB-white)",
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        width: "230px",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                    >
                      {TransSelTransactionTypeSpecific?.value === "voucher"
                        ? "Payee"
                        : "Bill To"}
                    </p>
                    {/* <Select
                      styles={customStyles}
                      isSearchable
                      menuPortalTarget={document.body}
                      width="100%"
                      options={TransSelPayeeData}
                      value={TransSelPayee}
                      // isLoading={SelInventoryLoading}
                      isDisabled={
                        TransactionSelectedOperation !==
                          SELECTED_OPERATION.EDIT &&
                        TransactionSelectedOperation !== SELECTED_OPERATION.ADD
                      }
                      getOptionLabel={(x) => x.company}
                      getOptionValue={(x) => x.id}
                      onChange={(x, name) => {
                        setTransTxtDescription(
                          ` ${
                            TransSelTransactionTypeSpecific?.value === "voucher"
                              ? "Voucher for "
                              : "Bill for "
                          } ${x.company}`
                        );
                        setTransSelPayee(x);
                      }}
                    /> */}
                    <CustomSelect
                      //state={props.state}
                      data={TransSelPayeeData}
                      name="selDetailPayee"
                      containerClassname="mb-3"
                      //isLoading={props.state.suppliers.isLoading}
                      selected={TransSelPayee}
                      //tabIndex={7}
                      lblHidden={true}
                      refreshData={(e) => {
                        getActors();
                      }}
                      detType={13}
                      dataStructure="invoiceActor"
                      addUrl="actor/"
                      editUrl="actor/updateActor"
                      deleteUrl="actor/deleteActor"
                      componentName="Payee"
                      componentType=""
                      modifyRequiredPermission="perm-ui-transactionPage-view-write-manage"
                      deleteRequiredPermission="perm-ui-transactionPage-view-write-manage-admin"
                      userPermissions={permissions}
                      optionLabel="company"
                      handler={(e) => {
                        setTransTxtDescription(
                          ` ${
                            TransSelTransactionTypeSpecific?.value === "voucher"
                              ? "Voucher for "
                              : "Bill for "
                          } ${e.target?.value?.company}`
                        );
                        setTransSelPayee(e.target?.value);
                      }}
                      disabled={
                        TransactionSelectedOperation !==
                          SELECTED_OPERATION.EDIT &&
                        TransactionSelectedOperation !== SELECTED_OPERATION.ADD
                      }
                    />
                  </div>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} className="px-1 mb-2">
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      className="m-0 pl-2"
                      style={{
                        backgroundColor: "var(--REB-primary)",
                        color: "var(--REB-white)",
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        width: "230px",
                        borderRadius: "5px 5px 0px 0px",
                      }}
                    >
                      Deadline
                    </p>
                    <FormControl
                      type="date"
                      placeholder="Date Filter"
                      value={TransTxtDeadline}
                      disabled={
                        TransactionSelectedOperation !==
                          SELECTED_OPERATION.EDIT &&
                        TransactionSelectedOperation !== SELECTED_OPERATION.ADD
                      }
                      onChange={(x) => {
                        setTransTxtDeadline(x.currentTarget.value);
                      }}
                      className="h-100"
                    />
                  </div>
                </Col>
              </Row>
              {(SelectedTransactionList?.generalTransType === "transaction" ||
                TransactionSelectedOperation === SELECTED_OPERATION.ADD) && (
                <>
                  <div
                    className="mb-2"
                    style={{
                      display: "flex",
                      backgroundColor: "var(--REB-primary)",
                      borderRadius: "10px 10px 0px 0px",
                    }}
                  >
                    <h5
                      className="p-3 m-0"
                      style={{ color: "white", fontWeight: "bolder" }}
                    >
                      {TransSelTransactionTypeSpecific?.label} Payment Details
                    </h5>
                  </div>
                  <Row
                    // className={`mt-1 mb-2 animate__animated ${
                    //   operation === "ADD" && "animate__fadeInRight "
                    // }${operation === "VIEW" && "animate__fadeOutRight "}`}
                    // className={()=>{
                    //   switch (operation) {
                    //     case 'ADD':
                    //       return('mt-1 mb-2 animate__animated animate__fadeInRight')
                    //       break;
                    //       case 'VIEW':
                    //         return('mt-1 mb-2 animate__animated ')
                    //         break;
                    //     default:
                    //       break;
                    //   }
                    // }
                    className="mb-2"
                    noGutters
                  >
                    <Col xs={4} className="px-1">
                      <div
                        className=""
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p
                          className="m-0 pl-2"
                          style={{
                            backgroundColor: TransPaymentDate
                              ? "var(--REB-primary)"
                              : "var(--REB-warning)",
                            color: "var(--REB-white)",
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                            width: "230px",
                            borderRadius: "5px 5px 0px 0px",
                          }}
                        >
                          Payment Date
                        </p>
                        <FormControl
                          type="date"
                          value={TransPaymentDate}
                          disabled={
                            TransactionSelectedOperation !==
                              SELECTED_OPERATION.EDIT &&
                            TransactionSelectedOperation !==
                              SELECTED_OPERATION.ADD
                          }
                          onChange={(x) => {
                            setTransPaymentDate(x.currentTarget.value);
                          }}
                        />
                      </div>
                    </Col>
                    <Col xs={4} className="px-1">
                      <div
                        className=""
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p
                          className="m-0 pl-2"
                          style={{
                            backgroundColor: TransSelPaymentType
                              ? "var(--REB-primary)"
                              : "var(--REB-warning)",
                            color: "var(--REB-white)",
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                            width: "230px",
                            borderRadius: "5px 5px 0px 0px",
                          }}
                        >
                          Payment Type
                        </p>
                        <Select
                          value={TransSelPaymentType}
                          // isMulti
                          //styles={styles}
                          styles={customStyles}
                          menuPortalTarget={document.body}
                          name="colors"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={setTransSelPaymentType}
                          options={PAYMENT_TYPES}
                          isDisabled={
                            TransactionSelectedOperation !==
                              SELECTED_OPERATION.EDIT &&
                            TransactionSelectedOperation !==
                              SELECTED_OPERATION.ADD
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={4} className="px-1 mb-2">
                      <div
                        className=""
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p
                          className="m-0 pl-2"
                          style={{
                            backgroundColor: "var(--REB-primary)",
                            color: "var(--REB-white)",
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                            width: "230px",
                            borderRadius: "5px 5px 0px 0px",
                          }}
                        >
                          Payment Notes
                        </p>
                        <FormControl
                          type="text"
                          //style={{ textTransform: "uppercase" }}
                          placeholder="Payment Notes"
                          name="TxtPaymentNotes"
                          value={TransPaymentNotes}
                          disabled={
                            TransactionSelectedOperation !==
                              SELECTED_OPERATION.EDIT &&
                            TransactionSelectedOperation !==
                              SELECTED_OPERATION.ADD
                          }
                          onChange={(e) =>
                            setTransPaymentNotes(e.currentTarget.value)
                          }
                          className=""
                        />
                      </div>
                    </Col>
                    <Col
                      xs={12}
                      className="p-3"
                      style={{ alignSelf: "center" }}
                    >
                      <style>
                        {`
                          @media (max-width: 991px) {
                          }
                          @media (min-width: 992px) {
                          }
                          .ChkDownPayment > input {
                            transform: scale(2);
                          }
                          .ChkDownPayment > label {
                            padding-left: 10px;
                          }
                        `}
                      </style>

                      <Form.Check
                        checked={TransChkDownPayment}
                        name="ChkDownPayment"
                        className="ChkDownPayment"
                        label="Down Payment"
                        disabled={
                          TransactionSelectedOperation !==
                            SELECTED_OPERATION.EDIT &&
                          TransactionSelectedOperation !==
                            SELECTED_OPERATION.ADD
                        }
                        onChange={(e) => {
                          setTransChkDownPayment(!TransChkDownPayment);
                        }}
                      />
                    </Col>

                    {/* {console.log(txtType)} */}
                    {TransSelPaymentType &&
                      TransSelPaymentType.value !== "cash" && (
                        <Row className="my-2 w-100" noGutters>
                          <Col xs={4} className="px-1">
                            <div
                              className=""
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <p
                                className="m-0 pl-2"
                                style={{
                                  backgroundColor: TransSelPaymentType
                                    ? "var(--REB-primary)"
                                    : "var(--REB-warning)",
                                  color: "var(--REB-white)",
                                  fontWeight: "bold",
                                  fontSize: "0.8rem",
                                  width: "230px",
                                  borderRadius: "5px 5px 0px 0px",
                                }}
                              >
                                Check Number
                              </p>
                              <FormControl
                                type="text"
                                //style={{ textTransform: "uppercase" }}
                                placeholder="Check Number"
                                name="TxtChkNumber"
                                value={TransCheckInformation}
                                disabled={
                                  TransactionSelectedOperation !==
                                    SELECTED_OPERATION.EDIT &&
                                  TransactionSelectedOperation !==
                                    SELECTED_OPERATION.ADD
                                }
                                onChange={(e) =>
                                  setTransCheckInformation(
                                    e.currentTarget.value
                                  )
                                }
                                className=""
                              />
                            </div>
                          </Col>
                          <Col xs={4} className="px-1">
                            <div
                              className=""
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <p
                                className="m-0 pl-2"
                                style={{
                                  backgroundColor: true
                                    ? "var(--REB-primary)"
                                    : "var(--REB-warning)",
                                  color: "var(--REB-white)",
                                  fontWeight: "bold",
                                  fontSize: "0.8rem",
                                  width: "230px",
                                  borderRadius: "5px 5px 0px 0px",
                                }}
                              >
                                Bank Information
                              </p>
                              <FormControl
                                type="text"
                                //style={{ textTransform: "uppercase" }}
                                placeholder="Bank Information"
                                name="TransBankNameInformation"
                                value={TransBankNameInformation}
                                disabled={
                                  TransactionSelectedOperation !==
                                    SELECTED_OPERATION.EDIT &&
                                  TransactionSelectedOperation !==
                                    SELECTED_OPERATION.ADD
                                }
                                onChange={(e) =>
                                  setTransBankNameInformation(
                                    e.currentTarget.value
                                  )
                                }
                                className=""
                              />
                            </div>
                          </Col>
                          <Col xs={4} className="px-1">
                            <div
                              className=""
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <p
                                className="m-0 pl-2"
                                style={{
                                  backgroundColor: true
                                    ? "var(--REB-primary)"
                                    : "var(--REB-warning)",
                                  color: "var(--REB-white)",
                                  fontWeight: "bold",
                                  fontSize: "0.8rem",
                                  width: "230px",
                                  borderRadius: "5px 5px 0px 0px",
                                }}
                              >
                                Bank Account
                              </p>
                              <FormControl
                                type="text"
                                //style={{ textTransform: "uppercase" }}
                                placeholder="Bank Account"
                                name="TransBankAccountInformation"
                                value={TransBankAccountInformation}
                                disabled={
                                  TransactionSelectedOperation !==
                                    SELECTED_OPERATION.EDIT &&
                                  TransactionSelectedOperation !==
                                    SELECTED_OPERATION.ADD
                                }
                                onChange={(e) =>
                                  setTransBankAccountInformation(
                                    e.currentTarget.value
                                  )
                                }
                                className=""
                              />
                            </div>
                          </Col>
                        </Row>
                      )}
                    <Col xs={12} sm={6} md={6} lg={6} className="px-1 mb-2">
                      <div
                        className=""
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p
                          className="m-0 pl-2"
                          style={{
                            backgroundColor: TransSelBankAccount
                              ? "var(--REB-primary)"
                              : "var(--REB-warning)",
                            color: "var(--REB-white)",
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                            width: "230px",
                            borderRadius: "5px 5px 0px 0px",
                          }}
                        >
                          Bank Account / Asset Account
                        </p>
                        <Select
                          styles={customStyles}
                          isSearchable
                          menuPortalTarget={document.body}
                          width="100%"
                          options={AccountsAssets}
                          value={TransSelBankAccount}
                          getOptionLabel={(x) => x.name}
                          getOptionValue={(x) => x.id}
                          isDisabled={
                            TransactionSelectedOperation !==
                              SELECTED_OPERATION.EDIT &&
                            TransactionSelectedOperation !==
                              SELECTED_OPERATION.ADD
                          }
                          onChange={(x, name) => {
                            setTransSelBankAccount(x);
                          }}
                        />
                      </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6} className="px-1 mb-2">
                      <div
                        className=""
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p
                          className="m-0 pl-2"
                          style={{
                            backgroundColor: TransSelExpenseAccount
                              ? "var(--REB-primary)"
                              : "var(--REB-warning)",
                            color: "var(--REB-white)",
                            fontWeight: "bold",
                            fontSize: "0.8rem",
                            width: "230px",
                            borderRadius: "5px 5px 0px 0px",
                          }}
                        >
                          Expense Account
                        </p>
                        <Select
                          styles={customStyles}
                          isSearchable
                          menuPortalTarget={document.body}
                          width="100%"
                          options={AccountsExpenses}
                          value={TransSelExpenseAccount}
                          getOptionLabel={(x) => x.name}
                          getOptionValue={(x) => x.id}
                          isDisabled={
                            TransactionSelectedOperation !==
                              SELECTED_OPERATION.EDIT &&
                            TransactionSelectedOperation !==
                              SELECTED_OPERATION.ADD
                          }
                          onChange={(x, name) => {
                            setTransSelExpenseAccount(x);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              )}

              <div
                style={{
                  display: "flex",
                  backgroundColor: "var(--REB-primary)",
                  borderRadius: "10px 10px 0px 0px",
                }}
              >
                <h5
                  className="p-3 m-0"
                  style={{ color: "white", fontWeight: "bolder" }}
                >
                  {TransSelTransactionTypeSpecific?.label} Content
                </h5>
              </div>
              <div>
                <style>
                  {`
                    input[type="number"]::-webkit-inner-spin-button,
                    input[type="number"]::-webkit-outer-spin-button {
                      opacity: 1;
                    }
                    .voucherContentHeaderP {
                      text-align: center;
                      color: var(--REB-secondary);
                      font-weight: bolder;
                    }
                  `}
                </style>
                <div>
                  <Row
                    style={{ border: "solid 1px #ced4da" }}
                    className=""
                    noGutters
                  >
                    <Col xs={12} sm={6} md={4} lg={2}>
                      <p
                        className="m-0 p-0 voucherContentHeaderP"
                        style={{ textAlign: "center" }}
                      >
                        Index
                      </p>
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={4}>
                      <p
                        className="m-0 p-0 voucherContentHeaderP"
                        style={{ textAlign: "center" }}
                      >
                        Item
                      </p>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={2}>
                      <p
                        className="m-0 p-0 voucherContentHeaderP"
                        style={{ textAlign: "center" }}
                      >
                        Qty
                      </p>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={2}>
                      <p
                        className="m-0 p-0 voucherContentHeaderP"
                        style={{ textAlign: "center" }}
                      >
                        Amount
                      </p>
                    </Col>
                    {/* <Col xs={12} sm={6} md={4} lg={2}>
                      <p
                        className="m-0 p-0 voucherContentHeaderP"
                        style={{ textAlign: "center" }}
                      >
                        Total
                      </p>
                    </Col> */}
                    <Col xs={12} sm={6} md={4} lg={2}>
                      <p className="m-0 p-0 voucherContentHeaderP">Actions</p>
                    </Col>
                  </Row>
                </div>
                {TransModalContent.map((val, index) => {
                  return (
                    <Row
                      key={index + "transCont"}
                      style={{ border: "solid 1px #ced4da" }}
                      className="pt-2"
                      noGutters
                    >
                      <Col
                        xs={12}
                        sm={6}
                        md={4}
                        lg={2}
                        className="px-2  "
                        style={{
                          // textAlign: "center",
                          alignSelf: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            flexDirection: "column",
                            display: "flex",
                          }}
                        >
                          <div
                            className="mb-2"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              backgroundColor: "var(--REB-primary)",
                              borderRadius: "5px",
                            }}
                          >
                            <p
                              className="m-0 pl-2"
                              style={{
                                // backgroundColor: "var(--REB-primary)",
                                color: "var(--REB-white)",
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                width: "230px",
                                borderRadius: "5px 5px 0px 0px",
                              }}
                            >
                              Index
                            </p>
                            <FormControl
                              type="number"
                              inputMode="numeric"
                              placeholder="Index"
                              name="partIndex"
                              disabled={
                                TransactionSelectedOperation !==
                                  SELECTED_OPERATION.EDIT &&
                                TransactionSelectedOperation !==
                                  SELECTED_OPERATION.ADD
                              }
                              value={val.partIndex}
                              onChange={(e) => {
                                TempModifyTransContent(
                                  {
                                    tempUUID: val.tempUUID,
                                    partIndex: e.currentTarget.value,
                                  },
                                  "partIndex"
                                );
                              }}
                              className=""
                            />
                          </div>
                          <div
                            className="mb-2"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              backgroundColor: val.contentTypeValue
                                ? "var(--REB-primary)"
                                : "var(--REB-warning)",
                              borderRadius: "5px",
                            }}
                          >
                            <p
                              className="m-0 pl-2"
                              style={{
                                // backgroundColor: "var(--REB-primary)",
                                color: "var(--REB-white)",
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                width: "230px",
                                borderRadius: "5px 5px 0px 0px",
                              }}
                            >
                              Content Type
                            </p>
                            <Select
                              styles={customStyles}
                              isSearchable
                              menuPortalTarget={document.body}
                              width="100%"
                              options={DContentType}
                              value={val.contentTypeValue}
                              isDisabled={
                                TransactionSelectedOperation !==
                                  SELECTED_OPERATION.EDIT &&
                                TransactionSelectedOperation !==
                                  SELECTED_OPERATION.ADD
                              }
                              onChange={(x) => {
                                TempModifyTransContent({
                                  tempUUID: val.tempUUID,
                                  contentType: x.value,
                                  contentTypeValue: x,
                                });
                                // console.log(x);
                              }}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        className="px-2"
                        style={{
                          // textAlign: "center",
                          alignSelf: "center",
                        }}
                      >
                        <div
                          style={{
                            flexDirection: "column",
                            display: "flex",
                          }}
                        >
                          {TransModalContentType?.value !== "description" && (
                            <div
                              className="mb-2"
                              style={{
                                display: "flex",
                                flexDirection: "column",

                                // borderRadius: "5px",
                              }}
                            >
                              <p
                                className="m-0 pl-2"
                                style={{
                                  backgroundColor: val.partName
                                    ? "var(--REB-primary)"
                                    : "var(--REB-warning)",
                                  color: "var(--REB-white)",
                                  fontWeight: "bold",
                                  fontSize: "0.8rem",
                                  width: "230px",
                                  borderRadius: "5px 5px 0px 0px",
                                }}
                              >
                                Name
                              </p>
                              <FormControl
                                type="text"
                                //style={{ textTransform: "uppercase" }}
                                placeholder="Name"
                                value={val.partName}
                                disabled={
                                  TransactionSelectedOperation !==
                                    SELECTED_OPERATION.EDIT &&
                                  TransactionSelectedOperation !==
                                    SELECTED_OPERATION.ADD
                                }
                                onChange={(e) => {
                                  TempModifyTransContent({
                                    tempUUID: val.tempUUID,
                                    partName: e.currentTarget.value,
                                  });
                                }}
                                className=""
                              />
                            </div>
                          )}

                          {val.contentType !== "title" && (
                            <div
                              className="mb-2"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                // backgroundColor: "var(--REB-primary)",
                                // borderRadius: "5px",
                              }}
                            >
                              <p
                                className="m-0 pl-2"
                                style={{
                                  backgroundColor: "var(--REB-primary)",
                                  color: "var(--REB-white)",
                                  fontWeight: "bold",
                                  fontSize: "0.8rem",
                                  width: "230px",
                                  borderRadius: "5px 5px 0px 0px",
                                }}
                              >
                                Description
                              </p>
                              <FormControl
                                type="text"
                                //style={{ textTransform: "uppercase" }}
                                placeholder="Description"
                                value={val.partDesc}
                                disabled={
                                  TransactionSelectedOperation !==
                                    SELECTED_OPERATION.EDIT &&
                                  TransactionSelectedOperation !==
                                    SELECTED_OPERATION.ADD
                                }
                                onChange={(e) =>
                                  TempModifyTransContent({
                                    tempUUID: val.tempUUID,
                                    partDesc: e.currentTarget.value,
                                  })
                                }
                                className=""
                              />
                            </div>
                          )}
                        </div>
                      </Col>
                      {val.contentType !== "item" && (
                        <>
                          <Col
                            xs={12}
                            sm={6}
                            md={4}
                            lg={2}
                            className="px-2"
                            style={{
                              // textAlign: "center",
                              alignSelf: "center",
                            }}
                          ></Col>
                          <Col
                            xs={12}
                            sm={6}
                            md={4}
                            lg={2}
                            className="px-2"
                            style={{
                              // textAlign: "center",
                              alignSelf: "center",
                            }}
                          ></Col>
                        </>
                      )}
                      {val.contentType === "item" && (
                        <>
                          <Col
                            xs={12}
                            sm={6}
                            md={4}
                            lg={2}
                            className="px-2"
                            style={{
                              // textAlign: "center",
                              alignSelf: "center",
                            }}
                          >
                            <div
                              style={{
                                flexDirection: "column",
                                display: "flex",
                              }}
                            >
                              <div
                                className="mb-2"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  backgroundColor: val.partQty
                                    ? "var(--REB-primary)"
                                    : "var(--REB-warning)",
                                  borderRadius: "5px",
                                }}
                              >
                                <p
                                  className="m-0 pl-2"
                                  style={{
                                    // backgroundColor: "var(--REB-primary)",
                                    color: "var(--REB-white)",
                                    fontWeight: "bold",
                                    fontSize: "0.8rem",
                                    width: "230px",
                                    borderRadius: "5px 5px 0px 0px",
                                  }}
                                >
                                  Qty
                                </p>
                                <FormControl
                                  as={NumericFormat}
                                  inputMode="numeric"
                                  type="text"
                                  placeholder="Qty"
                                  disabled={
                                    TransactionSelectedOperation !==
                                      SELECTED_OPERATION.EDIT &&
                                    TransactionSelectedOperation !==
                                      SELECTED_OPERATION.ADD
                                  }
                                  thousandSeparator={true}
                                  // prefix={"₱ "}
                                  suffix={` ${
                                    val.partUnit ? val.partUnit : "Select Unit"
                                  }`}
                                  allowNegative={false}
                                  value={val.partQty}
                                  onValueChange={(x) => {
                                    TempModifyTransContent({
                                      tempUUID: val.tempUUID,
                                      partQty: Number(x.value),
                                    });
                                  }}
                                />
                              </div>
                              <div
                                className="mb-2"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  backgroundColor: val.partUnitValue
                                    ? "var(--REB-primary)"
                                    : "var(--REB-warning)",
                                  borderRadius: "5px",
                                }}
                              >
                                <p
                                  className="m-0 pl-2"
                                  style={{
                                    // backgroundColor: "var(--REB-primary)",
                                    color: "var(--REB-white)",
                                    fontWeight: "bold",
                                    fontSize: "0.8rem",
                                    width: "230px",
                                    borderRadius: "5px 5px 0px 0px",
                                  }}
                                >
                                  Unit
                                </p>
                                <UnitSelect
                                  state={{ userType: CURRENT_USERTYPE }}
                                  data={TransSelModalContentUnitData}
                                  name="selDetailUnit"
                                  //isLoading={props.state.suppliers.isLoading}
                                  selected={val.partUnitValue}
                                  //tabIndex={7}
                                  lblHidden={true}
                                  refreshData={async (event) => {
                                    // props.state.refreshItemDetail("selDetailUnit", 2);
                                    const detData =
                                      await InventoryAPI.getItemDetail(
                                        dispatch,
                                        2
                                      );
                                    setTransSelModalContentUnitData(detData);
                                  }}
                                  detType={2}
                                  addUrl="inventory/createItem"
                                  editUrl="inventory/updateItem"
                                  deleteUrl="inventory/deleteItemDetail"
                                  componentName="Unit"
                                  dataStructure="itemDetailsInventory"
                                  componentType=""
                                  //optionLabel="name"
                                  disabled={
                                    TransactionSelectedOperation !==
                                      SELECTED_OPERATION.EDIT &&
                                    TransactionSelectedOperation !==
                                      SELECTED_OPERATION.ADD
                                  }
                                  handler={(data) => {
                                    // console.log(data);
                                    TempModifyTransContent({
                                      tempUUID: val.tempUUID,
                                      partUnit: data.target.value?.name,
                                      partUnitValue: data.target.value,
                                    });
                                  }}
                                  modifyRequiredPermission="perm-ui-inventoryPage-view-write-manage"
                                  deleteRequiredPermission="perm-ui-inventoryPage-view-write-manage-admin"
                                  userPermissions={permissions}
                                  // disabled={props.state.selDetailUnit.readOnly}
                                />
                                {/* <FormControl
                          type="text"
                          //style={{ textTransform: "uppercase" }}
                          placeholder="Unit"
                          value={TransSelModalContentUnit}
                          onChange={(e) =>
                            setTransSelModalContentUnit(e.currentTarget.value)
                          }
                          className=""
                        /> */}
                              </div>
                            </div>
                          </Col>
                          <Col
                            xs={12}
                            sm={6}
                            md={4}
                            lg={2}
                            className="px-2 "
                            style={{
                              textAlign: "center",
                              alignSelf: "center",
                            }}
                          >
                            <div
                              style={{
                                flexDirection: "column",
                                display: "flex",
                              }}
                            >
                              <div
                                className="mb-2"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  backgroundColor: val.amountValue
                                    ? "var(--REB-primary)"
                                    : "var(--REB-warning)",
                                  borderRadius: "5px",
                                }}
                              >
                                <p
                                  className="m-0 pl-2"
                                  style={{
                                    // backgroundColor: "var(--REB-primary)",
                                    color: "var(--REB-white)",
                                    fontWeight: "bold",
                                    fontSize: "0.60rem",
                                  }}
                                >
                                  Amount
                                </p>
                                <FormControl
                                  as={NumericFormat}
                                  inputMode="numeric"
                                  placeholder="Amount"
                                  thousandSeparator={true}
                                  prefix={"₱ "}
                                  disabled={
                                    TransactionSelectedOperation !==
                                      SELECTED_OPERATION.EDIT &&
                                    TransactionSelectedOperation !==
                                      SELECTED_OPERATION.ADD
                                  }
                                  allowNegative={true}
                                  value={val.amountValue}
                                  onValueChange={(x) => {
                                    TempModifyTransContent({
                                      tempUUID: val.tempUUID,
                                      amountValue: Number(x.value),
                                    });
                                  }}
                                />
                              </div>
                              <div
                                className="mb-2"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  backgroundColor: "var(--REB-primary)",
                                  borderRadius: "5px",
                                }}
                              >
                                <p
                                  className="m-0 pl-2"
                                  style={{
                                    // backgroundColor: "var(--REB-primary)",
                                    color: "var(--REB-white)",
                                    fontWeight: "bold",
                                    fontSize: "0.60rem",
                                  }}
                                >
                                  Total Amount
                                </p>
                                <FormControl
                                  as={NumericFormat}
                                  inputMode="numeric"
                                  placeholder="Total Amount"
                                  thousandSeparator={true}
                                  prefix={"₱ "}
                                  allowNegative={false}
                                  value={
                                    Number(val.partQty) *
                                    Number(val.amountValue)
                                  }
                                  readOnly
                                  disabled
                                />
                              </div>
                            </div>
                          </Col>
                        </>
                      )}
                      <Col
                        xs={12}
                        sm={6}
                        md={4}
                        lg={1}
                        className="px-2"
                        style={{
                          alignSelf: "center",
                          textAlign: "center",
                        }}
                      >
                        {TransModalContent?.length > 1 && (
                          <Button
                            disabled={
                              TransactionSelectedOperation !==
                                SELECTED_OPERATION.EDIT &&
                              TransactionSelectedOperation !==
                                SELECTED_OPERATION.ADD
                            }
                            onClick={() => {
                              let modifiedTransModalContent = [
                                ...TransModalContent,
                              ];
                              modifiedTransModalContent =
                                modifiedTransModalContent.filter(
                                  (x) => x.tempUUID !== val.tempUUID
                                );

                              setTransModalContent(modifiedTransModalContent);
                              //  console.log(modifiedTransModalContent);
                            }}
                            style={{
                              backgroundColor: "var(--REB-warning)",
                            }}
                            className="mx-1"
                          >
                            <i
                              style={{ color: "var(--REB-white)" }}
                              className="icofont-trash"
                            ></i>
                          </Button>
                        )}
                      </Col>
                    </Row>
                  );
                })}

                {/* {TransactionModalSelectedOperation ===
                  SELECTED_OPERATION.ADD_CONTENT && ( */}
                {/* <RenderEditContent data={{ isNew: true }} /> */}
                {/* )} */}
                {(TransactionSelectedOperation === SELECTED_OPERATION.ADD ||
                  TransactionSelectedOperation === SELECTED_OPERATION.EDIT) && (
                  <Row
                    style={{ border: "solid 1px #ced4da" }}
                    className="py-2"
                    noGutters
                  >
                    <Col
                      xs={12}
                      sm={6}
                      md={4}
                      lg={2}
                      className="px-2"
                      style={{ textAlign: "center", alignSelf: "center" }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <OverlayTrigger
                          placement={"bottom"}
                          overlay={
                            <Tooltip id={`actor-tooltip-modify`}>
                              Add Item
                            </Tooltip>
                          }
                        >
                          <Button
                            disabled={
                              TransactionSelectedOperation !==
                                SELECTED_OPERATION.EDIT &&
                              TransactionSelectedOperation !==
                                SELECTED_OPERATION.ADD
                            }
                            onClick={() => {
                              //tempCloseAllTransactionContent();
                              //console.log(TransModalContent);
                              let modifiedTransModalContent = [
                                ...TransModalContent,
                              ];
                              modifiedTransModalContent.push({
                                partName: "",
                                partIndex:
                                  Number(
                                    TransModalContent[
                                      TransModalContent.length - 1
                                    ].partIndex
                                  ) + 1,
                                partUnit: "",
                                tempUUID: filters.uuidv4(),
                                partDesc: "",
                                partQty: 0,
                                amountValue: 0,
                                // operation: SELECTED_OPERATION.VIEW,
                                contentType: "item",
                                contentTypeValue: DContentType[0],
                              });
                              const SortedData = modifiedTransModalContent.sort(
                                function (a, b) {
                                  return (
                                    Number(a.partIndex) - Number(b.partIndex)
                                  );
                                }
                              );
                              setTransModalContent(SortedData);

                              // setTransactionModalSelectedOperation(
                              //   SELECTED_OPERATION.ADD_CONTENT
                              // );
                            }}
                            style={{ backgroundColor: "var(--REB-green)" }}
                          >
                            <i
                              style={{ color: "var(--REB-white)" }}
                              className="icofont-ui-add"
                            ></i>
                          </Button>
                        </OverlayTrigger>
                        <p
                          className="m-0 p-0 pl-2"
                          style={{ alignSelf: "center" }}
                        >
                          Add new Item
                        </p>
                      </div>
                    </Col>
                  </Row>
                )}
                <div
                  className="py-2 px-4"
                  style={{ display: "flex", placeContent: "flex-end" }}
                >
                  <div
                    className="p-2 px-3"
                    style={{
                      display: "flex",
                      backgroundColor: "var(--REB-green)",
                      color: "var(--REB-white)",
                      borderRadius: "5px",
                    }}
                  >
                    <p
                      className="m-0 p-0"
                      style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                    >
                      Grand Total:
                    </p>
                    <NumericFormat
                      className="pl-3"
                      style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                      thousandSeparator={true}
                      prefix={"₱ "}
                      allowNegative={false}
                      value={TransGrandTotal}
                      displayType={"text"}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {UITransactionType === TRANS_TYPE.POSPayment && (
            <>
              <Button
                onClick={() => {
                  let _AmountDue = SelectedTransactionList.order.content
                    .reduce((a, c) => {
                      return a + c.price * c.itemQty;
                    }, 0)
                    .toFixed(2);
                  let _TotalQtyItems = SelectedTransactionList.order.content
                    .reduce((a, c) => {
                      return a + c.itemQty;
                    }, 0)
                    .toFixed(2);

                  const _VATSales = Number(_AmountDue / 1.12).toFixed(2);
                  const _VAT = Number(_AmountDue - _VATSales).toFixed(2);

                  dispatch({
                    type: "PDF_MODAL_TOGGLE",
                    doc: Receipt.ReceiptPDF(
                      SelectedTransactionList.order.content,
                      SelectedTransactionList.order.dateAdded,
                      _AmountDue,
                      SelectedTransactionList.cashTendered - _AmountDue,
                      _TotalQtyItems,
                      _VAT,
                      _VATSales,
                      SelectedTransactionList.order.client,
                      SelectedTransactionList.order.incharge,
                      SelectedTransactionList.order.cashTendered,
                      SelectedTransactionList.order,
                      true
                    ),
                  });
                }}
              >
                Reprint Receipt
              </Button>
              <h2>POS PAYMENT</h2>
            </>
          )}
          {UITransactionType === TRANS_TYPE.AcknowledgementReceipt && (
            <>
              <style>
                {`
                  @media (max-width: 991px) {
                  }
                  @media (min-width: 992px) {
                  }
                  .ChkAcknowledgement > input {
                    transform: scale(2);
                  }
                  .ChkAcknowledgement > label {
                    padding-left: 10px;
                  }
                `}
              </style>
              <Row noGutters>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <h3>Billing / Charge Invoice</h3>
                  <OverlayTrigger
                    placement={"bottom"}
                    overlay={
                      <Tooltip id={`actor-tooltip-add`}>
                        Export / Print Payment
                      </Tooltip>
                    }
                  >
                    <Button
                      onClick={async () => {
                        const fetchedTrans =
                          await TransactionsAPI.getTransaction(
                            dispatch,
                            SelectedTransactionList.id
                          );

                        let perChunk = 8; // items per chunk

                        let result = fetchedTrans.order.content.reduce(
                          (resultArray, item, index) => {
                            const chunkIndex = Math.floor(index / perChunk);
                            if (!resultArray[chunkIndex]) {
                              resultArray[chunkIndex] = []; // start a new chunk
                            }
                            resultArray[chunkIndex].push({
                              ...item,
                              index: index + 1,
                            });
                            return resultArray;
                          },
                          []
                        );
                        for (let index = 0; index < result.length; index++) {
                          const element = result[index];
                          result[index] = element.sort(function (a, b) {
                            return a.partIndex - b.partIndex;
                          });
                        }
                        const ITEMS_GRANDTOTAL =
                          fetchedTrans.order.content.reduce((a, c) => {
                            return a + c.price * c.itemQty;
                          }, 0);

                        //Filter invoice transactions for payments
                        let selectedInvoicePayments = [];
                        selectedInvoicePayments =
                          fetchedTrans.order.paymentTransaction.filter(
                            (x) => x.isEntry !== 1
                          );

                        //Push Index to paymentData
                        const IndexedPaymentData = selectedInvoicePayments.map(
                          (x, index) => {
                            return { ...x, index: index + 1 };
                          }
                        );

                        //let paymentData = [];
                        let invoiceTotalAmountPaid = 0;
                        let invoiceTotalAmountDue = 0;

                        // paymentData =
                        //   data.paidAmountHistory !== null ? JSON.parse(data.paidAmountHistory) : [];

                        invoiceTotalAmountPaid = selectedInvoicePayments
                          .reduce((a, c) => {
                            // if (c.childId == null) return a + c.price * c.itemQty;
                            // else return a + c.child.price * c.child.itemQty;
                            return a + c.amountValue;
                          }, 0)
                          .toFixed(2);

                        invoiceTotalAmountDue =
                          ITEMS_GRANDTOTAL - invoiceTotalAmountPaid;

                        if (fetchedTrans) {
                          dispatch({
                            type: "PDF_MODAL_TOGGLE",
                            doc: ChargeBillingReceipt.ChargeInvoice(
                              fetchedTrans.createdBy,
                              "Billing",
                              fetchedTrans.uniqueId,
                              fetchedTrans.client,
                              fetchedTrans.order.dateAdded,
                              fetchedTrans.amountValue,
                              fetchedTrans.createdBy,
                              fetchedTrans.approvedBy,
                              result,
                              fetchedTrans.paymentBankName,
                              fetchedTrans.paymentBankNumber,
                              fetchedTrans.checkInfo1,
                              fetchedTrans.transStatus,
                              ITEMS_GRANDTOTAL,
                              IndexedPaymentData,
                              Number(invoiceTotalAmountDue),
                              fetchedTrans
                            ),
                          });
                          // const blob = await pdf(
                          //   ChargeBillingReceipt.ChargeInvoice(
                          //     fetchedTrans.createdBy,
                          //     "Billing",
                          //     fetchedTrans.uniqueId,
                          //     fetchedTrans.client,
                          //     fetchedTrans.order.dateAdded,
                          //     fetchedTrans.amountValue,
                          //     fetchedTrans.createdBy,
                          //     fetchedTrans.approvedBy,
                          //     result,
                          //     fetchedTrans.paymentBankName,
                          //     fetchedTrans.paymentBankNumber,
                          //     fetchedTrans.checkInfo1,
                          //     fetchedTrans.transStatus,
                          //     ITEMS_GRANDTOTAL,
                          //     IndexedPaymentData,
                          //     Number(invoiceTotalAmountDue),
                          //     fetchedTrans
                          //   )
                          // ).toBlob();

                          // const blobLink = await window.URL.createObjectURL(
                          //   blob
                          // );

                          // dispatch({
                          //   type: "FILE_VIEWER_MODAL_TOGGLE",
                          //   doc: blobLink,
                          // });
                        } else {
                          dispatch({
                            type: "MODAL_SHOW",
                            payload: {
                              title: "Error Fetching Data",
                              desc: `Please Contact Administrators. Error in route getTransaction`,
                              isYesNo: false,
                            },
                          });
                        }
                      }}
                      style={{
                        backgroundColor: "var(--REB-green)",
                        color: "var(--REB-white)",
                      }}
                      className="my-1"
                    >
                      Generate Billing / Charge Invoice
                      <i
                        style={{ color: "var(--REB-white)" }}
                        className="icofont-print pl-2"
                      ></i>
                    </Button>
                  </OverlayTrigger>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <h3>Acknowledgement Receipt</h3>
                  <Button
                    style={{ backgroundColor: "var(--REB-green)" }}
                    onClick={async () => {
                      const invoicePayment = await misc.calculateInvoicePayment(
                        dispatch,
                        SelectedTransactionList.id
                      );

                      dispatch({
                        type: "PDF_MODAL_TOGGLE",
                        doc: AcknowledgementReceipt.AcknowledgementReceipt(
                          CURRENT_USER,
                          SelectedTransactionList.amountValue,
                          TransPaymentDate,
                          SelectedTransactionList.client.company,
                          SelectedTransactionList.order.uniqueId,
                          SelectedTransactionList.order.name,
                          SelectedTransactionList.order.dateAdded,
                          SelectedTransactionList.uniqueId,
                          SelectedTransactionList.paymentBankName,
                          SelectedTransactionList.paymentBankNumber,
                          SelectedTransactionList.checkInfo1,
                          AckIncBank,
                          AckIncCheck,
                          SelectedTransactionList.isDownPayment,
                          invoicePayment.invoiceTotalAmountDue === 0
                            ? true
                            : false
                        ),
                      });
                      // const blob = await pdf(
                      //   AcknowledgementReceipt.AcknowledgementReceipt(
                      //     CURRENT_USER,
                      //     SelectedTransactionList.amountValue,
                      //     TransPaymentDate,
                      //     SelectedTransactionList.client.company,
                      //     SelectedTransactionList.order.uniqueId,
                      //     SelectedTransactionList.order.name,
                      //     SelectedTransactionList.order.dateAdded,
                      //     SelectedTransactionList.uniqueId,
                      //     SelectedTransactionList.paymentBankName,
                      //     SelectedTransactionList.paymentBankNumber,
                      //     SelectedTransactionList.checkInfo1,
                      //     AckIncBank,
                      //     AckIncCheck
                      //   )
                      // ).toBlob();

                      // const blobLink = await window.URL.createObjectURL(blob);

                      // dispatch({
                      //   type: "FILE_VIEWER_MODAL_TOGGLE",
                      //   doc: blobLink,
                      // });
                    }}
                  >
                    Generate Acknowledgement Receipt
                    <i
                      style={{ color: "var(--REB-white)" }}
                      className="icofont-print pl-2"
                    ></i>
                  </Button>
                  <Row noGutters>
                    <Col xs={4} className="p-3" style={{ alignSelf: "center" }}>
                      <Form.Check
                        checked={AckIncBank}
                        name="AckIncBank"
                        className="ChkAcknowledgement"
                        label="Display Bank Info"
                        // disabled={
                        //   TransactionSelectedOperation !==
                        //     SELECTED_OPERATION.EDIT &&
                        //   TransactionSelectedOperation !== SELECTED_OPERATION.ADD
                        // }
                        onChange={(e) => {
                          setAckIncBank(!AckIncBank);
                        }}
                      />
                    </Col>
                    <Col xs={4} className="p-3" style={{ alignSelf: "center" }}>
                      <Form.Check
                        checked={AckIncCheck}
                        name="AckIncCheck"
                        className="ChkAcknowledgement"
                        label="Display Check Info"
                        // disabled={
                        //   TransactionSelectedOperation !==
                        //     SELECTED_OPERATION.EDIT &&
                        //   TransactionSelectedOperation !== SELECTED_OPERATION.ADD
                        // }
                        onChange={(e) => {
                          setAckIncCheck(!AckIncCheck);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
          {TransactionSelectedOperation !== SELECTED_OPERATION.ADD && (
            <>
              <div
                className="mb-2"
                style={{
                  display: "flex",
                  backgroundColor: "var(--REB-primary)",
                  borderRadius: "10px 10px 0px 0px",
                }}
              >
                <h5
                  className="p-3 m-0"
                  style={{ color: "white", fontWeight: "bolder" }}
                >
                  {TransSelTransactionTypeSpecific?.label} Documents
                </h5>
              </div>
              <Row noGutters className="px-3">
                <Col>
                  <>
                    <InputGroup
                      className="mb-3 p-3"
                      style={{ backgroundColor: "var(--REB-white)" }}
                    >
                      <FormControl
                        type="file"
                        custom
                        disabled={
                          TransactionSelectedOperation ===
                            SELECTED_OPERATION.VIEW ||
                          TransactionSelectedOperation ===
                            SELECTED_OPERATION.ADD
                        }
                        ref={inputFile}
                        onClick={(e) => (e.target.value = null)}
                        name="fileUploadInvoice"
                        // disabled={props.state.selContentItems.readOnly}
                        onChange={(e) => {
                          setFileUpload(e.target.files);

                          if (Array.from(e.target.files).length === 0) {
                            setBtnSaveFile(true);
                          } else {
                            setBtnSaveFile(false);
                          }
                        }}
                      />

                      <ButtonGroup className="">
                        <OverlayTrigger
                          placement={"top"}
                          overlay={
                            <Tooltip id={`order-tooltip-add`}>Save</Tooltip>
                          }
                        >
                          <Button
                            variant="secondary"
                            size="sm"
                            style={{ backgroundColor: "var(--REB-green)" }}
                            onClick={(e) => {
                              e.preventDefault();
                              //const currentFiles = fileUpload;
                              const formData = new FormData();
                              //console.log(fileUpload);
                              formData.append(
                                "uniqueId",
                                SelectedTransactionList.uniqueId
                              );
                              for (
                                let index = 0;
                                index < fileUpload.length;
                                index++
                              ) {
                                formData.append(
                                  "uploadedFiles",
                                  fileUpload[index]
                                  //`signature.${fileType}`
                                );
                              }
                              // for (var pair of formData.entries()) {
                              //   console.log(pair[0] + ", " + pair[1]);
                              // }
                              invoiceApi.uploadFile(
                                dispatch,
                                formData,
                                async () => {
                                  //const UPDATED_ITEMS = await this.refreshInvoice();
                                  //const oldSel = this.state.selectedRowInvoice;
                                  const fetchedTrans =
                                    await TransactionsAPI.getTransaction(
                                      dispatch,
                                      SelectedTransactionList.id
                                    );
                                  setSelectedTransactionList(fetchedTrans);
                                  findTransactionAndReplace(fetchedTrans);
                                }
                              );
                            }}
                            disabled={btnSaveFile}
                          >
                            Save
                          </Button>
                        </OverlayTrigger>
                      </ButtonGroup>
                    </InputGroup>
                    <Row className="p-3">
                      {FileUploadData.map((i, index) => (
                        <Col sm={3} key={index}>
                          <Card
                            className="text-center"
                            style={{ padding: "1px" }}
                          >
                            <Card.Body>
                              <Row className="mb-3">
                                <Col>
                                  <Image
                                    src={fileReg}
                                    style={{ width: "20%" }}
                                  />
                                </Col>
                              </Row>
                              <Row noGutters>
                                <Col className="">
                                  <Button
                                    size="md"
                                    style={{
                                      backgroundColor: "var(--REB-green)",
                                    }}
                                    onClick={(e) => {
                                      // props.state.handleActionChange({
                                      //   target: {
                                      //     name: "btnFileUploadDownload",
                                      //     complete: i.originalname,
                                      //     id: props.state.selectedRowInvoice
                                      //       .uniqueId,
                                      //     filename: i.filename,
                                      //   },
                                      // });
                                      invoiceApi.downloadFile(
                                        dispatch,
                                        SelectedTransactionList.uniqueId,
                                        i.filename,
                                        i.originalname,
                                        () => {
                                          // this.props.dispatch({
                                          //   type: "FILE_VIEWER_MODAL_TOGGLE",
                                          //   doc: {},
                                          //   docType: {},
                                          // });
                                        }
                                      );
                                    }}
                                    name="btnDLLink"
                                    key={index}
                                  >
                                    Download
                                  </Button>
                                </Col>
                                <Col className="">
                                  <Button
                                    size="md"
                                    style={{
                                      backgroundColor: "var(--REB-green)",
                                    }}
                                    onClick={(e) => {
                                      // props.state.handleActionChange({
                                      //   target: {
                                      //     name: "btnFileUploadView",
                                      //     filename: i.filename,
                                      //     originalname: i.originalname,
                                      //     id: props.state.selectedRowInvoice
                                      //       .uniqueId,
                                      //   },
                                      // });
                                      const url = `${
                                        process.env.REACT_APP_API_URL
                                      }global/getFile2?uniqueId=${
                                        SelectedTransactionList.uniqueId
                                      }&filename=${
                                        i.filename
                                      }&token=${localStorage.getItem("LUT")}`;
                                      dispatch({
                                        type: "FILE_VIEWER_MODAL_TOGGLE",
                                        doc: url,
                                      });
                                    }}
                                  >
                                    View
                                  </Button>
                                </Col>
                                <Col className="">
                                  <Button
                                    size="md"
                                    name={`delete${i.filename}`}
                                    value={i.filename}
                                    disabled={
                                      TransactionSelectedOperation !==
                                      SELECTED_OPERATION.EDIT
                                    }
                                    style={{
                                      backgroundColor: "var(--REB-warning)",
                                    }}
                                    onClick={(e) => {
                                      // props.state.handleActionChange({
                                      //   target: {
                                      //     name: "btnFileUploadDelete",
                                      //     filename: i.filename,
                                      //     originalname: i.originalname,
                                      //   },
                                      // });
                                      dispatch({
                                        type: "MODAL_SHOW",
                                        payload: {
                                          title: "Confirmation",
                                          desc: `Are you sure you want to delete ${i.originalname} ?`,
                                          isYesNo: true,
                                          cb: async (x) => {
                                            invoiceApi.deleteFile(
                                              dispatch,
                                              SelectedTransactionList.uniqueId,
                                              i.filename,
                                              async () => {
                                                const fetchedTrans =
                                                  await TransactionsAPI.getTransaction(
                                                    dispatch,
                                                    SelectedTransactionList.id
                                                  );
                                                setSelectedTransactionList(
                                                  fetchedTrans
                                                );
                                                findTransactionAndReplace(
                                                  fetchedTrans
                                                );
                                              }
                                            );
                                          },
                                        },
                                      });
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </Col>
                              </Row>
                            </Card.Body>
                            <Card.Footer>{i.originalname}</Card.Footer>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </>
                </Col>
              </Row>
            </>
          )}

          <div>
            {(() => {
              let disabled = false;
              if (
                TransactionSelectedOperation !== SELECTED_OPERATION.EDIT &&
                TransactionSelectedOperation !== SELECTED_OPERATION.ADD
              ) {
                disabled = true;
              }
              if (SelectedTransactionList?.transStatus === "admin-denied") {
                disabled = false;
              }
              return (
                <>
                  {" "}
                  <Form.Label
                    className="font-weight-bold"
                    style={{ color: "var(--REB-black)" }}
                  >
                    Notes
                  </Form.Label>
                  <FormControl
                    as="textarea"
                    //style={{ textTransform: "uppercase" }}
                    placeholder="Notes"
                    name="txtNotes"
                    value={TransModalNotes}
                    onChange={(e) => {
                      setTransModalNotes(e.currentTarget.value);
                    }}
                    disabled={disabled}
                  />
                </>
              );
            })()}
          </div>
        </Modal.Body>
        <Modal.Footer className="global-modal-footer">
          <Row className="w-100" noGutters>
            {(TransactionSelectedOperation === SELECTED_OPERATION.ADD ||
              TransactionSelectedOperation === SELECTED_OPERATION.EDIT) && (
              <Col xs={4} sm={4} md={4} lg={4} className="px-2">
                <Button
                  block
                  name="btnSubmitInvoice"
                  disabled={
                    TransactionSelectedOperation !== SELECTED_OPERATION.EDIT &&
                    TransactionSelectedOperation !== SELECTED_OPERATION.ADD
                  }
                  style={{ backgroundColor: "var(--REB-green)" }}
                  onClick={(e) => {
                    TransactionSelectedOperation === SELECTED_OPERATION.ADD &&
                      validateTransaction(["all"], () => createTransaction());
                    // console.log(TransactionSelectedOperation);
                    TransactionSelectedOperation === SELECTED_OPERATION.EDIT &&
                      validateTransaction(["all"], () => modifyTransaction());
                  }}
                >
                  SAVE
                </Button>
              </Col>
            )}
            {TransactionSelectedOperation === SELECTED_OPERATION.VIEW && (
              <>
                <Col xs={4} sm={4} md={4} lg={4} className=" px-2">
                  <Button
                    block
                    name="btnUnlockInvoice"
                    // disabled={
                    //   TransactionModalSelectedOperation ===
                    //     SELECTED_OPERATION.EDIT_CONTENT ||
                    //   TransactionModalSelectedOperation ===
                    //     SELECTED_OPERATION.ADD_CONTENT
                    // }
                    style={{ backgroundColor: "var(--REB-green)" }}
                    onClick={(e) => {
                      if (
                        permissions.includes("transactionPage-view-write") ||
                        userType.includes("Admin")
                      ) {
                        setTransactionSelectedOperation(
                          SELECTED_OPERATION.EDIT
                        );
                      } else {
                        dispatch({
                          type: "MODAL_SHOW",
                          payload: {
                            title: "No Permission",
                            desc: `You dont have permission to Modify Transactions.`,
                            isYesNo: false,
                          },
                        });
                      }
                    }}
                  >
                    MODIFY
                  </Button>
                </Col>
                <Col xs={4} sm={4} md={4} lg={4} className="px-2">
                  <Button
                    block
                    name="btnDeleteInvoice"
                    style={{ backgroundColor: "var(--REB-warning)" }}
                    // disabled={
                    //   TransactionModalSelectedOperation ===
                    //     SELECTED_OPERATION.EDIT_CONTENT ||
                    //   TransactionModalSelectedOperation ===
                    //     SELECTED_OPERATION.ADD_CONTENT
                    // }
                    // disabled={
                    //   props.state.handleInvoiceModal.isView ||
                    //   Number(props.state.selectedOperation.id) === 0
                    // }
                    onClick={async (e) => {
                      if (
                        permissions.includes(
                          "transactionPage-view-write-manage-admin"
                        ) ||
                        userType.includes("Admin")
                      ) {
                        dispatch({
                          type: "MODAL_SHOW",
                          payload: {
                            title: "Confirmation",
                            desc: `Are you sure you want to delete this transaction?`,
                            isYesNo: true,
                            cb: async (e) => {
                              await transactionsApi.deleteTransaction(
                                dispatch,
                                Number(SelectedTransactionList.id)
                              );
                              setTransactionModalOpen(false);
                              handleTransactionsReset();
                              refreshTransactions();
                              dispatch({
                                type: "TOAST_ADD",
                                payload: {
                                  title: `Successfully removed transaction`,
                                },
                              });
                            },
                          },
                        });
                      } else {
                        dispatch({
                          type: "MODAL_SHOW",
                          payload: {
                            title: "No Permission",
                            desc: `You dont have permission Remove Transactions.`,
                            isYesNo: false,
                          },
                        });
                      }

                      //props.state.handleActionChange(e);
                    }}
                  >
                    DELETE
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};
