import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../functions/auth";

export const ProtectedRoute = ({ component: Component, userType, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth.isAuthenticated()) {
          //console.log(props.history.location.pathname, userType);
          if (
            (props.history.location.pathname === "/dashboard" &&
              Number(userType) !== 0) ||
            (props.history.location.pathname === "/portal" &&
              Number(userType) !== 0)
          ) {
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          } else {
            return <Component {...props} />;
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};
