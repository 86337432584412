class InvoiceAPI {
  getOrdersByOrder = async (dispatch, id) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `order/getOrdersByOrder?id=${id}`,
        loadingMsg: "Fetching Invoices",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getInvoice = async (dispatch, id) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `order/getOrder?id=${id}`,
        loadingMsg: "Fetching Selected Invoice",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getInvoices = async (dispatch, actorId, filter) => {
    let filterData = [];
    if (filter.dateStart) filterData.push(`&dateStart=${filter.dateStart}`);
    if (filter.dateEnd) filterData.push(`&dateEnd=${filter.dateEnd}`);
    if (filter.ongoing) filterData.push(`&ongoing=${filter.ongoing}`);
    if (filter.displayPOS !== null && filter.displayPOS !== undefined)
      filterData.push(`&displayPOS=${Number(filter.displayPOS)}`);
    if (filter.inventoryId)
      filterData.push(`&inventoryId=${filter.inventoryId}`);
    //console.log(filterData);
    let data = {};
    if (actorId !== null) {
      data = await new Promise((resolve, reject) => {
        dispatch({
          type: "USER_GET_REQUEST",
          payload: `order/?actorId=${Number(actorId)}${filterData.join("")}`,
          loadingMsg: "Fetching Invoices",
          resolve: resolve,
          reject: reject,
        });
      }).then((api) => {
        return api.data;
      });
    } else {
      data = await new Promise((resolve, reject) => {
        dispatch({
          type: "USER_GET_REQUEST",
          payload: `order/?${filterData.join("")}`,
          loadingMsg: "Fetching Invoices",
          resolve: resolve,
          reject: reject,
        });
      }).then((api) => {
        return api.data;
      });
    }

    return data;
  };
  getInvoicesNoRel = async (dispatch, actorId, filter) => {
    let filterData = [];
    if (filter.dateStart) filterData.push(`&dateStart=${filter.dateStart}`);
    if (filter.dateEnd) filterData.push(`&dateEnd=${filter.dateEnd}`);
    if (filter.ongoing) filterData.push(`&ongoing=${filter.ongoing}`);
    if (filter.displayPOS !== null && filter.displayPOS !== undefined)
      filterData.push(`&displayPOS=${Number(filter.displayPOS)}`);
    if (filter.inventoryId)
      filterData.push(`&inventoryId=${filter.inventoryId}`);
    //console.log(filterData);
    let data = {};
    let url = "";
    if (actorId) {
      url = `order/getAllOrdersNoRel?actorId=${Number(
        actorId
      )}${filterData.join("")}`;
    } else {
      url = `order/getAllOrdersNoRel?${filterData.join("")}`;
    }

    data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: url,
        loadingMsg: "Fetching Invoices",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });

    return data;
  };
  getAllOrdersByClientId = async (dispatch, clientId, filter) => {
    let filterData = [];
    if (filter.dateStart) filterData.push(`&dateStart=${filter.dateStart}`);
    if (filter.dateEnd) filterData.push(`&dateEnd=${filter.dateEnd}`);
    if (filter.ongoing) filterData.push(`&ongoing=${filter.ongoing}`);
    if (filter.displayPOS !== null && filter.displayPOS !== undefined)
      filterData.push(`&displayPOS=${Number(filter.displayPOS)}`);
    if (filter.inventoryId)
      filterData.push(`&inventoryId=${filter.inventoryId}`);
    //console.log(filterData);
    let data = {};

    const stringIds = String(clientId)

    let url = "";
    if (clientId) {
      url = `order/getAllOrdersByClientId?actorId=${stringIds}${filterData.join("")}`;
    } else {
      url = `order/getAllOrdersByClientId?${filterData.join("")}`;
    }

    data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: url,
        loadingMsg: "Fetching Invoices",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });

    return data;
  };
  getInvoicesBreakdown = async (dispatch, filter) => {
    let filterData = [];
    if (filter.dateStart) filterData.push(`&dateStart=${filter.dateStart}`);
    if (filter.dateEnd) filterData.push(`&dateEnd=${filter.dateEnd}`);
    let data = {};
    data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `order/getInvoicesBreakdown?${filterData.join("")}`,
        loadingMsg: "Fetching Invoices",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });

    return data;
  };
  getOrderByStatusID = async (dispatch, id) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `order/getOrderByStatusID?id=${Number(id)}`,
        loadingMsg: "Fetching Invoices",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getOrderByDriverId = async (dispatch, id) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `order/getOrderByDriverId?id=${Number(id)}`,
        loadingMsg: "Fetching Invoices",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getOrderContentByStatus = async (dispatch, id, inventoryId) => {
    // console.log(inventoryId);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `order/getOrderContentByStatus?id=${Number(
          id
        )}&inventoryId=${Number(inventoryId)}`,
        loadingMsg: "Fetching Invoices",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getOrderByParentId = async (dispatch, id) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `order/getOrderByParentId?id=${id}`,
        loadingMsg: "Fetching Invoices",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getInvoiceDetailsConst = async (dispatch) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `order/getOrderConstants`,
        loadingMsg: "Fetching Invoice Details",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getInvoiceDetail = async (dispatch, type) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `order/getOrderDetailsByType?type=${type}`,
        loadingMsg: "Fetching Invoice Details",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getAllInvoiceDetail = async (dispatch) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `order/getAllOrderDetails`,
        loadingMsg: "Fetching Invoice Details",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getQuotesContent = async (dispatch, id) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `order/getQuotesContent?itemId=${id}`,
        loadingMsg: "Fetching Reserved Items",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  // getOrder = async (dispatch, sdata, cb) => {
  //   const data = await new Promise((resolve, reject) => {
  //     dispatch({
  //       type: "USER_GET_REQUEST",
  //       payload: `order/getOrderDetailsByType?type=${type}`,
  //       loadingMsg: "Fetching Order Details",
  //       resolve: resolve,
  //       reject: reject,
  //     });
  //   }).then((api) => {
  //     return api.data;
  //   });
  //   return data;
  // }
  createInvoice = async (dispatch, sdata, cb, cbFail) => {
    //console.log(sdata);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `order/`,
        loadingMsg: "Creating order",
        reqType: "post",
        data: sdata,
        resolve: resolve,
        reject: reject,
      });
    }).then(
      async (onfulfilled) => {
        //console.log(onfulfilled);
        if (cb) await cb();
        return onfulfilled.data;
      },
      async (onrejected) => {
        if (cb) await cb();
        return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };
  createInvoiceContent = async (dispatch, sdata, orderId, cb) => {
    //console.log(sdata);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `order/addOrderContent`,
        loadingMsg: "Creating Order Content",
        reqType: "post",
        data: { orderId: Number(orderId), data: sdata },
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );
    if (cb) await cb();
    return data;
  };
  updateInvoice = async (dispatch, sdata, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "put",
        data: sdata,
        payload: "order/updateOrder",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );
    if (cb) await cb();
    return data;
  };
  updateInvoiceContent = async (dispatch, sdata, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "put",
        data: sdata,
        payload: "order/updateOrderContent",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );
    if (cb) await cb();
    return data;
  };
  transferItemsToInventory = async (dispatch, sdata, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "put",
        data: sdata,
        payload: "inventory/transferItemsToInventory",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );
    if (cb) await cb();
    return data;
  };
  deleteInvoice = async (dispatch, id, cb, isArchive, keepInv) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "delete",
        data: {
          id: id,
          isArchive: Number(isArchive),
          keepInv: Number(keepInv),
        },
        payload: "order/deleteOrder",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );
    if (cb) await cb();
    return data;
  };
  deleteOrderContent = async (dispatch, id, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "delete",
        data: { id: id },
        payload: "order/deleteOrderContent",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );
    if (cb) await cb();
    return data;
  };
  deleteInvoiceDetail = async (dispatch, id, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "delete",
        data: { id: id },
        payload: "order/deleteOrderDetail",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );
    if (cb) await cb();
    return data;
  };

  addOrderContentQtyDelivered = async (dispatch, id, qty, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "post",
        data: { id: Number(id), qty: Number(qty) },
        payload: "order/addOrderContentQtyDelivered",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      async (onfulfilled) => {
        if (cb) await cb(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };
  resetOrderContentQtyDelivered = async (dispatch, id, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "post",
        data: { id: Number(id) },
        payload: "order/resetOrderContentQtyDelivered",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      async (onfulfilled) => {
        if (cb) await cb(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };

  addInvoiceImg = async (dispatch, formData, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "post",
        data: formData,
        payload: "order/uploadOrderImgs",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      async (onfulfilled) => {
        if (cb) await cb(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };

  downloadInvoiceImg = async (dispatch, e, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "getFile",
        payload: `global/getFile?uniqueId=${e.id}&filename=${e.name}`,
        resolve: resolve,
        reject: reject,
      });
    }).then(
      async (onfulfilled) => {
        if (cb) await cb(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };

  deleteInvoiceImg = async (dispatch, dat, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "delete",
        data: dat,
        payload: "global/deleteFile",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      async (onfulfilled) => {
        if (cb) await cb(onfulfilled);
        this.props.dispatch({
          type: "TOAST_ADD",
          payload: {
            title: `File Deleted Successfully`,
          },
        });
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };
  getOrderContent = async (dispatch, id) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `order/getOrderContent?id=${Number(id)}`,
        loadingMsg: "Fetching Selected Item",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  lockOrderContentEdit = async (dispatch, orderContentId, actorId, cb) => {
    //console.log(sdata);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `order/lockOrderContentEdit`,
        loadingMsg: "Locking Selected Item",
        reqType: "post",
        data: { id: Number(orderContentId), actorId: Number(actorId) },
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );
    if (cb) await cb();
    return data;
  };
  unlockOrderContentEdit = async (dispatch, orderContentId, cb) => {
    //console.log(sdata);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `order/unlockOrderContentEdit`,
        loadingMsg: "Unlocking Selected Item",
        reqType: "post",
        data: { id: Number(orderContentId) },
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );
    if (cb) await cb();
    return data;
  };
  addPayment = async (dispatch, orderContentId, payment, cb) => {
    //console.log(sdata);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `order/addPayment`,
        loadingMsg: "Adding Invoice Payment",
        reqType: "post",
        data: { id: Number(orderContentId), payment: payment },
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        if (cb) cb();
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };
  deletePayment = async (dispatch, orderContentId, paymentId, cb) => {
    //console.log(sdata);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `order/deletePayment`,
        loadingMsg: "Removing Invoice Payment",
        reqType: "post",
        data: { id: Number(orderContentId), paymentId: paymentId },
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        if (cb) cb();
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };
  uploadDRReceiver = async (dispatch, formData, cb) => {
    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "post",
        data: formData,
        payload: `order/uploadDRReceiver`,
        resolve: resolve,
        reject: reject,
      });
    }).then((onfulfilled) => {
      if (cb) cb();
      return onfulfilled.data;
    });

    return data;
  };
  uploadFile = async (dispatch, formData, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "post",
        data: formData,
        payload: `global/uploadFiles`,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      if (cb) cb();
    });

    return data;
  };
  downloadFile = async (dispatch, uniqueId, filename, complete, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "getFile",
        loadingMsg: "Preparing your file",
        payload: `global/getFile?uniqueId=${uniqueId}&filename=${filename}`,
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      let dlData = api.data;
      const url = window.URL.createObjectURL(new Blob([dlData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${complete}`);
      document.body.appendChild(link);
      link.click();
    });
    if (cb) await cb();
    return data;
  };

  deleteFile = async (dispatch, uniqueId, filename, cb) => {
    const dat = {
      uniqueId: uniqueId,
      filename: filename,
    };
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "delete",
        data: dat,
        loadingMsg: "Removing file",
        payload: "global/deleteFile",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      if (cb) cb();
    });

    return data;
  };
}
export default new InvoiceAPI();
