import React from "react";
import Table from "react-table";
import selectTableHOC from "react-table/lib/hoc/selectTable";

import {
  Invoice,
  invoiceColumn,
  invoiceDetailsColumn,
  itemColumn,
  termsColumn,
  deliveryListColumn,
  drColumn,
  selectedDrColumn,
} from "./Invoice";
import { connect } from "react-redux";
import invoiceApi from "./InvoiceAPI";
import inventoryApi from "../Inventory/InventoryAPI";
import settingsApi from "../Settings/SettingsAPI";
import accountsApi from "../Accounts/AccountsAPI";
import actorApi from "../Actor/ActorAPI";
import emptyImg from "../../assets/fork-lift.svg";
import QuotePDF from "./PDF/Quote";
import PR from "./PDF/PR";
import DR from "./PDF/DR";
import TransferItem from "./PDF/TransferItem";
import {
  PRColumn,
  POColumn,
  PRItemColumn,
  PRItemHistoryTableColumn,
} from "./PurchaseRequestContent";
import Receipt from "../POS/PDF/Receipt";
import { invTransColumn } from "./TransferInventoryContent";
import filters from "../../functions/filters";
import misc from "../../functions/misc";
import { SELECTED_OPERATION } from "../../constants";
import moment from "moment";
import {
  getInvoiceHeaderData,
  setInvoiceHeaderData,
} from "../../functions/pdfHeaderData";
import { generateQuotePDF } from "../../functions/pdfGenerate";
const SelectTable = selectTableHOC(Table);
let poDelivered = 0;
const HeaderData = getInvoiceHeaderData();
class InvoiceProcess extends React.Component {
  constructor(props) {
    super(props);
    this.invoiceDetailsSelectRef = React.createRef();
    //this.MaxDiscountOverlayRef = React.createRef();

    this.state = {
      dispatch: this.props.dispatch,
      //MaxDiscountOverlayRef: this.MaxDiscountOverlayRef,
      handleSearch: this.handleSearch.bind(this),
      handleSearchPRReqItems: this.handleSearchPRReqItems.bind(this),
      inputChange: this.inputChange.bind(this),
      handleInvoiceContentChange: this.handleInvoiceContentChange.bind(this),
      handleActionChange: this.handleActionChange.bind(this),
      handleActionChangeInvoiceDetails:
        this.handleActionChangeInvoiceDetails.bind(this),
      handleOperationChangeInvoiceDetails:
        this.handleOperationChangeInvoiceDetails.bind(this),
      getSuppliersClients: this.getSuppliersClients.bind(this),
      handleSubmit: this.handleSubmit.bind(this),
      handleInvoiceTypeChange: this.handleInvoiceTypeChange.bind(this),
      refreshInvoiceDetail: this.refreshInvoiceDetail.bind(this),
      refreshInvoice: this.refreshInvoice.bind(this),
      refreshSelectedDRContent: this.refreshSelectedDRContent.bind(this),
      updateInvoiceContentStatus: this.updateInvoiceContentStatus.bind(this),
      handleInvoiceModal: {
        //handleInvoiceSubmit: this.handleInvoiceSubmit.bind(this),
        handleExit: this.handleInvoiceExit.bind(this),
        toggle: this.handleToggleInvoiceModal.bind(this),
        isDR: false,
        isValidated: false,
        isYesNo: true,
        isShow: false,
        isView: false,
        title: "",
        desc: "",
        response: false,
      },
      handleInvoiceDetailsModal: {
        //handleInvoiceSubmit: this.handleInvoiceDetailsSubmit.bind(this),
        //handleExit: this.handleInvoiceDetailsExit.bind(this),
        toggle: this.handleInvoiceDetailsToggleModal.bind(this),
        isValidated: false,
        isYesNo: true,
        isShow: false,
        isView: false,
        title: "",
        desc: "",
        response: false,
      },
      handleItemModal: {
        //handleInvoiceSubmit: this.handleItemSubmit.bind(this),
        // handleExit: this.handleItemExit.bind(this),
        toggle: this.handleItemToggleModal.bind(this),
        isValidated: false,
        isYesNo: true,
        isShow: false,
        isView: false,
        title: "",
        desc: "",
        response: false,
      },
      handleDRModal: {
        //handleInvoiceSubmit: this.handleItemSubmit.bind(this),
        handleExit: this.handleDRExit.bind(this),
        toggle: this.handleDRToggleModal.bind(this),
        isValidated: false,
        isYesNo: true,
        isShow: false,
        isView: false,
        title: "",
        desc: "",
        response: false,
      },
      fileUpload: {
        handleSubmit: this.handleFileUploadSubmit.bind(this),
        handler: this.handleFileUploadChange.bind(this),
        value: "",
      },
      //InvoiceTable
      invoiceTableSelection: [],
      selectedRowInvoice: null,
      invoiceTableData: [],
      invoiceTableDataCopy: [],
      //InvoiceContent Table
      invoiceContentTableSelection: [],
      selectedRowInvoiceSelection: null,
      invoiceContentTableData: [],
      invoiceContentTableDataCopy: [],
      isInvoiceContentEdit: false,
      invoiceContentSelectedColumn: itemColumn([], []),
      //TermsTable
      termsTableSelection: [],
      selectedRowTerms: null,
      termsTableData: [],
      termsTableDataCopy: [],
      //DRItemsTable
      dRItemsTableSelection: [],
      selectedRowDRItems: null,
      dRItemsTableData: [],
      dRItemsTableDataCopy: [],
      dRItemsTableLoading: false,
      //DRItemSelectTable
      dRItemSelectTableSelection: [],
      selectedRowDRItemSelect: null,
      dRItemSelectTableData: [],
      dRItemSelectTableDataCopy: [],
      dRItemSelectTableLoading: false,
      //ListDRTable
      listDRTableSelection: [],
      selectedRowlistDR: null,
      listDRTableData: [],
      listDRTableDataCopy: [],
      listDRTableloading: false,
      //PRTable
      PRTableSelection: [],
      selectedRowPR: null,
      PRTableData: [],
      PRDataCopy: [],
      PRTableloading: false,
      PRItemsTableShowPrices: false,
      PRColumn: PRColumn(null, null, this.state),
      //PRTableItems
      PRItemsTableSelection: [],
      selectedRowPRItems: null,
      PRItemsTableData: [],
      PRItemsDataCopy: [],
      PRItemsTableloading: false,
      //TransferInventoryItemsTable
      TransferInvTableSelection: [],
      selectedRowTransferInv: null,
      TransferInvTableData: [],
      TransferInvDataCopy: [],
      TransferInvTableloading: false,
      //TransferInventoryItemsTable

      //TableReserved
      tableReservedData: [],

      POCogsData: [],
      POCogsDataDataCopy: [],
      //TransferInvTableloading: false,
      //Inventory
      inventoryData: [],
      InventoryItems: [],
      //Item
      itemData: [],
      //Actors
      supplierData: [],
      clientData: [],
      employees: [],
      //User
      user: this.props.user,
      userType: this.props.userType,
      invoiceContentStatus: [],
      //PDF DATA
      pdfData: null,
      pdfGroupTable: { result: [], _services: [] },
      grandTotal: 0,
      grandTotalSRP: 0,
      showPendingApproval: false,
      showInvoiceFinish: false,
      showDR: false,
      showDRConfirmation: false,
      showDROngoingDelivery: false,

      isInvoiceContentItemChange: false,

      selectedInvoice: null,
      selectedOperation: SELECTED_OPERATION.ADD,
      selectedModalOperation: SELECTED_OPERATION.ADD,
      selectedInvoiceDetailType: { value: 0, name: "name" },

      selectedInvoiceType: { name: "Quote", value: 1 },

      isFiltered: false,
      selectedContent: <></>,
      selectedColumn: invoiceColumn(),

      selectedInventory: null,
      selectedInvoiceContentItem: null,
      selectedInvoiceContentItemCopy: null,

      //Select Data
      invoiceDetailsConst: [],
      invoiceDetailsConstBak: [],
      InvoiceTypes: [],
      InvoiceStatus: [],

      chkShowAppData: { value: false, readOnly: false },

      //Select
      selDetailClient: { value: "", readOnly: false, data: [] },
      selDetailIncharge: {
        value: "",
        readOnly: false,
        data: [],
      },
      selDetailType: { value: "", readOnly: false, data: [], filteredDat: [] },
      selDetailStatus: { value: "", readOnly: false, data: [] },
      selDetailContentStatus: {
        value: "",
        readOnly: false,
        data: [],
      },
      selDetailContentType: {
        value: "",
        readOnly: false,
        data: [],
      },
      selDetailServices: {
        value: "",
        readOnly: false,
        btnReadOnly: false,
        data: [],
      },
      selInventory: {
        value: "",
        readOnly: false,
        data: [],
      },
      selContentItems: {
        value: null,
        readOnly: false,
        btnReadOnly: false,
        data: [],
        bakData: [],
      },
      selDetailTerms: {
        value: null,
        readOnly: false,
        data: [],
        bakData: [],
      },

      txtInvoiceDetailName: { value: "", readOnly: false },
      detModalSelType: { value: "", readOnly: false },

      selDetailSupplier: {
        value: "",

        readOnly: false,
        data: [],
      },

      txtQty: { value: "", readOnly: false },
      txtPrice: { value: "", readOnly: false },
      txtNetPrice: { value: "", readOnly: false },
      txtDesc: { value: "", readOnly: false },
      txtNotes: { value: "", readOnly: false },
      txtInvoiceName: { value: "", readOnly: false },
      txtShippingAddress: { value: "", readOnly: false },
      txtDeadline: { value: "", readOnly: false },
      txtDateAdded: { value: "", readOnly: false },
      txtDateFinished: { value: "", readOnly: false },
      txtDateLastModified: { value: "", readOnly: false },
      txtDiscountMax: { value: "", readOnly: false },
      txtAmountDue: { value: "", readOnly: false },
      txtAmountPaid: { value: "", readOnly: false },
      txtInvoiceOrigTotalDue: { value: "", readOnly: false },
      chkImg: { value: true, readOnly: false },
      chkTaxed: { value: false, readOnly: false, label: "" },
      chkIsDefaultTerm: { value: false, readOnly: false, label: "" },

      txtLastModifiedByActor: { value: "", readOnly: false },
      txtApprovedByActor: { value: "", readOnly: false },

      //QUOTE VIEW INPUTS
      txtQtyView: { value: "", readOnly: false },
      txtDisplayQtyView: { value: "", readOnly: false },
      txtSRPView: { value: "", readOnly: false },
      txtPlaceholder: { value: "", readOnly: false },
      txtItemIndex: { value: "", readOnly: false },

      chkIsReservation: { value: "", readOnly: false },

      //PDF CONTROLS
      chkPDFWithDesc: { value: true, readOnly: false },
      chkPDFGroupItems: { value: false, readOnly: false },

      //PR INPUTS
      txtSelectedItemPR: { value: "", readOnly: false },
      txtNetPricePR: { value: "", readOnly: false },
      txtDiscountMaxPR: { value: "", readOnly: false },
      txtQtyPR: { value: "", readOnly: false },
      txtNotesPRContent: { value: "", readOnly: false },
      txtNotesPR: { value: "", readOnly: false },
      isSelectedContentLocked: false,
      selectedPRID: null,
      txtDateDeadlinePR: { value: "", readOnly: false },
      txtDateAddedPR: { value: "", readOnly: false },
      txtDateFinishedPR: { value: "", readOnly: false },
      txtDateLastModifiedPR: { value: "", readOnly: false },

      txtCogsPR: { value: "", readOnly: false },
      txtCostPR: { value: "", readOnly: false },
      txtShippingPR: { value: "", readOnly: false },
      txtHandlingPR: { value: "", readOnly: false },
      refreshPRData: false,

      //PO INPUTS
      txtQtyDeliveredPO: { value: "", readOnly: false },

      //INV CHANGE INPUTS
      txtQtyInvChange: { value: "", readOnly: false },
      txtDateDeadlineInvChange: { value: "", readOnly: false },
      selInventoryInvChange: {
        value: "",
        readOnly: false,
        data: [],
      },
      selInventoryInvChangeFrom: {
        value: "",
        readOnly: false,
        data: [],
      },
      selInventoryInvChangeFromContent: {
        value: "",
        readOnly: false,
        data: [],
      },

      //FILTER
      dpStartDate: { value: "", readOnly: false },
      dpEndDate: { value: "", readOnly: false },
      chkAllTime: { value: false, readOnly: false },
      chkOngoing: { value: false, readOnly: false },
      chkDisplayPOS: { value: false, readOnly: false },

      //DR INPUTS
      txtNotesDR: { value: "", readOnly: false },
      txtShippingAddressDR: { value: "", readOnly: false },
      txtQtyLeftDR: { value: "", readOnly: false },
      txtQtyToDeliverDR: { value: "", readOnly: false, placeholder: "" },
      txtQtyDeliveredDR: { value: "", readOnly: false },
      selDetailStatusDR: { value: "", readOnly: false, data: [] },
      txtDeliveryDateDR: { value: "", readOnly: false },
      selectedDRID: null,
      txtDateDeadlineDR: { value: "", readOnly: false },
      txtDateDelivered: { value: "", readOnly: false },
      txtDateLastModifiedDR: { value: "", readOnly: false },
      txtDateAddedDR: { value: "", readOnly: false },
      txtInvoiceNameDR: { value: "", readOnly: false },

      selDetailDriver: {
        value: null,
        readOnly: false,
        data: [],
      },
      isPageLoading: true,
      invoiceTotalAmountDue: 0,
      invoiceTotalAmountPaid: 0,
      selectedInvoicePayments: [],

      permissions: this.props.permissions,
      isPOSDR: false,
    };
  }

  async onRefreshInvoice(data) {}
  async onRefreshInventory(data) {}

  async componentDidMount() {
    const detData = await invoiceApi.getInvoiceDetailsConst(
      this.props.dispatch
    );
    const DateToday = await actorApi.getCurrentDateTime(this.props.dispatch);
    const accounts = await accountsApi.getAccountByType(this.props.dispatch);

    let inventories = [];
    inventories = await inventoryApi.getInventory(this.props.dispatch);

    if (inventories.length === 0) {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Inventory Empty",
          desc: `You dont have permission to access any inventory. Please contact your administrator`,
          isYesNo: false,
        },
      });
    }

    let inventoryItems = [];
    let settings = null;
    if (inventories.length > 0) {
      // inventoryItems = await inventoryApi.getItemsByInventory(
      //   this.props.dispatch,
      //   inventories[0].id,
      //   null,
      //   null,
      //   null,
      //   null,
      //   null,
      //   'DAMAGED'
      // );
      settings = await settingsApi.getSettingsByInventory(
        this.props.dispatch,
        Number(inventories[0].id)
      );
      //console.log(settings);

      setInvoiceHeaderData(
        settings?.invoiceHeaderAddress1,
        settings?.invoiceHeaderAddress2,
        settings?.invoiceHeaderAddress3,
        settings?.invoiceHeaderLandline,
        settings?.invoiceHeaderMobile,
        settings?.invoiceHeaderContact,
        settings?.invoiceHeaderEmail,
        settings?.invoiceDeliveryReceiveAddress
      );
    }

    // console.log(detData.OrderDetailId);
    let FilteredInvoiceDetails = [];
    if (detData.OrderDetailId) {
      FilteredInvoiceDetails = detData.OrderDetailId.filter(
        (x) => x.name === "Terms"
      );
    }

    //console.log(inventories);
    this.setState(
      (a) => ({
        chkTaxed: { ...a.chkTaxed, label: `Add ${detData.Tax * 100}% Tax` },
        invoiceDetailsConst: detData.OrderDetailId,
        invoiceDetailsConstBak: FilteredInvoiceDetails,
        inventoryData: inventories,
        isInitialPageMount: false,
        //itemData: inventoryItems,
        DateToday,
        selContentItems: {
          ...a.selContentItems,

          data: inventoryItems,
        },
        selInventory: {
          ...a.selInventory,
          value: inventories[0],
          data: inventories,
        },
        selInventoryInvChange: {
          ...a.selInventoryInvChange,

          data: inventories,
        },
        dpStartDate: {
          ...a.dpStartDate,
          value: DateToday ? moment(DateToday).format("YYYY-MM") : "",
        },
        dpEndDate: { ...a.dpEndDate, value: "" },
        settings,
        accounts,
        //selectedInventory: inventories[0],
      }),
      () => {
        this.handleInvoiceContentChange({ target: { value: 1 } });
        this.initializeInvoiceDetails();
        this.getActors();
        if (!this.state.selInventory.value) {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "No Inventory Selection",
              desc: `Please select an Inventory`,
              isYesNo: false,
            },
          });
          return;
        }
        this.props.socketio.socket.on(
          // `refreshInvoice${this.props.user.id}`,
          `refreshInvoice`,
          async (data) => {
            if (data.userId !== this.props.user.id) {
              if (this.state.selectedContent.value === 1) {
                const invoiceItems = await this.refreshInvoice(
                  this.props.user.id
                );

                this.setState(
                  {
                    invoiceTableData: invoiceItems,
                    invoiceTableDataCopy: invoiceItems,
                  },
                  (x) => {}
                );
                this.props.dispatch({
                  type: "TOAST_ADD",
                  payload: {
                    title: `Invoice List Updated`,
                  },
                });
              }
            }
          }
          //this.onRefreshInvoice
        );
        this.props.socketio.socket.on(
          "refreshInventory",
          async (data) => {
            if (data.userId !== this.props.user.id) {
              const invId =
                Number(this.state.selInventory.value.id) === 1
                  ? 0
                  : Number(this.state.selInventory.value.id);
              const inventoryItems = await inventoryApi.getItemsByInventory(
                this.props.dispatch,
                invId,
                null,
                null,
                null,
                null,
                null,
                "ACTIVE"
              );
              //console.log(inventoryItems, this.state.selInventory.value.id);
              this.setState((a) => ({
                selContentItems: {
                  ...a.selContentItems,
                  data: inventoryItems,
                },
              }));
              this.props.dispatch({
                type: "TOAST_ADD",
                payload: {
                  title: `Inventory Items Updated`,
                },
              });
            }
          }
          //this.onRefreshInventory
        );
      }
    );
  }
  async componentWillUnmount() {
    this.props.socketio.socket.off("refreshInvoice");
    this.props.socketio.socket.off("refreshInventory");
  }

  async initializeInvoiceDetails() {
    const x = this.state.invoiceDetailsConst;
    const DETAILS = await invoiceApi.getAllInvoiceDetail(this.props.dispatch);

    if (DETAILS !== null && x.length !== 0) {
      // console.log(x);
      for (let index = 0; index < x.length; index++) {
        const arr = x[index];
        const compoName = `selDetail${arr.name}`;
        // const detData = await invoiceApi.getInvoiceDetail(
        //   this.props.dispatch,
        //   Number(x[index].value)
        // );

        const detData = DETAILS.find((x) => x.name === arr.name).details;
        // console.log(detData);

        //console.log(compoName);
        switch (compoName) {
          case "selDetailServices":
            let _services = [];
            detData.forEach((e) => {
              _services.push({ value: e.id, label: e.name });
            });
            //console.log(detData);
            this.setState((a) => ({
              [compoName]: {
                ...a[compoName],
                data: _services,
              },
            }));
            break;

          default:
            this.setState((a) => ({
              [compoName]: { ...a[compoName], data: detData },
            }));
            break;
        }
      }

      this.setState(
        (a) => ({
          selDetailType: {
            ...a.selDetailType,
            value: this.state.selDetailType.data[0],
            filteredDat: this.state.selDetailType.data.filter(
              (y) => y.hidden === 0
            ),
          },
        }),
        async () => {
          // if (this.props.userType.includes("Sales Person")) {
          //   this.setState((a) => ({
          //     selDetailType: {
          //       ...a.selDetailType,
          //       value: this.state.selDetailType.data[0],
          //       data: this.state.selDetailType.data.filter(
          //         (y) => y.name === "Quote"
          //       ),
          //     },
          //   }));
          // }
        }
      );
    } else {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Notice",
          desc: `Incomplete fetch data. You have been logged out.`,
          isYesNo: false,
        },
      });
      localStorage.clear();
      this.props.socketio.socket.disconnect();
      window.location.reload();
    }
  }

  getSuppliersClients = async (e) => {
    const _suppliers = await actorApi.getActorsByType(this.props.dispatch, 7);
    const _clients = await actorApi.getActorsByType(this.props.dispatch, 6);

    this.setState((x) => ({
      selDetailClient: {
        ...x.selDetailClient,
        data: _clients,
      },

      selDetailSupplier: {
        ...x.selDetailSupplier,
        data: _suppliers,
      },
    }));
  };

  getActors = async (e) => {
    const ACTORS = await actorApi.getAllActors(this.props.dispatch);

    const _suppliers = ACTORS.find((x) => x.name === "Supplier");
    const _clients = ACTORS.find((x) => x.name === "Client");
    const _drivers = ACTORS.find((x) => x.name === "Driver");
    //const _users = ACTORS.find((x) => x.name === "User");

    const _employee_types = [
      "Admin",
      "Executive",
      "Manager",
      "Driver",
      "Sales Person",
      "Purchaser",
      "Inventory Clerk",
      "User",
    ];

    let _employees = [];
    for (let i = 0; i < ACTORS.length; i++) {
      const _type = ACTORS[i];
      if (_employee_types.includes(_type.name))
        _employees = _employees.concat(_type.actors);
    }

    this.setState((x) => ({
      selDetailClient: {
        ...x.selDetailClient,
        data: _clients.actors,
      },
      selDetailIncharge: {
        ...x.selDetailIncharge,
        data: _employees,
      },
      selDetailSupplier: {
        ...x.selDetailSupplier,
        data: _suppliers.actors,
      },
      selDetailDriver: {
        ...x.selDetailDriver,
        data: _drivers.actors,
      },
    }));
  };

  inputChange = (e, cb) => {
    //console.log(e.target.name);
    this.setState(
      (a) => ({
        [e.target.name]: {
          ...a[e.target.name],
          value: e.target.value,
          placeholder: e.target.placeholder,
          className: e.target.className,
        },
      }),
      () => {
        if (cb) cb();
      }
    );
  };

  handleInvoiceTypeChange = (e) => {
    //(this.state.selDetailType.data[Number(e.target.value) - 1]);
    this.setState({
      selectedInvoiceType:
        this.state.selDetailType.data[Number(e.target.value) - 1],
    });
  };
  // Invoice Details Modal Handler
  handleInvoiceDetailsToggleModal = (e) => {
    if (this.state.handleInvoiceDetailsModal.isShow)
      this.setState((x) => ({
        handleInvoiceDetailsModal: {
          ...x.handleInvoiceDetailsModal,
          isShow: false,
        },
      }));
    else
      this.setState((x) => ({
        handleInvoiceDetailsModal: {
          ...x.handleInvoiceDetailsModal,
          isShow: true,
        },
      }));
  };

  // Invoice Modal Handler
  handleToggleInvoiceModal = (e, cb) => {
    if (this.state.handleInvoiceModal.isShow)
      this.setState(
        (x) => ({
          handleInvoiceModal: {
            ...x.handleInvoiceModal,
            isShow: false,
          },
        }),
        () => {
          if (cb) cb();
        }
      );
    else
      this.setState(
        (x) => ({
          handleInvoiceModal: {
            ...x.handleInvoiceModal,
            isShow: true,
          },
        }),
        () => {
          if (cb) cb();
        }
      );
  };

  handleInvoiceExit = async (e) => {
    if (this.state.refreshPRData) {
      const Invoices = await this.refreshInvoice();
      this.setState({
        invoiceTableData: Invoices,
        invoiceTableDataCopy: Invoices,
      });
    }
    this.setState((x) => ({
      selContentItems: {
        ...x.selContentItems,
        value: "",
      },
      selectedModalOperation: { id: 0, name: "Add" },

      invoiceTableSelection: [],
      selectedRowInvoice: null,
      invoiceContentTableSelection: [],
      selectedRowInvoiceSelection: null,
      termsTableSelection: [],
      selectedRowTerms: null,
      refreshPRData: false,
      selectedRowPRItems: null,
      selectedRowPR: null,
      PRTableSelection: [],
      PRItemsTableSelection: [],
      PRItemsTableloading: false,

      termsTableData: [],
      termsTableDataCopy: [],
    }));
  };

  handleDRExit = (e) => {
    if (this.state.handleInvoiceModal.isDR) {
      this.setState((x) => ({
        selectedOperation: SELECTED_OPERATION.EDIT,
        selectedModalOperation: SELECTED_OPERATION.EDIT,
      }));
    }

    this.setState((x) => ({
      dRItemSelectTableSelection: [],
      selectedRowDRItemSelect: null,
      dRItemSelectTableData: [],
      dRItemSelectTableDataCopy: [],
      dRItemsTableSelection: [],
      selectedRowDRItems: null,
      dRItemsTableData: [],
      dRItemsTableDataCopy: [],
      listDRTableSelection: [],
      selectedRowlistDR: null,
      dRItemsTableLoading: false,
      dRItemSelectTableLoading: false,
      listDRTableloading: false,
      handleInvoiceModal: { ...x.handleInvoiceModal, isDR: false },
    }));
  };

  // Item Modal Handler
  handleItemToggleModal = (e) => {
    if (this.state.handleItemModal.isShow)
      this.setState((x) => ({
        handleItemModal: {
          ...x.handleItemModal,
          isShow: false,
        },
      }));
    else
      this.setState((x) => ({
        handleItemModal: {
          ...x.handleItemModal,
          isShow: true,
        },
      }));
  };
  handleDRToggleModal = (e) => {
    if (this.state.handleDRModal.isShow)
      this.setState((x) => ({
        handleDRModal: {
          ...x.handleDRModal,
          isShow: false,
        },
      }));
    else
      this.setState((x) => ({
        handleDRModal: {
          ...x.handleDRModal,
          isShow: true,
        },
      }));
  };

  handleDRSubmit = async (e, form) => {
    let isValid = true;
    const services = this.state.invoiceContentTableData.filter(
      (x) => x.itemId === null
    );

    let updatedInvoiceContents = this.state.dRItemsTableData;
    let updatedInvoiceContentsBak = this.state.invoiceContentTableDataCopy;
    updatedInvoiceContents = updatedInvoiceContents.concat(services);
    updatedInvoiceContentsBak = updatedInvoiceContents.concat(services);
    this.setState(
      (x) => ({
        invoiceContentTableData: updatedInvoiceContents,
        invoiceContentTableDataCopy: updatedInvoiceContentsBak,
      }),
      () => {
        // if (
        //   this.state.txtDeliveryDateDR.value === null ||
        //   this.state.txtDeliveryDateDR.value === ""
        // )
        //   isValid = false;

        // if (
        //   this.state.selDetailDriver.value === null ||
        //   this.state.selDetailDriver.value === ""
        // )
        //   isValid = false;

        // if (
        //   this.state.selDetailStatusDR.value === null ||
        //   this.state.selDetailStatusDR.value === ""
        // )
        //   isValid = false;
        if (this.state.dRItemSelectTableData.length === 0) isValid = false;

        if (form.checkValidity() === false || !isValid) {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Validation warning",
              desc: `please fill in the form properly`,
              isYesNo: false,
            },
          });

          this.setState((x) => ({
            handleDRModal: {
              ...x.handleDRModal,
              isValidated: true,
            },
            //invoiceTableSelection: [],
          }));
        } else {
          //console.log(this.state.selectedModalOperation.id);
          if (this.state.selectedModalOperation.id === 0) {
            this.createDR();
          }
          if (this.state.selectedModalOperation.id === 1) {
            this.updateDR();
          }
          this.setState(
            (x) => ({
              handleDRModal: {
                ...x.handleDRModal,
                isValidated: true,
              },
              //invoiceTableSelection: [],
            }),
            () => {
              this.handleDRToggleModal();
            }
          );
        }
      }
    );
  };

  handleSubmit = async (e) => {
    const form = e.currentTarget;
    //console.log(this.state.selDetailType);
    e.preventDefault();
    e.stopPropagation();
    let isValid = true;
    let TYPE = this.state?.selDetailType?.value?.name;

    if (this.state.selectedContent.value === 1) {
      if (this.state.selectedRowInvoice?.invoiceType?.name === "POS") {
        TYPE = "Quote";
      }
      //if(this.state.selectedInvoice)
      if (TYPE === "Quote") {
        if (this.state.handleInvoiceModal.isDR) {
          this.handleDRSubmit(null, form);
        } else {
          if (
            this.state.selDetailClient.value === null ||
            this.state.selDetailClient.value === ""
          )
            isValid = false;
          if (
            this.state.selDetailIncharge.value === null ||
            this.state.selDetailIncharge.value === ""
          )
            isValid = false;
          if (
            this.state.selDetailStatus.value === null ||
            this.state.selDetailStatus.value === ""
          )
            isValid = false;

          if (form.checkValidity() === false || !isValid) {
            this.props.dispatch({
              type: "MODAL_SHOW",
              payload: {
                title: "Validation warning",
                desc: `please fill in the form properly`,
                isYesNo: false,
              },
            });

            this.setState((x) => ({
              handleInvoiceModal: {
                ...x.handleInvoiceModal,
                isValidated: true,
              },
              invoiceTableSelection: [],
            }));
          } else {
            if (this.state.selectedOperation.id === 0) {
              this.createInvoice();
            } else {
              await this.updateInvoice();
            }

            this.setState(
              (x) => ({
                handleInvoiceModal: {
                  ...x.handleInvoiceModal,
                  isValidated: true,
                },
                invoiceTableSelection: [],
              }),
              () => {
                this.handleToggleInvoiceModal();
              }
            );
          }
        }
      } else if (TYPE === "Purchase Request" || TYPE === "Purchase Order") {
        //Supplier Input Check
        // if (
        //   this.state.selDetailSupplier.value === null ||
        //   this.state.selDetailSupplier.value === ""
        // )
        //   isValid = false;

        if (
          this.state.selDetailIncharge.value === null ||
          this.state.selDetailIncharge.value === ""
        )
          isValid = false;

        if (
          this.state.selDetailStatus.value === null ||
          this.state.selDetailStatus.value === ""
        )
          isValid = false;
        if (this.state.PRTableData.length === 0) isValid = false;

        if (form.checkValidity() === false || !isValid) {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Validation warning",
              desc: `please fill in the form properly`,
              isYesNo: false,
            },
          });

          this.setState((x) => ({
            handleInvoiceModal: {
              ...x.handleInvoiceModal,
              isValidated: true,
            },
            invoiceTableSelection: [],
          }));
        } else {
          if (this.state.selectedOperation.id === 0) {
            //console.log("trigger");
            this.createPR();
          } else {
            this.updatePR();
            //await this.updateInvoice();
          }
          this.handleToggleInvoiceModal();

          this.setState((x) => ({
            handleInvoiceModal: {
              ...x.handleInvoiceModal,
              isValidated: true,
            },
            invoiceTableSelection: [],
          }));
        }
      } else if (TYPE === "Delivery Receipt") {
        this.handleDRSubmit(null, form);
      } else if (TYPE === "Transfer Inventory") {
        if (
          this.state.selDetailIncharge.value === null ||
          this.state.selDetailIncharge.value === ""
        )
          isValid = false;
        if (
          this.state.selDetailStatus.value === null ||
          this.state.selDetailStatus.value === ""
        )
          isValid = false;
        if (
          this.state.selInventoryInvChange.value === null ||
          this.state.selInventoryInvChange.value === ""
        )
          isValid = false;
        if (
          this.state.selInventory.value === null ||
          this.state.selInventory.value === ""
        )
          isValid = false;
        if (this.state.TransferInvTableData.length === 0) isValid = false;

        if (form.checkValidity() === false || !isValid) {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Validation warning",
              desc: `please fill in the form properly`,
              isYesNo: false,
            },
          });

          this.setState((x) => ({
            handleInvoiceModal: {
              ...x.handleInvoiceModal,
              isValidated: true,
            },
            invoiceTableSelection: [],
          }));
        } else {
          if (this.state.selectedOperation.id === 0) {
            //console.log("trigger");
            this.createInvTransfer();
          } else {
            await this.updateInvTransfer();
            //await this.updateInvoice();
          }
          this.handleToggleInvoiceModal();

          this.setState((x) => ({
            handleInvoiceModal: {
              ...x.handleInvoiceModal,
              isValidated: true,
            },
            invoiceTableSelection: [],
          }));
        }
      } else {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "No Invoice Selection",
            desc: `Please select an invoice type`,
            isYesNo: false,
          },
        });
      }
    } else {
      if (
        this.state.detModalSelType.value === null ||
        this.state.detModalSelType.value === ""
      )
        isValid = false;
      if (
        this.state.txtInvoiceDetailName.value === null ||
        this.state.txtInvoiceDetailName.value === ""
      )
        isValid = false;
      if (form.checkValidity() === false || !isValid) {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Validation warning",
            desc: `please fill in the form properly`,
            isYesNo: false,
          },
        });

        this.setState((x) => ({
          handleInvoiceDetailsModal: {
            ...x.handleInvoiceDetailsModal,
            isValidated: false,
          },
          invoiceTableSelection: [],
        }));
      } else {
        console.log(this.state.selectedOperation.id);
        if (this.state.selectedOperation.id === 0) {
          this.createInvoiceDetails();
        } else {
          this.updateInvoiceDetails();
        }
        this.handleInvoiceDetailsToggleModal();

        this.setState((x) => ({
          handleInvoiceDetailsModal: {
            ...x.handleInvoiceDetailsModal,
            isValidated: false,
          },

          invoiceTableSelection: [],
        }));
      }
    }
  };
  clearInputsInvTransfer = (readonly, clear) => {
    this.setState((a) => ({
      txtQtyInvChange: {
        ...a.txtQtyInvChange,
        value: clear ? "" : a.txtQtyInvChange.value,
        readOnly: readonly,
      },
      txtDateDeadlineInvChange: {
        ...a.txtDateDeadlineInvChange,
        value: clear
          ? ""
          : a.txtDateDeadlineInvChange.value
          ? a.txtDateDeadlineInvChange.value
          : "",
        readOnly: readonly,
      },
      selInventoryInvChange: {
        ...a.selInventoryInvChange,
        value: clear ? "" : a.selInventoryInvChange.value,
        readOnly: readonly,
      },
      selInventoryInvChangeFrom: {
        ...a.selInventoryInvChangeFrom,
        value: clear ? "" : a.selInventoryInvChangeFrom.value,
        readOnly: readonly,
      },
      selInventoryInvChangeFromContent: {
        ...a.selInventoryInvChangeFromContent,
        value: clear ? "" : a.selInventoryInvChangeFromContent.value,
        readOnly: readonly,
      },
    }));
  };
  clearInputsPR = (readonly, clear) => {
    this.setState((a) => ({
      txtSelectedItemPR: {
        ...a.txtSelectedItemPR,
        value: clear ? "" : a.txtSelectedItemPR.value,
        readOnly: readonly,
      },
      txtNetPricePR: {
        ...a.txtNetPricePR,
        value: clear ? "" : a.txtNetPricePR.value,
        readOnly: readonly,
      },
      txtDiscountMaxPR: {
        ...a.txtDiscountMaxPR,
        value: clear ? "" : a.txtDiscountMaxPR.value,
        readOnly: readonly,
      },
      txtQtyPR: {
        ...a.txtQtyPR,
        value: clear ? "" : a.txtQtyPR.value,
        readOnly: readonly,
      },
      txtNotesPRContent: {
        ...a.txtNotesPRContent,
        value: clear ? "" : a.txtNotesPRContent.value,
        readOnly: readonly,
      },
      txtNotesPR: {
        ...a.txtNotesPR,
        value: clear ? "" : a.txtNotesPR.value,
        readOnly: readonly,
      },
      txtDateDeadlinePR: {
        ...a.txtDateDeadlinePR,
        value: clear
          ? ""
          : a.txtDateDeadlinePR.value
          ? a.txtDateDeadlinePR.value
          : "",
        readOnly: readonly,
      },
      txtDateAddedPR: {
        ...a.txtDateAddedPR,
        value: clear
          ? ""
          : a.txtDateAddedPR.value
          ? a.txtDateAddedPR.value
          : "",
        readOnly: readonly,
      },
      txtDateFinishedPR: {
        ...a.txtDateFinishedPR,
        value: clear
          ? ""
          : a.txtDateFinishedPR.value
          ? a.txtDateFinishedPR.value
          : "",
        readOnly: readonly,
      },
      txtDateLastModifiedPR: {
        ...a.txtDateLastModifiedPR,
        value: clear ? "" : a.txtDateLastModifiedPR.value,
        readOnly: readonly,
      },
      txtCogsPR: {
        ...a.txtCogsPR,
        value: clear ? "" : a.txtCogsPR.value,
        readOnly: readonly,
      },
      txtCostPR: {
        ...a.txtCostPR,
        value: clear ? "" : a.txtCostPR.value,
        readOnly: readonly,
      },
      txtShippingPR: {
        ...a.txtShippingPR,
        value: clear ? "" : a.txtShippingPR.value,
        readOnly: readonly,
      },
      txtHandlingPR: {
        ...a.txtHandlingPR,
        value: clear ? "" : a.txtHandlingPR.value,
        readOnly: readonly,
      },
    }));
  };
  clearInputsDR = (readonly, clear) => {
    this.setState((a) => ({
      txtNotesDR: {
        ...a.txtNotesDR,
        value: clear ? "" : a.txtNotesDR.value,
        readOnly: readonly,
      },
      txtShippingAddressDR: {
        ...a.txtShippingAddressDR,
        value: clear ? "" : a.txtShippingAddressDR.value,
        readOnly: readonly,
      },
      txtInvoiceNameDR: {
        ...a.txtInvoiceNameDR,
        value: clear ? "" : a.txtInvoiceNameDR.value,
        readOnly: readonly,
      },
      txtQtyLeftDR: {
        ...a.txtQtyLeftDR,
        value: clear ? "" : a.txtQtyLeftDR.value,
        readOnly: readonly,
      },
      txtQtyToDeliverDR: {
        ...a.txtQtyToDeliverDR,
        value: clear ? "" : a.txtQtyToDeliverDR.value,
        readOnly: readonly,
      },
      txtQtyDeliveredDR: {
        ...a.txtQtyDeliveredDR,
        value: clear ? "" : a.txtQtyDeliveredDR.value,
        readOnly: readonly,
      },
      selDetailStatusDR: {
        ...a.selDetailStatusDR,
        value: clear ? "" : a.selDetailStatusDR.value,
        readOnly: readonly,
      },
      txtDeliveryDateDR: {
        ...a.txtDeliveryDateDR,
        value: clear ? "" : a.txtDeliveryDateDR.value,
        readOnly: readonly,
      },
      txtDateDeadlineDR: {
        ...a.txtDateDeadlineDR,
        value: clear
          ? ""
          : a.txtDateDeadlineDR.value
          ? a.txtDateDeadlineDR.value
          : "",
        readOnly: readonly,
      },
      txtDateDelivered: {
        ...a.txtDateDelivered,
        value: clear
          ? ""
          : a.txtDateDelivered.value
          ? a.txtDateDelivered.value
          : "",
        readOnly: readonly,
      },
      txtDateLastModifiedDR: {
        ...a.txtDateLastModifiedDR,
        value: clear
          ? ""
          : a.txtDateLastModifiedDR.value
          ? a.txtDateLastModifiedDR.value
          : "",
        readOnly: readonly,
      },
      selDetailDriver: {
        ...a.selDetailDriver,
        value: clear ? "" : a.selDetailDriver.value,
        readOnly: readonly,
      },
      txtDateAddedDR: {
        ...a.txtDateAddedDR,
        value: clear
          ? ""
          : a.txtDateAddedDR.value
          ? a.txtDateAddedDR.value
          : "",
        readOnly: readonly,
      },
    }));
  };
  clearInputs = (readonly, clear) => {
    this.setState((a) => ({
      txtInvoiceDetailName: {
        ...a.txtInvoiceDetailName,
        value: clear ? "" : a.txtInvoiceDetailName.value,

        readOnly: readonly,
      },
      // detModalSelType: {
      //   ...a.detModalSelType,
      //   value: clear ? "" : a.detModalSelType.value,

      //   readOnly: readonly,
      // },
      showDRConfirmation: false,
      txtQty: {
        ...a.txtQty,
        readOnly: readonly,
        value: clear ? "" : a.txtQty.value,
      },
      txtPrice: {
        ...a.txtPrice,
        readOnly: readonly,
        value: clear ? "" : a.txtPrice.value,
      },
      txtNetPrice: {
        ...a.txtNetPrice,
        readOnly: readonly,
        value: clear ? "" : a.txtNetPrice.value,
      },
      selDetailClient: {
        ...a.selDetailClient,
        readOnly: readonly,
        value: clear ? "" : a.selDetailClient.value,
      },
      selDetailSupplier: {
        ...a.selDetailSupplier,
        readOnly: readonly,
        value: clear ? "" : a.selDetailSupplier.value,
      },
      selDetailIncharge: {
        ...a.selDetailIncharge,
        readOnly: readonly,
        value: clear ? "" : a.selDetailIncharge.value,
      },
      txtInvoiceName: {
        ...a.txtInvoiceName,
        readOnly: readonly,
        value: clear ? "" : a.txtInvoiceName.value,
      },
      chkIsReservation: {
        ...a.chkIsReservation,
        readOnly: readonly,
        value: clear ? "" : a.chkIsReservation.value,
      },
      selDetailStatus: {
        ...a.selDetailStatus,
        readOnly: readonly,
        value: clear ? "" : a.selDetailStatus.value,
      },
      selInventory: {
        ...a.selInventory,
        readOnly: readonly,
        //value: clear ? "" : a.selInventory.value,
      },
      selContentItems: {
        ...a.selContentItems,
        readOnly: readonly,
        value: clear ? "" : a.selContentItems.value,
      },
      selDetailType: {
        ...a.selDetailType,
        readOnly: readonly,
        value: clear ? "" : a.selDetailType.value,
      },
      selDetailServices: {
        ...a.selDetailServices,
        readOnly: readonly,
        value: clear ? "" : a.selDetailServices.value,
      },
      selDetailContentStatus: {
        ...a.selDetailContentStatus,
        readOnly: readonly,
        value: clear ? "" : a.selDetailContentStatus.value,
      },
      selDetailTerms: {
        ...a.selDetailTerms,
        readOnly: readonly,
        value: clear ? "" : a.selDetailTerms.value,
      },
      txtDesc: {
        ...a.txtDesc,
        readOnly: readonly,
        value: clear ? "" : a.txtDesc.value,
      },
      txtNotes: {
        ...a.txtNotes,
        readOnly: readonly,
        value: clear ? "" : a.txtNotes.value,
      },
      txtShippingAddress: {
        ...a.txtShippingAddress,
        readOnly: readonly,
        value: clear
          ? HeaderData.invoiceDeliveryReceiveAddress
          : a.txtShippingAddress.value,
      },
      chkImg: {
        ...a.chkImg,
        value: true,
        readOnly: readonly,
      },
      txtDiscountMax: {
        ...a.txtDiscountMax,
        value: clear ? "" : a.txtDiscountMax.value,
        readOnly: readonly,
      },
      txtDeadline: {
        ...a.txtDeadline,
        value: clear ? "" : a.txtDeadline.value ? a.txtDeadline.value : "",
        readOnly: readonly,
      },
      txtDateLastModified: {
        ...a.txtDateLastModified,
        value: clear
          ? ""
          : a.txtDateLastModified.value
          ? a.txtDateLastModified.value
          : "",
        readOnly: readonly,
      },
      txtDateAdded: {
        ...a.txtDateAdded,
        value: clear ? "" : a.txtDateAdded.value ? a.txtDateAdded.value : "",
        readOnly: readonly,
      },
      txtInvoiceOrigTotalDue: {
        ...a.txtInvoiceOrigTotalDue,
        value: clear ? "" : a.txtInvoiceOrigTotalDue.value,
        readOnly: readonly,
      },
      txtAmountPaid: {
        ...a.txtAmountPaid,
        value: clear ? "" : a.txtAmountPaid.value,
        readOnly: readonly,
      },
      txtAmountDue: {
        ...a.txtAmountDue,
        value: clear ? "" : a.txtAmountDue.value,
        readOnly: readonly,
      },
      txtQtyView: {
        ...a.txtQtyView,
        value: clear ? "" : a.txtQtyView.value,
        readOnly: readonly,
      },
      txtDisplayQtyView: {
        ...a.txtDisplayQtyView,
        value: clear ? "" : a.txtDisplayQtyView.value,
        readOnly: readonly,
      },

      txtSRPView: {
        ...a.txtSRPView,
        value: clear ? "" : a.txtSRPView.value,
        readOnly: readonly,
      },

      txtLastModifiedByActor: {
        ...a.txtLastModifiedByActor,
        value: clear ? "" : a.txtLastModifiedByActor.value,
        readOnly: readonly,
      },
      txtApprovedByActor: {
        ...a.txtApprovedByActor,
        value: clear ? "" : a.txtApprovedByActor.value,
        readOnly: readonly,
      },
      txtDateFinished: {
        ...a.txtDateFinished,
        value: clear
          ? ""
          : a.txtDateFinished.value
          ? a.txtDateFinished.value
          : "",
        readOnly: readonly,
      },
      chkIsDefaultTerm: {
        ...a.chkIsDefaultTerm,
        value: clear ? "" : a.chkIsDefaultTerm.value,
        readOnly: readonly,
      },
      tableReservedData: [],
      // txtInvoiceDetailName: {
      //   value: clear ? "" : x.txtInvoiceDetailName.value,
      //
      //   readOnly: readonly,
      // },
      // detModalSelType: {
      //   value: clear ? "" : x.detModalSelType.value,
      //
      //   readOnly: readonly,
      // },
    }));
  };
  refreshInvoice = async (id, force) => {
    if (!this.state.selInventory.value) {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "No Inventory Selection",
          desc: `Please select an Inventory`,
          isYesNo: false,
        },
      });
    } else {
      const filter = {
        dateStart: this.state.dpStartDate.value,
        dateEnd: this.state.dpEndDate.value,
        ongoing: this.state.chkOngoing.value,
        displayPOS: this.state.chkDisplayPOS.value,
        inventoryId: this.state.selInventory.value.id,
      };
      //console.log(filter);
      let _id = !id ? this.props.user.id : id;
      let invoiceItems = [];
      if (this.props.userType.includes("Admin")) {
        invoiceItems = await invoiceApi.getInvoicesNoRel(
          this.props.dispatch,
          null,
          filter
        );
      } else {
        invoiceItems = await invoiceApi.getInvoicesNoRel(
          this.props.dispatch,
          Number(_id),
          filter
        );
      }
      //console.log(invoiceItems)

      let filteredInvoiceItems = [];

      if (this.state.permissions.includes("perm-ui-invoicingDRAccess-view")) {
        const DR = invoiceItems.filter(
          (x) => x.invoiceType.name === "Delivery Receipt"
        );
        filteredInvoiceItems = filteredInvoiceItems.concat(DR);
      }
      if (this.state.permissions.includes("perm-ui-invoicingPRAccess-view")) {
        const PRPO = invoiceItems.filter(
          (x) =>
            x.invoiceType.name === "Purchase Order" ||
            x.invoiceType.name === "Purchase Request"
        );
        filteredInvoiceItems = filteredInvoiceItems.concat(PRPO);
      }

      if (
        this.state.permissions.includes(
          "perm-ui-invoicingInvTransferAccess-view"
        )
      ) {
        const INV_TRANSFER = invoiceItems.filter(
          (x) => x.invoiceType.name === "Transfer Inventory"
        );
        filteredInvoiceItems = filteredInvoiceItems.concat(INV_TRANSFER);
      }

      if (
        this.state.permissions.includes("perm-ui-invoicingQuoteAccess-view")
      ) {
        const QUOTES = invoiceItems.filter(
          (x) => x.invoiceType.name === "Quote"
        );
        filteredInvoiceItems = filteredInvoiceItems.concat(QUOTES);
      }
      if (this.state.permissions.includes("perm-ui-chkPOS")) {
        const POS = invoiceItems.filter((x) => x.invoiceType.name === "POS");
        filteredInvoiceItems = filteredInvoiceItems.concat(POS);
      }
      // else if (this.props.userType.includes("Inventory Clerk")) {
      //   invoiceItems = await invoiceApi.getInvoices(
      //     this.props.dispatch,
      //     null,
      //     filter
      //   );
      //   invoiceItems = invoiceItems.filter(
      //     (x) =>
      //       x.invoiceType.name === "Purchase Order" ||
      //       x.invoiceType.name === "Purchase Request"
      //   );
      // }
      // else {
      //   invoiceItems = await invoiceApi.getInvoices(
      //     this.props.dispatch,
      //     Number(_id),
      //     filter
      //   );
      // }
      const inventoryItems = await inventoryApi.getItemsByInventory(
        this.props.dispatch,
        this.state.selInventory.value.id,
        null,
        null,
        null,
        null,
        null,
        "ACTIVE"
      );
      // console.log(inventoryItems);
      //console.log(this.state.selectedRowInvoice)
      if (force) {
        this.setState((a) => ({
          invoiceTableData: filteredInvoiceItems,
          invoiceTableDataCopy: filteredInvoiceItems,
        }));
      }

      this.setState((a) => ({
        selContentItems: {
          ...a.selContentItems,

          data: inventoryItems,
        },
      }));
      //console.log("INVOICE DATA ===> ", filteredInvoiceItems);
      return filteredInvoiceItems;
    }
  };

  populateInvoiceDetailModal = (data) => {
    this.setState((x) => ({
      txtInvoiceDetailName: { ...x.txtInvoiceDetailName, value: data.name },
      detModalSelType: { ...x.detModalSelType, value: data.type },
      chkIsDefaultTerm: {
        ...x.chkIsDefaultTerm,
        value: data.isDefault === 1 ? true : false,
      },
    }));
  };

  createInvoiceDetails = async (e) => {
    //const name = this.state.selectedInvoiceDetailType.name.toLowerCase();
    const data = {
      details: [
        {
          name: this.state.txtInvoiceDetailName.value,
          type: Number(this.state.selectedInvoiceDetailType.value),
          isDefault: this.state.chkIsDefaultTerm.value ? 1 : 2,
        },
      ],
    };

    await invoiceApi.createInvoice(this.props.dispatch, data);
    const detData = await invoiceApi.getInvoiceDetail(
      this.props.dispatch,
      this.state.selectedInvoiceDetailType.value
    );
    this.clearInputs();
    this.setState({
      invoiceTableData: detData,
      invoiceTableDataCopy: detData,
    });
  };

  updateInvoiceDetails = async () => {
    const data = {
      details: [
        {
          id: Number(this.state.selectedRowInvoice.id),
          name: this.state.txtInvoiceDetailName.value,
          type: Number(this.state.detModalSelType.value),
          isDefault: this.state.chkIsDefaultTerm.value ? 1 : 2,
        },
      ],
    };
    console.log(data);

    await invoiceApi.updateInvoice(this.props.dispatch, data);

    const detData = await invoiceApi.getInvoiceDetail(
      this.props.dispatch,
      this.state.selectedInvoiceDetailType.value
    );
    this.clearInputs();
    this.setState({
      invoiceTableData: detData,
      invoiceTableDataCopy: detData,
    });
  };

  deleteInvoiceDetail = async (id) => {
    await invoiceApi.deleteInvoiceDetail(this.props.dispatch, id);
    const detData = await invoiceApi.getInvoiceDetail(
      this.props.dispatch,
      this.state.selectedInvoiceDetailType.value
    );
    this.clearInputs();
    this.setState({
      invoiceTableData: detData,
      invoiceTableDataCopy: detData,
    });
  };
  getInvoice = async (e) => {
    await invoiceApi.getInvoice(this.props.dispatch).then((invData) => {
      this.setState({
        invoiceTableData: invData,
      });
    });
  };

  handleOperationChangeInvoiceDetails = (e) => {
    switch (e.target.name) {
      case "btnAddInvoiceDetails":
        this.handleInvoiceDetailsToggleModal();
        this.setState((x) => ({
          selectedOperation: SELECTED_OPERATION.ADD,
          handleInvoiceDetailsModal: {
            ...x.handleInvoiceDetailsModal,
            isView: false,
          },

          invoiceTableSelection: [],
        }));
        break;

      default:
        break;
    }
  };
  handleInvoiceContentChange = async (e) => {
    switch (Number(e.target.value)) {
      case 1:
        this.setState(
          (x) => ({
            chkAllTime: {
              ...x.chkAllTime,
              value: false,
              readOnly: true,
            },
            chkOngoing: {
              ...x.chkOngoing,
              value: true,
              readOnly: false,
            },
            chkDisplayPOS: {
              ...x.chkDisplayPOS,
              value: false,
              readOnly: false,
            },
            dpStartDate: {
              ...x.dpStartDate,
              value: moment(this.state.DateToday)
                .subtract(3, "months")
                .format("YYYY-MM"),
              // value: misc.parseAPIDate(moment(DateToday).subtract(30, "days")),
              readOnly: true,
            },

            // dpEndDate: {
            //   ...x.dpEndDate,
            //   value: "",
            //   readOnly: true,
            // },
          }),
          async () => {
            const invoiceItems = await this.refreshInvoice(this.props.user.id);
            //console.log(invoiceItems);
            this.setState((x) => ({
              selectedColumn: invoiceColumn(
                this.state.InvoiceTypes,
                this.state.InvoiceStatus,
                this.state.DateToday
              ),
              invoiceTableData: invoiceItems,
              invoiceTableDataCopy: invoiceItems,

              selectedContent: {
                value: Number(e.target.value),
              },
            }));
          }
        );

        break;
      case 2:
        this.setState(
          {
            selectedColumn: invoiceDetailsColumn,
            invoiceTableSelection: [],
            selectedRowInvoice: null,
            invoiceTableData: [],
            invoiceTableDataCopy: [],
            selectedContent: {
              value: Number(e.target.value),
            },
          },
          async (x) => {
            let event = new MouseEvent("change", {
              bubbles: true,
              cancelable: false,
            });

            this.invoiceDetailsSelectRef.current.dispatchEvent(event);
          }
        );
        break;
      default:
        break;
    }
  };

  loadPRItems = async () => {
    let invId =
      this.state.selInventory.value.id === 1
        ? 0
        : this.state.selInventory.value.id;

    //console.log('INVENTORY ID ===> ',  this.state.selInventory.value)

    const data = await invoiceApi.getOrderContentByStatus(
      this.props.dispatch,
      this.state.selDetailContentStatus.data.find((x) => x.name === "For order")
        .id,
      invId
    );
    //console.log('DETAIL STATUSES ===> ', this.state.selDetailContentStatus)
    //console.log('REQUEST ITEMS DATA ===> ', data)

    this.setState((x) => ({
      PRItemsTableData: data,
      PRItemsDataCopy: data,
      PRItemsTableSelection: [],
      selectedRowPRItems: null,
      PRTableSelection: [],
      selectedRowPR: null,
      PRTableData: [],
      PRDataCopy: [],
    }));

    //console.log(data, this.state.selInventory.value);

    //const _filtered = data.filter((x) => x.qtyOrdered !== x.itemQty);

    //return data;
  };

  handleActionChange = async (e, data, cb) => {
    //console.log(data);
    const _elName = e.target.name;
    if (_elName === "btnAddInvoice") {
      if (!this.state.selInventory.value) {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "No Inventory Selection",
            desc: `Please select an Inventory`,
            isYesNo: false,
          },
        });
      } else {
        this.clearInputs(false, true);
        if (this.state.selectedContent.value === 1) {
          this.handleToggleInvoiceModal();
        } else {
          this.handleInvoiceDetailsToggleModal();
          this.setState((x) => ({
            //selectedOperation: { id: 0, name: "Add" },
            handleInvoiceDetailsModal: {
              ...x.handleInvoiceDetailsModal,
              isView: false,
            },
            //invoiceTableSelection: [],
          }));
        }

        this.setState((x) => ({
          selectedOperation: SELECTED_OPERATION.ADD,

          handleInvoiceModal: {
            ...x.handleInvoiceModal,
            isView: false,
            isValidated: false,
          },
          handleInvoiceDetailsModal: {
            ...x.handleInvoiceDetailsModal,
            isView: false,
          },
          invoiceTableSelection: [],
          invoiceContentTableData: [],
          invoiceContentTableSelection: [],
          selectedRowInvoiceSelection: null,
          //isInvoiceContentEdit: false,
          termsTableData: [],
          termsTableDataCopy: [],
          invoiceContentTableDataCopy: [],
          selectedModalOperation: { id: 1, name: "Edit" },
          selectedRowInvoice: null,
          termsTableSelection: [],
          selectedRowTerms: null,
          grandTotal: 0,
          showPendingApproval: false,
          showDR: false,
          showInvoiceFinish: false,
        }));
      }
    }
    if (_elName === "btnUnlockInvoice") {
      let allow = true;

      if (
        (this.props.userType.includes("Sales Person") ||
          this.state.permissions.includes(
            "perm-ui-invoicingPage-view-write"
          )) &&
        this.state.selDetailStatus.value.name !== "New" &&
        this.state.selDetailStatus.value.name !== "Admin Denied"
      )
        allow = false;
      if (
        (this.props.userType.includes("Purchaser") ||
          this.state.permissions.includes(
            "perm-ui-invoicingPage-view-write"
          )) &&
        this.state.selDetailStatus.value.name !== "New" &&
        this.state.selDetailStatus.value.name !== "Admin Denied"
      )
        allow = false;
      if (this.props.userType.includes("Inventory Clerk")) allow = false;

      //override with permission
      if (
        this.state.permissions.includes(
          "perm-ui-invoicingPage-view-write-manage"
        )
      )
        allow = true;

      if (allow) {
       
        this.clearInputs(false);
        if (this.state.selectedContent.value === 1) {
          if (
            this.state.selectedRowInvoice.invoiceType.name ===
            "Purchase Request"
          ) {
            this.clearInputsPR(false, false);
          }

          this.setState((x) => ({
            handleInvoiceModal: { ...x.handleInvoiceModal, isView: false },
            //isInvoiceContentEdit: true,
            selectedModalOperation: { id: 1, name: "Edit" },
            selectedOperation: { id: 1, name: "Edit" },
            selDetailType: { ...x.selDetailType, readOnly: true },
          }));
        } else {
          this.setState((x) => ({
            handleInvoiceDetailsModal: {
              ...x.handleInvoiceDetailsModal,
              isView: false,
            },
          }));
        }
        // if(this.state.selectedRowInvoice.invoiceType.name === 'Quote')
      } else {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "No Permission",
            desc: `You are not allowed to modify this invoice.`,
            isYesNo: false,
          },
        });
      }
    }
    if (_elName === "handleInvoiceTypeChange") {
      const _newStatus = this.state.selDetailStatus.data.find(
        (x) => x.name === "New"
      );
      const _incharge = this.state.selDetailIncharge.data.find(
        (x) => x.id === this.state.user.id
      );

      let allow = false;

      // if (
      //   this.state.userType.includes("Sales Person") &&
      //   e.target.value.name !== "Quote"
      // )
      //   allow = false;
      // if (
      //   this.state.userType.includes("Purchaser") &&
      //   e.target.value.name !== "Purchase Request"
      // )
      //   allow = false;
      // if (
      //   this.state.userType.includes("Sales Person") &&
      //   e.target.value.name === "Transfer Inventory"
      // )
      //   allow = true;

      if (
        this.state.permissions.includes(
          "perm-ui-invoicingQuoteAccess-view-write"
        ) &&
        e.target.value.name === "Quote"
      ) {
        allow = true;
      }

      if (
        this.state.permissions.includes(
          "perm-ui-invoicingPRAccess-view-write"
        ) &&
        e.target.value.name === "Purchase Request"
      ) {
        allow = true;
      }

      if (
        this.state.permissions.includes(
          "perm-ui-invoicingInvTransferAccess-view-write"
        ) &&
        e.target.value.name === "Transfer Inventory"
      ) {
        allow = true;
      }
      //Override Admin
      if (this.state.userType.includes("Admin")) {
        allow = true;
      }

      if (allow) {
        switch (e.target.value.name) {
          case "Quote":
            this.handleActionChange({
              target: {
                value: e,
                name: "btnAddDefaultTerms",
              },
            });
            this.setState((x) => ({
              invoiceContentSelectedColumn: itemColumn([], []),
              selDetailStatus: { ...x.selDetailStatus, value: _newStatus },
              selDetailIncharge: { ...x.selDetailIncharge, value: _incharge },
              isInvoiceContentEdit: false,
            }));
            break;

          case "Purchase Request":
            this.clearInputsPR(false, true);
            await this.loadPRItems();
            this.setState((x) => ({
              PRColumn: PRColumn(null, null, this.state),
              POCogsData: [],
              selDetailStatus: { ...x.selDetailStatus, value: _newStatus },
              selDetailIncharge: { ...x.selDetailIncharge, value: _incharge },
            }));
            break;
          case "Transfer Inventory":
            this.clearInputsInvTransfer(false, true);
            this.setState((x) => ({
              PRColumn: PRColumn(null, null, this.state),
              TransferInvTableSelection: [],
              selectedRowTransferInv: null,
              TransferInvTableData: [],
              TransferInvDataCopy: [],
              TransferInvTableloading: false,
              selDetailStatus: { ...x.selDetailStatus, value: _newStatus },
              selDetailIncharge: { ...x.selDetailIncharge, value: _incharge },
              selInventoryInvChange: {
                ...x.selInventoryInvChange,
                data: this.state.selInventory.data,
              },
            }));
            break;
          default:
            break;
        }

        this.setState((x) => ({
          selDetailType: { ...x.selDetailType, value: e.target.value },
          PRItemsTableSelection: [],
          selectedRowPRItems: null,
          PRItemsTableShowPrices: false,
          PRItemsTableloading: false,
          invoiceTotalAmountDue: 0,
          invoiceTotalAmountPaid: 0,
          selectedInvoicePayments: [],
        }));
      } else {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "No Permission",
            desc: `You are not allowed to access this invoice type`,
            isYesNo: false,
          },
        });
      }
    }
    if (_elName === "btnEditInvoice") {
      if (this.state.invoiceTableSelection.length > 1) {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Selection Exceeded",
            desc: `Only 1 invoiceTableSelection is allowed`,
            isYesNo: false,
          },
        });
      } else {
        console.log(data);
        //Fetch invoice by ID

        // console.log(FETCHED_INVOICE);
        // console.log(e.target?.isRedirect);
        if (data.type > 0) {
          this.setState(
            (x) => ({
              handleInvoiceDetailsModal: {
                ...x.handleInvoiceDetailsModal,
                isView: true,
              },
            }),
            (x) => {
              this.populateInvoiceDetailModal(data);
              this.handleInvoiceDetailsToggleModal();
            }
          );
        } else {
          const FETCHED_INVOICE = await invoiceApi.getInvoice(
            this.props.dispatch,
            data.id
          );
          if (FETCHED_INVOICE) {
            //If DR is selected from inside quotation invoice, open DR Modal, Inject to selected Invoice State

            this.clearInputs(true);
            //Invoice Management Selection
            if (this.state.selectedContent.value === 1) {
              this.setState(
                (x) => ({
                  handleInvoiceModal: {
                    ...x.handleInvoiceModal,
                    isView: true,
                  },
                }),
                () => {}
              );
              const _name = FETCHED_INVOICE.invoiceType.name;
              if (_name === "Quote" || _name === "POS") {
                this.setState((x) => ({
                  invoiceContentSelectedColumn: itemColumn([], []),
                }));

                this.populateInvoiceModal(FETCHED_INVOICE, () => {
                  this.handleToggleInvoiceModal();
                });
              }
              if (_name === "Purchase Request") {
                this.clearInputsPR(true, true);
                this.setState((x) => ({
                  PRColumn: PRColumn(null, null, this.state),
                }));
                this.populatePR(FETCHED_INVOICE, () => {
                  this.handleToggleInvoiceModal();
                });
              }
              if (_name === "Purchase Order") {
                this.clearInputsPR(true, true);
                this.setState((x) => ({
                  PRColumn: POColumn(null, null, this.state),
                }));
                this.populatePR(FETCHED_INVOICE, () => {
                  this.handleToggleInvoiceModal();
                });
              }
              if (_name === "Delivery Receipt") {
                // if (e.target?.isRedirect) {
                //   this.setState(
                //     (x) => ({
                //       selectedRowInvoice: FETCHED_INVOICE,
                //     }),
                //     () => {
                //       this.populateDR(FETCHED_INVOICE, () => {
                //         this.handleDRToggleModal();
                //       });
                //     }
                //   );
                // } else {

                // }
                this.populateDR(FETCHED_INVOICE, () => {
                  this.handleDRToggleModal();
                });
              }
              if (_name === "Transfer Inventory") {
                this.setState((x) => ({
                  //invoiceContentSelectedColumn: PRColumn([], []),
                }));
                this.populateTransferItem(FETCHED_INVOICE, () => {
                  this.handleToggleInvoiceModal();
                });
              }
              //Invoice Details Selection
            } else {
              this.setState(
                (x) => ({
                  handleInvoiceDetailsModal: {
                    ...x.handleInvoiceDetailsModal,
                    isView: true,
                  },
                }),
                (x) => {
                  this.populateInvoiceDetailModal(data);
                  this.handleInvoiceDetailsToggleModal();
                }
              );
            }
            this.setState((x) => ({
              selectedOperation: SELECTED_OPERATION.EDIT,
              //override selected invoice in state
              selectedRowInvoice: FETCHED_INVOICE,
            }));
          } else {
            this.props.dispatch({
              type: "MODAL_SHOW",
              payload: {
                title: "Notice",
                desc: `There was a problem fetching this invoice. Its possible that you have a slow internet connection. Please contact administrator.`,
                isYesNo: false,
              },
            });
          }
        }
      }
    }
    if (_elName === "btnDeleteInvoice") {
      if (this.state.invoiceTableSelection.length > 1) {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Selection Exceeded",
            desc: `Please select 1 Invoice`,
            isYesNo: false,
          },
        });
      } else {
        if (this.state.invoiceTableSelection.length === 1) {
          this.setState((x) => ({
            selectedOperation: SELECTED_OPERATION.DELETE,
          }));
          if (this.state.selectedRowInvoice.isDefault === 1) {
            this.props.dispatch({
              type: "MODAL_SHOW",
              payload: {
                title: "Notice",
                desc: `You are not allowed to remove default terms.`,
                isYesNo: false,
              },
            });
            this.handleInvoiceDetailsToggleModal();
          } else {
            let _name = undefined;
            let _desc = "Remove or Archive";
            let _invoiceName = "";
            if (this.state.selectedRowInvoice.invoiceType) {
              _invoiceName = this.state.selectedRowInvoice.invoiceType.name;
            } else {
              _invoiceName = "itemDetail";
            }

            const _uniqueDelete = [
              "Quote",
              "Purchase Request",
              "Purchase Order",
              "Transfer Inventory",
            ];
            if (
              this.state.selectedContent.value === 1 &&
              _uniqueDelete.indexOf(_invoiceName) > -1
            ) {
              _name = "Archive";
            } else {
              _desc = "Remove";
            }

            this.props.dispatch({
              type: "MODAL_SHOW",
              payload: {
                title: "Confirmation",
                desc: `${_desc} ${this.state.selectedRowInvoice.name} ? \n This action will return all invoice contents to inventory`,
                isYesNo: true,
                yesLbl: "Remove Permanently",
                noLbl: _name,

                cb: async (e) => {
                  if (
                    this.state.permissions.includes(
                      "perm-ui-invoicingQuoteAccess-view-write-manage-admin"
                    ) ||
                    this.state?.selectedRowInvoice?.invoiceStatus?.name ===
                      "New" ||
                    this.state?.selectedRowInvoice?.invoiceStatus?.name ===
                      "Admin Denied"
                  ) {
                    if (this.state.selectedContent.value === 1) {
                      if (_uniqueDelete.indexOf(_invoiceName) > -1) {
                        await this.deleteInvoice(
                          this.state.selectedRowInvoice.id,
                          0,
                          1
                        );
                        this.handleToggleInvoiceModal();
                        // this.props.dispatch({
                        //   type: "MODAL_SHOW",
                        //   payload: {
                        //     title: "Confirmation",
                        //     desc: `Would you like to return contents of this invoice to inventory?`,
                        //     isYesNo: true,
                        //     yesLbl: "Return to Inventory",
                        //     noLbl: "Remove Permanently",
                        //     cb: (e) => {
                        //       this.handleToggleInvoiceModal();
                        //       this.deleteInvoice(
                        //         this.state.selectedRowInvoice.id,
                        //         0,
                        //         1
                        //       );
                        //     },
                        //     cbNo: (e) => {
                        //       this.deleteInvoice(
                        //         this.state.selectedRowInvoice.id,
                        //         0,
                        //         0
                        //       );
                        //       this.handleToggleInvoiceModal();
                        //     },
                        //   },
                        // });
                      } else {
                        this.handleToggleInvoiceModal();
                      }
                    } else {
                      await this.deleteInvoiceDetail(
                        this.state.selectedRowInvoice.id
                      );
                      this.handleInvoiceDetailsToggleModal();
                    }
                  } else {
                    this.props.dispatch({
                      type: "MODAL_SHOW",
                      payload: {
                        title: "No Permission",
                        desc: `You dont have permission to permanently remove invoices`,
                        isYesNo: false,
                      },
                    });
                  }
                },
                cbNo: async (e) => {
                  // this.handleToggleInvoiceModal();

                  if (
                    this.state.selectedContent.value === 1 &&
                    _uniqueDelete.indexOf(_invoiceName) > -1
                  ) {
                    await this.deleteInvoice(
                      this.state.selectedRowInvoice.id,
                      1,
                      1
                    );
                    if (this.state.handleInvoiceModal.isShow)
                      this.handleToggleInvoiceModal();
                  } else {
                    if (this.state.handleInvoiceModal.isShow)
                      this.handleToggleInvoiceModal();
                  }
                },
              },
            });
          }
        } else {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "No Selection",
              desc: `Please select a ${this.state.selectedRowInvoice.name}`,
              isYesNo: false,
            },
          });
        }
      }
    }
    if (_elName === "selDetailServices") {
      this.setState((x) => ({
        selDetailServices: {
          ...x.selDetailServices,
          value: e.target.value,
        },
      }));
    }
    if (_elName === "selDetailContentStatus") {
      this.setState((x) => ({
        selDetailContentStatus: {
          ...x.selDetailContentStatus,
          value: e.target.value,
        },
      }));
    }
    if (_elName === "selInvoiceContentItems") {
      //console.log("THIS", e.target.value);
      const _val = e.target.value;
      const reserved = await invoiceApi.getQuotesContent(
        this.props.dispatch,
        _val.id
      );
      //console.log(reserved)

      let productPrice = 0;

      let priceModifier = 0;
      if (this.state.settings?.itemPriceModification) {
        // const percentageModifier =
        //   this.state.settings?.itemPriceModification / 100;
        // priceModifier = percentageModifier;

        // productPrice = _val.price + _val.price * percentageModifier;

        // //console.log(productPrice)

        // productPrice = Number(productPrice.toFixed(2));
        // //console.log(productPrice)
        productPrice = misc.calculateMarkup(
          _val.price,
          this.state.settings?.itemPriceModification
        );
      } else {
        productPrice = _val.price;
      }

      this.setState(
        (x) => ({
          selContentItems: {
            ...x.selContentItems,
            value: e.target.value,
          },
          tableReservedData: reserved,
          //txtQtyView: { ...x.txtQtyView, value: _val.qty },
          txtDisplayQtyView: { ...x.txtDisplayQtyView, value: _val.display },
          txtSRPView: { ...x.txtSRPView, value: productPrice },
        }),
        () => {
          if (cb) cb();
        }
      );
    }
    if (_elName === "btnSubmitInvoiceContentQuote") {
      let _tempData = this.state.invoiceContentTableData;
      let _tempDataBak = this.state.invoiceContentTableDataCopy;
      let _newData = [];
      if (data.isGroup) {
        _newData.push({
          id:
            this.state.selectedRowInvoiceSelection !== null
              ? this.state.selectedRowInvoiceSelection.id
              : filters.uuidv4(),
          placeholder: this.state.txtPlaceholder.value,
          isNew: this.state.selectedRowInvoiceSelection !== null ? false : true,
          itemId: null,
          itemUnitId: null,
          childId: null,
          serviceName: null,
          orderStatus: null,
          price: 0,
          itemQty: 0,
          discount: 0,
          srp: 0,
          priceModifier: 0,
          itemIndex: this.state.txtItemIndex.value,
        });
      } else {
        if (data.isItem) {
          _newData.push({
            id:
              this.state.selectedRowInvoiceSelection !== null
                ? this.state.selectedRowInvoiceSelection.id
                : filters.uuidv4(),
            item: {
              details: this.state.selContentItems.value.details,
              id: this.state.selContentItems.value.id,
              uploads: this.state.selContentItems.value.files,
              uniqueId: this.state.selContentItems.value.uniqueId,
            },
            itemQty: Number(this.state.txtQty.value),
            price: Number(this.state.txtNetPrice.value),
            dispImg: { original: emptyImg, isDefault: true },
            showImg: this.state.chkImg.value === true ? 1 : 0,
            discount: Number(this.state.txtDiscountMax.value),
            priceModifier: Number(this.state.settings?.itemPriceModification),
            srp: Number(this.state.selContentItems.value.price),
            itemId: this.state.selContentItems.value.id,
            itemIndex: this.state.txtItemIndex.value,
            itemUnitId: this.state.selContentItems.value.details.find(
              (x) => x.type === 2
            ).id,
            orderStatus: this.state.selDetailContentStatus.value,
            orderType: this.state.selDetailContentType.value.name,
            orderStatusId: Number(this.state.selDetailContentStatus.value.id),
            serviceName: null,
            orderTypeId: this.state.selDetailContentType.data.find(
              (x) => x.name === "Item"
            ).id,
            childId:
              this.state.selectedRowInvoiceSelection !== null
                ? this.state.selectedRowInvoiceSelection.childId
                : null,
            child:
              this.state.selectedRowInvoiceSelection !== null
                ? this.state.selectedRowInvoiceSelection.child
                : null,
            isNew:
              this.state.selectedRowInvoiceSelection !== null ? false : true,
          });
          //console.log(this.state.selContentItems.value);
        } else {
          _newData.push({
            id:
              this.state.selectedRowInvoiceSelection !== null
                ? this.state.selectedRowInvoiceSelection.id
                : filters.uuidv4(),
            price: Number(this.state.txtNetPrice.value),
            itemQty: Number(this.state.txtQty.value),
            itemId: null,
            dispImg: { original: emptyImg, isDefault: true },
            showImg: 0,
            discount: Number(this.state.txtDiscountMax.value),
            priceModifier: Number(this.state.settings?.itemPriceModification),
            srp: Number(this.state.txtNetPrice.value),
            itemUnitId: null,
            itemIndex: this.state.txtItemIndex.value,
            serviceName: JSON.stringify(this.state.selDetailServices.value),
            orderStatus: this.state.selDetailContentStatus.value,
            orderType: this.state.selDetailContentType.value.name,
            orderStatusId: Number(this.state.selDetailContentStatus.value.id),
            orderTypeId: this.state.selDetailContentType.data.find(
              (x) => x.name === "Service"
            ).id,
            childId: null,

            isNew:
              this.state.selectedRowInvoiceSelection !== null ? false : true,
          });
        }
      }

      // _grandTotal = (
      //   Number(_grandTotal) + Number(this.state.grandTotal)
      // ).toFixed(2);

      if (this.state.invoiceContentTableSelection.length > 0) {
        const _updatedInvoiceContentTableData = _tempData.map((x) =>
          x.id === this.state.selectedRowInvoiceSelection.id
            ? { ..._newData[0] }
            : x
        );
        const _updatedInvoiceContentTableDataBak = _tempDataBak.map((x) =>
          x.id === this.state.selectedRowInvoiceSelection.id
            ? { ..._newData[0] }
            : x
        );

        const FINAL_DATA_ORIG = _updatedInvoiceContentTableData.sort(function (
          a,
          b
        ) {
          return a.itemIndex - b.itemIndex;
        });
        const FINAL_DATA_BAK = _updatedInvoiceContentTableDataBak.sort(
          function (a, b) {
            return a.itemIndex - b.itemIndex;
          }
        );
        let _grandTotal = FINAL_DATA_ORIG.reduce((a, c) => {
          return a + c.price * c.itemQty;
        }, 0);

        this.setState(
          {
            grandTotal: _grandTotal,
            invoiceContentTableDataCopy: FINAL_DATA_BAK,
            invoiceContentTableData: FINAL_DATA_ORIG,
          },
          (x) => {}
        );
      } else {
        //console.log(_tempData.concat(_newData));

        const FINAL_DATA = _tempData.concat(_newData).sort(function (a, b) {
          return a.itemIndex - b.itemIndex;
        });
        let _grandTotal = FINAL_DATA.reduce((a, c) => {
          return a + c.price * c.itemQty;
        }, 0);
        this.setState(
          {
            grandTotal: _grandTotal,
            invoiceContentTableData: FINAL_DATA,
            invoiceContentTableDataCopy: FINAL_DATA,
          },
          (x) => {}
        );
      }
      // const testData = _tempData.concat(_newData);
      // console.log(testData)

      if (cb) cb();
    }
    if (_elName === "btnCancelInvoiceContentQuote") {
      this.setState((x) => ({
        isInvoiceContentEdit: false,
        isInvoiceContentItemChange: false,
        selectedRowInvoiceSelection: null,
        invoiceContentTableSelection: [],
        txtPlaceholder: { value: "", readOnly: false },
        txtItemIndex: { value: "", readOnly: false },

        selContentItems: {
          ...x.selContentItems,
          value: "",
          readOnly: false,
        },
        txtQty: { ...x.txtQty, readOnly: true, value: "" },
        txtNetPrice: { ...x.txtNetPrice, readOnly: true, value: "" },
        txtDiscountMax: {
          ...x.txtDiscountMax,
          readOnly: false,
          value: "",
        },
        selDetailServices: {
          ...x.selDetailServices,
          value: "",
        },
      }));
    }
    if (_elName === "btnAddInvoiceContentQuote") {
      //console.log(this.state.invoiceContentTableDataCopy);
      // if (
      //   this.state.invoiceContentTableDataCopy.find(
      //     (x) =>
      //       Number(x.item.id) === Number(this.state.selContentItems.value.id)
      //   )
      // ) {
      //   this.props.dispatch({
      //     type: "MODAL_SHOW",
      //     payload: {
      //       title: "Duplicate Item",
      //       desc: `Selected item is already added`,
      //       isYesNo: false,
      //     },
      //   });
      // } else {

      // }
      const ITEM_PRICE = Number(this.state.selContentItems.value.price);
      if (typeof e.target.service === "undefined") {
        let productPrice = 0;

        let priceModifier = 0;

        if (this.state.settings?.itemPriceModification) {
          // const percentageModifier =
          //   this.state.settings?.itemPriceModification / 100;
          // priceModifier = percentageModifier;

          // productPrice = ITEM_PRICE + ITEM_PRICE * percentageModifier;

          // //console.log(productPrice)

          // productPrice = Number(productPrice.toFixed(2));
          // //console.log(productPrice)
          productPrice = misc.calculateMarkup(
            ITEM_PRICE,
            this.state.settings?.itemPriceModification
          );
        } else {
          productPrice = ITEM_PRICE;
        }

        this.setState((x) => ({
          txtQty: { ...x.txtQty, readOnly: false },
          txtDiscountMax: {
            ...x.txtDiscountMax,
            readOnly: false,
            value: "",
          },
          chkImg: {
            ...x.chkImg,
            readOnly: false,
          },
          txtNetPrice: {
            ...x.txtNetPrice,
            readOnly: true,
            value: productPrice,
          },
          selDetailContentStatus: {
            ...x.selDetailContentStatus,
            value:
              this.state.selContentItems.value.qty > 1
                ? this.state.selDetailContentStatus.data.find(
                    (x) => x.name === "In-Stock"
                  )
                : this.state.selDetailContentStatus.data.find(
                    (x) => x.name === "Out of stock"
                  ),
            readOnly: false,
          },
        }));
      } else {
        this.setState((x) => ({
          txtQty: { ...x.txtQty, readOnly: false, value: 1 },
          txtDiscountMax: {
            ...x.txtDiscountMax,
            readOnly: false,
            value: "",
          },
          txtNetPrice: {
            ...x.txtNetPrice,
            readOnly: false,
            value: "",
          },
          chkImg: { ...x.chkImg, value: true, readOnly: true },
          selDetailContentStatus: {
            ...x.selDetailContentStatus,
            value: this.state.selDetailContentStatus.data.find(
              (x) => x.name === "Service"
            ),
            readOnly: true,
          },
        }));
      }

      this.setState((x) => ({
        // selContentItems: {
        //   ...x.selContentItems,
        //   readOnly: false,
        // },
        txtItemIndex: {
          ...x.txtItemIndex,
          value: this.state.invoiceContentTableData.length + 1,
        },
        isInvoiceContentEdit: true,
        selectedRowInvoiceSelection: null,
        invoiceContentTableSelection: [],
        selContentItems: {
          ...x.selContentItems,
          readOnly: true,
        },
        selDetailServices: {
          ...x.selDetailServices,
          readOnly: false,
        },
      }));
    }
    if (_elName === "handleInvoiceContentDuplicate") {
      const itemId = this.state.selContentItems.value.id;
      const origQty = this.state.selContentItems.value.qty;

      // const item = this.state.invoiceContentTableData.map((x) => {
      //   if (x.item.id === itemId) return x;
      // });
      //console.log(this.state.invoiceContentTableData);

      const ITEM_FILTER = (item) => {
        //console.log(item);
        if (item.placeholder === null) {
          return true;
        }
        if (item.itemId !== null) {
          return true;
        } else {
          return false;
        }
      };
      let item = [];

      item = this.state.invoiceContentTableData.filter(ITEM_FILTER);

      //console.log(item);

      const ITEM_ID_FILTER = (item) => {
        if (item.itemId !== null) {
          if (item.item.id === itemId) return true;
        } else {
          return false;
        }
      };

      if (item.length > 0) {
        item = item.filter(ITEM_ID_FILTER);
      }

      //const item = this.state.invoiceContentTableData.filter(ITEM_FILTER);

      let qty = 0;

      for (let i = 0; i < item.length; i++) {
        const itemContent = item[i];
        qty = qty + itemContent.itemQty;
      }
      // console.log(qty, origQty);

      this.setState((x) => ({
        //txtQty: { ...x.txtQty,  value: qty },
        txtQtyView: { ...x.txtQtyView, value: Number(origQty - qty) },
      }));

      //console.log("DUPE ===>", item);

      //const item = this.state.invoiceContentTableData.find(x=>{x.item.id === itemId})
    }
    if (_elName === "handleInventoryChange") {
      // console.log("trigger", e.target.value);
      const invId =
        Number(e.target.value.id) === 1 ? 0 : Number(e.target.value.id);
      const inventoryItems = await inventoryApi.getItemsByInventory(
        this.props.dispatch,
        invId,
        null,
        null,
        null,
        null,
        null,
        "ACTIVE"
      );

      const settings = await settingsApi.getSettingsByInventory(
        this.props.dispatch,
        Number(e.target.value.id)
      );

      //Update Invoice Headers
      setInvoiceHeaderData(
        settings?.invoiceHeaderAddress1,
        settings?.invoiceHeaderAddress2,
        settings?.invoiceHeaderAddress3,
        settings?.invoiceHeaderLandline,
        settings?.invoiceHeaderMobile,
        settings?.invoiceHeaderContact,
        settings?.invoiceHeaderEmail,
        settings?.invoiceDeliveryReceiveAddress
      );
      // console.log(settings);
      this.setState(
        (a) => ({
          selInventory: { ...a.selInventory, value: e.target.value },
          selContentItems: {
            ...a.selContentItems,
            value: "",
            data: inventoryItems,
          },
          txtQty: { value: "", readOnly: false },
          txtNetPrice: { value: "", readOnly: false },
          settings,
        }),
        async () => {
          if (this.state?.selDetailType?.value?.name === "Purchase Request") {
            this.loadPRItems();
          }
          const invoiceItems = await this.refreshInvoice(this.props.user.id);
          this.setState((x) => ({
            invoiceTableData: invoiceItems,
            invoiceTableDataCopy: invoiceItems,
          }));
        }
      );
    }
    if (_elName === "handleInventoryChangeTransferItems") {
      // console.log("trigger", e.target.value);
      const invId =
        Number(e.target.value.id) === 1 ? 0 : Number(e.target.value.id);
      const inventoryItems = await inventoryApi.getItemsByInventory(
        this.props.dispatch,
        invId
      );
      this.setState(
        (a) => ({
          selInventoryInvChangeFrom: {
            ...a.selInventoryInvChangeFrom,
            value: e.target.value,
          },
          selInventoryInvChangeFromContent: {
            ...a.selInventoryInvChangeFromContent,
            value: "",
            data: inventoryItems,
          },
        }),
        async () => {}
      );
    }

    if (_elName === "btnEditInvoiceContent") {
      switch (this.state.selDetailType.value.name) {
        case "Quote":
          if (data.itemId === null) {
            let _tempData = this.state.selDetailServices.data;
            _tempData.push(JSON.parse(data.serviceName));
            this.setState((x) => ({
              selContentItems: { ...x.selContentItems, value: "" },
              txtNetPrice: {
                ...x.txtNetPrice,
                value: data.price,
                readOnly: false,
              },
              chkImg: {
                ...x.chkImg,
                value: data.showImg === 1 ? true : false,
                readOnly: true,
              },
              selDetailContentStatus: {
                ...x.selDetailContentStatus,
                value: data.orderStatus,
                readOnly: true,
              },
              selDetailServices: {
                ...x.selDetailServices,
                data: _tempData,
                value: JSON.parse(data.serviceName),
              },
            }));
          } else {
            this.setState((x) => ({
              selDetailServices: { ...x.selDetailServices, value: "" },
              chkImg: {
                ...x.chkImg,
                value: data.showImg === 1 ? true : false,
                readOnly: false,
              },
              txtNetPrice: {
                ...x.txtNetPrice,
                value: data.price,
                readOnly: true,
              },
              selDetailContentStatus: {
                ...x.selDetailContentStatus,
                value: data.orderStatus,
              },
              selContentItems: {
                ...x.selContentItems,
                value: this.state.selContentItems.data.find(
                  (x) => x.id === data.item.id
                ),
              },
            }));
          }
          this.setState(
            (x) => ({
              txtDiscountMax: { ...x.txtDiscountMax, value: data.discount },
              txtItemIndex: { ...x.txtItemIndex, value: data.itemIndex },
              txtPlaceholder: { ...x.txtPlaceholder, value: data.placeholder },
              txtQty: {
                ...x.txtQty,
                value: data.itemQty,
              },
            }),
            (x) => {
              //console.log(this.state);
            }
          );
          break;
        case "Purchase Request":
          this.setState((x) => ({
            selContentItems: {
              ...x.selContentItems,
              value: this.state.selContentItems.data.find(
                (x) => x.id === data.item.id
              ),
            },
            txtNetPrice: {
              ...x.txtNetPrice,
              value: data.price,
              readOnly: false,
            },
            txtQty: {
              ...x.txtQty,
              value: data.itemQty,
            },
          }));
          break;
        default:
          break;
      }
    }
    if (_elName === "btnUnlockInvoiceContent") {
      this.setState((x) => ({
        isInvoiceContentEdit: true,
        txtQty: {
          ...x.txtQty,
          readOnly: false,
        },
        selContentItems: { ...x.selContentItems, readOnly: false },
        selDetailServices: { ...x.selDetailServices, readOnly: false },
      }));
    }
    if (_elName === "btnAddGroupToQuote") {
      this.setState((x) => ({
        txtItemIndex: {
          ...x.txtItemIndex,
          value: this.state.invoiceContentTableData.length + 1,
        },
      }));
    }
    if (_elName === "btnUnlockInvoiceContentChangeItem") {
      this.setState(
        (x) => ({
          isInvoiceContentEdit: true,
          selectedRowInvoiceSelection:
            this.state.selectedRowInvoiceSelection.child,
          txtQty: {
            ...x.txtQty,
            readOnly: false,
          },
          selContentItems: { ...x.selContentItems, readOnly: false },
          selDetailServices: { ...x.selDetailServices, readOnly: false },
        }),
        () => {
          this.handleActionChange(
            { target: { name: "btnEditInvoiceContent" } },
            this.state.selectedRowInvoiceSelection
          );
        }
      );
    }
    if (_elName === "btnDeleteInvoiceContent") {
      const _sel = this.state.selectedRowInvoiceSelection.id;
      const _price = this.state.selectedRowInvoiceSelection.price;

      let _tableContent = this.state.invoiceContentTableData.filter(
        (x) => x.id !== _sel
      );
      let _tableContentBak = this.state.invoiceContentTableDataCopy.filter(
        (x) => x.id !== _sel
      );
      // console.log(this.state.selectedRowInvoiceSelection);
      const { price, itemQty } = this.state.selectedRowInvoiceSelection;
      this.setState(
        (x) => ({
          invoiceContentTableData: _tableContent,
          invoiceContentTableDataCopy: _tableContentBak,
          selectedRowInvoiceSelection: null,
          grandTotal: Number(x.grandTotal - price * itemQty).toFixed(2),
        }),
        () => {
          if (cb) cb();
        }
      );
    }
    if (_elName === "btnViewInvoicePDF") {
      let fileName = "";
      if (this.state.selectedRowInvoice.invoiceType.name === "Quote") {
        fileName = `${this.state.selectedRowInvoice.client.company} QUOTE`;
      }
      if (this.state.selectedRowInvoice.invoiceType.name === "POS") {
        fileName = `${this.state.selectedRowInvoice.client.company} POS`;
      }
      if (
        this.state.selectedRowInvoice.invoiceType.name === "Delivery Receipt"
      ) {
        fileName = `${this.state.selectedRowInvoice.client.company} DR`;
      }
      if (
        this.state.selectedRowInvoice.invoiceType.name === "Transfer Inventory"
      ) {
        fileName = `${this.state.selectedRowInvoice.inventoryFrom.name} TO ${this.state.selectedRowInvoice.inventoryTo.name} Transfer`;
      }
      if (
        this.state.selectedRowInvoice.invoiceType.name === "Purchase Order" ||
        this.state.selectedRowInvoice.invoiceType.name === "Purchase Request"
      ) {
        fileName = `${this.state.selectedRowInvoice?.supplier?.company} REQUEST`;
      }
      //console.log(this.state.selectedRowInvoice);
      const INVOICE_NAME = this.state.selectedRowInvoice.invoiceType.name;
      const SELECTED_INVOICE = this.state.selectedRowInvoice;

      const _services = this.state.invoiceContentTableData.filter(
        (x) => x.itemId === null && x.serviceName !== null
      );

      //console.log(_services);
      let _items = [];
      if (INVOICE_NAME === "Delivery Receipt") {
        if (this.state.handleInvoiceModal.isDR) {
          _items = this.state.selectedRowlistDR.content;
        } else {
          _items = this.state.selectedRowInvoice.content;
        }
      } else if (
        INVOICE_NAME === "Purchase Order" ||
        INVOICE_NAME === "Purchase Request"
      ) {
        _items = this.state.PRTableData.filter((x) => x.itemId !== null);
      } else if (INVOICE_NAME === "Transfer Inventory") {
        _items = this.state.TransferInvTableData;
      } else {
        //console.log(this.state.invoiceContentTableData)

        const CHANGE_ITEM_FILTER = (item) => {
          if (item.placeholder !== null || item.placeholder !== "") {
            return true;
          }
          if (item.itemId !== null) {
            return true;
          } else {
            return false;
          }
        };
        _items = this.state.invoiceContentTableData.filter(CHANGE_ITEM_FILTER);
        //console.log(_items);
        // _items = this.state.invoiceContentTableData.filter(
        //   (x) => x.itemId !== null
        // );
      }

      let perChunk = 8; // items per chunk
      if (
        INVOICE_NAME === "Delivery Receipt" ||
        INVOICE_NAME === "Purchase Order" ||
        INVOICE_NAME === "Purchase Request"
      ) {
        perChunk = 20;
      }
      let result = _items.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk);
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }
        resultArray[chunkIndex].push({ ...item, index: index + 1 });
        return resultArray;
      }, []);

      if (INVOICE_NAME === "POS") {
        let _AmountDue = SELECTED_INVOICE.content
          .reduce((a, c) => {
            return a + c.price * c.itemQty;
          }, 0)
          .toFixed(2);

        let _TotalQtyItems = SELECTED_INVOICE.content
          .reduce((a, c) => {
            return a + c.itemQty;
          }, 0)
          .toFixed(2);

        // const _VATSales = Number(_AmountDue / 1.12).toFixed(2);
        // const _VAT = Number(_AmountDue - _VATSales).toFixed(2);
        let settingTaxPercentage = 0;
        let _VATSales = 0;
        let _VAT = 0;
        if (this.state.settings?.valueAddedTax) {
          settingTaxPercentage =
            Number(this.state.settings?.valueAddedTax) / 100 + 1;
          _VATSales = Number(_AmountDue / settingTaxPercentage).toFixed(2);
          _VAT = Number(_AmountDue - _VATSales).toFixed(2);
        }

        this.props.dispatch({
          type: "PDF_MODAL_TOGGLE",
          doc: Receipt.ReceiptPDF(
            SELECTED_INVOICE.content,
            SELECTED_INVOICE.dateAdded,
            _AmountDue,
            SELECTED_INVOICE.cashTendered - _AmountDue,
            _TotalQtyItems,
            _VAT,
            _VATSales,
            SELECTED_INVOICE.client,
            SELECTED_INVOICE.incharge,
            SELECTED_INVOICE.cashTendered,
            SELECTED_INVOICE,
            true
          ),
        });
      }

      //console.log("PDF VIEW ===>", INVOICE_NAME);
      if (INVOICE_NAME === "Quote") {
        if (this.state.handleInvoiceModal.isDR) {
          this.setState({ pdfGroupTable: { result } }, async (x) => {
            //console.log(this.state);
            const Parent = await invoiceApi.getInvoice(
              this.state.dispatch,
              Number(this.state.selectedRowlistDR.parentId)
            );

            // const blob = pdf(
            //   DR.DRPDF(
            //     this.state,
            //     Parent,
            //     false,
            //     this.state.selectedRowInvoice.uniqueId,
            //     this.state.selectedRowInvoice.invoiceStatus.name,
            //     this.state.selectedRowInvoice.shippingAddress,
            //     this.state.selectedRowInvoice.incharge !== null
            //       ? this.state.selectedRowInvoice.incharge
            //       : null,
            //     this.state.selectedRowInvoice.approvedBy !== null
            //       ? this.state.selectedRowInvoice.approvedBy
            //       : null,
            //     false
            //   )
            // ).toBlob();

            // this.props.dispatch({
            //   type: "FILE_VIEWER_MODAL_TOGGLE",
            //   doc: blob,
            // });
            this.props.dispatch({
              type: "PDF_MODAL_TOGGLE",
              doc: DR.DRPDF(
                this.state,
                Parent,
                false,
                this.state.selectedRowInvoice.uniqueId,
                this.state.selectedRowInvoice.invoiceStatus.name,
                this.state.selectedRowInvoice.shippingAddress,
                this.state.selectedRowInvoice.incharge !== null
                  ? this.state.selectedRowInvoice.incharge
                  : null,
                this.state.selectedRowInvoice.approvedBy !== null
                  ? this.state.selectedRowInvoice.approvedBy
                  : null,
                false
              ),
            });
          });
        } else {
          result = result.sort(function (a, b) {
            return a.itemIndex - b.itemIndex;
          });

          this.setState({ pdfGroupTable: { result, _services } }, async (x) => {
            if (cb) {
              cb();
            } else {
              const _TotalMarkupDiscounted = _items
                .reduce((a, c) => {
                  return a + c.price * c.itemQty;
                }, 0)
                .toFixed(2);

              const _TotalMarkup = _items
                .reduce((a, c) => {
                  return (
                    a + (c.srp + c.srp * (c.priceModifier / 100)) * c.itemQty
                  );
                }, 0)
                .toFixed(2);

              //console.log(_TotalMarkupDiscounted, _TotalMarkup);

              //console.log(_TotalMarkupDiscounted, this.state.grandTotalSRP);
              // const GrandTotalMarkedUpDiscounted =
              //   Number(this.state.grandTotalSRP) -
              //   Number(_TotalMarkupDiscounted);
              //console.log(GrandTotalMarkedUpDiscounted);
              // if (a.priceModifier) {
              //   markupDiscounted = c.price * (c.discount / 100) - c.price;
              // }

              //TRIAL
              // this.props.dispatch({
              //   type: "PDF_MODAL_TOGGLE",
              //   doc: QuotePDF.QuotePDF(
              //     result,
              //     _services,
              //     this.state.termsTableData,
              //     this.state.selectedRowInvoice.uniqueId,
              //     this.state?.selectedRowInvoice?.approvedBy?.name,
              //     this.state?.selDetailClient?.value?.company,
              //     this.state?.selDetailClient?.value?.address,
              //     false,
              //     this.state?.selDetailIncharge?.value?.name,
              //     this.state.selectedRowInvoice.dateAdded,
              //     this.state.chkPDFWithDesc.value,
              //     this.state?.selDetailStatus?.value?.name,
              //     this.state.grandTotal,
              //     this.state.grandTotalSRP,
              //     _TotalMarkupDiscounted,
              //     _items,
              //     _TotalMarkup
              //   ),
              // });

              const pdfBlobLink = await generateQuotePDF(
                this.state.selectedRowInvoice.id,
                this.props.dispatch
              );

              this.props.dispatch({
                type: "FILE_VIEWER_MODAL_TOGGLE",
                doc: pdfBlobLink,
                fileName: this.state.selectedRowInvoice.name + ".pdf",
              });
            }
          });
        }
      }
      if (
        INVOICE_NAME === "Purchase Request" ||
        INVOICE_NAME === "Purchase Order"
      ) {
        this.setState({ pdfGroupTable: { result, _services } }, (x) => {
          if (cb) {
            cb();
          } else {
            this.props.dispatch({
              type: "PDF_MODAL_TOGGLE",
              doc: PR.PRPDF(
                this.state,
                null,
                this.state.selectedRowInvoice.approvedBy !== null
                  ? this.state.selectedRowInvoice.approvedBy
                  : null,
                false,
                this.state.selectedRowInvoice.incharge !== null
                  ? this.state.selectedRowInvoice.incharge
                  : null
              ),
            });
          }
        });
      }
      if (INVOICE_NAME === "Transfer Inventory") {
        this.setState({ pdfGroupTable: { result, _services } }, (x) => {
          if (cb) {
            cb();
          } else {
            this.props.dispatch({
              type: "PDF_MODAL_TOGGLE",
              doc: TransferItem.TransferItemPDF(
                this.state,
                false,
                this.state.selectedRowInvoice.uniqueId,
                this.state.selDetailStatus.value.name,

                this.state.selectedRowInvoice.incharge.name,
                this.state.selectedRowInvoice.approvedBy !== null
                  ? this.state.selectedRowInvoice.approvedBy
                  : null,
                this.state.selectedRowInvoice.incharge !== null
                  ? this.state.selectedRowInvoice.incharge
                  : null,
                false,
                this.state.selectedRowInvoice.inventoryFrom.name,
                this.state.selectedRowInvoice.inventoryTo.name
              ),
            });
          }
        });
      }
      if (INVOICE_NAME === "Delivery Receipt") {
        this.setState({ pdfGroupTable: { result } }, async () => {
          //console.log(this.state);
          const Parent = await invoiceApi.getInvoice(
            this.state.dispatch,
            Number(
              this.state.handleInvoiceModal.isDR
                ? this.state.selectedRowlistDR.parentId
                : this.state.selectedRowInvoice.parentId
            )
          );

          if (cb) {
            this.setState({ drParent: Parent }, () => {
              cb();
            });
          } else {
            this.props.dispatch({
              type: "PDF_MODAL_TOGGLE",
              doc: DR.DRPDF(
                this.state,
                Parent,
                false,
                this.state.selectedRowInvoice.uniqueId,
                this.state.selectedRowInvoice.invoiceStatus.name,
                this.state.selectedRowInvoice.shippingAddress,
                this.state.selectedRowInvoice.incharge !== null
                  ? this.state.selectedRowInvoice.incharge
                  : null,
                this.state.selectedRowInvoice.approvedBy !== null
                  ? this.state.selectedRowInvoice.approvedBy
                  : null,
                false
              ),
            });
          }
        });
      }
    }
    if (_elName === "chkImg") {
      this.setState((x) => ({
        chkImg: {
          ...x.chkImg,
          value: e.target.value.checked ? true : false,
        },
      }));
    }
    if (_elName === "txtDiscountMax") {
      let srp = 0;
      const ITEM_PRICE = this.state.selContentItems.value.price;
      if (this.state.selContentItems.value !== "") {
        if (this.state.settings?.itemPriceModification) {
          // let productPrice = 0;

          // let priceModifier = 0;
          // const percentageModifier =
          //   this.state.settings?.itemPriceModification / 100;
          // priceModifier = percentageModifier;

          // productPrice = ITEM_PRICE + ITEM_PRICE * percentageModifier;

          // console.log(ITEM_PRICE);

          // productPrice = Number(productPrice.toFixed(2));
          // srp = productPrice;
          //console.log(productPrice)
          srp = misc.calculateMarkup(
            ITEM_PRICE,
            this.state.settings?.itemPriceModification
          );
        } else {
          srp = ITEM_PRICE;
        }

        // srp = this.state.selContentItems.value.price;
      } else {
        if (e.target.value.value === "") {
          srp = 0;
        } else
          srp =
            this.state.txtNetPrice.value === ""
              ? (srp = 0)
              : this.state.txtNetPrice.value;
      }
      let _cost = 0;
      let _discount = 0;

      if (e.target.value !== "") {
        _discount = srp * (e.target.value * 0.01);
        _cost = srp - _discount;
      } else {
        _cost = srp;
      }
      this.setState((x) => ({
        txtDiscountMax: { ...x.txtDiscountMax, value: e.target.value },
        txtNetPrice: {
          ...x.txtNetPrice,
          value: Number(_cost).toFixed(2),
          discounted: Number(_discount).toFixed(2),
        },
      }));
    }
    if (_elName === "btnApplyPRDiscount") {
      let _cost = 0;
      let _discount = 0;
      const _inputPrice = Number(this.state.txtNetPricePR.value);
      const _inputDiscount = Number(this.state.txtDiscountMaxPR.value);

      // console.log(_inputPrice, _inputDiscount);

      if (_inputDiscount !== "") {
        _discount = _inputPrice * _inputDiscount * 0.01;
        _cost = _inputPrice - _discount;
      } else {
        _cost = _inputPrice;
      }
      this.setState((x) => ({
        txtNetPricePR: {
          ...x.txtNetPricePR,
          value: Number(_cost).toFixed(2),
          discounted: Number(_discount).toFixed(2),
        },
      }));
    }
    if (_elName === "handleTermsChange") {
      this.setState((x) => ({
        selDetailTerms: { ...x.selDetailTerms, value: e.target.value },
      }));
    }
    if (_elName === "btnAddTerm") {
      let _dat = this.state.termsTableData;
      const _srch = _dat.find(
        (x) => x.id === this.state.selDetailTerms.value.id
      );
      if (_srch) {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Duplicate Selection",
            desc: `Selected term is already added`,
            isYesNo: false,
          },
        });
      } else {
        _dat.push(this.state.selDetailTerms.value);
        this.setState((x) => ({
          termsTableData: [..._dat],
        }));
      }
    }
    if (_elName === "btnRemoveTerm") {
      let _removeTermData = this.state.termsTableData;
      _removeTermData = _removeTermData.filter(
        (x) => x.id !== this.state.selectedRowTerms.id
      );
      this.setState((x) => ({
        termsTableData: [..._removeTermData],
        termsTableSelection: [],
      selectedRowTerms: null,

      }));
    }
    if (_elName === "btnAddDefaultTerms") {
      let _termsTableData = this.state.termsTableData;
      const _TermsData = this.state.selDetailTerms.data;
      let _defaults = [];
      let _isValid = true;
      _TermsData.forEach((_e) => {
        if (_e.isDefault === 1) {
          const _srch = _termsTableData.find((x) => x.id === _e.id);
          if (_srch) _isValid = false;
          else _defaults.push(_e);
        }
      });
      // if (_isValid) {
      //   const _combined = _termsTableData.concat(_defaults);
      //   this.setState((x) => ({
      //     termsTableData: [..._combined],
      //   }));
      // } else {
      //   this.props.dispatch({
      //     type: "MODAL_SHOW",
      //     payload: {
      //       title: "Duplicate Selection",
      //       desc: `A default term already exists in your selection`,
      //       isYesNo: false,
      //     },
      //   });
      // }
      const _combined = _termsTableData.concat(_defaults);
        this.setState((x) => ({
          termsTableData: [..._combined],
        }));
    }
    if (_elName === "btnSubmitForConfirmation") {
      //console.log(this.state.selectedRowInvoice);
      // if (this.state.selDetailStatus.value.name === "New") {
      //   const items = this.state.selectedRowInvoice.content.filter(
      //     // (x) => x.itemId !== null && x.orderStatus.name !== "Reserved"
      //     (x) => x.itemId !== null
      //   );
      //   let itemProcess = [];
      //   for (let i = 0; i < items.length; i++) {
      //     const _item = items[i];
      //     itemProcess.push({
      //       data: { id: Number(_item.itemId), qty: Number(_item.itemQty) },
      //     });
      //   }
      //   await inventoryApi.subtractItemQtyMany(
      //     this.state.dispatch,
      //     itemProcess,
      //     () => {}
      //   );
      //   console.log(itemProcess);
      // }

      this.updateInvoiceStatus("Pending Admin Approval");
      actorApi.sendEmail(
        this.state.dispatch,
        this.state.selectedRowInvoice?.name,
        this.state.user?.incharge?.lastName,
        Number(this.state.selInventory.value.id)
      );
      //console.log(items);
    }
    if (_elName === "btnAddDeliveryReceipt") {
      let filtered = this.state.selectedRowInvoice.content.filter(
        (x) => x.itemId !== null && x.childId === null
      );

      const childrens = await invoiceApi.getOrderByParentId(
        this.props.dispatch,
        Number(this.state.selectedRowInvoice.id)
      );

      //console.log("BEFORE DR ===>", filtered, childrens);

      for (let i = 0; i < childrens.length; i++) {
        const content = childrens[i].content;

        for (let a = 0; a < content.length; a++) {
          const item = content[a];

          filtered = filtered.map((x) =>
            x.itemId === item.itemId
              ? { ...x, qtyDelivered: x.qtyDelivered + item.qtyDelivered }
              : x
          );
        }
      }
      const DRFilter = filtered.filter((x) => x.itemQty > x.qtyDelivered);
      const DateToday = await actorApi.getCurrentDateTime(this.state.dispatch);
      const NEW_DR_STATUS = this.state.selDetailStatus.data.find(
        (x) => x.name === "Pending Admin Approval"
      );
      //console.log(this.props);
      if (DRFilter.length === 0) {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Notice",
            desc: `All items are delivered or pending for admin confirmation`,
            isYesNo: false,
          },
        });
      } else {
        this.setState(
          (x) => ({
            dRItemsTableData: filtered,
            dRItemsTableDataCopy: filtered,
            handleInvoiceModal: { ...x.handleInvoiceModal, isDR: true },
            selectedOperation: SELECTED_OPERATION.ADD,
            selectedModalOperation: SELECTED_OPERATION.ADD,
            handleDRModal: { ...x.handleDRModal, isView: false },
          }),
          () => {
            this.clearInputsDR(false, true);
            this.setState((x) => ({
              txtShippingAddressDR: {
                ...x.selDetailStatusDR,
                value: this.state.selDetailClient.value.address,
              },
              txtInvoiceNameDR: {
                ...x.selDetailStatusDR,
                value: `${this.state.selectedRowInvoice.name} DR #-${
                  this.state.selectedRowInvoice.children.length + 1
                }`,
              },
              txtDateDeadlineDR: {
                ...x.txtDateDeadlineDR,
                value: misc.parseAPIDateTime(moment(DateToday).add(14, "days")),
              },
              selDetailStatusDR: {
                ...x.selDetailStatusDR,
                value: NEW_DR_STATUS,
                ...(this.props.userType.includes("Sales Person") && {
                  readOnly: true,
                }),
              },
              isPOSDR:
                this.state.selectedRowInvoice.invoiceType.name === "POS"
                  ? true
                  : false,
            }));
            this.handleDRToggleModal();
            // if (this.state.handleInvoiceModal.isShow) {
            //   this.handleToggleInvoiceModal();
            // }
          }
        );
      }
      //console.log(misc.parseAPIDate(moment(DateToday).add(14, "days")));
    }
    if (_elName === "btnEditDR") {
      let allow = true;

      if (
        this.props.userType.includes("Sales Person") ||
        this.state.permissions.includes("perm-ui-invoicingDRAccess-view-write")
      ) {
        if (
          this.state.selDetailStatusDR.value.name !== "New" &&
          this.state.selDetailStatusDR.value.name !== "Admin Denied"
        ) {
          //console.log(this.state);
          allow = false;
        }
      }
      //override with permission
      if (
        this.state.permissions.includes(
          "perm-ui-invoicingDRAccess-view-write-manage"
        )
      )
        allow = true;

      if (allow) {
        this.clearInputsDR(false, false);
        this.setState((x) => ({
          selectedOperation: SELECTED_OPERATION.EDIT,
          selectedModalOperation: SELECTED_OPERATION.EDIT,
          handleDRModal: { ...x.handleDRModal, isView: false },
          selDetailStatusDR: {
            ...x.selDetailStatusDR,

            ...((this.props.userType.includes("Sales Person") ||
              !this.state.permissions.includes(
                "perm-ui-invoicingDRAccess-view-write-manage-admin"
              )) && {
              readOnly: true,
            }),
          },
        }));
      } else {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "No Permission",
            desc: `You are not allowed to modify this invoice.`,
            isYesNo: false,
          },
        });
      }
    }
    if (_elName === "SelectDRItemSelect") {
      //console.log(data);
      let unit = "";
      if (data.item) {
        const sDat = data.item.details.find((x) => x.type === 2);
        sDat !== undefined ? (unit = sDat.name) : (unit = "");
      }
      //console.log(this.state.selectedRowDRItems);
      this.setState(
        (x) => ({
          dRItemsTableLoading: true,
          txtQtyToDeliverDR: {
            ...x.txtQtyToDeliverDR,
            placeholder: `Undelivered ${
              data.itemQty - data.qtyDelivered
            } ${unit}`,
          },
        }),
        () => {
          //this.handleDRToggleModal();
        }
      );
    }
    if (_elName === "btnReplaceInvoiceContent") {
      this.setState(
        (x) => ({
          isInvoiceContentEdit: true,
          isInvoiceContentItemChange: true,
          selDetailContentStatus: {
            ...x.selDetailContentStatus,
            value: this.state.selDetailContentStatus.data.find(
              (x) => x.name === "Change Item"
            ),
            readOnly: true,
          },
          selContentItems: {
            ...x.selContentItems,
            value: "",
          },
          selInventory: { ...x.selInventory, readOnly: true },
          txtDiscountMax: {
            ...x.txtDiscountMax,
            readOnly: true,
            value: "",
          },
          txtNetPrice: { ...x.txtNetPrice, readOnly: true, value: "" },
          txtQty: { ...x.txtQty, readOnly: true, value: "" },
          selDetailServices: {
            ...x.selDetailServices,
            value: "",
          },
        }),
        () => {}
      );
    }
    if (_elName === "btnAddInvoiceContentReplaceItem") {
      this.setState(
        (x) => ({
          txtQty: { ...x.txtQty, readOnly: false, value: "" },
          txtDiscountMax: {
            ...x.txtDiscountMax,
            readOnly: false,
            value: "",
          },
          txtNetPrice: {
            ...x.txtNetPrice,
            readOnly: true,
            value: this.state.selContentItems.value.price,
          },
        }),
        () => {}
      );
    }
    if (_elName === "btnSaveInvoiceContentReplacementItem") {
      const data = {
        //id: Number(this.state.selectedRowInvoiceSelection.id),
        //notes: notes,
        lastModifiedByActorId: Number(this.state.user.id),
        //dateLastModified: DateToday,

        itemQty: Number(this.state.txtQty.value),
        price: Number(this.state.txtNetPrice.value),
        itemId: Number(this.state.selContentItems.value.id),
        itemUnitId: Number(
          this.state.selContentItems.value.details.find((x) => x.type === 2).id
        ),
        orderStatusId: Number(this.state.selDetailContentStatus.value.id),
        orderTypeId: Number(
          this.state.selDetailContentType.data.find((x) => x.name === "Item").id
        ),

        discount: Number(this.state.txtDiscountMax.value),
        showImg: this.state.chkImg.value === true ? 1 : 0,
        srp: Number(this.state.selContentItems.value.price),
      };

      const ChildId = await invoiceApi.createInvoiceContent(
        this.props.dispatch,
        data,
        this.state.selectedRowInvoice.id
      );
      //console.log(ChildId);
      let UpdatedContentData = [];
      let UpdatedContentDataCopy = [];

      UpdatedContentData = this.state.invoiceContentTableData.map((x) =>
        x.uniqueId === this.state.selectedRowInvoiceSelection.uniqueId
          ? {
              ...x,
              orderStatusId: Number(
                this.state.selDetailContentStatus.data.find(
                  (x) => x.name === "Old Item"
                ).id
              ),
              childId: Number(ChildId.data.id),
            }
          : x
      );

      UpdatedContentDataCopy = this.state.invoiceContentTableDataCopy.map((x) =>
        x.uniqueId === this.state.selectedRowInvoiceSelection.uniqueId
          ? {
              ...x,
              orderStatusId: Number(
                this.state.selDetailContentStatus.data.find(
                  (x) => x.name === "Old Item"
                ).id
              ),
              childId: Number(ChildId.data.id),
            }
          : x
      );

      UpdatedContentData.push(ChildId.data);
      UpdatedContentDataCopy.push(ChildId.data);

      this.setState(
        (a) => ({
          invoiceContentTableData: UpdatedContentData,
          invoiceContentTableDataCopy: UpdatedContentDataCopy,
          selDetailStatus: {
            ...a.selDetailStatus,
            value: this.state.selDetailStatus.data.find(
              (x) => x.name === "Pending Admin Approval"
            ),
          },
        }),
        () => {
          this.updateInvoice();
        }
      );
    }
    if (_elName === "btnDeleteDR") {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Confirmation",
          desc: `Are you sure you want to remove this Delivery Receipt?`,
          isYesNo: true,
          cb: async () => {
            // console.log(
            //   this.state,
            //   this.state.selectedRowInvoice.id,
            //   this.state.selectedDRID
            // );
            await invoiceApi.deleteInvoice(
              this.props.dispatch,
              Number(this.state.selectedDRID)
            );

            this.clearInputsDR(true, true);
            const Invoices = await this.refreshInvoice(this.props.user.id);
            //const parentID = Number(this.state.selectedRowInvoice.id)

            const UpdatedInvoice = await invoiceApi.getInvoice(
              this.props.dispatch,
              Number(this.state.selectedDRParentID)
            );

            this.setState({
              invoiceTableData: Invoices,
              invoiceTableDataCopy: Invoices,
              listDRTableData: UpdatedInvoice.children,
              listDRTableDataCopy: UpdatedInvoice.children,
            });

            this.handleDRToggleModal();
          },
        },
      });
    }
    if (_elName === "btnAddItemToDelivery") {
      const _tempData = this.state.dRItemsTableData;
      const qtyDelivered =
        this.state.selectedRowDRItems.qtyDelivered === null
          ? 0
          : this.state.selectedRowDRItems.qtyDelivered;

      const newData = {
        ...this.state.selectedRowDRItems,
        qtyDelivered: Number(
          qtyDelivered + Number(this.state.txtQtyToDeliverDR.value)
        ),
      };

      let selectionData = this.state.dRItemSelectTableData;

      const addData = {
        ...this.state.selectedRowDRItems,
        _id: filters.uuidv4(),
        qtyDelivered: Number(this.state.txtQtyToDeliverDR.value),
      };

      if (
        selectionData.find(
          (x) =>
            x.itemId === this.state.selectedRowDRItems.itemId &&
            this.state.selectedRowDRItems.itemId.id === x.id
        )
      ) {
        selectionData = selectionData.map((x) =>
          x.itemId === this.state.selectedRowDRItems.itemId &&
          this.state.selectedRowDRItems.itemId.id === x.id
            ? {
                ...x,
                qtyDelivered:
                  x.qtyDelivered + Number(this.state.txtQtyToDeliverDR.value),
              }
            : x
        );
      } else {
        selectionData.push(addData);
      }

      //console.log(this.state.selectedRowDRItemSelect);
      let _updatedInvoiceContentTableData = _tempData.map((x) =>
        x.id === this.state.selectedRowDRItems.id ? { ...newData } : x
      );
      // _updatedInvoiceContentTableData = _updatedInvoiceContentTableData.filter(
      //   (x) => x.qtyDelivered < x.itemQty
      // );

      this.setState(
        (x) => ({
          dRItemsTableData: _updatedInvoiceContentTableData,
          dRItemsTableDataCopy: _updatedInvoiceContentTableData,
          dRItemSelectTableData: selectionData,
          dRItemSelectTableDataCopy: selectionData,
        }),
        () => {
          this.handleActionChange({
            target: { name: "btnAddItemToDeliveryCancel" },
          });
        }
      );
    }
    if (_elName === "btnAddItemToDeliveryCancel") {
      this.setState((x) => ({
        dRItemsTableSelection: [],
        selectedRowDRItems: null,
        dRItemsTableLoading: false,
        txtQtyToDeliverDR: {
          ...x.txtQtyToDeliverDR,
          value: "",
        },
      }));
    }
    if (_elName === "btnSubmitDR") {
      const services = this.state.invoiceContentTableData.filter(
        (x) => x.itemId === null
      );

      let updatedInvoiceContents = this.state.dRItemsTableData;
      let updatedInvoiceContentsBak = this.state.invoiceContentTableDataCopy;
      updatedInvoiceContents = updatedInvoiceContents.concat(services);
      updatedInvoiceContentsBak = updatedInvoiceContents.concat(services);
      this.setState(
        (x) => ({
          invoiceContentTableData: updatedInvoiceContents,
          invoiceContentTableDataCopy: updatedInvoiceContentsBak,
        }),
        () => {
          //console.log(this.state.selectedModalOperation.id);
          if (this.state.selectedModalOperation.id === 0) {
            this.createDR();
          }
          if (this.state.selectedModalOperation.id === 1) {
            this.updateDR();
          }
          this.handleDRToggleModal();
        }
      );
    }
    if (_elName === "btnSetDRDelivered") {
      const _srch = this.state.selDetailStatus.data.find(
        (x) => x.name === "Finished"
      );

      if (_srch) {
        const data = {
          data: {
            id: Number(this.state.selectedDRID),
            notes: this.state.txtNotes.value,
            invoiceStatusId: Number(_srch.id),
            //dateLastModified: DateToday,
            //dateFinished: DateToday,
            lastModifiedByActorId: Number(this.state.user.id),
          },
        };
        await invoiceApi.updateInvoice(this.props.dispatch, data);

        const Invoices = await this.refreshInvoice(this.props.user.id);

        // this.handleToggleInvoiceModal();
        const UpdatedInvoice = await invoiceApi.getInvoice(
          this.props.dispatch,
          Number(this.state.selectedRowInvoice.id)
        );
        this.setState(
          {
            invoiceTableData: Invoices,
            invoiceTableDataCopy: Invoices,
            listDRTableData: UpdatedInvoice.children,
            listDRTableDataCopy: UpdatedInvoice.children,
          },
          () => {
            this.handleDRToggleModal();
          }
        );
      } else {
        //console.log("Error");
      }
    }
    if (_elName === "btnSetQuoteOngoingDelivery") {
      const _srch = this.state.selDetailStatus.data.find(
        (x) => x.name === "Ongoing Delivery"
      );

      if (_srch) {
        const data = {
          data: {
            id: Number(this.state.selectedRowInvoice.id),
            invoiceStatusId: Number(_srch.id),
          },
        };
        await invoiceApi.updateInvoice(this.props.dispatch, data);

        const Invoices = await this.refreshInvoice(this.props.user.id);

        // this.handleToggleInvoiceModal();
        // const UpdatedInvoice = await invoiceApi.getInvoice(
        //   this.props.dispatch,
        //   Number(this.state.selectedRowInvoice.id)
        // );
        this.setState(
          {
            invoiceTableData: Invoices,
            invoiceTableDataCopy: Invoices,
          },
          () => {
            this.handleDRToggleModal();
          }
        );
      } else {
        //console.log("Error");
      }
    }
    if (_elName === "btnSetDROngoingDelivery") {
      const _srch = this.state.selDetailStatus.data.find(
        (x) => x.name === "Ongoing Delivery"
      );

      if (_srch) {
        const data = {
          data: {
            id: Number(this.state.selectedDRID),
            notes: this.state.txtNotes.value,
            invoiceStatusId: Number(_srch.id),
          },
        };
        await invoiceApi.updateInvoice(this.props.dispatch, data);

        const Invoices = await this.refreshInvoice(this.props.user.id);

        // this.handleToggleInvoiceModal();
        const UpdatedInvoice = await invoiceApi.getInvoice(
          this.props.dispatch,
          Number(this.state.selectedRowInvoice.id)
        );
        this.setState(
          {
            invoiceTableData: Invoices,
            invoiceTableDataCopy: Invoices,
            listDRTableData: UpdatedInvoice.children,
            listDRTableDataCopy: UpdatedInvoice.children,
          },
          () => {
            this.handleDRToggleModal();
          }
        );
      } else {
        //console.log("Error");
      }
    }
    if (_elName === "btnSetInvoiceFinish") {
      await this.updateInvoiceStatus("Finished");
    }
    if (_elName === "btnSetInvoiceForDelivery") {
      await this.updateInvoiceStatus("For Delivery");
    }
    if (_elName === "btnAddItemToPR") {
      invoiceApi.lockOrderContentEdit(
        this.props.dispatch,
        this.state.selectedRowDRItemSelect.id,
        this.state.user.id
      );
      this.setState({
        PRItemsTableloading: true,
        PRItemsTableShowPrices: true,
        txtNetPricePR: { value: "", readOnly: false },
        txtDiscountMaxPR: { value: "", readOnly: false },
        txtQtyPR: { value: "", readOnly: false },
        txtCogsPR: { value: "", readOnly: false },
        txtCostPR: { value: "", readOnly: false },
        txtShippingPR: { value: "", readOnly: false },
        txtHandlingPR: { value: "", readOnly: false },
      });
      //console.log(this.state.PRTableData);

      let sameItem = false;

      if (
        this.state.PRTableData.find(
          (x) => x.oldData.uniqueId === this.state.selectedRowPRItems.uniqueId
        )
      ) {
        sameItem = true;
        //console.log("trigger");
        this.setState((a) => ({
          PRItemsTableShowPrices: false,
          txtNetPricePR: { ...a.txtNetPricePR, value: 1 },
        }));
      }

      const FIND_SAME_ITEM = this.state.PRTableData.find(
        (x) => x.itemId === this.state.selectedRowPRItems.itemId
      );
      //console.log(FIND_SAME_ITEM);
      if (!sameItem && FIND_SAME_ITEM) {
        this.state.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Confirmation",
            desc: `You are trying to add 2 same items. Do you want to combine and copy price?`,
            isYesNo: true,
            cb: () => {
              this.setState((a) => ({
                PRItemsTableShowPrices: false,
                txtNetPricePR: { value: FIND_SAME_ITEM.price, readOnly: false },
                txtCogsPR: { value: FIND_SAME_ITEM.cogs, readOnly: false },
                txtCostPR: { value: FIND_SAME_ITEM.cost, readOnly: false },
                txtShippingPR: {
                  value: FIND_SAME_ITEM.shipping,
                  readOnly: false,
                },
                txtHandlingPR: {
                  value: FIND_SAME_ITEM.handling,
                  readOnly: false,
                },
                txtDiscountMaxPR: {
                  value: FIND_SAME_ITEM.discount,
                  readOnly: false,
                },
              }));
            },
          },
        });
      }
    }
    if (_elName === "btnCancelItemPR") {
      //Incorrect naming convention. selectedRowDRItemSelect should be PR
      //console.log('TRIGGER',this.state.selectedRowDRItemSelect.id);
      invoiceApi.unlockOrderContentEdit(
        this.props.dispatch,
        this.state.selectedRowDRItemSelect.id
      );
      this.setState({
        PRItemsTableSelection: [],
        selectedRowPRItems: null,
        PRItemsTableloading: false,
        PRItemsTableShowPrices: false,
      });
    }
    if (_elName === "btnSubmitItemToPR") {
      let _tempData = this.state.PRTableData;
      let _tempDataItems = this.state.PRItemsTableData;
      let _newData = [];

      if (data) {
        if (
          _tempData.find(
            (x) => x.oldData.uniqueId === this.state.selectedRowPRItems.uniqueId
          )
        ) {
          _tempData = _tempData.map((x) =>
            x.oldData.uniqueId === this.state.selectedRowPRItems.uniqueId
              ? {
                  ...x,
                  qtyOrdered: x.qtyOrdered + Number(this.state.txtQtyPR.value),
                  oldData: this.state.selectedRowPRItems,
                }
              : x
          );
        } else {
          _newData.push({
            id: filters.uuidv4(),
            item: this.state.selectedRowPRItems.item,
            qtyOrdered: Number(this.state.txtQtyPR.value),
            itemQty: Number(this.state.selectedRowPRItems.itemQty),
            price: Number(this.state.txtNetPricePR.value),
            dispImg: { original: emptyImg, isDefault: true },
            showImg: 0,
            discount: Number(this.state.txtDiscountMaxPR.value),
            srp: this.state.selectedRowPRItems.item.price,
            itemId: this.state.selectedRowPRItems.itemId,
            itemUnitId: this.state.selectedRowPRItems.itemUnitId,
            itemUnit: this.state.selectedRowPRItems.itemUnit,
            orderStatusId: Number(
              this.state.selDetailContentStatus.data.find(
                (x) => x.name === "For order"
              ).id
            ),
            orderStatus: this.state.selectedRowPRItems.orderStatus,
            orderTypeId: this.state.selectedRowPRItems.orderTypeId,
            orderType: this.state.selectedRowPRItems.orderType,
            serviceName: null,
            oldData: this.state.selectedRowPRItems,
            cogs: Number(this.state.txtCogsPR.value),
            cost: Number(this.state.txtCostPR.value),
            shipping: Number(this.state.txtShippingPR.value),
            handling: Number(this.state.txtHandlingPR.value),
          });
        }
        _tempDataItems = _tempDataItems.map((x) => {
          if (x.id === this.state.selectedRowPRItems.id) {
            const qtyOrdered = x.qtyOrdered + Number(this.state.txtQtyPR.value);

            return {
              ...x,
              qtyOrdered: qtyOrdered,
            };
          } else {
            return x;
          }
        });
        _tempDataItems = _tempDataItems.filter(
          (x) => x.qtyOrdered !== x.itemQty
        );
      }

      //console.log(_grandTotal);

      this.setState(
        {
          PRTableData: _tempData.concat(_newData),
          PRDataCopy: _tempData.concat(_newData),
          PRItemsTableData: _tempDataItems,
          PRItemsDataCopy: _tempDataItems,
        },
        (x) => {
          const _grandTotal = this.state.PRTableData.reduce((a, c) => {
            return a + c.price * c.itemQty;
          }, 0).toFixed(2);
          this.setState({ grandTotal: _grandTotal });
          //console.log("test");
        }
      );
      if (cb) cb();
    }
    if (_elName === "btnRemoveItemFromPR") {
      let _tempData = this.state.PRTableData;
      let _tempDataItems = this.state.PRItemsTableData;

      if (
        _tempDataItems.find(
          (x) => x.uniqueId === this.state.selectedRowPR.oldData.uniqueId
        )
      ) {
        _tempDataItems = _tempDataItems.map((x) => {
          if (x.uniqueId === this.state.selectedRowPR.oldData.uniqueId) {
            const qtyOrdered =
              x.qtyOrdered - this.state.selectedRowPR.qtyOrdered;

            return {
              ...x,
              qtyOrdered: qtyOrdered,
            };
          } else {
            return x;
          }
        });
      } else {
        _tempDataItems.push({
          ...this.state.selectedRowPR.oldData,
          qtyOrdered:
            this.state.selectedRowPR.oldData.itemQty -
            this.state.selectedRowPR.qtyOrdered,
        });
      }

      _tempData = _tempData.filter(
        (x) => x.oldData.uniqueId !== this.state.selectedRowPR.oldData.uniqueId
      );

      this.setState(
        {
          PRTableData: _tempData,
          PRDataCopy: _tempData,
          PRItemsTableData: _tempDataItems,
          PRItemsDataCopy: _tempDataItems,
          PRItemsTableSelection: [],
          selectedRowPRItems: null,
          PRTableSelection: [],
          selectedRowPR: null,
        },
        (x) => {
          //console.log("test");
        }
      );
    }
    if (_elName === "btnDeclineItemRequestPR") {
      //console.log(this.state.selectedRowPRItems);
      this.setState((a) => ({
        txtNotesPRContent: {
          ...a.txtNotesPRContent,
          value: this.state.selectedRowPRItems.notes,
        },
        PRItemsTableloading: true,
      }));
    }
    if (_elName === "btnCancelItemRequestPR") {
      this.setState({
        PRItemsTableSelection: [],
        selectedRowPRItems: null,
        PRItemsTableloading: false,
      });
    }
    if (_elName === "btnDeclineItemRequestPRSave") {
      await this.updateInvoiceContentStatus(
        "Admin Denied",
        this.state.selectedRowPRItems.id,
        this.state.txtNotesPRContent.value
      );
      //const SelectedInvoice = await invoiceApi.getInvoice(this.props.dispatch);

      const Invoices = await this.refreshInvoice(this.props.user.id);
      this.loadPRItems();

      this.setState({
        PRItemsTableSelection: [],
        selectedRowPRItems: null,
        PRItemsTableloading: false,
        invoiceTableData: Invoices,
        invoiceTableDataCopy: Invoices,
      });
    }
    if (_elName === "btnUnlockPRItem") {
      invoiceApi.unlockOrderContentEdit(
        this.props.dispatch,
        this.state.selectedRowDRItemSelect.id
      );
    }
    if (_elName === "btnAddDeliveredToItemPO") {
      await invoiceApi.addOrderContentQtyDelivered(
        this.props.dispatch,
        this.state.selectedRowPR.id,
        poDelivered
      );
      this.refreshSelectedPRContent();
      this.updatePR();
      //this.handleToggleInvoiceModal();
    }
    if (_elName === "btnResetDeliveredItemPO") {
      await this.updateInvoiceContentStatus("Processing", data.id);
      await this.updateInvoiceContentStatus("Processing", data.histOrderId);
      await invoiceApi.resetOrderContentQtyDelivered(
        this.props.dispatch,
        this.state.selectedRowPR.id
      );
      this.refreshSelectedPRContent();
      this.updatePR();
      const updatedInvoice = await invoiceApi.getInvoice(
        this.props.dispatch,
        this.state.selectedRowInvoice.id
      );

      const updatedData = this.state.invoiceTableData.map((x) =>
        x.id === updatedInvoice.id ? { ...updatedInvoice } : x
      );
      let showInvoiceFinish = true;
      const _srch = updatedInvoice.content.find(
        (x) => x.orderStatus.name === "Processing"
      );
      if (_srch) {
        showInvoiceFinish = false;
      } else {
        showInvoiceFinish = true;
      }
      // if (updatedData.invoiceStatus.name === "Finished")
      //   showInvoiceFinish = false;

      this.setState(
        {
          selectedRowInvoice: updatedInvoice,
          invoiceTableData: updatedData,
          invoiceTableDataCopy: updatedData,
          showInvoiceFinish: showInvoiceFinish,
        },
        (x) => {}
      );
      //this.handleToggleInvoiceModal();
    }
    if (_elName === "txtQtyDeliveredPO") {
      //console.log(data);
      poDelivered = data;
    }
    if (_elName === "btnSetItemDeliveredFinished") {
      //console.log(data);
      await this.updateInvoiceContentStatus("Purchased", data.id);
      await this.updateInvoiceContentStatus("Purchased", data.histOrderId);
      this.refreshSelectedPRContent(data.id);
      this.updatePR();

      const updatedInvoice = await invoiceApi.getInvoice(
        this.props.dispatch,
        this.state.selectedRowInvoice.id
      );

      const updatedData = this.state.invoiceTableData.map((x) =>
        x.id === updatedInvoice.id ? { ...updatedInvoice } : x
      );
      let showInvoiceFinish = true;
      const _srch = updatedInvoice.content.find(
        (x) => x.orderStatus.name === "Processing"
      );
      if (_srch) {
        showInvoiceFinish = false;
      } else {
        showInvoiceFinish = true;
      }
      // if (updatedData.invoiceStatus.name === "Finished")
      //   showInvoiceFinish = false;

      this.setState(
        {
          selectedRowInvoice: updatedInvoice,
          invoiceTableData: updatedData,
          invoiceTableDataCopy: updatedData,
          showInvoiceFinish: showInvoiceFinish,
        },
        (x) => {}
      );
      //this.handleToggleInvoiceModal();
    }
    if (_elName === "txtPOContentNote") {
      //console.log(data);
      await this.updateInvoiceContentNotes(data.id, e.value);
      this.refreshSelectedPRContent();
    }
    if (_elName === "txtQty") {
      this.setState((a) => ({
        txtQty: {
          ...a.txtQty,
          value: e.target.value,
        },
      }));
      const itemQty = Number(this.state.txtQtyView.value);

      if (
        this.state.selContentItems.value !== null &&
        !this.state.isInvoiceContentItemChange &&
        this.state.selDetailContentStatus.value !== null
      ) {
        if (this.state.selDetailContentStatus.value.name === "Reserved") {
          if (itemQty < Number(e.target.value)) {
            this.props.dispatch({
              type: "MODAL_SHOW",
              payload: {
                title: "Notice",
                desc: `Qty cannot be greater than item in stock for reservation.`,
                isYesNo: false,
              },
            });
            this.setState((a) => ({
              selDetailContentStatus: {
                ...a.selDetailContentStatus,
                value: this.state.selDetailContentStatus.data.find(
                  (x) => x.name === "For order"
                ),
              },
            }));
          }
        } else {
          if (itemQty < Number(e.target.value))
            this.setState((a) => ({
              selDetailContentStatus: {
                ...a.selDetailContentStatus,
                value: this.state.selDetailContentStatus.data.find(
                  (x) => x.name === "For order"
                ),
              },
            }));
          if (itemQty >= Number(e.target.value))
            this.setState((a) => ({
              selDetailContentStatus: {
                ...a.selDetailContentStatus,
                value: this.state.selDetailContentStatus.data.find(
                  (x) => x.name === "In-Stock"
                ),
              },
            }));
        }
      }

      // let srp = 0;
      // if (this.state.selContentItems.value !== "") {
      //   srp = this.state.selContentItems.value.price;
      // } else {
      //   if (e.target.value.value === "") srp = 0;
      //   else
      //     srp =
      //       this.state.txtNetPrice.value === ""
      //         ? (srp = 0)
      //         : this.state.txtNetPrice.value;
      // }
      // let _cost = 0;
      // let _disocunt = 0;

      // if (e.target.value !== "") {
      //   _disocunt = srp * e.target.value * 0.01;
      //   _cost = srp - _disocunt;
      // } else {
      //   _cost = srp;
      // }
      // this.setState((x) => ({
      //   txtDiscountMax: { ...x.txtDiscountMax, value: e.target.value },
      //   txtNetPrice: {
      //     ...x.txtNetPrice,
      //     value: Number(_cost).toFixed(2),
      //     discounted: Number(_disocunt).toFixed(2),
      //   },
      // }));
    }
    if (_elName === "btnSetItemToForOrder") {
      const _ForOrder = this.state.selDetailContentStatus.data.find(
        (x) => x.name === "For order"
      );
      this.setState((a) => ({
        selDetailContentStatus: {
          ...a.selDetailContentStatus,
          value: _ForOrder,
        },
      }));
    }
    if (_elName === "btnSetItemToReserved") {
      const _ForOrder = this.state.selDetailContentStatus.data.find(
        (x) => x.name === "Reserved"
      );

      const _itemQty = Number(this.state.selContentItems.value.qty);

      if (Number(this.state.txtQty.value) > _itemQty) {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Notice",
            desc: `Qty cannot be greater than item in stock for reservation.`,
            isYesNo: false,
          },
        });
      } else {
        this.setState((a) => ({
          selDetailContentStatus: {
            ...a.selDetailContentStatus,
            value: _ForOrder,
          },
        }));
      }
    }
    if (_elName === "selInvoiceInvTransferItem") {
      // const _ForOrder = this.state.selDetailContentStatus.data.find(
      //   (x) => x.name === "For order"
      // );
      this.setState((a) => ({
        selInventoryInvChangeFromContent: {
          ...a.selInventoryInvChangeFromContent,
          value: e.target.value,
        },
      }));
    }
    if (_elName === "selToInventoryTransferInv") {
      if (e.target.value === this.state.selInventory.value) {
        this.props.dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Notice",
            desc: `Origin Inventory cannot be the same as destination Inventory`,
            isYesNo: false,
          },
        });
      } else {
        this.setState((a) => ({
          selInventoryInvChange: {
            ...a.selInventoryInvChange,
            value: e.target.value,
          },
        }));
      }
    }
    if (_elName === "btnRemoveItemToTransfer") {
      let _data = this.state.TransferInvTableData;
      _data = _data.filter(
        (x) => x.id !== this.state.selectedRowTransferInv.id
      );
      this.setState((a) => ({
        TransferInvTableData: _data,
        txtQtyInvChange: { ...a.txtQtyInvChange, value: "" },
        selInventoryInvChangeFromContent: {
          ...a.selInventoryInvChangeFromContent,
          value: "",
        },
        TransferInvTableSelection: [],
        selectedRowTransferInv: null,
      }));
    }
    if (_elName === "btnAddItemToTransfer") {
      let _data = this.state.TransferInvTableData;
      _data.push({
        id:
          this.state.selectedRowInvoiceSelection !== null
            ? this.state.selectedRowInvoiceSelection.id
            : filters.uuidv4(),
        item: this.state.selInventoryInvChangeFromContent.value,
        itemId: Number(this.state.selInventoryInvChangeFromContent.value.id),
        itemQty: Number(this.state.txtQtyInvChange.value),
        itemUnitId:
          this.state.selInventoryInvChangeFromContent.value.details.find(
            (x) => x.type === 2
          ).id,
        showImg: 1,
        dispImg: { original: emptyImg, isDefault: true },
        orderStatus: this.state.selDetailContentStatus.value,
        orderType: this.state.selDetailContentType.value.name,
        orderStatusId: Number(this.state.selDetailContentStatus.value.id),
        orderTypeId: this.state.selDetailContentType.data.find(
          (x) => x.name === "Item"
        ).id,
      });
      let _content = [];
      //console.log(_data);
      for (let i = 0; i < _data.length; i++) {
        const _e = _data[i];
        if (_e.item !== null) {
          if (_e.item.images !== null && _e.item.images !== undefined) {
            if (_e.item.images.length > 0) {
              const _img = await inventoryApi.downloadItemImg(
                this.props.dispatch,
                _e.item,
                1
              );

              _content.push({ ..._e, dispImg: _img[0] });
            } else {
              _content.push({ ..._e });
            }
          } else {
            _content.push({ ..._e });
          }
        } else {
          _content.push({ ..._e });
        }
      }
      //console.log(_content);
      this.setState((a) => ({
        TransferInvTableData: _content,
        txtQtyInvChange: { ...a.txtQtyInvChange, value: "" },
        selInventoryInvChangeFromContent: {
          ...a.selInventoryInvChangeFromContent,
          value: "",
        },
      }));
    }
    if (_elName === "btnFinishInvTransfer") {
      let _data = this.state.TransferInvTableData;
      _data = _data.filter(
        (x) => x.id !== this.state.selectedRowTransferInv.id
      );
      this.setState((a) => ({
        TransferInvTableData: _data,
        txtQtyInvChange: { ...a.txtQtyInvChange, value: "" },
        selInventoryInvChangeFromContent: {
          ...a.selInventoryInvChangeFromContent,
          value: "",
        },
        TransferInvTableSelection: [],
        selectedRowTransferInv: null,
      }));
    }
    if (_elName === "btnTriggerTransfer") {
      //let _data = this.state.TransferInvTableData;
      const _dat = {
        data: {
          inventoryIdFrom: Number(
            this.state.selInventoryInvChangeFrom.value.id
          ),
          inventoryIdTo: Number(this.state.selInventoryInvChange.value.id),
        },
        content: this.state.TransferInvTableData,
      };
      //console.log(_dat);
      await invoiceApi.transferItemsToInventory(this.props.dispatch, _dat);
    }
    if (_elName === "btnSubmitInvoiceForCheckout") {
      // const items = this.state.selectedRowInvoice.content.filter(
      //   (x) => x.itemId !== null && x.orderStatus.name !== "Reserved"
      // );
      // let itemProcess = [];
      // for (let i = 0; i < items.length; i++) {
      //   const _item = items[i];
      //   itemProcess.push({
      //     data: { id: Number(_item.itemId), qty: Number(_item.itemQty) },
      //   });
      // }
      // await inventoryApi.subtractItemQtyMany(
      //   this.state.dispatch,
      //   itemProcess,
      //   () => {}
      // );
      this.updateInvoiceStatus("Walk In");
    }
    if (_elName === "handleDRStatusChange") {
      this.setState((x) => ({
        selDetailStatusDR: {
          ...x.selDetailStatusDR,
          value: e.target.value,
        },
      }));
    }
    if (_elName === "btnSetPickupClientDR") {
      const WALKIN = this.state.selDetailStatus.data.find(
        (x) => x.name === "Finished"
      );

      this.setState(
        (x) => ({
          selDetailDriver: { ...x.selDetailDriver, value: null },
          selDetailStatusDR: {
            ...x.selDetailStatusDR,
            value: WALKIN,
          },
        }),
        () => {
          // console.log("trigger", this.state.selDetailStatusDR.value);
        }
      );
    }
    if (_elName === "downloadPDF") {
      this.downloadPDF(e.target.value);
    }
    if (_elName === "resetFilter") {
      this.setState(
        (x) => ({
          dpStartDate: {
            ...x.dpStartDate,
            value: moment(this.state.DateToday)
              .subtract(2, "months")
              .format("YYYY-MM"),
            // value: misc.parseAPIDate(moment(DateToday).subtract(30, "days")),
            readOnly: true,
          },
          chkAllTime: {
            ...x.chkAllTime,
            value: false,
            readOnly: true,
          },
          chkOngoing: {
            ...x.chkOngoing,
            value: true,
            readOnly: false,
          },
          chkDisplayPOS: {
            ...x.chkDisplayPOS,
            value: false,
            readOnly: false,
          },
        }),
        () => {
          if (cb) cb();
        }
      );
    }
    if (_elName === "applyFilter") {
      const invoiceItems = await this.refreshInvoice();
      //console.log(invoiceItems);
      this.setState(
        {
          invoiceTableData: invoiceItems,
          invoiceTableDataCopy: invoiceItems,
        },
        (x) => {
          if (cb) cb();
        }
      );
    }
    if (_elName === "refreshAndReplaceSelected") {
      const returnedInvoice = e.target.value;
      const updatedData = this.state.invoiceTableData.map((x) =>
        x.id === returnedInvoice.id ? { ...returnedInvoice } : x
      );

      let paymentData = [];
      //let invoiceTotalAmountDue = 0;
      let invoiceAMountPaid = 0;

      let selectedInvoicePayments = [];
      selectedInvoicePayments = returnedInvoice.paymentTransaction.filter(
        (x) => x.isEntry !== 1
      );

      // paymentData =
      //   returnedInvoice.paidAmountHistory !== null
      //     ? JSON.parse(returnedInvoice.paidAmountHistory)
      //     : [];

      invoiceAMountPaid = selectedInvoicePayments
        .reduce((a, c) => {
          // if (c.childId == null) return a + c.price * c.itemQty;
          // else return a + c.child.price * c.child.itemQty;
          return a + c.amountValue;
        }, 0)
        .toFixed(2);

      //console.log(returnedInvoice);
      let _invoiceTotalAmountDue = Number(this.state.grandTotal) - Number(invoiceAMountPaid);
      if(_invoiceTotalAmountDue <= 0){
        _invoiceTotalAmountDue = 0
      }
      this.setState(
        {
          selectedRowInvoice: returnedInvoice,
          invoiceTableData: updatedData,
          invoiceTableDataCopy: updatedData,
          selectedInvoicePayments: selectedInvoicePayments,
          invoiceTotalAmountDue:
          _invoiceTotalAmountDue,
        },
        (x) => {}
      );
      //console.log(e.target.value);
    }
    if (_elName === "chkAllTime") {
      const DateToday = await actorApi.getCurrentDateTime(this.state.dispatch);
      if (!this.state.chkAllTime.value) {
        this.setState(
          (x) => ({
            chkAllTime: {
              ...x.chkAllTime,
              value: this.state.chkAllTime.value ? false : true,
            },
            chkOngoing: {
              ...x.chkOngoing,
              value: false,
              readOnly: true,
            },
            // chkDisplayPOS: {
            //   ...x.chkDisplayPOS,
            //   value: false,
            //   readOnly: false,
            // },

            dpStartDate: {
              ...x.dpStartDate,
              value: "",
              readOnly: true,
            },
            dpEndDate: {
              ...x.dpEndDate,
              value: "",
              readOnly: true,
            },
          }),
          () => {
            if (cb) cb();
          }
        );
      } else {
        this.setState(
          (x) => ({
            chkAllTime: {
              ...x.chkAllTime,
              value: this.state.chkAllTime.value ? false : true,
            },
            chkOngoing: {
              ...x.chkOngoing,
              value: false,
              readOnly: false,
            },
            // chkDisplayPOS: {
            //   ...x.chkDisplayPOS,
            //   value: false,
            //   readOnly: false,
            // },
            dpStartDate: {
              ...x.dpStartDate,
              value: misc.parseAPIDate(moment(DateToday).subtract(30, "days")),
              readOnly: false,
            },
            dpEndDate: {
              ...x.dpEndDate,
              value: "",
              readOnly: false,
            },
          }),
          () => {
            if (cb) cb();
          }
        );
      }
    }
    if (_elName === "chkOngoing") {
      const DateToday = await actorApi.getCurrentDateTime(this.state.dispatch);
      if (!this.state.chkOngoing.value) {
        this.setState(
          (x) => ({
            chkOngoing: {
              ...x.chkOngoing,
              value: this.state.chkOngoing.value ? false : true,
            },
            chkAllTime: {
              ...x.chkAllTime,
              value: false,
              readOnly: true,
            },
            // chkDisplayPOS: {
            //   ...x.chkDisplayPOS,
            //   value: false,
            //   readOnly: false,
            // },
            dpStartDate: {
              ...x.dpStartDate,
              value: "",
              readOnly: true,
            },
            dpEndDate: {
              ...x.dpEndDate,
              value: "",
              readOnly: true,
            },
          }),
          () => {
            if (cb) cb();
          }
        );
      } else {
        this.setState(
          (x) => ({
            chkOngoing: {
              ...x.chkOngoing,
              value: this.state.chkOngoing.value ? false : true,
            },
            chkAllTime: {
              ...x.chkAllTime,
              value: false,
              readOnly: false,
            },
            // chkDisplayPOS: {
            //   ...x.chkDisplayPOS,
            //   value: false,
            //   readOnly: false,
            // },
            dpStartDate: {
              ...x.dpStartDate,
              value: misc.parseAPIDate(moment(DateToday).subtract(30, "days")),
              readOnly: false,
            },
            dpEndDate: {
              ...x.dpEndDate,
              value: "",
              readOnly: false,
            },
          }),
          () => {
            if (cb) cb();
          }
        );
      }
    }
    if (_elName === "chkDisplayPOS") {
      this.setState(
        (x) => ({
          chkDisplayPOS: {
            ...x.chkDisplayPOS,
            value: !this.state.chkDisplayPOS.value,
          },
        }),
        () => {
          if (cb) cb();
        }
      );
    }
    if (_elName === "btnTransferToDisplay") {
      await inventoryApi.transferDisplayToAvailItem(
        this.state.dispatch,
        this.state.selContentItems.value.id
      );
      // console.log(this.state.selInventory.value);
      this.state.handleActionChange({
        target: {
          name: "handleInventoryChange",
          value: this.state.selInventory.value,
        },
      });
    }
    if (_elName === "chkPDFWithDesc") {
      this.setState((x) => ({
        chkPDFWithDesc: {
          ...x.chkPDFWithDesc,
          value: this.state.chkPDFWithDesc.value ? false : true,
        },
      }));
    }
    if (_elName === "chkPDFGroupItems") {
      this.setState((x) => ({
        chkPDFGroupItems: {
          ...x.chkPDFGroupItems,
          value: this.state.chkPDFGroupItems.value ? false : true,
        },
      }));
    }
    if (_elName === "btnTotalCogsPR") {
      let handling = 0 + Number(this.state.txtHandlingPR.value);
      let shipping = 0 + Number(this.state.txtShippingPR.value);
      let cost = 0 + Number(this.state.txtCostPR.value);

      let totalCost = handling + shipping + cost;

      this.setState((x) => ({
        txtCogsPR: {
          ...x.txtCogsPR,
          value: totalCost,
        },
      }));
    }
    if (_elName === "btnCopyCogsFromInventory") {
      //selectedRowPR
      //console.log(this.state.selectedRowPR);
      this.setState((x) => ({
        txtHandlingPR: {
          ...x.txtHandlingPR,
          value: this.state.selectedRowPRItems.item.handling,
        },
        txtShippingPR: {
          ...x.txtShippingPR,
          value: this.state.selectedRowPRItems.item.shipping,
        },
        txtCostPR: {
          ...x.txtCostPR,
          value: this.state.selectedRowPRItems.item.cost,
        },
        txtCogsPR: {
          ...x.txtCogsPR,
          value: this.state.selectedRowPRItems.item.cogs,
        },
      }));
    }
    if (_elName === "chkShowAppData") {
      let FilteredInvoiceDetails = [];
      if (this.state.chkShowAppData.value) {
        FilteredInvoiceDetails = this.state.invoiceDetailsConst.filter(
          (x) => x.name === "Terms"
        );
        const Terms = FilteredInvoiceDetails.find((x) => x.name === "Terms");
        //console.log(Terms);
        if (Terms)
          this.handleActionChangeInvoiceDetails({
            target: { value: Terms.value },
          });
      } else {
        FilteredInvoiceDetails = this.state.invoiceDetailsConst;
      }

      this.setState((a) => ({
        invoiceDetailsConstBak: FilteredInvoiceDetails,
        chkShowAppData: {
          ...a.chkShowAppData,
          value: this.state.chkShowAppData.value ? false : true,
        },
      }));
    }
    if (_elName === "chkIsDefaultTerm") {
      this.setState((a) => ({
        chkIsDefaultTerm: {
          ...a.chkIsDefaultTerm,
          value: this.state.chkIsDefaultTerm.value ? false : true,
        },
      }));
    }
    if (_elName === "handleFileUploadChange") {
      let currentFiles = e.target.files;
      //console.log(currentFiles);
      this.setState((x) => ({
        fileUploadInvoice: {
          ...x.fileUploadInvoice,
          value: currentFiles,
        },
      }));
    }
    if (_elName === "btnFileUploadSubmit") {
      const currentFiles = this.state.fileUploadInvoice.value;

      //For custom file name
      //const fileType = currentFiles[0].type.split("/")[1];
      //console.log(this.state.selectedRowInvoiceSelection);

      const formData = new FormData();
      formData.append("uniqueId", this.state.selectedRowInvoice.uniqueId);
      for (let index = 0; index < currentFiles.length; index++) {
        formData.append(
          "uploadedFiles",
          currentFiles[index]
          //`signature.${fileType}`
        );
      }
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      invoiceApi.uploadFile(this.props.dispatch, formData, async () => {
        this.refreshSelectedDRContent(this.state.selectedRowInvoice.id, true);
        // const UPDATED_ITEMS = await this.refreshInvoice();
        // const oldSel = this.state.selectedRowInvoice;

        // this.setState(
        //   (x) => ({
        //     invoiceTableData: UPDATED_ITEMS,
        //     invoiceTableDataCopy: UPDATED_ITEMS,
        //   }),
        //   () => {
        //     this.setState((x) => ({
        //       selectedRowInvoice: this.state.invoiceTableData.filter(
        //         (x) => x.id === oldSel.id
        //       )[0],
        //       isLoading: false,
        //     }));
        //     if (cb) cb();
        //   }
        // );
      });
    }
    if (_elName === "btnFileUploadDownload") {
      invoiceApi.downloadFile(
        this.props.dispatch,
        e.target.id,
        e.target.filename,
        e.target.complete,
        () => {
          // this.props.dispatch({
          //   type: "FILE_VIEWER_MODAL_TOGGLE",
          //   doc: {},
          //   docType: {},
          // });
        }
      );
    }
    if (_elName === "btnFileUploadView") {
      const url = `${process.env.REACT_APP_API_URL}global/getFile2?uniqueId=${
        e.target.id
      }&filename=${e.target.filename}&token=${localStorage.getItem("LUT")}`;
      this.props.dispatch({
        type: "FILE_VIEWER_MODAL_TOGGLE",
        doc: url,
      });
    }
    if (_elName === "btnFileUploadDelete") {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Confirmation",
          desc: `Are you sure you want to delete ${e.target.originalname} ?`,
          isYesNo: true,
          cb: async (x) => {
            invoiceApi.deleteFile(
              this.props.dispatch,
              this.state.selectedRowInvoice.uniqueId,
              e.target.filename,
              async () => {
                const UPDATED_ITEMS = await this.refreshInvoice();
                const oldSel = this.state.selectedRowInvoice;

                this.setState(
                  (x) => ({
                    invoiceTableData: UPDATED_ITEMS,
                    invoiceTableDataCopy: UPDATED_ITEMS,
                  }),
                  () => {
                    this.setState((x) => ({
                      selectedRowInvoice: this.state.invoiceTableData.filter(
                        (x) => x.id === oldSel.id
                      )[0],
                      isLoading: false,
                    }));
                  }
                );
              }
            );
          },
        },
      });
    }
    if (_elName === "toggleInvoiceFinish") {
      this.setState((x) => ({
        showInvoiceFinish: !this.state.showInvoiceFinish,
      }));
    }
    if (_elName === "chkHandleChange") {
      this.setState((x) => ({
        [e.target.componentName]: {
          ...x[e.target.componentName],
          value: this.state[e.target.componentName].value ? false : true,
        },
      }));
    }
  };

  downloadPDF = async (blobData, hist) => {
    let fileName = "";
    if (this.state.selectedRowInvoice.invoiceType.name === "Quote") {
      fileName = `${this.state.selectedRowInvoice.client.company} QUOTE`;
    }
    if (this.state.selectedRowInvoice.invoiceType.name === "Delivery Receipt") {
      fileName = `${this.state.selectedRowInvoice.client.company} DR`;
    }
    if (
      this.state.selectedRowInvoice.invoiceType.name === "Transfer Inventory"
    ) {
      fileName = `${this.state.selectedRowInvoice.inventoryFrom.name} TO ${this.state.selectedRowInvoice.inventoryTo.name} Transfer`;
    }
    if (
      this.state.selectedRowInvoice.invoiceType.name === "Purchase Order" ||
      this.state.selectedRowInvoice.invoiceType.name === "Purchase Request"
    ) {
      fileName = `${this.state.selectedRowInvoice.supplier.company} REQUEST`;
    }
    const href = await window.URL.createObjectURL(new Blob([blobData]));
    const link = document.createElement("a");
    link.href = href;

    link.download = fileName + ".pdf";
    document.body.appendChild(link);
    link.click();

    const data = {
      data: {
        id: Number(this.state.selectedRowInvoice.id),
        lastModifiedByActorId: Number(this.state.user.id),
        //dateLastModified: DateToday,
        isSignatureAvailable: 2,
      },
    };

    await invoiceApi.updateInvoice(this.props.dispatch, data);
    const Invoices = await this.refreshInvoice(this.props.user.id);

    if (this.state.handleInvoiceModal.isShow) this.handleToggleInvoiceModal();

    this.setState({
      invoiceTableData: Invoices,
      invoiceTableDataCopy: Invoices,
      invoiceTableSelection: [],
      selectedRowInvoice: null,
    });
  };

  refreshSelectedPRContent = async (id) => {
    const _UpdatedContent = await invoiceApi.getOrderContent(
      this.props.dispatch,
      !id ? this.state.selectedRowPR.id : id
    );
    //console.log(_UpdatedContent);

    if (_UpdatedContent) {
      let _tempData = this.state.PRTableData;
      _tempData = _tempData.map((x) =>
        x.id === _UpdatedContent.id ? _UpdatedContent : x
      );

      this.setState({
        refreshPRData: true,
        PRTableSelection: [],
        selectedRowPR: null,
        PRTableData: _tempData,
        PRDataCopy: _tempData,
      });
    }
  };
  refreshSelectedDRContent = async (id, ignoreClearState) => {
    const _UpdatedContent = await invoiceApi.getInvoice(
      this.props.dispatch,
      !id ? this.state.selectedRowInvoice.id : id
    );
    //console.log(_UpdatedContent);

    if (_UpdatedContent) {
      let _tempData = this.state.invoiceTableData;
      _tempData = _tempData.map((x) =>
        x.id === _UpdatedContent.id ? _UpdatedContent : x
      );

      this.setState({
        ...(!ignoreClearState && {
          selectedRowInvoice: null,
          invoiceTableSelection: [],
        }),
        ...(ignoreClearState && {
          selectedRowInvoice: _UpdatedContent,
        }),
        invoiceTableData: [..._tempData],
        invoiceTableDataCopy: [..._tempData],
      });
    }
  };
  createInvTransfer = async () => {
    let _tempData = [];
    const _TransferStatusId = Number(
      this.state.selDetailContentStatus.data.find((x) => x.name === "Transfer")
        .id
    );
    for (let i = 0; i < this.state.TransferInvTableData.length; i++) {
      const a = this.state.TransferInvTableData[i];

      _tempData.push({
        itemId: a.itemId,
        itemUnitId: a.itemUnitId,
        orderStatusId: _TransferStatusId,
        orderTypeId: a.orderTypeId,
        itemQty: a.itemQty,
      });
    }
    let _InvoiceStatusId = Number(
      this.state.selDetailStatus.data.find(
        (x) => x.name === "Pending Admin Approval"
      ).id
    );
    let InventoryId = Number(this.state.selInventory.value.id);
    if (InventoryId === 1) {
      InventoryId = null;
    }
    const data = {
      data: {
        name: this.state.txtInvoiceName.value,
        inchargeId: Number(this.state.user.id),
        description: this.state.txtDesc.value,
        // inventoryId: Number(this.state.selInventory.value.id),
        inventoryId: InventoryId,
        //dateAdded: DateToday,
        //dateLastModified: DateToday,
        lastModifiedByActorId: Number(this.state.user.id),
        notes: this.state.txtNotes.value,
        inventoryIdFrom: Number(this.state.selInventoryInvChangeFrom.value.id),
        inventoryIdTo: Number(this.state.selInventoryInvChange.value.id),
        invoiceTypeId: Number(
          this.state.selDetailType.data.find(
            (x) => x.name === "Transfer Inventory"
          ).id
        ),
        invoiceStatusId: _InvoiceStatusId,
        deadline:
          this.state.txtDeadline.value !== ""
            ? this.state.txtDeadline.value
            : null,
      },

      content: _tempData,
    };
    //console.log(data);

    await invoiceApi.createInvoice(this.props.dispatch, data);

    const Invoices = await this.refreshInvoice(this.props.user.id);
    actorApi.sendEmail(
      this.state.dispatch,
      this.state.txtDesc.value,
      this.state.user?.incharge?.lastName,
      Number(this.state.selInventory.value.id)
    );
    this.setState({
      invoiceTableData: Invoices,
      invoiceTableDataCopy: Invoices,
    });
  };

  updateInvTransfer = async () => {
    let _tempData = [];
    const _TransferStatusId = Number(
      this.state.selDetailContentStatus.data.find((x) => x.name === "Transfer")
        .id
    );
    for (let i = 0; i < this.state.TransferInvTableData.length; i++) {
      const a = this.state.TransferInvTableData[i];

      _tempData.push({
        itemId: a.itemId,
        itemUnitId: a.itemUnitId,
        orderStatusId: _TransferStatusId,
        orderTypeId: a.orderTypeId,
        itemQty: a.itemQty,
      });
    }

    const data = {
      data: {
        id: Number(this.state.selectedRowInvoice.id),
        name: this.state.txtInvoiceName.value,
        inchargeId: Number(this.state.user.id),
        description: this.state.txtDesc.value,
        //dateAdded: DateToday,
        //dateLastModified: DateToday,
        lastModifiedByActorId: Number(this.state.user.id),
        notes: this.state.txtNotes.value,
        inventoryIdFrom: Number(this.state.selInventory.value.id),
        inventoryIdTo: Number(this.state.selInventoryInvChange.value.id),

        invoiceStatusId: Number(this.state.selDetailStatus.value.id),
        deadline:
          this.state.txtDeadline.value !== ""
            ? this.state.txtDeadline.value
            : null,
      },

      content: _tempData,
    };
    //console.log(data);

    await invoiceApi.updateInvoice(this.props.dispatch, data);

    const Invoices = await this.refreshInvoice(this.props.user.id);

    this.setState({
      invoiceTableData: Invoices,
      invoiceTableDataCopy: Invoices,
    });
    return true;
  };

  createPR = async () => {
    //console.log(this.state.user);

    let _tempData = [];
    const _ProcessingStatusId = Number(
      this.state.selDetailContentStatus.data.find(
        (x) => x.name === "Processing"
      ).id
    );
    for (let i = 0; i < this.state.PRTableData.length; i++) {
      const a = this.state.PRTableData[i];
      console.log(a);
      _tempData.push({
        itemId: a.itemId,
        itemUnitId: a.itemUnitId,
        orderStatusId: _ProcessingStatusId,
        orderTypeId: a.orderTypeId,
        qtyOrdered: a.qtyOrdered,
        //itemQty: a.itemQty,
        histOrderId: Number(a.oldData.id),
        price: a.price ? a.price : a.item.cost,
        discount: a.discount,
        cogs: a.cogs,
        handling: a.handling,
        shipping: a.shipping,
        cost: a.cost ? a.cost : a.item.cost,
      });
    }
    //console.log(this.state);

    let _PRStatusId = Number(
      this.state.selDetailStatus.data.find(
        (x) => x.name === "Pending Admin Approval"
      ).id
    );

    // this.setState((x) => ({
    //   invoiceTableData: invoiceDetails,
    //   invoiceTableDataCopy: invoiceDetails,
    // }), () => {

    // });

    let InventoryId = Number(this.state.selInventory.value.id);
    if (InventoryId === 1) {
      InventoryId = null;
    }

    const data = {
      data: {
        name: `${this.state.txtInvoiceName.value}`,
        inventoryId: InventoryId,
        inchargeId: Number(this.state.user.id),
        shippingAddress: this.state.txtShippingAddress.value,
        description: this.state.txtDesc.value,
        //dateAdded: DateToday,
        //dateLastModified: DateToday,
        lastModifiedByActorId: Number(this.state.user.id),
        supplierId: Number(this.state.selDetailSupplier.value.id),
        notes: this.state.txtNotesPR.value,
        invoiceTypeId: Number(
          this.state.selDetailType.data.find(
            (x) => x.name === "Purchase Request"
          ).id
        ),
        invoiceStatusId: _PRStatusId,
        deadline: this.state.txtDateDeadlinePR.value
          ? this.state.txtDateDeadlinePR.value
          : "",
      },
      //details: this.state.termsTableData,
      content: _tempData,
    };
    //console.log(data);

    await invoiceApi.createInvoice(this.props.dispatch, data);

    const Invoices = await this.refreshInvoice(this.props.user.id);
    actorApi.sendEmail(
      this.state.dispatch,
      this.state.txtDesc.value,
      this.state.user?.incharge?.lastName,
      InventoryId
    );
    // const UpdatedInvoice = await invoiceApi.getInvoice(
    //   this.props.dispatch,
    //   Number(this.state.selectedRowInvoice.id)
    // );

    this.setState({
      invoiceTableData: Invoices,
      invoiceTableDataCopy: Invoices,
    });
  };
  updatePR = async () => {
    const data = {
      data: {
        name: `${this.state.txtInvoiceName.value}`,
        id: Number(this.state.selectedPRID),
        inchargeId: Number(this.state.user.id),
        shippingAddress: this.state.txtShippingAddress.value,
        description: this.state.txtDesc.value,
        lastModifiedByActorId: Number(this.state.user.id),
        //dateLastModified: DateToday,

        supplierId: Number(this.state.selDetailSupplier.value.id),
        notes: this.state.txtNotesPR.value,

        invoiceStatusId: Number(this.state.selDetailStatus.value.id),
        deadline: this.state.txtDateDeadlinePR.value
          ? this.state.txtDateDeadlinePR.value
          : "",
      },
      //details: this.state.termsTableData,
      //content: _tempData,
    };
    //console.log(data);

    await invoiceApi.updateInvoice(this.props.dispatch, data);

    const Invoices = await this.refreshInvoice(this.props.user.id);

    this.setState({
      invoiceTableData: Invoices,
      invoiceTableDataCopy: Invoices,
    });
  };

  populateDR = async (data, cb) => {
    console.log("SELECTED DR ===>", data);
    this.clearInputsDR(true, false);
    let showDRConfirmation = false;
    let showDROngoingDelivery = false;
    let ParentInvoice = await invoiceApi.getInvoice(
      this.props.dispatch,
      Number(data.parentId)
    );

    ParentInvoice = ParentInvoice.content.filter(
      (x) => x.itemId !== null && x.childId === null
    );

    const childrens = await invoiceApi.getOrderByParentId(
      this.props.dispatch,
      Number(data.parentId)
    );

    //console.log("BEFORE DR ===>", filtered);

    for (let i = 0; i < childrens.length; i++) {
      const content = childrens[i].content;

      for (let a = 0; a < content.length; a++) {
        const item = content[a];

        ParentInvoice = ParentInvoice.map((x) =>
          x.itemId === item.itemId
            ? { ...x, qtyDelivered: x.qtyDelivered + item.qtyDelivered }
            : x
        );
      }
    }
    //rowInfo.original.itemQty !== rowInfo.original.qtyDelivered
    const onProgressDRItems = ParentInvoice.filter(
      (x) => x.itemQty !== x.qtyDelivered
    );

    //console.log(onProgressDRItems);

    if (data.invoiceStatus.name === "Ongoing Delivery")
      showDRConfirmation = true;

    if (
      data.invoiceStatus.name === "For Delivery" &&
      onProgressDRItems.length === 0
    )
      showDROngoingDelivery = true;

    this.setState(
      (x) => ({
        selectedOperation: SELECTED_OPERATION.VIEW,
        selectedModalOperation: SELECTED_OPERATION.VIEW,
        handleDRModal: { ...x.handleDRModal, isView: true },
        selDetailDriver: {
          ...x.selDetailDriver,
          value: this.state.selDetailDriver.data.find(
            (x) => x.id === data.driverId
          ),
        },
        selDetailType: { ...x.selDetailType, value: data.invoiceType },
        txtShippingAddressDR: {
          ...x.txtShippingAddressDR,
          value: data.shippingAddress,
        },
        txtInvoiceNameDR: {
          ...x.txtInvoiceNameDR,
          value: data.name,
        },
        selDetailStatusDR: {
          ...x.selDetailStatusDR,
          value: this.state.selDetailStatus.data.find(
            (x) => x.id === data.invoiceStatusId
          ),
        },
        txtDateDeadlineDR: {
          ...x.txtDateDeadlineDR,
          value: data.deadline ? misc.parseAPIDateTime(data.deadline) : "",
        },
        txtDateLastModifiedDR: {
          ...x.txtDateLastModifiedPR,
          value: data.dateLastModified
            ? misc.parseAPIDateTime(data.dateLastModified)
            : "",
        },
        txtDateAddedDR: {
          ...x.txtDateAddedPR,
          value: data.dateAdded ? misc.parseAPIDateTime(data.dateAdded) : "",
        },
        txtDateDelivered: {
          ...x.txtDateDelivered,
          value: null
            ? ""
            : data.dateFinished
            ? misc.parseAPIDateTime(data.dateFinished)
            : "",
        },

        selectedDRID: data.id,
        selectedDRParentID: data.parentId,
        showDRConfirmation: showDRConfirmation,
        showDROngoingDelivery: showDROngoingDelivery,
        dRItemSelectTableData: data.content,
        dRItemSelectTableDataCopy: data.content,
        dRItemsTableData: ParentInvoice,
        dRItemsTableDataCopy: ParentInvoice,
      }),
      () => {
        if (cb) cb();
      }
    );
  };

  updateDR = async () => {
    const data = {
      data: {
        id: Number(this.state.selectedDRID),
        //inchargeId: Number(this.state.user.id),
        name: this.state.txtInvoiceNameDR.value,
        shippingAddress: this.state.txtShippingAddressDR.value,
        deadline: this.state.txtDateDeadlineDR.value,
        driverId: this.state.selDetailDriver?.value
          ? this.state.selDetailDriver?.value?.id
          : null,
        dateFinished: this.state.txtDateDelivered.value,
        //dateLastModified: DateToday,
        lastModifiedByActorId: Number(this.state.user.id),
        notes: this.state.txtNotes.value,
        invoiceTypeId: Number(
          this.state.selDetailType.data.find(
            (x) => x.name === "Delivery Receipt"
          ).id
        ),
        invoiceStatusId: Number(this.state.selDetailStatusDR.value.id),
      },
    };

    await invoiceApi.updateInvoice(this.props.dispatch, data);

    const Invoices = await this.refreshInvoice(this.props.user.id);

    const UpdatedInvoice = await invoiceApi.getInvoice(
      this.props.dispatch,
      Number(this.state.selectedRowInvoice.id)
    );

    //console.log(UpdatedInvoice);

    this.setState(
      {
        invoiceTableData: Invoices,
        invoiceTableDataCopy: Invoices,
        listDRTableData: UpdatedInvoice.children,
        listDRTableDataCopy: UpdatedInvoice.children,
      },
      () => {
        // if (this.state.handleInvoiceModal.isShow) {
        //   this.handleToggleInvoiceModal();
        // }
      }
    );
  };

  createDR = async () => {
    //console.log(this.state.user);

    let _tempData = [];
    for (let i = 0; i < this.state.dRItemSelectTableData.length; i++) {
      const a = this.state.dRItemSelectTableData[i];
      _tempData.push({
        itemId: a.itemId,
        itemUnitId: a.itemUnitId,
        orderStatusId: a.orderStatusId,
        orderTypeId: a.orderTypeId,
        qtyDelivered: a.qtyDelivered,
      });
    }
    //console.log(this.state);

    // let _DRStatusId = Number(
    //   this.state.selDetailStatus.data.find(
    //     (x) => x.name === "Pending Admin Approval"
    //   ).id
    // );

    // this.setState((x) => ({
    //   invoiceTableData: invoiceDetails,
    //   invoiceTableDataCopy: invoiceDetails,
    // }), () => {

    // });

    let InventoryId = Number(this.state.selInventory.value.id);
    if (InventoryId === 1) {
      InventoryId = null;
    }

    const data = {
      data: {
        name: this.state.txtInvoiceNameDR.value,
        inventoryId: InventoryId,
        parentId: Number(this.state.selectedRowInvoice.id),
        inchargeId: Number(this.state.user.id),
        shippingAddress: this.state.txtShippingAddressDR.value,
        deadline: this.state.txtDateDeadlineDR.value,
        //dateAdded: DateToday,
        //dateLastModified: DateToday,
        lastModifiedByActorId: Number(this.state.user.id),
        driverId:
          this.state.selDetailDriver.value !== null
            ? this.state.selDetailDriver.value.id
            : null,
        clientId: Number(this.state.selectedRowInvoice.clientId),
        notes: this.state.txtNotes.value,
        invoiceTypeId: Number(
          this.state.selDetailType.data.find(
            (x) => x.name === "Delivery Receipt"
          ).id
        ),
        isPOSTrans: this.state?.isPOSDR ? 1 : 0,
        invoiceStatusId: Number(this.state.selDetailStatusDR.value.id),
        // deadline:
        //   this.state.txtDeadline.value !== ""
        //     ? this.state.txtDeadline.value
        //     : null,
      },
      //details: this.state.termsTableData,
      content: _tempData,
    };
    //(data);

    await invoiceApi.createInvoice(this.props.dispatch, data);
    actorApi.sendEmail(
      this.state.dispatch,
      this.state.txtInvoiceNameDR.value,
      this.state.user?.incharge?.lastName,
      Number(this.state.selInventory.value.id)
    );
    const _srch = this.state.selDetailStatus.data.find(
      (x) => x.name === "Ongoing Delivery"
    );

    if (_srch) {
      const data = {
        data: {
          id: Number(this.state.selectedRowInvoice.id),
          invoiceStatusId: Number(_srch.id),
        },
      };
      await invoiceApi.updateInvoice(this.props.dispatch, data);
    }

    const Invoices = await this.refreshInvoice(this.props.user.id);
    const UpdatedInvoice = await invoiceApi.getInvoice(
      this.props.dispatch,
      Number(this.state.selectedRowInvoice.id)
    );

    this.setState(
      {
        invoiceTableData: Invoices,
        invoiceTableDataCopy: Invoices,
        listDRTableData: UpdatedInvoice.children,
        listDRTableDataCopy: UpdatedInvoice.children,
        isPOSDR: false,
      },
      () => {
        if (this.state.handleInvoiceModal.isShow) {
          this.handleToggleInvoiceModal();
        }
      }
    );
  };

  updateInvoiceContentNotes = async (id, notes) => {
    const data = {
      id: Number(
        typeof id === "undefined"
          ? this.state.selectedRowInvoiceSelection.id
          : id
      ),
      notes: notes,
      lastModifiedByActorId: Number(this.state.user.id),
      //dateLastModified: DateToday,
    };
    await invoiceApi.updateInvoiceContent(this.props.dispatch, data);
    return true;
  };

  updateInvoiceContentStatus = async (statusName, id, notes) => {
    const _srch = this.state.selDetailContentStatus.data.find(
      (x) => x.name === statusName
    );

    if (_srch) {
      const data = {
        id: Number(
          typeof id === "undefined"
            ? this.state.selectedRowInvoiceSelection.id
            : id
        ),
        lastModifiedByActorId: Number(this.state.user.id),
        //dateLastModified: DateToday,
        notes: notes,
        orderStatusId: Number(_srch.id),
      };
      await invoiceApi.updateInvoiceContent(this.props.dispatch, data);
    } else {
      console.log("Error");
    }
    return true;
  };

  updateInvoiceStatus = async (statusName, id) => {
    const _srch = this.state.selDetailStatus.data.find(
      (x) => x.name === statusName
    );

    if (_srch) {
      const data = {
        data: {
          id: Number(
            typeof id === "undefined" ? this.state.selectedRowInvoice.id : id
          ),
          lastModifiedByActorId: Number(this.state.user.id),
          //dateLastModified: DateToday,
          //dateFinished: DateToday,
          notes: this.state.txtNotes.value,
          invoiceStatusId: Number(_srch.id),
        },
      };
      await invoiceApi.updateInvoice(this.props.dispatch, data);

      const Invoices = await this.refreshInvoice(this.props.user.id);

      actorApi.sendEmail(
        this.props.dispatch,
        `Invoice "${this.state.selectedRowInvoice.name}" set to finished.`,
        this.state.user.lastName,
        this.state.selectedInventory
      );

      if (this.state.handleInvoiceModal.isShow) this.handleToggleInvoiceModal();

      this.setState({
        invoiceTableData: Invoices,
        invoiceTableDataCopy: Invoices,
        invoiceTableSelection: [],
        selectedRowInvoice: null,
      });
    } else {
      console.log("Error");
    }
    return true;
  };
  updateInvoiceType = async (typeName) => {
    const _srch = this.state.selDetailStatus.data.find(
      (x) => x.name === typeName
    );

    if (_srch) {
      const data = {
        data: {
          id: Number(this.state.selectedRowInvoice.id),
          notes: this.state.txtNotes.value,
          lastModifiedByActorId: Number(this.state.user.id),
          //dateLastModified: DateToday,
          invoiceTypeId: Number(_srch.id),
        },
      };
      await invoiceApi.updateInvoice(this.props.dispatch, data);

      const Invoices = await this.refreshInvoice(this.props.user.id);
      this.setState({
        invoiceTableData: Invoices,
        invoiceTableDataCopy: Invoices,
        invoiceTableSelection: [],
        selectedRowInvoice: null,
      });
    } else {
      console.log("Error");
    }
  };

  createInvoice = async (forPDF) => {
    //console.log(this.state.user);

    let _tempData = [];
    for (let i = 0; i < this.state.invoiceContentTableData.length; i++) {
      const a = this.state.invoiceContentTableData[i];
      _tempData.push({
        itemQty: a.itemQty,
        price: a.price,
        itemId: a.itemId,
        itemUnitId: a.itemUnitId,
        orderStatusId: a.orderStatusId,
        orderTypeId: a.orderTypeId,
        serviceName: a.serviceName,
        discount: a.discount,
        showImg: a.showImg,
        srp: a.srp,
        itemIndex: Number(a.itemIndex),
        placeholder: a.placeholder,
        priceModifier: a.priceModifier,
      });
    }

    let InventoryId = Number(this.state.selInventory.value.id);
    if (InventoryId === 1) {
      InventoryId = null;
    }

    const data = {
      data: {
        name: this.state.txtInvoiceName.value,
        isReservation: Number(this.state.chkIsReservation.value),
        supplierId:
          this.state.selDetailSupplier.value !== "" &&
          this.state.selDetailSupplier.value !== null
            ? Number(this.state.selDetailSupplier.value.id)
            : null,
        inventoryId: InventoryId,
        clientId:
          this.state.selDetailClient.value !== "" &&
          this.state.selDetailClient.value !== null
            ? Number(this.state.selDetailClient.value.id)
            : null,
        inchargeId: Number(this.state.user.id),
        lastModifiedByActorId: Number(this.state.user.id),
        //dateAdded: DateToday,
        //dateLastModified: DateToday,
        paidAmount: Number(this.state.txtAmountPaid.value),
        shippingAddress: this.state.txtShippingAddress.value,
        description: this.state.txtDesc.value,
        notes: this.state.txtNotes.value,
        invoiceTypeId: Number(this.state.selDetailType.value.id),
        invoiceStatusId: Number(this.state.selDetailStatus.value.id),
        deadline:
          this.state.txtDeadline.value !== ""
            ? this.state.txtDeadline.value
            : null,
      },
      details: this.state.termsTableData,
      content: _tempData,
    };

    // const items = _tempData.filter((x) => x.itemId !== null);
    // let itemProcess = [];
    // for (let i = 0; i < items.length; i++) {
    //   const _item = items[i];
    //   itemProcess.push({
    //     data: { id: Number(_item.itemId), qty: Number(_item.itemQty) },
    //   });
    // }
    // await inventoryApi.subtractItemQtyMany(
    //   this.state.dispatch,
    //   itemProcess,
    //   () => {}
    // );

    // console.log(itemProcess);

    const createdInvoice = await invoiceApi.createInvoice(
      this.props.dispatch,
      data
    );
    console.log("CREATED INVOICE ===> ", createdInvoice);

    const Invoices = await this.refreshInvoice(this.props.user.id);
    this.setState({
      invoiceTableData: Invoices,
      invoiceTableDataCopy: Invoices,
    });
  };

  updateInvoice = async () => {
    //console.log(this.state.invoiceContentTableData);

    let _tempData = [];
    for (let i = 0; i < this.state.invoiceContentTableDataCopy.length; i++) {
      const a = this.state.invoiceContentTableDataCopy[i];
      if (a.isNew) {
        _tempData.push({
          itemQty: a.itemQty,
          price: a.price,
          itemId: a.itemId,
          itemUnitId: a.itemUnitId,
          orderStatusId: a.orderStatusId,
          orderTypeId: a.orderTypeId,
          serviceName: a.serviceName,
          discount: a.discount,
          showImg: a.showImg,
          srp: a.srp,
          childId: a.childId,
          itemIndex: Number(a.itemIndex),
          placeholder: a.placeholder,
          priceModifier: a.priceModifier,
        });
      } else {
        _tempData.push({
          ...(typeof a.id === "number" ? { id: a.id } : null),
          itemQty: a.itemQty,
          price: a.price,
          itemId: a.itemId,
          itemUnitId: a.itemUnitId,
          orderStatusId: a.orderStatusId,
          orderTypeId: a.orderTypeId,
          serviceName: a.serviceName,
          discount: a.discount,
          showImg: a.showImg,
          srp: a.srp,
          childId: a.childId,
          itemIndex: Number(a.itemIndex),
          placeholder: a.placeholder,
          priceModifier: a.priceModifier,
        });
      }
    }

    //console.log(this.state.selectedRowInvoice);
    const data = {
      data: {
        id: Number(this.state.selectedRowInvoice.id),
        name: this.state.txtInvoiceName.value,
        isReservation: Number(this.state.chkIsReservation.value),
        //dateLastModified: DateToday,
        inchargeId: Number(this.state.selDetailIncharge.value.id),
        lastModifiedByActorId: Number(this.state.user.id),
        supplierId:
          this.state.selDetailSupplier.value !== "" &&
          this.state.selDetailSupplier.value !== null
            ? Number(this.state.selDetailSupplier.value.id)
            : null,
        clientId:
          this.state.selDetailClient.value !== "" &&
          this.state.selDetailClient.value !== null
            ? Number(this.state.selDetailClient.value.id)
            : null,
        shippingAddress: this.state.txtShippingAddress.value,
        description: this.state.txtDesc.value,
        notes: this.state.txtNotes.value,
        invoiceTypeId: Number(this.state.selDetailType.value.id),
        invoiceStatusId: Number(this.state.selDetailStatus.value.id),
        paidAmount: Number(this.state.txtAmountPaid.value),
        deadline:
          this.state.txtDeadline.value !== ""
            ? this.state.txtDeadline.value
            : "",
      },
      details: this.state.termsTableData,
      content: _tempData,
    };
    // console.log(_tempData);

    await invoiceApi.updateInvoice(this.props.dispatch, data);

    const Invoices = await this.refreshInvoice(this.props.user.id);

    this.setState({
      invoiceTableData: Invoices,
      invoiceTableDataCopy: Invoices,
    });
    return true;
  };

  deleteInvoice = async (id, isArchive, keepInv) => {
    // if (this.state.selectedRowInvoice.invoiceType.name === "Quote") {
    //   const items = this.state.selectedRowInvoice.content.filter(
    //     (x) => x.itemId !== null
    //   );
    //   for (let i = 0; i < items.length; i++) {
    //     const _item = items[i];
    //     await inventoryApi.addItemQty(
    //       this.state.dispatch,
    //       { id: Number(_item.itemId), qty: Number(_item.itemQty) },
    //       () => {}
    //     );
    //   }
    // }
    await invoiceApi.deleteInvoice(
      this.props.dispatch,
      id,
      null,
      isArchive,
      keepInv
    );

    this.clearInputs();
    const Invoices = await this.refreshInvoice(this.props.user.id);
    this.setState({
      invoiceTableData: Invoices,
      invoiceTableDataCopy: Invoices,
    });
  };

  handleActionChangeInvoiceDetails = async (e) => {
    //console.log(Number(e.target.value));
    this.setState(
      (x) => ({
        selectedInvoiceDetailType: {
          ...x.selectedInvoiceDetailType,
          value: e.target.value,
        },
        detModalSelType: {
          ...x.detModalSelType,
          value: Number(e.target.value),
        },
      }),
      async () => {
        const invoiceDetails = await invoiceApi.getInvoiceDetail(
          this.props.dispatch,
          Number(e.target.value)
        );
        this.setState((x) => ({
          invoiceTableData: invoiceDetails,
          invoiceTableDataCopy: invoiceDetails,
        }));
        // console.log(
        //   "INVOICE DETAILS =>",
        //   invoiceDetails,
        //   this.state.selectedInvoiceDetailType.value
        // );
        // console.log(this.state.detModalSelType);
      }
    );
  };

  populatePR = async (data, cb) => {
    const x = this.state.invoiceDetailsConst;
    //let _childrens = [];
    let showInvoiceFinish = true;
    let _showPendingApproval = false;
    for (let index = 0; index < x.length; index++) {
      const search = data.details.find((f) => f.type === x[index].value);

      if (search) {
        this.setState((a) => ({
          [`selDetail${x[index].name}`]: {
            ...a[`selDetail${x[index].name}`],
            value: search,
          },
        }));
      }
    }

    if (data.invoiceStatus.name === "Admin Denied") _showPendingApproval = true;

    const grandTotal = data.content
      .reduce((a, c) => {
        return a + c.price * c.qtyOrdered;
      }, 0)
      .toFixed(2);

    //Filter invoice transactions for payments
    let selectedInvoicePayments = [];
    let invoiceTotalAmountDue = 0;
    selectedInvoicePayments = data.paymentTransaction.filter(
      (x) => x.isEntry !== 1
    );

    const invoiceTotalPOPayment = selectedInvoicePayments
      .reduce((a, c) => {
        // if (c.childId == null) return a + c.price * c.itemQty;
        // else return a + c.child.price * c.child.itemQty;
        return a + c.amountValue;
      }, 0)
      .toFixed(2);

    invoiceTotalAmountDue = grandTotal - invoiceTotalPOPayment;

    if (invoiceTotalAmountDue <= 0) {
      invoiceTotalAmountDue = 0;
      showInvoiceFinish = true;
    } else {
      showInvoiceFinish = false;
    }
    const _srch = data.content.find((x) => x.orderStatus.name === "Processing");
    if (_srch) {
      showInvoiceFinish = false;
    }
    if (data.invoiceStatus.name === "Finished") showInvoiceFinish = false;

    this.setState(
      (a) => ({
        PRTableData: data.content,
        PRDataCopy: data.content,
        selectedPRID: Number(data.id),
        selDetailSupplier: { ...a.selDetailSupplier, value: data.supplier },
        txtInvoiceName: { ...a.txtInvoiceName, value: data.name },
        txtNotesPR: { ...a.txtNotesPR, value: data.notes },
        txtDateDeadlinePR: {
          ...a.txtDateDeadlinePR,
          value: data.deadline ? misc.parseAPIDate(data.deadline) : "",
        },
        txtDesc: { ...a.txtDesc, value: data.description },
        showPendingApproval: _showPendingApproval,
        showInvoiceFinish: showInvoiceFinish,
        grandTotal: grandTotal,

        txtLastModifiedByActor: {
          ...a.txtLastModifiedByActor,
          value:
            data.lastModifiedByActor !== null
              ? data.lastModifiedByActor.name
              : "-",
        },
        txtApprovedByActor: {
          ...a.txtApprovedByActor,
          alue: data.approvedBy !== null ? data.approvedBy.name : "-",
        },

        txtDateLastModifiedPR: {
          ...a.txtDateLastModifiedPR,
          value: misc.parseAPIDateTime(data.dateLastModified),
        },
        txtDateAddedPR: {
          ...a.txtDateAddedPR,
          value: misc.parseAPIDateTime(data.dateAdded),
        },
        txtDateFinishedPR: {
          ...a.txtDateFinishedPR,
          value: data.dateFinished ? misc.parseAPIDate(data.dateFinished) : "",
        },

        txtShippingAddress: {
          ...a.txtShippingAddress,
          value: data.shippingAddress,
        },
        selDetailStatus: {
          ...a.selDetailStatus,
          value: this.state.selDetailStatus.data.find(
            (x) => x.id === data.invoiceStatusId
          ),
        },
        selDetailType: { ...a.selDetailType, value: data.invoiceType },
        selDetailIncharge: {
          ...a.selDetailIncharge,
          value: this.state.selDetailIncharge.data.find(
            (x) => x.id === data.inchargeId
          ),
        },
        invoiceTotalAmountDue,
        selectedInvoicePayments,
      }),
      (x) => {
        console.log("SELECTED INVOICE PR ==>", data);
        if (cb) cb();
      }
    );
  };

  populateInvoiceModal = async (data, cb) => {
    const x = this.state.invoiceDetailsConst;
    let _childrens = [];
    let showInvoiceFinish = false;
    for (let index = 0; index < x.length; index++) {
      const search = data.details.find((f) => f.type === x[index].value);
      //console.log(data.details);
      if (search) {
        this.setState((a) => ({
          [`selDetail${x[index].name}`]: {
            ...a[`selDetail${x[index].name}`],
            value: search,
          },
        }));
      }
    }
    let _content = [];
    let _contentBackup = [];
    //console.log(data.content);
    for (let i = 0; i < data.content.length; i++) {
      const e = data.content[i];
      //console.log(e);
      if (e.item !== null) {
        if (e.item.uploads.length > 0) {
          let _imgChild = [];
          const _img = await inventoryApi.downloadItemImg(
            this.props.dispatch,
            e.item,
            1
          );
          if (e.childId !== null) {
            if (e.child.item.uploads.length > 0) {
              _imgChild = await inventoryApi.downloadItemImg(
                this.props.dispatch,
                e.child.item,
                1
              );
            }
          }
          if (_imgChild.length > 0) {
            _content.push({
              ...e,
              dispImg: _img[0],
              child: { ...e.child, dispImg: _imgChild[0] },
            });
          } else {
            _content.push({ ...e, dispImg: _img[0] });
          }
        } else {
          _content.push({ ...e });
        }
      } else {
        _content.push({ ...e });
      }
    }
    _contentBackup = _content;

    const CHANGE_ITEM_FILTER = (item) => {
      //console.log(item)
      if (item.orderStatus !== null) {
        if (item.orderStatus.name !== "Change Item") return true;
        else return false;
      } else {
        return true;
      }
    };

    _content = _content.filter(CHANGE_ITEM_FILTER);
    _content = _content.sort(function (a, b) {
      return a.itemIndex - b.itemIndex;
    });
    //_content = _content.filter((x) => x.orderStatus.name !== "Change Item");

    //Filter invoice transactions for payments
    let selectedInvoicePayments = [];
    selectedInvoicePayments = data.paymentTransaction.filter(
      (x) => x.isEntry !== 1
    );

    const checkPendingPayments = selectedInvoicePayments.find(
      (x) => x.transStatus !== "finished"
    );

    //let paymentData = [];
    let invoiceTotalAmountPaid = 0;
    let invoiceTotalAmountDue = 0;

    // paymentData =
    //   data.paidAmountHistory !== null ? JSON.parse(data.paidAmountHistory) : [];

    invoiceTotalAmountPaid = selectedInvoicePayments
      .reduce((a, c) => {
        // if (c.childId == null) return a + c.price * c.itemQty;
        // else return a + c.child.price * c.child.itemQty;
        return a + c.amountValue;
      }, 0)
      .toFixed(2);

    let _terms = [];
    for (let i = 0; i < data.details.length; i++) {
      const _e = data.details[i];
      if (_e.type === 6) {
        _terms.push(_e);
      }
    }
    const _grandTotal = _content
      .reduce((a, c) => {
        // if (c.childId == null) return a + c.price * c.itemQty;
        // else return a + c.child.price * c.child.itemQty;
        return a + c.price * c.itemQty;
      }, 0)
      .toFixed(2);
    invoiceTotalAmountDue = _grandTotal - invoiceTotalAmountPaid;
    
    if(invoiceTotalAmountDue <= 0){
      invoiceTotalAmountDue = 0
    }
    // if (data.invoiceType.name === "POS")
    //   invoiceTotalAmountDue = _grandTotal - invoiceTotalAmountPaid;

    const _grandTotalSRP = _content
      .reduce((a, c) => {
        // if (c.childId == null) return a + c.price * c.itemQty;
        // else return a + c.child.price * c.child.itemQty;
        return a + (c.srp + c.srp * (c.priceModifier / 100)) * c.itemQty;
      }, 0)
      .toFixed(2);
    let _showPendingApproval = false;
    let _showDR = false;
    if (data.invoiceStatus.name === "New") _showPendingApproval = true;
    if (data.invoiceStatus.name === "Admin Denied") _showPendingApproval = true;

    if (data.invoiceStatus.name === "Ongoing Delivery") _showDR = true;
    if (data.invoiceStatus.name === "For Delivery") _showDR = true;
    if (data.invoiceStatus.name === "Finished") _showDR = true;
    if (data.invoiceStatus.name === "Walk In") _showDR = true;
    if (data.invoiceStatus.name === "Archived") _showDR = true;

    if (data.children.length > 0) {
      _childrens = await invoiceApi.getOrderByParentId(
        this.props.dispatch,
        data.id
      );
    }
    const isInvoiceFinished = _childrens.filter(
      (x) => x.invoiceStatus.name !== "Finished"
    );

    // if (
    //   isInvoiceFinished.length === 0 &&
    //   data.invoiceStatus.name === "Ongoing Delivery"
    // ){
    //   showInvoiceFinish = true;
    // }
    // //No DR
    // if(_childrens.length === 0){
    //   showInvoiceFinish = false;
    // }

    if (data.invoiceType.name === "POS") showInvoiceFinish = false;

    if (
      data.invoiceStatus.name === "Pending Client Payment" ||
      data.invoiceStatus.name === "For Delivery" ||
      isInvoiceFinished.length > 0
    ) {
      showInvoiceFinish = false;
    }

    if (checkPendingPayments) {
      showInvoiceFinish = false;
    }

    //console.log(data)

    let filtered = null;
    let DRFilter = null;

    //only trigger this if selectedInvoice contains childrens
    if (this.state.selectedRowInvoice?.content && data.children.length > 0) {
      filtered = this.state.selectedRowInvoice.content.filter(
        (x) => x.itemId !== null && x.childId === null
      );

      // const childrens = await invoiceApi.getOrderByParentId(
      //   this.props.dispatch,
      //   Number(this.state.selectedRowInvoice.id)
      // );

      for (let i = 0; i < _childrens.length; i++) {
        const content = _childrens[i].content;

        for (let a = 0; a < content.length; a++) {
          const item = content[a];

          filtered = filtered.map((x) =>
            x.itemId === item.itemId
              ? { ...x, qtyDelivered: x.qtyDelivered + item.qtyDelivered }
              : x
          );
        }
      }
      DRFilter = filtered.filter((x) => x.itemQty > x.qtyDelivered);
    }

    //Check quote and only show set finish button if totalAmountDue is 0, has DR, all DR statuses are finished and all itemQtys are delivered.
    if (
      invoiceTotalAmountDue <= 0 &&
      isInvoiceFinished.length === 0 &&
      _childrens.length > 0 &&
      DRFilter.length === 0
    ) {
      showInvoiceFinish = true;
    }

    this.setState(
      (a) => ({
        showPendingApproval: _showPendingApproval,
        showDR: _showDR,
        showInvoiceFinish: showInvoiceFinish,
        txtQty: { ...a.txtQty, value: data.qty },
        txtNetPrice: { ...a.txtNetPrice, value: data.price },
        selDetailClient: { ...a.selDetailClient, value: data.client },
        selDetailSupplier: { ...a.selDetailSupplier, value: data.supplier },
        txtInvoiceName: { ...a.txtInvoiceName, value: data.name },
        grandTotal: _grandTotal,
        grandTotalSRP: _grandTotalSRP,
        txtDesc: { ...a.txtDesc, value: data.description },
        txtNotes: { ...a.txtNotes, value: data.notes },
        txtDateFinished: {
          ...a.txtDateFinished,
          value: data.dateFinished
            ? misc.parseAPIDateTime(data.dateFinished)
            : "",
        },
        txtLastModifiedByActor: {
          ...a.txtLastModifiedByActor,
          value:
            data.lastModifiedByActor !== null
              ? data.lastModifiedByActor.name
              : "-",
        },
        txtApprovedByActor: {
          ...a.txtApprovedByActor,
          value: data.approvedBy !== null ? data.approvedBy.name : "-",
        },
        txtDeadline: {
          ...a.txtDeadline,
          value: data.deadline ? misc.parseAPIDate(data.deadline) : "",
        },
        txtDateLastModified: {
          ...a.txtDateLastModified,
          value: data.dateLastModified
            ? misc.parseAPIDateTime(data.dateLastModified)
            : "",
        },
        txtDateAdded: {
          ...a.txtDateAdded,
          value: data.dateAdded ? misc.parseAPIDateTime(data.dateAdded) : "",
        },
        isInvoiceContentEdit: false,
        txtAmountPaid: { ...a.txtAmountPaid, value: data.paidAmount },
        txtInvoiceOrigTotalDue: {
          ...a.txtInvoiceOrigTotalDue,
          value: _grandTotal,
        },
        txtAmountDue: {
          ...a.txtAmountDue,
          value: (_grandTotal - data.paidAmount).toFixed(2),
        },
        txtShippingAddress: {
          ...a.txtInvoiceName,
          value: data.shippingAddress,
        },
        selDetailStatus: {
          ...a.selDetailStatus,
          value: this.state.selDetailStatus.data.find(
            (x) => x.id === data.invoiceStatusId
          ),
        },
        selDetailType: { ...a.selDetailType, value: data.invoiceType },
        selDetailIncharge: {
          ...a.selDetailIncharge,
          value: this.state.selDetailIncharge.data.find(
            (x) => x.id === data.inchargeId
          ),
        },
        invoiceContentTableData: _content,

        invoiceContentTableDataCopy: _contentBackup,
        termsTableData: _terms,
        listDRTableData: _childrens,
        listDRTableDataCopy: _childrens,
        invoiceTotalAmountDue: invoiceTotalAmountDue,
        selectedInvoicePayments: selectedInvoicePayments,

        chkIsReservation: {
          ...a.chkIsReservation,
          value: Boolean(data.isReservation),
        },
      }),
      (x) => {
        console.log("SELECTED INVOICE ==>", data);
        if (cb) cb();
      }
    );
  };

  populateTransferItem = async (data, cb) => {
    let _content = [];
    let showInvoiceFinish = false;
    let showPendingApproval = false;
    for (let i = 0; i < data.content.length; i++) {
      const _e = data.content[i];
      if (
        _e.item !== null &&
        _e.item.getImages !== null &&
        _e.item.getImages !== undefined
      ) {
        if (_e.item.getImages.length > 0) {
          const _img = await inventoryApi.downloadItemImg(
            this.props.dispatch,
            _e.item,
            1
          );
          _content.push({ ..._e, dispImg: _img[0], showImg: 1 });
        } else {
          _content.push({ ..._e });
        }
      } else {
        _content.push({ ..._e });
      }
    }

    if (data.invoiceStatus.name === "Ongoing Delivery")
      showInvoiceFinish = true;
    if (data.invoiceStatus.name === "New") showPendingApproval = true;
    //console.log(_content);
    this.setState(
      (a) => ({
        showPendingApproval: showPendingApproval,
        showInvoiceFinish: showInvoiceFinish,
        txtQty: { ...a.txtQty, value: data.qty },

        txtInvoiceName: { ...a.txtInvoiceName, value: data.name },

        txtDesc: { ...a.txtDesc, value: data.description },
        txtNotes: { ...a.txtNotes, value: data.notes },

        txtDateFinished: {
          ...a.txtDateFinished,
          value: data.dateFinished
            ? misc.parseAPIDateTime(data.dateFinished)
            : "",
        },
        txtLastModifiedByActor: {
          ...a.txtLastModifiedByActor,
          value:
            data.lastModifiedByActor !== null
              ? data.lastModifiedByActor.name
              : "-",
        },
        txtApprovedByActor: {
          ...a.txtApprovedByActor,
          alue: data.approvedBy !== null ? data.approvedBy.name : "-",
        },

        txtDeadline: {
          ...a.txtDeadline,
          value: data.deadline ? misc.parseAPIDate(data.deadline) : "",
        },

        selInventoryInvChange: {
          ...a.selInventoryInvChange,
          value: this.state.selInventoryInvChange.data.find(
            (x) => x.id === data.inventoryIdTo
          ),
        },
        selInventory: {
          ...a.selInventory,
          value: this.state.selInventory.data.find(
            (x) => x.id === data.inventoryIdFrom
          ),
        },

        txtDateLastModified: {
          ...a.txtDateLastModified,
          value: data.dateLastModified
            ? misc.parseAPIDateTime(data.dateLastModified)
            : "",
        },
        txtDateAdded: {
          ...a.txtDateAdded,
          value: data.dateAdded ? misc.parseAPIDateTime(data.dateAdded) : "",
        },
        isInvoiceContentEdit: false,

        selDetailStatus: {
          ...a.selDetailStatus,
          value: this.state.selDetailStatus.data.find(
            (x) => x.id === data.invoiceStatusId
          ),
        },
        selDetailType: { ...a.selDetailType, value: data.invoiceType },
        selDetailIncharge: {
          ...a.selDetailIncharge,
          value: this.state.selDetailIncharge.data.find(
            (x) => x.id === data.inchargeId
          ),
        },
        TransferInvTableData: _content,

        TransferInvDataCopy: _content,
      }),
      (x) => {
        console.log("SELECTED INVOICE TRANSFER ==>", data);
        if (cb) cb();
      }
    );
  };

  refreshInvoiceDetail = async (e, type) => {
    const detData = await invoiceApi.getInvoiceDetail(
      this.props.dispatch,
      Number(type)
    );

    this.setState((a) => ({
      [e]: { ...a[e], data: detData },
    }));
  };

  //File Handler
  handleFileUploadSubmit = async (e) => {
    e.preventDefault();
    let currentFiles = this.state.fileUpload.value;
    const fileType = currentFiles[0].type.split("/")[1];
    let formData = new FormData();
    formData.append("id", this.state.selectedRowInvoice.id);
    for (let index = 0; index < currentFiles.length; index++) {
      await formData.append(
        "uploadedFiles",
        currentFiles[index],
        `signature.${fileType}`
      );
    }
    //console.log(formData.values(), currentFiles);

    invoiceApi.addInvoiceImg(this.props.dispatch, formData, async (a) => {
      //refresh modal invoice
    });
  };
  handleFileUploadChange = (e) => {
    let currentFiles = e.target.files;

    this.setState((x) => ({
      fileUpload: {
        ...x.fileUpload,
        value: currentFiles,
      },
    }));
  };

  downloadFile = (e) => {
    const dat = { id: "", name: "" };
    invoiceApi.downloadInvoiceImg(this.props.dispatch, dat, async (a) => {
      const url = window.URL.createObjectURL(new Blob([a.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${e.complete}`);
      document.body.appendChild(link);
      link.click();
    });
  };

  deleteFile = async (filename, origName) => {
    this.props.dispatch({
      type: "MODAL_SHOW",
      payload: {
        title: "Confirmation",
        desc: `Are you sure you want to delete ${origName} ?`,
        isYesNo: true,
        cb: async (e) => {
          let dat = {
            uniqueId: this.state.actorSelection.uniqueId,
            filename: filename,
          };
          invoiceApi.deleteInvoiceImg(this.props.dispatch, dat, (x) => {
            //refresh modal invoice
          });
        },
      },
    });
  };

  toggleSelection = (key, shift, row) => {
    let invoiceTableSelection = this.state.invoiceTableSelection;
    const keyIndex = invoiceTableSelection.indexOf(key);
    if (keyIndex >= 0) {
      invoiceTableSelection = [];
      this.setState((x) => ({
        selectedRowInvoice: null,
      }));
    } else {
      invoiceTableSelection.length = 0;
      invoiceTableSelection.push(key);
      this.setState((x) => ({
        selectedRowInvoice: row,
      }));
    }
    this.setState((x) => ({
      invoiceTableSelection,
    }));
  };
  toggleSelectionTerms = (key, shift, row) => {
    if (this.state.selectedModalOperation.id === 1) {
      let termsTableSelection = this.state.termsTableSelection;
      const keyIndex = termsTableSelection.indexOf(key);
      if (keyIndex >= 0) {
        termsTableSelection = [];
        this.setState((x) => ({
          selectedRowTerms: null,
        }));
      } else {
        termsTableSelection.length = 0;
        termsTableSelection.push(key);
        this.setState((x) => ({
          selectedRowTerms: row,
        }));
      }
      this.setState((x) => ({
        termsTableSelection,
      }));
    }
  };

  toggleSelectionInvoiceContent = (key, shift, row) => {
    if (this.state.selectedModalOperation.id === 1) {
      let invoiceContentTableSelection =
        this.state.invoiceContentTableSelection;
      const keyIndex = invoiceContentTableSelection.indexOf(key);
      if (keyIndex >= 0) {
        invoiceContentTableSelection = [];
        this.setState((x) => ({
          selectedRowInvoiceSelection: null,
          selContentItems: {
            ...x.selContentItems,
            value: "",
          },
          selDetailServices: {
            ...x.selDetailServices,
            value: "",
          },
        }));
        //console.log("trigger");
      } else {
        invoiceContentTableSelection.length = 0;
        invoiceContentTableSelection.push(key);
        console.log("SELECTED INVOICE CONTENT ===> ", row);
        this.setState((x) => ({
          selectedRowInvoiceSelection: row,
        }));
      }
      this.setState((x) => ({
        invoiceContentTableSelection,
      }));
    }
  };
  toggleSelectionDRItems = (key, shift, row) => {
    let dRItemsTableSelection = this.state.dRItemsTableSelection;
    const keyIndex = dRItemsTableSelection.indexOf(key);
    if (keyIndex >= 0) {
      dRItemsTableSelection = [];
      this.setState((x) => ({
        selectedRowDRItems: null,
      }));
    } else {
      dRItemsTableSelection.length = 0;
      dRItemsTableSelection.push(+"-DRITEM");
      this.setState((x) => ({
        selectedRowDRItems: row,
      }));
    }
    this.setState((x) => ({
      dRItemsTableSelection,
    }));
  };
  toggleSelectionDRItemSelect = (key, shift, row) => {
    let dRItemSelectTableSelection = this.state.dRItemSelectTableSelection;
    const keyIndex = dRItemSelectTableSelection.indexOf(key);
    if (keyIndex >= 0) {
      dRItemSelectTableSelection = [];
      this.setState((x) => ({
        selectedRowDRItemSelect: null,
      }));
    } else {
      dRItemSelectTableSelection.length = 0;
      dRItemSelectTableSelection.push(key + "-DRSELECT");
      this.setState((x) => ({
        selectedRowDRItemSelect: row,
      }));
    }
    this.setState((x) => ({
      dRItemSelectTableSelection,
    }));
  };
  toggleSelectionListDR = (key, shift, row) => {
    let listDRTableSelection = this.state.listDRTableSelection;
    const keyIndex = listDRTableSelection.indexOf(key);
    if (keyIndex >= 0) {
      listDRTableSelection = [];
      this.setState((x) => ({
        selectedRowlistDR: null,
      }));
    } else {
      listDRTableSelection.length = 0;
      listDRTableSelection.push(key);
      this.setState((x) => ({
        selectedRowlistDR: row,
      }));
    }
    // const TYPE_DR = this.state.selDetailType.data.find(
    //   (x) => x.name === "Delivery Receipt"
    // );
    // if (TYPE_DR) {
    //   this.setState((x) => ({
    //     selDetailType: { ...x.selDetailType, value: TYPE_DR },
    //   }));
    // }
    //console.log(row);
    this.setState((x) => ({
      listDRTableSelection,
    }));
    console.log("SELECTED DR INVOICE ===> ", row);
    this.handleToggleInvoiceModal(null, () => {
      this.handleActionChange(
        { target: { name: "btnEditInvoice", isRedirect: true } },
        row
      );

      this.setState((x) => ({
        selectedRowInvoice: row,
        handleInvoiceModal: { ...x.handleInvoiceModal, isDR: true },
      }));
    });
  };
  toggleSelectionPR = (key, shift, row) => {
    let PRTableSelection = this.state.PRTableSelection;
    const keyIndex = PRTableSelection.indexOf(key);
    const _invoiceType = this.state.selDetailType.value.name;
    const _invoiceStatus = this.state.selDetailStatus.value.name;
    const _selectedOperation = this.state.selectedModalOperation.id;
    const _row = row;
    let allowEdit = true;
    //console.log(row);
    if (_invoiceType === "Purchase Request") {
      //console.log(_selectedOperation);
      _selectedOperation === 1 ? (allowEdit = true) : (allowEdit = false);
      _invoiceStatus === "New" ? (allowEdit = true) : (allowEdit = false);
    } else {
      //console.log(_selectedOperation);
      _selectedOperation === 1 ? (allowEdit = true) : (allowEdit = false);
      _invoiceStatus === "For Delivery"
        ? (allowEdit = true)
        : (allowEdit = false);
    }
    if (allowEdit) {
      if (keyIndex >= 0) {
        PRTableSelection = [];
        this.setState((x) => ({
          selectedRowPR: null,
        }));
      } else {
        PRTableSelection.length = 0;
        PRTableSelection.push(key);
        //console.log(row);
        this.setState((x) => ({
          selectedRowPR: _row,
        }));
      }
      this.setState((x) => ({
        PRTableSelection,
      }));
    }
  };
  toggleSelectionPRItems = (key, shift, row) => {
    let PRItemsTableSelection = this.state.PRItemsTableSelection;
    const keyIndex = PRItemsTableSelection.indexOf(key);
    if (keyIndex >= 0) {
      PRItemsTableSelection = [];
      this.setState((x) => ({
        selectedRowPRItems: null,
      }));
    } else {
      PRItemsTableSelection.length = 0;
      PRItemsTableSelection.push(key);
      this.setState((x) => ({
        selectedRowPRItems: row,
      }));
    }

    this.setState(
      (x) => ({
        PRItemsTableSelection,
      })
      // () => console.log("SELECTED REQUEST ITEM ===> ", _dat)
    );
  };
  toggleSelectionTransferInv = (key, shift, row) => {
    let TransferInvTableSelection = this.state.TransferInvTableSelection;
    const keyIndex = TransferInvTableSelection.indexOf(key);
    if (keyIndex >= 0) {
      TransferInvTableSelection = [];
      this.setState((x) => ({
        selectedRowTransferInv: null,
      }));
    } else {
      TransferInvTableSelection.length = 0;
      TransferInvTableSelection.push(key);
      this.setState((x) => ({
        selectedRowTransferInv: row,
      }));
    }

    this.setState(
      (x) => ({
        TransferInvTableSelection,
        txtQtyInvChange: { ...x.txtQtyInvChange, value: "" },
        selContentItems: { ...x.selContentItems, value: "" },
      })
      // () => console.log("SELECTED REQUEST ITEM ===> ", _dat)
    );
  };

  // isSelected = (key) => {
  //   return this.state.invoiceTableSelection.includes(`select-${key}`);
  // };

  // isSelectedInvoiceContent = (key) => {
  //   return this.state.invoiceContentTableSelection.includes(`select-${key}`);
  // };
  // isSelectedTerms = (key) => {
  //   return this.state.termsTableSelection.includes(`select-${key}`);
  // };
  // isSelectedDRItems = (key) => {
  //   return this.state.dRItemsTableSelection.includes(`select-${key}-DRITEM`);
  // };
  // isSelectedDRItemSelect = (key) => {
  //   return this.state.dRItemSelectTableSelection.includes(
  //     `select-${key}-DRSELECT`
  //   );
  // };
  // isSelectedListDR = (key) => {
  //   return this.state.listDRTableSelection.includes(`select-${key}`);
  // };

  rowFn = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      e.preventDefault();
      e.stopPropagation();

      if (
        this.state.invoiceTableSelection.length > 0 &&
        this.state.invoiceTableSelection[0] === `select-${rowInfo.original.id}`
      ) {
        this.setState((x) => ({
          invoiceTableSelection: [],
        }));
      } else {
        if (rowInfo) {
          this.toggleSelection(rowInfo.index, null, rowInfo.original);
          //console.log(this.state);

          this.handleActionChange(
            { target: { name: "btnEditInvoice" } },
            rowInfo.original
          );

          this.setState({
            invoiceTableSelection: ["select-" + rowInfo.original.id],
          });
        }
      }

      if (handleOriginal) {
        handleOriginal();
      }
    },
    style: {
      background:
        rowInfo &&
        this.state.invoiceTableSelection.includes(
          `select-${rowInfo.original.id}`
        ) &&
        "var(--REB-green)",
      color:
        rowInfo &&
        this.state.invoiceTableSelection.includes(
          `select-${rowInfo.original.id}`
        ) &&
        "var(--REB-white)",
    },
  });

  rowFnTerms = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      e.preventDefault();
      e.stopPropagation();
      if (this.state.selectedModalOperation.id === 1) {
        if (
          this.state.termsTableSelection.length > 0 &&
          this.state.termsTableSelection[0] === `select-${rowInfo.original.id}`
        ) {
          this.setState((x) => ({
            termsTableSelection: [],
          }));
        } else {
          if (rowInfo) {
            this.toggleSelectionTerms(rowInfo.index, null, rowInfo.original);
            //console.log(this.state);

            this.handleActionChange(
              { target: { name: "btnEditTerms" } },
              rowInfo.original
            );

            this.setState({
              termsTableSelection: ["select-" + rowInfo.original.id],
            });
          }
        }
      }
      if (handleOriginal) {
        handleOriginal();
      }
    },
    style: {
      background:
        rowInfo &&
        this.state.termsTableSelection.includes(
          `select-${rowInfo.original.id}`
        ) &&
        "var(--REB-green)",
      color:
        rowInfo &&
        this.state.termsTableSelection.includes(
          `select-${rowInfo.original.id}`
        ) &&
        "var(--REB-white)",
    },
  });

  rowFnInvoiceContent = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      e.preventDefault();
      e.stopPropagation();
      //console.log(rowInfo.original);
      if (this.state.selectedModalOperation.id === 1) {
        if (
          this.state.invoiceContentTableSelection.length > 0 &&
          this.state.invoiceContentTableSelection[0] ===
            `select-${rowInfo.original.id}`
        ) {
          this.setState((x) => ({
            invoiceContentTableSelection: [],
            selContentItems: {
              ...x.selContentItems,
              value: "",
            },
            selDetailServices: {
              ...x.selDetailServices,
              value: "",
            },
          }));
        } else {
          if (rowInfo) {
            this.toggleSelectionInvoiceContent(
              rowInfo.index,
              null,
              rowInfo.original
            );
            //console.log(this.state);

            this.handleActionChange(
              { target: { name: "btnEditInvoiceContent" } },
              rowInfo.original
            );
            //console.log('CONTENT" ', rowInfo.original);

            this.setState({
              invoiceContentTableSelection: ["select-" + rowInfo.original.id],
            });
          }
        }
      }

      if (handleOriginal) {
        handleOriginal();
      }
    },
    style: {
      background:
        rowInfo &&
        this.state.invoiceContentTableSelection.includes(
          `select-${rowInfo.original.id}`
        ) &&
        "var(--REB-green)",
      color:
        rowInfo &&
        this.state.invoiceContentTableSelection.includes(
          `select-${rowInfo.original.id}`
        ) &&
        "var(--REB-white)",
    },
  });
  rowFnDRItems = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      e.preventDefault();
      e.stopPropagation();

      if (
        this.state.selectedModalOperation.id === 1 ||
        this.state.selectedModalOperation.id === 0
      ) {
        if (rowInfo.original.itemQty !== rowInfo.original.qtyDelivered) {
          if (
            this.state.dRItemsTableSelection.length > 0 &&
            this.state.dRItemsTableSelection[0] ===
              `select-${rowInfo.original.id}-DRITEM`
          ) {
            this.setState((x) => ({
              dRItemsTableSelection: [],
            }));
          } else {
            if (rowInfo) {
              this.toggleSelectionDRItems(
                rowInfo.index,
                null,
                rowInfo.original
              );
              //console.log(this.state);

              this.handleActionChange(
                { target: { name: "SelectDRItemSelect" } },
                rowInfo.original
              );
              //console.log('CONTENT" ', rowInfo.original);

              this.setState({
                dRItemsTableSelection: [
                  "select-" + rowInfo.original.id + "-DRITEM",
                ],
              });
            }
          }
        } else {
          this.props.dispatch({
            type: "MODAL_SHOW",
            payload: {
              title: "Cannot Select",
              desc: `This item is fully delivered.`,
              isYesNo: false,
            },
          });
        }
      }

      if (handleOriginal) {
        handleOriginal();
      }
    },
    style: {
      background:
        rowInfo &&
        this.state.dRItemsTableSelection.includes(
          `select-${rowInfo.original.id}-DRITEM`
        ) &&
        "var(--REB-green)",
      color:
        rowInfo &&
        this.state.dRItemsTableSelection.includes(
          `select-${rowInfo.original.id}-DRITEM`
        ) &&
        "var(--REB-white)",
    },
  });
  rowFnDRItemSelect = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      e.preventDefault();
      e.stopPropagation();
      //console.log(rowInfo.original);
      if (
        this.state.selectedModalOperation.id === 1 ||
        this.state.selectedModalOperation.id === 0
      ) {
        if (
          this.state.dRItemSelectTableSelection.length > 0 &&
          this.state.dRItemSelectTableSelection[0] ===
            `select-${rowInfo.original.id}-DRSELECT`
        ) {
          this.setState((x) => ({
            dRItemSelectTableSelection: [],
          }));
        } else {
          if (rowInfo) {
            this.toggleSelectionDRItemSelect(
              rowInfo.index,
              null,
              rowInfo.original
            );
            //console.log(this.state);

            this.handleActionChange({ target: { name: "" } }, rowInfo.original);
            //console.log('CONTENT" ', rowInfo.original);

            this.setState({
              dRItemSelectTableSelection: [
                "select-" + rowInfo.original.id + "-DRSELECT",
              ],
            });
          }
        }
      }

      if (handleOriginal) {
        handleOriginal();
      }
    },
    style: {
      background:
        rowInfo &&
        this.state.dRItemSelectTableSelection.includes(
          `select-${rowInfo.original.id}-DRSELECT`
        ) &&
        "var(--REB-green)",
      color:
        rowInfo &&
        this.state.dRItemSelectTableSelection.includes(
          `select-${rowInfo.original.id}-DRSELECT`
        ) &&
        "var(--REB-white)",
    },
  });
  rowFnListDR = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      e.preventDefault();
      e.stopPropagation();
      //console.log(rowInfo.original);
      // if (this.state.selectedModalOperation.id === 1) {

      // }
      if (
        this.state.listDRTableSelection.length > 0 &&
        this.state.listDRTableSelection[0] === `select-${rowInfo.original.id}`
      ) {
        this.setState((x) => ({
          listDRTableSelection: [],
        }));
      } else {
        if (rowInfo) {
          this.toggleSelectionListDR(rowInfo.index, null, rowInfo.original);
        }
      }
      if (handleOriginal) {
        handleOriginal();
      }
    },
    style: {
      background:
        rowInfo &&
        this.state.listDRTableSelection.includes(
          `select-${rowInfo.original.id}`
        ) &&
        "var(--REB-green)",
      color:
        rowInfo &&
        this.state.listDRTableSelection.includes(
          `select-${rowInfo.original.id}`
        ) &&
        "var(--REB-white)",
    },
  });
  rowFnPR = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      e.preventDefault();
      e.stopPropagation();
      console.log("SELECTED PR CONTENT ===> ", rowInfo.original);
      //console.log(rowInfo.original);
      // if (this.state.selectedModalOperation.id === 1) {

      // }
      if (
        this.state.PRTableSelection.length > 0 &&
        this.state.PRTableSelection[0] === `select-${rowInfo.original.id}`
      ) {
        this.setState((x) => ({
          PRTableSelection: [],
          selectedRowPR: null,
        }));
      } else {
        if (rowInfo) {
          this.toggleSelectionPR(rowInfo.index, null, rowInfo.original);
          this.setState({
            PRTableSelection: ["select-" + rowInfo.original.id],
          });
        }
      }
      if (handleOriginal) {
        handleOriginal();
      }
    },

    // onClick: (e, handleOriginal) => {
    //   e.preventDefault();
    //   e.stopPropagation();
    //   console.log("SELECTED PR CONTENT ===> ", rowInfo.original);

    //   const _invoiceType = this.state.selDetailType.value.name;
    //   const _invoiceStatus = this.state.selDetailStatus.value.name;
    //   const _selectedOperation = this.state.selectedModalOperation.id;

    //   let allowEdit = true;

    //   if (_invoiceType === "Purchase Request") {
    //     //console.log(_selectedOperation);
    //     _selectedOperation === 1 ? (allowEdit = true) : (allowEdit = false);
    //     _invoiceStatus === "New" ? (allowEdit = true) : (allowEdit = false);
    //   } else {
    //     //console.log(_selectedOperation);
    //     _selectedOperation === 1 ? (allowEdit = true) : (allowEdit = false);
    //   }

    //   if (allowEdit) {
    //     if (
    //       this.state.PRTableSelection.length > 0 &&
    //       this.state.PRTableSelection[0] === `select-${rowInfo.original.id}`
    //     ) {
    //       this.setState((x) => ({
    //         PRTableSelection: [],
    //         selectedRowPR: null,
    //       }));
    //     } else {
    //       if (rowInfo) {
    //         this.toggleSelectionPR(rowInfo.index, null, rowInfo.original);
    //         //console.log(this.state);
    //         // this.handleActionChange(
    //         //   { target: { name: "btnEditPR" } },
    //         //   rowInfo.original
    //         // );
    //         //console.log('CONTENT" ', rowInfo.original);
    //         this.setState({
    //           PRTableSelection: ["select-" + rowInfo.original.id],
    //         });
    //       }
    //     }
    //   }

    //   if (handleOriginal) {
    //     handleOriginal();
    //   }
    // },
    style: {
      background:
        rowInfo &&
        this.state.PRTableSelection.includes(`select-${rowInfo.original.id}`) &&
        "var(--REB-green)",
      color:
        rowInfo &&
        this.state.PRTableSelection.includes(`select-${rowInfo.original.id}`) &&
        "var(--REB-white)",
    },
  });

  handleSearch = (event) => {
    if (event.target.value !== "") {
      this.handleActionChange(
        {
          target: {
            value: false,
            name: "chkOngoing",
          },
        },
        null,
        () => {}
      );
      this.handleActionChange(
        {
          target: {
            value: true,
            name: "chkAllTime",
          },
        },
        null,
        () => {
          this.handleActionChange(
            {
              target: {
                value: true,
                name: "applyFilter",
              },
            },
            null,
            () => {
              this.setState({ isFiltered: true });
              const data = filters.filterDataNested(
                this.state.invoiceTableDataCopy,
                event.target.value
              );

              if (data != null) {
                this.setState({ invoiceTableData: data });
              } else {
                this.setState({
                  invoiceTableData: this.state.invoiceTableDataCopy,
                });
              }
            }
          );
        }
      );
    } else {
      this.handleActionChange(
        {
          target: {
            value: false,
            name: "resetFilter",
          },
        },
        null,
        () => {
          this.handleActionChange(
            {
              target: {
                value: false,
                name: "applyFilter",
              },
            },
            null,
            () => {
              this.setState({
                invoiceTableData: this.state.invoiceTableDataCopy,
              });
              this.setState({ isFiltered: false });
            }
          );
        }
      );
    }
  };
  handleSearchPRReqItems = (event) => {
    if (event.target.value !== "") {
      //console.log(this.state.PRItemsDataCopy);
      // this.setState({ isFiltered: true });
      const data = filters.filterDataNested(
        this.state.PRItemsDataCopy,
        event.target.value
      );

      if (data != null) {
        this.setState({ PRItemsTableData: data });
      } else {
        this.setState({ PRItemsTableData: this.state.PRItemsDataCopy });
      }
    } else {
      this.setState({ PRItemsTableData: this.state.PRItemsDataCopy });
      //this.setState({ isFiltered: false });
    }
  };

  render() {
    const tablelist = (
      <>
        <style type="text/css">
          {`
      
      .tableInvoices{
        border-radius: 0px 0px 10px 10px !important;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        background-color: var(--REB-white) !important;
        height: 100%;
      }
      .rt-table{
        height: 100%;
        overflow: auto;
      }
      .rt-tbody{
        overflow: auto;
        overflow-y: scroll !important;
      }
      .rt-thead{
       padding-right: 17px;
        }
       .rt-th{
       
       
        border:none;
      }
      .rt-td{
        border:none;
      }
 

    
    `}
        </style>
        <Table
          data={this.state.invoiceTableData}
          keyField="id"
          // filterable
          defaultSorted={[
            // { id: "transPrio", desc: true },
            {
              id: "dateLastModified",
              desc: true,
            },
          ]}
          defaultFilterMethod={(filter, row, column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined
              ? String(row[id].toUpperCase()).includes(
                  filter.value.toUpperCase()
                )
              : true;
          }}
          columns={this.state.selectedColumn}
          toggleSelection={this.toggleSelection}
          // selectType="radio"
          showPageSizeOptions={false}
          isSelected={(key) =>
            this.state.invoiceTableSelection.includes(`select-${key}`)
          }
          getTrProps={this.rowFn}
          defaultPageSize={20}
          style={{ overflow: "auto", flex: 1 }}
          className="tableActor tableInvoices -highlight"
        />
      </>
    );
    const tableTerms = (
      <Table
        data={this.state.termsTableData}
        keyField="id"
        columns={termsColumn}
        toggleSelection={this.toggleSelectionTerms}
        // selectType="radio"
        showPageSizeOptions={false}
        showPageJump={false}
        showPaginationBottom={false}
        isSelected={(key) =>
          this.state.termsTableSelection.includes(`select-${key}`)
        }
        getTrProps={this.rowFnTerms}
        defaultPageSize={15}
        style={{
          overflow: "auto",
          flex: 1,
          backgroundColor: "var(--REB-bg-color)",
          borderRadius: "5px",
        }}
        className="tableActor -highlight mt-3"
      />
    );
    const tableInvoiceContent = (
      <>
        <Table
          data={this.state.invoiceContentTableData}
          keyField="id"
          columns={this.state.invoiceContentSelectedColumn}
          loading={this.state.isInvoiceContentEdit}
          toggleSelection={this.toggleSelectionInvoiceContent}
          //selectType="radio"
          isSelected={(key) =>
            this.state.invoiceContentTableSelection.includes(`select-${key}`)
          }
          getTrProps={this.rowFnInvoiceContent}
          defaultPageSize={30}
          showPageSizeOptions={false}
          style={{
            overflow: "auto",
            flex: 1,
            backgroundColor: "var(--REB-bg-color)",
            borderRadius: "5px",
          }}
          className="tableActor -highlight mt-3"
          disabled={true}
        />
      </>
    );
    const tableDRItems = (
      <>
        <style type="text/css">
          {`
  
  .rt-thead{
    padding-right: 0px;
    }
           
          `}
        </style>
        <Table
          data={this.state.dRItemsTableData}
          keyField="uniqueId"
          columns={drColumn()}
          loading={this.state.dRItemsTableLoading}
          toggleSelection={this.toggleSelectionDRItems}
          //selectType="radio"
          showPageSizeOptions={false}
          isSelected={(key) =>
            this.state.dRItemsTableSelection.includes(`select-${key}-DRITEM`)
          }
          getTrProps={this.rowFnDRItems}
          defaultPageSize={10}
          style={{
            overflow: "auto",
            flex: 1,
            backgroundColor: "var(--REB-bg-color)",
          }}
          className="tableActor -highlight mt-3"
        />
      </>
    );
    const tableDRItemSelect = (
      <>
        <style type="text/css">
          {`
  
            .rt-thead{
            padding-right: 0px;
            }
           
          `}
        </style>
        <Table
          data={this.state.dRItemSelectTableData}
          keyField="id"
          columns={selectedDrColumn()}
          loading={this.state.dRItemSelectTableLoading}
          toggleSelection={this.toggleSelectionDRItemSelect}
          //selectType="radio"
          showPageSizeOptions={false}
          isSelected={(key) =>
            this.state.dRItemSelectTableSelection.includes(
              `select-${key}-DRSELECT`
            )
          }
          getTrProps={this.rowFnDRItemSelect}
          defaultPageSize={10}
          style={{
            overflow: "auto",
            flex: 1,
            backgroundColor: "var(--REB-bg-color)",
          }}
          className="tableActor -highlight mt-3"
        />
      </>
    );
    const tableListDR = (
      <>
        <style type="text/css">
          {`
  
          .rt-thead{
          padding-right: 0px !important;
          }
         
        
           
          `}
        </style>
        <SelectTable
          data={this.state.listDRTableData}
          keyField="id"
          columns={deliveryListColumn}
          loading={this.state.listDRTableloading}
          toggleSelection={this.toggleSelectionListDR}
          selectType="radio"
          isSelected={(key) =>
            this.state.listDRTableSelection.includes(`select-${key}`)
          }
          getTrProps={this.rowFnListDR}
          defaultPageSize={5}
          style={{
            overflow: "auto",
            flex: 1,
            backgroundColor: "var(--REB-bg-color)",
          }}
          className="tableActor -highlight mt-3"
        />
      </>
    );
    const tablePR = (
      <Table
        data={this.state.PRTableData}
        keyField="id"
        columns={POColumn(null, null, this.state)}
        loading={this.state.PRTableloading}
        toggleSelection={this.toggleSelectionPR}
        // selectType="radio"
        isSelected={(key) =>
          this.state.PRTableSelection.includes(`select-${key}`)
        }
        getTrProps={this.rowFnPR}
        defaultPageSize={8}
        showPageSizeOptions={false}
        showPageJump={false}
        style={{
          overflow: "auto",
          flex: 1,
          backgroundColor: "var(--REB-bg-color)",
        }}
        className="tableActor -highlight "
      />
    );
    const tablePRItems = (
      <Table
        data={this.state.PRItemsTableData}
        keyField="id"
        showPageSizeOptions={false}
        showPageJump={false}
        columns={PRItemColumn()}
        defaultSorted={[
          {
            id: "reqDate",
            desc: true,
          },
        ]}
        loading={this.state.PRItemsTableloading}
        toggleSelection={this.toggleSelectionPRItems}
        //selectType="radio"
        isSelected={(key) =>
          this.state.PRItemsTableSelection.includes(`select-${key}`)
        }
        getTrProps={(state, rowInfo, column, instance) => ({
          onClick: async (e, handleOriginal) => {
            e.preventDefault();
            e.stopPropagation();

            if (
              this.state.PRItemsTableSelection.length > 0 &&
              this.state.PRItemsTableSelection[0] ===
                `select-${rowInfo.original.id}`
            ) {
              this.setState((x) => ({
                PRItemsTableSelection: [],
                txtSelectedItemPR: { ...x.txtSelectedItemPR, value: "" },
              }));
            } else {
              if (rowInfo) {
                this.toggleSelectionPRItems(
                  rowInfo.index,
                  null,
                  rowInfo.original
                );

                const COGS_DATA = await inventoryApi.getItemCogsSummary(
                  this.props.dispatch,
                  rowInfo.original.itemId
                );

                console.log("COGS DATA ===> ", COGS_DATA);

                const _dat = await invoiceApi.getOrderContent(
                  this.props.dispatch,
                  rowInfo.original.id
                );
                if (_dat) {
                  if (_dat.isLocked === 1) {
                    if (_dat.lockedByActor.id === this.state.user.id) {
                      this.setState((x) => ({
                        isSelectedContentLocked: false,
                      }));
                    } else {
                      this.setState((x) => ({
                        isSelectedContentLocked: true,
                      }));
                      this.props.dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "Selection Notice",
                          desc: `This item is curently modified by ${_dat.lockedByActor.name}`,
                          isYesNo: false,
                        },
                      });
                    }
                  } else {
                    this.setState((x) => ({
                      isSelectedContentLocked: false,
                    }));
                  }
                }
                this.setState((x) => ({
                  selectedRowDRItemSelect: _dat,
                  POCogsData: COGS_DATA,
                }));
                console.log("SELECTED REQUEST ITEM ===> ", _dat);
                this.handleActionChange(
                  { target: { name: "btnEditPRItems" } },
                  rowInfo.original
                );
                const d = rowInfo.original;
                let name = "-";
                if (d.item.details.length > 0) {
                  let nameDat = d.item.details.find((x) => x.type === 1);
                  let brandDat = d.item.details.find((x) => x.type === 3);
                  let desc =
                    d.item.description !== null ? d.item.description : "";
                  nameDat = nameDat !== null ? nameDat.name : "";
                  brandDat = brandDat !== null ? brandDat.name : "";
                  name = nameDat + " " + brandDat + " " + desc;
                }

                this.setState((x) => ({
                  PRItemsTableSelection: ["select-" + rowInfo.original.id],
                  txtSelectedItemPR: { ...x.txtSelectedItemPR, value: name },
                }));
              }
            }
            if (handleOriginal) {
              handleOriginal();
            }
          },
          style: {
            background:
              rowInfo &&
              this.state.PRItemsTableSelection.includes(
                `select-${rowInfo.original.id}`
              ) &&
              "var(--REB-green)",
            color:
              rowInfo &&
              this.state.PRItemsTableSelection.includes(
                `select-${rowInfo.original.id}`
              ) &&
              "var(--REB-white)",
          },
        })}
        defaultPageSize={8}
        style={{
          overflow: "auto",
          flex: 1,
          backgroundColor: "var(--REB-bg-color)",
        }}
        className="tableActor -highlight "
      />
    );

    const tableTransferInv = (
      <Table
        data={this.state.TransferInvTableData}
        keyField="id"
        showPageSizeOptions={false}
        showPageJump={false}
        columns={invTransColumn([], [])}
        loading={this.state.TransferInvTableloading}
        toggleSelection={this.toggleSelectionTransferInv}
        //selectType="radio"
        isSelected={(key) =>
          this.state.TransferInvTableSelection.includes(`select-${key}`)
        }
        getTrProps={(state, rowInfo, column, instance) => ({
          onClick: async (e, handleOriginal) => {
            e.preventDefault();
            e.stopPropagation();
            const _dat = rowInfo.original;
            if (this.state.selectedModalOperation.id === 1) {
              if (
                this.state.TransferInvTableSelection.length > 0 &&
                this.state.TransferInvTableSelection[0] ===
                  `select-${rowInfo.original.id}`
              ) {
                this.setState((x) => ({
                  TransferInvTableSelection: [],
                  txtSelectedItemPR: { ...x.txtSelectedItemPR, value: "" },
                }));
              } else {
                if (rowInfo) {
                  this.toggleSelectionTransferInv(
                    rowInfo.index,
                    null,
                    rowInfo.original
                  );

                  console.log("SELECTED CHNAGE INV ITEM ===> ", _dat);
                  this.handleActionChange(
                    { target: { name: "btnEditPRItems" } },
                    rowInfo.original
                  );

                  // let name = "-";
                  // if (d.item.details.length > 0) {
                  //   let nameDat = d.item.details.find((x) => x.type === 1);
                  //   let brandDat = d.item.details.find((x) => x.type === 3);
                  //   let desc =
                  //     d.item.description !== null ? d.item.description : "";
                  //   nameDat = nameDat !== null ? nameDat.name : "";
                  //   brandDat = brandDat !== null ? brandDat.name : "";
                  //   name = nameDat + " " + brandDat + " " + desc;
                  // }

                  this.setState((x) => ({
                    TransferInvTableSelection: [
                      "select-" + rowInfo.original.id,
                    ],
                    //txtSelectedItemPR: { ...x.txtSelectedItemPR, value: name },
                  }));
                }
              }
            }

            if (handleOriginal) {
              handleOriginal();
            }
          },
          style: {
            background:
              rowInfo &&
              this.state.TransferInvTableSelection.includes(
                `select-${rowInfo.original.id}`
              ) &&
              "var(--REB-green)",
            color:
              rowInfo &&
              this.state.TransferInvTableSelection.includes(
                `select-${rowInfo.original.id}`
              ) &&
              "var(--REB-white)",
          },
        })}
        defaultPageSize={8}
        style={{
          overflow: "auto",
          flex: 1,
          backgroundColor: "var(--REB-bg-color)",
        }}
        className="tableActor -highlight "
      />
    );

    const PRItemHistoryTable = (
      <Table
        data={this.state.POCogsData}
        columns={PRItemHistoryTableColumn()}
        selectType="radio"
        showPageSizeOptions={false}
        showPageJump={false}
        showPaginationBottom={false}
        defaultPageSize={10}
        style={{ overflow: "auto", height: "150px" }}
        className="tableActor -highlight mt-3"
      />
    );

    return (
      <Invoice
        handleActorChange={this.handleActorChange}
        handleSearch={this.handleSearch}
        inputChange={this.inputChange}
        state={this.state}
        handleShowModal={this.handleShowModal}
        handleHideModal={this.handleHideModal}
        onShow={this.state.isVisible}
        userType={this.props.userType}
        tablelist={tablelist}
        tableInvoiceContent={tableInvoiceContent}
        tableTerms={tableTerms}
        invoiceDetailsSelectRef={this.invoiceDetailsSelectRef}
        tableDRItems={tableDRItems}
        tableDRItemSelect={tableDRItemSelect}
        tableListDR={tableListDR}
        tablePR={tablePR}
        tablePRItems={tablePRItems}
        tableTransferInv={tableTransferInv}
        PRItemHistoryTable={PRItemHistoryTable}
        permissions={this.props.permissions}
      />
    );
  }
}
const mapStateToProps = (state) => {
  // console.log(state)
  const user = state.apiData.userData;
  let _permissions = [];
  if (user && user.permissions) {
    _permissions = JSON.parse(user.permissions);
  }
  return {
    user: state.apiData.userData,
    userType: state.apiData.userType,
    socketio: state.SocketHandler,
    permissions: _permissions.toString(),
  };
};
export default connect(mapStateToProps)(InvoiceProcess);
