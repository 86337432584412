import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Tooltip from "react-bootstrap/Tooltip";
import InputGroup from "react-bootstrap/InputGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { NumericFormat } from "react-number-format";
import DriverSelect from "../../components/CustomDrop";
import actorApi from "../Actor/ActorAPI";
import InventoryAPI from "../Inventory/InventoryAPI";
import LogisticsAPI from "./LogisticsAPI";
import CourierSelect from "../../components/CustomDrop";
import Badge from "react-bootstrap/Badge";
import moment from "moment";
import UnitSelect from "../../components/CustomDrop";
import emptyImg from "../../assets/fork-lift.svg";
import InvoiceAPI from "./InvoiceAPI";
import Logistics from "./PDF/Logistics";
//import DRStatusSelect from "../../components/CustomDrop";
import { ButtonGroup } from "react-bootstrap";
import Select from "react-select";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DR from "./PDF/DR";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import fileReg from "../../assets/file-regular.svg";
import { useDispatch, useSelector } from "react-redux";
import invoiceApi from "../Invoice/InvoiceAPI";
import SignatureCanvas from "react-signature-canvas";
import { SELECTED_OPERATION } from "../../constants";
import filters from "../../functions/filters";
const LogisticsContainer = (props) => {
  const { selectedRowInvoice, selectedInvoicePayments, settings } = props.state;

  const selectedInvoiceID = props.state.selectedRowInvoice
    ? Number(props.state.selectedRowInvoice.id)
    : null;
  let paymentData = [];

  if (selectedRowInvoice)
    paymentData =
      selectedInvoicePayments !== null ? selectedInvoicePayments : [];

  const dispatch = useDispatch();

  const DSelLogisticsStatus = [
    { value: "on-route", label: "On Route" },
    { value: "finished", label: "Finished" },
    { value: "pending-admin-approval", label: "Pending Admin Approval" },
    { value: "admin-denied", label: "Admin Denied" },
  ];

  const [invoiceStatus, setInvoiceStatus] = useState(null);
  const CURRENT_USERTYPE = useSelector((state) => state.apiData.userType);

  //const user = useSelector((state) => state.apiData.userData);
  const [POContentData, setPOContentData] = useState([]);
  const [LogisticsOperation, setLogisticsOperation] = useState(
    SELECTED_OPERATION.VIEW
  );
  const [txtAmount, setTxtAmount] = useState(0);
  const [txtDate, setTxtDate] = useState(null);
  const [txtType, setTxtType] = useState({ value: "cash", label: "Cash" });

  const [SelInvoiceBankAccount, setSelInvoiceBankAccount] = useState(null);
  const [SelInvoiceExpenseAccount, setSelInvoiceExpenseAccount] =
    useState(null);

  const [TxtContainerDesc, setTxtContainerDesc] = useState(null);
  const [TxtContainerSize, setTxtContainerSize] = useState(null);
  const [SelContainerUnit, setSelContainerUnit] = useState(null);
  const [SelContainerUnitData, setSelContainerUnitData] = useState([]);

  const [TxtCourierContactPerson, setTxtCourierContactPerson] = useState(null);
  const [TxtCourierContactPersonNo, setTxtCourierContactPersonNo] =
    useState(null);
  const [SelCourier, setSelCourier] = useState(null);
  const [SelCourierData, setSelCourierData] = useState([]);

  const [TxtSupplierContactPerson, setTxtSupplierContactPerson] =
    useState(null);
  const [TxtSupplierContactPersonNo, setTxtSupplierContactPersonNo] =
    useState(null);

  const [TxtPickupAddress, setTxtPickupAddress] = useState(null);
  const [TxtPickupDate, setTxtPickupDate] = useState(null);
  const [TxtETA, setTxtETA] = useState(null);
  const [TxtLogiNotes, setTxtLogiNotes] = useState(null);

  const [LogiTxtUniqueID, setLogiTxtUniqueID] = useState(null);
  const [LogiTxtDateAdded, setLogiTxtDateAdded] = useState(null);
  const [LogiTxtDateModified, setLogiTxtDateModified] = useState(null);
  const [LogiTxtLastModifiedBy, setLogiTxtLastModifiedBy] = useState(null);

  const [LogiTxtAddedBy, setLogiTxtAddedBy] = useState(null);

  const [SelInvoiceActsPayable, setSelInvoiceActsPayable] = useState(null);

  const [IsLogistics, setIsLogistics] = useState(false);
  const [SelectedLogistics, setSelectedLogistics] = useState(null);

  //console.log(props.state)

  useEffect(() => {
    if (props.state.selectedRowInvoice) {
      if (props.state.selectedRowInvoice.invoiceStatus) {
        if (props.state.selectedRowInvoice.invoiceStatus.name) {
          setInvoiceStatus(props.state.selectedRowInvoice.invoiceStatus.name);
        }
      }

      if (settings) {
        setSelInvoiceBankAccount(settings.POBankAccount);

        setSelInvoiceActsPayable(settings.POAcctsPayableAccount);
        setSelInvoiceExpenseAccount(settings.POExpenseAccount);
      }
    }
  }, [props.state.selectedRowInvoice]);

  useEffect(() => {
    //console.log("trigger rerender");
    getActors();
    getItemUnits();
  }, []);
  // useEffect(() => {
  //   if (POContentData.length > 0) {
  //     setLogisticsOperation(SELECTED_OPERATION.ADD);
  //   }
  //   if (POContentData.length === 0) {
  //     setLogisticsOperation(SELECTED_OPERATION.VIEW);
  //   }
  // }, [POContentData]);

  const getActors = async (e) => {
    const ACTORS = await actorApi.getAllActors(dispatch);

    const _clients = ACTORS.find((x) => x.name === "Payees");

    setSelCourierData(_clients.actors);
  };
  const getItemUnits = async () => {
    const detData = await InventoryAPI.getItemDetail(dispatch, 2);
    setSelContainerUnitData(detData);
  };

  const resetUserInputs = () => {
    setLogisticsOperation(SELECTED_OPERATION.VIEW);
    setTxtAmount(0);
    setTxtDate(null);
    setTxtType({ value: "cash", label: "Cash" });
    setPOContentData([]);
    setIsLogistics(false);
    setTxtCourierContactPerson("");
    setTxtCourierContactPersonNo("");
    setSelCourier("");
    setTxtSupplierContactPerson("");
    setTxtSupplierContactPersonNo("");
    setTxtPickupAddress("");
    setTxtETA("");
    setLogiTxtUniqueID("");
    setLogiTxtDateAdded("");
    setLogiTxtDateModified("");
    setLogiTxtLastModifiedBy("");
    setLogiTxtAddedBy("");
    setTxtLogiNotes("");

    setTxtContainerDesc("");
    setTxtContainerSize("");
    setSelContainerUnit("");
    setTxtPickupDate("");
    setSelectedLogistics(null);
  };

  const createLogistics = async (cb) => {
    let InventoryId = Number(selectedRowInvoice.inventoryId);
    const transUid = filters.uuidv4();
    // console.log(SelInventory);
    if (InventoryId === 1 || InventoryId === 0) {
      InventoryId = null;
    }

    let logisticsContent = [];

    selectedRowInvoice.content.forEach((cont) => {
      //console.log("CONTENT ===> ", cont);
      logisticsContent.push({
        uid: transUid,
        itemId: cont.itemId,
        itemUnitId: cont.itemUnitId,
        qty: cont.qtyDelivered,
        inventoryId: InventoryId,
        orderContentId: cont.id,
      });
    });

    const data = [
      {
        logistics: {
          inventoryId: InventoryId,
          uid: transUid,
          logiStatus: "pending-admin-approval",
          ...(TxtContainerDesc ? { containerDesc: TxtContainerDesc } : null),
          ...(TxtContainerSize ? { containerSize: TxtContainerSize } : null),
          ...(SelContainerUnit
            ? { containerUnitId: SelContainerUnit.id }
            : null),
          ...(TxtCourierContactPerson
            ? { courierContactPerson: TxtCourierContactPerson }
            : null),
          ...(TxtCourierContactPersonNo
            ? { courierContactNumber: TxtCourierContactPersonNo }
            : null),
          ...(SelCourier ? { courierId: SelCourier.id } : null),
          ...(selectedRowInvoice
            ? { supplierVendorId: selectedRowInvoice.supplierId }
            : null),
          ...(TxtSupplierContactPerson
            ? { supplierContactPerson: TxtSupplierContactPerson }
            : null),
          ...(TxtSupplierContactPersonNo
            ? { supplierContactNumber: TxtSupplierContactPersonNo }
            : null),
          ...(TxtPickupAddress ? { pickupAddress: TxtPickupAddress } : null),
          ...(TxtPickupDate ? { pickupDate: TxtPickupDate } : null),
          ...(TxtETA ? { eta: TxtETA } : null),
          ...(TxtLogiNotes ? { notes: TxtLogiNotes } : null),
          orderId: selectedRowInvoice.id,
        },
        content: logisticsContent,
      },
    ];
    console.log("PRE-POST DATA Logistics ===>", data);

    const createdInvoice = await LogisticsAPI.createMultiLogistics(
      dispatch,
      data,
      () => {
        if (cb) {
          // handleTransactionsReset();
          cb();
        }
        // refreshTransactions();
      },
      () => {
        dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Logistics Invoice Creation Error",
            desc: `Please Contact Administrator`,
            isYesNo: false,
          },
        });
      }
    );

    if (createdInvoice.data) {
      actorApi.sendEmail(
        dispatch,
        `DR Logistics - ${props.state.selectedRowInvoice?.name}`,
        props.state.selectedRowInvoice?.incharge?.lastName,
        props.state.selectedRowInvoice?.inventoryId
      );
      const orderContent = [];

      POContentData.forEach((cont) => {
        console.log("CONTENT ===> ", cont);
        orderContent.push({
          id: cont.id,
          qtyPendingDelivered: cont.itemQty + cont.qtyPendingDelivered,
        });
      });

      const data = { content: orderContent };
      await InvoiceAPI.updateInvoice(dispatch, data);

      console.log("CREATED LOGISTICS INVOICE ===> ", createdInvoice);
      resetUserInputs();
      const updatedInvoice = await InvoiceAPI.getInvoice(
        dispatch,
        selectedRowInvoice.id
      );
      props.state.handleActionChange({
        target: {
          value: updatedInvoice,
          name: "refreshAndReplaceSelected",
        },
      });
      dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Successfully added logistics invoice`,
        },
      });
    }
  };
  const modifyLogistics = async (cb) => {
    const data = [
      {
        logistics: {
          //inventoryId: InventoryId,
          //uid: transUid,
          logiStatus: "pending-admin-approval",
          id: Number(SelectedLogistics.id),
          ...(TxtContainerDesc ? { containerDesc: TxtContainerDesc } : null),
          ...(TxtContainerSize ? { containerSize: TxtContainerSize } : null),
          ...(SelContainerUnit
            ? { containerUnitId: SelContainerUnit.id }
            : null),
          ...(TxtCourierContactPerson
            ? { courierContactPerson: TxtCourierContactPerson }
            : null),
          ...(TxtCourierContactPersonNo
            ? { courierContactNumber: TxtCourierContactPersonNo }
            : null),
          ...(SelCourier ? { courierId: SelCourier.id } : null),
          ...(selectedRowInvoice
            ? { supplierVendorId: selectedRowInvoice.supplierId }
            : null),
          ...(TxtSupplierContactPerson
            ? { supplierContactPerson: TxtSupplierContactPerson }
            : null),
          ...(TxtSupplierContactPersonNo
            ? { supplierContactNumber: TxtSupplierContactPersonNo }
            : null),
          ...(TxtPickupAddress ? { pickupAddress: TxtPickupAddress } : null),
          ...(TxtPickupDate ? { pickupDate: TxtPickupDate } : null),
          ...(TxtETA ? { eta: TxtETA } : null),
          ...(TxtLogiNotes ? { notes: TxtLogiNotes } : null),
          //orderId: selectedRowInvoice.id,
        },
        //content: logisticsContent,
      },
    ];
    console.log("PRE-POST DATA Logistics Update ===>", data);

    const updatedInvoice = await LogisticsAPI.updateMultiLogistics(
      dispatch,
      data,
      () => {
        if (cb) {
          // handleTransactionsReset();
          cb();
        }
        // refreshTransactions();
      },
      () => {
        dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Logistics Invoice Modification Error",
            desc: `Please Contact Administrator`,
            isYesNo: false,
          },
        });
      }
    );
    console.log("MODIFIED LOGISTICS INVOICE ===> ", updatedInvoice);
    if (updatedInvoice.data) {
      resetUserInputs();
      const updatedInvoice = await InvoiceAPI.getInvoice(
        dispatch,
        selectedRowInvoice.id
      );
      props.state.handleActionChange({
        target: {
          value: updatedInvoice,
          name: "refreshAndReplaceSelected",
        },
      });
      dispatch({
        type: "TOAST_ADD",
        payload: {
          title: `Successfully modified logistics invoice`,
        },
      });
    }
  };

  const ReceiveLogistic = async (id) => {
    const data = [{ logistics: { id: id, logiStatus: "finished" } }];
    // await LogisticsAPI.receiveLogistics(dispatch, data);
    // //console.log("CREATED LOGISTICS INVOICE ===> ", createdInvoice);
    await LogisticsAPI.updateMultiLogistics(
      dispatch,
      data,
      () => {},
      () => {
        dispatch({
          type: "MODAL_SHOW",
          payload: {
            title: "Logistics Invoice Modification Error",
            desc: `Please Contact Administrator`,
            isYesNo: false,
          },
        });
      }
    );

    const updatedInvoice = await InvoiceAPI.getInvoice(
      dispatch,
      selectedRowInvoice.id
    );
    props.state.handleActionChange({
      target: {
        value: updatedInvoice,
        name: "refreshAndReplaceSelected",
      },
    });
    dispatch({
      type: "TOAST_ADD",
      payload: {
        title: `Successfully received Logistic`,
      },
    });
    resetUserInputs();
    setLogisticsOperation(SELECTED_OPERATION.VIEW);
  };

  const ResetQtyDelivered = async (id) => {
    for (const cont of selectedRowInvoice.content) {
      await InvoiceAPI.resetOrderContentQtyDelivered(dispatch, cont.id);
      await props.state.updateInvoiceContentStatus("Processing", cont.id);
      if (cont.histOrderId) {
        await props.state.updateInvoiceContentStatus(
          "Processing",
          cont.histOrderId
        );
      }
    }
    // selectedRowInvoice.content.forEach(async (cont) => {

    // });

    const updatedInvoice = await InvoiceAPI.getInvoice(
      dispatch,
      selectedRowInvoice.id
    );
    props.state.handleActionChange({
      target: {
        value: updatedInvoice,
        name: "refreshAndReplaceSelected",
      },
    });
    dispatch({
      type: "TOAST_ADD",
      payload: {
        title: `Successfully modified PO`,
      },
    });
  };

  const validateUserInputs = (cb) => {
    //let tempUserInput = { ...validateUser };
    let error = false;

    if (SelCourier === "" || SelCourier === null) error = true;
    if (TxtPickupAddress === null || TxtPickupAddress === "") error = true;

    // if (POContentData.length === 0) {
    //   error = true;
    // }
    // POContentData.forEach((cont) => {
    //   if (cont.qty === 0) {
    //     error = true;
    //   }
    // });
    // if (
    //   SelInvoiceInventoryAccount === null ||
    //   SelInvoiceInventoryAccount === ""
    // )
    //   error = true;

    if (error)
      dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Input Notice",
          desc: `Please check if courier, pickup address and contents are not empty or 0`,
          isYesNo: false,
        },
      });
    else {
      if (cb) cb();
    }

    // console.log(tempUserInput);
  };
  const TempModifyLogisContent = (value) => {
    setPOContentData((trans) =>
      trans?.map((dat) => (dat.id === value.id ? { ...dat, ...value } : dat))
    );
  };

  const PopulateLogisticsInvoice = async (d) => {
    const fetchData = await LogisticsAPI.getLogistics(dispatch, d.id);
    //console.log(fetchData);

    const editLastName = d.lastModifiedByActor?.lastName
      ? d.lastModifiedByActor?.lastName
      : "";
    const editFirstName = d.lastModifiedByActor?.firstName
      ? d.lastModifiedByActor?.firstName
      : "";
    const addFirstName = d.createdBy?.firstName ? d.createdBy?.firstName : "";
    const addLastName = d.createdBy?.lastName ? d.createdBy?.lastName : "";

    const tempContent = [];

    for (const cont of fetchData.content) {
      tempContent.push({ ...cont, itemQty: cont.qty });
    }
    //console.log(tempContent);
    setPOContentData(tempContent);

    setTxtCourierContactPerson(d.courierContactPerson);
    setTxtCourierContactPersonNo(d.courierContactNumber);
    setSelCourier(SelCourierData.find((x) => x.id === d.courierId));
    setTxtSupplierContactPerson(d.supplierContactPerson);
    setTxtSupplierContactPersonNo(d.supplierContactNumber);
    setTxtPickupAddress(d.pickupAddress);
    setTxtETA(d.eta ? moment(d.eta).format("YYYY-MM-DD") : "");
    setLogiTxtUniqueID(d.uniqueId);
    setLogiTxtDateAdded(d.datePosted ? moment(d.datePosted).format("LLL") : "");
    setLogiTxtDateModified(
      d.datePosted ? moment(d.datePosted).format("LLL") : ""
    );
    setLogiTxtLastModifiedBy(`${editLastName} ${editFirstName}`);
    setLogiTxtAddedBy(`${addLastName} ${addFirstName}`);
    setTxtLogiNotes(d.notes);

    setTxtContainerDesc(d.containerDesc);
    setTxtContainerSize(d.containerSize);
    setSelContainerUnit(
      SelContainerUnitData.find((x) => x.id === d.containerUnitId)
    );
    setTxtPickupDate(
      d.pickupDate ? moment(d.pickupDate).format("YYYY-MM-DD") : ""
    );
    setIsLogistics(true);
  };

  return (
    <div className="mb-3 pb-3" style={{ backgroundColor: "#e9ecef" }}>
      <style>
        {`
          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            opacity: 1;
          }
          .voucherContentHeaderP {
            text-align: center;
            color: var(--REB-secondary);
            font-weight: bolder;
          }
        `}
      </style>
      <div
        style={{
          backgroundColor: "var(--REB-primary)",
          borderRadius: "5px 5px 0px 0px",
          display: "flex",
        }}
        className="p-3"
      >
        <h4
          className="m-0"
          style={{
            flex: 1,
            fontWeight: "bolder",
            color: "var(--REB-white)",
          }}
        >
          <i
            className="icofont-money pr-1"
            style={{ flex: 1, fontSize: "1.5rem" }}
          ></i>
          DR Logistics Invoice
        </h4>
        <Button
          style={{
            backgroundColor: "var(--REB-yellow)",
            fontWeight: "bolder",
            color: "var(--REB-black)",
          }}
          onClick={() => {
            setLogisticsOperation(SELECTED_OPERATION.ADD);
          }}
        >
          Add Logistics Invoice
        </Button>
      </div>
      <div>
        <Row style={{ border: "solid 1px #ced4da" }} className="" noGutters>
          <Col xs={12} sm={6} md={3} lg={3}>
            <p
              className="m-0 p-0 voucherContentHeaderP"
              style={{ textAlign: "center" }}
            >
              Actions
            </p>
          </Col>
          <Col xs={12} sm={6} md={5} lg={5}>
            <p
              className="m-0 p-0 voucherContentHeaderP"
              style={{ textAlign: "center" }}
            >
              Logistics Invoice Description
            </p>
          </Col>

          <Col xs={12} sm={6} md={2} lg={2}>
            <p
              className="m-0 p-0 voucherContentHeaderP"
              style={{ textAlign: "center" }}
            >
              Status
            </p>
          </Col>
          <Col xs={12} sm={6} md={2} lg={2}>
            <p
              className="m-0 p-0 voucherContentHeaderP"
              style={{ textAlign: "center" }}
            >
              ETA
            </p>
          </Col>
        </Row>
      </div>
      {selectedRowInvoice?.logistics &&
        selectedRowInvoice?.logistics.map((logi, index) => {
          const d = logi;
          let status = "finished";
          let statusDisplay = "";
          let infoColor = "";
          let icon = <></>;

          if (d.logiStatus) {
            status = d.logiStatus;
          }

          if (status === "finished") {
            statusDisplay = "Finished";
            infoColor = "var(--REB-green)";
            icon = (
              <i
                className="icofont-ui-check "
                style={{ fontSize: "1.3em" }}
              ></i>
            );
          }
          if (status === "on-route") {
            statusDisplay = "On Route";
            infoColor = "var(--REB-secondary)";
            icon = (
              <i
                className="icofont-truck-loaded"
                style={{ fontSize: "1.3em" }}
              ></i>
            );
          }
          if (status === "admin-denied") {
            statusDisplay = "Admin Denied";
            infoColor = "var(--REB-warning)";
            icon = (
              <i
                className="icofont-ui-close "
                style={{ fontSize: "1.3em" }}
              ></i>
            );
          }
          if (status === "pending-admin-approval") {
            statusDisplay = "Pending Admin Approval";
            infoColor = "var(--REB-secondary)";
            icon = (
              <i className="icofont-warning " style={{ fontSize: "1.3em" }}></i>
            );
          }
          if (status === "processing") {
            statusDisplay = "Processing";
            infoColor = "var(--REB-green)";
            icon = (
              <i className="icofont-money" style={{ fontSize: "1.3em" }}></i>
            );
          }

          return (
            <Row
              style={{
                border: "solid 1px #ced4da",
                backgroundColor:
                  logi.id === SelectedLogistics?.id
                    ? "rgb(175, 217, 173)"
                    : "initial",
              }}
              className="py-2"
              key={logi.uniqueId}
              noGutters
            >
              <Col
                xs={12}
                sm={6}
                md={3}
                lg={3}
                className="px-2"
                style={{
                  alignSelf: "center",
                  textAlign: "center",
                }}
              >
                {/* <Button
                  onClick={async () => {
                    setLogisticsOperation(SELECTED_OPERATION.VIEW);
                    await LogisticsAPI.deleteLogistics(dispatch, logi.id);
                    const updatedInvoice = await InvoiceAPI.getInvoice(
                      dispatch,
                      selectedRowInvoice.id
                    );
                    props.state.handleActionChange({
                      target: {
                        value: updatedInvoice,
                        name: "refreshAndReplaceSelected",
                      },
                    });
                    dispatch({
                      type: "TOAST_ADD",
                      payload: {
                        title: `Successfully removed logistics invoice`,
                      },
                    });
                  }}
                  style={{
                    backgroundColor: "var(--REB-warning)",
                  }}
                  className="mx-1"
                >
                  <i
                    style={{ color: "var(--REB-white)" }}
                    className="icofont-trash"
                  ></i>
                </Button> */}

                <Button
                  onClick={() => {
                    setLogisticsOperation(SELECTED_OPERATION.VIEW);
                    PopulateLogisticsInvoice(logi);
                    setSelectedLogistics(logi);
                  }}
                  style={{
                    backgroundColor: "var(--REB-primary)",
                    fontWeight: "bolder",
                  }}
                  className="mx-1"
                >
                  {/* <i
                    style={{ color: "var(--REB-white)" }}
                    className="icofont-navigation-menu"
                  ></i> */}
                  View
                </Button>
                {/* {logi.logiStatus === "on-route" && (
                  <Button
                    onClick={() => {
                      ReceiveLogistic(logi.id);
                    }}
                    style={{
                      backgroundColor: "var(--REB-primary)",
                      fontWeight: "bolder",
                    }}
                    className="mx-1"
                  >
                    Receive
                  </Button>
                )} */}
              </Col>
              <Col xs={12} sm={6} md={5} lg={5}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                    fontSize: "0.8em",
                    height: "100%",
                    color: "var(--REB-blue)",
                    fontWeight: "bold",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <p className="m-0 p-0">Logistic Invoice # {index + 1}</p>
                </div>
              </Col>

              <Col style={{ textAlign: "center" }} xs={12} sm={6} md={2} lg={2}>
                <Badge
                  style={{
                    backgroundColor: infoColor,
                    color: "white",
                    fontSize: "0.8em",
                  }}
                >
                  {icon} {statusDisplay}
                </Badge>
              </Col>
              <Col style={{ textAlign: "center" }} xs={12} sm={6} md={2} lg={2}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                    fontSize: "0.8em",
                    height: "100%",
                    color: "var(--REB-blue)",
                    fontWeight: "bold",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  {logi.eta ? moment(logi.eta).format("LL") : ""}
                </div>
              </Col>
            </Row>
          );
        })}

      {(LogisticsOperation === SELECTED_OPERATION.ADD || SelectedLogistics) && (
        <>
          <div
            style={{
              backgroundColor: "var(--REB-primary)",
              borderRadius: "5px 5px 0px 0px",
              display: "flex",
            }}
            className="p-3"
          >
            <h4
              className="m-0"
              style={{
                flex: 1,
                fontWeight: "bolder",
                color: "var(--REB-white)",
              }}
            >
              <i
                className="icofont-money pr-1"
                style={{ flex: 1, fontSize: "1.5rem" }}
              ></i>
              Logistics Invoice
            </h4>
          </div>
          {(SelectedLogistics?.logiStatus === "on-route" ||
            SelectedLogistics?.logiStatus === "finished") && (
            <div className="p-3">
              <Button
                onClick={async () => {
                  const fetchData = await LogisticsAPI.getLogistics(
                    dispatch,
                    SelectedLogistics.id
                  );
                  console.log("SELECTED LOGISTICS ===>", SelectedLogistics);
                  console.log("FETCHED ORDER ===>", fetchData);

                  let perChunk = 8; // items per chunk

                  let result = fetchData.content.reduce(
                    (resultArray, item, index) => {
                      const chunkIndex = Math.floor(index / perChunk);
                      if (!resultArray[chunkIndex]) {
                        resultArray[chunkIndex] = []; // start a new chunk
                      }
                      resultArray[chunkIndex].push({
                        ...item,
                        index: index + 1,
                      });
                      return resultArray;
                    },
                    []
                  );
                  // For sort by index
                  // for (let index = 0; index < result.length; index++) {
                  //   const element = result[index];
                  //   result[index] = element.sort(function (a, b) {
                  //     return a.partIndex - b.partIndex;
                  //   });
                  // }
                  dispatch({
                    type: "PDF_MODAL_TOGGLE",
                    doc: Logistics.LogisticsPDF(
                      props.state,
                      fetchData,
                      result,
                      false
                    ),
                  });
                }}
                style={{
                  backgroundColor: "var(--REB-primary)",
                  fontWeight: "bolder",
                }}
                className="mx-4 p-3"
              >
                <i
                  style={{ color: "var(--REB-white)" }}
                  className="icofont-print"
                ></i>
              </Button>
              {SelectedLogistics?.logiStatus !== "finished" && (
                <Button
                  onClick={() => {
                    dispatch({
                      type: "MODAL_SHOW",
                      payload: {
                        title: "Confirmation",
                        desc: `Please make sure the items are delivered successfully`,
                        isYesNo: true,
                        cb: () => {
                          ReceiveLogistic(SelectedLogistics.id);
                        },
                      },
                    });
                  }}
                  style={{
                    backgroundColor: "var(--REB-green)",
                    fontWeight: "bolder",
                  }}
                  className="p-3"
                >
                  Receive Items
                </Button>
              )}
            </div>
          )}

          <>
            <Row className="mt-3" noGutters>
              <Col xs={12} sm={6} md={4} lg={3} className="px-1 mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: "var(--REB-white)",
                      color: "var(--REB-black)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                      WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                      MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                      boxShadow: "inset 0px 0px 0px 1px #ced4da",
                    }}
                  >
                    Unique ID
                  </p>
                  <FormControl
                    placeholder="Unique ID"
                    className="h-100"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    value={LogiTxtUniqueID}
                    disabled
                  />
                </div>
              </Col>
              <Col xs={12} sm={6} md={4} lg={3} className="px-1 mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: "var(--REB-white)",
                      color: "var(--REB-black)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                      WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                      MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                      boxShadow: "inset 0px 0px 0px 1px #ced4da",
                    }}
                  >
                    Date Added
                  </p>
                  <FormControl
                    placeholder="Date Added"
                    className="h-100"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    value={LogiTxtDateAdded}
                    disabled
                  />
                </div>
              </Col>
              <Col xs={12} sm={6} md={4} lg={3} className="px-1 mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: "var(--REB-white)",
                      color: "var(--REB-black)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                      WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                      MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                      boxShadow: "inset 0px 0px 0px 1px #ced4da",
                    }}
                  >
                    Date Modified
                  </p>
                  <FormControl
                    placeholder="Date Modified"
                    className="h-100"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    value={LogiTxtDateModified}
                    disabled
                  />
                </div>
              </Col>
            </Row>
            <Row noGutters>
              <Col xs={12} sm={6} md={6} lg={6} className="px-1 mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",

                    // border: "1px solid #ced4da",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: "var(--REB-white)",
                      color: "var(--REB-black)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                      WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                      MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                      boxShadow: "inset 0px 0px 0px 1px #ced4da",
                    }}
                  >
                    Added By
                  </p>
                  <FormControl
                    placeholder="Added By"
                    className="h-100"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    value={LogiTxtAddedBy}
                    disabled
                  />
                </div>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6} className="px-1 mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: "var(--REB-white)",
                      color: "var(--REB-black)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                      WebkitBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                      MozBoxShadow: "inset 0px 0px 0px 1px #ced4da",
                      boxShadow: "inset 0px 0px 0px 1px #ced4da",
                    }}
                  >
                    Modified By
                  </p>
                  <FormControl
                    placeholder="Modified By"
                    className="h-100"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    value={LogiTxtLastModifiedBy}
                    disabled
                  />
                </div>
              </Col>
            </Row>
            <Row noGutters>
              <Col xs={4} className="px-1 mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: SelCourier
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Courier
                  </p>
                  <CourierSelect
                    state={{ userType: CURRENT_USERTYPE }}
                    data={SelCourierData}
                    name="selActorCourier"
                    //isLoading={props.state.suppliers.isLoading}
                    selected={SelCourier}
                    //tabIndex={7}
                    lblHidden={true}
                    refreshData={(event) => {
                      getActors();
                    }}
                    detType={13}
                    dataStructure="invoiceActor"
                    addUrl="actor/"
                    editUrl="actor/updateActor"
                    deleteUrl="actor/deleteActor"
                    componentName="Courier"
                    componentType=""
                    optionLabel="company"
                    //optionLabel="name"
                    disabled={
                      LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                      LogisticsOperation !== SELECTED_OPERATION.ADD
                    }
                    handler={(data) => {
                      // console.log(data);
                      setSelCourier(data.target.value);
                    }}
                    // disabled={props.state.selDetailUnit.readOnly}
                  />
                </div>
              </Col>
              <Col xs={4} className="px-1 mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: true
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Courier Contact Person
                  </p>
                  <FormControl
                    type="text"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    placeholder="Courier Contact Person"
                    value={TxtCourierContactPerson}
                    onChange={(e) => {
                      setTxtCourierContactPerson(e.currentTarget.value);
                    }}
                    disabled={
                      LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                      LogisticsOperation !== SELECTED_OPERATION.ADD
                    }
                    className=""
                  />
                </div>
              </Col>
              <Col xs={4} className="px-1 mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: true
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Courier Contact Person #
                  </p>
                  <FormControl
                    type="text"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    placeholder=" Courier Contact Person #"
                    value={TxtCourierContactPersonNo}
                    disabled={
                      LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                      LogisticsOperation !== SELECTED_OPERATION.ADD
                    }
                    onChange={(e) => {
                      setTxtCourierContactPersonNo(e.currentTarget.value);
                    }}
                    className=""
                  />
                </div>
              </Col>
              <Col xs={4} className="px-1 mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: true
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Client Contact Person
                  </p>
                  <FormControl
                    type="text"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    placeholder="Client Contact Person"
                    value={TxtSupplierContactPerson}
                    disabled={
                      LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                      LogisticsOperation !== SELECTED_OPERATION.ADD
                    }
                    onChange={(e) => {
                      setTxtSupplierContactPerson(e.currentTarget.value);
                    }}
                    className=""
                  />
                </div>
              </Col>
              <Col xs={4} className="px-1 mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: true
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Client Contact Person #
                  </p>
                  <FormControl
                    type="text"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    placeholder="Client Contact Person #"
                    value={TxtSupplierContactPersonNo}
                    disabled={
                      LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                      LogisticsOperation !== SELECTED_OPERATION.ADD
                    }
                    onChange={(e) => {
                      setTxtSupplierContactPersonNo(e.currentTarget.value);
                    }}
                    className=""
                  />
                </div>
              </Col>
              <Col xs={4} className="px-1 mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: TxtPickupAddress
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Pickup Address
                  </p>
                  <FormControl
                    type="text"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    placeholder="Pickup Address"
                    value={TxtPickupAddress}
                    disabled={
                      LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                      LogisticsOperation !== SELECTED_OPERATION.ADD
                    }
                    onChange={(e) => {
                      setTxtPickupAddress(e.currentTarget.value);
                    }}
                    className=""
                  />
                </div>
              </Col>
              <Col xs={4} className="px-1  mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: true
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Estimated Arrival Date
                  </p>
                  <FormControl
                    type="date"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    placeholder="Estimated Delivery Date"
                    value={TxtETA}
                    disabled={
                      LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                      LogisticsOperation !== SELECTED_OPERATION.ADD
                    }
                    onChange={(e) => {
                      setTxtETA(e.currentTarget.value);
                    }}
                    className=""
                  />
                </div>
              </Col>
              <Col xs={4} className="px-1  mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: true
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Pickup Date
                  </p>
                  <FormControl
                    type="date"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    placeholder="  Pickup Date"
                    value={TxtPickupDate}
                    disabled={
                      LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                      LogisticsOperation !== SELECTED_OPERATION.ADD
                    }
                    onChange={(e) => {
                      setTxtPickupDate(e.currentTarget.value);
                    }}
                    className=""
                  />
                </div>
              </Col>
              <Col xs={4} className="px-1  mb-2"></Col>
              <Col xs={4} className="px-1  mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: true
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Container Description
                  </p>
                  <FormControl
                    type="text"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    placeholder=" Container Description"
                    value={TxtContainerDesc}
                    disabled={
                      LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                      LogisticsOperation !== SELECTED_OPERATION.ADD
                    }
                    onChange={(e) => {
                      setTxtContainerDesc(e.currentTarget.value);
                    }}
                    className=""
                  />
                </div>
              </Col>

              <Col xs={4} className="px-1  mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: true
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Container Size
                  </p>
                  <FormControl
                    as={NumericFormat}
                    inputMode="numeric"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    placeholder="Container Size"
                    thousandSeparator={true}
                    suffix={` ${
                      SelContainerUnit ? SelContainerUnit?.name : ""
                    }`}
                    allowNegative={false}
                    value={TxtContainerSize}
                    disabled={
                      LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                      LogisticsOperation !== SELECTED_OPERATION.ADD
                    }
                    onValueChange={(x) => {
                      setTxtContainerSize(Number(x.value));
                    }}
                  />
                </div>
              </Col>

              <Col xs={4} className="px-1  mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: true
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Container Unit
                  </p>
                  <UnitSelect
                    state={{ userType: CURRENT_USERTYPE }}
                    data={SelContainerUnitData}
                    name="selDetailUnit"
                    //isLoading={props.state.suppliers.isLoading}
                    selected={SelContainerUnit}
                    //tabIndex={7}
                    lblHidden={true}
                    refreshData={async (event) => {
                      getItemUnits();
                    }}
                    detType={2}
                    addUrl="inventory/createItem"
                    editUrl="inventory/updateItem"
                    deleteUrl="inventory/deleteItemDetail"
                    componentName="Unit"
                    dataStructure="itemDetailsInventory"
                    componentType=""
                    //optionLabel="name"
                    disabled={
                      LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                      LogisticsOperation !== SELECTED_OPERATION.ADD
                    }
                    handler={(data) => {
                      // console.log(data);
                      setSelContainerUnit(data.target.value);
                    }}
                    // disabled={props.state.selDetailUnit.readOnly}
                  />
                </div>
              </Col>
              <Col xs={6} className="px-1  mb-2">
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className="m-0 pl-2"
                    style={{
                      backgroundColor: true
                        ? "var(--REB-primary)"
                        : "var(--REB-warning)",
                      color: "var(--REB-white)",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      width: "230px",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    Logistics Notes
                  </p>
                  <FormControl
                    type="text"
                    style={{ borderRadius: "0px 5px 5px 5px" }}
                    //style={{ textLogiform: "uppercase" }}
                    placeholder="Logistics Notes"
                    value={TxtLogiNotes}
                    disabled={
                      LogisticsOperation !== SELECTED_OPERATION.EDIT &&
                      LogisticsOperation !== SELECTED_OPERATION.ADD
                    }
                    onChange={(e) => {
                      setTxtLogiNotes(e.currentTarget.value);
                    }}
                    className=""
                  />
                </div>
              </Col>
            </Row>
          </>

          <div>
            <Row style={{ border: "solid 1px #ced4da" }} className="" noGutters>
              <Col xs={12} sm={6} md={2} lg={2}>
                <p
                  className="m-0 p-0 voucherContentHeaderP"
                  style={{ textAlign: "center" }}
                >
                  Actions
                </p>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                <p
                  className="m-0 p-0 voucherContentHeaderP"
                  style={{ textAlign: "center" }}
                >
                  Item Name
                </p>
              </Col>

              <Col xs={12} sm={6} md={4} lg={4}>
                <p
                  className="m-0 p-0 voucherContentHeaderP"
                  style={{ textAlign: "center" }}
                >
                  Qty Expected
                </p>
              </Col>
            </Row>
          </div>
          {POContentData.map((val) => {
            const UNIT = val?.item?.details.find((x) => x.type === 2).name;
            const ITEM_NAME = val?.item?.details.find((x) => x.type === 1).name;
            const BRAND = val?.item?.details.find((x) => x.type === 3).name;
            let image = [];
            if (val.item?.uploads !== null && val.item?.uploads !== "") {
              if (val.item?.uploads?.constructor === String)
                image = JSON.parse(val.item?.uploads);
              else {
                image = val.item?.uploads;
              }
            }
            return (
              <>
                <Row
                  style={{ border: "solid 1px #ced4da" }}
                  className="py-2"
                  key={val.uniqueId}
                  noGutters
                >
                  <Col
                    xs={12}
                    sm={6}
                    md={2}
                    lg={2}
                    className="px-2"
                    style={{
                      alignSelf: "center",
                      textAlign: "center",
                    }}
                  >
                    {/* <Button
                      onClick={() => {
                        let tempData = [];
                        tempData = tempData.concat(POContentData);

                        const filtered = tempData.filter(
                          (x) => x.id !== val.id
                        );
                        setPOContentData([...filtered]);
                        if (filtered.length === 0) {
                          resetUserInputs();
                        }
                      }}
                      disabled={LogisticsOperation !== SELECTED_OPERATION.ADD}
                      style={{
                        backgroundColor: "var(--REB-warning)",
                      }}
                      className="mx-1"
                    >
                      <i
                        style={{ color: "var(--REB-white)" }}
                        className="icofont-trash"
                      ></i>
                    </Button> */}
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <Row noGutters>
                      <Col xs={12} sm={12} md={2} lg={2}>
                        <img
                          style={{
                            objectFit: "contain",
                            maxWidth: "100px",
                            height: "100px",
                            maxHeight: "100px",
                          }}
                          alt=""
                          src={
                            typeof image !== "undefined" &&
                            image !== null &&
                            image.length > 0 &&
                            image !== "null"
                              ? `${
                                  process.env.REACT_APP_API_URL
                                }global/getFile2?uniqueId=${
                                  val.item?.uniqueId
                                }&filename=${encodeURIComponent(
                                  image[0].filename
                                )}&token=${localStorage.getItem("LUT")}`
                              : emptyImg
                          }
                        />
                      </Col>
                      <Col xs={12} sm={12} md={10} lg={10}>
                        <p className="m-0 p-0 " style={{}}>
                          {ITEM_NAME}
                        </p>
                        <p className="m-0 p-0 " style={{}}>
                          {val?.item?.description}
                        </p>
                        <p className="m-0 p-0 " style={{}}>
                          {BRAND}
                        </p>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} sm={6} md={4} lg={4}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                        fontSize: "1.5em",
                        height: "100%",
                        color: "var(--REB-blue)",
                        fontWeight: "bold",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {/* <Button
                          onClick={() => {
                            if (Number(val.qtyDelivered) > 1) {
                              TempModifyLogisContent({
                                id: val.id,
                                qtyDelivered: Number(val.qtyDelivered) - 1,
                              });
                            }
                          }}
                          style={{
                            backgroundColor: "var(--REB-warning)",
                          }}
                          className="mx-1"
                        >
                          <i
                            style={{ color: "var(--REB-white)" }}
                            className="icofont-minus"
                          ></i>
                        </Button> */}
                        {/* <p style={{ marginBottom: "0px", flex: 1 }}>
                    {val?.qtyDelivered}
                  </p> */}
                        <FormControl
                          as={NumericFormat}
                          inputMode="numeric"
                          type="text"
                          placeholder="QTY Expected"
                          thousandSeparator={true}
                          suffix={` ${UNIT}`}
                          allowNegative={false}
                          value={val.itemQty}
                          style={{ textAlign: "center" }}
                          isAllowed={(values) => {
                            const { formattedValue, floatValue } = values;
                            const reqQty =
                              Number(val.qtyOrdered) -
                              (Number(val.qtyPendingDelivered) +
                                Number(val.qtyDelivered));
                            // console.log(
                            //   val.qtyOrdered,
                            //   val.qtyPendingDelivered,
                            //   val.qtyDelivered
                            // );
                            return (
                              formattedValue === "" ||
                              (floatValue <= reqQty && floatValue >= 0)
                            );
                          }}
                          onValueChange={(x) => {
                            TempModifyLogisContent({
                              id: val.id,
                              itemQty: Number(x.value),
                            });
                          }}
                          disabled={
                            LogisticsOperation !== SELECTED_OPERATION.ADD
                          }
                        />
                        {/* <Button
                          onClick={() => {
                            TempModifyLogisContent({
                              id: val.id,
                              qtyDelivered: Number(val.qtyDelivered) + 1,
                            });
                          }}
                          style={{
                            backgroundColor: "var(--REB-green)",
                          }}
                          className="mx-1"
                        >
                          <i
                            style={{ color: "var(--REB-white)" }}
                            className="icofont-ui-add"
                          ></i>
                        </Button> */}
                      </div>

                      <p
                        style={{
                          marginBottom: "0px",
                          fontWeight: "normal",
                          flex: 1,
                        }}
                      >
                        {UNIT}
                      </p>
                    </div>
                  </Col>
                </Row>
              </>
            );
          })}
          <>
            <Row className="my-3" noGutters>
              {(LogisticsOperation === SELECTED_OPERATION.ADD ||
                LogisticsOperation === SELECTED_OPERATION.EDIT) && (
                <Col xs={12} sm={12} md={4} lg={4} className="px-1">
                  <Button
                    style={{ backgroundColor: "var(--REB-green)" }}
                    onClick={() => {
                      //console.log(selectedRowInvoice);
                      LogisticsOperation === SELECTED_OPERATION.ADD &&
                        validateUserInputs(() => {
                          createLogistics();
                        });
                      LogisticsOperation === SELECTED_OPERATION.EDIT &&
                        validateUserInputs(() => {
                          modifyLogistics();
                        });
                    }}
                    block
                  >
                    Save Logistics
                  </Button>
                </Col>
              )}
              {LogisticsOperation === SELECTED_OPERATION.VIEW && (
                <Col xs={12} sm={12} md={4} lg={4} className="px-1">
                  <Button
                    style={{ backgroundColor: "var(--REB-green)" }}
                    onClick={() => {
                      if (
                        props.state.permissions.includes(
                          "perm-ui-invoicingDRAccess-view-write-manage"
                        )
                      ) {
                        setLogisticsOperation(SELECTED_OPERATION.EDIT);
                      } else {
                        props.state.dispatch({
                          type: "MODAL_SHOW",
                          payload: {
                            title: "No Permission",
                            desc: `You dont have access to modify DR`,
                            isYesNo: false,
                          },
                        });
                      }
                    }}
                    block
                  >
                    Modify
                  </Button>
                </Col>
              )}
              {LogisticsOperation === SELECTED_OPERATION.EDIT && (
                <Col xs={12} sm={12} md={4} lg={4} className="px-1">
                  <Button
                    style={{ backgroundColor: "var(--REB-warning)" }}
                    onClick={async () => {
                      dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "Confirmation",
                          desc: `Are you sure you want to remove this Logistics invoice? Doing so will reset the QTY and sets items status to processing`,
                          isYesNo: true,
                          cb: async () => {
                            await LogisticsAPI.deleteLogistics(
                              dispatch,
                              SelectedLogistics.id
                            );

                            const updatedInvoice = await InvoiceAPI.getInvoice(
                              dispatch,
                              selectedRowInvoice.id
                            );
                            // await props.state.updateInvoiceContentStatus(
                            //   "Processing",
                            //   selectedRowInvoice.id
                            // );
                            resetUserInputs();
                            props.state.handleActionChange({
                              target: {
                                value: updatedInvoice,
                                name: "refreshAndReplaceSelected",
                              },
                            });
                            dispatch({
                              type: "TOAST_ADD",
                              payload: {
                                title: `Successfully removed logistics invoice`,
                              },
                            });
                          },
                        },
                      });
                    }}
                    block
                  >
                    Delete
                  </Button>
                </Col>
              )}

              {(LogisticsOperation === SELECTED_OPERATION.ADD ||
                LogisticsOperation === SELECTED_OPERATION.EDIT ||
                LogisticsOperation === SELECTED_OPERATION.VIEW) && (
                <Col xs={12} sm={12} md={4} lg={4} className="px-1">
                  <Button
                    style={{
                      backgroundColor: "var(--REB-yellow)",
                      color: "var(--REB-black)",
                    }}
                    onClick={() => {
                      resetUserInputs();
                      setLogisticsOperation(SELECTED_OPERATION.VIEW);
                    }}
                    block
                  >
                    Cancel
                  </Button>
                </Col>
              )}
            </Row>
          </>
        </>
      )}
    </div>
  );
};
function TxtFileUpload(props) {
  const [btnSave, setBtnSave] = useState(true);
  const inputFile = useRef();
  const uploads =
    typeof props.state.selectedRowInvoice?.uploads === "string" &&
    props.state.selectedRowInvoice?.uploads
      ? JSON.parse(props.state.selectedRowInvoice?.uploads)
      : [];
  return (
    <>
      <InputGroup
        className="mb-3 p-3"
        style={{ backgroundColor: "var(--REB-white)" }}
      >
        <FormControl
          type="file"
          custom
          ref={inputFile}
          data-browse="Browse"
          className="inputFileUpload"
          name="fileUploadInvoice"
          // disabled={props.state.txtInvoiceNameDR.readOnly}
          label={"Test"}
          onClick={(e) => (e.target.value = null)}
          onChange={(e) => {
            props.state.handleActionChange({
              target: {
                name: "handleFileUploadChange",
                files: e.target.files,
              },
            });
            //console.log(Array.from(e.target.files));

            if (Array.from(e.target.files).length === 0) {
              setBtnSave(true);
            } else {
              setBtnSave(false);
            }
          }}
        />

        <ButtonGroup className="">
          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip id={`order-tooltip-add`}>Save</Tooltip>}
          >
            <Button
              variant="secondary"
              size="sm"
              style={{ backgroundColor: "var(--REB-green)" }}
              onClick={(e) => {
                // props.state.fileUpload.handleSubmit(e);
                e.preventDefault();

                props.state.handleActionChange(
                  {
                    target: {
                      name: "btnFileUploadSubmit",
                      value: e,
                    },
                  },
                  null,
                  () => {
                    inputFile.current.value = null;
                  }
                );
              }}
              disabled={btnSave}
            >
              Save
            </Button>
          </OverlayTrigger>
        </ButtonGroup>
      </InputGroup>
      <Row className="p-3">
        {props.state.selectedRowInvoice
          ? uploads.map((i, index) => (
              <Col sm={3} key={index}>
                <Card className="text-center" style={{ padding: "1px" }}>
                  <Card.Body>
                    <Row className="mb-3">
                      <Col>
                        <Image src={fileReg} style={{ width: "20%" }} />
                      </Col>
                    </Row>
                    <Row noGutters>
                      <Col className="">
                        <Button
                          size="md"
                          style={{
                            backgroundColor: "var(--REB-green)",
                          }}
                          onClick={(e) => {
                            props.state.handleActionChange({
                              target: {
                                name: "btnFileUploadDownload",
                                complete: i.originalname,
                                id: props.state.selectedRowInvoice.uniqueId,
                                filename: i.filename,
                              },
                            });
                          }}
                          name="btnDLLink"
                          key={index}
                        >
                          Download
                        </Button>
                      </Col>
                      <Col className="">
                        <Button
                          size="md"
                          style={{
                            backgroundColor: "var(--REB-green)",
                          }}
                          onClick={(e) => {
                            props.state.handleActionChange({
                              target: {
                                name: "btnFileUploadView",
                                filename: i.filename,
                                originalname: i.originalname,
                                id: props.state.selectedRowInvoice.uniqueId,
                              },
                            });
                          }}
                        >
                          View
                        </Button>
                      </Col>
                      <Col className="">
                        <Button
                          size="md"
                          name={`delete${i.filename}`}
                          value={i.filename}
                          disabled={props.state.txtInvoiceNameDR.readOnly}
                          style={{
                            backgroundColor: "var(--REB-warning)",
                          }}
                          onClick={(e) => {
                            props.state.handleActionChange({
                              target: {
                                name: "btnFileUploadDelete",
                                filename: i.filename,
                                originalname: i.originalname,
                              },
                            });
                          }}
                        >
                          Delete
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>{i.originalname}</Card.Footer>
                </Card>
              </Col>
            ))
          : ""}
      </Row>
    </>
  );
}
function TxtFileUploadReceiversImageContainer(props) {
  const [btnSave, setBtnSave] = useState(true);

  const {
    InputReceiversImage,
    setInputReceiversImage,
    ImgPreview,
    setImgPreview,
    receiversImg,
    ReceiverIsDisabled,
  } = props;
  //console.log(receiversImg);
  const inputFileReceiver = useRef();
  return (
    <>
      {InputReceiversImage && (
        <div
          style={{ display: "flex", justifyContent: "center" }}
          className="mb-3"
        >
          {receiversImg ? (
            <img
              style={{
                objectFit: "contain",

                height: "200px",
              }}
              alt="Receivers-Signature"
              src={`${process.env.REACT_APP_API_URL}global/getFile2?uniqueId=${
                props.state.selectedRowInvoice.uniqueId
              }&filename=${encodeURIComponent(
                InputReceiversImage.filename
              )}&token=${localStorage.getItem("LUT")}`}
            />
          ) : (
            ImgPreview && (
              <div
                style={{ display: "flex", justifyContent: "center" }}
                className="mb-3"
              >
                <Image
                  src={ImgPreview}
                  name="btnHome"
                  style={{
                    height: "200px",
                  }}
                  alt="receivers-image"
                />
              </div>
            )
          )}
        </div>
      )}
      {ImgPreview && (
        <div className="d-flex justify-content-center my-4">
          <Button
            style={{
              fontWeight: "bolder",
              backgroundColor: "var(--REB-warning)",
            }}
            className=""
            onClick={() => {
              setImgPreview(null);
              inputFileReceiver.current.value = null;
            }}
          >
            CLEAR IMAGE
          </Button>
        </div>
      )}
      <InputGroup
        className="mb-3 p-3"
        style={{
          backgroundColor: "var(--REB-white)",
          border: "1px solid #ced4da",
          borderRadius: "10px",
          alignItems: "center",
        }}
      >
        <FormControl
          type="file"
          custom
          ref={inputFileReceiver}
          data-browse="Browse"
          className="inputFileUpload"
          name="fileUploadInvoice"
          disabled={ReceiverIsDisabled}
          // label={"Test"}
          onClick={(e) => (e.target.value = null)}
          onChange={(e) => {
            setInputReceiversImage(e.target.files[0]);

            const reader = new FileReader();
            reader.onload = function (ev) {
              setImgPreview(ev.target.result);
              //console.log(ev.target.result);
            };
            reader.readAsDataURL(e.target.files[0]);

            // props.state.handleActionChange({
            //   target: {
            //     name: "handleFileUploadChange",
            //     files: e.target.files,
            //   },
            // });

            //console.log(Array.from(e.target.files));

            if (Array.from(e.target.files).length === 0) {
              setBtnSave(true);
            } else {
              setBtnSave(false);
            }
          }}
        />

        {/* <ButtonGroup className="">
          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip id={`order-tooltip-add`}>Attach Image</Tooltip>}
          >
            <Button
              variant="secondary"
              style={{
                backgroundColor: "var(--REB-green)",
                height: "55px",
                fontWeight: "bolder",
              }}
              onClick={(e) => {
                // props.state.fileUpload.handleSubmit(e);
                e.preventDefault();
                console.log(e);
                // props.state.handleActionChange(
                //   {
                //     target: {
                //       name: "btnFileUploadSubmit",
                //       value: e,
                //     },
                //   },
                //   null,
                //   () => {
                //     inputFileReceiver.current.value = null;
                //   }
                // );
              }}
              disabled={btnSave}
            >
              Attach Receivers Image
            </Button>
          </OverlayTrigger>
        </ButtonGroup> */}
      </InputGroup>
      {/* <Row className="p-3">
        {props.state.selectedRowInvoice
          ? props.state.selectedRowInvoice.uploads.map((i, index) => (
              <Col sm={3} key={index}>
                <Card className="text-center" style={{ padding: "1px" }}>
                  <Card.Body>
                    <Row className="mb-3">
                      <Col>
                        <Image src={fileReg} style={{ width: "20%" }}  />
                      </Col>
                    </Row>
                    <Row noGutters>
                      <Col className="">
                        <Button
                          size="md"
                          style={{
                            backgroundColor: "var(--REB-green)",
                          }}
                          onClick={(e) => {
                            props.state.handleActionChange({
                              target: {
                                name: "btnFileUploadDownload",
                                complete: i.originalname,
                                id: props.state.selectedRowInvoice.uniqueId,
                                filename: i.filename,
                              },
                            });
                          }}
                          name="btnDLLink"
                          key={index}
                        >
                          Download
                        </Button>
                      </Col>
                      <Col className="">
                        <Button
                          size="md"
                          style={{
                            backgroundColor: "var(--REB-green)",
                          }}
                          onClick={(e) => {
                            props.state.handleActionChange({
                              target: {
                                name: "btnFileUploadView",
                                filename: i.filename,
                                originalname: i.originalname,
                                id: props.state.selectedRowInvoice.uniqueId,
                              },
                            });
                          }}
                        >
                          View
                        </Button>
                      </Col>
                      <Col className="">
                        <Button
                          size="md"
                          name={`delete${i.filename}`}
                          value={i.filename}
                          // disabled={props.state.txtInvoiceNameDR.readOnly}
                          style={{
                            backgroundColor: "var(--REB-warning)",
                          }}
                          onClick={(e) => {
                            props.state.handleActionChange({
                              target: {
                                name: "btnFileUploadDelete",
                                filename: i.filename,
                                originalname: i.originalname,
                              },
                            });
                          }}
                        >
                          Delete
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>{i.originalname}</Card.Footer>
                </Card>
              </Col>
            ))
          : ""}
      </Row> */}
    </>
  );
}
let TxtShippingAddress = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      <span className="required-field">* </span>
      Shipping Address
    </Form.Label>
    <FormControl
      type="text"
      style={{ textTransform: "uppercase" }}
      placeholder="Shipping Address"
      name="txtShippingAddressDR"
      value={props.state.txtShippingAddressDR.value}
      onChange={props.state.inputChange}
      className={
        props.state.txtShippingAddressDR.className + " css-actorInput mb-2"
      }
      readOnly={props.state.txtShippingAddressDR.readOnly}
      required
    />
  </>
);

let TxtDRName = (props) => (
  <>
    <Form.Label
      className="font-weight-bold"
      style={{ color: "var(--REB-black)" }}
    >
      <span className="required-field">* </span>
      DR Name
    </Form.Label>
    <FormControl
      type="text"
      style={{ textTransform: "uppercase" }}
      placeholder="Shipping Address"
      name="txtInvoiceNameDR"
      value={props.state.txtInvoiceNameDR.value}
      onChange={props.state.inputChange}
      className={
        props.state.txtInvoiceNameDR.className + " css-actorInput mb-2"
      }
      readOnly={props.state.txtInvoiceNameDR.readOnly}
      required
    />
  </>
);

let TxtDateAddedDR = (props) => (
  <>
    <Form.Label>
      <strong>Date Added</strong>
    </Form.Label>
    <FormControl
      type="datetime-local"
      //placeholder={props.state.txtDeadline.placeholder}
      name="txtDateAddedDR"
      value={props.state.txtDateAddedDR.value}
      onChange={props.state.inputChange}
      className={props.state.txtDateAddedDR.className + " css-projectInput"}
      readOnly={true}
    />
  </>
);
let TxtDateLastModifiedDR = (props) => (
  <>
    <Form.Label>
      <strong>Date Last Modified</strong>
    </Form.Label>
    <FormControl
      type="datetime-local"
      //placeholder={props.state.txtDeadline.placeholder}
      name="txtDateLastModifiedDR"
      value={props.state.txtDateLastModifiedDR.value}
      onChange={props.state.inputChange}
      className={
        props.state.txtDateLastModifiedDR.className + " css-projectInput"
      }
      readOnly={true}
    />
  </>
);
let TxtDateDelivered = (props) => (
  <>
    <Form.Label>
      <strong>Date Delivered</strong>
    </Form.Label>
    <FormControl
      type="datetime-local"
      //placeholder={props.state.txtDeadline.placeholder}
      name="txtDateDelivered"
      value={props.state.txtDateDelivered.value}
      onChange={props.state.inputChange}
      className={props.state.txtDateDelivered.className + " css-projectInput"}
      readOnly={true}
    />
  </>
);
let TxtDateDeadline = (props) => (
  <>
    <Form.Label>
      <strong>Delivery Deadline</strong>
    </Form.Label>
    <FormControl
      type="datetime-local"
      //placeholder={props.state.txtDeadline.placeholder}
      name="txtDateDeadlineDR"
      value={props.state.txtDateDeadlineDR.value}
      onChange={props.state.inputChange}
      className={props.state.txtDateDeadlineDR.className + " css-projectInput"}
      readOnly={props.state.txtDateDeadlineDR.readOnly}
    />
  </>
);

function PDFGeneratePOHistory(props) {
  const [btn, setBtn] = useState(false);

  return (
    <InputGroup className="pdfOrder-select">
      {/* <InputGroup.Prepend>
        <InputGroup.Text className="">PDF</InputGroup.Text>
      </InputGroup.Prepend> */}

      {(() => {
        if (btn) {
          return (
            <PDFDownloadLink
              document={DR.DRPDF(
                props.state,
                props.state.drParent,
                false,
                props.state.selectedRowInvoice.uniqueId,
                props.state.selectedRowInvoice.invoiceStatus.name,
                props.state.selectedRowInvoice.address,
                props.state.selectedRowInvoice.incharge !== null
                  ? props.state.selectedRowInvoice.incharge
                  : null,
                props.state.selectedRowInvoice.approvedBy !== null
                  ? props.state.selectedRowInvoice.approvedBy
                  : null,
                props.state.selectedRowInvoice.isSignatureAvailable
              )}
            >
              {({ blob, url, loading, error }) => {
                if (loading) {
                  return "Loading";
                } else {
                  return (
                    <>
                      <Button
                        name="downloadPDF"
                        style={{ backgroundColor: "var(--REB-primary)" }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          props.state.dispatch({
                            type: "MODAL_SHOW",
                            payload: {
                              title: "Confirmation",
                              desc: `Are you sure you want to generate signed DR? This action will update the status of this invoice.`,
                              isYesNo: true,
                              cb: () => {
                                props.state.handleActionChange({
                                  target: {
                                    value: blob,
                                    name: "downloadPDF",
                                  },
                                });
                                setBtn(false);
                              },
                            },
                          });
                        }}
                      >
                        {" "}
                        Download Signed DR
                      </Button>
                    </>
                  );
                }
              }}
            </PDFDownloadLink>
          );
        } else {
          if (props.state.handleDRModal.isView) {
            return (
              <Button
                name="btnGeneratePDF"
                className=""
                style={{
                  backgroundColor: "var(--REB-primary)",
                  //fontSize: "0.8em",
                }}
                block
                onClick={(e) => {
                  props.state.handleActionChange(
                    {
                      target: {
                        name: "btnViewInvoicePDF",
                      },
                    },
                    null,

                    () => {
                      setBtn(true);
                    }
                  );
                }}
              >
                {" "}
                Generate Signed Invoice
              </Button>
            );
          } else {
            return null;
          }
        }
      })()}
    </InputGroup>
  );
}

const DRModal = (props) => {
  const SignatureInputRef = useRef();
  const dispatch = useDispatch();
  const [TxtReceiversName, setTxtReceiversName] = useState(null);
  const [TxtReceiversNameDisabled, setTxtReceiversNameDisabled] =
    useState(false);
  const [InputReceiversImage, setInputReceiversImage] = useState([]);
  const [ImgPreview, setImgPreview] = useState(null);
  const [SignatureImage, setSignatureImage] = useState(null);
  const [InvoiceStatus, setInvoiceStatus] = useState(null);

  const [ReceiverIsDisabled, setReceiverIsDisabled] = useState(true);

  const [SignatureOperation, setSignatureOperation] = useState(
    SELECTED_OPERATION.VIEW
  );

  const [DRUploads, setDRUploads] = useState([]);

  //console.log(props.state);
  //Listen to table selection
  useEffect(() => {
    // console.log(props.state);
    //console.log(props.state.selectedRowInvoice)
    if (
      props.state.selectedRowInvoice !== null &&
      props.state.selectedRowInvoice !== undefined &&
      props.state.selectedRowInvoice?.invoiceType?.name === "Delivery Receipt"
    ) {
      const { receiversName, uploads, invoiceStatus, receiversImg } =
        props.state.selectedRowInvoice;
      receiversName && setTxtReceiversName(receiversName);
      // console.log(uploads)
      // const parsedUploads = JSON.parse(uploads ? uploads : []);
      if (typeof uploads === "string") {
        const _DRUploads = JSON.parse(uploads);
        setDRUploads(_DRUploads);
        //console.log(_DRUploads)
        const _SigImage = _DRUploads.find(
          (x) => x.originalname === "signature.png"
        );
        //console.log(_SigImage)
        if (_SigImage) {
          setSignatureImage(_SigImage);
        }

        if (receiversImg) {
          //console.log(uploads.find((x) => x.originalname === receiversImg));
          setInputReceiversImage(
            _DRUploads.find((x) => x.originalname === receiversImg)
          );
        }
      }

      setInvoiceStatus(invoiceStatus.name);

      // if (
      //   invoiceStatus.name === "Ongoing Delivery" &&
      //   !receiversImg &&
      //   !receiversName
      // ) {
      //   // console.log("trigger top");
      //   // setReceiverIsDisabled(false);
      // }
    }
  }, [props.state.selectedRowInvoice]);

  //Listen to DR Modal
  useEffect(() => {
    const { isShow } = props.state.handleDRModal;
    if (!isShow) {
      setImgPreview(null);
    }
  }, [props.state.handleDRModal.isShow]);
  //Listen to DR Modal
  useEffect(() => {
    const { selectedOperation } = props.state;
    if (
      props.state.selectedRowInvoice !== null &&
      props.state.selectedRowInvoice !== undefined &&
      props.state.selectedRowInvoice?.invoiceType?.name === "Delivery Receipt"
    ) {
      const { receiversName, uploads, invoiceStatus, receiversImg } =
        props.state.selectedRowInvoice;

      //console.log(selectedOperation);
      if (selectedOperation === SELECTED_OPERATION.VIEW) {
        setReceiverIsDisabled(true);
      }
      if (
        invoiceStatus.name === "Ongoing Delivery" &&
        !receiversImg &&
        !receiversName
      ) {
        setReceiverIsDisabled(false);
      }

      if (selectedOperation === SELECTED_OPERATION.EDIT) {
        setReceiverIsDisabled(false);
      }
    }
  }, [props.state.selectedOperation]);

  const uploadReceiverInfo = async (cb) => {
    const formData = new window.FormData();
    formData.append("uniqueId", props.state.selectedRowInvoice.uniqueId);
    formData.append("id", props.state.selectedRowInvoice.id);
    formData.append("receiversName", TxtReceiversName);

    formData.append("receiversImg", InputReceiversImage.name);

    if (SignatureInputRef.current) {
      const canvas = SignatureInputRef.current.getCanvas();

      //wait for signature to convert to blob
      const myPromise = new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
          resolve(blob);
        });
      });
      //After conversion, append to form then post
      myPromise.then(async (blob) => {
        formData.append("signature", blob, "signature.png");
        formData.append("files", InputReceiversImage);
        const updatedDR = await invoiceApi.uploadDRReceiver(
          dispatch,
          formData,
          async () => {
            if (cb) cb();
          }
        );
        props.state.refreshSelectedDRContent(
          props.state.selectedRowInvoice.id,
          true
        );
        //console.log(updatedDR);
        //if (cb) cb();
      });
    } else {
      //UPLOAD WITHOUT SIGNATURE
      formData.append("files", InputReceiversImage);
      const updatedDR = await invoiceApi.uploadDRReceiver(
        dispatch,
        formData,
        async () => {
          if (cb) cb();
        }
      );
      props.state.refreshSelectedDRContent(
        props.state.selectedRowInvoice.id,
        true
      );
    }
  };
  return (
    <>
      <style>
        {`
        .DRModal {
          width: 100vw;
          max-width: 100vw;
          margin: 0px;
          height: 100vh;
        }
        .DRModalBody {
          width: 100vw;
          height: 100%;
          padding: 0px;
        }
        
        .modal {
          padding-left: 0px !important;
        }
        .modal-body {
          padding: 0px;
        }
        .modalDialogInvoice {
          height: 100vh;
        }
        .modal-content{
          height: 100%;
        }
        .DRModalFooter {
         
        }
        .DRModalHeader{
            
        }
        .DRModalForm{
         
        }
        

        @media (max-width: 768px) {
          .itemModalContentHeader {
            padding: 0px;
          }
         
          .DRModalBody {
           
          }
          .DRModalFooter {
            padding: 0px;
          }
          .tabletMarginTop {
            margin-top: 20px;
          }
          .DRModalHeader{
            
          }
          .DRModalFooterButtons{
            font-size: 0.75rem;
           }
           .DRModalFooter {
            padding-top: 5px;
            padding-bottom: 5px;
          }
        }
        @media (min-width: 769px) {
          .itemModalContentHeader {
            padding: 10px;
            padding-left: 20px;
          }
          .DRModalFooter {
            padding-top: 10px;
            padding-bottom: 10px;
          }
          .DRModalBody {
            
          }
          .DRModalContent{
            padding-left: 20px;
            padding-right: 20px;
          }
          .DRModalHeader{
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 10px;
            padding-right: 10px;
          }
          .itemModalCRUDButtons {
            padding-left: 30px !important;
          }
         
        }
      `}
      </style>
      <Modal
        show={props.state.handleDRModal.isShow}
        onHide={props.state.handleDRModal.toggle}
        onExit={(e) => {
          props.state.handleDRModal.handleExit(e);
        }}
        dialogClassName="DRModal"
        centered
      >
        <Modal.Body
          className="DRModalBody"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Row
            className="DRModalHeader"
            style={{ backgroundColor: "var(--REB-tertiary)" }}
          >
            <Col md={1} lg={1} sm={2} xs={2}>
              <OverlayTrigger
                placement={"right"}
                overlay={<Tooltip id={`project-tooltip-add`}>Close</Tooltip>}
              >
                <Button
                  variant="secondary"
                  name="btnCloseProjectModal"
                  style={{ backgroundColor: "var(--REB-primary)" }}
                  className="icofont-rounded-left"
                  onClick={(e) => {
                    props.state.handleDRModal.toggle();
                  }}
                />
              </OverlayTrigger>
            </Col>
            <Col
              md={3}
              lg={3}
              sm={10}
              xs={10}
              className=""
              style={{ alignSelf: "center" }}
            >
              <h4 className="m-0 " style={{ color: "var(--REB-white)" }}>
                {props.state.selectedOperation.name} Delivery Receipt
              </h4>
            </Col>
            {props.state.showDRConfirmation ? (
              <Col
                xs={6}
                sm={6}
                md={3}
                lg={3}
                className="px-2 my-1"
                // hidden={
                //   !props.state.handleInvoiceModal.isView ||
                //   !props.state.showPendingApproval
                // }
              >
                <OverlayTrigger
                  placement={"left"}
                  overlay={
                    <Tooltip id={`project-tooltip-add`}>
                      Set DR to DELIVERED
                    </Tooltip>
                  }
                >
                  <Button
                    block
                    name="btnSetDRDelivered"
                    style={{ backgroundColor: "var(--REB-green)" }}
                    onClick={(e) => {
                      if (
                        props.state.permissions.includes(
                          "perm-ui-invoicingDRAccess-view-write"
                        )
                      ) {
                        props.state.dispatch({
                          type: "MODAL_SHOW",
                          payload: {
                            title: "Confirmation",
                            desc: `Are you sure you want to set this DR status to finished? Once done only Administrators can modify this DR`,
                            isYesNo: true,
                            cb: () => {
                              props.state.handleActionChange(e);
                            },
                          },
                        });
                      } else {
                        props.state.dispatch({
                          type: "MODAL_SHOW",
                          payload: {
                            title: "No Permission",
                            desc: `You dont have access to modify DR`,
                            isYesNo: false,
                          },
                        });
                      }
                    }}
                  >
                    SET DELIVERED
                  </Button>
                </OverlayTrigger>
              </Col>
            ) : null}
            {props.state.showDROngoingDelivery ? (
              <Col
                xs={6}
                sm={6}
                md={3}
                lg={3}
                className="px-2"
                // hidden={
                //   !props.state.handleInvoiceModal.isView ||
                //   !props.state.showPendingApproval
                // }
              >
                <OverlayTrigger
                  placement={"left"}
                  overlay={
                    <Tooltip id={`project-tooltip-add`}>
                      Set DR to DELIVERED
                    </Tooltip>
                  }
                >
                  <Button
                    block
                    name="btnSetDROngoingDelivery"
                    style={{ backgroundColor: "var(--REB-green)" }}
                    onClick={(e) => {
                      props.state.dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "Confirmation",
                          desc: `Are you sure you want to set DR status to ONGOING DELIVERY?`,
                          isYesNo: true,
                          cb: () => {
                            props.state.handleActionChange(e);
                          },
                        },
                      });
                    }}
                  >
                    SET ONGOING DELIVERY
                  </Button>
                </OverlayTrigger>
              </Col>
            ) : null}
            {!props.state.handleDRModal.isView &&
            props.state.selDetailStatus.value.name === "Walk In" &&
            props.state.selDetailStatusDR.value.name !== "Finished" ? (
              <Col
                xs={6}
                sm={6}
                className="px-2"
                // hidden={
                //   !props.state.handleInvoiceModal.isView ||
                //   !props.state.showPendingApproval
                // }
              >
                <OverlayTrigger
                  placement={"left"}
                  overlay={
                    <Tooltip id={`project-tooltip-add`}>
                      Set DR to DELIVERED
                    </Tooltip>
                  }
                >
                  <Button
                    block
                    name="btnSetPickupClientDR"
                    style={{ backgroundColor: "var(--REB-green)" }}
                    onClick={(e) => {
                      props.state.dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "Confirmation",
                          desc: `This will automatically finish this DR. Please make sure to reviw all information before submitting.`,
                          isYesNo: true,
                          cb: () => {
                            props.state.handleActionChange(e);
                          },
                        },
                      });
                    }}
                  >
                    SET PICKED UP BY CLIENT
                  </Button>
                </OverlayTrigger>
              </Col>
            ) : null}
            <Col
              xs={6}
              sm={6}
              md={2}
              lg={2}
              className="px-2"
              style={{ alignSelf: "center" }}
            >
              <Button
                block
                name="btnViewInvoicePDF"
                className=""
                hidden={!props.state.handleDRModal.isView}
                style={{ backgroundColor: "var(--REB-green)" }}
                onClick={(e) => {
                  if (
                    props.state.permissions.includes("perm-ui-chkDRInvoice")
                  ) {
                    props.state.handleActionChange(e, (x) => {
                      // props.state.dispatch({
                      //   type: "PDF_MODAL_TOGGLE",
                      //   doc: QuotePDF.QuotePDF(props.state),
                      // });
                    });
                  } else {
                    props.state.dispatch({
                      type: "MODAL_SHOW",
                      payload: {
                        title: "No Permission",
                        desc: `You dont have access to view DR PDF`,
                        isYesNo: false,
                      },
                    });
                  }
                }}
              >
                VIEW PDF
              </Button>
            </Col>
            {/* {props.state.selectedRowInvoice !== null ? (
            props.state.selectedRowInvoice.isSignatureAvailable === 1 ? (
              <Col md={3} lg={3} className="user-col-cont-padding">
                <PDFGeneratePOHistory state={props.state} />
              </Col>
            ) : null
          ) : null} */}
          </Row>

          <div
            className="DRModalContent"
            style={{
              overflow: "scroll",
              overflowX: "hidden",
              flex: 1,
            }}
          >
            <Row noGutters>
              <Col className="pl-2 pr-2">
                <TxtDRName state={props.state} />
              </Col>
            </Row>
            <Row noGutters>
              <Col className="pl-2 pr-2">
                <DriverSelect
                  state={props.state}
                  data={props.state.selDetailDriver.data}
                  name="selDetailDriver"
                  containerClassname="mb-3"
                  //isLoading={props.state.suppliers.isLoading}
                  selected={props.state.selDetailDriver.value}
                  //tabIndex={7}
                  isDropHidden={true}
                  lblHidden={false}
                  addUrl="inventory/createItem"
                  editUrl="inventory/updateItem"
                  deleteUrl="inventory/deleteItemDetail"
                  componentName="Driver"
                  componentType=""
                  optionLabel="name"
                  handler={props.state.inputChange}
                  disabled={props.state.selDetailDriver.readOnly}
                />
              </Col>
              <Col className="pl-2 pr-2">
                <TxtShippingAddress state={props.state} />
              </Col>
            </Row>
            <Row className="mb-3" noGutters>
              <Col className="pl-2 pr-2">
                <TxtDateAddedDR state={props.state} />
              </Col>
              <Col className="pl-2 pr-2">
                <TxtDateLastModifiedDR state={props.state} />
              </Col>
              <Col className="pl-2 pr-2">
                <TxtDateDeadline state={props.state} />
              </Col>
              <Col className="pl-2 pr-2">
                <TxtDateDelivered state={props.state} />
              </Col>
            </Row>
            <Row className="mb-3" noGutters>
              <Col className="pl-2 pr-2">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      style={{
                        color: "var(--REB-white)",
                        fontWeight: "bold",
                        backgroundColor: "var(--REB-primary)",
                      }}
                    >
                      DR STATUS
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  {props.state.permissions.includes(
                    "perm-ui-invoicingDRAccess-view-write-manage"
                  ) ? (
                    <Select
                      styles={props.customStyles}
                      isSearchable
                      menuPortalTarget={document.body}
                      width="100%"
                      options={props.state.selDetailStatus.data}
                      value={props.state.selDetailStatusDR.value}
                      isDisabled={props.state.selDetailStatusDR.readOnly}
                      getOptionLabel={(x) => x.name}
                      getOptionValue={(x) => x.id}
                      //formatOptionLabel={"test"}
                      //innerRef={this.props.propRef}

                      onChange={(x, name) => {
                        //console.log(x, name);

                        props.state.handleActionChange({
                          target: {
                            value: x,
                            name: "handleDRStatusChange",
                          },
                        });
                      }}
                    />
                  ) : (
                    <FormControl
                      value={props.state.selDetailStatusDR.value?.name}
                      className={
                        props.state.txtDateDelivered.className +
                        " css-projectInput"
                      }
                      readOnly={true}
                    />
                  )}
                </InputGroup>

                {/* <DRStatusSelect
            state={props.state}
            data={props.state.selDetailStatus.data}
            name="selDetailStatusDR"
            containerClassname="mb-3"
            //isLoading={props.state.suppliers.isLoading}
            selected={props.state.selDetailStatusDR.value}
            //tabIndex={7}
            isDropHidden={true}
            lblHidden={false}
            addUrl="inventory/createItem"
            editUrl="inventory/updateItem"
            deleteUrl="inventory/deleteItemDetail"
            componentName="DR Status"
            componentType=""
            optionLabel="name"
            handler={props.state.inputChange}
            disabled={
              props.state.selDetailStatusDR.readOnly ||
              props.state.userType.includes("Sales Person")
            }
          /> */}
              </Col>
            </Row>
            {props.state.selDetailStatusDR.value.name === "Finished" ||
              (props.state.selDetailStatusDR.value.name ===
                "Ongoing Delivery" && (
                <LogisticsContainer state={props.state} />
              ))}
            <div className="my-3" style={{ backgroundColor: "#e9ecef" }}>
              <div
                style={{
                  backgroundColor: "var(--REB-primary)",
                  borderRadius: "5px 5px 0px 0px",
                }}
                className="p-3"
              >
                <h4
                  style={{ fontWeight: "bolder", color: "var(--REB-white)" }}
                  className="m-0"
                  hidden={props.state.userType.includes("Inventory Clerk")}
                >
                  Invoice Items
                </h4>
              </div>
              {props.state.dRItemsTableSelection.length > 0 ? (
                <Row className="my-3" noGutters>
                  <Col className="pl-2 pr-2">
                    <InputGroup>
                      <InputGroup.Prepend style={{}}>
                        <InputGroup.Text
                          style={{
                            color: "var(--REB-white)",
                            fontWeight: "bold",
                            backgroundColor: "var(--REB-primary)",
                          }}
                        >
                          QTY TO DELIVER
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      {/* {console.log(props.state.selectedRowDRItems)} */}
                      <FormControl
                        as={NumericFormat}
                        inputMode="numeric"
                        thousandSeparator={true}
                        suffix={` ${
                          props.state.selectedRowDRItems !== null
                            ? props.state.selectedRowDRItems.item !== null
                              ? props.state.selectedRowDRItems.item.details.find(
                                  (x) => x.type === 2
                                ).name
                              : ""
                            : ""
                        }`}
                        // suffix={` ${
                        //   props.state.selectedRowDRItems &&
                        //   props.state.selectedRowDRItems.item !== null &&
                        //   props.state.selectedRowDRItems.item.details.find(
                        //     (x) => x.type === 2
                        //   ).name
                        // }`}
                        style={{ flex: 1 }}
                        placeholder={props.state.txtQtyToDeliverDR.placeholder}
                        name="txtQtyToDeliverDR"
                        isAllowed={(values) => {
                          const { formattedValue, floatValue } = values;
                          return (
                            formattedValue === "" ||
                            floatValue <=
                              props.state.selectedRowDRItems.itemQty -
                                props.state.selectedRowDRItems.qtyDelivered
                          );
                        }}
                        value={props.state.txtQtyToDeliverDR.value}
                        onValueChange={(x) => {
                          //console.log(x.value);
                          props.state.inputChange({
                            target: {
                              value: Number(x.value),
                              name: "txtQtyToDeliverDR",
                            },
                          });
                        }}
                        className={props.state.txtQtyToDeliverDR.className}
                        readOnly={props.state.txtQtyToDeliverDR.readOnly}
                      />
                    </InputGroup>
                  </Col>
                  <Col className="pl-2 pr-2">
                    <ButtonGroup>
                      <OverlayTrigger
                        placement={"top"}
                        overlay={
                          <Tooltip id={`actor-tooltip-add`}>
                            Add this selected item to delivery receipt.
                          </Tooltip>
                        }
                      >
                        <Button
                          name="btnAddItemToDelivery"
                          //disabled={!props.state.handleInvoiceModal.isView}
                          style={{
                            backgroundColor: "var(--REB-green)",
                          }}
                          className="mr-3"
                          // hidden={

                          // }
                          onClick={(e) => {
                            if (props.state.txtQtyToDeliverDR.value === "") {
                              props.state.dispatch({
                                type: "MODAL_SHOW",
                                payload: {
                                  title: "QTY Empty",
                                  desc: `Please enter qty to deliver`,
                                  isYesNo: false,
                                },
                              });
                            } else {
                              props.state.handleActionChange({
                                target: { name: "btnAddItemToDelivery" },
                              });
                            }
                          }}
                        >
                          ADD SELECTED ITEM TO DELIVERY
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement={"top"}
                        overlay={
                          <Tooltip id={`actor-tooltip-add`}>
                            Cancel selection
                          </Tooltip>
                        }
                      >
                        <Button
                          name="btnAddItemToDeliveryCancel"
                          //disabled={!props.state.handleInvoiceModal.isView}
                          style={{
                            backgroundColor: "var(--REB-yellow)",
                            color: "var(--REB-black)",
                          }}
                          className="mr-3"
                          // hidden={

                          // }
                          onClick={(e) => {
                            props.state.handleActionChange({
                              target: { name: "btnAddItemToDeliveryCancel" },
                            });
                          }}
                        >
                          CANCEL
                        </Button>
                      </OverlayTrigger>
                    </ButtonGroup>
                  </Col>
                </Row>
              ) : null}
              {props.tableDRItems}
            </div>
            <div className="my-3" style={{ backgroundColor: "#e9ecef" }}>
              <div
                style={{
                  backgroundColor: "var(--REB-primary)",
                  borderRadius: "5px 5px 0px 0px",
                }}
                className="p-3"
              >
                <h4
                  style={{ fontWeight: "bolder", color: "var(--REB-white)" }}
                  className="m-0"
                  hidden={props.state.userType.includes("Inventory Clerk")}
                >
                  DR Contents
                </h4>
              </div>
              {props.tableDRItemSelect}
            </div>
            {props.state.selDetailStatusDR?.value?.name !==
              "Pending Admin Approval" && (
              <div style={{ backgroundColor: "#e9ecef" }}>
                <div
                  style={{
                    backgroundColor: "var(--REB-primary)",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                  className="p-3"
                >
                  <h4
                    style={{ fontWeight: "bolder", color: "var(--REB-white)" }}
                    className="m-0"
                    hidden={props.state.userType.includes("Inventory Clerk")}
                  >
                    Invoice Documents
                  </h4>
                </div>
                <Row noGutters className="px-3 mt-3">
                  <Col>
                    <TxtFileUpload state={props.state} />
                  </Col>
                </Row>
              </div>
            )}
            {props.state.selDetailStatusDR?.value?.name !==
              "Pending Admin Approval" &&
              InvoiceStatus === "Ongoing Delivery" && (
                <>
                  <div
                    className="mt-2"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <h5 style={{ flex: 1, textAlign: "center" }}>
                      Receivers Image
                    </h5>
                    <p style={{ textAlign: "center", flex: 1 }} className="m-0">
                      Please attach receivers image before attaching signature
                    </p>
                    {props.state.selectedRowInvoice?.receiversName && (
                      <p
                        style={{ textAlign: "center", flex: 1 }}
                        className="m-0"
                      >
                        Receivers Name:{" "}
                        {props.state.selectedRowInvoice?.receiversName
                          ? props.state.selectedRowInvoice?.receiversName
                          : "-"}
                      </p>
                    )}
                    <TxtFileUploadReceiversImageContainer
                      state={props.state}
                      InputReceiversImage={InputReceiversImage}
                      setInputReceiversImage={setInputReceiversImage}
                      ImgPreview={ImgPreview}
                      setImgPreview={setImgPreview}
                      ReceiverIsDisabled={ReceiverIsDisabled}
                      receiversImg={
                        props.state.selectedRowInvoice
                          ? props.state.selectedRowInvoice.receiversImg
                          : null
                      }
                    />
                  </div>

                  {ImgPreview && (
                    <>
                      <div
                        className="mt-2"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <h5 style={{ textAlign: "center", flex: 1 }}>
                          Receivers Signature
                        </h5>
                        {SignatureOperation === SELECTED_OPERATION.ADD && (
                          <p
                            style={{ textAlign: "center", flex: 1 }}
                            className="m-0"
                          >
                            Please sign in the box provided below
                          </p>
                        )}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          // height: "250px",
                          margin: "0 auto",
                          backgroundColor: "var(--REB-white)",
                          border: "1px solid black",
                        }}
                      >
                        {SignatureOperation === SELECTED_OPERATION.ADD && (
                          <SignatureCanvas
                            canvasProps={{
                              style: {
                                width: "100%",
                                height: "250px",
                              },
                              // width: "100",
                              // height: "100",
                            }}
                            // ref={(ref) => {
                            //   SignatureInputRef = ref;
                            // }}
                            ref={SignatureInputRef}
                          />
                        )}

                        {props.state.selectedRowInvoice && SignatureImage && (
                          <div
                            style={{
                              display: "flex",
                              height: "250px",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              style={{
                                objectFit: "contain",

                                height: "50%",
                              }}
                              alt="Receivers-Signature"
                              src={`${
                                process.env.REACT_APP_API_URL
                              }global/getFile2?uniqueId=${
                                props.state.selectedRowInvoice.uniqueId
                              }&filename=${encodeURIComponent(
                                SignatureImage.filename
                              )}&token=${localStorage.getItem("LUT")}`}
                            />
                          </div>
                        )}
                      </div>

                      {SignatureOperation === SELECTED_OPERATION.ADD && (
                        <p
                          style={{ textAlign: "center", flex: 1 }}
                          className="m-0"
                        >
                          I/We have received the delivered items stated in this
                          DR complete and without damage.
                        </p>
                      )}
                      <div
                        style={{ display: "flex", flexDirection: "column" }}
                        className="mt-2"
                      >
                        <Form.Label
                          className="font-weight-bold"
                          style={{
                            color: "var(--REB-black)",
                            flex: 1,
                            margin: 0,
                            alignSelf: "start",
                          }}
                        >
                          Receivers Full Name
                        </Form.Label>
                        <div style={{ display: "flex" }}>
                          <FormControl
                            type="text"
                            style={{
                              textTransform: "uppercase",
                              flex: 4,
                              height: "55px",
                            }}
                            placeholder="Enter Receivers Full Name Here"
                            value={TxtReceiversName}
                            onChange={(e) =>
                              setTxtReceiversName(e.target.value)
                            }
                            disabled={ReceiverIsDisabled}
                          />
                          {SignatureOperation === SELECTED_OPERATION.VIEW &&
                            SignatureImage === null && (
                              <Button
                                style={{
                                  fontWeight: "bolder",
                                  backgroundColor: "var(--REB-green)",
                                }}
                                className="ml-3"
                                disabled={!TxtReceiversName}
                                onClick={() => {
                                  setSignatureOperation(SELECTED_OPERATION.ADD);
                                  setTxtReceiversNameDisabled(true);
                                }}
                              >
                                ADD SIGNATURE
                              </Button>
                            )}
                          {SignatureOperation === SELECTED_OPERATION.ADD &&
                            SignatureImage === null && (
                              <Button
                                style={{
                                  fontWeight: "bolder",
                                  backgroundColor: "var(--REB-warning)",
                                }}
                                className="ml-3"
                                onClick={() => {
                                  setSignatureOperation(
                                    SELECTED_OPERATION.VIEW
                                  );
                                  setTxtReceiversNameDisabled(false);
                                  SignatureInputRef.current.clear();
                                  setTxtReceiversName("");
                                }}
                              >
                                CLEAR
                              </Button>
                            )}
                        </div>
                      </div>

                      <div
                        className="mt-2"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* <Button
                style={{
                  backgroundColor: "var(--REB-green)",
                  height: "55px",
                  fontWeight: "bolder",
                }}
                onClick={(e) => {
                  
                }}
              >
                Upload Receivers Info
              </Button> */}
                        {/* {ImgPreview && (
                <Button
                  style={{
                    backgroundColor: "var(--REB-warning)",
                    height: "55px",
                    fontWeight: "bolder",
                  }}
                  onClick={(e) => {
                    SignatureInputRef.current.clear();
                  }}
                >
                  Clear Signature
                </Button>
              )} */}
                        {props.state.selectedRowInvoice &&
                          !ReceiverIsDisabled && (
                            <Button
                              className=""
                              name="btnSetDRDelivered"
                              disabled={ReceiverIsDisabled}
                              style={{
                                backgroundColor: "var(--REB-green)",
                                height: "55px",
                                fontWeight: "bolder",
                              }}
                              onClick={(e) => {
                                let err = [];
                                if (
                                  TxtReceiversName === null ||
                                  TxtReceiversName === ""
                                ) {
                                  err.push({
                                    err: "TxtReceiversName",
                                    msg: "Receivers name cant be empty",
                                  });
                                }
                                if (SignatureInputRef.current)
                                  if (SignatureInputRef.current.isEmpty()) {
                                    err.push({
                                      err: "SignatureInput",
                                      msg: "Receivers signature cant be empty",
                                    });
                                  }
                                if (ImgPreview === null) {
                                  err.push({
                                    err: "Receiver Image",
                                    msg: "Receivers Image cant be empty",
                                  });
                                }
                                if (err.length > 0) {
                                  props.state.dispatch({
                                    type: "MODAL_SHOW",
                                    payload: {
                                      title: "Incomplete Receiver Info",
                                      JsxDesc: () => {
                                        return (
                                          <>
                                            {err.map((_dat) => {
                                              return (
                                                <p
                                                  key={filters.uuidv4()}
                                                  style={{
                                                    fontWeight: "bolder",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {_dat.msg}
                                                </p>
                                              );
                                            })}
                                          </>
                                        );
                                      },
                                      isYesNo: false,
                                      cb: () => {},
                                    },
                                  });
                                } else {
                                  props.state.dispatch({
                                    type: "MODAL_SHOW",
                                    payload: {
                                      title: "Delivery Confirmation",
                                      desc: `Please review all receivers details. Are you sure you want to submit?`,
                                      isYesNo: true,
                                      cb: () => {
                                        uploadReceiverInfo(() => {
                                          setReceiverIsDisabled(true);
                                        });
                                      },
                                    },
                                  });
                                }

                                // const _SignatureImage = SignatureInputRef.current
                                //   .getTrimmedCanvas()
                                //   .toDataURL("image/png");
                                // setSignatureImage(_SignatureImage);
                                // dispatch({
                                //   type: "FILE_VIEWER_MODAL_TOGGLE",
                                //   doc: SignatureImage,
                                // });
                              }}
                            >
                              Upload Receivers Information
                            </Button>
                          )}
                      </div>
                    </>
                  )}
                </>
              )}
          </div>

          <Form
            id="formDR"
            noValidate
            validated={props.state.handleDRModal.isValidated}
            onSubmit={props.state.handleSubmit}
            className="DRModalForm"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Row
              className="m-0 DRModalFooter"
              style={{ backgroundColor: "var(--REB-secondary)" }}
              noGutters
            >
              <Col></Col>
              <Col
                md={2}
                lg={2}
                sm={4}
                xs={4}
                className="px-2 DRModalFooterButtons"
              >
                <Button
                  block
                  className=""
                  name="btnEditDR"
                  style={{ backgroundColor: "var(--REB-green)" }}
                  disabled={!props.state.handleDRModal.isView}
                  onClick={(e) => {
                    if (
                      props.state.permissions.includes(
                        "perm-ui-invoicingDRAccess-view-write-manage"
                      )
                    ) {
                      props.state.handleActionChange(e);
                    } else {
                      props.state.dispatch({
                        type: "MODAL_SHOW",
                        payload: {
                          title: "No Permission",
                          desc: `You dont have access to modify DR`,
                          isYesNo: false,
                        },
                      });
                    }
                  }}
                >
                  MODIFY
                </Button>
              </Col>
              <Col
                md={2}
                lg={2}
                sm={4}
                xs={4}
                className="px-2 DRModalFooterButtons"
              >
                <Button
                  block
                  className=""
                  style={{ backgroundColor: "var(--REB-green)" }}
                  name="btnSubmitDR"
                  disabled={props.state.handleDRModal.isView}
                  type="submit"
                  // onClick={(e) => {
                  //   props.state.handleActionChange(e);
                  // }}
                >
                  SAVE
                </Button>
              </Col>
              <Col
                md={2}
                lg={2}
                sm={4}
                xs={4}
                className="px-2 DRModalFooterButtons"
              >
                <Button
                  block
                  className=""
                  name="btnDeleteDR"
                  style={{ backgroundColor: "var(--REB-warning)" }}
                  disabled={
                    props.state.handleDRModal.isView ||
                    Number(props.state.selectedOperation.id) === 0
                  }
                  onClick={(e) => {
                    props.state.handleActionChange(e);
                  }}
                >
                  DELETE
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export { DRModal };
