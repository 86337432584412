import React from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
//import InputGroup from "react-bootstrap/InputGroup";
import "../css/login.css";
//import socketIOClient from "socket.io-client";
//import RebenaBG from "../assets/loginBG.svg";
//import JVLogo from "../../assets/jv-logo 2.svg";

import auth from "../functions/auth";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { WorkspaceProcess } from "./workspace";
//import DashboardProcess from "../Dashboard/DashboardProcess";
//import HomeProcess from "../Home/HomeProcess";
//import Filters from "../functions/filters";
import { Switch, Route } from "react-router-dom";
import { ProtectedRoute } from "./protected.route";
import Image from "react-bootstrap/Image";
import { isChrome, isOpera } from "react-device-detect";
import rebLogo from "../assets/rebLogo.png";
import jnjLogo from "../assets/Jnj/jnj-logo.png";
import { InputGroup } from "react-bootstrap";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

let CryptoJS = require("crypto-js");

class LoginProcess extends React.Component {
  constructor(props) {
    super(props);

    this.inputChange = this.inputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showPassword = this.showPassword.bind(this);

    this.state = {
      isRegister: false,

      txtPassword: { value: "", placeholder: "Password", className: "mb-3" },
      txtCPassword: {
        value: "",
        placeholder: "Confirm Password",
        className: "mb-3",
      },
      txtLogUsername: {
        value: "",
        placeholder: "Enter here",
        className: "mb-3",
      },
      txtLogPassword: {
        value: "",
        placeholder: "Enter here",
        className: "mb-3",
      },
      lblLogininfo: {
        value: "",
        className: "",
      },

      onHide: false,
      userType: null,
      authUser: null,
      isServerOnline: false,
    };
  }

  async componentDidMount() {
    //console.log(browserName);
    // console.log(this.props.socketio.socket.disconnected);
    // if (this.props.socketio.socket.connected) {
    //   this.setState({ isServerOnline: true });
    // }
    //console.log('trigger')
    //this.props.socketio.socket.emit("this is data");
    if (!isChrome && !isOpera) {
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Browser not Supported",
          desc: `This app is optimized for google chrome browser use only. Use of other browser can cause issues.`,
          isYesNo: false,
        },
      });
    }
    if (localStorage.getItem("LUT") != null) {
      let userData = JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("LUD"),
          process.env.REACT_APP_SEK
        ).toString(CryptoJS.enc.Utf8)
      );

      await this.getUser(this.props.dispatch, Number(userData.id));

      this.setState({ userType: userData.type });

      //console.log(userType);
      auth.login((x) => {});
      // this.props.history.push({
      //   pathname: "/workspace",
      //   //userType: userType,
      // });
    } else {
      this.props.history.push({
        pathname: "/login",
      });
    }
  }

  inputChange = (e) => {
    this.setState({
      [e.target.name]: {
        value: e.target.value,
        placeholder: e.target.placeholder,
        className: e.target.className,
      },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    auth.login(this.authenticate);
  };

  showPassword = () => {
    this.setState({
      show: !this.state.show,
    });
  };

  getUser = async (dispatch, id) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `actor/getActor?id=${id}`,
        loadingMsg: "Fetching User",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        dispatch({
          type: "API_STORE_USER",
          payload: { data: onfulfilled.data },
        });
        dispatch({
          type: "API_STORE_USER_TYPE",
          payload: { data: onfulfilled.data },
        });
        return onfulfilled.data;
      },
      (onrejected) => {
        return onrejected.data;
      }
    );

    return data;
  };

  authenticate = async () => {
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const result = await fp.get();

    const browserData = `${result.components.vendor.value}, ${result.components.platform.value}, ${result.components.webGlBasics.value.rendererUnmasked}`;
    if (this.props.socketio.socket) {
      this.setState({ isServerOnline: true });
      let authResponse = { status: 0, user: {}, token: "", set: true };
      let logDat = {
        username: this.state.txtLogUsername.value,
        password: this.state.txtLogPassword.value,
        sessionID: this.props.socketio.socket.id,
        browserData: browserData,
      };

      new Promise((resolve, reject) => {
        this.props.dispatch({
          type: "USER_GET_REQUEST",
          reqType: "post",
          data: logDat,
          payload: "auth/login",
          resolve: resolve,
          reject: reject,
        });
      }).then(
        async (onfulfilled) => {
          authResponse.status = onfulfilled.status;
          authResponse.user = onfulfilled.data.user;
          authResponse.token = onfulfilled.data.accessToken;

          /**
           * LUD = Logged User Details
           * LUT = Logged user Token
           */

          //console.log("LOGGED USER: ", onfulfilled.data);
          this.setState({ userType: authResponse.user.types });

          let enLUD = CryptoJS.AES.encrypt(
            JSON.stringify(authResponse.user),
            process.env.REACT_APP_SEK
          );

          this.props.dispatch({
            type: "API_STORE_BROWSER_INFO",
            payload: { data: browserData },
          });

          localStorage.setItem("LUD", enLUD);
          localStorage.setItem("LUT", authResponse.token);
          localStorage.setItem("LUF", result.visitorId);

          //console.log(authResponse.user);

          await this.getUser(this.props.dispatch, authResponse.user.id);

          let userType = [];

          for (let i = 0; i < authResponse.user.types.length; i++) {
            const _e = authResponse.user.types[i];
            userType.push(_e.name);
          }
          //console.log(userType);

          this.props.history.push({
            pathname: "/workspace",
            userType: userType,
          });
        },
        (onrejected) => {
          this.setState({
            txtLogUsername: {
              value: "",
              placeholder: "Username",
              className: "mb-3 is-invalid",
            },
            txtLogPassword: {
              value: "",
              placeholder: "Password",
              className: "mb-3 is-invalid",
            },
            lblLogininfo: {
              value: "Username or password incorrect",
              className: "mb-3 is-invalid",
            },
          });
        }
      );
    } else {
      this.setState({ isServerOnline: false });
      this.props.history.push({
        pathname: "/",
        userType: this.state.userType,
      });
      this.props.dispatch({
        type: "MODAL_SHOW",
        payload: {
          title: "Server Offline",
          desc: `App cant connect to the server. Please check connection or contact administrator`,
          isYesNo: false,
        },
      });
    }
  };

  render() {
    return (
      <Switch>
        <Route
          path="/"
          exact
          strict
          render={() => (
            <Login
              inputChange={this.inputChange}
              handleSubmit={this.handleSubmit}
              showPassword={this.showPassword}
              state={this.state}
              socket={this.props.socketio.socket}
              dispatch={this.props.dispatch}
            />
          )}
        />
        {/* <Route path="/bypass" exact strict render={() => <Workspace />} /> */}
        <Route
          path="/login"
          render={() => (
            <Login
              inputChange={this.inputChange}
              handleSubmit={this.handleSubmit}
              showPassword={this.showPassword}
              state={this.state}
              socket={this.props.socketio.socket}
              dispatch={this.props.dispatch}
            />
          )}
        />
        {/* <ProtectedRoute
          exact
          path="/dashboard"
          userType={this.state.userType}
          component={() => <DashboardProcess />}
        /> */}
        {/* <ProtectedRoute
          exact
          path="/portal"
          userType={this.state.userType}
          component={() => <HomeProcess />}
        /> */}
        <ProtectedRoute
          // exact
          path="/workspace"
          userType={this.state.userType}
          component={() => <WorkspaceProcess history={this.props.history} />}
        />

        <Route path="*" component={() => "404 Not Found"} />
      </Switch>
    );
  }
}
const mapStateToProps = (state) => {
  return { socketio: state.SocketHandler };
};
export default connect(mapStateToProps)(withRouter(LoginProcess));

const primary = {
  color: "var(--REB-secondary)",
};

const Login = (props) => (
  <>
    <style type="text/css">
      {`
      .css-loginInput, .btnLogin{
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      }
      .css-loginInput{
        height: 50px;
      }
    @media (min-width: 992px) {
      .col-login {
        max-width: 50%;
       
      }
      .form-login-container{
        border-radius: 20px;
        background-color: rgba(256, 256, 256, 0.90);
      }
    }
    @media (max-width: 992px) {
      .col-login {
        max-width: 100%;
        height: 100%;
      }
      .form-login-container{
        border-radius: 0px;
        background-color: rgba(256, 256, 256, 0.60);
      }
    }

    
    `}
    </style>
    {process.env.REACT_APP_THEME === "SURFACEPLUS" && (
      <div
        style={{
          backgroundImage: "url(/loginBG.svg)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          width: "100%",
          height: "100vh",
          position: "absolute",
        }}
      ></div>
    )}
    
    {process.env.REACT_APP_THEME === "JNJ" && (
      <div
        style={{
          backgroundImage: "url(/Jnj/jnj-main-bg.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          width: "100%",
          height: "100vh",
          position: "absolute",
        }}
      ></div>
    )}
    {process.env.REACT_APP_THEME === "DEMO" && (
      <div
      style={{
        backgroundImage: "url(/loginBG.svg)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50% 50%",
        backgroundSize: "cover",
        width: "100%",
        height: "100vh",
        position: "absolute",
      }}
    ></div>
    )}

    <div className="IlluContainer"></div>

    <Row
      className="row-content"
      style={{
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignContent: "center",
      }}
      noGutters={true}
    >
      <Col
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className="col-login"
        style={{
          flexDirection: "column",

          // maxWidth: "50%",
          // maxHeight: "50%",
          display: "flex",
          // paddingTop: "4%",
          // paddingBottom: "8%",
        }}
      >
        <Row className="logoContainer" noGutters={true}>
          {/* <img src={JVLogo} className="landing-logo" alt="jvLandmark-logo" /> */}
        </Row>
        <div
          style={{
            flex: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "60px",
            //border: "solid 1px",
            // borderRadius: "20px",
            // backgroundColor: "white",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            // backgroundColor: "rgba(256, 256, 256, 0.90)",
          }}
          className="form-login-container"
        >
          <Form
            className="form-login"
            style={{ alignSelf: "center", width: "100%" }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                props.handleSubmit(e);
              }
            }}
          >
            <h4 className="text-center" style={{ paddingBottom: "100px" }}>
              {/* <span className="span-login-welcome">Welcome to </span> */}
              <span className="span-login-firmName" style={primary}>
                {/* Surface+ */}
                {process.env.REACT_APP_THEME === "SURFACEPLUS" && (
                  <Image
                    src={rebLogo}
                    className="workspace-logo"
                    name="btnHome"
                    alt="rebena-logo"
                  />
                )}
                
                {process.env.REACT_APP_THEME === "JNJ" && (
                  <>
                    <style>
                      {`
                        .jollof-main-logo {
                          max-width: 30%;
                          max-height: 30%;
                        }
                      `}
                    </style>
                    <Image
                      src={jnjLogo}
                      className="jollof-main-logo"
                      name="btnHome"
                      alt="jnj-logo"
                    />
                  </>
                )}
              </span>
            </h4>

            <h3 className="text-center" style={{ fontSize: "34px" }}>
              Login
            </h3>
            <span className="mr-1">
              <i className="icofont-user"></i>
            </span>
            <Form.Label>Username</Form.Label>

            <FormControl
              type="text"
              className={
                props.state.txtLogUsername.className + " css-loginInput"
              }
              placeholder={props.state.txtLogUsername.placeholder}
              value={props.state.txtLogUsername.value}
              onChange={props.inputChange}
              name="txtLogUsername"
              id="css-uname"
              required
              //disabled={!props.state.isServerOnline}
            />
            <span className="mr-1">
              <i className="icofont-lock"></i>
            </span>
            <Form.Label>Password</Form.Label>

            <InputGroup className="mb-3">
              <FormControl
                type={props.state.show ? "text" : "password"}
                className={" css-loginInput"}
                placeholder={props.state.txtLogPassword.placeholder}
                value={props.state.txtLogPassword.value}
                onChange={props.inputChange}
                name="txtLogPassword"
                id="css-pword"
                required
                //disabled={!props.state.isServerOnline}
              />
              <Button
                style={{
                  backgroundColor: "var(--REB-tertiary)",
                  borderRadius: "0px 5px 5px 0px",
                }}
                onClick={() => props.showPassword()}
              >
                {props.state.show === true ? (
                  <i className="icofont-eye-alt"></i>
                ) : (
                  <i className="icofont-eye-blocked"></i>
                )}
              </Button>
            </InputGroup>
            <p
              className={props.state.lblLogininfo.className}
              style={{ color: "#d32f2f" }}
              id="css-loginInfo"
            >
              {props.state.lblLogininfo.value}
            </p>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                type="button"
                className="btnLogin"
                style={{
                  backgroundColor: "var(--REB-secondary)",
                  // boxShadow:
                  //   "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
                  width: "50%",
                }}
                onClick={props.handleSubmit}

                //disabled={!props.state.isServerOnline}
              >
                LOGIN
              </Button>
            </div>

            <p className="text-center pt-5">Version 2.8.7</p>
          </Form>
        </div>
      </Col>
      {/* <Col className="landingContainer" lg={2}>
        <Row className="IlluContainer">
        
        </Row>
      </Col> */}
    </Row>
  </>
);

export { Login };
