import React from "react";
//import { NumericFormat } from 'react-number-format';
import pdfTemplate from "../../../components/PdfHeadFoot";
import { Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer";
import misc from "../../../functions/misc";
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";
import emptyImg from "../../../assets/fork-lift.png";
Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf",
      fontWeight: 800,
    },
  ],
});
let themeColor = "";
if (process.env.REACT_APP_THEME === "SURFACEPLUS") themeColor = "#5eaaff";
if (process.env.REACT_APP_THEME === "DEMO") themeColor = "#ed5309";

const tableStyles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  tableHeader: {
    textAlign: "center",
    fontSize: 9,
    marginLeft: 2,
    marginRight: 2,
    color: themeColor,
    fontFamily: "Open Sans",
    fontWeight: 800,
  },
  tableCell: {
    marginLeft: 2,
    marginRight: 2,
  },
  tableCellNames: {
    fontSize: 8,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

class InvReport {
  _itemTable(data, showImage, props) {
    const userType = props.userType;
    let showCogs = false;
    userType.includes("Admin") ||
    userType.includes("Executive") ||
    userType.includes("Purchaser")
      ? (showCogs = true)
      : (showCogs = false);
    return (
      <Table data={data}>
        <TableHeader>
          <TableCell weighting={0.03} style={[tableStyles.tableHeader]}>
            #
          </TableCell>
          {showImage ? (
            <TableCell weighting={0.1} style={[tableStyles.tableHeader]}>
              IMAGE
            </TableCell>
          ) : null}

          <TableCell weighting={0.5} style={[tableStyles.tableHeader]}>
            ITEM DESCRIPTION
          </TableCell>
          <TableCell weighting={0.06} style={[tableStyles.tableHeader]}>
            QTY
          </TableCell>
          <TableCell
            weighting={0.06}
            style={[tableStyles.tableHeader, { fontSize: 6 }]}
          >
            DISPLAY
          </TableCell>
          <TableCell
            weighting={0.06}
            style={[tableStyles.tableHeader, { fontSize: 6 }]}
          >
            ORDER
          </TableCell>
          <TableCell weighting={0.08} style={[tableStyles.tableHeader]}>
            SRP
          </TableCell>
          {showCogs ? (
            <>
              <TableCell weighting={0.08} style={[tableStyles.tableHeader]}>
                COGS
              </TableCell>
              <TableCell weighting={0.08} style={[tableStyles.tableHeader]}>
                VALUE
              </TableCell>
            </>
          ) : null}
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={[
              tableStyles.tableCell,
              { height:  55 , fontSize: 8 },
            ]}
            weighting={0.03}
            getContent={(d) => (d.index === null ? "" : d.index)}
          />
          {showImage ? (
            <DataTableCell
              style={[tableStyles.tableCell]}
              weighting={0.1}
              getContent={(d) => {
                return (
                  <View style={{ flexDirection: "row" }}>
                    {((x) => {
                      // console.log(d);
                      let image = [];
                      if (d) {
                        if (d.files !== null && d.files !== "") {
                          if (d.files.constructor === String)
                            image = JSON.parse(d.files);
                          else {
                            image = d.files;
                          }
                          //console.log(image);
                        }
                      }

                      if (image.length > 0) {
                        return (
                          <Image
                            style={[
                              {
                                maxWidth: 50,
                                height: 50,
                                marginTop: 2,
                                marginBottom: 2,
                                marginLeft: 2,
                              },
                            ]}
                            // src={d.dispImg.original}
                            //src={emptyImg}
                            src={{
                              uri: `${
                                process.env.REACT_APP_API_URL
                              }global/getFile?uniqueId=${
                                d.uniqueId
                              }&filename=${encodeURIComponent(
                                image[0].filename
                              )}`,
                              method: "GET",

                              headers: {
                                Authorization:
                                  "Bearer " + localStorage.getItem("LUT"),
                              },
                              body: "",
                            }}
                          />
                        );
                      } else {
                        return (
                          <Image
                            style={[
                              {
                                maxWidth: 50,
                                height: 50,
                                marginTop: 2,
                                marginBottom: 2,
                                marginLeft: 2,
                              },
                            ]}
                            src={emptyImg}
                          />
                        );
                      }
                    })()}
                  </View>
                );
              }}
            />
          ) : null}

          <DataTableCell
            style={[
              tableStyles.tableCell,
              tableStyles.tableCellNames,
              { fontSize: 8 },
            ]}
            weighting={0.5}
            getContent={(d) => {
              //console.log(d);
              let name = "-";
              if (d.details.length > 0) {
                const sDat = d.details.find((x) => x.type === 1);
                sDat !== undefined ? (name = sDat.name) : (name = "-");
              }

              return (
                <View style={{ flexDirection: "column" }}>
                  <Text style={[{ marginLeft: 5 }]}>{name}</Text>
                  <Text style={[{ marginLeft: 5 }]}>{d.description}</Text>
                  <Text style={[{ marginLeft: 5 }]}>{d.code}  |  {d.uniqueId}</Text>
                  <Text style={[{ marginLeft: 5 }]}>{d.type}  |  {d.brand}</Text>
                </View>
              );
            }}
          />
          <DataTableCell
            style={[
              tableStyles.tableCell,
              tableStyles.tableCellNames,
              { fontSize: 8 },
            ]}
            weighting={0.06}
            getContent={(d) => {
              let name = "-";

              let qty = d.qty;
              // let order = 0;

              if (qty < 0) {
                //order = Math.abs(qty);
                qty = 0;
              }

              if (d.details.length > 0) {
                const sDat = d.details.find((x) => x.type === 2);
                sDat !== undefined ? (name = sDat.name) : (name = "-");
              }
              return `${qty} \n${name}`;

              // return `stock: ${qty} ${name} \n display:${d.display} ${name} \n order:${order} ${name}`;
            }}
          />
          <DataTableCell
            style={[
              tableStyles.tableCell,
              tableStyles.tableCellNames,
              { fontSize: 8 },
            ]}
            weighting={0.06}
            getContent={(d) => {
              //let name = "-";

              // if (d.details.length > 0) {
              //   const sDat = d.details.find((x) => x.type === 2);
              //   sDat !== undefined ? (name = sDat.name) : (name = "-");
              // }

              return `${d.display} `;
            }}
          />
          <DataTableCell
            style={[
              tableStyles.tableCell,
              tableStyles.tableCellNames,
              { fontSize: 8 },
            ]}
            weighting={0.06}
            getContent={(d) => {
              //let name = "-";

              let qty = d.qty;
              let order = 0;

              if (qty < 0) {
                order = Math.abs(qty);
                qty = 0;
              }

              // if (d.details.length > 0) {
              //   const sDat = d.details.find((x) => x.type === 2);
              //   sDat !== undefined ? (name = sDat.name) : (name = "-");
              // }
              return `${order} `;

              // return `stock: ${qty} ${name} \n display:${d.display} ${name} \n order:${order} ${name}`;
            }}
          />
          <DataTableCell
            style={[
              tableStyles.tableCell,
              tableStyles.tableCellNames,
              { fontSize: 7 },
            ]}
            weighting={0.08}
            getContent={(d) => {
              let modifiedPrice = 0;
              // console.log(priceModification)
              const priceModifier = props.settings?.itemPriceModification;
              if (props.settings) {
                const percentageModifier = priceModifier / 100;
                modifiedPrice = d.price + d.price * percentageModifier;
                modifiedPrice = Number(modifiedPrice.toFixed(2));
              }
              return (
                <View style={{ flexDirection: "column" }}>
                  {showCogs && (
                    <Text
                      style={[
                        {
                          marginLeft: 1,
                          textDecoration: "line-through",
                          fontSize: 6,
                        },
                      ]}
                    >
                      {misc.parseCurrencyNoCurrency(d.price)}
                    </Text>
                  )}

                  <Text style={[{ marginLeft: 1, color: "#007bd3" }]}>
                    {misc.parseCurrencyNoCurrency(modifiedPrice)}
                  </Text>
                </View>
              );
              // return `${d.price} `;
            }}
          />
          {showCogs ? (
            <DataTableCell
              style={[
                tableStyles.tableCell,
                tableStyles.tableCellNames,
                { fontSize: 7 },
              ]}
              weighting={0.08}
              getContent={(d) => {
                return (
                  <View style={{ flexDirection: "row" }}>
                    <Text style={[{ marginLeft: 1 }]}>
                      {/* <NumberFormat
                        value={d.cogs}
                        displayType={"text"}
                        thousandSeparator={true}
                      /> */}
                      {misc.parseCurrencyNoCurrency(d.cogs)}
                    </Text>
                  </View>
                );
                // return `${d.price} `;
              }}
            />
          ) : null}
          {showCogs ? (
            <DataTableCell
              style={[
                tableStyles.tableCell,
                tableStyles.tableCellNames,
                { fontSize: 7 },
              ]}
              weighting={0.08}
              getContent={(d) => {
                let qty = d.qty;
                // let order = 0;

                if (qty <= 0) {
                  //order = Math.abs(qty);
                  qty = 0;
                  qty = qty + d.display;
                } else {
                  qty = qty + d.display;
                }

                return (
                  <View style={{ flexDirection: "row" }}>
                    <Text style={[{ marginLeft: 1 }]}>
                      {/* <NumberFormat
                        value={(qty * d.cogs).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      /> */}
                      {misc.parseCurrencyNoCurrency((qty * d.cogs).toFixed(2))}
                    </Text>
                  </View>
                );
                // return `${d.price} `;
              }}
            />
          ) : null}
        </TableBody>
      </Table>
    );
  }

  _termsSignature(props, incharge) {
    return (
      <>
        <View>
          <View
            style={[
              {
                marginTop: 5,

                paddingLeft: 80,
                paddingRight: 80,
              },
            ]}
          >
            <View
              style={[
                {
                  marginTop: 10,
                  flex: 1,
                  flexDirection: "row",
                },
              ]}
            >
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      color: themeColor,
                      fontFamily: "Open Sans",
                      fontWeight: 800,
                    },
                  ]}
                >
                  Generated by:
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View>
          <View
            style={[
              {
                marginTop: 5,
                marginBottom: 25,
                paddingLeft: 80,
                paddingRight: 80,
              },
            ]}
          >
            <View
              style={[
                {
                  marginTop: 10,
                  flex: 1,
                  flexDirection: "row",
                },
              ]}
            >
              <View style={[{ flex: 1 }]}>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      textAlign: "center",
                    },
                  ]}
                >
                  {incharge !== null && incharge !== undefined
                    ? incharge.name
                    : "-"}
                </Text>
                {(() => {
                  //console.log(incharge);
                  if (incharge !== null && incharge !== undefined) {
                    if (incharge.uploads) {
                      if (Number(incharge.uploads.length) > 0) {
                        return (
                          <Image
                            style={[
                              {
                                position: "absolute",
                                width: 150,
                                marginTop: -25,
                              },
                            ]}
                            src={{
                              uri: `${process.env.REACT_APP_API_URL}global/getFile?uniqueId=${incharge.uniqueId}&filename=${incharge.uploads[0].filename}`,
                              method: "GET",

                              headers: {
                                Authorization:
                                  "Bearer " + localStorage.getItem("LUT"),
                              },
                              body: "",
                            }}
                          />
                        );
                      }
                    }
                  }
                })()}
              </View>
            </View>
          </View>
        </View>
      </>
    );
  }

  InvReport(props, incharge, showImage, priceModifier) {
    //console.log(props.pdfGroupTable[0]);

    let _content = [];

    _content.push(
      <>
        {/* <View>
         
          <View
            style={[
              {
                marginTop: 8,
                marginBottom: 8,
                paddingLeft: 80,
                paddingRight: 80,
              },
            ]}
          >
            <Text
              style={[
                {
                  marginBottom: 5,
                  fontSize: 12,
                },
              ]}
            >
              Client:{" "}
              {parent !== null && parent !== undefined
                ? parent.client.company
                : "-"}
            </Text>
            <Text
              style={[
                {
                  marginBottom: 20,
                  fontSize: 10,
                },
              ]}
            >
              Address: {shippingAddress}
            </Text>
          </View>
        </View> */}

        <View
          style={[
            {
              //height: 325,
              paddingLeft: 20,
              paddingRight: 20,
            },
          ]}
        >
          {((x) => {
            let _e = [];
            if (props.pdfGroupTable.result.length > 0) {
              _e = props.pdfGroupTable.result[0];
            }
            //console.log(props.pdfGroupTable);
            if (_e.length < 8) {
              return (
                <>
                  <View
                    style={[
                      {
                        paddingLeft: 20,
                        paddingRight: 20,
                      },
                    ]}
                  >
                    {this._itemTable(_e, showImage, props)}
                  </View>

                  {this._termsSignature(props, incharge)}
                </>
              );
            } else {
              return <>{this._itemTable(_e, showImage, props)}</>;
            }
          })()}
        </View>
      </>
    );

    for (let i = 1; i < props.pdfGroupTable.result.length; i++) {
      const _e = props.pdfGroupTable.result[i];

      if (_e.length > 6) {
        _content.push(
          <View
            style={[
              {
                paddingLeft: 20,
                paddingRight: 20,
              },
            ]}
          >
            {this._itemTable(_e, showImage, props)}
          </View>
        );
      } else {
        _content.push(<>{this._termsSignature(props, incharge)}</>);
      }
    }

    return pdfTemplate.TemplateHeaderFooter(_content, "Inventory Report", "-");
  }
}
export default new InvReport();
