import React, { useState, useEffect } from "react";
//import Workspace from "./Workspace";
//import { withRouter } from "react-router-dom";

//import { connect } from "react-redux";
import InventoryProcess from "./Inventory/InventoryProcess";

import DashboardProcess from "./Dashboard/DashboardProcess";
import { ActorProcess2 } from "./Actor/ActorProcess2";
import ProfileProcess from "./Profile/ProfileProcess";
import VerificationProcess from "./Verification/VerificationProcess";
// import Phase from "../Phase/PhaseProcess";
import Button from "react-bootstrap/Button";
import "../css/workspace.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import rebLogo from "../assets/rebLogo.png";
import jnjLogo from "../assets/Jnj/jnj-logo.png";
import demoLogo from "../assets/Demo/DemoLogo.png";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import Dropdown from "react-bootstrap/Dropdown";
//import DropdownButton from "react-bootstrap/DropdownButton";
import InvoiceProcess from "./Invoice/InvoiceProcess";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import bgImg from "../assets/pageBG.jpg";
import bgImgJnj from "../assets/Jnj/jnj-main-bg.jpg";
import bgImgDemo from "../assets/Demo/5227299.jpg";
import { useDispatch, useSelector } from "react-redux";

//import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { POSProcess } from "./POS/POSProcess";
import { AccountsProcess } from "./Accounts/AccountsProcess";
import { TransactionsProcess } from "./Transactions/TransactionsProcess";
import { SettingsProcess } from "./Settings/SettingsProcess";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

export const WorkspaceProcess = (props) => {
  const fpPromise = FingerprintJS.load();
  //const [content, setContent] = useState(1);
  const [selectedNav, setSelectedNav] = useState("Invoices");
  const [canvasClassNames, setCanvasClassNames] = useState(
    "animate__animated d-none"
  );
  const [isCanvasVisible, setIsCanvasVisible] = useState(1);
  // const [navItems, setNavItems] = useState(defaultNav);

  const user = useSelector((state) => state.apiData.userData);
  const userType = useSelector((state) => state.apiData.userType);
  const socketio = useSelector((state) => state.SocketHandler.socket);
  const permissions = useSelector((state) => {
    const user = state.apiData.userData;
    let _permissions = [];
    if (user && user.permissions) {
      _permissions = JSON.parse(user.permissions);
    }
    return _permissions.toString();
  });
  const dispatch = useDispatch();

  // Set initial landing page based on user permission
  useEffect(() => {
    const getFingerprint = async () => {
      //get browser unique ID;
      const fp = await fpPromise;
      const result = await fp.get();
      //Store to redux

      dispatch({
        type: "API_STORE_BROWSER_INFO",
        payload: {
          data: `${result.components.vendor.value}, ${result.components.platform.value}, ${result.components.webGlBasics.value.rendererUnmasked}`,
        },
      });
      dispatch({
        type: "API_STORE_FINGERPRINT",
        payload: { data: result.visitorId },
      });
      dispatch({
        type: "API_STORE_FINGERPRINT",
        payload: { data: result.visitorId },
      });
      localStorage.setItem("LUF", result.visitorId);
    };
    const Fingerprint = getFingerprint();

    if (localStorage.getItem("PS")) {
      // localStorage.setItem("PS", "btnProfile");
      // setNavItems({
      //   ...defaultNav,
      //   btnContProfile: { val: true, name: "Profile" },
      // });
      setSelectedNav(localStorage.getItem("PS"));
    } else {
      if (userType.includes("Executive")) {
        localStorage.setItem("PS", "btnVerification");
        // setNavItems({
        //   ...defaultNav,
        //   btnContVerification: { val: true, name: "Verification" },
        // });
        setSelectedNav("btnVerification");
      }
      if (userType.includes("Admin")) {
        localStorage.setItem("PS", "btnVerification");
        // setNavItems({
        //   ...defaultNav,
        //   btnContDashboard: { val: true, name: "Dashboard" },
        // });
        setSelectedNav("btnVerification");
      }
      if (userType.includes("Driver")) {
        // setNavItems({
        //   ...defaultNav,
        //   btnContProject: { val: true, name: "Dashboard" },
        // });
        setSelectedNav("btnDashboard");
      }
      if (
        userType.includes("Sales Person") ||
        userType.includes("Manager") ||
        userType.includes("Inventory Clerk") ||
        userType.includes("Purchaser")
      ) {
        // setNavItems({
        //   ...defaultNav,
        //   btnContProject: { val: true, name: "Invoice Management" },
        // });
        setSelectedNav("btnInvoiceManagement");
      }
      if (
        userType.includes("Sales Person") ||
        userType.includes("Manager") ||
        userType.includes("Inventory Clerk") ||
        userType.includes("Purchaser")
      ) {
        // setNavItems({
        //   ...defaultNav,
        //   btnContProject: { val: true, name: "Invoice Management" },
        // });
        setSelectedNav("btnInvoiceManagement");
      }
      if (userType.includes("User")) {
        const specPerms = JSON.parse(user.permissions);
        if (specPerms.includes("perm-ui-chkPOS")) {
          // setNavItems({
          //   ...defaultNav,
          //   btnContPOS: { val: true, name: "POS Management" },
          // });
          setSelectedNav("btnPOS");
        } else {
          setSelectedNav("btnProfile");
        }
      }
    }
    //console.log("USER PERMISSIONS ===> ", permissions);
    //console.log("Mounted");
    const onForceLogoutUser = () => {
      localStorage.clear();

      socketio.disconnect();
      window.location.reload();
    };
    const onLogoutDupeUser = (data) => {
      if (Fingerprint.visitorId !== data.fingerprint)
        //console.log(data);
        localStorage.clear();
      socketio.disconnect();
      dispatch({
        type: "MODAL_SHOW",
        isYesNo: false,

        payload: {
          title: "Logout Notice",
          desc: `Your account has been logged-in from a another device. You will be logged out. Please login again.`,
          isYesNo: false,
          onClose: () => {
            //window.location.reload();
            props.history.push({
              pathname: "/login",
            });
            dispatch({
              type: "TOAST_ADD",
              payload: {
                title: `You have been logged out.`,
              },
            });
          },
          cb: (e) => {},
        },
      });
    };
    socketio.on(`forceLogoutUser${user.id}`, onForceLogoutUser);
    socketio.on(`logoutDupeUser${user.id}`, onLogoutDupeUser);
    return () => {
      socketio.off(`forceLogoutUser${user.id}`, onForceLogoutUser);
      socketio.off(`logoutDupeUser${user.id}`, onLogoutDupeUser);
      //console.log("unmounted");
    };
  }, []);

  const handleLogout = () => {
    dispatch({
      type: "MODAL_SHOW",
      payload: {
        title: "Confirmation",
        desc: `Are you sure you want to Logout?`,
        isYesNo: true,
        cb: (e) => {
          new Promise((resolve, reject) => {
            dispatch({
              type: "USER_GET_REQUEST",
              reqType: "post",
              payload: "auth/logout",
              resolve: resolve,
              reject: reject,
            });
          }).then((api) => {
            localStorage.clear();
            socketio.disconnect();
            window.location.reload();
          });
        },
      },
    });
  };

  // function debounce(func, timeout = 300) {
  //   let timer;
  //   return (...args) => {
  //     clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       func.apply(this, args);
  //     }, timeout);
  //   };
  // }

  const handleSlideToggle = () => {
    if (!isCanvasVisible) {
      setCanvasClassNames("animate__animated animate__slideOutLeft");
    } else {
      setCanvasClassNames("animate__animated animate__slideInLeft");
    }

    setIsCanvasVisible(!isCanvasVisible);
  };

  return (
    <>
      <div
        style={{
          position: "absolute",
          width: "100%",
          //  maxHeight:'80%',
          overflow: "hidden",
          zIndex: 3,
          backgroundColor: "transparent",
        }}
        className="workspaceContainer "
      >
        <Navbar
          collapseOnSelect
          className="workspace-nav"
          style={{ zIndex: 5, width: "100%" }}
          expand="lg"
        >
          <Row
            className="top-nav-cont animate__animated animate__fadeInLeft p-0"
            noGutters
          >
            {/* <Navbar.Toggle
              className="workspace-nav-toggle"
              aria-controls="basic-navbar-nav"
            /> */}
            <Col
              xs={3}
              sm={0}
              md={2}
              lg={2}
              className="h-100"
              id="btnSlidingNavToggler"
              // style={{ display: "flex" }}
            >
              <Button
                style={{ backgroundColor: "transparent" }}
                className="pb-0 shadow-none"
                onClick={() => {
                  handleSlideToggle();
                }}
              >
                <i
                  style={{ color: "black" }}
                  className="icofont-navigation-menu icofont-2x"
                ></i>
              </Button>
            </Col>
            <Col
              xs={9}
              lg={9}
              className="workspace-brand-cont d-flex justify-content-center"
            >
              <Navbar.Brand className="workspaceLogoCont ">
                {process.env.REACT_APP_THEME === "SURFACEPLUS" && (
                  <Image
                    src={rebLogo}
                    className="workspace-logo"
                    name="btnHome"
                    alt="rebena-logo"
                  />
                )}
                {process.env.REACT_APP_THEME === "JNJ" && (
                  <Image
                    src={jnjLogo}
                    className="workspace-logo"
                    name="btnHome"
                    alt="jnj-logo"
                  />
                )}
                {process.env.REACT_APP_THEME === "DEMO" && (
                  <Image
                    src={demoLogo}
                    className="workspace-logo"
                    name="btnHome"
                    alt="rebena-logo"
                  />
                )}
              </Navbar.Brand>
            </Col>
          </Row>
          <style>
            {`
              @media (max-width: 991px) {
                #slidingNavbar {
                  display: block;
                }
                #btnSlidingNavToggler {
                  display: flex;
                }
              }
              @media (min-width: 992px) {
                #basic-navbar-nav {
                  display: none !important;
                }
                #slidingNavbar {
                  display: block;
                }
                #btnSlidingNavToggler {
                  display: flex;
                }
              }
              .pageName {
                margin: 0px;
                padding-left: 20px;
                color: var(--REB-white);
                font-weight: bold;
                align-self: center;
              }
            `}
          </style>

          <style>
            {`
              @media (max-width: 991px) {
                .pageName {
                  display: none;
                }
              }
              @media (min-width: 992px) {
                .pageName {
                  display: flex;
                }
              }
            `}
          </style>
          <div
            style={{
              width: "100%",
              textAlign: "right",
              paddingRight: "15px",
              display: "flex",
              justifyContent: "right",
            }}
          >
            {(() => {
              const CONTENT_TYPE = selectedNav;
              if (CONTENT_TYPE === "btnActorManagement") {
                return <h5 className="pageName">Partners</h5>;
              }
              if (CONTENT_TYPE === "btnVerification") {
                return <h5 className="pageName">Verification Management</h5>;
              }
              if (CONTENT_TYPE === "btnDashboard") {
                return <h5 className="pageName">Dashboard</h5>;
              }
              if (CONTENT_TYPE === "btnInventory") {
                return <h5 className="pageName">Inventory Management</h5>;
              }
              if (CONTENT_TYPE === "btnInvoiceManagement") {
                return <h5 className="pageName">Invoice Management</h5>;
              }
              if (CONTENT_TYPE === "btnProfile") {
                return <h5 className="pageName">Profile</h5>;
              }
              if (CONTENT_TYPE === "btnPOS") {
                return <h5 className="pageName">POS</h5>;
              }
              if (CONTENT_TYPE === "btnAccounts") {
                return <h5 className="pageName">Accounts</h5>;
              }
              if (CONTENT_TYPE === "btnSettings") {
                return <h5 className="pageName">Settings</h5>;
              }
            })()}

            <Dropdown
              as={ButtonGroup}
              className="ml-2 profileDropdown nav-hide-mobile animate__animated animate__fadeInDown"
              // menuAlign="right"
              id="dropdown-basic-button"
              // align="end"
              // drop="start"
              // title={props.state.loggedUser.name}
            >
              <Dropdown.Toggle
                style={{ backgroundColor: "transparent" }}
                className="p-0 m-0 shadow-none"
              >
                <i className="icofont-user  pr-1"></i>
                {`${user.firstName ? user.firstName : ""} ${
                  user.lastName ? user.lastName : ""
                }`}
              </Dropdown.Toggle>
              <Dropdown.Menu align={"right"}>
                {/* <Dropdown.ItemText>{user.name}</Dropdown.ItemText> */}
                <Dropdown.Divider />
                <Dropdown.Item
                  as="button"
                  onClick={(x) => {
                    localStorage.setItem("PS", "btnProfile");
                    // setNavItems({
                    //   ...defaultNav,
                    //   btnContProject: { val: true, name: "Profile" },
                    // });
                    setSelectedNav("btnProfile");
                  }}
                  name="btnProfile"
                >
                  Profile
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={handleLogout}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Navbar>
        <div style={{ width: "100%", height: "100%", position: "absolute" }}>
          {/* <div style={{
             width: "100%",
             height: "100vh",
             position: "absolute",
             paddingLeft: '300px',
             pointerEvents: 'none',
          }} onClick={()=>{}}></div> */}
          <div
            className={canvasClassNames}
            id="slidingNavbar"
            // hidden={isCanvasVisible}
            onClick={() => {
              if (!isCanvasVisible) {
                setCanvasClassNames("animate__animated animate__slideOutLeft");
              } else {
                setCanvasClassNames("animate__animated animate__slideInLeft");
              }

              setIsCanvasVisible(!isCanvasVisible);
            }}
            style={{
              width: "100%",
              height: "100vh",
              position: "absolute",
              backgroundColor: "transparent",
              zIndex: 1,
              paddingTop: "55px",
            }}
          >
            <style>
              {`
              .nav-link {
                padding: 1.5rem;
                background-color: var(--REB-primary);
                color: var(--REB-white);
                border-bottom: 1px solid white;
                 {
                  /* margin-top: 5px; */
                }
              }
              .nav-link:hover {
                background-color: var(--REB-tertiary);
                color: var(--REB-white);
              }

              a {
              }
            `}
            </style>

            <div
              style={{
                display: "flex",
                overflow: "hidden",
                overflowY: "auto",
                flexDirection: "column",
                width: "300px",
                height: "100%",
               backgroundColor:'var(--REB-white)',
                boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
              }}
            >
              {(() => {
                //console.log(props.decUser.type);
                if (
                  userType.includes("Admin") ||
                  userType.includes("Executive") ||
                  permissions.includes("perm-ui-verificationAccess")
                ) {
                  return (
                    <>
                      <Nav.Link
                        className="p-4"
                        style={{
                          display: "flex",
                          backgroundColor:
                            selectedNav === "btnVerification" &&
                            "var(--REB-tertiary)",
                        }}
                        onSelect={(x) => {
                          localStorage.setItem("PS", "btnVerification");
                          // setNavItems({
                          //   ...defaultNav,
                          //   btnContVerification: {
                          //     val: true,
                          //     name: "Verification",
                          //   },
                          // });
                          setSelectedNav("btnVerification");
                        }}
                        eventKey="btnVerification"
                      >
                        <i
                          className="icofont-shield"
                          style={{ fontSize: "2rem" }}
                        ></i>
                        <p
                          className="mb-0 ml-3"
                          style={{
                            alignSelf: "center",
                            fontWeight: "bolder",
                            fontSize: "1.5rem",
                          }}
                        >
                          Verification
                        </p>
                      </Nav.Link>
                    </>
                  );
                }
              })()}
              {(() => {
                if (
                  userType.includes("Admin") ||
                  userType.includes("Executive") ||
                  userType.includes("Driver") ||
                  userType.includes("Branch Manager")
                ) {
                  return (
                    <>
                      <Nav.Link
                        className="p-4"
                        style={{
                          display: "flex",
                          backgroundColor:
                            selectedNav === "btnDashboard" &&
                            "var(--REB-tertiary)",
                        }}
                        onSelect={(x) => {
                          localStorage.setItem("PS", "btnDashboard");
                          // setNavItems({
                          //   ...defaultNav,
                          //   btnContDashboard: {
                          //     val: true,
                          //     name: "Dashboard",
                          //   },
                          // });
                          setSelectedNav("btnDashboard");
                        }}
                        eventKey="btnDashboard"
                      >
                        <i
                          className="icofont-dashboard-web"
                          style={{ fontSize: "2rem" }}
                        ></i>
                        <p
                          className="mb-0 ml-3"
                          style={{
                            alignSelf: "center",
                            fontWeight: "bolder",
                            fontSize: "1.5rem",
                          }}
                        >
                          Dashboard
                        </p>
                      </Nav.Link>
                    </>
                  );
                }
              })()}
              {(() => {
                if (
                  userType.includes("Admin") ||
                  userType.includes("Executive") ||
                  userType.includes("Manager") ||
                  userType.includes("Sales Person") ||
                  userType.includes("Purchaser") ||
                  userType.includes("Inventory Clerk") ||
                  userType.includes("Branch Manager") ||
                  permissions.includes("perm-ui-invoicingPage")
                ) {
                  return (
                    <>
                      <Nav.Link
                        className="p-4 "
                        style={{
                          display: "flex",
                          backgroundColor:
                            selectedNav === "btnInvoiceManagement" &&
                            "var(--REB-tertiary)",
                        }}
                        onSelect={(x) => {
                          localStorage.setItem("PS", "btnInvoiceManagement");
                          // setNavItems({
                          //   ...defaultNav,
                          //   btnContInvoice: {
                          //     val: true,
                          //     name: "Invoices",
                          //   },
                          // });
                          setSelectedNav("btnInvoiceManagement");
                        }}
                        eventKey="btnInvoiceManagement"
                      >
                        <i
                          className="icofont-file-document"
                          style={{ fontSize: "2rem" }}
                        ></i>
                        <p
                          className="mb-0 ml-3"
                          style={{
                            alignSelf: "center",
                            fontWeight: "bolder",
                            fontSize: "1.5rem",
                          }}
                        >
                          Invoices
                        </p>
                      </Nav.Link>
                    </>
                  );
                }
              })()}
              {(() => {
                if (
                  userType.includes("Admin") ||
                  userType.includes("Manager") ||
                  userType.includes("Purchaser") ||
                  userType.includes("Sales Person") ||
                  userType.includes("Inventory Clerk") ||
                  userType.includes("Branch Manager") ||
                  permissions.includes("perm-ui-inventoryPage")
                ) {
                  return (
                    <Nav.Link
                      className="p-4"
                      style={{
                        display: "flex",
                        backgroundColor:
                          selectedNav === "btnInventory" &&
                          "var(--REB-tertiary)",
                      }}
                      onSelect={(x) => {
                        localStorage.setItem("PS", "btnInventory");
                        // setNavItems({
                        //   ...defaultNav,
                        //   btnContInventory: { val: true, name: "Inventory" },
                        // });
                        setSelectedNav("btnInventory");
                      }}
                      eventKey="btnInventory"
                    >
                      <i
                        className="icofont-box"
                        style={{ fontSize: "2rem" }}
                      ></i>
                      <p
                        className="mb-0 ml-3"
                        style={{
                          alignSelf: "center",
                          fontWeight: "bolder",
                          fontSize: "1.5rem",
                        }}
                      >
                        Inventory
                      </p>
                    </Nav.Link>
                  );
                }
              })()}
              {(() => {
                if (
                  userType.includes("Admin") ||
                  userType.includes("Executive") ||
                  userType.includes("Manager") ||
                  userType.includes("Purchaser") ||
                  userType.includes("Sales Person") ||
                  userType.includes("Branch Manager") ||
                  permissions.includes("perm-ui-invoicingPage-view-write") ||
                  permissions.includes("perm-ui-transactionPage-view-write")
                ) {
                  return (
                    <Nav.Link
                      className="p-4"
                      style={{
                        display: "flex",
                        backgroundColor:
                          selectedNav === "btnActorManagement" &&
                          "var(--REB-tertiary)",
                      }}
                      onSelect={(x) => {
                        localStorage.setItem("PS", "btnActorManagement");
                        // setNavItems({
                        //   ...defaultNav,
                        //   btnContActor: { val: true, name: "Partners" },
                        // });
                        setSelectedNav("btnActorManagement");
                      }}
                      eventKey="btnActorManagement"
                    >
                      <i
                        className="icofont-users-alt-1"
                        style={{ fontSize: "2rem" }}
                      ></i>
                      <p
                        className="mb-0 ml-3"
                        style={{
                          alignSelf: "center",
                          fontWeight: "bolder",
                          fontSize: "1.5rem",
                        }}
                      >
                        Partners
                      </p>
                    </Nav.Link>
                  );
                }
              })()}
              {(() => {
                if (
                  userType.includes("Admin") ||
                  userType.includes("Manager") ||
                  permissions.includes("perm-ui-transactionPage")
                ) {
                  return (
                    <Nav.Link
                      className="p-4"
                      style={{
                        display: "flex",
                        backgroundColor:
                          selectedNav === "btnTransactions" &&
                          "var(--REB-tertiary)",
                      }}
                      onSelect={(x) => {
                        localStorage.setItem("PS", "btnTransactions");
                        // setNavItems({
                        //   ...defaultNav,
                        //   btnContPOS: { val: true, name: "POS" },
                        // });
                        setSelectedNav("btnTransactions");
                      }}
                      eventKey="btnTransactions"
                    >
                      <i
                        className="icofont-bank"
                        style={{ fontSize: "2rem" }}
                      ></i>
                      <p
                        className="mb-0 ml-3"
                        style={{
                          alignSelf: "center",
                          fontWeight: "bolder",
                          fontSize: "1.5rem",
                        }}
                      >
                        Transactions
                      </p>
                    </Nav.Link>
                  );
                }
              })()}
              {(() => {
                if (
                  userType.includes("Admin") ||
                  userType.includes("Manager") ||
                  permissions.includes("perm-ui-accountingPage")
                ) {
                  return (
                    <Nav.Link
                      className="p-4"
                      style={{
                        display: "flex",
                        backgroundColor:
                          selectedNav === "btnAccounts" &&
                          "var(--REB-tertiary)",
                      }}
                      onSelect={(x) => {
                        localStorage.setItem("PS", "btnAccounts");
                        // setNavItems({
                        //   ...defaultNav,
                        //   btnContPOS: { val: true, name: "POS" },
                        // });
                        setSelectedNav("btnAccounts");
                      }}
                      eventKey="btnAccounts"
                    >
                      <i
                        className="icofont-notebook"
                        style={{ fontSize: "2rem" }}
                      ></i>
                      <p
                        className="mb-0 ml-3"
                        style={{
                          alignSelf: "center",
                          fontWeight: "bolder",
                          fontSize: "1.5rem",
                        }}
                      >
                        Accounts
                      </p>
                    </Nav.Link>
                  );
                }
              })()}
              {(() => {
                if (
                  userType.includes("Admin") ||
                  user.permissions?.includes("perm-ui-chkPOS")
                ) {
                  return (
                    <Nav.Link
                      className="p-4"
                      style={{
                        display: "flex",
                        backgroundColor:
                          selectedNav === "btnPOS" && "var(--REB-tertiary)",
                      }}
                      onSelect={(x) => {
                        localStorage.setItem("PS", "btnPOS");
                        // setNavItems({
                        //   ...defaultNav,
                        //   btnContPOS: { val: true, name: "POS" },
                        // });
                        setSelectedNav("btnPOS");
                      }}
                      eventKey="btnPOS"
                    >
                      <i
                        className="icofont-tags"
                        style={{ fontSize: "2rem" }}
                      ></i>
                      <p
                        className="mb-0 ml-3"
                        style={{
                          alignSelf: "center",
                          fontWeight: "bolder",
                          fontSize: "1.5rem",
                        }}
                      >
                        POS
                      </p>
                    </Nav.Link>
                  );
                }
              })()}
              {(() => {
                if (userType.includes("Admin")) {
                  return (
                    <Nav.Link
                      className="p-4"
                      style={{
                        display: "flex",
                        backgroundColor:
                          selectedNav === "btnSettings" &&
                          "var(--REB-tertiary)",
                      }}
                      onSelect={(x) => {
                        localStorage.setItem("PS", "btnSettings");
                        // setNavItems({
                        //   ...defaultNav,
                        //   btnContPOS: { val: true, name: "POS" },
                        // });
                        setSelectedNav("btnSettings");
                      }}
                      eventKey="btnSettings"
                    >
                      <i
                        className="icofont-settings"
                        style={{ fontSize: "2rem" }}
                      ></i>
                      <p
                        className="mb-0 ml-3"
                        style={{
                          alignSelf: "center",
                          fontWeight: "bolder",
                          fontSize: "1.5rem",
                        }}
                      >
                        Settings
                      </p>
                    </Nav.Link>
                  );
                }
              })()}
              {/* <div
              style={{
                display: "flex",
                backgroundColor: "red",
                flexDirection: "column",

                marginTop: "auto",
              }}
            >
              
            </div> */}
              <Nav.Link
                className="p-4"
                style={{
                  display: "flex",
                  // backgroundColor:
                  //   selectedNav === "btnSettings" && "var(--REB-tertiary)",
                }}
                onClick={(x) => {
                  handleLogout();
                }}
                //eventKey="btnInventory"
              >
                <i
                  className="icofont-logout"
                  style={{ fontSize: "2rem", alignSelf: "center" }}
                ></i>
                <p
                  className="mb-0 ml-3"
                  style={{
                    alignSelf: "center",
                    fontWeight: "bolder",
                    fontSize: "1.5rem",
                  }}
                >
                  Logout
                </p>
              </Nav.Link>
            </div>
          </div>
        </div>

        <div
          className="stage"
          style={{ zIndex: 0, width: "auto", flex: 1, overflow: "auto" }}
        >
          {(() => {
            const CONTENT_TYPE = selectedNav;
            if (CONTENT_TYPE === "btnActorManagement") {
              // return <ActorProcess state={props.state} userType={userType} />;
              return <ActorProcess2 state={props.state} userType={userType} />;
            }
            if (CONTENT_TYPE === "btnVerification") {
              return (
                <VerificationProcess
                  state={{
                    ...props.state,
                    loggedUser: user,
                    selectedNav: selectedNav,
                    //content: content,
                  }}
                  userType={userType}
                />
              );
            }
            if (CONTENT_TYPE === "btnDashboard") {
              return (
                <DashboardProcess
                  state={{
                    ...props.state,
                    loggedUser: user,
                    selectedNav: selectedNav,
                    // content: content,
                  }}
                  userType={userType}
                />
              );
            }
            if (CONTENT_TYPE === "btnInventory") {
              return (
                <InventoryProcess
                  state={{
                    ...props.state,
                    loggedUser: user,
                    selectedNav: selectedNav,
                    //content: content,
                  }}
                  userType={userType}
                />
              );
            }
            if (CONTENT_TYPE === "btnInvoiceManagement") {
              return (
                <InvoiceProcess
                  state={{
                    ...props.state,
                    loggedUser: user,
                    selectedNav: selectedNav,
                    // content: content,
                  }}
                  userType={userType}
                />
              );
            }
            if (CONTENT_TYPE === "btnProfile") {
              return (
                <ProfileProcess
                  state={{
                    ...props.state,
                    user: user,
                    selectedNav: selectedNav,
                    //content: content,
                  }}
                  userType={userType}
                />
              );
            }
            if (CONTENT_TYPE === "btnPOS") {
              return (
                <POSProcess
                  state={{
                    ...props.state,
                    user: user,
                    selectedNav: selectedNav,
                    //content: content,
                  }}
                  userType={userType}
                />
              );
            }
            if (CONTENT_TYPE === "btnAccounts") {
              return (
                <AccountsProcess
                  state={{
                    ...props.state,
                    user: user,
                    selectedNav: selectedNav,
                    //content: content,
                  }}
                  userType={userType}
                />
              );
            }
            if (CONTENT_TYPE === "btnTransactions") {
              return (
                <TransactionsProcess
                  state={{
                    ...props.state,
                    user: user,
                    selectedNav: selectedNav,
                    //content: content,
                  }}
                  userType={userType}
                />
              );
            }
            if (CONTENT_TYPE === "btnSettings") {
              return (
                <SettingsProcess
                  state={{
                    ...props.state,
                    user: user,
                    selectedNav: selectedNav,
                    //content: content,
                  }}
                  userType={userType}
                />
              );
            }
          })()}
          {/* {props.content} */}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",

          zIndex: 1,
        }}
      >
        {process.env.REACT_APP_THEME === "SURFACEPLUS" && (
          <img
            src={bgImg}
            alt="Surfaceplus-logo"
            style={{
              objectFit: "fit",
              width: "100%",
              height: "100vh",
              overflow: "hidden",
              opacity: "0.65",
            }}
          ></img>
        )}
        {process.env.REACT_APP_THEME === "JNJ" && (
          <img
            src={bgImgJnj}
            alt="jnj-logo"
            style={{
              objectFit: "fit",
              width: "100%",
              height: "100vh",
              overflow: "hidden",
              opacity: "0.45",
            }}
          ></img>
        )}
        {process.env.REACT_APP_THEME === "DEMO" && (
          <img
            src={bgImgDemo}
            alt="demo-logo"
            style={{
              objectFit: "fit",
              width: "100%",
              height: "100vh",
              overflow: "hidden",
              opacity: "0.10",
            }}
          ></img>
        )}
      </div>
    </>
  );
};
