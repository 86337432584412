class InventoryAPI {
  getItemsByInventory = async (
    dispatch,
    id,
    sortBy,
    isPOS,
    startIndex,
    endIndex,
    disableLoad,
    itemStatus
  ) => {
    const data = await new Promise((resolve, reject) => {
      let filterData = []
      if (id) filterData.push(`id=${id}`);
      if (sortBy) filterData.push(`&sortBy=${sortBy}`);
      if (isPOS) filterData.push(`&isPOS=${isPOS}`);
      if (startIndex) filterData.push(`&startIndex=${startIndex}`);
      if (endIndex) filterData.push(`&endIndex=${endIndex}`);
      if (itemStatus) filterData.push(`&itemStatus=${itemStatus}`);
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `inventory/getItemsByInventory?${filterData.join("")}`,
        loadingMsg: "Fetching Items",
        ...(disableLoad ? { disableLoad: true } : {}),
        resolve: resolve,
        reject: reject,
      });
    }).then(async (api) => {
      let _data = api.data;

      return _data;
    });

    return data;
  };

  getTotalSales = async (dispatch, filter, cb) => {
    let filterData = [];
    if (filter.dateStart) filterData.push(`&dateStart=${filter.dateStart}`);
    if (filter.dateEnd) filterData.push(`&dateEnd=${filter.dateEnd}`);
    if (filter.isDaily) filterData.push(`&isDaily=${filter.isDaily}`);
    if (filter.inventoryId)
      filterData.push(`&inventoryId=${filter.inventoryId}`);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `inventory/getTotalSales?${filterData.join("")}`,
        loadingMsg: "Fetching Total Sales",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      if (cb) cb();
      return api.data;
    });
    return data;
  };
  getMostOrderedItemsComp = async (dispatch, filter, cb) => {
    let filterData = [];
    if (filter.dateStart) filterData.push(`&dateStart=${filter.dateStart}`);
    if (filter.dateEnd) filterData.push(`&dateEnd=${filter.dateEnd}`);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `inventory/getMostOrderedItemsComp?${filterData.join("")}`,
        loadingMsg: "Fetching Supplier Statistics",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      if (cb) cb();
      return api.data;
    });
    return data;
  };
  getMostOrderedItemsClients = async (dispatch, filter, cb) => {
    let filterData = [];
    if (filter.dateStart) filterData.push(`&dateStart=${filter.dateStart}`);
    if (filter.dateEnd) filterData.push(`&dateEnd=${filter.dateEnd}`);
    if (filter.limit) filterData.push(`&limit=${filter.limit}`);
    if (filter.isDaily) filterData.push(`&isDaily=${filter.isDaily}`);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `inventory/getMostOrderedItemsClients?${filterData.join("")}`,
        loadingMsg: "Fetching Client Statistics",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      if (cb) cb();
      return api.data;
    });
    return data;
  };
  getTopSellingItem = async (dispatch, filter, cb) => {
    let filterData = [];
    if (filter.dateStart) filterData.push(`&dateStart=${filter.dateStart}`);
    if (filter.dateEnd) filterData.push(`&dateEnd=${filter.dateEnd}`);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `inventory/getTopSellingItem?${filterData.join("")}`,
        loadingMsg: "Fetching Item Statistics",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      if (cb) cb();
      return api.data;
    });
    return data;
  };
  getInventoryStats = async (dispatch, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `inventory/getInventoryStats`,
        loadingMsg: "Fetching Item Stats",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      if (cb) cb();
      return api.data;
    });
    return data;
  };
  getItemCogsSummary = async (dispatch, id, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `inventory/getItemCogsSummary?id=${Number(id)}`,
        loadingMsg: "Fetching Item Stats",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      if (cb) cb();
      return api.data;
    });
    return data;
  };
  getItemPreviousPurchases = async (dispatch, id, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `inventory/getItemPreviousPurchases?id=${Number(id)}`,
        loadingMsg: "Fetching Item Stats",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      if (cb) cb();
      return api.data;
    });
    return data;
  };
  getItemPriceHistoryByItem = async (dispatch, id, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `history/getItemPriceHistoryByItem?itemId=${Number(id)}`,
        loadingMsg: "Fetching Item History",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      if (cb) cb();
      return api.data;
    });
    return data;
  };
 
  getInventory = async (dispatch) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `inventory/`,
        loadingMsg: "Fetching Inventories",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getInventoryRequests = async (dispatch) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `order/getInventoryRequests`,
        loadingMsg: "Fetching Inventory Requests",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getItem = async (dispatch, id, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `inventory/getItem?id=${id}`,
        loadingMsg: "Fetching Item",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );
    if (cb) await cb();
    return data;
  };
  getItemDetailsConst = async (dispatch) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `inventory/getItemConstants`,
        loadingMsg: "Fetching Item Details",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  getItemDetail = async (dispatch, type) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `inventory/getItemDetailsByType?type=${type}`,
        loadingMsg: "Fetching Item Details",
        resolve: resolve,
        reject: reject,
      });
    }).then((api) => {
      return api.data;
    });
    return data;
  };
  // getItem = async (dispatch, sdata, cb) => {
  //   const data = await new Promise((resolve, reject) => {
  //     dispatch({
  //       type: "USER_GET_REQUEST",
  //       payload: `inventory/getItemDetailsByType?type=${type}`,
  //       loadingMsg: "Fetching Item Details",
  //       resolve: resolve,
  //       reject: reject,
  //     });
  //   }).then((api) => {
  //     return api.data;
  //   });
  //   return data;
  // }
  createItem = async (dispatch, sdata, cb) => {
    //console.log(sdata);
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        payload: `inventory/createItem`,
        loadingMsg: "Creating item",
        reqType: "post",
        data: sdata,
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );
    if (cb) await cb();
    return data;
  };
  updateItem = async (dispatch, sData, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "put",
        data: sData,
        payload: "inventory/updateItem",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );
    if (cb) await cb();
    return data;
  };

  addItemQty = async (dispatch, sdata, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "put",
        data: { id: sdata.id, qty: sdata.qty },
        payload: "inventory/addItemQty",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );
    if (cb) await cb();
    return data;
  };
  subtractItemQty = async (dispatch, sdata, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "put",
        data: { id: sdata.id, qty: sdata.qty },
        payload: "inventory/subtractItemQty",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );
    if (cb) await cb();
    return data;
  };
  transferDisplayToAvailItem = async (dispatch, itemId, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "put",
        data: { id: Number(itemId) },
        payload: "inventory/transferDisplayToAvailItem",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );
    if (cb) await cb();
    return data;
  };

  subtractItemQtyMany = async (dispatch, sdata, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "put",
        data: { data: sdata, qty: sdata.qty },
        payload: "inventory/subtractItemQtyMany",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );
    if (cb) await cb();
    return data;
  };
  addItemQtyMany = async (dispatch, sdata, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "put",
        data: { data: sdata, qty: sdata.qty },
        payload: "inventory/addItemQtyMany",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );
    if (cb) await cb();
    return data;
  };

  deleteItem = async (dispatch, id, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "delete",
        data: { id: id },
        payload: "inventory/deleteItem",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );
    if (cb) await cb();
    return data;
  };
  deleteitemDetail = async (dispatch, id, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "delete",
        data: { id: id },
        payload: "inventory/deleteItemDetail",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      (onfulfilled) => {
        //console.log(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );
    if (cb) await cb();
    return data;
  };

  addItemImg = async (dispatch, formData, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "post",
        data: formData,
        payload: "inventory/uploadItemImgs",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      async (onfulfilled) => {
        if (cb) await cb(onfulfilled);
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };

  downloadItemImg = async (dispatch, item, cb, indexMax) => {
    let imgs = [];
    let _index = 0;

    //console.log(item, indexMax);

    if (indexMax !== undefined) {
      _index = indexMax;
    } else {
      if (item.files === undefined) {
        if (item.uploads !== null) {
          _index = item.uploads.length;
        }
      } else {
        if (item.files !== null) {
          _index = JSON.parse(item.files).length;
        }
      }
    }

    for (let index = 0; index < _index; index++) {
      let e = "";
      if (item.files === undefined) e = item.uploads[index];
      else e = JSON.parse(item.files)[index];

      //console.log(e);
      await new Promise((resolve, reject) => {
        dispatch({
          type: "USER_GET_REQUEST",
          reqType: "getFile",
          payload: `inventory/getItemImg?id=${
            item.id
          }&filename=${encodeURIComponent(e.filename)}`,
          loadingMsg: "Fetching Images",
          resolve: resolve,
          reject: reject,
        });
      }).then(
        async (onfulfilled) => {
          const blob = new Blob([onfulfilled.data]);
          const url = URL.createObjectURL(blob);

          imgs.push({ original: url, filename: e.filename });
          //console.log(onfulfilled.data);
          return onfulfilled.data;
        },
        (onrejected) => {
          //return onrejected.data;
          //this.handleDropCancel();
        }
      );
    }
    return imgs;
  };

  deleteItemImg = async (dispatch, dat, cb) => {
    const data = await new Promise((resolve, reject) => {
      dispatch({
        type: "USER_GET_REQUEST",
        reqType: "delete",
        data: dat,
        payload: "inventory/deleteItemImg",
        resolve: resolve,
        reject: reject,
      });
    }).then(
      async (onfulfilled) => {
        if (cb) await cb(onfulfilled);
        dispatch({
          type: "TOAST_ADD",
          payload: {
            title: `File Deleted Successfully`,
          },
        });
        return onfulfilled.data;
      },
      (onrejected) => {
        //return onrejected.data;
        //this.handleDropCancel();
      }
    );

    return data;
  };
}
export default new InventoryAPI();
