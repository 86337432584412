import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";

import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
//import { PDFDownloadLink } from "@react-pdf/renderer";

import { NumericFormat } from "react-number-format";

import { Modal } from "react-bootstrap";
import misc from "../../functions/misc";
const TxtDiscountPercent = (props) => {
  const { value, setter, calculate } = props;
  let { PRICE_MODIFIER = 0, SRP = 0 } = props;
  return (
    <>
      <p className="m-0 p-0">Percent Discount</p>
      <FormControl
        as={NumericFormat}
        inputMode="numeric"
        thousandSeparator={true}
        prefix={"% "}
        style={{ flex: 1 }}
        placeholder="Discount"
        name="txtDiscount"
        allowNegative={false}
        decimalScale={2}
        value={value}
        isAllowed={(values) => {
          const { formattedValue, floatValue } = values;
          return formattedValue === "" || floatValue <= 100;
        }}
        onValueChange={(x, e) => {
          e.source === "event" && calculate(Number(x.value), SRP);
        }}
        className=""
      />
    </>
  );
};
const TxtDiscountAmount = (props) => {
  const { value, setter, calculate } = props;
  let { PRICE_MODIFIER = 0, SRP = 0 } = props;

  return (
    <>
      <p className="m-0 p-0">Discount Amount</p>
      <FormControl
        as={NumericFormat}
        inputMode="numeric"
        thousandSeparator={true}
        style={{ flex: 1 }}
        placeholder="Discounted SRP"
        name="txtDiscounted"
        prefix={"₱ "}
        // disabled={true}
        // readOnly={true}
        allowNegative={false}
        decimalScale={2}
        value={value}
        isAllowed={(values) => {
          const { formattedValue, floatValue } = values;

          if (PRICE_MODIFIER) {
            SRP = SRP + SRP * (PRICE_MODIFIER / 100);
          }
          return (
            formattedValue === "" || (floatValue <= SRP && floatValue >= 0)
          );
        }}
        onValueChange={(x, e) => {
          e.source === "event" && calculate(Number(x.value), SRP);
        }}
        className=""
      />
    </>
  );
};
const TxtDiscountedWhole = (props) => {
  const { value, setter, calculate } = props;
  let { PRICE_MODIFIER = 0, SRP = 0 } = props;
  return (
    <>
      <p className="m-0 p-0">Discounted Price</p>
      <FormControl
        as={NumericFormat}
        inputMode="numeric"
        thousandSeparator={true}
        style={{ flex: 1 }}
        allowNegative={false}
        decimalScale={2}
        prefix={"₱ "}
        placeholder="Amount Discounted"
        name="txtDiscountedWhole"
        value={value}
        isAllowed={(values) => {
          const { formattedValue, floatValue } = values;

          if (PRICE_MODIFIER) {
            SRP = SRP + SRP * (PRICE_MODIFIER / 100);
          }

          return (
            formattedValue === "" || (floatValue <= SRP && floatValue >= 0)
          );
        }}
        onValueChange={(x, e) => {
          e.source === "event" && calculate(Number(x.value), SRP);
        }}
        className=""
      />
    </>
  );
};

const TxtMarkupPercent = (props) => {
  const { value, setter, calculate } = props;
  let { PRICE_MODIFIER = 0, SRP = 0 } = props;
  return (
    <>
      <h5>Markup Percent</h5>
      <FormControl
        as={NumericFormat}
        inputMode="numeric"
        thousandSeparator={true}
        prefix={"% "}
        style={{ flex: 1 }}
        placeholder="Markup Percent"
        name="txtMarkupPercent"
        allowNegative={false}
        decimalScale={2}
        value={value}
        onValueChange={(x, e) => {
          e.source === "event" && calculate(Number(x.value), SRP);
        }}
        className=""
      />
    </>
  );
};
const TxtMarkupAmount = (props) => {
  const { value, setter, calculate } = props;
  let { PRICE_MODIFIER = 0, SRP = 0 } = props;
  return (
    <>
      <h5>Markup Amount</h5>
      <FormControl
        as={NumericFormat}
        inputMode="numeric"
        thousandSeparator={true}
        style={{ flex: 1 }}
        placeholder="Markup Amount"
        name="txtMarkupAmount"
        prefix={"₱ "}
        // disabled={true}
        // readOnly={true}
        allowNegative={false}
        decimalScale={2}
        value={value}
        isAllowed={(values) => {
          const { formattedValue, floatValue } = values;

          return formattedValue === "" || floatValue > 0;
        }}
        onValueChange={(x, e) => {
          e.source === "event" && calculate(Number(x.value), SRP);
        }}
        className=""
      />
    </>
  );
};
const TxtMarkupWhole = (props) => {
  const { value, setter, calculate } = props;
  let { PRICE_MODIFIER = 0, SRP = 0 } = props;
  return (
    <>
      <h5>Marked Up Price</h5>
      <FormControl
        as={NumericFormat}
        inputMode="numeric"
        thousandSeparator={true}
        style={{ flex: 1 }}
        allowNegative={false}
        decimalScale={2}
        prefix={"₱ "}
        placeholder="Amount Discounted"
        name="txtMarkupWhole"
        value={value}
        isAllowed={(values) => {
          const { formattedValue, floatValue } = values;
          return formattedValue === "" || floatValue > 0;
        }}
        onValueChange={(x, e) => {
          e.source === "event" && calculate(Number(x.value), SRP);
        }}
        className=""
      />
    </>
  );
};

export const PriceAdjustModal = (props) => {
  const {
    PriceAdjustModalOpen,
    setPriceAdjustModalOpen,
    SelectedCartList,
    UITxtDiscountPercent,
    setUITxtDiscountPercent,
    UITxtDiscountAmount,
    setUITxtDiscountAmount,
    UITxtDiscountedWhole,
    setUITxtDiscountedWhole,
    UITxtMarkupPercent,
    setUITxtMarkupPercent,
    UITxtMarkupAmount,
    setUITxtMarkupAmount,
    UITxtMarkupWhole,
    setUITxtMarkupWhole,
    Settings,
    CartData,
    setCartData,
    setCartDataCopy,
    setCartModalOpen,
    setSelectedCartList,
  } = props;
  const dispatch = useDispatch();

  const permissions = useSelector((state) => {
    const user = state.apiData.userData;
    let _permissions = [];
    if (user && user.permissions) {
      _permissions = JSON.parse(user.permissions);
    }
    return _permissions.toString();
  });

  const [IsMarkup, setIsMarkup] = useState(false);
  const [IsMarkdown, setIsMarkdown] = useState(false);

  useEffect(() => {
    setIsMarkup(false);
    setIsMarkdown(false);
  }, [PriceAdjustModalOpen]);

  //Triggers on markup, markdown button is pressed
  useEffect(() => {
    resetInputs();

    //If marking-up item, reset selected item to SRP price so that
    //markup price will reset and will base calulation to SRP
    if (IsMarkup) {
      let _CartSelection = [];
      _CartSelection = CartData.map((x) => {
        return x.id === SelectedCartList?.id
          ? {
              ...x,
              discount: 0,
              priceModifier: Settings.itemPriceModification
                ? Settings.itemPriceModification
                : 0,
              price: SelectedCartList?.srp,
            }
          : x;
      });

      setCartData(_CartSelection);
      setCartDataCopy(_CartSelection);
      setSelectedCartList(
        _CartSelection.find((x) => x.id === SelectedCartList?.id)
      );
    }
    //If marking-down, use marked-up item price as baseprice
    if (IsMarkdown) {
      const modifiedPrice = misc.calculateMarkupDiscount(
        SelectedCartList?.srp,
        SelectedCartList?.discount,
        SelectedCartList?.priceModifier,
        Settings?.itemPriceModification
      );
      CalculateDiscountPercent(SelectedCartList.discount, modifiedPrice);
      //setUITxtDiscountPercent(SelectedCartList.discount);
    }
  }, [IsMarkup, IsMarkdown]);

  const resetInputs = () => {
    setUITxtDiscountPercent("");
    setUITxtDiscountAmount("");
    setUITxtDiscountedWhole("");
    setUITxtMarkupPercent("");
    setUITxtMarkupAmount("");
    setUITxtMarkupWhole("");
  };

  const CalculateDiscountPercent = (value, SRP, PRICE_MODIFIER) => {
    if (PRICE_MODIFIER) {
      SRP = SRP + SRP * (PRICE_MODIFIER / 100);
    }

    const discountedWhole = SRP - (value / 100) * SRP;
    const discounted = SRP - discountedWhole;

    setUITxtDiscountPercent(value);
    setUITxtDiscountAmount(Number(discounted));
    setUITxtDiscountedWhole(Number(discountedWhole));
  };
  const CalculateDiscountAmount = (
    value,
    SRP,
    PRICE_MODIFIER = 0,
    percentDiscount = 0
  ) => {
    console.log(SRP);
    if (PRICE_MODIFIER) {
      SRP = SRP + SRP * (PRICE_MODIFIER / 100);
    }

    const discounted = value;
    const discountedWhole = SRP - discounted;
    const discount = (discounted / SRP) * 100;

    setUITxtDiscountPercent(discount);
    setUITxtDiscountAmount(Number(discounted));
    setUITxtDiscountedWhole(Number(discountedWhole));
  };
  const CalculateDiscountedWhole = (
    value,
    SRP,
    PRICE_MODIFIER = 0,
    percentDiscount = 0
  ) => {
    if (PRICE_MODIFIER) {
      SRP = SRP + SRP * (PRICE_MODIFIER / 100);
    }

    const discountedWhole = Number(value);

    percentDiscount = Number(((SRP - discountedWhole) / SRP) * 100);
    const discounted = (percentDiscount / 100) * SRP;

    setUITxtDiscountPercent(percentDiscount);
    setUITxtDiscountAmount(Number(discounted));
    setUITxtDiscountedWhole(Number(discountedWhole));
  };

  const CalculateMarkupPercent = (percentDiscount, SRP, PRICE_MODIFIER) => {
    const discountedWhole = SRP + (percentDiscount / 100) * SRP;
    const markupAmount = (percentDiscount / 100) * SRP;

    setUITxtMarkupPercent(percentDiscount);
    setUITxtMarkupAmount(Number(markupAmount));
    setUITxtMarkupWhole(Number(discountedWhole));
  };
  const CalculateMarkupAmount = (value, SRP, PRICE_MODIFIER) => {
    const discounted = value;
    const discountedWhole = SRP + discounted;
    const discount = (discounted / SRP) * 100;

    setUITxtMarkupPercent(discount);
    setUITxtMarkupAmount(Number(discounted));
    setUITxtMarkupWhole(Number(discountedWhole));
  };
  const CalculateMarkupWhole = (value, SRP, PRICE_MODIFIER) => {
    if (Number(value) >= SRP) {
      const discountedWhole = Number(value);
      const percentDiscount = Number(((discountedWhole - SRP) / SRP) * 100);
      const discounted = (percentDiscount / 100) * SRP;

      setUITxtMarkupPercent(percentDiscount);
      setUITxtMarkupAmount(Number(discounted));
      setUITxtMarkupWhole(Number(discountedWhole));
    } else {
      setUITxtMarkupPercent(0);
      setUITxtMarkupAmount(0);
    }
  };

  return (
    <>
      <Modal
        show={PriceAdjustModalOpen}
        onHide={() => {
          setPriceAdjustModalOpen(false);
        }}
        onExit={(e) => {
          setPriceAdjustModalOpen(false);
        }}
        dialogClassName=""
        centered
      >
        <Modal.Header
          className="p-0 global-modal-header"
          style={{ backgroundColor: "var(--REB-primary)" }}
        >
          <Modal.Title className="p-2 global-modal-header-title">
            <Row noGutters>
              <Col xs="auto" className="h-100">
                <Button
                  style={{ backgroundColor: "var(--REB-warning)" }}
                  onClick={() => {
                    setPriceAdjustModalOpen(false);
                  }}
                  className="h-100"
                >
                  <i className="icofont-ui-close"></i>
                </Button>
              </Col>
              <Col xs="auto" className="pl-3" style={{ alignSelf: "center" }}>
                <h6
                  className="text-center m-0 h-100"
                  style={{ color: "var(--REB-white)", fontWeight: "bolder" }}
                >
                  Price Adjust
                </h6>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3" noGutters>
            <Col xs={12}>
              <h5>{SelectedCartList?.name}</h5>
            </Col>
            <Col xs={6} className="px-2">
              {(permissions.includes("perm-ui-chkPOSMarkdown") ||
                props.CURRENT_USERTYPE.includes("Admin")) && (
                <Button
                  block
                  onClick={() => {
                    setIsMarkdown(true);
                    setIsMarkup(false);
                  }}
                  style={{
                    backgroundColor: IsMarkdown
                      ? "var(--REB-tertiary)"
                      : "var(--REB-info)",
                  }}
                >
                  Markdown Item
                </Button>
              )}
            </Col>
            <Col xs={6} className="px-2">
              {(permissions.includes("perm-ui-chkPOSMarkup") ||
                props.CURRENT_USERTYPE.includes("Admin")) && (
                <Button
                  block
                  onClick={() => {
                    setIsMarkup(true);
                    setIsMarkdown(false);
                  }}
                  style={{
                    backgroundColor: IsMarkup
                      ? "var(--REB-tertiary)"
                      : "var(--REB-info)",
                  }}
                >
                  Markup Item
                </Button>
              )}
            </Col>

            {/* <Col xs={6}>
              {IsMarkup && (
                <Button
                  block
                  onClick={() => {
                    setIsMarkup(false);
                  }}
                >
                  Markdown Item
                </Button>
              )}
            </Col>
            <Col xs={6}>
              {!IsMarkup && (
                <Button
                  block
                  onClick={() => {
                    setIsMarkup(true);
                  }}
                >
                  Markup Item
                </Button>
              )}
            </Col> */}
            <Col xs={12} className="my-2">
              <h5 style={{ textAlign: "center" }}>
                {IsMarkup && "Markup Item"}
                {IsMarkdown && "Markdown Item"}
              </h5>
            </Col>
            <Col xs={12} className="my-2">
              <h5 style={{ textAlign: "center" }}>
                Item Current Price (SRP) : {SelectedCartList?.price}
                {/* {misc.calculateMarkupDiscount(
                  SelectedCartList?.price,
                  SelectedCartList?.discount,
                  SelectedCartList?.priceModifier,
                  Settings?.itemPriceModification
                )} */}
              </h5>
              <h2 style={{ textAlign: "center" }}>
                <i className="icofont-swoosh-down"></i>
              </h2>
              {IsMarkup && (
                <>
                  <h5 style={{ textAlign: "center" }}>
                    Adjusted Item Price : {Number(UITxtMarkupWhole).toFixed(2)}
                  </h5>
                </>
              )}
              {IsMarkdown && (
                <>
                  <h5 style={{ textAlign: "center" }}>
                    Adjusted Item Price :{" "}
                    {Number(UITxtDiscountedWhole).toFixed(2)}
                  </h5>
                </>
              )}
            </Col>
            <Col xs={12}>
              {IsMarkdown && (
                <>
                  <TxtDiscountPercent
                    value={UITxtDiscountPercent}
                    calculate={CalculateDiscountPercent}
                    SRP={SelectedCartList?.price}
                  />
                  <TxtDiscountAmount
                    value={UITxtDiscountAmount}
                    calculate={CalculateDiscountAmount}
                    SRP={SelectedCartList?.price}
                  />
                  <TxtDiscountedWhole
                    value={UITxtDiscountedWhole}
                    calculate={CalculateDiscountedWhole}
                    SRP={SelectedCartList?.price}
                  />
                </>
              )}
              {IsMarkup && (
                <>
                  <TxtMarkupPercent
                    value={UITxtMarkupPercent}
                    calculate={CalculateMarkupPercent}
                    SRP={SelectedCartList?.price}
                  />
                  <TxtMarkupAmount
                    value={UITxtMarkupAmount}
                    calculate={CalculateMarkupAmount}
                    SRP={SelectedCartList?.price}
                  />
                  <TxtMarkupWhole
                    value={UITxtMarkupWhole}
                    calculate={CalculateMarkupWhole}
                    SRP={SelectedCartList?.price}
                  />
                </>
              )}
            </Col>
            {/* <Col xs className="px-2">
              <Form.Label
                className="font-weight-bold"
                style={{ color: "var(--REB-black)" }}
              >
                <span className="required-field">* </span>
                Cash Tendered
              </Form.Label>
              <FormControl
                as={NumericFormat}
                inputMode="numeric"
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={"₱ "}
                style={{}}
                isInvalid={ValidateCheckout.TxtCashTendered.error}
                placeholder="Cash Tendered"
                value={TxtCashTendered}
                onValueChange={(x) => {
                  setTxtCashTendered(Number(x.value));
                  const _change = Number(Number(x.value) - UIAmountDue);
                  //console.log(_change);
                  if (_change >= 0) {
                    setUIAmountChange(_change);
                  } else {
                    setUIAmountChange(0);
                  }
                }}
                className={""}
              />
              {ValidateCheckout.TxtCashTendered.error && (
                <p style={{ color: " var(--REB-warning)", fontSize: "0.8rem" }}>
                  {ValidateCheckout.TxtCashTendered.message}
                </p>
              )}
            </Col> */}
          </Row>
        </Modal.Body>
        <Modal.Footer className="global-modal-footer">
          <Row noGutters>
            <Col xs>
              <Button
                onClick={() => {
                  let markup = 0;
                  let discount = 0;

                  if (IsMarkup) {
                    markup = UITxtMarkupPercent;
                  }
                  if (IsMarkdown) {
                    discount = UITxtDiscountPercent;
                  }

                  if (discount || markup) {
                    let _CartSelection = [];
                    //_CartSelection = _CartSelection.concat(CartData);
                    _CartSelection = CartData.map((x) => {
                      return x.id === SelectedCartList?.id
                        ? {
                            ...x,
                            discount: discount,
                            priceModifier: markup
                              ? markup
                              : Settings.itemPriceModification,
                            price: misc.calculateMarkupDiscount(
                              x.srp,
                              discount,
                              markup,
                              Settings.itemPriceModification
                            ),
                          }
                        : x;
                    });
                    console.log(_CartSelection);
                    // _CartSelection = _CartSelection.filter((x) => x.qty !== 0);
                    setCartData(_CartSelection);
                    setCartDataCopy(_CartSelection);
                    setCartModalOpen(false);
                    setPriceAdjustModalOpen(false);
                  } else {
                    dispatch({
                      type: "MODAL_SHOW",
                      payload: {
                        title: "Validation Error",
                        desc: `Please check inputs.`,
                        isYesNo: false,
                      },
                    });
                  }
                }}
                style={{ backgroundColor: "var(--REB-tertiary)" }}
              >
                Apply Price Adjustment
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};
