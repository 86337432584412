import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { NumericFormat } from "react-number-format";

import moment from "moment";

import Spinner from "react-bootstrap/Spinner";

import AccountsAPI from "../Accounts/AccountsAPI";

export const DashboardStatistics = (props) => {
  const dispatch = useDispatch();

  //ACCOUNTS DATA
  // const [AccountsAssets, setAccountsAssets] = useState([]);
  // const [AccountsExpenses, setAccountsExpenses] = useState([]);
  // const [AccountsLiabilities, setAccountsLiabilities] = useState([]);
  // const [AccountsEquity, setAccountsEquity] = useState([]);
  // const [AccountsRevenues, setAccountsRevenues] = useState([]);

  const [TxtDailyTotalSales, setTxtDailyTotalSales] = useState(0);
  const [TxtDailyTotalExpenses, setTxtDailyTotalExpenses] = useState(0);
  const [TxtDailyTotalRevenue, setTxtDailyTotalRevenue] = useState(0);

  const [TxtMonthlyTotalSales, setTxtMonthlyTotalSales] = useState(0);
  const [TxtMonthlyTotalExpenses, setTxtMonthlyTotalExpenses] = useState(0);
  const [TxtMonthlyTotalRevenue, setTxtMonthlyTotalRevenue] = useState(0);
  const [TxtMonthlyTotalReceivables, setTxtMonthlyTotalReceivables] =
    useState(0);
  const [TxtMonthlyTotalPayable, setTxtMonthlyTotalPayable] = useState(0);

  // const [LoadingDailyTotalSales, setLoadingDailyTotalSales] = useState(true);
  // const [LoadingDailyTotalExpenses, setLoadingDailyTotalExpenses] =
  //   useState(true);
  // const [LoadingDailyTotalRevenue, setLoadingDailyTotalRevenue] =
  //   useState(true);

  // const [LoadingMonthlyTotalSales, setLoadingMonthlyTotalSales] =
  //   useState(true);
  // const [LoadingMonthlyTotalExpenses, setLoadingMonthlyTotalExpenses] =
  //   useState(true);
  // const [LoadingMonthlyTotalRevenue, setLoadingMonthlyTotalRevenue] =
  //   useState(true);
  // const [LoadingMonthlyTotalReceivables, setLoadingMonthlyTotalReceivables] =
  //   useState(0);
  // const [LoadingMonthlyTotalPayable, setLoadingMonthlyTotalPayable] =
  //   useState(true);

  // const [InitialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    PopulateStatistics();
  }, [
    props.state.settings,
    props.state.selInventory.value,
    props.state.dpStartDate.value,
  ]);

  const PopulateStatistics = async () => {
    const filter = {
      dateStart: moment(props.state.dpStartDate.value).format("YYYY-MM-DD"),
    };

    // console.log(moment(props.state.dpStartDate.value).format("YYYY-MM-DD"))

    const statSalesAccountId = props.state?.settings?.statSalesAccountId;
    const statProfitAccountId = props.state?.settings?.statProfitAccountId;
    const statExpenseAccountId = props.state?.settings?.statExpenseAccountId;
    const statActsReceivableAccountId =
      props.state?.settings?.statActsReceivableAccountId;
    const statActsPayableAccountId =
      props.state?.settings?.statActsPayableAccountId;

    //console.log(props.state.settings.statSalesAccountId);

    const fetchSales = await AccountsAPI.getAccountStatsById(
      dispatch,
      statSalesAccountId,
      filter
    );
    const fetchProfit = await AccountsAPI.getAccountStatsById(
      dispatch,
      statProfitAccountId,
      filter
    );
    const fetchExpense = await AccountsAPI.getAccountStatsById(
      dispatch,
      statExpenseAccountId,
      filter
    );
    const fetchActsReceivable = await AccountsAPI.getAccountStatsById(
      dispatch,
      statActsReceivableAccountId,
      {
        dateStart: moment(props.state.dpStartDate.value).format("YYYY-MM-01"),
      }
    );
   
    const fetchActsPayable = await AccountsAPI.getAccountStatsById(
      dispatch,
      statActsPayableAccountId,

      {
        dateStart: moment(props.state.dpStartDate.value).format("YYYY-MM-01"),
      }
    );
    setTxtMonthlyTotalReceivables(fetchActsReceivable.total);
    setTxtMonthlyTotalPayable(fetchActsPayable.total);
    // console.log(
    //   fetchSales,
    //   fetchProfit,
    //   fetchExpense,
    //   fetchActsReceivable,
    //   fetchActsPayable
    // );
  };

  const ResetUI = () => {
    setTxtDailyTotalSales(0);
    setTxtDailyTotalExpenses(0);
    setTxtDailyTotalRevenue(0);

    setTxtMonthlyTotalSales(0);
    setTxtMonthlyTotalExpenses(0);
    setTxtMonthlyTotalRevenue(0);
    setTxtMonthlyTotalReceivables(0);
    setTxtMonthlyTotalPayable(0);
  };

  return (
    <>
      <Row className="   my-3 mb-3" noGutters>
        <Col
          md={4}
          lg={4}
          style={{}}
          className="res-pr-2 animate__animated animate__fadeInLeft"
        >
          {/* <Button
                type="button"
                className="btnLogin"
                style={{
                  backgroundColor: "var(--REB-secondary)",
                  // boxShadow:
                  //   "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
                  width: "50%",
                }}
                onClick={()=>{
                  
                  
                  new Promise((resolve, reject) => {
                    dispatch({
                      type: "USER_GET_REQUEST",
                      reqType: "post",
                      data: {data:'='},
                      payload: "global/emitPrint",
                      resolve: resolve,
                      reject: reject,
                    });
                  }).then()
                }}

                //disabled={!props.state.isServerOnline}
              >
                PRINT
              </Button> */}
          <Row className="globalContainerStyle p-3 res-mb-3" noGutters>
            <Col xs={3} style={{ flexDirection: "column" }}>
              <h5
                className="m-0 p-0"
                style={{
                  fontWeight: "bolder",
                  alignSelf: "center",
                }}
              >
                <i className="icofont-cart icofont-2x"></i>
              </h5>
            </Col>
            {props.state.getTotalSalesDailyLoading ? (
              <>
                <Col xs={9} style={{ textAlign: "center" }}>
                  <Spinner animation="border" />
                </Col>
              </>
            ) : (
              <>
                <Col
                  xs={9}
                  className="animate__animated animate__fadeInLeft"
                  style={{ alignSelf: "center" }}
                >
                  <h4
                    className="m-0 p-0"
                    style={{
                      fontWeight: "bolder",
                      alignSelf: "center",
                      textAlign: "center",
                    }}
                  >
                    ₱{" "}
                    <NumericFormat
                      value={
                        props.state.TotalSalesDaily.totalSales !== undefined
                          ? Number(
                              props.state.TotalSalesDaily.totalSales
                            ).toFixed(2)
                          : 0
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </h4>
                </Col>
              </>
            )}

            <Col xs={12} style={{ flexDirection: "column" }}>
              <h5
                className="m-0 p-0"
                style={{
                  fontWeight: "bolder",
                  alignSelf: "center",
                }}
              >
                Sales Today
              </h5>
            </Col>
          </Row>
        </Col>
        <Col
          md={4}
          lg={4}
          style={{}}
          className="res-px-1 animate__animated animate__fadeInLeft"
        >
          <Row className="globalContainerStyle p-3 res-mb-3" noGutters>
            <Col xs={3} style={{ flexDirection: "column" }}>
              <h5
                className="m-0 p-0"
                style={{
                  fontWeight: "bolder",
                  alignSelf: "center",
                }}
              >
                <i className="icofont-wallet icofont-2x"></i>
              </h5>
            </Col>
            {props.state.getTotalSalesDailyLoading ? (
              <>
                <Col xs={9} style={{ textAlign: "center" }}>
                  <Spinner animation="border" />
                </Col>
              </>
            ) : (
              <>
                <Col
                  xs={9}
                  className="animate__animated animate__fadeInLeft"
                  style={{ alignSelf: "center" }}
                >
                  <h4
                    className="m-0 p-0"
                    style={{
                      fontWeight: "bolder",
                      alignSelf: "center",
                      textAlign: "center",
                    }}
                  >
                    ₱{" "}
                    <NumericFormat
                      value={
                        props.state.TotalSalesDaily.profits !== undefined
                          ? Number(props.state.TotalSalesDaily.profits).toFixed(
                              2
                            )
                          : 0
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </h4>
                </Col>
              </>
            )}

            <Col xs={12} style={{ flexDirection: "column" }}>
              <h5
                className="m-0 p-0"
                style={{
                  fontWeight: "bolder",
                  alignSelf: "center",
                }}
              >
                Profit Today
              </h5>
            </Col>
          </Row>
        </Col>
        {/* <Col
          md={4}
          lg={4}
          style={{}}
          className="res-pl-2 animate__animated animate__fadeInLeft"
        >
          <Row className="globalContainerStyle p-3 res-mb-3" noGutters>
            <Col xs={3} style={{ flexDirection: "column" }}>
              <h5
                className="m-0 p-0"
                style={{
                  fontWeight: "bolder",
                  alignSelf: "center",
                }}
              >
                <i className="icofont-coins icofont-2x"></i>
              </h5>
            </Col>
            {props.state.getTotalSalesDailyLoading ? (
              <>
                <Col xs={9} style={{ textAlign: "center" }}>
                  <Spinner animation="border" />
                </Col>
              </>
            ) : (
              <>
                <Col
                  xs={9}
                  className="animate__animated animate__fadeInLeft"
                  style={{ alignSelf: "center" }}
                >
                  <h4
                    className="m-0 p-0"
                    style={{
                      fontWeight: "bolder",
                      alignSelf: "center",
                      textAlign: "center",
                    }}
                  >
                    ₱{" "}
                    <NumericFormat
                      value={
                        props.state.TotalSalesDaily.totalSpent !== undefined
                          ? Number(
                              props.state.TotalSalesDaily.totalSpent
                            ).toFixed(2)
                          : 0
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </h4>
                </Col>
              </>
            )}

            <Col xs={12} style={{ flexDirection: "column" }}>
              <h5
                className="m-0 p-0"
                style={{
                  fontWeight: "bolder",
                  alignSelf: "center",
                }}
              >
                Expenses Today
              </h5>
            </Col>
          </Row>
        </Col> */}
      </Row>
      <Row className="mb-3 " noGutters>
        <Col
          md={4}
          lg={4}
          style={{}}
          className="res-pr-2 animate__animated animate__fadeInLeft"
        >
          <Row className="globalContainerStyle p-3 res-mb-3" noGutters>
            <Col xs={3} style={{ flexDirection: "column" }}>
              <h5
                className="m-0 p-0"
                style={{
                  fontWeight: "bolder",
                  alignSelf: "center",
                }}
              >
                <i className="icofont-cart icofont-2x"></i>
              </h5>
            </Col>
            {props.state.getTotalSalesLoading ? (
              <>
                <Col xs={9} style={{ textAlign: "center" }}>
                  <Spinner animation="border" />
                </Col>
              </>
            ) : (
              <>
                <Col
                  xs={9}
                  className="animate__animated animate__fadeInLeft"
                  style={{ alignSelf: "center" }}
                >
                  <h4
                    className="m-0 p-0"
                    style={{
                      fontWeight: "bolder",
                      alignSelf: "center",
                      textAlign: "center",
                    }}
                  >
                    ₱{" "}
                    <NumericFormat
                      value={
                        props.state.TotalSales.totalSales !== undefined
                          ? Number(props.state.TotalSales.totalSales).toFixed(2)
                          : 0
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </h4>
                </Col>
              </>
            )}

            <Col xs={12} style={{ flexDirection: "column" }}>
              <h5
                className="m-0 p-0"
                style={{
                  fontWeight: "bolder",
                  alignSelf: "center",
                }}
              >
                Sales ({props.state.SelectedMonth})
              </h5>
            </Col>
          </Row>
        </Col>
        <Col
          md={4}
          lg={4}
          style={{}}
          className="res-px-1 animate__animated animate__fadeInLeft"
        >
          <Row className="globalContainerStyle p-3 res-mb-3" noGutters>
            <Col xs={3} style={{ flexDirection: "column" }}>
              <h5
                className="m-0 p-0"
                style={{
                  fontWeight: "bolder",
                  alignSelf: "center",
                }}
              >
                <i className="icofont-wallet icofont-2x"></i>
              </h5>
            </Col>
            {props.state.getTotalSalesLoading ? (
              <>
                <Col xs={9} style={{ textAlign: "center" }}>
                  <Spinner animation="border" />
                </Col>
              </>
            ) : (
              <>
                <Col
                  xs={9}
                  className="animate__animated animate__fadeInLeft"
                  style={{ alignSelf: "center" }}
                >
                  <h4
                    className="m-0 p-0"
                    style={{
                      fontWeight: "bolder",
                      alignSelf: "center",
                      textAlign: "center",
                    }}
                  >
                    ₱{" "}
                    <NumericFormat
                      value={
                        props.state.TotalSales.profits !== undefined
                          ? Number(props.state.TotalSales.profits).toFixed(2)
                          : 0
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </h4>
                </Col>
              </>
            )}

            <Col xs={12} style={{ flexDirection: "column" }}>
              <h5
                className="m-0 p-0"
                style={{
                  fontWeight: "bolder",
                  alignSelf: "center",
                }}
              >
                Profit ({props.state.SelectedMonth})
              </h5>
            </Col>
          </Row>
        </Col>
        <Col
          md={4}
          lg={4}
          style={{}}
          className="res-pl-2 animate__animated animate__fadeInLeft"
        >
          <Row className="globalContainerStyle p-3 res-mb-3" noGutters>
            <Col xs={3} style={{ flexDirection: "column" }}>
              <h5
                className="m-0 p-0"
                style={{
                  fontWeight: "bolder",
                  alignSelf: "center",
                }}
              >
                <i className="icofont-coins icofont-2x"></i>
              </h5>
            </Col>
            {props.state.getTotalSalesLoading ? (
              <>
                <Col xs={9} style={{ textAlign: "center" }}>
                  <Spinner animation="border" />
                </Col>
              </>
            ) : (
              <>
                <Col
                  xs={9}
                  className="animate__animated animate__fadeInLeft"
                  style={{ alignSelf: "center" }}
                >
                  <h4
                    className="m-0 p-0"
                    style={{
                      fontWeight: "bolder",
                      alignSelf: "center",
                      textAlign: "center",
                    }}
                  >
                    ₱{" "}
                    <NumericFormat
                      value={
                        props.state.TotalSales.totalSpent !== undefined
                          ? Number(props.state.TotalSales.totalSpent).toFixed(2)
                          : 0
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </h4>
                </Col>
              </>
            )}

            <Col xs={12} style={{ flexDirection: "column" }}>
              <h5
                className="m-0 p-0"
                style={{
                  fontWeight: "bolder",
                  alignSelf: "center",
                }}
              >
                Expenses ({props.state.SelectedMonth})
              </h5>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="my-3" noGutters>
        <Col
          md={6}
          lg={6}
          style={{}}
          className="res-pr-2 animate__animated animate__fadeInLeft"
        >
          <Row className="globalContainerStyle p-3 res-mb-3" noGutters>
            <Col xs={3} style={{ flexDirection: "column" }}>
              <h5
                className="m-0 p-0"
                style={{
                  fontWeight: "bolder",
                  alignSelf: "center",
                }}
              >
                <i className="icofont-circled-down icofont-2x"></i>
              </h5>
            </Col>
            {props.state.getTotalSalesLoading ? (
              <>
                <Col xs={9} style={{ textAlign: "center" }}>
                  <Spinner animation="border" />
                </Col>
              </>
            ) : (
              <>
                <Col
                  xs={9}
                  className="animate__animated animate__fadeInLeft"
                  style={{ alignSelf: "center" }}
                >
                  <h4
                    className="m-0 p-0"
                    style={{
                      fontWeight: "bolder",
                      alignSelf: "center",
                      textAlign: "center",
                    }}
                  >
                    ₱{" "}
                    <NumericFormat
                      value={TxtMonthlyTotalReceivables}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </h4>
                </Col>
              </>
            )}

            <Col xs={12} style={{ flexDirection: "column" }}>
              <h5
                className="m-0 p-0"
                style={{
                  fontWeight: "bolder",
                  alignSelf: "center",
                }}
              >
                Accounts Receivable ({props.state.SelectedMonth})
              </h5>
            </Col>
          </Row>
        </Col>

        <Col
          md={6}
          lg={6}
          style={{}}
          className="res-pl-2 animate__animated animate__fadeInLeft"
        >
          <Row className="globalContainerStyle p-3 res-mb-3" noGutters>
            <Col xs={3} style={{ flexDirection: "column" }}>
              <h5
                className="m-0 p-0"
                style={{
                  fontWeight: "bolder",
                  alignSelf: "center",
                }}
              >
                <i className="icofont-circled-up icofont-2x"></i>
              </h5>
            </Col>
            {props.state.getTotalSalesLoading ? (
              <>
                <Col xs={9} style={{ textAlign: "center" }}>
                  <Spinner animation="border" />
                </Col>
              </>
            ) : (
              <>
                <Col
                  xs={9}
                  className="animate__animated animate__fadeInLeft"
                  style={{ alignSelf: "center" }}
                >
                  <h4
                    className="m-0 p-0"
                    style={{
                      fontWeight: "bolder",
                      alignSelf: "center",
                      textAlign: "center",
                    }}
                  >
                    ₱{" "}
                    <NumericFormat
                      value={Math.abs(TxtMonthlyTotalPayable)}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </h4>
                </Col>
              </>
            )}

            <Col xs={12} style={{ flexDirection: "column" }}>
              <h5
                className="m-0 p-0"
                style={{
                  fontWeight: "bolder",
                  alignSelf: "center",
                }}
              >
                Accounts Payable ({props.state.SelectedMonth})
              </h5>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="" noGutters>
        <Col
          md={6}
          lg={6}
          style={{}}
          className="res-pr-2 animate__animated animate__fadeInLeft"
        >
          <Row className="globalContainerStyle p-3 res-mb-3" noGutters>
            <Col xs={3} style={{ flexDirection: "column" }}>
              <h5
                className="m-0 p-0"
                style={{
                  fontWeight: "bolder",
                  alignSelf: "center",
                }}
              >
                <i className="icofont-circled-up icofont-2x"></i>
              </h5>
            </Col>
            {props.state.getTotalSalesLoading ? (
              <>
                <Col xs={9} style={{ textAlign: "center" }}>
                  <Spinner animation="border" />
                </Col>
              </>
            ) : (
              <>
                <Col
                  xs={9}
                  className="animate__animated animate__fadeInLeft"
                  style={{ alignSelf: "center" }}
                >
                  <h4
                    className="m-0 p-0"
                    style={{
                      fontWeight: "bolder",
                      alignSelf: "center",
                      textAlign: "center",
                    }}
                  >
                    <NumericFormat
                      value={props.state.TotalSales.qtySold}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </h4>
                </Col>
              </>
            )}

            <Col xs={12} style={{ flexDirection: "column" }}>
              <h5
                className="m-0 p-0"
                style={{
                  fontWeight: "bolder",
                  alignSelf: "center",
                }}
              >
                Qty Sold ({props.state.SelectedMonth})
              </h5>
            </Col>
          </Row>
        </Col>

        <Col
          md={6}
          lg={6}
          style={{}}
          className="res-pl-2 animate__animated animate__fadeInLeft"
        >
          <Row className="globalContainerStyle p-3 res-mb-3" noGutters>
            <Col xs={3} style={{ flexDirection: "column" }}>
              <h5
                className="m-0 p-0"
                style={{
                  fontWeight: "bolder",
                  alignSelf: "center",
                }}
              >
                <i className="icofont-circled-down icofont-2x"></i>
              </h5>
            </Col>
            {props.state.getTotalSalesLoading ? (
              <>
                <Col xs={9} style={{ textAlign: "center" }}>
                  <Spinner animation="border" />
                </Col>
              </>
            ) : (
              <>
                <Col
                  xs={9}
                  className="animate__animated animate__fadeInLeft"
                  style={{ alignSelf: "center" }}
                >
                  <h4
                    className="m-0 p-0"
                    style={{
                      fontWeight: "bolder",
                      alignSelf: "center",
                      textAlign: "center",
                    }}
                  >
                    <NumericFormat
                      value={props.state.TotalSales.totalAcquired}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </h4>
                </Col>
              </>
            )}

            <Col xs={12} style={{ flexDirection: "column" }}>
              <h5
                className="m-0 p-0"
                style={{
                  fontWeight: "bolder",
                  alignSelf: "center",
                }}
              >
                Qty Acquired ({props.state.SelectedMonth})
              </h5>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
