import moment from "moment";
import { ToWords } from "to-words";
import TransactionsAPI from "../pages/Transactions/TransactionsAPI";
class Misc {
  async calculateInvoicePayment(dispatch, transactionId) {
    const fetchedTrans = await TransactionsAPI.getTransaction(
      dispatch,
      transactionId
    );

    let invoiceTotalAmountPaid = 0;
    let invoiceTotalAmountDue = 0;

    const ITEMS_GRANDTOTAL = fetchedTrans.order.content.reduce((a, c) => {
      return a + c.price * c.itemQty;
    }, 0);

    //Filter invoice transactions for payments
    let selectedInvoicePayments = [];
    selectedInvoicePayments = fetchedTrans.order.paymentTransaction.filter(
      (x) => x.isEntry !== 1
    );
    invoiceTotalAmountPaid = selectedInvoicePayments
      .reduce((a, c) => {
        // if (c.childId == null) return a + c.price * c.itemQty;
        // else return a + c.child.price * c.child.itemQty;
        return a + c.amountValue;
      }, 0)
      .toFixed(2);

    invoiceTotalAmountDue = ITEMS_GRANDTOTAL - invoiceTotalAmountPaid;

    return {
      invoiceTotalAmountDue: Number(invoiceTotalAmountDue),
      invoiceTotalAmountPaid: Number(invoiceTotalAmountPaid),
    };
  }
  calculateMarkup(basePrice, percentage, settingsMarkupPercentage) {
    let modifiedPrice = 0;

    //Use percentage if present
    if (percentage) {
      const percentageModifier = percentage / 100;
      modifiedPrice = basePrice + basePrice * percentageModifier;
    } else {
      const settingsPercentageModifier = settingsMarkupPercentage / 100;
      modifiedPrice = basePrice + basePrice * settingsPercentageModifier;
    }

    modifiedPrice = Number(modifiedPrice.toFixed(2));
    if (percentage || settingsMarkupPercentage) {
      return modifiedPrice;
    } else {
      return basePrice;
    }
  }
  calculateDiscount(basePrice, percentage) {
    let modifiedPrice = 0;

    const percentageModifier = percentage / 100;

    modifiedPrice = basePrice - basePrice * percentageModifier;

    modifiedPrice = Number(modifiedPrice.toFixed(2));
    if (percentage) {
      return modifiedPrice;
    } else {
      return basePrice;
    }
  }
  //Apply discounting first then apply markup
  calculateMarkupDiscount(
    basePrice = 0.0,
    discountPercentage,
    markupPercentage,
    settingsMarkupPercentage
  ) {
    let finalPrice = basePrice;

    if (discountPercentage) {
      finalPrice = this.calculateDiscount(basePrice, discountPercentage);
    }

    if (markupPercentage || settingsMarkupPercentage) {
      finalPrice = this.calculateMarkup(
        finalPrice,
        markupPercentage,
        settingsMarkupPercentage
      );
    }
    // console.log(
    //   basePrice,
    //   discountPercentage,
    //   markupPercentage,
    //   settingsMarkupPercentage,
    //   finalPrice
    // );
    //console.log(finalPrice);
    if (discountPercentage || markupPercentage || settingsMarkupPercentage) {
      return Number(finalPrice.toFixed(2));
    } else {
      return Number(basePrice.toFixed(2));
    }
  }
  currencyToWords(value) {
    const toWords = new ToWords({
      localeCode: "en-US",
      converterOptions: {
        currency: true,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
        doNotAddOnly: false,
        currencyOptions: {
          // can be used to override defaults for the selected locale
          name: "Peso",
          plural: "Pesos",
          symbol: "P",
          fractionalUnit: {
            name: "Cent",
            plural: "Cents",
            symbol: "",
          },
        },
      },
    });
    let convert = 0;
    if (value) convert = value;
    //console.log(convert);
    return toWords.convert(convert);
  }
  parseCurrencyNoCurrency(value) {
    let formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "PHP",

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    let initFormat = formatter.format(value);
    return initFormat.substring(1);
  }
  dateToday(data) {
    if (data) {
      return moment(data).format("LL");
    } else {
      return moment().format("LL");
    }
  }
  dateTimeToday(data) {
    if (data) {
      return moment(data).format("llll");
    } else {
      return moment().format("llll");
    }
  }
  parseAPIDateTime(data) {
    return moment(data).format("YYYY-MM-DDTHH:mm");
  }
  parseAPIDate(data) {
    return moment(data).format("YYYY-MM-DD");
  }
  parseAPIDateTimeToShortDate(data) {
    return moment(data).format("ll");
  }
  parseAPIDateTimeToShortDateTime(data) {
    return moment(data).format("ll HH:mm");
  }
  parseAPIDateTimeToLongDate(data) {
    return moment(data).format("LL");
  }
  debounce = (func, wait) => {
    let timeout;

    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };
}
export default new Misc();
